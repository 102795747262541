import React, { useState } from "react"
import styled from "styled-components"
import TechAssets from "container/TechAssets/index"
import {
  StyledTextArea,
} from "dumbComponents/common/UI/antd"

const StyledWrap = styled.div``

const StyledNote = styled.p``

const Label = styled.label``

const ConfirmationNote = ({
  text,
  updateNote,
  value,
}) => {
  const [currentNote, setNote] = useState("")
  return (
    <StyledWrap>
      <StyledNote>
        {text}
      </StyledNote>
    </StyledWrap>
  )
}

export default ConfirmationNote
