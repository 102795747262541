import React  from 'react';
import {Fetch} from "../../../lib/NetworkUtils";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export function submitLead(agentId, clientId, type, isIsa, referred_client_status) {
    let url = baseUrl + "/v1.0/refer/lead";
    let body =  {
        "agentId": agentId,
        "clientId": clientId,
        "is_isa": isIsa,
        "type": type,
        "referred_client_status": referred_client_status,
    };
    return Fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export function updateReferral(referralId, body) {
    let url = baseUrl + "/v1.0/consumers/referral/" + referralId;
    return Fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export function markProspectInvisible(referralId, body) {
     let url = baseUrl + "/v2.0/prospects/"+ referralId + "/mark-invisible";
    return Fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
    })

}

//convert prospect from -1 to 0
export function createProspect(referralId, body) {
    let url = baseUrl +'/v2.0/prospect-agents/' + referralId;
    return Fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

//assign agent
export function assignUnAssignAgent(receiver_client_id, body) {
    let url = baseUrl +'/v1.0/consumer/' + receiver_client_id;
    return Fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export function getClientDetails(queryParams) {
    let url = baseUrl + '/v1.0/caller/client-details?' + queryParams;
    return Fetch(url, {
        method: 'GET',
    })
}

export function updateProspects(referralId, body) {
    let url = baseUrl + '/v2.0/prospects/' + referralId;
    return Fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export function fetchProspects(referralId, queryParams) {
    let url = baseUrl + '/v2.0/prospect-agents/' + referralId + "?visible=1&fields=agreement_signed_data&" + queryParams;
    return Fetch(url, {
        method: 'GET',
    })
}

export function generateProspects(referralId) {
    let url = baseUrl + '/v2.0/prospect-agents-unverified/' + referralId;
    return Fetch(url, {
        method: 'GET',
    })
}

export function getAgentReferralSettings(agentId) {
    let url = baseUrl + `/v1.0/user/${agentId}/referral-settings?fields=radius_phone_number`;
    return Fetch(url, {
        method: 'GET',
    })
}

export function getReferralSettings(referralId) {
    let url = baseUrl + '/v1.0/crs-referral-settings/' + referralId;
    return Fetch(url, {
        method: 'GET',
    })
}

export function updateReferralSettings(referralId, body) {
    let url = baseUrl + '/v1.0/crs-referral-settings/' + referralId;
    return Fetch(url, {
        method: 'PUT',
        body: JSON.stringify(body),
    })
}

export function getDevicePhoneNumber(body) {
    let url = baseUrl + '/v1.0/get-zillow-device-number'
    return Fetch(url, {
        method: 'GET',
    })
}

export function updateDevicePhoneNumber(body) {
    let url = baseUrl + '/v1.0/set-zillow-device-number'
    return Fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
    })
}

export function getCallerSettings(body) {
    let url = baseUrl + '/v2.0/caller/settings'
    return Fetch(url, {
        method: 'GET',
    })
}

export function updateAutoPrioritizingStatus(body) {
    let url = baseUrl + '/v1.0/caller/toggle-auto-prioritizing-settings'
    return Fetch(url, {
        method: 'PUT',
        body: JSON.stringify(body),
    })
}

export function restartSocket() {
    let url = baseUrl + '/v1.0/gabby/caller/socket/restart'
    return Fetch(url, {
        method: 'GET',
    })
}

export function isValidForAutoCall(referralId, twilioClientName) {
    let url = baseUrl + '/v1.0/gabby/caller/valid-referrals-for-call';
    let body = {
        "twilio_client_name":twilioClientName,
        "referral_ids":[referralId.toString()]
    };
    return Fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export function hideMessage(messageId) {
    let url = baseUrl + '/v1.0/gabby/caller/hide-message/'+messageId;
    return Fetch(url, {
        method: 'PUT',
    })
}

export function markLeadPhoneNumberIncorrect(body) {
    let url = baseUrl + "/v1.0/mark-phone-number-incorrect";
    return Fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}
