/* eslint-disable no-else-return */
/* eslint-disable max-len */
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import root from "window-or-global"
import { isEmpty } from "lodash"
// eslint-disable-next-line import/no-unresolved
import Loader from "dumbComponents/common/UI/Loader"
import {
  Input, Button,
} from "antd"
import container from "../../../container/BrokerDashboard/editAgentContainer"

const Wrap = styled.div`
  
`
const HeaderWrap = styled.div`
 display: flex;
 justify-content: space-between;
`
const Title = styled.h1`
 margin-left: 8px;
 font-weight: 300;
`
const Label = styled.h3`
 margin: 8px 6px 10px 8px;
 font-weight: 200;
`
const InputDiv = styled.div`
 display: flex;
 flex-direction: column;
`
const ButtonDiv = styled.div`
  display: flex;
`

const ImageButton = styled(Button)`
  width: 100px;
`
const UploadButton = styled.input`
  width: 90px;
  margin-left: 8px;
`
const TemplateBusinessCard = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
`
const TemplateDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-right: 5px;
`
const Image = styled.img`
  height: 400px;
  width: 300px;
`
const RowDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
const Checkbox = styled.input`

`

const BrandingInfo = ({
  getBrandingInfoResponse,
  getBrandingAssetsResponse,
  agentId,
  createBrandingInfo,
  uploadDocsToBucketBranding,
  uploadDocsToBucketBrandingResponse,
  updateBrandingInfo,
  yardSign,
  setYardSign,
  businessCard,
  setBusinessCard,
  updateBrandingAssets,
  createBrandingAssets,
}) => {
  const [preferredTitle, setPreferredTitle] = useState("")
  const [logo, setLogo] = useState("")
  const [headshot, setHeadshot] = useState("")

  const [businessCardReqId, setBusinessCardReqId] = useState()
  const [businessCardRarId, setBusinessCardRarId] = useState()
  const [businessCardUploadedAsset, setBusinessCardUploadedAsset] = useState("")
  const [businessCardLayout, setBusinessCardLayout] = useState()

  const [yardSignReqId, setYardSignReqId] = useState()
  const [yardSignRarId, setYardSignRarId] = useState()
  const [yardSignUploadedAsset, setYardSignUploadedAsset] = useState("")
  const [yardSignLayout, setYardSignLayout] = useState()

  const {
    data,
  } = getBrandingInfoResponse || {}

  const { reqAssets } = (getBrandingInfoResponse && getBrandingInfoResponse.data && getBrandingInfoResponse.data.requested_assets) || []

  const {
    data: assets,
    templateUrl: templates,
    assetsInfo: info,
  } = getBrandingAssetsResponse.data || {}

  const {
    isFetching: isUploadingFile,
    data: uploadData,
  } = uploadDocsToBucketBrandingResponse || {}

  const getReqAssetsBusinessCard = () => {
    const ra = data && data.requested_assets && data.requested_assets.map((req) => {
      if (req.identifier === "business_cards") {
        setBusinessCardReqId(req.requirement_id ? req.requirement_id : null)
        setBusinessCardRarId(req.rar_assset_id ? req.rar_assset_id : null)
        setBusinessCardUploadedAsset(req.uploaded_asset_url ? req.uploaded_asset_url : null)
        setBusinessCardLayout(req.layout ? req.layout : null)
      }
      return ra
    })
  }

  const getReqAssetsYardSign = () => {
    const ys = data && data.requested_assets && data.requested_assets.map((req) => {
      if (req.identifier === "yard_signs") {
        setYardSignReqId(req.requirement_id ? req.requirement_id : null)
        setYardSignRarId(req.rar_assset_id ? req.rar_assset_id : null)
        setYardSignUploadedAsset(req.uploaded_asset_url ? req.uploaded_asset_url : null)
        setYardSignLayout(req.layout ? req.layout : null)
      }
      return ys
    })
  }

  const getRARAssetId = () => {
    const ai = info && info.map((x) => {
      if (x.identifier === "business_cards") {
        setBusinessCardRarId(x.id)
      } else {
        setYardSignRarId(x.id)
      }
    })
    return ai
  }

  useEffect(() => {
    if (data) {
      setPreferredTitle(data.preferred_title ? data.preferred_title : null)
      setLogo(data.logo_url ? data.logo_url : null)
      setHeadshot(data.headshot_url ? data.headshot_url : null)

      getReqAssetsBusinessCard()
      getReqAssetsYardSign()

      getRARAssetId()
    }
  }, [data, templates, assets])

  const showLogoImage = () => {
    if ((!isUploadingFile && uploadData && uploadData.logo)) {
      return root.open(uploadData.logo.logo)
    } else if (logo !== null) {
      return root.open(logo)
    } return null
  }
  const showHeadshotImage = () => {
    if (!isUploadingFile && uploadData && uploadData.headshot) {
      return root.open(uploadData.headshot.headshot)
    } else if (headshot !== null) {
      return root.open(headshot)
    } return null
  }

  const showBusinessCardDesign = () => {
    if ((!isUploadingFile && uploadData && uploadData.businessCardDesign)) {
      return root.open(uploadData.businessCardDesign.businessCardDesign)
    } else if (businessCardUploadedAsset !== "") {
      return root.open(businessCardUploadedAsset)
    } return null
  }

  const showYardSignDesign = () => {
    if (!isUploadingFile && uploadData && uploadData.yardSignDesign) {
      return root.open(uploadData.yardSignDesign.yardSignDesign)
    } else if (yardSignUploadedAsset !== "") {
      return root.open(yardSignUploadedAsset)
    } return null
  }

  const handleSave = () => {
    const payloadInfo = {}
    const payloadAssets = {}

    payloadInfo.agent_id = parseInt(agentId, 10) + 1 - 1
    payloadInfo.preferred_title = preferredTitle || null
    payloadInfo.logo_url = uploadData && uploadData.logo ? (uploadData.logo.logo) : logo
    payloadInfo.headshot_url = uploadData && uploadData.headshot ? (uploadData.headshot.headshot) : headshot

    if (!isEmpty(data)) {
      if (payloadInfo.headshot_url === null) {
        return window.alert("Please upload a headshot URL")
      }
      updateBrandingInfo({ payloadInfo })
    } else {
      if (payloadInfo.headshot_url === null) {
        return window.alert("Please upload a headshot URL")
      }
      createBrandingInfo({ payloadInfo })
    }

    if (isEmpty(data.requested_assets)) {
      payloadAssets.agent_id = agentId && parseInt(agentId, 10) + 1 - 1
      payloadAssets.requested_assets = [
        {
          rar_assset_id: yardSignRarId,
          layout: yardSignLayout || null,
          uploaded_asset_url: yardSignUploadedAsset,
        },
        {
          rar_assset_id: businessCardRarId,
          layout: businessCardLayout || null,
          uploaded_asset_url: businessCardUploadedAsset,
        },
      ]
      if (isEmpty(data.requested_assets) && (payloadAssets.requested_assets[0].layout === null || payloadAssets.requested_assets[1].layout === null)) {
        return window.alert("Please select Yard Sign and Business Card layouts")
      }
      createBrandingAssets({ payloadAssets })
    } else {
      payloadAssets.agent_id = agentId && parseInt(agentId, 10) + 1 - 1
      payloadAssets.requested_assets = [
        {
          requirement_id: yardSignReqId,
          rar_assset_id: yardSignRarId,
          layout: yardSignLayout,
          uploaded_asset_url: uploadData && uploadData.yardSignDesign ? uploadData.yardSignDesign.yardSignDesign : yardSignUploadedAsset,
        },
        {
          requirement_id: businessCardReqId,
          rar_assset_id: businessCardRarId,
          layout: businessCardLayout,
          uploaded_asset_url: uploadData && uploadData.businessCardDesign ? uploadData.businessCardDesign.businessCardDesign : businessCardUploadedAsset,
        },
      ]
      if (isEmpty(data.requested_assets) && (payloadAssets.requested_assets[0].layout === null || payloadAssets.requested_assets[1].layout === null)) {
        return window.alert("Please select Yard Sign and Business Card layouts")
      }
      updateBrandingAssets({ payloadAssets })
    }
  }

  return (
    <Wrap>
      <HeaderWrap>
        <Title>Edit Branding Assets Info</Title>
        <Button onClick={() => { handleSave() }}>
          Save
        </Button>
      </HeaderWrap>
      <InputDiv>
        <Label>Preferred Title</Label>
        <Input
          value={preferredTitle}
          style={{
            position: "relative",
            width: "140px",
            marginLeft: "8px",
          }}
          onChange={(e) => {
            setPreferredTitle(e.target.value)
          }}
        />
      </InputDiv>
      <InputDiv>
        <Label>Logo URL</Label>
        <ButtonDiv>
          {(!isUploadingFile && uploadData && uploadData.logo) || logo !== null ? (
            <ImageButton onClick={() => { showLogoImage() }} type="primary" shape="round">
                      View Image
            </ImageButton>
          ) : (
            <Label>No Image Uploaded</Label>
          )}
          {isUploadingFile === true ? (
            <h4>Uploading...</h4>
          ) : (
            <UploadButton
              type="file"
              onChange={(e) => {
                uploadDocsToBucketBranding({
                  file: e.target.files[0],
                  objName: "logo",
                  fileName: e.target.files[0].name,
                  bucketName: "branding_assets_user_logo",
                })
              }}
            />
          )}
        </ButtonDiv>
      </InputDiv>
      <InputDiv>
        <Label>Professional Headshot URL</Label>
        <ButtonDiv>
          {(!isUploadingFile && uploadData && uploadData.headshot) || headshot !== null ? (
            <ImageButton onClick={() => { showHeadshotImage() }} type="primary" shape="round">
              View Image
            </ImageButton>
          ) : (
            <Label>No Image Uploaded</Label>
          )}
          {isUploadingFile === true ? (
            <h4>Uploading...</h4>
          ) : (
            <UploadButton
              type="file"
              onChange={(e) => {
                uploadDocsToBucketBranding({
                  file: e.target.files[0],
                  objName: "headshot",
                  fileName: e.target.files[0].name,
                  bucketName: "branding_assets_user_logo",
                })
              }}
            />
          )}
        </ButtonDiv>
      </InputDiv>
      <Label>Yard Sign Templates</Label>
      {templates ? (
        <TemplateBusinessCard>
          {templates.map(url => (
            <TemplateDiv>
              {url.type.includes("yard_sign") && !url.subtype.includes("overview") ? (
                <>
                  {url.subtype.includes("with_headshot") ? (
                  <>
                    <Image src={url.image_url} />
                    <RowDiv>
                      <Checkbox
                        type="radio"
                        name="YardSign"
                        checked={yardSignLayout && yardSignLayout === "with_headshot" || yardSign && yardSign.layout === "with_headshot"}
                        onClick={() => {
                          setYardSignLayout("with_headshot")
                          setYardSign({
                            requirement_id: yardSignReqId,
                            rar_assset_id: yardSignRarId,
                            layout: "with_headshot",
                            uploaded_asset_url: yardSignUploadedAsset,
                          })
                        }}
                      />
                      <Label>With Headshot</Label>
                    </RowDiv>
                  </>
                  ) : (
                  <>
                    <Image src={url.image_url} />
                    <RowDiv>
                      <Checkbox
                        type="radio"
                        name="YardSign"
                        checked={yardSignLayout && yardSignLayout === "without_headshot"}
                        onClick={() => {
                          setYardSignLayout("without_headshot")
                          setYardSign({
                            requirement_id: yardSignReqId,
                            rar_assset_id: yardSignRarId,
                            layout: "without_headshot",
                            uploaded_asset_url: yardSignUploadedAsset,
                          })
                        }}
                      />
                      <Label>Without Headshot</Label>
                    </RowDiv>
                  </>
                  )}
                </>
              ) : null}
            </TemplateDiv>
          ))}
        </TemplateBusinessCard>
      ) : <Loader />
      }
      <Label>Business Card Templates</Label>
      {templates ? (
        <TemplateBusinessCard>
          {templates.map(url => (
            <TemplateDiv>
              {url.type.includes("business_card") && !url.subtype.includes("overview") ? (
                <>
                  {url.subtype.includes("logo_only") ? (
                  <>
                    <Image src={url.image_url} />
                    <RowDiv>
                      <Checkbox
                        type="radio"
                        name="BusinessCard"
                        checked={businessCardLayout && businessCardLayout === "logo_only"}
                        onClick={() => {
                          setBusinessCardLayout("logo_only")
                          setBusinessCard({
                            requirement_id: businessCardReqId,
                            rar_assset_id: businessCardRarId,
                            layout: "logo_only",
                            uploaded_asset_url: businessCardUploadedAsset,
                          })
                        }}
                      />
                      <Label>Logo Only</Label>
                    </RowDiv>
                  </>
                  ) : (
                  <>
                    {url.subtype.includes("headshot_with_logo_in_front") ? (
                    <>
                      <Image src={url.image_url} />
                      <RowDiv>
                        <Checkbox
                          type="radio"
                          name="BusinessCard"
                          checked={businessCardLayout && businessCardLayout === "headshot_with_logo_in_front"}
                          onClick={() => {
                            setBusinessCardLayout("headshot_with_logo_in_front")
                            setBusinessCard({
                              requirement_id: businessCardReqId,
                              rar_assset_id: businessCardRarId,
                              layout: "headshot_with_logo_in_front",
                              uploaded_asset_url: businessCardUploadedAsset,
                            })
                          }}
                        />
                        <Label>Headshot with Logo in Front</Label>
                      </RowDiv>
                    </>
                    ) : (
                    <>
                      <Image src={url.image_url} />
                      <RowDiv>
                        <Checkbox
                          type="radio"
                          name="BusinessCard"
                          checked={businessCardLayout && businessCardLayout === "logo_in_front_headshot_at_back"}
                          onClick={() => {
                            setBusinessCardLayout("logo_in_front_headshot_at_back")
                            setBusinessCard({
                              requirement_id: businessCardReqId,
                              rar_assset_id: businessCardRarId,
                              layout: "logo_in_front_headshot_at_back",
                              uploaded_asset_url: businessCardUploadedAsset,
                            })
                          }}
                        />
                        <Label>Logo in front and Headshot in the back</Label>
                      </RowDiv>
                    </>
                    )}
                  </>
                  )}
                </>
              ) : null}
            </TemplateDiv>
          ))}
        </TemplateBusinessCard>
      ) : <Loader />
      }
      <InputDiv>
        <Label>Business Card Uploaded Image</Label>
        <ButtonDiv>
          {(!isUploadingFile && uploadData && uploadData.businessCardDesign) || businessCardUploadedAsset !== "" ? (
            <ImageButton onClick={() => { showBusinessCardDesign() }} type="primary" shape="round">
                      View Image
            </ImageButton>
          ) : (
            <Label>No Image Uploaded</Label>
          )}
          {isUploadingFile === true ? (
            <h4>Uploading...</h4>
          ) : (
            <UploadButton
              type="file"
              onChange={(e) => {
                uploadDocsToBucketBranding({
                  file: e.target.files[0],
                  objName: "businessCardDesign",
                  fileName: e.target.files[0].name,
                  bucketName: "branding_assets_user_logo/requested_assets_files_url",
                })
              }}
            />
          )}
        </ButtonDiv>
      </InputDiv>
      <InputDiv>
        <Label>Yard Sign Uploaded Image</Label>
        <ButtonDiv>
          {(!isUploadingFile && uploadData && uploadData.yardSignDesign) || yardSignUploadedAsset !== "" ? (
            <ImageButton onClick={() => { showYardSignDesign() }} type="primary" shape="round">
                      View Image
            </ImageButton>
          ) : (
            <Label>No Image Uploaded</Label>
          )}
          {isUploadingFile === true ? (
            <h4>Uploading...</h4>
          ) : (
            <UploadButton
              type="file"
              onChange={(e) => {
                uploadDocsToBucketBranding({
                  file: e.target.files[0],
                  objName: "yardSignDesign",
                  fileName: e.target.files[0].name,
                  bucketName: "branding_assets_user_logo/requested_assets_files_url",
                })
              }}
            />
          )}
        </ButtonDiv>
      </InputDiv>
    </Wrap>
  )
}

export default container(BrandingInfo)
