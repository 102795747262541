import React, { PureComponent } from "react"
import styled from "styled-components"
import ListingList from "dumbComponents/Listings/components/ListingList"
import cookie from "services/CookieStorage"
import { DownOutlined } from "@ant-design/icons"
import TechAssetsContainer from "container/BrokerDashboard/globalPagesContainer"
// import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import { withRouter } from "react-router-dom"
// import Loader from "@ui/Loader"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import {
  MONTH_OPTIONS,
  YEAR_OPTIONS,
} from "shared/consts"

import { Button, Select, Input, Dropdown, Space } from "dumbComponents/common/UI/antd"
const { Search } = Input
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 42px;
`

const ListingContainer = styled.div`
  margin-top: 28px;
`

const AddNewListing = styled.button`
  height: 36px;
  width: 188px;
  border-radius: 100px;
  background: black;
  color: white;
  cursor: pointer;
  border: none;

  span img {
    height: 15px;
    position: relative;
    left: 11px;
    top: 2px;
  }
`
const ListingHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => props.mt && `
    margin-top: ${props.mt}
  `}
`
const DropDownHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  margin-left: 5px;
  h2 {

  }
  span {
    margin-left: 5px;
  }
`

const FilterSpace = styled.div`display: flex;`
const FilterInnerSpace = styled.div`
  padding: 10px 0 0 20px;
`
class Listings extends PureComponent {
  state = {
    selectedMenu: "My Listing",
    selectedMenuValue: "",
    month: -1,
    year: new Date().getFullYear(),
    team_member_id: null,
    searchText: "",
  }

  componentDidMount() {
    const { getAllListings, getListingStatusMap } = this.props
    const user = cookie.load("user") || {}
    getAllListings({
      skip: 0,
      limit: 200,
      agentId: user.id,
      forListing: true,
    })
    getListingStatusMap()
  }

  getFiltersOptions = () => {
    const { getTeamDetailsResponse } = this.props
    const {
      data: teamDetails,
    } = getTeamDetailsResponse || {}
    const teamNames = (teamDetails && teamDetails.team_members) ? teamDetails.team_members.filter(td => td.member_type !== "Team Lead").map(tm => ({
      key: `${tm.agent_id}::${tm.firstname}'s Listing`,
      label: tm.firstname,
    })) : []

    return [
      {
        key: "::My Listing",
        label: "My Listing",
      },
      {
        key: "all::All Listing",
        label: "All Listing",
      },
      {
        key: "member::My Team Members",
        label: "My Team Members",
      },
      ...teamNames,
    ]
    // { value: "view_specific_team_member", label: `${td.firstname || ""}
  }

  handleMenuClick = (e) => {
    console.log("Eval===", e.key.split("::"))
    const keys = e.key.split("::")
    const team_member_id = ["all", "member"].includes(keys[0]) ? null : keys[0]
    this.setState({
      selectedMenu: keys[1],
      selectedMenuValue: keys[0],
      team_member_id,
    })
    this.handleFilterSelect(team_member_id, keys[0])
  }

  handleFilterSelect = (filterType, filterValue, teamMemberId, selectedMenuVal) => {
    if (filterType && filterValue) {
      this.setState({
        [filterType]: filterValue,
      })
    }
    const { getAllListings } = this.props
    const {
      month, year, team_member_id, selectedMenuValue,
    } = this.state
    // const yr = filterType === "year" ? filterValue : year
    // const mn = filterType === "month" ? filterValue : month
    // const { firstDateEpoch, lastDateEpoch } = mn === -1 ? getFirstAndLastDateFromYear(yr) : getFirstAndLastDateFromMonth(mn, yr)
    const payload = {}
    if (team_member_id) {
      payload.member_agent_id = teamMemberId || team_member_id
    }
    if (selectedMenuValue) {
      payload.filter_by = selectedMenuVal || selectedMenuValue
    }
    console.log("Eval===", payload)
    const user = cookie.load("user") || {}
    getAllListings({
      skip: 0,
      limit: 200,
      agentId: user.id,
      forListing: true,
      ...payload,
    })
  }

  searchOnLocal = (text) => {
    this.setState({
      searchText: text,
    })
  }

  getFilteredListings = () => {
    const { getAllListingsResponse } = this.props
    const { searchText } = this.state
    return getAllListingsResponse.data?.filter(dt => dt.listing_address.includes(searchText) || dt.city.includes(searchText) || dt.state.includes(searchText) || dt.client_name.includes(searchText))
  }

  addNewListing = () => {
    const { setDealToView, history, setLoaderOnAgentInfo, resetListings } = this.props
    // resetListings()
    setLoaderOnAgentInfo(true)
    setDealToView(null)
    history.push("/broker/tc/add-listings/coversheet/listing-agent-info")
  }

  render() {
    const { history, getAllListingsResponse } = this.props
    const { selectedMenu } = this.state
    console.log("==getAllListingsResponse==", getAllListingsResponse)
    return (
      <Wrap>
        <ListingHeader>
          <AddNewListing
            onClick={() => { this.addNewListing() }}
          >
            Add New Listings
            <span>
              <img src={PlusIcon} alt="test" />
            </span>
          </AddNewListing>
          <FilterSpace>
            <FilterInnerSpace>
              <Search
                placeholder="Search by address or agent name"
                onSearch={(e) => { this.searchOnLocal(e.target.value) }}
                style={{ width: 300, border: "1px solid #C7C7C7", borderRadius: "4px" }}
              />
            </FilterInnerSpace>
          </FilterSpace>
        </ListingHeader>
        <ListingHeader mt="10px">
          <Dropdown
            overlayStyle={{
              fontSize: 40,
            }}
            menu={{
              items: this.getFiltersOptions(),
              onClick: this.handleMenuClick,
            }}
          >
            <DropDownHeader>
              <h2>{selectedMenu}</h2>
              <span><DownOutlined /></span>
            </DropDownHeader>
          </Dropdown>
        </ListingHeader>
        <ListingContainer>
          {getAllListingsResponse.isFetching ? (
            <div />
          ) : (
            <>
              {this.getFilteredListings()?.map(dt => (<ListingList listingData={dt} />))}
            </>
          )}

        </ListingContainer>
      </Wrap>
    )
  }
}

export default withRouter(TechAssetsContainer((Listings)))
