import React, { Component } from "react"
import styled from "styled-components"

const HeaderWrap = styled.div`
  width: 100%;
  height: 66px;
  background-color: #1d1f23;
`


class AcademyHeader extends Component{
 componentDidMount() {
  //
 }

 render(){
  return (
    <HeaderWrap>
    </HeaderWrap>
  )
 }
}

export default AcademyHeader
