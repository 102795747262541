import React  from 'react';
import {Fetch} from "../../lib/NetworkUtils";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export function searchAgent(currentSearchText, queryParams) {
    let url = baseUrl + "/v1.0/agent/elastic/query" + "?" + queryParams;
    let body =  {
        "findAll": currentSearchText,
        "sortIsAsc": false,
        "ascending": false,
        "facade": false,
        "from": 0,
        "size": 50,
    };
    return Fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export function propertyMetrics(agentId) {
    let url = baseUrl + '/v1.0/user/' + agentId + '/property-metrics';
    return Fetch(url, {
        method: 'GET',
    })
}

export function getAgentSecureId(agentId) {

    let url = baseUrl + '/v2.0/user/secure_id?getAgentDetails=true&agentId=' + agentId;
    return Fetch(url, {
        method: 'GET',
    })
}

export function getSuggestedAgents(referralId) {
    let url = baseUrl + '/v1.0/crs/referrals/' + referralId + '/suggested-agents';
    return Fetch(url, {
        method: 'GET',
    })

}

export function getSubscription(agentId) {
    let url = baseUrl + '/v2.0/stripe/subscriptions?agentId='+ agentId;
    return Fetch(url, {
        method: 'GET',
    })
}
