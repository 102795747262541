import React, { PureComponent } from "react"
import styled from "styled-components"
import colors from "../../../../lib/colors"
import TechAssetsContainer from "container/TechAssets"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
import {
  Form,
  Button,
  Space,
  Radio,
} from "dumbComponents/common/UI/antd"
import { get } from "lodash"
import {
  PageArray,
  OfferInfoRoute,
  OfferWritingOptInStep,
  SellerInfoRoute,
  DocumentsInfoRoute,
} from "../../consts"
import {
  fetchDetails,
  getAgentId,
  getDealId,
  SubmitForm,
} from "../../commonUtils"
import Sidebar from "../Sidebar"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const HeaderNav = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LabelText = styled.h2`
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`

const Required = styled.span`
  color: #E34C4C
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  formRef = React.createRef();

  componentDidMount() {
    const { getDealDetails } = this.props

    fetchDetails(getDealDetails, OfferWritingOptInStep)
  }

  onFinish = (fieldValues, isDraft) => {
    const {
      history,
      updateOffer,
      createOffer,
    } = this.props

    const currentStep = OfferWritingOptInStep
    const agentId = getAgentId() || false

    SubmitForm(
      fieldValues,
      isDraft,
      createOffer,
      updateOffer,
      history,
      SellerInfoRoute,
      currentStep,
      null,
      false,
      false,
      agentId,
      false,
    )
  }

  onSaveAsDraft = (values) => {
    console.log("===this.formRef.current.getFieldsValue===", this.formRef.current.getFieldsValue())
    this.onFinish(this.formRef.current.getFieldsValue(), true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  getInitialValues = () => {
    const result = {
      writing_opt_in: 1,
    }
    return result
  }

  removeColistingAgent = (index) => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform.filter((sc, ind) => ind !== index)
    // colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
  }

  setOfferWrtingOptIn = (value) => {
    this.setState({
      offerWritingOptIn: value,
    })
  }

  render() {
    const {
      history,
      getCurrentDealDetailsResponse,
      updateOfferActionResponse,
      setLoader,
      offerInfoDetails,
      match,
    } = this.props
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]

    const {
      isFetching: fetchingDeal,
    } = getCurrentDealDetailsResponse || {}

    const {
      isFetching: updatingDeal,
    } = updateOfferActionResponse || {}

    const isLoading = fetchingDeal || updatingDeal
    const state = offerInfoDetails && offerInfoDetails.state
    const enableDocs = ["California", "Oregon", "Washington", "Georgia", "Texas", "Florida", "Colorado"]

    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Offer Writing Opt-In Information
        </HeaderNav>
        <ProgressBarComponent total={PageArray} ongoingOrCompleted={4} />
        <Container>
          <LeftSideBar>
            <Sidebar />
          </LeftSideBar>
          <RightSideBar>
            {isLoading ? (
              <p>Loading</p>
            ) : (
              <Form name="offer_writing_opt_in" colon={false} layout="vertical" requiredMark={false} onFinish={this.onFinish} initialValues={this.getInitialValues()} ref={this.formRef} onFinishFailed={(e) => { console.log("ERROROOOO", e) }} scrollToFirstError>
                <>
                  <FlexBox wd="100%">
                    <Form.Item name="writing_opt_in" label="I need my Radius TC to help with writing this offer" rules={commonRules}>
                      <Radio.Group onChange={(e) => { this.setOfferWrtingOptIn(e.target.value) }}>
                        <Space direction="vertical">
                          <Radio value={1}>
                            <LabelComponent text="Yes" />
                          </Radio>
                          <Radio value={0}>
                            <LabelComponent text="No" />
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </FlexBox>
                </>
                <FlexBox>
                  <FlexBox wd="100%">
                  </FlexBox>
                  <FlexBox>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "white",
                          float: "right",
                          borderColor: "#3B60E4",
                          color: "#3B60E4",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        // htmlType="submit"
                        onClick={() => {
                          if (enableDocs.includes(state)) {
                            history.push(`${DocumentsInfoRoute}?dealId=${getDealId()}&agentId=${getAgentId()}`)
                          } else {
                            history.push(`${OfferInfoRoute}?dealId=${getDealId()}&agentId=${getAgentId()}`)
                          }
                          setLoader(true)
                        }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "#3B60E4",
                          float: "right",
                          borderColor: "white",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        loading={isLoading}
                        htmlType="submit"
                      // onClick={() => { history.push("/realty/listings/coversheet/listing-details") }}
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </FlexBox>
                </FlexBox>
              </Form>
            )}
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)

