import React from "react"
import { Tabs } from "antd"
import styled from "styled-components"
import { get } from "lodash"
import Assets from "dumbComponents/BrokerDashboard/AgentDashboard/Assets"
import BrandingInfo from "dumbComponents/BrokerDashboard/AgentDashboard/BrandingInfo"

const Wrap = styled.div`
  padding: 20px;
`

class AssetsDashboard extends React.PureComponent {
  componentDidMount() {

  }

  render() {
    const { match } = this.props
    const agentId = get(match, "params.agentId")
    return (
      <Wrap>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Assets" key="1">
            <Assets agentId={agentId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Branding Info" key="2">
            <BrandingInfo agentId={agentId} />
          </Tabs.TabPane>
        </Tabs>
      </Wrap>
    )
  }
}

export default AssetsDashboard
