import React from "react"
import root from "window-or-global"
import {
  ResetButton,
} from "./styles"

const ResetCTA = () => (
  <ResetButton
    onClick={() => {
      root.location.href = "/broker/ai"
    }}
  >
    Clear Filter
  </ResetButton>
)

export default ResetCTA
