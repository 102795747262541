import {
  Drawer,
  Tag,
  Input,
  Button,
  Select,
  Modal,
} from "antd"
import React, { useState } from "react"
import styled from "styled-components"
import cookie from "react-cookies"
import RARTechContainer from "container/TechAssets"
import ImageUploader from "react-images-upload"
import { withRouter } from "react-router-dom"
import PhotoEditor from "dumbComponents/common/PhotoEditor"
import ImageSelect from "./ImagesSelector"
import { ExclamationCircleOutlined, CloseCircleTwoTone } from "@ant-design/icons"
import ConfirmationNote from "../../Common/ConfirmationNote"
const baseUrl = process.env.REACT_APP_API_BASE_URL
const token = process.env.REACT_APP_SECRET_ID

const ImageUploaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .fileUploader {
    width: 30%;
    .fileContainer {
      text-align: center;
      box-shadow: none;
      margin: 0;
      padding: 0;
    }
  }
  // img {
  //   width: 200px;
  //   height: 100px;
  // }
`

//Offer Statuses
const OFFER_STATUSES = [
  {
    value: "incomplete",
    label: "Incomplete",
  },
  {
    value: "new_offer",
    label: "New Offer",
  },
  {
    value: "pending_signatures",
    label: "Pending Signatures",
  },
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "offer_accepted",
    label: "Offer Accepted",
  },
  {
    value: "rejected",
    label: "Rejected",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
]

//Color Map
const ColorMap = [
  {
    value: "#B3E4C7",
    label: "completed",
  },
  {
    value: "#B3E4C7",
    label: "offer_accepted",
  },
  {
    value: "#D2D8D8",
    label: "incomplete",
  },
  {
    value: "#FAEBA6",
    label: "pending_signatures",
  },
  {
    value: "#F0C3A2",
    label: "new_offer",
  },
  {
    value: "#D2D8D8",
    label: "rejected",
  },
  {
    value: "#EBBAB5",
    label: "cancelled",
  },
]

const { confirm } = Modal

const Wrap = styled.div`
 display: flex;
 justify-content: space-between;
 flex-direction: ${props => props.flex};
 align-items: baseline;

 ${props => props.padding && `
  padding: ${props.padding};
 `}

  ${props => props.gap && `
    gap: ${props.gap};
  `}
`

const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  background: #F9F9F7;
  padding-left: 10px;
`

const StatusWrap = styled.div`
  display: flex;
  align-items: center;
  align-items: flex-start;
  padding-left: 10px;
  flex-direction: column;
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #849DF8;
    margin: 10px 0 0;
  }
`
const CloseCircleTwoToneIcon = styled(CloseCircleTwoTone)`
  margin-top: -10px;
  position: absolute;
  top: 0;
  font-size: 25px;
  cursor: pointer;
  margin-left: -14px;
`

const StatusButton = styled.div`
  width: 12.23px;
  height: 12.23px;
  background: #B3E4C7;
  ${props => props.bg && `
    background: ${props.bg}
  `}
  border-radius: 50%;
`

const Property = styled.p`
  font-size: 28px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
`

const CTAWrap = styled.div``

const ImageWrapper = styled.div`
  display: inline-block;
  position: relative;
`

const OfferInformation = (props) => {
  const [currentStatus, setStatus] = useState("")
  const [currentLinkedAgentId, setLinkedAgentId] = useState("")
  const [currentLinkedAgent, setLinkedAgent] = useState("")
  const [isError, setError] = useState("")
  const [iconUrl, setIconUrl] = useState(null)
  const [iconEditShow, setEdit] = useState(false)
  const [currImage, setCurrImage] = useState("")
  const [imageLoading, setImageLoading] = useState(false)

  const {
    toggleDrawer,
    currentDealToEdit: data,
    isEditDrawerOpen,
    updateOfferStatus,
    updateNote,
    offerStatusChangeNote,
    history,
    uploadImageForProperty,
  } = props || {}

  const getHumanReadableValue = status => OFFER_STATUSES.find(stat => stat.value === status)?.label

  const handleChange = (prevValue, newValue, id, agentId) => {
    const PREVIOUS = getHumanReadableValue(prevValue)
    const UPDATED = getHumanReadableValue(newValue)

    const adminName = cookie.load("user").name

    const statusChangeText = `Status change from ${PREVIOUS} to ${UPDATED}`

    const additionalText = `
      Since you are changing the status of this Offer to 'Offer Completed', 
      we would create a Contract with status as "Incomplete Contract",
      the Contract needs to updated with correct details.
    `

    const isOfferAccepted = newValue === "offer_accepted"

    if (prevValue !== newValue) {
      confirm({
        title: "Do you want to update the offers status?",
        icon: <ExclamationCircleOutlined />,
        content: <ConfirmationNote
          text={isOfferAccepted ? additionalText : statusChangeText}
          updateNote={updateNote}
          value={offerStatusChangeNote}
        />,
        onOk() {
          const payload = {
            notes: offerStatusChangeNote || "",
            offer_id: id,
            status: newValue,
            admin_name: adminName || null,
          }
          updateOfferStatus({
            payload,
            agentId,
            isOfferAccepted,
            history,
          })
        },
        onCancel() {
          //
        },
      })
    }
  }

  const handleClose = () => {
    toggleDrawer({
      data: null,
      bool: false,
    })
    clearFields()
  }

  const getColor = status => ColorMap.find(cl => cl.label === status)?.value

  const clearFields = () => {
    setStatus(null)
    setLinkedAgent("")
    setLinkedAgent("")
    setError(false)
    setIconUrl(null)
    setCurrImage(null)
    setImageLoading(false)
    setEdit(false)
  }

  const createReactImageCropReader = (picture) => {
    const reader = new FileReader()
    reader.addEventListener(
      "load",
      () => {
        setCurrImage(reader.result)
        setEdit(true)
      },
      false
    )
    reader.readAsDataURL(picture[0])
  }

  const removeImage = () => {
    clearFields()
  }

  const uploadTeamCoverImage = async (picture) => {
    const fd = new FormData()
    fd.append("imageFile", picture)
    const datum = {
      filePath: `offersDeal/${Math.random().toString(36).replace(/[^a-z]+/g, "").substr(0, 5)}`,
      config: {
        bucket: "broker",
        convertTo: "jpeg",
      },
    }
    fd.append("requestBody", JSON.stringify(datum))
    let resp = await fetch(`${baseUrl}/v1.0/image`, {
      method: "POST",
      headers: {
        "internal-id": token,
        "X-User-Agent": "agent-php",
      },
      body: fd,
    })
    resp = await resp.json()

    const url = resp.response.imageURL
    uploadImageForProperty({
      payload: {
        offer_id: data.dealId,
        image_urls: [url],
      },
    })

    clearFields()
  }

  const closeImageEdit = () => {
    clearFields()
  }

  const FlexWrap = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
    align-items: end;
  `

  return (
    <Drawer
      width={537}
      placement="left"
      onClose={handleClose}
      visible={isEditDrawerOpen}
    >
      {data && (
        <Wrap
          flex="column"
          gap="50px"
        >
          <Property>
            {`${data.propertyName}, ${data.propertyAddress}`}
          </Property>
          <FlexWrap>
            <StatusWrap>
              <p>Update Offer Status: </p>
              <SelectWrap>
                <StatusButton bg={getColor(data.currentStatus)} />
                <Select
                  value={data.currentStatus}
                  onChange={
                    (e) => {
                      handleChange(data.currentStatus, e, data.dealId, data.primaryAgentId)
                    }}
                  style={{
                    width: 155,
                    backgroundColor: "#F9F9F7",
                  }}
                  bordered={false}
                  disabled={data.currentStatus === "incomplete"}
                  options={OFFER_STATUSES}
                />
              </SelectWrap>
            </StatusWrap>
            <CTAWrap>
              <Button
                type="primary"
                onClick={() => {
                  history.push(`/broker/tc/add-offers/coversheet/buyer-agent-info?dealId=${data.dealId}&agentId=${data.primaryAgentId}`)
                }}
              >
                Click here to Edit Offer Details
              </Button>
            </CTAWrap>
          </FlexWrap>
          <StatusWrap mt="35px">
            <div>
              {/* <RequiredWrap> * </RequiredWrap> */}
              Property Image
            </div>
            <ImageUploaderContainer>
              <ImageUploader
                buttonText="Upload Image"
                onChange={(picture) => { createReactImageCropReader(picture, "icon") }}
                imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                maxFileSize={5242880}
                singleImage
              />
              {/* https://staging-radius.imgix.net/BlankImage.svg */}
              {imageLoading ? <div>Uploading....</div> : (
                <ImageWrapper>
                  {iconUrl ? (
                    <>
                      <img width="300" style={{ border: "2px solid #096dd9" }} src={iconUrl} />
                      <CloseCircleTwoToneIcon
                        onClick={() => { removeImage("icon") }}
                      />
                    </>
                  ) : (
                    <>
                      <img width="200" src="https://staging-radius.imgix.net/BlankImage.svg" />
                    </>
                  )}
                </ImageWrapper>
              )}
            </ImageUploaderContainer>
            {iconEditShow && (
              <PhotoEditor
                type=""
                imageSrc={currImage}
                uploadImage={uploadTeamCoverImage}
                closeImageEdit={closeImageEdit}
                cropConfig={1}
              />)}
          </StatusWrap>
          <ImageSelect imageArray={data.propertyImages} />
        </Wrap>
      )}
    </Drawer>
  )
}
export default withRouter(RARTechContainer(OfferInformation))
