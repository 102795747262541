import React, { Component } from 'react';
import _ from 'lodash';
import "./QualifiedSearching.css";
import {NotifyBugsnagError, ShowErrorToast, ShowErrorToastFromText, ShowSuccessToast} from "../../../lib/ErrorUtils";
import {getSuggestedAgents, propertyMetrics, searchAgent} from "../../API/AgentApi";
import AgentBox from "./AgentBox";
import AgentComparisonFilter from "./AgentComparisonFilter";
import AgentSearchResult from "./AgentSearchResult";
import {
    fetchProspects,
    createProspect,
    generateProspects
} from "../../API/Referral/ReferralApi";
import AddToMarketPlace from "./AddToMarketPlace";
import {DefaultLoader} from "../../UI/Loader/Loader";
import AgentColorMap from "./AgentColorMap";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;

//const
const starredString = "Received referrals from Radius before";
const NO_OF_PROSPECTS_TO_SELECT_FOR_LMP = 60;

class QualifiedSearching extends Component {

    state = {

        loading: true,
        referral: null,
        suggestedAgents: [],
        filteredAgents: [],
        agentMetrics: {
            assignedAgentIds: [],
            recommendedProspectIds: [],
            sentProspectIds: [],
            acceptedProspectIds: [],
            rejectedProspectIds: [],
            agentIdsForMarketPlace: [],
        },

        agentSearchText: '',
        agentSearchResults: [],
        showAgentSearch: false,

        filter : {
            showCRSOnly: false,
            showPartnerOnly: false,
            showRCAOnly: false,
            showSignedOnly: false,
            showRegisteredOnly: false,
            showStarredOnly:false,
        },
    };

    componentDidMount() {
        this.setState({
            referral: this.props.referral
        });
        let limit;
        if (this.props.fromScreen && this.props.fromScreen === 2){
            limit = 3;
        }
        else{
            limit = 200;
        }
        this.loadSuggestedAgents(this.props.referral.id, limit);
    }


    componentWillReceiveProps = (props) => {
        this.setState({
            referral: props.referral
        });
    }

    startLoader = () => {
        this.setState({loading: true})
    };

    stopLoader = () => {
        this.setState({loading: false})
    };

    handleFilterChange = (key) => {
        let filter = this.state.filter;
        filter[key] = !!!filter[key];
        this.setState(filter);
        this.filterAgents(this.state.suggestedAgents);
    };

    addOrRemoveToMarketPlaceAgentIds = (agentId) => {
        let agentIdsForMarketPlace = this.state.agentMetrics.agentIdsForMarketPlace;
        if(_.includes(agentIdsForMarketPlace, agentId)) {
            agentIdsForMarketPlace.splice(agentId);
            let agentIds = _.remove(agentIdsForMarketPlace, function(n) {
                return n !== agentId;
            });
            let agentMetrics = this.state.agentMetrics;
            agentMetrics.agentIdsForMarketPlace = agentIds;
            this.setState({agentMetrics: agentMetrics});
        } else {
            let agentMetrics = this.state.agentMetrics;
            agentMetrics.agentIdsForMarketPlace.push(agentId);
            this.setState(agentMetrics);
        }
    };

    clearAgentSearch = () => {
        this.setState({agentSearchText: '', showAgentSearch: false});
    };

    addAgent = (agent) => {
        agent.prospect_status = -1;
        let agents = [agent].concat(this.state.suggestedAgents);
        this.setState(
            {suggestedAgents: agents, showAgentSearch: false, filteredAgents: agents}
        );
    };

    assignAndRefreshPage = async (agentId) => {
        try {
            let agentMetrics = this.state.agentMetrics;
            agentMetrics.assignedAgentIds.push(agentId);
            this.fetchLatestToggleStatus();
            this.setState({agentMetrics: agentMetrics});
        } catch(e) {
            console.log(e);
            NotifyBugsnagError(e);
            ShowErrorToast(e);
        }
    };

    unassign = async (id) => {
        try {
            const assignedAgentIds = _.filter(this.state.agentMetrics.assignedAgentIds, (agentId) => agentId !== id);
            let agentMetrics = this.state.agentMetrics;
            agentMetrics.assignedAgentIds = assignedAgentIds;
            this.fetchLatestToggleStatus();
            this.setState({agentMetrics : agentMetrics});
        } catch(e) {
            console.log(e);
            NotifyBugsnagError(e);
        }
    };

    invite = async(agentId) => {
        try {
            let agentMetrics = this.state.agentMetrics;
            const recommendedProspectIds = _.filter(agentMetrics.recommendedProspectIds, (agentId) => agentId !== agentId);
            agentMetrics.sentProspectIds.push(agentId);
            agentMetrics.recommendedProspectIds = recommendedProspectIds;
            this.setState({agentMetrics : agentMetrics});
            this.fetchLatestToggleStatus();
        } catch(e) {
            console.log(e);
            NotifyBugsnagError(e);
        }
    };

    removeProspect = async(agent) => {
        try {
            let agentMetrics = this.state.agentMetrics;
            const filteredAgents = _.filter(this.state.filteredAgents, (filteredAgent) => filteredAgent.id !== agent.id);
            const sentProspectIds = _.filter(agentMetrics.sentProspectIds, (agentId) => agentId !== agent.id);
            const recommendedProspectIds = _.filter(agentMetrics.recommendedProspectIds, (agentId) => agentId !== agent.id);
            const agentIdsForMarketPlace = _.filter(agentMetrics.agentIdsForMarketPlace, (agentId) => agentId !== agent.id);
            agentMetrics.sentProspectIds = sentProspectIds;
            agentMetrics.recommendedProspectIds = recommendedProspectIds;
            agentMetrics.agentIdsForMarketPlace = agentIdsForMarketPlace;
            this.setState({agentMetrics : agentMetrics, filteredAgents: filteredAgents});
        } catch(e) {
            console.log(e);
            NotifyBugsnagError(e);
        }
    };

    toggleLiveOnMarketPlace = async () => {
        let referral = this.state.referral;
        let liveOnMLP = !!!referral.live_on_lmp;
        referral.live_on_lmp = liveOnMLP;
        this.startLoader();
        try {
            let agentMetrics = this.state.agentMetrics;
            let body =  {
                "live_on_lmp": liveOnMLP,
            };
            if (agentMetrics.agentIdsForMarketPlace.length > 0 && liveOnMLP === true) {
                body["agent_ids"] = agentMetrics.agentIdsForMarketPlace;
                body["prospect_status"] = 0;
            }
            await createProspect(this.state.referral.id, body).then(res => {
                this.setState({referral: referral});
                this.loadSuggestedAgents(this.state.referral.id);
                this.fetchLatestToggleStatus();
                ShowSuccessToast("Success!!!");
                this.stopLoader();
            }).catch(err => {
                ShowErrorToast(err);
                this.stopLoader();
            });
        } catch(e) {
            console.log(e);
            NotifyBugsnagError(e);
            this.stopLoader();
        }
    };

    fetchLatestToggleStatus = async () => {
        try{
            let referralId = this.state.referral.id;
            let res = await fetch(baseUrl+'/v2.0/get-toggle-status/'+referralId, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'secure-id': token,
                },
            });
            res = await res.json();
            let referral = this.state.referral;
            referral.live_on_lmp = res.response.live_on_lmp;
            this.setState({referral});
            this.props.updateReferralLMPStatusOnParent(referralId, res.response.live_on_lmp);
        }
        catch(e){
            NotifyBugsnagError(e);
        }
    }

    loadSuggestedAgents = async (referralId, limit = 200, refreshToggle = false) => {
        if (!referralId) {
            return;
        }
        this.startLoader();
        try {
            await fetchProspects(referralId, "offset=0&limit="+ limit   ).then(res => {
                let agentMetrics = this.state.agentMetrics;
                let suggestedAgents = res.response && res.response.agents ? res.response.agents : [];
                agentMetrics.assignedAgentIds = res.response.assignedAgentIds ? res.response.assignedAgentIds : [];
                agentMetrics.recommendedProspectIds = res.response.recommendedProspectIds ? res.response.recommendedProspectIds : [];
                agentMetrics.sentProspectIds = res.response.sentProspectIds ? res.response.sentProspectIds : [];
                agentMetrics.acceptedProspectIds = res.response.acceptedProspectIds ? res.response.acceptedProspectIds : [];
                agentMetrics.rejectedProspectIds = res.response.rejectedProspectIds ? res.response.rejectedProspectIds : [];
                agentMetrics.agentIdsForMarketPlace = [];

                let count = 0;
                if(agentMetrics.assignedAgentIds.length < 3
                    && agentMetrics.sentProspectIds.length < NO_OF_PROSPECTS_TO_SELECT_FOR_LMP
                    && agentMetrics.recommendedProspectIds.length !== 0) {
                    _.each(suggestedAgents, function (agent) {
                        if(agent.prospect_status === -1 && count < NO_OF_PROSPECTS_TO_SELECT_FOR_LMP - agentMetrics.sentProspectIds.length) {
                            agentMetrics.agentIdsForMarketPlace.push(agent.id);
                            count++;
                        }
                    });
                }

                this.setState({agentMetrics: agentMetrics, suggestedAgents: suggestedAgents, filteredAgents: suggestedAgents});
                this.props.updateBrokerAgentsCountOnParent(res.response.broker_agents_count);
                this.stopLoader();
                if (refreshToggle){
                    this.fetchLatestToggleStatus();
                }
            });
        } catch(e) {
            console.log(e);
            this.setState({loading: false});
            NotifyBugsnagError(e);
            this.stopLoader();
        }
    };

    generateSuggestedAgents = async (referralId) => {
        if (!referralId) {
            return;
        }
        this.startLoader();
        await generateProspects(referralId).then(res => {
            ShowSuccessToast("Recommendations generated");
            //this.loadSuggestedAgents(referralId, 200, true);
            this.stopLoader();
        }).catch(err => {
            ShowErrorToast(err);
            this.stopLoader();
        });
    };

    doNotContact = (id) => {
        // this.setState({apiCall:true});
        // let body = {"do_not_contact":1};
        // Fetch(baseUrl + '/v1.0/user/' + id + '/referral-settings', {
        //     method: "PUT",
        //     body: JSON.stringify(body)
        // }).then(respJson => {
        //     ShowSuccessToast("This agent will not be contacted or suggested anymore", 3000);
        //     this.setState({apiCall:false, suggestedAgents: this.state.suggestedAgents.filter(function(agent) {
        //             return agent.id !== id
        //         })});
        // }).catch(e => {
        //     this.setState({apiCall:false});
        //     ShowErrorToast(e);
        // });
    };

    handleAgentSearchChange = async (e) => {
        const currentSearchText = e.target.value;
        this.setState({agentSearchText: currentSearchText, showAgentSearch: true});

        _.debounce(async () => {
            try {
                return await searchAgent(currentSearchText, "fields=agreement_signed_date")
                    .then(res => {
                        this.setState({agentSearchResults: res.response.agents});
                    });
            } catch(e) {
                console.log(e);
                NotifyBugsnagError(e);
            }
        }, 500)();
    };

    filterAgents = (agents) => {
        let suggestedAgentsList = [];
        _.each(agents || [], (agent) => {

            const isRCA=(agent) => agent.is_rca;
            const isPartner = (agent) => agent.is_partner;
            const isCrs=(agent) => agent.isCrsDesignee;
            const isRegistered=(agent) => agent.unregistered_agent === "N";
            const isSigned=(agent) => agent.leadAgreementSigned === "signed";
            const isStarred=(agent) => agent.reasons && agent.reasons.indexOf(starredString) > -1;

            let agentSelected = true;
            let anyConditionSelected = false;
            let filter = this.state.filter;
            if(filter.showCRSOnly){
                anyConditionSelected = true;
                agentSelected = agentSelected && isCrs(agent);
            }
            if(filter.showRCAOnly){
                anyConditionSelected = true;
                agentSelected = agentSelected && isRCA(agent);
            }
            if(filter.showPartnerOnly){
                anyConditionSelected = true;
                agentSelected = agentSelected && isPartner(agent);
            }
            if(filter.showRegisteredOnly){
                anyConditionSelected = true;
                agentSelected = agentSelected && isRegistered(agent);
            }
            if(filter.showSignedOnly){
                anyConditionSelected = true;
                agentSelected = agentSelected && isSigned(agent);
            }
            if(filter.showStarredOnly){
                anyConditionSelected = true;
                agentSelected = agentSelected && isStarred(agent);
            }

            if(anyConditionSelected && agentSelected){
                suggestedAgentsList.push(agent);
            }
            if(!anyConditionSelected){
                suggestedAgentsList.push(agent);
            }
        });
        this.setState({filteredAgents: suggestedAgentsList})
    };

    render() {

        let suggestedAgents = "No suggested agents for this lead";
        if(this.state.filteredAgents.length > 0) {
            suggestedAgents = this.state.filteredAgents.map((agent) => {
                return (
                    <AgentBox key={agent.id} agent={agent}
                              assign={this.assign}
                              unassign={this.unassign}
                              invite={this.invite}
                              assignAndRefreshPage={this.assignAndRefreshPage}
                              addOrRemoveToMarketPlaceAgentIds={this.addOrRemoveToMarketPlaceAgentIds}
                              removeProspect={this.removeProspect}
                              referral={this.state.referral}
                              agentMetrics={this.state.agentMetrics}
                    />
                );
            })
        }

        return (
            <div>
                {this.state.loading === true ?
                    <DefaultLoader height={0} width={0}/>
                    :
                    <div>
                        <AgentComparisonFilter filter={this.state.filter}
                                               handleFilterChange={this.handleFilterChange}/>

                        <div className={"agents-comparison"}>
                            <div className="heading">
                                <span >
                                    <input className="agent-search-box"
                                           placeholder="Search an agent by their name"
                                           onChange={this.handleAgentSearchChange}
                                           value={this.state.agentSearchText} />
                                    <i className="fa fa-times" aria-hidden="true" onClick={this.clearAgentSearch}></i>
                                </span>
                                {this.state.showAgentSearch &&
                                <div className="searchResults">
                                    <AgentSearchResult addAgent={this.addAgent} agents={this.state.agentSearchResults}/>
                                </div>
                                }

                                <button onClick={() => this.loadSuggestedAgents(this.state.referral.id, 200, true)}>
                                    Reload Prospects</button>&nbsp;&nbsp;&nbsp;

                                {/*<button onClick={() => this.generateSuggestedAgents(this.state.referral.id, 200, true)}>
                                    ReGenerate Prospects for unverified lead</button>*/}

                                {this.state.referral.is_isa === 0 && <AddToMarketPlace toggleLiveOnMarketPlace={this.toggleLiveOnMarketPlace}
                                                  referral={this.state.referral}
                                                  agentIds={this.state.agentMetrics.agentIdsForMarketPlace}/>}

                            </div>
                            <hr/>
                            <div className={"agents-to-compare"}>
                                {suggestedAgents}
                            </div>
                        </div>

                        {/*<AgentColorMap/>*/}

                    </div>
                }
            </div>
        );
    }
}

export default QualifiedSearching;
