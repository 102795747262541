import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "AGENT_COMPARISON"

export const getAgentsForComparisonAction = createSignalAction(BASE, "GET_AGENTS_FOR_COMPARISON")
export const assignAgentAction = createSignalAction(BASE, "ASSIGN_AGENT")
export const liveTransferAction = createSignalAction(BASE, "LIVE_TRANSFER")
export const searchAgentAction = createSignalAction(BASE, "SEARCH_AGENT")
export const removeFromSuggestionsAction = createSignalAction(BASE, "REMOVE_FROM_SUGGESTIONS")
export const reloadProspectsAction = createSignalAction(BASE, "RELOAD_PROSPECTS")
export const toggleLMPAction = createSignalAction(BASE, "TOGGLE_LMP")
export const getLMPStatusAction = createSignalAction(BASE, "GET_LMP_STATUS")
export const inviteAgentAction = createSignalAction(BASE, "INVITE_AGENT")
export const toggleStarAction = createSignalAction(BASE, "TOGGLE_STAR")


export const updateTheStarInStateAction = createSimpleCreator(BASE, "UPDATE_STAR")
export const setFilterKeysAction = createSimpleCreator(BASE, "FILTER_OPTIONS")
