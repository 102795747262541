import React from "react"
import styled from "styled-components"

import {
  Form,
  AutoComplete,
  Checkbox,
  DatePicker,
  InputNumber,
  Input,
  Mentions,
  Rate,
  Radio,
  Switch,
  Slider,
  Select,
  TreeSelect,
  Transfer,
  Upload,
  Tooltip,
} from "antd"

const { TextArea } = Input

const StyledInput = styled(Input)`
`

const StyledSelect = styled(Select)`

`

const StyledTextArea = styled(TextArea)``

export {
  Form,
  AutoComplete,
  Checkbox,
  DatePicker,
  InputNumber,
  Mentions,
  Rate,
  Radio,
  Switch,
  Slider,
  TreeSelect,
  Transfer,
  Upload,
  StyledInput as Input,
  StyledSelect as Select,
  StyledTextArea,
  Tooltip,
}
