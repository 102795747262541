import React, { Component } from 'react';
import _ from "lodash";

class AgentSearchResult extends Component{

    render() {

        const {agents, addAgent} = this.props;


        let agentSearchResults = _.filter(agents,(result) => {
            return ((result.firstname && result.firstname !== '') || ((result.lastname && result.lastname !== ' ')));
        });

        const agentSearchOptions =  agentSearchResults.map(
            (agent) => {
                return (<div key={agent.id} onClick= {() => {}}>
                    <span className="agent-name-in-search">
                      {(agent.firstname ? agent.firstname : ' ')+ ' ' + (agent.lastname ? agent.lastname : ' ')}
                    </span>
                        <button className= "agent-search-button" onClick={() => addAgent(agent)}>Add</button>
                        <br/>
                        {agent.agency_name &&
                        <span>
                          <span className="agent-name-in-search">
                            {agent.agency_name}
                          </span>
                          <br/>
                      </span>
                        }
                        {agent.office_city &&
                        <span className="agent-name-in-search">
                        {agent.office_city} {agent.office_state ? (', ' + agent.office_state) : ''}
                      </span>
                        }
                        <hr/>
                    </div>
                );
            }
        );

        return (
            <div>
                {agentSearchOptions}
            </div>
        );
    }
}

export default AgentSearchResult;