import styled from "styled-components"

export const StatsHeaderTitle = styled.div`
  h2 {
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.108px;
  }
`

export const SectionalDiv = styled.div`
  display: flex;
  padding: 18px 20px 18px 32px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  gap: 20px;
  flex-wrap: wrap;

  ${props => props.sectionBorder && `
    border-right: 0.5px solid #E3E4FC;
  `}
`

export const StatsParentDiv = styled.div`
  display: flex;
  padding: 12px 16px 18px 16px;
  align-items: center;
  // gap: 20px;
  align-self: stretch;
  border-radius: 3px;
  border: 1px solid var(--Light-Blue, #E3E4FC);
  background: #FBFBFF;
  flex-wrap: wrap;
`

export const StatCard = styled.div`
  display: flex;
  width: 285px;
  min-height: 148px;
  padding: 20px;
  // align-items: center;
  // align-content: center;
  gap: 0px 10.861px;
  flex-shrink: 0;
  // flex-wrap: wrap;
  flex-direction: column;

  border-radius: 5.134px;
  border: 0.856px solid var(--Dark-Blue, #41446A);
  background: var(--White, #FFF);
  box-shadow: 0px 1.711px 8.556px 0px rgba(0, 0, 0, 0.10);
`

export const StatIcon = styled.div`
  width: 20.535px;
  height: 20.535px;

  img {
    width: 100%;
    height: 100%;
  }
`

export const StatTitle = styled.p`
  color: var(--Gray1, #828282);
  font-family: Roboto;
  font-size: 13.69px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const StatNumber = styled.p`
  color: var(--Value-Color, #0B1354);
  font-family: Roboto;
  font-size: 34.224px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.283px;
`

export const ResponsesText = styled.p`
  color: var(--Gray2, #4F4F4F);
  font-family: Roboto;
  font-size: 11.979px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const NegativeFeedbackTitles = styled.p`
  color: var(--gray-900, #18181B);

  /* Semi-bold/13px */
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 161.538% */
`

export const OrangeDot = styled.p`
  width: 10.538px;
  height: 10.538px;
  background-color: var(--Color-Accent, #FFAB55);
`

export const NegativeFeedbackPercentageNumber = styled.p`
  color: var(--gray-900, #18181B);
  text-align: right;

  /* Semi-bold/13px */
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 161.538% */
`
