import React from "react"
import {
  AgentChatColumn,
} from "../../chatSectionStyles"
import AI from "container/AI/index"
import AgentCard from "../AgentCard"

const ChatTopics = ({
  fetchAIChatSessionsResponse,
  currentTopics,
  setCurrentSessionsArray,
  fetchAgentChatSession,
}) => {
  const {
    data: chats,
    isFetching: fetchingChats,
  } = fetchAIChatSessionsResponse || {}

  const handleCurrentTopicSet = (key) => {
    const selectedChat = chats[key]
    const currentTopic = {
      currentAgentKey: key,
      topics: selectedChat.chat_sessions,
      data: selectedChat,
    }
    fetchAgentChatSession({
      agentId: selectedChat.agent_id,
      currentTopics: currentTopic,
    })
  }

  return (
    <>
      {fetchingChats ? (
        <AgentChatColumn>
          <AgentCard />
          <AgentCard />
          <AgentCard />
          <AgentCard />
          <AgentCard />
        </AgentChatColumn>
      ) : (
        <AgentChatColumn>
          {chats && Object.keys(chats).map(item => (
            <AgentCard
              onClick={() => handleCurrentTopicSet(item)}
              selected={currentTopics.currentAgentKey === item}
              name={item}
              recentStatus={chats[item].recent_session_status}
              count={chats[item].chat_sessions && chats[item].chat_sessions.length || 0}
            />
          ))}
        </AgentChatColumn>
      )}
    </>
  )
}

export default AI(ChatTopics)
