import { Button, Col, Row } from "antd"
import React from "react"
import styled from "styled-components"
import container from "container/BrokerDashboard/resourcesContainer"
import AddResourseForm from "./AddResourseForm"
import { DOC_CATEGORY, DOC_SUBCATEGORY, DOC_TYPE, MODIFIED_US_STATE } from "./data"

const Wrap = styled.div`
  max-width: 1200px;
  margin: 0px auto;
`

class AddEditResources extends React.PureComponent {
  state = {
    isEdit: false,
  }

  componentDidMount() {
    const { match: { params }, getResourcesList } = this.props
    if (params && !!params.resourceId) {
      this.setState({
        isEdit: true,
        resourceId: params.resourceId
      })
      getResourcesList({
        resourceId: params.resourceId,
        isQuery: true,
      })
    }
  }

  handleSubmit = (value) => {
    const { history } = this.props
    const { createResources, updateResources } = this.props
    const { isEdit, resourceId } = this.state
    const payload = {
      ...value,
      doc_category: value.doc_category && value.doc_category.value,
      doc_type: value.doc_type && value.doc_type.value,
      state: (value.state && value.state && value.state.map(x => x && x.label)) || null,
      doc_category_name: value.doc_category_name && value.doc_category_name.value,
      doc_type_name: value.doc_type_name && value.doc_type_name.value,
      doc_sub_category: value.doc_sub_category && value.doc_sub_category.value,
      status: null,
    }
    delete payload.link_type
    if (isEdit) {
      delete payload.firstname
      delete payload.lastname
      delete payload.email
      delete payload.agent_id
      updateResources({
        payload: {
          id: Number(resourceId),
          data: payload,
        },
        history,
      })
    } else {
      createResources({
        payload,
        history,
      })
    }
  }

  getValueByList = (value, list = [], bylabel) => {
    if (bylabel) {
      return list.find(x => x.label === value)
    }
    return list.find(x => x.value === value)
  }

  formatServerData = (data) => {
    if (data) {
      const payload = {
        ...data,
        doc_category: this.getValueByList(data.doc_category, DOC_CATEGORY),
        doc_type: this.getValueByList(data.doc_type, DOC_TYPE),
        state: [this.getValueByList(data.state, MODIFIED_US_STATE, true)],
        doc_category_name: this.getValueByList(data.doc_category_name, DOC_CATEGORY),
        doc_type_name: this.getValueByList(data.doc_type_name, DOC_TYPE),
        doc_sub_category: this.getValueByList(data.doc_sub_category, DOC_SUBCATEGORY)
      }
      return payload
    }
    return {}
  }

  render() {
    const { isEdit } = this.state
    const { resourcesListResponse, formValues, history } = this.props
    const { data, isFetching } = resourcesListResponse
    const editData = this.formatServerData(data && data.editResource)
    console.log({ editData });
    return (
      <Wrap className="container">
        <Row>
          <Col>
            <h1>Resources</h1>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col flex={1}>
            <h2>{isEdit ? "Edit" : "Add"} a Resource</h2>
          </Col>
          <Col>
            <Row style={{ gap: 20 }}>
              <Col>
                <Button onClick={() => { history.push("/broker/resources") }}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        {isFetching ? (
          <h1>Loading...</h1>
        ) : (
          <React.Fragment>
            <AddResourseForm
              onSubmit={this.handleSubmit}
              initialValues={editData}
              formValues={formValues}
            />
          </React.Fragment>
        )}
      </Wrap>
    )
  }
}

export default container(AddEditResources)
