import React, { Component } from "react"
import getAssistPreferencesDiv from "./AssistPreferencesDiv"
import styled from "styled-components"

const Wrap = styled.div`
   display: flex;
   ${props => props.pd && `
      padding-left: 10px;
    `}
`

const Title = styled.div`
  flex-grow: 1;
  ${props => props.interests && `
    flex-grow: 3;
   `}
  height:100%;
  .headerTitle {
   font-size: 12px;
   font-weight: bold;
   margin-bottom: 0;
   color: #a8a8a8;
  }
  .title {
   font-family: "SF Pro Display", sans-serif;
   font-size: 12px;
   color: #a8a8a8;
   text-decoration: underline;
  }
`

const DetailsDiv = styled.div`
  height: 150px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  div {
   p {
    margin: 0;
   }
   height: 50px;
   .detailTitle {
    font-size: 12px;
    color: #a8a8a8;
    margin-bottom: 1px;
   }

   .boxValue {
    color: white;
    width: 29px;
    height: 13px;
    border-radius: 2px;
    background-color: ${props => props.isOpenToNegotiation ? "#21863b" : "red"};
    padding: 0;
    font-size: 12px;
    text-align: center;
    margin-top: 3px;
   }
   .boxValueVac {
    color: white;
    width: 29px;
    height: 13px;
    border-radius: 2px;
    background-color: ${props => props.vacationMode ? "red" : "#21863b"};
    padding: 0;
    font-size: 12px;
    text-align: center;
    margin-top: 3px;
   }
  }
`

const InnerDivWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  border: solid 1px #ebebeb;
  height: 132px;
  border-radius: 4px;
  margin-right: 12px;
  padding-top: 13px;
  max-width: 337px;
`
const InnerDiv = styled.div`
  height: 35px;
  width: 91px;
  padding: 7px 7px 7px 12px;
`
const Type = styled.div`
  width: 70px;
  height: 20px;
  border-radius: 5px;
  border: solid 1px #d6d6d6;
  p {
   margin: 0;
   text-align: center;
   font-size: 16px;
   color: #cfcfcf;
  }
  ${props => props.money && `
    border: none;
    p{
     color: black;
     font-size: 14px;
    }
   `}
`

const addCommas = (nStr) => {
   nStr += '';
   var x = nStr.split('.');
   var x1 = x[0];
   var x2 = x.length > 1 ? '.' + x[1] : '';
   var rgx = /(\d+)(\d{3})/;
   while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
   }
   return x1 + x2;
 }

const getPreferencesDiv = (settings, stateRef, userRefSettings) => {
    if (!settings){
        return null;
    }

    let prefNotesDiv = null;
    if (stateRef && stateRef.assist_preferences && stateRef.is_isa){
      prefNotesDiv = getAssistPreferencesDiv(stateRef.assist_preferences);
    }

    const {
        is_isa_buyer_client_preferred,
        is_isa_renter_client_preferred,
        is_isa_seller_client_preferred,
        isa_buyer_min_budget,
        isa_seller_min_budget,
        isa_renter_min_budget,
        isa_ask_credit_score,
        isa_preferred_timeline,
        qualification_preference,
        is_active_to_receive_referral,
        is_open_to_negotiation,
    } = settings;

    const {
        radius_phone_number,
    } = userRefSettings;

    return (
     <React.Fragment>
     <Wrap>
      <Title>
       <p className="headerTitle">Lead Preferences</p>
      </Title>
     </Wrap>
     <Wrap pd>
      <Title>
       <p className="title">Budget (Minimum)</p>
      </Title>
      <Title interests>
       <p className="title">Interests & Preferences</p>
      </Title>
     </Wrap>
     <Wrap pd>
       <InnerDivWrap>
         <InnerDiv>
            <Type>
              <p>BUYER</p>
            </Type>
         </InnerDiv>
         <InnerDiv>
         <Type>
           <p>SELLER</p>
         </Type>
         </InnerDiv>
         <InnerDiv>
         <Type>
           <p>RENTER</p>
         </Type>
         </InnerDiv>
         <InnerDiv>
           <Type money>
             <p>{isa_buyer_min_budget ? `$${addCommas(isa_buyer_min_budget)}` : "-"}</p>
           </Type>
         </InnerDiv>
         <InnerDiv>
           <Type money>
             <p>{isa_seller_min_budget ? `$${addCommas(isa_seller_min_budget)}` : "-"}</p>
           </Type>
         </InnerDiv>
         <InnerDiv>
           <Type money>
             <p>{isa_renter_min_budget ? `$${addCommas(isa_renter_min_budget)}` : "-"}</p>
           </Type>
         </InnerDiv>
       </InnerDivWrap>
       <DetailsDiv
         isOpenToNegotiation={is_open_to_negotiation === 1}
         vacationMode={is_active_to_receive_referral === 1}
       >
         <div>
          <p className="detailTitle">Qualification Preference</p>
          <p className="value">
            {qualification_preference === 'min' ? ('Minimal') : (qualification_preference === 'mid' ? 'Partial' : 'Full')}
          </p>
         </div>
         <div>
           <p className="detailTitle">Vacation Mode</p>
           <div className="boxValueVac">
             {is_active_to_receive_referral === 1 ? 'NO' : 'YES'}
           </div>
         </div>
         <div>
           <p className="detailTitle">Open to negotiation?</p>
           <div className="boxValue">
             {is_open_to_negotiation === 1 ? 'YES' : 'NO'}
           </div>
         </div>
         {is_isa_buyer_client_preferred !== 0 && (
          <div>
            <p className="detailTitle">Interested in Buyers?</p>
            <p className="value">{is_isa_buyer_client_preferred ? "YES" : "NO"}</p>
          </div>
         )}
         {is_isa_seller_client_preferred !== 0 && (
          <div>
            <p className="detailTitle">Interested in Sellers?</p>
            <p className="value">{is_isa_seller_client_preferred ? "YES" : "NO"}</p>
          </div>
         )}
         {is_isa_renter_client_preferred !== 0 && (
          <div>
            <p className="detailTitle">Interested in Renters?</p>
            <p className="value">{is_isa_renter_client_preferred ? "YES" : "NO"}</p>
          </div>
         )}
         <div>
           <p className="detailTitle">Timeline</p>
           <p className="value">{isa_preferred_timeline ? isa_preferred_timeline : "N/A"}</p>
         </div>
         <div>
           <p className="detailTitle">Radius Phone number</p>
           <p className="value">{radius_phone_number ? radius_phone_number : "N/A"}</p>
         </div>
       </DetailsDiv>
       {prefNotesDiv}
     </Wrap>
    </React.Fragment>
    )
}

export default getPreferencesDiv
