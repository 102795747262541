import Embed from "@editorjs/embed";
import List from "@editorjs/list";
import Header from "@editorjs/header";
import Marker from "@editorjs/marker";
import Delimiter from "@editorjs/delimiter";

export const EDITOR_JS_TOOLS = {
  embed: Embed,
  marker: Marker,
  list: List,
  header: Header,
  delimiter: Delimiter,
};
