import { fork } from "redux-saga/effects"
import timelineSaga from "container/Client/saga"
import agentSaga from "container/Agent/saga"
import multipleCallerSaga from "container/MultipleCaller/sagas"
import settingsSaga from "container/Settings/saga"
import academySaga from "container/Academy/saga"
import recruitAndEarnSaga from "container/RecruitAndEarn/saga"
import dashboardSaga from "container/Dashboards/saga"
import productInterestSaga from "container/ProductInterest/saga"
import brokerDashboard from "container/BrokerDashboard/saga"
import agentComparisonSaga from "container/AgentComparison/saga"
import eventRoomsSaga from "container/EventRooms/saga"
import mortgageSaga from "container/Mortgage/saga"
import rarTechSaga from "container/TechAssets/saga"
import aiDashboardSaga from "container/AI/saga"
import ChecklistSaga from "container/CheckList/saga"
import ConsumerAppSaga from "container/ConsumerApp/saga"

export default function* main() {
  yield fork(timelineSaga)
  yield fork(agentSaga)
  yield fork(multipleCallerSaga)
  yield fork(settingsSaga)
  yield fork(academySaga)
  yield fork(recruitAndEarnSaga)
  yield fork(dashboardSaga)
  yield fork(productInterestSaga)
  yield fork(brokerDashboard)
  yield fork(agentComparisonSaga)
  yield fork(eventRoomsSaga)
  yield fork(mortgageSaga)
  yield fork(rarTechSaga)
  yield fork(aiDashboardSaga)
  yield fork(ChecklistSaga)
  yield fork(ConsumerAppSaga)
}
//add here
