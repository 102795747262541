import React, { PureComponent } from "react"
import styled from "styled-components"
import Loader from "dumbComponents/common/UI/Loader"
import moment from "moment"
import TechAssetsContainer from "container/BrokerDashboard/globalPagesContainer"
import ListingList from "dumbComponents/Listings/components/ListingList"
// import Loader from "@ui/Loader"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import Sidebar from "dumbComponents/Listings/components/Sidebar"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
import {
  Input, InputNumber, Select, Form, Checkbox,
  Divider, DatePicker, Button, Radio,
} from "dumbComponents/common/UI/antd"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const HeaderNav = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
`

const ProgressBarContainer = styled.div`
  padding: 30px 0px;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #3B60E4;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LabelText = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
`

const Required = styled.span`
  color: #E34C4C
`

const CheckboxLabelText = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  text-transform: uppercase;
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const CheckboxContainer = styled.div`
  padding: 0 20px 0 0;
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
`

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  state = {
    isLeadSourceOther: null,
    isTransactionTypeOther: null,
  }

  formRef = React.createRef();

  setSource = (value) => {
    if (value === "other") {
      this.setState({
        isLeadSourceOther: true,
      })
    } else {
      this.setState({
        isLeadSourceOther: false,
      })
    }
  }

  setTransactionType = (value) => {
    if (value === "other") {
      this.setState({
        isTransactionTypeOther: true,
      })
    } else {
      this.setState({
        isTransactionTypeOther: false,
      })
    }
  }

  onFinish = (fieldValues, isDraft) => {
    const { updateListingCoverSheet, history, dealToView, dealAgentId } = this.props
    const { propertyMarkedAsPublic } = this.state
    console.log("==fieldValues==", fieldValues)
    Object.keys(fieldValues).forEach((key) => {
      if (fieldValues[key] === undefined) {
        // eslint-disable-next-line no-param-reassign
        fieldValues[key] = null
      }
    })
    updateListingCoverSheet({
      payload: {
        ...fieldValues,
        listing_contract_date: fieldValues.listing_contract_date.unix(),
        listing_expiration_date: fieldValues.listing_expiration_date.unix(),
        listing_on_market_date: fieldValues.listing_on_market_date.unix(),
        current_listing_step: "source_time",
        draft: isDraft ? "Y" : "N",
      },
      dealAgentId,
      dealId: dealToView,
      // callAPI: ["agent_info", "seller_info", "source_time", "property_info"],
      // nextRoute: isDraft ? "/broker/tc/add-listings" : "/broker/tc/add-listings/coversheet/property-info",
      callAPI: ["agent_info", "seller_info", "source_time", "property_info", "tc_info", "select_vendor"],
      //nextRoute
      nextRoute: isDraft ? "/broker/tc/add-listings" : "/broker/tc/listings",
      history,
    })
  }

  onSaveAsDraft = (values) => {
    console.log("===this.formRef.current.getFieldsValue===", this.formRef.current.getFieldsValue())
    this.onFinish(this.formRef.current.getFieldsValue(), true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  getInitialValues = () => {
    const { getListingCoverSheetResponse } = this.props
    const { isLeadSourceOther, isTransactionTypeOther } = this.state
    const sourceTime = getListingCoverSheetResponse.data?.sourceTime
    const result = {}
    if (sourceTime) {
      //result.source = sourceTime.source
      result.listing_contract_date = sourceTime.listing_contract_date ? moment(sourceTime.listing_contract_date * 1000) : null
      result.listing_expiration_date = sourceTime.listing_expiration_date ? moment(sourceTime.listing_expiration_date * 1000) : null
      result.listing_on_market_date = sourceTime.listing_on_market_date ? moment(sourceTime.listing_on_market_date * 1000) : null
      result.transaction_type = sourceTime.transaction_type || ""
      result.property_marked_public = sourceTime.property_marked_public
      // if (!isLeadSourceOther && sourceTime.other_source && sourceTime.source === "other") {
      //   result.other_source = sourceTime.other_source
      //   this.setSource(result.source)
      // }
      if (!isTransactionTypeOther && sourceTime.other_transaction_type && sourceTime.transaction_type === "other") {
        result.other_transaction_type = sourceTime.other_transaction_type
        this.setTransactionType(sourceTime.transaction_type)
      }
    }
    return result
  }

  render() {
    const { history, updateListingCoverSheetResponse, getListingCoverSheetResponse, loaderOnAgentInfo, listingState } = this.props
    const { isLeadSourceOther, isTransactionTypeOther } = this.state
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]
    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Important Details
        </HeaderNav>
        <ProgressBarComponent total={[1, 2, 3, 4]} ongoingOrCompleted={4} />
        <Container>
          <LeftSideBar>
            <Sidebar />
          </LeftSideBar>
          <RightSideBar>
            {loaderOnAgentInfo ? (<Loader />) : (
              <Form colon={false} layout="vertical" requiredMark={false} onFinish={this.onFinish} initialValues={this.getInitialValues()} ref={this.formRef}>
                <Form.Item name="listing_contract_date" label={<LabelComponent text="Listing Contract Date" required />} rules={commonRules}>
                  <DatePicker />
                </Form.Item>

                <Form.Item name="listing_on_market_date" label={<LabelComponent text="On Market Date" required />} rules={commonRules}>
                  <DatePicker />
                </Form.Item>

                <Form.Item name="listing_expiration_date" label={<LabelComponent text="Listing Expiration Date" required />} rules={commonRules}>
                  <DatePicker />
                </Form.Item>

                {listingState == "California" && <Form.Item name="property_marked_public" label={<LabelComponent text="Would you like to include a (SELM) Seller Instruction To Exclude Listing From The MLS, with your listing?" required />} rules={commonRules}>
                  <Radio.Group>
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>}

                <FlexBox>
                  <FlexBox wd="100%">
                    <Form.Item>
                      {/* <Button
                        style={{
                          width: 119,
                          height: 48,
                          color: "#849DF8",
                          padding: 0,
                          marginLeft: "-10px",
                        }}
                        size="large"
                        type="link"
                        onClick={this.onSaveAsDraft}
                      >
                        Save for Later
                      </Button> */}
                    </Form.Item>
                  </FlexBox>
                  <FlexBox>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "white",
                          float: "right",
                          borderColor: "#3B60E4",
                          color: "#3B60E4",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        // htmlType="submit"
                        //onClick={() => { history.push("/broker/tc/add-listings/coversheet/seller-info") }}
                        onClick={() => { history.push("/broker/tc/add-listings/coversheet/property-info") }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "#3B60E4",
                          float: "right",
                          borderColor: "white",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        htmlType="submit"
                        loading={updateListingCoverSheetResponse.isFetching || getListingCoverSheetResponse.isFetching}
                      // onClick={() => { history.push("/broker/tc/add-listings/coversheet/property-info") }}
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </FlexBox>
                </FlexBox>
              </Form>
            )}
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)

