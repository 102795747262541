/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react"
import styled from "styled-components"
import AgentContainer from "container/BrokerDashboard/editAgentContainer"
import SubDeetsContainer from "container/Agent"
import { ShimmerBox } from "shared/styles/animation"
import { get } from "lodash"
import {
  Container,
  PlanClickBar,
  PlanTitle,
  PlanWrap,
  PlanPriceWrap,
  PlanPrice,
  Title,
  TitleWrap,
  ShimmerWrap,
} from "../../common"

const Wrap = styled.div``

const Production = ({
  getBrokerageInfoMetabaseResponse,
  selectAgentPlan,
  selectedAgentPlans,
  subscriptions,
}) => {
  const isProductionAlready = get(subscriptions, "data.additional_info.production")
  const isFetching = get(getBrokerageInfoMetabaseResponse, "isFetching")
  const production = get(getBrokerageInfoMetabaseResponse, "data.production")

  useEffect(() => {
    if (isProductionAlready) {
      const payload = {
        key: "production",
        production: isProductionAlready,
      }
      selectAgentPlan(payload)
    }
  }, [isProductionAlready])

  const handleClick = (value) => {
    const payload = {
      key: "production",
      production: value.value,
      name: value.name,
    }
    selectAgentPlan(payload)
  }
  return (
    <Wrap>
      <Container>
        <TitleWrap>
          <Title>
            Production
          </Title>
        </TitleWrap>
        {isFetching ? (
          <ShimmerWrap>
            <ShimmerBox w="233px" h="156px" />
            <ShimmerBox w="233px" h="156px" />
            <ShimmerBox w="233px" h="156px" />
            <ShimmerBox w="233px" h="156px" />
            <ShimmerBox w="233px" h="156px" />
            <ShimmerBox w="233px" h="156px" />
          </ShimmerWrap>
        ) : (
          <PlanWrap>
            {production && production.map(prod => (
            <>
              <PlanClickBar
                onClick={() => {
                  handleClick(prod)
                }}
                isSelected={selectedAgentPlans.some(el => el.production === prod.value)}
                key={prod.id}
                width={233.67}
              >
                <PlanTitle
                  style={{
                    maxWidth: "161px",
                  }}
                >
                  {prod.name}
                </PlanTitle>
                <PlanPriceWrap
                  isSelected={selectedAgentPlans.some(el => el.production === prod.value)}
                  key={prod.id}
                >
                  <PlanPrice
                    isSelected={selectedAgentPlans.some(el => el.production === prod.value)}
                    key={prod.id}
                  >
                    {prod.key}
                  </PlanPrice>
                </PlanPriceWrap>
              </PlanClickBar>
            </>
            ))}
          </PlanWrap>
        )}
      </Container>
    </Wrap>
  )
}

export default SubDeetsContainer(AgentContainer(Production))
