import styled from "styled-components"

const RemoveCoListingAgentButton = styled.button`
  background: transparent;
  cursor: pointer;
  border: none;
`

export {
  RemoveCoListingAgentButton,
}
