import React, {Component} from "react";
import {clientConnectDivOptions, receiverExpertiseDivOptions} from "../../Constants/constants";
import { console } from "window-or-global";

class AgentReqDetails extends Component {

    state = {

    };

    render() {

        const {handleTimeline, handleAgentReqFieldChange, agentReq, isPreApproved,
            handleChangeChk, clientType} = this.props;
        return (
            <div>

                {/* <input type="checkbox" name={"is_pre_approved"} className="checkbox-left"
                       checked={agentReq.is_pre_approved}
                       onChange={handleAgentReqFieldChange} /> */}

                       {clientType === "Buyer" && (
                           <React.Fragment>
                               <label style={{ marginLeft: 35 }}>
                                    Pre-Approved? <span class="required-asterik">*</span>
                                </label>
                                <select
                                    name="looking-for-lender"
                                    className="checkbox-left"
                                    style={{ width: 90, marginRight: 10 }}
                                    onChange={(event) => {
                                        handleAgentReqFieldChange(event.target.value === "true")}
                                    }
                                    value={agentReq.is_pre_approved}
                                    >
                                    <option value="" disabled selected>Select your option</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                    </select>
                           </React.Fragment>
                       )}
                
                <br/>
                <br/>

                {/*<input type="checkbox" name={"receiveReferralUpdate"} className="checkbox-left"*/}
                       {/*checked={agentReq.receiveReferralUpdate}*/}
                       {/*onChange={handleAgentReqFieldChange}/>*/}
                {/*<label className="checkbox-text">*/}
                    {/*Receive Referral Update*/}
                {/*</label>*/}
                {/*<br/>*/}
                {/*<br/>*/}

                <input type="checkbox" name={"contactClient"} className="checkbox-left"
                       onChange={handleAgentReqFieldChange}
                       checked={agentReq.contactClient}/>
                <label className="checkbox-text">
                    Contact Client
                </label>
                <br/>
                <br/>

                <input type="checkbox" name={"is_property_appraised"} className="checkbox-left"
                       checked={agentReq.is_property_appraised}
                       onChange={handleAgentReqFieldChange}/>
                <label className="checkbox-text">
                    Property Appraised
                </label>
                <br/>
                <br/>

                <input type="checkbox" name={"is_online_lead"} className="checkbox-left"
                       checked={agentReq.is_online_lead}
                       onChange={handleAgentReqFieldChange}/>
                <label className="checkbox-text">
                    Online Lead
                </label>
                <br/>
                <br/>

                <select className="dropdown-1" onChange={handleTimeline} >
                    <option value="asap" selected={parseInt(agentReq.timeline, 10) === 0}>ASAP</option>
                    <option value="30-60" selected={parseInt(agentReq.timeline, 10) === 30}>30-60 days</option>
                    <option value="60-90" selected={parseInt(agentReq.timeline, 10) === 60}>60-90 days</option>
                    <option value="4-months" selected={parseInt(agentReq.timeline, 10) === 90}>>90 Days</option>
                </select>

                <select className="dropdown-1" name={"clientConnect"}
                        value={agentReq.clientConnect}
                        onChange={handleAgentReqFieldChange} >
                    {clientConnectDivOptions()}
                </select>

                <select className="dropdown-1" name={"receiver_expertise"}
                        value={agentReq.receiver_expertise}
                        onChange={handleAgentReqFieldChange} >
                    {receiverExpertiseDivOptions()}
                </select>
                <br/>
                <br/>

                {agentReq.referral_hist && <div className="checkbox-left">
                    History with referral :
                    <label className="checkbox-text">
                        {agentReq.referral_hist}
                    </label>
                    <br/>
                    <br/>
                </div>
                }

            </div>
        );
    }

}

export default AgentReqDetails;
