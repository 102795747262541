/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import AgentContainer from "container/BrokerDashboard/editAgentContainer"
import { withRouter } from "react-router-dom"
import { Select, Collapse, Button } from "antd"
import { SALES_REPRESENTATIVE } from "dumbComponents/BrokerDashboard/GlobalPages/Resources/data"
import { get } from "lodash"
import { ShimmerBox } from "shared/styles/animation"
import {
  TitleWrap,
  LicenseTransDateWrap,
  SidebarHeading,
  CustomInputNumber,
} from "../../common"

const { Panel } = Collapse

const Wrap = styled.div``

const SalesRep = ({
  agentBrokerageInfoResponse,
  updateAgentBrokerageInfo,
  getAgentBrokerageInfo,
  ...props
}) => {
  const { data: agentBrokerageInfoData, isFetching } = agentBrokerageInfoResponse || {}
  const agentId = get(props, "match.params.agentId", "")
  const {
    is_license_transferred, license_transferred_date,
    is_ica_signed, ica_signed_date, past_volume, past_brokerage_name,
  } = agentBrokerageInfoData || {}
  const primarySaleRep = get(agentBrokerageInfoData, "primary_sale_representative")
  const primarySaleRepSplit = get(agentBrokerageInfoData, "primary_sale_representative_split_percentage")
  const secondarySaleRep = get(agentBrokerageInfoData, "secondary_sale_representative")
  const secondarySaleRepSplit = get(agentBrokerageInfoData, "secondary_sale_representative_split_percentage")

  const [currentSaleRep, setPrimarySalesRep] = useState()
  const [currentSecSaleRep, setSecSaleRep] = useState()
  const [currentSaleRepSplit, setPrimarySalesRepSplit] = useState()
  const [currentSecSaleRepSplit, setSecondarySalesRepSplit] = useState()

  const handleSubmit = () => {
    const payload = {
      is_license_transferred,
      agent_id: parseInt(agentId, 10),
      primary_sale_representative: currentSaleRep,
      secondary_sale_representative: currentSecSaleRep,
      primary_sale_representative_split_percentage: currentSaleRepSplit,
      secondary_sale_representative_split_percentage: currentSecSaleRepSplit,
      cap: null,
      is_ica_signed,
      ica_signed_date,
      license_transferred_date,
      other_past_brokerage_name: null,
      past_brokerage_name,
      past_brokerage_production: null,
      production_tier: null,
      past_volume,
    }
    updateAgentBrokerageInfo({
      payload,
      agentId: parseInt(agentId, 10),
      callback: () => {
        getAgentBrokerageInfo({ agentId: parseInt(agentId, 10) })
      },
    })
  }

  return (
    <Wrap>
      <TitleWrap>
        <Collapse expandIconPosition="end" ghost>
          <Panel header="Sales Representative">
            <LicenseTransDateWrap>
              <SidebarHeading>
                Primary Sales Rep
              </SidebarHeading>
              {isFetching ? <ShimmerBox w="290px" h="39px" /> : (
                <Select
                  placeholder="Select"
                  options={SALES_REPRESENTATIVE}
                  defaultValue={primarySaleRep}
                  onChange={value => setPrimarySalesRep(value)}
                />
              )}
            </LicenseTransDateWrap>
            <LicenseTransDateWrap>
              <SidebarHeading>
                Primary Sales Rep Split Percentage
              </SidebarHeading>
              {isFetching ? <ShimmerBox w="290px" h="39px" /> : (
                <CustomInputNumber
                  style={{
                    height: "39px",
                  }}
                  formatter={value => value ? `${value}%` : ""}
                  defaultValue={primarySaleRepSplit}
                  controls={false}
                  onChange={value => setPrimarySalesRepSplit(value)}
                />
              )}
            </LicenseTransDateWrap>
            <LicenseTransDateWrap>
              <SidebarHeading>
                Secondary Sales Rep
              </SidebarHeading>
              {isFetching ? <ShimmerBox w="290px" h="39px" /> : (
                <Select
                  placeholder="Select"
                  options={SALES_REPRESENTATIVE}
                  defaultValue={secondarySaleRep}
                  onChange={value => setSecSaleRep(value)}
                />
              )}
            </LicenseTransDateWrap>
            <LicenseTransDateWrap>
              <SidebarHeading>
                Secondary Sales Rep Split Percentage
              </SidebarHeading>
              {isFetching ? <ShimmerBox w="290px" h="39px" /> : (
                <CustomInputNumber
                  style={{
                    height: "39px",
                  }}
                  formatter={value => value ? `${value}%` : ""}
                  defaultValue={secondarySaleRepSplit}
                  controls={false}
                  onChange={value => setSecondarySalesRepSplit(value)}
                />
              )}
            </LicenseTransDateWrap>
            <Button
              onClick={() => {
                handleSubmit()
              }}
              type="primary"
            >
              Save
            </Button>
          </Panel>
        </Collapse>
      </TitleWrap>
    </Wrap>
  )
}

export default withRouter(AgentContainer(SalesRep))
