import { Button, Col, Row } from "antd"
import React from "react"
import styled from "styled-components"
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector  } from "redux-form"
import { Select, Textarea, DatePicker, FileUpload, Input } from "dumbComponents/common/UI/Form"
import { required } from "services/formUtils"
import { MODIFIED_US_STATE, DOC_CATEGORY, DOC_SUBCATEGORY, DOC_TYPE } from "./data"
const GAP = 40

const Wrap = styled.form`
  
`

const AddResourceForm = ({
  values,
  handleSubmit,
  formValues,
}) => {
  return (
    <Wrap onSubmit={handleSubmit}>
      <Row gutter={GAP}>
        <Col flex={3}>
          <Field
            component={Input}
            name="heading_display_name"
            label="Resource"
            placeholder="Name of the resource"
            validate={[ required ]}
          />
        </Col>
        <Col flex={1}>
          <Field
            component={Select}
            options={DOC_CATEGORY}
            name="doc_category"
            label="Document Category"
            validate={[ required ]}
          />
        </Col>
        <Col flex={1}>
          <Field
            component={Select}
            options={DOC_TYPE}
            name="doc_type"
            label="Document Type"
            validate={[ required ]}
          />
        </Col>
        <Col flex={1}>
          <Field
            component={Select}
            options={DOC_SUBCATEGORY}
            name="doc_sub_category"
            label="Document Sub Type"
          />
        </Col>
      </Row>
      <Row gutter={GAP} style={{ marginTop: GAP }}>
        <Col flex={3}>
          <Field
            component={Textarea}
            name="description"
            label="Description"
            placeholder="Description"
            validate={[ required ]}
          />
        </Col>
        <Col flex={1}>
          <Field
            component={Select}
            config={{
              isMulti: true,
            }}
            options={MODIFIED_US_STATE}
            name="state"
            label="State"
            placeholder="Select a state"
            validate={[ required ]}
          />
        </Col>
        <Col flex={1}>
          
        </Col>
      </Row>
      <p style={{ margin: "30px 0px" }}>Resource Link / Document</p>
      <Row gutter={GAP}>
        <Col  flex={2}>
          <Field
            component={Select}
            options={[
              { label: "Link", value: "link" },
              { label: "File", value: "file" },
            ]}
            name="link_type"
            label="Link Type"
            placeholder="Choose a Type"
            validate={[ required ]}
          />
        </Col>
        <Col flex={3}>
          {formValues.link_type && formValues.link_type.value === "link" && (
            <Field
              component={Input}
              name="download_url"
              label="Link of the document"
              placeholder="https://samplelink.com"
              validate={[ required ]}
            />
          )}

          {(formValues.link_type === undefined || formValues.link_type.value === "file") && (
            <Field
              component={FileUpload}
              name="download_url"
              isUpload
              bucket="brokerage_docs"
              label="Link of the document"
              placeholder="https://samplelink.com"
              validate={[ required ]}
            />
          )}
        </Col>
      </Row>
      <br />
      <br />
      <Button htmlType="submit" type="primary">
        Submit
      </Button>
    </Wrap>
  )
}

const WrapperForm = reduxForm({
  form: "ADD_RESOURCES_FORM",
})(AddResourceForm)

export default WrapperForm

