import React, { Component } from "react"
import styled from "styled-components"
import DashboardContainer from "container/Dashboards"
import Select from "react-select"
import ThreeDotLoader from "./ThreeDotLoader"
import moment from 'moment';

const Wrap = styled.div`
  width: 100%;
  height: max-content;
  background-color: #fbfbfb;
  display: flex;
  margin-bottom: 65px;
`
const StyledSelect = styled(Select)`
  /* & div:nth-child(1){
    min-height: 20px !important;
    height: 33px;
    font-size: 11px;
  } */

  .css-yk16xz-control {
   min-height: 20px !important;
   height: 33px;
   font-size: 11px;
  }
`

const InnerWrap = styled.div`
  height: 292px;
  width: 679px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 5px;
  background-color: #fbfbfb;

  .innerFilters {
    width: 219px;
    height: 69px;
    padding: 10px;

    .innerLabel {
      font-size: 12px;
      color: #505050;
    }

    .fetching {
     position: relative;
     display: inline-block;
     text-align: right;
     left: 464px;
     top: 34px;
    }

    .h0 {
     height: 0px !important;
    }

    button {
     float: right;
     position: relative;
     top: 33px;
     left: 436px;
     color: #11adf3;
     border: none;
     background: none;
     text-decoration: underline;
     cursor: pointer;
    }

    .clear {
     color: red;
    }

    .innerFilterTitle {
     margin: 0;
     font-size: 12px;
     color: #505050;
    }

    .c1, .c2, .c3 {
     height: 17px;
     border-radius: 3px;
     background-color: #d8d8d8;
     margin-top: 10px;
     border-width: inherit;
    }

    .c1 {
     width: 63px;
    }

    .c2 {
     width: 48px;
    }

    .c3 {
     width: 118px;

    }

    .c1::-webkit-outer-spin-button,
    .c1::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
     }
  }
`

const LeftWrap = styled.div`
 margin-right: 39px;
 .selects {
  width: 209px;
 }
 padding: 10px 0px 10px 10px;
 .subscriptionSelectWrap, .dateRangeWrap {
  margin-top: 24px;
 }
 label {
  color: #aaaaaa;
  font-size: 10px;
 }
`

const RightWrap = styled.div`
 width: 600px;
`

const getLastWeek = (flag) => {
 if(flag) {
  return moment().subtract(1, 'weeks').startOf('isoWeek').unix()
 }

 return moment().subtract(1, 'weeks').endOf('isoWeek').unix()
}

const getThisWeek = (flag) => {
 if(flag) {
  return  moment().startOf('week').unix();
 }
 return  moment().endOf('week').unix();
}

class Filters extends Component {
//moment().subtract(1, 'days').unix()
 state = {
  options: [
   { value: "all", label: "All"},
   { value: "today",
     min: moment().startOf('day').unix(),
     max:moment().endOf('day').unix(),
     label: "Today"
   },
   { value: "yesterday",
     min: moment().subtract(1,'days').startOf('day').unix(),
     max:moment().subtract(1,'days').endOf('day').unix(),
     label: "Yesterday"
   },
   { value: "thisWeek", label: "This Week", min: getThisWeek(true), max: getThisWeek(false)},
   { value: "lastWeek", label: "Last Week", min: getLastWeek(true), max: getLastWeek(false)},
   { value: "custom", label: "Custom"},
  ],
 }

 componentDidMount() {

 }

 getTimeFrom = (day, hour, min) => {
   let curr = 0;
   let dayToSec = day * 24 * 60 * 60 || 0;
   let hourToSec = hour * 60 * 60 || 0
   let minToSec = min * 60 || 0
   let totalSec = dayToSec + hourToSec + minToSec || 0
   return totalSec
 }

 handleFilter = () => {
  const {
   lowerInbound,
   lowerZillow,
   lowerRinging,
   upperZillow,
   upperInbound,
   upperRinging,
   callerQTY,
   avgCallTime,
   minTextResponseTime,
   maxTextResponseTime,
   fetchMetrics,
   currentSubscriptionFilter,
   currentDateFilter,
   currentAgents,
   currentSortType,
   currentReferralFilter,
   currentWorkTypeFilter,
   currentReferralSourceFilter,
   durationDay,
   durationHour,
   durationMin,
   customDate,
   textDurationHour,
   textDurationMin,
   textDurationDay,
   setCurrentFiltersURL,
  } = this.props

  const filterParams = []

  const getUnixTimeForCallResponse = this.getTimeFrom(durationDay, durationHour, durationMin)

  const getUnixTimeForTextResponse = this.getTimeFrom(textDurationDay, textDurationHour, textDurationMin)

  if(currentDateFilter && (!currentDateFilter[0] && currentDateFilter.value !== "all")){
   if(currentDateFilter.label === "Custom"){
     filterParams.push(`start_time=${customDate.start}&end_time=${customDate.end}`)
   } else {
     filterParams.push(`start_time=${currentDateFilter.min}&end_time=${currentDateFilter.max}`)
   }
  } else {
    if((currentDateFilter[0] && currentDateFilter[0].value !== "Select") && currentDateFilter.value !== "all") {
     filterParams.push(`start_time=${currentDateFilter[0].min}&end_time=${currentDateFilter[0].max}`)
    }
  }

  if(currentSortType && !currentSortType[0]){
   filterParams.push(`order=${currentSortType.value}`)
  }

  if(currentReferralSourceFilter && !currentReferralSourceFilter[0]){
   filterParams.push(`referral_source=${currentReferralSourceFilter.value}`)
  }

  if(currentReferralFilter && !currentReferralFilter[0]){
   filterParams.push(`is_isa=${currentReferralFilter.value}`)
  }

  if(currentWorkTypeFilter && !currentWorkTypeFilter[0]){
   filterParams.push(`is_called_during_working_hours=${currentWorkTypeFilter.value}`)
  }

  if(currentSubscriptionFilter && !currentSubscriptionFilter[0]) {
   filterParams.push(`subscription_status=${currentSubscriptionFilter.value}`)
  }

  if(lowerInbound) {
   filterParams.push(`min_incoming_missed_calls=${lowerInbound}`)
  }

  if(upperInbound) {
   filterParams.push(`max_incoming_missed_calls=${upperInbound}`)
  }

  if(lowerZillow) {
   filterParams.push(`min_zillow_missed_calls=${lowerZillow}`)
  }

  if(upperZillow) {
   filterParams.push(`max_zillow_missed_calls=${upperZillow}`)
  }

  if (lowerRinging) {
   filterParams.push(`min_missed_call_ringing_duration=${lowerRinging}`)
  }

  if (upperRinging) {
   filterParams.push(`max_missed_call_ringing_duration=${upperRinging}`)
  }

  if(minTextResponseTime) {
   filterParams.push(`min_text_response_time=${minTextResponseTime}`)
  }

  if(maxTextResponseTime) {
   filterParams.push(`max_text_response_time=${maxTextResponseTime}`)
  }

  if(callerQTY) {
   filterParams.push(`failed_call_count=${callerQTY}`)
  }

  if(getUnixTimeForCallResponse && getUnixTimeForCallResponse > 0) {
   filterParams.push(`min_average_call_time=${getUnixTimeForCallResponse}`)
  }

  if(getUnixTimeForTextResponse && getUnixTimeForTextResponse > 0) {
   filterParams.push(`min_average_text_time=${getUnixTimeForTextResponse}`)
  }

  let finalParams = filterParams.join("&")
  finalParams = finalParams +"&"
  setCurrentFiltersURL({
   currentFilter: finalParams,
  })

  fetchMetrics({
   filters: finalParams,
   limit: 10,
  })
 }

 handleSortType = (val) => {
  const {
   setSortType,
  } = this.props

  setSortType({
   currentSortType: val,
  })
 }

 clearFilter = () => {
  const {
   fetchMetrics,
   currentAgents,
   clearFilters,
   setCurrentPaginationLimit,
  } = this.props

  setCurrentPaginationLimit(10)

  fetchMetrics({
   filters:"",
   limit: 10,
  })

  clearFilters()

 }



 handleDuration = (e, type) => {
   const {
    setCurrentDay,
    setCurrentHour,
    setCurrentMin,
    setCurrentTextDay,
    setCurrentTextHour,
    setCurrentTextMin,
   } = this.props || {}


   switch(type) {
    case "responseDay":
     setCurrentDay(e.target.value)
     break
    case "responseHour":
     setCurrentHour(e.target.value)
     break
    case "responseMin":
     setCurrentMin(e.target.value)
     break
    case "textResponseDay":
     setCurrentTextDay(e.target.value)
     break
    case "textResponseHour":
     setCurrentTextHour(e.target.value)
     break
    case "textResponseMin":
     setCurrentTextMin(e.target.value)
     break
    default:
     break
   }
 }

 handleValueChange = (e, type) => {
  const {
   setLowerInbound,
   setUpperInbound,
   setLowerZillow,
   setUpperZillow,
   setLowerRinging,
   setUpperRinging,
   setCallerQTY,
   setMinTextResponseTime,
   setMaxTextResponseTime,
   setAvgCallTime,
   currentSortType,
  } = this.props

  switch(type) {
   case "lowerInbound":
    setLowerInbound({
     lowerInbound: e.target.value,
    })
    break;
   case "upperInbound":
    setUpperInbound({
     upperInbound: e.target.value,
    })
    break;
   case "lowerZillow":
    setLowerZillow({
     lowerZillow: e.target.value,
    })
    break;
   case "upperZillow":
    setUpperZillow({
     upperZillow: e.target.value,
    })
    break;
   case "lowerRinging":
    setLowerRinging({
     lowerRinging: e.target.value,
    })
    break;
   case "upperRinging":
    setUpperRinging({
     upperRinging: e.target.value,
    })
    break;
   case "callerQTY":
    setCallerQTY({
     callerQTY: e.target.value,
    })
   break;
   case "avgCallTime":
    setAvgCallTime({
     avgCallTime: e.target.value,
    })
   break;
   case "minTextResponseTime":
    setMinTextResponseTime({
     minTextResponseTime: e.target.value,
    })
    break;
   case "maxTextResponseTime":
    setMaxTextResponseTime({
     maxTextResponseTime: e.target.value,
    })
    break;
   default:
    break;
  }
 }

 handleWorkHoursType = (val) => {
  const {
   setCurrentCallWorkType,
  } = this.props

  setCurrentCallWorkType({
   currentWorkTypeFilter: val,
  })
 }

 handleReferralType = (val) => {
  const {
   setCurrentReferralType,
  } = this.props
  setCurrentReferralType({
   currentReferralFilter: val,
  })
 }

 handleSubscriptionType = (val) => {
  const {
   setCurrentSubscriptionFilter,
  } = this.props

  setCurrentSubscriptionFilter({
   currentSubscriptionFilter: val,
  })
 }

 handleCustomDate = (e, type) => {
  const {
   customDate,
   setCustomDate,
  } = this.props

  const dateObj = {}

  if(type === "start") {
   dateObj.start = new Date(e.target.value).getTime() / 1000
  } else {
   dateObj.end = new Date(e.target.value).getTime() / 1000
  }

  setCustomDate({
   customDate: {
    start: dateObj && dateObj.start || customDate.start || "",
    end: dateObj && dateObj.end || customDate.end || "",
   }
  })

 }

 handleCurrentReferralSource = (val) => {
  const {
   setCurrentReferralSource,
  } = this.props
  setCurrentReferralSource({
   currentReferralSourceFilter: val,
  })
 }

 handleDateFilterType = (val) => {
  const {
   setCurrentDateFilter,
  } = this.props

  setCurrentDateFilter({
   currentDateFilter: val,
  })
 }

 render() {
  const {
   options,
  } = this.state

  const {
   currentDateFilter,
   currentSubscriptionFilter,
   currentSortType,
   lowerZillow,
   lowerInbound,
   upperInbound,
   upperZillow,
   callerQTY,
   avgCallTime,
   maxTextResponseTime,
   minTextResponseTime,
   lowerRinging,
   upperRinging,
   durationDay,
   durationMin,
   durationHour,
   currentReferralFilter,
   currentWorkTypeFilter,
   currentReferralSourceFilter,
   textDurationDay,
   textDurationHour,
   textDurationMin,
   fetchMetricsResponse,
  } = this.props

  const {
   data,
   isFetching,
  } = fetchMetricsResponse

  return (
   <Wrap>
    <LeftWrap>
      <div className="dateSelectWrap selects">
        <label htmlFor="Date Range">Date Range</label>
        <StyledSelect
         options={options}
         onChange={this.handleDateFilterType}
         value={currentDateFilter}
         openOnFocus={true}
         autofocus={true}
        />
      </div>
      {currentDateFilter && (!currentDateFilter[0] && currentDateFilter.value === "custom") && (
       <div className="dateRangeWrap selects">
         <label htmlFor="Date Range">Date Range (Custom)</label>
         <input onChange={(e) => this.handleCustomDate(e, "start")} type="date" name="range_start_date" />
         <input onChange={(e) => this.handleCustomDate(e, "end")} type="date" name="range_end_date" />
       </div>
      )}
      <div className="subscriptionSelectWrap selects">
        <label htmlFor="Subscription">Subscription</label>
        <StyledSelect
         options={[
          { value: "all", label: "All"},
          { value: "active", label: "Active"},
          { value: "paused", label: "Paused"},
          { value: "churned", label: "Churned"},
         ]}
         onChange={this.handleSubscriptionType}
         value={currentSubscriptionFilter}
        />
      </div>
      <div className="subscriptionSelectWrap selects">
        <label htmlFor="Subscription">Referral Source</label>
        <StyledSelect
         options={[
          { value: "all", label: "All"},
          { value: "integrations", label: "Integrations"},
          { value: "bulk", label: "Bulk"},
          { value: "submitleads", label: "App Submit"},
         ]}
         onChange={this.handleCurrentReferralSource}
         value={currentReferralSourceFilter}
        />
      </div>
    </LeftWrap>
    <RightWrap>
      <InnerWrap>
       <div class="innerFilters callResponseTime">
        <p class="innerFilterTitle">Call Count</p>
        <input value={callerQTY} placeholder="1-10" onChange={(e) => this.handleValueChange(e, "callerQTY")} class="c2" type="number" id="callerQTY" name="callerQTY" min="1" max="10" />
       </div>
       <div className="innerFilters">
        <p className="innerFilterTitle">Average Call Response Time</p>
        <input value={durationDay} placeholder="day" onChange={(e) => this.handleDuration(e, "responseDay")} className="c2" type="number" id="responseDay" name="responseDay" min="1"/>
        <span> - </span>
        <input value={durationHour} placeholder="hour" onChange={(e) => this.handleDuration(e, "responseHour")} className="c2" type="number" id="responseHour" name="responseHour"/>
        <span> - </span>
        <input value={durationMin} placeholder="min" onChange={(e) => this.handleDuration(e, "responseMin")} className="c2" type="number" id="responseMin" name="responseMin"/>
       </div>
       <div class="innerFilters">
        <p class="innerFilterTitle">Inbound Missed Call Count</p>
        <input value={lowerInbound} placeholder="min" onChange={(e) => this.handleValueChange(e, "lowerInbound")} class="c1" type="number" id="lowerInbound" name="lowerInbound" min="1"/>
        <span> - </span>
        <input value={upperInbound} placeholder="max" onChange={(e) => this.handleValueChange(e, "upperInbound")} class="c1" type="number" id="upperInbound" name="quantity" min="1"/>
       </div>
       <div className="innerFilters">
        <p className="innerFilterTitle">Average Text Response Time</p>
        <input value={textDurationDay} placeholder="day" onChange={(e) => this.handleDuration(e, "textResponseDay")} className="c2" type="number" id="textResponseDay" name="textResponseDay" min="1"/>
        <span> - </span>
        <input value={textDurationHour} placeholder="hour" onChange={(e) => this.handleDuration(e, "textResponseHour")} className="c2" type="number" id="textResponseHour" name="textResponseHour"/>
        <span> - </span>
        <input value={textDurationMin} placeholder="min" onChange={(e) => this.handleDuration(e, "textResponseMin")} className="c2" type="number" id="textResponseMin" name="textResponseMin"/>
       </div>
       <div class="innerFilters">
        <p class="innerFilterTitle">Zillow Missed Call Count</p>
        <input value={lowerZillow} placeholder="min" onChange={(e) => this.handleValueChange(e, "lowerZillow")} class="c1" type="number" id="lowerZillow" name="lowerZillow" min="1"/>
        <span> - </span>
        <input value={upperZillow} placeholder="max" onChange={(e) => this.handleValueChange(e, "upperZillow")} class="c1" type="number" id="upperZillow" name="upperZillow" min="1"/>
       </div>
       <div className="innerFilters">
        <p className="innerFilterTitle">Missed Call Ringing Duration (seconds)</p>
        <input value={lowerRinging} placeholder="min" onChange={(e) => this.handleValueChange(e, "lowerRinging")} className="c1" type="number" id="lowerRinging" name="lowerRinging" min="1"/>
        <span> - </span>
        <input value={upperRinging} placeholder="max" onChange={(e) => this.handleValueChange(e, "upperRinging")} className="c1" type="number" id="upperRinging" name="upperRinging" min="1"/>
       </div>
       <div class="innerFilters">
        <div className="subscriptionSelectWrap selects">
          <label className="innerLabel" htmlFor="Sort by">Sort by</label>
          <StyledSelect
           options={[
            { value: "average_call_time", label: "Average Call Time"},
            { value: "text_average_time", label: "Text Average Time"},
            { value: "missed_call_count", label: "Missed Call Count"},
            { value: "zillow_missed_call_count", label: "Zillow missed call count"},
           ]}
           onChange={this.handleSortType}
           value={currentSortType || { value: "select" , label: "Select"}}
          />
         </div>
       </div>
       <div class="innerFilters">
        <div className="subscriptionSelectWrap selects">
          <label className="innerLabel" htmlFor="Sort by">Referral Type</label>
          <StyledSelect
           options={[
            { value: 1, label: "Isa"},
            { value: 0, label: "Referral"},
           ]}
           onChange={this.handleReferralType}
           value={currentReferralFilter || { value: "select" , label: "Select"}}
          />
         </div>
       </div>
       <div class="innerFilters">
        <div className="subscriptionSelectWrap selects">
          <label className="innerLabel" htmlFor="Sort by">Working hours</label>
          <StyledSelect
           options={[
            { value: true, label: "Working Hour Calls"},
            { value: false, label: "Non-Working Hour Calls"},
           ]}
           onChange={this.handleWorkHoursType}
           value={currentWorkTypeFilter || { value: "select" , label: "Select"}}
          />
         </div>
       </div>
       <div class="innerFilters h0">
        {isFetching && (
         <div className="fetching">
           <ThreeDotLoader />
         </div>
        )}
        <button onClick={this.handleFilter}>Apply</button>
        <button class="clear" onClick={this.clearFilter}>Clear</button>
       </div>
      </InnerWrap>
    </RightWrap>
   </Wrap>
  )
 }

}


export default DashboardContainer(Filters)
