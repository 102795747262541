import React, { Component } from 'react';
import Popup from "reactjs-popup";
import styled from "styled-components";

const RemoveAgentDiv = styled.div`
  
`;

const CrossLi = styled.li`
    font-size: 1em;
    margin-left: 20px;
    color: black;
    position: inherit;
`;

class AgentRemoveButton extends Component {

    state = {
        loading : false,
    };

    render() {

        const {removeProspect, agent} = this.props;

        return (
            <RemoveAgentDiv>
                <Popup
                    trigger={
                        <a className={"market-place-checkbox"}
                           disabled={agent.prospect_status !== -1}
                        ><CrossLi className="fa fa-times" aria-hidden="true"></CrossLi></a>
                    }
                    modal
                    closeOnDocumentClick={true}
                    closeOnEscape={true}
                >
                    {close => (
                        <div className="modal-2">
                            <div className="modal-header">
                                <span className="modal-header-text">
                                    {"Are you sure you want to continue with this action?"}
                                </span>
                            </div>
                            <div className="dialogue-box-footer">
                                {<button className="med-btn-1" onClick={() => {removeProspect(agent); close();}}>
                                    YES
                                </button>}
                                <button className="med-btn-1" onClick={()=> {close();}}>
                                    {"NO"}
                                </button>
                            </div>
                        </div>
                    )}
                </Popup>
            </RemoveAgentDiv>
        );
    }
}

export default AgentRemoveButton;