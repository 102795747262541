import { combineReducers } from "redux"
import { routerReducer as routing } from "react-router-redux"
import { reducer as formReducer } from "redux-form"
import clientReducer from "container/Client/reducer"
import agentReducer from "container/Agent/reducer"
import multipleCaller from "container/MultipleCaller/reducer"
import settingsReducer from "container/Settings/reducer"
import academyAdminReducer from "container/Academy/reducer"
import recruitAndEarnReducer from "container/RecruitAndEarn/reducer"
import dashboardReducer from "container/Dashboards/reducer"
import productInterestReducer from "container/ProductInterest/reducer"
import brokerDashboard from "container/BrokerDashboard/reducer"
import agentComparisonReducer from "container/AgentComparison/reducer"
import eventRoomsReducer from "container/EventRooms/reducer"
import mortgageReducer from "container/Mortgage/reducer"
import TechReducer from "container/TechAssets/reducer"
import AIReducer from "container/AI/reducer"
import checklistReducer from "container/CheckList/reducer"
import consumerAppReducer from "container/ConsumerApp/reducer"

const rootReducer = combineReducers({
  client: clientReducer,
  agent: agentReducer,
  multipleCaller,
  settings: settingsReducer,
  form: formReducer,
  brokerDashboard,
  routing,
  academy: academyAdminReducer,
  recruitAndEarn: recruitAndEarnReducer,
  dashboard: dashboardReducer,
  productInterest: productInterestReducer,
  agentComparison: agentComparisonReducer,
  eventRooms: eventRoomsReducer,
  mortgage: mortgageReducer,
  rarTech: TechReducer,
  aiDashboard: AIReducer,
  checklist: checklistReducer,
  consumerApp: consumerAppReducer
})

export default rootReducer
//add here
