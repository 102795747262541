import React, { PureComponent } from "react"
import styled from "styled-components"
import { Button, Modal, Drawer } from "antd"
import { withRouter } from "react-router-dom"
import Container from "container/EventRooms"
import TeamContainer from "container/BrokerDashboard/teamContainer"
import CreateForm from "components/Jobs/CreateForm.js"

const Wrap = styled.div`

`

class JobCreateModal extends PureComponent {
  state = {

  }

  render() {
    const {
      onClose, visible, teamInfo, showJobConfirmationAction,
    } = this.props
    return (
      <Wrap>
        <Drawer
          width="1250px"
          title="Create Job Post on behalf of Team Lead"
          centered
          placement="right"
          visible={visible}
          onClose={() => {
            showJobConfirmationAction(false)
            onClose(false)
          }}
        >
          {visible && (<CreateForm teamInfo={teamInfo} />)}
        </Drawer>
      </Wrap>)
  }
}

export default TeamContainer(Container(withRouter(JobCreateModal)))

