import styled from "styled-components"

export const FilterByText = styled.p`
  color: #667085;
  /* Text md/Normal */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`

export const FlexWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: baseline;
`

export const FormWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: baseline;

  // .styled_Borders input {
  //   height: 42px;
  //   width: 200px;
  //   border: 1px solid var(--Neutral-5, #D9D9D9) !important;
  //   box-shadow: unset !important;
  // }

  .ant-picker {
    height: 42px;
    border: 1px solid var(--Neutral-5, #D9D9D9) !important;
  }
`

export const FormElement = styled.div`
  ${props => props.flex && `
    display: flex;
    align-items: baseline;
    gap: 10px;

    p {
      color: #D9D9D9;
    }

    input {
      height: 100% !important;
    }
  `}

  ${props => props.anim && `
    opacity: 0;
    animation: showBlock 0.5s ease-out forwards;

    @keyframes showBlock {
      to {
        opacity: 1;
      }
    }
  `}
`

export const HideUntilAgentID = styled.div`
    display: none;
    opacity: 0;
`