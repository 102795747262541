import React from "react"
import styled from "styled-components"
import {
  Card,
  SectionTitle,
  CardV2,
  Badge,
  StatusText,
  StatusDiv,
  StatusMessage,
  BadgeText,
} from "../commonStyles"

const ComponentWrap = styled.div``

const SalesRep = () => (
  <ComponentWrap>
    <CardV2
      cardWidth="800px"
    >
      <StatusText>
        Status of the ICA
      </StatusText>
      <StatusDiv>
        <Badge
          bgColor="#A74F4F"
        >
          <img src="https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/SOUL_ISA_AGENT_HAND.svg" alt="soul_asset" />
          <BadgeText>Sales rep review pending</BadgeText>
        </Badge>
        <StatusMessage>
          This envelope must be signed before it can be sent to the client.
        </StatusMessage>
      </StatusDiv>
    </CardV2>
  </ComponentWrap>
)

export default SalesRep
