import React, { useState } from "react"
import styled from "styled-components"

const Wrap = styled.div`
  display: flex; 
  border: 1px solid #eee;
  align-items: center;
  border-radius: 5px;

`

const UserImgWrap = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #999;
  margin: 0px 10px;

  > span {
    font-size: 25px;
    color: #fff;
  }
`

const UserDropdownTooltip = styled.div`
  position: absolute;
  top: 50px;
  right: 0px;
  width: 150px;
	background: #ffffff;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);

  &:after,
  &:before {
    bottom: 100%;
    left: 85%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 10px;
    margin-left: -10px;
  }

  &:before {
    border-color: rgba(212, 212, 212, 0);
    border-bottom-color: #d4d4d4;
    border-width: 11px;
    margin-left: -11px;
  }

  ul {
    padding: 0px;
    margin: 0px;
    li {
      list-style: none;
      padding: 0px 20px;
    }
  }
`

const UserDropDown = styled.div`
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #eee;
  height: 50px;
  position: relative;
  cursor: pointer;
  > i {
    color: #999;
  }
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
`

const ProfileDropdown = ({
  handleLogout,
  availabilityDropdown,
  loggedUser,
  settingsUrl,
  isAdmin,
}) => {
  const [isShowUserDropdown, setIsShowUserDropdown] = useState(false)
  return (
    <Wrap>
       <UserImgWrap>
          <span>{loggedUser && loggedUser[0]}</span>
       </UserImgWrap>
       <UserInfo>
         {loggedUser}
         {availabilityDropdown}
       </UserInfo>
        <UserDropDown
          onMouseEnter={() => setIsShowUserDropdown(true)}
          onMouseLeave={() => setIsShowUserDropdown(false)}
        >
          <i className="fas fa-angle-down" />
          {isShowUserDropdown && (
            <UserDropdownTooltip>
              <ul>
                {isAdmin && (
                  <React.Fragment>
                    <li onClick={() => { window.location.href = settingsUrl }}>
                        <p>Settings</p>
                      </li>
                  </React.Fragment>
                )}
                
                <li onClick={handleLogout}>
                  <p>Logout</p>
                </li>
              </ul>
            </UserDropdownTooltip>
          )}
        </UserDropDown>
    </Wrap>
    
  )
}

export default ProfileDropdown