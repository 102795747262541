import React, { Component } from "react"
import { LoadScript } from "services/Utils"
import Textbox from "components/UI/Textbox/Textbox.js"
import styled from "styled-components"
import LocationIcon from "icons/location2.svg"

const Wrap = styled.div`
  .form-group:focus-within {
    border: 1px solid #4E26BE;
  }

  ${props => props.width && `
    width: ${props.width};
  `}
`

const FormGroup = styled.div`
  margin-bottom: 15px;
  outline: 0;
  border: 1px solid #354052;
  ${props => props.disabled && `
    border: 1px solid #D3DAE8;
    cursor: not-allowed;
  `}
  ${props => props.cityFieldError && `
    border: 1px solid #F6284F;
  `}
  border-radius: 8px;
  width: auto;
  ${props => props.styles && props.styles.height && `
    height: ${props.styles.height}
  `}
  ${props => props.styles && props.styles.borderRadius && `
    border-radius: ${props.styles.borderRadius}
  `}
  ${props => props.styles && props.styles.background && `
    background: ${props.styles.background}
  `}
  ${props => props.styles && props.styles.width && `
    width: ${props.styles.width}
  `}
  ${props => props.styles && props.styles.marginTop && `
    margin-top: ${props.styles.marginTop}
  `}

`

const InputGroup = styled.span`
  position: relative;
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  border-radius: 8px;
  ${props => props.disabled && `
    pointer-events: none;
  `}
`

const InputText = styled(Textbox)`
  border-right: none;
  display: table-cell;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border: none;
  ${props => props.styles && props.styles.fontSize && `
    font-size: ${props.styles.fontSize}
  `}
`

const InputGroupAddOn = styled.span`
  background: white;
  border: none;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: #555;
  text-align: center;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: table-cell;
  i {
    color: black;
  }
`

const Icon = styled.img`
  margin-left: -8px;
  height: 13px;
`
const IconGroup = styled.span`
  display: table-cell;
`

class AutoComplete extends Component {
  constructor(props) {
    super(props)
    this.referenceHandler = this.referenceHandler.bind(this)
  }

  loadScript() {
    const { disabled } = this.props
    if (disabled) return false
    if (typeof window.google === "undefined") {
      const key = "AIzaSyCdHBYtGkNUbvpTXOSIJCglnVxzyFoyPuo"
      LoadScript(
        `https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`,
        () => {
          this.googleAutoCompleteInitializer()
        }
      )
    } else {
      this.googleAutoCompleteInitializer()
    }
    return true
  }

  googleAutoCompleteInitializer() {
    const options = {
      types: this.props.types,
    }
    const geoComponents = new window.google.maps.places.Autocomplete(this.inputElement, options)
    if (this.props.restrict) {
      const cityBounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(this.props.restrict.south_west.lat, this.props.restrict.south_west.lng),
        new window.google.maps.LatLng(this.props.restrict.north_east.lat, this.props.restrict.north_east.lng)
      )
      options.strictBounds = true
      options.bounds = cityBounds
      options.componentRestrictions = {}
      options.componentRestrictions.country = this.props.restrict.country.short_name.toLowerCase()
      geoComponents.setOptions(options)
    }
    window.google.maps.event.addDomListener(this.inputElement, "keydown", (e) => {
      if (e.keyCode === 13) {
        e.preventDefault()
      }
    })

    window.google.maps.event.addListener(geoComponents, "place_changed", () => {
      const place = geoComponents.getPlace()
      if (place.geometry) {
        const location = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          south_west: {
            lat: place.geometry.viewport.getSouthWest().lat(),
            lng: place.geometry.viewport.getSouthWest().lng(),
          },
          north_east: {
            lat: place.geometry.viewport.getNorthEast().lat(),
            lng: place.geometry.viewport.getNorthEast().lng(),
          },
          address: place.formatted_address,
        }
        place.address_components.map(component => (location[component.types[0]] = component))
        if (this.props.clearInput) {
          this.inputElement.value = ""
          this.inputElement.focus()
        }
        this.props.location(location)
      }
    })
  }

  componentDidMount() {
    this.loadScript()
    // this.inputElement.focus();
  }

  referenceHandler(input) {
    this.inputElement = input
  }

  render() {
    const {
      loader: isLoader,
      isTextbox,
      placeholder,
      id,
      value,
      disabled,
      cityFieldError,
      handleChangeInput,
      styles,
      width,
      showLoader = true,
      inputValue,
      showMapPinIcon
    } = this.props
    // const loader = isLoader ? (
    //   <img src="https://d2fedz0by71ckz.cloudfront.net/images/rds-logo-blue.gif" height="22" alt="radius loader" />
    // ) : (
    //   <i className={icons.genericSearch} styles={{ color: "#000000" }} />
    // )
    if (isTextbox) {
      console.log("?A?A?")
      return <Textbox id={id} placeholder={placeholder} reference={this.referenceHandler} />
    }

    return (
      <Wrap
        width={width}
      >
        <FormGroup className="form-group" disabled={disabled} cityFieldError={cityFieldError && cityFieldError} styles={styles}>
          <InputGroup disabled={disabled}>
            {showMapPinIcon && <IconGroup><Icon src={LocationIcon}></Icon></IconGroup>}
            <InputText onChange={handleChangeInput} id={id} placeholder={placeholder} reference={this.referenceHandler} defaultValue={value} styles={styles} />
            {/* {showLoader && (<InputGroupAddOn>{loader}</InputGroupAddOn>)} */}
          </InputGroup>
        </FormGroup>
      </Wrap>
    )
  }
}

export default AutoComplete
