import axios from "axios"

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const IMAGE_API = process.env.IMAGE_API

export const uploadImageAPI = image => axios
.post("https://staging.agentdesks.com/v1.0/image", image).then((res) => {
 return res
})

export const getAllPimAPI = () => axios
.get(`${baseUrl}/v1.0/pim/product-interest-modals`)

export const createNewFeatureAPI = payload => axios
.post(`${baseUrl}/v1.0/pim/product-interest-modal`, payload)

export const updateFeatureAPI = (payload, id) => axios
.put(`${baseUrl}/v1.0/pim/product-interest-modal/${id}`, payload)
