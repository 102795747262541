import React from "react"
import styled from "styled-components"
import {
  Card,
  SectionTitle,
  CardV2,
  Badge,
  StatusText,
  StatusDiv,
  StatusMessage,
  BadgeText,
} from "../commonStyles"

const ComponentWrap = styled.div``

const AgentSignaturePending = () => (
  <ComponentWrap>
    <CardV2>
      <StatusText>
        Status of the ICA
      </StatusText>
      <StatusDiv>
        <Badge
          bgColor="#33B5A8"
        >
          <img src="https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/SOUL_ISA_AGENT_HAND.svg" alt="soul_asset" />
          <BadgeText>Agent Signature Pending</BadgeText>
        </Badge>
        <StatusMessage>
          You have signed the document. Now lets send it to the agent for signature..
        </StatusMessage>
      </StatusDiv>
    </CardV2>
  </ComponentWrap>
)

export default AgentSignaturePending
