import React, { Component } from "react"
import styled from "styled-components"
import { reduxForm, Field } from "redux-form"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"


const CoursesForm = styled.form`
 display: flex;
 flex-direction: column;
 margin-bottom: -200px;
 label {
  font-family: "SF Pro Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #24292e;
 }
`
const UpperDiv = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
`

const LowerDiv = styled.div`
  width: 100%;
  height: 350px;
  padding: 50px;
  button {
   float: right;
  }
`

const InfoDiv = styled.div`
  width: 250px;
  height: 350px;
  flex-grow: 3;
  .fields {
   margin-bottom: 30px;
  }
  .info {
   font-size: 12px !important;
   margin: 0;
   color: #586069;
  }
`

const InputFields = styled(Field)`
  width: 100%;
  height: 39px;
  border-radius: 3px;
  border: solid 1px #d1d5da;
  background-color: #fafbfc;
  padding-left: 9px;
`

class AddCourseForm extends React.Component {

 componentDidMount (){

  const {
   initialize,
   details,
   isUpdateMode,
  } = this.props

  if(details
   && Object.keys(details).length > 0
   && isUpdateMode
   && isUpdateMode.length > 0) {
   initialize({
    courseTitle: details.title,
   })
  } else {
   initialize({
    courseTitle: "",
   })
  }
 }

 render() {
  const { handleSubmit, isUpdateMode } = this.props

  return (
    <CoursesForm id='courses-form' onSubmit={handleSubmit}>
      <UpperDiv>
        <InfoDiv
         isUpdateMode={isUpdateMode}
        >
          <div className="fields">
            <label htmlFor="Course Name">Course Name</label>
            <InputFields name="courseTitle" component="input" type="text" />
            <p className="info">
             Enter the name for the course which will be diplayed to the agents.
            </p>
          </div>
        </InfoDiv>
      </UpperDiv>
    </CoursesForm>
  )
 }
}

export default reduxForm({
  form: 'addCoursesForm',
  destroyOnUnmount: false,
})(AddCourseForm)
