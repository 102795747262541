import React from "react"
import styled from "styled-components"
import ProperyImage from "./../Components/Image"
import Button from "./../Components/Button"
import Para from "./../Components/Para"

const H5 = ({
  style,
  ...props
}) => <h5
  style={{
    color: "#555555",
    fontSize: 12,
  }}
  {...props}
/>

const Image = ({ style, ...props }) => (
  <img
    style={{
      width: 152,
    }}
    {...props}
  />
)

const UpgradeRequest = props => (
  <table style={{ position: "relative", marginTop: 50, background: "#FFF", padding: 20 }}>
    <tbody>
      <tr
        colSpan="10"
        style={{
          height: 70,
          
        }}
      >
        <td style={{ padding: 20 }}>
          <table
            style={{
              width: "100%",
              background: "#FFF",
              position: "relative",
              boxShadow: "0 8px 12px 4px rgba(0, 0, 0, 0.05)",
              top: -100,
            }}
          >
            <tbody style={{ margin: 50 }}>
              <tr
                style={{
                  borderRadius: 4,
                  background: "#FFFFFF",
                  margin: 20,
                }}
              >
                <td>
                  <h1
                    style={{
                      textAlign: "center",
                      fontWeight: "normal",
                      fontFamily: "HelveticaNeue-Light",
                      fontSize: 28,
                    }}
                  >
                    Get the best of Radius Assist
                  </h1>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <ProperyImage type="full" address={false} />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td style={{ position: "relative", top: -100 }}>
          <Para>Hi {`{{ Name }}`}</Para>
          <br />
          <Para>
            Hi [Biju],
            <br/>

            Jessica requested to upgrade to Radius Assist premium! Without premium, you're missing out on potentially higher conversion rates. Upgrade today to take advantage of these key features:
            <ul>
              <li>Increased engagements (calls, texts, and emails) from xxx to xxx</li>
              <li>Increased engagement period from xxx to xxx</li>
              <li>Ability to customize the SMS messages we send to leads</li>
            </ul>
          </Para>
          <br />
          <Para>
            We want to boost exposure for your listings so we've created a profile page for you on Radius Agent for you with your listings displayed.
          </Para>
          <br />
          <Button style={{ margin: "0px auto", display: "block", width: 200, fontSize: 16 }}>
            Update now
          </Button>
          <Para>
          Learn more about our program <a href="#">here</a> or <a href="#">schedule a chat</a> with our team. 
          </Para>
          <Para>Thank you,</Para>
          <br/>
          <Para>
            Cheers,
            <br/>
            <a href="#">radiusagent.com</a>
          </Para>
        </td>
      </tr>
    </tbody>
  </table>
)

export default UpgradeRequest
