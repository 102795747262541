import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import US_STATES from "shared/US_STATES.json";
import { Button, Modal, Form, Input, Select, Upload, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import aiContainer from "container/AI";
import root from "window-or-global";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;
const { Option } = Select;

const FormModal = ({ toggleTrainingFormModal, trainingFormModalResponse, uploadLinkToFile }) => {
  const STATE_OPTIONS = Object.keys(US_STATES).map((key) => ({
    label: US_STATES[key],
    value: US_STATES[key],
  }));
  const [form] = Form.useForm();
  const [selectedFile, setSelectedfile] = useState(null);
  const [uploadType, setUploadType] = useState(null);
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  };

  const handleUploadType = (value) => {
    setUploadType(value)
    form.setFieldsValue({ file: null });
    form.setFieldsValue({ linkToFile: null });
  }

  const handleSubmit = async (values) => {
    const { category, description, documentName, file, linkToFile, state } =
      values || {};
    console.log("values", values);
    if (!file && !linkToFile) {
      console.log("not allowed");
      notification.error({
        message: "Error",
        description:
          "Please either upload document or provide link to the file",
      });
      return;
    }
    const data = {
      category: category || null,
      description: description || null,
      compliance_state: state || null,
    };
    const fd = new FormData();
    if (file && uploadType === "uploadDocument") {
      const fileBlob = new Blob([file], { type: "text/csv;charset=utf-8;" });
      fd.append("files", fileBlob, file[0].name);
      fd.append("properties", JSON.stringify(data));
    try {
      let resp = await fetch(
        `${baseUrl}/v1.0/agent-ally/insert_knowledge_base_documents`,
        {
          method: "POST",
          headers: {
            "internal-id": token,
            "X-User-Agent": "agent-php",
          },
          body: fd,
        }
      );

      resp = await resp.json();
      root.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
    }
    if (linkToFile && uploadType === "uploadLinkToFile"){
      data["document_link"] = linkToFile || null
      const payload = {
        url_info : [data]
      }
      uploadLinkToFile({
          payload
      })
    }
  };

  const handleCancel = () => {
    form.resetFields();
    toggleTrainingFormModal(false);
  };
  const handleRemove = () => {
    setSelectedfile(null);
  };

  const normFile = (e) => {
    return e && e.fileList;
  };

  const handleUpload = (file) => {
    setSelectedfile(file);
  };
  return (
    <Modal
      title="Add Documents For Training"
      visible={trainingFormModalResponse}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={handleCancel}
      okText="Submit"
    >
      <Form
        {...formItemLayout}
        variant="filled"
        style={{
          maxWidth: 600,
        }}
        onFinish={(data) => {
          handleSubmit(data);
        }}
        form={form}
      >
        <Form.Item
          label="Description:"
          name="description"
          rules={[
            {
              required: true,
              message: "Description",
            },
          ]}
        >
          <Input style={{ marginLeft: 20 }} />
        </Form.Item>
        <Form.Item
          label="Select State"
          name="state"
          rules={[
            {
              required: false,
              message: "Please input!",
            },
          ]}
        >
          <Select style={{ marginLeft: 20 }}>
            {STATE_OPTIONS.map((st) => (
              <Select.Option key={st.value} value={st.value}>
                {st.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Category"
          name="category"
          rules={[
            {
              required: false,
              message: "Please input!",
            },
          ]}
        >
          <Input style={{ marginLeft: 20 }} />
        </Form.Item>
        <Form.Item
          label="Type of Upload"
          name="uploadType"
          rules={[
            {
              required: false,
              message: "Please Select!",
            },
          ]}
        >
          <Select 
            onChange={(value) => handleUploadType(value)}
            style={{ marginLeft: 20 }}>
            <Option value="uploadLinkToFile">Upload via Link</Option>
            <Option value="uploadDocument">Upload via Document</Option>
          </Select>
        </Form.Item>
        {uploadType == "uploadLinkToFile" && (
          <Form.Item
          label="Link To File"
          name="linkToFile"
          rules={[
            {
              //required: true,
              message: "Name",
            },
          ]}
        >
          <Input style={{ marginLeft: 20 }} />
        </Form.Item>
        )}
        {uploadType == "uploadDocument" && (
          <Form.Item
          label="Upload File"
          name="file"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            onRemove={handleRemove}
            showUploadList={{
              showRemoveIcon: true,
            }}
            beforeUpload={handleUpload}
          >
            <Button style={{ marginLeft: 20 }} icon={<UploadOutlined />}>
              Select File
            </Button>
          </Upload>
        </Form.Item>
        )}
        <Form.Item
          wrapperCol={{
            offset: 6,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" style={{ marginLeft: 70 }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default aiContainer(FormModal);
