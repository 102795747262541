import React, { PureComponent } from "react"
import styled from "styled-components"
import TechAssetsContainer from "container/TechAssets"
import { get } from "lodash"
import root, { setTimeout } from "window-or-global"
import Sidebar from "dumbComponents/Listings/components/Sidebar"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
import {
  Input,
  InputNumber,
  Form,
  Button,
  GoogleAutoComplete,
  Select,
  Divider,
} from "dumbComponents/common/UI/antd"
import colors from "../../../../lib/colors"
import {
  PageArray,
  BuyerInfoRoute,
  OfferInfoStep,
  SellerInfoRoute,
  SellerInfoStep,
  OfferWritingRoute,
  DocumentsInfoRoute,
} from "../../consts"
import {
  fetchDetails,
  SubmitForm,
  getDealId,
  setStreet,
  getAgentId,
} from "../../commonUtils"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const HeaderNav = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LabelText = styled.h2`
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`

const Required = styled.span`
  color: #E34C4C
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
  .ant-input-number-disabled {
    color: black;
  }
`

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  state = {
    showCoListingform: [1],
    cityFormValue: {},
    offerState: "",
  }

  formRef = React.createRef()

  componentDidMount() {
    const { getDealDetails } = this.props

    fetchDetails(getDealDetails, OfferInfoStep)
  }

  onFinish = (fieldValues, isDraft) => {
    const {
      history,
      updateOffer,
      createOffer,
      setOfferInfoDetails,
      setLoader,
      getDealDetails
    } = this.props
    const finalpayload = {
      ...fieldValues,
      other_fees: [fieldValues.other_fees_1, fieldValues.other_fees_2] || [],
    }
    const currentStep = OfferInfoStep
    const agentId = getAgentId() || false
    setOfferInfoDetails(fieldValues)

    SubmitForm(
      finalpayload,
      isDraft,
      createOffer,
      updateOffer,
      history,
      //DocumentsInfoRoute,
      SellerInfoRoute,
      currentStep,
      null,
      false,
      false,
      agentId,
      false,
    )
    fetchDetails(getDealDetails, SellerInfoStep)
    setLoader(true)
  }

  onSaveAsDraft = (values) => {
    this.onFinish(this.formRef.current.getFieldsValue(), true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  addAdditionalSeller = () => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform
    colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
    setTimeout(() => {
      root.scrollTo(0, 3100)
    }, 300)
  }

  setCities = (location, index) => {
    const { cityFormValue } = this.state
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    this.formRef.current.setFieldsValue({
      city: `${locationDetails.city}`,
      state: `${locationDetails.state}`,
      country: `${locationDetails.country}`,
    })
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [`city-${index}`]: `${locationDetails.city}`,
      },
    })
  }

  setLocaCity = () => {
    // const { cityFormValue } = this.state
    // this.setState({
    //   cityFormValue: {
    //     ...cityFormValue,
    //     [cityFormName]: value,
    //   },
    // })
  }

  getTransactionType = () => {
    const { offerState } = this.state
    if (offerState === "California") {
      return [{
        value: "residential_1_4",
        label: "Standard Purchase",
      },
      {
        value: "commercial",
        label: "Commercial",
      },
      {
        value: "lease",
        label: "Lease",
      },
      {
        value: "manufactured_mo",
        label: "Mobile/Manufactured Home",
      },
      {
        value: "residential_inc",
        label: "Residential Income",
      },
      {
        value: "san_francisco",
        label: "San Francisco",
      },
      {
        value: "vacant_land",
        label: "Vacant Land",
      }]
    }
    if (offerState === "Washington") {
      return [{
        value: "residential_1_4",
        label: "Standard Purchase",
      },
      {
        value: "condo_purchase",
        label: "Condo Purchase",
      },
      {
        value: "commercial",
        label: "Commercial",
      },
      {
        value: "manufactured_mo",
        label: "Mobile/Manufactured Home",
      },
      {
        label: "New Construction",
        value: "new_construction",
      },
      {
        value: "vacant_land",
        label: "Vacant Land",
      }]
    }
    if (offerState === "Texas") {
      return [{
        value: "residential_1_4",
        label: "Standard Purchase",
      },
      {
        value: "condo_purchase",
        label: "Condo Purchase",
      },
      {
        value: "commercial_lease",
        label: "Commercial Lease",
      },
      {
        value: "commercial_sale",
        label: "Commercial Sale",
      },
      {
        value: "residential_lease",
        label: "Residential Lease",
      },
      {
        value: "farm_and_ranch",
        label: "Farm and Ranch",
      },
      {
        label: "New Construction (complete)",
        value: "new_construction_completed",
      },
      {
        label: "New Construction (incomplete)",
        value: "new_construction_incomplete",
      },
      {
        value: "vacant_land",
        label: "Vacant Land",
      }]
    }
    if (offerState === "Oregon") {
      return [{
        value: "residential_1_4",
        label: "Standard Purchase",
      },
      {
        value: "commercial",
        label: "Commercial",
      },
      {
        value: "manufactured_mo",
        label: "Mobile/Manufactured Home",
      },
      {
        label: "New Construction",
        value: "new_construction",
      },
      {
        value: "vacant_land",
        label: "Vacant Land",
      }]
    }
    if (offerState === "Georgia") {
      return [{
        value: "residential_1_4",
        label: "Standard Purchase",
      },
      {
        value: "commercial",
        label: "Commercial",
      },
      {
        label: "New Construction",
        value: "new_construction",
      },
      {
        value: "vacant_land",
        label: "Vacant Land",
      }]
    }
    if (offerState === "Florida") {
      return [{
        value: "residential_1_4",
        label: "Standard Purchase",
      },
      {
        value: "lease",
        label: "Lease",
      },
      {
        value: "northeast_florida_purchase",
        label: "Northeast Florida Purchase",
      },
      {
        label: "New Construction",
        value: "new_construction",
      },
      {
        value: "vacant_land",
        label: "Vacant Land",
      }]
    }
    if (offerState === "Colorado") {
      return [{
        value: "residential_1_4",
        label: "Standard Purchase",
      },
      {
        value: "commercial",
        label: "Commercial",
      },
      {
        value: "lease",
        label: "Lease",
      },
      {
        value: "manufactured_mo",
        label: "Mobile/Manufactured Home",
      },
      {
        value: "vacant_land",
        label: "Vacant Land",
      }]
    }
    return [{
      value: "No Value",
      label: "No Value",
    }]
  }

  getInitialValues = () => {
    const { getCurrentDealDetailsResponse } = this.props
    const { offerState } = this.state
    const {
      data: offerInfo,
    } = getCurrentDealDetailsResponse || {}
    let result = {}
    let other_fees_1 = null;
    let other_fees_2 = null;
    if (offerInfo) {
      if (Array.isArray(offerInfo.other_fees) && offerInfo.other_fees.length > 0) {
        other_fees_1 = offerInfo.other_fees[0] !== undefined ? offerInfo.other_fees[0] : null;
        other_fees_2 = offerInfo.other_fees[1] !== undefined ? offerInfo.other_fees[1] : null;
      }
      result = {
        ...offerInfo,
        other_fees_1,
        other_fees_2,
      }
      if (offerInfo.state !== null && (offerState === "" || offerState === undefined)) {
        this.setState({
          offerState: offerInfo.state,
        })
      }
    }
    return result
  }

  moneyFormatter(number = 0, decPlaces = 0) {
    decPlaces = Math.pow(10, decPlaces)
    const digitsAfterDec = number.toString().includes(".") ? number.toString().split(".")[1].length : number
    if (number.toString().includes(".")) {
      number = Math.round(number * decPlaces) / decPlaces
    }
    return number
  }

  calculatePercentage = (amount, percentage) => {
    const values = this.formRef?.current?.getFieldsValue()
    if (values[amount] === null) {
      this.formRef.current.setFieldsValue({
        [percentage]: null,
      })
    }
    if (values[amount] === 0) {
      this.formRef.current.setFieldsValue({
        [percentage]: 0,
      })
    }
    if (values.price && values[amount]) {
      const percent = (values[amount] * 100) / values.price
      // const rounded = Math.round(percent * 100) / 100
      // const rounded = percent.toFixed(2)
      this.formRef.current.setFieldsValue({
        [percentage]: this.moneyFormatter(percent, 2),
      })
    }
  }

  calculateAmount = (amount, percentage) => {
    const values = this.formRef?.current?.getFieldsValue()
    if (values[percentage] === null) {
      this.formRef.current.setFieldsValue({
        [amount]: null,
      });
    }
    if (values[percentage] === 0) {
      this.formRef.current.setFieldsValue({
        [amount]: 0,
      });
    }
    if (values[percentage]) {
      const loanAmount = values[percentage] * values.price / 100
      this.formRef.current.setFieldsValue({
        [amount]: this.moneyFormatter(loanAmount, 2),
      })
    }
  }
  totalPrice = () => {
    const { offerState } = this.state
    const values = this.formRef?.current?.getFieldsValue()
    if (values.price) {
      if(offerState == "California"){
        const total = values.price - (values.loan_amount + values.initial_deposit_amount + values.increased_deposit_amount + values.additional_financial_amount)
      this.formRef.current.setFieldsValue({
        balance_of_down_payment: total,
      })
      } else if(offerState == "Texas"){
        const total = values.price - (values.loan_amount)
        this.formRef.current.setFieldsValue({
          balance_of_down_payment: total,
      })
      } else if(offerState =="Florida"){
        const total = values.price - ((values.initial_deposit_amount||0) + (values.additional_deposit|| 0) + (values.loan_amount || 0) + (values.other||0))
        this.formRef.current.setFieldsValue({
          balance_to_close:total,
        })
      } else if(offerState == "Washington"){
        const total = values.price - (values.earnest_money + values.loan_amount)
        this.formRef.current.setFieldsValue({
          balance_of_down_payment:total,
        })
      } else if(offerState == "Oregon"){
        const total = values.price - ((values.earnest_money || 0) + (values.increased_deposit_amount || 0))
        this.formRef.current.setFieldsValue({
          balance_of_down_payment:total,
        })
      } else if(offerState == "Colorado"){
        const total = values.price - (values.earnest_money +  values.new_loan_amount + values.assumption_balance + values.private_financing+ values.seller_financing + values.other_fees_1 + values.other_fees_2)
        this.formRef.current.setFieldsValue({
          balance_of_down_payment:total,
        })
      }
    }
  }

  render() {
    const {
      history,
      getCurrentDealDetailsResponse,
      updateOfferActionResponse,
    } = this.props
    const { offerState } = this.state
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]

    const {
      isFetching: fetchingDeal,
    } = getCurrentDealDetailsResponse || {}

    const {
      isFetching: updatingDeal,
    } = updateOfferActionResponse || {}

    const isLoading = fetchingDeal || updatingDeal

    const statesForDropdown = ["California", "Washington", "Texas", "Oregon", "Georgia", "Florida", "Colorado"]

    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Offer Information
        </HeaderNav>
        <ProgressBarComponent total={PageArray} ongoingOrCompleted={3} />
        <Container>
          <LeftSideBar>
            <Sidebar />
          </LeftSideBar>
          <RightSideBar>
            {isLoading ? (
              <p>Loading</p>
            ) : (
              <Form
                name="offer_information"
                colon={false}
                layout="vertical"
                requiredMark={false}
                onFinish={this.onFinish}
                initialValues={this.getInitialValues()}
                ref={this.formRef}
                onFinishFailed={(e) => { console.log("ERROROOOO", e) }}
                scrollToFirstError
              >
                {/*MLS Select*/}
                <FlexBox wd="100%">
                  <Form.Item
                    name="mls_select"
                    label={<LabelComponent text="MLS Name" />}
                  >
                    <Input />
                  </Form.Item>
                </FlexBox>

                <FlexBox>
                  <Form.Item name="mls_number" label={<LabelComponent text="MLS Number" />}>
                    <Input />
                  </Form.Item>
                  {(offerState === "California" || offerState ==="Texas") && 
                    <Form.Item 
                      name="apn_number" 
                      label={<LabelComponent text="APN Number" />}
                    >
                    <Input />
                  </Form.Item>}
                </FlexBox>

                {(offerState ==="Texas" || offerState ==="Georgia") && <FlexBox>
                  <Form.Item 
                    name="lot" 
                    label={<LabelComponent text="Lot" />} 
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item 
                    name="block" 
                    label={<LabelComponent text="Block"/>} 
                  >
                    <Input />
                  </Form.Item>
                </FlexBox>}

                {(offerState === "Florida" ) && <FlexBox>
                    <Form.Item 
                      name="tax_id_number" 
                      label={<LabelComponent text="Tax ID number" required/>}
                      rules={[
                        {
                          required: true,
                          message: "Required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </FlexBox>}

                {(offerState === "Florida" || offerState === "Colorado") && <FlexBox>
                  <Form.Item 
                    name="legal_description" 
                    label={<LabelComponent text="Legal Description" required/>}
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  </FlexBox>}

                {(offerState === "Georgia") && 
                <>
                <FlexBox>
                  <Form.Item 
                    name="tax_parcel_id_number" 
                    label={<LabelComponent text="Tax Parcel ID number" required/>}
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name="land_lots" label={<LabelComponent text="Land Lot(s)" required/>} rules={commonRules}>
                    <Input />
                  </Form.Item>
                </FlexBox>
                <FlexBox>
                <Form.Item name="district" label={<LabelComponent text="District" required/>} rules={commonRules}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="section_gmd" label={<LabelComponent text="Section / GMD" required />} rules={commonRules}>
                    <Input />
                  </Form.Item>
                </FlexBox>
                <FlexBox>
                <Form.Item name="unit" label={<LabelComponent text="Unit" required/>} rules={commonRules}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="phase_section" label={<LabelComponent text="Phase / Section" required />} rules={commonRules}>
                    <Input />
                  </Form.Item>
                </FlexBox>
                <FlexBox>
                <Form.Item name="subdivision_development" label={<LabelComponent text="Subdivision / Development"/>}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="plat_book" label={<LabelComponent text="Plat-book" required />} rules={commonRules}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="page" label={<LabelComponent text="Page" required />} rules={commonRules}>
                    <Input />
                  </Form.Item>
                </FlexBox>
                </>}

                {/*Street*/}
                <FlexBox wd="100%">
                  <Form.Item 
                    name="street" 
                    label={<LabelComponent text="Street" required />}
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    {/* <Input /> */}
                    <GoogleAutoComplete
                      id="cities"
                      types={["address"]}
                      name="cities"
                      // formValue={cityFormValue}
                      onChange={(e) => { }}
                      location={(loc) => {
                        setStreet(loc, this.formRef, "street", true, null, false)
                        this.setState({ offerState: loc.administrative_area_level_1.long_name })
                      }}
                      placeholder=""
                      styles={{
                        height: "49px",
                        borderRadius: "0px",
                        background: "white",
                        fontSize: "16px",
                        marginTop: "10px",
                      }}
                    />
                  </Form.Item>
                </FlexBox>
                <FlexBox>
                  <Form.Item name="apt" label={<LabelComponent text="Apt/Unit/Suite No." />}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="city" label={<LabelComponent text="City" required />} rules={commonRules}>
                    <GoogleAutoComplete
                      id="cities"
                      types={["(cities)"]}
                      name="cities"
                      // formValue={cityFormValue[`city-${index}`]}
                      onChange={(e) => { this.setLocaCity(e.target.value, "city") }}
                      location={(loc) => { this.setCities(loc) }}
                      placeholder=""
                      styles={{
                        height: "49px",
                        borderRadius: "0px",
                        background: "white",
                        fontSize: "16px",
                        marginTop: "10px",
                      }}
                      restrict={{
                        lat: 37.09024,
                        lng: -95.712891,
                        south_west: {
                          lat: 25.82,
                          lng: -124.39,
                        },
                        north_east: {
                          lat: 49.38,
                          lng: -66.94,
                        },
                        address: "United States",
                        country: {
                          long_name: "United States",
                          short_name: "US",
                          types: [
                            "country",
                            "political",
                          ],
                        },
                      }}
                    />
                  </Form.Item>

                  <Form.Item name="state" label={<LabelComponent text="State" required />} rules={commonRules}>
                    <Input
                      onChange={e => this.setState({ offerState: e.target.value })}
                    />
                    {/* <Select> */}
                    {/* {STATE_OPTIONS.map(st => (
                        <Select.Option value={st.value}>{st.label}</Select.Option>
                      ))} */}
                    {/* </Select> */}
                  </Form.Item>
                </FlexBox>

                <FlexBox>
                  <Form.Item
                    name="zipcode"
                    label={<LabelComponent text="Zip Code" required />}
                    rules={[
                      {
                        required: true,
                        message: "Invalid Input",
                        type: "string",
                        pattern: new RegExp(/\d+/g),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="county"
                    label={<LabelComponent text="County" />}
                  >
                    <Input />
                  </Form.Item>
                </FlexBox>
                {statesForDropdown.includes(offerState) && (
                  <FlexBox>
                    <Form.Item
                      name="type_of_transaction"
                      label={<LabelComponent text="Type of Transaction" required />}
                      rules={[
                        {
                          required: true,
                          message: "Required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select"
                        style={{
                          width: "70%",
                          marginTop: 8,
                        }}
                        options={this.getTransactionType()}
                      />
                    </Form.Item>
                  </FlexBox>
                )}
                <Divider />
                <FlexBox wd="100%">
                  <Form.Item
                    name="price"
                    label={offerState === "Texas" ?  <LabelComponent text="Sales Price" required /> : <LabelComponent text="Purchase Price" required />}
                    required
                    rules={[
                      {
                        type: "number",
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      onChange={() => {
                        const values = this.formRef?.current?.getFieldsValue()
                        if (values.loan_amount) {
                          this.calculatePercentage("loan_amount", "loan_amount_percentage")
                        }
                        if (values.initial_deposit_amount) {
                          this.calculatePercentage("initial_deposit_amount", "initial_deposit_amount_percentage")
                        }
                        if (values.increased_deposit_amount) {
                          this.calculatePercentage("increased_deposit_amount", "increased_deposit_amount_percentage")
                        }
                        if (values.additional_financial_amount) {
                          this.calculatePercentage("additional_financial_amount", "additional_financial_amount_percentage")
                        } 
                        if (values.additional_earnest_money) {
                          this.calculatePercentage("additional_earnest_money", "additional_earnest_money_percentage")
                        } 
                        if (values.additional_deposit) {
                          this.calculatePercentage("additional_deposit", "additional_deposit_percentage")
                        }
                        if (values.earnest_money) {
                          this.calculatePercentage("earnest_money","earnest_money_percentage")
                        }
                        if (values.new_loan_amount) {
                          this.calculatePercentage("new_loan_amount","new_loan_amount_percentage")
                        }
                        this.totalPrice()
                      }}
                    />
                    {/* <Select disabled defaultValue="USD" style={{ width: 70 }}>
                      <Select.Option value="USD">$</Select.Option>
                    </Select> */}
                  </Form.Item>
                  {(offerState == "California" || offerState == "Texas"|| offerState == "Florida" || offerState == "Georgia" || offerState == "Washington" ) && <Form.Item
                    name="loan_amount"
                    label={
                      (offerState === "Texas" &&  <LabelComponent text="Financed Amount" />) ||
                      ((offerState == "California" ||  offerState == "Washington") && <LabelComponent text="Loan Amount" />) ||
                      (offerState == "Florida" && <LabelComponent text="Financing Amount" />) ||
                      (offerState == "Georgia" && <LabelComponent text="First Mortgage Loan Amount" />)
                    }
                    //required
                  >
                    <InputNumber
                      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      onChange={() => {
                        this.calculatePercentage("loan_amount", "loan_amount_percentage")
                        this.totalPrice()
                      }}
                    />
                  </Form.Item>}
                  {(offerState == "California" || offerState == "Texas"|| offerState == "Florida" || offerState == "Georgia" || offerState == "Washington") && <Form.Item
                    name="loan_amount_percentage"
                    label={
                      (offerState === "Texas"&&<LabelComponent text="Financed Amount Percentage" />) ||  
                      ((offerState == "California" || offerState == "Washington") && <LabelComponent text="Loan Amount Percentage" />) ||
                      (offerState == "Florida"&& <LabelComponent text="Financing Amount Percentage" />) ||
                      (offerState == "Georgia" && <LabelComponent text="First Mortgage Loan Amount Percentage" />)
                    }
                  >
                    <InputNumber
                      formatter={value => value ? `${value} %` : ""}
                      onChange={() => {
                        this.calculateAmount("loan_amount", "loan_amount_percentage")
                        this.totalPrice()
                      }}
                    />
                    {/* <Select disabled defaultValue="USD" style={{ width: 70 }}>
                      <Select.Option value="USD">$</Select.Option>
                    </Select> */}
                  </Form.Item>}
                </FlexBox>
                  {offerState === "Texas" && (
                    <FlexBox wd="100%">
                    <Form.Item
                      name="option_fee"
                      label={<LabelComponent text="Option Fee"  />}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={() => {
                          this.totalPrice()
                        }}
                      />
                      {/* <Select disabled defaultValue="USD" style={{ width: 70 }}>
                    <Select.Option value="USD">$</Select.Option>
                  </Select> */}
                    </Form.Item>
                    </FlexBox>
                  )}
                  {((offerState === "California" || offerState === "Florida") && (
                    <FlexBox wd="100%">
                    <Form.Item
                    name="initial_deposit_amount"
                    label={<LabelComponent text="Initial Deposit Amount"  />}
                  >
                    <InputNumber
                      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      onChange={() => {
                        this.calculatePercentage("initial_deposit_amount", "initial_deposit_amount_percentage")
                        this.totalPrice()
                      }}
                    />
                    {/* <Select disabled defaultValue="USD" style={{ width: 70 }}>
                    <Select.Option value="USD">$</Select.Option>
                  </Select> */}
                  </Form.Item>
                  <Form.Item
                  name="initial_deposit_amount_percentage"
                  label={<LabelComponent text="Initial Deposit Amount Percentage"/>}
                >
                  <InputNumber
                    formatter={value => value ? `${value} %` : ""}
                    onChange={() => {
                      this.calculateAmount("initial_deposit_amount", "initial_deposit_amount_percentage")
                      this.totalPrice()
                    }}
                  />
                </Form.Item>
                </FlexBox>
                  ))}
                {(offerState === "California") && (
                  <FlexBox wd="100%">
                    <Form.Item
                      name="increased_deposit_amount"
                      label={<LabelComponent text="Increased Deposit Amount" />}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={() => {
                          this.calculatePercentage("increased_deposit_amount", "increased_deposit_amount_percentage")
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="increased_deposit_amount_percentage"
                      label={<LabelComponent text="Increased Deposit Amount Percentage" />}
                    >
                      <InputNumber
                        formatter={value => value ? `${value} %` : ""}
                        onChange={() => {
                          this.calculateAmount("increased_deposit_amount", "increased_deposit_amount_percentage")
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                  </FlexBox>
                )}
                {(offerState === "California" || offerState === "Georgia") && (
                  <FlexBox wd="100%">
                    <Form.Item
                      name="additional_financial_amount"
                      label={
                        (offerState === "California"&& <LabelComponent text="Additional Financing Amount" />) || 
                        ( offerState === "Georgia" && <LabelComponent text="Second Mortgage Loan Amount" />)
                      }
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={() => {
                          this.calculatePercentage("additional_financial_amount", "additional_financial_amount_percentage")
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="additional_financial_amount_percentage"
                      label={
                        (offerState === "California"&& <LabelComponent text="Additional Financing Amount Percentage" />) || 
                        ( offerState === "Georgia" && <LabelComponent text="Second Mortgage Loan Amount Percentage" />)
                      }
                    >
                      <InputNumber
                        formatter={value => value ? `${value} %` : ""}
                        onChange={() => {
                          this.calculateAmount("additional_financial_amount", "additional_financial_amount_percentage")
                          this.totalPrice()
                        }}
                      />
                      {/* <Select disabled defaultValue="USD" style={{ width: 70 }}>
                        <Select.Option value="USD">$</Select.Option>
                      </Select> */}
                    </Form.Item>
                  </FlexBox>
                )}
                {(offerState === "California" || offerState === "Texas" || offerState == "Washington" || offerState == "Oregon" || offerState === "Colorado") && (
                  <FlexBox wd="100%">
                    <Form.Item
                      name="balance_of_down_payment"
                      label={
                        ((offerState === "California" || offerState == "Washington") && <LabelComponent text="Balance of Down Payment" />) || 
                        (offerState === "Texas" && <LabelComponent text="Cash Portion of Sales Price" />) ||
                        (offerState == "Oregon" && <LabelComponent text="Remainder Of Purchase Price" />) ||
                        (offerState === "Colorado" && <LabelComponent text="Cash at Closing" />)
                      }
                    >
                      <InputNumber
                        disabled
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        // onChange={() => {
                        //   this.totalPrice()
                        // }}
                      />
                      {/* <Select disabled defaultValue="USD" style={{ width: 70 }}>
                        <Select.Option value="USD">$</Select.Option>
                      </Select> */}
                    </Form.Item>
                  </FlexBox>
                )}
                {offerState == "Texas" && (
                  <FlexBox wd="100%">
                    <Form.Item
                      name="additional_earnest_money"
                      label={<LabelComponent text="Additional Earnest Money" />}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={() => {
                          this.calculatePercentage("additional_earnest_money", "additional_earnest_money_percentage")
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="additional_earnest_money_percentage"
                      label={<LabelComponent text="Additional Earnest Money Percentage" />}
                    >
                      <InputNumber
                        formatter={value => value ? `${value} %` : ""}
                        onChange={() => {
                          this.calculateAmount("additional_earnest_money", "additional_earnest_money_percentage")
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                  </FlexBox>
                )}
                {offerState == "Colorado" && (
                  <FlexBox wd="100%">
                    <Form.Item
                      name="new_loan_amount"
                      label={<LabelComponent text="New Loan" />}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={() => {
                          this.calculatePercentage("new_loan_amount", "new_loan_amount_percentage")
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="new_loan_amount_percentage"
                      label={<LabelComponent text="New Loan Percentage" />}
                    >
                      <InputNumber
                        formatter={value => value ? `${value} %` : ""}
                        onChange={() => {
                          this.calculateAmount("new_loan_amount", "new_loan_amount_percentage")
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                  </FlexBox>
                )}
                {(offerState == "Texas" || offerState == "Georgia"|| offerState == "Washington" || offerState == "Oregon" || offerState == "Colorado") && (
                  <FlexBox wd="100%">
                    <Form.Item
                      name="earnest_money"
                      label={<LabelComponent text="Earnest Money" />}
                      required
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={() => {
                          this.calculatePercentage("earnest_money", "earnest_money_percentage")
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="earnest_money_percentage"
                      label={<LabelComponent text="Earnest Money Percentage " />}
                    >
                      <InputNumber
                        formatter={value => value ? `${value} %` : ""}
                        onChange={() => {
                          this.calculateAmount("earnest_money", "earnest_money_percentage")
                          this.totalPrice()
                        }}
                      />
                      {/* <Select disabled defaultValue="USD" style={{ width: 70 }}>
                        <Select.Option value="USD">$</Select.Option>
                      </Select> */}
                    </Form.Item>
                  </FlexBox>
                )}
                {offerState == "Florida" && (
                  <FlexBox wd="100%">
                    <Form.Item
                      name="additional_deposit"
                      label={<LabelComponent text="Additional Deposit" />}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={() => {
                          this.calculatePercentage("additional_deposit", "additional_deposit_percentage")
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="additional_deposit_percentage"
                      label={<LabelComponent text="Additional Deposit Percentage " />}
                    >
                      <InputNumber
                        formatter={value => value ? `${value} %` : ""}
                        onChange={() => {
                          this.calculateAmount("additional_deposit", "additional_deposit_percentage")
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                  </FlexBox>
                )}
                {offerState === "Florida" && (
                    <FlexBox wd="100%">
                    <Form.Item
                      name="other"
                      label={<LabelComponent text="Other" />}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={() => {
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                    </FlexBox>
                  )}
                  {(offerState === "Florida") && (
                  <FlexBox wd="100%">
                    <Form.Item
                      name="balance_to_close"
                      label={ <LabelComponent text="Balance To Close" required />}
                      required
                      rules={[
                        {
                          type: "number",
                          required: true,
                          message: "Required",
                        },
                      ]}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        disabled
                        // onChange={() => {
                        //   this.totalPrice()
                        // }}
                      />
                    </Form.Item>
                  </FlexBox>
                )}
                {(offerState === "Georgia") && (
                  <FlexBox wd="100%">
                    <Form.Item
                      name="sellers_contribution_at_closing"
                      label={ <LabelComponent text="Closing Costs. Seller's Contribution at Closing" />}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        // onChange={() => {
                        //   this.totalPrice()
                        // }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="additional_option_money"
                      label={ <LabelComponent text="Additional Option Money" />}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        // onChange={() => {
                        //   this.totalPrice()
                        // }}
                      />
                    </Form.Item>
                  </FlexBox>
                )}
                {(offerState === "Oregon") && (
                  <FlexBox wd="100%">
                    <Form.Item
                      name="increased_deposit_amount"
                      label={<LabelComponent text="Remainder Of Down Payment" />}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={() => {
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="buyers_closing_costs_and_prepaid_items"
                      label={ <LabelComponent text="Closing Costs. Seller's Contribution at Closing" />}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      />
                    </Form.Item>
                  </FlexBox>
                )}
                {(offerState === "Colorado") && (
                  <FlexBox wd="100%">
                    <Form.Item
                      name="assumption_balance"
                      label={ <LabelComponent text="Assumption Balance" />}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={() => {
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="private_financing"
                      label={ <LabelComponent text="Private Financing" />}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={() => {
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                  </FlexBox>
                )}
                {(offerState === "Colorado") && (
                  <FlexBox wd="100%">
                    <Form.Item
                      name="seller_financing"
                      label={ <LabelComponent text="Seller Financing" />}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={() => {
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                  </FlexBox>
                )}
                {(offerState === "Colorado") && (
                  <FlexBox wd="100%">
                    <Form.Item
                      name="other_fees_1"
                      label={ <LabelComponent text="Other 1" />}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={() => {
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="other_fees_2"
                      label={ <LabelComponent text="Other 2" />}
                    >
                      <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={() => {
                          this.totalPrice()
                        }}
                      />
                    </Form.Item>
                  </FlexBox>
                )}
                <FlexBox>
                  <FlexBox wd="100%">
                  </FlexBox>
                  <FlexBox>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "white",
                          float: "right",
                          borderColor: "#3B60E4",
                          color: "#3B60E4",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        // htmlType="submit"
                        onClick={() => { history.push(`${BuyerInfoRoute}?dealId=${getDealId()}&agentId=${getAgentId()}`) }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "#3B60E4",
                          float: "right",
                          borderColor: "white",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        loading={isLoading}
                        htmlType="submit"
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </FlexBox>
                </FlexBox>
              </Form>
            )}
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)

