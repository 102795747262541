/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react"
import { Drawer, Timeline } from "antd"
import moment from "moment"
import styled from "styled-components"
import Loader from "dumbComponents/common/UI/Loader"
import container from "container/BrokerDashboard/globalPagesContainer"

const Title = styled.p`
  font-size: 19px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 35px;
`
const Date = styled.h3`
  font-size: 14px;
  font-weight: 400;
`
const Text = styled.h3`
  font-size: 13px;
  font-weight: 200;
`

//notes
const NotesContainer = styled.div`
  display: flex;
  align-items: center;
`

const TimelineDrawer = (props) => {
  const {
    toggleTimelineDrawer,
    isTimelineDrawerOpen,
    getDealStatusTimelineResponse,
  } = props || {}

  const handleClose = () => {
    toggleTimelineDrawer(false)
  }

  const getLocalTime = (dateString) => {
    const localDate = dateString
    const tempDate = moment.utc(localDate)
    const finalDate = moment(tempDate).local().format("YYYY-MM-DD HH:mm")
    return finalDate
  }

  const {
    data: timeline,
  } = getDealStatusTimelineResponse || {}

  return (
    <Drawer
      title="Activity Log"
      width={700}
      placement="left"
      onClose={handleClose}
      visible={isTimelineDrawerOpen}
    >
      <Title>Deal Status Timeline</Title>
      {!timeline ? (
        <Loader />
      ) : (
        <>
          {timeline && Object.keys(timeline).length > 0 ? (
            <Timeline>
              {timeline.map(item => (
                <Timeline.Item key={item.created_at}>
                  <Date>
                    {getLocalTime(item.created_at)}
                  </Date>
                  <Text>
                    {item.status}
                  </Text>
                  <NotesContainer>
                    <p>
                      {`Notes: ${item.notes || "N/A"}`}
                    </p>
                  </NotesContainer>
                </Timeline.Item>
              ))}
            </Timeline>
          ) : (
            <p>N/A</p>
          )}
        </>
      )}
    </Drawer>
  )
}
export default container(TimelineDrawer)
