import React from "react"
import {
  Table,
  Tag,
  Pagination,
  Button,
  Modal,
} from "antd"
import styled from "styled-components"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import RecruitAndEarn from "../../../../container/RecruitAndEarn"
import ConfirmationModal from "../../Common/ConfirmationModal"
import { serializer, handleDrawerActions } from "../../Common/Utils"

const { confirm } = Modal

const StyledButton = styled.button`
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0px;
  text-align: left;
`
const ResultsFor = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  -webkit-letter-spacing: 0em;
  -moz-letter-spacing: 0em;
  -ms-letter-spacing: 0em;
  letter-spacing: 0em;
  text-align: left;
  font-style: italic;
  color: #2d2d2d;
`

const Wrap = styled.div`
  padding-top: 17px;
  overflow: scroll;
  width: 100%;
  .infoTable {
    th, td {
      border: none;
      font-family: "Rubik", sans-serif;
    }

    th {
      padding: 5px 5px 5px 18px;

    }

    td {
      padding: 10px 5px 10px 18px;
      background: #fafafa;

      &:hover {
        background: #fcfcfc;
      }
     }

    .ant-table-thead tr th {
      background: linear-gradient(0deg, #E5E5E5, #E5E5E5),
      linear-gradient(0deg, #E6E6E6, #E6E6E6);
    }
  }
`

const ResultsTable = (props) => {
  const {
    toggleInvitedDrawer,
    referralList,
    fetchingList,
    fetchInviteeTimeline,
    toggleAgentDetailsDrawer,
    currentSenderAgent,
    currentReferralAgent,
    deleteInvite,
    fetchReferrals,
  } = props || {}

  const confirmDelete = (id) => {
    confirm({
      title: "Please confirm the action.",
      icon: <ExclamationCircleOutlined />,
      content: <ConfirmationModal
        typeSet="delete"
      />,
      onOk() {
        deleteInvite({
          id,
        })
      },

      onCancel() {
        return false
      },
    })
  }

  const createColumn = () => {
    const columns = [
      {
        title: "Referred Agent",
        dataIndex: "name",
        key: "name",
        render: (text, record) => {
          const payload = {
            agentId: record.receiverId,
          }
          const togglePayload = {
            inviteeData: record,
            bool: true,
          }
          return (
            <StyledButton
              onClick={() => handleDrawerActions(
                fetchInviteeTimeline,
                payload,
                toggleInvitedDrawer,
                togglePayload,
              )}
            >
              {text || "N/A"}
            </StyledButton>
          )
        },
      },
      {
        title: "Referred by",
        dataIndex: "referred",
        key: "referred",
        render: (text, record) => {
          const togglePayload = {
            agentName: record.referred,
            agentId: record.senderId,
            bool: true,
          }
          return (
            <StyledButton
              onClick={() => handleDrawerActions(
                false,
                false,
                toggleAgentDetailsDrawer,
                togglePayload,
              )}
            >
              {text}
            </StyledButton>
          )
        },
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
      },
      {
        title: "How Agent knows Referred Agent",
        dataIndex: "notes",
        key: "notes",
      },
      {
        title: "Referred Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Team Acquisition",
        dataIndex: "senderTeamName",
        key: "senderTeamName",
      },
      {
        title: "Joined Brokerage Service",
        dataIndex: "joinDate",
        key: "joinDate",
      },
      {
        title: "Estimated (Stock)",
        dataIndex: "stock",
        key: "stock",
      },
      {
        title: "Churned At",
        dataIndex: "churnedAt",
        key: "churnedAt",
      },
      {
        title: "Added By Admin",
        dataIndex: "addedBy",
        key: "addedBy",
      },
      {
        title: "Status",
        key: "tags",
        dataIndex: "tags",
        render: (_, { tags }) => (
          <>
            {tags.map((tag) => {
              let color = ""
  
              switch (tag) {
                case "invited":
                  color = "#D8A8007D"
                  break
                case "joined":
                  color = "#42F52F"
                  break
                case "declined":
                  color = "#DC0000AB"
                  break
                case "non_responsive":
                  color = "#D3D3D3"
                  break
                case "reaching_out":
                  color = "#45ABF5"
                  break
                case "in_progress":
                  color = "#7DF5D5"
                  break
                default:
                  color = ""
                  break
              }
    
              return (
                <Tag color={color} key={tag} style={{ color: "#303030" }}>
                  {tag.toUpperCase().replaceAll("_", " ")}
                </Tag>
              )
            })}
          </>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        render: (text, record) => (
          <Button
            type="danger"
            onClick={() => confirmDelete(record.id)}
          >
            Delete
          </Button>
        ),
      },
    ]
    return columns
  }

  const serialized = !fetchingList && serializer(referralList) || []

  return (
    <Wrap>
      {(currentSenderAgent || currentReferralAgent) && (
        <ResultsFor>
          {`Showing results for: 
            "${currentReferralAgent ? `Referred by : ${currentReferralAgent}` : ""}", 
            "${currentSenderAgent ? `Received by : ${currentSenderAgent}` : ""}"`
          }
        </ResultsFor>
      )}
      <Table
        className="infoTable"
        columns={createColumn()}
        dataSource={serialized}
        loading={fetchingList}
      >
        <Pagination
          total={serialized.length}
          defaultPageSize={50}
          defaultCurrent={1}
        />
      </Table>
    </Wrap>
  )
}

export default RecruitAndEarn(ResultsTable)
