import React, { Component } from "react"
import styled from "styled-components"
import { reduxForm, Field } from "redux-form"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"

const LessonsForm = styled.form`
 display: flex;
 flex-direction: column;
 margin-bottom: -250px;
 label {
  font-family: "SF Pro Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #24292e;
 }
`
const UpperDiv = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
`

const LowerDiv = styled.div`
  width: 100%;
  height: 350px;
  padding: 50px;
  button {
   float: right;
  }
`

const InfoDiv = styled.div`
  width: 250px;
  height: 350px;
  flex-grow: 3;
  .fields {
   margin-bottom: 30px;
   label {
    padding-bottom: 10px;
   }
  }
  .info {
   font-size: 12px !important;
   margin: 0;
   color: #586069;
  }
`

const InputFields = styled(Field)`
  width: 100%;
  height: 39px;
  border-radius: 3px;
  border: solid 1px #d1d5da;
  background-color: #fafbfc;
  margin-top: 10px;
  padding-left: 9px;
`

class About extends React.Component {
  componentDidMount() {
   const {
    courseDetail,
    initialize,
    isIndividualLessonMode,
   } = this.props

   if(courseDetail
    && Object.keys(courseDetail).length > 0
    && courseDetail.Lessons
    && courseDetail.Lessons.length > 0
    && isIndividualLessonMode
    && isIndividualLessonMode.length > 0
   ){
    let lessonArray = courseDetail.Lessons
    let currentLesson = lessonArray.filter(lesson => lesson.id === isIndividualLessonMode)[0]
    initialize({
     lessonTitle: currentLesson && currentLesson.title,
    })
   }
  }

  render() {
   const {
    handleSubmit,
    courseDetail,
   } = this.props

   return (
     <LessonsForm id='about-lesson-form' onSubmit={handleSubmit}>
       <UpperDiv>
         <InfoDiv>
           <div className="fields">
             <label htmlFor="Lesson Title">Lesson Title</label>
             <InputFields name="lessonTitle" component="input" type="text" />
             <p className="info">
              Title of the lesson displayed for the agents
             </p>
           </div>
         </InfoDiv>
       </UpperDiv>
     </LessonsForm>
   )
  }
}

About = reduxForm({
  form: 'aboutLessonForm',
  destroyOnUnmount: false,
})(About)

export default Container(About)
