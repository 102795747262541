import React, { Component } from 'react';
import bugsnag from 'bugsnag-js';
import Notifications, {notify} from 'react-notify-toast';
import styled from "styled-components";
import AutoSuggest from "../../UI/AutoSuggest/AutoSuggest";
import {searchAgent} from "../../API/AgentApi";
import {NotifyBugsnagError} from "../../../lib/ErrorUtils";
import _ from "lodash";
import {DefaultLoader} from "../../UI/Loader/Loader";
import MultiCallerNote from "./NotesForPool"

const baseUrl =process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;
const originalKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(originalKey);

const ModalTitle = styled.h3`
  font-size: 24px;
  font-weight: 500;
  color: #354052;
  margin-top: 0;
`

const ModalIcon = styled.i`
  float: right;
  color: #dadada;
  font-size: 22px;
  margin-right: -20px;
  cursor: pointer;
`

class PoolAgents extends Component {

    state = {
        juniorConsultants: [],
        pool: null,
        pool_agents: [],
        pool_agent_ids: [],
        loading:false,
    };

    startLoader = () => {
        this.setState({
            loading: true
        });
    };
    stopLoader = () => {
        this.setState({
            loading: false
        });
    };

    setConsultantData = (pool) => {
        try{
            if (pool) {
                let pool_agent_ids = [];
                let pool_agents = [];
                if (pool.pool_agent_ids) {
                    pool_agent_ids = JSON.parse("[" + pool.pool_agent_ids + "]")
                }
                if(pool.pool_agents) {
                    pool_agents = pool.pool_agents;
                }
                this.setState({ pool_agent_ids: pool_agent_ids,
                    pool_agents: pool_agents, pool: pool});
            }
        }
        catch(e){
            console.log(e);
            bugsnagClient.notify(e);
        }
    };

    componentWillReceiveProps(props) {
        this.setConsultantData(this.props.pool);
        this.setState({juniorConsultants: this.props.juniorConsultants});
    }

    componentDidMount() {
        this.setConsultantData(this.props.pool);
        this.setState({juniorConsultants: this.props.juniorConsultants});
    }

    handleCancel = () => {
        //event.preventDefault();
        this.props.closePrioritizeModal();
    };

    searchAgent = async (currentSearchText) => {
        try {
            let users = this.state.juniorConsultants ? this.state.juniorConsultants : [];
            users = _.filter( users, (user) => user.name.includes(currentSearchText) || user.email.includes(currentSearchText));
            //return await searchAgent(currentSearchText).then(res => {
                return users.map((agent) => {
                    let cli = {};
                    cli.name = agent.name;
                    cli.email = agent.email;
                    cli.agent_id = agent.agent_id;
                    return cli;
                });
            //});
        }
        catch(e) {
            console.log(e);
            NotifyBugsnagError(e);
        }
    };

    getSuggestionValue = (suggestion) => {
        this.addPrioritizedAgent(suggestion);
        return suggestion.name ? suggestion.name : suggestion.email;
    };

    addPrioritizedAgent = (suggestion) => {
        try{
            let pool_agent_ids = this.state.pool_agent_ids ? this.state.pool_agent_ids : [];
            console.log(pool_agent_ids);
            let pool_agents = this.state.pool_agents ?  this.state.pool_agents : [];
            let isPresent = false;
            if (pool_agent_ids && pool_agent_ids.length > 0) {
                for (let index=0 ; index<pool_agent_ids.length ; index++) {
                    if (suggestion.agent_id === pool_agent_ids[index]) {
                        isPresent = true;
                    }
                }
            }
            if (!isPresent) {
                pool_agent_ids.push(suggestion.agent_id);
                pool_agents.push({firstname: suggestion.name, email: suggestion.email});
            }
            this.setState({ pool_agents: pool_agents, pool_agent_ids: pool_agent_ids});
        }
        catch(e){
            console.log(e);
            bugsnagClient.notify(e);
        }
    };

    removePrioritizedAgent = (agentId, agentEmail) => {
        try{
            let pool_agent_ids = this.state.pool_agent_ids;
            let pool_agents = this.state.pool_agents;
            let pool_agent_ids_updated = [];
            if (pool_agent_ids && pool_agent_ids.length > 0) {
                for (let index = 0; index < pool_agent_ids.length; index++) {
                    if (pool_agent_ids[index] != agentId) {
                        pool_agent_ids_updated.push(pool_agent_ids[index]);
                    }
                }
            }
            let pool_agents_updated = [];
            if (pool_agents && pool_agents.length > 0) {
                for (let index = 0; index < pool_agents.length; index++) {
                    if (pool_agents[index].email != agentEmail) {
                        pool_agents_updated.push(pool_agents[index]);
                    }
                }
            }
            this.setState({ pool_agent_ids: pool_agent_ids_updated,
                pool_agents: pool_agents_updated});
        }
        catch(e){
            console.log(e);
            bugsnagClient.notify(e);
        }
    };

    save = async () => {
        try {
            this.startLoader();
            let pool_agent_ids = this.state.pool_agent_ids;
            let body = {
                pool_agent_ids: pool_agent_ids,
            };
            let response = await fetch(baseUrl + '/v1.0/pools/' + this.props.pool.id, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'secure-id': token,
                },
                body: JSON.stringify(body)

            });
            let respJson = await response.json();
            await this.props.loadAccounts();
            await this.props.closePrioritizeModal();
            this.stopLoader();
        }
        catch (e) {
            await this.props.loadAccounts();
            await this.props.closePrioritizeModal();
            console.log(e);
            bugsnagClient.notify(e);
            this.stopLoader();
        }
    };

    render() {
        const props = this.props;
        let pool_agents = this.state.pool_agents;

        if(!this.state.pool) {
            return <div>Loading</div>
        }
        let poolAgentsDiv = null;
        if(pool_agents && pool_agents.length > 0) {
            poolAgentsDiv = pool_agents.map((pool_agent) =>
                <tr>
                    <td>
                        {pool_agent.firstname} {pool_agent.lastname}
                    </td>
                    <td>
                        {pool_agent.email}
                    </td>
                    <td>
                        <button
                            className="btn-primary "
                            onClick={this.removePrioritizedAgent.bind(this, pool_agent.id, pool_agent.email)}
                            style={{
                                width: '150px',
                                background: '#00adf3',
                                marginLeft: '20px',
                            }}
                        >
                            Remove
                        </button>


                    </td>
                </tr>);
        } else {
            poolAgentsDiv = <tr></tr>
        }

        return <div className={"caller-settings-modal"} style={{'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto'}}>
                <React.Fragment>
                    <Notifications/>

                    <ModalTitle>
                        POOL USERS
                        <ModalIcon
                            className={"fas fa-times modal-icon"}
                            onClick={props.closePrioritizeModal}
                        />
                    </ModalTitle>
                    {this.state.loading ?
                        <DefaultLoader/> :

                        <div>
                            <div>
                                <AutoSuggest
                                    placeholder={"Search CSE"}
                                    getSuggestions={this.searchAgent}
                                    getSuggestionValue={this.getSuggestionValue}/>
                            </div>
                            <MultiCallerNote />
                            <div style={{paddingBottom: '10px'}}>
                                <table>
                                    {poolAgentsDiv}
                                </table>
                            </div>

                            <div>
                                <button className="modal-cancel-btn" onClick={this.handleCancel}>
                                    Cancel
                                </button>
                                <button type={"submit"} disabled={this.state.loading} className="modal-submit-btn"
                                        onClick={this.save}>Save
                                </button>
                            </div>
                        </div>
                    }

                </React.Fragment>

        </div>

    }

}


export default PoolAgents;