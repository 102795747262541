import React from "react";
import CheckList from "container/CheckList/index";
import ChecklistDocument from "./components/ChecklistDocuments";
const ChecklistTemplateDocument = ({

}) => { 
    return (
        <>
            <ChecklistDocument />
        </>
    )
}

export default CheckList(ChecklistTemplateDocument);