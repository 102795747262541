import {
  Drawer,
  Tag,
  Input,
  Button,
  Select,
  Modal,
} from "antd"
import React, { useState } from "react"
import styled from "styled-components"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import RecruitAndEarn from "../../../../container/RecruitAndEarn"
import ConfirmationModal from "../../Common/ConfirmationModal"

const { TextArea } = Input

const { Option } = Select

const { confirm } = Modal

const Wrap = styled.div`
 display: flex;
 justify-content: space-between;
 flex-direction: ${props => props.flex};
 align-items: baseline;

 ${props => props.padding && `
  padding: ${props.padding};
 `}

  ${props => props.gap && `
    gap: ${props.gap};
  `}
`

const InnerWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${props => props.flex};
  align-items: baseline;
`

const Label = styled.label`
  width: -webkit-fill-available;
  max-width: 100px;
`

const Invitee = styled.p`
  font-size: 28px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
`

const User = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px;
`

const EditDrawer = (props) => {
  const [currentReward, setReward] = useState("")
  const [currentStatus, setStatus] = useState(null)
  const [currentReason, setReason] = useState("")

  const {
    isEditDrawerOpen,
    toggleEditDrawer,
    editableData,
    updateEarnings,
  } = props || {}

  const {
    agentName,
    tags,
    milestoneType,
    expandedText,
    reward,
    id,
  } = editableData || {}

  const handleClose = () => {
    clear()
    toggleEditDrawer({
      data: null,
      bool: false,
    })
  }

  const getTag = (tag) => {
    let color = ""
    switch (tag) {
      case "pending":
        color = "#D8A8007D"
        break
      case "joined":
      case "transferred":
        color = "#62AD047D"
        break
      case "declined":
        color = "#EFB0B07D"
        break
      default:
        color = "#62AD047D"
        break
    }

    return (
      <Tag color={color} key={tag} style={{ color: "#303030" }}>
        {tag.toUpperCase()}
      </Tag>
    )
  }

  const showConfirm = () => {
    confirm({
      title: "Please confirm the action.",
      icon: <ExclamationCircleOutlined />,
      content: <ConfirmationModal
        reward={currentReward || reward}
        typeSet="rewarding"
        reason={currentReason}
        type={currentStatus || tags[0]}
        agentName={agentName}
        milestoneType={milestoneType}
      />,
      onOk() {
        handleSubmit()
      },

      onCancel() {
        return false
      },
    })
  }

  const handleSubmit = () => {
    const payload = {
      status: currentStatus || tags[0],
      reward: currentReward || reward,
      reason: currentReason,
      credited: currentStatus === "credited",
    }

    updateEarnings({
      id,
      payload,
    })
    handleClose()
  }

  const clear = () => {
    setReason("")
    setReward("")
    setStatus(null)
  }

  const handleRewardEdit = e => setReward(e.target.value)

  const handleReasonChange = e => setReason(e.target.value)

  const handleStatusChange = val => setStatus(val)

  return (
    <Drawer
      width={537}
      placement="left"
      onClose={handleClose}
      visible={isEditDrawerOpen}
    >
      <Wrap flex="row">
        <Invitee>{agentName}</Invitee>
        {getTag(tags ? tags[0] : "")}
      </Wrap>
      <Wrap flex="column" padding="72px 0px" gap="12px">
        {milestoneType === "deal" ? (
          <InnerWrap flex="row">
            <Label htmlFor="amount">Amount:</Label>
            <Input
              prefix="$"
              name="amount"
              value={currentReward || reward}
              onChange={handleRewardEdit}
            />
          </InnerWrap>
        ) : (
          <InnerWrap flex="row">
            <Label htmlFor="shares">Shares:</Label>
            <Input
              name="shares"
              value={currentReward || reward}
              onChange={handleRewardEdit}
            />
          </InnerWrap>
        )}
        <InnerWrap flex="row">
          <Label htmlFor="shares">For:</Label>
          <User>{expandedText}</User>
        </InnerWrap>
        <InnerWrap flex="row">
          <Label htmlFor="status">Status:</Label>
          <Select
            style={{
              width: 120,
            }}
            onChange={e => handleStatusChange(e)}
            value={currentStatus || tags && tags[0]}
            placeholder="Filter by"
          >
              <>
                <Option value="pending">Pending</Option>
                <Option value="approved">Approve</Option>
                {milestoneType === "deal" && (
                  <Option value="credited">Credit</Option>
                )}
                <Option value="declined">Decline</Option>
              </>
          </Select>
        </InnerWrap>
        <InnerWrap flex="row">
          <Label htmlFor="reason">Reason:</Label>
          <TextArea
            name="reason"
            rows={4}
            placeholder="State reason"
            maxLength={120}
            value={currentReason}
            onChange={handleReasonChange}
            style={{
              position: "relative",
              top: "10px",
            }}
          />
        </InnerWrap>
      </Wrap>
      <Wrap
        gap="10px"
        flex="row"
        style={{
          justifyContent: "flex-end",
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "22px",
        }}
      >
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="primary" onClick={showConfirm}>Save</Button>
      </Wrap>
    </Drawer>
  )
}
export default RecruitAndEarn(EditDrawer)
