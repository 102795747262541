import React from "react"
import styled from "styled-components"
import { Title, Note } from "./common"

const Wrap = styled.div``

const Notes = ({
  text,
  time,
  note,
}) => (
  <Wrap>
    <Title type={text} time={time} />
    {note && (
      <Note>
        {note}
      </Note>
    )}
  </Wrap>
)

export default Notes
