import { call, put, throttle, takeLatest, select } from "redux-saga/effects"
import * as DashboardActions from "./actions"
import { isSuccess } from "services/Utils"
import { toast } from "react-toastify"
import root from "window-or-global"
import * as APIs from "./api"

const baseUrl = process.env.REACT_APP_API_BASE_URL;

function* fetchAgentSearch(action) {
    try {
        const { search } = action.data
        const payload = {
            "text_value": search,
            "sortIsAsc": false,
            "ascending": false,
            "facade": false,
            "from": 0,
            "size": 10,
            "text_search_params": ["email","full_name"],
            "unRegistered": false
        };
       const res = yield call(APIs.searchAgentByNameOrEmailAPI, payload)
        if (isSuccess(res)) {
            const { response } = res.data
            const {
             agents,
            } = response || {}
            yield put(DashboardActions.supplyOptionsAction.call({ op: agents }))
            yield put(DashboardActions.handleAgentSearchAction.success(response))
        }
    } catch(error) {
        yield put(DashboardActions.handleAgentSearchAction.failure(error))
    }
}

function* handleMetricsFetch(action) {
    try {
        const {
         filters,
         deletionID,
         isDelete,
         limit,
        } = action.data || {}
        const payload = {
            subscription_status: "all",
        };
        let curr = yield select(state => state.dashboard.currentAgents)
        let currentLimit = yield select(state => state.dashboard.currentLimit)

        if(isDelete) {
         yield put(DashboardActions.removeFromCurrentAgentsActions.call({
          deletionID,
          currentAgents: curr,
         }))
        }
        let modFilters = filters || ""
        let currIds = curr && curr.filter(key => key.id !== deletionID && key.id).map(key => key.id)
        if(curr && currIds) {
         if(currIds.length > 1) {
          modFilters = `${filters || ""}agent_ids=${currIds.join(",")}&`
         } else if(currIds.length === 1) {
          modFilters = `${filters || ""}agent_ids=${currIds.join("")}&`
         }
        } else {
         modFilters = filters || ""
        }

       const res = yield call(APIs.fetchMetricsAPI, modFilters, limit)
        if (isSuccess(res)) {
            const { response } = res.data
            yield put(DashboardActions.fetchMetricsAction.success(response))
            if(response.metrics.length > 0) {
             yield put(DashboardActions.modifyCurrentLimitAction.call((limit || 10) + 10))
            }
        }
    } catch(error) {
        yield put(DashboardActions.fetchMetricsAction.failure(error))
    }
}





export default function* main() {
    yield takeLatest(DashboardActions.fetchMetricsAction.REQUEST, handleMetricsFetch)
    yield takeLatest(DashboardActions.handleAgentSearchAction.REQUEST, fetchAgentSearch)
}
