/* eslint-disable max-len */
import React, { useEffect } from "react"
import { isEmpty } from "lodash"
import moment from "moment"
import styled from "styled-components"
import {
  Drawer,
  Checkbox,
  DatePicker,
} from "antd"
import Loader from "../../common/UI/Loader"
import container from "../../../container/BrokerDashboard/globalPagesContainer"
import Alert from "./Icons/Alert.svg"
import Assist from "./Icons/Assist.svg"
import Checkmark from "./Icons/Checkmark.svg"

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`
const AddressMeeting = styled.div`
  display flex;
  flex-direction: row;
  gap: 26px;
`
const AddressTimeline = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 20px;
  width: 370px;
  height: 88px;
  background: rgba(59, 96, 228, 0.05);
`
const BigText = styled.h1`
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: #303030;
  margin: 0px;
  ${props => props.tcdetails && `
    font-size: 18px;
    width: 135px;
  `}
`
const SmallText = styled.h2`
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #8C8C8C;
  margin: 0px;
  ${props => props.tcdetails && `
  font-size: 12px;
  `}
`
const Checklist = styled.div`
  margin-top: 20px;
`
const Row = styled.div`
  padding-top: 30px;
  padding-bottom: 60px;
  border-bottom: 1px solid #EFEFEF;
  width: 730px;
  height: 52px;
  display: flex;
  justify-content: space-between;
`
const DateStatusSection = styled.div`
  display: flex;
`
const DateAndStatusDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 3px;
  width: 99px;
  height: 35px;
`
const DatePickDiv = styled.div`
  margin-right: 32px;
`
const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: 104px;
  height: 16px;
`
const StatusIcon = styled.img`

`
const StatusText = styled.h3`
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 16px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  ${props => props.pastdue && `
    color: #E34C4C;
  `}
  ${props => props.tcdone && `
    color: #3B60E4;
  `}  
  ${props => props.completed && `
    color: #58C896;
  `}
  ${props => props.nostatus && `
    color: #8C8C8C;
  `}    
`

const TimelineChecklist = ({
  dealId,
  isOpenTimelineChecklist,
  toggleTimelineChecklist,
  resetDealId,
  fetchDealTimeline,
  fetchDealTimelineResponse,
  updateDealTimeline,
}) => {
  const handleClose = () => {
    toggleTimelineChecklist(false)
    resetDealId()
  }

  const {
    data: timeline,
    isFetching: fetchingTimeline,
  } = fetchDealTimelineResponse || {}

  useEffect(() => {
    if (dealId) {
      fetchDealTimeline({ dealId })
    }
  }, [isOpenTimelineChecklist])

  const disabledDate = current => current < moment().subtract(1, "days")

  const handleTick = (id, date, status) => {
    const payload = {
      checklist_item_id: id,
      status,
      user_added_date: date,
    }
    updateDealTimeline({
      dealId,
      payload,
      callback: () => {
        fetchDealTimeline({
          dealId,
        })
      },
    })
  }

  const handleAddDate = (value, id, status) => {
    const date = moment(value).format("YYYY-MM-DD HH:mm:ss")
    const payload = {
      checklist_item_id: id,
      status,
      user_added_date: date,
    }
    updateDealTimeline({
      dealId,
      payload,
      callback: () => {
        fetchDealTimeline({
          dealId,
        })
      },
    })
  }

  const today = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")

  return (
    <Drawer
      title="Timeline Checklist"
      width={950}
      placement="left"
      visible={isOpenTimelineChecklist === true}
      onClose={handleClose}
    >
      {fetchingTimeline === true ? (
        <Loader />
      ) : (
        <Wrap>
          <AddressMeeting>
            {dealId && !isEmpty(timeline) && (
              <AddressTimeline>
                <BigText>{timeline.deal_info.listing_address}</BigText>
                <SmallText>{`${timeline.deal_info.city}, ${timeline.deal_info.state} ${timeline.deal_info.zipcode}`}</SmallText>
              </AddressTimeline>
            )}
          </AddressMeeting>
          <Checklist>
            {!isEmpty(timeline) && timeline.timeline_checklist.map(item => (
              <Row>
                <Checkbox
                  checked={item.is_completed === 1}
                  onChange={() => handleTick(item.id, today, "tc_completed")}
                  style={{
                    padding: 0,
                    margin: 0,
                    backgroundColor: "#58C896",
                  }}
                >
                  {item.display_text}
                </Checkbox>
                <DateStatusSection>
                  {item.is_editable === 1 && item.is_completed === 0 ? (
                    <DatePickDiv>
                      <DatePicker
                        placeholder="Add Date"
                        size="small"
                        disabledDate={disabledDate}
                        style={{
                          width: 120,
                          height: 32,
                          borderSize: 1,
                          borderColor: "#3B60E4",
                          borderRadius: 36,
                        }}
                        onChange={(_date, dateString) => handleAddDate(dateString, item.id, null)}
                      />
                    </DatePickDiv>
                  ) : null}
                  <DateAndStatusDiv>
                    <Status>
                      {(today > item.suggested_date || today > item.user_added_date) && item.is_completed !== 1 && item.status !== null ? (
                    <>
                      <StatusIcon src={Alert} />
                      <StatusText pastdue>{item.status}</StatusText>
                    </>
                      ) : (
                    <>
                      {item.status === null ? (
                      <>
                        <StatusText nostatus>Suggested Date</StatusText>
                      </>
                      ) : (
                      <>
                        {item.is_completed === 1 && item.status === "TC Completed" ? (
                        <>
                          <StatusIcon src={Assist} />
                          <StatusText tcdone>{item.status}</StatusText>
                        </>
                        ) : (
                        <>
                          <StatusIcon src={Checkmark} />
                          <StatusText completed>Completed</StatusText>
                        </>
                        )}
                      </>
                      )}
                    </>
                      )}
                    </Status>
                    {item.user_added_date !== null ? (
                  <>
                    {item.is_completed === 0 && item.status === "Past Due" ? (
                      <StatusText pastdue>{moment(item.user_added_date).format("MM/DD/YYYY")}</StatusText>
                    ) : (
                    <>
                      {item.is_completed === 1 && item.status === "TC Completed" ? (
                        <StatusText tcdone>{moment(item.user_added_date).format("MM/DD/YYYY")}</StatusText>
                      ) : (
                      <>
                        {item.is_completed === 1 && item.status !== "TC Completed" ? (
                          <StatusText completed>{moment(item.user_added_date).format("MM/DD/YYYY")}</StatusText>
                        ) : (
                          <StatusText nostatus>{moment(item.user_added_date).format("MM/DD/YYYY")}</StatusText>
                        )}
                      </>
                      )}
                    </>
                    )}
                  </>
                    ) : (
                      null
                    )}
                    {item.user_added_date === null && item.suggested_date !== null ? (
                  <>
                    {item.is_completed === 0 && item.status === "Past Due" ? (
                      <StatusText pastdue>{moment(item.suggested_date).format("MM/DD/YYYY")}</StatusText>
                    ) : (
                    <>
                      {item.status !== null && item.is_completed === 1 && item.status === "TC Completed" ? (
                        <StatusText tcdone>{moment(item.suggested_date).format("MM/DD/YYYY")}</StatusText>
                      ) : (
                      <>
                        {item.status !== null && item.is_completed === 1 && item.status !== "TC Completed" ? (
                          <StatusText completed>{moment(item.suggested_date).format("MM/DD/YYYY")}</StatusText>
                        ) : (
                          <StatusText nostatus>{moment(item.suggested_date).format("MM/DD/YYYY")}</StatusText>
                        )}
                      </>
                      )}
                    </>
                    )}
                  </>
                    ) : (
                  <>
                    {item.user_added_date === null && item.suggested_date === null ? (
                      " - "
                    ) : null}
                  </>
                    )}
                  </DateAndStatusDiv>
                </DateStatusSection>
              </Row>
            ))}
          </Checklist>
        </Wrap>
      )}
    </Drawer>
  )
}

export default container(TimelineChecklist)
