import React from "react"
import styled from "styled-components"

const Wrap = styled.button`
  border: none;
  padding: 5px 20px;
  border-radius: 4px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  ${props => props.success && `
    border: 1px solid #198647;
    color: #FFF;
    background: #27ae60;
  `}

  ${props => props.ghost && `
    border: 1px solid #198647;
    color: #888;
    background: transparent;
  `}
  ${props => props.danger && `
    background: #e02020;
    color: #FFF;
  `}
  ${props => props.purple && `
    background: #100063;
    color: #FFF;
  `}
  ${props => props.link && `
    background: transparent;
    color: #100063;
  `}

  ${props => props.rounded && `
    border-radius: 25px;
  `}
  ${props => props.blue && `
    color: #3B60E4;
    border: #3B60E4 1px solid;
    background: transparent;
  `}

  ${props => props.green && `
    color: #60CA4E;
    border: #60CA4E 1px solid;
    background: transparent;
  `}

  ${props => props.noBorder && `
    border: none;
    background: transparent;
  `}


  &:disabled {
    background: grey;
    border: none;
    cursor: not-allowed;
    pointer-events: none;
    color: #ccc;
    border: #ccc;
  }
`

const Button = props => (
  <Wrap {...props} />
)

export default Button
