import React, { PureComponent } from "react"
import styled from "styled-components"
 
import Button from "dumbComponents/common/UI/Button"
import ListingList from "dumbComponents/Listings/components/ListingList"
import TechAssetsContainer from "container/BrokerDashboard/globalPagesContainer"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import {
  Input, InputNumber, Select, Form, Checkbox,
  Divider,
} from "dumbComponents/common/UI/antd"
import { EditOutlined } from "@ant-design/icons"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const HeaderNav = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
`

const ProgressBarContainer = styled.div`
  padding: 30px 0px;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #3B60E4;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LabelText = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
`

const Required = styled.span`
  color: #E34C4C
`

const CheckboxLabelText = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  text-transform: uppercase;
`

const FlexBox = styled.div`
  display: flex;
`

const CheckboxContainer = styled.div`
  padding: 0 20px 0 0;
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
`

const VendorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const VendorBox = styled.div`
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
  width: 47%;
  margin: 10px;
`
const VendorHeader = styled.div`
  border-bottom: 1px solid #E5E5E5;
  display: flex;
  justify-content: space-between;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 22px;
  h1 {
    color: #303030;
    margin: 0 0 10px;
  }
  p {
    margin: 5px 0 0 0;
    font-size: 14px;
  }
`
const VendorContent = styled.div`
  margin: 20px 0px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  color: #303030;
  span {
    color: #8C8C8C;
  }
`
const VendorButton = styled(Button)`

`

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  componentDidMount() {
    // gapi.load("client:auth2", this.fetchGoogleContacts)
    const { getListingCoverSheet, dealToView, getListingCoverSheetResponse } = this.props
    // getListingCoverSheet({
    //   dealId: dealToView || 0,
    //   callAPI: ["select_vendor"],
    // })
  }

  onFinish = (vendorId) => {
    const { updateListingCoverSheet, history, dealToView, updateDealStatus } = this.props
    updateListingCoverSheet({
      payload: {
        vendor_id: vendorId,
        current_listing_step: "select_vendor",
      },
      dealId: dealToView,
      callAPI: [],
      nextRoute: "/broker/tc/add-listings",
      history,
      callback: () => {
        updateDealStatus({
          dealId: dealToView,
          step: "coming_soon",
        })
      },
    })
  }

  setEscrowIdToEdit = (id) => {
    const { setEscrowIdToEdit, history } = this.props
    setEscrowIdToEdit(id)
    history.push("/broker/tc/add-listings/coversheet/escrow-info")
  }

  render() {
    const { getListingCoverSheetResponse, updateListingCoverSheetResponse } = this.props
    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Escrow & TItle Information
        </HeaderNav>
        <ProgressBarContainer>
          <ProgressBar completed />
          <ProgressBar completed />
          <ProgressBar completed />
          <ProgressBar completed />
          <ProgressBar completed />
          <ProgressBar completed />
        </ProgressBarContainer>
        <Container>
          <LeftSideBar>
            <CheckboxContainer>
              <Checkbox>
                <CheckboxLabelText>Cover sheet</CheckboxLabelText>
              </Checkbox>
              <StyledDivider />
              <Checkbox>
                <CheckboxLabelText>Documents</CheckboxLabelText>
              </Checkbox>
              <StyledDivider />
              <Checkbox>
                <CheckboxLabelText>Listing Checklist</CheckboxLabelText>
              </Checkbox>
              <StyledDivider />
              <Checkbox>
                <CheckboxLabelText>Contract Info</CheckboxLabelText>
              </Checkbox>
            </CheckboxContainer>
          </LeftSideBar>
          <RightSideBar>
            <VendorContainer>
              {getListingCoverSheetResponse.data?.selectVendor?.map(dt => (
                <VendorBox>
                  <VendorHeader>
                    <h1>
                      {dt.vendor_type}
                      {dt.draft === "Y" && (
                        <p>
                          In Draft
                        </p>
                      )}
                    </h1>
                    <EditOutlined onClick={() => this.setEscrowIdToEdit(dt.id)} />
                  </VendorHeader>
                  <VendorContent>
                    <p>
                      {dt.vendor_name}
                    </p>
                    <p>
                      {dt.vendor_number}
                    </p>
                    <p>
                      {`${dt.vendor_officer_first_name} ${dt.vendor_officer_last_name}`}
                    </p>
                    <p>
                      {`${dt.vendor_address}, ${dt.vendor_city}, ${dt.vendor_state} ${dt.vendor_zipcode}`}
                    </p>
                    <p>
                      {dt.vendor_email}
                    </p>
                    <p>
                      {dt.vendor_phone}
                    </p>
                    {dt.notes && (
                      <span>
                        Notes:
                        {dt.notes}
                      </span>
                    )}
                  </VendorContent>
                  <VendorButton
                    bsStyle="marineBLueOutlined"
                    padding="12px 8px"
                    width="100%"
                    fontSize="14px"
                    fontWeight="500"
                    fontColor="#303030"
                    disabled={dt.draft === "Y"}
                    isShowLoader={updateListingCoverSheetResponse.isFetching || getListingCoverSheetResponse.isFetching}
                    loaderOption={{ color: "black" }}
                    onClick={() => { this.onFinish(dt.id) }}
                  >
                    Select
                  </VendorButton>
                </VendorBox>
              ))}
            </VendorContainer>
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)
