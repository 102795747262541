import React, { Component } from 'react';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import './Calendar.css';

class Calendar extends Component {
  constructor() {
    super();

    this.state = {
      date: new Date()
    };
  }

  render() {
    const { date } = this.state;
    return (
        <Flatpickr options={this.props.options} key={this.props.options.defaultDate.toString()} onChange={this.props.changed} />    
    );
  }
}

export default Calendar;