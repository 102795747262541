import React from "react"
import Styled, { css } from "styled-components"
import { setInterval, clearInterval } from "window-or-global"

const PANEL_WIDTH = "380px"

const Wrapper = Styled.div`
  position: fixed;
  height: calc(100% - 50px);
  background: #FFF;
  width: ${PANEL_WIDTH};
  right: -${PANEL_WIDTH};
  top: 50px;
  transition-duration: 0.4s;
  border-left: 1px solid #c8c7cc;
  z-index: 100;

  ${props => props.isShow && css`
    right: 0px;
  `}
`

const CloseButton = Styled.div`
  position: absolute;
  top: 60px;
  left: 0px;
  width: 50px;
  height: 50px;
  background: #e74c3c;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.1s;
  transition-delay: 0.2s;
  opacity: 0;
  cursor: pointer;
  border: 1px solid #e74c3c;
  border-right: none;
  color: #fff;


  ${props => props.isShow && css`
    left: -50px;
    opacity: 1;
  `}
`

const Iframe = Styled.iframe`
  width: 100%;
  height: 100%;
`

const LoaderWrap = Styled.div`
  background: #FFF;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`

class MessagesTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isClient: false,
      isLoadingIframe: false,
      isIframeLoaded: false,
    }
  }

  checkIfIframeLoaded = () => {
    try {
      this.iframe = window.document.getElementById("messages_iframe")
      const iFrameDoc = this.iframe.contentDocument || this.iframe.contentWindow.document
      if (iFrameDoc.readyState === "complete") {
        clearInterval(this.taskId)
        this.setState({
          isIframeLoaded: true,
        })
      } else {
        console.log("Loading iframe....")
      }
    } catch (error) {
      console.error(error)
    }
  }

  componentDidMount() {
    this.setState({
      isClient: true,
    })
    this.timeoutThePage()
    console.log("All resources finished loading!")
    console.log("Loading messages iFrame")
    // Add a delay for APIs network APIs
    setTimeout(() => {
      this.handleDOMLoaded()
      clearInterval(this.TIMEOUT)
    }, 400)
  }

  timeoutThePage = () => {
    this.TIMEOUT = setTimeout(() => {
      this.setState({
        isTimeout: true,
      })
    }, 20000);
  }

  handleDOMLoaded = () => {
    let count = 0 
    this.setState({
      isDomLoaded: true,
      isIframeLoaded: true,
    })
    this.taskId = setInterval(() => {
      if (count > 10) {
        clearInterval(this.taskId)
      }
      //this.checkIfIframeLoaded()
      count = count + 1;
    }, 200)
  }

  render() {
    const {
      isClient,
      isDomLoaded,
      isIframeLoaded,
      isTimeout,
    } = this.state
    const {
      show = false,
      toggleMessagesPanel,
      url,
    } = this.props

    // Only Run this on client
    if (!isClient) {
      return null
    }
    console.log("Opening URL => ", url)
    return (
      <Wrapper isShow={show}>
        {isTimeout && (
          <LoaderWrap>
          <h4>Error Loading the messages tab.</h4>
          <h6>Please try again</h6>
        </LoaderWrap>
        )}
        {!isIframeLoaded && !isTimeout && (
          <LoaderWrap>
            <h4>Loading..</h4>
          </LoaderWrap>
        )}
        { isDomLoaded && (
          <React.Fragment>
            <CloseButton onClick={() => { toggleMessagesPanel() }} isShow={show}>
              Close
            </CloseButton>
            <Iframe
              src={url}
              id="messages_iframe"
            />
          </React.Fragment>
        )}
      </Wrapper>
    )
  }
}

export default MessagesTab

// 
// http://communications.radiusagent.com/#/