import React from "react"
import Reminder from "./Events/Reminder"
import AdminNotes from "./Events/AdminNotes"
import Generic from "./Events/Generic"
import Note from "./Events/Note"
import Property from "./Events/Property"
import Requirements from "./Events/Requirements"

const Entity = ({
  entityName,
  ...props
}) => {
  switch (entityName) {
    case "reminders":
      return <Reminder entityName={entityName} {...props} />
    case "adminNotes":
      return <AdminNotes entityName={entityName} {...props} />
    case "notes":
      return <Note entityName={entityName} {...props} />
    case "properties":
      return <Property entityName={entityName} {...props} />
    case "requirement":
      return <Requirements entityName={entityName} {...props} />
    default:
      return <Generic entityName={entityName} {...props} />
  }
}

export default Entity
