import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"
const initState = {
  showJobConfirmation: false,
  currentCroppedType: "",
  deleteMode: false,
  currentImageToBeCropped: null,
  downloadDrawerOpen: false,
  currentRecordings: null,
  deleteAllOccurencesBool: false,
  ...createDeltaReducer("rooms"),
  ...createDeltaReducer("rarFinancingClientList"),
  ...createDeltaReducer("rarFinancingSetting"),
  ...createDeltaReducer("saveRarFinancingSetting"),
  ...createDeltaReducer("updateRarFinancingClientList"),
  ...createDeltaReducer("clientDeleted"),
  ...createDeltaReducer("allJobsByTeamId"),
  ...createDeltaReducer("allJobApplications"),
  ...createDeltaReducer("updatedJobApplications"),
  ...createDeltaReducer("createdJobs"),
  ...createDeltaReducer("updatedJobs"),
  ...createDeltaReducer("deletedJobs"),
  ...createDeltaReducer("uploadTeamImageToS3Response"),
  ...createDeltaReducer("cashbackList"),
  ...createDeltaReducer("cashbackAgentInfo"),
  ...createDeltaReducer("updateCashbackAgentInfoResponse"),
  ...createDeltaReducer("createCashbackAmountCreditsResponse"),
  ...createDeltaReducer("cashbackAgentTimeline"),
  ...createDeltaReducer("sendJobNotificationsResponse"),

  ...createDeltaReducer("getOnboardingCommissionResponse"),
  ...createDeltaReducer("createOnboardingCommissionResponse"),
  ...createDeltaReducer("updateOnboardingCommissionResponse"),
  ...createDeltaReducer("getRecordedContentResponse"),
  ...createDeltaReducer("deleteRecordingsResponse"),
  ...createDeltaReducer("getScheduledRoomsResponse"),
  ...createDeltaReducer("updatingScheduledRoomsResponse"),
  ...createDeltaReducer("deleteScheduledRoomsResponse"),
}

export default function getRoomsReducer(state = initState, action) {
  switch (action.type) {
    case Actions.getRooms.REQUEST:
    case Actions.getRooms.SUCCESS:
    case Actions.getRooms.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getRooms, "rooms"),
      }
    }

    case Actions.createRooms.REQUEST:
    case Actions.createRooms.SUCCESS:
    case Actions.createRooms.FAILURE: {
      return {
        ...state,
      }
    }

    case Actions.updateRoom.REQUEST:
    case Actions.updateRoom.SUCCESS:
    case Actions.updateRoom.FAILURE: {
      return {
        ...state,
      }
    }

    case Actions.endRoom.REQUEST:
    case Actions.endRoom.SUCCESS:
    case Actions.endRoom.FAILURE: {
      return {
        ...state,
      }
    }

    case Actions.getFinancingClientList.REQUEST:
    case Actions.getFinancingClientList.SUCCESS:
    case Actions.getFinancingClientList.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getFinancingClientList, "rarFinancingClientList"),
      }
    }

    case Actions.getFinancingSetting.REQUEST:
    case Actions.getFinancingSetting.SUCCESS:
    case Actions.getFinancingSetting.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getFinancingSetting, "rarFinancingSetting"),
      }
    }

    case Actions.saveFinancingSetting.REQUEST:
    case Actions.saveFinancingSetting.SUCCESS:
    case Actions.saveFinancingSetting.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.saveFinancingSetting, "saveRarFinancingSetting"),
      }
    }

    case Actions.updateFinancingClientList.REQUEST:
    case Actions.updateFinancingClientList.SUCCESS:
    case Actions.updateFinancingClientList.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateFinancingClientList, "updateRarFinancingClientList"),
      }
    }

    case Actions.deleteClient.REQUEST:
    case Actions.deleteClient.SUCCESS:
    case Actions.deleteClient.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.deleteClient, "clientDeleted"),
      }
    }

    case Actions.getAllJobsByTeamId.REQUEST:
    case Actions.getAllJobsByTeamId.SUCCESS:
    case Actions.getAllJobsByTeamId.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getAllJobsByTeamId, "allJobsByTeamId"),
      }
    }

    case Actions.getJobApplications.REQUEST:
    case Actions.getJobApplications.SUCCESS:
    case Actions.getJobApplications.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getJobApplications, "allJobApplications"),
      }
    }

    case Actions.updateJobApplications.REQUEST:
    case Actions.updateJobApplications.SUCCESS:
    case Actions.updateJobApplications.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateJobApplications, "updatedJobApplications"),
      }
    }

    case Actions.createJobs.REQUEST:
    case Actions.createJobs.SUCCESS:
    case Actions.createJobs.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createJobs, "createdJobs"),
      }
    }

    case Actions.updateJobs.REQUEST:
    case Actions.updateJobs.SUCCESS:
    case Actions.updateJobs.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateJobs, "updatedJobs"),
      }
    }

    case Actions.deleteJobs.REQUEST:
    case Actions.deleteJobs.SUCCESS:
    case Actions.deleteJobs.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.deleteJobs, "deletedJobs"),
      }
    }

    case Actions.showJobConfirmationAction.type: {
      return {
        ...state,
        showJobConfirmation: action.data,
      }
    }

    case Actions.uploadImagesToS3Action.REQUEST:
    case Actions.uploadImagesToS3Action.FAILURE:
    case Actions.uploadImagesToS3Action.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.uploadImagesToS3Action,
          "uploadTeamImageToS3Response"
        ),
      }
    }

    case Actions.getRecordedRoomsContentAction.REQUEST:
    case Actions.getRecordedRoomsContentAction.FAILURE:
    case Actions.getRecordedRoomsContentAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.getRecordedRoomsContentAction,
          "getRecordedContentResponse"
        ),
      }
    }

    //deleteRecordingsAction
    case Actions.deleteRecordingsAction.REQUEST:
    case Actions.deleteRecordingsAction.FAILURE:
    case Actions.deleteRecordingsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.deleteRecordingsAction,
          "deleteRecordingsResponse"
        ),
      }
    }

    case Actions.getScheduledRoomsAction.REQUEST:
    case Actions.getScheduledRoomsAction.FAILURE:
    case Actions.getScheduledRoomsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.getScheduledRoomsAction,
          "getScheduledRoomsResponse"
        ),
      }
    }

    case Actions.setImageBlocksAction.type: {
      const {
        image,
        type,
      } = action.data
      return {
        ...state,
        [type]: image,
      }
    }

    case Actions.setCurrentImageToBeCroppedAction.type: {
      const {
        image,
        type,
      } = action.data
      return {
        ...state,
        currentImageToBeCropped: image,
        currentCroppedType: type,
      }
    }

    case Actions.toggleDeleteModeAction.type: {
      return {
        ...state,
        deleteMode: action.data,
      }
    }

    case Actions.getCashbackList.REQUEST:
    case Actions.getCashbackList.SUCCESS:
    case Actions.getCashbackList.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getCashbackList, "cashbackList"),
      }
    }

    case Actions.getCashbackAgentInfo.REQUEST:
    case Actions.getCashbackAgentInfo.SUCCESS:
    case Actions.getCashbackAgentInfo.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getCashbackAgentInfo, "cashbackAgentInfo"),
      }
    }

    case Actions.updateCashbackAgentInfo.REQUEST:
    case Actions.updateCashbackAgentInfo.SUCCESS:
    case Actions.updateCashbackAgentInfo.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateCashbackAgentInfo, "updateCashbackAgentInfoResponse"),
      }
    }

    case Actions.createCashbackAmountCredits.REQUEST:
    case Actions.createCashbackAmountCredits.SUCCESS:
    case Actions.createCashbackAmountCredits.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createCashbackAmountCredits, "createCashbackAmountCreditsResponse"),
      }
    }

    case Actions.getCashbackAgentTimeline.REQUEST:
    case Actions.getCashbackAgentTimeline.SUCCESS:
    case Actions.getCashbackAgentTimeline.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getCashbackAgentTimeline, "cashbackAgentTimeline"),
      }
    }

    case Actions.sendJobNotifications.REQUEST:
    case Actions.sendJobNotifications.SUCCESS:
    case Actions.sendJobNotifications.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendJobNotifications, "sendJobNotificationsResponse"),
      }
    }

    case Actions.getOnboardingCommission.REQUEST:
    case Actions.getOnboardingCommission.FAILURE:
    case Actions.getOnboardingCommission.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.getOnboardingCommission,
          "getOnboardingCommissionResponse"
        ),
      }
    }

    case Actions.createOnboardingCommission.REQUEST:
    case Actions.createOnboardingCommission.FAILURE:
    case Actions.createOnboardingCommission.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.createOnboardingCommission,
          "createOnboardingCommissionResponse"
        ),
      }
    }

    case Actions.updateOnboardingCommission.REQUEST:
    case Actions.updateOnboardingCommission.FAILURE:
    case Actions.updateOnboardingCommission.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.updateOnboardingCommission,
          "updateOnboardingCommissionResponse"
        ),
      }
    }

    case Actions.updateScheduledRoomsAction.REQUEST:
    case Actions.updateScheduledRoomsAction.FAILURE:
    case Actions.updateScheduledRoomsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.updateScheduledRoomsAction,
          "updatingScheduledRoomsResponse"
        ),
      }
    }

    case Actions.deleteScheduledRoomsAction.REQUEST:
    case Actions.deleteScheduledRoomsAction.FAILURE:
    case Actions.deleteScheduledRoomsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.deleteScheduledRoomsAction,
          "deleteScheduledRoomsResponse"
        ),
      }
    }

    case Actions.toggleDownloadDrawerAction.type: {
      const {
        bool,
        data,
      } = action.data || {}
      return {
        ...state,
        downloadDrawerOpen: bool,
        currentRecordings: data,
      }
    }

    case Actions.toggleScheduledRoomDrawerAction.type: {
      const {
        bool,
        data,
      } = action.data || {}

      return {
        ...state,
        scheduledRoomDrawerOpen: bool,
        currentViewedScheduledRoom: data,
      }
    }

    case Actions.deleteAllOccurencesCheckAction.type: {
      const {
        bool
      } = action.data || {}

      return {
        ...state,
        deleteAllOccurencesBool: bool,
      }
    }

    default: {
      return state
    }
  }
}
