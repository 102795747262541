import React from "react"
import styled from "styled-components"

const SectionWrap = styled.div`
  transition-duration: 1000ms;
  background: #FFF

  ${props => props.isHideSection && `
    display: none;
  `}
  header {
    padding: 3px 20px;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    > p {
      flex: 1;
      margin: 0px;
      padding: 0px;
      font-size: 12px;
    }

  i {
    width: 20px;
    position: relative;
    height: 20px;
    font-size: 20px;
    padding: 0px;
    line-height: 1;
    transition-duration: 0.3s;
    ${props => !props.isShow && `
      transform: rotate(-90deg);
    `}
  }
}

> div {
  display: flex;
  transition-duration: 0.2s;
  flex-direction: column;
  max-height: 200px;
  overflow: scroll;
  

  ${props => props.isHide && `
    opacity: 0;
    padding: 10px;
    z-index: -10;
  `}

  ${props => props.isForceHide && `
    max-height: 0px;
    overflow: hidden;
    background: #CCC;
  `}
}
`

export const Section = ({
  color,
  headerTitle,
  nameOfSection,
  handleArrowClick,
  isHide,
  children,
  isForceHide,
  isHideSection,
}) => (
  <SectionWrap
    isHide={isHide}
    isForceHide={isForceHide}
    isHideSection={isHideSection}
  >
    <header
      style={{ background: `${color}` }}
      onClick={() => { handleArrowClick({ [nameOfSection]: !isForceHide }) }}
    >
      <p>{headerTitle}</p>
      <i
        className="fa fa-angle-down"
        aria-hidden="true"
      >
      </i>
    </header>
    <div>
      {children}
    </div>
  </SectionWrap>
)

export default Section
