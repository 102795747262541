import React, { PureComponent } from "react"
import styled from "styled-components"
import moment from "moment"
import { withRouter } from "react-router-dom"
import Container from "container/EventRooms"
import {
  Button, Dropdown, Menu, Space,
  Collapse,
  Divider,
  Drawer,
  Tabs,
  Modal,
  Form,
  Input,
  Select,
  Spin,
  notification,
  Pagination,
  InputNumber,
} from "antd"
import { LoadingOutlined, SmileOutlined } from "@ant-design/icons"
import CashbackStatus from "./components/CashbackStatus"
import Timeline from "./components/Timeline"
import SearchControls from "./components/SearchControls"
import { isNumber } from "lodash-es"

const { Option } = Select

const { Panel } = Collapse

const { TabPane } = Tabs

const Wrap = styled.div`
  padding: 40px;
  .collapse {
    margin: 5px 0 5px;
  }
  .panel {
    background: #FAFAFA;
  }
  .ant-collapse-header {
    padding: 25px !important;
  }
`

const PageHeader = styled.div`
  padding: 20px 0;
`

const SubmitButton = styled(Button)`
  margin-left: 20px;
`

const DrawerWrap = styled.div`
  padding: 0px 25px;
`

const ListComponentWrap = styled.div`
  width: 100%;
`

const ListHeader = styled.div`
  background: #E5E5E5;
  border: 1px solid #E6E6E6;
  display: flex;
  padding: 12px 10px;
`

const ColumnHeader = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #0B0A0A;
  margin-left: 30px;
  text-align: left;
  ${props => props.wd && `
    width: ${props.wd}
  `}
`

const Row = styled.div`
  display: flex;
`
const PanelRow = styled.div`
  display: flex;
  padding: 15px 40px 15px 0px;
`

const RowData = styled(ColumnHeader)`
  ${props => props.color && `
    color: ${props.color}
  `}
`

const PanelData = styled(ColumnHeader)`
  flex: 1;
  ${props => props.ta && `
    text-align: ${props.ta}
  `}
  color: #6D7E8F;
  .action_button {
    width: 120px;
  }
`

const PanelContainer = styled.div``

const PanelContent = styled.div`
  .divider {
    margin: 0px;
  }
`

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const ModalHeader = styled.h2`
  margin: 20px;
  font-size: 28px;
  text-decoration: underline;
  font-weight: 400;
`

const FilterContainer = styled.div`
  padding: 30px 10px;
`

const TeamName = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #8A8A8A;
  margin-top: 2px;
  margin-left: -10px;
`

// const FilterContainer = styled.div``

const ListComponent = ({ cashbackList, rowNameClicked, getAgentInfo }) => (
  <ListComponentWrap>
    <Row>
      <RowData wd="50%">
        <span
          style={{
            color: "#303030",
            textDecoration: "underline",
            fontWeight: 400,
            marginLeft: "-10px",
          }}
          onClick={(e) => {
            e.stopPropagation()
            rowNameClicked(true, "1")
            getAgentInfo(cashbackList)
          }}
        >
          {`${cashbackList.firstname || ""} ${cashbackList.lastname || ""}`}
        </span>
        {cashbackList.team_name && (
          <TeamName>
            TEAM :
            {" "}
            {cashbackList.team_name}
          </TeamName>
        )}
      </RowData>
      <RowData wd="20%" color="#6D7E8F">
        {`$${cashbackList.approved_amount.toLocaleString()}`}
      </RowData>
      <RowData wd="16%" color="#6D7E8F">
        {`$${cashbackList.credited_amount.toLocaleString()}`}
      </RowData>
      <RowData wd="16%" color="#6D7E8F">
        {`$${cashbackList.pending_amount.toLocaleString()}`}
      </RowData>
    </Row>
  </ListComponentWrap>
)

class Cashback extends PureComponent {
  state = { clickedCashbackListObject: {}, dateValue: "", agentDetails: {}, defaultDrawerActiveKey: "1" }

  componentDidMount() {
    const { getCashbackList } = this.props
    getCashbackList({
      skip: 0,
      limit: 10,
    })
  }

  rowNameClicked = (value, key) => {
    this.setState({
      showDrawer: value,
      defaultDrawerActiveKey: key,
    })
  }

  setDate = (value) => {
    this.setState({
      dateValue: value,
    })
  }

  setAgentDetails = (value) => {
    this.setState({
      agentDetails: value,
    })
  }

  editOpen = (value, index, status, cashbackListObject) => {
    if (cashbackListObject) {
      this.setState({
        clickedCashbackListObject: cashbackListObject,
      })
    }
    this.setState({
      showEdit: value,
      selectedRow: index,
      editStatus: status,
    })
  }

  creditOpen = (value) => {
    this.setState({
      showCredit: value,
    })
  }

  getAgentInfo = (cashbackListObject) => {
    const { getCashbackAgentInfo } = this.props
    getCashbackAgentInfo({
      agentId: cashbackListObject.agent_id,
    })
    this.setState({
      clickedCashbackListObject: cashbackListObject,
    })
  }

  openNotificationWithIcon = ({ message, description }) => {
    notification.open({
      message,
      description,
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    })
  }

  cashbackAgentInfoFromId = (cashbackListObject) => {
    const { cashbackAgentInfo } = this.props
    const agentId = cashbackListObject.agent_id
    if (cashbackAgentInfo.data && cashbackAgentInfo.data[agentId] && cashbackAgentInfo.data[agentId].cashback.length) {
      const pendingData = cashbackAgentInfo.data[agentId].cashback.filter(cb => cb.status === "pending")
      return pendingData
    }
    return null
  }

  cashbackAgentStatsFromId = (cashbackListObject) => {
    const { cashbackAgentInfo } = this.props
    const agentId = cashbackListObject.agent_id
    if (cashbackAgentInfo.data && cashbackAgentInfo.data[agentId] && cashbackAgentInfo.data[agentId].stats) {
      const statsData = cashbackAgentInfo.data[agentId].stats
      return statsData
    }
    return null
  }

  handleDataSave = (data) => {
    const { clickedCashbackListObject, selectedRow, editStatus } = this.state
    const { updateCashbackAgentInfo } = this.props
    this.setState({
      dateSaveLoading: true,
    })
    updateCashbackAgentInfo({
      callback: () => {
        this.setState({
          dateSaveLoading: false,
        })
        this.getAgentInfo(clickedCashbackListObject)
        this.editOpen(false)
        this.openNotificationWithIcon({
          message: "Updated",
          description: `Status has been Updated to ${editStatus}!`,
        })
      },
      payload: {
        ...data,
        amount: parseInt(data.amount, 10),
        status: editStatus,
      },
      id: this.cashbackAgentInfoFromId(clickedCashbackListObject) && this.cashbackAgentInfoFromId(clickedCashbackListObject).length && this.cashbackAgentInfoFromId(clickedCashbackListObject)[selectedRow] && this.cashbackAgentInfoFromId(clickedCashbackListObject)[selectedRow].id,
    })
  }

  handleCreateCredits = (data) => {
    const { createCashbackAmountCredits, getCashbackAgentTimeline, getCashbackList } = this.props
    const { clickedCashbackListObject, selectedRow } = this.state
    this.setState({
      createCreditLoading: true,
    })
    createCashbackAmountCredits({
      payload: {
        amount: parseInt(data.amount, 10),
        status: "credited",
        agent_id: clickedCashbackListObject.agent_id,
      },
      callback: () => {
        this.setState({
          createCreditLoading: false,
        })
        this.getAgentInfo(clickedCashbackListObject)
        getCashbackAgentTimeline({
          skip: 0,
          limit: 5000,
          agentId: clickedCashbackListObject.agent_id,
        })
        getCashbackList({
          skip: 0,
          limit: 10,
        })
        this.creditOpen(false)
        this.openNotificationWithIcon({
          message: "Credited",
          description: `$${parseInt(data.amount, 10)} has been credited`,
        })
      },
    })
  }

  fetchFilteredAgents = (agent, event, page) => {
    const { getCashbackList } = this.props
    const body = {
      skip: (page - 1) * 10,
      limit: 10,
    }
    if (agent && agent.id) {
      body.agentId = agent.id
    }
    if (event && event.unix()) {
      body.createdDate = event.unix()
    }
    getCashbackList(body)
  }

  isMakeATransferDisabled = (clickedCashbackListObject) => {
    const obj = this.cashbackAgentStatsFromId(clickedCashbackListObject)
    return obj && obj.balance_cashback <= 0
  }

  render() {
    const { cashbackList, cashbackAgentInfo } = this.props
    const {
      showDrawer, showEdit, clickedCashbackListObject, selectedRow,
      showCredit, editStatus,
      dateValue, agentDetails,
      createCreditLoading, dateSaveLoading,
      defaultDrawerActiveKey,
    } = this.state
    return (
      <Wrap>
        <PageHeader>
          <h1 style={{ marginLeft: "-10px" }}>Cashback Dashboard</h1>
        </PageHeader>
        <FilterContainer>
          <SearchControls dateValue={dateValue} setDate={this.setDate} agentDetails={agentDetails} setAgentDetails={this.setAgentDetails} />
        </FilterContainer>
        <ListHeader>
          <ColumnHeader wd="50%">
            Agent Name / Team Lead
          </ColumnHeader>
          <ColumnHeader wd="20%">
            Cashback Approved
          </ColumnHeader>
          <ColumnHeader wd="16%">
            Transferred
          </ColumnHeader>
          <ColumnHeader wd="16%">
            Pending
          </ColumnHeader>
        </ListHeader>
        {cashbackList.isFetching ? (
          <Spin style={{ width: "100%" }} indicator={(<LoadingOutlined style={{ fontSize: 44, margin: "60px auto" }} spin />)} />
        ) : (
          <>
            {cashbackList.data && cashbackList.data.cashback.map(cashbackListObject => (
              <Collapse accordion className="collapse" onChange={() => { this.getAgentInfo(cashbackListObject) }}>
                <Panel className="panel" header={(<ListComponent cashbackList={cashbackListObject} getAgentInfo={this.getAgentInfo} rowNameClicked={this.rowNameClicked} />)}>
                  <PanelContainer>
                    <DrawerHeader>
                      <h3>
                        Pending approvals
                      </h3>
                      <Button
                        type="link"
                        size="medium"
                        onClick={() => {
                          this.getAgentInfo(cashbackListObject)
                          this.rowNameClicked(true, "2")
                        }}
                      >
                        See Timeline
                      </Button>
                    </DrawerHeader>
                    {(cashbackAgentInfo.isFetching && (clickedCashbackListObject.agent_id === cashbackListObject.agent_id)) ? (
                      <Spin style={{ width: "100%" }} indicator={(<LoadingOutlined style={{ fontSize: 44, margin: "30px auto" }} spin />)} />
                    ) : (
                      <>
                        {this.cashbackAgentInfoFromId(cashbackListObject) && this.cashbackAgentInfoFromId(cashbackListObject).length ? this.cashbackAgentInfoFromId(cashbackListObject).map((cashbackInfo, index) => (
                          <PanelContent>
                            <PanelRow>
                              <PanelData>
                                {moment(cashbackInfo.created_at * 1000).format("MMM DD, YYYY hh:mm A")}
                              </PanelData>
                              <PanelData>
                                {cashbackInfo.text}
                              </PanelData>
                              <PanelData ta="end">
                                <Button className="action_button" danger type="link" size="medium" onClick={() => this.editOpen(true, index, "declined", cashbackListObject)}>
                                  Decline
                                </Button>
                                <Button className="action_button" type="primary" ghost size="medium" onClick={() => this.editOpen(true, index, "approved", cashbackListObject)}>
                                  Approve
                                </Button>
                              </PanelData>
                            </PanelRow>
                            {this.cashbackAgentInfoFromId(cashbackListObject).length > (index + 1) && (
                              <Divider className="divider" />
                            )}

                          </PanelContent>
                        )) : (
                          <p style={{ textAlign: "center" }}>No Pending Approvals</p>
                        )}
                      </>
                    )}

                  </PanelContainer>

                </Panel>
              </Collapse>
            ))}
          </>
        )}
        <Pagination
          style={{
            textAlign: "right",
            margin: "25px 0",
          }}
          total={cashbackList.data ? cashbackList.data.total : 0}
          defaultPageSize={10}
          defaultCurrent={1}
          onChange={(page) => {
            this.fetchFilteredAgents(agentDetails, dateValue, page)
          }}
        />
        {showDrawer && (
          <Drawer placement="right" onClose={() => { this.rowNameClicked(false, "1") }} visible={showDrawer} width="60%">
            <DrawerWrap>
              <DrawerHeader>
                <h1>
                  {`${clickedCashbackListObject.firstname} ${clickedCashbackListObject.lastname}`}
                </h1>
                <Button type="danger" disabled={this.isMakeATransferDisabled(clickedCashbackListObject)} size="medium" onClick={() => { this.creditOpen(true) }}>
                  Make a Transfer
                </Button>
              </DrawerHeader>
              <Tabs defaultActiveKey={defaultDrawerActiveKey} onChange={() => { }}>
                <TabPane tab="Cashback Stats" key="1">
                  <CashbackStatus
                    isFetching={cashbackAgentInfo.isFetching} editOpen={this.editOpen}
                    cashbackAgentStatsFromId={this.cashbackAgentStatsFromId(clickedCashbackListObject)}
                    cashbackAgentInfoFromId={this.cashbackAgentInfoFromId(clickedCashbackListObject)}
                    clickedCashbackListObject={clickedCashbackListObject}
                  />
                </TabPane>
                <TabPane tab="Timeline" key="2">
                  <Timeline agentId={clickedCashbackListObject.agent_id} />
                </TabPane>
              </Tabs>
            </DrawerWrap>
          </Drawer>
        )}
        {this.cashbackAgentInfoFromId(clickedCashbackListObject) && this.cashbackAgentInfoFromId(clickedCashbackListObject)[selectedRow] && showEdit && (
          <Modal title={<ModalHeader>{`${clickedCashbackListObject.firstname} ${clickedCashbackListObject.lastname}`}</ModalHeader>} visible={showEdit} zIndex="9999999" width="700px" footer={null} onOk={() => { }} onCancel={() => { this.editOpen(false) }}>
            <Form
              layout="vertical"
              name="basic"
              size="large"
              initialValues={{
                amount: this.cashbackAgentInfoFromId(clickedCashbackListObject) && this.cashbackAgentInfoFromId(clickedCashbackListObject).length && this.cashbackAgentInfoFromId(clickedCashbackListObject)[selectedRow] && this.cashbackAgentInfoFromId(clickedCashbackListObject)[selectedRow].amount,
              }}
              onFinish={(data) => { this.handleDataSave(data) }}
              onFinishFailed={() => { }}
            >
              <Form.Item
                label="Cashback ($)"
                name="amount"
                rules={[{ required: true }]}
              >
                <Input disabled={editStatus === "declined"} />
              </Form.Item>
              <div style={{ display: "flex" }}>
                <h4>For: </h4>
                <p style={{ marginLeft: "10px" }}>{this.cashbackAgentInfoFromId(clickedCashbackListObject)[selectedRow].text}</p>
              </div>
              {/* <Form.Item name="status" label="Status" rules={[{ required: true }]}>
                <Input disabled /> */}
              {/* <Select onChange={() => {}} defaultValue="approved">
                  <Option value="approved">Approved</Option>
                  <Option value="pending">Pending</Option>
                  <Option value="credited">Credited</Option>
                  <Option value="decline">Decline</Option>
                </Select> */}
              {/* </Form.Item> */}

              <Form.Item
                name="reason"
                label="Notes"
              >
                <Input.TextArea showCount maxLength={100} />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="default" onClick={() => this.editOpen(false)}>
                  Cancel
                </Button>
                <SubmitButton loading={dateSaveLoading} className="submit-button" type="primary" htmlType="submit">
                  Submit
                </SubmitButton>
              </Form.Item>
            </Form>
          </Modal>
        )}
        {showCredit && (
          <Modal title={<ModalHeader>{`${clickedCashbackListObject.firstname} ${clickedCashbackListObject.lastname}`}</ModalHeader>} visible={showCredit} zIndex="9999999" width="700px" footer={null} onOk={() => { }} onCancel={() => { this.creditOpen(false) }}>
            <Form
              layout="vertical"
              name="basic"
              size="large"
              initialValues={{}}
              onFinish={(data) => { this.handleCreateCredits(data) }}
              onFinishFailed={() => { }}
            >
              <Form.Item
                label="Cashback ($)"
                name="amount"
                rules={[
                  { required: true },
                  { validator: (_, value) => {
                    const obj = this.cashbackAgentStatsFromId(clickedCashbackListObject)
                    if (obj && value > obj.balance_cashback) {
                      return Promise.reject("Value should not be more than cashback balance");
                    }
                    if (isNaN(value)) {
                      return Promise.reject("Value should be number");
                    }
                    return Promise.resolve();
                  }}
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="reason"
                label="Notes"
              >
                <Input.TextArea showCount maxLength={100} />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="default" onClick={() => this.creditOpen(false)}>
                  Cancel
                </Button>
                <SubmitButton loading={createCreditLoading} className="submit-button" type="primary" htmlType="submit">
                  Submit
                </SubmitButton>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </Wrap>
    )
  }
}

export default Container(withRouter(Cashback))
