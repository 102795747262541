import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import colors from "../../../lib/colors"
import { reduxForm, Field, FieldArray } from "redux-form"
import Select from "react-select"
import TimeFields from "./TimeFields"
import _ from 'lodash';

const Form = styled.form`
  margin-top: 55px;
  p {
   .required {
    color: red;
   }
  }
  .disclaimer {
    font-size: 13px;
    margin-top: -10px;  
  }
`

class DateAndTimeEventsForm extends React.Component{

 render() {
  const {
   handleSubmit,
   pristine,
   reset,
   submitting,
  } = this.props

  return (
    <Form onSubmit={handleSubmit}>
      <p>Day and Time (Pacific Standard Time)
       <span className="required"> *</span>
      </p>
      <p className="disclaimer"><i>Enter time in PST only</i></p>
      <FieldArray name="dayTime" component={TimeFields}/>
    </Form>
  )
 }
}

DateAndTimeEventsForm = reduxForm({
  form: 'addDayTimeForEventsForm',
  destroyOnUnmount: false,
})(DateAndTimeEventsForm)

export default DateAndTimeEventsForm
