import React, { Component } from 'react';
import moment from 'moment';
import bugsnag from 'bugsnag-js';
import Button from "./UI/Button/Button";
import {updateNote} from "./API/NotesApi";
const bugsnagkey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(bugsnagkey);

class NotesListView extends Component {

    state = {
        editingText: "",
        isEditing: false,
    };

    enableEditMode = () => {
        const { note: { note } } = this.props;
        this.setState({
            isEditing: true,
            editingText: note,
        });
    };

    saveCurrentNote = async() => {

        try {
            this.setState({
                isEditing: false
            });

            const {editingText} = this.state;
            const {note: {id}, updateNoteObject} = this.props;

            let body = {
                note: editingText,
            };

            await updateNote(body, id).then(respJson => {
                updateNoteObject(id, respJson.response);
            });
        }
        catch(e) {
            alert(JSON.stringify(e.error.message));
            bugsnagClient.notify(e);
        }
    };


    cancelCurrentNote = () => {
        this.setState({
            isEditing: false,
            editingText: "",
        });
    };

    handleChange = e => {
        this.setState({ editingText : e.target.value });
    };

    render() {
        const { note, addedFor } = this.props;

        const { isEditing, editingText } = this.state;

        return (
            <li>
                {note
                    ? (
                        <span>
                          <div className={"note-time"}>
                            <p>
                              {moment(note.created_at * 1000).format("MMMM Do")} {"  "}
                                {"  (Added by " + note.added_by + addedFor + ") "} {note.internal_note === 1 && <span>(internal)</span>}
                            </p>
                          </div>
                        {isEditing
                            ? (
                                <textarea
                                    name="body"
                                    onChange={this.handleChange}
                                    value={editingText}
                                    rows="8"
                                    className={"form-control"}
                                />
                            )
                            : (
                                <React.Fragment>
                                    <div><p>{note.note}</p></div>
                                </React.Fragment>
                        )}
                         </span>
                    )
                    : null
                }
                {!this.state.isEditing ?
                    <Button  className = "btn-primary" style={{width: '150px'}} onClick = {() => {this.enableEditMode()}}>Edit</Button> :
                    <React.Fragment>
                        <Button className = "med-btn-1" onClick = {() => {this.saveCurrentNote()}} >Save</Button>&nbsp;&nbsp;<Button  className = "med-btn-5" onClick = {() => {this.cancelCurrentNote()}}>Cancel</Button>
                    </React.Fragment>
                }
            </li>
        )
    }
}
export default NotesListView;
