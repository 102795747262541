import axios from "axios"

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const IMAGE_API = process.env.IMAGE_API

export const getBrokerInvitesAPI = () => axios
  .get(`${baseUrl}/v1.0/broker-invites`)

export const getBrokerEarningsAPI = () => axios
  .get(`${baseUrl}/v1.0/broker-invite/earnings`)

export const toggleBrokerInviteAPI = (id, payload) => axios
  .put(`${baseUrl}/v1.0/broker-invites/${id}`, payload)

export const updateBrokerEarningsAPI = (id, payload) => axios
  .put(`${baseUrl}/v1.0/broker-invite/earnings/${id}`, payload)

export const fetchAgentInviteAnalyticsAPI = agentId => axios
  .get(`${baseUrl}/v1.0/broker-invites-analytics?agentId=${agentId}`)

export const createNewInviteAPI = payload => axios
  .post(`${baseUrl}/v1.0/create-broker-invite`, payload)

export const deleteInviteAPI = id => axios
  .delete(`${baseUrl}/v1.0/delete-invite/${id}`)

export const fetchInviteByIdAPI = id => axios
  .get(`${baseUrl}/v1.0/fetch-invite/${id}`)

export const editInviteAPI = (id, payload) => axios
  .post(`${baseUrl}/v1.0/edit-invite/${id}`, payload)

export const getAllReferralsAPI = queryParams => axios
  .get(`${baseUrl}/v1.0/broker-invites?${queryParams}`)

export const getInviteeStatusTimelineAPI = agentId => axios
  .get(`${baseUrl}/v1.0/broker-invite-timelines?agentId=${agentId}&type=receiver`)

export const getSenderTimelinesAPI = agentId => axios
  .get(`${baseUrl}/v1.0/broker-invite-timelines?agentId=${agentId}&type=sender`)

export const updateInviteeStatusAPI = (id, payload) => axios
  .put(`${baseUrl}/v1.0/broker-invites/${id}`, payload)

export const getAllEarningsAPI = queryParams => axios
  .get(`${baseUrl}/v1.0/brokerage-invite-earnings?${queryParams}`)

export const getAllAgentDetailsAPI = queryParams => axios
  .get(`${baseUrl}/v1.0/broker-referrers?${queryParams}`)

export const getAgentSpecificStatsAPI = id => axios
  .get(`${baseUrl}/v1.0/broker-invite-sender-summary?agentId=${id}`)

export const updateEarningsForAgentAPI = (id, payload) => axios
  .put(`${baseUrl}/v1.0/brokerage-invite-earnings/${id}`, payload)

export const getTotalStatsRAEAPI = query => axios
  .get(`${baseUrl}/v1.0/broker-invites-overview${query && `?${query}`}`)

export const downloadCSV = query => axios
  .get(`${baseUrl}/v1.0/broker-invite-earnings-download`)

export const downloadReferralCSV = query => axios
  .get(`${baseUrl}/v1.0/broker-invites-download`)

