import React from "react"
import styled from "styled-components"

const LeadRowWrap = styled.div`
  > p {
    margin: 0px;
    font-size: 14px;
    color: #888;
    > span {
      color: #222;
      font-weight: 600;
      margin: 0px 5px;
    }
  }
`

export const LeadRow = ({
  active_referral_count,
  closed_referral_percentage,
  claimed_referral_count,
  closed_referral_count,
}) => (
  <LeadRowWrap>
    <p>
      Active:
      <span>
        {active_referral_count}
      </span>
    </p>
    <p>
      Claimed:
      <span>
        {claimed_referral_count}
      </span>
    </p>
    <p>
      Conversion:
      <span>
        {Number(closed_referral_percentage || 0).toFixed(2)}%
      </span>
    </p>
    <p>
      Closed:
      <span>
        {closed_referral_count}
      </span>
    </p>
  </LeadRowWrap>
)

