import React from "react"
import styled from "styled-components"
import Loader from "dumbComponents/common/UI/Loader"
import { CALL_STATUS } from "./Shared"

const Wrap = styled.div`
  position: relative;
  background: #EEE;
  border-radius: 10px;
  box-shadow: 0 4px 12px -4px #767676;
  display: flex;
  overflow: hidden;
  border: 1px solid #CCC;
  z-index: 10;
`

const Status = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border-right: 1px solid #CCC;

  ${props => props.isHide && `
    display: none;
  `}
  &:last-child{
    border-right: none;
  }
  h2, p {
    margin: 0px;
    padding: 0px;
    /* color: #FFF; */
  }
  flex: 1;
  h2 {
    font-size: 20px;
  }
  p {
    font-size: 10px;
    font-weight: bold;
  }
`

const LoaderWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FloatingIndicator = ({
  isFullMode,
  toggleFullMode,
  isConnected,
  callStatus,
}) => (
  <Wrap onClick={() => { toggleFullMode(!isFullMode) }}>
    {isConnected ? (
      <React.Fragment>
        {Object.keys(CALL_STATUS).map((key) => {
          const hasNoValue = callStatus && callStatus[key] && !callStatus[key]
          return (
            <Status
              key={key}
              isHide={hasNoValue || CALL_STATUS[key].isHideFromFloater}
              style={{
                color: CALL_STATUS[key].color,
                backgroundColor: `${CALL_STATUS[key].color}26`,
              }}
            >
              <h2>{(callStatus[key] && callStatus[key].length) || "0"}</h2>
              <p>{CALL_STATUS[key].value}</p>
            </Status>
          )
        })}
      </React.Fragment>
    ) : (
      <LoaderWrap>
        {/* <Loader top="0" bottom="0" size="30" /> */}
        <h5>Initializing...</h5>
      </LoaderWrap>
    )}
  </Wrap>
)

export default FloatingIndicator
