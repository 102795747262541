/* eslint-disable import/no-unresolved */
import React, { useState } from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import globalContainer from "container/BrokerDashboard/globalPagesContainer"
import sidebarContainer from "container/BrokerDashboard/sidebarContainer"
import { Collapse, Select } from "antd"
import { ShimmerBox } from "shared/styles/animation"
import { get } from "lodash"
import {
  TitleWrap,
  LicenseTransDateWrap,
  SidebarHeading,
  CustomInput,
} from "../../common"

const { Panel } = Collapse

const Wrap = styled.div``

const SidePanelSettings = ({
  getTCAgentAssignedResponse,
  saveCardResponse,
  getTCListResponse,
  updateAgentOptedTC,
  ...props
}) => {
  const agentId = get(props, "match.params.agentId", "")
  const fetchingCardDetails = get(saveCardResponse, "isFetching")
  const last_four_digits = get(saveCardResponse, "data.payment_sources[0].last_four_digits", "")
  const brand = get(saveCardResponse, "data.payment_sources[0].brand", "")
  const cardDefaultValue = last_four_digits ? `${brand} | **** **** **** ${last_four_digits}` : ""
  const TCListfinal = getTCListResponse.data && getTCListResponse.data.length > 0 && getTCListResponse.data.map(x => ({ label: `${x.tc_firstname} ${x.tc_lastname}`, value: x.id }))

  const updateTCAgentOptInStatus = (item) => {
    if (item !== undefined) {
      if (item.tc_firstname === null && item.tc_lastname === null) { return { label: "TC not assigned", value: 0 } }
      return { label: `${item.tc_firstname} ${item.tc_lastname}`, value: item.assigned_tc_id }
    }
    return { label: "TC not assigned" }
  }

  const assignTCtoAgent = async (tcId) => {
    const agentIdInt = parseInt(agentId, 10)
    updateAgentOptedTC({
      agentId: agentIdInt,
      tcId,
    })
  }
  return (
    <Wrap>
      <TitleWrap>
        <Collapse expandIconPosition="end" ghost>
          <Panel header="Settings">
            <LicenseTransDateWrap>
              <SidebarHeading>
                TC Assigned
              </SidebarHeading>
              <Select
                options={TCListfinal}
                defaultValue={getTCAgentAssignedResponse.data ? updateTCAgentOptInStatus(getTCAgentAssignedResponse.data[0]) : { label: "TC Not Assinged", value: 0 }}
                onChange={(selectedItem) => {
                  assignTCtoAgent(selectedItem)
                }}
              />
            </LicenseTransDateWrap>
            <LicenseTransDateWrap>
              <SidebarHeading>
                Payment Details
              </SidebarHeading>
              {fetchingCardDetails ? <ShimmerBox w="290px" h="39px" /> : (
                <CustomInput
                  style={{
                    height: "39px",
                  }}
                  value={cardDefaultValue}
                />
              )}
            </LicenseTransDateWrap>
          </Panel>
        </Collapse>
      </TitleWrap>
    </Wrap>
  )
}

export default withRouter(sidebarContainer(globalContainer(SidePanelSettings)))
