/* eslint-disable max-len */
import React, { useLayoutEffect, useEffect } from "react"
import styled, { consolidateStreamedStyles } from "styled-components"
import { get, isEmpty, isObject } from "lodash"
import Loader from "dumbComponents/common/UI/Loader"
import moment from "moment"
import { Card } from "antd"
import { withRouter } from "react-router-dom"
import { USCurrencyFormat } from "shared/currencyUtils.js"
import { notify } from "react-notify-toast"
import Button from "dumbComponents/common/UI/Button"
import CircularImage from "dumbComponents/common/UI/CircularImage"
import sidebarContainer from "container/BrokerDashboard/sidebarContainer"
import container from "container/BrokerDashboard/editAgentContainer"
import globalContainer from "container/BrokerDashboard/globalPagesContainer"
import Select from "react-select"
import { ToastContainer, toast } from "react-toastify"
import { JSON } from "window-or-global"
import { data } from "jquery"
import colors from "../../../lib/colors"
import PlanDetails from "../Comp/PlanInfo"
import { changeUserAgentTypeAPI, getTCListAPI } from "../../../container/BrokerDashboard/api"
import EditAgentInfo from "./EditAgentInfo"

const baseUrl = process.env.REACT_APP_API_BASE_URL
const token = process.env.REACT_APP_SECRET_ID

const Wrap = styled.div`
  width: 330px;
  border-right: 1px solid ${colors.borderColor};
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: aliceblue;
  .ant-card-head {
    background: lightgrey;
  }
`
const DateDiv = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    font-weight: 100;
  }
`

const AgentInfo = styled.div`
  margin-top: 40px;
  text-align: center;
  h2 {
    font-size: 20px;
    font-weight: 600;
    margin: 0px;
    display: inline-block;
  }
  h3, h5 {
    margin: 0px;
    font-weight: 300;
  }
  h5 {
    color: #999;
    font-size: 16px;
  }
`

const StarWrap = styled.span`
  margin-left: 10px;
  cursor: pointer;
  i.fa.fa-star {
    color:  #FFD700;
  }
`
const AgentDetails = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TagInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
`
const TagName = styled.h4`
  font-size: 14px;
  font-weight: 300;
  margin-right: 2px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px; 
`
const TagDetails = styled.h5`
  font-size: 5px;
  margin: 0px;
`

const Label = styled.h3`
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 0px 0;
  text-align: left;
  ${props => props.ica && `
    margin-top: 20px;
  `}
`

const SearchWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  i {
    font-size: 26px;
    padding: 10px;
  }
  input {
    flex: 1;
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 6px;
    border: 1px solid #ccc;
  }
`

const SearchResultWrap = styled.div`
  position: absolute;
  bottom: -80px;
  width: 80%;
  left: 0px;
  background: #fff;
  border: 1px solid #ccc;


`

const SearchItem = styled.div`

`

const AgentStates = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  span {
    padding: 5px;
    background: #CCC;
    margin: 3px;
    border-radius: 3px;
    font-size: 12px;
  }
`
const AgentTypeDropdownTop = styled.div`
  display: flex;
  align-items: center;
  text-align:left;
  > p {
    flex: 1;
    text-align: left !important ;
    padding-right: 20px;
  }
  > div {
    width: 300px;
  }
  div[class*="-placeholder"] {
    color: #333 !important;
  }
  margin-top:0.5cm;

`

const AgentTypeDropdownBottom = styled.div`
  display: flex;
  align-items: center;
  text-align:left;
  > p {
    flex: 1;
    text-align: left !important ;
    padding-right: 20px;
  }
  > div {
    width: 300px;
  }
  margin-top:0.3cm;
`

const CardWrap = styled.div`
  padding: 10px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #ccc;
  margin-top: 10px;
  > p {
    padding: 0px;
    margin: 0px;
  }
`

const AGENT_TYPE = [
  { label: "Individual Agent", value: "1" },
  { label: "Team Member", value: "2" },
  { label: "Team Lead", value: "3" },
  { label: "Accelerator Agent", value: "4" },
  { label: "Internal", value: "5" },
  { label: "Co Team Lead", value: "6" },
]

const PAGE_SIZE = 30

class SidePanel extends React.PureComponent {
  state = {
    teams: [],
    agentValue: 0,
  }

  async componentDidMount() {
    const {
      getAgentDetails,
      match,
      getAgentStates,
      getAgentCardDetails,
      getAgentTypes,
      userAgentType,
      changeUserAgentType,
      getRARTeamList,
      getTCList,
      getAgentBrokerageInfo,
      getTCAgentOpted,
    } = this.props

    const agentId = get(match, "params.agentId")
    getAgentDetails({
      agentId,
      isFetching: true,
    })
    getAgentStates({
      agentId,
    })
    getAgentCardDetails({
      agentId,
    })
    userAgentType({
      agentId,
    })
    changeUserAgentType({
      agentId,
    })
    getAgentBrokerageInfo({ agentId: parseInt(agentId, 10) })
    getAgentTypes({})
    getRARTeamList({ skip: 0, limit: PAGE_SIZE })
    const selectedAgentTeamInfo = await this.getTeamOfAgent(agentId)

    this.setState({
      selectedAgentTeamInfo: get(selectedAgentTeamInfo, "response[0]"),
    })
    getTCList()
    getTCAgentOpted({
      agentId,
    })
  }

  setTeamOrTeamLead = async (agent_id, is_admin_or_not, team_id) => {
    const body = {
      members: [
        {
          agent_id: Number(agent_id),
          is_admin: Number(is_admin_or_not),
          brokerage_team_id: Number(team_id),
        },
      ],
    }

    try {
      const res = await fetch(`${baseUrl}/v1.0/assign-brokerage-team`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "internal-id": token,
          "X-User-Agent": "agent-php",
        },
        body: JSON.stringify(body),
      })
      const json = await res.json()
      if (res.status !== 200) {
        const errorMessage = get(json, "response.error", "Oop! Something went wrong!")
        notify.hide()
        notify.show(errorMessage, "error")
        return
      }
      notify.hide()
      notify.show("Team Assigned Successfully", "success")
      return
    } catch (e) {
      console.log(e)
      notify.hide()
      notify.show("Something Went Wrong, while assigning team", "error")
    }
  }

  deleteTeamData = async (agentId, teamId) => {
    try {
      const res = await fetch(`${baseUrl}/v1.0/brokerage-team/${teamId}/member`, {
        method: "DELETE",
        body: JSON.stringify({
          agent_id: agentId,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "internal-id": token,
          "X-User-Agent": "agent-php",
        },
      })
      if (res.status != 200) {
        notify.hide()
        notify.show("Something went wrong! Please try again!", "error")
        return
      }
      //notify.hide()
      //notify.show("Agent Removed from", "success")
      return
    } catch (error) {
      console.log(error)
      notify.hide()
      notify.show("Something went wrong! Please try again!", "error")
    }
  }

  getTeamOfAgent = async (agentId) => {
    try {
      const res = await fetch(`${baseUrl}/v1.0/brokerage-agent-team?agentId=${agentId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "internal-id": token,
          "X-User-Agent": "agent-php",
        },
      })
      const json = await res.json()
      return json
    } catch (e) {
      console.log(e)
      notify.hide()
      notify.show("Something Went Wrong.", "error")
    }
  }

  handleSeachType = (event) => {
    const { value } = event.target
    const { fetchAgentSearch } = this.props
    fetchAgentSearch({ search: value })
  }

  handleAgentTypeChange = async (agentType) => {
    const { match } = this.props
    const agentId = get(match, "params.agentId")
    const { value: selectedTeamValue } = agentType
    this.setState({
      isRoleChanged: true,
    })

    // Get team info for all cases
    const selectedAgentTeamInfo = await this.getTeamOfAgent(agentId)
    const teamId = get(selectedAgentTeamInfo, "response[0].team_id")
    this.setState({
      selectedAgentTeamInfo: get(selectedAgentTeamInfo, "response[0]"),
    })
    if (selectedTeamValue == "1" || selectedTeamValue == "4") {
      if (teamId) {
        await this.deleteTeamData(agentId, teamId)
      }
    }
    await changeUserAgentTypeAPI(agentId, selectedTeamValue)

    if (selectedTeamValue == "2" || selectedTeamValue == "3") {
      if (teamId) {
        this.setTeamOrTeamLead(agentId, selectedTeamValue == "2" ? 0 : 1, teamId)
      }
    }
    this.setState({
      agentValue: selectedTeamValue,
    })
    notify.hide()
    notify.show("Agent Type Changed", "success")
  }

  getIsAdmin = (role = undefined, defualtValue) => {
    if (role === undefined) {
      return 0
    }
    if (role === 3) {
      return 1
    }
    if (defualtValue && defualtValue.value == "3") {
      return 1
    }
    return 0
  }

  createDefaultValue = (agentType, role) => {
    if (role === "role") {
      const selectedRole = AGENT_TYPE.find(x => x.label === agentType)
      if (agentType == "Team Lead" || agentType == "Team Member") {
        if (selectedRole) {
          return selectedRole
        }
      }
      return selectedRole
    }
    if (role === "team" && isObject(agentType)) {
      return { label: agentType.team_name, value: agentType.team_id }
    }
  }

  createDefaultAgentValue = (agentType, role) => {
    if (role === "role") {
      const selectedRole = AGENT_TYPE.find(x => x.label === agentType)
      if (agentType == "Team Lead" || agentType == "Team Member") {
        if (selectedRole) {
          return selectedRole
        }
      }
      return selectedRole
    }
    if (role === "team" && isObject(agentType)) {
      return { label: agentType.team_name, value: agentType.team_id }
    }
  }

  onToggleStarred = (userData, preferences) => {
    if (userData && userData.id && preferences && preferences.data) {
      const { updateUserSettings } = this.props
      updateUserSettings({
        payload: {
          starred_for_lmp: preferences.data.starred_for_lmp === 0 ? 1 : 0,
        },
        agentId: userData.id,
      })
    } else {
      notify.show("Something went wrong, unable to fetch starred status", "error")
    }
  }

  render() {
    const {
      agentDetailsResponse,
      currentPlan,
      history,
      match,
      agentStates,
      addons,
      addonsResponse,
      saveCardResponse,
      assistPlans,
      getAgentTypesResponse,
      getUserAgentTypeResponse,
      rarTeamListResponse,
      getTCListResponse,
      preferences,
      updateAgentOptedTC,
      getTCAgentAssignedResponse,
      getOrientationInfoResponse,
      agentBrokerageInfoResponse,
    } = this.props

    const {
      agentValue,
      selectedAgentTeamInfo,
      isRoleChanged,
      selectedTeam,
      selectedRole,
      selectedAgent,
    } = this.state
    this.state.selectedAgent = {}

    const { data: agentStatesData } = agentStates || {}
    const agentId = get(match, "params.agentId")
    const { isFetching, data: userData = {} } = agentDetailsResponse
    const { payment_sources } = saveCardResponse.data || {}
    const { data: types } = getUserAgentTypeResponse || {}
    const { result } = types || {}
    const { type } = result || {}
    const { data: teams = [] } = rarTeamListResponse || {}
    const {
      data: orientationData = {},
    } = getOrientationInfoResponse || {}
    const {
      data: agentBrokerageInfo,
      isFetching: isFetchingAgentBrokerageInfo,
    } = agentBrokerageInfoResponse || {}
    const parsedList = teams && teams.length > 0 && teams.map(x => ({ label: x.name, value: x.id }))

    const assignTCtoAgent = async (agentId, tcId) => {
      const agentIdInt = parseInt(agentId)
      updateAgentOptedTC({
        agentId: agentIdInt,
        tcId,
      })
    }

    const updateTCAgentOptInStatus = (item) => {
      if (item !== undefined) {
        if (item.tc_firstname === null && item.tc_lastname === null) { return { label: "TC not assigned", value: 0 } }
        return { label: `${item.tc_firstname} ${item.tc_lastname}`, value: item.assigned_tc_id }
      }
      return { label: "TC not assigned" }
    }

    // Default Values
    const defaultRole = this.createDefaultValue(type, "role")
    const defaultAgent = this.createDefaultAgentValue(type, "role")
    const defaultTeam = this.createDefaultValue(selectedAgentTeamInfo, "team")
    const is_admin = this.getIsAdmin(agentValue, defaultRole)
    console.log(userData)
    const TCListfinal = getTCListResponse.data && getTCListResponse.data.length > 0 && getTCListResponse.data.map(x => ({ label: `${x.tc_firstname} ${x.tc_lastname}`, value: x.id }))

    return (
      <Wrap>
        {(isFetching && isEmpty(userData)) ? (
          <React.Fragment>
            <h1>Loading..</h1>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {userData && (
              <AgentInfo>
                <CircularImage
                  name="A"
                  image={userData.agent_image}
                  size={100}
                  style={{
                    margin: "0px auto",
                  }}
                />
                <h2>{`${userData.firstname || ""} ${userData.lastname || ""}`}</h2>
                <StarWrap onClick={() => { this.onToggleStarred(userData, preferences) }}>
                  <i className={preferences && preferences.data && preferences.data.starred_for_lmp ? "fa fa-star fa-lg" : "fa fa-star-o fa-lg"}></i>
                </StarWrap>
                <br />
                <AgentDetails>
                  <TagInfo>
                    <TagName> Primary Email: </TagName>
                    <TagDetails>{userData.email}</TagDetails>
                  </TagInfo>
                  <TagInfo>
                    <TagName> Preferred Email: </TagName>
                    <TagDetails>{userData.preferred_email}</TagDetails>
                  </TagInfo>
                  <TagInfo>
                    <TagName>Phone Number: </TagName>
                    <TagDetails>{userData.phone}</TagDetails>
                  </TagInfo>
                  <TagInfo>
                    <TagName>Real Estate License Number: </TagName>
                    <TagDetails>{orientationData.real_estate_license_number}</TagDetails>
                  </TagInfo>
                </AgentDetails>
                <p>
                  Agent Opt In :
                  {" "}
                  {preferences.data && preferences.data.is_using_radius_transaction_coordination ? "Yes" : "No"}
                </p>
              </AgentInfo>
            )}
            {agentStatesData && (
              <React.Fragment>
                <TagName>Agent States: </TagName>
                <AgentStates>
                  {agentStatesData.map(
                    x => (
                      <span key={x.id}>
                        {x.name}
                      </span>
                    )
                  )}
                </AgentStates>

                <div style={{ textAlign: "right" }}>
                  <Button ghost onClick={() => { history.push(`/broker/dashboard/${agentId}/edit-agent-info`) }}>
                    Edit
                  </Button>
                  
                  <Label>TC Assigned</Label>
                  <AgentTypeDropdownTop>
                    <Select
                      width={250}
                      style={{ zIndex: 100, margin: "0px 20px" }}
                      options={TCListfinal}
                      defaultValue={getTCAgentAssignedResponse.data ? updateTCAgentOptInStatus(getTCAgentAssignedResponse.data[0]) : { label: "TC Not Assinged", value: 0 }}
                      onChange={(selectedItem) => {
                        assignTCtoAgent(agentId, selectedItem.value)
                        this.setState.selectedAgent = selectedItem
                      }}
                    />
                  </AgentTypeDropdownTop>

                  <AgentTypeDropdownTop>
                    <Select
                      width={250}
                      style={{ zIndex: 100, margin: "0px 20px" }}
                      options={AGENT_TYPE}
                      placeholder={defaultRole && defaultRole.label}
                      value={selectedRole || defaultRole}
                      onChange={(selectedItem) => {
                        this.setState({
                          selectedRole: selectedItem,
                        })
                        this.handleAgentTypeChange(selectedItem)
                      }}
                    />
                  </AgentTypeDropdownTop>
                  {(
                    (agentValue === "2" || agentValue === "3") && parsedList
                    || type === "Team Member"
                    || type === "Team Lead"
                  ) && (
                    <AgentTypeDropdownBottom
                      style={{ display: (agentValue == "1" || agentValue == "4") ? "none" : "flex" }}
                    >
                      <Select
                        width={250}
                        style={{ zIndex: 100, margin: "0px 20px" }}
                        options={parsedList}
                        placeholder="Team Not Assigned"
                        value={selectedTeam || defaultTeam}
                        onChange={(selectedItem) => {
                          this.setState({
                            selectedTeam: selectedItem,
                          })
                          this.setTeamOrTeamLead(
                            agentId,
                            get(selectedRole || defaultRole, "value") == "3",
                            selectedItem.value
                          )
                        }}
                      />
                    </AgentTypeDropdownBottom>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {payment_sources && payment_sources.length > 0 && (
          <React.Fragment>
            {payment_sources.map(card => (
              <CardWrap>
                <p>
                  {card.brand}
                  {" "}
                  |
                  {" "}
                  {`•••• •••• •••• ${card.last_four_digits}`}
                </p>
              </CardWrap>
            ))}
          </React.Fragment>
        )}
        <PlanDetails
          currentPlan={currentPlan}
          addons={addonsResponse.data}
          assistPlans={assistPlans}
        />
        {isFetchingAgentBrokerageInfo === true ? <Loader /> : (
        <>
          {(agentBrokerageInfo?.display_banner === 1 || agentBrokerageInfo?.display_banner === true) && (
            <>
              <Label ica>ICA Details</Label>
              <Card title="ICA Details" style={{ width: 270, background: "lightcyan" }}>
                <DateDiv>
                  <h3>
                    Cap Reset Date:
                  </h3>
                  {" "}
                  <h4>
                    {moment(agentBrokerageInfo?.cap_reset_date).format("MM-DD-YYYY")}
                  </h4>
                </DateDiv>
                <DateDiv>
                  <h3>
                    ICA Signed Date:
                  </h3>
                  {" "}
                  <h4>
                    {agentBrokerageInfo?.ica_signed_date ? moment.unix(agentBrokerageInfo?.ica_signed_date).format("MM-DD-YYYY") : "N/A"}
                  </h4>
                </DateDiv>
                <DateDiv>
                  <h3>
                    License Transferred Date
                  </h3>
                  {" "}
                  <h4>
                    {agentBrokerageInfo?.license_transferred_date ? moment.unix(agentBrokerageInfo?.license_transferred_date).format("MM-DD-YYYY") : "N/A"}
                  </h4>
                </DateDiv>
                <DateDiv>
                  <h3>
                    Brokerage fee paid by agent in current cycle:
                  </h3>
                  {" "}
                  <h4>
                    {USCurrencyFormat(agentBrokerageInfo?.brokerage_fee_paid_current_cycle) || "N/A"}
                  </h4>
                  {" "}
                  <h3>
                    Of Cap Amount:
                  </h3>
                  {" "}
                  <h4>
                    {`$${agentBrokerageInfo?.cap}` || "N/A"}
                  </h4>
                </DateDiv>
              </Card>
            </>
          )}
        </>
        )}
      </Wrap>
    )
  }
}

export default globalContainer(container(sidebarContainer(withRouter(SidePanel))))
