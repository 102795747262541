import React from "react"
import moment from "moment"
import {
  ChatCardWrap,
  ChatCardUpperRow,
  ChatCardProfileImage,
  AIResponse,
  TimeAndDate,
  AIBorder,
  ChatMessageAI,
  Reported,
  AgentMessage,
  ContentArea,
  SoulMessage,
  ContentWrap,
} from "./chatCardStyles"

const AI_ICON = "https://s3.amazonaws.com/cdn.agentdesks.com/images/SOUL_AI/MiniAIIcon.png"
const RADIUS_USER = "https://s3.amazonaws.com/cdn.agentdesks.com/images/SOUL_AI/RadiusUser.png"

const ChatCard = ({
  userType,
  feedbackType,
  message,
  since = "8:50 AM - Today",
  name,
}) => (
  <ChatCardWrap
    applyBorder={userType === "ai"}
  >
    <ChatCardUpperRow>
      <ChatCardProfileImage
        fill={userType === "radius_team"}
      >
        <img src={userType === "ai" ? AI_ICON : RADIUS_USER} alt="userimage" />
      </ChatCardProfileImage>
      {userType === "ai" && (
        <AIResponse>AI Response</AIResponse>
      )}
      {userType === "human" && (
        <AIResponse>{name}</AIResponse>
      )}
      {userType === "radius_team" && (
        <AIResponse>Radius Team</AIResponse>
      )}
      <TimeAndDate>
        <p>{since && moment(since * 1000).fromNow() || "Date "}</p>
      </TimeAndDate>
    </ChatCardUpperRow>
    <ContentArea
      applyBorder={userType === "ai"}
    >
      {userType === "ai" && feedbackType !== 0 && (
        <AIBorder bgColor={feedbackType === -1 ? "#F87171" : "#15A75B"} />
      )}
      <ContentWrap>
        {userType === "ai" && (
          <ChatMessageAI>
            <p>{message}</p>
          </ChatMessageAI>
        )}
        {userType === "human" && (
          <AgentMessage>
            <p>{`"${message}"`}</p>
          </AgentMessage>
        )}
        {userType === "radius_team" && (
          <SoulMessage>
            <p>{message}</p>
          </SoulMessage>
        )}
        {userType === "ai" && feedbackType !== 0 && (
          <Reported reportColor={feedbackType === -1 ? "#F87171" : "#15A75B"}>
            {feedbackType === -1 ? (
              <>
                <img src="https://s3.amazonaws.com/cdn.agentdesks.com/images/SOUL_AI/thumbsDown.png" alt="barely" />
                <p>Reported Bad</p>
              </>
            ) : (
              <>
                <img src="https://s3.amazonaws.com/cdn.agentdesks.com/images/SOUL_AI/thumbsUp.png" alt="barely" />
                <p>Reported Good</p>
              </>
            )}
          </Reported>
        )}
      </ContentWrap>
    </ContentArea>
  </ChatCardWrap>
)

export default ChatCard
