import React from "react";
import styled from "styled-components";

let Input = styled.input`
  outline: none;
  background-color: white;
  font: inherit;
  padding: 6px 10px;
  width: ${props => props.width ? props.width : "100%"};
  box-sizing: border-box;
  border: 0.5px solid #d4d4d4;
  border-radius: 2px;
  height: ${props => props.height ? props.height : "40"}px;
  box-shadow: none;
  overflow: visible;
  margin: 20px;
  color: inherit;
  cursor: text;
  &:focus,
  &:active {
    box-shadow: none;
    border: solid 0.5px #11adf3;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${props => props.placeholderColor} !important;
    font-weight: 500;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${props => props.placeholderColor} !important;
    font-weight: 500;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${props => props.placeholderColor} !important;
    font-weight: 500;
  }
`;

const textBox = props => {
  if (props.withoutBorder) {
    Input = styled(Input)`
      border: none;
    `;
  }
  return (
    <Input
      innerRef={props.reference}
      onKeyPress={props.onKeyPress}
      className={props.className}
      readOnly={props.readOnly}
      onBlur={props.onBlur}
      width={props.width}
      height={props.height}
      {...props}
    />
  );
};

export default textBox;
