import React, { Component } from "react"
import styled from "styled-components"
import container from "container/Agent"
import DashboardContainer from "container/Dashboards"

const Wrap = styled.div`
  height: 50px;
  width: 100%;
  margin-bottom: 10px;

  ul {
   margin: 0 !important;
   padding: 10px 10px 10px 0px !important;
   display: flex;
  }
`
const Cross = styled.span`
  margin-left: 10px;
  font-size: 11px;
  cursor: pointer;
`

const Tag = styled.div`
  width: max-content;
  height: 26px;
  border-radius: 3px;
  background-color: #e6e6e6;
  color: black;
  text-align: left;
  padding: 5px;
  margin-right: 10px;
`

class CurrentAgentsSearched extends Component {
 componentDidMount() {
  //
 }

 render() {
  const {
   currentAgents,
   fetchMetrics,
  } = this.props

  return (
    <Wrap>
     <ul>
      {currentAgents && currentAgents.map(key => (
       <Tag>
         {`${key.firstname} ${key.lastname}`}
         <Cross onClick={() => fetchMetrics({
          isDelete: true,
          deletionID: key.id,
          limit: 10,
         })}>
          X
         </Cross>
       </Tag>
      ))}
     </ul>
    </Wrap>
  )
 }
}

export default DashboardContainer(CurrentAgentsSearched)
