import React from "react"
import { RADIUS_ASSIST_LOGO_BLACK } from "./../ImagesData"

const getBackground = (type) => {
  switch (type) {
    case "green":
      return { backgroundImage: "linear-gradient(250deg, #0ac4ba 101%, #2bda8e -2%)" }
    default:
      return { backgroundColor: "#f0f0f0" }
  }
}

const Header = ({ type }) => (
  <table
    className={`class-${type}`}
    style={{
      ...getBackground(type),
      border: "none",
      width: "100%",
    }}
  >
    <tbody>
      <tr>
        <td
          style={{
            textAlign: "center",
          }}
        >
          <img
            src={RADIUS_ASSIST_LOGO_BLACK}
            alt="Radius Agent Header"
            style={{
              height: 40,
              width: "auto",
              margin: "40px auto",
            }}
          />
        </td>
      </tr>
    </tbody>
  </table>
)

export default Header
