import React, { Component } from "react"
import styled from "styled-components"
import { reduxForm, Field } from "redux-form"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import RTFEditorIO from "dumbComponents/Academy/Common/EditorRTF"

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  .title {
   font-family: "SF Pro Display", sans-serif;
   font-size: 14px;
   font-weight: bold;
   color: #24292e;
   .required {
    color: red;
   }
  }
  .sub {
   font-size: 12px;
   color: #586069;
  }
  .res, .link {
   margin-top: 34px;
  }
  .help {
   margin-top: 17px
  }
`

const LineBreak = styled.div`
  height: 100px;
  width: 100%;
`

const Row = styled.div`
  height: 400px;
  width: 108%;
`

class EventRTF extends React.Component {
 componentDidMount() {
  //
 }

  render() {
   const {
    rtfContent,
    registrationInfo,
    venueLinkInfo,
    aboutEvent,
    feedbackPost,
   } = this.props
   return(
    <Wrap>
     <Row>
      <p className="title">
       About this event
       <span className="required"> *</span>
      </p>
      <p className="sub">Enter About the event to show a description about the event</p>
      <RTFEditorIO
        customMinHeight={200}
        customMaxHeight={349}
        customMarginBottom={56}
        contentType="aboutEvent"
        rtfContent={rtfContent && rtfContent.description || aboutEvent || "" }
      />
     </Row>
     <Row
      className="res"
     >
      <p className="title">
       Registration Info
        <span className="required"> *</span>
      </p>
      <RTFEditorIO
        customMinHeight={200}
        customMaxHeight={349}
        customMarginBottom={56}
        contentType="registrationInfo"
        rtfContent={rtfContent && rtfContent.registration_info || registrationInfo || ""}
      />
     </Row>
     <Row
      className="res"
     >
      <p className="title">
       Feedback Post
        <span className="required"> *</span>
      </p>
      <RTFEditorIO
        customMinHeight={200}
        customMaxHeight={349}
        customMarginBottom={56}
        contentType="feedbackPost"
        rtfContent={rtfContent && rtfContent.feedback_post_text || feedbackPost || ""}
      />
     </Row>
    </Wrap>
   )
  }
}

export default Container(EventRTF)
