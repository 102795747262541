import React, { useEffect } from "react"
import styled from "styled-components"
import EventRooms from "container/EventRooms"
import {
  Header,
  Table,
  DownloadDrawer,
} from "./Components"

const Wrap = styled.div`
  padding-top: 80px;
  padding-left: 50px;
`

const ScheduledRooms = ({
  getScheduledRooms,
  getScheduledRoomsResponse,
  toggleScheduledRoomDrawer,
  scheduledRoomDrawerOpen,
  currentViewedScheduledRoom,
  updateScheduledRooms,
  deleteScheduledRooms,
  updatingScheduledRoomsResponse,
}) => {
  useEffect(() => {
    getScheduledRooms()
  }, [])

  const {
    data: scheduledRooms,
    isFetching: fetchingRooms,
  } = getScheduledRoomsResponse || {}

  const {
    isFetching: isUpdating,
  } = updatingScheduledRoomsResponse || {}

  return (
    <Wrap>
      <Header />
      <Table
        recordings={scheduledRooms}
        fetchingData={fetchingRooms}
        toggleDownloadDrawer={toggleScheduledRoomDrawer}
      />
      {scheduledRoomDrawerOpen && (
        <DownloadDrawer
          toggleDownloadDrawer={toggleScheduledRoomDrawer}
          downloadDrawerOpen={scheduledRoomDrawerOpen}
          recordings={currentViewedScheduledRoom}
          updateScheduledRooms={updateScheduledRooms}
          deleteScheduledRooms={deleteScheduledRooms}
          isUpdating={isUpdating}
        />
      )}
    </Wrap>
  )
}

export default EventRooms(ScheduledRooms)
