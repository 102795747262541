import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as AgentComparisonActions from "./actions"

function mapStateToProps(state) {
  const { agentComparison } = state
  return {
    ...agentComparison,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      getAgentsForComparison: AgentComparisonActions.getAgentsForComparisonAction.request,
      assignAgent: AgentComparisonActions.assignAgentAction.request,
      liveTransfer: AgentComparisonActions.liveTransferAction.request,
      searchAgent: AgentComparisonActions.searchAgentAction.request,
      removeFromSuggestions: AgentComparisonActions.removeFromSuggestionsAction.request,
      toggleLMP: AgentComparisonActions.toggleLMPAction.request,
      reloadProspects: AgentComparisonActions.reloadProspectsAction.request,
      inviteAgent: AgentComparisonActions.inviteAgentAction.request,
      getLMPStatus: AgentComparisonActions.getLMPStatusAction.request,
      toggleStar: AgentComparisonActions.toggleStarAction.request,
      setFilterKeys: AgentComparisonActions.setFilterKeysAction.call,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
