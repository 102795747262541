import React from "react"
import styled from "styled-components"
import { get } from "lodash"
import OfflineImg from "images/v-offline.gif"
import Dialer from "./Dialer"
import SearchDialer from "./SearchResult"
import SearchResult from "./SearchResult"
import OtherOption from "./OtherOption"

const Wrap = styled.div`
  background: #1A0C36;
  z-index: 100;
  padding: 40px 20px;
  display: flex;

  ${props => props.isOffline && `
    justify-content: center;
    align-items: center;
    background: #34495D;
  `}
`

const SearchWrap = styled.div`
  flex: 3;
  position: relative;
  > input {
    padding: 16px;
    font-size: 18px;
    border: 1px solid #888;
    border-radius: 40px;
    width: 100%;
    outline: none;
  } 
`

const MarkedNumberWrap = styled.div`
  color: #fff;
  padding: 16px 0px;
`

const DialerWrap = styled.div`
  flex: 3;
  margin-left: 20px;
`

const OfflineWrap = styled.div`
  text-align: center;
  img {
    max-height: 200px;
    margin: 0px auto;
  }
`

class CallHeader extends React.PureComponent {
  state = {}

  componentDidMount() {
    // const {  } = this.props
  }

  render() {
    // const {  } = this.state
    const { 
      // user props
      userStatus,

      searchText,
      onSearchType,
      searchResultClients,
      setClientDetailsFromSearch,
      isShowSearch,
      // dialer props
      dialedNumber,
      onDialerType,
      dialComponent,
      callTimer,
      isOnCall,
      isRinging,
      startCaller,
      allowAutoCallerMode,
      refreshQueue,
      upcomingCallsUrl,
      handleDisconnectConfirm,
      isShowTransferButton,
      IVRInput,
      handleIVRChange,
      handleIVRKeyPress,
      addNewLeads,
      unsavedReferrals,
      conferenceCall,
      isAutoCalls,
      maskedNumber,

      // Message
      resetNewMessagesCount,
      messagesCount,
    } = this.props
    if (userStatus === "offline") {
      return (
        <Wrap isOffline>
          <OfflineWrap>
            <img src={OfflineImg} alt="" />
          </OfflineWrap>
        </Wrap>
      )
    }
    return (
      <Wrap>
        <SearchWrap>
          {isOnCall ? (
            <input
              type="text"
              onKeyPress={handleIVRKeyPress}
              placeholder="Enter key to send to IVR"
              value={IVRInput}
              onChange={handleIVRChange}
            />
          ) : (
            <input
              type="text"
              placeholder="Search a client by name, phone or both"
              value={searchText}
              onChange={onSearchType}
            />
          )}
          {isShowSearch && searchResultClients && searchResultClients.length > 0 && (
            <SearchResult
              agents={searchResultClients}
              setClientDetailsFromSearch={setClientDetailsFromSearch}
            />
          )}
          <OtherOption
            resetNewMessagesCount={resetNewMessagesCount}
            messagesCount={messagesCount}
            addNewLeads={addNewLeads}
            unsavedReferrals={unsavedReferrals}
            allowAutoCallerMode={allowAutoCallerMode}
          />
          <MarkedNumberWrap>
            {maskedNumber}
          </MarkedNumberWrap>
        </SearchWrap>
        <DialerWrap>
          <Dialer
            dialedNumber={dialedNumber}
            onDialerType={onDialerType}
            dialComponent={dialComponent}
            callTimer={callTimer}
            isOnCall={isOnCall}
            isRinging={isRinging}
            startCaller={startCaller}
            allowAutoCallerMode={allowAutoCallerMode}
            refreshQueue={refreshQueue}
            upcomingCallsUrl={upcomingCallsUrl}
            handleDisconnectConfirm={handleDisconnectConfirm}
            isShowTransferButton={isShowTransferButton}
            conferenceCall={conferenceCall}
            isAutoCalls={isAutoCalls}
          />
        </DialerWrap>
      </Wrap>
    )
  }
}

export default CallHeader
