import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"

const initState = {
  ...createDeltaReducer("currentRates"),
}

export default function mortgageReducer(state = initState, action) {
  switch (action.type) {
    case Actions.getCurrentRates.REQUEST:
    case Actions.getCurrentRates.SUCCESS:
    case Actions.getCurrentRates.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getCurrentRates, "currentRates"),
      }
    }
    default:
      return state
  }
}
