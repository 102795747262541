import React, { PureComponent } from "react"
import styled from "styled-components"
import Loader from "dumbComponents/common/UI/Loader"
import agentSearchContainer from "container/BrokerDashboard/agentSearchContainer"
import { get } from "lodash"
// import SearchContainer from "container/Search/SearchContainer"
import TechAssetsContainer from "container/BrokerDashboard/globalPagesContainer"
import root, { setTimeout } from "window-or-global"
import ListingList from "dumbComponents/Listings/components/ListingList"
// import Loader from "@ui/Loader"
import { withRouter } from "react-router-dom"
import cookie from "services/CookieStorage"
import PlusIcon from "images/icons/WhiteIconPlus.png"
// import GoogleContacts from "react-google-contacts"
// import { GoogleLogin } from 'react-google-login'
// import { GoogleLogin, GoogleLogout } from "react-google-login"
// import { useGoogleLogin } from "@react-oauth/google"
import { PlusOutlined, PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons"
import Sidebar from "dumbComponents/Listings/components/Sidebar"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
// import { Form } from "@ant-design/compatible"
// import "@ant-design/compatible/assets/index.css"
// import { gapi } from "gapi-script"

import US_STATES from "shared/US_STATES.json"
import { CloseCircleOutlined } from "@ant-design/icons"
import { normalizePhone } from "../../../../services/formUtils"

import {
  Input, InputNumber, Select, Checkbox, Form,
  AutoComplete,
  Divider,
  Button,
  GoogleAutoComplete,
  Spin,
} from "dumbComponents/common/UI/antd"
// import "antd/dist/antd.css"
console.log("----Form----", Form)
// try {
//   const [form] = Form.useFormInstance()
// } catch(err){
//   console.log("eerorroro====", err)
// }

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
  .ant-form-item-control-input {
    min-height: 0px;
  }
`

const HeaderNav = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
`

const ProgressBarContainer = styled.div`
  padding: 30px 0px;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #3B60E4;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LabelText = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
`

const Required = styled.span`
  color: #E34C4C
`

const CheckboxLabelText = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  text-transform: uppercase;
`

const FlexBox = styled.div`
  display: flex;
`

const CheckboxContainer = styled.div`
  padding: 0 20px 0 0;
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
`

const AddForm = styled.div`
  padding: 0 0 34px 0;
  text-align: right;
`

const StyledSpin = styled(Spin)`
  width: 100%;
`

const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  h2 {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin: 0px;
  }
`
const STATE_OPTIONS = Object.keys(US_STATES).map(key => ({ label: US_STATES[key], value: US_STATES[key] }))

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)
// var clientId = '180520004723-ja501s6hkq27puptbn6oa20ldi47jt0d.apps.googleusercontent.com';
// var apiKey = 'AIzaSyC75MR5jnct4TwoKolQ06TJV6MPJcQrgIk';
// var scopes = 'https://www.googleapis.com/auth/contacts.readonly';

class CoverSheet extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      contacts: [],
      showCoListingform: [1],
      cityFormValue: {},
      agentValues: [],
    }
  }

  formRef = React.createRef();

  componentDidMount() {
    // gapi.load("client:auth2", this.fetchGoogleContacts)
    const { getListingCoverSheet, dealToView, dealAgentId, setLoaderOnAgentInfo } = this.props
    const payload = {}
    if (dealAgentId) {
      payload.dealAgentId = dealAgentId
      getListingCoverSheet({
        dealId: dealToView || 0,
        callAPI: ["agent_info"],
        ...payload,
      })
    } else {
      setLoaderOnAgentInfo(false)
    }
  }

  // login = () => {
  //   useGoogleLogin({
  //     onSuccess: codeResponse => console.log("codeResponse", codeResponse),
  //     flow: "auth-code",
  //   })
  // }

  // fetchGoogleContacts = () => {
  //   console.log("==TEST===11")
  //   gapi.client.init({
  //     apiKey: "AIzaSyC75MR5jnct4TwoKolQ06TJV6MPJcQrgIk",
  //     clientId: "180520004723-ja501s6hkq27puptbn6oa20ldi47jt0d.apps.googleusercontent.com",
  //     scope: "https://www.googleapis.com/auth/contacts.readonly",
  //     discoveryDocs: ["https://people.googleapis.com/$discovery/rest"],
  //   }).then(() => {
  //     console.log("==TEST===2")
  //     // return gapi.client.people.people.connections.list({
  //     //   resourceName: "people/me",
  //     //   pageSize: 10,
  //     //   personFields: "names,emailAddresses",
  //     // })
  //     const tempToken = cookie.load("google_access_token")
  //     return gapi.client.request({
  //       path: "https://content-people.googleapis.com/v1/people/me/connections?pageSize=2000&personFields=names%2CemailAddresses,phoneNumbers&sortOrder=FIRST_NAME_ASCENDING",
  //       headers: {
  //         Authorization: `Bearer ${tempToken}`,
  //       },
  //     })
  //   }).then((response) => {
  //     console.log("==TEST===3")
  //     if (response.result?.connections) {
  //       this.setState({
  //         contacts: response.result?.connections,
  //       })
  //     }
  //     console.log(response.result)
  //   }, (reason) => {
  //     console.log("==TEST===5")
  //     console.log(`Error: ${reason.result.error.message}`)
  //   })
  // }

  getOptions = () => {
    const { contacts } = this.state
    console.log("==contacts===", contacts)
    return contacts && contacts.length ? contacts.filter(ct => ct.names).map((ct, index) => ({ label: ct.names[0].displayName, value: ct.names[0].displayName })) : []
  }

  addColistingAgent = () => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform
    colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
    setTimeout(() => {
      root.scrollTo(0, 3100)
    }, 300)
  }

  removeColistingAgent = (index) => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform.filter((sc, ind) => ind !== index)
    // colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
  }

  searchAgentDetails = (searchValue) => {
    const { getSearchedAgents, fetchAgentSearch } = this.props
    let timeout = 0
    if (timeout) {
      clearTimeout(timeout)
      timeout = 0
    }
    const callSearch = () => {
      fetchAgentSearch({ search: searchValue })
      // getSearchedAgents({
      //   isInitial: true,
      //   currentPageNumber: 1,
      //   queryParams: {
      //     text_value: searchValue,
      //     unRegistered: false,
      //   },
      // })
    }

    timeout = setTimeout(callSearch, 500)
  }

  onFinish = (fieldValues, isDraft) => {
    //api
    const { createListingCoverSheet, history, getListingCoverSheet, dealToView, updateListingCoverSheet, updateDealStatus, dealAgentId, saveListingSteps } = this.props
    const { cityFormValue } = this.state
    const user = cookie.load("user") || {}
    console.log("==fieldValues==", fieldValues)
    console.log("==cityFormValue==", cityFormValue)
    Object.keys(fieldValues).forEach((key) => {
      if (fieldValues[key] === undefined) {
        // eslint-disable-next-line no-param-reassign
        fieldValues[key] = null
      }
    })
    const localFieldValues = {
      ...fieldValues,
    }
    const payload = []
    console.log("==localFieldValues==", localFieldValues)
    Object.keys(localFieldValues).forEach((fvKey) => {
      const index = parseInt(fvKey.split("-")[1], 10)
      const keyName = fvKey.split("-")[0]
      if (!payload[index]) {
        payload[index] = {
          // is_primary: index === 0 ? "Y" : "N",
          is_primary: index === 0 ? 1 : 0,
        }
      }
      payload[index] = {
        ...payload[index],
        [keyName]: localFieldValues[fvKey],
      }
    })
    console.log("==payload==", payload)
    if (dealToView) {
      updateListingCoverSheet({
        payload: {
          agents: payload,
          current_listing_step: "agent_info",
          draft: isDraft ? "Y" : "N",
        },
        dealAgentId: fieldValues["agent_id-0"],
        dealId: dealToView,
        callAPI: ["agent_info", "seller_info"],
        nextRoute: !isDraft ? "/broker/tc/add-listings/coversheet/seller-info" : "/broker/tc/add-listings",
        history,
      })
    } else {
      createListingCoverSheet({
        payload: {
          agents: payload,
          current_listing_step: "agent_info",
          draft: isDraft ? "Y" : "N",
        },
        dealAgentId: fieldValues["agent_id-0"],
        callAPI: ["agent_info", "seller_info"],
        nextRoute: !isDraft ? "/broker/tc/add-listings/coversheet/seller-info" : "/broker/tc/add-listings",
        history,
        callback: (dealIdToSend) => {
          // updateDealStatus({
          //   dealId: dealIdToSend,
          //   step: "incomplete",
          // })
          saveListingSteps({
            payload: {
              listing_id: dealIdToSend,
              step: "incomplete",
              notes: "",
            },
          })
        },
      })
    }
    // history.push("/broker/tc/add-listings/coversheet/seller-info")
  }

  onFinishFailed = (values) => {
    console.log("==payload=values=", values)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  onSaveAsDraft = (values) => {
    console.log("===this.formRef.current.getFieldsValue===", this.formRef.current.getFieldsValue())
    this.onFinish(this.formRef.current.getFieldsValue(), true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  changeOtherFieldsValues = (objString, index) => {
    const { agents } = this.props
    const { isFetching, data } = agents
    const obj = objString.split("::")
    const agent = data.agents.find(ag => ag.id === parseInt(obj[0], 10))
    const { brokerage_info_from_LOC } = agent
    this.formRef.current.setFieldsValue({
      [`agent_id-${index}`]: parseInt(obj[0], 10),
      [`name-${index}`]: obj[1] || "",
      [`email-${index}`]: agent.email || "",
      [`phone-${index}`]: agent.phone,
      [`agent_license_number-${index}`]: brokerage_info_from_LOC?.agent_license_number || "",
      [`listing_brokerage-${index}`]: brokerage_info_from_LOC?.listing_brokerage || "",
      [`brokerage_license_number-${index}`]: brokerage_info_from_LOC?.brokerage_license_number || "",
      [`brokerage_address-${index}`]: brokerage_info_from_LOC?.brokerage_address || "",
      [`apt-${index}`]: brokerage_info_from_LOC?.apt || null,
      [`city-${index}`]: brokerage_info_from_LOC?.city || "",
      [`state-${index}`]: brokerage_info_from_LOC?.state || "",
      [`zipcode-${index}`]: brokerage_info_from_LOC?.zipcode,
    })
  }

  setCities = (location, cityFormName, stateFormName) => {
    const { cityFormValue } = this.state
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    this.formRef.current.setFieldsValue({
      [cityFormName]: `${locationDetails.city || ""}`,
      [stateFormName]: `${locationDetails.state || ""}`,
    })
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [cityFormName]: `${locationDetails.city}`,
      },
    })
  }

  setStreet = (location, index) => {
    console.log("==payload=values=location", location)
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    let neighborhood = ""
    let zipcode = ""
    if (location.street_number) {
      neighborhood += `${location.street_number.long_name} `
    }
    if (location.route) {
      neighborhood += location.route.long_name
    }
    if (location.postal_code) {
      zipcode = location.postal_code.long_name
    }

    this.formRef.current.setFieldsValue({
      [`city-${index}`]: `${locationDetails.city || ""}`,
      [`state-${index}`]: `${locationDetails.state || ""}`,
      [`country-${index}`]: `${locationDetails.country}`,
      [`zipcode-${index}`]: zipcode,
    })
    if (neighborhood) {
      this.formRef.current.setFieldsValue({
        [`brokerage_address-${index}`]: neighborhood || "",
      })
    }
  }

  setLocaCity = (value, cityFormName) => {
    const { cityFormValue } = this.state
    console.log("==this.state===", this.state, "value=====", value, "==--cityFormValue--==", cityFormValue)
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [cityFormName]: value,
      },
    })
  }

  getInitialValues = () => {
    const { getListingCoverSheetResponse } = this.props
    const { cityFormValue, showCoListingform } = this.state
    const agentInfo = getListingCoverSheetResponse.data?.agentInfo
    const localShowCoListingform = showCoListingform
    const result = {}
    if (agentInfo) {
      const agentInformation = agentInfo.id ? [agentInfo] : agentInfo
      agentInformation.forEach((info, index) => {
        Object.keys(info).forEach((key) => {
          result[`${key}-${index}`] = info[key]
          if (key === "city") {
            // need to check this logic
            cityFormValue[`${key}-${index}`] = info[key]
          }
        })
        // need to check this logic
        if (index > 0 && !localShowCoListingform.includes(index + 1)) localShowCoListingform.push(index + 1)
      })
    }
    console.log("===result===", result)
    return result
  }

  render() {
    const { showCoListingform, cityFormValue } = this.state
    const {
      history, createListingCoverSheetResponse, updateListingCoverSheetResponse, getListingCoverSheetResponse,
      form, agents, isFetchingAgents, loaderOnAgentInfo,
    } = this.props
    console.log("===showCoListingform==showCoListingform=", showCoListingform)
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]
    // console.log("createListingCoverSheetResponse.isFetching || updateListingCoverSheetResponse.isFetching || getListingCoverSheetResponse.isFetching----", createListingCoverSheetResponse.isFetching, updateListingCoverSheetResponse.isFetching, getListingCoverSheetResponse.isFetching)
    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Listing Agent Information
        </HeaderNav>
        <ProgressBarComponent total={[1, 2, 3, 4]} ongoingOrCompleted={1} />
        <Container>
          <LeftSideBar>
            <Sidebar />
          </LeftSideBar>
          <RightSideBar>
            <AddForm>
              <Button
                ghost
                style={{
                  float: "right",
                  borderColor: "#3B60E4",
                  color: "#3B60E4",
                  display: "flex",
                  alignItems: "center",
                }}
                type="primary"
                shape="round"
                size="large"
                icon={<PlusCircleOutlined />}
                onClick={() => { this.addColistingAgent() }}
              >
                Add co-listing agent
              </Button>
            </AddForm>
            {loaderOnAgentInfo ? (<Loader />) : (
              <Form
                name="listing_agent_information"
                preserve
                colon={false}
                layout="vertical"
                requiredMark={false}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                initialValues={this.getInitialValues()}
                ref={this.formRef}
              >
                {/* colisting */}
                {showCoListingform.map((lst, index) => (
                  <>
                    {index > 0 && (<Divider />)}
                    {index > 0 && (
                      <HeaderInfo>
                        <h2>
                          Co Listing Agent Information
                        </h2>
                        <div onClick={() => { this.removeColistingAgent(index) }} >
                          <CloseCircleOutlined style={{ fontSize: "22px" }} />
                        </div>
                        
                      </HeaderInfo>
                    )}
                    <Form.Item name={`agent_id-${index}`} style={{ minHeight: "0px" }}></Form.Item>
                    <Form.Item name={`name-${index}`} label={<LabelComponent text="First and Last Name" required />} rules={commonRules}>
                      {index === 0 ? (
                        <Select
                          showSearch
                          defaultActiveFirstOption={false}
                          showArrow={false}
                          filterOption={false}
                          onSearch={this.searchAgentDetails}
                          onChange={(e => this.changeOtherFieldsValues(e, index))}
                          notFoundContent={(agents.isFetching || agents.isFetchingMore) ? <StyledSpin size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}
                          options={(agents && agents.data && agents.data.agents) ? agents.data.agents.map(d => ({
                            label: `${d.firstname} ${d.lastname} - ${d.email}`,
                            value: `${d.id}::${d.firstname} ${d.lastname}`,
                          })) : [{ value: "", label: "" }]}
                        />
                      ) : (
                        <Select
                          showSearch
                          defaultActiveFirstOption={false}
                          showArrow={false}
                          filterOption={false}
                          onSearch={this.searchAgentDetails}
                          onChange={(e => this.changeOtherFieldsValues(e, index))}
                          notFoundContent={(agents.isFetching || agents.isFetchingMore) ? <StyledSpin size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}
                          options={(agents && agents.data && agents.data.agents) ? agents.data.agents.map(d => ({
                            label: `${d.firstname} ${d.lastname} - ${d.email}`,
                            value: `${d.id}::${d.firstname} ${d.lastname}`,
                          })) : [{ value: "", label: "" }]}
                        />
                      )}
                    </Form.Item>
                    <FlexBox>
                      <Form.Item
                        name={`email-${index}`}
                        label={<LabelComponent text="Email" required />}
                        required
                        rules={commonRules}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item name={`agent_license_number-${index}`} label={<LabelComponent text="Agent License No." required />} rules={commonRules}>
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item name={`landline-${index}`} label={<LabelComponent text="Home Phone" />}>
                        <Input />
                      </Form.Item>
                      <Form.Item name={`phone-${index}`} label={<LabelComponent text="Cell Phone" />} normalize={normalizePhone}>
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item name={`listing_brokerage-${index}`} label={<LabelComponent text="Listing Brokerage" required />} rules={commonRules}>
                        <Input />
                      </Form.Item>

                      <Form.Item name={`brokerage_license_number-${index}`} label={<LabelComponent text="Brokerage License No." required />} rules={commonRules}>
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item name={`brokerage_address-${index}`} label={<LabelComponent text="Brokerage Address" />}>
                        {/* <Input /> */}
                        <GoogleAutoComplete
                          id="cities"
                          types={["address"]}
                          name="cities"
                          // formValue={cityFormValue}
                          onChange={(e) => { }}
                          location={(loc) => { this.setStreet(loc, index) }}
                          placeholder=""
                          styles={{
                            height: "49px",
                            borderRadius: "0px",
                            background: "white",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        />
                      </Form.Item>

                      <Form.Item name={`apt-${index}`} label={<LabelComponent text="Apt/Unit/Suite No." />}>
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item name={`city-${index}`} label={<LabelComponent text="City" />}>
                        <GoogleAutoComplete
                          id="cities"
                          types={["(cities)"]}
                          name="cities"
                          // formValue={cityFormValue[`city-${index}`]}
                          onChange={(e) => { this.setLocaCity(e.target.value, `city-${index}`) }}
                          location={(loc) => { this.setCities(loc, `city-${index}`, `state-${index}`) }}
                          placeholder=""
                          styles={{
                            height: "49px",
                            borderRadius: "0px",
                            background: "white",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                          restrict={{
                            lat: 37.09024,
                            lng: -95.712891,
                            south_west: {
                              lat: 25.82,
                              lng: -124.39,
                            },
                            north_east: {
                              lat: 49.38,
                              lng: -66.94,
                            },
                            address: "United States",
                            country: {
                              long_name: "United States",
                              short_name: "US",
                              types: [
                                "country",
                                "political",
                              ],
                            },
                          }}
                        />
                      </Form.Item>

                      <Form.Item name={`state-${index}`} label={<LabelComponent text="State" />}>
                        {/* <Select>
                          {STATE_OPTIONS.map(st => (
                            <Select.Option value={st.value}>{st.label}</Select.Option>
                          ))}
                        </Select> */}
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name={`zipcode-${index}`}
                        label={<LabelComponent text="Zip Code" />}
                        rules={[
                          {
                            type: "string",
                            pattern: new RegExp(/\d+/g),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </FlexBox>
                  </>
                ))}
                <FlexBox>
                  <Form.Item>
                    {/* <Button
                      style={{
                        width: 119,
                        height: 48,
                        color: "#849DF8",
                        padding: 0,
                      }}
                      size="large"
                      type="link"
                      onClick={this.onSaveAsDraft}
                    >
                      Save for Later
                    </Button> */}
                  </Form.Item>

                  <Form.Item>
                    <Button
                      style={{
                        width: 119,
                        height: 48,
                        background: "#3B60E4",
                        float: "right",
                        borderColor: "white",
                      }}
                      type="primary"
                      shape="round"
                      size="large"
                      loading={createListingCoverSheetResponse.isFetching || updateListingCoverSheetResponse.isFetching || getListingCoverSheetResponse.isFetching}
                      htmlType="submit"
                    // onClick={() => { history.push("/broker/tc/add-listings/coversheet/seller-info") }}
                    >
                      Next
                    </Button>
                  </Form.Item>
                </FlexBox>
              </Form>
            )}
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}
// const WrappedCoverSheet = Form.create()(CoverSheet)
export default withRouter(TechAssetsContainer(agentSearchContainer(CoverSheet)))
