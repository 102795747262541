import React, { useEffect } from "react"
import { Drawer, Tabs } from "antd"
import styled from "styled-components"
import RecruitAndEarn from "../../../container/RecruitAndEarn"
import AgentDetails from "./AgentDetailComponents/Details"
import AgentActivity from "./AgentDetailComponents/Timeline"

const { TabPane } = Tabs

const Wrap = styled.div``

const Name = styled.p`
  font-size: 28px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`

const InvitedInformation = (props) => {
  const {
    toggleAgentDetailsDrawer,
    isAgentInfoDrawerOpen,
    idToBeFetched,
    fetchAgentEarnings,
    getAgentSpecificDetailResponse,
    fetchSenderTimelinesResponse,
    getSenderTimelines,
    agentName,
  } = props || {}

  const handleClose = () => {
    toggleAgentDetailsDrawer({
      bool: false,
      agentId: null,
      agentName: null,
    })
  }

  useEffect(() => {
    if (idToBeFetched) {
      fetchAgentEarnings({
        id: idToBeFetched,
      })
      getSenderTimelines({
        agentId: idToBeFetched,
      })
    }
  }, [idToBeFetched])

  const {
    data: agentEarnings,
    isFetching: fetchingAgentEarnings,
  } = getAgentSpecificDetailResponse || {}

  const {
    data: senderTimelines,
    isFetching: fetchingSenderActivity,
  } = fetchSenderTimelinesResponse || {}

  const onChange = () => {
    //
  }

  return (
    <Drawer
      title="Agent Info"
      width={537}
      placement="left"
      onClose={handleClose}
      visible={isAgentInfoDrawerOpen}
    >
      {agentName && (
        <Wrap>
          <Name>{agentName}</Name>
        </Wrap>
      )}
      <Tabs defaultActiveKey="referrals" onChange={onChange}>
        <TabPane tab="Earnings" key="earnings">
          <AgentDetails
            agentEarnings={agentEarnings}
            fetchingAgentEarnings={fetchingAgentEarnings}
          />
        </TabPane>
        <TabPane tab="Activity" key="activity">
          <AgentActivity data={senderTimelines} fetchingSenderActivity={fetchingSenderActivity} />
        </TabPane>
      </Tabs>
    </Drawer>
  )
}
export default RecruitAndEarn(InvitedInformation)
