import React from "react"
import { Tooltip } from "antd"
import {
  StatCard,
} from "../../statSectionCommonStyles"
import {
  TitleWrap,
  StatValue,
  ResponseText,
  ExtraStatWrap,
  ExtraStatRow,
  ExtraStatCircle,
  ExtraStatText,
  ExtraStatPercentage,
  ExtraStatInnerWrap,
} from "./cardStyles"

const Card = ({
  title,
  value,
  extraResponse,
  promptText = "Total number of feedback given by agents on an AI response.",
}) => (
  <StatCard>
    <TitleWrap>
      <h1>{title}</h1>
      <Tooltip title={promptText}>
        <img src="https://s3.amazonaws.com/cdn.agentdesks.com/images/SOUL_AI/MinistatusInfo.png" alt="soul_ai" />
      </Tooltip>
    </TitleWrap>
    <StatValue>
      <p>{value}</p>
    </StatValue>
    <ResponseText>
      <p>responses</p>
    </ResponseText>
    {extraResponse && (
      <ExtraStatWrap>
        <ExtraStatRow>
          <ExtraStatInnerWrap>
            <ExtraStatCircle />
            <ExtraStatText>Not relevant</ExtraStatText>
          </ExtraStatInnerWrap>
          <ExtraStatPercentage>{`${extraResponse.notRelevantPercent}%`}</ExtraStatPercentage>
        </ExtraStatRow>
        <ExtraStatRow>
          <ExtraStatInnerWrap>
            <ExtraStatCircle />
            <ExtraStatText>Inaccurate</ExtraStatText>
          </ExtraStatInnerWrap>
          <ExtraStatPercentage>{`${extraResponse.inacuratePercent}%`}</ExtraStatPercentage>
        </ExtraStatRow>
        <ExtraStatRow>
          <ExtraStatInnerWrap>
            <ExtraStatCircle />
            <ExtraStatText>Inconclusive</ExtraStatText>
          </ExtraStatInnerWrap>
          <ExtraStatPercentage>{`${extraResponse.inconclusivePercent}%`}</ExtraStatPercentage>
        </ExtraStatRow>
        <ExtraStatRow>
          <ExtraStatInnerWrap>
            <ExtraStatCircle />
            <ExtraStatText>Repetitive</ExtraStatText>
          </ExtraStatInnerWrap>
          <ExtraStatPercentage>{`${extraResponse.repetitivePercent}%`}</ExtraStatPercentage>
        </ExtraStatRow>
        <ExtraStatRow>
          <ExtraStatInnerWrap>
            <ExtraStatCircle />
            <ExtraStatText>Something else</ExtraStatText>
          </ExtraStatInnerWrap>
          <ExtraStatPercentage>{`${extraResponse.somethingElsePercent}%`}</ExtraStatPercentage>
        </ExtraStatRow>
      </ExtraStatWrap>
    )}
  </StatCard>
)

export default Card
