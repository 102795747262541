import React, { Component } from "react"
import styled, { css } from "styled-components"
import colors from "lib/colors"
import Container from "container/Agent"


const PlanUL = styled.ul`
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(11, auto);
  ${props => props.isTeam && `
   grid-template-rows: repeat(8, auto);
   `}
`
const PlanLi = styled.li`
  height: max-content;
  width: 423px;
  display: flex;
  margin-bottom: 10px;
  cursor: pointer;
`
const Tier = styled.div`
  height: 16px;
  border-radius: 2px;
  background-image: linear-gradient(104deg,#2bda8e -1%,#0ac4ba 100%);
  color: white;
  font-size: 12px;
  width: 65px;
  text-align: center;

  ${props => props.isRtl && `
    margin-right: 50px;
    margin-left: 16px;
   `}

  ${props => !props.isPremium && `
    color: #0cc5b6;
    background: transparent;
    border: 1px solid #0cc5b6;
   `}
   ${props => props.isPremium && `
     padding: 1px;
    `}
`
const Amount = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #8c8c8c;
  max-width: max-content;
  ${props => props.isRtl && `
     flex-grow: 1;
     padding-left: 37px;
   `}

   ${props => props.leg && `
    max-width: 76px;
    overflow: hidden;
    text-overflow: ellipsis;
    `}
`

const PerLead = styled.p`
  font-size: 14px;
  span {
   font-size: 11px;
   color: #8c8c8c;
  }
`

const AmountDet = styled.p`
  font-size: 14px;
  font-weight: bold;
  flex-grow: 0;
  span {
   font-size: 11px;
   color: #8c8c8c;
  }
`

const DetailsWrap = styled.div`
  width: 100%;
  height: 28px;
  border-radius: 7px;
  border: solid 1px #e5e5e5;
  display: flex;
  padding: 5px;
  ${props => props.isBLU && `
    justify-content: space-around;
   `}
  p {
   margin:0;
  }

  ${props => props.isSelected && `
    background: #eadbc0;
   `}
  ${props => props.isCurrent && `
     border: 2px solid black;
   `}
`

const CheckBoxWrap = styled.div`
  width: 28px;
  height: 28px;
  padding: 5px;
  div {
   width: 16px;
   height: 16px;
   border-radius: 20px;
   border: solid 2px #d1d1d1;
   div {
     display: ${props => props.isCurrent ? "block" : "none"};
     width: 12px;
     height: 12px;
     border-radius: 20px;
     background: purple;
   }
  }
`

const TitleWrap = styled.div`
 display: flex;
 h1 {
  font-size: 18px;
  font-weight: bold;
 }

 div {
  width: 56px;
  height: 18px;
  border-radius: 2px;
  background-color: #fff3cc;
  margin-top: 15px;
  margin-left: 8px;
  padding-left: 9px;
  padding-top: 1px;
  p {
   font-size: 13px;
   margin: 0;
  }
 }

`

const Wrap = styled.div`
  font-family: "SF Pro Display", sans-serif;
  min-width: 1000px;
  padding: 10px 0px 0px 25px;
`

const Plan = styled.div`
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #CCC;
    background: #FFF;
    padding: 10px;
    margin-top: 10px;

    ${props => props.isSelected && `
        background: #2ecc71;
        color: #FFF;
        font-weight: bold;
    `}
`

class PlanList extends React.Component {
  state = {
    currentId: "",
  }
  componentDidMount() {
    //
  }

  myRef = React.createRef();

  getDuration = (interval) => {
    switch (interval) {
      case 1:
        return "month"
        break
      case 6:
        return `${interval} months`
        break
      case 12:
        return "year"
        break
      default:
        return `${interval} months`
        break
    }
  }

  handlePlanSelect = (plan, agentSecureId, subscriptions, isActivePlan, id) => {
    const {
      setPlanForCheckout,
      getInstallments,
      setCurrentRTLPlan,
      setCurrentBLUPlan,
      setCurrentBROKERPlan,
      setInstallment,
      currentMode,
      installmentPlan,
      setBenefitsToBeAdded,
      alreadyAddedFeatures
    } = this.props

    if (plan.product_nickname === "rtl") {
      setCurrentRTLPlan({
        currentRTLPlanSelected: plan,
      })
    }

    if (plan.product_nickname === "blu") {
      setCurrentBLUPlan({
        currentBLUPlanSelected: plan,
      })
    }

    if (plan.product_nickname === "broker") {
      setCurrentBROKERPlan({
        currentBROKERPlanSelected: plan,
      })
    }

    setPlanForCheckout({
      currentSelectedPlan: plan,
    })
    getInstallments({ planId: plan.plan_id })
    setInstallment({
      currentMode,
      currentSelectedInstallmentPlan: {}
    })
    setBenefitsToBeAdded({
      addedBenefits: [],
      alreadyAddedFeatures
    })
  }

  isCurrentSubscribedPlan = (item) => {
    const { currentSelectedPlan, currentRTLPlanSelected, currentBLUPlanSelected} = this.props
    return [currentSelectedPlan.plan_id, currentRTLPlanSelected.plan_id, currentBLUPlanSelected.plan_id].includes(item.plan_id)
  }

  render() {
    const {
      plan,
      isLegacy,
      base,
      title,
      activePlansIds,
      type,
      sizeType,
      agentSecureId,
      subscriptions,
      currentSelectedPlan,
      currentRTLPlanSelected,
      currentBLUPlanSelected,
    } = this.props
    const {
      currentId,
    } = this.state

    const isHidePremiumTag = type === "broker"
    return (
      <Wrap>
        <TitleWrap>
          <h1>{base}</h1>
          {isLegacy && (
            <div>
              <p>Legacy</p>
            </div>
          )}
        </TitleWrap>
        <PlanUL
          isTeam={sizeType === "team"}
        >
          {plan && Object.keys(plan).map(key => {
            if (typeof plan[key] === "string") {
              return null
            }
            return (
              <React.Fragment>
                {plan[key].map((item, iter) => {
                  if (isLegacy && (Boolean(item.is_blink_visible) || activePlansIds.includes(item.plan_id))) {
                    if (item.product_nickname === type && Boolean(item.is_legacy_plan) && item.plan_id.indexOf("member") === -1) {
                      return (
                        <PlanLi
                          key={item.id}
                          onClick={(e) => this.handlePlanSelect(item, agentSecureId, subscriptions, activePlansIds.includes(item.plan_id), item.plan_id)}
                        >
                          <CheckBoxWrap
                            isCurrent={
                              (currentSelectedPlan && item.plan_id === currentSelectedPlan.plan_id)
                              || (currentRTLPlanSelected && item.plan_id === currentRTLPlanSelected.plan_id)
                              || (currentBLUPlanSelected && item.plan_id === currentBLUPlanSelected.plan_id)
                            }
                          >
                            <div>
                              <div></div>
                            </div>
                          </CheckBoxWrap>
                          <DetailsWrap
                            isBLU={type === "blu"}
                            isCurrent={
                              (currentSelectedPlan && item.plan_id === currentSelectedPlan.plan_id)
                              || (currentRTLPlanSelected && item.plan_id === currentRTLPlanSelected.plan_id)
                              || (currentBLUPlanSelected && item.plan_id === currentBLUPlanSelected.plan_id)
                            }
                            isSelected={activePlansIds.includes(item.plan_id)}
                          >
                            {!isHidePremiumTag && (
                              <Tier
                                isRtl={type === "rtl" || type === "broker"}
                                isPremium={item.plan_tier === "PREMIUM"}
                              >
                                {item.plan_tier}
                              </Tier>
                            )}
                            {(type === "rtl" || type === "broker") ? (
                              <Amount>{item.name}</Amount>
                            ) : (
                              <Amount leg>{item.name}</Amount>
                            )}
                            {type === "blu" ? (
                              <React.Fragment>
                                <PerLead>{`$${item.unit_price / item.quota}`}<span>{` / lead`}</span></PerLead>
                                <AmountDet>
                                  {`$${item.unit_price}`}
                                  <span>{` / ${item.quota} leads`}</span>
                                </AmountDet>
                              </React.Fragment>
                            ) : (
                              <AmountDet>

                              </AmountDet>
                            )}
                          </DetailsWrap>
                        </PlanLi>
                      )
                    }
                  } else {
                    if (item.product_nickname === type && (Boolean(item.is_blink_visible) || activePlansIds.includes(item.plan_id)) && !Boolean(item.is_legacy_plan) && item.plan_id.indexOf("member") === -1) {
                      return (
                        <PlanLi
                          onClick={() => this.handlePlanSelect(item, agentSecureId, subscriptions, activePlansIds.includes(item.plan_id), item.plan_id)}
                        >
                          <CheckBoxWrap
                            isCurrent={
                              (currentSelectedPlan && item.plan_id === currentSelectedPlan.plan_id)
                              || (currentRTLPlanSelected && item.plan_id === currentRTLPlanSelected.plan_id)
                              || (currentBLUPlanSelected && item.plan_id === currentBLUPlanSelected.plan_id)
                            }
                          >
                            <div>
                              <div></div>
                            </div>
                          </CheckBoxWrap>
                          <DetailsWrap
                            isBLU={type === "blu"}
                            isCurrent={
                              (currentSelectedPlan && item.plan_id === currentSelectedPlan.plan_id)
                              || (currentRTLPlanSelected && item.plan_id === currentRTLPlanSelected.plan_id)
                              || (currentBLUPlanSelected && item.plan_id === currentBLUPlanSelected.plan_id)
                            }
                            isSelected={activePlansIds.includes(item.plan_id)}
                          >
                            {!isHidePremiumTag && (
                              <Tier
                                isRtl={(type === "rtl" || type === "broker")}
                                isPremium={item.plan_tier === "PREMIUM"}
                              >
                                {item.plan_tier}
                              </Tier>
                            )}
                            {(type === "rtl" || type === "broker") ? (
                              <Amount>{item.name}</Amount>
                            ) : (
                              <Amount>{item.name}</Amount>
                            )}
                            {type === "blu" ? (
                              <React.Fragment>
                                <PerLead>{`$${item.unit_price / item.quota}`}<span>{` / lead`}</span></PerLead>
                                <AmountDet>
                                  {`$${item.unit_price}`}
                                  <span>{` / ${item.quota} leads`}</span>
                                </AmountDet>
                              </React.Fragment>
                            ) : (
                              <AmountDet>
                              </AmountDet>
                            )}
                          </DetailsWrap>
                        </PlanLi>
                      )
                    }
                  }
                })}
              </React.Fragment>
            )
          })}
        </PlanUL>
      </Wrap>
    )
  }
}

export default Container(PlanList)
