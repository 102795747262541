import React from 'react';
import moment from 'moment';
import './Reminder.css';

const reminder = props => (
  <div className={"reminder"}>
    <div className={"highlighter"} />
    <div className={"content"}>
      <p className={"title"}>{moment(props.reminder.date, "X").format("MMMM Do")}</p>
      <p className={"description"}>{props.reminder.note}</p>
      <p className={"title"}>
        {moment(props.reminder.date, "X").format("hh:mm A")} - {moment(props.reminder.date, "X").add(1, 'hour').format("hh:mm A")}
      </p>
    </div>
  </div>
);

export default reminder;