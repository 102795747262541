import React, { Component } from 'react';
import fetch from 'isomorphic-fetch';
import bugsnag from 'bugsnag-js';
import * as qs from 'query-string';
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;
const bugsnagBlinkKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(bugsnagBlinkKey);


class RecommendationToggler extends Component {
  state = {
   referralId : null,
   callingApi: true,
   buttonStatus: null,
   status: 'Unknown',
  };

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    const parsed = qs.parse(window.location.search);
    if (parsed && parsed.referralId){
      this.loadProspectStatus(parsed.referralId);
    }
  }

  toggleProspectStatus = async () => {
    if (!this.state.referralId){
      return;
    }
    this.setState({callingApi: true})

    try{
       let toggleResp = await fetch(baseUrl + `/v1.0/prospects/${this.state.referralId}/toggle`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'internal-id': token
          },
          body: {}
       });
       toggleResp = await toggleResp.json();
       this.loadProspectStatus(this.state.referralId);
    }
    catch(e){
      this.setState({callingApi: false})
      console.log(e);
    }
   
  }

  loadProspectStatus = async (referralId) => {
    try{
       let toggleResp = await fetch(baseUrl + `/v1.0/prospects/${referralId}/status`, {
       method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token
        },
      });
      toggleResp = await toggleResp.json();
      console.log(toggleResp.response.status);

      this.setState({callingApi: false, status: toggleResp.response.status, referralId: referralId})
    }
    catch(e){
      console.log(e);
    }
   
  }

  render() { 
    return <div>
      {this.state.callingApi ? 
        "Loading"
        :
        <table>
            <tr>
              <th>Type</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            <tr>
              <td>Inbound offering visiblity</td>
              <td>{this.state.status}</td>
              <td><button onClick={() => {this.toggleProspectStatus()}}>Toggle</button></td>
            </tr>
        </table> 
      }  
    </div>  
  }  
}  

export default RecommendationToggler;
