import React, { Component } from "react"
import fetch from 'isomorphic-fetch'
import bugsnag from 'bugsnag-js'
import root from "window-or-global"
import moment from 'moment';
import {
	Route, Switch, Redirect, Link
} from "react-router-dom"
import _, { parseInt, get } from 'lodash';
import Notifications, { notify } from 'react-notify-toast';
import { console } from "window-or-global";
const baseUrl = process.env.REACT_APP_API_BASE_URL
const token = process.env.REACT_APP_SECRET_ID
const containerStyle = {"backround" : "#7a7a8c0a" }
const marginTop={"margin-top" : "50px"};
const originalKey = process.env.REACT_APP_BUGSNAG_KEY
const bugsnagClient = bugsnag(originalKey)

const displayValues = {
  "id" : "Action",
  "created_at" : "Closing Date",
  "listing_address": "Listing Address",
  "city": "City",
  "state" : "State",
  "price"  : "Price",
  "commision_percentage" : "Commision Percentage",
  "source" : "Source",
  "gross_commission" : "Gross Commission",
  "radius_transaction_fee" : "Radius Transaction Fee",
  "client_name" : "Client Name",
  "client_email" : "Client Email",
  "transaction_type" : "Transaction Type",
  "client_type" : "Client Type",
  "is_radius_lead" : "Is Radius Lead"
}

const dealsStyle = {
    "maxHeight" : "800px",
    "backgroundColor": "white",
    "overflowY": "scroll",
    "marginLeft" : "50px",
    "marginRight" : "50px"
};

const emptystate = {

}

const addDealButton = {
  "float" : "right",
  "marginRight" : "50px",
  "float": "right",
  "borderRadius": "30px",
  "background": "#2cb72c",
  "color": "white",
  "fontWeight": "100",
}

const tableStyle = {
  "display": "table",
  "background" : "#fdf6f6",
  "overflow": "scroll"
}

const tableRowStyle  = {
  "display": "table-row"
}

const tableCellStyle  = {
  "display": "table-cell",
  "padding": "10px",
  "border": "1px solid black",
}


const tableHedaderStyle  = {
  "display": "table-cell",
  "padding": "10px",
  "border": "1px solid black",
  "fontWeight" : "500"
}

const welcomeMessage = {
  "margin" : "50px",
  "color": "#434356",
  "fontFamily": "sans-serif"
}

const firstsectionHeader = {
  "margin" : "50px",
  "color": "#434356",
  "fontFamily": "serif",
  "fontSize" : "20px",
}


class AgentDeals extends Component {
  state = {
   agentId: null,
   deals: [],
   agent: null,
   loading: true,
  
  };

  getName = (agent) => {
    let firstname = agent.firstname || "";
    let lastname = agent.lastname || "";
    return `${firstname.toUpperCase()} ${lastname.toUpperCase()}`

  }


 getValue = (deal, val) => {
  if (val === "id"){

    return <Link to={"/deals/agent/" +  this.state.agentId + '/edit/' + deal[val] }><i class="fa fa-edit"></i></Link>

  }
  if (val === "created_at"){
    return moment.unix(deal.created_at).format("MM/DD/YYYY")
  }
  if (val === "is_radius_lead"){
    return deal.is_radius_lead ? 'Yes' : 'No'
  }
  return deal[val]
}

  setAgentDetails = async(agentId) => {
    try {
      let sol = await fetch(baseUrl + '/v2.0/user/secure_id?getAgentDetails=true&agentId=' + agentId, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token
        },
      });
      sol = await sol.json();
      let secureId =  sol.response.secure_id;


      sol = await fetch(baseUrl + '/v2.0/user/info', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'secure-id': secureId
        },
      });
      sol = await sol.json();
      let agent =  sol.response;
      this.setState({agent})
    }
    catch(e){
      console.log(e);
      //bugsnagClient.notify(e);
    }

  };

  getAgentDeals  = async (agentId) => {
    try{
      let deals = await fetch(baseUrl + `/v1.0/brokerage-deals/agent/${agentId}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token
        },
      });
      deals = await deals.json();
      deals = deals.response;
      this.setState({deals, loading: false});
    
    }
    catch(e){
      bugsnagClient.notify(e);
    }
    
  }

  componentDidMount() {
    const { pathname } = root.location || ""
    const agentId = parseInt(pathname.split("/")[3], 10)
    
    this.setState({agentId})
    this.getAgentDeals(agentId);
    this.setAgentDetails(agentId);
   }

   getDealsDiv = (deals) => {
     let rowTitles = Object.keys(deals[0]);
     rowTitles = _.reject(rowTitles, (title) => title == "row_created_at" || title === "row_updated_at" || title === "agent_id")
     const rowTitlesdiv = rowTitles.map((val) => <div style={tableCellStyle}>
      {displayValues[val] || val}
      </div>);
     const dealsDiv = deals.map((deal) => {
       const dealCellDiv = rowTitles.map((val) => <div style={tableCellStyle}>
       {this.getValue(deal, val)}
     </div>)
       return <div style={tableRowStyle}>
          {dealCellDiv}
       </div>

     })
     return <div style={tableStyle}>
        <div style={tableRowStyle}>
          {rowTitlesdiv}
        </div>
        {dealsDiv}
   </div>;
   } 


   createNewDeal = () => {

   }

   
 
  render() {

      const dealsDiv = this.state.deals.length > 0 ? this.getDealsDiv(this.state.deals) : null;
      const emptyDiv = <span style={emptystate}>"No deals yet, please add a deal."</span> 

        if (this.state.loading){

          return <div class="new-loader"></div>
        }
    
        return <div style={containerStyle}> 
           {this.state.agent ? <span style={welcomeMessage}> {"Profile of " + this.getName(this.state.agent)}</span> : null}

          <br/>
          <div style={marginTop}>
              <span style={firstsectionHeader}> {"Deals"}</span>
              <Link to={`/broker/dashboard/${this.state.agentId}/deals/create`}>
                <button style={addDealButton}   >Add Deal</button>
              </Link>
              <br/>
              <br/>
              <div style={dealsStyle}>
                {this.state.deals.length > 0 ? dealsDiv : emptyDiv}
              </div>
          </div> 
        </div>

    }

}
export default AgentDeals;
