import React from "react"
import styled from "styled-components"
import Modal from "./../common/UI/Modal"
import Button from "dumbComponents/common/UI/Button"

const Wrap = styled.div`
  background: #FFF;

  > header {
    background: #eccc68;
    color: #222;
    padding: 10px 20px;
    font-weight: bold;
    display: none;
  }

  > main {
    padding: 30px 20px 10px 20px;
    > p {
      margin: 0px;
      font-size: 16px;
    }
  }

  > footer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 10px 20px;
  }
`

class ConfirmModal extends React.PureComponent {
  state = {}

  render() {
    // const {  } = this.state
    const {
      message,
      show,
      callback,
      cancel,
      children,
      isDisableConfirm = false,
    } = this.props
    return (
      <Modal
        show={show}
        toClose={() => { console.log("close")  }}
        dialogStyles={{ background: "transparent" }}
        modalStyles={{
          padding: 0,
          borderRadius: 10,
          overflow: "hidden",
          background: "transparent",
        }}
      >
        <Wrap>
          <header>
            Alert
          </header>
          {children ? (
            <main>
              {children}
            </main>
          ) : (
            <main>
              <p>{message}</p>
            </main>
          )}
          <footer>
            <Button onClick={cancel}>
              Cancel
            </Button>
            <Button danger onClick={callback} disabled={isDisableConfirm}>
              Confirm
            </Button>
          </footer>
        </Wrap>
      </Modal>
    )
  }
}

export default ConfirmModal
