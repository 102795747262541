import React, { Component, useState } from "react";
import styled from "styled-components";
import fetch from "isomorphic-fetch";
import {
  Row,
  Col,
  Container,
  Button,
  FormSelect,
  FormCheckbox,
  FormGroup,
  FormRadio,
  FormTextarea,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";

import { Multiselect } from "multiselect-react-dropdown";
import { Zoom } from "react-toastify";
import Input from "./Form/RenderInput";
import {DefaultLoader} from "./UI/Loader/Loader";
import {ShowErrorToast, ShowSuccessToast} from "../lib/ErrorUtils";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;
const defaultBatchCount = 5000

const buttonStyle = {
  display: "flex",
  justifyContent: "left",
  marginLeft:'20px',
};

const contentStyle = {
  display: "flex",
  justifyContent: "left",
  padding: 0
};

const filterStyle = {
    marginLeft: '300px',
    marginRight: '300px',
};

//Other Constants
const options = [
  {
    label: "String",
    value: "String or Number"
  }
];

class AgentNotificationsDashboard extends Component {
  state = {
    isTextMessageEnabled: false,
    isPushNotificationEnabled: true,
    isTestMode: false,
    stripePlans: [],
    stripePlansSelected: [],
    notificationTypes: [
        {type:"appUpdate", "type_display_name":"App upgrade / install "}
    ],
    isMobileUsers: null,
    shouldHideSendButton: false,
    notifyCountModelOpen: false,
    notifyCount: 0,
    allSubscribedUsers: null,
    activeRequests: [],
    activeRequestId: null,
    userType: "registered",
    submitParams: {
      testEmail: null,
      smsText: "",
      pushText: "",
      notificationType: "appUpdate",
      isRegisteredUser: true,
      selectedPlanIds: [],
      fromDate: null,
      toDate: null,
      batchCount: defaultBatchCount,
      loading:false,
    }
  };

  reset = () => {
      let submitParams = {
          testEmail: null,
          smsText: null,
          pushText: null,
          notificationType: "appUpdate",
          isRegisteredUser: true,
          selectedPlanIds: [],
          fromDate: null,
          toDate: null,
          batchCount: defaultBatchCount,
          loading:false,
      };
      this.setState({
          submitParams: submitParams
      });
  };

    startLoader = () => {
        this.setState({
            loading: true
        });
    };

    stopLoader = () => {
        this.setState({
            loading: false
        });
    };

  submitForPushNotification = async () => {
     //Making the Params
     let bodyParam = this.getSubmitBodyParams()
     if (bodyParam === null) {
        this.hideSendButton(false)
       return
     }
     this.startLoader();
     try {
       let response = await fetch(baseUrl + "/v1.0/growth/bulk-notifications", {
         method: "POST",
         headers: {
           Accept: "application/json",
           "Content-Type": "application/json",
           "secure-id": token
         },
         body: JSON.stringify(bodyParam)
       });
       let respJson = await response.json();
       if (response && response.status === 200) {
         ShowSuccessToast("Notification Successfully sent.");
         this.getActiveRequests();
       } else {
         ShowErrorToast(response);
       }
       this.setState({
        shouldHideSendButton: false
      })
      this.stopLoader();
     } catch (e) {
       this.hideSendButton(false)
       ShowErrorToast(e);
       this.stopLoader();
     }
  }


  getNotificationAgentsCount = async () => {

    this.setState({
      shouldHideSendButton: true
    })

    //Making the Params
    let bodyParam = this.getSubmitBodyParams()
    if (bodyParam === null) {
      this.hideSendButton(false)
      return
    }

    this.startLoader();
    try {
      let response = await fetch(baseUrl + "/v1.0/growth/bulk-notifications/fetch-agents-count", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "secure-id": token
        },
        body: JSON.stringify(bodyParam)
      });
      let respJson = await response.json();

      if (response && response.status === 200) {
        //ShowSuccessToast("Notification Successfully sent.");
      } else {
        ShowErrorToast(response);
      }

      this.setState({
        notifyCountModelOpen: true,
        shouldHideSendButton: false,
        notifyCount:respJson.response.agentIdsToNotifyCount
      })
      this.stopLoader();
    } catch (e) {
      this.hideSendButton(false)
      ShowErrorToast(e);
      this.stopLoader();
    }
  }

  getNotificationTypes = async () => {
    try {
      let response = await fetch(baseUrl + "/v1.0/growth/bulk-notifications/types", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "secure-id": token
        }
      });
      let respJson = await response.json();
      this.setState({
        notificationTypes: respJson.response,
        submitParams: {
          ...this.state.submitParams,
          notificationType: respJson.response[0].type
        }
      });
    } catch (e) {
       alert(e)
    }
  }

  getStripePlans = async () => {
    try {
      let response = await fetch(baseUrl + "/v1.0/stripe/plans", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "secure-id": token
        }
      });
      let respJson = await response.json();
      console.log(respJson);
      this.setState({
        stripePlans: this.getCombinedPlansForDropDown(respJson.response)
      });
    } catch (e) {
      console.log(e);
    }
  };

  getActiveRequests = async () => {
    try {
      let response = await fetch(baseUrl + "/v1.0/growth/bulk-notifications/active-requests", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "secure-id": token
        }
      });
      let respJson = await response.json();
      this.setState({
        activeRequests: respJson.response
      });
    } catch (e) {
      console.log(e)
    }
  }

  deactivateRequest = async (id) => {
    try {
      let response = await fetch(baseUrl + "/v1.0/growth/bulk-notifications/deactivate-request", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "secure-id": token
        },
        body: JSON.stringify({
          "bulk_notification_request_id" : id
        })
      });
      let respJson = await response.json();
      alert("Notification Request removed");
      window.location.reload();
    } catch (e) {
      console.log(e)
    }
  }

  //Utility Function
  

  getSubmitBodyParams = () => {

    let param = {}
    let channels = []

    if (this.state.submitParams.batchCount != null && !isNaN(this.state.submitParams.batchCount)) {
      param.batch_count = this.state.submitParams.batchCount
    } else {
      param.batch_count = defaultBatchCount
    }

    if (this.state.isPushNotificationEnabled === false && this.state.isTextMessageEnabled === false) {
      alert("Please select Push Notification or SMS Text to proceed.")
      return null
    }

    if (this.state.isPushNotificationEnabled === true) {
      if (this.state.submitParams.pushText === null) {
        alert("Please enter Push Text to send")
        return null
      } else {
        param.push_text = this.state.submitParams.pushText
        channels.push("push")
      }
    }

    if (this.state.isTextMessageEnabled === true) {
      if (this.state.submitParams.smsText === null) {
        alert("Please enter SMS Text to send")
        return null
      } else {
        param.sms_text = this.state.submitParams.smsText
        channels.push("sms")
      }
    }

    param.channels = channels

    if (this.state.submitParams.notificationType != null) {
      param.notification_type = this.state.submitParams.notificationType
    } else {
      alert("Please select the Notification Type.")
      return null
    }

    if (this.state.submitParams.isRegisteredUser != null) {
      param.registered = this.state.submitParams.isRegisteredUser
    }

    if (this.state.isMobileUsers != null) {
      param.mobile_users = this.state.isMobileUsers
    }

    if (this.state.allSubscribedUsers != null) {
      param.assist_subscribed = this.state.allSubscribedUsers
      if (this.state.allSubscribedUsers === true && this.state.stripePlansSelected != null) {
        param.assist_subscribed_plans = this.state.stripePlansSelected.map(x => 
            x.planId
          )
      }
    }

    if (this.state.submitParams.fromDate && this.state.submitParams.toDate) {
      console.log(this.state.submitParams.fromDate)
      console.log(this.state.submitParams.toDate)
      param.date_start = this.state.submitParams.fromDate
      param.date_end = this.state.submitParams.toDate
    }

    if (this.state.isTestMode === true) {
      if (this.state.submitParams.testEmail !== null) {
        param.test_email = this.state.submitParams.testEmail
        param.is_test = true
        return param
      } else {
        alert("You should enter the email in test mode")
        return null
      }
    } else {
      return param
    }
  }

  getCombinedPlansForDropDown = plans => {
    console.log({ plans });
    var combinedPlans = [
      ...plans.single_plans.rtl,
      ...plans.single_plans.blu,
      ...plans.team_plans.rtl,
      ...plans.team_plans.blu
    ];

    let groupedPlans = combinedPlans.map(x => ({
      id: x.id,
      name: x.name,
      type: x.plan_tier + " - " + x.plan_type + " - " + x.product_nickname,
      planId: x.stripe_plan_id
    }));
    return groupedPlans;
  };

  stripePlanSelected = (optionList, removedItem) => {};

  loadStyles = () => {
    Promise.all([
      // import("bootstrap/dist/css/bootstrap.min.css"),
      // import("shards-ui/dist/css/shards.min.css"),
    ]).then((bootstrap, shards) => {

    })
  }

  async componentDidMount() {
    try {
      //await this.getStripePlans();
      //await this.getNotificationTypes();
      await this.getActiveRequests();
      //this.loadStyles()
    } catch (e) {
      console.log(e);
    }
  }

  handleTextAreaVisibility = (e, param) => {
    if (param === "text") {
      this.setState({
        isTextMessageEnabled: !this.state.isTextMessageEnabled,
        isPushNotificationEnabled: this.state.isTextMessageEnabled
      });
    } else if (param === "push") {
      this.setState({
        isPushNotificationEnabled: !this.state.isPushNotificationEnabled,
        isTextMessageEnabled: this.state.isPushNotificationEnabled
      });
    }
  };

  handleTextMessageCheckChange = e => {
    this.setState({
      isTextMessageEnabled: !this.state.isTextMessageEnabled
    });
  };

  toggleTestMode = e => {
    this.setState({
      isTestMode: !this.state.isTestMode
    });
  };

  toggleallSubscribedUsers = e => {
    if (e.target.value === 'subscribed') {
      this.setState({
        allSubscribedUsers: true
      })
    } else if (e.target.value === 'unsubscribed') {
      this.setState({
        allSubscribedUsers: false
      })
    } else {
      this.setState({
        allSubscribedUsers: null
      })
    }
  }

  updatePlatform = (e, param) => {
    this.setState({
      isMobileUsers: param
    })
  }

  handleUserTypeChange = e => {
    let isRegistered = null
    if (e.target.value === 'registered') {
      isRegistered = true
    } else if (e.target.value === 'unregistered') {
      isRegistered = false
    }
    this.setState({
      submitParams: {
        ...this.state.submitParams,
        isRegisteredUser: isRegistered
      },
      userType: e.target.value,
    })
  }

  handleNotificationsTypeChange = e => {
    this.setState({
      submitParams: {
        ...this.state.submitParams,
        notificationType: e.target.value
      }
    })
  }

  toggleModal = e => {
    this.setState({
      notifyCountModelOpen: !this.state.notifyCountModelOpen
    })
  }

  modalCancelClicked = e => {
    this.setState({
      notifyCountModelOpen: false
    })
  }

  modalYesClicked = e => {
    this.setState({
      notifyCountModelOpen: false
    })
    this.submitForPushNotification()
  }

  hideSendButton = (hidden) => {
    this.setState({
      shouldHideSendButton: hidden
    })
  }

  updatePushText = e => {
    this.setState({
      submitParams: {
        ...this.state.submitParams,
        pushText: e.target.value
      }
    })
  }

  updateSMSText = e => {
    this.setState({
      submitParams: {
        ...this.state.submitParams,
        smsText: e.target.value
      }
    })
  }

  onPlanSelect = (optionsList, selectedItem) => {
    console.log(optionsList)
    console.log(selectedItem)
    this.setState({
      stripePlansSelected: optionsList
    })
  }

  setFromDate = (e) => {
    this.setState({
      submitParams: {
        ...this.state.submitParams,
        fromDate: new Date(e.target.value).getTime()/1000
      }
    })
  }

  setToDate = (e) => {
    this.setState({
      submitParams: {
        ...this.state.submitParams,
        toDate: new Date(e.target.value).getTime()/1000
      }
    })
  }

  setBatchCount = (e) => {
    this.setState({
      submitParams: {
        ...this.state.submitParams,
        batchCount: e.target.value
      }
    })
  }

  onActiveThreadClicked = (e, payload) => {
    this.setState({
      clickedActive: payload
    })
  }


  //Render functions

  askConsent = (
    <Modal open={this.state.notifyCountModelOpen} toggle={this.toggleModal}>
          <ModalHeader> Alert!!</ModalHeader>
            <ModalBody>
              👋 You will be sending this Notification to {this.state.notifyCount} people. Are you sure want to proceed ?
              <br />
              <Button pill onClick={this.modalYesClicked}>Yes</Button>
              &nbsp;
              <Button pill onClick={this.modalCancelClicked}>No, Abort</Button>
            </ModalBody>
        </Modal>
  )


  render() {
      let loading = this.state.loading;
    var activeRequests = this.state.activeRequests.map(x => 
    <li key={x.id}>
      <Button outline squared theme="light" onClick={() => {
        this.setState({ activeRequestId: x.id})
      } }> { x.id } {x.payload.notification_type} - {x.payload.channels} - {x.payload.push_text} / {x.payload.sms_text} </Button><br/>
      <div hidden={x.id !== this.state.activeRequestId}>
      <Button onClick={() => this.deactivateRequest(x.id)}>Stop Notification</Button>
      <ol>
      <li> Id: {x.id} </li>
      <li> PushText: {x.payload.push_text} </li>
      <li> SMS Text: {x.payload.sms_text} </li>
      <li> Channels: {x.payload.channels} </li>
      <li> Type: {x.payload.notification_type} </li>
      <li> Created at: {x.created_at} </li>
      <li> Batch Count: {x.batch_count} </li>
      <li> Total: {x.total} </li>
      <li> Registered: {x.payload.registered ? "Yes" : "No"} </li>
      <li> Assist Subscribed: {x.payload.assist_subscribed ? "Yes" : "No"} </li>
      <li> Assist Subscribed Plans: {x.payload.assist_subscribed_plans != null ? x.payload.assist_subscribed_plans.join(', ') : "No Plans"} </li>
      </ol>
      </div>
      </li>
    )

    return (
        <div style={{ paddingTop: "60px" }}>
            <h3 style={{margin: '5px', textAlign: "center"}}> Send Sms & Push Notification </h3>
            <br/><br/>

          <div style={filterStyle}>

              <Container style={contentStyle}>
                  <Row>
                      <Col>
                      <FormGroup>
                          <label>Text Message</label>
                          <FormRadio
                          inline
                          onChange={e => this.handleTextAreaVisibility(e, "text")}
                          checked={this.state.isTextMessageEnabled}>
                          {" "}
                        </FormRadio>
                          <label>Push Notification{" "}</label>
                        <FormRadio
                              inline
                              onChange={e => this.handleTextAreaVisibility(e, "push")}
                              checked={this.state.isPushNotificationEnabled}>
                              {" "}
                        </FormRadio>
                      </FormGroup>

                          <br/>

                      <label>Body:</label>
                          <br/>
                      <FormTextarea
                          style={{"width": "500px", height: '100px'}}
                          hidden={!this.state.isTextMessageEnabled}
                          size="lg"
                          onChange={this.updateSMSText}
                          value={this.state.submitParams.smsText}/>

                      <FormTextarea
                          hidden={!this.state.isPushNotificationEnabled}
                          size="lg"
                          style={{"width": "500px", height: '100px'}}
                          onChange={this.updatePushText}
                          value={this.state.submitParams.pushText}/>

                      </Col>
                  </Row>
              </Container><br/>

              <Container style={contentStyle}>

                  <Row>
                      <label>Test Notification:</label>
                      <FormCheckbox
                          toggle
                          checked={this.state.isTestMode}
                          onChange={this.toggleTestMode}
                      >&nbsp;&nbsp;
                      </FormCheckbox>
                  </Row><br/>
              </Container>

              <Container style={contentStyle}>
                  <Row hidden={!this.state.isTestMode}>
                      <br/>
                      <label>Test User Email:</label>&nbsp;&nbsp;
                      <Input id="test_email" placeholder="Email" onChange={e =>
                          this.setState(
                              {
                                  submitParams: {
                                      ...this.state.submitParams,
                                      testEmail: e.target.value
                                  }
                              }
                          )
                      }/><br/><br/>
                  </Row><br/>
              </Container>

              <Container style={contentStyle}>
                  <Row>
                      <label>Type of Notification:</label>&nbsp;&nbsp;
                      <select className="dropdown" onChange={this.handleNotificationsTypeChange} value={"appUpgrade"}>
                          {
                              this.state.notificationTypes.map(
                                  x => <option key={x.type} value={x.type}>{x.type_display_name}</option>)
                          }
                      </select>

                      <br/>
                      <br/>
                  </Row>
              </Container>

              <Container hidden={this.state.isTestMode} style={contentStyle}>
                  <Row>
                      <label>Type of User:</label>&nbsp;&nbsp;
                      <select className="dropdown" onChange={this.handleUserTypeChange} value={this.state.userType}>
                          <option value="registered"> Registered</option>
                          <option value="unregistered"> Non Registered </option>
                          <option value="all"> All Users </option>
                      </select>
                  </Row>
                  <br/>
                  {/*<Row>*/}
                  {/*<strong>Subscribed Users ?</strong>*/}
                  {/*<FormSelect onChange={this.toggleallSubscribedUsers}>*/}
                  {/*<option value="all"> Both </option>*/}
                  {/*/!*<option value="subscribed"> Subscribed Only </option>*!/*/}
                  {/*/!*<option value="unsubscribed"> Un Subscribed Only</option>*!/*/}
                  {/*</FormSelect>*/}
                  {/*</Row>*/}
                  {/*<Row hidden={!this.state.allSubscribedUsers}>*/}
                  {/*<strong>*/}
                  {/*Select the plan to filter the user with the below plans*/}
                  {/*</strong>*/}
                  {/*{this.state.stripePlans && (*/}
                  {/*<Multiselect*/}
                  {/*hidden={this.state.allSubscribedUsers}*/}
                  {/*options={this.state.stripePlans}*/}
                  {/*selectedvalues={this.state.stripePlansSelected}*/}
                  {/*groupBy="type"*/}
                  {/*onSelect={this.onPlanSelect}*/}
                  {/*displayValue="name" */}
                  {/*showCheckbox={true}*/}
                  {/*closeIcon="canel"*/}
                  {/*/>*/}
                  {/*)}*/}
                  {/**/}
                  {/*</Row>*/}

                  {/*<Row>*/}
                  {/*<FormGroup>*/}
                  {/*<br />*/}
                  {/*<br />*/}
                  {/*<strong>Platfrom</strong>*/}
                  {/*<br />*/}
                  {/*<FormCheckbox inline checked={this.state.isMobileUsers === true} onChange={e => this.updatePlatform(e, true)}> Mobile Users </FormCheckbox>*/}
                  {/*<FormCheckbox inline checked={this.state.isMobileUsers === false} onChange={e => this.updatePlatform(e, false)}> Non Mobile Users </FormCheckbox>*/}
                  {/*<FormCheckbox inline checked={this.state.isMobileUsers === null} onChange={e => this.updatePlatform(e, null)}> All </FormCheckbox>*/}
                  {/*</FormGroup>*/}
                  {/*</Row>*/}

                  {/*<br />*/}
                  {/*<Row>*/}
                  {/*<strong>Sign up date range</strong>*/}
                  {/*<Col>*/}
                  {/*<label>From </label>*/}
                  {/*<FormInput id="test_email" placeholder="From Data" type="date" onChange={this.setFromDate}/>*/}
                  {/*</Col>*/}
                  {/*<Col>*/}
                  {/*<label>To </label>*/}
                  {/*<FormInput id="test_email" placeholder="From Data" type="date" onChange={this.setToDate}/>*/}
                  {/*</Col>*/}
                  {/*</Row>*/}
                  {/*<Row>*/}
                  {/**/}
                  {/*</Row>*/}
                  {/*<br/><br/>*/}

                  {/*<strong> Active Push & SMS Requests </strong>*/}

              </Container>

              <br/>

              <Container>
                  <Row>
                      <label>Number of SMS/Notifications per day: </label>&nbsp;&nbsp;
                      <Input style={{width:'200px'}} placeholder="Number of SMS per day" onChange={this.setBatchCount} />
                  </Row>
              </Container>

              <br/>

              {loading ? <DefaultLoader/> :

              <div style={buttonStyle}>
                  <Button pill onClick={this.getNotificationAgentsCount}
                          hidden={this.state.shouldHideSendButton}>Send</Button>
                  <br/>
                  <br/>
              </div>
              }
              <Modal open={this.state.notifyCountModelOpen} toggle={this.toggleModal}>
                  <ModalHeader> Alert!! </ModalHeader>
                  <ModalBody>
                      👋 You will be sending this Notification to {this.state.notifyCount} people. Are you sure
                      want to proceed ?
                      <br/>
                      <Button pill onClick={this.modalYesClicked}>Yes</Button>
                      &nbsp;
                      <Button pill onClick={this.modalCancelClicked}>No, Abort</Button>
                  </ModalBody>
              </Modal>

              <br/>
              <h3>Active requests</h3>
              {activeRequests}

          </div>
        </div>
    );
  }
}
export default AgentNotificationsDashboard;