import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { get } from "lodash"
import AsyncSelect from 'react-select/async';
import TextInput from "../../Comp/TextInput"
import { JSON } from "window-or-global";
import { normalizePhone, validateEmail } from "services/Utils.js"

const Wrap = styled.div`
  display: flex;
  opacity: 0.4;
  pointer-events: none;
  flex-wrap: wrap;

  ${props => props.isEnable && `
    opacity: 1;
    pointer-events: inherit;
  `}
`

const Label = styled.div`
  color: #A3A3A3;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;
  height: 16px;
`

const ClientItem = styled.div`
  border: 1px solid #D3DAE8;
  border-radius: 20px;
  width: 350px;
  padding: 20px;
  margin-right: 20px;
  min-height: 200px;
  padding-bottom: 20px;
  position: relative;
  margin-top: 20px;
  input {
    margin-top: 12px;
  }

  ${props => props.isAdd && `
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px #D3DAE8 dashed;
    cursor: pointer;
  `}

  ${props => props.disable && `
     display: none;
   `}
`

const RemoveBTN = styled.p`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 16px;
  color: red;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
`

const AddNewClient = ({
 addClient,
 disable,
}) => (
  <ClientItem disable={disable} isAdd onClick={addClient}>
    <span>+ Click to add more client</span>
  </ClientItem>
)

const ClientReselector = ({
  handleInput,
  errorFields,
  addClient,
  removeClient,
  agentId,
  searchClientByText,
  clients,
  secureIdForTC,
}) => {
  const [fetchedClient, setFetchedClients] = useState([])
  const [errorIndex, setErrorIndex] = useState()
  const isEnable = agentId && secureIdForTC

  const handleSearchText = (text) => {
    // searchClientByText({
    //   text: value,
    //   secureId: secureIdForTC,
    //   agentId,
    // })
    console.log({ text });
    //loadOptions(text)
  }

  const loadOptions = (text, callback) => {
    try {
      const baseUrlV2 = `${process.env.REACT_APP_API_BASE_URL}/v2.0`;
      const payload = {
        text,
        sort_by: "created_at",
        ascending: false,
        aggregationNotRequired: false,
        start: 0,
        agentId: Number(agentId),
        end: 10,
      }
      const json = fetch(`${baseUrlV2}/clients/search`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "internal-id": "e82f206e5238e9cfbfb613e7a298be34",
          "content-type": "application/json",
        },
      }).then(res => res.json())
      const { clients } = json.response
      if (clients && clients.length > 0) {
        const clientList = clients.map(x => ({
          label: x.name,
          value: {
            name: x.name,
            phone: get(x, "phones[0].phone"),
            email: get(x, "emails[0].email"),
            client_id: x.id,
          }
        }))
        setFetchedClients(clientList)
        callback()
      }
    } catch (error) {

    }
  }

  const handleUpdate = (text, field, index) => {
    const newClient = [...clients]
    newClient[index][field] = text
    handleInput(handleInput)
  }

  return (
    <Wrap isEnable={isEnable || true}>
      {clients && clients.map((item, index) => (
        <ClientItem>
          <Label>
            Client
          </Label>
          {index > 0 && (
            <RemoveBTN onClick={() => { removeClient(index) }}>Remove</RemoveBTN>
          )}
          {/* <AsyncSelect
            cacheOptions={fetchedClient}
            loadOptions={loadOptions}
            defaultOptions
            options={fetchedClient}
            onInputChange={handleSearchText}
          /> */}
          <TextInput
            name="name"
            placeholder="Client Name"
            value={item.name}
            onChange={(e) => { handleUpdate(e.target.value, e.target.name, index) }}
            isHideLabel
          />
          <TextInput
            name="email"
            type="email"
            onChange={(e) => { handleUpdate(e.target.value, e.target.name, index) }}
            placeholder="Client Email"
            value={item.email}
            isHideLabel
          />
          <TextInput
            name="phone"
            placeholder="Client Phone"
            value={item.phone}
            onChange={(e) => { handleUpdate(e.target.value, e.target.name, index) }}
            isHideLabel
          />
        </ClientItem>
      ))}

      <AddNewClient addClient={() => { addClient() }} disable={clients && clients.length === 4} />
    </Wrap>
  )
}

export default ClientReselector
