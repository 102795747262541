/* eslint-disable max-len */
import React, { useState, useEffect } from "react"
import InfiniteScroll from "react-infinite-scroller"
import styled from "styled-components"
import { Link, withRouter } from "react-router-dom"
import moment from "moment"
import { addMinutes } from "date-fns"
import {
  Button as UploadImageButton,
} from "antd"
import localStorageUtil from "services/localstorageUtil"
import Select from "react-select"
import { get, capitalize, throttle } from "lodash"
import cookie from "react-cookies"
import Button from "dumbComponents/common/UI/Button"
import globalContainer from "container/BrokerDashboard/globalPagesContainer"
import { formatSelectedValue } from "services/Utils"
import { Row, Col, LabelAndText } from "dumbComponents/BrokerDashboard/AgentDashboard/ViewDeal"
import ConfirmModal from "dumbComponents/UI/ConfirmModal"
import Loader from "dumbComponents/common/UI/Loader"
import TextInput from "dumbComponents/BrokerDashboard/Comp/TextInput"
import { SectionWrap } from "dumbComponents/BrokerDashboard/AgentDashboard/ViewDeal/DetailedViewComponent"
import { TC_STATUS, getDefaultStatus, NEW_LISTING_SPECIFIC_STATUS, ACTIVE_LISTING_SPECIFIC_STATUS } from "dumbComponents/BrokerDashboard/GlobalPages/Deals/consts"
import DealCloseConfirmModal from "dumbComponents/BrokerDashboard/GlobalPages/Deals/DealCloseConfirmModal"
import DealDemandConfirmModal from "../GlobalPages/Deals/DealDemandConfirmModal"
import { USCurrencyFormat } from "../../../shared/currencyUtils"
import { setStatic } from "recompose"
import UploadImageDrawer from "./UploadImageDrawer"
import root from "window-or-global" 
import TimelineChecklist from "./TimelineChecklist"

const baseUrl = process.env.REACT_APP_API_BASE_URL
const token = process.env.REACT_APP_SECRET_ID

const Wrap = styled.div`
  margin: 0px 20px;
`

const DealList = styled.div`
`

const Genraltab = styled.div`
  width: 120px;
  padding: 10px;
  display: inline-block;
  vertical-align: top;
  ${props => props.isFixed && `
    width: 200px;
  `}
  ${props => props.isDropdown && `
    width: 300px;
    padding: 10px;
  `}
  ${props => props.isPrice && `
    width: 150px;
    padding: 9px;
  `}
  ${props => props.isStatus && `
  width: 250px;
  padding: 10px;
`}
${props => props.isDeal && `
  width: 150px;
  padding: 10px;
`}
${props => props.isSticky && `
  position: sticky;
  z-index: 100;
  left: 0;
  background: #f1f1f1;
`}
${props => props.isStickyList && `
  position: sticky;
  z-index: 11;
  left: 0;
  background: white;
`}
${props => props.isRent && `
    width: 200px;
  `}
  > p {
    margin: 0px;
  }
`

const ListItem = styled.div`
  border-bottom: 1px solid #ccc;
  width: max-content;
  display: flex;
  ${props => props.isHead === true && `
    background: #f1f1f1;
    margin-top: 20px;
    ${Genraltab} {
      > p {
        font-weight: bold;
      }
      position: sticky;
      z-index: 100;
    }
  `}

  ${props => props.isHead === false && `
    background: #f1f1f1;
    margin-top: 20px;
    ${Genraltab} {
      > p {
        font-weight: bold;
      }
      position: sticky;
      top: 59px;
      z-index: 100;
    }
  `}

  

  ${props => props.isClickable && `
    &:hover {
      background: #eee;
      transition-duration: 0.3s;
      cursor: pointer;
    }
  `}
`

const LightP = styled.p`
  font-size: 14px;
  color: #666;
`

const RFType = styled.div`
  display: inline-block;
  padding: 4px;
  font-size: 10px;
  background: #009688;
  color: #fff;
  border-radius: 4px;
`

const SortableCol = styled.div`
  display: flex;
  cursor: pointer;
  > div {
    &:nth-child(1) {
      flex: 1;
      p {
        padding: 0px;
        margin: 0px;
      }
    }
  }
`

const UploadButton = styled.div`
  label {
    background-color: #27AE60;
    color: #fff;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;

    ${props => props.isUploading && `
      background-color: #999;
    `}

  }
  input[type="file"] {
    display: none;
  } 
  `
const TCNotAssigned = styled.div`
  display: flex;
  flex-direction: row;
`

const EditButton = styled.button`
    background-color: #27AE60;
    color: #fff;
    padding: 5px 10px 5px 10px;
    margin-left: 10px;
    border-radius: 5px;
    border: 0;
    cursor: pointer;
    font-size: 12px;
`

const getLabel = value => value && value.replace(value[0], value[0]
  .toUpperCase())
  .replaceAll("_", " ")

const Item = ({
  data,
  history,
  isShowActive,
  saveStepsForProcuct,
  sortTCList,
  sortData,
  isAgentPage,
  updateCommissionDemand,
  deleteCommissionDemand,
  loadMore,
  isLoadingMore,
  triggerFetchDeals,
  hasMore = true,
  getTCListResponse,
  createDeals,
  getTCList,
  updateTCAgent,
  sendSlackNotifyOnStatusChange,
  handleRefreshPage,
  showTCLoader,
  getContractStatus,
  getContractStatusResponse,
  saveFileTypeChange,
  isUploadImage,
  toggleUploadImageDrawer,
  setDealIdToUpload,
  dealIdToUpload,
  toggleTimelineChecklist,
  setDealIdTimeline,
  dealIdTimeline,
}) => {
  const [dealSelected, setDealSelected] = useState()
  const [stepValue, setStepValue] = useState()
  const [isUploadingCommissionDemand, setIsUploadingCommissionDemand] = useState(false)

  const handleStatusUpdate = (item, selectedValue) => {
    const isConfirm = window.confirm("Are you sure?")
    setStepValue(selectedValue.value)
    const user = cookie.load("user")
    if (!isConfirm) {
      return
    }
    if (selectedValue.value === "closed" || selectedValue.value === "demand_pending" || selectedValue.value === "demand_completed") {
      setDealSelected(item)
      return
    }

    const statContract = ContractStatus.filter(x => x.value === selectedValue.value) || null

    const statList = ListingStatus.filter(x => x.value === selectedValue.value && selectedValue.label !== "Contract Submitted") || null


    if (selectedValue.value !== "active_listing" && selectedValue.value !== "listing_verified") {
      let rowCreatedAt
      if (item.row_created_at === null) {
        rowCreatedAt = moment().format("YYYY-MM-DD HH:mm:ss")
      } else {
        rowCreatedAt = item.row_created_at
      }
      const payload = {
        ...item,
        row_created_at: rowCreatedAt,
      }
      delete payload.date_of_listing_submission
      if (item.radius_transaction_type === null) {
        delete payload.radius_transaction_type
      } else {
        payload.radius_transaction_type = item.radius_transaction_type.value
      }
      showTCLoader(true)
    }
    
    if (selectedValue.value === "contract_canceled" && item.client_type === "seller" && item.file_creation_flow ==="listing") {
      const isActive = window.confirm(`Since you are changing the status of a Contract to ${selectedValue.label}, would you like to generate a Listing for the same?`)
      if (isActive) {
        saveStepsForProcuct({
          payload: {

            deal_id: item.id,
            step: selectedValue.value,
            generate_listing: 1,
          },
          callback: () => {
            triggerFetchDeals()
            showTCLoader(false)
          },
        })

      }
      showTCLoader(false)
    }

    saveStepsForProcuct({
      payload: {
        deal_id: item.id,
        step: selectedValue.value,
        generate_listing: 0,
      },
      callback: () => {
        if (selectedValue.value === "audit_verified" || selectedValue.value === "demand_pending") {
          sendSlackNotifyOnStatusChange({
            agentId: item.agent_id,
            dealId: item.id,
            dealStatus: selectedValue.value,
            listingId: null,
          })
        }
        triggerFetchDeals()
      },
    })
  }

  const TimeZone = (date, isEpoch) => {
    if (isEpoch === 1) {
      const newDate = moment.unix(date)
      const localeDateString = moment(newDate.toLocaleString()).format("D MMM, YYYY")
      return localeDateString
    }
    const localeDateString = moment(date.toLocaleString()).format("D MMM, YYYY")
    return localeDateString
  }

  const assignTCtoDeal = async (item, tcId) => {
    updateTCAgent({
      dealId: item.id,
      tcId,
    })
  }

  const updateTCStatus = (item) => {
    if (item.radius_tc_firstname === null && item.radius_tc_lastname === null) { return { label: "TC not assigned" } }
    return { label: `${item.radius_tc_firstname} ${item.radius_tc_lastname}`, value: item.assigned_tc_id }
  }

  const uploadfileGetURL = async (file) => {
    try {
      const BUCKET = "commision_demand"
      const signedRes = await fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=${BUCKET}&filename=${file.name}`)
      const myHeaders = new Headers({ "Content-Type": file.type })
      const signedResJson = await signedRes.json()
      const uploadRes = await fetch(signedResJson.url, {
        method: "PUT",
        headers: myHeaders,
        body: file,
      })
      return signedResJson.fileUrlAfterUpload
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleRowClick = (item, event) => {
    const parsedTC = JSON.stringify(item)
    const now = new Date()
    const expiryDate = addMinutes(now, 2)
    localStorageUtil.setItem(
      `deal_${item.id}`,
      item,
      expiryDate,
    )
    window.open(`/broker/dashboard/${item.agent_id}/deals/view/${item.id}?fromContract`)
  }

  const handleModalConfirm = () => {
    saveStepsForProcuct({
      payload: {
        deal_id: dealSelected.id,
        step: stepValue,
      },
      callback: () => {
        if (stepValue === "demand_pending") {
          sendSlackNotifyOnStatusChange({
            agentId: dealSelected.agent_id,
            dealId: dealSelected.id,
            dealStatus: stepValue,
            listingId: null,
          })
        }
        triggerFetchDeals()
        root.location.reload()
      },
    })
    setDealSelected(false)
  }

  const TCAssignmentChange = (item) => {
    const payload = {
      fileType: item.file_type,
      is_using_deal_tc: 1,
    }
    if (item.radius_transaction_type === null) {
      delete payload.radius_transaction_type
    } else {
      payload.radius_transaction_type = item.radius_transaction_type.value
    }
    saveFileTypeChange({
      dealId: item.id,
      payload,
      isToListing: "invalid",
      callback: () => {
        triggerFetchDeals()
      },
    })
  }

  const handleFileUpload = async (item, e) => {
    setIsUploadingCommissionDemand(item.id)
    const { files } = e.target
    const url = await uploadfileGetURL(files[0])
    const payload = {
      deal_id: item.id,
      url,
    }
    updateCommissionDemand({
      payload,
      callback: () => {
        setIsUploadingCommissionDemand(false)
      },
    })
    if (item.step === "demand_pending") {
      const isConfirm = window.confirm("Change Deal Status to Demand Completed?")
      if (!isConfirm) {
        return
      } else {
        setDealSelected(item)
        setStepValue("demand_completed")
        return
      }
    }
  }

  useEffect(() => {
    getTCList()
    getContractStatus()
  }, [])
  const removeEscapeChar = (state) => {
    try {
      const final_state = state.replace(/\\/g, "")
      return final_state
    } catch (e) {
      return console.log("error", e)
    }
  }
  const ContractStatus = getContractStatusResponse.data && getContractStatusResponse.data.contractStatus && getContractStatusResponse.data.contractStatus.length > 0 && getContractStatusResponse.data.contractStatus.map(x => (
    { label: x.display_name, value: x.value }
  ))

  const ListingStatus = getContractStatusResponse.data && getContractStatusResponse.data.listingStatus && getContractStatusResponse.data.listingStatus.length > 0 && getContractStatusResponse.data.listingStatus.map(x => (
    { label: x.display_name, value: x.value }
  ))

  const getDefaultStatusContract = (listingValue, stepValue) => {
    if (getContractStatusResponse.data && (listingValue || stepValue)) {
      const selectedItem = ContractStatus.find(x => x.value === stepValue)
      return selectedItem
    }
    return {
      label: "Incomplete Contract", value: "incomplete",
    }
  }

  const getDefaultListingStatus = (listingValue, stepValue) => {
    if (getContractStatusResponse.data && (listingValue || stepValue)) {
      if (!listingValue) {
        return {
          label: "None", value: null,
        }
      }
      const selectedItem = ListingStatus.find(x => x.value === listingValue)
      return selectedItem
    }
  }

  //temp
  const getContractStatusOptions = (ContractStatusOptions, completedSteps) => {
    if (ContractStatusOptions && completedSteps) {
      if (completedSteps.includes("submitted")) {
        const a = ContractStatusOptions.filter(x => x.value === "incomplete")
        console.log("hello", completedSteps, a)
      }
    }
  }

  const TCListfinal = getTCListResponse.data && getTCListResponse.data.length > 0 && getTCListResponse.data.map(x => ({ label: `${x.tc_firstname} ${x.tc_lastname}`, value: x.id }))
  return (
    <Wrap>
      {!!dealSelected && stepValue === "closed" && (
        <DealCloseConfirmModal
          onConfirm={() => { handleModalConfirm(dealSelected) }}
          onCancel={() => {
            setDealSelected(false)
          }}
          isDisableConfirm={!dealSelected.confirmed_closed_on}
          dealSelected={dealSelected}
          onEdit={() => {
            history.push({
              pathname: `/broker/dashboard/${dealSelected.agent_id}/deals/edit/${dealSelected.id}?checkPricing=true`,
              state: {
                deal: dealSelected,
              },
            })
          }}
        />
      )}
      {(stepValue === "demand_pending" || stepValue === "demand_completed") && !!dealSelected && (
         <DealDemandConfirmModal
           onConfirm={() => { handleModalConfirm(dealSelected) }}
           onCancel={() => {
             setDealSelected(false)
           }}
           dealSelected={dealSelected}
           onEdit={() => {
             history.push({
               pathname: `/broker/dashboard/${dealSelected.agent_id}/deals/edit/${dealSelected.id}?checkPricing=true`,
               state: {
                 deal: dealSelected,
               },
             })
           }}
         />
      )}
      <ListItem isHead={isAgentPage ? true : false}>
        <Genraltab isStatus isFixed>
          <p>
            Contract Status
          </p>
        </Genraltab>
        <Genraltab isFixed>
          <SortableCol onClick={() => { sortTCList && sortTCList("rowCreatedAtSortOrder", sortData && sortData.rowCreatedAtSortOrder) }}>
            <div>
              <p>
                Date of Contract Submission
              </p>
            </div>
            {!isAgentPage && (
              <div>
                <i className={`fa fa-arrow-${sortData && sortData.rowCreatedAtSortOrder === "ASC" ? "up" : "down"}`}></i>
              </div>
            )}
          </SortableCol>
        </Genraltab>
        <Genraltab>
          <p>
            Agent Name
          </p>
        </Genraltab>
        <Genraltab isFixed>
          <p>
            Preferred Email
          </p>
        </Genraltab>
        <Genraltab isFixed isSticky>
          <p>
            Address
          </p>
        </Genraltab>
        <Genraltab isFixed>
          <p>
            Plan Category
          </p>
        </Genraltab>
        <Genraltab isFixed>
          <p>
            Client Detail
          </p>
        </Genraltab>
        <Genraltab isFixed>
          <p>
            Contract Timeline
          </p>
        </Genraltab>
        {!isShowActive && (
          <Genraltab isFixed>
            <SortableCol onClick={() => { sortTCList && sortTCList("createdAtSortOrder", sortData && sortData.createdAtSortOrder) }}>
              <div>
                <p>
                  Date of Contract
                </p>
                <h6 style={{ margin: 0, padding: 0 }}>(Acceptance Date)</h6>
              </div>
              {!isAgentPage && (
                <div>
                  <i className={`fa fa-arrow-${sortData && sortData.createdAtSortOrder === "ASC" ? "up" : "down"}`}></i>
                </div>
              )}
            </SortableCol>
          </Genraltab>
        )}
        <Genraltab isFixed>
          <p>
            Confirmed/Tentative Closed Date
          </p>
        </Genraltab>
        <Genraltab isFixed>
          <p>
            Estimated Closed Date
          </p>
        </Genraltab>
        <Genraltab isFixed>
          <p>
            Agent States
          </p>
        </Genraltab>
        <Genraltab isFixed>
          <p>
            Commission Demand
          </p>
        </Genraltab>
        <Genraltab isFixed>
          <p>
            Agent Opt In
          </p>
        </Genraltab>
        <Genraltab isPrice>
          <p>
            Is Using Deal TC
          </p>
        </Genraltab>
        <Genraltab isDropdown>
          <p>
            TC Assigned
          </p>
        </Genraltab>
        <Genraltab isPrice>
          <p>
            Cap Amount
          </p>
        </Genraltab>
        <Genraltab isPrice>
          <p>
            License Transferred Date
          </p>
        </Genraltab>
        <Genraltab isPrice>
          <p>
            Sales Price
          </p>
        </Genraltab>
        <Genraltab isPrice>
          <p>
            List Price
          </p>
        </Genraltab>
        <Genraltab isPrice>
          <p>
            Rent Amount
          </p>
        </Genraltab>
        <Genraltab isRent>
          <p>
            Rent Amount Term
          </p>
        </Genraltab>
        <Genraltab isRent>
          <p>
            Other Rent Amount Term
          </p>
        </Genraltab>
        <Genraltab isPrice>
          <p>
            Gross Commission
          </p>
        </Genraltab>
        <Genraltab isPrice>
          <p>
            Radius Fee
          </p>
        </Genraltab>
        <Genraltab isPrice>
          <p>
            Coupon Claimed
          </p>
        </Genraltab>
        <Genraltab isFixed>
          <p>
            Referral Source
          </p>
        </Genraltab>
      </ListItem>
      <DealList>
        <InfiniteScroll
          hasMore={hasMore}
          dataLength={data && data.length}
          loadMore={throttle(() => { loadMore() }, 4000)}
          threshold={250}
          loader={(
            <div>
              <Loader />
            </div>
          )}
        >
          <UploadImageDrawer dealId={dealIdToUpload} data={data} />
          <TimelineChecklist dealId={parseInt(dealIdTimeline, 10)} />

          {data && data.length && data.map(item => (
            <ListItem
              isClickable
              key={item.id}
            >
              
              <Genraltab isFixed isStatus>
                <Select
                  options={ContractStatus}
                  openMenuOnClick={(e) => { e.stopPropagation() }}
                  value={item && getDefaultStatusContract(item.listing_status, item.step)}
                  onChange={(selectedItem) => { handleStatusUpdate(item, selectedItem) }}
                />
              </Genraltab>
              
              <Genraltab isFixed onClick={() => { handleRowClick(item) }}>
                <p>
                  {item.row_created_at ? (TimeZone(item.row_created_at)) : "N/A"}
                </p>
              </Genraltab>
              
              <Genraltab onClick={() => { handleRowClick(item) }}>
                <p>
                  {`${item.agent_firstname || ""} ${item.agent_lastname || ""}`}
                </p>
              </Genraltab>
              <Genraltab isFixed>
                <p>
                  {item.preferred_email || "-"}
                </p>
              </Genraltab>
              <Genraltab isStickyList isFixed onClick={() => { handleRowClick(item) }}>
                <LightP>{`${item.listing_address}, ${item.city}, ${item.state}`}</LightP>
              </Genraltab>
              <Genraltab isFixed>
                <p>
                  {item.planCategory}
                </p>
              </Genraltab>
              <Genraltab isFixed onClick={() => { handleRowClick(item) }}>
               
                <p>{get(item, "clients[0].name", "") || get(item, "client_name", "") || ""}</p>
                <LightP>
                  {`${get(item, "clients[0].email", "") || get(item, "client_email", "") || ""}`}
                </LightP>
                <LightP>
                  {`${get(item, "clients[0].phone", "") || get(item, "client_phone", "") || ""}`}
                </LightP>
                {get(item, "clients.length") > 1 && (
                  <div style={{ fontSize: 12, fontWeight: "700" }}>
                    {get(item, "clients.length") - 1}
                    {" "}
more clients
                  </div>
                )}
                <RFType>
                  {capitalize(item.client_type)}
                </RFType>
              </Genraltab>
              <Genraltab isFixed>
                {item.show_timeline === 1 ? (
                  <>
                    <UploadImageButton
                      onClick={() => {
                        toggleTimelineChecklist(true)
                        setDealIdTimeline(item.id)
                      }}
                      type="primary"
                    >
                      Show Timeline
                    </UploadImageButton>
                  </>
                ) : (
                  "-"
                )}
              </Genraltab>
              {!isShowActive && (
                <Genraltab isFixed onClick={() => { handleRowClick(item) }}>
                  <p>
                    {item.created_at ? (TimeZone(item.created_at, 1)) : "N/A"}
                  </p>
                </Genraltab>
              )}
              <Genraltab isFixed onClick={() => { handleRowClick(item) }}>
                <p>
                  {item.confirmed_closed_on ? (TimeZone(item.confirmed_closed_on, 1)) : "N/A"}
                </p>
              </Genraltab>
              <Genraltab isFixed>
                <p>
                  {item.closed_on ? (TimeZone(item.closed_on, 1)) : "N/A"}
                </p>
              </Genraltab>
              {/*<Genraltab isFixed>
                {item.file_type === "new_listing" ? (
                  <>
                    <UploadImageButton
                      onClick={() => {
                        toggleUploadImageDrawer(true)
                        setDealIdToUpload(item.id)
                      }}
                      type="primary"
                    >
                      {item.listing_images !== null ? "View Images" : "Upload Images"}
                    </UploadImageButton>
                  </>
                ) : (
                  "-"
                )}
              </Genraltab> */}
              <Genraltab isFixed onClick={() => { handleRowClick(item) }}>
                {removeEscapeChar(item.active_state_served) || "-"}
              </Genraltab>
              <Genraltab isFixed>
                {item.commission_demand_url ? (
                  <React.Fragment>
                    <Button onClick={() => { window.open(encodeURI(item.commission_demand_url)) }}>
                      View
                    </Button>
                    <Button
                      danger
                      style={{ marginLeft: 5 }}
                      onClick={() => {
                        if (window.confirm("Are you sure?")) {
                          deleteCommissionDemand({ deal_id: item.id })
                        }
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </Button>
                  </React.Fragment>

                ) : (
                  <UploadButton isUploading={isUploadingCommissionDemand === item.id}>
                    <label htmlFor={`cus-upload-${item.id}`}>{isUploadingCommissionDemand === item.id ? "Uploading.." : "Upload"}</label>
                    <input type="file" id={`cus-upload-${item.id}`} onChange={(e) => { 
                      handleFileUpload(item, e)
                    }} />
                  </UploadButton>
                )}

                <UploadButton type="file" />
              </Genraltab>
              <Genraltab isFixed onClick={() => { handleRowClick(item) }}>
                <p>
                  {(item.is_using_radius_tc === "1" || item.is_using_radius_tc === 1) ? "Yes" : "No"}
                </p>
              </Genraltab>
              <Genraltab isFixed isDeal onClick={() => { handleRowClick(item) }}>
                <p>
                  {item.is_using_deal_tc === null ? "N/A" : (item.is_using_deal_tc === 1) ? "Yes" : "No"}
                </p>
              </Genraltab>
              <Genraltab isDropdown>
                {(item.is_using_deal_tc === 1) ? (
                  <React.Fragment>
                    <Select
                      options={TCListfinal}
                      // openMenuOnClick={(e) => { e.stopPropagation() }}
                      defaultValue={updateTCStatus(item)}
                      onChange={(selectedItem) => { assignTCtoDeal(item, selectedItem.value) }}
                    />
                  </React.Fragment>
                ) : (
                  <TCNotAssigned>
                    {"TC Not Assigned"}
                    <EditButton onClick={() => TCAssignmentChange(item)}>
                      Edit
                    </EditButton>
                  </TCNotAssigned>
                )}
              </Genraltab>
              <Genraltab isPrice onClick={() => { handleRowClick(item) }}>
                <p>
                  {USCurrencyFormat(item.cap_amount || 0)}
                </p>
              </Genraltab>
              <Genraltab isFixed onClick={() => { handleRowClick(item) }}>
                <p>
                  {item.license_transferred_date ? (TimeZone(item.license_transferred_date, 1)) : "N/A"}
                </p>
              </Genraltab>
              <Genraltab isPrice onClick={() => { handleRowClick(item) }}>
                <p>
                  {USCurrencyFormat(item.price || 0)}
                </p>
              </Genraltab>
              <Genraltab isPrice onClick={() => { handleRowClick(item) }}>
                <p>
                  {USCurrencyFormat(item.list_price || 0)}
                </p>
              </Genraltab>
              <Genraltab isPrice onClick={() => { handleRowClick(item) }}>
                <p>
                {item.rent_amount? USCurrencyFormat(item.rent_amount || 0): "N/A"}
                </p>
              </Genraltab>
              <Genraltab  isRent onClick={() => { handleRowClick(item) }}>
                <p>
                {item.rent_amount_term || "N/A"}
                </p>
              </Genraltab>
              <Genraltab isRent onClick={() => { handleRowClick(item) }}>
                <p>
                {item.other_rent_amount_term || "N/A"}
                </p>
              </Genraltab>
              <Genraltab isPrice onClick={() => { handleRowClick(item) }}>
                <p>
                  {USCurrencyFormat(item.gross_commission || 0)}
                </p>
              </Genraltab>
              <Genraltab isPrice onClick={() => { handleRowClick(item) }}>
                <p>
                  {USCurrencyFormat(item.radius_transaction_fee || 0)}
                </p>
              </Genraltab>
              <Genraltab isPrice>
                <p>
                  {item.coupon_claimed_on_file === 1 ? "Yes" : "No"}
                </p>
              </Genraltab>
              <Genraltab isPrice onClick={() => { handleRowClick(item) }}>
                <p>
                  {getLabel(item.source, "")}
                </p>
              </Genraltab>
            </ListItem>
          ))}
        </InfiniteScroll>

      </DealList>
    </Wrap>
  )
}

export default globalContainer(withRouter(Item))
