import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/ProductInterest"

const Wrap = styled.div`
  height: max-content;
  width: 100%;
`


class Header extends Component {
  componentDidMount(){
    // apiCalls
  }

  render(){
    return (
      <h1>New Features / Admin</h1>
    )
  }
}


export default Container(Header)
