import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import ImageUploader from 'react-images-upload';
import colors from "../../../lib/colors"
import Select from "react-select"
import AddEventMenu from "./AddEventsMenu"
import RTFEditor from "dumbComponents/Academy/Common/RTF"
import RTFEditorIO from "dumbComponents/Academy/Common/EditorRTF"
import AddEventForm from "./AddEventsForm"
import ThirdPartyLinkForm from "./ThirdPartyForm"
import DateAndTimeEventsForm from "./DayAndTime"
import EventRTF from "./EventRTF"
import _ from 'lodash';
import moment from 'moment-timezone';
import { DefaultLoader } from "components/UI/Loader/Loader";


const OuterWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`
const EditInstructor = styled(Link)`
  margin-bottom: 14px;
  width: 118px;
  display: block;
  height: 30px;
  font-size: 14px;
  border: none;
  background: none;
  color: black;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding-top: 2px;
`

const LeftMenu = styled.div`
  height: 73px;
  max-width: 188px;
  flex-grow: 1;
`

const ListWrap = styled.ul`
 list-style: none;
 padding:0;
 margin:0;
 li {
  width: 100%;
  height: 68px;
  border: solid 1px #e2e4e8;
  display: flex;
  .instructor_image {
    flex-grow: 1;
    height: 100%;
    max-width: 60px;
    padding-top: 14px;
    padding-left: 14px;
    img {
     width: 34px;
     height: 34px;
     border-radius: 18px;
    }
  }
  .instructor_details{
    height: 100%;
    padding: 14px;
    width: 222px;
   p {
    margin: 0;
   }
   p:last-child {
    font-size: 12px;
    color: #586069;
   }
  }
  .cta {
   height: 100%;
   width: 400px;
   display: flex;
   justify-content: flex-end;
   div {
    flex-grow: 1;
    height: 100%;
    padding: 23px 8px 8px 24px;
    max-width: 99px;
    button{
     cursor: pointer;
     background: none;
     border: none;
    }
    .delete {
     color: red;
    }
   }
  }
 }
`

const FeedbackForm = styled.div`
  textarea {
   resize: none;
   font-family: "SF Pro Display", sans-serif;
   width: 724px;
   height: 125px;
   border-radius: 3px;
   border: solid 1px #e3e4e8;
  }
`

const EventAttendees = styled.ul`
  list-style: none;
  padding: 0;

  li {
   display: flex;
   margin-top: 10px;
  }
`

const RightMenu = styled.div`
  height: 500px;
  width: 699px;
  padding-left: 24px;
  .title {
   .required {
    color: red;
   }
  }
  .row {
   display: flex;
   .selectWrap {
    margin-left: 56px;
    width: 250px;
    label {
     font-family: "SF Pro Display", sans-serif;
     font-size: 14px;
     font-weight: bold;
     font-stretch: normal;
     font-style: normal;
     line-height: normal;
     letter-spacing: normal;
     color: #24292e;
    }
   }
  }
  .imagePreview {
   img {
    width: 100%;
   }
  }
  .header {
   height: 50px;
   width: 100%;
   p {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin: 0;
   }
  }

  .inst {
   margin-top: 50px;
  }

  .event-image {
   height: 300px;
   width: 100%;
   .image-header-div {
    display: flex;
    justify-content: space-between;
    button {
     top: 10px;
     height: 30px;
     position: relative;
     left: 50px;
    }
   }
   .title {
    font-size: 14px;
   font-weight: bold;
   color: #24292e;
   }
   .image-placeholder {
    width: 730px;
    height: 242px;
    border-radius: 8px;
    margin-bottom: -97px;
    .fileContainer {
     padding: 50px 0;
     .uploadPicturesWrapper {
      position: absolute;
      .uploadPictureContainer{
        width: 30%;
      }
     }
    }
   }
  }
`

const TimeStamp = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #586069;
`

const Wrap = styled.div`
height: 100px;
width: 100px;
background: red;
`
const AttendeeImage = styled.div`
 img {
  width: 34px;
  height: 34px;
  border-radius: 44%;
 }
`

const AttendeeInfo = styled.div`
  padding: 4px 0px 0px 16px;
`
const AttendeeTitle = styled.h1`
  font-size: 14px;
  margin-top: -1px;
`

class AddEvent extends Component{
 state = {
  pictures: [],
  isUpdateMode: this.props.match.params.id,
 }

 onDrop = (picture, file) => {
    this.setState({
        pictures: this.state.pictures.concat(picture),
    });
 }

 initiateImageUpload = (id) => {
  const {
   pictures,
  } = this.state

  const {
   setEventHeaderImage,
   getEventDetailsResponse,
  } = this.props

  const {
   data: eventDetails,
  } = getEventDetailsResponse || {}

  const file = pictures[0]

  setEventHeaderImage({
   file,
   id,
  })

 }

 componentDidMount(){
  const {
   fetchMultipleInstructors,
   getEventDetails,
   getEventDetailsResponse,
   setCurrentEventType,
   addHostsToEvent,
   setPrivate,
  } = this.props
  getEventDetails({ id: this.props.match.params.id})
  fetchMultipleInstructors()

  const {
   data: eventDetails,
  } = getEventDetailsResponse


  const eventExists =
  this.props.match.params.id
  && eventDetails
  && Object.keys(eventDetails).length > 0

  const current = eventExists
  && eventDetails.type

  const currentHosts = eventExists && eventDetails.Instructors


  if (eventExists) {
   addHostsToEvent(currentHosts)
  }
 }

 handleEventType = (val) => {
   const {
    setCurrentEventType,
   } = this.props
   setCurrentEventType({
    currentEvent: val,
   })
 }

  getOptions = (instructorsData) => {
   if(!instructorsData || instructorsData.length <= 0) {
    return [{ value: "Loading", label: "Loading" }]
   } else {
    return instructorsData.length >= 0 && instructorsData.map(inst => {
     return {
       label: inst.name,
       value: inst.name,
       id: inst.id,
       instructor: inst,
     }
    })
   }
   return [{ value: "Loading", label: "Loading" }]
  }

  handleInstructorDelete = (id, instArr) => {
    const {
     addHostsToEvent,
    } = this.props
    let filtered = instArr.filter(function( obj ) {
       return obj.instructor.id !== id;
     });
     this.setState({
      deletionMode: true,
     })
     addHostsToEvent(filtered)
  }

  handlePrivateEvent = () => {
    const {
      isPrivate,
      setPrivate,
    } = this.props

    setPrivate({
      isPrivate: !isPrivate,
    })
  }

  handleChange = (e) => {
   const {
    saveSettingsValues,
   } = this.props

   this.setState({
    [e.target.name]: e.target.value
   })
   saveSettingsValues({
    data: e.target.value,
    type: e.target.name
   })
  }


  handleInstructorSelect = (value, uniqueArray) => {
    const {
     addHostsToEvent,
     hostsArray,
    } = this.props

    let instArr = [...uniqueArray]
    instArr.push({
     id: value.id,
     instructor: value.instructor,
    })
    let _instArr = _.uniqBy(instArr, function (e) {
    return e;
      });
    addHostsToEvent(_instArr)
  }

 render() {

  const {
   multipleInstructorsResponse,
   hostsArray,
   currentEvent,
   getEventDetailsResponse,
   isPrivate,
   feedbackGatheringPushNotificationText,
   hideOldTime,
  } = this.props

  const {
   isUpdateMode,
   pictures,
   deletionMode,
  } = this.state

  const {
   data: eventDetail,
   isFetching: fetchingEventDetails,
  } = getEventDetailsResponse || {}

  const {
   data: instructorsData,
  } = multipleInstructorsResponse

  let uniqueArray = []

  if(eventDetail
    && eventDetail.Instructors
    && isUpdateMode
    && isUpdateMode.length > 0
    && !deletionMode) {
    eventDetail.Instructors.forEach(inst => {
      uniqueArray.push({
       id: inst.id,
       instructor: inst,
      })
    })
  }

  if(hostsArray && hostsArray.length > 0) {
   uniqueArray = _.uniqBy(hostsArray, function (e) {
    return e;
   });
  }

  if(uniqueArray.length > 0) {
   uniqueArray = _.uniqBy(uniqueArray, function (e) {
    return e;
   });
  }

  const registeredAgents = eventDetail && eventDetail.registered_event_agents

  return (
   <React.Fragment>
     {fetchingEventDetails ? (
      <OuterWrap>
       <LeftMenu>
        <AddEventMenu />
       </LeftMenu>
       <RightMenu>
        <DefaultLoader height={200} width={200}/>
       </RightMenu>
      </OuterWrap>
     ) : (
      <OuterWrap>
       <LeftMenu>
        <AddEventMenu
         isUpdateMode={this.props.match.params.id}
        />
        {this.props.match.params.id
         && registeredAgents
         && registeredAgents.length > 0
         && (
         <React.Fragment>
          <AttendeeTitle>
           Event Attendees
          </AttendeeTitle>
          <EventAttendees>
            {registeredAgents.map(agent => (
             <li>
               <AttendeeImage>
                 <img src="https://api.adorable.io/avatars/112/abott@adorable.png" alt="instructor_image" />
               </AttendeeImage>
               <AttendeeInfo>
                 {`${agent.firstname} ${agent.lastname}`}
               </AttendeeInfo>
             </li>
            ))}
          </EventAttendees>
         </React.Fragment>
        )}
       </LeftMenu>
       <RightMenu
        isUpdateMode={this.props.match.params.id}
       >
        <div className="addEvent">
         <div className="header">
          <p className="title">Event Information</p>
         </div>
         <div className="row">
          <AddEventForm
           isUpdateMode={this.props.match.params.id}
           eventDetail={eventDetail}
          />
          {/*
          <div className="selectWrap">
              <label htmlFor="Event Name">Event Type</label>
              <Select
               options={[
                { value: "virtual", label: "Virtual"},
                { value: "in-person", label: "In-Person"},
               ]}
               onChange={this.handleEventType}
               value={currentEvent}
              />
          </div>
         */}
         </div>

         <div className="event-image">
         <div className="image-header-div">
           <p className="title">
            Header Image
           </p>
           <button
            disabled={pictures.length <= 0}
            onClick={() => this.initiateImageUpload()}>
            Save Image
           </button>
         </div>
          <div className="image-placeholder">
           <ImageUploader
               withIcon={true}
               buttonText='Choose images'
               onChange={this.onDrop}
               imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
               maxFileSize={5242880}
               withPreview={true}
               singleImage={true}
               withLabel={true}
            />
          </div>
        </div>

         <EventRTF
           rtfContent={this.props.match.params.id && eventDetail}
         />
         <FeedbackForm>
          <p>Feedback Gathering Push Notification Text</p>
          <textarea
          onChange={this.handleChange}
          name="feedbackGatheringPushNotificationText"
          value={feedbackGatheringPushNotificationText}
          >
          </textarea>
        </FeedbackForm>
         {this.props.match.params.id
          && eventDetail
          && eventDetail.cover_image_url
          && eventDetail.cover_image_url !== "abc.com"
          && (
          <React.Fragment>
            <div className="imagePreview">
              <p className="title">
                Image Preview
              </p>
              <div className="preview">
                <img src={eventDetail.cover_image_url} alt="event_image"/>
              </div>
            </div>
          </React.Fragment>
         )}
         <div style={{ marginBottom: "15px" }}>
          <p style={{ fontSize: "14px", fontWeight: "500" }}>Third Party Event</p>
           <div className="access">
            <div>
             <input checked={isPrivate} name="broker" type="checkbox" value="broker" onChange={this.handlePrivateEvent}/>
             <label htmlFor="broker">This is a Third Party Event</label>
            </div>
           </div>
         </div>
         <ThirdPartyLinkForm
          isUpdateMode={this.props.match.params.id}
          eventDetail={eventDetail}
         />
         <DateAndTimeEventsForm
          isUpdateMode={this.props.match.params.id}
         />
       {!hideOldTime && this.props.match.params.id && eventDetail && (
          <React.Fragment>
           <TimeStamp>{`Starts on ${moment.unix(eventDetail.event_start_timestamp).tz("America/Tijuana").format("dddd, MMMM Do YYYY, h:mm:ss a z")}`}</TimeStamp>
           <TimeStamp>{`Ends on ${moment.unix(eventDetail.event_end_timestamp).tz("America/Tijuana").format("dddd, MMMM Do YYYY, h:mm:ss a z")}`}</TimeStamp>
          </React.Fragment>
         )}
         <div className="inst">
           <p className="title">
            Instructors
            <span className="required"> *</span>
           </p>
           <div className="instructors-list">
            <div className="headerDiv">
              <Select
                options={this.getOptions(instructorsData)}
                onChange={e => this.handleInstructorSelect(e, uniqueArray)}
              />
            </div>
            {
             uniqueArray &&
             uniqueArray.length > 0 && (
             <ListWrap>
             {uniqueArray.map(data => {
               return (
                 <li>
                 <div className="instructor_image">
                     { data.instructor && data.instructor.image_url && data.instructor.image_url !== "abcd.com" ? (
                      <img src={data.instructor.image_url} alt="instructor_image" />
                     ) : (
                      <img src="https://api.adorable.io/avatars/112/abott@adorable.png" alt="instructor_image" />
                     )}
                 </div>
                  <div className="instructor_details">
                    <p>{data.instructor && data.instructor.name || data.name || ""}</p>
                    <p>{data.instructor && data.instructor.designation || data.designation || ""}</p>
                  </div>
                  <div className="cta">
                    <div>
                     {/*<EditInstructor
                       to={`/academy-admin/people/${data.instructor && data.instructor.id}`}
                     >
                      Edit
                     </EditInstructor>*/}
                    </div>
                    <div>
                    <button
                     onClick={() => this.handleInstructorDelete(data.instructor.id, uniqueArray)}
                     className="delete">
                     Remove
                    </button>
                    </div>
                  </div>
                 </li>
                )
             })}
             </ListWrap>
            )}
           </div>
         </div>
        </div>
       </RightMenu>
      </OuterWrap>
     )}
   </React.Fragment>
  )
 }
}

export default Container(AddEvent)
