import { Button, Col, Row } from "antd"
import Select from "react-select"
import React from "react"
import styled from "styled-components"
import container from "container/BrokerDashboard/resourcesContainer"
import ResourceList from "./ResourceList"
import Loader from "dumbComponents/common/UI/Loader"
import { MODIFIED_US_STATE } from "./data"
const Wrap = styled.div`
  max-width:1200px;
  margin: 40px auto 60px auto;
`

class Resources extends React.PureComponent {
  state = {
    selectedStates: [],
  }

  componentDidMount() {
    const { getResourcesList } = this.props
    getResourcesList({})
  }

  applyFilter = () => {
    const { getResourcesList } = this.props
    const { selectedStates } = this.state
    getResourcesList({
      isQuery: true,
      selectedStates,
      isFilter: true,

    })
  }

  handleStateFilter = (values) => {
    this.setState({
      selectedStates: values,
    })
  }

  render() {
    // const {  } = this.state
    const { resourcesListResponse, history } = this.props
    const { data, isFetching } = resourcesListResponse
    return (
      <Wrap>
        <Row>
          <Col flex={1}>
            <h1>Resources</h1>
          </Col>
          <Col>
            <Button type="primary" onClick={() => { history.push("/broker/resources/create") }}>
              Add Resource
            </Button>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Row gutter={20} align="center" justify="center">
              <Col style={{ width: 300 }}>
                <Select
                  isMulti
                  options={MODIFIED_US_STATE}
                  onChange={this.handleStateFilter}
                />
              </Col>
              <Col>
                <Button onClick={this.applyFilter}>
                  Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        {isFetching ? (
          <Loader top={0} bottom={0}/>
        ) : (
          <ResourceList data={data} />
        )}
      </Wrap>
    )
  }
}

export default container(Resources)

