import React from "react"
import styled from "styled-components"
import ProperyImage from "./../Components/Image"
import Button from "./../Components/Button"
import Para from "./../Components/Para"
import { RADIUS_ASSIST_LOGO_BLACK } from "./../ImagesData"

const H5 = ({
  style,
  ...props
}) => <h5
  style={{
    color: "#555555",
    fontSize: 12,
  }}
  {...props}
/>

const Image = ({ style, ...props }) => (
  <img
    style={{
      width: 152,
    }}
    {...props}
  />
)

const ProperyStatus = props => (
  <table style={{ marginTop: 20, background: "#FFFFFF", padding: 20, }}>
    <tbody>
      <tr
        colSpan="10"
        style={{
          height: 70,
        }}
      >
        <td>
          <table
            id="property-status-image-holder"
            style={{
              padding: 20,
              marginTop: "-60px ",
              marginRight: "auto",
              marginLeft: "auto",
              maxWidth: "500px",
              width: "100%",
              background: `url(http://www.gstatic.com/android/market_images/email/mid.png)`,
              border: "1px solid #CCC",
              borderRadius: "4px",
              background: "#FFF",
            }}
          >
            <tbody style={{ margin: 50 }}>
              <tr
                style={{
                  borderRadius: 4,
                  margin: 20,
                }}
              >
                <td>
                  <h1
                    style={{
                      textAlign: "center",
                      fontWeight: "normal",
                      fontFamily: "HelveticaNeue-Light",
                      fontSize: 28,
                    }}
                  >
                    Please update propery status
                  </h1>
                </td>
              </tr>
              <tr>
                <td className="propery-status-emails" style={{ textAlign: "left" }}>
                  <div>
                    <ProperyImage
                      address="Sample Address, multilines. Sample Address, multilines.  Sample Address, multilines.  Sample Address, multilines. "
                      image="https://unplash-api.herokuapp.com/api/2d4lAQAlbDA/regular"
                    />
                    <ProperyImage image="https://unplash-api.herokuapp.com/api/2d4lAQAlbDA/regular" />
                    <ProperyImage />
                    {/* <ProperyImage
                      address="asdasdjasdjajdajsdjasdjasjdasjda as da sd asd a d asd a  ad ad asdassdsasd"
                    /> */}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>
          <Para>Hi {`{{ Name }}`}</Para>
          <Para>
            Radius Agent is a referral network with an active referral marketplace where you can claim newly qualified buyers and sellers in your area for FREE.
          </Para>
          <Para>
            We want to boost exposure for your listings so we've created a profile page for you on Radius Agent for you with your listings displayed.
          </Para>
          <Button style={{ margin: "0px auto", display: "block", width: 200, fontSize: 16 }}>
            Update Status
          </Button>
          <Para>
          P.S. We also offer a well trained ISA concierge team ready to qualify your new and past online real estate leads. Learn more here.
          </Para>
          <Para>Thank you,</Para>
          <Para>
            Peter
            Radius Agent Support
            <a href="#">radiusagent.com</a>
          </Para>
        </td>
      </tr>
    </tbody>
  </table>
)

export default ProperyStatus
