import React from "react"
import styled from "styled-components"

const Wrap = styled.div``

const Exclamation = () => (
  <Wrap>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <g clip-path="url(#clip0_588_14454)">
        <path d="M10.0842 6.41634H11.9176V8.24967H10.0842V6.41634ZM10.0842 10.083H11.9176V15.583H10.0842V10.083ZM11.0009 1.83301C5.9409 1.83301 1.83423 5.93967 1.83423 10.9997C1.83423 16.0597 5.9409 20.1663 11.0009 20.1663C16.0609 20.1663 20.1676 16.0597 20.1676 10.9997C20.1676 5.93967 16.0609 1.83301 11.0009 1.83301ZM11.0009 18.333C6.9584 18.333 3.66756 15.0422 3.66756 10.9997C3.66756 6.95717 6.9584 3.66634 11.0009 3.66634C15.0434 3.66634 18.3342 6.95717 18.3342 10.9997C18.3342 15.0422 15.0434 18.333 11.0009 18.333Z" fill="#52525B"/>
      </g>
      <defs>
        <clipPath id="clip0_588_14454">
          <rect width="22" height="22" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </Wrap>
)

export default Exclamation
