import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  getPendingRequestsAction,
  changeLinkAction,
  changeStatusRequestAction,
} from "./actions"

function mapStateToProps(state) {
  const { brokerDashboard } = state
  const { pendingRequestsResponse } = brokerDashboard
  return {
    pendingRequestsResponse,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
      {
        getPendingRequests: getPendingRequestsAction.request,
        changeLink: changeLinkAction.request,
        changeStatusRequest: changeStatusRequestAction.request,
      },
      dispatch
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)