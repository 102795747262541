import React from "react"
import styled, { keyframes } from "styled-components"
import Timer from "./Timer"
import { CALL_STATUS } from "./Shared"

const deleteAnimation = keyframes`
  0% {
    width: 0%;
  } 
  99% {
    width: 100%;
  }
`

const Wrap = styled.div`
  border-bottom: 1px solid #CCC;
  padding: 10px 20px;
  background: #EEE;
  position: relative;
  h4, h6, h5 {
    padding: 0px;
    margin: 0px;
    font-weight: 300;
  }

  h4 {
    font-weight: 400;
    margin-bottom: 5px;
    display: flex;
    font-size: 14px;
  }

  h6 {
    color: #999;
  }
  ${props => props.isConference && `
    box-shadow: 0px 0px 10px 0px rgba(48,210,56,0.77);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(48,210,56,0.77);
    -moz-box-shadow: 0px 0px 10px 0px rgba(48,210,56,0.77);
  `}
`

const DailingState = styled.span`
  border-radius: 2px;
  color: #FFF;
  font-size: 10px;
  padding: 2px 5px;
  align-self: flex-end;
  margin: 0px 2px;
  ${props => props.type === "dailing" && `
    background: #f7b500;
  `}

  ${props => props.type === "ringing" && `
    background: #fa6400;
  `}

  ${props => props.type === "in-conf" && `
    background: #3498db;
  `}
`

const DeletionBar = styled.div`
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  background: rgba(244, 0, 0, 0.2);
  left: 0px;
  ${props => props.timeout && `
    animation: ${deleteAnimation} ${props.timeout}ms  linear;
  `}
`

const getCallStatus = (status) => {
  switch (status) {
    case "busy":
    case "busy-RM":
    case "call-start":
    case "canceled":
    case "Client-Hangup":
    case "completed":
    case "failed":
    case "no-answer":
    case "no-answer-machine":
    case "no-answer-RM":
    case "CallCycleFinishedEvent":
    case "RemoveReferralFromListEvent":
      return "prevCall"
    case "in-conf": 
      return "in-conferrence"
    default:
      return status
  }
}

class CallItem extends React.PureComponent {
  state = {
    isShow: true,
  }

  componentDidMount() {
    const {
      call_status,
      referral_id,
      deleteItemAutomatically,
    } = this.props
    const SelectedItem = CALL_STATUS[getCallStatus(call_status)]
    if (SelectedItem && SelectedItem.isAutoDelete) {
      setTimeout(() => {
        deleteItemAutomatically({
          referral_id,
          deleteFrom: getCallStatus(call_status),
        })
        this.setState({ isShow: false })
      }, SelectedItem.deleteAfter)
    }
  }

  getValue = (value) => {
    const { is_conference } = this.props
    if (is_conference) {

    }
  }

  render() {
    const {
      sender_name,
      ClientPhone,
      ClientName,
      sender_email,
      call_status,
      time_in_sec,
      is_conference,
      conference_agent_name,
      conference_agent_email,
      conference_agent_phone,
    } = this.props
    const { isShow } = this.state
    const SelectedItem = CALL_STATUS[getCallStatus(call_status)]
    if (!isShow) {
      return null
    }
    return (
      <Wrap isConference={is_conference}>
        {is_conference && (
          <h5>Client Info</h5>
        )}
        <h4>
          {(ClientName || "No Name")}
          <span style={{ flex: 1 }}></span>
          { call_status === "waiting" && (
            <Timer count={time_in_sec || parseInt(((new Date()).getTime() / 1000), 10) + 20}>00:06</Timer>
          )}
          <DailingState type="dailing">
            {call_status}
          </DailingState>
        </h4>
        <h6>{ClientPhone || "No phone"}</h6>
        <h6>{`Agent: ${sender_name || "No name"} / ${sender_email || "No name"}`}</h6>
        
        {is_conference && (
          <React.Fragment>
            <h5>Agent Info</h5>
            <h4>{(conference_agent_name || "No Name")}</h4>
            <h6>{conference_agent_phone || "No phone"}</h6>
            <h6>{conference_agent_email || "No email"}</h6>
          </React.Fragment>
        )}
        {SelectedItem && SelectedItem.isAutoDelete && (
          <DeletionBar timeout={SelectedItem.deleteAfter} />
        )}
      </Wrap>
    )
  }
}

export default CallItem
