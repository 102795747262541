import { Math } from "window-or-global";

export default function moneyFormatter(number = 0, decPlaces = 0) {
  decPlaces = Math.pow(10, decPlaces);
  var abbrev = [ "K", "M", "B", "T" ];
  for (var i=abbrev.length-1; i>=0; i--) {
    var size = Math.pow(10,(i+1)*3);
    if(size <= number) {
      number = Math.round(number*decPlaces/size)/decPlaces;
      if((number == 1000) && (i < abbrev.length - 1)) {
        number = 1;
        i++;
      }
      number += abbrev[i];
      break
    }
  }
  return number
}

export const USCurrencyFormat = (number, props = {
  isRound: true,
}) => new Intl.NumberFormat(
  "en-US",
  {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }
).format(props.isRound ? Number(number) : number)

export function addCommas(nStr) {
  let x, x1, x2
  nStr += '';
  x = nStr.split('.');
  x1 = x[0];
  x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

export const deformatCurrency = (str = "") => {
  console.log(str);
  if (!str) {
    return null
  }
  const finalStrinf = str.replace(/[^0-9.-]+/g, "")
  console.log({ finalStrinf })
  return finalStrinf
}
