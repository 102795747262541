import React from 'react';
import './PanelContainer.css';

const panelContainer = ({
 hideHeader,
 title,
 style,
 children,
 childClass,
}) => (
  <div className={childClass ? "panel " + childClass : "panel"}>
    {!hideHeader && (
      <div className={"panel-heading"}>{title}</div>
    )}
    <div className={"panel-body"} style={style}>
      {children}
    </div>
  </div>
);

export default panelContainer;
