import React from "react"
import styled from "styled-components"
import { withRouter, Link } from "react-router-dom"
import { DownOutlined } from '@ant-design/icons';
import SoulGif from "images/soul/soul-logo.gif"
import colors from "dumbComponents/common/UI/colors"
import { Button, Dropdown, Menu, Space } from 'antd'
import AgentSearch from "./AgentSearch"

const Wrap = styled.div`
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 2.5px 0px;
  background: #3B60E4;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1000;

  a.dropdown {
    .ant-space-item {
      color: #fff !important;
      font-weight: 500;
      font-size: 18px;
    }
  }
`

const SoulLogo = styled.div`
  margin-right: 20px;
  color: #fff;
  > span {
    font-size: 20px;
    letter-spacing: 3px;
    font-weight: bold;
  }

  img {
    width: 40px;
    height: 40px;
  }
`

const HomeIcon = styled.div`
  font-size: 30px;
  width: 50px;
  cursor: pointer;
  transition-duration: 0.2s;
  color: #fff;
  &:hover {
    color: ${colors.primaryBorderColor};
  }
`
const LinkList = styled.ul`
  display: flex;
  > li {
    list-style: none;
    padding: 0px 20px;
     > a {
      color: #100063;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const CTAWrap = styled.div`
 margin-left: 20px;
`

const CreateNewAgentCTA = styled.button`
 padding: 8px;
 color: #303030;
 border-radius: 18px;
 border: 1.5px solid #ccc;
 background: transparent;
 cursor: pointer;

 &:hover {
  background: ${colors.deepPurple};
  color: white;
  border: 1.5px solid #303030;
 }
`
//menuItems
const MenuItems = (
  <Menu>
    <Menu.Item>
      <Link to="/broker/roster">
        Roster
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/broker/ai">
        AI Chat
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/broker/agent-pipeline">
        Agent Pipeline
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/broker/all-assets">
        All Assets
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/broker/tc/list">
        Contracts
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/broker/tc/offers">
        Offers
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/broker/tc/listings">
        Listings
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/broker/teams">
        Teams
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/broker/teams/jobs">
        Team Jobs
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/broker/requests">
        Requests
      </Link>
    </Menu.Item>
    {/* <Menu.Item>
      <Link to="/broker/refer-a-friend">
        Invites
      </Link>
    </Menu.Item> */}
    <Menu.Item>
      <Link to="/broker/financing">
        Financing
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/broker/resources">
        Resources
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/broker/listing-documents">
        Listing Documents
      </Link>
    </Menu.Item>
     <Menu.Item>
      <Link to="/broker/checklist-documents">
        Checklist Documents
      </Link>
    </Menu.Item>
        <Menu.Item>
      <Link to="/broker/consumer-app">
        Consumer App
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/broker/offers-documents">
        Offers Documents
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/broker/recruit-and-earn">
        Refer &amp; Earn
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/broker/cashback">
        Cashback
      </Link>
    </Menu.Item>
  </Menu>
)

const GHOST_TIMER = 2000 //ms

class BrokerHeader extends React.PureComponent {
  state = {}

  componentDidMount() {
    // const {  } = this.props
    document.title = "SOUL | RadiusAgent Internal Tools"
    document.querySelector("link[rel='shortcut icon']").href = "/soul-logo.png";
  }

  startHoverWatcher = () => {
    let timePassed = 0
    this.GHOST_WATCHER = setInterval(() => {
      if (timePassed < GHOST_TIMER) {
        timePassed = timePassed + 500
      } else {
        this.stopWatcher()
      }
    }, 500)
  }

  stopWatcher = () => {
    clearInterval(this.GHOST_WATCHER)
  }

  render() {
    // const {  } = this.state
    const { history } = this.props
    return (
      <Wrap>
        <SoulLogo
          onMouseEnter={this.startHoverWatcher}
          onMouseLeave={this.stopWatcher}
        >
          <img src={SoulGif} alt="" />
        </SoulLogo>
        <HomeIcon onClick={() => { history.push("/broker/tc/list") }}>
          <i className="fa fa-home"></i>
        </HomeIcon>
        <AgentSearch />
        <div style={{ flex: 1 }}></div>
        <Dropdown
          className="dropdown"
          overlay={(
            <Menu>
              <Menu.Item>
                <Link to="/broker/mortgage-rates">
                  Set Daily Rates
                </Link>
              </Menu.Item>
            </Menu>
          )}
        >
          <a onClick={e => e.preventDefault()}>
            <Space>
              Mortgage
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
        <div style={{ width: 60 }}></div>
        <Dropdown overlay={MenuItems} className="dropdown">
          <a onClick={e => e.preventDefault()}>
            <Space>
              Dashboards
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
        {/* <LinkList>
          <li>
            <Link to="/broker/roster">
              Roster
            </Link>
          </li>
          <li>
            <Link to="/broker/agent-pipeline">
              Agent Pipeline
            </Link>
          </li>
          <li>
            <Link to="/broker/all-assets">
              All Assets
            </Link>
          </li>
          <li>
            <Link to="/broker/tc/list">
              TC
            </Link>
          </li>
          <li>
            <Link to="/broker/teams">
              Teams
            </Link>
          </li>
          <li>
            <Link to="/broker/teams/jobs">
              Jobs
            </Link>
          </li>
          {/* <li>
            <Link to="/broker/all-resources">
              Resources
            </Link>
          </li>
          <li>
            <Link to="/broker/requests">
              Requests
            </Link>
          </li>
          <li>
            <Link to="/broker/refer-a-friend">
              Invites
            </Link>
          </li>
          <li>
            <Link to="/broker/financing">
              Financing
            </Link>
          </li>
          <li>
            <Link to="/broker/resources">
              Resources
            </Link>
          </li>
        </LinkList> */}
        <CTAWrap>
          <Button onClick={() => { history.push("/broker/create") }}>
            Create Agent Account
          </Button>
        </CTAWrap>
      </Wrap>
    )
  }
}

export default withRouter(BrokerHeader)
