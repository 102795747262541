export const MONTH_DATE = "MMMM DD"

export const DEFAULT_REFERRAL_MANAGER = {
  firstname: "Craig Carle",
  phone: "+1415792013",
  id: 7857161,
}

export const MOBILE_BREAK_POINT = "720px"

export const ADD_REMINDERS_EVENTS = [
  { value: "call", label: "Call" },
  { value: "property showing", label: "Property Showing" },
  { value: "meeting", label: "Meeting" },
  { value: "sale", label: "Sale" },
  { value: "email", label: "Email" },
  { value: "follow up", label: "Follow up" },
]

export const ADD_REMINDERS_TYPES = [{
  label: "At the time of event",
  value: "0m",
}, {
  label: "5 minutes before",
  value: "5m",
}, {
  label: "15 minutes before",
  value: "15m",
}, {
  label: "30 minutes before",
  value: "30m",
}, {
  label: "1 hour before",
  value: "1h",
}, {
  label: "2 hours before",
  value: "2h",
}, {
  label: "1 day before",
  value: "1d",
}, {
  label: "1 week before",
  value: "1w",
}]
