import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/RecruitAndEarn"
import PendingInvites from "./PendingInvites"
import AcceptedInvites from "./AcceptedInvites"
import PendingEarnings from "./PendingEarnings"
import CreditedEarnings from "./CreditedEarnings"
import Select from 'react-select';
import Button from "dumbComponents/common/UI/Button"

const Wrap = styled.div`
  padding: 70px 250px 0px 100px;
`

const SelectContainer = styled.div`
  width: 350px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

class RecruitAndEarn extends React.Component {
  state = {
    view: { value: "brokerage_pending_invites", label: "Brokerage Pending Invites" },
  }
  componentDidMount() {
    const {
      fetchInvites,
      fetchEarnings,
    } = this.props
    fetchInvites()
    fetchEarnings()
  }

  handleViewChange = (view) => {
    this.setState({
      view
    })
  }

  getFilteredData = (data, type) => {
    if(type === "brokerage_pending_invites" && data && data.pending) {
      return {
        accepted: data.accepted,
        pending: data.pending.filter(dt => dt.invite_type === "brokerage")
      }
    }
    if(type === "brokerage_joined_invites" && data && data.pending) {
      return {
        accepted: data.accepted.filter(dt => dt.invite_type === "brokerage"),
        pending: data.pending
      }
    }
    if(type === "community_pending_invites" && data && data.pending) {
      return {
        accepted: data.accepted,
        pending: data.pending.filter(dt => dt.invite_type === "community")
      }
    }
    if(type === "community_joined_invites" && data && data.pending) {
      return {
        accepted: data.accepted.filter(dt => dt.invite_type === "community"),
        pending: data.pending
      }
    }
  }

  render() {
    const {
      getBrokerInvitesResponse,
      getBrokerEarningsResponse,
      history,
    } = this.props

    const {
      data,
      isFetching: isFetchingInvites,
    } = getBrokerInvitesResponse

    const {
      data: earningData,
      isFetching: isFetchingEarnings,
    } = getBrokerEarningsResponse

    return (
      <Wrap>
        <Header>
          <h1>Agent Invite Dashboard</h1>
          <SelectContainer>
            <Select
              placeholder="Select Invite Type"
              options={[
                { value: "brokerage_pending_invites", label: "Brokerage Pending Invites" },
                { value: "brokerage_joined_invites", label: "Brokerage Joined Invites" },
                { value: "community_pending_invites", label: "Community Pending Invites" },
                { value: "community_joined_invites", label: "Community Joined Invites" },
              ]}
              value={this.state.view}
              onChange={e => this.handleViewChange(e)}
            />
            <div>
            <Button style={{ margin: "10px 60px", position: 'relative',left: 100,top: 60}} success onClick={() => { history.push("/broker/invite/add") }}>
              Add Invite
            </Button>
            </div>
          </SelectContainer>
        </Header>

        {isFetchingInvites ? (
          <p>Loading...</p>
        ) : (
          <React.Fragment>
            {this.state.view.value.includes('pending') ? 
              <PendingInvites inviteList={this.getFilteredData(data, this.state.view.value)} /> : 
              <AcceptedInvites inviteList={this.getFilteredData(data, this.state.view.value)} />}
          </React.Fragment>
        )}
        {/* {isFetchingEarnings ? (
      <p>Loading...</p>
    ): (
     <React.Fragment>
      <PendingEarnings earningData={earningData}/>
      <CreditedEarnings earningData={earningData}/>
     </React.Fragment>
    )} */}
      </Wrap>
    )
  }
}

export default Container(RecruitAndEarn)
