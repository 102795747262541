import React, { PureComponent } from "react"
import styled from "styled-components"
import { Button, Modal, Drawer } from "antd"
import CreateForm from "components/Jobs/CreateForm.js"
import { withRouter } from "react-router-dom"
import Container from "container/EventRooms"
import TeamContainer from "container/BrokerDashboard/teamContainer"

const Wrap = styled.div`

`

class JobEditModal extends PureComponent {
    state = {

    }

    render() {
      const {
        onClose, visible, jobDetails, teamInfo, showJobConfirmationAction,
      } = this.props
      return (
        <Wrap>
          <Drawer
            width="60%"
            title="Edit Job Post on behalf of Team Lead"
            centered
            placement="right"
            visible={visible}
            onClose={() => {
              onClose(false)
              showJobConfirmationAction(false)
            }}
          >
            {visible && (<CreateForm isEdit jobDetails={jobDetails} teamInfo={teamInfo} />)}
          </Drawer>
        </Wrap>)
    }
}

export default TeamContainer(Container(withRouter(JobEditModal)))

