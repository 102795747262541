import React from "react"
import { console } from "window-or-global"

const DetailCell = ({ ...props }) => (
    <div style={{
        borderBottom: '1px solid #D3DAE8',
        margin: '16px 0px'
    }}>
        <h6
            style={{
                fontSize: 14,
                lineHeight: '14px',
                fontWeight: 600,
                color: '#303030',
                letterSpacing: '1px',
                margin: '2px 0px',
            }}
        >
            {props.header}
        </h6>
        <p style={{
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: 15,
            lineHeight: '20px',
            color: '#303030',
            margin: '6px 0px 10px 0px',

        }}>
            {props.para}
        </p>
    </div>
)


export default DetailCell
