import React from "react"
import {
  TopicCountWrap,
  TopicCountText,
} from "./topicCountStyles"

const TopicCount = ({
  count = 0,
}) => (
  <TopicCountWrap>
    <TopicCountText>
      {count || "XX"}
    </TopicCountText>
  </TopicCountWrap>
)

export default TopicCount
