import React, { Component } from 'react';
import moment from 'moment';
import _ from "lodash";
import axios from "../../../../src/axios";
import Reminder from "./Reminder/Reminder";
import './styles.css';
import Calendar from '../../UI/Calendar/Calendar';

class Reminders extends Component {
  constructor(props) {
    super(props);
    let getDate = this.getStartAndEndBasedOnDate();
    this.state = {
      u_cust_id : this.props.clientId,
      agent_id : this.props.agentId,
      start : getDate.start,
      end : getDate.end,
      reminders : [],
      selectedDates : []
    };
    this.getRemindersBasedOnClientId();
  }

  getStartAndEndBasedOnDate(selectedDate) {
    if (!selectedDate) {
      selectedDate = moment().format("YYYY-MM-DD");
    }
    return { start: moment(selectedDate)
        .startOf("month")
        .unix(), end: moment(selectedDate)
        .endOf("month")
        .unix() };
  }

  async getRemindersBasedOnClientId() {
    try {
      const result = await axios.post(axios.defaults.appURLV2 + "filterReminders", {agent_id : this.state.agent_id, u_cust_id : this.state.u_cust_id, start : this.state.start, end : this.state.end});
      this.setState({
        reminders: _.orderBy(result.data.response.reminders, ['date','desc']),
        selectedDates : _.map(result.data.response.reminders,'activity_date')
      });
    }
    catch(e) {
      console.log('Error',e);
    }
  }

  saveReminder(data) {}

  render() {
    return (
      <div>
        <Calendar
          options={{
            inline: true,
            enableTime: false,
            mode: "multiple",
            dateFormat: "Y-m-d",
            defaultDate: this.state.selectedDates
          }}
          changed={this.getRemindersBasedOnDate}
          onMonthChanged={this.getRemindersBasedOnDate}
        />
        <div className={"upcoming-reminders"}>
          <p>UPCOMING</p>
          {
            this.state.reminders.map((reminder) => <Reminder key={'reminder-' + reminder.activity_id} reminder={reminder}/>)
          }
        </div>
      </div>
    );
  }
}

export default Reminders;