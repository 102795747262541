import React, { Component } from "react"
import styled from "styled-components"
import SearchBar from "./SearchBar"
import DashboardContainer from "container/Dashboards"
import AgentSearch from "./AgentSearch"
import Filters from "./Filters"
import MetricList from "./MetricList"
import MetricListInfinite from "./MetricListInfinite"
import CurrentAgentsSearched from "./CurrentAgentsSearched"

const Wrap = styled.div`
  padding: 70px 250px 0px 250px;
`

class CallDashboard extends Component {

 componentDidMount() {
   const {
    fetchMetrics,
    currentDateFilter,
   } = this.props

   fetchMetrics({
    filters: "",
   })
 }

 render() {
  const {
   currentSearchValue,
   currentAgents,
  } = this.props
  return (
   <Wrap>
     <AgentSearch />
     {currentAgents && currentAgents.length > 0 && (
      <CurrentAgentsSearched
      currentAgents={currentAgents}
      />
     )}
     <Filters />
     <MetricListInfinite />
   </Wrap>

  )
 }

}


export default DashboardContainer(CallDashboard)
