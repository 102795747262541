import React from "react"
import styled from "styled-components"
import RoundButton from "./../Components/RoundButton"

const Wrap = styled.div`
  
`

const TopP = styled.div`
  
`

const LeadWrap = styled.div`
  
`

const ClientTypeTag = styled.div`
  
`

const PriceWrap = styled.div`
  
`

const LocationWrap = styled.div`
  
`

const LeadQualityWrap = styled.div`
  
`

const Sections50 = ({ children, style }) => (
  <div style={{
    width: "240px",
    display: "inline-block",
    ...style,
  }}>
    {children}
  </div>
)

const ProgressWrap = styled.div`
  
`

const Progress = styled.div`
  
`

const LeadBody = styled.div`
  
`

const NewLead = (props) => (
  <Wrap style={{
    background: "#FFF",
    paddingBottom: 50,
  }}>
    <TopP style={{
      padding: "32px 0px",
      textAlign: "center",
    }}>
      Do you want this lead?
    </TopP>
    <LeadWrap style={{
      width: "500px",
      margin: "0px auto",
      borderRadius: "8px",
      padding: "16px",
      border: "1px solid #D3DAE8",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
    }}>
      <ClientTypeTag style={{
        fontSize: "10px",
        color: "#F8F8F8",
        background: "#354052",
        padding: "6px",
        borderRadius: 3,
        display: "inline-block"
      }}>
        Buyer
      </ClientTypeTag>
      <PriceWrap style={{
        fontSize: 28,
        fontWeight: "500",
        margin: "10px 0px 4px 0px",
      }}>
        $750K
      </PriceWrap>
      <LocationWrap style={{
        fontWeight: 300,
      }}>
        San Francisco, CA 94109
      </LocationWrap>
      <LeadQualityWrap>
        <Sections50>
          <p
            style={{
              fontSize: 12,
              margin: "10px 0px 4px 0px",
            }}
          >Lead Quality:
            <strong> High </strong>
          </p>
          <ProgressWrap style={{
            borderRadius: "10px",
            background: "#FBF8EF",
            width: 123,
            height: 6,
          }}>
            <Progress style={{
              width: "50%",
              borderRadius: "10px",
              height: 6,
              background: "#F99603",
            }}>
              
            </Progress>
          </ProgressWrap>
        </Sections50>
        <Sections50>
          <p style={{
            fontSize: 12,
            textAlign: "right"
          }}>
            {`Claim this lead >`}
          </p>
        </Sections50>
      </LeadQualityWrap>
    </LeadWrap>
    <LeadBody style={{
      width: "500px",
      margin: "0px auto",
    }}>
      <p style={{
        fontWeight: 300,
        lineHeight: "20px",
      }}>
        Hey Nolan,
        <br/>
        <br/>

        We’ve found a lead in your area that you might be interested in.
        <br/>
        <br/>
        To be considered for this lead, please sign our referral contract and then claim it soon! If you have any questions, please reply to this email. We only assign each lead to 2 agents and the first 2 agents to accept this lead will eventually get the client’s details
      </p>
      <RoundButton style={{
        background: "#0B8DEC",
        fontSize: 16,
      }}>
        Claim This Lead
      </RoundButton>
      <p style={{
        fontWeight: 300,
        lineHeight: "20px",
      }}>
        Thank You,
        <br/>
        Radius Concierge Team
      </p>
    </LeadBody>

  </Wrap>
)

export default NewLead