const colors = {
  disabledColor: "#e7ebed",
  white: "#ffffff",
  borderColor: "#e7e8ea",
  starInactive: "#d4d4d4",
  //SHADES OF SHALLOW WHITE
  blueDarkShade: "#f0f3f5",
  milkyGrey: "#f5f5f5",
  priceCardGrey: "#f7f7f7",
  bgGrey: "#f8f9fa",
  greyBackground: "#fbfcfc",
  lightWhiteGray: "#fcfcfc",

  //SHADES OF WHITE
  borderGray: "#eaeaea",
  veryLightGray: "#eeeeee",
  newTagInactive: "#eef0f0",
  shallowGrey: "#e5e9eb",
  disabledInput: "#e6e8e9",
  tagActive: "#e9f7fd",
  whiteBlue: "#f8f9fa",

  //SHADES OF YELLOW
  starActive: "#ffd05b",

  //SHADES OF RED
  peachRed: "#c47070",
  redText: "#f96d72",
  strawberryPink: "#ea526f",
  red: "#ff0000",

  //SHADES OF GREEN
  textGreen: "#09c199",
  turqoiseGreen: "#0ac4ba",
  successColor: "#51d3b7",
  gradientGreen: "#2bda8e",

  //SHADES OF BLUE
  fadedPrimaryColor: "#ADE1F8",
  primaryColor: "#11adf3",
  infoBorderColor: "#10adf3",
  primaryBorderColor: "#11adf3",
  primaryHover: "#0088a6",

  //SHADES OF BLACK AND DARK GREY
  paleGrayWhite: "#f6f6f6",
  milkyWhite: "#dadada",
  starInactive: "#d4d4d4",
  tabDisabledColor: "#c4c7ca",
  textDisabledColor: "#c6c9cb",
  faintGrey: "#adb2b9",
  bluishGrey: "#adb3bb",
  grayishBlue: "#aab3be",
  primaryInactiveColor: "#a5a8ad",
  tagInactive: "#a7a9ad",
  charcoalWhiteGrey: "#808080",
  faintBlueishBlack: "#868b90",
  newTagText: "#898c90",
  silkGrey: "#929292",
  charcoalBlueGrey: "#979797",
  dirtBrown: "#666b71",
  reddishGrey: "#d8d8d8",
  contentText: "#354052",
  deepSeaBlue: "#3c4858",
  deepSeaGray: "#767676",
  black: "#303030",
}

export default colors
