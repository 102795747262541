import React, {Component} from 'react';
import TimePicker from "rc-time-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {formatDate} from 'react-day-picker/moment';
import styled from "styled-components";
import "react-day-picker/lib/style.css";
import "rc-time-picker/assets/index.css";
import moment from "moment";

const DATE_FORMAT = "YYYY-MM-DD";
const TIME_FORMAT = "hh:mm A";
const CURRENT_TIME = new Date();
const timezones = [
  "ADT",
  "AKDT",
  "AKST",
  "AST",
  "CDT",
  "ChST",
  "CST",
  "EDT",
  "EST",
  "HST",
  "MDT",
  "MST",
  "NDT",
  "NST",
  "PDT",
  "PST",
]   
const timezoneoptions = timezones.map((timezone) =>   <option value={timezone} >{timezone}</option>)
const timezonediv = <React.Fragment>{timezoneoptions}</React.Fragment>; 

const TimeWrap = styled.div`
  display: flex;
  .DayPickerInput{
    width: 100px;
    background: #f7f7f7;
    color: #9b9b9b;
    border-radius: 4px;
    > input {
      border: none;
      font-size: 14px;
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
  .rc-time-picker  {
    background: #f7f7f7;
    width: 100px;
    margin-left: 75px;
    input.rc-time-picker-input {
      height: 36px;
      font-size: 14px;
      border-radius: 4px;
      background: #ffffff;
      color: #000000;
      padding: 10px;
      cursor: pointer;
    }
    .rc-time-picker-clear-icon {
      display: none;
    }
  }
`

const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  > span {
    margin-bottom: 5px;
  }
  input {
    width: 100%;
    border: 1px solid #CCC;
    padding: 12px 8px;
    border-radius: 4px;
  }
  .rc-time-picker-panel-select {
    overflow-x: hidden;
  }
`

const TimePickerWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const DatePickerWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

class AppointmentTime extends Component {

  constructor(props){
    super(props);
    this.handleTimezoneChange = this.handleTimezoneChange.bind(this)

  }

  state = {
      date: null,
      time: null,
      timezone: null,
  };

    componentDidMount() {
        const appointmentTime = this.props.appointmentTime ? new Date(this.props.appointmentTime) : null;
        console.log(this.props.appointment_timezone);
        this.setState({
            date: appointmentTime,
            time: appointmentTime ? moment(appointmentTime) : null,
            timezone: this.props.appointmentTimezone || null
        })
    }

    handleDateChange(date) {
        this.setState({date}, () => {
            this.handleDateTimeChange();
        });
    }

    handleTimeChange(time) {
        this.setState({time}, () => {
            this.handleDateTimeChange();
        });
    }

    componentWillReceiveProps(props){
      this.setState({
        timezone: props.appointmentTimezone
      })

    }

    handleDateTimeChange() {
        if (!this.state.date || !this.state.time) {
            return;
        }
        const dateObj = moment(this.state.date).hour(this.state.time.hour()).minute(this.state.time.minute());
        this.props.handleAppointmentTimeStamp(dateObj ? dateObj.format("YYYY-MM-DD hh:mm a") : null);
    }

    handleTimezoneChange(e){
      this.props.handleAppointmentTimezone(e);
    }

    render() {
        return (
            <div className="referral-text">
                <div className="client-additional-details-label-text">Appointment Time:</div>
                <TimeWrap>
                    <Label>
                        <DatePickerWrap>
                            <DayPickerInput
                                formatDate={formatDate}
                                format={DATE_FORMAT}
                                onDayChange={(day) => {
                                    this.handleDateChange(day)
                                }}
                                value={this.state.date || ""}
                                placeholder={DATE_FORMAT}
                                dayPickerProps={{disabledDays: {before: CURRENT_TIME}}}
                            />
                        </DatePickerWrap>
                    </Label>
                    <Label>
                        <TimePickerWrap>
                            <TimePicker
                                showSecond={false}
                                format={TIME_FORMAT}
                                onChange={(date) => {
                                    this.handleTimeChange(date);
                                }}
                                value={this.state.time || ""}
                                placeholder={TIME_FORMAT}
                                use12Hours
                            />
                        </TimePickerWrap>
                    </Label>
                </TimeWrap>
            

                {this.state.date ? 
                <div>
                    <div className="label-text">Timezone<span class="required-asterik">*</span></div>
                    <span className="disclaimer">Do not forget to select the timezone of the lead for the appointment, if you don't know what the timezone is, choose Mountain Time</span><br/>
                    <select className="dropdown" onChange={this.handleTimezoneChange} value={this.state.timezone}>
                        {timezonediv}
                    </select>
                </div>
                : null}
            </div>
        )
    }
}

export default AppointmentTime
