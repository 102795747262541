import React, { useEffect, useState } from "react"
import cookies from "react-cookies"
import styled, { css } from "styled-components"
import {
  Select as SelectAntD, Input, InputNumber, Space, Tag as EmailTag, Tooltip, DatePicker,
} from "antd"
import Loader from "dumbComponents/common/UI/Loader"
import Button from "dumbComponents/common/UI/Button"
import Toggle from "dumbComponents/common/UI/Toggle"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import AgentContainer from "container/Agent"
import container from "container/BrokerDashboard/editAgentContainer"
import DayPickerInput from "react-day-picker/DayPickerInput"
import searchContainer from "container/BrokerDashboard/agentSearchContainer"
import globalContainer from "container/BrokerDashboard/globalPagesContainer"
import Select from "react-select"
import axios from "axios"
import { get, isEmpty, uniqBy } from "lodash"
import CancelIcon from "icons/cancel.png"
import { ToastContainer, toast } from "react-toastify"
import ThreeDotLoader from "dumbComponents/common/UI/ThreeLoader"
import colors from "dumbComponents/common/UI/colors"
import { useInput } from "dumbComponents/common/Hooks/InputHook"
import {
  validEmail, validURL, emptyInput, isAlphaNumeric,
} from "dumbComponents/common/Utils"
import sidebarContainer from "container/BrokerDashboard/sidebarContainer"
import Modal from "dumbComponents/common/UI/Modal"
import GoogleLocation from "../../../components/GoogleAutoComplete"
import { PAST_BROKERAGE_NAME_OPTIONS, PRODUCTION_TIER_OPTIONS, SALES_REPRESENTATIVE } from "dumbComponents/BrokerDashboard/GlobalPages/Resources/data"
import { format } from "date-fns"

const US_OBJ = {
  lat: 37.09024,
  lng: -95.712891,
  south_west: {
    lat: 25.82,
    lng: -124.39,
  },
  north_east: {
    lat: 49.38,
    lng: -66.94,
  },
  address: "United States",
  country: {
    long_name: "United States",
    short_name: "US",
    types: [
      "country",
      "political",
    ],
  },
}

const API_V2_URL = `${process.env.REACT_APP_API_BASE_URL}/v2.0/`
const API_URL = `${process.env.REACT_APP_API_BASE_URL}/v1.0/`

const Wrap = styled.div`
  margin: 20px;
`

const NeighborhoodWrap = styled.div`
  margin-top: 20px;
`

const NoNeighbourhoodsFound = styled.div`
  padding: 0px;
  font-style: italic;
  font-weight: 100;
  color: #999;
`

const HeaderWrap = styled.div`
 display: flex;
 margin-top: 10px;
 h1 {
   flex: 1;
   margin: 0px;
   font-weight: 400;
 }
 > div {

 }
`
const Fields = styled.div`
 margin-top: 5px;
 margin-bottom: 5px;
 .DayPickerInput {
  input {
    width: 200px;
    height: 40px;
    border: solid 1px #d9d9d9;
    border-radius: 4px;
    padding: 10px;
  }
}

.DayPickerInput-OverlayWrapper {
  z-index: 104;
}
`

const CTAWrap = styled.div`
 margin-top: 10px;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
`

const SaveButton = styled.button`
  padding: 12px;
  border-radius: 18px;
  border: 0.5px solid white;
  cursor: pointer;
  width: 100px;

  &:hover {
   background: #4e26be;
   color: white;
  }

  ${props => props.disabled && `
     cursor: not-allowed;
     color: grey;
     opacity: 0.5;
     border: 0.5px solid #7c7c7c;
   `}


`

const LabelAndToggleWrap = styled.div`
  display: flex;
  align-items: center;
  > p {
    flex: 1;
  }
`

const CalendlyLinkWrap = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: space-between;
`

const UserInput = styled.input`
 height: 24px;
 -webkit-align-items: center;
 -webkit-box-align: center;
 -ms-flex-align: center;
 align-items: center;
 background-color: hsl(0,0%,100%);
 border-color: hsl(0,0%,80%);
 border-radius: 4px;
 border-style: solid;
 border-width: 1px;
 cursor: default;
 display: -webkit-box;
 display: -webkit-flex;
 display: -ms-flexbox;
 display: flex;
 -webkit-flex-wrap: wrap;
 -ms-flex-wrap: wrap;
 flex-wrap: wrap;
 -webkit-box-pack: justify;
 -webkit-justify-content: space-between;
 -ms-flex-pack: justify;
 justify-content: space-between;
 min-height: 38px;
 outline: 0 !important;
 position: relative;
 -webkit-transition: all 100ms;
 transition: all 100ms;
 box-sizing: border-box;
 padding: 9px;
 margin-bottom: 10px;
`

const CitiesServedWrap = styled.div``

const ClearCalendlyCTA = styled.button`
  width: max-content;
  position: relative;
  top: -13px;
  left: 10px;
  cursor: pointer;
  background: transparent;
  border: 1px solid black;
  border-radius: 18px;

  &:hover {
   color: red;
   border-color: red;
  }
 `

const SubHeader = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 13px;
  ${props => props.new && `
     font-size: 15px;
  `}
`

const InnerWrap = styled.div`
 display: flex;
 flex-direction: column;

 a {
   font-size: 14px;
   position: relative;
   top: -12px;
 }
`
const GoogleLocationWrap = styled.div`
 width: 98%;
`

const ClearPreferredEmailCTA = styled.button`
  width: max-content;
  position: relative;
  top: -13px;
  left: 10px;
  cursor: pointer;
  background: transparent;
  border: 1px solid black;
  border-radius: 18px;

  &:hover {
   color: red;
   border-color: red;
  }
 `

const DeleteButton = styled.button`
 padding: 12px;
 border-radius: 18px;
 border: 0.5px solid red;
 cursor: pointer;
 width: max-content;
 color: red;
 background: transparent;


 &:hover {
  background: red;
  color: white;
 }
`

const Img = styled.img`
  max-width: 10px;
  margin-left: 10px;
  cursor: pointer;

  ${props => props.addable && css`
    transform: rotate(45deg);
  `}
`

const TagWrap = styled.li`
  display: inline-block;
  padding: 10px;
  background: #e9f7fd;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  ${props => props.addable && css`
    background: #FFF;
    border: #868b90 1px solid;
  `}

  ${props => props.showMoreBtn && css`
    border: none;
    color: #11ADF3;
  `}

  ${props => props.isSaved && `
     color: white;
     background: black;

     img{
      filter: invert(1);
     }
   `}

   ${props => props.isUserSelected && `
      color: white;
      background: ${colors.deepPurple};

      img {
       filter: invert(1);
      }
    `}
`

const Tag = ({
  title,
  handleClick,
  addable,
  showMoreBtn,
  isSaved,
  isUserSelected,
  getTCAgentOpted,
}) => (
  <TagWrap
    isUserSelected={isUserSelected}
    isSaved={isSaved}
    addable={addable}
    showMoreBtn={showMoreBtn}
    onClick={handleClick}
  >
    <span>{title}</span>
    <Img
      src={CancelIcon}
      alt="remove icon"
      addable={!isUserSelected && !isSaved}
    />
  </TagWrap>
)

const LabelAndToggle = ({
  label,
  children,
}) => (
  <LabelAndToggleWrap>
    <p>
      {label}
    </p>
    <div>
      {children}
    </div>
  </LabelAndToggleWrap>
)

const Invalid = styled.p`
 color: red;
 font-size: 14px;
`

const CityUL = styled.ul`
 display: flex;
 gap: 8px;
 padding: 0px;
 flex-wrap: wrap;
`

const FLexWrap = styled.div`
 display: flex;
 flex-direction: row;
 margin-top: 12px;
`

const CityBlock = styled.button`
 height: max-Content;
 width: max-content;
 padding: 18px;
 border: 1px solid black;
 border-radius: 18px;
 cursor: pointer;
 background: transparent;
 color: ${colors.black}
 font-size: 14px;

 ${props => props.hide && `
   display: none;
  `}

 ${props => props.hoverCSS && `
    &:hover {
     background: ${colors.deepPurple};
     color: white;
    }
  `}
`

const UpdateAgentInfo = ({
  USStates,
  getUSStates,
  changeStatesForAgent,
  match,
  agentStates,
  preferences,
  updateUserSettings,
  fetchCitiesServed,
  agentDetailsResponse,
  getCitiesServedResponse,
  fetchNeighbourhoods,
  getNeighbourHoodFromCitiesResponse,
  saveNeighbourhoods,
  deleteNeighbourhood,
  fetchAgentSecureId,
  getAgentDetails,
  updateAgentOptedTC,
  getTCAgentOpted,
  getTCAgentAssignedResponse,
  getAgentBrokerageInfo,
  postAgentBrokerageInfo,
  agentBrokerageInfoResponse,
  agentId,
  updateAgentBrokerageInfo,
  ...props
}) => {
  const [currentInputCity, setInputCity] = useState("")
  const {
    value: calendlyLink,
    bind: calendlyBind,
    error: invalidLink,
    setValue: setCalendly,
    reset,
  } = useInput("", validURL)
  const {
    value: firstname,
    bind: firstNameBind,
    setValue: setFirstName,
    error: emptyFieldError,
  } = useInput("", emptyInput)
  const {
    value: lastname,
    bind: lastNameBind,
    setValue: setLastName,
    error: lastNameError,
  } = useInput("", isAlphaNumeric)
  const {
    value: preferredEmail,
    bind: preferredEmailBind,
    setValue: setPreferredEmail,
    error: preferredEmailError,
  } = useInput("", validEmail)
  const [modifiedNeighbourhood, setModifiedNeighborhoodArray] = useState([])
  const [showCityNeighbourHoodModal, toggleCityModal] = useState(false)
  const [neighbourhoodArray, setNeighborhoods] = useState([])
  const [selectedStates, setSelectedStates] = useState([])
  const [defaultOptions, setDefaultOptions] = useState([])
  const [isTCChecked, setTCCheked] = useState(false)
  const [isAssistPlusChecked, setAssistPlus] = useState(false)
  const [isVacationModeChecked, setVacationModeChecked] = useState(false)

  const [isLicenseTransferred, setIsLicenseTransferred] = useState(null)
  const [isIcaSigned, setIsIcaSigned] = useState(null)
  const [icaSignedDate, setIcaSignedDate] = useState("")
  const [licenseTransferredDate, setLicenseTransferredDate] = useState("")
  const [primarySalesRep, setPrimarySalesRep] = useState("")
  const [secondarySalesRep, setSecondarySalesRep] = useState("")
  const [primarySplit, setPrimarySplit] = useState("")
  const [secondarySplit, setSecondarySplit] = useState("")
  const [pastBrokerageProduction, setPastBrokerageProduction] = useState(null)
  const [pastBrokerageName, setPastBrokerageName] = useState("")
  const [otherPastBrokerageName, setOtherPastBrokerageName] = useState("")
  const [productionTier, setProductionTier] = useState("")
  const [cap, setCap] = useState(null)
  const [pastVolume, setPastVolume] = useState(null)
  const [rnePayout, setRnePayout] = useState("")

  // const agentId = get(match, "params.agentId")

  const disableSaveButton = emptyFieldError || invalidLink

  const {
    data: cities,
    isFetching: fetchingCities,
  } = getCitiesServedResponse || {}

  const {
    data: agentBrokerageInfo,
    isFetching: isFetchingAgentBrokerageInfo,
  } = agentBrokerageInfoResponse || {}

  const {
    data: neighbourhoodsResponse,
    isFetching: isFetchingNeighbourhoods,
  } = getNeighbourHoodFromCitiesResponse || {}

  const {
    data: agentDetails,
    isFetching: fetchingAgentDetails,
  } = agentDetailsResponse || {}

  const {
    neighbourhoods,
  } = neighbourhoodsResponse || {}

  useEffect(() => {
    getUSStates()
    fetchAgentSecureId({ agentId })
    const { data } = agentStates || {}
    if (data && data.length) {
      setSelectedStates(data.map(x => ({ label: x.name, value: x.id })))
    }
  }, [])

  useEffect(() => {
    if (agentId) {
      getAgentBrokerageInfo({ agentId: parseInt(agentId, 10) })
    }
  }, [agentId])

  useEffect(() => {
    if (currentInputCity) {
      fetchNeighbourhoods({
        city: currentInputCity.city,
      })
    }
  }, [currentInputCity])

  useEffect(() => {
    getTCAgentOpted({
      agentId,
    })
  }, [])

  useEffect(() => {
    const currentCityNeighbourhoods = currentInputCity && currentInputCity.neighbourhoods || []
    const mod = neighbourhoods && neighbourhoods.map((item, index) => {
      if (currentCityNeighbourhoods.includes(item)) {
        return {
          id: `${item}_RADS_${index}`,
          name: item,
          isSaved: true,
          isUserSelected: false,
        }
      }

      return {
        id: `${item}_RADS_${index}`,
        name: item,
        isSaved: false,
        isUserSelected: false,
      }
    })
    setModifiedNeighborhoodArray(mod)
  }, [neighbourhoods])

  useEffect(() => {
    if (agentDetails) {
      setFirstName(agentDetails.firstname)
      setLastName(agentDetails.lastname)
      setPreferredEmail(agentDetails.preferred_email)
    }
  }, [agentDetails])

  const getNewDate = (newDate) => {
    const final = new Date(newDate * 1000)
    const finalDate = moment(final).format("MM-DD-YYYY")
    return finalDate
  }

  useEffect(() => {
    if (!isEmpty(agentBrokerageInfo)) {
      setIsLicenseTransferred(agentBrokerageInfo?.is_license_transferred)
      setIsIcaSigned(agentBrokerageInfo?.is_ica_signed)
      if (agentBrokerageInfo?.ica_signed_date === null) {
        setIcaSignedDate("")
      } else {
        setIcaSignedDate(getNewDate(agentBrokerageInfo?.ica_signed_date))
      }
      if (agentBrokerageInfo?.license_transferred_date === null) {
        setLicenseTransferredDate("")
      } else {
        setLicenseTransferredDate(getNewDate(agentBrokerageInfo?.license_transferred_date))
      }
      setPrimarySalesRep(agentBrokerageInfo?.primary_sale_representative)
      setSecondarySalesRep(agentBrokerageInfo?.secondary_sale_representative)
      setPrimarySplit(agentBrokerageInfo?.primary_sale_representative_split_percentage)
      setSecondarySplit(agentBrokerageInfo?.secondary_sale_representative_split_percentage)
      if (agentBrokerageInfo?.pastBrokerageProduction === 0) {
        setPastBrokerageProduction(0)
      } else if (agentBrokerageInfo?.pastBrokerageProduction === null) {
        setPastBrokerageProduction(null)
      } else {
        setPastBrokerageProduction(agentBrokerageInfo?.past_brokerage_production)
      }
      setPastBrokerageName(agentBrokerageInfo?.past_brokerage_name)
      setOtherPastBrokerageName(agentBrokerageInfo?.other_past_brokerage_name)
      setProductionTier(agentBrokerageInfo?.production_tier)
      setCap(agentBrokerageInfo?.cap)
      setPastVolume(agentBrokerageInfo?.past_volume)
    }
    // if (!isEmpty(icaSignedDate)) {
    //   const dateControl = document.querySelector("input[type=\"date\"]")
    //   dateControl.value = icaSignedDate
    // }
  }, [agentBrokerageInfo])

  useEffect(() => {
    if (preferences.data) {
      setCalendly(preferences.data.schedule_meeting_calendly_link_blink)
      setRnePayout(preferences.data.recruit_and_earn_payout)
    }
  }, [preferences])

  useEffect(() => {
    const selectedModArray = modifiedNeighbourhood && modifiedNeighbourhood.map(item => ({
      id: item.id,
      name: item.name,
      isSaved: item.isSaved,
      isUserSelected: neighbourhoodArray.includes(item.name),
    }))
    setModifiedNeighborhoodArray(selectedModArray)
  }, [neighbourhoodArray])

  const updateToggleableAgentSettings = (key) => {
    const payload = {
      [key]: preferences.data[key] ? 0 : 1,
    }
    updateUserSettings({
      payload,
      isPageRefresh: true,
      agentId,
    })
  }

  const updateRnEPayout = (value) => {
    const payload = {
      recruit_and_earn_payout: value,
    }
    updateUserSettings({
      payload,
      isPageRefresh: true,
      agentId,
    })
  }

  const selectNeighborhood = (neighbourhood) => {
    if (neighbourhoodArray.includes(neighbourhood.name)) {
      const restArray = []
      neighbourhoodArray.forEach((item) => {
        if (item !== neighbourhood.name) {
          restArray.push(item)
        }
      })
      setNeighborhoods(restArray)
      return
    }
    if (neighbourhoodArray && neighbourhoodArray.length <= 0) {
      setNeighborhoods([neighbourhood.name])
    } else {
      setNeighborhoods([...neighbourhoodArray, neighbourhood.name])
    }
  }

  const handleCitySave = (cityObj) => {
    axios.post(`${API_URL}user/cities-neighbourhoods-served`, cityObj, {
      headers: {
        "secure-id": cookies.load("secureId"),
      },
    }).then((data) => {
      const { data: res } = data || {}
      const { response } = res || {}
      const {
        profile_name: username,
      } = agentDetails || {}
      fetchCitiesServed({
        username,
      })
      toast.success("City Served saved")
      const modifiedCityObj = cityObj
      modifiedCityObj.id = response ? response.id : null
      setInputCity(modifiedCityObj)
    })
  }

  const handleCityDelete = () => {
    axios.delete(`${API_URL}user/city-served?cityServedId=${currentInputCity.id}`, {
      headers: {
        "secure-id": cookies.load("secureId"),
      },
    }).then(() => {
      const {
        profile_name: username,
      } = agentDetails || {}
      fetchCitiesServed({
        username,
      })
      toast.success("City Deleted")
      toggleCityModal(false)
    })
  }

  const handleRemoveCalendlyLink = () => {
    const payload = {
      schedule_meeting_calendly_link_blink: "",
    }
    updateUserSettings({
      payload,
      agentId,
    })

    toast.success("Meeting Link removed successfully")
  }

  const removeSavedNeighbourhood = async (neighbourhoodToBeRemoved) => {
    const payload = {
      cityServedId: currentInputCity.id,
      neighbourhoods: [neighbourhoodToBeRemoved.name],
      secure_id: cookies.load("secureId"),
    }

    await fetch(`${API_URL}user/neighbourhoods-served`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "secure-id": process.env.REACT_APP_SECRET_ID,
      },
      body: JSON.stringify(payload),
    })

    const {
      profile_name: username,
    } = agentDetails || {}
    fetchCitiesServed({
      username,
    })

    toggleCityModal(false)
    toast.success("Neighbourhood deleted")
  }

  const handleDownloadExcel = () => {
    if (!agentDetails || !agentDetails.id) {
      return
    }
    axios.get(`${API_URL}refer/referrals-list/soul-excel?agentId=${agentId}`).then((res) => {
      const { data } = res
      const blob = new Blob([data], { type: "text/csv;charset=utf-8;" })
      const link = document.createElement("a")
      link.setAttribute("href", URL.createObjectURL(blob))
      const currentDate = new Date()
      link.setAttribute("download", `${currentDate.getTime()}_${format(currentDate, "MMM_dd_yyyy")}.csv`)
      document.body.appendChild(link)
      link.click()
      if (res.status === 200) {
        toast.success("Downloaded all assist leads")
      }
    }).catch((error) => {
      toast.error(error.data.error.message || "Error!")
    })
  }

  const handleUpdateSave = () => {
    const ids = selectedStates && selectedStates.length > 0 && selectedStates.map(x => x.value)
    if (ids) {
      changeStatesForAgent({
        ids,
        agentId,
      })
    }
    if (calendlyLink) {
      const payload = {
        schedule_meeting_calendly_link_blink: calendlyLink,
      }
      updateUserSettings({
        payload,
        agentId,
      })

      reset()
    }

    const userPayload = {}
    const payload = {
      agent_id: parseInt(agentId, 10),
      primary_sale_representative: primarySalesRep || null,
      secondary_sale_representative: secondarySalesRep || null,
      primary_sale_representative_split_percentage: primarySplit || null,
      secondary_sale_representative_split_percentage: secondarySplit || null,
      cap: cap || null,
      is_ica_signed: (isIcaSigned === 0 ? 0 : (isIcaSigned === 1 ? 1 : null)),
      ica_signed_date: moment(icaSignedDate).unix() || null,
      license_transferred_date: moment(licenseTransferredDate).unix() || null,
      is_license_transferred: (isLicenseTransferred === 0 ? 0 : (isLicenseTransferred === 1 ? 1 : null)),
      other_past_brokerage_name: otherPastBrokerageName || null,
      past_brokerage_name: pastBrokerageName || null,
      past_brokerage_production: pastBrokerageProduction === 0 ? 0 : ((pastBrokerageProduction !== 0 || pastBrokerageProduction !== null) ? pastBrokerageProduction : null),
      production_tier: productionTier || null,
      past_volume: pastVolume || null,
    }
    if (agentBrokerageInfo && isEmpty(agentBrokerageInfo)) {
      postAgentBrokerageInfo({ payload })
    } else if (agentBrokerageInfo && !isEmpty(agentBrokerageInfo)) {
      delete payload.agent_id
      updateAgentBrokerageInfo({
        payload,
        agentId: parseInt(agentId, 10),
        callback: () => {
          getAgentBrokerageInfo({ agentId: parseInt(agentId, 10) })
        },
      })
    }

    userPayload.id = agentDetails.id || ""
    userPayload.firstname = firstname || agentDetails.firstname
    userPayload.lastname = lastname || agentDetails.lastname
    userPayload.preferred_email = preferredEmail || null

    axios.put(`${API_V2_URL}user/info/update`, userPayload, {
      headers: {
        "secure-id": cookies.load("secureId"),
      },
    }).then((res) => {
      if (res.status === 200) {
        toast.success("Agent Details Updated")
      }
      getAgentDetails({
        agentId,
        isFetching: true,
      })
    }).catch((error) => {
      toast.error(error.response.data.error.message || "Error!")
    })
  }

  const addProductionTier = (value) => {
    if (value > 20 || value === 20) {
      setProductionTier("tier_1")
    } else if (value > 10 || value === 10) {
      setProductionTier("tier_2")
    } else if (value > 5 || value === 5) {
      setProductionTier("tier_3")
    } else if ((value > 0 || value === 0) && value < 5) {
      setProductionTier("tier_4")
    }
  }

  const onDateChange = (day, field) => {
    if (field === "ica") {
      setIcaSignedDate(moment(day))
    } else {
      setLicenseTransferredDate(moment(day))
    }
  }

  const handleNeighbourhoodSave = async () => {
    const payload = {
      cityServedId: currentInputCity.id,
      neighbourhoods: neighbourhoodArray,
      secure_id: cookies.load("secureId"),
    }

    await fetch(`${API_URL}user/neighbourhoods-served`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "secure-id": cookies.load("secureId"),
      },
      body: JSON.stringify(payload),
    })

    const {
      profile_name: username,
    } = agentDetails || {}
    fetchCitiesServed({
      username,
    })

    toast.success("Neighbourhoods saved")
    setNeighborhoods([])
    toggleCityModal(false)
  }

  return (
    <Wrap>
      <HeaderWrap>
        <h1>Edit agent Info</h1>
        <div>
          <Button success lg onClick={handleDownloadExcel} style={{
            margin: "0px 8px",
          }}>
            Export Assist Leads to CSV
          </Button>
          <Button disabled={disableSaveButton} success lg onClick={handleUpdateSave}>
            Save
          </Button>
        </div>
      </HeaderWrap>
      <h4>Agent&apos;s Details</h4>
      <div>
        <SubHeader>First Name</SubHeader>
        <UserInput
          type="text"
          {...firstNameBind}
          name="firstname"
        />
        {emptyFieldError && (
          <Invalid>Enter Valid Name</Invalid>
        )}
      </div>
      <div>
        <SubHeader>Last Name</SubHeader>
        <UserInput
          type="text"
          {...lastNameBind}
          name="lastname"
        />
        {lastNameError && (
          <Invalid>Enter Valid Last Name</Invalid>
        )}
      </div>
      <div>
        <SubHeader>Preferred Email</SubHeader>
        <UserInput
          type="text"
          {...preferredEmailBind}
          name="preferredEmail"
        />
        {preferredEmailError && (
          <Invalid>Enter Valid Email</Invalid>
        )}
        <p
          style={{
            margin: "0px 0px 30px 0px",
          }}
        >
        </p>
        <ClearPreferredEmailCTA onClick={() => setPreferredEmail("")}>
            Remove Email
        </ClearPreferredEmailCTA>
      </div>
      {isFetchingAgentBrokerageInfo === true ? <Loader /> : (
        <>
          <Fields>
            <SubHeader new>License transferred</SubHeader>
            <SelectAntD
              placeholder="Select"
              onChange={value => setIsLicenseTransferred(value)}
              value={isLicenseTransferred}
              style={{
                width: 200,
              }}
              options={[
                {
                  label: "Yes",
                  value: 1,
                },
                {
                  label: "No",
                  value: 0,
                },
              ]}
            />
          </Fields>
          <Fields>
            <SubHeader new>License Transferred Date</SubHeader>
            <DatePicker
              style={{
                width: 200,
              }}
              allowClear={false}
              value={!isEmpty(licenseTransferredDate) ? moment(licenseTransferredDate) : licenseTransferredDate}
              onChange={(_date, dateString) => onDateChange(dateString, "license")}
            />
          </Fields>
          <Fields>
            <SubHeader new>ICA Signed</SubHeader>
            <SelectAntD
              placeholder="Select"
              onChange={value => setIsIcaSigned(value)}
              value={isIcaSigned}
              style={{
                width: 200,
              }}
              options={[
                {
                  label: "Yes",
                  value: 1,
                },
                {
                  label: "No",
                  value: 0,
                },
              ]}
            />
          </Fields>
          <Fields>
            <SubHeader new>ICA Signed Date</SubHeader>
            <DatePicker
              style={{
                width: 200,
              }}
              allowClear={false}
              value={!isEmpty(icaSignedDate) ? moment(icaSignedDate) : icaSignedDate}
              onChange={(_date, dateString) => onDateChange(dateString, "ica")}
            />
          </Fields>
          <Fields>
            <SubHeader new>Primary Sales Representative</SubHeader>
            <SelectAntD
              placeholder="Select"
              onChange={value => setPrimarySalesRep(value)}
              value={primarySalesRep}
              style={{
                width: 200,
              }}
              options={SALES_REPRESENTATIVE}
            />
          </Fields>
          <Fields>
            <SubHeader new>Secondary Sales Representative</SubHeader>
            <SelectAntD
              placeholder="Select"
              onChange={value => setSecondarySalesRep(value)}
              value={secondarySalesRep}
              style={{
                width: 200,
              }}
              options={SALES_REPRESENTATIVE}
            />
          </Fields>
          <Fields>
            <SubHeader new>Primary Sales Rep Split Percentage</SubHeader>
            <InputNumber
              placeholder="50%"
              formatter={value => value ? `% ${value}` : ""}
              onChange={(value) => {
                setPrimarySplit(value)
              }}
              value={primarySplit}
              style={{
                width: 200,
              }}
            />
          </Fields>
          <Fields>
            <SubHeader new>Secondary Sales Rep Split Percentage</SubHeader>
            <InputNumber
              placeholder="50%"
              formatter={value => value ? `% ${value}` : ""}
              onChange={(value) => {
                setSecondarySplit(value)
              }}
              value={secondarySplit}
              style={{
                width: 200,
              }}
            />
          </Fields>
          <Fields>
            <SubHeader new>Past Production (Last 12 Months)</SubHeader>
            <InputNumber
              placeholder="No. of Deals"
              onChange={(value) => {
                setPastBrokerageProduction(value)
                addProductionTier(value)
              }}
              value={pastBrokerageProduction}
              style={{
                width: 200,
              }}
            />
          </Fields>
          <Fields>
            <SubHeader new>Previous Brokerage</SubHeader>
            <SelectAntD
              placeholder="Select"
              onChange={value => setPastBrokerageName(value)}
              value={pastBrokerageName}
              style={{
                width: 200,
              }}
              options={PAST_BROKERAGE_NAME_OPTIONS}
            />
          </Fields>
          {pastBrokerageName === "other" || pastBrokerageName === "Other" ? (
            <Fields>
              <SubHeader new>Other Previous Brokerage</SubHeader>
              <Input
                placeholder="Other previous Brokerage"
                onChange={e => setOtherPastBrokerageName(e.target.value)}
                value={otherPastBrokerageName}
                style={{
                  width: 200,
                }}
              />
            </Fields>
          ) : null}
          <Fields>
            <SubHeader new>Production Tier</SubHeader>
            <SelectAntD
              placeholder="Select"
              onChange={value => setProductionTier(value)}
              value={productionTier}
              style={{
                width: 200,
              }}
              options={PRODUCTION_TIER_OPTIONS}
            />
          </Fields>
          <Fields>
            <SubHeader new>Cap</SubHeader>
            <InputNumber
              placeholder="Enter Amount"
              formatter={value => value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}
              onChange={value => setCap(value)}
              value={cap}
              style={{
                width: 200,
              }}
            />
          </Fields>
          <Fields>
            <SubHeader new>Past Volume (Last 12 months)</SubHeader>
            <InputNumber
              placeholder="Enter Amount"
              formatter={value => value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}
              onChange={value => setPastVolume(value)}
              value={pastVolume}
              style={{
                width: 200,
              }}
            />
          </Fields>
          {console.log("preferences", preferences)}
          <Fields>
            <SubHeader new>Refer &amp; Earn Payout</SubHeader>
            <SelectAntD
              placeholder="Select"
              onChange={value => updateRnEPayout(value)}
              value={rnePayout}
              style={{
                width: 200,
              }}
              options={[
                {
                  label: "Quarterly",
                  value: "quarterly",
                },
                {
                  label: "Monthly",
                  value: "monthly",
                },
              ]}
            />
          </Fields>
        </>
      )}
      <CalendlyLinkWrap>
        <InnerWrap>
          <h4>Calendly Meeting Link</h4>
          {preferences.data && preferences.data.schedule_meeting_calendly_link_blink && (
           <>
             <p
               style={{
                 margin: "0px 0px 10px 0px",
               }}
             >
             Current Meeting Link
             </p>
             {" "}
             <FLexWrap>
               <a href={`//${preferences.data.schedule_meeting_calendly_link_blink}`} target="_blank">
                 {preferences.data.schedule_meeting_calendly_link_blink}
               </a>
               <ClearCalendlyCTA
                 onClick={() => handleRemoveCalendlyLink()}
               >
                Remove link
               </ClearCalendlyCTA>
             </FLexWrap>
           </>
          )}
        </InnerWrap>
        <UserInput
          type="text"
          {...calendlyBind}
          name="schedule_meeting_calendly_link_blink"
        />
        {invalidLink && (
          <Invalid>Enter Valid URL.</Invalid>
        )}
      </CalendlyLinkWrap>
      <h4>Agent States</h4>
      <Select
        options={USStates}
        isMulti
        onChange={(item) => { setSelectedStates(item) }}
        value={selectedStates}
      />
      <h4>Other Settings</h4>
      <>
        <LabelAndToggle
          label="Visible on public page"
        >
          <Toggle
            isChecked={preferences.data && preferences.data.visible_on_landing_page}
            onChange={() => {
              updateToggleableAgentSettings("visible_on_landing_page")
              setTCCheked(!isTCChecked)
            }}
          />
        </LabelAndToggle>
        <LabelAndToggle
          label="Agent Opt In"
        >
          <Toggle
            isChecked={preferences.data && preferences.data.is_using_radius_transaction_coordination}
            onChange={() => {
              updateToggleableAgentSettings("is_using_radius_transaction_coordination")
              setTCCheked(!isTCChecked)
            }}
          />
        </LabelAndToggle>
        { preferences.data && preferences.data.active_subscription === "Yes" && (
          <LabelAndToggle
            label="Assist Plus User"
          >
            <Toggle
              isChecked={preferences.data && preferences.data.is_assist_plus_user}
              onChange={() => {
                updateToggleableAgentSettings("is_assist_plus_user")
                setAssistPlus(!isAssistPlusChecked)
              }}
            />
          </LabelAndToggle>
        )}
        <LabelAndToggle
          label="Active to recieve referral"
        >
          <Toggle
            isChecked={preferences.data && preferences.data.is_active_to_receive_referral}
            onChange={() => {
              updateToggleableAgentSettings("is_active_to_receive_referral")
              setVacationModeChecked(!isVacationModeChecked)
            }}
          />
        </LabelAndToggle>
        {!fetchingCities && (
          <CitiesServedWrap>
            <h4>Cities Served</h4>
            <CityUL>
              <CityBlock
                hoverCSS
                onClick={() => {
                  setInputCity("")
                  setModifiedNeighborhoodArray([])
                  toggleCityModal(true)
                }}
              >
                Add City +
              </CityBlock>
              {cities && cities.cities && cities.cities.map(city => (
                <CityBlock
                  hoverCSS
                  hide={Boolean(city.isHide)}
                  onClick={() => {
                    setInputCity({
                      id: city.id,
                      city: city.city,
                      neighbourhoods: city.neighbourhoods && city.neighbourhoods.map(item => item.name),
                    })
                    toggleCityModal(true)
                  }}
                >
                  {city.city}
                </CityBlock>
              ))}
            </CityUL>
          </CitiesServedWrap>
        )}
      </>
      <Modal
        toClose={() => {
          toggleCityModal(false)
        }}
        show={showCityNeighbourHoodModal}
      >
        <GoogleLocationWrap>
          {currentInputCity ? (
            <CityBlock>
              {currentInputCity.city}
            </CityBlock>
          ) : (
            <GoogleLocation
              type="cities"
              value={currentInputCity}
              placeholder="Enter city"
              restrict={US_OBJ}
              types={["(cities)"]}
              location={(location) => {
                const selectedCity = {
                  city: get(location, "locality.long_name"),
                  state: get(location, "administrative_area_level_1.long_name"),
                  country: get(location, "country.long_name"),
                  latitude: get(location, "lat"),
                  longitude: get(location, "lng"),
                }
                handleCitySave(selectedCity)
              }}
            />
          )}
        </GoogleLocationWrap>
        <NeighborhoodWrap>
          {!isFetchingNeighbourhoods && modifiedNeighbourhood && modifiedNeighbourhood
            .map(item => (
              <Tag
                key={item.id}
                addable
                title={item.name}
                isSaved={item.isSaved}
                isUserSelected={item.isUserSelected}
                handleClick={() => {
                  if (item.isSaved) {
                    removeSavedNeighbourhood(item)
                  } else {
                    selectNeighborhood(item)
                  }
                }}
              />
            ))}
          {isFetchingNeighbourhoods && <ThreeDotLoader />}
        </NeighborhoodWrap>
        <CTAWrap>
          {currentInputCity && (
            <DeleteButton
              onClick={() => handleCityDelete()}
            >
              Remove City
            </DeleteButton>
          )}
          <SaveButton
            disabled={neighbourhoodArray && neighbourhoodArray.length <= 0}
            onClick={() => handleNeighbourhoodSave()}
          >
            Save
          </SaveButton>
        </CTAWrap>
      </Modal>
      <ToastContainer />
    </Wrap>
  )
}

export default globalContainer(searchContainer(AgentContainer(sidebarContainer(container(UpdateAgentInfo)))))
