import styled from "styled-components"
import {
  Input,
  Button,
} from "antd"

export const Wrap = styled.div`
  padding: 25px;
`

export const CouponInput = styled(Input)`
`

export const StyledLabel = styled.label`
  overflow: hidden;
  color: var(--Neutral-800, #191D23);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const InputWrap = styled.div`
  width: 400px;
`

export const SubmitCTA = styled(Button)``

export const FlexWrap = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  gap: 10px;
  padding-top: 10px;

  input {
    height: 36px;
    ${props => props.isErrored && `
      border: 1px solid #F00;
    `}
  }
`

export const ErrMessage = styled.p`
  overflow: hidden;
  color: #F00;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const SuccessMessage = styled.p`
  overflow: hidden;
  color: #1890ff;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
