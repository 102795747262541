import React from "react"
import styled, { css } from "styled-components"
import { format } from "date-fns"
import colors from "library/colors"
import { unixToDate } from "services/Utils"
import { getType } from "dumbComponents/Timeline/common/consts"
import Entity from "./Entity"

const Wrap = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 10px 0px;

  ${props => props.isLast && css`
      ${DotLine} {
        display: none;
      }
  `}
`

const Conference = styled.p`
  font-size: 12px;
`

const DateWrap = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  h4, p {
    margin: 0px;
    font-size: 14px;
    color: ${colors.contentText};
  }
  h4 {
    margin-left: 4px;
  }

  ${props => props.isActive && `
    color: ${colors.primaryColor} !important;
  `}

  ${props => (props.isStage || props.isActive) && `
    flex-direction: column;
    margin-top: 0px;
    > p {
      font-weight: 100;
      font-size: 16px;
    }
    > h4 {
      font-size: 20px;
      font-weight: 500;
      margin-left: 0px;
    }
  `}


`

const DetailsWrap = styled.div`
  margin: 5px 0px 20px 25px;
  flex: 1;
`

const Stage = styled.div`
  border-radius: 15px;
  text-transform: uppercase;
  background: ${colors.disabledColor};
  color: #4e676a;
  padding: 5px 15px;
  display: inline-block;
  ${props => props.isActive && `
    background: ${colors.successColor};
    color: #066752;
  `}
`

export const IconWrap = styled.div`
  position: relative;
  align-self: normal;
  padding-top: 5px;
  width: 20px;

  ${props => props && props.active ? `
    &:after {
      content: "";
      position: absolute;
      height: 27px;
      width: 1px;
      top: -30px;
      left: 50%;
      border-left: #d4d4d4 solid 2px;
      transform: translateX(-50%);
    }
  ` : `
    &:before {
      content: "";
      position: absolute;
      height: 20px;
      width: 1px;
      top: -20px;
      left: 50%;
      border-left: #d4d4d4 solid 2px;
      transform: translateX(-50%);
    }
  `}
`

const AdminDetails = styled.div`
    font-size: 12px;
    margin-top: 5px;
    i {
      color: #636363;
    }
`

export const DotLine = styled.div`
  z-index: 1;
  position: absolute;
  height: calc(100% - 10px);
  width: 2px;
  top: 35px;
  left: 50%;
  border-left: #d4d4d4 dotted 3px;
  transform: translateX(-50%);
  background: #FFF;
`

export const Icon = styled.div`
  width: 10px;
  height: 10px;
  background: ${colors.disabledColor};
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  z-index: 10;
  margin-top: 10px;

  ${props => props.isStage && `
    width: 20px;
    height: 20px;
    margin-top: 5px;
  `}

  ${props => props.active && `
    width: 20px;
    height: 20px;
    margin-top: 5px;
    &:after {
      width: 75%;
      height: 75%;
      content: "";
      border-radius: 50%;
      background: ${colors.primaryColor};
    }
  `}
`

const getConferenceDetails = (id, data, entity_name) => data &&
data[entity_name] && data[entity_name].filter(item => id === item.entity_id)

const AddedInfo = ({ addedBy, addedFor, reason }) => (
  <AdminDetails>
    {addedBy && (
      <React.Fragment>
        <i>Added by </i>
        <span>{addedBy}</span>
      </React.Fragment>
    )}
    {addedFor && (
      <React.Fragment>
        <i> Added for </i>
        <span>{addedFor}</span>
      </React.Fragment>
    )}
    {reason && (
      <React.Fragment>
        <i> Reason </i>
        <span>{reason}</span>
      </React.Fragment>
    )}
  </AdminDetails>
)

const Activity = ({
  isActive,
  currentEntityId,
  type_id,
  text,
  time,
  entityData,
  entity,
  entity_name,
  isLast,
  reason,
  added_by,
  added_for,
  manualCallRecording,
}) => {
  const isStage = (
    getType(type_id) === "sales_stage"
    || getType(type_id) === "checklist"
    || getType(type_id) === "callLogOrManualCall"
  )

  const conferenceDetails = entity_name == "call_logs" &&
  getConferenceDetails(currentEntityId, entityData, entity_name)

  const parsedTime = unixToDate(time)
  return (
    <Wrap isActive={isActive} isStage={isStage} isLast={isLast}>
      <DateWrap isStage={isStage} isActive={isActive}>
        <p>{format(parsedTime, "MMM")}</p>
        <h4>{format(parsedTime, "d")}</h4>
      </DateWrap>
      <IconWrap>
        <Icon active={isActive} isStage={isStage}>
          {/* <img src={getImageBasedOnDPI(item.iconUrl, 2)} alt={item.stage} /> */}
        </Icon>
        { !isStage && (
          <DotLine />
        )}
      </IconWrap>
      <DetailsWrap>
        { isStage ? (
          <div>
            <Stage isActive={isActive}>
              {text}
            </Stage>
            {time && (
              <React.Fragment>
                <span> at </span>
                <time>{format(parsedTime, "h:mm a")}</time>
              </React.Fragment>
            )}
          </div>
        ) : (
          <React.Fragment>
            <Entity
              entityName={entity_name}
              {...entity || { time, text, reason }}
            />
          </React.Fragment>
        )}
        <AddedInfo addedFor={added_for} addedBy={added_by} reason={reason} />
        {manualCallRecording && (
          <React.Fragment>
            <audio controls>
              <source src={manualCallRecording} type="audio/ogg" />
            </audio>
            {conferenceDetails && conferenceDetails.length > 0 && conferenceDetails.map(callLog => (
              <React.Fragment>
                {callLog.is_conference === 1 && (
                  <React.Fragment>
                    {callLog.status === "completed" ? (
                      <Conference>
                        Successful live transfer to {callLog.firstname} {callLog.lastname}
                      </Conference>
                    ) : (
                      <Conference>
                        Live transfer attempted, {callLog.firstname} {callLog.lastname} did not answer
                      </Conference>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
      </DetailsWrap>
    </Wrap>
  )
}

export default Activity
