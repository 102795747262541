import React from "react"
import { RADIUS_ASSIST_LOGO_BLACK } from "./../ImagesData"
import { containerStyles } from "./../common"

const storeImg = {
  height: 30,
}

const P = ({
  style,
  ...props
}) => (
  <p
    style={{
      fontSize: 12,
      color: "#818181",
      textAlign: "center",
      margin: 8,
      ...style,
    }}
    {...props}
  >
  </p>
)

const Footer = props => (
  <table style={{
    width: "100%",
  }}>
    <tbody valign="center">
      <tr>
        <td colSpan="5">
          <p style={{ textAlign: "center", color: "#555555", maxWidth: 270, margin: "0px auto" }}>
            Never miss an update, download the Radius App!
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ width: "50%", textAlign: "right", background: "none" }}>
          <a href="https://itunes.apple.com/us/app/agentdesks-real-estate-agents/id974478110?mt=8">
            <img
              src="https://d2fedz0by71ckz.cloudfront.net/images/ios_app_store_btn.png"
              alt="Download from App Store"
              style={storeImg}
            />
          </a>
        </td>
        <td style={{ width: "50%" }}>
          <a href="https://play.google.com/store/apps/details?id=com.agentdesks.android.crm&hl=en">
            <img
              src="https://d2fedz0by71ckz.cloudfront.net/images/google_play_store_btn.png"
              alt="Download from Google Play Store"
              style={storeImg}
            />
          </a>
        </td>
      </tr>
      <tr>
        <td colSpan="10" style={{ textAlign: "center" }}>
          <img
              src={RADIUS_ASSIST_LOGO_BLACK}
              alt="Radius Agent Header"
              style={{
                height: 20,
                width: "auto",
                margin: "10px auto",
              }}
            />
        </td>
      </tr>
      <tr>
        <td colSpan="10">
          <P style={{ marginBottom: 20 }}>© 2019 Radius Agent. All rights reserved.</P>
          <P>1160 Battery St East | Suite 100, San Francisco, CA 94111 </P>
          <P>If you no longer wish to receive these emails you can unsubscribe.</P>
        </td>
      </tr>
    </tbody>
  </table>
)

export default Footer
