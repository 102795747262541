import React from "react"
import styled from "styled-components"
import Loader from "dumbComponents/common/UI/Loader"
import moment from "moment"
import { TRANSACTION_TYPE } from "../../FieldData"
import { getSource } from "../../Util"

const Wrap = styled.div``

export const Row = styled.div`
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  gap: 14px;

  ${props => props.noMargin && `
    margin-top: 0px;
  `}
  ${props => props.withBorders && `
  `}
`

export const Col = styled.div`
  display: none;
  width: 226px;
  margin-right: 20px;
  margin-bottom: 20px;
  &:last-child {
    margin-right: 0px;
  }

  ${props => props.show && `
    display: block;
  `}
  ${props => props.noMargin && `
    margin: 0px !important;
  `}
`

const LabelAndTextWrap = styled.div`
  flex: 1;
  h5 {
    font-size: 12px;
    text-transform: uppercase;
    color: #555;
    margin: 0px;
    ${props => props.underline && `
      border-bottom: 1px solid #555;
    `}
  }
  p {
    color: #111;
    margin: 6px 0px 0px 0px;
  }
`
const CouponDiv = styled.div`
  width: 727px;
  padding: 5px;
  color: #FFFFFF;
  height: 35px;
  h3 {
    color: black;
    font-size: 14px;
    font-weight: 200;
  }
`

export const SectionWrap = styled.div`
  display: none;
  padding: 0px 20px 20px 54px;
  border-radius: 20px;
  border: 1px solid #ccc;
  position: relative;
  margin-bottom: 40px;
  margin-top: 10px;

  > h6 {
    top: -16px;
    left: 40px;
    position: absolute;
    background: #fff;
    padding: 5px 16px;
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
    color: #999;
  }

  ${props => props.show && `
    display: block;
   `}
`
const getLabel = value => value && value.replace(value[0], value[0]
  .toUpperCase())
  .replaceAll("_", " ")

const getUserReadableValue = (value) => {
  const filtered = TRANSACTION_TYPE.filter(item => item.value === value)
  return filtered && filtered.length > 0 && filtered[0].label
}

const LabelAndText = ({
  title,
  data,
  children,
  underline,
}) => (
  <LabelAndTextWrap underline={underline}>
    {title && (
      <h5>{title.replaceAll("_", " ")}</h5>
    )}
    {data && (
      <p>{data}</p>
    )}
    {children && (
      <div>
        {children}
      </div>
    )}
  </LabelAndTextWrap>
)

const listPriceTypes = ["new_listing", "listing_with_accepted_offer", "dual", "lease_listing", "external_referral_agreement"]
const listPriceClient = ["dual", "seller", "buyer", "landlord"]
const salesPriceClient = ["seller", "buyer", "dual"]
const salesPriceTypes = ["external_referral_agreement", "pending_contract", "dual"]
const rentPriceTypes = ["pending_lease", "lease_listing", "external_referral_agreement"]
const escrowFormFileTypes = ["dual", "pending_contract", "external_referral_agreement"]

const DetailedViewComponent = ({
  details,
  clients,
  vendors,
}) => {
  //vendor
  const vendorChosen = vendors && vendors?.vendors?.filter(x => x.id === vendors.selected_vendor_id)
  return (
    <>
      {details !== undefined && (
        <Wrap>
          <SectionWrap show>
            <h6>
       Basic Info
            </h6>
            <Row>
              <Col show>
                <LabelAndText
                  title="Listing Address"
                  data={details.listing_address}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="City"
                  data={details.city}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="State"
                  data={details.state}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Zipcode"
                  data={details.zipcode}
                />
              </Col>
            </Row>
          </SectionWrap>
          <SectionWrap show={clients && clients.length > 0}>
            <h6>
       Client Info
            </h6>
            <Row>
              <Col show>
                <LabelAndText
                  title="No of Clients"
                  data={clients ? clients.length : 0}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="File type"
                  data={details.file_type && getLabel(details.file_type)}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Source"
                  data={details.source ? details.source === "other" ? details.other_source : getSource(details.source) : "N/A"}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Client Type"
                  data={(details.client_type || "").toUpperCase()}
                />
              </Col>
            </Row>
            <br />
            {clients && clients.length > 0 && clients.map((client, index) => (
              <Row key={client.email} noMargin withBorders>
                <Col show noMargin style={{ width: 30, textAlign: "center" }}>
                  <LabelAndText
                    title={index === 0 ? "S/n" : ""}
                    underline={index === 0}
                    data={index + 1}
                  />
                </Col>
                <Col show noMargin>
                  <LabelAndText
                    title={index === 0 ? "Name" : ""}
                    underline={index === 0}
                    data={client.name}
                  />
                </Col>
                <Col show noMargin>
                  <LabelAndText
                    title={index === 0 ? "Email" : ""}
                    underline={index === 0}
                    data={client.email}
                  />
                </Col>
                <Col show noMargin>
                  <LabelAndText
                    title={index === 0 ? "Phone" : ""}
                    underline={index === 0}
                    data={client.phone}
                  />
                </Col>
              </Row>
            ))}
          </SectionWrap>
          <SectionWrap show>
            <h6>
       Transaction Details
            </h6>
            <Row>
              <Col show={["listing_with_accepted_offer"].includes(details.file_type)}>
                <LabelAndText
                  title="Sale Price"
                  data={details.price ? `$${details.price}` : "$0"}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Radius Transaction Type"
                  data={details.radius_transaction_type ? getLabel(details.radius_transaction_type) : "N/A"}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Commission Type"
                  data={details.commission_type ? getLabel(details.commission_type) : "N/A"}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Transaction Type"
                  data={details.transaction_type
                    ? details.transaction_type === "other"
                      ? details.other_transaction_type : getUserReadableValue(details.transaction_type) : "N/A"}
                />
              </Col>
              <Col show={details.commission_type === "percentage" || details.commission_type === "both"}>
                <LabelAndText
                  title="Commission Percentage"
                  data={details.commision_percentage ? `${details.commision_percentage}%` : "0%"}
                />
              </Col>
              <Col show={details.commision_percentage === "fixed_fee" || details.commision_percentage === "both"}>
                <LabelAndText
                  title="Fixed fee"
                  data={details.fixed_fee ? `$${details.fixed_fee}` : "0"}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Team split percentage"
                  data={details.team_split_percentage ? `${details.team_split_percentage}%` : "0%"}
                />
              </Col>
              <Col show={listPriceTypes.includes(details.file_type) && (listPriceClient.includes(details.client_type))}>
                <LabelAndText
                  title="List Price"
                  data={details.list_price ? `$${details.list_price}` : "$0"}
                />
              </Col>
              <Col show={salesPriceTypes.includes(details.file_type) && (salesPriceClient.includes(details.client_type))}>
                <LabelAndText
                  title="Sales Price"
                  data={details.price ? `$${details.price}` : "$0"}
                />
              </Col>
              <Col show={rentPriceTypes.includes(details.file_type)}>
                <LabelAndText
                  title="Rent Amount"
                  data={details.rent_amount ? `$${details.rent_amount}` : "$0"}
                />
              </Col>
              <Col show={rentPriceTypes.includes(details.file_type)}>
                <LabelAndText
                  title="Rent Term"
                  data={details.rent_amount_term
                    ? details.rent_amount_term === "other"
                      ? details.other_rent_amount_term : details.rent_amount_term : "N/A"}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Gross Commission"
                  data={details.gross_commission ? `$${details.gross_commission}` : "$0"}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Agent Commission"
                  data={details.agent_commission ? `$${details.agent_commission}` : "$0"}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Radius Transaction Fee"
                  data={details.radius_transaction_fee ? `$${details.radius_transaction_fee}` : "$0"}
                />
              </Col>
              <Col show={details.file_type !== "new_listing"}>
                <LabelAndText
                  title="Acceptance date (Date of contract)"
                  data={details.created_at
                    ? `${moment.unix((new Date(details.created_at).getTime())).format("D MMM, YYYY")}` : "N/A"}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Est. Closing Date (Escrow)"
                  data={details.closed_on
                    ? `${moment.unix((new Date(details.closed_on).getTime())).format("D MMM, YYYY")}` : "N/A"}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Confirmed/Tentative Closed Date (Escrow)"
                  data={details.confirmed_closed_on
                    ? `${moment.unix((new Date(details.confirmed_closed_on).getTime())).format("D MMM, YYYY")}` : "N/A"}
                />
              </Col>
              <Col show={details.file_type === "pending_lease"}>
                <LabelAndText
                  title="Lease Commencement Date"
                  data={details.lease_commencement_on
                    ? `${moment.unix((new Date(details.lease_commencement_on).getTime())).format("D MMM, YYYY")}` : "N/A"}
                />
              </Col>
              <Col show={details.file_type === "new_listing"}>
                <LabelAndText
                  title="Listing Expiration Date"
                  data={details.listing_expiration_date
                    ? `${moment.unix((new Date(details.listing_expiration_date).getTime())).format("D MMM, YYYY")}` : "N/A"}
                />
              </Col>
              <Col show={details.file_type === "new_listing"}>
                <LabelAndText
                  title="Listing Date"
                  data={details.listing_date
                    ? `${moment.unix((new Date(details.listing_date).getTime())).format("D MMM, YYYY")}` : "N/A"}
                />
              </Col>
            </Row>
            {details.coupon_claimed_on_file === 1 ? (
              <Row noMargin>
                <CouponDiv>
                  <h3>* Coupon has been applied for this file. Radius fee will change after status is changed to "Payment Received"</h3>
                </CouponDiv>
              </Row>
            ) : null}
          </SectionWrap>
          <SectionWrap show>
            <h6>
       Listing/Deal Details
            </h6>
            <Row>
              <Col show>
                <LabelAndText
                  title="Is all cash offer?"
                  data={details.is_all_cash_offer ? "Yes" : "No"}
                />
              </Col>
              <Col show={!details.is_all_cash_offer}>
                <LabelAndText
                  title="Lender Email Address"
                  data={details.lender_email_address || "N/A"}
                />
              </Col>
            </Row>
            <Row>
              <Col show>
                <LabelAndText
                  title="Opted for TC?"
                  data={details.is_using_deal_tc === null ? " N/A " : details.is_using_deal_tc === 1 ? "Yes" : "No"}
                />
              </Col>
              <Col show={(details.is_using_deal_tc !== 1)}>
                <LabelAndText
                  title="External TC Name"
                  data={details.tc_name || "-"}
                />
              </Col>
              <Col show={(details.is_using_deal_tc !== 1)}>
                <LabelAndText
                  title="External TC Email ID"
                  data={details.tc_email || "-"}
                />
              </Col>
              <Col show={(details.is_using_deal_tc !== 1)}>
                <LabelAndText
                  title="External TC Phone Number"
                  data={details.tc_phone || "-"}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Is Radius TC to Open Escrow ?"
                  data={details.is_radius_tc_to_open_escrow ? "Yes" : "No"}
                />
              </Col>
            </Row>
          </SectionWrap>
          {(details.is_radius_tc_to_open_escrow === 1 && vendors && vendors.selected_vendor_id && vendorChosen[0]) ? (
            <SectionWrap show={escrowFormFileTypes.includes(details.file_type)}>
              <h6>
             Escrow/Pre-Escrow Details
              </h6>
              <Row>
                <Col show>
                  <LabelAndText
                    title="Is Radius TC to Open Escrow ?"
                    data={details.is_radius_tc_to_open_escrow ? "Yes" : "No"}
                  />
                </Col>
                <Col show={details.is_radius_tc_to_open_escrow}>
                  <LabelAndText
                    title="Vendor Type"
                    data={vendorChosen[0].vendor_type || "-"}
                  />
                </Col>
                <Col show={details.is_radius_tc_to_open_escrow}>
                  <LabelAndText
                    title="Escrow Company Name"
                    data={vendorChosen[0].vendor_name || "-"}
                  />
                </Col>
                <Col show={details.is_radius_tc_to_open_escrow}>
                  <LabelAndText
                    title="Escrow Number"
                    data={vendorChosen[0].vendor_number || "-"}
                  />
                </Col>
              </Row>
              <Row>
                <Col show>
                  <LabelAndText
                    title="Escrow Officer Name"
                    data={`${vendorChosen[0].vendor_officer_first_name} ${vendorChosen[0].vendor_officer_last_name}`}
                  />
                </Col>
                <Col show={details.is_radius_tc_to_open_escrow}>
                  <LabelAndText
                    title="Escrow Officer Phone Number"
                    data={vendorChosen[0].vendor_phone || "-"}
                  />
                </Col>
                <Col show={details.is_radius_tc_to_open_escrow}>
                  <LabelAndText
                    title="Escrow Officer Email"
                    data={vendorChosen[0].vendor_email || "-"}
                  />
                </Col>
                <Col show={details.is_radius_tc_to_open_escrow}>
                  <LabelAndText
                    title="Company Address"
                    data={vendorChosen[0].vendor_address || "-"}
                  />
                </Col>
              </Row>
              <Row>
                <Col show>
                  <LabelAndText
                    title="Escrow City"
                    data={vendorChosen[0].vendor_city || "-"}
                  />
                </Col>
                <Col show={details.is_radius_tc_to_open_escrow}>
                  <LabelAndText
                    title="Escrow State"
                    data={vendorChosen[0].vendor_state || "-"}
                  />
                </Col>
                <Col show={details.is_radius_tc_to_open_escrow}>
                  <LabelAndText
                    title="Escrow Zipcode"
                    data={vendorChosen[0].vendor_zipcode || "-"}
                  />
                </Col>
                <Col show={details.is_radius_tc_to_open_escrow}>
                  <LabelAndText
                    title="Notes"
                    data={vendorChosen[0].notes || "-"}
                  />
                </Col>
              </Row>
            </SectionWrap>
          ) : (
            <SectionWrap show>
              <Row>
                <Col show>
                  <LabelAndText
                    title="No Vendor Chosen"
                    data=""
                  />
                </Col>
              </Row>
            </SectionWrap>
          )}
          <SectionWrap show>
            <h6>
       Misc Details
            </h6>
            <Row>
              <Col show>
                <LabelAndText
                  title="Is Active Listing?"
                  data={details.active_listing_status
              && details.active_listing_status === "active_listing" ? "Yes" : "No"}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Is Co-listed deal?"
                  data={details.co_listing_agents && details.co_listing_agents.length > 0 ? "Yes" : "No"}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Co-listed agent"
                  data={details.co_listing_agents
              && details.co_listing_agents.length > 0
              && `${details.co_listing_agents[0].firstname} ${details.co_listing_agents[0].lastname || ""}`}
                />
              </Col>
              <Col show>
                <LabelAndText
                  title="Is Radius Lead?"
                  data={details.is_radius_lead ? "Yes" : "No"}
                  //heree
                />
              </Col>
            </Row>
          </SectionWrap>
        </Wrap>
      )}
    </>
  )
}

export default DetailedViewComponent
