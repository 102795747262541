import React, { useState } from "react"
import axios from "axios"
import {
  Select as SelectAntD, Input, InputNumber, Space, Tag as EmailTag, Tooltip, DatePicker,
} from "dumbComponents/common/UI/antd"
import AI from "container/AI"
import {
  DebouncedSelect,
  GoogleLocationInput,
  ResetCTA,
} from "./Elements"
import {
  Wrap,
} from "../commonStyles"
import {
  FilterByText,
  FlexWrap,
  FormWrap,
  FormElement,
  HideUntilAgentID,
} from "./filterCommonStyles"
const baseUrl = process.env.REACT_APP_API_BASE_URL

const { RangePicker } = DatePicker

const FilterSection = ({
  fetchTeamsResponse,
  query,
  constructQuery,
}) => {
  const [searchValue, setValue] = useState([])
  const [city, setCity] = useState()

  const {
    data: teams,
    isFetching: fetchingTeams,
  } = fetchTeamsResponse || {}
  
  const parsedList = teams && teams.length > 0 && teams.map(x => ({ label: x.name, value: x.id })) || []

  const handleFilterQuery = (key, value) => {
    const constructed = {
      ...query,
    }

    switch (key) {
      case "agent_id":
        constructed.agent_id = value.value
        break
      case "team_id":
        constructed.team_id = value
        break
      case "location":
        constructed.city = value.city
        constructed.state = value.state
        break
      case "time":
        constructed.start_date = value.startDate
        constructed.end_date = value.endDate
        break
      default:
        break
    }

    constructQuery({
      constructed,
    })
  }

  const fetchUserList = async (search) => {
    const url = `${baseUrl}/v1.0/agent/elastic/query`

    const payload = {
      text_value: search,
      sortIsAsc: false,
      ascending: false,
      facade: false,
      from: 0,
      size: 10,
      text_search_params: ["email", "full_name"],
      unRegistered: false,
    }

    const mapped = await axios.post(url, payload)
      .then((res) => {
        const {
          response,
        } = res.data || {}
        return response && response.agents || []
      })

    const options = mapped && mapped.map(item => ({
      value: item.id,
      label: `${item.firstname || "Radius"} ${item.lastname || "User"}`,
    }))

    return options
  }

  return (
    <Wrap>
      <FlexWrap>
        <FilterByText>
        Filter By |
        </FilterByText>
        <FormWrap>
          <FormElement flex className="styled_Borders">
            <p>Agent Name</p>
            <DebouncedSelect
              showSearch
              placeholder="Select users"
              value={searchValue}
              fetchOptions={fetchUserList}
              onChange={(newValue) => {
                setValue(newValue)
                handleFilterQuery("agent_id", newValue)
              }}
            />
          </FormElement>
          <FormElement anim className="styled_Borders">
            <SelectAntD
              placeholder="Team Name"
              onChange={(val) => {
                handleFilterQuery("team_id", val)
              }}
              // disabled={query && !query.agent_id}
              style={{
                width: 200,
              }}
              loading={fetchingTeams}
              options={parsedList}
            />
          </FormElement>
          <FormElement anim className="styled_Borders">
            <GoogleLocationInput
              city={city}
              // disabled={query && !query.agent_id}
              setCity={(val) => {
                setCity(() => {
                  handleFilterQuery("location", val)
                  console.log(val)
                  return val
                })
              }}
            />
          </FormElement>
          <FormElement
            anim
          >
            <RangePicker
              // disabled={query && !query.agent_id}
              onChange={(value) => {
                let startDate = ""
                let endDate = ""
                if (value && value.length > 0) {
                  startDate = value[0].unix()
                  endDate = value[1].unix()
                }
                handleFilterQuery("time", { startDate, endDate })
              }}
            />
          </FormElement>
          <FormElement>
            <ResetCTA />
          </FormElement>
        </FormWrap>
      </FlexWrap>
    </Wrap>
  )
}

export default AI(FilterSection)

