import * as Actions from "./actions"

const initState = {
  isMulticallerActive: false,
  isConnected: false,
}

export default function multipleCallerReducer(state = initState, action) {
  switch (action.type) {
    case Actions.createInitStateAction.type: {
      return {
        ...state,
        isConnected: true,
        callStatus: {
          ...action.data,
        },
      }
    }
    
    case Actions.updateCallStateAction.type: {
      return {
        ...state,
        callStatus: {
          ...action.data,
        },
      }
    }
    default:
      return {
        ...state,
      }
  }
}
