import axios from "axios"

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const IMAGE_API = process.env.IMAGE_API

export const getCoursesAPI = () => axios
.get(`${baseUrl}/v1.0/shiksha/courses`)

export const getAllEventsNotificationAPI = () => axios
.get(`${baseUrl}/v1.0/notifications/push/academyDailyEventSummaryNotification`)

export const setAllEventsNotificationAPI = (payload) => axios
.put(`${baseUrl}/v1.0/notifications/push/academyDailyEventSummaryNotification`, payload)

export const getMultipleInstructorsAPI = () => axios
.get(`${baseUrl}/v1.0/shiksha/instructors`)

export const createInstructorAPI = (payload) => axios
.post(`${baseUrl}/v1.0/shiksha/instructor`, payload)

export const createCourseAPI = (payload) => axios
 .post(`${baseUrl}/v1.0/shiksha/course`, payload)

 export const updateCourseAPI = (payload, id) => axios
  .put(`${baseUrl}/v1.0/shiksha/course/${id}`, payload)

 export const deleteCourseAPI = (payload) => axios
 .put(`${baseUrl}/v1.0/shiksha/course`, payload)

 export const getCourseDetailsAPI = (id) => axios
 .get(`${baseUrl}/v1.0/shiksha/course/${id}`)

 export const getAllEventsAPI = () => axios
 .get(`${baseUrl}/v1.0/shiksha/events`)

 export const getUpcomingEventsAPI = () => axios
 .get(`${baseUrl}/v1.0/shiksha/events?upcoming=true`)

 export const createLessonAPI = (payload) => axios
 .post(`${baseUrl}/v1.0/shiksha/lesson`, payload)

 export const updateLessonAPI = (payload, id) => axios
 .put(`${baseUrl}/v1.0/shiksha/lesson/${id}`, payload)

 export const uploadImageAPI = image => axios
 .post("https://staging.agentdesks.com/v1.0/image", image).then((res) => {
  return res
 })

 export const updateInstructorAPI = (payload, id) => axios
 .put(`${baseUrl}/v1.0/shiksha/instructor/${id}`, payload)

 export const deleteInstructorAPI = (payload, id) => axios
 .put(`${baseUrl}/v1.0/shiksha/instructor/${id}`, payload)

 export const fetchInstructorDetailsAPI = (id) => axios
 .get(`${baseUrl}/v1.0/shiksha/instructors?instructor_ids=${id}`)

 export const deleteLessonAPI = (payload, id) => axios
 .put(`${baseUrl}/v1.0/shiksha/lesson/${id}`, payload)

 export const getEventDetailsAPI = (id) => axios
 .get(`${baseUrl}/v1.0/shiksha/event/${id}`)

 export const createEventAPI = (payload) => axios
 .post(`${baseUrl}/v1.0/shiksha/event`, payload)

 export const updateEventAPI = (payload, id) => axios
 .put(`${baseUrl}/v1.0/shiksha/event/${id}`, payload)

 export const deleteEventAPI = (payload, id) => axios
 .put(`${baseUrl}/v1.0/shiksha/event/${id}`, payload)

 export const batchUpdateLessonAPI = (payload) => axios
 .put(`${baseUrl}/v1.0/shiksha/lessons/batch-update`, payload)

 export const getAllCategoriesAPI = () => axios
 .get(`${baseUrl}/v1.0/shiksha/categories`)

 export const getCategoryDetailsAPI = categoryId => axios
 .get(`${baseUrl}/v1.0/shiksha/categories?category_ids=${categoryId}`)

 export const updateCategoryDetailsAPI = (categoryId, payload) => axios
 .put(`${baseUrl}/v1.0/shiksha/category/${categoryId}`, payload)

 export const createCategoryAPI = payload => axios
 .post(`${baseUrl}/v1.0/shiksha/category`, payload)

 export const getCourseListForCategoriesAPI = id => axios
 .get(`${baseUrl}/v1.0/shiksha/courses?categoryIds=${id}`)

 export const updateCourseBatchAPI = payload => axios
 .put(`${baseUrl}/v1.0/shiksha/courses/batch-update`, payload)
