import React from "react"
import { Tabs } from "antd"
import styled from "styled-components"
import { get } from "lodash"
import EditAgentInfo from "./EditAgentInfo"
import SetupProfileInfo from "./SetupProfileInfo"

const Wrap = styled.div`
  padding: 20px;
`

class EditAgentInfoDashboard extends React.PureComponent {
  componentDidMount() {

  }

  render() {
    const { match } = this.props
    const agentId = get(match, "params.agentId")
    return (
      <Wrap>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Edit Agent Info" key="1">
            <EditAgentInfo agentId={agentId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Setup Profile Info" key="2">
            <SetupProfileInfo agentId={agentId} />
          </Tabs.TabPane>
        </Tabs>
      </Wrap>
    )
  }
}

export default EditAgentInfoDashboard
