import React, { Component } from "react"
import styled from "styled-components"
import Async, { makeAsyncSelect } from 'react-select/async';
import AsyncSelect from 'react-select/async';
import DashboardContainer from "container/Dashboards"

const Wrap = styled.div`
`

class SearchBar extends Component {

 componentDidMount() {

 }

 handleInputChange = (newValue) => {
  const {
   currentSearchValue,
   agentSearch,
  } = this.props
  this.setState({
   inputValue: newValue,
  })
  if(newValue) {
   agentSearch({
    search: newValue,
   })
  }
  currentSearchValue(newValue)
  return newValue
 }

 createOptions = (agents) => {
  if(agents) {
   const op = agents.map(key => {
    return { value: key , label: key.firstname }
   })
   return op
  }
 }

 loadOptions = (inputValue, callback) => {
  const {
   agentSearchResponse,
   agentsOptions,
  } = this.props

  const {
   data: agentsData,
   isFetching,
  } = agentSearchResponse || {}


  if(!isFetching){
   callback(agentsOptions)
  }

};

 render() {
  return (
   <Wrap>
    <AsyncSelect
      cacheOptions
      loadOptions={this.loadOptions}
      defaultOptions
      isMulti
      onInputChange={this.handleInputChange}
    />
   </Wrap>
  )
 }

}


export default DashboardContainer(SearchBar)
