import React, { PureComponent } from "react"
import styled from "styled-components"
import { checkForUserObject } from "services/Utils"
import { withRouter } from "react-router-dom"
// import CircularImage from "dumbComponents/common/CircularImage" //not supported
import TechAssetsContainer from "container/TechAssets"
// import ListingsPropertyFallbackImage from "images/Graphics/OffersAssets/PropertyListingImageFallback.jpg"
import {
  Select,
  Modal,
} from "dumbComponents/common/UI/antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import ConfirmationNote from "../ConfirmationNote"
import {
  BuyerAgentInfoRoute,
  OfferStatuses,
  ColorMap,
} from "../../consts"

const user = checkForUserObject("user")

const InfoContainer = styled.div`
  width: 100%;
  padding: 0 20px;
`

const PropertyInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DateContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 5px 0 0px;
  border-top: 0.5px solid #C7C7C7;
  padding-top: 5px;
  p {
    font-family: $"ATC";
    margin-right: 28px;
    margin-bottom: 0px;
  }
`

const Wrap = styled.li`
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px 2px rgb(204 204 204 / 25%);
  border-radius: 4px;
  justify-content: space-between;
  height: 160px;
  margin: 25px 0px;
  cursor: pointer;
  flex-direction: column;
`

const Image = styled.img`
  width: 163px;
  height: 160px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
`

const ListHeader = styled.h1`
  font-family: $"ATC";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  color: #303030;
  margin: 0;
  ${props => props.mt && `
    margin-top: ${props.mt};
  `}
`

const ListSubtext = styled.p`
  font-family: $"ATC";
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  color: #8C8C8C;
  ${props => props.mt && `
    margin-top: ${props.mt};
  `}
  p {
    
  }
`

const StatusButton = styled.div`
  width: 12.23px;
  height: 12.23px;
  background: #B3E4C7;
  ${props => props.bg && `
    background: ${props.bg}
  `}
  border-radius: 50%;
`

const ListContainer = styled.div``

const FirstBox = styled.div`
  display: flex;
  align-items: center;
`

const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  background: #F9F9F7;
  padding-left: 10px;
`

const StatusWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex-direction: column;
  h4 {
    font-family: $"ATC";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #849DF8;
    margin: 10px 0 0;
  }
`

const ImageContainer = styled.div`
  display: flex;
`

class OffersListing extends PureComponent {
  setDealToView = (dealId) => {
    const { history } = this.props
    history.push(`${BuyerAgentInfoRoute}?dealId=${dealId}`)
  }

  handleChange = (prevValue, newValue, id) => {
    const {
      updateOfferStatus,
      updateNote,
      offerStatusChangeNote,
    } = this.props

    const PREVIOUS = this.getHumanReadableValue(prevValue)
    const UPDATED = this.getHumanReadableValue(newValue)

    const { confirm } = Modal
    const context = this

    const statusChangeText = `Status change from ${PREVIOUS} to ${UPDATED}`

    if (prevValue !== newValue) {
      confirm({
        title: "Do you want to update the offers status?",
        icon: <ExclamationCircleOutlined />,
        content: <ConfirmationNote
          text={statusChangeText}
          updateNote={updateNote}
          value={offerStatusChangeNote}
        />,
        onOk() {
          const payload = {
            notes: context?.props?.offerStatusChangeNote || "",
            offer_id: id,
            status: newValue,
          }
          updateOfferStatus({
            payload,
          })
        },
        onCancel() {
          //
        },
      })
    }
  }

  getColor = status => ColorMap.find(cl => cl.label === status)?.value

  getHumanReadableValue = status => OfferStatuses.find(stat => stat.value === status)?.label

  render() {
    const {
      listingData,
    } = this.props

    return (
      <Wrap>
        <FirstBox>
          {/* <Image
            src={ListingsPropertyFallbackImage}
            onClick={(e) => {
              this.setDealToView(listingData.id, listingData.primary_agent_id)
            }}
          /> */}
          <InfoContainer>
            <PropertyInfoContainer>
              <ListContainer
                onClick={(e) => {
                  this.setDealToView(listingData.id, listingData.primary_agent_id)
                }}
              >
                <ListHeader>
                  {listingData.apt ? (
                    <>
                      {listingData.apt}
                    </>
                  ) : (
                    <>
                      Incomplete Property Information
                    </>
                  )}
                </ListHeader>
                <ListHeader mt="3px">
                  {listingData.city && (
                    <>
                      {`${listingData.city}, ${listingData.state}, ${listingData.zipcode}`}
                    </>
                  )}
                </ListHeader>
                <ListSubtext mt="8px">
                  <span>
                    Seller(s):&nbsp;
                  </span>
                  {listingData.seller_clients && listingData.seller_clients.length > 0 ? listingData.seller_clients.map((col, index) => (
                    <span>
                      {`${index === 0 ? "" : ", "}${col.name || ""}`}
                    </span>
                  )) : (
                    <span>
                      N/A
                    </span>
                  )}
                </ListSubtext>
              </ListContainer>
              <StatusWrap>
                <SelectWrap>
                  <StatusButton bg={this.getColor(listingData.current_offer_status)} />
                  <Select
                    value={listingData.current_offer_status}
                    onChange={
                      (e) => {
                        this.handleChange(listingData.current_offer_status, e, listingData.id)
                      }}
                    style={{
                      width: 155,
                      backgroundColor: "#F9F9F7",
                    }}
                    bordered={false}
                    disabled={listingData.current_offer_status === "incomplete"}
                    options={OfferStatuses}
                  />
                </SelectWrap>
              </StatusWrap>
            </PropertyInfoContainer>
            <DateContainer
              onClick={(e) => {
                e.stopPropagation()
                this.setDealToView(listingData.id, listingData.primary_agent_id)
              }}
            >
              {/* <ImageContainer>
                <CircularImage
                  style={{ marginRight: "5px" }}
                  size={40}
                  charSize="18px"
                  image={user.agent_image}
                  name={`${user.firstname || ""}
                  ${user.lastname || ""}`}
                />
                {listingData.co_listing_agents
                  && listingData.co_listing_agents.length
                  && listingData.co_listing_agents.map((col) => {
                    if (col.agent_id !== listingData.agent_id) {
                      return (
                        <CircularImage
                          style={{ marginRight: "5px" }}
                          size={40}
                          charSize="18px"
                          image={col.profile_url}
                          name={`${col.firstname || ""}
                          ${col.lastname || ""}`}
                        />
                      )
                    }
                  })}
              </ImageContainer> */}
            </DateContainer>
          </InfoContainer>
        </FirstBox>
      </Wrap>
    )
  }
}

export default withRouter(TechAssetsContainer(OffersListing))
