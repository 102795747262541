import React from "react"
import { Route, Switch } from "react-router-dom"
// import { Helmet } from "react-helmet" //missing
import styled from "styled-components"
import {
  Listings,
  Header,
  BuyerAgentInformation,
  BuyerInfo,
  OfferInfo,
  ListingAgentInfo,
  SellerInfo,
  CreateVendorInfo,
  LenderInfo,
  VendorsSelect,
  LenderSelect,
  OfferWritingInfo,
  DocumentInformation,
} from "./Components"
import {
  BuyerAgentInfoRoute,
  BuyerInfoRoute,
  OfferInfoRoute,
  ListingAgentInfoRoute,
  SellerInfoRoute,
  EscrowInfoRoute,
  LenderInfoRoute,
  VendorSelectRoute,
  LenderSelectRoute,
  CreateVendorRoute,
  UpdateVendorRoute,
  UpdateLenderRoute,
  OfferWritingRoute,
  DocumentsInfoRoute,
} from "./consts"

const Wrap = styled.div`
  padding: 42px 50px;
`

const Offers = ({
  history,
}) => (
  <React.Fragment>
    <Wrap>
      {/* <Helmet>
        <title>Offers | Radius Agent</title>
      </Helmet> */}
      <Header
        history={history}
        isFormPage={history.location.pathname.includes("/offers/")}
      />
      <Switch>
        <Route path={BuyerAgentInfoRoute} exact component={BuyerAgentInformation} />
        <Route path={BuyerInfoRoute} exact component={BuyerInfo} />
        <Route path={OfferInfoRoute} exact component={OfferInfo} />
        <Route path={SellerInfoRoute} exact component={SellerInfo} />
        <Route path={OfferWritingRoute} exact component={OfferWritingInfo} />
        <Route path={ListingAgentInfoRoute} exact component={ListingAgentInfo} />
        <Route path={VendorSelectRoute} exact component={VendorsSelect} />
        <Route path={CreateVendorRoute} exact component={CreateVendorInfo} />
        <Route path={`${UpdateVendorRoute}/:id`} exact component={CreateVendorInfo} />
        <Route path={LenderSelectRoute} exact component={LenderSelect} />
        <Route path={LenderInfoRoute} exact component={LenderInfo} />
        <Route path={DocumentsInfoRoute} exact component={DocumentInformation} />
        <Route path={`${UpdateLenderRoute}/:id`} exact component={LenderInfo} />
      </Switch>
    </Wrap>
  </React.Fragment>
)

export default Offers

