import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as Actions from "./actions"
import {reset} from 'redux-form';


function mapStateToProps(state) {
    const { dashboard } = state
    return {
         globalState: state,
        ...dashboard,
    }
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
           currentSearchValue: Actions.handleSearchInputAction.call,
           agentSearch: Actions.handleAgentSearchAction.request,
           setCurrentAgents: Actions.setCurrentAgentsAction.call,
           fetchMetrics: Actions.fetchMetricsAction.request,
           setLowerInbound: Actions.lowerInboundMissedCallAction.call,
           setUpperInbound: Actions.upperInboundMissedCallAction.call,
           setLowerZillow: Actions.lowerZillowMissedCallAction.call,
           setUpperZillow: Actions.upperZillowMissedCallAction.call,
           setLowerRinging: Actions.lowerRingingMissedCallAction.call,
           setUpperRinging: Actions.upperRingingMissedCallAction.call,
           setCallerQTY: Actions.callerQtyAction.call,
           setMinTextResponseTime: Actions.minTextResponseTimeAction.call,
           setMaxTextResponseTime: Actions.maxTextResponseTimeAction.call,
           setAvgCallTime: Actions.avgCallTimeAction.call,
           setCurrentDateFilter: Actions.setCurrentDateFilterAction.call,
           setCurrentSubscriptionFilter: Actions.setCurrentSubscriptionFilterAction.call,
           clearFilters: Actions.clearAllFiltersAction.call,
           setSortType: Actions.setCurrentSortAction.call,
           setCurrentDay: Actions.setCurrentDurationFilterDayAction.call,
           setCurrentHour: Actions.setCurrentDurationFilterHourAction.call,
           setCurrentMin: Actions.setCurrentDurationFilterMinAction.call,
           setCurrentReferralType: Actions.setCurrentReferralTypeAction.call,
           setCurrentCallWorkType: Actions.setCurrentWorkingHoursAction.call,
           setCustomDate: Actions.setCurrentCustomDateRangeAction.call,
           setCurrentReferralSource: Actions.setCurrentReferralSourceAction.call,
           setCurrentTextDay: Actions.setCurrentTextDurationFilterDayAction.call,
           setCurrentTextHour: Actions.setCurrentTextDurationFilterHourAction.call,
           setCurrentTextMin: Actions.setCurrentTextDurationFilterMinAction.call,
           setCurrentFiltersURL: Actions.setCurrentFiltersAction.call,
           setCurrentPaginationLimit: Actions.modifyCurrentLimitAction.call,
        },
        dispatch
    ),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)
