import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { fetchAgentSearchAction, rosterFetchAgentSearchAction, toggleAgentListDropdownAction } from "../Agent/actions"
import {
  resetSelectionsAction, getAgentDetailsAction,
} from "./actions"

function mapStateToProps(state) {
  const { agent } = state
  const { agents, isAgentListDropdownVisible } = agent
  return {
    agents,
    isAgentListDropdownVisible,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      fetchAgentSearch: fetchAgentSearchAction.request,
      rosterFetchAgentSearch: rosterFetchAgentSearchAction.request,
      resetSelections: resetSelectionsAction.call,
      clearAgent: getAgentDetailsAction.clear,
      toggleAgentList: toggleAgentListDropdownAction.call,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
