import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as Actions from "./actions"

function mapStateToProps(state) {
  const { multipleCaller } = state
  return {
    ...multipleCaller,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      connectToSocket: Actions.connectToSocketAction.call,
      changeCallState: Actions.changeCallStateAction.call,
      deleteItemAutomatically: Actions.deleteItemAutomaticallyAction.call,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
