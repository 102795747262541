import React, { Component } from "react"
import styled, { css } from "styled-components"
import root, { console } from "window-or-global"
import container from "container/Agent"
import Popup from "reactjs-popup";
import Select, { createFilter } from 'react-select';
import colors from "lib/colors"
import { isEmpty, get } from "lodash"
import { notify } from "react-notify-toast";
import Plans from "./PlansDisplay"
import Checkout from "./Checkout"
import { DefaultLoader } from "components/UI/Loader/Loader";
import {
	Route, Switch, Redirect, Link,
} from "react-router-dom"
import classnames from "classnames";
import DatePicker from "react-datepicker";
import moment from "moment";
import { NotifyBugsnagError, ShowErrorToast, ShowErrorToastFromText, ShowSuccessToast } from "../../lib/ErrorUtils";
import { Box, Flex } from "@rebass/grid";
import bugsnag from "bugsnag-js";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;
const bugsnagBlinkKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(bugsnagBlinkKey);

const AgentDetails = styled.div`
  display: flex;
  padding-left: 25px;
  margin-top: 16px;
  border-bottom: solid 1px #e0e0e0;
  }
  h1, p {
   margin-bottom: 5px;
  }
  p {
   margin-top: 0px;
  }
  .autoRefer {
   border-left: solid 1px #e0e0e0;
   padding: 30px 45px;
   label {
    margin-left: 10px;
   }
   .error {
    color: red;
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
   }
   p {
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: bold;
    color: #7a7a7a;
   }
  }
`

const Details = styled.div``

const CTAWrap = styled.div``

const AgentWrap = styled.div`
    background: #FFF;
    padding: 10px 50px;
    text-align: center
    border: 1px solid #CC:
    border-radius: 5px;
`

const TextWrap = styled.div`
    display: inline-block;
    text-align: left;
    width: 100%;
`

const PlanWrap = styled.div`
    margin-right: 10px;
    flex-wrap: wrap;
`

const Plan = styled.div`
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #CCC;
    background: #FFF;
    padding: 10px;
    margin-top: 10px;

    ${props => props.isSelected && `
        background: #2ecc71;
        color: #FFF;
        font-weight: bold;
    `}
`

const Tabs = styled.ul`
  display: flex;
  padding: 0px;
  margin: 0px;
  padding-left: 47px;
  margin-top: 15px;
  position: relative;
  list-style: none;
  li{
   margin-right: 20px;
  }
`
const Tab = styled.li`
  padding: 10px 1px 5px 1px;
  position: relative;
  top: 3px;
  cursor: pointer;
  ${props => props.isActive
		&& css`
      border-bottom: ${colors.primaryColor} solid 4px;
      color: ${colors.primaryColor};
    `}
`

const StyledDatePicker = styled(DatePicker)`
  outline: none;
  background-color: white;
  font: inherit;
  padding: 6px 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 0.5px solid #d4d4d4;
  border-radius: 2px;
  height: 30px;
  box-shadow: none;
  overflow: visible;
  margin: 0;
  margin-bottom: 10px;
  color: inherit;
  cursor: text;
  &:focus,
  &:active {
    box-shadow: none;
    border: solid 0.5px #11adf3;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc !important;
    font-weight: 300;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ccc !important;
    font-weight: 300;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ccc !important;
    font-weight: 300;
  }
`;

const SubscriptionStatus = styled.div`
  border-radius: 2px;
  background-image: linear-gradient(104deg,#2bda8e -1%,#0ac4ba 100%);
  color: white;
  font-size: 14px;
  width: 65px;
  text-align: center;
  padding: 2px;

  ${props => props.isSubscriptionPaused && `
    background-image: linear-gradient(104deg,#ffd460 -1%,#ffd460 100%);
   `}
`

const isLinkActive = (url) => {
	const { pathname } = root.location || ""
	return pathname.split("/").includes(url)
}


class Subscription extends Component {

	state = {
		selectedPlan: {},
		isPlanSelected: false,
		agentId: '',
		secure_id: '',
		stripe_token_id: '',
		type: "Subscriptions",
		sourcesDropdown: [],
		currentSource: null,
		templates: [],
		currentText: '',
		isSingleActive: true,
		currentTabMode: "single",
		agentWebsite: '',
		maxCall: null,
		renewalDate: null,
		pauseDuration: null,
		loading: false,
		isSubscriptionPaused: false,
		allCounties: [],
		agentCounties: [],
		selectedCounties: [],
		inputValue: "",
		savingCounties: false
	}

	handleInputChange = (newValue) => {
		this.setState({ inputValue: newValue });
		return newValue;
	};

	handleChange = (selectedCounties) => {
		this.setState({ selectedCounties })
	}


	getCountiesDiv = (counties) => {
		counties = counties.map(county => ({ label: county.name + ', ' + county.state, value: county.name + ', ' + county.state, OGR_FID: county.OGR_FID }));

		return <div>
			<Select
				options={counties}
				filterOption={createFilter({ ignoreAccents: false })}
				defaultValue={this.state.selectedCounties}
				onChange={this.handleChange}
				isMulti
			/>
		</div>;
	}
	componentDidMount() {
		try {
			const {
				fetchStripePlans,
				fetchAgentSecureId,
				clearCheckoutPlan,
				getAutoRefer,
				clearErrors
			} = this.props
			const { id } = this.props.location.state.agent
			clearErrors()
			getAutoRefer({ agentId: id })
			fetchStripePlans()
			this.fetchSelectedCounties(id)
			this.fetchAllCounties()
			//fetchAgentCounties()
			fetchAgentSecureId({ agentId: id })
			getAutoRefer({ agentId: id })
			clearCheckoutPlan()
			this.getSubscriptions()
			this.fetchSubscriptionPausedStatus(id);
			const { getAutoReferOutResponse } = this.props;
			if (getAutoReferOutResponse && getAutoReferOutResponse.data) {
				this.setState({ agentWebsite: getAutoReferOutResponse.data.agent_website, maxCall: getAutoReferOutResponse.data.max_call })
			}
			this.fetchSubscriptionPausedStatus(id);
		}
		catch (e) {
			console.log(e);
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		try {
			const { getAutoReferOutResponse } = nextProps;
			if (getAutoReferOutResponse && getAutoReferOutResponse.data) {
				this.setState({ agentWebsite: getAutoReferOutResponse.data.agent_website, maxCall: getAutoReferOutResponse.data.max_call })
			}
		}
		catch (e) {
			console.log(e);
		}
	}

	handleSMSTextChange = (e) => {
		this.setState({ currentText: e.target.value })
	}

	clearTempval = () => {
		this.setState({ currentText: '' })
	}

	setTempVal = (temp) => {
		this.setState({ currentText: temp.template })

	}

	handleSetAutoRefer = (e, currentVal) => {
		const {
			setAutoRefer,
		} = this.props

		const {
			id,
		} = this.props.location.state.agent

		setAutoRefer({
			agentId: id,
			autoReferVal: !currentVal,
			updatingAutoRefVal: true
		})

	}

	handleSetDoNotAutoSubscribeToAssist = (e, doNotAutoSubscribeToAssist) => {
		const {
			setAutoRefer,
		} = this.props

		const {
			id,
		} = this.props.location.state.agent

		setAutoRefer({
			agentId: id,
			doNotAutoSubscribeToAssist: !doNotAutoSubscribeToAssist,
			updatingDisableAutoAssistSubscription: true,
		})
	}

	handleAgentWebsite = (e) => {
		this.setState({ agentWebsite: e.target.value })
	}

	fetchAllCounties = async () => {

		try {
			let url = baseUrl + '/v2.0/counties';

			let resp = await fetch(url, {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'internal-id': token,
				},
			});
			resp = await resp.json();
			this.setState({ allCounties: resp.response })
		}
		catch (e) {
			console.log(e);
		}


	}

	fetchSelectedCounties = async (agentId) => {
		try {
			let url = baseUrl + `/v2.0/counties?agent_id=${agentId}`;

			let resp = await fetch(url, {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'internal-id': token,
				},
			});
			resp = await resp.json();

			let selectedCounties = resp.response;
			selectedCounties = selectedCounties.map(county => ({ label: county.name + ', ' + county.state, value: county.name + ', ' + county.state, OGR_FID: county.OGR_FID }));

			this.setState({ selectedCounties })
		}
		catch (e) {
			console.log(e);
		}


	}

	submitAgentWebsite = () => {
		const {
			setAutoRefer,
		} = this.props

		const {
			id,
		} = this.props.location.state.agent

		setAutoRefer({
			agentId: id,
			agentWebsite: this.state.agentWebsite ? this.state.agentWebsite : null,
			updatingWebsite: true,
		})

		setTimeout(() => {
			ShowSuccessToast("Agent's website updated successfully!")
		}, 2000);
	}

	handleMaxCall = (e) => {
		this.setState({ maxCall: e.target.value })
	}

	submitMaxCall = () => {
		const {
			setAutoRefer,
		} = this.props

		const {
			id,
		} = this.props.location.state.agent

		setAutoRefer({
			agentId: id,
			maxCall: this.state.maxCall && parseInt(this.state.maxCall) ? parseInt(this.state.maxCall) : null,
			updatingMaxCall: true,
		})

		setTimeout(() => {
			ShowSuccessToast("Maximum calls updated successfully!")
		}, 2000);
	}

	save = async (temp) => {
		if (this.state.currentText && this.state.currentText.length > 0) {
			try {
				let url = baseUrl + '/v1.0/notification-template-mappings/' + temp.template_id + '?agent_id=' + this.props.location.state.agent.id;
				temp.template = this.state.currentText;
				const body = JSON.stringify(temp);
				let resp = await fetch(url, {
					method: 'PUT',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'internal-id': token,
					},
					body: body
				});
				resp = await resp.json();
			}
			catch (e) {
				console.log(e)
			}
			this.loadAgentSpecificMapping(this.state.currentSource, this.props.location.state.agent)

		}
	}


	getTemplatesDiv = () => {
		if (this.state.templates.length > 0) {
			const indivdiualTemplatesDiv = this.state.templates.map((temp) => <React.Fragment><tr className="sms-row"><td> <Popup
				template={temp}
				trigger={
					<span className="edit-underline">
						<a>Edit</a>
					</span>
				}
				modal
				closeOnDocumentClick={false}
				closeOnEscape={false}
			>
				{close => (
					<div className="modal-2">
						<div className="modal-header">
							<span className="modal-header-text">
								Update Text
                        </span>
						</div>
						<div>
							<textarea className="admin-text-sms" onChange={this.handleSMSTextChange}>
								{temp.template}
							</textarea>

						</div>
						<div className="dialogue-box-footer">
							<button className="med-btn-1" onClick={() => { this.save(temp); close(); }}>
								SAVE
                        </button>
							<button className="med-btn-1" onClick={() => { this.clearTempval(); close(); }}>
								CANCEL
                        </button>
						</div>
					</div>

				)}
			</Popup></td><td>{temp.id}</td><td>{temp.title}</td><td>{temp.template}</td><td>{temp.day}</td><td>{temp.category}</td></tr></React.Fragment>)
			return <table>
				<tr>
					<th>
						Action
           </th>
					<th>
						Id
           </th>
					<th>
						Title
           </th>
					<th>
						Template
           </th>
					<th>
						Day
           </th>
					<th>
						Category
           </th>
				</tr>
				{indivdiualTemplatesDiv}
			</table>
		}
		return null;
	}

	componentWillUnmount() {
	}

	handlePlanSelect = (plan, agentSecureId, subscriptions, isActivePlan) => {
		if (isActivePlan) {
			alert("Plan already active!")
		} else {
			this.setState(
				{
					selectedPlan: plan,
					isPlanSelected: true,
				}
			)

			const { history } = this.props
			console.log("path", `agents/${this.props.location.state.agent.id}/checkout`)
			history.push({
				pathname: `${this.props.location.state.agent.id}/checkout`, state: {
					agent: this.props.location.state.agent,
					selectedPlan: plan,
					isPlanSelected: this.state.isPlanSelected,
					secureId: agentSecureId,
					subscriptions: subscriptions
				}
			})
		}
	}

	loadAgentTemplates = async (agent) => {
		try {
			let url = baseUrl + '/v1.0/notification-template-types?agent_id=' + agent.id;
			let resp = await
				fetch(url, {
					method: 'GET',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'internal-id': token,
					},
				});
			resp = await resp.json();
			console.log(resp.response);
			this.loadAgentSpecificMapping(resp.response[0], this.props.location.state.agent)

			this.setState({ sourcesDropdown: resp.response })

		}
		catch (e) {
			console.log(e)
		}
	}

	getSubscriptions = () => {
		const { fetchSubscriptions } = this.props
		console.log("in fetchSubscriptions")
		const { id } = this.props.location.state.agent
		fetchSubscriptions({ agentId: id })
	}

	handleTypeChange = (e, agent) => {
		const self = this;
		this.setState({ type: e.target.value }, () => {
			if (self.state.type === "Templates") {
				self.loadAgentTemplates(agent)
			}
		})
	}

	loadAgentSpecificMapping = async (source, agent) => {

		try {
			let url = baseUrl + '/v1.0/notification-template-mappings/' + source.id + '?agent_id=' + agent.id;
			let resp = await
				fetch(url, {
					method: 'GET',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'internal-id': token,
					},
				});
			resp = await resp.json();
			console.log(resp)
			console.log(resp.response);
			this.setState({ templates: resp.response.template_mappings })

		}
		catch (e) {
			console.log(e)
		}

	}

	handleDropDownChange = (e, agent) => {


		let source = JSON.parse(e.target.value)

		console.log(source.title);

		this.setState({ currentSource: source, });
		this.loadAgentSpecificMapping(source, agent)


	}

	handleDropdownClick = (source, agent) => {
		console.log(source);
		console.log(agent);
	}

	getSourcesDropdownDiv = (agent) => {

		const sourcesIndividualDiv = this.state.sourcesDropdown.map((source) => <option value={JSON.stringify(source)}>{source.title}</option>)

		return <select className="dropdown-agents-page" onChange={(e) => this.handleDropDownChange(e, agent)} selected={this.state.currentSource ? this.state.currentSource.title : ''}>
			{sourcesIndividualDiv}
		</select>
	}

	handlePlanTab = (bool, tabMode) => {
		const { setPlanForCheckout } = this.props;
		setPlanForCheckout({
			currentSelectedPlan: {},
		})
		this.setState({
			isSingleActive: bool,
			currentTabMode: tabMode,
		})
	}

	handleRenewalDate(date) {
		this.setState({
			renewalDate: date,
		});
	}

	handlePauseDuration = (e) => {
		this.setState({
			pauseDuration: e.target.value,
		});
	}

	submitRenewalDate = async (planId, activeRtlSubscriptionsInfo) => {
		// validate new renewal date
		if (!this.state.renewalDate || moment(this.state.renewalDate).isBefore()) {
			ShowErrorToastFromText("Select a future renewal date");
			return;
		}

		let momentObj = moment();
		if (activeRtlSubscriptionsInfo && activeRtlSubscriptionsInfo[planId] && activeRtlSubscriptionsInfo[planId].trial_end) {
			momentObj = moment(activeRtlSubscriptionsInfo[planId].trial_end * 1000);
		}
		const currentDate = momentObj.hours(0).minutes(0).seconds(0);
		const renewalDate = moment(this.state.renewalDate).seconds(5);
		if (renewalDate.diff(currentDate, 'days') < 1) {
			ShowErrorToastFromText("Already on a trial period on this date");
			return;
		}

		this.startLoader();
		const { id } = this.props.location.state.agent;
		let body = {
			agentId: id,
			planId: planId,
			days: renewalDate.diff(currentDate, 'days'),
		}
		console.log(body);
		try {
			let sol = await fetch(baseUrl + '/v2.0/stripe/extendTrial', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'internal-id': token,
				},
				body: JSON.stringify(body)
			});
			sol = await sol.json();
			if (sol && sol.response && sol.response.success) {
				ShowSuccessToast("Subscription Renewal Date is extended!");
			} else {
				ShowErrorToast(sol ? sol.response : null);
			}
			await this.getSubscriptions();
			this.stopLoader();
		} catch (e) {
			this.stopLoader();
			bugsnagClient.notify(e);
		}
	}

	submitPauseSubscription = async (planId) => {
		// validate pause duration
		if (!this.state.pauseDuration) {
			ShowErrorToastFromText("Select pause duration");
			return;
		}
		this.startLoader();
		const { id } = this.props.location.state.agent;
		let body = {
			agentId: id,
			planId: planId,
			days: this.state.pauseDuration === "1000" ? 1 : this.state.pauseDuration * 30,
		};
		console.log(body);
		try {
			let sol = await fetch(baseUrl + '/v1.0/pause-subscription', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'internal-id': token,
				},
				body: JSON.stringify(body)
			});
			sol = await sol.json();
			if (sol && sol.response && sol.response.success) {
				if (this.state.pauseDuration === "1000") {
					ShowSuccessToast(`Subscription has been paused for 1 day`);
				} else {
					let message = this.state.pauseDuration > 1 ? "months" : "month";
					ShowSuccessToast(`Subscription has been paused for ${this.state.pauseDuration} ${message}`);
				}
			} else {
				ShowErrorToast(sol ? sol.response : null);
			}
			await this.fetchSubscriptionPausedStatus(id);
			await this.getSubscriptions();
			this.stopLoader();
		} catch (e) {
			this.stopLoader();
			bugsnagClient.notify(e);
		}
	}

	fetchSubscriptionPausedStatus = async (agent_id) => {
		try {
			let sol = await fetch(baseUrl + '/v1.0/subscription-pause-status?agentId=' + agent_id, {
				method: 'GET',
				headers: {
					'internal-id': token,
				},
			});
			sol = await sol.json();
			if (sol && sol.response && sol.response) {
				this.setState({ isSubscriptionPaused: sol.response.is_subscription_paused })
			}
		} catch (e) {
			bugsnagClient.notify(e);
		}
	}

	saveCounties = async (agentId) => {
		this.setState({ savingCounties: true })
		try {


			let body = {
				OGR_FIDS: this.state.selectedCounties ? this.state.selectedCounties.map((county) => county.OGR_FID) : []
			}
			let sol = await fetch(baseUrl + '/v2.0/counties/' + agentId, {
				method: 'PUT',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'internal-id': token,
				},
				body: JSON.stringify(body)
			});
			sol = await sol.json();

			notify.show('Successfully updated counties for this agent', 'success', 2000);

			await this.fetchSelectedCounties(agentId)
			this.setState({ savingCounties: false })

		}
		catch (e) {
			console.log(e);
			notify.show('Could not update counties for this agent', 'error', 2000);
			bugsnagClient.notify(e);
			this.setState({ savingCounties: false })
		}
	}

	unpauseSubscription = async (planId) => {
		// validate subscription status
		if (!this.state.isSubscriptionPaused) {
			ShowErrorToastFromText("Subscription is not paused!");
			return;
		}
		this.startLoader();
		const { id } = this.props.location.state.agent;
		let body = {
			agentId: id,
			planId: planId,
		};
		console.log(body);
		try {
			let sol = await fetch(baseUrl + '/v1.0/unpause-subscription', {
				method: 'PUT',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'internal-id': token,
				},
				body: JSON.stringify(body)
			});
			sol = await sol.json();
			if (sol && sol.response && sol.response.success) {
				ShowSuccessToast(`Subscription has been resumed!`);
			} else {
				ShowErrorToast(sol ? sol.response : null);
			}
			await this.fetchSubscriptionPausedStatus(id);
			await this.getSubscriptions();
			this.stopLoader();
		} catch (e) {
			this.stopLoader();
			bugsnagClient.notify(e);
		}
	}

	migratePendingRtlLeads = async () => {
		try {
			this.startLoader();
			const { id } = this.props.location.state.agent;
			let sol = await fetch(baseUrl + '/v1.0/migrate-pending-rtl-leads?agent_id=' + id, {
				method: 'GET',
				headers: {
					'internal-id': token,
				},
			});
			sol = await sol.json();
			if (sol && sol.response && sol.response.success) {
				ShowSuccessToast("Leads processing is successful");
			} else if (sol && sol.error) {
				ShowErrorToast(sol)
			}
			this.stopLoader();
		} catch (e) {
			this.stopLoader();
			bugsnagClient.notify(e);
		}
	}

	startLoader = () => {
		this.setState({ loading: true })
	};

	stopLoader = () => {
		this.setState({ loading: false })
	};


	render() {

		const { agent } = this.props.location.state
		const { isPlanSelected, isSingleActive, currentTabMode } = this.state
		const { email, phone, firstname, lastname } = agent
		const { plans, subscriptions, secure_id, getAutoReferOutResponse, setAutoReferOutResponse, parentBenefitPlans } = this.props
		const { isFetching, data, isError, error } = plans || {}
		const { data: agentSecureData } = secure_id || {}
		const { secure_id: agentSecureId } = agentSecureData || {}
		const { isError: isSubError, error: subError, data: subscriptionsData } = subscriptions || {}
		const { team_plans, single_plans } = data || {}
		const activePlansIdsV2 = subscriptionsData && get(subscriptionsData, "subscriptions", []).map(sub => get(sub, "plan.id")) || []
		const activePlansIdsItems = subscriptionsData && get(subscriptionsData, "subscriptions", []).map(sub => get(sub, "items")) || []
		const activeSubscriptions = activePlansIdsItems && activePlansIdsItems.map(sub => get(sub, "data")) || []
		const sourcesDropdownDiv = this.state.sourcesDropdown && this.state.sourcesDropdown.length > 0 ? this.getSourcesDropdownDiv(agent) : null;
		const templatesDiv = this.getTemplatesDiv();
		let activePlansIds = [];
		let activeNonParentPlanIds = [];
		let allPlanIds = [];
		if(activeSubscriptions.length > 0) {
			activeSubscriptions.forEach(fetchActiveSubscriptionIds)
		} else {
			activePlansIds = activePlansIdsV2
			activeNonParentPlanIds = activePlansIdsV2
			allPlanIds = [...allPlanIds, ...activePlansIds]
		}
		
		activeSubscriptions.length > 0 ? activeSubscriptions.forEach(fetchActiveSubscriptionIds) : activePlansIds = activePlansIdsV2
		activeSubscriptions.length > 0 ? activeSubscriptions.forEach(fetchActiveSubscriptionIds) : activeNonParentPlanIds = activePlansIdsV2
		let subscribedToABrokerPlan = isSubscribedToABrokerPlan();
		let activeRealtimePlanId = getActiveRealtimePlanId();
		let activeRealtimePlanName = getActiveRealtimePlanName();
		let activeSubscriptionsInfo = activeRealtimePlanId && subscriptionsData ? getActiveRtlSubscriptionInfo(get(subscriptionsData, "subscriptions", [])) : [];
		const {
			data: getterAutoReferData,
			isFetching: gettingAutoReferResponse,
		} = getAutoReferOutResponse

		const {
			data: setterAutoReferData,
			isFetching: gettingSetAutoReferResponse,
			error: setAutoReferError,
		} = setAutoReferOutResponse || []


		const allCountiesDiv = this.state.allCounties && this.state.allCounties.length > 0 ? this.getCountiesDiv(this.state.allCounties) : null


		function isSubscribedToABrokerPlan() {
			let isSubscribed = false;
			if (single_plans && single_plans["broker"] && activeNonParentPlanIds) {
				single_plans["broker"].map((item) => {
					if (item && activeNonParentPlanIds.includes(item.plan_id)) {
						isSubscribed = true;
					}
				});
			}
			return isSubscribed;
		}
		function getActiveRealtimePlanId() {
			let activeRtlPlanId = null;
			if (activeNonParentPlanIds) {
				activeNonParentPlanIds.map((item) => {
					if (item && (item.includes("rtl") || item.includes("concierge")) && !item.includes("member") && (parentBenefitPlans && !parentBenefitPlans.includes(item) || !parentBenefitPlans)) {
						activeRtlPlanId = item;
					}
				})
			}
			return activeRtlPlanId;
		}

		function getPlansName(plans, activeRealtimePlanId) {
			let activeRtlPlanName;
			plans["rtl"].map((item) => {
				if (item && item.plan_id === activeRealtimePlanId) {
					activeRtlPlanName = item.name;
				}
			});
			return activeRtlPlanName
		}

		function getActiveRealtimePlanName() {
			let activeRtlPlanName;
			if (activeRealtimePlanId && single_plans && single_plans["rtl"]) {
				activeRtlPlanName = getPlansName(single_plans, activeRealtimePlanId)
			}
			if (!activeRtlPlanName && activeRealtimePlanId && team_plans && team_plans["rtl"]) {
				activeRtlPlanName = getPlansName(team_plans, activeRealtimePlanId)
			}
			return activeRtlPlanName || 'N/A';
		}

		function getActiveRtlSubscriptionInfo(subscriptions) {
			let mappings = [];
			mappings[activeRealtimePlanId] = {};
			if (subscriptions) {
				subscriptions.map((item) => {
					if (item) {
						if (item.plan && item.plan.id === activeRealtimePlanId) {
							mappings[activeRealtimePlanId].trial_end = item.trial_end;
							mappings[activeRealtimePlanId].current_period_end = item.current_period_end;
						} else if (item.items && item.items.data) {
							item.items.data.map((subItem) => {
								if (subItem && subItem.plan && subItem.plan.id === activeRealtimePlanId) {
									mappings[activeRealtimePlanId].trial_end = item.trial_end;
									mappings[activeRealtimePlanId].current_period_end = item.current_period_end;
								}
							})
						}
					}
				})
			}
			return mappings;
		}

		function fetchActiveSubscriptionIds(item) {
			const subscription = item && item.map(sub => get(sub, "plan.id")) || []
			const parent = item && item.map(sub => get(sub, "plan.parentPlanId")) || []
			subscription.forEach((item) => {
				if (!activePlansIds.includes(item)) {
					activePlansIds.push(item)
				}
				if (!activeNonParentPlanIds.includes(item)) {
					activeNonParentPlanIds.push(item)
				}
			})
			parent.forEach((item) => {
				if (!activePlansIds.includes(item)) {
					activePlansIds.push(item)
				}
			})
			allPlanIds = [...allPlanIds, ...activePlansIds, ...activeNonParentPlanIds]
		}
		return (
			<div>
				{isError && (
					<div>
						<h1>Failed to load agent details</h1>
						<code>{error.message}</code>
					</div>
				)}
				<TextWrap>

					<form>
						<span className="radio">
							<label>
								<input type="radio" value="Subscriptions" checked={this.state.type === 'Subscriptions'} onChange={(e) => this.handleTypeChange(e, agent)} />
                        Subscriptions
                        </label>
						</span>
                       &nbsp;
                       <span className="radio">
							<label>
								<input type="radio" value="Templates" checked={this.state.type === 'Templates'} onChange={(e) => this.handleTypeChange(e, agent)} />
                            Templates
                        </label>
						</span>
						<span className="radio">
							<label>
								<input type="radio" value="Agent Settings" checked={this.state.type === 'Agent Settings'} onChange={(e) => this.handleTypeChange(e, agent)} />
                                 Agent Settings
                            </label>
						</span>


					</form>

					<AgentDetails>
						<div style={{ "padding-right": "25px", "padding-bottom": "25px" }}>
							<h1>
								{`${firstname} ${lastname ? lastname : ""}`}
							</h1>
							{email &&
								<p>{email}</p>
							}
							{phone &&
								<p>{phone}</p>
							}

							{this.state.type !== "Agent Settings" && activeRealtimePlanId && <SubscriptionStatus isSubscriptionPaused={this.state.isSubscriptionPaused}>{this.state.isSubscriptionPaused ? "PAUSED" : "ACTIVE"}</SubscriptionStatus>}
							{this.state.type !== "Agent Settings" &&
								<Popup
									trigger={
										<div style={{ "padding-right": "25px", "padding-bottom": "7px", "padding-top": "25px" }}>
											<button className={subscribedToABrokerPlan ? "med-btn-7" : "med-btn-7-disabled"}
												disabled={!subscribedToABrokerPlan}>
												Brokerage Settings
                                        </button>
										</div>
									}
									disabled={!subscribedToABrokerPlan}
									modal
									closeOnDocumentClick={true}
									closeOnEscape={true}
								>
									{close => (
										<div>
											<div className="modal-header">
												<span className="modal-header-text">
													Brokerage Settings
                                            </span>
											</div>
											{gettingAutoReferResponse || gettingSetAutoReferResponse ? (<DefaultLoader />) :
												(<div className="edit-client-1">
													<div className="referral-text">
														<div style={{ "margin-left": "10px" }}>
															<input
																checked={getterAutoReferData && Boolean(getterAutoReferData.auto_rtl_subscription_status === 3 && getterAutoReferData.auto_blu_subscription_status === 3)}
																onChange={e => this.handleSetDoNotAutoSubscribeToAssist(e, Boolean(getterAutoReferData.auto_rtl_subscription_status === 3 && getterAutoReferData.auto_blu_subscription_status === 3))}
																type="checkbox"
																name={"doNoAutoSubscribeToAssist"}
															/>
															<label style={{ "font-size": "18px", "margin-left": "8px" }}>
																Do not auto subscribe to assist plans
                                                        </label>
														</div>
														{setAutoReferError && setAutoReferError.response && setAutoReferError.response.data &&
															setAutoReferError.response.data.error && setAutoReferError.response.data.error.code === 50005 &&
															<div style={{ "margin-left": "10px", "margin-top": "10px" }}>
																<p className="error-text">
																	Agent is not subscribed to a broker plan.
                                                        </p>
															</div>}
														{setAutoReferError && setAutoReferError.response && setAutoReferError.response.data &&
															setAutoReferError.response.data.error && setAutoReferError.response.data.error.code === 50006 &&
															<div style={{ "margin-left": "10px", "margin-top": "10px" }}>
																<p className="error-text">
																	{setAutoReferError.response.data.error.message ? setAutoReferError.response.data.error.message : "Something went wrong!"}
																</p>
															</div>}
														<div style={{ "margin-left": "10px", "margin-top": "20px" }} className="label-text">Agent Website:</div>
														<div>
															<input className="edit-client-input-1"
																onChange={this.handleAgentWebsite}
																value={this.state.agentWebsite ? this.state.agentWebsite : ""} />
														</div>

														{setAutoReferError && setAutoReferError.response && setAutoReferError.response.data &&
															setAutoReferError.response.data.error && setAutoReferError.response.data.error.code === 50004 &&
															<div style={{ "margin-left": "10px" }}>
																<p className="error-text">
																	Agent is not subscribed to a broker plan.
                                                        </p>
															</div>}
														<div style={{ "margin-top": "25px" }}>
															<button className="med-btn-1" onClick={() => {
																this.submitAgentWebsite();
																close();
															}}>
																Save
                                                        </button>
															<button className="med-btn-1" onClick={() => close()}>
																Cancel
                                                        </button>
														</div>
													</div>
												</div>)}
										</div>
									)}
								</Popup>
							}
						</div>

						{this.state.type !== "Agent Settings" ?
							<div className="autoRefer">
								{gettingSetAutoReferResponse || gettingAutoReferResponse ? (
									<DefaultLoader height={0} width={0} />

								) : (
									<React.Fragment>
										<p>ISA Settings</p>
										<input
											checked={getterAutoReferData && Boolean(getterAutoReferData.auto_refer_out_leads)}
											onChange={e => this.handleSetAutoRefer(e, Boolean(getterAutoReferData.auto_refer_out_leads))}
											type="checkbox"
											name={"autoReferOut"}
										/>
										<label>
											Auto Referout Leads
                            </label>
										{setAutoReferError && setAutoReferError.response && setAutoReferError.response.data &&
											setAutoReferError.response.data.error && setAutoReferError.response.data.error.code === 50003 && (
												<p className="error">
													Agent has not signed the referral agreement to enable auto refer out leads.
												</p>
											)}
										<p style={{
											fontSize: "12px",
											fontWeight: "100",
											marginTop: "10px",
										}}>
											Auto Referout Leads (Make sure the agent has signed the referral agreement before clicking on this button)
                            </p>
										<Popup
											trigger={
												<button className={activePlansIds && activePlansIds.length > 0 ? "med-btn-7" : "med-btn-7-disabled"}
													disabled={!(activePlansIds && activePlansIds.length > 0)}>
													Subscription Settings
                                        </button>
											}
											disabled={!(activePlansIds && activePlansIds.length > 0)}
											modal
											closeOnDocumentClick={true}
											closeOnEscape={true}
										>
											{close => (
												<div>
													<div className="modal-header">
														<span className="modal-header-text ">
															Subscription Settings
                                            </span>
													</div>
													{this.state.loading && <DefaultLoader />}
													{!this.state.loading && <div className="edit-client-1">
														{activeRealtimePlanId && <div>
															<div style={{ "margin-bottom": "10px", "font-size": "16px" }}>
																<b>Active Real Time Plan:</b> {activeRealtimePlanName} ({activeRealtimePlanId})
																{activeSubscriptionsInfo && activeSubscriptionsInfo[activeRealtimePlanId] &&
																	<div style={{ "margin-top": "5px" }}>
																		{activeSubscriptionsInfo[activeRealtimePlanId].current_period_end &&
																			<span><b>Next Billing Date:</b>{` ${moment(activeSubscriptionsInfo[activeRealtimePlanId].current_period_end * 1000).format("MMMM Do, YYYY")} `}</span>}
																	</div>}
															</div>
															<div style={{ "margin-bottom": "5px", "font-size": "16px" }}>
																Extend Subscription Renewal Date:
                                                </div>
															<div style={{ "margin-bottom": "30px", "width": "400px" }}>
																<Flex>
																	<Box width={1 / 2} my={2}>
																		<StyledDatePicker
																			selected={this.state.renewalDate}
																			onChange={this.handleRenewalDate.bind(this)}
																			placeholderText={"New Renewal Date"}
																		/>
																	</Box>
																	<Box width={1 / 2} my={2}>
																		<button className="med-btn-7" onClick={() => {
																			this.submitRenewalDate(activeRealtimePlanId, activeSubscriptionsInfo);
																		}}>Change Renewal Date
                                                            </button>
																	</Box>
																</Flex>
															</div>
															<div style={{ "padding-bottom": "5px", "font-size": "16px" }}>
																Pause Real-time Subscription:
                                                </div>
															<div style={{ "margin-bottom": "30px", "width": "400px" }}>
																<Flex>
																	<Box width={1 / 2} my={2}>
																		<select className="dropdown-2"
																			onChange={this.handlePauseDuration.bind(this)}
																			disabled={this.state.isSubscriptionPaused}>
																			<option value="" disabled selected>Pause Duration
                                                                </option>
																			<option value="1000">1 day</option>
																			<option value="1">1 month</option>
																			<option value="2">2 months</option>
																			<option value="3">3 months</option>
																			<option value="6">6 months</option>
																		</select>
																	</Box>
																	<Box width={1 / 2} my={2}>
																		<button
																			className={!this.state.isSubscriptionPaused ? "med-btn-7" : "med-btn-8"}
																			onClick={() => {
																				if (this.state.isSubscriptionPaused) {
																					this.unpauseSubscription(activeRealtimePlanId);
																				} else {
																					this.submitPauseSubscription(activeRealtimePlanId);
																				}
																			}}>{this.state.isSubscriptionPaused ? "Unpause Subscription" : "Pause Subscription"}
																		</button>
																	</Box>
																</Flex>
															</div>
															<div style={{ "padding-bottom": "5px", "font-size": "16px" }}>
																<span>Real time leads cap settings: </span>
																<button
																	className="med-btn-7"
																	onClick={() => {
																		if (!this.state.loading) {
																			this.migratePendingRtlLeads();
																		}
																	}}>Process Unsaved Leads
                                                    </button>
															</div>
														</div>}
														<div style={{ "padding-bottom": "5px", "margin-top": "10px", "font-size": "16px" }}>
															<div style={{ "padding-bottom": "5px" }} className="label-text">Maximum number of calls:</div>
															<div style={{ "margin-bottom": "30px", "width": "400px" }}>
																<input
																	style={{ "height": "27px" }}
																	type="number"
																	onChange={this.handleMaxCall}
																	value={this.state.maxCall ? this.state.maxCall : ""}
																/>
																<button className="med-btn-7" style={{ "margin-left": "10px" }}
																	onClick={() => {
																		this.submitMaxCall();
																		close();
																	}}>
																	Save Maximum Calls
                                                    </button>
															</div>
														</div>
													</div>}
												</div>

											)}
										</Popup>
									</React.Fragment>
								)}
							</div>
							: null}


					</AgentDetails>
					{this.state.type === "Subscriptions" && !isPlanSelected && (
						<React.Fragment>
							<Tabs>
								<Tab
									onClick={() => this.handlePlanTab(true, "single")}
									isActive={currentTabMode === "single"}
								>
									Single Plans
                       </Tab>
								<Tab
									onClick={() => this.handlePlanTab(false, "team")}
									isActive={currentTabMode === "team"}
								>
									Team Plans
                       </Tab>
								<Tab
									onClick={() => this.handlePlanTab(false, "broker")}
									isActive={currentTabMode === "broker"}
								>
									Broker
                       </Tab>
							</Tabs>
							{/* isSingleActive ? (
                     <div style={{
                       "display": "flex",
                     }}>
                       <Plans
                         type="single"
                         plan={single_plans}
                         subscriptions={subscriptions}
                         activePlansIds={activePlansIds}
                       />
                       <Checkout
                         type="single"
                         agentSecureId={agentSecureId}
                       />
                     </div>
                     ) : (
                      <div style={{
                        "display": "flex",
                      }}>
                       <Plans
                         type="team"
                         plan={team_plans}
                         subscriptions={subscriptions}
                         activePlansIds={activePlansIds}
                       />
                       <Checkout
                        type="team"
                        agentSecureId={agentSecureId}
                       />
                      </div>
                     )*/}
							{currentTabMode === "single" && (
								<div style={{
									"display": "flex",
								}}>
									<Plans
										type="single"
										plan={single_plans}
										subscriptions={subscriptions}
										activePlansIds={activePlansIds}
									/>
									<Checkout
										type="single"
										agentSecureId={agentSecureId}
									/>
								</div>
							)}

							{currentTabMode === "team" && (
								<div style={{
									"display": "flex",
								}}>
									<Plans
										type="team"
										plan={team_plans}
										subscriptions={subscriptions}
										activePlansIds={activePlansIds}
									/>
									<Checkout
										type="team"
										agentSecureId={agentSecureId}
									/>
								</div>
							)}
							{currentTabMode === "broker" && (
								<div style={{
									"display": "flex",
								}}>
									<Plans
										type="single"
										broker
										plan={single_plans}
										subscriptions={subscriptions}
										activePlansIds={activePlansIds}
									/>
									<Checkout
										type="single"
										agentSecureId={agentSecureId}
									/>
								</div>
							)}
						</React.Fragment>
					)}

					{/*this.state.type === "Subscriptions" && !isPlanSelected && <React.Fragment>
                       <PlanWrap>
                           <h3> Please select a plan:</h3>
                           <h4>Single Plans:</h4>
                           <div style={{ display: "flex" , flexWrap: "wrap"}}>
                               {single_plans && Object.keys(single_plans).map(key => (
                                   <React.Fragment>
                                       {single_plans[key].map(item => (
                                           <Plan
                                               onClick={() => this.handlePlanSelect(item, agentSecureId, subscriptions, activePlansIds.includes(item.plan_id))}
                                               isSelected={activePlansIds.includes(item.plan_id)}
                                           >
                                               {Boolean(item.is_legacy_plan) && (
                                                   <h6>Legacy Plan</h6>
                                               )}
                                               <h5>{item.name}</h5>
                                           </Plan>
                                       ))}
                                   </React.Fragment>

                               ))}
                           </div>
                       </PlanWrap>
                       {<PlanWrap>
                           <h3>Team Plans:</h3>
                           <div style={{ display: "flex", flexWrap: "wrap" }}>
                               {team_plans && Object.keys(team_plans).map(key => (
                                   <React.Fragment>
                                       {team_plans[key].map(item => (
                                           Boolean(!item.plan_id.includes("member")) && (


                                           <Plan onClick={() => this.handlePlanSelect(item, agentSecureId, subscriptions, activePlansIds.includes(item.plan_id))}
                                                 isSelected={activePlansIds.includes(item.plan_id)}
                                           >

                                               {Boolean(item.is_legacy_plan)&& (
                                                   <h6>Legacy Plan</h6>
                                               )}
                                               <h5>{item.name}</h5>

                                           </Plan>
                                       )))}
                                   </React.Fragment>

                               ))}
                           </div>
                       </PlanWrap>}

                   </React.Fragment>
                   */}
					{
						this.state.type === "Templates" && <div>
							{this.state.sourcesDropdown && this.state.sourcesDropdown.length > 0 && <React.Fragment>
								{sourcesDropdownDiv}
								<hr />
								{templatesDiv}
							</React.Fragment>}
						</div>
					}

					{

						this.state.type === "Agent Settings" && !this.state.savingCounties && this.state.allCounties.length > 0 ? <div className="counties-div">

							<React.Fragment>
								<span className="label-counties">
									Update Counties for RAR user here. Please type/scroll on the form below to find all the available counties.
                            </span>
								{allCountiesDiv}
							</React.Fragment>
							<button className="counties-button" onClick={() => this.saveCounties(this.props.location.state.agent.id)} disabled={this.state.savingCounties}>Save</button>
						</div>
							: <div>Loading...</div>
					}


				</TextWrap>
			</div>
		)
	}
}

export default container(Subscription);
