import React from "react"
import styled from "styled-components"
import Modal  from "react-modal"

const Wrap = styled.div`
  position: relative;
  z-index: 20;
`

class EmailModal extends React.PureComponent {
  state = {}

  componentDidMount() {
    // const {  } = this.props
  }

  handleInput = (e) => {
    const { value, name } = e.target
    this.setState({
      [name]: value,
    })
  }

  handleSendEmail = () => {
    const { emailAddress } = this.state
    
  }

  render() {
    const { emailAddress } = this.state
    const { isOpen, toggleEmailModal } = this.props
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={() => { toggleEmailModal(false) }}
      >
      <Wrap>
        <h2>Enter the email address you want to send email to</h2>
        <input type="text" value={emailAddress} name="emailAddress" onChange={this.handleInput} />
        <button>Send Email</button>
      </Wrap>
      </Modal>
    )
  }
}

export default EmailModal
