import React from "react"
import { format } from "date-fns"
import styled from "styled-components"
import icons from "library/icons/icons"
import colors from "library/colors"

const AddSearchWrap = styled.div`
  display: flex;
  color: ${colors.primaryColor};
  align-items: center;
  justify-content: center;
  font-weight: 300;
  transition-duration: 0.3s;
  font-size: 14px;
  cursor: pointer;
  ${props => props.isStandAlone && `
    padding: 15px 0px;
    border-bottom: 0.5pt solid ${colors.disabledColor};
    background: #fbfcfc;
  `}

  i {
    margin-right: 10px;
  }
`

const SectionWrap = styled.div`
  border-bottom: 0.5pt solid ${colors.disabledColor};
  border-top: 0.5pt solid ${colors.disabledColor};
  background: ${colors.greyBackground};
  color: ${colors.contentText};
  padding: 15px 30px;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;

  span.count {
    background: ${colors.tagInactive};
    display: flex;
    justify-content: center;
    border-radius: 50%;
    min-width: 16px;
    min-height: 16px;
    font-size: 12px;
    align-items: center;
    margin-left: 5px;
    color: ${colors.white};
  }
`

const EmptyStateWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
  color: #9c9c9c;

  i {
    font-size: 30px;
  }

  h3 {
    font-size: 18px;
  }
`

export const AddSection = ({ text, onClick, isStandAlone }) => (
  <AddSearchWrap onClick={onClick} isStandAlone={isStandAlone}>
    <i className={icons.genericPlus} />
    {text}
  </AddSearchWrap>
)

export const ErrorBox = ({ errorText }) => (
  <div>
    {errorText || "Oops! Something went wrong"}
  </div>
)

export const Section = ({
  title = "Title Here..",
  addText = "Add Button Text",
  count,
  addOnClick,
}) => (
  <SectionWrap>
    <div style={{ display: "flex" }}>
      {title}
      {!!count && (
        <span className="count">{count}</span>
      )}
    </div>
    {addText && (
      <AddSearchWrap onClick={addOnClick}>
        <i className={icons.genericPlus} />
        <span>{addText}</span>
      </AddSearchWrap>
    )}
  </SectionWrap>
)

export const EmptyState = ({ text, icon }) => (
  <EmptyStateWrap>
    <i className={icon}></i>
    <h3>{text}</h3>
  </EmptyStateWrap>
)

const ReminderDateWrap = styled.div``

export const ReminderDate = ({ date }) => (
  <ReminderDateWrap>
    <i className={icons.interfaceCalendar}></i>
    <p>{format(date, "ddd, MMM DD h:mm a")}</p>
  </ReminderDateWrap>
)
