import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as Actions from "./actions"
import {reset} from 'redux-form';

function mapStateToProps(state) {
    const { academy } = state
    return {
         globalState: state,
        ...academy,
    }
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
               fetchCourses: Actions.getCoursesAction.request,
               createCourse: Actions.createCourseAction.request,
               updateCourse: Actions.updateCourseAction.request,
               deleteCourse: Actions.deleteCourseAction.request,
               updateLesson: Actions.updateLessonDetailsAction.request,
               fetchEvents: Actions.getAllEventsAction.request,
               fetchMultipleInstructors: Actions.getMultipleInstructorsAction.request,
               createInstructor: Actions.createInstructorAction.request,
               addInstructorToCourse: Actions.addInstructorToCourseAction.call,
               addHostsToEvent: Actions.addHostsToEventAction.call,
               getCourseDetails: Actions.getCourseDetailsAction.request,
               setLessonType: Actions.setCurrentLessonTypeAction.call,
               setRTFValue: Actions.getCurrentRTFValueAction.call,
               createLesson: Actions.createLessonAction.request,
               saveSequenceState: Actions.currentCourseLessonArrangementAction.call,
               setCourseHeaderImage: Actions.setCurrentCourseHeaderImageAction.request,
               getInstructorDetail: Actions.getInstructorDetailsAction.request,
               updateInstructor: Actions.updateInstructorAction.request,
               updateProfileImage: Actions.setCurrentProfileImageAction.request,
               deleteInstructor: Actions.deleteInstructorAction.request,
               deleteLesson: Actions.deleteLessonAction.request,
               dateIndexUpdate: Actions.dateIndexUpdateAction.call,
               setCurrentEventType: Actions.currentEventTypeAction.call,
               createEvent: Actions.createEventAction.request,
               deleteEvent: Actions.deleteEventAction.request,
               updateEvent: Actions.updateEventAction.request,
               getEventDetails: Actions.getCurrentEventDetailsAction.request,
               clearEventAboutForm: () => {
                dispatch(reset('addEventsForm'))
                dispatch(reset('thirdPartyLinkForm'))
                dispatch(reset('addDayTimeForEventsForm'))
               },
               clearLessonAboutForm: () => { dispatch(reset('aboutLessonForm')) },
               clearInstructorForm: () => { dispatch(reset('addInstructor')) },
               clearCategoryForm: () => { dispatch(reset('addCategoryForm')) },
               clearFormsEdits: Actions.clearFormsAction.call,
               setEventHeaderImage: Actions.setCurrentEventHeaderImageAction.request,
               saveSettingsValues: Actions.saveSettingsValuesAction.call,
               accessToCourse: Actions.accessToCourseValueAction.call,
               setlastRTFState: Actions.previousRTFStateAction.call,
               getCurrentGlobalSetting: Actions.getGlobalEventNotificationAction.request,
               setCurrentGlobalSetting: Actions.setGlobalEventNotificationAction.request,
               getCategories: Actions.getCategoriesAction.request,
               getCategoryDetail: Actions.getCategoryDetailAction.request,
               updateCategoryDetail: Actions.updateCategoryDetailAction.request,
               createCategory:Actions.createCategoryAction.request,
               setCurrentEventFilter: Actions.eventFilterTypeAction.call,
               setCurrentCategoryType: Actions.courseCategorySelectionAction.call,
               getCourseListing: Actions.getCourseListingForCategoryAction.request,
               saveCourseState: Actions.currentCategoryCourseArrangementAction.call,
               batchUpdateCourse: Actions.batchUpdateForCoursesAction.request,
               setTest: Actions.setCurrentAsTestAction.call,
               setPrivate: Actions.setIsPrivateEventAction.call,
               enablePushNotification: Actions.setPushNotificationEnableAction.call,
               courseTopicSelection: Actions.courseTopicSelectionAction.call,
               updateIsPromotedState: Actions.updateIsPromotedStateAction.call,
        },
        dispatch
    ),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)
