import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "ACADEMY"

export const getCoursesAction = createSignalAction(BASE, "FETCH_COURSES")

export const updateCourseAction = createSignalAction(BASE, "UPDATE_COURSE")

export const getMultipleInstructorsAction = createSignalAction(BASE, "FETCH_MULTIPLE_INSTRUCTORS")

export const createInstructorAction = createSignalAction(BASE, "CREATE_INSTRUCTOR")

export const deleteInstructorAction = createSignalAction(BASE, "DELETE_INSTRUCTOR")

export const createCourseAction = createSignalAction(BASE, "CREATE_COURSE")

export const createLessonAction = createSignalAction(BASE, "CREATE_LESSON")

export const addInstructorToCourseAction = createSimpleCreator(BASE, "ADD_INSTRUCTOR_TO_COURSE")

export const addHostsToEventAction = createSimpleCreator(BASE, "ADD_HOSTS_TO_EVENTS")

export const getCourseDetailsAction = createSignalAction(BASE, "GET_COURSE_DETAILS")

export const deleteCourseAction = createSignalAction(BASE, "DELETE_COURSE")

export const setCurrentLessonTypeAction = createSimpleCreator(BASE, "CURRENT_LESSON_TYPE")

export const getCurrentRTFValueAction = createSimpleCreator(BASE, "CURRENT_RTF_VALUE")

export const getAllEventsAction = createSignalAction(BASE, "FETCH_EVENTS")

export const currentCourseLessonArrangementAction = createSimpleCreator(BASE, "CURRENT_LESSONS_ARRANGED")

export const setCurrentCourseHeaderImageAction = createSignalAction(BASE, "SET_COURSE_IMAGE_HEADER")

export const setCurrentProfileImageAction = createSignalAction(BASE, "SET_INSTRUCTOR_PROFILE_IMAGE")

export const getInstructorDetailsAction = createSignalAction(BASE, "FETCH_INSTRUCTOR_DETAILS")

export const updateInstructorAction = createSignalAction(BASE, "UPDATE_INSTRUCTOR")

export const updateLessonDetailsAction = createSignalAction(BASE, "UPDATE_LESSON")

export const deleteLessonAction = createSignalAction(BASE, "DELETE_LESSON")

export const dateIndexUpdateAction = createSimpleCreator(BASE, "SET_DATE_INDEX")

export const currentEventTypeAction = createSimpleCreator(BASE, "SET_CURRENT_EVENT_TYPE")

export const getCurrentEventDetailsAction = createSignalAction(BASE, "FETCH_EVENT_DETAILS")

export const createEventAction = createSignalAction(BASE, "CREATE_EVENT_ACTION")

export const updateEventAction = createSignalAction(BASE, "UPDATE_EVENT_ACTION")

export const deleteEventAction = createSignalAction(BASE, "DELETE_EVENT_ACTION")

export const clearFormsAction = createSimpleCreator(BASE, "CLEAR_FORMS")

export const batchUpdateLessonsAction = createSignalAction(BASE, "UPDATE_BATCH_LESSON")

export const setCurrentEventHeaderImageAction = createSignalAction(BASE, "SET_EVENT_IMAGE_HEADER")

export const saveSettingsValuesAction = createSimpleCreator(BASE, "SAVE_SETTINGS_VALUE")

export const accessToCourseValueAction = createSimpleCreator(BASE, "ACCESS_TO_SAVE")

export const previousRTFStateAction = createSimpleCreator(BASE, "LAST_RTF_STATE")

export const currentImageURLSetAction = createSimpleCreator(BASE, "CURRENT_IMAGE_URL")

export const getGlobalEventNotificationAction = createSignalAction(BASE, "GET_GLOBAL_EVENT_NOTIFICATION_SETTING")

export const setGlobalEventNotificationAction = createSignalAction(BASE, "SET_GLOBAL_EVENT_NOTIFICATION_SETTING")

export const getCategoriesAction = createSignalAction(BASE, "FETCH_ALL_CATEGORIES")

export const getCategoryDetailAction = createSignalAction(BASE, "FETCH_CATEGORY_DETAIL")

export const createCategoryAction = createSignalAction(BASE, "CREATE_CATEGORY")

export const updateCategoryDetailAction = createSignalAction(BASE, "UPDATE_CATEGORY_DETAIL")

export const eventFilterTypeAction = createSimpleCreator(BASE, "TOGGLE_EVENTS_FILTER")

export const courseCategorySelectionAction = createSimpleCreator(BASE, "HANDLE_CATEGORY_SELECTION")

export const getCourseListingForCategoryAction = createSignalAction(BASE, "FETCH_COURSE_FOR_CATEGORY")

export const currentCategoryCourseArrangementAction = createSimpleCreator(BASE, "CURRENT_COURSE_ARRANGED")

export const batchUpdateForCoursesAction = createSignalAction(BASE, "UPDATE_COURSE_BATCH")

export const setCurrentAsTestAction = createSimpleCreator(BASE, "SET_CURRENT_AS_TEST")

export const setIsPrivateEventAction = createSimpleCreator(BASE, "SET_CURRENT_PRIVATE_EVENT")

export const setPushNotificationEnableAction = createSimpleCreator(BASE, "SET_PUSH_NOTIFICATION_ENABLE")

export const courseTopicSelectionAction = createSimpleCreator(BASE, "CURRENT_TOPIC_SELECTED")

export const updateIsPromotedStateAction = createSimpleCreator(BASE, "TOGGLE_PROMOTED")
