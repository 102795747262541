export const getInterval = (pricing = {}) => {
  const {billing_cycle,  billing_interval } = pricing
  if (billing_cycle === "MONTHLY" && billing_interval === 1) {
    return " monthly"
  }
  if (billing_cycle === "MONTHLY" && billing_interval === 3) {
    return " quarterly"
  }
  if (billing_cycle === "MONTHLY" && billing_interval === 6) {
    return " half-yearly"
  }
  if (billing_cycle === "YEARLY" && billing_interval === 1) {
    return " yearly"
  }
  return ""
}