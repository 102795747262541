import React from "react"
import styled from "styled-components"
import { get } from "lodash"
import Modal from "dumbComponents/common/UI/Modal"
import Button from "dumbComponents/common/UI/Button"
import TextInput from "dumbComponents/BrokerDashboard/Comp/TextInput"
import GoogleLocation from "components/GoogleAutoComplete"
import AgentList from "./AgentList"
import {
  US_OBJ,
} from "../../BrokerDashboard/AgentDashboard/FieldData"

const Wrap = styled.div`
  width: 1000px;
`

const SearchWrap = styled.div`
  display: flex;
`

const SortFilterWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;

  > span {
    margin-right: 16px;
  }
  > select {
    padding: 8px;
  }
`

const NoResultWrap = styled.div`
  text-align: center;
  min-height: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
`

const ButtonWrap = styled.div`
  padding: 30px;
  display: flex;
  align-items: flex-end;
  > button {
    padding: 15px;
    width: 150px;
  }

`

const FilterWrap = styled.div`
  flex: 1;
`

const LocationWrap = styled.div`
  width: 400px;
  margin-right: 20px;
`

const BottomWrap = styled.div`
  display: flex;
`

class SearchModal extends React.PureComponent {
  state = {
    form: {},
  }

  componentDidMount() {
    // const {  } = this.props
  }

  handleInput = (event) => {
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        [event.target.name]: event.target.value,
      },
    })
  }

  handleSearch = () => {
    const { referralId, searchAgent } = this.props
    const { form, locationPayload } = this.state
    searchAgent({
      referralId,
      searchText: form.searchText,
      locationPayload,
    })
  }

  handleLocation = (location) => {
    const locationPayload = {
      city: get(location, "locality.long_name"),
      country: get(location, "country.long_name"),
      searchString: get(location, "address"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShort: get(location, "administrative_area_level_1.short_name"),
      type: "LOCATION",
    }
    this.setState({
      locationPayload,
    })
  }

  handleSorting = (item) => {
    const { referralId, sortResults } = this.props
    const { form, locationPayload } = this.state
    const payload = {
      referral_id: referralId,
      searchText: form.searchText,
      locationPayload,
      sortByEntity: item,
    }
    if (item) {
      payload.sortByEntity = item
    }
    sortResults({
      from: "search-results",
      payload,
    })
  }

  render() {
    const { form } = this.state
    const {
      searchAgentResponse,
      toClose,
      inviteAgent,
      toggleStarAgent,
      assignAgent,
      liveTransfer,
    } = this.props
    const { isFetching, data } = searchAgentResponse
    return (
      <Modal
        show
        toClose={toClose}
        width={1000}
      >
        <Wrap>
          <SearchWrap>
            <FilterWrap>
              <div>
                <TextInput
                  label="Agent"
                  name="searchText"
                  placeholder="Agent Search"
                  value={form.searchText}
                  onChange={this.handleInput}
                />
              </div>
              <BottomWrap>
                <LocationWrap>
                  <p>LOCATION</p>
                  <GoogleLocation
                    type="cities"
                    restrict={US_OBJ}
                    location={(data) => {
                      this.handleLocation(data)
                    }}
                  />
                </LocationWrap>
                <div style={{ paddingTop: 27, width: 400 }}>
                  <TextInput
                    label="Language"
                    placeholder="Language"
                  />
                </div>
              </BottomWrap>
            </FilterWrap>
            <ButtonWrap>
              <Button onClick={this.handleSearch} rounded>
                Search
              </Button>
            </ButtonWrap>
          </SearchWrap>
          <SortFilterWrap>
            <span>Sort By: </span>
            <select name="" id="" onChange={(event) => { this.handleSorting(event.target.value) }}>
              <option value="">None</option>
              <option value="closed_referral_percentage">Closed Percentage</option>
            </select>
          </SortFilterWrap>
          <AgentList
            agents={data && data.agents}
            isSeachModal
            isFetching={isFetching}
            inviteAgent={inviteAgent}
            toggleStarAgent={toggleStarAgent}
            assignAgent={assignAgent}
            liveTransfer={liveTransfer}
          />
        </Wrap>
      </Modal>
    )
  }
}

export default SearchModal
