/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react"
import styled from "styled-components"
import { ShimmerBox } from "shared/styles/animation"
import SubDeetsContainer from "container/Agent"
import AgentContainer from "container/BrokerDashboard/editAgentContainer"
import { get } from "lodash"
import {
  Container,
  PlanClickBar,
  PlanWrap,
  PlanPriceWrap,
  PlanPrice,
  Title,
  TitleWrap,
  ShimmerWrap,
} from "../../common"

const Wrap = styled.div``

const Split = ({
  getBrokerageInfoMetabaseResponse,
  selectAgentPlan,
  selectedAgentPlans,
  subscriptions
}) => {
  const isSplitAlready = get(subscriptions, "data.additional_info.split", "")
  const isFetching = get(getBrokerageInfoMetabaseResponse, "isFetching")
  const split = get(getBrokerageInfoMetabaseResponse, "data.split")

  useEffect(() => {
    if (isSplitAlready) {
      const payload = {
        key: "split",
        split: isSplitAlready,
      }
      selectAgentPlan(payload)
    }
  }, [isSplitAlready])

  const handleClick = (value) => {
    const payload = {
      key: "split",
      split: value.value,
    }
    selectAgentPlan(payload)
  }
  return (
    <Wrap>
      <Container>
        <TitleWrap>
          <Title>
            Split
          </Title>
        </TitleWrap>
        {isFetching ? (
          <ShimmerWrap>
            <ShimmerBox w="129px" h="102px" />
            <ShimmerBox w="129px" h="102px" />
          </ShimmerWrap>
        ) : (
          <PlanWrap>
            {split && split.map(plans => (
            <>
              <PlanClickBar
                onClick={() => {
                  handleClick(plans)
                }}
                isSelected={selectedAgentPlans.some(el => el.split === plans.value)}
                key={plans.id}
                width={129}
              >
                <PlanPriceWrap
                  isSelected={selectedAgentPlans.some(el => el.split === plans.value)}
                  key={plans.id}
                >
                  <PlanPrice
                    isSelected={selectedAgentPlans.some(el => el.split === plans.value)}
                    key={plans.id}
                  >
                    {plans.value}
                  </PlanPrice>
                </PlanPriceWrap>
              </PlanClickBar>
            </>
            ))}
          </PlanWrap>
        )}
      </Container>
    </Wrap>
  )
}

export default SubDeetsContainer(AgentContainer(Split))
