import React, { Component } from 'react';
import styled from "styled-components";

const Red = styled.li`
    background-color: red;
    color: red;
    width: 20px;
`;

function AgentColorMap() {
    return (
        <div>
            <ul>
                <Red role={"button"}/>partner
                <Red role={"button"}/>partner
            </ul>
        </div>
    );
}

export default AgentColorMap;