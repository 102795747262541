import { Button, Tag } from "antd"
import React from "react"
import { isArray } from "lodash"
import { format } from "date-fns"
import styled from "styled-components"
import container from "container/BrokerDashboard/resourcesContainer"

const Wrap = styled.div`
  header {

  }

  main {

  }

  a {
    overflow-wrap: anywhere;
  }
`


const ItemWrap = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
  gap:20px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  ${props => props.isHeader && `
    background: #E5E5E5;
  `}
`

const GeneralCell = styled.div`
  width: 25%;
`


const DetailsWrap = styled.div`
  
`

const ResourceList = ({
  data,
  deleteResource,
  history,
}) => (
  <Wrap>
   <ItemWrap isHeader>
      <GeneralCell>
        Resource Details
      </GeneralCell>
      <GeneralCell>
        Link Document
      </GeneralCell>
      <GeneralCell>
        Submitted on
      </GeneralCell>
      <GeneralCell>
        Resource Details
      </GeneralCell>
    </ItemWrap>
    <main>
      {data && isArray(data) && data.map(item => (
        <ItemWrap>
          <GeneralCell>
            <DetailsWrap>
              <h4>{item.heading_display_name}</h4>
              <h5>{item.doc_type_name}</h5>
              <h6>
                {item.description}
              </h6>
              <Tag>
                {item.state}
              </Tag>
            </DetailsWrap>
          </GeneralCell>
          <GeneralCell>
            <a href={item.download_url} download target="_blank">
              {item.download_url}
            </a>
          </GeneralCell>
          <GeneralCell>
            <p>
              {item.updated_at ? format(new Date(item.updated_at), "dd MMM, yyyy") : "N/a"}
            </p>
          </GeneralCell>
          <GeneralCell>
            <Button onClick={() => { window.open(`/broker/resources/edit/${item.id}`) }}>
              Edit
            </Button>
            <Button style={{margin: "10px"}} onClick={() => { if (window.confirm('Are you sure you wish to Delete this Resource?'))
                deleteResource({
                      payload: {
                        id: item.id,
                      },history
                    })
            }}>
              Delete
            </Button>
          </GeneralCell>
        </ItemWrap>
      ))}
    </main>
  </Wrap>
)

export default container(ResourceList)
