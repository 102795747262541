/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react"
import {
  Drawer,
  Input,
  Select,
  Button,
} from "antd"
import {
  isEmpty,
} from "lodash"
import { toast } from "react-toastify"
import styled from "styled-components"
// import Loader from "dumbComponents/common/UI/Loader"
import container from "container/BrokerDashboard/globalPagesContainer"

const Wrap = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
`
const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
const Title = styled.p`
  font-size: 19px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 35px;
`

const TextInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`
const InputBox = styled(Input)`
  width: 100px;
`

const Label = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 6px;
`
const ButtonDiv = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: flex-end;
`

const EscrowDrawer = (props) => {
  const {
    toggleEscrowDrawer,
    isEscrowDrawerOpen,
    isEditEscrow,
    getVendorDetailsResponse,
    allVendors,
    setAllVendors,
    agentId,
  } = props || {}

  const TC_STATE = [
    { label: "All States", value: "all-states" },
    { label: "California", value: "California" },
    { label: "Colorado", value: "Colorado" },
    { label: "Texas", value: "Texas" },
    { label: "Florida", value: "Florida" },
    { label: "Washington", value: "Washington" },
    { label: "Georgia", value: "Georgia" },
    { label: "Oregon", value: "Oregon" },
    { label: "Others", value: "others" },
  ]

  const [type, setType] = useState(null)
  const [name, setName] = useState("")
  const [number, setNumber] = useState("")
  const [officerFirstname, setOfficerFirstname] = useState("")
  const [officerLastname, setOfficerLastname] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState(null)
  const [zipcode, setZipcode] = useState("")
  const [notes, setNotes] = useState("")

  const handleClose = () => {
    toggleEscrowDrawer(false)
  }

  const { data: vendorDetails } = getVendorDetailsResponse
  let vendorChosen

  useEffect(() => {
    if (isEditEscrow === null) {
      setType(null)
      setName("")
      setNumber("")
      setOfficerFirstname("")
      setOfficerLastname("")
      setEmail("")
      setPhone("")
      setAddress("")
      setCity("")
      setState(null)
      setZipcode("")
      setNotes("")
    }
    if (isEditEscrow !== null) {
      vendorChosen = vendorDetails && vendorDetails.vendors && vendorDetails.vendors.filter(x => x.id === isEditEscrow)

      if (vendorChosen) {
        setType((vendorChosen[0].vendor_type))
        setName(vendorChosen[0].vendor_name)
        setNumber(vendorChosen[0].vendor_number)
        setOfficerFirstname(vendorChosen[0].vendor_officer_first_name)
        setOfficerLastname(vendorChosen[0].vendor_officer_last_name)
        setEmail(vendorChosen[0].vendor_email)
        setPhone(vendorChosen[0].vendor_phone)
        setAddress(vendorChosen[0].vendor_address)
        setCity(vendorChosen[0].vendor_city)
        setState(vendorChosen[0].vendor_state)
        setZipcode(vendorChosen[0].vendor_zipcode)
        setNotes(vendorChosen[0].notes)
      }
    }
  }, [isEditEscrow])

  const formKeys = []

  const handleSave = () => {
    let count = allVendors && allVendors.length
    if (count === null) {
      count = 0
    }

    const ignoredFields = [
      "notes",
    ]

    if (count === 0 || isEditEscrow === null) {
      allVendors.push({
        agent_id: parseInt(agentId, 10) + 1 - 1,
        vendor_type: type,
        vendor_name: name,
        vendor_number: parseInt(number, 10) + 1 - 1,
        vendor_officer_first_name: officerFirstname,
        vendor_officer_last_name: officerLastname,
        vendor_email: email,
        vendor_phone: parseInt(phone, 10) + 1 - 1,
        vendor_address: address,
        vendor_city: city,
        vendor_state: state,
        vendor_zipcode: parseInt(zipcode, 10) + 1 - 1,
        current_listing_step: "insert_vendor",
        notes,
        draft: "N",
      })

      if (count === 1) {
        Object.keys(allVendors[0]).map((key) => {
          if (ignoredFields.indexOf(key) === -1) {
            if (allVendors[0][key] === "") {
              formKeys.push(key)
              return true
            } if (allVendors[0][key] === null) {
              formKeys.push(key)
              return true
            }
          }
        })
      } else if (count > 1) {
        console.log("came here", count, allVendors[count])
        Object.keys(allVendors[count - 1]).map((key) => {
          if (ignoredFields.indexOf(key) === -1) {
            if (allVendors[count][key] === "") {
              console.log("came here inside if", allVendors[count][key])
              formKeys.push(key)
              return true
            } if (allVendors[count][key] === null) {
              formKeys.push(key)
              return true
            }
          }
        })
      }

      if (!isEmpty(formKeys)) {
        toast.error(
          `Please fill the missing fields \n
            ${formKeys.map(x => `${x}`).join(", ")}
          `, {
            position: "top-center",
            hideProgressBar: true,
          }
        )
      } else {
        setType(null)
        setName("")
        setNumber("")
        setOfficerFirstname("")
        setOfficerLastname("")
        setEmail("")
        setPhone("")
        setAddress("")
        setCity("")
        setState(null)
        setZipcode("")
        setNotes("")
        toggleEscrowDrawer(false)
      }
    } else if (count !== 0 && isEditEscrow) {
      const index = allVendors.findIndex(x => x.id === isEditEscrow)
      allVendors[index].vendor_type = type
      allVendors[index].vendor_name = name
      allVendors[index].vendor_number = number
      allVendors[index].vendor_officer_first_name = officerFirstname
      allVendors[index].vendor_officer_last_name = officerLastname
      allVendors[index].vendor_email = email
      allVendors[index].vendor_phone = phone
      allVendors[index].vendor_address = address
      allVendors[index].vendor_city = city
      allVendors[index].vendor_state = state
      allVendors[index].vendor_zipcode = zipcode
      allVendors[index].draft = "N"
      allVendors[index].notes = notes

      Object.keys(allVendors[index]).map((key) => {
        if (ignoredFields.indexOf(key) === -1) {
          if (allVendors[index][key] === "") {
            formKeys.push(key)
            return true
          } if (allVendors[index][key] === null) {
            formKeys.push(key)
            return true
          }
        }
      })
      if (!isEmpty(formKeys)) {
        toast.error(
          `Please fill missing fields \n
            ${formKeys.map(x => `${x}`).join(", ")}
          `, {
            position: "top-center",
            hideProgressBar: true,
          }
        )
      } else {
        setType(null)
        setName("")
        setNumber("")
        setOfficerFirstname("")
        setOfficerLastname("")
        setEmail("")
        setPhone("")
        setAddress("")
        setCity("")
        setState(null)
        setZipcode("")
        setNotes("")
        toggleEscrowDrawer(false)
      }
    }
  }

  return (
    <Drawer
      title="Escrow Drawer"
      width={700}
      placement="left"
      onClose={handleClose}
      visible={isEscrowDrawerOpen}
    >
      <Wrap>
        <Title>Escrow and Title Information</Title>
        <TextInput>
          <Label>
                    Vendor Type
          </Label>
          <Select
            placeholder="Title and Escrow"
            value={type}
            options={[
              { label: "Title", value: "title" },
              { label: "Escrow", value: "escrow" },
              { label: "Title and Escrow", value: "title_and_escrow" },
            ]}
            onChange={(value) => {
              setType(value)
            }}
          />
        </TextInput>
        <Row>
          <TextInput>
            <Label>Escrow Company Name</Label>
            <Input
              required
              placeholder="Company Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </TextInput>
          {/* <TextInput>
            <Label>Escrow Number</Label>
            <Input
              required
              placeholder="Escrow Number"
              value={number}
              onChange={(e) => {
                setNumber(e.target.value)
              }}
            />
          </TextInput> */}
        </Row>
        <Row>
          <TextInput>
            <Label>Escrow Officer Firstname</Label>
            <Input
              required
              placeholder="Firstname"
              value={officerFirstname}
              onChange={(e) => {
                setOfficerFirstname(e.target.value)
              }}
            />
          </TextInput>
          <TextInput>
            <Label>Escrow Officer Lastname</Label>
            <Input
              required
              placeholder="Lastname"
              value={officerLastname}
              onChange={(e) => {
                setOfficerLastname(e.target.value)
              }}
            />
          </TextInput>
        </Row>
        <Row>
          <TextInput>
            <Label>Email</Label>
            <Input
              required
              placeholder="Email"
              value={email}
              onChange={(e) => {
                console.log("formKeys email", email)
                setEmail(e.target.value)
              }}
            />
          </TextInput>
          <TextInput>
            <Label>Phone Number</Label>
            <Input
              type="number"
              required
              placeholder="Phone"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value)
              }}
            />
          </TextInput>
        </Row>
        <TextInput>
          <Label>Company Address</Label>
          <InputBox
            style={{ width: "100%" }}
            placeholder="Company Address"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value)
            }}
          />
        </TextInput>
        <Row>
          <TextInput>
            <Label>City</Label>
            <Input
              required
              placeholder="City"
              value={city}
              onChange={(e) => {
                setCity(e.target.value)
              }}
            />
          </TextInput>
          <TextInput>
            <Label>Zipcode</Label>
            <Input
              type="number"
              required
              placeholder="Zipcode"
              value={zipcode}
              onChange={(e) => {
                setZipcode(e.target.value)
              }}
            />
          </TextInput>
        </Row>
        <TextInput>
          <Label> State </Label>
          <Select
            placeholder="State"
            options={TC_STATE}
            value={state}
            onChange={(value) => {
              setState(value)
            }}
          />
        </TextInput>
        <TextInput>
          <Label>
                  Notes (Optional)
          </Label>
          <Input
            placeholder="Notes"
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value)
            }}
          />
        </TextInput>
        <ButtonDiv>
          <Button
            type="primary"
            onClick={() => {
              handleSave()
            }}
            style={{
              width: "70px",
              borderRadius: "100px",
              alignSelf: "center",
              boxShadow: "0px 1px 11px rgba(0, 0, 0, 0.05)",
            }}
          >
                  Save
          </Button>
        </ButtonDiv>
      </Wrap>
    </Drawer>
  )
}
export default container(EscrowDrawer)
