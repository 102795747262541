const BUYER_AGENT_INFO_ROUTE = "/broker/tc/add-offers/coversheet/buyer-agent-info"
const BUYER_INFO_ROUTE = "/broker/tc/add-offers/coversheet/buyer-info"
const OFFER_INFO_ROUTE = "/broker/tc/add-offers/coversheet/offer-info"
const LISTING_AGENT_INFO_ROUTE = "/broker/tc/add-offers/coversheet/listing-agent-info"
const SELLER_INFO_ROUTE = "/broker/tc/add-offers/coversheet/seller-info"
const ESCROW_INFO_ROUTE = "/broker/tc/add-offers/coversheet/escrow-info"
const TITLE_INFO_ROUTE = "/broker/tc/add-offers/coversheet/title-info"
const VENDER_SELECT_ROUTE = "/broker/tc/add-offers/coversheet/vendor-select"
const NEW_VENDOR_ROUTE = "/broker/tc/add-offers/coversheet/new-vendor"
const UPDATE_VENDOR_ROUTE = "/broker/tc/add-offers/coversheet/update-vendor"
const UPDATE_LENDER_ROUTE = "/broker/tc/add-offers/coversheet/update-lender"
const LENDER_SELECT_ROUTE = "/broker/tc/add-offers/coversheet/lender-select"
const LENDER_INFO_ROUTE = "/broker/tc/add-offers/coversheet/lender-info"
const DOCUMENTS_INFO_ROUTE = "/broker/tc/add-offers/coversheet/documents-info"
const OFFER_WRITING_ROUTE = "/broker/tc/add-offers/coversheet/offer-writing-info"
// const GOOGLE_CONTACTS_INTEGRATION_ROUTE = "/realty/offers/google-contact-integration"
const PAGE_ARRAY = [1, 2, 3, 4, 5, 6, 7, 8]

//steps
const BUYER_AGENT_INFO_STEP = "buyer_agent_info"
const BUYER_INFO_STEP = "buyer_info"
const OFFER_INFO_STEP = "offer_info"
const LISTING_AGENT_INFO_STEP = "listing_agent_info"
const SELLER_INFO_STEP = "seller_info"
const VENDOR_INFO_STEP = "vendor_info"
const VENDOR_LIST_STEP = "select_vendor"
const NEW_VENDOR_STEP = "insert_vendor"
const OFFER_WRITING_INFO_STEP = "writing_opt_in"
const UPDATE_VENDOR_STEP = "update_vendor"
const LENDER_LIST_STEP = "select_lender"
const NEW_LENDER_STEP = "insert_lender"
const UPDATE_LENDER_STEP = "update_lender"
const LENDER_INFO_STEP = "lender_info"
const DOCUMENTS_INFO_STEP = "documents_info"
const BUYERS_INFORMATION ="buyers_information_mobile"

//Offer Statuses
const OFFER_STATUSES = [
  {
    value: "incomplete",
    label: "Incomplete",
  },
  {
    value: "new_offer",
    label: "New Offer",
  },
  {
    value: "pending_signatures",
    label: "Pending Signatures",
  },
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "offer_accepted",
    label: "Offer Accepted",
  },
  {
    value: "rejected",
    label: "Rejected",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
]

//Color Map
const ColorMap = [
  {
    value: "#B3E4C7",
    label: "active",
  },
  {
    value: "#D2D8D8",
    label: "incomplete",
  },
  {
    value: "#FAEBA6",
    label: "pending",
  },
  {
    value: "#D9BDE3",
    label: "ratified",
  },
  {
    value: "#F0C3A2",
    label: "new_offer",
  },
  {
    value: "#D2D8D8",
    label: "rejected",
  },
  {
    value: "#EBBAB5",
    label: "cancelled",
  },
]

export {
  /*ROUTES*/
  BUYER_AGENT_INFO_ROUTE as BuyerAgentInfoRoute,
  BUYER_INFO_ROUTE as BuyerInfoRoute,
  OFFER_INFO_ROUTE as OfferInfoRoute,
  LISTING_AGENT_INFO_ROUTE as ListingAgentInfoRoute,
  SELLER_INFO_ROUTE as SellerInfoRoute,
  ESCROW_INFO_ROUTE as EscrowInfoRoute,
  TITLE_INFO_ROUTE as TitleInfoRoute,
  LENDER_INFO_ROUTE as LenderInfoRoute,
  VENDER_SELECT_ROUTE as VendorSelectRoute,
  UPDATE_VENDOR_ROUTE as UpdateVendorRoute,
  UPDATE_LENDER_ROUTE as UpdateLenderRoute,
  NEW_VENDOR_ROUTE as CreateVendorRoute,
  LENDER_SELECT_ROUTE as LenderSelectRoute,
  OFFER_WRITING_ROUTE as OfferWritingRoute,
  DOCUMENTS_INFO_ROUTE as DocumentsInfoRoute,
  // GOOGLE_CONTACTS_INTEGRATION_ROUTE as GoogleContactIntegrationRoute,

  /*PROPERTIES*/
  PAGE_ARRAY as PageArray,
  BUYER_AGENT_INFO_STEP as BuyerAgentInfoStep,
  BUYER_INFO_STEP as BuyerInfoStep,
  BUYERS_INFORMATION as BuyersInformation,
  OFFER_INFO_STEP as OfferInfoStep,
  LISTING_AGENT_INFO_STEP as ListingAgentInfoStep,
  SELLER_INFO_STEP as SellerInfoStep,
  OFFER_STATUSES as OfferStatuses,
  VENDOR_LIST_STEP as VendorSelectStep,
  VENDOR_INFO_STEP as VendorInfoStep,
  NEW_VENDOR_STEP as CreateVendorStep,
  UPDATE_VENDOR_STEP as UpdateVendorStep,
  LENDER_LIST_STEP as LenderSelectStep,
  NEW_LENDER_STEP as CreateLenderStep,
  UPDATE_LENDER_STEP as UpdateLenderStep,
  LENDER_INFO_STEP as LenderInfoStep,
  OFFER_WRITING_INFO_STEP as OfferWritingOptInStep,
  DOCUMENTS_INFO_STEP as DocumentsInfoStep,
  ColorMap,
}
