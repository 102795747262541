import React, { Component } from "react"
import { LoadScript } from "services/Utils"
import { Input } from "antd"

export default class GoogleLocation extends Component {

  loadScript() {
    if (typeof window.google === "undefined") {
      LoadScript(
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyDxe0pqmDuegFXpqdbrugXjW4sM7xEttzU&libraries=places`,
        () => {
          this.googleAutoCompleteInitializer()
        }
      )
    } else {
      this.googleAutoCompleteInitializer()
    }
  }

  googleAutoCompleteInitializer() {
    const { types, restrict } = this.props
    const options = {
      types,
    }
    const geoComponents = new window.google.maps.places.Autocomplete(this.inputText, options)
    if (restrict) {
      const cityBounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(restrict.south_west.lat, restrict.south_west.lng),
        new window.google.maps.LatLng(restrict.north_east.lat, restrict.north_east.lng)
      )
      options.strictBounds = true
      options.bounds = cityBounds
      options.componentRestrictions = {}
      options.componentRestrictions.country = restrict.country.short_name.toLowerCase()
      geoComponents.setOptions(options)
    }
    window.google.maps.event.addDomListener(this.inputText, "keydown", (e) => {
      if (e.keyCode === 13) {
        e.preventDefault()
      }
    })

    window.google.maps.event.addListener(geoComponents, "place_changed", () => {
      const place = geoComponents.getPlace()
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        south_west: {
          lat: place.geometry.viewport.getSouthWest().lat(),
          lng: place.geometry.viewport.getSouthWest().lng(),
        },
        north_east: {
          lat: place.geometry.viewport.getNorthEast().lat(),
          lng: place.geometry.viewport.getNorthEast().lng(),
        },
        address: place.formatted_address,
      }
      place.address_components.map(component => (location[component.types[0]] = component))
      if (this.props.clearInput) {
        this.inputText.value = ""
        this.inputText.focus()
      }
      this.props.location(location)
    })
  }

  componentDidMount() {
    this.loadScript()
    // this.inputElement.focus();
  }

  referenceHandler = (ref) => {
    // console.log("REFERENCE", ref)
    this.inputText = ref?.input
  }

  render() {
    const { placeholder, formValue, value, onChange } = this.props
    // console.log("==value, onChange==", value, onChange)
    return (
      <Input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        ref={this.referenceHandler}
      />
    )
  }
}
