import React, { PureComponent } from "react"
import styled from "styled-components"
import TechAssetsContainer from "container/TechAssets"
import { get } from "lodash"
import root, { setTimeout } from "window-or-global"
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
import {
  Input, Select, Form,
  Divider, Button,
  AutoComplete,
} from "dumbComponents/common/UI/antd"
// import US_STATES from "shared/US_STATES.json"
import colors from "../../../../lib/colors"
import {
  PageArray,
  ListingAgentInfoRoute,
  SellerInfoStep,
  OfferInfoRoute,
  OfferWritingRoute,
  DocumentsInfoRoute,
} from "../../consts"
import {
  fetchDetails,
  getDealId,
  SubmitForm,
  normalizePhone,
  getAgentId,
} from "../../commonUtils"
import {
  RemoveCoListingAgentButton,
} from "../../commonStyles"
import Sidebar from "../Sidebar"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const HeaderNav = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const ProgressBarContainer = styled.div`
  padding: 30px 0px;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #3B60E4;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LabelText = styled.h2`
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`

const Required = styled.span`
  color: #E34C4C
`

const CheckboxLabelText = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  color: ${colors.black};
  text-transform: uppercase;
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const CheckboxContainer = styled.div`
  padding: 0 20px 0 0;
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
`

const AddForm = styled.div`
  padding: 0 0 34px 0;
  text-align: right;
`

const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    margin: 0px;
  }
`

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

// const STATE_OPTIONS = Object.keys(US_STATES).map(key => ({ label: US_STATES[key], value: US_STATES[key] }))

class CoverSheet extends PureComponent {
  state = {
    showCoListingform: [1],
    cityFormValue: {},
    typeOfOwnership: { 0 : null },
  }

  formRef = React.createRef();

  componentDidMount() {
    const { getDealDetails, getCurrentDealDetailsResponse } = this.props

    fetchDetails(getDealDetails, SellerInfoStep)
    const { typeOfOwnership } = this.state
    const {
      data: sellerInfo,
    } = getCurrentDealDetailsResponse || {}
    const result = {
      "type_of_ownership-0": null,
    }

    if (sellerInfo && sellerInfo.sellers) {
      sellerInfo.sellers.forEach((seller, index) => {
        Object.keys(seller).forEach((key) => {
          const curr = `${key}-${index}`
          if (typeOfOwnership[index] && key === "type_of_ownership" && seller[key]) {
            result[curr] = seller[key] || typeOfOwnership[index]
            typeOfOwnership[index] = result[curr]
            this.setState({
              typeOfOwnership: { ...typeOfOwnership },
            })
          }
        })
      })
    }
  }

  onFinish = (fieldValues, isDraft) => {
    const {
      history,
      updateOffer,
      createOffer,
    } = this.props

    const currentStep = SellerInfoStep
    const agentId = getAgentId() || false

    SubmitForm(
      fieldValues,
      isDraft,
      createOffer,
      updateOffer,
      history,
      ListingAgentInfoRoute,
      currentStep,
      "clients",
      false,
      false,
      agentId,
      false,
    )
  }

  onSaveAsDraft = (values) => {
    console.log("===this.formRef.current.getFieldsValue===", this.formRef.current.getFieldsValue())
    this.onFinish(this.formRef.current.getFieldsValue(), true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  addAdditionalSeller = () => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform
    colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
    setTimeout(() => {
      root.scrollTo(0, 3100)
    }, 300)
  }

  setCities = (location, index) => {
    const { cityFormValue } = this.state
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    this.formRef.current.setFieldsValue({
      [`city-${index}`]: `${locationDetails.city || ""}`,
      [`state-${index}`]: `${locationDetails.state || ""}`,
      [`country-${index}`]: `${locationDetails.country || ""}`,
    })
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [`city-${index}`]: `${locationDetails.city}`,
      },
    })
  }

  setLocaCity = (value, cityFormName) => {
    const { cityFormValue } = this.state
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [cityFormName]: value,
      },
    })
  }

  getInitialValues = () => {
    const { getCurrentDealDetailsResponse } = this.props
    const {
      data: sellerInfo,
    } = getCurrentDealDetailsResponse || {}
    const { cityFormValue, showCoListingform, typeOfOwnership, formsInitialized } = this.state
    const localShowCoListingform = showCoListingform
    const result = {
      "type_of_ownership-0": null,
    }
    if (sellerInfo && sellerInfo.sellers) {
      sellerInfo.sellers.forEach((seller, index) => {
        Object.keys(seller).forEach((key) => {
          //result[`ownership_name-${index}`] = ""
          const curr = `${key}-${index}`
          result[curr] = seller[key]
          if (key === "city") {
            // need to check this logic
            cityFormValue[`${key}-${index}`] = seller[key]
          }

          if (typeOfOwnership[index] && key === "type_of_ownership" && seller[key]) {
            result[curr] = seller[key] || typeOfOwnership[index]
          } else if (!typeOfOwnership[index] && key === "type_of_ownership" && seller[key]) {
            result[curr] = seller[key].toLowerCase()
            typeOfOwnership[index] = result[curr]
            this.setState({
              typeOfOwnership: { ...typeOfOwnership },
            })
          }
        })
        // need to check this logic
        // if (index > 0 && !localShowCoListingform.includes(index + 1)) localShowCoListingform.push(index + 1)
        if (index > 0 && !formsInitialized) {
          localShowCoListingform.push(true)
          this.setState({
            formsInitialized: true,
            showCoListingform: [...localShowCoListingform],
          })
        }
      })
    }
    return result
  }

  getOptions = () => {
    const { agentGoogleContacts } = this.props
    return agentGoogleContacts && agentGoogleContacts.length ? agentGoogleContacts.filter(ct => ct.names).map((ct, index) => ({ label: ct.names[0].displayName, value: `${ct.names[0].displayName}`, key: index })) : []
  }

  changeOtherFieldsValues = (objString, index, option) => {
    const { agentGoogleContacts } = this.props
    const name = objString
    const nameArray = name.split(" ")
    const contactIndex = option.key
    const phoneNumber = agentGoogleContacts[contactIndex]?.phoneNumbers?.[0].value
    const emailAddresses = agentGoogleContacts[contactIndex]?.emailAddresses?.[0].value
    this.formRef.current.setFieldsValue({
      [`firstname-${index}`]: nameArray[0],
      [`lastname-${index}`]: nameArray[1] || "",
      [`email-${index}`]: emailAddresses,
      [`phone-${index}`]: phoneNumber,
    })
  }

    setTypeOfOwnership = (value, index) => {
      const { typeOfOwnership } = this.state
      typeOfOwnership[index] = value
      this.setState({
        typeOfOwnership: { ...typeOfOwnership },
      })
    }

  removeColistingAgent = (index) => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform.filter((sc, ind) => ind !== index)
    // colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
  }

  getFieldName = (typeOfOwnership) => {
    if (typeOfOwnership === "trust") {
      return "Full Name of Trust"
    }
    if (typeOfOwnership === "successor_trust") {
      return "Full Name of Trust"
    }
    if (typeOfOwnership === "entity") {
      return "Full Name of Entity"
    }
    if (typeOfOwnership === "probate") {
      return "Full Name of Executer"
    }
    if (typeOfOwnership === "estate") {
      return "Full Name of Estate"
    }
    if (typeOfOwnership === "power_of_attorney") {
      return "Fulll Name of Attorney-in-fact"
    }
    return "Full Name"
  }

  render() {
    const { showCoListingform, typeOfOwnership } = this.state
    const {
      history,
      getCurrentDealDetailsResponse,
      updateOfferActionResponse,
      offerInfoDetails,
      setLoader,
    } = this.props
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]

    const {
      isFetching: fetchingDeal,
    } = getCurrentDealDetailsResponse || {}

    const {
      isFetching: updatingDeal,
    } = updateOfferActionResponse || {}

    const enableTC = ["California", "Oregon", "Washington"]
    const state = offerInfoDetails && offerInfoDetails.state
    const enableDocs = ["California", "Oregon", "Washington", "Georgia", "Texas", "Florida", "Colorado"]

    const isLoading = fetchingDeal || updatingDeal
    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Seller Information
        </HeaderNav>
        <ProgressBarComponent total={PageArray} ongoingOrCompleted={4} />
        <Container>
          <LeftSideBar>
            <Sidebar />
          </LeftSideBar>
          <RightSideBar>
            <AddForm>
              <Button
                ghost
                style={{
                  float: "right",
                  borderColor: "#3B60E4",
                  color: "#3B60E4",
                  display: "flex",
                  alignItems: "center",
                }}
                type="primary"
                shape="round"
                size="large"
                icon={<PlusCircleOutlined />}
                onClick={() => { this.addAdditionalSeller() }}
              >
                Add additional seller
              </Button>
            </AddForm>
            {isLoading ? (
              <p>Loading</p>
            ) : (
              <Form name="seller_information" colon={false} layout="vertical" requiredMark={false} onFinish={this.onFinish} initialValues={this.getInitialValues()} ref={this.formRef} onFinishFailed={(e) => { console.log("ERROROOOO", e) }} scrollToFirstError>
                {showCoListingform.map((lst, index) => (
                  <>
                    {index > 0 && (<Divider />)}
                    {index > 0 && (
                      <HeaderInfo>
                        <h2>
                          Co-Seller Information
                        </h2>
                        <RemoveCoListingAgentButton onClick={() => { this.removeColistingAgent(index) }}>
                          <CloseCircleOutlined style={{ fontSize: "22px" }} />
                        </RemoveCoListingAgentButton>

                      </HeaderInfo>
                    )}
                    <FlexBox wd="100%">
                      <Form.Item name={`firstname-${index}`} label={<LabelComponent text="First Name"  />} >
                        <Input />
                      </Form.Item>
                      <Form.Item name={`lastname-${index}`} label={<LabelComponent text="Last Name"  />} >
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <Divider />
                    <Form.Item
                      name={`type_of_ownership-${index}`}
                      label={<LabelComponent text="Type Of Ownership" />}
                    >
                      <Select
                        onChange={(value) => { this.setTypeOfOwnership(value, index) }}
                        value={this.typeOfOwnership}
                      >
                        <Select.Option value="trust">Trust </Select.Option>
                        <Select.Option value="entity">Entity (LLC, Corp, Partnership,Other) </Select.Option>
                        <Select.Option value="power_of_attorney">Power of Attorney </Select.Option>
                        <Select.Option
                          value="individual"
                        >
                              Individual
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    {(typeOfOwnership[index] !== "individual") && (
                      <>
                        {["trust", "entity", "power_of_attorney"].includes(typeOfOwnership[index]) && (
                          <Form.Item name={`ownership_name-${index}`} label={<LabelComponent text={this.getFieldName(typeOfOwnership[index])} />}>
                            <Input />
                          </Form.Item>
                        )}
                        {/* <FlexBox>
                          <Form.Item
                            name={`ownership_email-${index}`}
                            label={<LabelComponent text="Email" required />}
                            required
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message: "Required",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item name={`ownership_mobile-${index}`} label={<LabelComponent text="Cell Phone" required />} rules={commonRules} normalize={normalizePhone}>
                            <Input />
                          </Form.Item>
                        </FlexBox> */}
                      </>
                    )}
                  </>
                ))}
                <FlexBox>
                  <FlexBox wd="100%">
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          color: "#849DF8",
                          padding: 0,
                          marginLeft: "-10px",
                        }}
                        size="large"
                        type="link"
                        onClick={this.onSaveAsDraft}
                      >
                        Save for Later
                      </Button>
                    </Form.Item>
                  </FlexBox>
                  <FlexBox>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "white",
                          float: "right",
                          borderColor: "#3B60E4",
                          color: "#3B60E4",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        // htmlType="submit"
                        // onClick={() => {
                        //   if (enableDocs.includes(state)) {
                        //     history.push(`${DocumentsInfoRoute}?dealId=${getDealId()}`)
                        //   } else {
                        //     history.push(`${OfferInfoRoute}?dealId=${getDealId()}`)
                        //   }
                        //   setLoader(true)
                        // }}
                        onClick={() => { history.push(`${OfferInfoRoute}?dealId=${getDealId()}&agentId=${getAgentId()}`) }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "#3B60E4",
                          float: "right",
                          borderColor: "white",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        loading={isLoading}
                        htmlType="submit"
                      // onClick={() => { history.push("/realty/listings/coversheet/listing-details") }}
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </FlexBox>
                </FlexBox>
              </Form>
            )}
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)

