import React from "react"
import styled from "styled-components"
import ProperyImage from "./../Components/Image"
import Button from "./../Components/Button"
import RoundButton from "./../Components/RoundButton"
import Para from "./../Components/Para"
import { RADIUS_ASSIST_LOGO_BLACK, SHADOW_BORDER } from "./../ImagesData"

const H5 = ({
  style,
  ...props
}) => <h5
  style={{
    color: "#555555",
    fontSize: 12,
  }}
  {...props}
/>

const Image = ({ style, ...props }) => (
  <img
    style={{
      width: 152,
    }}
    {...props}
  />
)

const Tag = ({
  style,
  ...props
}) => (
  <span
    style={{
      background: "#11adf3",
      color: "#FFF",
      borderRadius: "4px",
      padding: 5,
      letterSpacing: 1,
      fontSize: 12,
      margin: "3px",
      ...style,
    }}
    {...props}
  >
  </span>
)

const CommonTextStyles = {
  fontSize: 12,
  margin: 0,
  padding: 0,
  fontWeight: 100,
}

const Title = ({
  style,
  ...props
}) => (
  <h6
    style={{
      ...CommonTextStyles,
      marginTop: 15,
      color: "#a5a8ad",
      ...style,
    }}
    {...props}
  >

  </h6>
)

const TextData = ({
  style,
  ...props
}) => (
  <h6
    style={{
      color: "#354052",
      ...CommonTextStyles,
      ...style,
    }}
    {...props}
  >

  </h6>
)

const NewLead = props => (
  <table style={{ marginTop: 20, background: "#FFFFFF", padding: 20, }}>
    <tbody>
      <tr
        colSpan="10"
        style={{
          height: 70,
        }}
      >
        <td>
          <table
            id="property-status-image-holder"
            style={{
              padding: 20,
              marginTop: "-60px ",
              marginRight: "auto",
              marginLeft: "auto",
              maxWidth: "500px",
              width: "100%",
              background: `url(http://www.gstatic.com/android/market_images/email/mid.png)`,
              border: "1px solid #CCC",
              borderRadius: "4px",
              background: "#FFF",
            }}
          >
            <tbody style={{ margin: 50 }}>
              <tr
                style={{
                  borderRadius: 4,
                  margin: 20,
                }}
              >
                <td>
                  <h1
                    style={{
                      textAlign: "center",
                      fontWeight: "normal",
                      fontFamily: "HelveticaNeue-Light",
                      fontSize: 28,
                    }}
                  >
                    Do you want this lead?
                  </h1>
                </td>
              </tr>
              <tr>
                <td className="new-lead-wrap" style={{ textAlign: "left" }}>
                  <div
                    style={{
                      borderRadius: "4px",
                      maxWidth: "330px",
                      padding: "30px",
                      margin: "0px auto 30px auto",
                      textAlign: "center",
                      backgroundImage: `url(${SHADOW_BORDER})`,
                      backgroundSize: "100% 100%",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <Tag
                        style={{
                          background: "#11adf3",
                        }}
                      >
                        QUALIFIED
                      </Tag>
                      <Tag
                        style={{
                          background: "#354052",
                        }}
                      >
                        Buyer
                      </Tag>
                    </div>
                    <div>
                      <h3>$789K</h3>
                      <Title>
                        Location
                      </Title>
                      <TextData>
                        Some Random Location
                      </TextData>

                      <Title>
                        Some Lable
                      </Title>
                      <TextData>
                        Some Data
                      </TextData>

                      <Title>
                        Some Lable
                      </Title>
                      <TextData>
                        Some Data
                      </TextData>

                      <TextData style={{ color: "#ea526f", marginTop: 20 }}>
                        Name will be displayed upon claiming
                      </TextData>
                      <RoundButton style={{ margin: "20px auto" }}>
                        Claim
                      </RoundButton>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>
          <Para>Hi {`{{ Name }}`}</Para>
          <Para>
            We’ve found a lead in your area that you might be interested in.
          </Para>
          <Para style={{ marginBottom: 50 }}>
            To claim this lead, please log into your Radius Agent account or click the button below. Up to 3 agents can claim this lead before it is removed from the marketplace. The first 3 agents to claim this lead will eventually get the client’s name, email and phone number. 
            We want to boost exposure for your listings so we've created a profile page for you on Radius Agent for you with your listings displayed.
          </Para>
          <Button style={{ margin: "0px auto", display: "block", width: 200, fontSize: 16, background: "#ea526f" }}>
            Update Status
          </Button>
          <Para>Thank you,</Para>
          <Para>
            Peter
            Radius Agent Support
            <a href="#">radiusagent.com</a>
          </Para>
        </td>
      </tr>
    </tbody>
  </table>
)

export default NewLead
