import styled from "styled-components"

export const Title = styled.h1`
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.72px;
`