import React, { Component } from "react"
import styled from "styled-components"
import { reduxForm, Field } from "redux-form"
import {
  Route, Switch, Link,
} from "react-router-dom"
import Container from "container/ProductInterest"
import RTFEditorIO from "./RTF"

const Wrap = styled.div`
  height: max-content;
  width: ${props => props.isChild ? "100%;" : "140%;"}
  display: flex;
  flex-direction: column;
  .title {
   font-family: "SF Pro Display", sans-serif;
   font-size: 14px;
   font-weight: bold;
   color: #24292e;
   margin-left: 50px;
  }
  .sub {
   font-size: 12px;
   color: #586069;
   margin-left: 50px;
  }
  .ress {
   margin-top: 12px;
  }
  .help {
   margin-top: 17px
  }
`

const LineBreak = styled.div`
  height: 100px;
  width: 100%;
`

const Row = styled.div`
  height: max-content;
  width: 100%;
  ${props => props.published && `
      padding: 45px;
    `}
`

class DescriptionRTF extends React.Component {
 componentDidMount() {
  //
  const {
    isChildView,
    currentEditableProduct,
    setPublishedStatus,
    setRARStatus,
    setAssistStatus,
    isPublished,
  } = this.props


  if(!isChildView) {
    setPublishedStatus({
      isPublished: currentEditableProduct.published,
    })
    setAssistStatus({
      isAssist: currentEditableProduct.isAssist,
    })
    setRARStatus({
      isRAR: currentEditableProduct.isRAR,
    })
  }
 }

 componentDidUpdate(prevProps, prevState) {
   const {
     isChildView,
     currentEditableProduct,
     setPublishedStatus,
     setRARStatus,
     setAssistStatus,
     isPublished,
     isNewFeatureOrChild,
   } = this.props

   if(!isChildView && prevProps.currentEditableProduct !== this.props.currentEditableProduct) {
     setPublishedStatus({
       isPublished: currentEditableProduct.published,
     })
     setAssistStatus({
       isAssist: currentEditableProduct.isAssist,
     })
     setRARStatus({
       isRAR: currentEditableProduct.isRAR,
     })
   }
 }

 handlePublishStatus = () => {
  const {
    currentEditableProduct,
    setPublishedStatus,
    isPublished,
  } = this.props

  setPublishedStatus({
   isPublished: !isPublished,
  })
 }

 handleAssistStatus = () => {
  const {
    setAssistStatus,
    isAssist,
    setRARStatus,
  } = this.props

  setAssistStatus({
   isAssist: !isAssist,
  })
  setRARStatus({
   isRAR: false,
  })
 }

 handleRARStatus = () => {
  const {
    setAssistStatus,
    setRARStatus,
    isRAR,
  } = this.props

  setRARStatus({
   isRAR: !isRAR,
  })
  setAssistStatus({
    isAssist: false,
  })
 }

  render() {
   const {
    rtfContent,
    child,
    isPublished,
    isRAR,
    isAssist,
    currentEditableProduct,
    isNewFeatureOrChild,
   } = this.props

   return(
    <Wrap isChild={child}>
      {child && (
        <Row>
         <p className="title">Product Description</p>
         <p className="sub">Provide description in brief about the features being showcased</p>
         <RTFEditorIO
           customMinHeight={100}
           contentType="productDescription"
           rtfContent={rtfContent && rtfContent.length > 0 ? rtfContent : ""}
         />
        </Row>
      )}
     {!isNewFeatureOrChild && !child && currentEditableProduct.screens[0].ctaButtonText !== "edit" && currentEditableProduct.screens[0].noButtonText !== "edit" && currentEditableProduct.screens[0].webLink !== ""? (
     <Row published>
       <p>Published</p>
        <div className="publishedModal">
         <div>
          <input checked={isPublished} name="publishedModal" type="checkbox" onChange={this.handlePublishStatus}/>
          <label htmlFor="Published">Published?</label>
         </div>
        </div>
        <p>Type | Select only ONE.</p>
         <div className="publishedModal">
          <div>
           <input checked={isAssist} name="publishedModal" type="checkbox" onChange={this.handleAssistStatus}/>
           <label htmlFor="Published">Assist?</label>
          </div>
         </div>
         <div className="publishedModal">
          <div>
           <input checked={isRAR} name="publishedModal" type="checkbox" onChange={this.handleRARStatus}/>
           <label htmlFor="Published">Radius Realty?</label>
          </div>
         </div>
     </Row>
   ) : (
     <p style={{ color: "red", fontSize: "14px", paddingLeft: "46px"}}>Please fill the Feature fields entire before publishing.</p>
   )}
     {!child && currentEditableProduct && Object.keys(currentEditableProduct).length <= 0 &&(
       <p style={{ color: "red", fontSize: "14px", paddingLeft: "46px"}}>Please fill the Feature fields entire before publishing.</p>
     )}
    </Wrap>
   )
  }
}
export default Container(DescriptionRTF)
