/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-multi-str */
import React, { useEffect, useState } from "react"
import {
  Table,
  Tag,
  Pagination,
  Button,
  Select,
  Modal,
} from "antd"
import styled from "styled-components"
import root from "window-or-global"
import { withRouter } from "react-router-dom"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { get, capitalize, throttle } from "lodash"
import RARTechContainer from "container/TechAssets"
import globalContainer from "container/BrokerDashboard/globalPagesContainer"
import UploadImageDrawer from "dumbComponents/BrokerDashboard/Components/UploadImageDrawer"
// import ConfirmationModal from "../../Common/ConfirmationModal"
import { serializer, handleDrawerActions } from "../../Common/Utils_listing.js"

const { confirm } = Modal

const StyledButton = styled.button`
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0px;
  text-align: left;
`
const ResultsFor = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  -webkit-letter-spacing: 0em;
  -moz-letter-spacing: 0em;
  -ms-letter-spacing: 0em;
  letter-spacing: 0em;
  text-align: left;
  font-style: italic;
  color: #2d2d2d;
`

const Wrap = styled.div`
  padding-top: 17px;
  overflow-x: scroll;
  width: 100%;
  .infoTable {
    th, td {
      border-right: none;
      border-top: none;
      border-left: none;
      font-family: "Rubik", sans-serif;
    }

    th {
      padding: 5px 5px 5px 18px;
    }
    

    td {
      padding: 10px 5px 10px 18px;
      background: #fafafa;

      &:hover {
        background: #ffffff;
      }
     }
     tr {
      border-bottom: 1px solid black;
      cursor: pointer;
      &:hover {
        background:  #fafafa;
      }
    }
    .ant-table-thead tr th {
      border-bottom: 1px solid black;
      background: linear-gradient(0deg, #E5E5E5, #E5E5E5),
      linear-gradient(0deg, #E6E6E6, #E6E6E6);
    }
  }
`

const RFType = styled.div`
  display: inline-block;
  padding: 4px;
  font-size: 10px;
  background: #009688;
  color: #fff;
  border-radius: 4px;
  width: max-content;
  align-self: auto;
`
const UploadButton = styled.div`
  label {
    background-color: #27AE60;
    color: #fff;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;

    ${props => props.isUploading && `
      background-color: #999;
    `}

  }
  input[type="file"] {
    display: none;
  } 
`
const CellDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 0px;
  }
`
const LightP = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0px;
`
const PAGE_SIZE = 10
//check here
const ResultsTable = (props) => {
  const {
    dealsListingResponse,
    fetchingList,
    deleteInvite,
    history,
    toggleDrawer,
    getConvertedOfferDealActionResponse,
    createContractActionResponse,
    getContractStatusResponse,
    toggleUploadImageDrawer,
    setDealIdToUpload,
    dealIdToUpload,
    getAllDealsListing,
    saveListingSteps,
    saveListingStepsResponse,
    setTotalListingCount,
    totalListingCount,
  } = props || {}

  const [listingStatuses, setListingStatuses] = useState()
  const [showStatus, setShowStatus] = useState()

  useEffect(() => {
    setListingStatuses(getContractStatusResponse?.data && getContractStatusResponse?.data?.listingStatus && getContractStatusResponse?.data?.listingStatus?.length > 0 && getContractStatusResponse.data.listingStatus.map(x => (
      { label: x.display_name, value: x.value }
    )))
    getAllDealsListing({
      type: "global",
      limit: PAGE_SIZE,
      skip: 0,
      transactionType: "Listing",
    })
  }, [getContractStatusResponse?.data])
  //console.log("listingStatus", listingStatuses)
  const { isFetching, data: listingData, isFetchingMore } = dealsListingResponse

  const redirect = (record) => {
    if (record.activeContractId !== null) {
      root.open(`/broker/dashboard/${record.agentId}/deals/view/${record.activeContractId}?fromContract?listingId=${record.dealId}`)
    } else {
      root.open(`/broker/dashboard/${record.agentId}/deals/view/${record.dealId}?fromListing?listingId=${record.dealId}`)
    }
  }

  const handleStatusUpdate = (data, value) => {
    if (value === "pending") {
      const isActive = window.confirm("Since you are changing the status of a New Listing to 'Pending', we will be creating a Contract and Changing Contract status to Incomplete. Please fill the  pending Transaction Details for this Contract to change status of contract from Incomplete to Submitted")
      if (!isActive) {
        return
      }
    } else {
      const isConfirm = window.confirm("Are you sure?")
      if (!isConfirm) {
        return
      }
    }
    const payload = {
      listing_id: data.dealId,
      step: value,
      notes: "",
    }
    saveListingSteps({
      payload,
      callback: () => {
        getAllDealsListing({
          type: "global",
          limit: 30,
          skip: 0,
          transactionType: "Listing",
        })
      },
    })
    setShowStatus(value)
  }

  const createColumn = () => {
    const columns = [
      {
        title: "Actions",
        dataIndex: "actions",
        render: (text, record) => (
          <>
            <Select
              options={listingStatuses}
              openMenuOnClick={(e) => { e.preventDefault() }}
              value={record.listingStatus}
              style={{
                width: 200,
              }}
              onChange={(selectedItem) => { handleStatusUpdate(record, selectedItem) }}
              // loading={isFetchingStatus}
              disabled={record.listingStatus === "incomplete"}
            />
          </>
        ),
      },
      {
        title: "Date of Listing Submission",
        dataIndex: "dateOfListingSubmission",
        key: "dateOfListingSubmission",
        onCell: record => ({ onClick: () => { redirect(record) } }),
      },
      {
        title: "Agent Name",
        dataIndex: "agentName",
        key: "agentName",
        onCell: record => ({ onClick: () => { redirect(record) } }),
      },
      {
        title: "Preferred Email",
        dataIndex: "preferredEmail",
        key: "preferredEmail",
        onCell: record => ({ onClick: () => { redirect(record) } }),
      },
      {
        title: "Address",
        dataIndex: "propertyAddress",
        key: "propertyAddress",
        onCell: record => ({ onClick: () => { redirect(record) } }),
      },
      {
        title: "Client Details",
        dataIndex: "clientType",
        key: "clientType",
        onCell: record => ({ onClick: () => { redirect(record) } }),
        render: (text, record) => (
          <CellDiv>
            <p>{get(record, "clientDetails.client_name", "") || "-"}</p>
            <LightP>
              {`${get(record, "clientDetails.client_email", "") || ""}`}
            </LightP>
            <LightP>
              {`${get(record, "clientDetails.client_phone", "") || ""}`}
            </LightP>
            {get(record, "numOfClients") > 1 && (
              <div style={{ fontSize: 12, fontWeight: "700" }}>
                {get(record, "numOfClients") - 1}
                {" "}
                more clients
              </div>
            )}
            <RFType>
              {capitalize(record.clientType)}
            </RFType>
          </CellDiv>
        ),
      },
      {
        title: "Upload Image",
        dataIndex: "uploadImage",
        render: (text, record) => (
          <>
            <Button
              onClick={() => {
                toggleUploadImageDrawer(true)
                setDealIdToUpload(record.dealId)
              }}
              type="primary"
            >
              {record.listingImages !== null ? "View Images" : "Upload Images"}
            </Button>
          </>
        ),
      },
      {
        title: "Cap Amount",
        dataIndex: "capAmount",
        key: "capAmount",
        onCell: record => ({ onClick: () => { redirect(record) } }),
      },
      {
        title: "List Price",
        dataIndex: "listPrice",
        key: "listPrice",
        onCell: record => ({ onClick: () => { redirect(record) } }),
      },
      {
        title: "Active Contract",
        dataIndex: "activeContractId",
        render: (text, record) => (
          <>
            {record.activeContractId ? (
              <Button
                type="primary"
                onClick={() => {
                  root.open(`/broker/dashboard/${record.agentId}/deals/view/${record.activeContractId}`)
                }}
              >
            View Contract
              </Button>
            ) : "-"}
          </>
        ),
      },
    ]
    return columns
  }

  const serialized = !isFetching && serializer(listingData) || []
  const { isFetching: isFetchingStatus } = saveListingStepsResponse

  useEffect(() => {
    setShowStatus(serialized.listingStatus)
  }, [serialized])

  const {
    isFetching: gettingDealId,
  } = getConvertedOfferDealActionResponse || {}

  const {
    isFetching: convertingToContract,
  } = createContractActionResponse || {}

  const onChange = (page, pageSize) => {
    const alreadyFetched = get(dealsListingResponse, "data.length")
    console.log("here", alreadyFetched)
    getAllDealsListing({
      type: "global",
      limit: alreadyFetched + PAGE_SIZE,
      skip: alreadyFetched,
      //offset: 10 * (page - 1),
      transactionType: "Listing",
    })
  }
  console.log("---count---", totalListingCount)
  return (
    <Wrap>
      <Table
        className="infoTable"
        columns={createColumn()}
        dataSource={serialized}
        loading={isFetching || gettingDealId || convertingToContract}
        scroll={{
          x: 2000,
        }}
        pagination={
          {
            total: totalListingCount,
            defaultPageSize: 10,
            defaultCurrent: 1,
            onChange,
          }
        }
      >
      </Table>
      <UploadImageDrawer dealId={dealIdToUpload} data={listingData} />
    </Wrap>
  )
}

export default globalContainer(withRouter(RARTechContainer(ResultsTable)))
