import React from "react"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import cookie from "react-cookies"
import {
  BlueButton,
} from "../../commonStyles"
import { showConfirm } from "../../ConfirmationModalUtil"

const ReviewDocuments = ({
  triggerRecieverViewDocuSign,
  fetchICAListingsResponse,
  disabled,
  triggerRecieverViewDocuSignResponse,
  isLiveLoaderShown,
}) => {
  const {
    data: isaDocuments,
    isFetching: fetchingICADocuments,
  } = fetchICAListingsResponse || {}

  const {
    isFetching: triggeringReviewMode,
  } = triggerRecieverViewDocuSignResponse || {}

  const user = cookie.load("user")
  const adminId = cookie.load("admin_id")
  const id = adminId ? parseInt(adminId, 10) : parseInt(user.agent_id, 10)

  const envelopeId = !fetchingICADocuments && isaDocuments && isaDocuments.length > 0 && isaDocuments.map(doc => doc.envelope_id) || []

  return (
    <BlueButton
      disabled={disabled || fetchingICADocuments || triggeringReviewMode || isLiveLoaderShown}
      onClick={() => {
        //On clicking this trigger the RECEIVER VIEW
        if (envelopeId && envelopeId.length > 0) {
          const postAction = () => {
            triggerRecieverViewDocuSign({
              payload: {
                agentId: parseInt(id, 10),
                envelopeId: envelopeId[0],
              },
            })
          }

          const message = "Proceed signing the documents?"
          const warning = "Please ensure you have reviewed the document before signing them!"

          showConfirm(message, postAction, warning)
        } else {
          // eslint-disable-next-line no-alert
          alert("Something went wrong!")
        }
      }}
      ATCFONT
      borderRadius="33.5px"
    >
      {triggeringReviewMode ? "Please Wait" : " Review Documents"}
      {!triggeringReviewMode && (
        <span>
          <img
            src="https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/ISA_SOUL_SUBMIT_Arrows.svg"
            alt="submit_isa"
          />
        </span>
      )}
    </BlueButton>
  )
}

export default subscriptionContainer(ReviewDocuments)

