import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as Actions from "./actions"

function mapStateToProps(state) {
  const { recruitAndEarn } = state
  return {
    ...recruitAndEarn,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      fetchInvites: Actions.getBrokerInvitesAction.request,
      fetchEarnings: Actions.getBrokerEarningAction.request,
      toggleInvite: Actions.toggleInviteAction.request,
      updateBrokerEarning: Actions.updateBrokerEarningsAction.request,
      fetchAgentInviteAnalytics: Actions.fetchAgentInviteAnalytics.request,
      createNewInvite: Actions.createNewInviteAction.request,
      deleteInvite: Actions.deleteInviteAction.request,
      fetchInviteById: Actions.fetchInviteByIdAction.request,
      editInviteById: Actions.editInviteAction.request,
      toggleInvitedDrawer: Actions.toggleInvitedDrawerAction.call,
      fetchReferrals: Actions.getReferralsListAction.request,
      fetchInviteeTimeline: Actions.getInviteeTimelineAction.request,
      updateInviteeStatus: Actions.updateInviteeStatusAction.request,
      fetchAllEarnings: Actions.getEarningsListAction.request,
      fetchAgentDetailsRAE: Actions.getAgentDetailsActionRAE.request,
      fetchAgentEarnings: Actions.getAgentSpecificDetailAction.request,
      toggleAgentDetailsDrawer: Actions.toggleAgentDetailsDrawerAction.call,
      toggleEditDrawer: Actions.toggleEditDrawerAction.call,
      updateEarnings: Actions.updateEarningsAction.request,
      setSearchSenderAgent: Actions.setSearchSenderAgentAction.call,
      setSearchReferralAgent: Actions.setSearchReferralAgentAction.call,
      clearSetSearchValues: Actions.clearSetSearchValuesAction.call,
      getSenderTimelines: Actions.getSenderTimelinesAction.request,
      toggleConfirm: Actions.toggleConfirmationModalAction.call,
      setModalReasonTextValue: Actions.setConfirmationModalReasonAction.call,
      getTotalRecruitAndEarnStats: Actions.getTotalRecruitAndEarnStatsAction.request,
      setFilterQueries: Actions.setFilterQueriesAction.call,
      downloadCSV: Actions.downloadCSV.request,
      downloadReferralCSV: Actions.downloadReferralCSV.request,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
