import React from "react"
import styled from "styled-components"
import { format } from "date-fns"
import { unixToDate } from "services/Utils"
import colors from "library/colors"

const TitleWrap = styled.p`
  font-weight: 100;
  white-space: pre-line;
  strong{
    text-transform: capitalize;
    font-weight: 400;
  }
`

export const Note = styled.h6`
  font-weight: 100;
  font-size: 14px;
  margin: 0px;
  color: ${colors.contentText};
  strong {
    font-weight: bold;
  }
`

export const Title = ({
  type,
  time,
  extraText,
}) => (
  <TitleWrap>
    <strong>{type}</strong>
    {time && (
      <React.Fragment>
        <span> at </span>
        <time>{format(unixToDate(time), "h:mm a")}</time>
      </React.Fragment>
    )}
    {extraText && (
      <span>
        {extraText}
      </span>
    )}
  </TitleWrap>
)
