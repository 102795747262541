import axios from "axios"
import { Fetch } from "./../../lib/NetworkUtils"

const baseURLV1 = process.env.REACT_APP_API_BASE_URL;
const baseURLV2 = process.env.REACT_APP_API_V2_URL

// https://staging.agentdesks.com/v2.0/prospect-agents/287469?visible=1&fields=agreement_signed_data&offset=0&limit=200

export const getAgentsForMatchingAPI = (referralId, limit, query) => axios.get(`${baseURLV2}prospect-agents/${referralId}?visible=1&fields=agreement_signed_data&offset=0&limit=${limit}${query}`)

//https://staging.agentdesks.com/v1.0/consumer/62060de1c5fc4f08cc37fe2d
export const assignAgentAPI = (clientId, payload) => axios.post(`${baseURLV1}/v1.0/consumer/${clientId}`, payload)

// https://api1.agentdesks.com/v1.0/agent/elastic/query

export const agentSearcAPI = payload => axios.post(`${baseURLV1}/v1.0/agent/elastic/query/fetch-prospects`, payload)

// https://staging.agentdesks.com/v2.0/prospects/287469/mark-invisible
export const removeFromSuggestinsAPI = (referralId, payload) => axios.post(`${baseURLV2}prospects/${referralId}/mark-invisible`, payload)

// https://staging.agentdesks.com/v2.0/prospects/287469
// agent_id: 10023679
// prospect_status: 0
// type: "qualification"
// visible: 1

export const inviteAgentAPI = (referralId, payload) => axios.post(`${baseURLV2}prospects/${referralId}`, payload)

export const getLMPStatusAPI = referralId => axios.get(`${baseURLV2}get-toggle-status/${referralId}`)

export const changeLMPStatusAPI = (referralId, payload) => axios.post(`${baseURLV2}prospect-agents/${referralId}`, payload)

