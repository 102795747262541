import React, { useEffect } from "react"
import styled from "styled-components"
import SearchControls from "../Common/SearchControls"
import RecruitAndEarn from "../../../container/RecruitAndEarn"
import ConfirmationModal from "../Common/ConfirmationModal"
import Table from "./FinancialsComponents/Table"
import EditDrawer from "./FinancialsComponents/EditDrawer"

const Wrap = styled.div``

const Financials = (props) => {
  const {
    fetchAllEarnings,
    getEarningsListResponse,
  } = props || {}

  useEffect(() => {
    fetchAllEarnings({
      queryParams: "skip=0&limit=5000",
    })
  }, [])

  const {
    isFetching: fetchingEarnings,
    data: earningsList,
  } = getEarningsListResponse || {}

  return (
    <>
      <Wrap>
        <SearchControls />
        <Table
          fetchingEarnings={fetchingEarnings}
          earningsList={earningsList}
        />
        <EditDrawer />
      </Wrap>
      <ConfirmationModal />
    </>
  )
}

export default RecruitAndEarn(Financials)
