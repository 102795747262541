import React, { Component } from "react"
import styled from "styled-components";
import container from "container/Agent"
import StripeCheckout from 'react-stripe-checkout'
import { isEmpty, get } from "lodash"
const stripeKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;

const AgentWrap = styled.div`
    background: #FFF;
    padding: 10px 50px;
    text-align: center
    border: 1px solid #CC:
    border-radius: 5px;
`

const TextWrap = styled.div`
    display: inline-block;
    text-align: left;
`

const PlanWrap = styled.div`
    margin-right: 10px;
    flex-wrap: wrap;
`

const ApplyCouponInput = styled.input``

const CTAWrap = styled.div`
    display: flex;
    flex-direction: column;
    input {
      margin-right: 13px;
      padding: 7px;
    }
    div {
     padding: 20px;
     display: flex;
     flex-direction: row;
    }
    button {
     border: none;
     /* cursor: ${props => !props.isApplyEnabled ? "not-allowed" : "pointer"};
     color: ${props => !props.isApplyEnabled ? "grey" : "#11adf3"}; */
     font-size: 12px;
    }
`

const CouponValidity = styled.p`
   margin-top: -10px;
   margin-bottom: 10px;
   ${props => !props.isValid && `
      color: red;
    `}
`

const Plan = styled.div`
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #CCC;
    background: #FFF;
    padding: 10px;
    margin-top: 10px;

    ${props => props.isSelected && `
        background: #2ecc71;
        color: #FFF;
        font-weight: bold;
    `}
`




class Checkout extends Component {
    state = {
        selectedPlan: {},
        isPlanSelected: true,
        agentId: '',
        secure_id: '',
        stripe_token_id: '',
        flag: false,
    }

    componentDidMount(){
        try {
            this.setState({
             flag: false,
            })
            this.props.couponResponse.data = null
            this.props.subscriptions.error.isError = false
            console.log("in checkout component mount...")
            this.props.checkout.data.success = false
            this.checkoutStripePlan()
            //this.forceUpdate()
        }
        catch(e){
            console.log(e);
        }
    }

    componentWillUnmount() {
        console.log("called checkout unmount..")
    }

    onCheckout = ({ id: tokenId}) => {
        const {secure_id} = this.props
        const {data : agentSecureData } = secure_id || {}
        const {secure_id: agentSecureId} = agentSecureData || {}
        this.checkoutStripePlan(agentSecureId, tokenId)
    }

    handleCouponInput = (e) => {
      this.setState({
       [e.target.name]: e.target.value,
      })
    }

    handleApplyCoupon = (id) => {
     const {
      couponInput,
     } = this.state
     const {
      applyCoupon,
      location,
     } = this.props
     const {
      state: {
       secureId,
       selectedPlan: {
        plan_id
       }
      }
     } = location

     this.setState({
      flag: true,
     })

     applyCoupon({
      agentId: id,
      couponCode: couponInput,
      plans: [plan_id],
     })
    }


    checkoutStripePlan = (agentSecureId, tokenId = "") => {
        const { checkoutPlan, couponResponse} = this.props
        const {
         data: coupon,
        } = couponResponse
        checkoutPlan({
          secure_id: agentSecureId,
          plan_id : this.props.location.state.selectedPlan.plan_id,
          tokenId: tokenId,
          couponCode: coupon ? coupon.couponCode : null,
         })
    }

    render() {
        const {
          couponInput,
          flag,
        } = this.state
        const { couponResponse } = this.props
        const {
         isError: errorApplyingCode,
         error,
         data: coupon,
        } = couponResponse
        const {agent, selectedPlan, isPlanSelected, secureId, subscriptions }  = this.props.location.state
        const { email, phone, firstname, lastname, id } = agent
        console.log(agent)
        const { checkout} = this.props
        const {isError: checkoutError, data: checkoutData, isFetching: checkoutFetching} = checkout || {}
        const {success} = checkoutData  || {}
        const {isError: isSubError, data: subscriptionsData } = subscriptions || {}
        const isSubscribed = !isEmpty((subscriptionsData))
        const {
         unit_price,
        } = selectedPlan
        return (
            <div style={{ margin: "10px auto", maxWidth: 800 }}>
                <div>
                    <TextWrap>
                        <h1>
                            Agent Details:
                        </h1>

                        {firstname &&
                        <span>
                        <span>Agent name:&nbsp;</span>
                        <span>{firstname}</span>
                    </span>
                        }

                        {lastname &&
                        <span>
                        <span>&nbsp;{lastname}</span>
                    </span>
                        }

                        {email &&
                        <span>
                        <br></br>
                        <span>Agent email:&nbsp;</span>
                        <span>{email}</span>
                        <br></br>
                    </span>
                        }

                        {phone &&
                        <span>
                        <span>Agent phone:&nbsp;</span>
                        <span>{phone}</span>
                    </span>
                        }
                        <AgentWrap>
                        <React.Fragment>
                            <h4>You have selected the {selectedPlan.name} </h4>
                            {!isSubError && isSubscribed ? (
                                <React.Fragment>
                                    {success ? (
                                        <React.Fragment>
                                        <h1> Successfully subscribed to the plan</h1>
                                        </React.Fragment>
                                    ): (
                                        <React.Fragment>
                                        {checkoutFetching ? (
                                                <React.Fragment>
                                                    <h1>loading...</h1>
                                                </React.Fragment>
                                            ): (
                                             <CTAWrap>
                                               <div>
                                                 <input
                                                  type="text"
                                                  name="couponInput"
                                                  onChange={e => this.handleCouponInput(e)}
                                                 />
                                                 <button
                                                  disabled={!couponInput}
                                                  onClick={() => this.handleApplyCoupon(id)}
                                                 >
                                                   Apply Coupon
                                                 </button>
                                               </div>
                                               {coupon && (
                                                <CouponValidity
                                                  isValid={coupon.isValid}
                                                >
                                                {coupon.isValid ? `${coupon.couponCode} is a valid coupon and applied` : `${coupon.couponCode} is not a valid coupon for this plan`}
                                                </CouponValidity>
                                               )}
                                               {(coupon && coupon.isValid && coupon.discountAmount) && (
                                                <p>{`Amount to be charged - $${unit_price - coupon.discountAmount}`}</p>
                                               )}
                                                <button className="med-btn-1" onClick={() => {this.checkoutStripePlan(secureId);}}>Checkout Plan</button>
                                             </CTAWrap>
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {!success ? (
                                        <React.Fragment>
                                            {checkoutFetching ? (
                                                <h1>loading...</h1>
                                            ):(
                                                isSubError && !checkoutFetching && (
                                                 <CTAWrap
                                                   isApplyEnabled={couponInput}
                                                 >
                                                   <div>
                                                     <input
                                                      type="text"
                                                      name="couponInput"
                                                      onChange={e => this.handleCouponInput(e)}
                                                     />
                                                     <button
                                                      disabled={!couponInput}
                                                      onClick={() => this.handleApplyCoupon(id)}
                                                     >
                                                       Apply Coupon
                                                     </button>
                                                   </div>
                                                   {(coupon && flag) && (
                                                    <CouponValidity
                                                      isValid={coupon.isValid}
                                                    >
                                                    {coupon.isValid ? `${coupon.couponCode} is a valid coupon and applied` : `${coupon.couponCode} is not a valid coupon or not yet applied`}
                                                    </CouponValidity>
                                                   )}
                                                   {(coupon && coupon.isValid && coupon.discountAmount) && (
                                                    <p>{`Amount to be charged - $${unit_price - coupon.discountAmount}`}</p>
                                                   )}
                                                   <StripeCheckout
                                                      token={this.onCheckout}
                                                      stripeKey= {stripeKey}
                                                   />
                                                 </CTAWrap>

                                               )
                                            )}
                                        </React.Fragment>
                                        ):
                                        (<React.Fragment>
                                            <h1> Successfully subscribed to the stripe plan</h1>
                                        </React.Fragment>)}
                                </React.Fragment>
                            )}

                        </React.Fragment>
                    </AgentWrap>
                    </TextWrap>
                </div>
            </div>

        )
    }
}

export default container(Checkout);
