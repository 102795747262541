import React from "react"
import styled from "styled-components"
import { Input, Table } from "antd"

const { Pagination } = Table

const Wrap = styled.div`
  padding-top: 38px;
`

const EarningsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

const Box = styled.div`
  height: 136.205078125px;
  width: ${props => `${props.wd}px`};
  padding: 23px 19px 23px 19px;
  background: #F9F9F7;
`

const Title = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.06em;
  text-align: left;
`

const Value = styled.p`
  font-size: 56px;
  font-weight: 400;
  line-height: 67px;
  letter-spacing: 0.06em;
  text-align: left;

`

const InnerWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${props => props.flex};
  align-items: baseline;

  input {
    padding-left: 0px;
  }
`

const Label = styled.label`
  width: -webkit-fill-available;
`

const PendingsWrap = styled.div`
 display: flex;
 flex-direction: row;
 margin-top: 18px;
`

const TableWrap = styled.div`
  padding-top: 18px;
  .infoTable {
    th, td {
      border: none;
      font-family: "Rubik", sans-serif;
    }

    th {
      padding: 5px 5px 5px 18px;

    }

    td {
      padding: 10px 5px 10px 18px;
      background: #fafafa;

      &:hover {
        background: #fcfcfc;
      }
    }

    .ant-table-thead tr th {
      background: linear-gradient(0deg, #E5E5E5, #E5E5E5),
      linear-gradient(0deg, #E6E6E6, #E6E6E6);
    }
  }
`

const AgentDetails = (props) => {
  const {
    agentEarnings,
    fetchingAgentEarnings,
  } = props

  const {
    invites,
    overview,
  } = agentEarnings || {}

  const createColumn = () => {
    const columns = [
      {
        title: "Agent Name",
        dataIndex: "name",
        key: "name",
        render: text => <p>{text}</p>,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: text => <p>{text}</p>,
      },
      {
        title: "Amount / Stock",
        dataIndex: "stock",
        key: "stock",
        render: text => <p>{text}</p>,
      },
    ]
    return columns
  }

  const getValues = () => {
    if (!invites || invites === null) return []
    return invites.map((item) => {
      const {
        firstname,
        lastname,
        status,
        earned_rewards,
        earned_stocks,
      } = item || {}

      return {
        name: `${firstname} ${lastname}`,
        status,
        stock: `$${earned_rewards}/${earned_stocks}`,
      }
    })
  }

  return (
    <>
      {fetchingAgentEarnings ? (
        <p>Fetching...</p>
      ) : (
        <Wrap>
          <EarningsWrap>
            <Box wd={190}>
              <Title>AGENTS REFERRED</Title>
              <Value>{overview && overview.total_referred}</Value>
            </Box>
            <Box wd={190}>
              <Title>AGENTS RECRUITED</Title>
              <Value>{overview && overview.total_recruited}</Value>
            </Box>
            <Box wd={394}>
              <Title>TOTAL MONEY EARNED</Title>
              <Value>{overview && overview.total_earned_rewards}</Value>
            </Box>
            <Box wd={394}>
              <Title>TOTAL SHARES EARNED</Title>
              <Value>{overview && overview.total_earned_stocks}</Value>
            </Box>
          </EarningsWrap>
          <PendingsWrap>
            <InnerWrap flex="row">
              <Label htmlFor="pending_shares">Pending Shares:</Label>
              <Input
                readOnly
                name="pending_shares"
                value={overview && overview.total_pending_stocks}
                style={{ border: "none", cursor: "pointer" }}
              />
            </InnerWrap>
            <InnerWrap flex="row">
              <Label htmlFor="pending_rewards">Pending Rewards:</Label>
              <Input
                readOnly
                name="pending_rewards"
                value={overview && `$${overview.total_pending_stocks}`}
                style={{ border: "none", cursor: "pointer" }}
              />
            </InnerWrap>
          </PendingsWrap>
          <TableWrap>
            <Table
              className="infoTable"
              columns={createColumn()}
              dataSource={getValues()}
            >
              <Pagination
                total={getValues().length}
                defaultPageSize={5}
                defaultCurrent={1}
              />
            </Table>
          </TableWrap>
        </Wrap>
      )}
    </>
  )
}
export default AgentDetails
