import React, { Component } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import root from "window-or-global"
import Transition from "react-transition-group/Transition"
import { isBrowser } from "services/Utils"
import Loader from "dumbComponents/common/UI/Loader"
import colors from "library/colors"
import icons from "library/icons/icons"

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: scroll;
  top: 0;
  background: ${props => props.overlayColor};
`

const Dialog = styled.div`
  width: ${props => (props.width ? props.width : "500px")};
  background-color: white;
  margin: ${props => (props.mm ? props.mm : "80px 0")};
  ${props => props.responsive && `
     @media (max-width: 1500px){
      width: ${props.responsiveWidth};
      height: ${props.responsiveHeight};
     }
  `}
`

const IconContainer = styled.div`
  position: relative;
`

const StyledFontAwesome = styled.i`
  font-size: 18px;
  color: ${colors.milkyWhite};
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 99;
`

const Content = styled.div`
  padding: 40px;
  ${props => props.responsive && `
     @media (max-width: 1500px){
      width: ${props.responsiveWidth};
      height: ${props.responsiveHeight}
     }
  `}
  .slick-list{
   height: 800px;
  }
`

const StyledTitle = styled.p`
  position: absolute;
  top: 16px;
  left: 16px;
  color: #354052;
  text-transform: ${props => props.textTransform};
  line-height: 1;
`

const HorizontalBar = styled.div`
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: ${colors.borderColor};
`

const defaultStyle = {
  transition: "opacity 150ms ease-in-out",
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exited: { opacity: 0 },
}

class Modal extends Component {
  constructor(props) {
    super(props)
    if (isBrowser()) {
      this.isBrowser = true
      this.ele = root.document.getElementById("index")
      this.blurEle = root.document.getElementById("index-layout")
    }
    this.modalRef = React.createRef()
  }

  componentDidMount() {
    const { show, onMount } = this.props
    if (show) {
      if (this.ele) {
        this.ele.focus()
        const top = `-${root.pageYOffset}px`
        this.top = root.pageYOffset
        this.ele.classList.add("noScroll")
        this.ele.style.top = top
      }

      if (this.blurEle) {
        this.blurEle.classList.add("blur-it")
      }
    }

    if (onMount) {
      onMount()
    }
  }

  componentWillUnmount() {
    const { closeModalOnEscape } = this.props
    if (this.isBrowser) {
      if (this.ele) {
        this.ele.classList.remove("noScroll")
        root.scrollTo(0, this.top)
      }
      if (this.blurEle) {
        this.blurEle.classList.remove("blur-it")
      }
      if (closeModalOnEscape) {
        root.document.removeEventListener("keydown", this.handleKeydown, false)
      }
    }
  }

  handleKeydown = (e) => {
    const { toClose } = this.props
    if (e.keyCode === 27) {
      toClose()
    }
  }

  handleOutsideClick = () => {
    const { closeModalOnOutsideClick, toClose } = this.props
    if (closeModalOnOutsideClick) {
      toClose()
    }
  }

  handleModalClick = (e) => {
    e.stopPropagation()
  }

  render() {
    const {
      toClose,
      showCloseButton,
      children,
      show,
      width,
      title,
      loading,
      modalStyles,
      textTransform,
      overlayColor,
      showHorizontalBar,
      dialogStyles,
      mm,
      responsive,
      responsiveHeight,
      responsiveWidth,
      iconStyles,
    } = this.props
    return (
      <Transition in={show} timeout={150} mountOnEnter unmountOnExit>
        {state => (
          <Overlay
            onClick={this.handleOutsideClick}
            overlayColor={overlayColor}
            style={{ ...defaultStyle, ...transitionStyles[state] }}
            ref={this.modalRef}
          >
            <Dialog
              responsive={responsive}
              responsiveHeight={responsiveHeight}
              responsiveWidth={responsiveWidth}
              onClick={this.handleModalClick}
              width={width}
              mm={mm}
              style={{ ...dialogStyles }}
            >
              { showCloseButton
              && (
                <IconContainer onClick={() => { toClose() }}>
                  {title
                  && (
                    <React.Fragment>
                      <StyledTitle size="18" fontWeight={500} textTransform={textTransform}>
                        {title}
                      </StyledTitle>
                      { showHorizontalBar && <HorizontalBar />}
                    </React.Fragment>
                  )
                  }
                  <StyledFontAwesome
                    className={icons.arrowsClose}
                    onClick={toClose}
                    style={{ ...iconStyles }}
                  />
                </IconContainer>
              )}
              <Content
                responsive={responsive}
                responsiveHeight={responsiveHeight}
                responsiveWidth={responsiveWidth}
                style={{
                  borderRadius: 4,
                  boxShadow: "0 1px 5px 0 rgba(0, 0, 0, 0.1)",
                  ...modalStyles,
                }}
              >
                { loading ? <Loader top={10} bottom={0} /> : children }
              </Content>
            </Dialog>
          </Overlay>
        )}
      </Transition>
    )
  }
}

Modal.defaultProps = {
  closeModalOnOutsideClick: true,
  closeModalOnEscape: true,
  showCloseButton: true,
  modalStyles: {
    borderRadius: 4,
    boxShadow: "0 1px 5px 0 rgba(0, 0, 0, 0.1)",
  },
  overlayColor: "rgba(0,0,0,0.3)",
  showHorizontalBar: true,
}

let ModalPortal = null
if (isBrowser()) {
  const ele = root.document.getElementById("modal_wrap")
  ModalPortal = (props) => {
    const { show } = props
    if (!show) {
      return null
    }
    return (
      ReactDOM.createPortal(
        <Modal {...props} />,
        ele,
      )
    )
  }
} else {
  ModalPortal = Modal
}

export default ModalPortal
