import React, { PureComponent } from "react"
import styled from "styled-components"

import { get, zip } from "lodash"
import ListingList from "dumbComponents/Listings/components/ListingList"
import TechAssetsContainer from "container/BrokerDashboard/globalPagesContainer"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import {
  Input, InputNumber, Select, Form, Checkbox,
  Divider, GoogleAutoComplete, Button,
} from "dumbComponents/common/UI/antd"
import Sidebar from "dumbComponents/Listings/components/Sidebar"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
import US_STATES from "shared/US_STATES.json"
import { normalizePhone } from "../../../../services/formUtils"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const HeaderNav = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
`

const ProgressBarContainer = styled.div`
  padding: 30px 0px;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #3B60E4;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LabelText = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
`

const Required = styled.span`
  color: #E34C4C
`

const CheckboxLabelText = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  text-transform: uppercase;
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const CheckboxContainer = styled.div`
  padding: 0 20px 0 0;
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
`

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

const STATE_OPTIONS = Object.keys(US_STATES).map(key => ({ label: US_STATES[key], value: US_STATES[key] }))

class CoverSheet extends PureComponent {
  state = {
    cityFormValue: "",
  }

  setLocaCity = (value) => {
    this.setState({
      cityFormValue: value,
    })
  }

  formRef = React.createRef();

  setCities = (location) => {
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    this.formRef.current.setFieldsValue({
      vendor_city: `${locationDetails.city || ""}`,
    })
    this.formRef.current.setFieldsValue({
      vendor_state: `${locationDetails.state || ""}`,
    })
    // this.setState({
    //   cityFormValue: `${locationDetails.city}`,
    // })
  }

  onFinish = (fieldValues, isDraft) => {
    const {
      updateListingCoverSheet, history, dealToView, dealAgentId,
    } = this.props
    const { cityFormValue } = this.state
    Object.keys(fieldValues).forEach((key) => {
      if (fieldValues[key] === undefined) {
        // eslint-disable-next-line no-param-reassign
        fieldValues[key] = null
      }
    })
    const payload = {
      ...fieldValues,
      city: cityFormValue,
    }
    updateListingCoverSheet({
      payload: {
        ...payload,
        current_listing_step: "insert_vendor",
        draft: isDraft ? "Y" : "N",
      },
      dealAgentId,
      dealId: dealToView,
      callAPI: ["tc_info", "select_vendor"],
      nextRoute: isDraft ? "/broker/tc/add-listings" : "/broker/tc/add-listings/coversheet/vendors",
      history,
    })
  }

  onSaveAsDraft = (values) => {
    console.log("===this.formRef.current.getFieldsValue===", this.formRef.current.getFieldsValue())
    this.onFinish(this.formRef.current.getFieldsValue(), true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  getInitialValues = () => {
    const { getListingCoverSheetResponse, escrowIdToEdit } = this.props
    const selectVendor = getListingCoverSheetResponse.data?.selectVendor
    console.log("===selectVendor===", selectVendor)
    console.log("===escrowIdToEdit===", escrowIdToEdit)
    const result = {}
    if (selectVendor && escrowIdToEdit) {
      return {
        ...selectVendor.find(vn => vn.id === escrowIdToEdit),
      }
    }
    return result
  }

  render() {
    const { cityFormValue } = this.state
    const { history } = this.props
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]
    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Listing Agent Information
        </HeaderNav>
        <ProgressBarComponent total={[1, 2, 3, 4, 5, 6, 7, 8]} ongoingOrCompleted={8} />
        <Container>
          <LeftSideBar>
            <Sidebar />
          </LeftSideBar>
          <RightSideBar>
            <Form colon={false} layout="vertical" requiredMark={false} onFinish={this.onFinish} preserve={false} initialValues={this.getInitialValues()} ref={this.formRef}>
              <Form.Item name="vendor_type" label={<LabelComponent text="Vendor Type" required />} rules={commonRules}>
                <Select>
                  <Select.Option value="title">Title </Select.Option>
                  <Select.Option value="escrow">Escrow </Select.Option>
                  <Select.Option value="title_and_escrow">Title and Escrow </Select.Option>
                  <Select.Option value="closing_attorney">Closing Attorney </Select.Option>
                </Select>
              </Form.Item>
              <FlexBox>
                <Form.Item name="vendor_name" label={<LabelComponent text="Company Name" required />} rules={commonRules}>
                  <Input />
                </Form.Item>
                {/* <Form.Item name="vendor_number" label={<LabelComponent text="Company Number" required />} rules={commonRules}>
                  <Input />
                </Form.Item> */}
              </FlexBox>
              <FlexBox>
                <Form.Item name="vendor_officer_first_name" label={<LabelComponent text="Officer First Name" required />} rules={commonRules}>
                  <Input />
                </Form.Item>
                <Form.Item name="vendor_officer_last_name" label={<LabelComponent text="Officer Last Name" required />} rules={commonRules}>
                  <Input />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Form.Item
                  name="vendor_email"
                  label={<LabelComponent text="Email" required />}
                  required
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Invalid Email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="vendor_phone" label={<LabelComponent text="Phone Number" required />} rules={commonRules} normalize={normalizePhone}>
                  <Input />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Form.Item name="vendor_address" label={<LabelComponent text="Company Address" required />} rules={commonRules}>
                  <Input />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Form.Item name="vendor_city" label={<LabelComponent text="City" required />} rules={commonRules}>
                  <GoogleAutoComplete
                    id="cities"
                    types={["(cities)"]}
                    name="cities"
                    onChange={(e) => { this.setLocaCity(e.target.value) }}
                    location={(loc) => { this.setCities(loc) }}
                    placeholder=""
                    styles={{
                      height: "49px",
                      borderRadius: "0px",
                      background: "white",
                      fontSize: "16px",
                      marginTop: "10px",
                    }}
                  />
                </Form.Item>

                <Form.Item name="vendor_state" label={<LabelComponent text="State" required />} rules={commonRules}>
                  {/* <Select>
                    {STATE_OPTIONS.map(st => (
                      <Select.Option value={st.value}>{st.label}</Select.Option>
                    ))}
                  </Select> */}
                  <Input />
                </Form.Item>

                <Form.Item
                  name="vendor_zipcode"
                  label={<LabelComponent text="Zip Code" required />}
                  rules={[
                    {
                      required: true,
                      message: "Invalid Input",
                      type: "regexp",
                      pattern: new RegExp(/\d+/g),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <FlexBox wd="100%">
                  <Form.Item>
                    {/* <Button
                      style={{
                        width: 119,
                        height: 48,
                        color: "#849DF8",
                        padding: 0,
                        marginLeft: "-10px",
                      }}
                      size="large"
                      type="link"
                      onClick={this.onSaveAsDraft}
                    >
                      Save for Later
                    </Button> */}
                  </Form.Item>
                </FlexBox>
                <FlexBox>
                  <Form.Item>
                    <Button
                      style={{
                        width: 119,
                        height: 48,
                        background: "white",
                        float: "right",
                        borderColor: "#3B60E4",
                        color: "#3B60E4",
                      }}
                      type="primary"
                      shape="round"
                      size="large"
                      // htmlType="submit"
                      onClick={() => { history.push("/broker/tc/add-listings/coversheet/sign-and-post-installation") }}
                    >
                      Back
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      style={{
                        width: 119,
                        height: 48,
                        background: "#3B60E4",
                        float: "right",
                        borderColor: "white",
                      }}
                      type="primary"
                      shape="round"
                      size="large"
                      htmlType="submit"
                    // onClick={() => { history.push("/broker/tc/add-listings/coversheet/tc-info") }}
                    >
                      Next
                    </Button>
                  </Form.Item>
                </FlexBox>
              </FlexBox>
            </Form>
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)
