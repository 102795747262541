import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/RecruitAndEarn"
import PendingInvites from "./PendingInvites"
import AcceptedInvites from "./AcceptedInvites"
import PendingEarnings from "./PendingEarnings"
import CreditedEarnings from "./CreditedEarnings"
import Select from 'react-select';

const Wrap = styled.div`
  padding: 70px 250px 0px 100px;
`

const SelectContainer = styled.div`
  width: 350px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => props.mt && `
    margin-top: ${props.mt}
  `}
`

const AgentData = styled.div`
  padding: 20px;
`

const AnalyticsContainer = styled.div`
  padding: 20px;
`

class RecruitAndEarn extends React.Component {
  state = {
    view: { value: "brokerage_pending_invites", label: "Brokerage Pending Invites" },
  }
  componentDidMount() {
    const {
      fetchAgentInviteAnalytics,
      fetchInvites
    } = this.props
    fetchInvites()
    fetchAgentInviteAnalytics({ agentId: this.props.match.params.agentId })
  }

  handleViewChange = (view) => {
    this.setState({
      view
    })
  }

  getFilteredData = (agentData, type) => {
    if(!agentData) return {
      accepted: [],
      pending: []
    }
    const data = {
      accepted: agentData.accepted && agentData.accepted.filter(dt => dt.sender_id === parseInt(this.props.match.params.agentId, 10)),
      pending: agentData.pending && agentData.pending.filter(dt => dt.sender_id === parseInt(this.props.match.params.agentId, 10)),
    }
    if (type === "brokerage_pending_invites" && data && data.pending) {
      return {
        accepted: data.accepted,
        pending: data.pending.filter(dt => dt.invite_type === "brokerage")
      }
    }
    if (type === "brokerage_joined_invites" && data && data.pending) {
      return {
        accepted: data.accepted.filter(dt => dt.invite_type === "brokerage"),
        pending: data.pending
      }
    }
    if (type === "community_pending_invites" && data && data.pending) {
      return {
        accepted: data.accepted,
        pending: data.pending.filter(dt => dt.invite_type === "community")
      }
    }
    if (type === "community_joined_invites" && data && data.pending) {
      return {
        accepted: data.accepted.filter(dt => dt.invite_type === "community"),
        pending: data.pending
      }
    }
  }

  render() {
    const {
      agentInviteAnalytics,
      getBrokerInvitesResponse
    } = this.props

    const {
      data,
    } = agentInviteAnalytics


    const {
      data: brokerInviteData,
      isFetching: isFetchingInvites,
    } = getBrokerInvitesResponse

    console.log('==data===', data)

    return (
      <Wrap>
        <Header>
          <h1>Agent Invite Analytics</h1>
        </Header>
        <AnalyticsContainer>
          <table>
            <tr>
              <th>Is RAR Agent</th>
              <th>Joined Brokerage</th>
              <th>Total Invited Brokerage</th>
              <th>Joined Community</th>
              <th>Total Invited Community</th>

              <th>Brokerage Referral Bonus</th>
              <th>K-Factor Brokerage</th>
              <th>K-Factor community</th>
            </tr>
            <tr>
              <td>
                {data && data.length && data[0].is_rar_agent ? 'Yes' : 'No'}
              </td>
              <td>
                {data && data.length && data[0].joined_brokerage}
              </td>
              <td>
                {data && data.length && data[0].total_invited_brokerage}
              </td>
              <td>
                {data && data.length && data[0].joined_community}
              </td>
              <td>
                {data && data.length && data[0].total_invited_community}
              </td>
              <td>
                {data && data.length && data[0].brokerage_referral_bonus}
              </td>
              <td>
                {data && data.length && data[0].k_factor_brokerage_invite}
              </td>
              <td>
                {data && data.length && data[0].k_factor_community_invite}
              </td>

            </tr>
          </table>
        </AnalyticsContainer>


        <Header mt="60px">
          <h1>Invite Dashboard</h1>

          <SelectContainer>
            <Select
              placeholder="Select Invite Type"
              options={[
                { value: "brokerage_pending_invites", label: "Brokerage Pending Invites" },
                { value: "brokerage_joined_invites", label: "Brokerage Joined Invites" },
                { value: "community_pending_invites", label: "Community Pending Invites" },
                { value: "community_joined_invites", label: "Community Joined Invites" },
              ]}
              value={this.state.view}
              onChange={e => this.handleViewChange(e)}
            />
          </SelectContainer>
        </Header>

        {isFetchingInvites ? (
          <p>Loading...</p>
        ) : (
          <AgentData>
            {this.state.view.value.includes('pending') ?
              <PendingInvites inviteList={this.getFilteredData(brokerInviteData, this.state.view.value)} /> :
              <AcceptedInvites inviteList={this.getFilteredData(brokerInviteData, this.state.view.value)} />}
          </AgentData>
        )}
      </Wrap>
    )
  }
}

export default Container(RecruitAndEarn)
