import React, {useState, useEffect} from "react"
import styled from "styled-components"
import colors from "lib/colors"
import Header from "dumbComponents/AIDashboard/Components/AIDocuments/Header.js"
import {
    Button, Modal, DatePicker,
    Form,
    Input,
    InputNumber,
    Mentions,
    Select,
    TreeSelect,
    Upload,
 } from "antd"
import root from "window-or-global"
import ResultTable from "./TabComponents/Table"

const Wrap = styled.div`
  padding: 20px 74px 20px 74px;

  @media(max-width: 1280px) {
    padding: 10px;
  }
`
const UploadAIDocuments = () => {
  return (
    <Wrap>
     <Header />
     <ResultTable />
  </Wrap>
  )
}


export default UploadAIDocuments