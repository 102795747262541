import React, { useEffect } from "react"
import EventRooms from "container/EventRooms"
import styled from "styled-components"
import {
  Header,
  Table,
  DownloadDrawer,
} from "./Components"

const Wrap = styled.div`
  padding-top: 80px;
  padding-left: 50px;
`

const RecordingDashboard = ({
  getRecordings,
  getRecordedContentResponse,
  toggleDownloadDrawer,
  downloadDrawerOpen,
  currentRecordings,
  deleteRecordings,
}) => {
  useEffect(() => {
    getRecordings({
      skip: 0,
      limit: 100,
    })
  }, [])

  const {
    data: recordedData,
    isFetching: fetchingData,
  } = getRecordedContentResponse || {}

  return (
    <Wrap>
      <Header />
      <Table
        recordings={recordedData}
        fetchingData={fetchingData}
        toggleDownloadDrawer={toggleDownloadDrawer}
        deleteRecordings={deleteRecordings}
      />
      {downloadDrawerOpen && (
        <DownloadDrawer
          toggleDownloadDrawer={toggleDownloadDrawer}
          downloadDrawerOpen={downloadDrawerOpen}
          recordings={currentRecordings}
        />
      )}
    </Wrap>
  )
}

export default EventRooms(RecordingDashboard)
