import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "./actions";

function mapStateToProps(state) {
  const { consumerApp } = state;

  return {
    ...consumerApp,
  };

}

const mapDispatchToProps = (dispatch) => ({

  ...bindActionCreators(
    {
      fetchWaitlistTable: Actions.fetchWaitlistAction.request,
      saveWaitlistStatus: Actions.saveWaitlistStatusAction.request,
      uploadAppAssets: Actions.uploadAppAssetsAction.request,
      updateAppCustomisations: Actions.updateAppCustomisationsAction.request,
      fetchAppCustomisations: Actions.fetchAppCustomisationsAction.request,
      selectedApp: Actions.selectedAppAction.call,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
