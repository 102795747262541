import React, { Component } from "react"
import styled from "styled-components"
import { reduxForm, Field } from "redux-form"
import Container from "container/Academy"
import Select from 'react-select';
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"

const required = value => value ? undefined : "* Required"

const EventForm = styled.form`
 label {
  font-family: "SF Pro Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #24292e;
  .required {
   color: red;
  }
 }
`
const UpperDiv = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
`

const LowerDiv = styled.div`
  width: 100%;
  height: 350px;
  padding: 50px;
  button {
   float: right;
   position: relative;
   top: 207px;
  }
`

const InfoDiv = styled.div`
  width: 250px;
  height: 350px;
  flex-grow: 3;
  padding: 46px;
`


const PhotoDiv = styled.div`
  height: 100%;
  max-width: 250px;
  flex-grow:2;
`

const ExamplePara = styled.p`
  font-size: 13px;
  font-style: italic;
`

const InputFields = styled(Field)`
  width: 100%;
  height: 39px;
  border-radius: 3px;
  border: solid 1px #d1d5da;
  background-color: #fafbfc;
  margin-bottom: 37px;
  padding-left: 9px;
`
class ThirdPartyLinkForm extends React.Component {

 componentDidMount() {
  const {
   eventDetail,
   isUpdateMode,
   initialize,
  } = this.props

  if(isUpdateMode
  && eventDetail
  && Object.keys(eventDetail).length > 0) {
    initialize({
     thirdPartyLink: eventDetail.third_party_event_link,
     zoomLink: eventDetail.zoom_link,
     adLink: eventDetail.community_ad_video_link,
    })
  }
 }

 render() {
  const {
    handleSubmit,
    eventDetail,
    isUpdateMode,
   } = this.props

  return (
    <EventForm id='event-form' onSubmit={handleSubmit}>
      <div className="eventname">
        <label htmlFor="Third Party Event Link">
         Third Party Event Link (Provide link if the event is being handled by a third party)
        </label>
        <InputFields name="thirdPartyLink" component="input" type="text" />
      </div>
      <div className="eventname">
        <label htmlFor="Zoom Link">
         Zoom Link
        </label>
        <InputFields name="zoomLink" component="input" type="text" />
      </div>
      <div className="youtubeVimeoLink">
        <label htmlFor="Ad Link">
         Advertisement Link (Youtube Preferred) [Can be added after publishing the event]
        </label>
        <ul>
          <li>
            <ExamplePara>Youtube Links must be of this format - "https://www.youtube.com/embed/QrwGISxc9VU"</ExamplePara>
          </li>
          <li>
            <ExamplePara>In order to get this link, go to the video link and click Share button</ExamplePara>
          </li>
          <li>
            <ExamplePara>Click on Embed</ExamplePara>
          </li>
          <li>
            <ExamplePara>On the right side, there would be an iFrame code</ExamplePara>
          </li>
          <li>
            <ExamplePara>Just copy the "src" key</ExamplePara>
          </li>
        </ul>
        <InputFields name="adLink" component="input" type="text" />
      </div>
    </EventForm>
  )
 }
}

ThirdPartyLinkForm = reduxForm({
  form: 'thirdPartyLinkForm',
  destroyOnUnmount: false,
})(ThirdPartyLinkForm)

export default ThirdPartyLinkForm
