import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/ProductInterest"
import ImageUploader from 'react-images-upload';

const Wrap = styled.div`
  height: 20%;
  width: 100%;
  .fileUploader {
    width: 49%;
    position: relative;
    left: 46px;
  }

  .uploadPicturesWrapper {
    position: absolute;
    left: 94%;
  }
`
const CTAWrap = styled.div`
  width: 100%;
  height: 50px;
  padding: 10px 20px 0px 0px;

  button {
    float: right;
    cursor: pointer;
    background: none;
    border: none;
    text-decoration: underline;
    color: #0882e4;
  }
`


class ImageUpload extends Component {
  state = {
    pictures: [],
  }
  componentDidMount(){
    // apiCalls
  }

  onDrop = (picture, file) => {
     this.setState({
         pictures: this.state.pictures.concat(picture),
     });
  }

  initiateImageUpload = (id) => {
   const {
    pictures,
   } = this.state

   const {
    setFeatureImage,
   } = this.props

   const file = pictures[0]

   setFeatureImage({
    file,
   })

  }


  render(){
    const {
      imageUrl,
    } = this.props

    return (
      <Wrap>
        <CTAWrap>
         <button onClick={() => this.initiateImageUpload()}>Upload</button>
        </CTAWrap>
        <ImageUploader
            withIcon={true}
            buttonText='Choose images'
            onChange={this.onDrop}
            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
            maxFileSize={5242880}
            withPreview={true}
            singleImage={true}
            withLabel={true}
         />
      </Wrap>
    )
  }
}


export default Container(ImageUpload)
