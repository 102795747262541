import React from "react"
import styled from "styled-components"
import { ToastContainer, toast } from "react-toastify"
import copy from "copy-to-clipboard"
import pretty from "pretty"
import "react-toastify/dist/ReactToastify.css"
import { getHtmlPage } from "./Util"
import Sidebar from "./Sidebar"
import CodeEditor from "./CodeEditor"
import RenderComp from "./RenderedComp"

const Wrap = styled.div`
  display: flex;
  margin-left: 40px;
  padding-top: 60px;

`

const Title = styled.div`
  padding: 2px;
  p {
    font-size: 14px;
    padding: 0px;
    margin: 0px;
  }
`

class EmailsGen extends React.Component {
  state = {
    components: [],
    editedCode: null,
    codeForRender: null,
    isSendEmailModalOpen: false,
  }

  insertCode = (item) => {
    const { components } = this.state
    const newComponents = components.concat(item)
    this.setState({
      components: newComponents,
      isNewComp: true,
    })
  }

  toggleEmailModal = (isShow) => {
    this.setState({
      isSendEmailModalOpen: isShow,
    })
  }

  handleEditCode = (code) => {
    this.setState({
      editedCode: code,
      isEdited: true,
      isNewComp: false,
      components: [],
    })
  }

  getCode = () => {
    const $ta = document.getElementById("emailCodeEditor")
    const { value } = $ta
    const fullPage = getHtmlPage(value)
    let newDemo = window.open()
    newDemo = newDemo.document.write(fullPage)
  }

  handleExport = () => {
    const $ta = document.getElementById("emailCodeEditor")
    const { value } = $ta
    const fullPage = pretty(getHtmlPage(value), { ocd: true })
    copy(fullPage)
    toast.success("Code Copied to clipboard, Enjoy!")
  }

  handleEmailSend = () => {
    // const $ta = document.getElementById("emailCodeEditor")
    // const { value } = $ta
    // const fullPage = pretty(getHtmlPage(value), { ocd: true })

  }

  render() {
    const {
      components,
      editedCode,
      isEdited,
      isNewComp,
      isSendEmailModalOpen,
      //codeForRender,
    } = this.state
    return (
      <div className="email-root">
        {/* <Title>
          <p>Radius Email Generator Tool</p>
        </Title> */}
        <Sidebar
          injectCode={this.insertCode}
          handleReset={this.handleReset}
          handleExport={this.handleExport}
          handleOpenNewTab={this.getCode}
          isSendEmailModalOpen={isSendEmailModalOpen}
          toggleEmailModal={this.toggleEmailModal}
        />
        <Wrap>
          <CodeEditor
            components={components}
            editedCode={editedCode}
            updateRender={this.handleEditCode}
            isEdited={isEdited}
            isNewComp={isNewComp}
          />
          <RenderComp
            components={components}
            editedCode={editedCode}
          />
        </Wrap>
        <ToastContainer />
      </div>
    )
  }
}

export default EmailsGen
