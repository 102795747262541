import React, { PureComponent } from "react"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import { Tabs, Table, Select, Button, Input, Spin } from "antd"
import Container from "container/EventRooms"
import TeamContainer from "container/BrokerDashboard/teamContainer"
import moment from "moment"
const { TabPane } = Tabs

const { Option } = Select

const Wrap = styled.div`
  margin: 0px 30px;
`

const PageTitle = styled.h2`
  margin: 40px 0px;
`

const Card = styled.div`
  padding: 24px;
  gap: 10px;
  width: 398px;
  height: 93.55px;
  background: #F9F9F7;
`

const Header = styled.h1`
  font-size: 24px;
  margin: 20px 0px;
  ${props => props.fs && `
    font-size: ${props.fs};
  `}
`

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 30px 0 50px;
  border-radius: 8px;
  > div {
    display: flex;
    align-items: end;
  }
`

const FilterContainer = styled.div`
  margin-right: 5px;
`

const ButtonWrap = styled.div`
  margin-left: 20px;
  display: flex;
  > button {
    margin-right: 10px;
  }
`

const Label = styled.h3`
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 5px 0;
`

const Address = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  span {
    font-weight: 500;
    background: #f0f0f0;
    padding: 4px 10px;
    margin: 3px;
    font-size: 12px;
    border-radius: 50px;
    text-align: center;
  }
`

class RARTeamJobs extends PureComponent {
  state = {
    applicantSearch: "",
    jobId: null,
  }

  componentDidMount() {
    const { getAllJobsByTeamId, getJobApplications, getRARTeamList, match } = this.props
    getAllJobsByTeamId({
      skip: 0,
      limit: 10,
    })
    getJobApplications({
      skip: 0,
      limit: 10,
    })
    getRARTeamList()
    const { jobId } = match.params
    this.setState({ jobId })
    getJobApplications({
      skip: 0,
      limit: 10,
      jobId,
    })
    // eslint-disable-next-line no-unused-expressions
    import("../antd.css")
  }

  handleApplicantNameFilter = (e) => {
    this.setState({
      applicantSearch: e.target.value,
    })
  }

  searchApplications = (skip, limit) => {
    const { getJobApplications } = this.props
    const { applicantSearch, jobId } = this.state
    getJobApplications({
      skip,
      limit,
      jobId,
      search: applicantSearch,
    })
  }

  applicantPagination = (page) => {
    this.searchApplications(((page.current - 1) * page.pageSize), page.pageSize)
  }

  handleChange = (key, value, record) => {
    // eslint-disable-next-line no-param-reassign
    record.status = key
    const { updateJobApplications } = this.props
    updateJobApplications({
      applicantId: record.application_id,
      body: {
        status: value.value,
      },
    })
    console.log(`selected ${key}`, value, record)
  }

  applicantColumns = [
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "10%",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      width: "10%",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      width: "10%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "10%",
    },
    {
      title: "Applied On",
      dataIndex: "created_at",
      key: "created_at",
      width: "10%",
      render: (text, record, index) => (
        <span>{moment(record.created_at).format("YYYY-MM-DD")}</span>
      ),
    },
    // {
    //   title: "Location",
    //   dataIndex: "locations",
    //   key: "locations",
    //   width: "10%",
    //   render: (data) => {
    //     console.log("===data====?>>>>>", data)
    //     return (
    //       <Address>
    //         {data && data.map((lc, index) => (
    //           <span>{lc && lc.location}</span>
    //         ))}
    //       </Address>
    //     )
    //   },
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (text, record, index) => (
        <Select
          key={index}
          defaultValue={record.status}
          style={{ width: 140 }}
          onChange={(key, value) => this.handleChange(key, value, record)}
        >
          <Option value="in_review">In Review</Option>
          <Option value="verified">Verified</Option>
          <Option value="hired">Hired</Option>
          <Option value="declined">Declined</Option>
        </Select>
      ),
    },
  ]

  tabChange = () => {

  }

  getTotal = (arr) => {
    return arr && arr.total || 0
  }

  render() {
    const { allJobsByTeamId, allJobApplications, rarTeamListResponse } = this.props
    console.log("==allJobsByTeamId==allJobApplications>", allJobsByTeamId, allJobApplications)
    console.log("==rarTeamListResponse===000rarTeamListResponse00", rarTeamListResponse)
    return (
      <Wrap>
        <PageTitle>
          {/* Team Jobs */}
        </PageTitle>
        <Tabs onChange={this.tabChange} size="large" animated>
          <TabPane tab="Application List" key="applications">
            <FilterWrapper>
              <Header>
                Application List
              </Header>
              <div>
                <FilterContainer>
                  <Label>Search</Label>
                  <Input style={{ width: 250 }} size="large" placeholder="firstname, email, position" onChange={this.handleApplicantNameFilter} onPressEnter={() => this.searchApplications(0, 5)} />
                </FilterContainer>
                <ButtonWrap>
                  <Button type="primary" size="large" onClick={this.searchApplications} shape="round">
                    Search Applications
                  </Button>
                </ButtonWrap>
              </div>
            </FilterWrapper>
            <Table
              scroll={{ x: 540 }}
              columns={this.applicantColumns}
              dataSource={allJobApplications && allJobApplications.data && allJobApplications.data.applicants}
              pagination={{ total: this.getTotal(allJobApplications.data), defaultPageSize: 5 }}
              onChange={this.applicantPagination}
              loading={{ indicator: <div>{<Spin />}</div>, spinning: !!allJobApplications.isFetching }}
            />
          </TabPane>
        </Tabs>
      </Wrap>
    )
  }
}
export default TeamContainer(Container(withRouter(RARTeamJobs)))
