import React from "react"
import { Table, Tag, Pagination } from "antd"
import styled from "styled-components"
import { RightOutlined, UpOutlined } from "@ant-design/icons"
import Expandable from "./Expandable"
import { detailsSerializer, handleDrawerActions } from "../../Common/Utils"
import RecruitAndEarn from "../../../../container/RecruitAndEarn"

const Button = styled.button`
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0px;
`
const ResultsFor = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  -webkit-letter-spacing: 0em;
  -moz-letter-spacing: 0em;
  -ms-letter-spacing: 0em;
  letter-spacing: 0em;
  text-align: left;
  font-style: italic;
  color: #2d2d2d;
`

const Wrap = styled.div`
  padding-top: 17px;
  .infoTable {
    th, td {
      border: none;
      font-family: "Rubik", sans-serif;
    }

    th {
      padding: 5px 5px 5px 18px;

    }

    td {
      padding: 10px 5px 10px 18px;
    }

    [class*="expanded"] td{
      padding: 0px;
    }

    .ant-table-thead tr th {
      background: linear-gradient(0deg, #E5E5E5, #E5E5E5),
      linear-gradient(0deg, #E6E6E6, #E6E6E6);
    }
  }
`

const ResultsTable = (props) => {
  const {
    fetchingAgentDetails,
    agentDetailsList,
  } = props || {}

  const createColumn = () => {
    const columns = [
      {
        title: "Agent Name",
        dataIndex: "agentName",
        key: "firstName",
      },
      {
        title: "Email ID",
        dataIndex: "emailID",
        key: "email",
      },
      {
        title: "Phone Number",
        dataIndex: "phoneNumber",
        key: "phone",
      },
      {
        title: "Total Recruited",
        dataIndex: "total_recruited",
        key: "total_recruited",
        sorter: (a, b) => a.total_recruited - b.total_recruited,
      },
      {
        title: "Total Referred",
        dataIndex: "total_referred",
        key: "total_referred",
        sorter: (a, b) => a.total_referred - b.total_referred,
      },
      {
        title: "Pending Stocks",
        dataIndex: "pending_stocks",
        key: "pending_stocks",
        sorter: (a, b) => a.pending_stocks - b.pending_stocks,
      },
      {
        title: "Earned Stocks",
        dataIndex: "earned_stocks",
        key: "earned_stocks",
        sorter: (a, b) => a.earned_stocks - b.earned_stocks,
      },
    ]

    return columns
  }

  const serialized = !fetchingAgentDetails && agentDetailsList
  && detailsSerializer(agentDetailsList) || []

  return (
    <Wrap>
      <Table
        className="infoTable"
        columns={createColumn()}
        dataSource={serialized}
        loading={fetchingAgentDetails}
      >
        <Pagination
          total={serialized.length}
          defaultPageSize={50}
          defaultCurrent={1}
        />
      </Table>
    </Wrap>
  )
}

export default RecruitAndEarn(ResultsTable)
