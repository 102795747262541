import React, { useState } from "react"
import styled from "styled-components"
import root from "window-or-global"
import { Checkbox } from 'antd';

const StyledWrap = styled.div``

const StyledNote = styled.p``

const Label = styled.label``

const DangerNote = styled.p`
  color: red;
  margin-top: 5px;
`

const ConfirmationNote = ({
  text,
  updateNote,
  value,
  deleteCheckBox,
  allInstanceBool,
  updateAllInstance,
}) => {

  const [isChecked, setChecked] = useState(false)

  const handleChange = (e) => {
    const checkBool = e.target.checked
    setChecked(checkBool)
    root.localStorage.setItem("deleteAllOccurences", checkBool)
  }
  return (
    <StyledWrap>
      <StyledNote>
        {text}
      </StyledNote>
      {deleteCheckBox && (
        <>
          <Checkbox checked={isChecked} onChange={handleChange}>Delete all occurences</Checkbox>
          {isChecked && (
            <DangerNote>All the scheduled rooms would be deleted!</DangerNote>
          )}
        </>
      )}
    </StyledWrap>
  )
}

export default ConfirmationNote
