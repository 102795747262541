import React, { Component } from "react"
import styled from "styled-components"
import AcademyHeader from "./Header"
import Courses from "./Course/Courses"
import AddCourse from "./Course/AddCourse"
import CourseSettings from "./Course/CourseSettings"
import People from "./Instructors/People"
import Events from "./Events/Events"
import Categories from "./Category/Category"
import AddCategory from "./Category/AddCategory"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../lib/colors"
import root from "window-or-global"
import AddInstructor from "./Instructors/AddInstructor"
import Lessons from "./Lesson/Lessons"
import NotesAndResources from "./Lesson/NotesForm"
import ContentForm from "./Lesson/ContentForm"
import AboutLesson from "./Lesson/AboutLessonForm"
import AddEvent from "./Events/AddEvent"
import PeopleSettings from "./Instructors/PeopleSettings"
import LessonSettings from "./Lesson/LessonSettings"
import EventSettings from "./Events/EventSettings"
import AllSettings from "./Events/AllSettings"

const Tabs = styled.ul`
  display: flex;
  padding: 0px;
  border-bottom: ${colors.tabDisabledColor} solid 1px;
  position: relative;
  list-style: none;
`

const Tab = styled.li`
  pointer-events: none;
  padding: 10px 25px;
  position: relative;
  top: 3px;
  a {
    pointer-events: all;
    text-decoration: none;
    font-weight: 500;
    color: ${colors.tabDisabledColor};
    &:hover {
      color: ${colors.primaryColor};
    }
  }
  ${props => props.isActive && `
      border-bottom: ${colors.primaryColor} solid 4px;
      a {
        color: ${colors.primaryColor};
      }
    `}
`

const ContainerWrap = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  width: 100%;
  font-family: "SF Pro Display", sans-serif;
`

const InnerWraps = styled.div`
  height: 66px;
  background-color: white;
  color: black;

  ${props => props.header && `
     h2 {
       font-size: 24px;
       color: #282b2f;
     }
   `}
`

const Wrap = styled.div`
  padding: 0px 250px 0px 250px;
`

class AcademyIndex extends Component{
 componentDidMount() {
   const {
    fetchCourses,
    fetchEvents,
   } = this.props
   fetchCourses()
   fetchEvents({
    filter: "upcoming",
   })
 }

 loadCourses = () => {
  const {
   fetchCourses,
   clearFormsEdits,
  } = this.props

  clearFormsEdits()
  fetchCourses()
 }

 handleClick = () => {
  const {
   clearFormsEdits,
   clearEventAboutForm,
  } = this.props

  clearFormsEdits()
 }

 handleClearEventForm = () => {
   const {
     clearEventAboutForm,
   } = this.props

   clearEventAboutForm()
 }

 render() {
  const currentPath = root.location.pathname
   return (
    <ContainerWrap>
     <Wrap>
      <InnerWraps header>
        <h2>Academy</h2>
      </InnerWraps>
      <InnerWraps>
       <Tabs>
         <Tab
          onClick={() => this.loadCourses()}
          isActive={
           currentPath.indexOf("courses") > -1
           || (
            currentPath.indexOf("events") <= -1
            && currentPath.indexOf("people") <= -1
            && currentPath.indexOf("categories") <= -1
           )}
         >
           <Link to="/academy-admin/courses">Courses</Link>
         </Tab>
         <Tab
           isActive={currentPath.indexOf("events") > -1}
         >
           <Link to="/academy-admin/events">Events</Link>
         </Tab>
         <Tab
          isActive={currentPath.indexOf("people") > -1}
         >
           <Link to="/academy-admin/people">People</Link>
         </Tab>
         <Tab
          isActive={currentPath.indexOf("categories") > -1}
         >
           <Link to="/academy-admin/categories">Category</Link>
         </Tab>
        </Tabs>
        <Switch>
          //COURSES ROUTE
          <Route exact path="/academy-admin" component={Courses} />
          <Route exact path="/academy-admin/courses" component={Courses} />
          <Route exact path="/academy-admin/courses/add-new-course" component={AddCourse} />
          <Route exact path="/academy-admin/courses/course/:id" component={AddCourse} />
          <Route exact path="/academy-admin/courses/course/:id/settings" component={CourseSettings} />


          //EVENTS ROUTE
          <Route exact path="/academy-admin/events" component={Events} />
          <Route exact path="/academy-admin/events/add-new-event" component={AddEvent} />
          <Route exact path="/academy-admin/events/add-new-event/settings" component={EventSettings} />
          <Route exact path="/academy-admin/events/all-settings" component={AllSettings} />
          <Route exact path="/academy-admin/events/event/:id" component={AddEvent} />
          <Route exact path="/academy-admin/events/event/:id/settings" component={EventSettings} />



          //INSTRUCTORS ROUTE
          <Route exact path="/academy-admin/people" component={People} />
          <Route exact path="/academy-admin/people/add-new-instructor" component={AddInstructor} />
          <Route exact path="/academy-admin/people/:id" component={AddInstructor} />
          <Route exact path="/academy-admin/people/:id/settings" component={PeopleSettings} />


          //LESSONS ROUTE
          <Route exact path="/academy-admin/courses/add-new-course/lesson" component={Lessons} />
          <Route exact path="/academy-admin/courses/course/:id/lessons" component={Lessons} />
          <Route exact path="/academy-admin/courses/course/:id/lesson/:lid/about" component={AboutLesson} />
          <Route exact path="/academy-admin/courses/course/:id/lesson/:lid/content" component={ContentForm} />
          <Route exact path="/academy-admin/courses/course/:id/lesson/:lid/notes-resources" component={NotesAndResources} />
          <Route exact path="/academy-admin/courses/course/:id/lesson/:lid/settings" component={LessonSettings} />
          <Route exact path="/academy-admin/courses/add-new-course/lesson/create-lesson/about" component={AboutLesson} />
          <Route exact path="/academy-admin/courses/course/:id/lessons/add-lesson/about" component={AboutLesson} />
          <Route exact path="/academy-admin/courses/add-new-course/lesson/create-lesson/content" component={ContentForm} />
          <Route exact path="/academy-admin/courses/course/:id/lessons/add-lesson/content" component={ContentForm} />
          <Route exact path="/academy-admin/courses/add-new-course/lesson/create-lesson/notes-resources" component={NotesAndResources} />
          <Route exact path="/academy-admin/courses/course/:id/lessons/add-lesson/notes-resources" component={NotesAndResources} />

          //CATEGORY ROUTE
          <Route exact path="/academy-admin/categories" component={Categories} />
          <Route exact path="/academy-admin/categories/add-new-category" component={AddCategory} />
          <Route exact path="/academy-admin/categories/category/:id" component={AddCategory} />
        </Switch>
      </InnerWraps>
     </Wrap>
    </ContainerWrap>
   )
 }
}

export default Container(AcademyIndex)
