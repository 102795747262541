import React from "react"
import {
  StatusWrap,
  StatusText,
  StatusImage,
} from "./statusCardStyle"

const StatusCard = ({
  text,
  icon,
  bgColor,
}) => (
  <StatusWrap
    bgColor={bgColor}
  >
    <StatusImage>
      <img
        src={icon}
        alt="status_icon"
      />
    </StatusImage>
    <StatusText>
      <p>{text || "Resolved"}</p>
    </StatusText>
  </StatusWrap>
)

export default StatusCard
