import React, { useState } from "react"
import { get } from "lodash"
import {
  GoogleLocationWrap,
} from "./googleLocationSearchStyles"
import GoogleLocation from "../../../../../../components/GoogleAutoComplete"

const GoogleLocationInput = ({
  setCity,
  city,
  disabled,
}) => (
  <GoogleLocationWrap>
    <GoogleLocation
      type="cities"
      disabled={disabled}
      value={city}
      placeholder={city || "City"}
      placeholderColor="#bfbfbf"
      location={(location) => {
        const selectedCity = {
          city: get(location, "locality.long_name"),
          state: get(location, "administrative_area_level_1.long_name"),
          country: get(location, "country.long_name"),
          latitude: get(location, "lat"),
          longitude: get(location, "lng"),
        }
    
        setCity(selectedCity)
      }}
    />
    {/* {errorLocation && noLocationError && (
      <ErrorText
        style={{
          marginTop: "-15px",
        }}
      >
        {errorLocation}
      </ErrorText>
    )} */}
  </GoogleLocationWrap>
)

export default GoogleLocationInput
