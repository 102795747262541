import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import CheckList from "container/CheckList/index";
import { TitleColumn, StatusColumn } from "./helper";
import "./styles.css";

const DragOrderTableModal = ({
  isDragOrderTableModalOpen,
  setIsDragOrderTableModalOpen,
  fetchAllTemplatesResponse,
  updateOrderingTemplates,
  selectedSection,
  fetchAllTemplates,
  dropdownDataArray,
}) => {
  const [modalData, setModalData] = useState([]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(modalData, oldIndex, newIndex);
      setModalData(newData);
    }
  };

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));

  useEffect(() => {
    const filteredData = fetchAllTemplatesResponse.data.filter(
      (e) => e.checklist === selectedSection.checklist_value
    );
    const sortedData = filteredData.sort((a, b) => a.ordering - b.ordering);
    const flattenedData = sortedData.flatMap((e) =>
      e.document_items.map((item, index) => ({ ...item, index }))
    );
    setModalData(flattenedData);
  }, [fetchAllTemplatesResponse, selectedSection]);

  const SortableItem = SortableElement((props) => (
    <tr
      {...props}
      style={{ cursor: "move", zIndex: "99999", position: "relative" }}
    />
  ));

  const SortableBody = SortableContainer((props) => <tbody {...props} />);

  const columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    TitleColumn,
  ];

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = modalData.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const handleSave = () => {
    const payload = [];
    modalData.forEach((e, index) => {
      const item = {
        documentItemId: e.document_item_id,
        ordering: index + 1,
      };
      payload.push(item);
    });
    const newPayload = { payload: payload };
    cancelPlanAPI(newPayload);
  };

  const cancelPlanAPI = (payload) => {
    if (payload) {
      updateOrderingTemplates({
        payload,
        callback: () => {
          const payload = {
            state: dropdownDataArray.state,
            type: dropdownDataArray.type,
            checklistTemplate:
              dropdownDataArray.checklistTemplate.checklist_template_value,
          };
          fetchAllTemplates(payload);
          setIsDragOrderTableModalOpen(false);
        },
      });
    }
  };
  const onCancel = () => {
    setIsDragOrderTableModalOpen(false);
  };

  return (
    <Modal
      title="Rearrange Table"
      visible={isDragOrderTableModalOpen}
      onCancel={onCancel}
      footer={[
        <Button
          className={`outline cancel-btn-custom`}
          key="cancel"
          onClick={onCancel}
        >
          Cancel
        </Button>,
        <button
          className={`add-due-btn add-due-btn-color ${
            !modalData || modalData.length === 0 ? "disabled-btn" : ""
          }`}
          key="save"
          type="primary"
          onClick={handleSave}
          disabled={!modalData || modalData.length === 0}
        >
          Save
        </button>,
      ]}
    >
      <Table
        pagination={false}
        dataSource={modalData}
        columns={columns}
        rowClassName="editable-row"
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        className={isDragOrderTableModalOpen ? "dragging-table" : ""}
      />
    </Modal>
  );
};

export default CheckList(DragOrderTableModal);
