import React, { useEffect } from "react"
import styled from "styled-components"
import aiContainer from "container/AI"
import root from "window-or-global"
import { get } from "lodash"
import queryString from "query-string"

import {
  message,
} from "antd"
import {
  HeaderSection,
  FilterSection,
  StatsSection,
  ChatSection,
  PubnubForAIDashboard,
} from "./Components"
import {
  getIsAIEnabledStatus,
  openNotification,
} from "./commonUtils"

const OuterWrap = styled.div`
  padding: 40px;
`

//https://stagingblink.radiusagents.com/broker/ai?agent_id=11026523&session_id=276

const AiDashboard = ({
  fetchTeams,
  fetchAiChatSession,
  fetchAIAnalytics,
  fetchSessionHistory,
  currentAgentIdForPubnub,
  actionIdFromPubnub,
  chatObject,
  currentSession,
  updateCurrentChatView,
  currentSessionId,
  currentTopics,
  newAgentInQueueForPubNub,
  query,
  constructQuery,
}) => {
  useEffect(() => {
    const search = get(root, "location.search", {})
    const parsedQuery = queryString.parse(search)
    const {
      agent_id,
      session_id,
    } = parsedQuery || {}

    let newQuery = ""
    const queryPayload = {}

    //fetch teams
    fetchTeams({
      skip: 0,
      limit: 30,
    })

    if (agent_id) {
      newQuery += `agent_id=${agent_id}`
      queryPayload.agent_id = agent_id
      constructQuery({
        constructed: { ...query, ...queryPayload },
        session_id,
      })
    } else {
      // //fetch stats
      fetchAIAnalytics({
        query: newQuery,
      })

      //fetch chats with no query or with url parsed query
      fetchAiChatSession({
        query: newQuery,
      })
    }
    //fetch session history
  }, [])

  useEffect(() => {
    if (actionIdFromPubnub && chatObject) {
      if (currentSessionId === chatObject.sessionId) {
        const newChats = [...currentSession, chatObject]

        if (chatObject.role !== "radius_team") {
          updateCurrentChatView({
            chats: newChats,
          })
        }

        const isAiEnabled = getIsAIEnabledStatus(currentTopics, currentSessionId)

        if (isAiEnabled) {
          fetchSessionHistory({
            limit: 1,
            offset: "",
            sessionId: chatObject.sessionId,
            agentId: currentAgentIdForPubnub,
            existingChats: newChats,
          })
        }
      } else {
        message.info("There might be new messages")
      }
    }
  }, [actionIdFromPubnub, chatObject])

  useEffect(() => {
    if (newAgentInQueueForPubNub) {
      openNotification(newAgentInQueueForPubNub, query, constructQuery)
    }
  }, [newAgentInQueueForPubNub])

  return (
    <OuterWrap>
      <HeaderSection />
      <FilterSection />
      <StatsSection />
      <ChatSection />
    </OuterWrap>
  )
}

export default aiContainer(AiDashboard)

