import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "EVENT_ROOMS"

//export const getCoursesAction = createSignalAction(BASE, "FETCH_COURSES")
export const getRooms = createSignalAction(BASE, "FETCH_ROOMS")

export const getScheduledRoomsAction = createSignalAction(BASE, "FETCH_SCHEDULED_ROOMS")

export const createRooms = createSignalAction(BASE, "CREATE_ROOMS")

export const updateRoom = createSignalAction(BASE, "UPDATE_ROOM")

export const endRoom = createSignalAction(BASE, "END_ROOM")

export const getFinancingClientList = createSignalAction(BASE, "GET_FINANCING_CLIENT_LIST")

export const getFinancingSetting = createSignalAction(BASE, "RAR_FINANCING_SETTING")

export const saveFinancingSetting = createSignalAction(BASE, "SAVE_RAR_FINANCING_SETTING")

export const updateFinancingClientList = createSignalAction(BASE, "UPDATE_FINANCING_CLIENT_LIST")

export const deleteClient = createSignalAction(BASE, "DELETE_CLIENT")

export const getAllJobsByTeamId = createSignalAction(BASE, "GET_ALL_JOBS_BY_TEAM_ID")

export const getJobApplications = createSignalAction(BASE, "GET_JOB_APPLICATIONS")

export const updateJobApplications = createSignalAction(BASE, "UPDATE_JOB_APPLICATIONS")

export const createJobs = createSignalAction(BASE, "CREATE_JOBS")

export const updateJobs = createSignalAction(BASE, "UPDATE_JOBS")

export const deleteJobs = createSignalAction(BASE, "DELETE_JOBS")

export const showJobConfirmationAction = createSimpleCreator(BASE, "SHOW_JOB_CONFIRMATION")

export const uploadImagesToS3Action = createSignalAction(BASE, "UPLOAD_TEAM_IMAGE_TO_S3")

export const setImageBlocksAction = createSimpleCreator(BASE, "SET_IMAGE_BLOCK")

export const setCurrentImageToBeCroppedAction = createSimpleCreator(BASE, "IMAGE_TO_BE_CROPPED")

export const toggleDeleteModeAction = createSimpleCreator(BASE, "TOGGLE_DELETE_MODE")

export const getCashbackList = createSignalAction(BASE, "GET_CASHBACK_LIST")

export const getCashbackAgentInfo = createSignalAction(BASE, "GET_CASHBACK_AGENT_INFO")

export const updateCashbackAgentInfo = createSignalAction(BASE, "UPDATE_CASHBACK_AGENT_INFO")

export const createCashbackAmountCredits = createSignalAction(BASE, "CREATE_CASHBACK_AGENT_CREDITS")

export const getCashbackAgentTimeline = createSignalAction(BASE, "GET_CASHBACK_TIMELINE_INFO")

export const sendJobNotifications = createSignalAction(BASE, "SEND_JOB_NOTIFICATIONS")

export const getOnboardingCommission = createSignalAction(BASE, "GET_ONBOARDING_COMMISSION")
export const createOnboardingCommission = createSignalAction(BASE, "CREATE_ONBOARDING_COMMISSION")
export const updateOnboardingCommission = createSignalAction(BASE, "UPDATENBOARDING_COMMISSION")

export const getRecordedRoomsContentAction = createSignalAction(BASE, "GET_RECORDED_ROOMS")
export const toggleDownloadDrawerAction = createSimpleCreator(BASE, "TOGGLE_DRAWER_ACTION")
export const deleteRecordingsAction = createSignalAction(BASE, "DELETE_RECORDING")

export const toggleScheduledRoomDrawerAction = createSimpleCreator(BASE, "TOGGLE_SCHEDULED_ROOMS_DRAWER")
export const updateScheduledRoomsAction = createSignalAction(BASE, "UPDATE_SCHEDULED_ROOMS")
export const deleteScheduledRoomsAction = createSignalAction(BASE, "DELETE_SCHEDULED_ROOMS")
export const deleteAllOccurencesCheckAction = createSimpleCreator(BASE, "DELETE_ALL_OCCURENCES_CHECK")
