import React, { Component } from "react"
import styled from "styled-components"
import { reduxForm, Field } from "redux-form"
import Container from "container/Academy"
import Select from 'react-select';
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"
import AddLessonMenu from "./AddLessonMenu"
import RTFEditor from "dumbComponents/Academy/Common/RTF"
import RTFEditorIO from "dumbComponents/Academy/Common/EditorRTF"
import Text from "./forms/Text"
import Video from "./forms/Video"


const OuterWrap = styled.div`
 display: flex;
 .lesson-content-form{
   width: 500px;
 }
 iframe {
  margin-top: 93px;
 }
 .forms {
  margin-top: 10px;
 }
`
const LessonsForm = styled.form`
 display: flex;
 flex-direction: column;
 margin-bottom: -100px;
 label {
  font-family: "SF Pro Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #24292e;
 }
`
const UpperDiv = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
`

const LowerDiv = styled.div`
  width: 100%;
  height: 350px;
  padding: 50px;
  button {
   float: right;
  }
`

const InfoDiv = styled.div`
  width: 250px;
  height: 350px;
  flex-grow: 3;
  .fields {
   margin-bottom: 30px;
   label {
    padding-bottom: 10px;
   }
  }
  .info {
   font-size: 12px !important;
   margin: 0;
   color: #586069;
  }
`

const InputFields = styled(Field)`
  width: 100%;
  height: 39px;
  border-radius: 3px;
  border: solid 1px #d1d5da;
  background-color: #fafbfc;
  margin-top: 10px;
  padding-left: 9px;
  ${props => props.custom && `
     width: 90%;
   `}
`

class LessonContent extends Component {
 componentDidMount(){
  const {
   setLessonType,
   courseDetailResponse,
   getCourseDetails,
  } = this.props

  if (this.props.match.params.id && this.props.match.params.lid) {
   getCourseDetails({ id: this.props.match.params.id})
  }

  const {
   data: course,
  } = courseDetailResponse

  const courseExists =
  this.props.match.params.lid
  && this.props.match.params.id
  && course
  && Object.keys(course).length > 0
  && course.Lessons

  const current = courseExists
  && course.Lessons.filter(lesson => lesson.id === this.props.match.params.lid)[0].type
  if(current === "text"){
   setLessonType({
    type: [{
     value: "text",
     label: "Notes",
    }],
   })
  } else if(current === "video"){
   setLessonType({
    type: [{
     value: "video",
     label: "Video",
    }],
   })
  }
 }

 handleLessonType = (val) => {
  const {
   setLessonType,
  } = this.props

  setLessonType({
   type: [{
    value: val.value,
    label: val.label,
   }],
  })
 }

 render() {
  const {
   currentLessonType,
   courseDetailResponse,
   lessonNotes,
  } = this.props

  const {
   data: course,
   isFetching,
  } = courseDetailResponse

  const courseExists =
  this.props.match.params.lid
  && this.props.match.params.id
  && course
  && Object.keys(course).length > 0
  && course.Lessons

  const videoUrl = courseExists
  && course.Lessons
  && course.Lessons.filter(lesson => lesson.id === this.props.match.params.lid)[0].video_url || ""

  return (
   <React.Fragment>
    {isFetching ? (
     <OuterWrap>
      <AddLessonMenu
       isUpdateMode={this.props.match.params.id || ""}
       isIndividualLessonMode={this.props.match.params.lid || "" }
      />
      <div className="lesson-content-form">
        <p>Loading</p>
      </div>
     </OuterWrap>
    ) : (
     <OuterWrap>
      <AddLessonMenu
       isUpdateMode={this.props.match.params.id || ""}
       isIndividualLessonMode={this.props.match.params.lid || "" }
      />
      <div className="lesson-content-form">
        <p>Content</p>
        <div>
         <p>Type</p>
         <Select
           options={[
            { value: "video", label: "Video" },
            { value: "text", label: "Notes" },
          ]}
           value={currentLessonType}
           onChange={e => this.handleLessonType(e)}
         />
         {currentLessonType[0].value === "video" ? (
          <React.Fragment>
           <Video
            courseDetail={course}
            isIndividualLessonMode={this.props.match.params.lid || "" }
           />
           {false
            && this.props.match.params.lid
            && videoUrl
            && (videoUrl.indexOf("player") > -1 || videoUrl.indexOf("vimeo") > -1 )
            && (
             <iframe
               src={videoUrl || ""}
               width="400"
               height="340"
               frameBorder="0"
               allow="autoplay; fullscreen"
               title="partner"
               allowFullScreen
            />
            )
           }
         </React.Fragment>
         ) : (
          <React.Fragment>
           <RTFEditorIO
            contentType="lessonNotes"
            contentTitle="Note type content"
            rtfContent={courseExists
            && course.Lessons
            && course.Lessons.filter(lesson => lesson.id === this.props.match.params.lid)[0].text_content || lessonNotes || ""}
           />
           <Text
             courseDetail={course}
             isIndividualLessonMode={this.props.match.params.lid || "" }
           />
         </React.Fragment>
         )}
        </div>
      </div>
     </OuterWrap>
    )}
   </React.Fragment>

  )
 }
}
export default Container(LessonContent)
