import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  getAgentDetailsAction,
  getAgentStatesActions,
  getAgentCardDetailsAction,
  getAgentTypesAction,
  getUserAgentTypeAction,
  changeUserAgentTypeAction,
  getRARTeamListAction,
  updateAgentOptedTCAction,
} from "./actions"
import {
  getCurrentPlan,
  getAddons,
  getAssistPlans,
} from "./selector"

function mapStateToProps(state) {
  const { brokerDashboard } = state
  const {
    agentDetailsResponse,
    agentStates,
    addonsResponse,
    saveCardResponse,
    getAgentTypesResponse,
    getUserAgentTypeResponse,
    changeUserAgentTypeResponse,
    rarTeamListResponse,
    fetchAgentAsmRecruiterInfoResponse,
  } = brokerDashboard
  const currentPlan = getCurrentPlan(state)
  const assistPlans = getAssistPlans(state)
  const addons = getAddons(state)
  return {
    agentDetailsResponse,
    currentPlan,
    agentStates,
    addons,
    addonsResponse,
    saveCardResponse,
    assistPlans,
    getAgentTypesResponse,
    getUserAgentTypeResponse,
    changeUserAgentTypeResponse,
    rarTeamListResponse,
    fetchAgentAsmRecruiterInfoResponse,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      getAgentDetails: getAgentDetailsAction.request,
      getAgentStates: getAgentStatesActions.request,
      getAgentCardDetails: getAgentCardDetailsAction.request,
      getAgentTypes: getAgentTypesAction.request,
      userAgentType: getUserAgentTypeAction.request,
      changeUserAgentType: changeUserAgentTypeAction.request,
      getRARTeamList: getRARTeamListAction.request,
      updateAgentOptedTC: updateAgentOptedTCAction.request,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
