import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import InstructorsMenu from "./InstructorsMenu"
import colors from "../../../lib/colors"
import { DefaultLoader } from "components/UI/Loader/Loader";
import _ from 'lodash';

const Wrap = styled.div`
 height: 100px;
 width: 300px;
 border: 1px solid red;
 margin-left: 100px;
`

const EditInstructor = styled(Link)`
  margin-bottom: 14px;
  width: 118px;
  display: block;
  height: 30px;
  font-size: 14px;
  border: none;
  background: none;
  color: black;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding-top: 2px;
`

const ListWrap = styled.ul`
 list-style: none;
 padding:0;
 margin:0;
 li {
  width: 100%;
  height: 68px;
  border: solid 1px #e2e4e8;
  display: flex;
  .instructor_image {
    flex-grow: 1;
    height: 100%;
    max-width: 60px;
    padding-top: 14px;
    padding-left: 14px;
    img {
     width: 34px;
     height: 34px;
     border-radius: 18px;
    }
  }
  .instructor_details{
    height: 100%;
    padding: 14px;
    width: 222px;
   p {
    margin: 0;
   }
   p:last-child {
    font-size: 12px;
    color: #586069;
   }
  }
  .cta {
   height: 100%;
   width: 400px;
   display: flex;
   justify-content: flex-end;
   div {
    flex-grow: 1;
    height: 100%;
    padding: 23px 8px 8px 24px;
    max-width: 99px;
    button{
     cursor: pointer;
     background: none;
     border: none;
    }
    .delete {
     color: red;
    }
   }
  }
 }
`

const OuterWrap = styled.div`
 display: flex;
`

const RightMenu = styled.div`
  height: 500px;
  width: 699px;
  padding-left: 20px;

  .rtf {
   margin-top: -50px;
  }

  .imagePreview {
   margin-top: 50px;
   .preview {
    width: 100%;
    img{
     max-width: 100%;
    }
   }
  }

  .header {
   height: 50px;
   width: 100%;
   p {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin: 0;
   }
  }

  .course-image {
   height: 300px;
   width: 100%;
   .image-header-div {
    display: flex;
    justify-content: space-between;
    button {
     top: 10px;
     height: 30px;
     position: relative;
     left: 50px;
    }
   }
   .title {
    font-size: 14px;
   font-weight: bold;
   color: #24292e;
   }
   .image-placeholder {
    width: 730px;
    height: 242px;
    border-radius: 8px;
    margin-bottom: -97px;

   }
  }

  .instructors-list {
   width: 100%;
   min-height: 100px;
   height: max-content;

   border: 1px solid #e2e4e8;
   .headerDiv {
    height: 33px;
    border-radius: 4px;
    border-bottom: solid 1px #e2e4e8;
    background-color: #f4f5f8;
   }
  }
`
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const Remove = styled.button`
  border: none;
  background: transparent;
  padding: 28px;
  font-size: 14px;
  font-weight: 500;
  color: #cb2431;
  cursor: pointer;
`


class PeopleSettings extends Component {
 state = {
  isUpdateMode: this.props.match.params.id,
 }
 componentDidMount(){
  const {
   getCourseDetails,
  } = this.props

  if(this.props.match.params.id) {
   getCourseDetails({ id: this.props.match.params.id})
  }
 }

 handleDelete = () => {
   const {
    deleteInstructor,
   } = this.props
   deleteInstructor({
    id: this.props.match.params.id,
   })
 }

 render(){
  const {
   multipleInstructorsResponse,
   instructorArray,
   courseDetailResponse,
  } = this.props

  const {
   isUpdateMode,
   pictures,
  } = this.state

  const {
   data: courseDetail,
   isFetching: fetchingCourseDetails,
  } = courseDetailResponse || {}

  return (
    <React.Fragment>
    {fetchingCourseDetails ? (
     <OuterWrap>
       <InstructorsMenu
        submitFunc={this.submit}
        isUpdateMode={this.props.match.params.id || ""}
       />
       <RightMenu>
        <DefaultLoader height={200} width={200}/>
       </RightMenu>
     </OuterWrap>
    ) : (
     <OuterWrap>
      <InstructorsMenu
       submitFunc={this.submit}
       isUpdateMode={this.props.match.params.id || ""}
      />
      <RightMenu>
        <h1>Settings</h1>
        <p>Delete this Instructor/Host ?</p>
        <button onClick={this.handleDelete}>Delete</button>
      </RightMenu>
     </OuterWrap>
    )}
    </React.Fragment>
  )
 }
}

export default Container(PeopleSettings)
