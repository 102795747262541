import React, { Component } from "react"
import styled from "styled-components"
import { reduxForm, Field } from "redux-form"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import RTFEditorIO from "dumbComponents/Academy/Common/EditorRTF"

const Wrap = styled.div`
  height: 1200px;
  width: 140%;
  display: flex;
  flex-direction: column;
  .title {
   font-family: "SF Pro Display", sans-serif;
   font-size: 14px;
   font-weight: bold;
   color: #24292e;
  }
  .sub {
   font-size: 12px;
   color: #586069;
  }
  .res {
   margin-top: 12px;
  }
  .help {
   margin-top: 17px
  }
`

const LineBreak = styled.div`
  height: 100px;
  width: 100%;
`

const Row = styled.div`
  height: 400px;
  width: 103%;
`

class Notes extends React.Component {
 componentDidMount() {
  //
 }

  render() {
   const {
    rtfContent,
    notesNotes,
    resourceNotes,
    helpNotes,
   } = this.props

   return(
    <Wrap>
     <Row>
      <p className="title">Notes</p>
      <p className="sub">Notes related to this lesson.</p>
      <RTFEditorIO
        customMinHeight={200}
        customMaxHeight={349}
        customMarginBottom={56}
        contentType="notesNotes"
        rtfContent={rtfContent && rtfContent.notes.definitions || notesNotes || ""}
      />
     </Row>
     <Row
      className="res"
     >
      <p className="title">Resources (Additional resources for note lessons)</p>
      <p className="sub">Resources related to this lesson.</p>
      <RTFEditorIO
        customMinHeight={200}
        customMaxHeight={349}
        customMarginBottom={56}
        contentType="resourceNotes"
        rtfContent={rtfContent && rtfContent.resources || resourceNotes || ""}
      />
     </Row>
     <Row className="help">
      <p className="title">Help</p>
      <p className="sub">This will be displayed under Need Help? section</p>
      <RTFEditorIO
        customMaxHeight={349}
        customMinHeight={200}
        customMarginBottom={56}
        contentType="helpNotes"
        rtfContent={rtfContent && rtfContent.notes.help || helpNotes || ""}
      />
     </Row>
    </Wrap>
   )
  }
}
export default Container(Notes)
