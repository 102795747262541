import React from "react"
import {
  Form,
  Input,
} from "antd"
import {
  FormWrap,
  FlexRow,
} from "./commonStyles"
import {
  SubmitFormButton,
  CancelFormButton,
} from "./commonComponents"

const TeamCreationForm = ({
  onFinishHandle,
  isSending: submitting,
  toClose,
}) => (
  <FormWrap>
    <Form
      name="new_team_creation_form"
      layout="vertical"
      size="large"
      onFinish={onFinishHandle}
      onFinishFailed={() => { }}
      autoComplete="off"
    >
      <FlexRow>
        <Form.Item
          label="Team Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </FlexRow>
      <FlexRow
        gap="10px"
      >
        <SubmitFormButton submitting={submitting} />
        <CancelFormButton onClick={toClose} />
      </FlexRow>
    </Form>
  </FormWrap>
)

export default TeamCreationForm
