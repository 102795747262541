import React from "react"
import root from "window-or-global"
import { message as notif } from "antd"
import AI from "container/AI"

function LoadScript(url, callback) {
  const script = document.createElement("script")
  script.type = "text/javascript"

  if (script.readyState) {
    //IE
    // eslint-disable-next-line func-names
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    //Others
    // eslint-disable-next-line func-names
    script.onload = function () {
      callback()
    }
  }

  script.src = url
  document.getElementsByTagName("head")[0].appendChild(script)
}

class PubnubForDocusign extends React.Component {
  componentDidMount() {
    console.log("loading pubnub")
    this.loadScript()
  }

  resetPubnub = (id) => {
    if (id) {
      notif.info(`Updated agent... ${id}`)
      const globalChannel = "ai_chat_soul"
      const currentChannelToBeSubbed = `ai_chat_${id}`
      if (root.pubNubForAIChat) {
        root.pubNubForAIChat.subscribe({
          channels: [currentChannelToBeSubbed, globalChannel],
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      currentAgentIdForPubnub: agentId,
    } = this.props
    if (agentId !== prevProps.currentAgentIdForPubnub) {
      if (!root.pubNubForAIChat) {
        this.loadScript()
      } else if (prevProps.currentAgentIdForPubnub) {
        const channelToBeUnsubscribed = `ai_chat_${prevProps.currentAgentIdForPubnub}`
        root.pubNubForAIChat.unsubscribe({
          channels: [channelToBeUnsubscribed],
        })
        this.resetPubnub(agentId)
      } else {
        this.resetPubnub(agentId)
      }
    }
  }

  loadScript = () => {
    const {
      currentAgentIdForPubnub: agentId,
      handlePubNubEvent,
      currentSessionId,
      query,
      constructQuery,
      handleNewAgentPubNub,
    } = this.props

    if (!agentId) {
      return
    }

    const channelForAIChat = `ai_chat_${agentId}`
    const globalChannel = "ai_chat_soul"
    console.log("docusignListingChannel", channelForAIChat)
    LoadScript("https://cdn.pubnub.com/sdk/javascript/pubnub.4.29.8.min.js", () => {

      const pubkey = "pub-c-37fa6662-4400-4062-83d4-27ae1b0bec02"
      const subkey = "sub-c-3ef98a2e-3df8-11eb-a83f-7e8713e36938"

      root.pubNubForAIChat = new root.PubNub({
        publishKey: `${pubkey}`,
        subscribeKey: `${subkey}`,
        uuid: agentId,
      })

      root.pubNubForAIChat.subscribe({
        channels: [channelForAIChat, globalChannel],
      })

      root.pubNubForAIChat.addListener({
        message: (data) => {
          console.log("Listening to..", channelForAIChat, globalChannel, data)

          try {
            const { message, channel } = data
            console.log("check here->", data)
            const currentChannel = data.channel
            const { event_type } = message
            console.log(message)
            console.log(event_type)

            if (currentChannel !== "ai_chat_soul") {
              const {
                message: chatMessage,
                source,
                action_id,
                latest_session_id,
              } = message || {}

              const newMessage = {
                createdAt: Date.now(),
                feedback: 0,
                feedback_reason: null,
                message: chatMessage,
                message_id: action_id + 9999,
                message_timestamp: Math.floor(Date.now() / 1000),
                role: source,
                updated_at: Date.now(),
                sessionId: latest_session_id,
              }

              handlePubNubEvent({
                actionId: action_id,
                chatObject: newMessage,
              })
            } else {
              const {
                agent_id,
              } = message || {}
              handleNewAgentPubNub({
                newAgentId: agent_id,
              })
              // openNotification(agent_id, query, constructQuery)
            }
          } catch (e) {
            console.log("exception", e)
          }
        },
      })
    })
  }

  componentWillUnmount() {
    const { currentAgentIdForPubnub: agentId } = this.props
    const channelForAIChat = `ai_chat_${agentId}`
    const globalChannel = "ai_chat_soul"
    if (root.channelForAIChat) {
      root.channelForAIChat.unsubscribe({
        channels: [channelForAIChat, globalChannel],
      })
    }
  }

  render() {
    return null
  }
}

export default AI(PubnubForDocusign)
