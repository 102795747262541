import { toLower } from "lodash"

const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,8}(:[0-9]{1,5})?(\/.*)?$/

export const validURL = value => !value || !URL_REGEX.test(toLower(value))

export const emptyInput = (value) => {
  let err = false

  if (value.length <= 0) {
    err = true
  }

  if (/[^a-zA-Z0-9 ]/i.test(value)) {
    err = true
  }

  return err
}

export const isAlphaNumeric = (value) => {
  let err = false

  if (/[^a-zA-Z0-9 ]/i.test(value)) {
    err = true
  }

  return err
}

export const validEmail = (value) => {
  let err = true
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (re.test(value) || !value) {
    err = false
  }

  return err
}
