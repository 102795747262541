import React, { Component } from "react"
import { Link } from "react-router-dom"
import { withRouter } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroller"
import fetch from 'isomorphic-fetch'
import { unixToDate } from "services/Utils"
import styled from "styled-components"
import { get, isEmpty } from "lodash"
import {
  SALES_REPRESENTATIVE as SALES_REPRESENTATIVE_OPTIONS,
} from "dumbComponents/BrokerDashboard/GlobalPages/Resources/data"
import moment from "moment"
import bugsnag from 'bugsnag-js'
import Button from "dumbComponents/common/UI/Button"
import container from "container/BrokerDashboard/teamContainer"
import DayPickerInput from 'react-day-picker/DayPickerInput'
import root from "window-or-global"
import {
  Col,
  Row,
  Select as MultipleSelect,
  Input,
} from "antd"
import { USCurrencyFormat } from "shared/currencyUtils"
import OverviewTeams from "./OverviewTeams"
const { Option } = MultipleSelect

const baseUrl = process.env.REACT_APP_API_BASE_URL
const token = process.env.REACT_APP_SECRET_ID
const originalKey = process.env.REACT_APP_BUGSNAG_KEY
const bugsnagClient = bugsnag(originalKey)

const Wrap = styled.div`
  margin: 0px 30px;
`
const FilterWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: space-between;

  a {
    width: 150px;
  }
`

const PageTitle = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`
const TeamTitle = styled.h2`
  margin: 40px 0px;
`

const TeamWrap = styled.div`
  // background: #F1EEFF;
  padding: 16px 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
`
const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`
const MaxMinWrapper = styled.div`
  margin: 0px 8px 16px 8px;
  h6 {
    margin: 0px 0px 6px 10px;
    font-size: 16px;
  }
  > div {
    display: flex;
    input {
      margin: 0px 5px;
      padding: 11px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  div[class*="-container"] {
    width: 150px;
    z-index: 101;
  }

  &.source-container {
    div[class*="-container"] {
      width: 200px;
      z-index: 103;
    }
    div[class*="-control"] {
      width: 200px;
    }
  }

  &.client-type-container {
    div[class*="-container"] {
      width: 200px;
      z-index: 103;
    }
    div[class*="-control"] {
      width: 200px;
    }
  }

  &.agent-type-container {
    div[class*="-container"] {
      width: 200px;
      z-index: 102;
    }
    div[class*="-control"] {
      width: 200px;
    }
  }

  &.transaction-type-container {
    
    div[class*="-container"] {
      width: 250px;
      z-index: 101;
    }
    div[class*="-control"] {
      width: 250px;
    }
  }

  &.deal-status-container {
    div[class*="-container"] {
      width: 250px;
    }
    div[class*="-control"] {
      width: 250px;
    }
  }

  .DayPickerInput {
    input {
      width: 100px;
    }
  }

  .DayPickerInput-OverlayWrapper {
    z-index: 104;
  }
`

const TeamDetails = styled.div`
  flex: 1;

  h4, p {
    margin: 0px;
    padding: 0px;
  }

  p { 
    font-size: 14px;
    color: #999;
  }
`
const Genraltab = styled.div`
  width: max-content;
  padding: 10px;
  display: inline-block;
  vertical-align: top;
  ${props => props.isFixed && `
    width: 200px;
  `}
  ${props => props.isDropdown && `
    width: 300px;
    padding: 10px;
  `}
  ${props => props.isPrice && `
    width: 150px;
    padding: 9px;
  `}
  ${props => props.isStatus && `
  width: 300px;
  padding: 10px;
`}
${props => props.isDetails && `
  width: 600px;
  padding: 10px;
`}
${props => props.isDeal && `
  width: 150px;
  padding: 10px;
`}
${props => props.isSticky && `
  position: sticky;
  z-index: 100;
  left: 0;
  // background: #f1f1f1;
`}
${props => props.isStickyList && `
  position: sticky;
  z-index: 11;
  left: 0;
  background: white;
`}
  > p {
    margin: 0px;
  }
`
const ListItem = styled.div`
  border-bottom: 1px solid #ccc;
  width: max-content;
  display: flex;
  ${props => props.isHead === true && `
    background: #f1f1f1;
    margin-top: 20px;
    ${Genraltab} {
      > p {
        font-weight: bold;
      }
      position: sticky;
      z-index: 100;
    }
  `}

  ${props => props.isHead === false && `
    background: #f1f1f1;
    margin-top: 20px;
    ${Genraltab} {
      > p {
        font-weight: bold;
      }
      position: sticky;
      top: 59px;
      z-index: 100;
    }
  `}

  

  ${props => props.isClickable && `
    &:hover {
      background: #eee;
      transition-duration: 0.3s;
      cursor: pointer;
    }
  `}
`

const TeamSize = styled.div`
  width: 150px; 
`

const LoadingWrap = styled.div`
  min-height: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const TeamLead = styled.div`
  width: 200px;
  h6, p {
    margin: 0px;
    padding: 0px;
  }
  h6 {
    color: #999;
  }
  ${props => props.date && `
    margin-top: 5px;
  `}
  ${props => props.value && `
    font-size: 16px;
  `}
`

const ViewAndEdit = styled.div`
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

`
export const SALES_REPRESENTATIVE = [
  { label: "David Cheatham", value: "David Cheatham" },
  { label: "Julia Walter", value: "Julia Walter" },
  { label: "Justin Rush", value: "Justin Rush" },
  { label: "Kristi Wiles", value: "Kristi Wiles" },
  { label: "Sandi Rockwood", value: "Sandi Rockwood" },
  { label: "Julie Rose", value: "Julie Rose" },
  { label: "Tiffany Klem", value: "Tiffany Klem" },
  { label: "Rhonda Lawson", value: "Rhonda Lawson" },
  { label: "Legacy", value: "Legacy" },
  { label: "Gabrielle Mazza", value: "Gabrielle Mazza" },
]

const TC_STATE = [
  { label: "All States", value: "all-states" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Texas", value: "Texas" },
  { label: "Florida", value: "Florida" },
  { label: "Washington", value: "Washington" },
  { label: "Georgia", value: "Georgia" },
  { label: "Oregon", value: "Oregon" },
  { label: "Others", value: "others" },
]

const INIT_STATE = {
  fromTeamCreatedDate: null,
  toTeamCreatedDate: null,
  minTeamMembers: null,
  maxTeamMembers: null,
  minPastProduction: null,
  maxPastProduction: null,
  primarySaleRepresentative: null,
  secondarySaleRepresentative: null,
  pastBrokerageName: null,
  state: null,
}

class RARTeam extends Component {
  state = {
    teams: [],
    loading: true,
    ...INIT_STATE,
  };

  componentDidMount() {
    const { getRARTeamList, getBrokerTeamsOverview } = this.props
    getRARTeamList(this.getFilterParams())
    getBrokerTeamsOverview(this.getFilterParams())
  }

  searchHandler = () => {
    const { getRARTeamList, getBrokerTeamsOverview } = this.props
    const params = {
      ...this.getFilterParams(),
    }
    getRARTeamList(params)
    getBrokerTeamsOverview(params)
  }

  clearFilters = () => {
    const { getRARTeamList, getBrokerTeamsOverview } = this.props
    this.setState({
      ...INIT_STATE,
    })
    const params = {
      skip: 0,
      limit: 300,
    }
    getRARTeamList(params)
    getBrokerTeamsOverview(params)
  }

  handleDownloadButton = () => {
    const {
      getBrokerageTeamDownload,
    } = this.props

    const params = {
      ...this.getFilterParams(),
    }

    getBrokerageTeamDownload(params)
  }

  getTeamSelectOptions = () => {
    const { rarTeamListResponse } = this.props
    return (rarTeamListResponse && !isEmpty(rarTeamListResponse.data)) ? rarTeamListResponse.data.map(dt => ({
      name: dt.name,
      id: dt.id,
    })) : []
  }
   
  joinLeadNames = (lead) => {
    return `${lead.firstname} ${lead.lastname}`;
  };

  getTeamLead = (members = []) => {
    if (members) {
      const lead = members.filter(x => x.is_brokerage_admin === 1)
      return lead.length > 0 ? lead.map(e => this.joinLeadNames(e)).join(', ') : null;
    }
    return "N/a"
  }

  setFromTeamCreatedDate = (value) => {
    this.setState({ fromTeamCreatedDate: value })
  }

  setToTeamCreatedDate = (value) => {
    this.setState({ toTeamCreatedDate: value })
  }

  setMinTeamMembers = (value) => {
    this.setState({ minTeamMembers: value })
  }

  setMaxTeamMembers = (value) => {
    this.setState({ maxTeamMembers: value })
  }

  setMinPastProduction = (value) => {
    this.setState({ minPastProduction: value })
  }

  setMaxPastProduction = (value) => {
    this.setState({ maxPastProduction: value })
  }

  setPrimarySaleRepresentative = (value) => {
    this.setState({ primarySaleRepresentative: value })
  }

  setSecondarySaleRepresentative = (value) => {
    this.setState({ secondarySaleRepresentative: value })
  }

  setPastBrokerageName = (value) => {
    this.setState({ pastBrokerageName: value })
  }

  setStateFilter = (value) => {
    this.setState({ state: value })
  }

  getFilterParams = () => {
    const fromTeamCreatedDate = get(this.state, "fromTeamCreatedDate")
    const toTeamCreatedDate = get(this.state, "toTeamCreatedDate")
    const minTeamMembers = get(this.state, "minTeamMembers")
    const maxTeamMembers = get(this.state, "maxTeamMembers")
    const minPastProduction = get(this.state, "minPastProduction")
    const maxPastProduction = get(this.state, "maxPastProduction")
    const primarySaleRepresentative = get(this.state, "primarySaleRepresentative")
    const secondarySaleRepresentative = get(this.state, "secondarySaleRepresentative")
    const pastBrokerageName = get(this.state, "pastBrokerageName")
    const state = get(this.state, "state")
    const payload = {
      fromTeamCreatedDate,
      toTeamCreatedDate,
      minTeamMembers,
      maxTeamMembers,
      minPastProduction,
      maxPastProduction,
      primarySaleRepresentative,
      secondarySaleRepresentative,
      pastBrokerageName,
      state,
    }
    return payload
  }

  handleTeamDelete = (teamId) => {
    if (root.confirm("Delete this Team?")) {
      const { deleteRARTeam } = this.props
      const { res } = deleteRARTeam({
        teamId: teamId
      })
    }
  }

   getTeamsDiv = () => {
     const { rarTeamListResponse, history } = this.props
     const { data: teams } = rarTeamListResponse
     let rowTitles = ["Name", "Members", "Action"];
     const rowTitlesdiv = rowTitles.map(val => (
       <div>
         {val}
       </div>
     ))
     return (
       <div>
         <React.Fragment>
           <ListItem isHead>
             <Genraltab isDetails isFixed>
               <p>
                  Team Details
               </p>
             </Genraltab>
             <Genraltab isStatus isFixed>
               <p>
                  Team Size
               </p>
             </Genraltab>
             <Genraltab isStatus isFixed>
               <p>
                  Past Brokerage Name
               </p>
             </Genraltab>
             <Genraltab isStatus isFixed>
               <p>
                  Production Volume
               </p>
             </Genraltab>
             <Genraltab isStatus isFixed>
                Current Production Value
             </Genraltab>
             <Genraltab isFixed isStatus>
                Previous Year Production Value
             </Genraltab>
             <Genraltab isFixed isStatus>
                Team Lead
             </Genraltab>
             <Genraltab isFixed isStatus>
                Team Start Date
             </Genraltab>
             <Genraltab isFixed isStatus>
                View/Edit
             </Genraltab>
           </ListItem>
           <TeamWrap>
             <InfiniteScroll
               //  hasMore={hasMore}
               dataLength={teams && teams.length}
               //  loadMore={throttle(() => { loadMore() }, 4000)}
               //  threshold={250}
               //  loader={(
               //    <div>
               //      <Loader />
               //    </div>
               //  )}
             >
               {teams.map(team => (
                 <ListItem
                   isClickable
                   key={team.id}
                 >
                   <Genraltab isDetails isStatus>
                     <TeamDetails>
                       <h4 style={{ marginBottom: 5, fontWeight: 400 }}>
                         {team.name}
                       </h4>
                       <p>
                         {team.members ? team.members.map(agent => `${agent.firstname || " "} ${agent.lastname || " "}`).join(", ") : null}
                       </p>
                       <Button style={{ marginTop: 16 }} success onClick={() => { history.push(`/broker/roster?teamId=${team.id}&teamName=${team.name}`) }}>
                  View in Roster
                       </Button>
                       <Button style={{ marginLeft: 10, marginTop: 16 }} success onClick={() => { this.handleTeamDelete(team.id) }}>
                  Delete this Team
                       </Button>
                     </TeamDetails>
                   </Genraltab>
                   <Genraltab isFixed isStatus>
                     <TeamSize>
                       <p>
                         <i className="fa fa-user"></i>
                         <span style={{ padding: "0px 3px" }}>
                           {team.members.length}
                         </span>
                       </p>
                     </TeamSize>
                   </Genraltab>
                   <Genraltab isFixed isStatus>
                     <TeamLead value>
                       <p>
                         {team.past_brokerage_name || "-"}
                       </p>
                     </TeamLead>
                   </Genraltab>
                   <Genraltab isFixed isStatus>
                     <TeamLead value>
                       <p>
                         {USCurrencyFormat(team.production_volume) || "-"}
                       </p>
                     </TeamLead>
                   </Genraltab>
                   <Genraltab isFixed isStatus>
                     <TeamLead value>
                       <p>
                         {USCurrencyFormat(team.current_production_value)}
                       </p>
                     </TeamLead>
                   </Genraltab>
                   <Genraltab isFixed isStatus>
                     <TeamLead value>
                       <p>
                         {USCurrencyFormat(team.previous_year_production_value)}
                       </p>
                     </TeamLead>
                   </Genraltab>
                   <Genraltab isFixed isStatus>
                     <TeamLead>
                       <p>
                         {this.getTeamLead(team.members)}
                       </p>
                     </TeamLead>
                   </Genraltab>
                   <Genraltab isFixed isStatus>
                     <TeamLead date>
                       <p>
                         {moment(team.created_at).format("MM/DD/YYYY")}
                       </p>
                     </TeamLead>
                   </Genraltab>
                   <Genraltab isFixed isStatus>
                     <ViewAndEdit>
                       <Link to={`/broker/teams/edit/${team.id}`}>
                         <i className="fa fa-edit"></i>
                         {" view / edit"}
                       </Link>
                     </ViewAndEdit>
                   </Genraltab>
                 </ListItem>
               ))}
             </InfiniteScroll>
           </TeamWrap>
           {/*<ColumnDiv>
               </ColumnDiv>
               <ColumnDiv>
               </ColumnDiv>
                */}
         </React.Fragment>
       </div>
     )
   }

   render() {
     console.log(this.props);
     const { fromTeamCreatedDate, toTeamCreatedDate, minTeamMembers, maxTeamMembers, maxPastProduction, minPastProduction, primarySaleRepresentative, secondarySaleRepresentative, pastBrokerageName, state } = this.state
     const emptyDiv = <span>"No teams yet, please add a team."</span>
     const { rarTeamListResponse, getBrokerTeamsOverviewResponse } = this.props
     const { data: teamList, isFetching } = rarTeamListResponse
     const { data: overview, isFetching: isFetchingTeams } = getBrokerTeamsOverviewResponse
     const countAgents = (teams) => {
       let count = 0
       const a = teams?.map((team) => {
         count += team.members.length
       })
       return count
     }

     if (isFetching && isFetchingTeams) {
       return (
         <LoadingWrap className="new-loader">
           <h1>Loading...</h1>
         </LoadingWrap>
       )
     }
     return (
       <Wrap>
         <OverviewTeams overview={overview} />
         <PageTitle>
           <TeamTitle>
             {`Team List (${teamList && teamList.length > 0 ? teamList.length : 0})`}
           </TeamTitle>
           <TeamTitle>
             {`Total Agents (${teamList && countAgents(teamList)})`}
           </TeamTitle>
         </PageTitle>

         <div>
           <FilterWrap>
             <a href={"/teams/create" }>
               <Button purple>
                 <i className="fa fa-plus"></i>
                 {` Add Team`}
               </Button>
             </a>
             <Row>
               <Col>
                 <MaxMinWrapper className="source-container">
                   <h6>Team Started Date</h6>
                   <div>
                     <DayPickerInput
                       type="text"
                       onDayChange={(day) => {
                         this.setFromTeamCreatedDate(moment(day).format("YYYY-MM-DD HH:mm:ss"))
                       }}
                       placeholder="From"
                       style={{ width: 100, marginRight: 20 }}
                       value={fromTeamCreatedDate ? moment(fromTeamCreatedDate).format("YYYY-MM-DD") : null}
                     />
                     <DayPickerInput
                       type="text"
                       onDayChange={(day) => { this.setToTeamCreatedDate(moment(day).format("YYYY-MM-DD HH:mm:ss")) }}
                       style={{ width: 100 }}
                       placeholder="To"
                       value={toTeamCreatedDate ? moment(toTeamCreatedDate).format("YYYY-MM-DD") : null}
                     />
                   </div>
                 </MaxMinWrapper>
               </Col>
               <Col>
                 <MaxMinWrapper className="source-container">
                   <h6>Past Production</h6>
                   <div>
                     <Input
                       type="text"
                       onChange={(e) => { this.setMinPastProduction(e.target.value) }}
                       placeholder="From"
                       style={{ width: 100, marginRight: 20 }}
                       value={minPastProduction || null}
                     />
                     <Input
                       type="text"
                       onChange={(e) => { this.setMaxPastProduction(e.target.value) }}
                       style={{ width: 100 }}
                       placeholder="To"
                       value={maxPastProduction || null}
                     />
                   </div>
                 </MaxMinWrapper>
               </Col>
               <Col>
                 <MaxMinWrapper className="source-container">
                   <h6>Team Size</h6>
                   <div>
                     <Input
                       type="text"
                       onChange={(e) => { this.setMinTeamMembers(e.target.value) }}
                       placeholder="From"
                       style={{ width: 100, marginRight: 20 }}
                       value={minTeamMembers || null}
                     />
                     <Input
                       type="text"
                       onChange={(e) => { this.setMaxTeamMembers(e.target.value) }}
                       style={{ width: 100 }}
                       placeholder="To"
                       value={maxTeamMembers || null}
                     />
                   </div>
                 </MaxMinWrapper>
               </Col>
               <Col>
                 <MaxMinWrapper style={{ zIndex: 100 }}>
                   <h6>State</h6>
                   <div>
                     <MultipleSelect
                       width={250}
                       isMulti={false}
                       style={{ zIndex: 100, margin: "0px 20px", width: "250px" }}
                       options={TC_STATE}
                       value={state || null}
                       placeholder="Select"
                       onChange={(value) => {
                         this.setStateFilter(value)
                       }}
                     />
                   </div>
                 </MaxMinWrapper>
               </Col>
             </Row>
             <Row>
               <Col>
                 <MaxMinWrapper style={{ zIndex: 100 }}>
                   <h6>Primary Sales Representative</h6>
                   <div>
                     <MultipleSelect
                       width={250}
                       isMulti={false}
                       style={{ zIndex: 100, margin: "0px 20px", width: "250px" }}
                       options={SALES_REPRESENTATIVE_OPTIONS}
                       value={primarySaleRepresentative}
                       placeholder="Select"
                       onChange={(value) => {
                         this.setPrimarySaleRepresentative(value)
                       }}
                     />
                   </div>
                 </MaxMinWrapper>
               </Col>
               <Col>
                 <MaxMinWrapper style={{ zIndex: 100 }}>
                   <h6>Secondary Sales Representative</h6>
                   <div>
                     <MultipleSelect
                       width={250}
                       isMulti={false}
                       style={{ zIndex: 100, margin: "0px 20px", width: "250px" }}
                       options={SALES_REPRESENTATIVE_OPTIONS}
                       value={secondarySaleRepresentative}
                       placeholder="Select"
                       onChange={(value) => {
                         this.setSecondarySaleRepresentative(value)
                       }}
                     />
                   </div>
                 </MaxMinWrapper>
               </Col>
               <Col>
                 <MaxMinWrapper className="source-container">
                   <h6>Past Brokerage</h6>
                   <div>
                     <Input
                       type="text"
                       onChange={(e) => { this.setPastBrokerageName(e.target.value) }}
                       placeholder="Enter Name"
                       style={{ width: 250, marginRight: 20 }}
                       value={pastBrokerageName || null}
                     />
                   </div>
                 </MaxMinWrapper>
               </Col>
               <Button
                 onClick={this.searchHandler}
                 style={{
                   height: 47,
                   alignSelf: "center",
                   marginBottom: -15,
                   marginLeft: 10,
                 }}
               >
                Filter
               </Button>
               <Button
                 onClick={this.clearFilters}
                 style={{
                   height: 47,
                   alignSelf: "center",
                   marginBottom: -15,
                   marginLeft: 10,
                 }}
               >
                Clear
               </Button>
               <Button
                 type="primary"
                 title="Download Teams Data"
                 onClick={this.handleDownloadButton}
                 style={{
                   height: 47,
                   alignSelf: "center",
                   marginBottom: -15,
                   marginLeft: 10,
                 }}
               >
                Export Data to CSV
               </Button>
             </Row>
           </FilterWrap>
           <div style={{ marginTop: 20 }}>
             {teamList && teamList.length > 0 ? (
               <React.Fragment>
                 {this.getTeamsDiv()}
               </React.Fragment>
             ) : emptyDiv}
           </div>
         </div>
       </Wrap>
     )
   }
}
export default container(RARTeam)
