import {Component} from "react";
import styled from "styled-components"
import { get } from "lodash"
import { Fetch } from "../lib/NetworkUtils"
import {NotifyBugsnagError, ShowErrorToast, ShowSuccessToast} from "../lib/ErrorUtils";
import { makeConferenceCall, removeAgentAPI} from "./API/CallerApi";
import React from "react";
import {DefaultLoader} from "./UI/Loader/Loader";
import classnames from "classnames";
import {getSubscription} from "./API/AgentApi";
import { Button } from "./CallHeader/common"
import { alert } from "window-or-global";

const HoldButton = styled.button`
	left: 400px;
	position: relative;
	padding: 5px;
	top: 5px;

	${props => props.isOnHold && `
		background: #2ecc71;
	`}
`

const ButtonWraps = styled.div`
	display: flex;
	flex-direction: row;
`

class ConferenceCall extends Component {

	state = {
		loading: false,
		isAllowed: true,
		isOnHold: false,
	};

	componentDidMount() {
		this.getSubscription();
	}

	getSubscription = () => {
		const self = this;
		let referral = this.props.referral || {};
		let prospectBrokerCounts = this.props.broker_agents_count;

		//allow this for all rar referrals
		// if (referral.is_isa === 0 || referral.is_isa === false) {
		// 	self.setState({ isAllowed: true });
		// 	return;
		// }

		//conference_agent_id should be present for isa
		if(!referral.conference_agent_id) {
			NotifyBugsnagError("no conference_agent_id for " + referral.id);
			return;
		}

		if(!referral.sender_id) {
			console.log("no sender id to get subscription");
			return;
		}

		let res = getSubscription(referral.sender_id).then(res => {
			let assistFeatures = res.response ? res.response.assist_features : null;
			self.isAllowedFeature(assistFeatures)
		}).catch(err => {
			console.log(err);
			ShowErrorToast(err);
		});
	};

	startLoader = () => {
		this.setState({
			loading: true
		});
	};
	stopLoader = () => {
		this.setState({
			loading: false
		});
	};

	isAllowedFeature = (assistFeatures) => {
		const self = this;
		if (!assistFeatures) {
			return;
		}
		return assistFeatures.map(item => {
			if (item.key === "live_transfer" && item.value === 1) {
				self.setState({isAllowed: true})
			}
		});
		};

	makeConferenceCall = () => {
		this.startLoader();
		let referral = this.props.referral ? this.props.referral : {};

		let body = {
			child_call_sid: this.props.call_sid,
			referral_id: referral.id,
			twilio_client_name: this.props.twilio_client_name,
		};

		if (referral.is_isa) {
			body["agent_id"] = referral.conference_agent_id;
		}

		makeConferenceCall(body)
		.then(res => {
			ShowSuccessToast(res.response);
			this.stopLoader();
		}).catch(err => {
			ShowErrorToast(err);
			this.stopLoader();
		});
	};
		
	handleHold = async () => {
		try {
			const res = await Fetch(`${process.env.REACT_APP_API_BASE_URL}/v1.0/gabby/call/conference-call-hold`, {
				method: "POST",
				body: JSON.stringify({
					child_call_sid: this.props.call_sid,
					twilio_client_name:"sannidhi",
					referral_id: get(this.props, "activeInConferenceCall.referral_id"),
					hold_client: !this.state.isOnHold 
				})
			})
			this.setState({
				isOnHold: !this.state.isOnHold,
			})
		} catch (error) {
			console.log(error)
		}
	}

	removeAgent = async () => {
		//alert("Remove agent is still in development.")
		const { parentCallSid, twilio_client_name } = this.props
		const body = {
			referral_id: get(this.props, "activeInConferenceCall.referral_id"),
			remove_agent: true,
			twilio_client_name,
			parent_call_sid : get(this.props, "activeInConferenceCall.conference_parent_call_id")
		}
		await removeAgentAPI({
			method: "POST",
			body: JSON.stringify(body)
		})
		const agentName = get(this.props, "activeInConferenceCall.conference_agent_name")
		ShowSuccessToast(`Agent: ${agentName} removed succeccfully.`);
	}

	render() {
		const { activeInConferenceCall, isHideRemoveAndHold } = this.props
		const { loading, isAllowed, isOnHold } = this.state;
		let disabled = loading || !isAllowed;
		console.log({ activeInConferenceCall, isHideRemoveAndHold });
		return (
			<ButtonWraps>
				<Button
					disabled={disabled}
					onClick={() => {this.makeConferenceCall();}}
					icon="users"
				>
					Connect to Agent
				</Button>

				{(activeInConferenceCall || !isHideRemoveAndHold) && (
					<Button
						onClick={this.handleHold}
						icon={isOnHold ? "play" : "pause"}
						type={isOnHold ? "success" : "info"}
					>
						{isOnHold ? "On Hold" : "Hold Client"}
					</Button>
				)}
				{(activeInConferenceCall || !isHideRemoveAndHold) && (
					<Button type="danger" onClick={this.removeAgent} icon="user-times">
						Remove Agent
					</Button>
				)}
			</ButtonWraps>
		)
	}
}

export default ConferenceCall;
