import React, { Component } from "react"
import fetch from 'isomorphic-fetch'
import bugsnag from 'bugsnag-js'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
const baseUrl = process.env.REACT_APP_API_BASE_URL
const token = process.env.REACT_APP_SECRET_ID

const originalKey = process.env.REACT_APP_BUGSNAG_KEY
const bugsnagClient = bugsnag(originalKey)

class Messages extends Component {
  state = {
    callLogs: [],
    currentConsultantView: 'All',
    currentStatusView: 'All',
    direction: 'All',
    filteredLogs: [],
    startDate: null,
    endDate: null,
    consultantNames: [],
  };

  secondsTohhmmss = (duration) => {
    const totalSeconds = duration;
    let hours   = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
    let seconds = totalSeconds - (hours * 3600) - (minutes * 60);
    seconds = Math.round(seconds * 100) / 100;
    if (minutes > 0) {
        return minutes + ' mins ' + seconds + ' secs';
    }
    return seconds + ' secs';

  };

  getAdminUsers = async () => {
    try {
        let response = await fetch(baseUrl + '/v1.0/consultants/all', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'secure-id': token,
            },
        });
        let respJson = await response.json();
        let consultantNames = respJson.response.accounts;
        consultantNames = consultantNames.map(c => c.name);
        consultantNames = ['All'].concat(consultantNames.sort());
        this.setState({consultantNames});
    } catch (e) {
        bugsnagClient.notify(e);
    }
  };

  componentDidMount() {
      this.getCallLogs();
      this.getAdminUsers();
   }

  getCallLogs = async () => {
      try {
        let response = await fetch(baseUrl + '/v1.0/caller/call-logs?&sort=-id&offset=0&limit=1000', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'secure-id': token,
            },
        });
        let respJson = await response.json();
        this.setState({callLogs: respJson.response.items,  filteredLogs: respJson.response.items,});
      }
      catch (e) {
          bugsnagClient.notify(e);
      }
  };

  handleConsultantChange = (e) =>{
    const value = e.target.value;
    this.setState({currentConsultantView: value});
  };

  handleDirectionChange = (e) =>{
    const value = e.target.value;
    this.setState({direction: value});
  };

  handleStatusChange = (e) =>{
    const value = e.target.value;
    this.setState({currentStatusView: value});
  };


  handleStartDateChange(date) {
    this.setState({
        startDateinMS: moment(date).format('X'),
        startDate: date,
    });
  }

  handleEndDateChange(date) {
    this.setState({
        endDateinMS: moment(date).format('X'),
        endDate: date,
    });
  }

  reset = () => {
    this.setState({filteredLogs: this.state.callLogs, currentConsultantView: 'All', currentStatusView: 'All', startDate: null, startDateinMS: null, endDate: null, endDateinMS: null, direction: 'All'}, this.getCallLogs);
  };

  filter = async () => {
    try {
        let self = this;
        let query = '';

        if (self.state.direction !== 'All') {
            if (self.state.direction === 'Outgoing') {
                query += '&direction=1';
            }
            else {
                query += '&direction=0';
            }
        }


        if (self.state.currentConsultantView !== 'All') {
            query += '&referral_manager=' + self.state.currentConsultantView;
        }


        if (self.state.currentStatusView !== 'All') {
            query += '&status=' + self.state.currentStatusView;
        }

        if (this.state.endDate && this.state.startDate) {
           query += '&start_date='+ this.state.startDateinMS + '&end_date=' + this.state.endDateinMS;
        }
        let response = await  fetch(baseUrl + '/v1.0/caller/call-logs?&sort=-id&offset=0&limit=1000'+ query, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'secure-id': token,
            },
        });
        let respJson = await response.json();


        let filteredLogs = respJson.response.items;
        this.setState({filteredLogs: filteredLogs,});
    }
    catch(e) {
        bugsnagClient.notify(e);
    }

  };

  render() {
    try{
        const callLogs = this.state.filteredLogs.map((log) => {
            let redirectUrl = process.env.REACT_APP_BLINK_BASE_URL;
            if (log.received_date) {
                log.received_date = moment(log.received_date).utc().format('MMMM Do YYYY');
            }
            else if (log.referral_created_at){
                log.received_date = moment(log.referral_created_at*1000).utc().format('MMMM Do YYYY');
            }

            if (log.referral_id){
                redirectUrl += '?referral_id=' + log.referral_id + "&isPageInactive=true";
            }
            if(log.client) {
                log.client_name = log.client.name;
                if(log.client.emails) {
                    log.client_email = log.client.emails[0].email;
                }
                if(log.client.phones) {
                    log.client_phone = log.client.phones[0].phone;
                }
            }
            if(!log.client_phone) {
                if (log.payload.direction === 0) {
                    log.client_phone = log.payload.from;
                } else {
                    log.client_phone = log.payload.to;
                }
            }


            return <tr>
                <td>{moment(log.created_at*1000).format('MMM Do, h:mm a')}</td>
                <td>{log.referral_manager}</td>
                <td><a href={redirectUrl} target="_">
                    {log.client_name}<br/>
                    {log.client_email}<br/>
                    {log.client_phone}
                    </a>
                </td>
                <td>{log.status}</td>
                <td>{log.total_call_count}</td>
                <td>{log.received_date || moment(log.created_at*1000).format('MMM Do, h:mm a')}</td>
                <td>{log.referral_source || ''}</td>
                <td>{this.secondsTohhmmss(log.duration)}</td>
                <td>{this.secondsTohhmmss(log.ringing_duration)}</td>
                <td>{log.direction === 1 ? 'Outgoing' : 'Incoming'}</td>
                <td>
                    {log.recording_url&&
                        <audio controls>
                            <source src={log.recording_url} type="audio/ogg" />
                        </audio>
                    }
                </td>
                <td>
                    {log.payload.caller_number}
                </td>
            </tr>
        });

        const consultantDiv = this.state.consultantNames.map(consultant => <option value={consultant} >{consultant}</option>);

        return (
            <div style={{ paddingTop: "60px" }}>
                <div className="dashboard-dropdown">
                    Consultant:
                    <select onChange={this.handleConsultantChange} value={this.state.currentConsultantView}>
                        {consultantDiv}
                    </select>
                </div>
                <div className="dashboard-dropdown">
                    Incoming/Outgoing:
                    <select onChange={this.handleDirectionChange} value={this.state.direction}>
                        <option value="All" >All</option>
                        <option value="Incoming">Incoming</option>
                        <option value="Outgoing" >Outgoing</option>
                    </select>
                </div>
                <div className="dashboard-dropdown">
                    Status:
                    <select onChange={this.handleStatusChange} value={this.state.currentStatusView}>
                        <option value="All" >All</option>
                        <option value="completed">completed</option>
                        <option value="no-answer" >no-answer</option>
                        <option value="failed" >failed</option>
                        <option value="no-answer-RM">no-answer-RM</option>
                        <option value="no-answer-machine">no-answer-machine</option>
                    </select>
                </div>
                <table className="dashboard-dates">
                    <tr>
                        <th>FROM DATE</th>
                        <th>END DATE</th>
                    </tr>
                    <tr>
                        <td>
                            <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleStartDateChange.bind(this)}
                                />
                        </td>
                        <td>
                            <DatePicker
                                selected={this.state.endDate}
                                onChange={this.handleEndDateChange.bind(this)}
                            />
                        </td>
                        <td>
                            <button onClick={this.filter.bind(this)}> Filter </button>
                            <button onClick={this.reset.bind(this)}> Reset </button>
                        </td>
                    </tr>
                </table>
                <hr/>
                <table className="dashboard">
                        <tr>
                            <th>Date</th>
                            <th>Consultant</th>
                            <th>Client</th>
                            <th>Status</th>
                            <th>Call Count</th>
                            <th>Inquiry Date</th>
                            <th>Source</th>
                            <th>Duration</th>
                            <th>Ringing Duration</th>
                            <th>Direction</th>
                            <th>Recording</th>
                            <th>Caller Number</th>
                        </tr>
                        {callLogs}
                </table>
            </div>
        );

    }
    catch(e){
        bugsnagClient.notify(e);
    }
  }
}
export default Messages;
