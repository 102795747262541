import React, { useRef, useEffect, memo } from "react"
import AI from "container/AI/index"
import {
  ChatArea,
  MessagesArea,
  ActionArea,
} from "../../chatSectionStyles"
import {
  Header,
  ChatCard,
  Actions,
} from "./Elements"

const Chat = memo(({
  currentSession,
  currentTopics,
  fetchSessionHistoryResponse,
}) => {
  const {
    data: chats,
    isFetching: fetchingChats,
  } = fetchSessionHistoryResponse || {}

  useEffect(() => {
    if (currentMessageArea) {
      const ele = currentMessageArea.current
      if (ele) {
        ele.scrollTo(0, ele.scrollHeight)
      }
    }
  }, [currentSession])

  const currentMessageArea = useRef(null)

  return (
    <ChatArea>
      <Header />
      {fetchingChats ? (
        <>
          <div
            style={{
              maxHeight: "55vh",
              overflow: "scroll",
              width: "100%",
            }}
            ref={currentMessageArea}
          >
            {currentSession && currentSession.length > 0
              ? currentSession.map(chat => (
                <ChatCard
                  id={chat.message_id}
                  feedbackType={chat.feedback}
                  message={chat.message}
                  messageId={chat.message_id}
                  userType={chat.role}
                  since={chat.message_timestamp}
                  name={currentTopics.currentAgentKey || "Agent"}
                />
              )) : (
                <p><i>Fetching...</i></p>
              )}
          </div>
          <ActionArea>
            <Actions disabled />
          </ActionArea>
        </>
      ) : (
        <>
          <div
            style={{
              maxHeight: "55vh",
              overflow: "scroll",
              width: "100%",
            }}
            ref={currentMessageArea}
          >
            {chats
            && currentSession
            && currentSession.length > 0
              ? currentSession.map(chat => (
                <ChatCard
                  id={chat.message_id}
                  feedbackType={chat.feedback}
                  message={chat.message}
                  messageId={chat.message_id}
                  userType={chat.role}
                  since={chat.message_timestamp}
                  name={currentTopics.currentAgentKey || "Agent"}
                />
              )) : (
                <p><i>Select a topic from left</i></p>
              )}
          </div>
          <ActionArea>
            <Actions />
          </ActionArea>
        </>
      )}
    </ChatArea>
  )
})

export default AI(Chat)
