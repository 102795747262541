import React, { Component } from 'react';
import styled from "styled-components"
import EditorJs from "react-editor-js";
import Container from "container/ProductInterest"
import { EDITOR_JS_TOOLS } from "./EditorConstants";

const editor = new EditorJs({
  defaultBlock: "",
});

const Wrap = styled.div`
 height: max-content;
 .title {
  font-size: 14px;
  font-weight: bold;
  color: #24292e;
  span {
   color: red;
  }
 }
 .sub {
  font-size: 12px;
  color: #586069;
  margin-bottom: 27px;
 }
 .codex-editor {
  z-index: 0;
 }
 .codex-editor__redactor {
   border: 1px solid #e3e4e8;
   padding: 8px;
   overflow: scroll;
   max-height: 150px;
   width: 63%;
   margin-left: 45px;
 }
`


class RTFEditorIO extends React.Component {
 state = {
  editorState: "",
  currentBase: "",
 }
 componentDidMount() {

 }

 componentDidUpdate(prevProps, prevState) {

  const {
    isNewFeatureOrChild,
    currentEditableProduct,
    setRTFValue
  } = this.props
  this.editorInstance.isReady
  .then((props) => {
    /** Do anything you need after editor initialization */
    if(prevProps.isNewFeatureOrChild !== isNewFeatureOrChild) {
      this.editorInstance.clear()
    }

    if(currentEditableProduct.description !== prevProps.currentEditableProduct.description) {
      try {
        console.log(this.editorInstance.render(JSON.parse(decodeURIComponent(escape(atob(currentEditableProduct.description))))))
      } catch(e) {
        console.log(e)
      }
    }
  })
  .catch((reason) => {
    console.log(`Editor.js initialization failed because of ${reason}`)
  });
 }

 onChange = (val, e) => {
  const {
   setRTFValue,
   contentType,
  } = this.props

  this.editorInstance.save().then((outputData) => {
  setRTFValue({
   data: btoa(unescape(encodeURIComponent(JSON.stringify(outputData)))),
   type: contentType,
  })
  }).catch((error) => {
    console.log('Saving failed: ', error)
  });
  this.setState({
   editorState: val,
  })
 }
 decode = (rtfContent) => {
  try {
   return JSON.parse(decodeURIComponent(escape(atob(rtfContent))))
  } catch (e) {
   //do nothing
  }
 }

 render() {
  const {
   contentTitle,
   contentType,
   contentSub,
   rtfContent,
   customMinHeight,
   customMarginBottom,
   customMaxHeight,
   isNewFeatureOrChild,
  } = this.props

  return(
   <Wrap
    customMinHeight={customMinHeight}
    customMarginBottom={customMarginBottom}
    customMaxHeight={customMaxHeight}
   >
    {contentTitle && (
      <p className="title">
      {contentTitle}
       <span className="important">*</span>
      </p>
    )}
    <EditorJs
       holder={contentType}
       tools={EDITOR_JS_TOOLS}
       onChange={this.onChange}
       data={rtfContent && this.decode(rtfContent) || this.state.editorState}
       instanceRef={instance => this.editorInstance = instance}
    >
      <div id={contentType} />
    </EditorJs>
    {contentSub && (
     <p className="sub">{contentSub}</p>
    )}
   </Wrap>
  )
 }
}


export default Container(RTFEditorIO)
