import React  from 'react';
import Popup from "reactjs-popup";
import AddOrUpdateClient from "./AddOrUpdateClient"
import styled from "styled-components"

const ClientPopup = styled(Popup)`
    padding: 0px !important;
    border:0px !important;
`;

const ClientModal = ({ client, triggerUI }) => {
    return (
        <ClientPopup
            trigger={
                triggerUI
            }
            modal
            closeOnDocumentClick={false}
            closeOnEscape={true}
        >
            {close => (
                    <AddOrUpdateClient client={client} close={close}/>
            )}
        </ClientPopup>
    );
};

export default  ClientModal