import { isEmpty } from "lodash"

const HAS_ALPHA_RE = /^[a-zA-Z]*$/

export const removeAllChars = (str = "", character, replaceWith = "") => {
  const re = new RegExp(character, "g")
  return str.replace(re, replaceWith)
}

// Helper Functions
function funkyStringSplit(val = "") {
  const s = val
  let i = s.length % 3
  const parts = i ? [s.substr(0, i)] : []
  for (; i < s.length; i += 3) {
    parts.push(s.substr(i, 3))
  }
  return parts
}
const numberPattern = /\d+/g

export const required = value => value ? undefined : "* Required"

export const email = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    "Invalid email address" : undefined
)

export const commaSeperatedNumberUS = (value = "") => {
  try {
    if (!value) {
      return value
    }
    value = String(value)
    let trimmedNum = value.match(numberPattern)
    trimmedNum = Array.isArray(trimmedNum) ? trimmedNum.join("") : trimmedNum
    const parts = funkyStringSplit(trimmedNum) || [value]
    return parts.join(",")
  } catch (e) {
    return value
  }
}

export const locationRequired = value => !isEmpty(value) ? undefined : "* Required"

export const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, "")
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums + '-'
    }
    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-'
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
  }
  return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 10)
}

export const zeroToHundred = value => value > 100 || value < 0 ? "Allowed range is from 0 to 100" : undefined

export const onlyNumbers = value => !HAS_ALPHA_RE.test(removeAllChars(value, ",")) || !value ? undefined : "Only numbers are allowed"
