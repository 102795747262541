import React from "react"
import { withRouter } from "react-router-dom"
import Loader from "dumbComponents/common/UI/Loader"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
  Form,
  Input,
  Select,
  Button,
  Modal,
} from "antd"
import Container from "container/EventRooms"
import cookie from "react-cookies"
import styled from "styled-components"
const { confirm } = Modal

const Wrap = styled.div`
  padding: 20px;
`

class PaymentInfoTC extends React.PureComponent {
  componentDidMount() {
    const { getOnboardingCommission, history, match } = this.props
    const user = cookie.load("user")
    console.log("==user===", user)
    getOnboardingCommission({
      admin_name: user.twilio_client_name,
      agent_id: parseInt(match.params.agentId, 10),
    })
  }

  showConfirm = (data) => {
    confirm({
      title: "Do you Want to save these informations?",
      icon: <ExclamationCircleOutlined />,
      content: "Please confirm once as this can't be reverted back",
      onOk: () => {
        this.createOrUpdate(data)
      },
      onCancel: () => {
        console.log("Cancel")
      },
    })
  };

  createOrUpdate = (data) => {
    console.log("==data===", data)
    const user = cookie.load("user")
    const { getOnboardingCommissionResponse, createOnboardingCommission, updateOnboardingCommission, match } = this.props
    if (getOnboardingCommissionResponse.data.agent_id) {
      updateOnboardingCommission({
        payload: {
          ...data,
          admin_name: user.twilio_client_name,
          agent_id: parseInt(match.params.agentId, 10),
        },
      })
    } else {
      createOnboardingCommission({
        payload: {
          ...data,
          admin_name: user.twilio_client_name,
          agent_id: parseInt(match.params.agentId, 10),
        },
      })
    }
  }

  render() {
    const { getOnboardingCommissionResponse } = this.props
    const responseData = getOnboardingCommissionResponse.data
    console.log("==responseData==responseData  responseData=", getOnboardingCommissionResponse)
    return (
      <Wrap>
        {getOnboardingCommissionResponse.isFetching ? (<Loader />) : (
          <Form
            name="basic"
            layout="vertical"
            size="large"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              ...responseData,
              transfer_listing_or_deal: (responseData && responseData.transfer_listing_or_deal) ? "Yes" : "No",
              about_to_place_offer: (responseData && responseData.about_to_place_offer) ? "Yes" : "No",
            }}
            onFinish={(data) => { this.showConfirm(data) }}
            onFinishFailed={() => { }}
            autoComplete="off"
          >
            <Form.Item label="How would you like to receive your commission?" name="receiving_commission_mode">
              <Select>
                <Select.Option value="Wire">Wire</Select.Option>
                <Select.Option value="Check">Check</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Personal Bank Name"
              name="personal_bank_name"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Personal Bank Routing Number"
              name="personal_bank_routing_number"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Personal Bank Account Number"
              name="personal_bank_account_number"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="S Corp Bank Name"
              name="s_corp_bank_name"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="S Corp Routing Number"
              name="s_corp_routing_number"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="S Corp Account Number"
              name="s_corp_account_number"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="ZipForm User Name"
              name="zipform_user_name"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="ZipForm Password"
              name="zipform_password"
            >
              <Input />
            </Form.Item>

            <Form.Item label="Are you about to place an offer for a buyer or sign a contract for buyer/seller?" name="about_to_place_offer">
              <Select>
                <Select.Option value="1">Yes</Select.Option>
                <Select.Option value="0">No</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Will you be transferring any active listings or buyer deals over?" name="transfer_listing_or_deal">
              <Select>
                <Select.Option value="1">Yes</Select.Option>
                <Select.Option value="0">No</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Save Payment Info
              </Button>
            </Form.Item>
          </Form>
        )}

      </Wrap>
    )
  }
}

export default Container(withRouter(PaymentInfoTC))
