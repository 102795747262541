
import {
  call, put, throttle, takeLatest, select,
} from "redux-saga/effects"
import { isSuccess } from "services/Utils"
import { message, Modal } from "antd"
import moment from "moment"
import uuid from "uuid"
import root from "window-or-global"
import { get } from "lodash"
import {
  getSecureIdAPI,
} from "container/BrokerDashboard/api"
import * as AIDashboardAction from "./actions"
import * as AIDashboardAPIs from "./api"
import { toast } from "react-toastify"

const internalID = process.env.REACT_APP_SECRET_ID

const baseUrl = process.env.REACT_APP_API_BASE_URL

function* getSecureIdForAgent(agentId) {
  try {
    const secureRes = yield call(getSecureIdAPI, agentId)
    if (isSuccess(secureRes)) {
      const secureId = get(secureRes, "data.response.secure_id")
      return secureId
    }
  } catch (error) {
    console.log("error", error)
  }
  return ""
}

function* fetchAgentSearch(action) {
  try {
    const { search } = action.data

    const payload = {
      text_value: search,
      sortIsAsc: false,
      ascending: false,
      facade: false,
      from: 0,
      size: 10,
      text_search_params: ["email", "full_name"],
      unRegistered: false,
    }

    const res = yield call(AIDashboardAPIs.fetchAgentAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AIDashboardAction.fetchAgentAction.success(response))
    }
  } catch (error) {
    TriggerError()
    yield put(AIDashboardAction.fetchAgentAction.failure(error))
  }
}

function* handleRarTeamList(action) {
  try {
    const {
      skip = 0,
      limit = 300,
      fromTeamCreatedDate,
      toTeamCreatedDate,
      minTeamMembers,
      maxTeamMembers,
      minPastProduction,
      maxPastProduction,
      primarySaleRepresentative,
      secondarySaleRepresentative,
      pastBrokerageName,
      state,
    } = action.data
    let query = ""
    query = `?skip=${skip}&limit=${limit}&noparam=false`
    if (fromTeamCreatedDate) {
      query += `&fromTeamCreatedDate=${fromTeamCreatedDate}`
    }
    if (toTeamCreatedDate) {
      query += `&toTeamCreatedDate=${toTeamCreatedDate}`
    }
    if (minTeamMembers) {
      query += `&minTeamMembers=${minTeamMembers}`
    }
    if (maxTeamMembers) {
      query += `&maxTeamMembers=${maxTeamMembers}`
    }
    if (minPastProduction) {
      query += `&minPastProduction=${minPastProduction}`
    }
    if (maxPastProduction) {
      query += `&maxPastProduction=${maxPastProduction}`
    }
    if (primarySaleRepresentative) {
      query += `&primarySaleRepresentative=${primarySaleRepresentative}`
    }
    if (secondarySaleRepresentative) {
      query += `&secondarySaleRepresentative=${secondarySaleRepresentative}`
    }
    if (pastBrokerageName) {
      query += `&pastBrokerageName=${pastBrokerageName}`
    }
    if (state) {
      query += `&state=${state}`
    }
    const res = yield call(AIDashboardAPIs.getRARTeamListAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AIDashboardAction.fetchTeamsAction.success(response))
    }
  } catch (error) {
    TriggerError()
    yield put(AIDashboardAction.fetchTeamsAction.failure(error))
  }
}

function* handleSubmitQueryToChat(action) {
  try {
    const {
      payload,
      agentId,
      sessionId,
    } = action.data || {}

    const secureId = yield getSecureIdForAgent(agentId)

    const url = `${baseUrl}/v1.0/agent-ally/query?session_id=${sessionId}`

    const customAPICallForMessageQuery = yield fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "secure-id": secureId,
      },
    })

    const sessionResponse = yield customAPICallForMessageQuery.json()

    if (customAPICallForMessageQuery && customAPICallForMessageQuery.status === 200) {
      const { response } = sessionResponse

      console.log("query", response)

      yield put(AIDashboardAction.submitQueryToChatAction.success(response))
    }
  } catch (e) {
    TriggerError()
    yield put(AIDashboardAction.submitQueryToChatAction.failure(e))
  }
}

function* handleToggleAIInChat(action) {
  try {
    const {
      payload,
      agentId,
      currentTopics,
      markAsResolved,
    } = action.data || {}

    const url = `${baseUrl}/v1.0/agent-ally/toggle_ai`

    const customAPICallforTogglingAI = yield fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "internal-id": internalID,
        "agent-id": agentId,
      },
    })

    const sessionResponse = yield customAPICallforTogglingAI.json()

    if (customAPICallforTogglingAI && customAPICallforTogglingAI.status === 200) {
      const { response } = sessionResponse

      currentTopics.topics.forEach((item) => {
        if (item.session_id === payload.session_id) {
          // eslint-disable-next-line no-param-reassign
          item.ai_response_on = payload.activate
        }
      })

      yield put(AIDashboardAction.setCurrentSessionsArrayAction.call({
        currentTopics: {
          ...currentTopics,
        },
      }))

      yield put(AIDashboardAction.toggleAIinChatAction.success(response))

      if (markAsResolved) {
        yield put(AIDashboardAction.markAsResolvedAction.request({
          agentId,
          sessionId: payload.session_id,
        }))
      }
    }
  } catch (e) {
    TriggerError()
    yield put(AIDashboardAction.toggleAIinChatAction.failure(e))
  }
}

function* handleMarkAsResolved(action) {
  //work in progress.
  try {
    const {
      agentId,
      sessionId,
    } = action.data

    const url = `${baseUrl}/v1.0/agent-ally/mark_resolved`

    const payload = {
      session_id: sessionId,
      mark_resolved: 1,
    }

    const customAPIforMarkAsResolved = yield fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "internal-id": internalID,
        "agent-id": agentId,
      },
    })

    const sessionResponse = yield customAPIforMarkAsResolved.json()

    if (customAPIforMarkAsResolved && customAPIforMarkAsResolved.status === 200) {
      const { response } = sessionResponse
      yield put(AIDashboardAction.markAsResolvedAction.success(response))
      TriggerSuccess("Session marked as resolved.")
    }
  } catch (e) {
    yield put(AIDashboardAction.markAsResolvedAction.failure(e))
  }
}

function* handleFetchAIChatSessions(action) {
  try {
    const {
      query = "",
      sessionId = "",
    } = action.data || {}

    const res = yield call(AIDashboardAPIs.fetchAIChatSessionAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data

      if (response !== undefined && response && Object.keys(response).length > 0) {
        const agentName = Object.keys(response)[0]
        const agentId = response[agentName].agent_id
        const chats = response[agentName].chat_sessions
        const firstChatSessionID = chats && chats.length > 0 && chats[0].session_id || ""

        const secureId = yield getSecureIdForAgent(agentId)
        const setTopic = {
          currentAgentKey: agentName,
          topics: chats,
          data: response[agentName],
        }

        yield put(AIDashboardAction.fetchAgentChatSessionAction.request({
          secureId,
          currentTopics: setTopic,
          sessionId,
        }))

        if (firstChatSessionID) {
          yield put(AIDashboardAction.setCurrentAgentSelectedForPubnubAction.call(agentId))

          yield put(AIDashboardAction.fetchSessionHistoryAction.request({
            limit: 100,
            offset: 0,
            sessionId: firstChatSessionID,
            agentId,
          }))
        } else {
          yield put(AIDashboardAction.setCurrentSessionsArrayAction.call({
            currentTopics: {
              currentAgentKey: null,
              topics: [],
              data: null,
            },
          }))
        }
      } else {
        yield put(AIDashboardAction.setCurrentSessionsArrayAction.call({
          currentTopics: {
            currentAgentKey: null,
            topics: [],
            data: null,
          },
        }))
      }
      yield put(AIDashboardAction.fetchAIChatSessionsAction.success(response))
    }
  } catch (e) {
    yield put(AIDashboardAction.fetchAIChatSessionsAction.failure(e))
  }
}

function* handleFetchAIAnalytics(action) {
  try {
    const {
      query,
    } = action.data || {}

    const res = yield call(AIDashboardAPIs.fetchAIAnalyticsAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AIDashboardAction.fetchAIAnalyticsAction.success(response))
    }
  } catch (e) {
    TriggerError()
    yield put(AIDashboardAction.fetchAIAnalyticsAction.failure(e))
  }
}

function* handleFetchTrainedDocuments(action) {
  try {
    const {
      skip=0,
      complianceState="",
        category="",
        filename="",
        startDate="",
        endDate=""
    } = action.data || {}
    let query = ""
    // query = `?skip=${skip}&noparam=false`
    query = ""
    if (complianceState) {
      query += `&compliance_state=${complianceState}`
    }
    if (category) {
      query += `&category=${category}`
    }
    if (filename) {
      query += `&document_name=${filename}`
    }
    if (startDate) {
      query += `&start_date=${startDate}`
    }
    if (endDate) {
      query += `&end_date=${endDate}`
    }
    const res = yield call(AIDashboardAPIs.fetchTrainedDocumentsAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AIDashboardAction.fetchTrainedDocumentsAction.success(response))
    }
  } catch (e) {
    TriggerError()
    yield put(AIDashboardAction.fetchTrainedDocumentsAction.failure(e))
  }
}

function* handleDeleteTrainedDocuments(action) {
  const {
    payload
  } = action.data

  try {
    const res = yield call(AIDashboardAPIs.deleteTrainedDocumentsAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AIDashboardAction.deleteTrainedDocumentsAction.success(response))
      toast.success("Document Deleted")
      root.location.reload()
      console.log("deleted")
    }
  } catch (error) {
    yield put(AIDashboardAction.deleteTrainedDocumentsAction.failure(error))
  }
}

function* handleUploadLinkToFile(action) {
  const {
    payload
  } = action.data

  try {
    const res = yield call(AIDashboardAPIs.uploadLinkToFileAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AIDashboardAction.uploadLinkToFileAction.success(response))
      toast.success("Document Uploaded")
      root.location.reload()
      console.log("uploaded")
    }
  } catch (error) {
    yield put(AIDashboardAction.uploadLinkToFileAction.failure(error))
  }
}

function* handleFetchSessionHistory(action) {
  try {
    const {
      limit,
      offset = 0,
      sessionId,
      agentId,
      existingChats = [],
    } = action.data || {}

    message.info("Fetching chats...")

    const secureId = yield getSecureIdForAgent(agentId)

    const getQuery = () => {
      let query = ""
      if (limit) {
        query += `limit=${limit}&`
      }

      if (offset) {
        query += `offset=${offset}&`
      }

      if (sessionId) {
        query += `session_id=${sessionId}&`
      }
      return query
    }

    const url = `${baseUrl}/v1.0/agent-ally/chat_history?${getQuery()}`

    const customAPICallForSessions = yield fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "secure-id": secureId,
        "agent-id": agentId,
      },
    })

    const sessionResponse = yield customAPICallForSessions.json()

    if (customAPICallForSessions && customAPICallForSessions.status === 200) {
      const { response } = sessionResponse
      const { chat_history } = response || {}
      if (agentId) {
        yield put(AIDashboardAction.setCurrentAgentSelectedForPubnubAction.call(agentId))
      }
      yield put(AIDashboardAction.setCurrentSessionIdAction.call(sessionId))
      yield put(AIDashboardAction.fetchSessionHistoryAction.success(response))
      yield put(AIDashboardAction.setCurrentChatForDetailsViewAction.call({
        chats: [...existingChats, ...chat_history],
      }))
      message.success("Done!")
    } else {
      throw new Error("failed api call")
    }
  } catch (e) {
    TriggerError()
    yield put(AIDashboardAction.fetchSessionHistoryAction.failure(e))
  }
}

function* handleFetchQuery(action) {
  const {
    constructed,
    session_id: sessionId,
  } = action.data

  let queryStr = ""

  //make string of query
  Object.keys(constructed).forEach((item) => {
    if (item === "forFilter") {
      queryStr += `${constructed[item].status ? `status=${constructed[item].status}&` : ""}`
    } else if (constructed[item] !== undefined && constructed[item]) {
      queryStr += `${item}=${constructed[item]}&`
    }
  })

  yield put(AIDashboardAction.fetchAIAnalyticsAction.request({
    query: queryStr,
  }))

  yield put(AIDashboardAction.fetchAIChatSessionsAction.request({
    query: queryStr,
    sessionId,
  }))
}

function* handleFetchAgentChatSessions(action) {
  try {
    const {
      secureId,
      agentId,
      currentTopics = null,
      sessionId,
    } = action.data || {}

    message.info("Fetching agent sessions..")

    let secId = secureId

    if (!secId) {
      secId = yield getSecureIdForAgent(agentId)
    }

    let urlForSession = `${baseUrl}/v1.0/agent-ally/fetch_chat_session`

    if (sessionId) {
      urlForSession += `?session_id=${sessionId}`
    }

    const customAPIForGettingAgentSession = yield fetch(urlForSession, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "secure-id": secId,
      },
    })

    const sessionResponse = yield customAPIForGettingAgentSession.json()

    if (customAPIForGettingAgentSession && customAPIForGettingAgentSession.status === 200) {
      const { response } = sessionResponse

      yield put(AIDashboardAction.setCurrentAgentSelectedForPubnubAction.call(agentId))

      if (currentTopics) {
        const update = (topics) => {
          const tempTopics = topics
          if (!response) {
            return topics
          }

          const {
            sessions,
          } = response || {}

          for (let j = 0; j < topics.length; j += 1) {
            const filtered = sessions.filter((item) => {
              const topicId = parseInt(tempTopics[j].session_id, 10)
              const sessionUID = parseInt(item.id, 10)
              return topicId === sessionUID
            }) || []

            if (filtered && filtered.length > 0) {
              tempTopics[j].ai_response_on = filtered[0].ai_response_on
            }
          }

          return tempTopics
        }

        const updatedTopicsWithAISessionBool = {
          currentAgentKey: currentTopics.currentAgentKey,
          topics: update(currentTopics.topics),
          data: currentTopics.data,
        }

        yield put(AIDashboardAction.setCurrentSessionsArrayAction.call({
          currentTopics: updatedTopicsWithAISessionBool,
        }))
      }

      //empty current session
      yield put(AIDashboardAction.setCurrentChatForDetailsViewAction.call({
        chats: [],
      }))

      yield put(AIDashboardAction.fetchAgentChatSessionAction.success(response))
      message.success("Done")
    } else {
      throw new Error("failed api call")
    }
  } catch (e) {
    TriggerError()
    yield put(AIDashboardAction.fetchAgentChatSessionAction.failure(e))
  }
}

const TriggerError = (msg) => {
  message.error("Oops, something went wrong, check the message below.")
  Modal.error({
    content: msg || "Server down, contact backend team to resolve.",
  })
}

const TriggerSuccess = (msg) => {
  message.info(`Task completed : ${msg}`)
  Modal.success({
    content: msg || "Task done.",
  })
}

export default function* main() {
  yield takeLatest(AIDashboardAction.submitQueryToChatAction.REQUEST, handleSubmitQueryToChat)
  yield takeLatest(AIDashboardAction.toggleAIinChatAction.REQUEST, handleToggleAIInChat)
  yield takeLatest(AIDashboardAction.markAsResolvedAction.REQUEST, handleMarkAsResolved)
  yield takeLatest(AIDashboardAction.fetchAIChatSessionsAction.REQUEST, handleFetchAIChatSessions)
  yield takeLatest(AIDashboardAction.fetchAIAnalyticsAction.REQUEST, handleFetchAIAnalytics)
  yield takeLatest(AIDashboardAction.fetchSessionHistoryAction.REQUEST, handleFetchSessionHistory)
  yield takeLatest(AIDashboardAction.fetchAgentAction.REQUEST, fetchAgentSearch)
  yield takeLatest(AIDashboardAction.fetchTeamsAction.REQUEST, handleRarTeamList)
  yield takeLatest(AIDashboardAction.constructQueryAction.type, handleFetchQuery)
  yield takeLatest(AIDashboardAction.fetchAgentChatSessionAction.REQUEST, handleFetchAgentChatSessions)
  yield takeLatest(AIDashboardAction.fetchTrainedDocumentsAction.REQUEST, handleFetchTrainedDocuments)
  yield takeLatest(AIDashboardAction.deleteTrainedDocumentsAction.REQUEST, handleDeleteTrainedDocuments)
  yield takeLatest(AIDashboardAction.uploadLinkToFileAction.REQUEST, handleUploadLinkToFile)
}

