import styled from "styled-components"

export const ResetButton = styled.button`
  background-color: red;
  cursor: pointer;
  color: white;
  border: none;
  height: 41px;
  width: max-content;
  padding: 10px;
`
