import axios from "axios";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
console.log("pragya");

export const fetchWaitlistAPI = (startDate, endDate, status, searchText, limit, skip) =>
  axios.get(
    `${baseUrl}/v1.0/consumerapp/soul/get-app-requests?start_date=${startDate}&end_date=${endDate}&status=${status}&searchText=${searchText}&limit=${limit}&skip=${skip}`
  );

export const saveWaitlistStatusAPI = (id, requestPayload) =>
  axios.post(`${baseUrl}/v1.0/consumerapp/update-app-request/${id}`, requestPayload);

export const uploadAppAssetsAPI = (payload) =>
  axios.post(`${baseUrl}/v1.0/consumer-app/uploads/app-assets`, payload);

export const updateAppCustomisationsAPI = (payload) =>
  axios.post(`${baseUrl}/v1.0/consumerapp/update-app-customizations/${payload.id}`, payload.payload);

export const fetchAppCustomisationsAPI = (id) =>
  axios.get(`${baseUrl}/v1.0/consumerapp/soul/get-app-settings/${id}`);
