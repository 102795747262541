import React, { Component } from "react"
import styled from "styled-components"
import moment from "moment"
import TechAssetsContainer from "container/BrokerDashboard/globalPagesContainer"
import Loader from "dumbComponents/common/UI/Loader"
import { Tabs, Table, Select, Button, Input, Spin } from "antd"
import { Switch, Route, Redirect, withRouter } from "react-router-dom"
// import { TRANSACTION_TYPE } from "../../FieldData"
// import { getSource } from "../../Util"

const Wrap = styled.div``

const Row = styled.div`
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  gap: 14px;

  ${props => props.noMargin && `
    margin-top: 0px;
  `}
  ${props => props.withBorders && `
  `}
`

const Col = styled.div`
  display: none;
  width: 226px;
  margin-right: 20px;
  margin-bottom: 20px;
  &:last-child {
    margin-right: 0px;
  }

  ${props => props.show && `
    display: block;
  `}
  ${props => props.noMargin && `
    margin: 0px !important;
  `}
`

const LabelAndTextWrap = styled.div`
  flex: 1;
  h5 {
    font-size: 12px;
    text-transform: uppercase;
    color: #555;
    margin: 0px;
    ${props => props.underline && `
      border-bottom: 1px solid #555;
    `}
  }
  p {
    color: #111;
    margin: 6px 0px 0px 0px;
  }
`

const SectionWrap = styled.div`
  display: none;
  padding: 0px 20px 20px 54px;
  border-radius: 20px;
  border: 1px solid #ccc;
  position: relative;
  margin-bottom: 40px;
  margin-top: 10px;

  > h6 {
    top: -16px;
    left: 40px;
    position: absolute;
    background: #fff;
    padding: 5px 16px;
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
    color: #999;
  }

  ${props => props.show && `
    display: block;
   `}
`
const CouponDiv = styled.div`
  width: 727px;
  padding: 5px;
  color: #FFFFFF;
  height: 35px;
  h3 {
    color: black;
    font-size: 14px;
    font-weight: 200;
  }
`

const LabelAndText = ({
  title,
  data,
  children,
  underline,
}) => (
  <LabelAndTextWrap underline={underline}>
    {title && (
      <h5>{title.replaceAll("_", " ")}</h5>
    )}
    {data && (
      <p>{data}</p>
    )}
    {children && (
      <div>
        {children}
      </div>
    )}
  </LabelAndTextWrap>
)

const listPriceTypes = ["new_listing", "listing_with_accepted_offer", "dual", "lease_listing"]
const salesPriceTypes = ["external_referral_agreement", "pending_contract", "dual"]
const rentPriceTypes = ["pending_lease", "lease_listing"]
const escrowFormFileTypes = ["dual", "pending_contract", "external_referral_agreement"]

class ViewListings extends Component {
  componentDidMount() {
    // gapi.load("client:auth2", this.fetchGoogleContacts)
    //listing cover
    const { getListingCoverSheet, dealToView, dealAgentId, match, location } = this.props
    console.log("===match====", match)
    getListingCoverSheet({
      dealAgentId: match.params.agentId,
      //here
      dealId: parseInt(location.search.split("=")[1], 10),
      callAPI: ["agent_info", "seller_info", "source_time", "property_info", "tc_info", "sign_post"],
    })
  }

  render() {
    const { getListingCoverSheetResponse, couponClaimed } = this.props
    const agentInfo = getListingCoverSheetResponse.data?.agentInfo || []
    const sellerInfo = getListingCoverSheetResponse.data?.sellerInfo || []
    const sourceTime = getListingCoverSheetResponse.data?.sourceTime || {}
    const propertyInfo = getListingCoverSheetResponse.data?.propertyInfo || {}
    const tcInfo = getListingCoverSheetResponse.data?.tcInfo || {}
    const signPost = getListingCoverSheetResponse.data?.signPost || {}
    return (
      <>
        {getListingCoverSheetResponse.isFetching && (
          <Loader />
        )}
        {getListingCoverSheetResponse.data && (
          <Wrap>
            <SectionWrap show>
              <h6>
                Agent Info
              </h6>
              {agentInfo && agentInfo.length > 0 && agentInfo.map((agent, index) => (
                <Row>
                  <Col show>
                    <LabelAndText
                      title="Is Primary"
                      data={(agent.is_primary === "Y" || agent.is_primary === 1) ? "YES" : "NO"}
                    />
                  </Col>
                  <Col show>
                    <LabelAndText
                      title="Name"
                      data={agent.name}
                    />
                  </Col>
                  <Col show>
                    <LabelAndText
                      title="Email"
                      data={agent.email}
                    />
                  </Col>
                  <Col show>
                    <LabelAndText
                      title="Phone"
                      data={agent.phone}
                    />
                  </Col>
                  <Col show>
                    <LabelAndText
                      title="City"
                      data={agent.city}
                    />
                  </Col>
                  <Col show>
                    <LabelAndText
                      title="State"
                      data={agent.state}
                    />
                  </Col>
                  <Col show>
                    <LabelAndText
                      title="Zipcode"
                      data={agent.zipcode}
                    />
                  </Col>
                </Row>
              ))}
            </SectionWrap>
            <SectionWrap show>
              <h6>
                Seller Info
              </h6>
              <Row>
                <Col show>
                  <LabelAndText
                    title="No of Clients"
                    data={sellerInfo ? sellerInfo.length : 0}
                  />
                </Col>
              </Row>
              <br />
              {sellerInfo && sellerInfo.length > 0 && (
                <Table
                  dataSource={sellerInfo}
                  scroll={{ x: 540 }}
                  columns={[
                    {
                      title: "Is Primary",
                      dataIndex: "is_primary",
                      key: "is_primary",
                    },
                    {
                      title: "Name",
                      dataIndex: "name",
                      key: "name",
                    },
                    {
                      title: "Email",
                      dataIndex: "email",
                      key: "email",
                    },
                    {
                      title: "Phone",
                      dataIndex: "phone",
                      key: "phone",
                    },
                    {
                      title: "Address",
                      dataIndex: "address",
                      key: "address",
                    },
                    {
                      title: "Type Of Ownership",
                      dataIndex: "type_of_ownership",
                      key: "type_of_ownership",
                    },
                    {
                      title: "Ownership Name",
                      dataIndex: "ownership_name",
                      key: "ownership_name",
                    },
                    {
                      title: "Ownership Authorizer",
                      dataIndex: "ownership_authorizer",
                      key: "ownership_authorizer",
                    },
                    {
                      title: "Ownership Email",
                      dataIndex: "ownership_email",
                      key: "ownership_email",
                    },
                    {
                      title: "Ownership Mobile",
                      dataIndex: "ownership_mobile",
                      key: "ownership_mobile",
                    },
                  ]}
                />
              )}

              {/* {sellerInfo && sellerInfo.length > 0 && sellerInfo.map((client, index) => (
                <Row key={client.email} noMargin withBorders>
                  <Col show noMargin style={{ width: 30, textAlign: "center" }}>
                    <LabelAndText
                      title={index === 0 ? "S/n" : ""}
                      underline={index === 0}
                      data={index + 1}
                    />
                  </Col>
                  <Col show>
                    <LabelAndText
                      title="Is Primary"
                      data={client.is_primary === "Y" ? "YES" : "NO"}
                    />
                  </Col>
                  <Col show noMargin>
                    <LabelAndText
                      title={index === 0 ? "Name" : ""}
                      underline={index === 0}
                      data={client.name}
                    />
                  </Col>
                  <Col show noMargin>
                    <LabelAndText
                      title={index === 0 ? "Email" : ""}
                      underline={index === 0}
                      data={client.email}
                    />
                  </Col>
                  <Col show noMargin>
                    <LabelAndText
                      title={index === 0 ? "Phone" : ""}
                      underline={index === 0}
                      data={client.phone}
                    />
                  </Col>
                  <Col show noMargin>
                    <LabelAndText
                      title={index === 0 ? "Address" : ""}
                      underline={index === 0}
                      data={`${client.address || ""} ${client.city || ""} ${client.state || ""} ${client.zipcode || ""}`}
                    />
                  </Col>
                  <Col show noMargin>
                    <LabelAndText
                      title={index === 0 ? "Type Of Ownership" : ""}
                      underline={index === 0}
                      data={`${client.type_of_ownership || ""} - [ name: ${client.ownership_name || ""} | Authorizer: ${client.ownership_authorizer || ""} | Email: ${client.ownership_email || ""} ] | Mobile: ${client.ownership_mobile || ""} ]`}
                    />
                  </Col>
                </Row>
              ))} */}
            </SectionWrap>
            <SectionWrap show>
              <h6>
                Important details
              </h6>
              <Row>
                <Col>
                  <LabelAndText
                    title="Source"
                    data={sourceTime.source}
                  />
                </Col>
                <Col show>
                  <LabelAndText
                    title="Listing Contract Date"
                    data={`${sourceTime.listing_contract_date ? moment.unix((new Date(sourceTime.listing_contract_date).getTime())).format("D MMM, YYYY") : "N/A"}`}
                  />
                </Col>
                <Col show>
                  <LabelAndText
                    title="Listing Expiration Date"
                    data={`${sourceTime.listing_expiration_date ? moment.unix((new Date(sourceTime.listing_expiration_date).getTime())).format("D MMM, YYYY") : "N/A"}`}
                  />
                </Col>
                <Col show>
                  <LabelAndText
                    title="Listing On Market Date"
                    data={`${sourceTime.listing_on_market_date ? moment.unix((new Date(sourceTime.listing_on_market_date).getTime())).format("D MMM, YYYY") : "N/A"}`}
                  />
                </Col>
              </Row>
            </SectionWrap>

            <SectionWrap show>
              <h6>
                Property Information
              </h6>
              <Row>
                <Col show>
                  <LabelAndText
                    title="Price"
                    data={propertyInfo.price}
                  />
                </Col>
                <Col show>
                  <LabelAndText
                    title="APN Number"
                    data={propertyInfo.apn_number}
                  />
                </Col>
                <Col show>
                  <LabelAndText
                    title="Street"
                    data={propertyInfo.street}
                  />
                </Col>
                <Col show>
                  <LabelAndText
                    title="Apt"
                    data={propertyInfo.apt}
                  />
                </Col>
                <Col show>
                  <LabelAndText
                    title="City"
                    data={propertyInfo.city}
                  />
                </Col>
                <Col show>
                  <LabelAndText
                    title="State"
                    data={propertyInfo.state}
                  />
                </Col>
                <Col show>
                  <LabelAndText
                    title="Zipcode"
                    data={propertyInfo.zipcode}
                  />
                </Col>
                <Col show>
                  <LabelAndText
                    title="County"
                    data={propertyInfo.county}
                  />
                </Col>
              </Row>
            </SectionWrap>

            <SectionWrap show>
              <h6>
                Transaction Information
              </h6>
              <Row>
                <Col show>
                  <LabelAndText
                    title="Want to use TC for Deal"
                    data={tcInfo.is_using_deal_tc === null ? "N/A" : tcInfo.is_using_deal_tc === 1 ? "Yes" : "No"}
                  />
                </Col>
                {/* <Col show>
                  <LabelAndText
                    title="Want radius TC to open Escrow"
                    data={tcInfo.is_radius_tc_to_open_escrow === null ? "N/A" : tcInfo.is_radius_tc_to_open_escrow === 1 ? "Yes" : "No"}
                  />
                </Col> */}
                {/* {tcInfo.is_using_deal_tc && (
                  <>
                    <Col show>
                      <LabelAndText
                        title="TC Name"
                        data={tcInfo.tc_name}
                      />
                    </Col>
                    <Col show>
                      <LabelAndText
                        title="TC Email"
                        data={tcInfo.tc_email}
                      />
                    </Col>
                    <Col show>
                      <LabelAndText
                        title="TC Phone"
                        data={tcInfo.tc_phone}
                      />
                    </Col>
                  </>
                )} */}
              </Row>
            </SectionWrap>

            <SectionWrap show>
              <h6>
                Listing/Deal Details
              </h6>
              <Row>
                <Col show>
                  <LabelAndText
                    title="Is all cash offer?"
                    data={tcInfo.is_all_cash_offer ? "Yes" : "No"}
                  />
                </Col>
                <Col show={!tcInfo.is_all_cash_offer}>
                  <LabelAndText
                    title="Lender Email Address"
                    data={tcInfo.lender_email_address || "N/A"}
                  />
                </Col>
              </Row>
            </SectionWrap>

            <SectionWrap show>
              <h6>
                Sign and Post Details
              </h6>
              <Row>
                <Col show>
                  <LabelAndText
                    title="Schedule Sign Post Installation"
                    data={signPost.schedule_sign_post_installation === null ? "N/A" : signPost.schedule_sign_post_installation === 1 ? "Yes" : "No"}
                  />
                </Col>
                <>
                  <Col show>
                    <LabelAndText
                      title="HOA Guidelines"
                      data={signPost.hoa_guidelines}
                    />
                  </Col>
                  <Col show>
                    <LabelAndText
                      title="Sign Post Installation Location"
                      data={signPost.sign_post_installation_location}
                    />
                  </Col>
                  <Col show>
                    <LabelAndText
                      title="Sign Post Installation On"
                      data={`${signPost.sign_post_installation_on ? moment.unix((new Date(signPost.sign_post_installation_on).getTime())).format("D MMM, YYYY") : "N/A"}`}
                    />
                  </Col>
                </>
              </Row>
            </SectionWrap>


          </Wrap>
        )}
      </>
    )
  }
}

export default withRouter(TechAssetsContainer(ViewListings))
