import axios from "axios"

const baseUrl = process.env.REACT_APP_API_BASE_URL
const legacyURL = `${process.env.REACT_APP_API_BASE_URL}/v1.0`

export const submitQueryToChatAPI = payload => axios
  .post(`${baseUrl}/v1.0/agent-ally/query`, payload)

export const toggleAIinChatAPI = payload => axios
  .post(`${baseUrl}/v1.0/agent-ally/toggle_ai`, payload)

export const markAsResolvedAPI = payload => axios
  .post(`${baseUrl}/v1.0/agent-ally/mark_resolved`, payload)

export const fetchAIChatSessionAPI = query => axios
  .get(`${baseUrl}/v1.0/agent-ally/ai_chats${query ? `?${query}` : ""}`)

export const fetchAIAnalyticsAPI = query => axios
  .get(`${baseUrl}/v1.0/agent-ally/ai_chat_feedback_analytics?${query}`)

export const fetchSessionHistoryAPI = (limit, offset, sessionId) => axios
  .get(`${baseUrl}/v1.0/agent-ally/chat_history?limit=${limit}&offset=${offset}&session_id=${sessionId}`)

export const fetchAgentAPI = payload => axios
  .post(`${baseUrl}/v1.0/agent/elastic/query`, payload)

export const getRARTeamListAPI = (query = "") => axios.get(`${legacyURL}/brokerage-teams${query}`)

export const fetchTrainedDocumentsAPI = (query) => axios.get(`${baseUrl}/v1.0/agent-ally/display_documents${query ? `?${query}` : ""}`)

export const deleteTrainedDocumentsAPI = payload => axios.post(`${baseUrl}/v1.0/agent-ally/delete_trained_documents`, payload)

export const uploadLinkToFileAPI = (payload) => axios.post(`${baseUrl}/v1.0/agent-ally/insert_knowledge_base_urls`,payload)