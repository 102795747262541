export const SIGN_UP_FORM_AGENT_TYPE = [
  { value: "real_estate_agent", label: "Real Estate Agent" },
  { value: "loan_officer", label: "Loan Officer" },
  { value: "buyer_seller", label: "Buyer/Seller" },
  { value: "other", label: "Other" },
]

export const SALES_REPRESENTATIVE = [
  { label: "Yes", value: 1 },
  { label: "No", value: 0 },
]

export const SALES_REPRESENTATIVE_OPTIONS = [
  {
    label: "David Cheatham",
    value: "David Cheatham",
  },
  {
    label: "Julia Walter",
    value: "Julia Walter",
  },
  {
    label: "Justin Rush",
    value: "Justin Rush",
  },
  {
    label: "Kristi Wiles",
    value: "Kristi Wiles",
  },
  {
    label: "Sandi Rockwood",
    value: "Sandi Rockwood",
  },
  {
    label: "Rhonda Lawson",
    value: "Rhonda Lawson",
  },
  {
    label: "Julie Rose",
    value: "Julie Rose",
  },
  {
    label: "Tiffany Klem",
    value: "Tiffany Klem",
  },
  {
    label: "Legacy",
    value: "Legacy",
  },
]
