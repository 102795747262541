import { call, put, throttle, takeLatest, select } from "redux-saga/effects"
import { isSuccess } from "services/Utils"
import { notify } from "react-notify-toast"
import * as AgentComparisonActions from "./actions"
import { makeConferenceCall } from "components/API/CallerApi";
import { applyAutoReferOutSettings } from "container/Agent/api"
import * as APIS from "./api"
import { get } from "lodash";

function* handleGetAgentsForMatch(action) {
  try {
    const { referralId, limit = 200, isReloadProspects, query = "" } = action.data
    const res = yield call(APIS.getAgentsForMatchingAPI, referralId, limit, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AgentComparisonActions.getAgentsForComparisonAction.success(response))
    }
  } catch (error) {
    yield put(AgentComparisonActions.getAgentsForComparisonAction.failure(error))
  }
}

function* handleAssignAgent(action) {
  try {
    const { payload, clientId } = action.data
    const res = yield call(APIS.assignAgentAPI, clientId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AgentComparisonActions.assignAgentAction.success(response))
      yield put(AgentComparisonActions.getAgentsForComparisonAction.request({
        referralId: payload.referral_id,
      }))
      yield put(AgentComparisonActions.getLMPStatusAction.request({
        referralId: payload.referral_id,
      }))
    }
  } catch (error) {
    yield put(AgentComparisonActions.assignAgentAction.failure(error))
    const message = get(error, "response.data.error.message")
    notify.show(message, "error")
  }
}

function* handleSearch(action) {
  try {
    const {
      searchText,
      referralId,
      locationPayload,
      language,
      closed_referral_percentage,
      isFrom,
      payload: searchPayload,
    } = action.data
    let payload = {
      facade: true,
      from: 0,
      findAll: searchText,
      size: 200,
      referral_id: referralId,
    }
    if (locationPayload) {
      payload = {
        ...payload,
        ...locationPayload,
      }
    }
    if (isFrom === "search-results") {
      payload = {
        ...payload,
        ...searchPayload,
      }
    }
    if (language) {
      payload = {
        ...payload,
        language,
      }
    }
    if (closed_referral_percentage) {
      payload = {
        ...payload,
        closed_referral_percentage,
      }
    }
    const res = yield call(APIS.agentSearcAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AgentComparisonActions.searchAgentAction.success(response))
    }
  } catch (error) {
    yield put(AgentComparisonActions.searchAgentAction.failure(error))
  }
}

// function* handleLiveTransfer(action) {
//   try {
//     const { payload } = action.data
//   } catch (error) {
     
//   }
// }

function* handleRemoveFromSuggestions(action) {
  try {
    const { payload, referralId } = action.data
    const res = yield call(APIS.removeFromSuggestinsAPI, referralId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AgentComparisonActions.removeFromSuggestionsAction.success(response))
      yield put(AgentComparisonActions.getAgentsForComparisonAction.request({
        referralId,
      }))
    }
  } catch (error) {
    yield put(AgentComparisonActions.removeFromSuggestionsAction.failure(error))
  }
}

function* handleInviteAgent(action) {
  try {
    const { agentId, referralId, isFromList } = action.data
    const payload = {
      agent_id: agentId,
      prospect_status: 0,
      type: "qualification",
      visible: 1,
    }
    const res = yield call(APIS.inviteAgentAPI, referralId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AgentComparisonActions.inviteAgentAction.success(response))
      // const searchResultAgents = yield select(state => state.agentComparison.searchAgentResponse.data)
      // if (searchResultAgents && searchResultAgents.agents) {
      //   const invitedAgent = searchResultAgents.agents.find(x => x.agent_id === agentId)
      //   if (invitedAgent) {
      //     const mainList = yield select(state => state.agentComparison.agentRecomendationsResponse.data)
      //     const mainListResponse = {
      //       ...mainList,
      //       agents: [
      //         {
      //           ...invitedAgent,
      //           prospect_status: 0,
      //         },
      //         ...mainList.agents,
      //       ],
      //     }
      //     yield put(AgentComparisonActions.getAgentsForComparisonAction.success(mainListResponse))
      //     if (isFromList) {
      //       yield put(AgentComparisonActions.getAgentsForComparisonAction.request({ referralId }))
      //     }
      //   }
      // }
      yield put(AgentComparisonActions.getAgentsForComparisonAction.request({ referralId }))
      notify.show("Agent Invited successfully", "success")
    }
  } catch (error) {
    yield put(AgentComparisonActions.inviteAgentAction.failure(error))
  }
}

function* handleGetLMPStatus(action) {
  try {
    const { referralId } = action.data
    const res = yield call(APIS.getLMPStatusAPI, referralId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AgentComparisonActions.getLMPStatusAction.success(response))
      notify.show("LMP Status Updated", "success")
    }
  } catch (error) {
    notify.show("Error updating LMP Status", "error")
    yield put(AgentComparisonActions.getLMPStatusAction.failure(error))
  }
}

function* handleChangeLMPStatus(action) {
  try {
    const { payload, referralId } = action.data
    const res = yield call(APIS.changeLMPStatusAPI, referralId, payload)
    if (isSuccess(res)) {
      yield put(AgentComparisonActions.getLMPStatusAction.request({ referralId }))
    }
  } catch (error) {
    const { referralId } = action.data
    yield put(AgentComparisonActions.toggleLMPAction.failure(error))
    yield put(AgentComparisonActions.getLMPStatusAction.request({ referralId }))
  }
}

function* handleToggleStar(action) {
  try {
    const { payload, agentId } = action.data
    const res = yield call(applyAutoReferOutSettings, agentId, payload)
    yield put(AgentComparisonActions.updateTheStarInStateAction.call({
      ...action.data,
    }))
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AgentComparisonActions.toggleStarAction.success(response))
      notify.hide()
      notify.show("Agent Stared", "success")
    }
  } catch (err) {
    yield put(AgentComparisonActions.toggleStarAction.failure(err))
  }
}

function* handleStarUpdate(action) {
  try {
    const { agentId, payload, isSeachModal } = action.data

    // For main list
    if (!isSeachModal) {
      const searchResultAgents = yield select(state => state.agentComparison.agentRecomendationsResponse.data)
      const invitedAgent = searchResultAgents.agents.find(x => x.agent_id === agentId)
      const selectedAgentIndex = searchResultAgents.agents.findIndex(x => x.agent_id === agentId)
      if (invitedAgent) {
        const mainList = yield select(state => state.agentComparison.agentRecomendationsResponse.data)
        mainList.agents[selectedAgentIndex] = {
          ...mainList.agents[selectedAgentIndex],
          starred_for_lmp: payload.starred_for_lmp,
        }
        const mainListResponse = {
          ...mainList,
          agents: [
            ...mainList.agents,
          ],
        }
        yield put(AgentComparisonActions.getAgentsForComparisonAction.success(mainListResponse))
      }
    }
    if (isSeachModal) {
      const searchResultAgents = yield select(state => state.agentComparison.searchAgentResponse.data)
      const invitedAgent = searchResultAgents.agents.find(x => x.agent_id === agentId)
      const selectedAgentIndex = searchResultAgents.agents.findIndex(x => x.agent_id === agentId)
      if (invitedAgent) {
        const mainList = yield select(state => state.agentComparison.searchAgentResponse.data)
        mainList.agents[selectedAgentIndex] = {
          ...mainList.agents[selectedAgentIndex],
          starred_for_lmp: payload.starred_for_lmp,
        }
        const mainListResponse = {
          ...mainList,
          agents: [
            ...mainList.agents,
          ],
        }
        yield put(AgentComparisonActions.searchAgentAction.success(mainListResponse))
      }
    }
  } catch (error)   {
    notify.show("Something went wrong", "error")
  }
}

export default function* main() {
  yield takeLatest(AgentComparisonActions.getAgentsForComparisonAction.REQUEST, handleGetAgentsForMatch)
  yield takeLatest(AgentComparisonActions.assignAgentAction.REQUEST, handleAssignAgent)
  yield takeLatest(AgentComparisonActions.searchAgentAction.REQUEST, handleSearch)
  yield takeLatest(AgentComparisonActions.removeFromSuggestionsAction.REQUEST, handleRemoveFromSuggestions)
  yield takeLatest(AgentComparisonActions.inviteAgentAction.REQUEST, handleInviteAgent)
  yield takeLatest(AgentComparisonActions.getLMPStatusAction.REQUEST, handleGetLMPStatus)
  yield takeLatest(AgentComparisonActions.toggleLMPAction.REQUEST, handleChangeLMPStatus)
  yield takeLatest(AgentComparisonActions.toggleStarAction.REQUEST, handleToggleStar)
  yield takeLatest(AgentComparisonActions.updateTheStarInStateAction.type, handleStarUpdate)
}
