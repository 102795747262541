import React, { Component } from "react"
import styled, { css } from "styled-components"
import colors from "lib/colors"
import Container from "container/Agent"
import Benefits from "./Benefits"
import { getDuration } from "./shared"
import root from "window-or-global"

const Wrap = styled.div`
 padding-top: 15px;
 padding-left: 15px;
 input {
  width: 160px;
  height: 26px;
  border-radius: 5px;
  border: solid 1px #e3e3e3;
  padding-left: 10px;
 }
 button {
  border: none;
  color: #0882e4;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
 }
`
const Response = styled.div`
  width: 249px;
  height: 56px;
  border-radius: 4px;
  display: flex;
  background-color: ${props => props.isValid ? "#e9fbea" : "#fbe9ea"};
  margin-top: 10px;
  p {
   font-size: 12px;
   padding: 8px;
   color: #8c8c8c;
   margin: 0;
   span {
    font-weight: bolder;
    color: #4b4444;
   }
  }
`

class Coupon extends React.Component{
 state = {
  couponInput: "",
  removedCoupon: false,
 }
 componentDidMount() {
  //
 }

 handleCouponInput = (e) => {
   this.setState({
    [e.target.name]: e.target.value,
    removedCoupon: false,
   })
 }

 handleApplyCoupon = (id) => {
  const {
   couponInput,
  } = this.state
  const {
   applyCoupon,
   currentSelectedPlan,
   currentRTLSelectedInstallmentPlan,
   currentBLUSelectedInstallmentPlan,
   currentMode,
  } = this.props

  const { pathname } = root.location || ""
  if(currentMode === "rtl") {
   applyCoupon({
    currentMode,
    agentId: parseInt(pathname.split("/")[2], 10),
    couponCode: couponInput,
    plans: [currentRTLSelectedInstallmentPlan && Object.keys(currentRTLSelectedInstallmentPlan).length > 0
     ? currentRTLSelectedInstallmentPlan.plan_id : currentSelectedPlan.plan_id]
   })
  } else if(currentMode === "blu") {
   applyCoupon({
    currentMode,
    agentId: parseInt(pathname.split("/")[2], 10),
    couponCode: couponInput,
    plans: [currentBLUSelectedInstallmentPlan && Object.keys(currentBLUSelectedInstallmentPlan).length > 0
     ? currentBLUSelectedInstallmentPlan.plan_id : currentSelectedPlan.plan_id]
   })
  } else if(currentMode === "broker") {
   applyCoupon({
    currentMode,
    agentId: parseInt(pathname.split("/")[2], 10),
    couponCode: couponInput,
    plans: [currentSelectedPlan.plan_id],
   })
  }
 }

 removeCoupon = () => {
  const {
   removeCouponAct,
   currentMode,
  } = this.props
  this.setState({
   removedCoupon: true,
   couponInput: "",
  })
  removeCouponAct({
   couponIsRemoved: true,
   currentMode,
  })
 }

 render() {
  const {
   secure_id,
   couponResponse,
   currentMode,
   rtlCoupon,
   bluCoupon,
   brokerCoupon,
  } = this.props
  const {
   data: {
    secure_id: secid,
   }
  } = secure_id || {}
  const {
   couponInput,
   removedCoupon,
  } = this.state
  const {
   data: coupon,
  } = couponResponse || {}
  return (
  <Wrap>
    <input
     type="text"
     name="couponInput"
     value={couponInput}
     onChange={e => this.handleCouponInput(e)}
     placeholder="Enter Coupon Code"
    />
    <button
     onClick={() => this.handleApplyCoupon(secid)}
    >
      Apply Coupon
    </button>
    {currentMode === "rtl" && rtlCoupon && !removedCoupon && (
     <Response isValid={rtlCoupon.isValid}>
       <div>
         <p>
          <span>
            {rtlCoupon ? rtlCoupon.couponCode : couponInput}
          </span>
          {rtlCoupon.isValid ? ` is applied. $${rtlCoupon.discountAmount} on the current invoice` : " is not applied"}
         </p>
       </div>
       {rtlCoupon && rtlCoupon.isValid && (
        <div>
          <button onClick={() => this.removeCoupon()}>Remove</button>
        </div>
       )}
     </Response>
    )}
    {currentMode === "broker" && brokerCoupon && !removedCoupon && (
     <Response isValid={brokerCoupon.isValid}>
       <div>
         <p>
          <span>
            {brokerCoupon ? brokerCoupon.couponCode : couponInput}
          </span>
          {brokerCoupon.isValid ? ` is applied. $${brokerCoupon.discountAmount} on the current invoice` : " is not applied"}
         </p>
       </div>
       {brokerCoupon && brokerCoupon.isValid && (
        <div>
          <button onClick={() => this.removeCoupon()}>Remove</button>
        </div>
       )}
     </Response>
    )}
    {currentMode === "blu" && bluCoupon && !removedCoupon && (
     <Response isValid={bluCoupon.isValid}>
       <div>
         <p>
          <span>
            {bluCoupon ? bluCoupon.couponCode : couponInput}
          </span>
          {bluCoupon.isValid ? ` is applied. $${bluCoupon.discountAmount} on the current invoice` : " is not applied"}
         </p>
       </div>
       {bluCoupon && bluCoupon.isValid && (
        <div>
          <button onClick={() => this.removeCoupon()}>Remove</button>
        </div>
       )}
     </Response>
    )}
  </Wrap>

  )
 }
}

export default Container(Coupon)
