import React from "react"
import styled from "styled-components"
import {
  Title,
} from "./commonStyles"

const ComponentWrap = styled.div``

const Header = ({
  title,
}) => (
  <ComponentWrap>
    <Title>{title || "ICA"}</Title>
  </ComponentWrap>
)

export default Header
