import React, { useEffect, useState } from "react";
import { Modal, Select, Checkbox, Radio } from "antd";
import CheckList from "container/CheckList/index";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles.css"

const { Option } = Select;

const AddCriteriaModal = ({
  isAddCriteriaModalOpen,
  cancelAddCriteriaModal,
  documentItem,
  fetchDocumentCriteria,
  fetchDocumentCriteriaResponse,
  saveAddCriteria,
  saveAddCriteriaResponse,
}) => {
  const [selectedCriteria, setSelectedCriteria] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    fetchDocumentCriteria(documentItem.document_item_id);
  }, [fetchDocumentCriteria, documentItem.document_item_id]);

  useEffect(() => {
    if (fetchDocumentCriteriaResponse?.data) {
      const defaultSelectedCriteria = fetchDocumentCriteriaResponse.data.find(
        (crit) => crit.selected === "true"
      );
      if (defaultSelectedCriteria) {
        setSelectedCriteria(defaultSelectedCriteria);
        setSelectedOption(
          defaultSelectedCriteria.options.find((opt) => opt.selected === "true")
        );
        setSelectedOptions(
          defaultSelectedCriteria.options
            .filter((opt) => opt.selected === "true")
            .map((opt) => opt.id)
        );
      }
    }
  }, [fetchDocumentCriteriaResponse]);

  const onCancel = () => {
    cancelAddCriteriaModal();
    setSelectedOptions([])
    setSelectedOption(null)
    setSelectedCriteria(null)
  };

  const handleSaveAddCriteria = () => {
    if (!selectedCriteria) {
      return;
    }
    const payload = {
      documentItemId: documentItem.document_item_id,
      criteriaId: selectedCriteria.id,
      criteriaOptionIds: selectedOptions,
    };

    saveAddCriteria({
      payload,
      callback: () => {
        fetchDocumentCriteria(documentItem.document_item_id);
        onCancel();
    }});
  };

  const handleTemplateSelectChange = (value) => {
    const criteria = fetchDocumentCriteriaResponse?.data.find(
      (crit) => crit.criteria_value === value
    );
    setSelectedCriteria(criteria);
    setSelectedOption(null);
    setSelectedOptions([]);
  };

  const handleRadioChange = (option) => {
    setSelectedOption(option);
    setSelectedOptions([option.id]); 
  };

  const handleCheckboxChange = (option) => {
    const index = selectedOptions.indexOf(option.id);
    if (index === -1) {
      setSelectedOptions([...selectedOptions, option.id]);
    } else {
      const updatedOptions = [...selectedOptions];
      updatedOptions.splice(index, 1);
      setSelectedOptions(updatedOptions);
    }
    setSelectedOption(null);
  };

  const onReset = () => {
    setSelectedOption(null);
    setSelectedOptions([]);
  }

  return (
    <Modal
      destroyOnClose={true}
      mask={true}
      maskClosable={false}
      title={null}
      visible={isAddCriteriaModalOpen}
      onCancel={onCancel}
      footer={[
         <button
          className="add-due-btn border-gray"
          style={{float: 'left'}}
          key="reset"
          onClick={onReset}
        >
          Reset
        </button>,
        <button
          className="add-due-btn border-gray"
          key="cancel"
          onClick={onCancel}
        >
          Cancel
        </button>,
        <button
          key="add"
          type="primary"
           className={`add-due-btn add-due-btn-color ${
              !selectedCriteria ||
            saveAddCriteriaResponse?.isFetching || fetchDocumentCriteriaResponse?.isFetching ? "disabled-btn" : ""
            }`}
          onClick={handleSaveAddCriteria}
              disabled={
            !selectedCriteria ||
            saveAddCriteriaResponse?.isFetching || fetchDocumentCriteriaResponse?.isFetching
          }

        >
          {(
            saveAddCriteriaResponse?.isFetching) && (
            <LoadingOutlined style={{ marginRight: "10px" }} />
          )}
          Save
        </button>,
      ]}
    >
      <div>
        <p className="icon-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21 7.5L12 2.25L3 7.5M21 7.5L12 12.75M21 7.5V16.5L12 21.75M3 7.5L12 12.75M3 7.5V16.5L12 21.75M12 12.75V21.75"
              stroke="#6366F1"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </p>
        <h4 className="modal-title">Set Document Criteria</h4>
      </div>
      <p className="modal-description">
        Define specific rules for documents based on the selected city. You can
        specify requirements and conditions to ensure compliance with local
        regulations.
      </p>
      {!fetchDocumentCriteriaResponse?.isFetching ? (
        <Select
          placeholder="Select Criteria"
          className="dropdown-state"
          style={{ width: "100%", marginTop: 0 }}
          onChange={handleTemplateSelectChange}
          value={selectedCriteria ? selectedCriteria.criteria_value : undefined}
        >
          {fetchDocumentCriteriaResponse?.data?.map((criteria) => (
            <Option
              key={criteria.criteria_value}
              value={criteria.criteria_value}
            >
              {criteria.criteria_key}
            </Option>
          ))}
        </Select>
      ) : (
        <LoadingOutlined style={{ marginRight: "10px" }} />
      )}

      {selectedCriteria && !fetchDocumentCriteriaResponse?.isFetching ? (
        <div className="options-wrapper">
          {selectedCriteria.criteria_multiselect === 0 ? (
            <Radio.Group
              className="radio-group-grid"
              onChange={(e) =>
                handleRadioChange(
                  selectedCriteria.options.find(
                    (opt) => opt.id === e.target.value
                  )
                )
              }
              value={selectedOption ? selectedOption.id : undefined}
            >
              {selectedCriteria.options.map((option) => (
                <Radio key={option.id} value={option.id} className="radio-item">
                  {option.key}
                </Radio>
              ))}
            </Radio.Group>
          ) : (
            <Checkbox.Group
              className="checkbox-group-grid"
              value={selectedOptions}
              onChange={(checkedValues) => setSelectedOptions(checkedValues)}
            >
              {selectedCriteria.options.map((option) => (
                <Checkbox
                  key={option.id}
                  value={option.id}
                  onChange={() => handleCheckboxChange(option)}
                  className="checkbox-item"
                >
                  {option.key}
                </Checkbox>
              ))}
            </Checkbox.Group>
          )}
        </div>
      ) : null}
    </Modal>
  );
};

export default CheckList(AddCriteriaModal);
