import React, { Component } from "react"
import styled, { css } from "styled-components"
import colors from "lib/colors"
import Container from "container/Agent"
import Benefits from "./Benefits"
import { getDuration } from "./shared"
import root from "window-or-global"

const Wrap = styled.div`
 padding-top: 15px;
 padding-left: 15px;
 input {
  width: 242px;
  height: 26px;
  border-radius: 5px;
  border: solid 1px #e3e3e3;
  padding-left: 10px;
 }
 button {
  border: none;
  color: #0882e4;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
 }
`
const Response = styled.div`
  width: 249px;
  height: 56px;
  border-radius: 4px;
  background-color: ${props => props.isValid ? "#e9fbea" : "#fbe9ea"};
  p {
   font-size: 12px;
   padding: 13px;
   color: #8c8c8c;
   span {
    font-weight: bolder;
    color: #4b4444;
   }
  }
`

class LeadCap extends React.Component{
 state = {
  leadCap: "",
 }
 componentDidMount() {
   //
 }

 handleLeadInput = (e, type) => {
   const {
     setLeadCap,
   } = this.props
   this.setState({
    [e.target.name]: e.target.value,
   })

   if (type !== "team"){
    setLeadCap({
     singleLeadCap: e.target.value,
    })
   } else {
    setLeadCap({
     teamLeadCap: e.target.value,
    })
   }
 }

 render() {
  const {
   subscriptions,
   type,
  } = this.props
  const {
   data: subData,
  } = subscriptions

  return (
  <Wrap>
    <input
     type="number"
     name="leadCap"
     onChange={e => this.handleLeadInput(e, type)}
     placeholder={subData ? subData.lead_cap_threshold[0].rtl_threshold : ""}
    />
  </Wrap>

  )
 }
}

export default Container(LeadCap)
