import React from "react"
import styled from "styled-components"
import { withFormik } from "formik"
import md5 from 'md5';
import Cookies from 'universal-cookie';
import { Input } from "./../components/Form"
import Button from "./../components/Button"
import { Fetch } from "./../lib/NetworkUtils";
import {SetBugsnagUser} from "./../lib/ErrorUtils";
import { window } from "window-or-global";


const Wrap = styled.div`
  background: #FFF;
  max-width: 350px;
  padding: 20px;
  border-radius: 4px;
  margin: 0px auto;
  text-align: center;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);
  margin-top: 100px;

  form {
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      display: block;
      margin-bottom: 10px;
    }
  }

  .error {
    color: red;
  }
`


const LoginFormEle = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
}) => (
    <form onSubmit={handleSubmit}>
      <Input
        type="text"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        name="email"
        placeholder="Email"
        required
      />
      <Input
        type="password"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        placeholder="Password"
        name="password"
        required
      />
      <Button success>Login</Button>
    </form>
  )

const LoginForm = withFormik({
  displayName: "LOGIN_FORM",
  handleSubmit: (values, other) => {
    const { onSubmit } = other.props 
    onSubmit(values)
  }
})(LoginFormEle)

class Login extends React.Component {
  state = {
    isSoul: false,
  }

  componentDidMount() {
    const { origin } = window.location
    const isSoul = origin.includes("soul.")
    this.setState({
      isSoul,
    })
  }

  handleSubmit = (values) => {
    this.setState({
      ...values,
      isLoggingIn: true,
    }, () => {
      this.login()
    })
    
  }

  login = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const cookies = new Cookies();
    const { isSoul } = this.state
    const {
      email,
      password
    } = this.state
    try {
      let sol = await Fetch(baseUrl+ '/v1.0/admin/login', {
        method: 'POST',
        body: JSON.stringify({
          email,
          password: md5(password)
        })
      });
      this.setState({clicked: false});
      if (sol.response) {
        //saving call_settings is not required in cookie and sometimes it's creating problem not getting saved. 
        delete sol.response.call_settings;
        cookies.set("admin_id", sol.response.agent_id, { path: '/' });
        cookies.set("user", JSON.stringify(sol.response), { path: '/' });
        const { origin } = window.location
        if (isSoul) {
          window.location = "/broker/tc/list"
          return
        }
        window.location = "/"
        SetBugsnagUser(sol.response);
      }
    } catch (error) {
      console.log(error)
      const { message } = error.error || {}
      this.setState({
        isLoggingIn: false,
        errorMessage: message,
      })
    }
  }

  render() {
    const {
      isLoggingIn,
      errorMessage,
      isSoul,
    } = this.state
    return (
      <Wrap>
        <div className="img-wrap">
          <img src="https://d2fedz0by71ckz.cloudfront.net/images/temp-logo%402x.png" alt="Radius Logo" />
          <h4>{isSoul ? "Soul" : "Blink"} Login</h4>
        </div>
        { !isLoggingIn ? (
          <LoginForm onSubmit={this.handleSubmit} />
        ) : (
          <p>Authenticating..</p>
        )}
      </Wrap>
    )
  }
}

export default Login