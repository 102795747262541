import styled from "styled-components"

export const Header = styled.header`
  background: #EEE;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 0px 40px;
  z-index: 100;
`

export const MultiCallerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    margin: 0px 20px;
  }
`
