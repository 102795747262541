import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/RecruitAndEarn"

const PendingTable = styled.table``

const TitleWrap = styled.div``

const Name = styled.p`
 margin: 0;
`
const Email = styled.p`
 margin: 0;
 font-size: 12px;
`

const Wrap = styled.div``

class CreditedEarnings extends React.Component{
 componentDidMount() {
  const {
   inviteList,
  } = this.props
 }

 render() {
  const {
   earningData,
  } = this.props

  return(
   <Wrap>
    <TitleWrap>
     <h1>Credited Earnings</h1>
    </TitleWrap>
    <PendingTable>
     <tr>
         <th>Sender</th>
         <th>Receiver</th>
         <th>Client</th>
         <th>Gift Card Amount</th>
         <th>Is Credited?</th>
     </tr>
     {earningData && earningData.credited && earningData.credited.map(key => (
      <tr>
          <td>
           <Name>{(key.sender_firstname || "") + " " + (key.sender_lastname || "") }</Name>
           <Email>{key.sender_email}</Email>
          </td>
          <td>
           <Name>{(key.firstname || "") + " " + (key.lastname || "") }</Name>
           <Email>{key.email}</Email>
          </td>
          <td>
           <Name>{(key.client_name || "-")}</Name>
           <Email>{key.client_email || "-"}</Email>
          </td>
          <td><input value={key.amount || 0} type="text" placeholder="Gift card amount" disabled/></td>
          <td><input type="checkbox" checked={key.is_credited} disabled /></td>
      </tr>
     ))}
    </PendingTable>
   </Wrap>
  )
 }
}

export default Container(CreditedEarnings)
