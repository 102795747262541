import React, { PureComponent } from "react"
import styled from "styled-components"
import { get } from "lodash"
import TechAssetsContainer from "container/TechAssets"
import agentSearchContainer from "container/BrokerDashboard/agentSearchContainer"
import root, { clearTimeout, setTimeout } from "window-or-global"
import { withRouter } from "react-router-dom"
import {
  PlusCircleOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
// import { gapi } from "gapi-script"
import {
  Input, InputNumber, Select, Checkbox, Form,
  Divider,
  Button,
  GoogleAutoComplete,
  Spin,
} from "dumbComponents/common/UI/antd"
import colors from "../../../../lib/colors"
import {
  PageArray,
  ListingAgentInfoStep,
  SellerInfoRoute,
  SellerInfoStep,
  VendorSelectRoute,
} from "../../consts"
import {
  fetchDetails,
  SubmitForm,
  getDealId,
  setStreet,
  normalizePhone,
  getAgentId,
} from "../../commonUtils"
import {
  RemoveCoListingAgentButton,
} from "../../commonStyles"
import Sidebar from "../Sidebar"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
  .ant-form-item-control-input {
    min-height: 0px;
  }
`

const HeaderNav = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LabelText = styled.h2`
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`

const Required = styled.span`
  color: #E34C4C
`

const FlexBox = styled.div`
  display: flex;
  justify-content: flex-end;
`

const AddForm = styled.div`
  padding: 0 0 34px 0;
  text-align: right;
  margin-bottom: 30px;
`

const StyledSpin = styled(Spin)`
  width: 100%;
`

const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    margin: 0px;
  }
`

// const STATE_OPTIONS = Object.keys(US_STATES).map(key => ({ label: US_STATES[key], value: US_STATES[key] }))

const debounce = (callback, delay) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => callback(...args), delay)
  }
}

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      contacts: [],
      showCoListingform: [1],
      cityFormValue: {},
      agentValues: [],
      isRadiusUser: false,
      timeout: null,
      searchValue: "",
    }
  }

  formRef = React.createRef();

  componentDidMount() {
    const { getDealDetails } = this.props

    fetchDetails(getDealDetails, ListingAgentInfoStep)
  }

  getOptions = () => {
    const { agentGoogleContacts } = this.props
    return agentGoogleContacts
      && agentGoogleContacts.length
      ? agentGoogleContacts.filter(ct => ct.names)
        .map((ct, index) => ({ label: ct.names[0].displayName, value: `${ct.names[0].displayName}`, key: index })) : []
  }

  addColistingAgent = () => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform
    colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
    setTimeout(() => {
      root.scrollTo(0, 3100)
    }, 300)
  }

  searchAgentDetails = (searchValue) => {
    const { fetchAgentSearch } = this.props
    this.setState({
      searchValue,
    })
    let timeout = 0
    if (timeout) {
      clearTimeout(timeout)
      timeout = 0
    }
    const callSearch = () => {
      fetchAgentSearch({ search: searchValue })
    }

    timeout = setTimeout(callSearch, 500)
  }

  onFinish = (fieldValues, isDraft) => {
    const {
      history,
      updateOffer,
      createOffer,
    } = this.props

    const currentStep = ListingAgentInfoStep
    const agentId = getAgentId() || false

    SubmitForm(
      fieldValues,
      isDraft,
      createOffer,
      updateOffer,
      history,
      VendorSelectRoute,
      currentStep,
      "agents",
      true,
      false,
      agentId,
      false,
    )
  }

  onFinishFailed = (values) => {
    console.log("==payload=values=", values)
  }

  onSaveAsDraft = () => {
    this.onFinish(this.formRef.current.getFieldsValue(), true)
  }

  changeOtherFieldsValues = (objString, index) => {
    const { agents } = this.props
    const { searchValue } = this.state
    const { isFetching, data } = agents
    const obj = objString.split("::")
    const agent = data?.agents.find(ag => ag.id === parseInt(obj[0], 10))

    if (agent) {
      this.formRef.current.setFieldsValue({
        [`agent_id-${index}`]: parseInt(obj[0], 10),
        [`name-${index}`]: obj[1] || "",
        [`email-${index}`]: agent.email || "",
        [`phone-${index}`]: agent.phone,
        [`brokerage_address-${index}`]: agent?.brokerage_info_from_LOC?.brokerage_address,
        [`agent_license_number-${index}`]: agent?.brokerage_info_from_LOC?.agent_license_number,
        [`landline-${index}`]: agent?.brokerage_info_from_LOC?.landline,
        [`state-${index}`]: agent?.brokerage_info_from_LOC?.state,
        [`zipcode-${index}`]: agent?.brokerage_info_from_LOC?.zipcode,
        [`city-${index}`]: agent?.brokerage_info_from_LOC?.city,
        [`listing_brokerage-${index}`]: agent?.brokerage_info_from_LOC?.brokerage,
        [`brokerage-${index}`]: agent?.brokerage_info_from_LOC?.brokerage,
        [`brokerage_license_number-${index}`]: agent?.brokerage_info_from_LOC?.brokerage_license_number,
      })
    } else {
      this.formRef.current.setFieldsValue({
        [`agent_id-${index}`]: null,
        [`name-${index}`]: searchValue || "",
      })
    }
  }

  setCities = (location, index) => {
    const { cityFormValue } = this.state
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    this.formRef.current.setFieldsValue({
      [`city-${index}`]: `${locationDetails.city || ""}`,
      [`state-${index}`]: `${locationDetails.state || ""}`,
      [`country-${index}`]: `${locationDetails.country}`,
    })
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [`city-${index}`]: `${locationDetails.city}`,
      },
    })
  }

  setLocaCity = (value, cityFormName) => {
    const { cityFormValue } = this.state
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [cityFormName]: value,
      },
    })
  }

  getInitialValues = () => {
    const { getCurrentDealDetailsResponse } = this.props
    const {
      data: listingAgentInfo,
    } = getCurrentDealDetailsResponse || {}
    const { cityFormValue, showCoListingform } = this.state
    const localShowCoListingform = showCoListingform
    const result = {}
    if (listingAgentInfo && listingAgentInfo.listing_agents) {
      listingAgentInfo.listing_agents.forEach((info, index) => {
        Object.keys(info).forEach((key) => {
          result[`${key}-${index}`] = info[key]
          if (key === "city") {
            // need to check this logic
            cityFormValue[`${key}-${index}`] = info[key]
          }
        })
        // need to check this logic
        if (index > 0 && !localShowCoListingform.includes(index + 1)) localShowCoListingform.push(index + 1)
      })
    }
    return result
  }

  activateGmail = (auth) => {
    const { syncGmail } = this.props
    syncGmail({ payload: auth })
  }

  setIsRadiusUser = (e, index) => {
    this.setState({
      [`${index}_isRadiusUser`]: e.target.checked,
    })
  }

  getValues = () => {
    const {
      searchValue,
    } = this.state

    const {
      agents,
    } = this.props

    const resultArray = []

    resultArray.push({
      label: searchValue,
      value: searchValue,
    })

    if (agents && agents.data && agents.data.agents && agents.data.agents.length > 0) {
      agents.data.agents.forEach((d) => {
        resultArray.push({
          label: `${d.firstname} ${d.lastname} - ${d.email}`,
          value: `${d.id}::${d.firstname} ${d.lastname}`,
        })
      })
    }

    return resultArray
  }

  removeColistingAgent = (index) => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform.filter((sc, ind) => ind !== index)
    // colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
  }

  render() {
    const { showCoListingform, searchValue } = this.state
    const {
      history,
      getCurrentDealDetailsResponse,
      updateOfferActionResponse,
      getDealDetails,
      isFetchingAgents,
      agents,
    } = this.props
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]

    const {
      isFetching: fetchingDeal,
    } = getCurrentDealDetailsResponse || {}

    const {
      isFetching: updatingDeal,
    } = updateOfferActionResponse || {}

    const isLoading = fetchingDeal || updatingDeal
    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Listing Agent Information
        </HeaderNav>
        <ProgressBarComponent total={PageArray} ongoingOrCompleted={5} />
        <Container>
          <LeftSideBar>
            <Sidebar />
          </LeftSideBar>
          <RightSideBar>
            <AddForm>
              <Button
                ghost
                style={{
                  float: "right",
                  borderColor: "#3B60E4",
                  color: "#3B60E4",
                  display: "flex",
                  alignItems: "center",
                }}
                type="primary"
                shape="round"
                size="large"
                icon={<PlusCircleOutlined />}
                onClick={() => { this.addColistingAgent() }}
              >
                Add co-listing agent
              </Button>
            </AddForm>
            {isLoading ? (
              <p>Loading</p>
            ) : (
              <Form
                name="listing_agent_information"
                preserve
                colon={false}
                layout="vertical"
                requiredMark={false}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                initialValues={this.getInitialValues()}
                ref={this.formRef}
              >
                {/* colisting */}
                {showCoListingform.map((lst, index) => (
                  <>
                    {index > 0 && (<Divider />)}
                    {index > 0 && (
                      <HeaderInfo>
                        <h2>
                          Co-Listing Agent Information
                        </h2>
                        <RemoveCoListingAgentButton
                          type="button"
                          onClick={() => { this.removeColistingAgent(index) }}
                        >
                          <CloseCircleOutlined style={{ fontSize: "22px" }} />
                        </RemoveCoListingAgentButton>

                      </HeaderInfo>
                    )}
                    <Form.Item name={`agent_id-${index}`} style={{ textAlign: "end" }}>
                    </Form.Item>
                    {/* {index === 0 && (
                      <Form.Item name={`name-${index}`} label={<LabelComponent text="First and Last Name" required />} rules={commonRules}>
                        <Input />
                      </Form.Item>
                    )} */}
                    {/*eslint-disable-next-line react/destructuring-assignment*/}
                    <Form.Item name={`name-${index}`} label={<LabelComponent text="First and Last Name" required />} rules={commonRules}>
                      <Select
                        showSearch
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={this.searchAgentDetails}
                        onChange={(e => this.changeOtherFieldsValues(e, index))}
                        notFoundContent={(agents.isFetching || agents.isFetchingMore) ? <StyledSpin size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}
                        options={this.getValues()}
                      />
                    </Form.Item>
                    {/* {index > 0 && !isRadiusUser && (
                      <Form.Item name={`name-${index}`} label={<LabelComponent text="First and Last Name" required />} rules={commonRules}>
                        <AutoComplete
                          options={this.getOptions()}
                          onChange={(e, option) => this.changeOtherFieldsValues(e, index, true, option)}
                        />
                      </Form.Item>
                    )} */}
                    <FlexBox>
                      <Form.Item
                        name={`email-${index}`}
                        label={<LabelComponent text="Email" required />}
                        required
                        rules={commonRules}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item name={`agent_license_number-${index}`} label={<LabelComponent text="Agent License No." required />} rules={commonRules}>
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item name={`landline-${index}`} label={<LabelComponent text="Home Phone" />}>
                        <Input />
                      </Form.Item>
                      {/* normalizePhoneNumber */}
                      <Form.Item name={`phone-${index}`} label={<LabelComponent text="Cell Phone" required />} rules={commonRules} normalize={normalizePhone}>
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item name={`brokerage-${index}`} label={<LabelComponent text="Listing Brokerage" required />} rules={commonRules}>
                        <Input />
                      </Form.Item>

                      <Form.Item name={`brokerage_license_number-${index}`} label={<LabelComponent text="Brokerage License No." required />} rules={commonRules}>
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item name={`brokerage_address-${index}`} label={<LabelComponent text="Brokerage Address" />}>
                        {/* <Input /> */}
                        <GoogleAutoComplete
                          id="cities"
                          types={["address"]}
                          name="cities"
                          // formValue={cityFormValue}
                          onChange={(e) => { }}
                          location={(loc) => { setStreet(loc, this.formRef, `brokerage_address-${index}`, false, index, false) }}
                          placeholder=""
                          styles={{
                            height: "49px",
                            borderRadius: "0px",
                            background: "white",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        />
                      </Form.Item>

                      <Form.Item name={`apt-${index}`} label={<LabelComponent text="Apt/Unit/Suite No." />}>
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item name={`city-${index}`} label={<LabelComponent text="City" />}>
                        <Input />
                        {/* <GoogleAutoComplete
                          id="cities"
                          types={["(cities)"]}
                          // types={["address"]}
                          name="cities"
                          // formValue={cityFormValue[`city-${index}`]}
                          onChange={(e) => { this.setLocaCity(e.target.value, `city-${index}`) }}
                          location={(loc) => { this.setCities(loc, index) }}
                          placeholder=""
                          styles={{
                            height: "49px",
                            borderRadius: "0px",
                            background: "white",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                          restrict={{
                            lat: 37.09024,
                            lng: -95.712891,
                            south_west: {
                              lat: 25.82,
                              lng: -124.39,
                            },
                            north_east: {
                              lat: 49.38,
                              lng: -66.94,
                            },
                            address: "United States",
                            country: {
                              long_name: "United States",
                              short_name: "US",
                              types: [
                                "country",
                                "political",
                              ],
                            },
                          }}
                        /> */}
                      </Form.Item>

                      <Form.Item name={`state-${index}`} label={<LabelComponent text="State" />}>
                        <Input />
                        {/* <Select>
                          {STATE_OPTIONS.map(st => (
                            <Select.Option value={st.value}>{st.label}</Select.Option>
                          ))}
                        </Select> */}
                      </Form.Item>

                      <Form.Item
                        name={`zipcode-${index}`}
                        label={<LabelComponent text="Zip Code" />}
                      >
                        <Input />
                      </Form.Item>
                    </FlexBox>
                  </>
                ))}
                <FlexBox>
                  <FlexBox>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "white",
                          float: "right",
                          borderColor: "#3B60E4",
                          color: "#3B60E4",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        // htmlType="submit"
                        onClick={() => {
                          history.push(`${SellerInfoRoute}?dealId=${getDealId()}&agentId=${getAgentId()}`)
                          fetchDetails(getDealDetails, SellerInfoStep)
                        }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "#3B60E4",
                          float: "right",
                          borderColor: "white",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        loading={isLoading}
                        htmlType="submit"
                      // onClick={() => { history.push("/realty/listings/coversheet/listing-details") }}
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </FlexBox>
                </FlexBox>
              </Form>
            )}
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}
// const WrappedCoverSheet = Form.create()(CoverSheet)
export default withRouter(TechAssetsContainer(agentSearchContainer(CoverSheet)))
