import React from "react"
import {
  Table,
  Tag,
  Pagination,
  Button,
  Modal,
} from "antd"
import { SearchOutlined } from "@ant-design/icons"
import styled from "styled-components"
import colors from "../../../../lib/colors"
import {
  roomsSerializer,
} from "./utils"
import ConfirmationNote from "../ConfirmationNote/index"

const { confirm } = Modal

const Wrap = styled.div``

const DeleteButton = styled.button`
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
`

const ActionButton = styled.button`
  background-color: #3B60E4;
  color: white;
  border: none;
  cursor: pointer;
  padding: 9px;
  border-radius: 10px;
`

const RecordingTable = ({
  recordings,
  fetchingData,
  toggleDownloadDrawer,
}) => {
  const getFilters = () => {
    if (!recordings) return []
    return recordings.map((item) => {
      return {
        text: item.name,
        value: item.name,
      }
    })
  }

  const createColumn = () => {
    const columns = [
      {
        title: "Date",
        dataIndex: "scheduledFor",
        key: "scheduledFor",
      },
      {
        title: "Room Name",
        dataIndex: "name",
        key: "name",
        filters: getFilters(),
        onFilter: (value, record) => record.name.startsWith(value),
        filterSearch: true,
        width: "30%",
        filterIcon: filtered => (
          <SearchOutlined
            style={{
              color: filtered ? "#1890ff" : undefined,
            }}
          />
        ),
      },
      {
        title: "Hosted by",
        dataIndex: "host",
        key: "host",
      },
      {
        title: "Promoted",
        dataIndex: "promoted",
        key: "promoted",
        render: (data) => {
          const isPromoted = data === 1
          return (
            <p>{isPromoted ? "Yes" : "No"}</p>
          )
        },
      },
      {
        title: "Update Room",
        key: "key",
        dataIndex: "key",
        render: (id, roomData) => {
          return (
            <ActionButton
              onClick={() => {
                toggleDownloadDrawer({
                  bool: true,
                  data: roomData,
                })
              }}
            >
              Edit Room
            </ActionButton>
          )
        },
      },
    ]
    return columns
  }

  const serialized = recordings && roomsSerializer(recordings) || []

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra)
  };

  return (
    <Wrap>
      <Table
        className="infoTable"
        columns={createColumn()}
        dataSource={serialized}
        loading={fetchingData}
        onChange={onChange}
        pagination={{ pageSize: 5 }}
      >
        {/* <Pagination
          total={serialized.length}
          defaultPageSize={10}
          defaultCurrent={1}
        /> */}
      </Table>
    </Wrap>
  )
}

export default RecordingTable
