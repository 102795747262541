/* eslint-disable global-require */
/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react"
import styled from "styled-components"
import AgentContainer from "container/BrokerDashboard/editAgentContainer"
import BrokerContainer from "container/BrokerDashboard/subscriptionContainer"
import { get, isEmpty } from "lodash"
import {
  Production,
  Split,
  CommissionCap,
  MonthlySupportFee,
  RiskManagement,
  AddOns,
  Summary,
  TeamModal,
  CouponCode,
} from "./components"

const Wrap = styled.div``

const Container = styled.div``

const TitleWrap = styled.div``

const DashboardBusy = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > img {
    max-width: 200px;
  }
  p {
    font-size: 28px;
  }
`

const Title = styled.h1`
  color: #000;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding: 16px;
`

const SubscriptionV2 = ({
  getAgentBrokerageMetadataInfo,
  planBenefitsResponse,
  fetchStripePlans,
  getPlanbenefits,
  currentPlan,
  getSubsribedAddons,
  updateSubscriptionV2Response,
  checkoutBrokerPlanV2Response,
  cancelSubscriptionResponse,
  inviteWithoutSubscriptionResponse,
  toggleTeamModal,
  toggleTeamCreateModal,
  couponCodeResponse,
  ...props
}) => {
  const isSubmitting = get(checkoutBrokerPlanV2Response, "isFetching")
  const isUpdating = get(updateSubscriptionV2Response, "isFetching")
  const isCancelling = get(cancelSubscriptionResponse, "isFetching")
  const {
    data: couponData,
  } = couponCodeResponse || {}
  const {
    isFetching: subscribingWithoutInvite,
  } = inviteWithoutSubscriptionResponse || {}
  const isBusy = isSubmitting || isUpdating || isCancelling || subscribingWithoutInvite
  const currentMonthlySupportFee = get(currentPlan, "currentPlan")
  const data = get(planBenefitsResponse, "data")
  const agentId = get(props, "match.params.agentId", "")
  useEffect(() => {
    getAgentBrokerageMetadataInfo()
    fetchStripePlans()
    getSubsribedAddons({
      agentId,
    })
  }, [])

  useEffect(() => {
    getPlanbenefits({
      planId: currentMonthlySupportFee?.id,
      agentId,
    })
  }, [currentMonthlySupportFee])
  return (
    <>
      <Wrap>
        {isBusy && (
          <DashboardBusy>
            <img src={require("../../../../images/soul/processing.gif")} alt="" />
            <h4>Processing..</h4>
          </DashboardBusy>
        )}
        <Container>
          <TitleWrap>
            <Title>
              Brokerage Subscription
            </Title>
          </TitleWrap>
          <Production />
          <Split />
          <CommissionCap />
          <MonthlySupportFee />
          {!isEmpty(data) && (
            <>
              <RiskManagement />
              <AddOns />
            </>
          )}
          <CouponCode />
          <Summary
            agentId={agentId}
            couponData={couponData}
          />
        </Container>
      </Wrap>
    </>
  )
}

export default BrokerContainer(AgentContainer(SubscriptionV2))
