import React from "react"
import styled from "styled-components"
import moment from "moment"
import { isEmpty, get, parseInt, assign } from "lodash"
import { getInterval } from "./../helper"

const Wrap = styled.div`
  border-top: 1px solid #ccc;
  margin-top: 30px;
`

const Title = styled.div`
  text-transform: uppercase;
  margin: 20px 0px;
`

const SectionTitle = styled.h4`
  
`

const PlanDetails = styled.div`
  background: #F1EEFF;
  padding: 20px;
  border-radius: 8px;
  margin-top: 16px;

  > div {
    flex: 1;
    display: flex;
    &:nth-child(1) {
      padding: 10px 0px;
      > h6 {
        flex: 1;
        margin: 0px;
        font-size: 16px;
        font-weight: 300;
      }
    }
    &:nth-child(2) {
      display: flex;
      justify-content: center;
      align-items: center;
      > p {
        font-size: 14px;
        color: #888;
        flex: 1;
      }
      > span {
        color: #888;
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
`

const NoPlansActive = styled.div`
  text-align: center;
  margin-top: 100px;
  > img {
    max-width: 80px;
    margin: 0px auto;
  }
`

const TagWrap = styled.div`
  > span {
    border: 1px solid #999;
    padding: 3px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 5px;

    &.expired {
      border-color: #ff5722;
      color: #ff5722;
    }
  }
`

const getPlanType = (id) => {
  if (id.includes("expert")) {
    return "Expert"
  }
  if (id.includes("professional")) {
    return "Professional"
  }
  if (id.includes("royale")) {
    return "Royale"
  }
  if (id.includes("base")) {
    return "Base"
  }
  if (id.includes("elite")) {
    return "Elite"
  }
  if (id.includes("90_10")) {
    return "90/10"
  }
  if (id.includes("95_5")) {
    return "95/5"
  }
  return "Legacy"
}

const PlanInfo = ({
  currentPlan,
  addons,
  assistPlans,
}) => {
  const { subscriptionDetails, currentPlan: activePlan, planFromSub } = currentPlan
  const selectedPlan = get(currentPlan, "subscriptionDetails.items.data[0].plan")

  if (isEmpty(currentPlan)) {
    return (
      <Wrap>
        <NoPlansActive>
          <img src={require("./../../../images/soul/subscription-model.png")} alt=""/>
          <p>No Active Subscription Plan</p>
        </NoPlansActive>
      </Wrap>
    )
  }

  console.log({ activePlan, subscriptionDetails });

  return (
    <Wrap>
      <SectionTitle>
        Current Plan
      </SectionTitle>
      <Title>
        Brokerage Plan
      </Title>
      <PlanDetails>
        <div>
          {activePlan.id === "broker_accelerator_program_yearly_0" ? (
            <h6>Broker Accelerator Plan</h6>
          ) : (
            <h6>{getPlanType(activePlan.id)} Plan</h6>
          )}
          
          <span>{`$${activePlan.amount / 100}${getInterval(planFromSub)}`}</span>
        </div>
        <div>
          <p>{`Last billed: ${moment(subscriptionDetails.current_period_start * 1000).format("DD MMM, YYYY")}`}</p>
          <span>
            {`Next: ${moment(subscriptionDetails.current_period_end * 1000).format("DD MMM, YYYY")}`}
          </span>
        </div>

        <TagWrap>
           {planFromSub && planFromSub.is_blink_visible === 0 && (
              <span>Legecy Plan</span>
            )}
          {get(subscriptionDetails, "cancel_at") && (
            <span className="expired">
              Expiring on {moment(get(subscriptionDetails, "cancel_at") * 1000).format("DD MMM, YYYY")}
            </span>
          )}
        </TagWrap>
      </PlanDetails>
      {!isEmpty(assistPlans) && (
        <React.Fragment>
          <Title>
            Assist Plan
          </Title>
          <PlanDetails>
            <div>
              <h6>{get(assistPlans, "currentAssistPlan.plan.metadata.plan_tier")} Plan</h6>
              <span>{`$${get(assistPlans, "currentAssistPlan.plan.amount" ) / 100}`}</span>
            </div>
            <h6>
              per {get(assistPlans, "currentAssistPlan.plan.interval")}
            </h6>
            <div>
              <p>{`Last billed: ${moment(get(assistPlans, "subscriptionDetails.current_period_start") * 1000).format("DD MMM, YYYY")}`}</p>
              <span>
                {`Next: ${moment(get(assistPlans, "subscriptionDetails.current_period_end") * 1000).format("DD MMM, YYYY")}`}
              </span>
            </div>
            <TagWrap>
              {planFromSub && planFromSub.is_blink_visible === 0 && (
                <span>Legecy Plan</span>
              )}
              {get(assistPlans, "subscriptionDetails.cancel_at_period_end") && (
                <span className="expired">
                  Expiring on {moment(get(assistPlans, "subscriptionDetails.canceled_at") * 1000).format("DD MMM, YYYY")}
                </span>
              )}
            </TagWrap>
          </PlanDetails>
        </React.Fragment>
      )}
      
      <Title>
        Addons
      </Title>
      {addons && (
        <React.Fragment>
          {addons.length > 0 && addons.map(addon => (
            <PlanDetails key={addon.id}>
              <div>
                <h6>{addon.name}</h6>
                <span>
                  {`$${get(addon, "pricing.amount", "")}${getInterval(addon.pricing)}`}
                </span>
              </div>
              <div>
                <p>{`Last billed: ${moment(get(addon, "pricing.last_billed_at", 0) * 1000).format("DD MMM, YYYY")}`}</p>
              </div>
              <TagWrap>
                {!addon.recurring_add_on_sub_id && (
                  <span>One Time</span>
                )}
                {addon.recurring_add_on_sub_id && (
                  <span>Recurring</span>
                )}
                {addon.ends_at && (
                  <span className="expired">Expiring on {moment(addon.ends_at * 1000).format("DD MMM, YYYY")}</span>
                )}
              </TagWrap>
            </PlanDetails>
          ))}
        </React.Fragment>
      )}
    </Wrap>
  )
}

export default PlanInfo