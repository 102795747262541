import React, { useEffect } from "react"
import styled from "styled-components"
import RARTechContainer from "container/TechAssets"
import globalContainer from "container/BrokerDashboard/globalPagesContainer"
import {
  getAgentId,
} from "dumbComponents/Offers/commonUtils"
// import SearchControls from "../Common/SearchControls"
import ResultsTable from "./DealsComponents/Tables"
// import InvitedDrawer from "./DealsComponents/EditDrawer"

const Wrap = styled.div``

const PAGE_SIZE = 30

const Referrals = (props) => {
  const {
    getAllOffersActionResponse,
    getOffers,
    getAllDealsListing,
    dealsListingResponse,
    getContractStatus,
    saveListingStepsResponse,
  } = props

  //check here
  useEffect(() => {
    getOffers({
      skip: 0,
      limit: 300,
      query: `&agentId=${getAgentId() || ""}`,
    })
    getContractStatus()
  }, [])

  const {
    // isFetching,
    data: offers,
  } = getAllOffersActionResponse || {}

  return (
    <Wrap>
      <ResultsTable />
    </Wrap>
  )
}

export default globalContainer(RARTechContainer(Referrals))
