import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/ProductInterest"
import PIMList from "./PIM/ParentPIMList"
import Disabled from "./PIM/Disabled"
import root from "window-or-global"
import { DefaultLoader } from "components/UI/Loader/Loader";
import { formValueSelector } from "redux-form"
import {reset} from 'redux-form';


const Wrap = styled.div`
  height: 100%;
  width: 50%;
  border: 1px solid #e7e7e7;
  padding: 100px 100px 100px 0px;
  cursor: pointer;
`
const InnerWrap = styled.div`
  height: 50%;
  width: 100%;
  ${props => props.disabled && `
    .disabledText {
      margin-left: 26px;
      color: #8c8c8c;
      font-size: 14px;
      font-weight: bold;
    }
  `}
`


const AddNewButton = styled.button`
  position: relative;
  left: 90%;
  height: 27px;
  border-radius: 4px;
  background-color: #11adf3;
  border: none;
  color: white;
  cursor: pointer;
  width: max-content;
  ${props => props.isChildView && `
      left: 65%;
  `}
`


const Button = styled.button`
  position: relative;
  left: 62%;
  ${props => props.isChildView && `
      left: 62%;
  `}
  ${props => props.arranged && `
    left: -24%;
  `}
`

class LeftPanel extends Component {
  constructor(props) {
    super(props)
    this.pimRef = React.createRef()
  }

  componentDidMount(){
    // apiCalls
  }

  handleNewFeatureCreation = () => {
    const {
      createNewFeatureOrChild,
      isChildView,
      currentEditableProduct,
    } = this.props


    createNewFeatureOrChild({
      isNewFeatureOrChild: true,
      isChild: isChildView,
      currentEditableProduct: {},
      currentImageURL: "",
      productDescription: "",
      isEditingExisting: false,
    })
  }

  handleUpdateArrangement = () => {
    const {
      currentProduct,
      arranged,
      updateFeature,
    } = this.props

    const payload = {
      title: currentProduct.title,
      screens: arranged,
      "isEnabled" : true,
      "isActiveWeb" : true,
      "isActiveMobile": true,
    }

    updateFeature({
      id: currentProduct._id,
      payload,
    })
  }

  handleNewModuleCreation = () => {
    const {
      globalState,
      uploadNewFeature,
      productDescription,
    } = this.props

    const productDescriptionFormSelector = formValueSelector("ProductDescriptionForm")
    const additionalDetailsFormSelector = formValueSelector("AdditionalDetailsForm")

    const payload = {
    "title" : "Module",
    "description": "randomDescription",
    "published": false,
    "screens": [{
        "title" : "edit",
        "description" : "edit",
        "image_url" : "edit",
        "ctaButtonText" : "edit",
        "noButtonText" : "edit",
        "webLink" : "edit",
        "iOSLink" : "edit",
        "androidLink" : "edit",
    }],
    "isEnabled" : true,
    "isActiveWeb" : true,
    "isActiveMobile": true
    }

    uploadNewFeature({
      payload,
    })
  }

  handleBackToParent = () => {
    const {
      setCurrentProduct,
      currentProduct,
      setCurrentEditableProduct,
    } = this.props

    setCurrentProduct({
      currentProduct,
      child: [],
      isChildView: false,
    })

    setCurrentEditableProduct({
      currentEditableProduct: false,
    })

    root.location.href ="/product-interest-admin"
  }

  render(){
    const {
      modals,
      isFetching,
      isChildView,
      arranged,
      currentEditableProduct,
      isNewFeatureOrChild,
    } = this.props

    return (
      <Wrap>
        {isFetching ? (
          <DefaultLoader height={200} width={200}/>
        ): (
          <React.Fragment>
            <InnerWrap>
              {isChildView && (
                <React.Fragment>
                  <Button isChildView={isChildView} onClick={this.handleBackToParent}>Back To Module</Button>
                  <AddNewButton isChildView={isChildView} onClick={this.handleNewFeatureCreation}>Add Feature</AddNewButton>
                </React.Fragment>
              )}
              {!isChildView && (
                <AddNewButton isChildView={isChildView} onClick={this.handleNewModuleCreation}>Add New Module</AddNewButton>
              )}
              {isChildView && arranged && arranged.length > 1 && (
                <Button arranged onClick={this.handleUpdateArrangement}>Update New Arrangement</Button>
              )}
              <PIMList ref={this.pimRef} modals={modals || []} />
            </InnerWrap>
            {/*
              <InnerWrap disabled>
                <p className="disabledText">Disabled</p>
                <Disabled />
              </InnerWrap>
            */}
          </React.Fragment>
        )}
      </Wrap>
    )
  }
}


export default Container(LeftPanel)
