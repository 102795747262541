import React, { Component } from "react"
import styled from "styled-components"
import { formValueSelector } from "redux-form"
import Container from "container/Academy"
import root from "window-or-global"
import { ToastContainer } from "react-toastify"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"

const Wrap = styled.div`
  height: max-content;
  width: 188px;
  .alert {
   font-size: 11px;
   p {
    span {
     color: red;
    }
   }
  }
`

const HeaderList = styled.li`
  background-color: #f4f5f8;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  padding: 10px 10px 15px 15px;
`

const ListItem = styled.li`
  padding: 10px 10px 15px 15px;
  width: 100%;
  height: 37px;
  border-bottom: 1px solid #e2e4e8;
  font-size: 14px;
  ${props => props.isActive && `
    border-left: 3px solid orange;
   `}
   a {
    text-decoration: none;
    color: black;
    cursor: pointer;
   }
`

const MenuList = styled.ul`
  width: 100%;
  height: max-content;
  margin: 0;
  padding: 0;
  border: 1px solid #e2e4e8;
  border-radius: 3px;
  list-style: none;
`

const Publish = styled.button`
   width: 145px;
   height: 30px;
   border-radius: 4px;
   font-size: 16px;
   font-weight: 500;
   text-align: center;
   cursor: ${props => props.disabled ? "not-allowed" : "cursor"};
   ${props => props.disabled && `
      background-image: none !important;
      background: grey;
    `}
`

const Update = styled.button`
   width: 145px;
   height: 30px;
   border-radius: 4px;
   font-size: 16px;
   font-weight: 500;
   text-align: center;
   cursor: pointer;
`

const CTAWrap = styled.div`
  height: max-content;
  width: 100%;
  margin-top: 46px;

  .publish {
   background-image: linear-gradient(to bottom, #34ce57, #28a745);
   border: solid 1px #2a8b3f;
   color: white;
  }

  .draft {
   border: solid 1px rgba(27, 31, 35, 0.12) !important;
   background-color: #fafbfc;
   color: black;
  }
`

class AddEventMenu extends Component {
 componentDidMount(){
   //
 }

 handleEventPublish = () => {
  const {
   globalState,
   dateIndex,
   hostsArray,
   venueLinkInfo,
   registrationInfo,
   feedbackPost,
   aboutEvent,
   currentEvent,
   createEvent,
   eventAnnouncementText,
   communityEventPostText,
   eventReminderPost,
   currentImageURL,
   accessArray,
   testOnly,
   isPrivate,
   enableEventPushNotificationBool,
   feedbackGatheringPushNotificationText,
  } = this.props

  const aboutDayTimeFormSelector = formValueSelector("addDayTimeForEventsForm")
  const eventTitleForm = formValueSelector("addEventsForm")
  const thirdPartyRegistrationLinkForm = formValueSelector("thirdPartyLinkForm")

  const eventTitle = eventTitleForm(globalState, "eventName")
  const eventSource = eventTitleForm(globalState, "eventSource")
  const eventShortDescription = eventTitleForm(globalState, "eventShortDescription")
  const thirdPartyLinkURL = thirdPartyRegistrationLinkForm(globalState, "thirdPartyLink")
  const zoomLinkURL = thirdPartyRegistrationLinkForm(globalState, "zoomLink")
  const communityAdVideoLink = thirdPartyRegistrationLinkForm(globalState, "adLink")

  const adUrlExist = communityAdVideoLink && communityAdVideoLink.length > 0

  const dateArray = []

  for(let i = 0; i < dateIndex; i++) {
   dateArray.push({
    start: new Date(aboutDayTimeFormSelector(globalState, `${i}_start_date`)).getTime() / 1000,
    end: new Date(aboutDayTimeFormSelector(globalState, `${i}_end_date`)).getTime() / 1000,
   })
  }

  let instructorIDS = []

  hostsArray && hostsArray.forEach(inst => {
   instructorIDS.push(inst.id)
  })

  const payload = {
   title: eventTitle,
   event_source: eventSource,
   community_post_text: communityEventPostText || "",
   non_rtf_description: eventShortDescription,
   url: "abcd.com",
   description: aboutEvent || "",
   feedback_post_text: feedbackPost,
   registration_info: registrationInfo,
   cover_image_url : currentImageURL || "",
   event_start_timestamp: null,
   event_end_timestamp: null,
   instructor_ids: instructorIDS || [],
   access_to : accessArray || [],
   published: Math.floor((Date.now()/1000)),
   type: currentEvent.value,
   Instructors: hostsArray,
   first_announcement_push_text: eventAnnouncementText || "",
   reminder_push_text: eventReminderPost || "",
   test: testOnly,
   third_party_event: isPrivate,
   send_notification: enableEventPushNotificationBool,
   third_party_event_link: "",
   zoom_link: "",
   feedback_push_text: feedbackGatheringPushNotificationText || "",
   community_ad_video_link: adUrlExist ? `${communityAdVideoLink.split("?")[0]}?autoplay=1&mute=1` : "",
  }

  if(dateArray.length > 0) {
   payload.event_start_timestamp = dateArray[0].start || 0
   payload.event_end_timestamp = dateArray[0].end || 0
  }

  if(thirdPartyLinkURL && thirdPartyLinkURL.length > 0) {
   payload.third_party_event_link = thirdPartyLinkURL
  }

  if(zoomLinkURL) {
    payload.zoom_link = zoomLinkURL
  }

  createEvent({
   payload,
  })
 }


 handleEventUpdate = (id) => {
  const {
   globalState,
   dateIndex,
   hostsArray,
   venueLinkInfo,
   registrationInfo,
   feedbackPost,
   aboutEvent,
   currentEvent,
   updateEvent,
   getEventDetailsResponse,
   eventAnnouncementText,
   communityEventPostText,
   eventReminderPost,
   currentImageURL,
   accessArray,
   testOnly,
   isPrivate,
   enableEventPushNotificationBool,
   feedbackGatheringPushNotificationText,
  } = this.props

  const {
   data: existingEventDetails,
  } = getEventDetailsResponse

  const aboutDayTimeFormSelector = formValueSelector("addDayTimeForEventsForm")
  const eventTitleForm = formValueSelector("addEventsForm")
  const thirdPartyRegistrationLinkForm = formValueSelector("thirdPartyLinkForm")

  const eventTitle = eventTitleForm(globalState, "eventName")
  const eventSource = eventTitleForm(globalState, "eventSource")
  const eventShortDescription = eventTitleForm(globalState, "eventShortDescription")
  const thirdPartyLinkURL = thirdPartyRegistrationLinkForm(globalState, "thirdPartyLink")
  const zoomLinkURL = thirdPartyRegistrationLinkForm(globalState, "zoomLink")
  const communityAdVideoLink = thirdPartyRegistrationLinkForm(globalState, "adLink")

  const adUrlExist = communityAdVideoLink && communityAdVideoLink.length > 0

  const dateArray = []

  for(let i = 0; i < dateIndex; i++) {
   dateArray.push({
    start: new Date(aboutDayTimeFormSelector(globalState, `${i}_start_date`)).getTime() / 1000,
    end: new Date(aboutDayTimeFormSelector(globalState, `${i}_end_date`)).getTime() / 1000,
   })
  }

  let instructorIDS = []

  hostsArray && hostsArray.forEach(inst => {
   instructorIDS.push(inst.id)
  })

  const payload = {
   title: eventTitle || existingEventDetails.title,
   event_source: eventSource || existingEventDetails.event_source,
   non_rtf_description: eventShortDescription || existingEventDetails. non_rtf_description,
   url: existingEventDetails.url || "abcd.com",
   description: aboutEvent || existingEventDetails.description,
   feedback_post_text: feedbackPost || existingEventDetails.feedback_post_text,
   feedback_push_text: feedbackGatheringPushNotificationText || existingEventDetails.feedback_push_text,
   registration_info: registrationInfo || existingEventDetails.registration_info,
   cover_image_url : currentImageURL || existingEventDetails.cover_image_url,
   event_start_timestamp: null,
   event_end_timestamp: null,
   instructor_ids: instructorIDS && instructorIDS.length > 0 ? instructorIDS : existingEventDetails.instructor_ids,
   access_to : accessArray || existingEventDetails.access_to,
   published: existingEventDetails.published,
   type: currentEvent.value || existingEventDetails.value,
   test: testOnly,
   Instructors: hostsArray && hostsArray.length > 0 ? hostsArray : existingEventDetails.Instructors,
   community_post_text: communityEventPostText || existingEventDetails.community_post_text || "",
   first_announcement_push_text: eventAnnouncementText || existingEventDetails.first_announcement_push_text || "",
   reminder_push_text: eventReminderPost || existingEventDetails.reminder_push_text || "",
   third_party_event: isPrivate,
   send_notification: enableEventPushNotificationBool,
   third_party_event_link: existingEventDetails.third_party_event_link,
   zoom_link: existingEventDetails.zoom_link,
   community_ad_video_link: adUrlExist ? `${communityAdVideoLink.split("?")[0]}?autoplay=1&mute=1` : existingEventDetails.community_ad_video_link,
  }

  if(dateArray.length > 0) {
   payload.event_start_timestamp = dateArray[0].start || existingEventDetails.event_start_timestamp || 0
   payload.event_end_timestamp = dateArray[0].end || existingEventDetails.event_end_timestamp || 0
  } else {
   payload.event_start_timestamp = existingEventDetails.event_start_timestamp || 0
   payload.event_end_timestamp = existingEventDetails.event_end_timestamp || 0
  }

  if(thirdPartyLinkURL && thirdPartyLinkURL.length > 0) {
   payload.third_party_event_link = thirdPartyLinkURL
  } else {
   payload.third_party_event_link = ""
  }

  if(zoomLinkURL) {
    payload.zoom_link = zoomLinkURL
  }

  updateEvent({
   payload,
   id,
  })
 }

 checkDates = (globalState, dateIndex) => {
   const aboutDayTimeFormSelector = formValueSelector("addDayTimeForEventsForm")

   const dateArray = []

   for(let i = 0; i < dateIndex; i++) {
    dateArray.push({
     start: new Date(aboutDayTimeFormSelector(globalState, `${i}_start_date`)).getTime() / 1000,
     end: new Date(aboutDayTimeFormSelector(globalState, `${i}_end_date`)).getTime() / 1000,
    })
   }

   return dateArray
 }


 render(){
  const {
   submitFunc,
   isUpdateMode,
   isPublished,
   eventReminderPost,
   eventAnnouncementText,
   communityEventPostText,
   createEventResponse,
   updateEventResponse,
   globalState,
   dateIndex,
  } = this.props

   const {
    isFetching: isCreatingEvent,
   } = createEventResponse

   const {
    isFetching: isUpdatingEvent,
   } = updateEventResponse

  const dates = this.checkDates(globalState, dateIndex)
  const isValidDate = dates && dates.length > 0 && dates[0].start && dates[0].end && parseInt(dates[0].end, 10) > parseInt(dates[0].start, 10)
  const enablePublish = eventReminderPost && eventAnnouncementText && eventReminderPost.length > 0 && eventAnnouncementText.length > 0 && isValidDate

  return (
   <Wrap>
     <MenuList>
      <HeaderList> Event Details </HeaderList>
      <ListItem
       isActive={root.location.pathname.indexOf("settings") <= -1}
      >
       <Link
       to={isUpdateMode ? `/academy-admin/events/event/${isUpdateMode}`
       : "/academy-admin/events/add-new-event"}
       >
        Info
       </Link>
      </ListItem>
      {isUpdateMode ? (
       <ListItem
        isActive={root.location.pathname.indexOf("settings") > -1}
       >
        <Link
        to={`/academy-admin/events/event/${isUpdateMode}/settings`}
        >
         Settings
        </Link>
       </ListItem>
      ) : (
       <ListItem
        isActive={root.location.pathname.indexOf("settings") > -1}
       >
        <Link
        to={`/academy-admin/events/add-new-event/settings`}
        >
         Settings
        </Link>
       </ListItem>
      )}
     </MenuList>
     <CTAWrap>
       {isUpdateMode ? (
        <Update disabled={isUpdatingEvent} onClick={() => this.handleEventUpdate(isUpdateMode)} className="publish" type="submit">
         Update Event
       </Update>
      ) : (
       <Publish disabled={!enablePublish || isCreatingEvent} onClick={() => this.handleEventPublish()} className="publish" type="submit">
        Publish Event
      </Publish>
      )}
     </CTAWrap>
     <div className="alert">
       <p>Fields marked with <span> *</span> are mandatory, please check before publishing or updating.</p>
       {!enablePublish
       && (
        <p>Notification Settings are mandatory, please fill both via settings page.</p>
       )}
     </div>
     <ToastContainer />
   </Wrap>
  )
 }
}

export default Container(AddEventMenu)
