import React, { Component } from "react"
import styled from "styled-components"
import colors from "../../../lib/colors"
import Container from "container/Academy"
import { ToastContainer, toast } from "react-toastify"
import {
  ThreeBarsIcon,
  FileIcon,
  PlayIcon,
 } from '@primer/octicons-react'
import {
  Route, Switch, Link,
} from "react-router-dom"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const grid = 8;

const InnerWrap = styled.div`
 display: flex;
 div {
  flex-grow:1;
 }
 .icon {
  padding: 18px 10px 10px 10px;
  max-width: 35px;
  .file {
   color: green;
  }
 }
 .lesson-details {
  flex-grow: 12;
  padding: 11px;
  p {
   margin: 0;
  }
  .lesson-title{
   font-size: 16px;
   font-weight: 600;
   color: #282b2f;
  }
  .duration {
   font-size: 12px;
   color: #586069;
  }
 }
 .lesson-cta {
  display: flex;
  justify-content: flex-end;
  flex-grow: 0;
  div {
   flex-grow: 1;
   padding-top: 14px;
   padding-right: 14px;
   max-width: 99px;
   button{
    cursor: pointer;
    background: none;
    border: none;
    margin-top: 7px;
   }
   .delete {
    color: red;
   }
  }
 }
`

const EditLesson = styled(Link)`
  margin-bottom: 14px;
  width: 118px;
  display: block;
  height: 30px;
  font-size: 14px;
  border: none;
  background: none;
  color: black;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding-top: 6.9px;
`

class CourseListing extends React.Component {
state = {
   items: []
}

 componentDidMount() {
  const {
   course,
   courseList,
   arrangedCourses,
  } = this.props

  this.setState({
   items: arrangedCourses && arrangedCourses.length > 0 ? arrangedCourses : courseList
  })
 }

 onDragEnd = (result) => {
   const {
    saveCourseState,
   } = this.props

   if (!result.destination) {
     return;
   }

   const items = this.reorder(
     this.state.items,
     result.source.index,
     result.destination.index
   );

   const converted = []

   items.forEach((item, index) => {
    converted.push({
     ...item,
     sequence_number: index +1,
    })
   })

   saveCourseState({
    courses: converted,
   })

   this.setState({
     items: converted
   });

 }


reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging ? "lightgreen" : "white",
  borderBottom: "solid 1px #e2e4e8",
  ...draggableStyle
});

getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "white",
  width: "100%"
});


 render() {
  const {
   courseList,
  } = this.props

  const {
   items,
  } = this.state

  const filteredItems = items
  && items.length > 0
  && items.filter(key => key.published !== 0)

  return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={this.getListStyle(snapshot.isDraggingOver)}
            >
              {filteredItems && filteredItems.length > 0 && items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={this.getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                     <InnerWrap>
                      <div className="icon">
                       <ThreeBarsIcon size={16} />
                      </div>
                      <div className="lesson-details">
                        <p className="lesson-title">{item.title}</p>
                      </div>
                     </InnerWrap>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <ToastContainer />
      </DragDropContext>
    );
 }
}

export default Container(CourseListing)
