import React, { useEffect, } from "react"
import styled from "styled-components"

const Wrap = styled.div`
  
`

const FetchAddons = ({
  onMount,
}) => {
  useEffect(() => {
    onMount()
  }, [])
  return null
}

export default FetchAddons