import axios from "axios";
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  appURL: process.env.REACT_APP_API_BASE_URL + "/v1.0/",
  appURLV2: process.env.REACT_APP_API_BASE_URL + "/v2.0/",
  appURLV3: process.env.REACT_APP_API_BASE_URL + "/v3.0/"
});

instance.interceptors.request.use(
  request => {
    request.headers["internal-id"] = "d3ca2d4f40f2c501d33c474f78750a29";
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
