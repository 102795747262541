import React  from 'react';
import {Fetch} from "../../lib/NetworkUtils";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export function fetchCategorizedNotes(body) {
    let url = baseUrl + '/v1.0/admin-notes/categorized';
    return Fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export function saveNote(body) {
    let url = baseUrl + '/v1.0/admin-notes';
    return Fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export function updateNote(body, noteID) {
     let url = baseUrl + '/v1.0/admin-notes/' + noteID;
     return Fetch(url, {
         method: 'PUT',
         body: JSON.stringify(body)
     })
}