import React from "react"
import {
  DashboardBusy,
} from "./commonStyles"
import ProcessingGhost from "../../../../../images/soul/processing.gif"

const Busy = ({
  show,
}) => (
  <DashboardBusy show={show}>
    <img src={ProcessingGhost} alt="" />
    <h4>Processing..</h4>
  </DashboardBusy>
)

export default Busy
