import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "PRODUCTINTEREST"

//export const getCoursesAction = createSignalAction(BASE, "FETCH_COURSES")
export const getAllPimAction = createSignalAction(BASE, "FETCH_ALL_PIM")

export const setCurrentProductAction = createSimpleCreator(BASE, "CURRENT_PRODUCT")

export const setCurrentEditableProductAction = createSimpleCreator(BASE, "CURRENT_EDITABLE")

export const createNewFeatureOrChildAction = createSimpleCreator(BASE, "CREATE_NEW")

export const uploadNewFeatureAction = createSignalAction(BASE, "UPLOAD_NEW_FEATURE")

export const updateExistingFeatureAction = createSignalAction(BASE, "UPDATE_EXISTING")

export const uploadImageAction = createSignalAction(BASE, "UPLOAD_IMAGE")

export const currentImageURLSetAction = createSimpleCreator(BASE, "CURRENT_IMAGE_URL")

export const updateChildArrangementAction = createSimpleCreator(BASE, "CHILD_REARRANGE")

export const getCurrentRTFValueAction = createSimpleCreator(BASE, "CURRENT_RTF_VALUE")

export const setPublishedStatusAction = createSimpleCreator(BASE, "SET_PUBLISHED_STATUS")

export const setPIMAssistAction = createSimpleCreator(BASE, "SET_ASSIST_STATUS")

export const setPIMRARAction = createSimpleCreator(BASE, "SET_RAR_STATUS")

export const clearAndResetAction = createSimpleCreator(BASE, "CLEAR_AND_RESET")

export const clearFormsActions = createSimpleCreator(BASE, "CLEAR_FORMS")
