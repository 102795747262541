import React from "react"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import {
  BlueButton,
} from "../../commonStyles"

const ResendInvitation = ({
  disabled,
}) => (
  <BlueButton
    onClick={() => {
      //
    }}
    ATCFONT
    disabled={disabled}
    borderRadius="33.5px"
  >
    Resend Invitation
    <span>
      <img
        src="https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/ISA_SOUL_SUBMIT_Arrows.svg"
        alt="submit_isa"
      />
    </span>
  </BlueButton>
)

export default subscriptionContainer(ResendInvitation)

