import Popup from "reactjs-popup";
import React, { Component } from 'react';

class QualifyAndReferOut extends Component {
    state = {
        referral: null,
        submitting: false,
        locations: [],
    };

    componentDidMount() {
        this.setState({
            referral: this.props.referral,
            submitting: this.props.submitting,
            locations: this.props.locations,
        })
    }

    componentWillReceiveProps(props){
        this.setState({
            referral: this.props.referral,
            submitting: this.props.submitting,
            locations: this.props.locations,
        })
    }

    getQualifyColorForBtn = () => {
        const {
         referral: {
          isLeadAgreementSigned,
         },
        } = this.props
        const disabled = (  !isLeadAgreementSigned || this.state.submitting || (this.state.locations && this.state.locations.length === 0 ));
        if(disabled) {
            return "med-btn-6-disabled";
        }
        return "med-btn-6";
    };

    render() {
        const {
         referral: {
          isLeadAgreementSigned,
         },
        } = this.props
        return (
            <span>
            {this.state.referral && this.state.referral.is_isa === 1 &&
                    <Popup
                        trigger={
                            <button className={this.getQualifyColorForBtn()}
                                    disabled={!isLeadAgreementSigned || this.state.submitting || (this.state.locations && this.state.locations.length === 0)}>
                                Qualify and Refer out
                            </button>
                        }
                        modal
                        closeOnDocumentClick
                    >
                        {close => (
                            <div className="modal-2">
                                <div className="modal-header">
                                      <span className="modal-header-text">
                                          Qualify and refer out referral without notification to sender agent
                                      </span>
                                </div>
                                <div className="dialogue-box-footer">
                                    <button className="med-btn-1" onClick={() => {
                                        this.props.handleSubmitWithReferOut();
                                        close();
                                    }}>
                                        YES
                                    </button>
                                    <button className="med-btn-1" onClick={() => {
                                        close();
                                    }}>
                                        CANCEL
                                    </button>
                                </div>
                            </div>

                        )}
                    </Popup>
            }
            </span>
        )
    }
}

export default QualifyAndReferOut
