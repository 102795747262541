import React from "react"
import styled from "styled-components"
import pretty from "pretty"
import { CodeMirror } from "react-codemirror2"
import { renderToString } from "react-dom/server"
import { commonWrapStyles } from "./common"

const Wrap = styled.div`
 ${commonWrapStyles};
 background: #282C34;
 
`

const TextEditor = styled.textarea`
  width: 100%;
  min-height: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  font-family: Courier;
  background: transparent;
  color: #FFF;
`

class CodeViewer extends React.PureComponent {
  satte = {
  }

  componentDidMount() {
    // const {  } = this.props
  }

  handleEdit = (event) => {
    event.preventDefault()
    const { value } = event.target
    const { updateRender } = this.props
    updateRender(value)
  }

  getString = (components) => {
    const html = components.map(({
      Component,
      label
    }) => `
      <!-- Start  {${label}}  -->
      ${renderToString(<Component />)}
      <!-- End {${label}}  -->
    `)
    return html.join("\n\n")
  }

  render() {
    const { components, editedCode, isEdited, isNewComp } = this.props
    const codeText = this.getString(components)
    let finalCode = isEdited ? editedCode : pretty(editedCode || codeText || "", { ocd: true })
    finalCode = isNewComp ? pretty(this.getString(components)) : finalCode
    return (
      <Wrap>
        <TextEditor
          id="emailCodeEditor"
          value={finalCode}
          onChange={this.handleEdit}
        >
        </TextEditor>
      </Wrap>
    )
  }
}

export default CodeViewer
