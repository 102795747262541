import React from "react";
import CheckList from "container/CheckList/index";
import "./styles.css";

const NoTemplatesFound = ({
  fetchAllTemplatesResponse,
  setIsAddChecklistModalOpen,
  setAddNewChecklist,
}) => {
  const handleAddChecklistModal = () => {
    setIsAddChecklistModalOpen(true);
    setAddNewChecklist(false);
  };
  return (
    <div className="no-templates-container">
      {Object.keys(fetchAllTemplatesResponse?.data || {}).length === 0 &&
      !Array.isArray(fetchAllTemplatesResponse.data) ? (
        <div className="no-templates-content">
          <div className="no-templates-border">
            <h3>No Checklist Available</h3>
            <p>
              It looks like you don't have any checklist yet. Start by creating
              a new checklist or replicating an existing one to streamline your
              processes.
            </p>
            <button
              style={{ cursor: "pointer" }}
              type="primary"
              size="large"
              onClick={handleAddChecklistModal}
            >
              Replicate from Existing Template
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CheckList(NoTemplatesFound);
