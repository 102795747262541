import React from "react";
import { Modal, Button } from "antd";

export const RemoveModal = ({
  isRemoveModalOpen,
  handleRemove,
  cancelRemovingModal,
}) => {
  return (
    <Modal
      destroyOnClose={true}
      mask={true}
      maskClosable={false}
      footer={[
        <Button
          key="cancel"
          className="add-due-btn pt-5 outline"
          onClick={cancelRemovingModal}
        >
          Cancel
        </Button>,
        <Button
          className="add-due-btn add-due-btn-color pt-5"
          key="ok"
          type="primary"
          onClick={handleRemove}
        >
          Remove
        </Button>,
      ]}
      title="Confirm Deletion?"
      visible={isRemoveModalOpen}
    >
      <p>
        Do you want to remove the documents from the checklist. This action is
        undoable. You might want to redo the process to add this document back
      </p>
    </Modal>
  );
};
