import {
  Drawer,
  Tag,
  Input,
  Button,
  Select,
  Modal,
} from "antd"
import React, { useState } from "react"
import styled from "styled-components"
import AgentSearch from "dumbComponents/BrokerDashboard/Components/AgentSearch"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import InviteeStatusTimeline from "./Timeline"
import RecruitAndEarn from "../../../../container/RecruitAndEarn"
import ConfirmationModal from "../../Common/ConfirmationModal"

const { Option } = Select

const { confirm } = Modal

const Wrap = styled.div`
 display: flex;
 justify-content: space-between;
 flex-direction: ${props => props.flex};
 align-items: baseline;

 ${props => props.padding && `
  padding: ${props.padding};
 `}

  ${props => props.gap && `
    gap: ${props.gap};
  `}
`

const InnerWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${props => props.flex};
  align-items: baseline;

  input {
    padding-left: 0px;
  }
`

const FlexWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const Error = styled.p`
  color: #e74f4f;
`

const Linked = styled.p`
  span {
    color: #303030;
    font-weight: 500;
    text-decoration: underline;
  }
`

const Label = styled.label`
  width: -webkit-fill-available;
  max-width: 100px;
`

const LoadingText = styled.p`
  display: flex;
 span {
   margin: 0px;
   animation: flicker 1s linear 0.2s infinite alternate;

    @keyframes flicker {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
 }
`

const Invitee = styled.p`
  font-size: 28px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
`

const InvitedInformation = (props) => {
  const [currentStatus, setStatus] = useState("")
  const [currentLinkedAgentId, setLinkedAgentId] = useState("")
  const [currentLinkedAgent, setLinkedAgent] = useState("")
  const [isError, setError] = useState("")

  const {
    toggleInvitedDrawer,
    isInfoDrawerOpen,
    inviteeData,
    updateInviteeStatus,
    fetchInviteeTimelineResponse,
    deleteInvite,
  } = props || {}

  const {
    name,
    referred,
    tags,
    id,
    email,
    phone,
    senderId,
  } = inviteeData || {}

  const {
    data: timelines,
    isFetching: fetchingTimelines,
  } = fetchInviteeTimelineResponse

  const showConfirm = () => {
    confirm({
      title: "Please confirm the action.",
      icon: <ExclamationCircleOutlined />,
      content: <ConfirmationModal
        typeSet="invitation"
        status={currentStatus || tags[0]}
        referrer={referred}
        agentName={name}
        currentLinkedAgent={currentLinkedAgent}
      />,
      onOk() {
        handleSubmit()
      },

      onCancel() {
        return false
      },
    })
  }

  const confirmDelete = () => {
    confirm({
      title: "Please confirm the action.",
      icon: <ExclamationCircleOutlined />,
      content: <ConfirmationModal
        typeSet="delete"
        status={currentStatus || tags[0]}
        referrer={referred}
        agentName={name}
        currentLinkedAgent={currentLinkedAgent}
      />,
      onOk() {
        deleteInvite({
          id,
        })
        handleClose()
      },

      onCancel() {
        return false
      },
    })
  }

  const handleClose = () => {
    toggleInvitedDrawer({
      inviteeData: null,
      bool: false,
    })
    clearFields()
  }

  const getTag = (tag) => {
    let color = ""
    switch (tag) {
      case "invited":
        color = "#D8A8007D"
        break
      case "joined":
        color = "#62AD047D"
        break
      case "declined":
        color = "#DC0000AB"
        break
      case "non_responsive":
        color = "#D3D3D3"
        break
      case "reaching_out":
        color = "#45ABF5"
        break
      case "in_progress":
        color = "#7DF5D5"
        break
      default:
        color = ""
        break
    }

    return (
      <Tag color={color} key={tag} style={{ color: "#303030" }}>
        {tag.toUpperCase()}
      </Tag>
    )
  }

  const clearFields = () => {
    setStatus(null)
    setLinkedAgent("")
    setLinkedAgent("")
    setError(false)
  }

  const handleSubmit = () => {
    const payload = {
      status: currentStatus || tags[0],
    }

    if (currentLinkedAgentId) {
      payload.receiverId = currentLinkedAgentId
    }

    updateInviteeStatus({
      id,
      payload,
    })

    clearFields()
  }

  const handleStatusChange = (val) => {
    if (val === "invited" || val === "declined") {
      setLinkedAgentId("")
      setLinkedAgent("")
    }

    setStatus(val)
  }

  const handleSenderAgent = (agent) => {
    try {
      const {
        id: agentId,
        firstname,
        lastname,
      } = agent
      if (senderId !== agentId) {
        setLinkedAgentId(agentId)
        setLinkedAgent(`${firstname} ${lastname}`)
        setError(false)
      } else {
        setLinkedAgent("")
        setLinkedAgentId("")
        setError(true)
      }
    } catch (e) {
      console.log(e)
    }
    return true
  }

  const handleExit = () => {
    clearFields()
    toggleInvitedDrawer({
      inviteeData: null,
      bool: false,
    })
  }

  const isDisabled = () => {
    if (currentStatus === "declined" || currentStatus === "invited") return false
    if (currentStatus === "joined" && !currentLinkedAgentId) return true
    return false
  }

  return (
    <Drawer
      width={537}
      placement="left"
      onClose={handleClose}
      visible={isInfoDrawerOpen}
    >
      <>
        {inviteeData ? (
        <>
          <Wrap flex="row">
            <Invitee>{name || ""}</Invitee>
            {getTag(tags[0] || "")}
          </Wrap>
          <Wrap flex="column" padding="72px 0px" gap="12px">
            <InnerWrap flex="row">
              <Label htmlFor="referredBy">Email:</Label>
              <Input readOnly name="referredBy" value={email || "n/a"} style={{ border: "none", cursor: "pointer" }} />
            </InnerWrap>
            <InnerWrap flex="row">
              <Label htmlFor="referredBy">Phone:</Label>
              <Input readOnly name="referredBy" value={phone || "n/a"} style={{ border: "none", cursor: "pointer" }} />
            </InnerWrap>
            <InnerWrap flex="row">
              <Label htmlFor="referredBy">Invited by:</Label>
              <Input readOnly name="referredBy" value={referred} style={{ border: "none", cursor: "pointer" }} />
            </InnerWrap>
            <InnerWrap flex="row">
              <Label htmlFor="status">Status:</Label>
              <Select
                style={{
                  width: 140,
                }}
                onChange={e => handleStatusChange(e)}
                placeholder="Filter by"
                value={currentStatus || tags && tags[0]}
              >
                <Option value="invited">Invited</Option>
                <Option value="joined">Joined</Option>
                <Option value="ineligible">Ineligible</Option>
                <Option value="declined">Declined</Option>
                <Option value="non_responsive">Non-Responsive</Option>
                <Option value="reaching_out">Reaching Out</Option>
                <Option value="in_progress">In Progress</Option>
              </Select>
            </InnerWrap>
            <InnerWrap flex="row">
              <Label htmlFor="status">Link Account (Mandatory):</Label>
              <FlexWrap>
                <AgentSearch
                  onAgentSelect={handleSenderAgent}
                  customInputStyling={{
                    height: "35px",
                    paddingLeft: "11px",
                    fontSize: "13px",
                  }}
                />
                {currentLinkedAgent && (
                  <Linked>
                    Click on Save to link this invited agent to
                    {" "}
                    <span>
                      {`${currentLinkedAgent}`}
                    </span>
                  </Linked>
                )}
                {isError && (
                  <Error>Referred agent cannot be linked to Sender Agent</Error>
                )}
              </FlexWrap>
            </InnerWrap>
          </Wrap>
          <Wrap flex="column" padding="5px 0px">
            {fetchingTimelines ? (
              <LoadingText>
                Fetching timelines
                <span>
                  ...
                </span>
              </LoadingText>
            ) : (
              <InviteeStatusTimeline timelines={timelines} />
            )}
          </Wrap>
          <Wrap
            gap="10px"
            flex="row"
            style={{
              justifyContent: "space-between",
              position: "absolute",
              bottom: "0",
              right: "0",
              padding: "22px",
              width: "100%",

            }}
          >
            <Wrap>
              <Button type="danger" onClick={confirmDelete}>Delete</Button>
            </Wrap>
            <Wrap
              gap="10px"
              flex="row"
              style={{
                justifyContent: "space-between",
                position: "absolute",
                bottom: "0",
                right: "0",
                padding: "22px",
              }}
            >
              <Button onClick={handleExit}>Cancel</Button>
              <Button
                type="primary"
                disabled={isDisabled()}
                onClick={showConfirm}
              >
                Save
              </Button>
            </Wrap>
          </Wrap>
        </>
        ) : null}
      </>
    </Drawer>
  )
}
export default RecruitAndEarn(InvitedInformation)
