import React from "react"
import styled from "styled-components"

const ButtonWrap = styled.button`
  display: flex;
  padding: 10px 10px;
  border-radius: 20px;
  border: none;
  outline: none;
  font-size: 18px;
  color: #fff;
  margin-right: 10px;
  cursor: pointer;
  
  &:disabled,
  &[disabled]{
    color: #888;
    background: #ccc;
  }
  ${props => props.type === "success" && `
    background: #27ae60;
  `}

  ${props => props.type === "info" && `
    background: #2c3e50;
    color: #fff;
  `}

  ${props => props.type === "danger" && `
    background: #c0392b;
    color: #fff;
  `}
  

${props => props.small && `
  padding: 6px 8px;
  font-size: 16px;
`}
`

const ButtonInner = styled.div`
  padding: 0px 5px;
`

const IconWrap = styled.div`
  padding: 0px 5px;
`

export const Button = ({
  children,
  icon,
  type = "success",
  ...props
}) => (
  <ButtonWrap type={type} {...props}>
    {icon && (
      <IconWrap>
        <i className={`fas fa-${icon}`}></i>
      </IconWrap>
    )}
    <ButtonInner>
      {children}
    </ButtonInner>
  </ButtonWrap>
)