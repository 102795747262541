import styled from "styled-components"

export const ChatCardWrap = styled.div`
  display: flex;
  padding: 24px 13px;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  align-self: stretch;
  flex-direction: column;

   ${props => props.applyBorder && `
    border: 1px solid #E1E1E1;
    background: #FFF;
    border-radius: 4px;
  `}
`
export const ChatCardUpperRow = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`

export const ChatCardProfileImage = styled.div`
  width: 19.792px;
  height: 19px;
  border-radius: 100px;

  img {
    width: 100%;
    height: 100%;
  }

  ${props => props.fill && `
    background-color: #5A5FF2;
    img {
      position: relative;
      top: -2px;
    }
  `}
`
export const AIResponse = styled.p`
  color: #333;
  text-align: justify;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const TimeAndDate = styled.div`
  border-left: 1px solid #E5E9EB;
  
  p {
    color: #7F7F7F;
    text-align: justify;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding-left: 10px;
  }
`

export const AIBorder = styled.div`
  width: 3px;
  height: 81px;
  border-radius: 2.5px;
  background: ${props => props.bgColor || "black"};
`

export const ChatMessageAI = styled.div`
  p {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    white-space: pre-wrap;
  }
`

export const ContentArea = styled.div`
  display: flex;
  gap: 10px;
`

export const Reported = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    width: 19.2px;
    height: 19.2px;
    flex-shrink: 0;
  }

  p {
    color: ${props => props.reportColor || "black"};
    text-align: justify;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }
`

export const AgentMessage = styled.div`
  p {
    color: #333;
    text-align: justify;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: pre-wrap;
  }
`

export const SoulMessage = styled.div`
  color: #333;
  text-align: justify;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: pre-wrap;
`

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
