import React from "react"
import {
  SubmitButton,
  SubmitButtonText,
  CancelButtonText,
  CancelButton,
} from "./commonStyles"

export const SubmitFormButton = ({
  submitting,
}) => (
  <SubmitButton type="primary" htmlType="submit">
    {submitting ? (
      <SubmitButtonText>Submitting ...</SubmitButtonText>
    ) : (
      <>
        <SubmitButtonText>
           Submit
        </SubmitButtonText>
        <img
          src="https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/ISA_SOUL_SUBMIT_Arrows.svg"
          alt="submit_isa"
        />
      </>
    )}
  </SubmitButton>
)

export const CancelFormButton = ({
  onClick,
}) => (
  <CancelButton type="button" onClick={onClick}>
    <CancelButtonText>
      Cancel
    </CancelButtonText>
  </CancelButton>
)
