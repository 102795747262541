import React from "react"
import {
  CardWrap,
  AgentName,
  UpperWrap,
  HeaderWrap,
  ChevronWrap,
} from "./cardStyles"
import {
  StatusCard,
  TopicCount,
} from "./Elements"
import {
  getText,
  getIcon,
  getBGColor,
} from "./commonUtils"

const AgentCard = ({
  name,
  selected,
  onClick,
  count,
  recentStatus,
}) => {
  return (
    <CardWrap
      selected={selected}
      onClick={onClick}
    >
      <HeaderWrap>
        <UpperWrap>
          <AgentName>
            <p>{name}</p>
          </AgentName>
          <TopicCount count={count} />
        </UpperWrap>
        <ChevronWrap>
          <img src="https://s3.amazonaws.com/cdn.agentdesks.com/images/SOUL_AI/chevronRight.png" alt="chevron" />
        </ChevronWrap>
      </HeaderWrap>
      <StatusCard
        text={getText(recentStatus)}
        icon={getIcon(recentStatus)}
        bgColor={getBGColor(recentStatus)}
      />
    </CardWrap>
  )
}

export default AgentCard
