import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as Actions from "./actions"

function mapStateToProps(state) {
  const { eventRooms } = state
  return {
    state,
    ...eventRooms,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      getRooms: Actions.getRooms.request,
      createRooms: Actions.createRooms.request,
      updateRoom: Actions.updateRoom.request,
      endRoom: Actions.endRoom.request,
      getFinancingClientList: Actions.getFinancingClientList.request,
      getFinancingSetting: Actions.getFinancingSetting.request,
      saveFinancingSetting: Actions.saveFinancingSetting.request,
      updateFinancingClientList: Actions.updateFinancingClientList.request,
      deleteClient: Actions.deleteClient.request,
      getAllJobsByTeamId: Actions.getAllJobsByTeamId.request,
      getJobApplications: Actions.getJobApplications.request,
      updateJobApplications: Actions.updateJobApplications.request,
      createJobs: Actions.createJobs.request,
      updateJobs: Actions.updateJobs.request,
      deleteJobs: Actions.deleteJobs.request,
      showJobConfirmationAction: Actions.showJobConfirmationAction.call,
      uploadToS3: Actions.uploadImagesToS3Action.request,
      setImageBlocks: Actions.setImageBlocksAction.call,
      setCurrentImageToBeCropped: Actions.setCurrentImageToBeCroppedAction.call,
      deleteModeToggle: Actions.toggleDeleteModeAction.call,
      getCashbackList: Actions.getCashbackList.request,
      getCashbackAgentInfo: Actions.getCashbackAgentInfo.request,
      updateCashbackAgentInfo: Actions.updateCashbackAgentInfo.request,
      createCashbackAmountCredits: Actions.createCashbackAmountCredits.request,
      getCashbackAgentTimeline: Actions.getCashbackAgentTimeline.request,
      sendJobNotifications: Actions.sendJobNotifications.request,

      getOnboardingCommission: Actions.getOnboardingCommission.request,
      createOnboardingCommission: Actions.createOnboardingCommission.request,
      updateOnboardingCommission: Actions.updateOnboardingCommission.request,
      getRecordings: Actions.getRecordedRoomsContentAction.request,
      toggleDownloadDrawer: Actions.toggleDownloadDrawerAction.call,
      deleteRecordings: Actions.deleteRecordingsAction.request,
      getScheduledRooms: Actions.getScheduledRoomsAction.request,
      toggleScheduledRoomDrawer: Actions.toggleScheduledRoomDrawerAction.call,
      updateScheduledRooms: Actions.updateScheduledRoomsAction.request,
      deleteScheduledRooms: Actions.deleteScheduledRoomsAction.request,
      toggleDeleteAllScheduledRoomsOccurence: Actions.deleteAllOccurencesCheckAction.call,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
