import React, { Component } from "react"
import fetch from 'isomorphic-fetch'
import bugsnag from 'bugsnag-js'
import moment from 'moment'
const baseUrl = process.env.REACT_APP_API_BASE_URL
const token = process.env.REACT_APP_SECRET_ID

const originalKey = process.env.REACT_APP_BUGSNAG_KEY
const bugsnagClient = bugsnag(originalKey)

class ShareTheLove extends Component {
    state = {
        sent: [],
        pending: [],


    };

    getSubscriptionInvites = async () => {
        try {
            let response = await fetch(baseUrl + '/v1.0/admin-subscription-invites', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'secure-id': token,
                },
            });
            let respJson = await response.json();
            this.setState({
                sent: respJson.response.sent,
                pending: respJson.response.pending,

            })
        } catch (e) {
            bugsnagClient.notify(e);
        }
    };


    updateAmount = async (type, id) => {
        let item = null;
        if (type === "pending"){
            item =  this.state.pending.find(item => item.id === id);
            if (item){
                item.saving = true
            }
            const pendingItems = this.state.pending.map((currentItem) => {
                if (currentItem.id === item.id){
                    return item;
                }
                return currentItem;
            })
            this.setState({pending: pendingItems})
        }
        else{
            item =  this.state.sent.find(item => item.id === id);
            if (item){
                item.saving = true
            }
            const items = this.state.sent.map((currentItem) => {
                if (currentItem.id === item.id){
                    return item;
                }
                return currentItem;
            })
            this.setState({sent: items})

        }
        try {
            let response = await fetch(baseUrl + '/v1.0/admin-subscription-invites/'+item.id, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'secure-id': token,
                },
                body: JSON.stringify({
                    amount_charged : item.amount_sent
                })
            });
            let respJson = await response.json();
            if (type === "pending"){
                if (item){
                    item.saving = false
                }
                const pendingItems = this.state.pending.map((currentItem) => {
                    if (currentItem.id === item.id){
                        return item;
                    }
                    return currentItem;
                })
                this.setState({pending: pendingItems})
            }
            else{
                let item =  this.state.sent.find(item => item.id === id);
                if (item){
                    item.saving = false
                }
                const items = this.state.sent.map((currentItem) => {
                    if (currentItem.id === item.id){
                        return item;
                    }
                    return currentItem;
                })
                this.setState({sent: items})

            }
            window.location.reload();

        } catch (e) {
            bugsnagClient.notify(e);
        }

    }


    getSentItemsDiv = () => {
        if (this.state.sent.length === 0){
            return null;
        }
        const div = this.state.sent.map((item) => {
            return <tr>
                    <td>{(item.sender.firstname || "") + " " + (item.sender.lastname || "") }</td>
                    <td>{item.receiver_name}</td>
                    <td>{item.email}</td>
                    <td></td>
                    <td>
                        <input onChange={(e) => this.handleTextChange(e, 'sent', item.id)} value={item.amount_sent || ""} />

                    </td>
                <td><button onClick={() => this.updateAmount('sent', item.id)} disabled={item.saving}>Save</button></td>
            </tr>

        })
        return div;
    }

    handleTextChange = (e, type, id) => {
        if (type === "pending"){
            let item =  this.state.pending.find(item => item.id === id);
            if (item){
                item.amount_sent = parseInt(e.target.value, 10)
            }
            const pendingItems = this.state.pending.map((currentItem) => {
                if (currentItem.id === item.id){
                    return item;
                }
                return currentItem;
            })
            this.setState({pending: pendingItems})
        }
        else{
            let item =  this.state.sent.find(item => item.id === id);
            if (item){
                item.amount_sent = parseInt(e.target.value, 10)
            }
            const sendItems = this.state.sent.map((currentItem) => {
                if (currentItem.id === item.id){
                    return item;
                }
                return currentItem;
            })
            this.setState({sent: sendItems})

        }
    }

    getPendingDiv = () => {
        if (this.state.pending.length === 0){
            return null;
        }
        const div = this.state.pending.map((item) => {
            return <tr>
                <td>{(item.sender.firstname || "") + " " + (item.sender.lastname || "") }</td>
                <td>{item.receiver_name}</td>
                <td>{item.email}</td>
                <td></td>
                <td>
                    <input onChange={(e) => this.handleTextChange(e, 'pending', item.id)} value={item.amount_sent || ""} />

                </td>
                <td><button onClick={() => this.updateAmount('pending', item.id)} disabled={item.saving}>Save</button></td>
            </tr>

        })
        return div;
    }


    componentDidMount() {
        this.getSubscriptionInvites();
    }

    render() {

        const sentItemsDiv = this.state.sent && this.state.sent.length > 0 ? this.getSentItemsDiv() : null;
        const pendingItemsDiv = this.state.pending && this.state.pending.length > 0 ? this.getPendingDiv() : null;

        return <div style={{ paddingTop: "60px" }}>
            <h1>Pending</h1>
            <table className="dashboard">
            <tr>
                <th>Sender</th>
                <th>Receiver</th>
                <th>Receiver Email</th>
                <th>PlanId</th>
                <th>Amount</th>
            </tr>
            {pendingItemsDiv}
            </table>
            <h1>GIFT CARDS SENT</h1>
            <table className="dashboard">
                <tr>
                    <th>Sender</th>
                    <th>Receiver</th>
                    <th>Receiver Email</th>
                    <th>PlanId</th>
                    <th>Amount</th>
                    <th>Action</th>
                </tr>
                {sentItemsDiv}
            </table>

        </div>
    }
}
export default ShareTheLove;
