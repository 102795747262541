import React from "react"
import AI from "container/AI/index"
import { Input } from "antd"
import {
  UserInputWrap,
} from "./userInputStyles"

const AiToggle = ({
  onChange,
  isDisabled,
  message,
  onKeyPress,
}) => (
  <UserInputWrap>
    <Input
      value={message}
      disabled={isDisabled}
      onChange={onChange}
      placeholder={isDisabled ? "Disable AI to send message.." : "Enter your message.."}
      onKeyPress={onKeyPress}
    />
  </UserInputWrap>
)

export default AI(AiToggle)
