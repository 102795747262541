import { takeLatest, call, put, select } from "redux-saga/effects"
import deepMerge from "deepmerge"
import { isSuccess } from "services/Utils"
import { isEmpty } from "lodash"
import * as SettingsAction from "./actions"
import * as API from "./api"

console.log({ SettingsAction })

function* hadleFetchMultiCallerState() {
  try {
    const res = yield call(API.fetchMultiCallerStateAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsAction.getMultiCallerStateAction.success(response))
    }
  } catch (error) {
    yield put(SettingsAction.getMultiCallerStateAction.failure(error))
  }
}

function* changeMultiCallerState(action) {
  try {
    const res = yield call(API.changeMultiCallerStateAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsAction.changeMultiCallerStateActions.success(response))
      yield put(SettingsAction.getMultiCallerStateAction.request())
    }
  } catch (error) {
    yield put(SettingsAction.changeMultiCallerStateActions.failure(error))
  }
}

export default function* main() {
  yield takeLatest(SettingsAction.getMultiCallerStateAction.REQUEST, hadleFetchMultiCallerState)
  yield takeLatest(SettingsAction.changeMultiCallerStateActions.REQUEST, changeMultiCallerState)
}
