import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"
const initState = {
    isNewFeatureOrChild: false,
    isPublished: false,
    isAssist: false,
    isRAR: false,
    isEditingExisting: false,
    ...createDeltaReducer("getAllPIMResponse"),
    ...createDeltaReducer("uploadImageResponse"),
    ...createDeltaReducer("uploadNewFeatureResponse"),
    ...createDeltaReducer("updateFeatureResponse"),
}

export default function clientProfileReducer(state = initState, action) {
    switch (action.type) {
        case Actions.getAllPimAction.REQUEST:
        case Actions.getAllPimAction.SUCCESS:
        case Actions.getAllPimAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(state, action, Actions.getAllPimAction, "getAllPIMResponse"),
            }
        }

        case Actions.uploadNewFeatureAction.REQUEST:
        case Actions.uploadNewFeatureAction.SUCCESS:
        case Actions.uploadNewFeatureAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(state, action, Actions.uploadNewFeatureAction, "uploadNewFeatureResponse"),
            }
        }


        //uploadImageAction
        case Actions.uploadImageAction.REQUEST:
        case Actions.uploadImageAction.SUCCESS:
        case Actions.uploadImageAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(state, action, Actions.uploadImageAction, "uploadImageResponse"),
            }
        }

        //updateExistingFeatureAction
        case Actions.updateExistingFeatureAction.REQUEST:
        case Actions.updateExistingFeatureAction.SUCCESS:
        case Actions.updateExistingFeatureAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(state, action, Actions.updateExistingFeatureAction, "updateFeatureResponse"),
            }
        }

        case Actions.setCurrentProductAction.type: {
          const {
            product,
            child,
            isChildView,
          } = action.data
          return {
            ...state,
            currentProduct: product,
            currentChildOfProductArray: child,
            isChildView,
          }
        }

        case Actions.createNewFeatureOrChildAction.type: {
          const {
            isNewFeatureOrChild,
            isChild,
            currentEditableProduct,
            currentImageURL,
            productDescription,
            isEditingExisting,
          } = action.data
          return {
            ...state,
            isNewFeatureOrChild,
            isChild,
            currentEditableProduct,
            currentImageURL,
            productDescription,
            isEditingExisting,
          }
        }

        case Actions.currentImageURLSetAction.type: {
         const {
          currentImageURL,
         } = action.data
         return {
             ...state,
             currentImageURL,
         }
        }

        case Actions.setCurrentEditableProductAction.type: {
          const {
            currentEditableProduct,
            currentIndex,
            isNewFeatureOrChild,
            isEditingExisting,
          } = action.data
          return {
            ...state,
            currentEditableProduct,
            currentIndex,
            isNewFeatureOrChild,
            isEditingExisting,
          }
        }

        case Actions.updateChildArrangementAction.type: {
          const {
            arranged,
          } = action.data
          return {
            ...state,
            arranged,
          }
        }

        case Actions.getCurrentRTFValueAction.type: {
         const {
          data,
          type,
         } = action.data
         return {
             ...state,
             [type]: data,
         }
        }

        case Actions.setPIMRARAction.type: {
          const {
            isRAR,
          } = action.data

          return {
            ...state,
            isRAR,
          }
        }

        case Actions.setPIMAssistAction.type: {
          const {
            isAssist,
          } = action.data

          return {
            ...state,
            isAssist,
          }
        }

        case Actions.setPublishedStatusAction.type: {
          const {
            isPublished,
          } = action.data

          return {
            ...state,
            isPublished,
          }
        }

        case Actions.clearAndResetAction.type: {
          return {
            ...state,
            isPublished: false,
            productDescription: "",
            currentImageURL: "",
          }
        }

        default: {
            return state
        }
    }
}
