import React from "react"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import {
  BlueButton,
} from "../../commonStyles"
import { showConfirm } from "../../ConfirmationModalUtil"

const SaveAgreement = ({
  saveTeamAgreement,
  currentAgentIDViewed,
  teamAgreementViewType,
  saveTeamAgreementResponse,
  fetchTeamAgreementsResponse,
  teamBasedCurrentTeamAgreementStatus,
  agentBrokerageInfoResponse,
  rarTeamListResponse,
}) => {
  const {
    isFetching,
  } = saveTeamAgreementResponse || {}

  const {
    data: files,
    isFetching: fetchingAgreements,
  } = fetchTeamAgreementsResponse || {}

  const {
    data: agentBrokerageData,
    isFetching: fetchingAgentInfoResponse,
  } = agentBrokerageInfoResponse || {}

  const {
    data: teamList,
    isFetching: fetchingTeamList,
  } = rarTeamListResponse || {}

  const shouldButtonBeDisabledBasedOnDBValue = () => {
    const customTeamAgreement = teamBasedCurrentTeamAgreementStatus

    console.log("DB VALUE", customTeamAgreement, "local value", teamAgreementViewType)

    if (teamAgreementViewType) {
      if ((teamAgreementViewType === 2 // is radius
        && (customTeamAgreement === 1
        || !customTeamAgreement))

        || (teamAgreementViewType === 1 // is custom
        && (customTeamAgreement === 0
          || (!customTeamAgreement)))

        || (teamAgreementViewType === 3 // is null
        && (customTeamAgreement === 1 || customTeamAgreement === 0))) {
        return false //should not be disabled
      }
    }
    return true
  }

  return (
    <BlueButton
      onClick={() => {
        const postAction = () => {
          const selectedValue = teamAgreementViewType === 2 ? 0 : teamAgreementViewType === 3 ? null : 1
          const teamId = agentBrokerageData && agentBrokerageData.brokerage_team_id
          const filteredTeams = teamList && teamList.length > 0 && teamList.filter(item => item.id === teamId) || []
          const teamName = filteredTeams && filteredTeams.length > 0 && filteredTeams[0].name || ""

          const payload = {
            is_custom_team_agreement: selectedValue,
            name: teamName,
          }

          saveTeamAgreement({
            teamId,
            payload,
          })
        }

        const message = "Proceed with this action?"
        const warning = "Invited team members will see this team agreement when they get onboarded."

        showConfirm(message, postAction, warning)
      }}

      ATCFONT
      disabled={files && files.length <= 0
        || isFetching
        || fetchingAgreements
        || fetchingAgentInfoResponse
        || !teamAgreementViewType
        || shouldButtonBeDisabledBasedOnDBValue()}
      borderRadius="33.5px"
    >
      {isFetching ? "Saving.." : "Save"}
      <span>
        <img
          src="https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/ISA_SOUL_SUBMIT_Arrows.svg"
          alt="submit_isa"
        />
      </span>
    </BlueButton>
  )
}

export default subscriptionContainer(SaveAgreement)

