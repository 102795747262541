import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import ImageUploader from 'react-images-upload';
import {
  Route, Switch, Link,
} from "react-router-dom"
import AddCourseMenu from "./AddCourseMenu"
import AddCourseForm from "./AddCourseForm"
import CourseRTF from "./CourseRTF"
import colors from "../../../lib/colors"
import Select from 'react-select';
import { DefaultLoader } from "components/UI/Loader/Loader";
import RTFEditor from "dumbComponents/Academy/Common/RTF"
import RTFEditorIO from "dumbComponents/Academy/Common/EditorRTF"
import _ from 'lodash';

const TOPICS = [{
  value: 1, label: "Agent Growth",
},{
  value: 2, label: "Tools & Services",
},{
  value: 3, label: "Marketing & Brand",
},{
  value: 4, label: "Compliance & Brokerage Support",
},{
  value: 5, label: "Lending",
},{
  value: 6, label: "Teams",
},{
  value: 7, label: "Radius Community",
}]

const Wrap = styled.div`
 height: 100px;
 width: 300px;
 border: 1px solid red;
 margin-left: 100px;
`

const EditInstructor = styled(Link)`
  margin-bottom: 14px;
  width: 118px;
  display: block;
  height: 30px;
  font-size: 14px;
  border: none;
  background: none;
  color: black;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding-top: 2px;
`

const ListWrap = styled.ul`
 list-style: none;
 padding:0;
 margin:0;
 li {
  width: 100%;
  height: 68px;
  border: solid 1px #e2e4e8;
  display: flex;
  .instructor_image {
    flex-grow: 1;
    height: 100%;
    max-width: 60px;
    padding-top: 14px;
    padding-left: 14px;
    img {
     width: 34px;
     height: 34px;
     border-radius: 18px;
    }
  }
  .instructor_details{
    height: 100%;
    padding: 14px;
    width: 222px;
   p {
    margin: 0;
   }
   p:last-child {
    font-size: 12px;
    color: #586069;
   }
  }
  .cta {
   height: 100%;
   width: 400px;
   display: flex;
   justify-content: flex-end;
   div {
    flex-grow: 1;
    height: 100%;
    padding: 23px 8px 8px 24px;
    max-width: 99px;
    button{
     cursor: pointer;
     background: none;
     border: none;
    }
    .delete {
     color: red;
    }
   }
  }
 }
`

const OuterWrap = styled.div`
 display: flex;
`

const RightMenu = styled.div`
  height: 500px;
  width: 699px;
  padding-left: 20px;

  .rtf {
   margin-top: -50px;
   width: 76%;
  }
  .insts {
   margin-top: -365px;
  }

  .courseForm {
   height: 100px;
   label {
    font-size: 14px;
    font-weight: bold;
    color: #24292e;
   }

   .categoryDefined {
    height: max-content;
    width: 100px;
    border: 1px solid #d1d5db;
    padding: 9px;
    margin-top: 10px;
    p {
     margin: 0;
    }
   }
  }

  .imagePreview {
   margin-bottom: 61px;
   .preview {
    width: 100%;
    img{
     max-width: 100%;
    }
   }
  }

  .header {
   height: 50px;
   width: 100%;
   p {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin: 0;
   }
  }

  .course-image {
   height: 300px;
   width: 100%;
   .image-header-div {
    display: flex;
    justify-content: space-between;
    button {
     top: 10px;
     height: 30px;
     position: relative;
     left: 50px;
     z-index: 9999;
    }
   }
   .title {
    font-size: 14px;
   font-weight: bold;
   color: #24292e;
   }
   .image-placeholder {
    width: 730px;
    height: 242px;
    border-radius: 8px;
    margin-bottom: 100px;
    .fileContainer {
     padding: 50px 0;
     p {
      display: none;
     }
     .uploadPicturesWrapper {
      position: absolute;
      .uploadPictureContainer{
        width: 30%;
      }
     }
    }
   }
  }

  .instructors-list {
   width: 100%;
   min-height: 100px;
   height: max-content;

   border: 1px solid #e2e4e8;
   .headerDiv {
    height: 33px;
    border-radius: 4px;
    border-bottom: solid 1px #e2e4e8;
    background-color: #f4f5f8;
   }
  }
`
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const Remove = styled.button`
  border: none;
  background: transparent;
  padding: 28px;
  font-size: 14px;
  font-weight: 500;
  color: #cb2431;
  cursor: pointer;
`


class AddCourse extends Component {
 state = {
  isUpdateMode: this.props.match.params.id,
  pictures: [],
  deletionMode: false,
 }
 componentDidMount(){
  const {
   fetchMultipleInstructors,
   getCourseDetails,
   getCategories,
   match,
   setCurrentCategoryType,
   courseDetailResponse,
   getCourseListing,
  } = this.props
  getCategories()

  const {
   data: courseDetail,
  } = courseDetailResponse

  if(this.props.match.params.id) {
   getCourseDetails({ id: this.props.match.params.id})
   // getCourseListing({
   //  id: courseDetail && courseDetail.category_id,
   // })
  } else {
   setCurrentCategoryType({
    currentCategorySelected: [{ value: "select-value", label: "Select", data:[]}]
   })
  }
  fetchMultipleInstructors()
 }

 getOptions = (instructorsData) => {
  if(!instructorsData || instructorsData.length <= 0) {
   return [{ value: "Loading", label: "Loading" }]
  } else {
   return instructorsData.length >= 0 && instructorsData.map(inst => {
    return {
      label: inst.name,
      value: inst.name,
      id: inst.id,
      instructor: inst,
    }
   })
  }
  return [{ value: "Loading", label: "Loading" }]
 }

 onDrop = (picture, file) => {
    this.setState({
        pictures: this.state.pictures.concat(picture),
    });
 }

 getCategoriesOptions = () => {
  const {
   getCategoriesResponse,
  } = this.props

  const {
   data: categoryData,
  } = getCategoriesResponse

  if(categoryData && categoryData.length > 0){
   return categoryData.map(key => {
    return { value: key.id, label: key.name, data: key }
   })
  }

  return [{ label: "Loading..", value: "Loading.." }]
 }

 initiateImageUpload = (id) => {
  const {
   pictures,
  } = this.state

  const {
   setCourseHeaderImage,
   courseDetailResponse,
  } = this.props

  const {
   data: courseDetail,
  } = courseDetailResponse || {}

  const file = pictures[0]

  setCourseHeaderImage({
   file,
   id,
   exisitingCourseDetails: courseDetail,
  })

 }

 handleCategorySelection = (val) => {
   const {
    setCurrentCategoryType,
    getCourseListing,
   } = this.props

   setCurrentCategoryType({
    currentCategorySelected: [val]
   })

   getCourseListing({
    id: val.value,
   })
 }
 //courseTopicSelectionAction

 handleTopicSelection = (val) => {
  const {
    courseTopicSelection,
  } = this.props

  courseTopicSelection({
    currentTopicSelected: [val],
  })

}

  getTopicName = (id) => {
    const topic = TOPICS.filter(item => parseInt(item.value, 10) === parseInt(id, 10))[0].label
    return topic || "ello"
  }

 handleInstructorDelete = (id, instArr) => {
   const {
    addInstructorToCourse,
   } = this.props
   let filtered = instArr.filter(function( obj ) {
      return obj.instructor.id !== id;
    });
    this.setState({
     deletionMode: true,
    })
    addInstructorToCourse(filtered)
 }

 handleInstructorSelect = (value, uniqueArray) => {
   const {
    addInstructorToCourse,
    instructorArray,
   } = this.props

   let instArr = [...uniqueArray]
   instArr.push({
    id: value.id,
    instructor: value.instructor,
   })
   let _instArr = _.uniqBy(instArr, function (e) {
   return e;
     });

   addInstructorToCourse(instArr)
 }

 render(){
  const {
   multipleInstructorsResponse,
   instructorArray,
   courseDetailResponse,
   getCategoriesResponse,
   currentCategorySelected,
   getCourseListingForCategoryResponse,
   currentTopicSelected,
  } = this.props

  const {
   isUpdateMode,
   pictures,
   deletionMode,
  } = this.state

  const {
   isFetching: fetchingCategories,
  } = getCategoriesResponse || {}

  const {
   isFetching: fetchingList,
  } = getCourseListingForCategoryResponse || {}

  const {
   data: courseDetail,
   isFetching: fetchingCourseDetails,
  } = courseDetailResponse || {}

  const {
   data: instructorsData,
  } = multipleInstructorsResponse

  let uniqueArray = []

  if(
   !fetchingCourseDetails
   && courseDetail
   && courseDetail.Instructors
   && isUpdateMode
   && isUpdateMode.length > 0
   && !deletionMode) {
    courseDetail.Instructors.forEach(inst => {
      if(inst) {
       uniqueArray.push({
        id: inst.id,
        instructor: inst,
       })
      }
    })
  }

  if(instructorArray.length > 0) {
   uniqueArray = _.uniqBy(instructorArray, function (e) {
     return e;
   });
  }

  if(uniqueArray.length > 0) {
   uniqueArray = _.uniqBy(uniqueArray, function (e) {
     return e;
   });
  }

  return (
    <React.Fragment>
    {fetchingCourseDetails || fetchingCategories ? (
      <OuterWrap>
        <AddCourseMenu
         submitFunc={this.submit}
         isUpdateMode={this.props.match.params.id || ""}
         isPublished={courseDetail && Boolean(courseDetail.published) || false}
        />
        <RightMenu>
         <DefaultLoader height={200} width={200}/>
        </RightMenu>
      </OuterWrap>
    ) : (
     <OuterWrap>
      <AddCourseMenu
       submitFunc={this.submit}
       isUpdateMode={this.props.match.params.id || ""}
       isPublished={courseDetail && Boolean(courseDetail.published) || false}
      />
      <RightMenu>
       <div className="header">
        <p className="title">About the course</p>
       </div>
       <div className="courseForm">
        <AddCourseForm
         details={courseDetail}
         onSubmit={this.submit}
         isUpdateMode={isUpdateMode}
        />
       </div>
       {fetchingList ? (
         <DefaultLoader height={20} width={20}/>
       ) : (
        <div className="courseForm">
          <label htmlFor="Course Category">Course Category (This cannot be changed)</label>
          {isUpdateMode && courseDetail && courseDetail.category_name ? (
           <div className="categoryDefined">
            <p>{courseDetail.category_name}</p>
           </div>
          ) : (
           <Select
            options={this.getCategoriesOptions()}
            onChange={this.handleCategorySelection}
            value={currentCategorySelected}
           />
          )}
        </div>
       )}
        {false ? (
         <DefaultLoader height={20} width={20}/>
       ) : (
        <div className="courseForm">
          <label htmlFor="Course Category">Topic</label>
          {isUpdateMode && courseDetail && courseDetail.topic_id ? (
           <div className="categoryDefined">
            <p>{this.getTopicName(courseDetail.topic_id)}</p>
           </div>
          ) : (
           <Select
            options={TOPICS}
            onChange={this.handleTopicSelection}
            value={currentTopicSelected}
           />
          )}
        </div>
       )}
       <div className="course-image">
       {true && (
        <React.Fragment>
          <div className="image-header-div">
            <p className="title">
             Header Image
            </p>
            <button
             disabled={pictures.length <= 0}
             onClick={() => this.initiateImageUpload()}>
             Save Image
            </button>
          </div>
           <div className="image-placeholder">
            <ImageUploader
                withIcon={true}
                buttonText='Choose images (Max file size 3mb)'
                onChange={this.onDrop}
                imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                maxFileSize={3242880}
                withPreview={true}
                singleImage={true}
                withLabel={true}
             />
           </div>
        </React.Fragment>
       )}
         {this.props.match.params.id
          && courseDetail
          && courseDetail.cover_image_url
          && (
          <div className="imagePreview">
            <p className="title">
              Image Preview
            </p>
            <div className="preview">
              <img src={courseDetail.cover_image_url} alt="course_image"/>
            </div>
          </div>
         )}
        <div className="rtf">
          <CourseRTF
           rtfContent={isUpdateMode ? courseDetail : ""}
          />
        </div>
         <div className="insts">
           <p className="title">Instructors</p>
           <div className="instructors-list">
            <div className="headerDiv">
              <Select
                options={this.getOptions(instructorsData)}
                onChange={e => this.handleInstructorSelect(e, uniqueArray)}
              />
            </div>
            {
             uniqueArray &&
             uniqueArray.length > 0 && (
             <ListWrap>
             {uniqueArray.map(data => {
               console.log(data)
               return (
                 <li>
                 <div className="instructor_image">
                     {data.instructor && data.instructor.image_url && data.instructor.image_url !== "abcd.com" ? (
                      <img src={data.instructor.image_url} alt="" />
                     ) : (
                      <img src="https://api.adorable.io/avatars/112/abott@adorable.png" alt="instructor_image" />
                     )}
                 </div>
                  <div className="instructor_details">
                    <p>{data.instructor && data.instructor.name}</p>
                    <p>{data.instructor && data.instructor.designation || ""}</p>
                  </div>
                  <div className="cta">
                    {/*<div>
                     <EditInstructor
                       to={`/academy-admin/people/${data.instructor && data.instructor.id}`}
                     >
                      Edit
                     </EditInstructor>
                    </div>*/}
                    <div>
                     <button
                      onClick={() => this.handleInstructorDelete(data.instructor.id, uniqueArray)}
                      className="delete">
                      Remove
                     </button>
                    </div>
                  </div>
                 </li>
                )
             })}
             </ListWrap>
            )}
           </div>
         </div>
       </div>
      </RightMenu>
     </OuterWrap>

    )}
    </React.Fragment>
  )
 }
}

export default Container(AddCourse)
