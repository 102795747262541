import { call, put, takeLatest } from "redux-saga/effects"; //takevery , take


import { isSuccess } from "services/Utils";
import { toast } from "react-toastify";
import { message } from "antd";
import { fetchAppCustomisationsAction, fetchWaitlistAction, saveWaitlistStatusAction, updateAppCustomisationsAction, uploadAppAssetsAction } from "./actions";
import { fetchAppCustomisationsAPI, fetchWaitlistAPI, saveWaitlistStatusAPI, updateAppCustomisationsAPI, uploadAppAssetsAPI } from "./api";
const token = process.env.REACT_APP_SECRET_ID

function* fetchWaitlistSaga(action) {
  try {
    const { startDate, endDate, status, searchText,limit, skip, callback } = action.data;
    const data = yield call(fetchWaitlistAPI, startDate, endDate,status,searchText, limit, skip);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchWaitlistAction.success(response ));
      if (callback) callback();
    }
  } catch (error) {
    // const { payload, callback } = action.data;
    message.error("Something went again.. Try again later");
    yield put(fetchWaitlistAction.failure(error.message));
    // if (callback) callback();
  }
}

function* saveWaitlistStatusSaga(action) {
  try {
    const { payload, callback } = action.data;
    const requestPayload = {
      'status': payload.status,
      'reason': payload.reason
    }
    const data = yield call(saveWaitlistStatusAPI, payload.id, requestPayload);
    if (isSuccess(data)) {
      const { response } = data.data;
      message.success("Status updated successfully");
      yield put(saveWaitlistStatusAction.success(response));
      if (callback) callback();
    }
  } catch (error) {
    const { payload, callback } = action.data;
    message.error("Something went again.. Try again later");
    yield put(saveWaitlistStatusAction.failure(error.message));
    if (callback) callback();
  }
}


function* updateAppCustomisationsSaga(action) {
  try {
    const {payload, callback } = action.data;
    const data = yield call(updateAppCustomisationsAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(updateAppCustomisationsAction.success(response ));
      if (callback) callback();
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(updateAppCustomisationsAction.failure(error.message));
  }
}


function* fetchAppCustomisationsSaga(action) {
  try {
    const id = action.data;
    const data = yield call(fetchAppCustomisationsAPI, id);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchAppCustomisationsAction.success(response ));
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(fetchAppCustomisationsAction.failure(error.message));
  }
}

function* uploadAppAssetsSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(uploadAppAssetsAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(uploadAppAssetsAction.success(response ));
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(uploadAppAssetsAction.failure(error.message));
  }
}

function* ConsumerAppSaga() {
  yield takeLatest(fetchWaitlistAction.REQUEST, fetchWaitlistSaga);
  yield takeLatest(saveWaitlistStatusAction.REQUEST, saveWaitlistStatusSaga);
  yield takeLatest(uploadAppAssetsAction.REQUEST, uploadAppAssetsSaga);
  yield takeLatest(updateAppCustomisationsAction.REQUEST, updateAppCustomisationsSaga);
  yield takeLatest(fetchAppCustomisationsAction.REQUEST, fetchAppCustomisationsSaga)
}

export default ConsumerAppSaga;
