import React from 'react';
import "./LetterAvatar.css";

const letterAvatar = props => {
    let capitalizedLetter;
    try {
        capitalizedLetter = (props.name) ? (props.name.match(/\b(\w)/g).join("").toUpperCase()).substr(0,2) : "";
    }
    catch(e) {
        capitalizedLetter = "-";
    }
    return <div className={"client-avatar"}>
        <p>{capitalizedLetter}</p>
    </div>
};

export default letterAvatar;
