import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/RecruitAndEarn"
import moment from 'moment'
import { Button } from "antd"
import Select from 'react-select';

const PendingTable = styled.table``

const TitleWrap = styled.div``

const Wrap = styled.div``

const Name = styled.p`
 margin: 0;
`
const Email = styled.p`
 margin: 0;
 font-size: 12px;
`

class PendingInvites extends React.Component{
 componentDidMount() {
  const {
   inviteList,
  } = this.props
 }

 handleCheckBox = (details,value) => {
  const {
   toggleInvite,
  } = this.props
  //toggleInvite
  toggleInvite({
   id: details.id,
   payload: {
    is_accepted: value.value,
   }
  })
 }

 handleDeleteRequest = (details) => {
     const {
        deleteInvite,
     } = this.props

     if(window.confirm("Are you sure you want to Delete Invite?")){
        deleteInvite({
            id:details.id,
        })
    }
 }

 render() {
  const {
   inviteList,
  } = this.props

  return(
   <Wrap>
    <TitleWrap>
     <h1>Pending Invites</h1>
    </TitleWrap>
    <PendingTable>
     <tr>
         <th>Invite Date</th>
         <th>Sender</th>
         <th>Receiver</th>
         <th>Joining Date</th>
         <th>Accepted Status</th>
         <th>Invite Options</th>
     </tr>
     {inviteList && inviteList.pending && inviteList.pending.map(key => (
      <tr>
          <td>{moment(key.created_at).format('DD-MMM-YYYY')}</td>
          <td>
           <Name>{(key.sender_firstname || "") + " " + (key.sender_lastname || "") }</Name>
           <Email>{key.sender_email}</Email>
          </td>
          <td>
           <Name>{(key.firstname || "") + " " + (key.lastname || "") }</Name>
           <Email>{key.email}</Email>
          </td>
          <td>{ key.join_date ? moment(key.join_date).format('DD-MMM-YYYY') : "N/A" }</td>
          <td>
          <Select
              placeholder={key.is_accepted === 1 ? "Accepted" : "Pending"}
              options={[
                { value: 1, label: "Accepted" },
              ]}
              onChange={(value) => {
                this.handleCheckBox(key,value)
              }}
            />
          </td>
          <td>
              <Button style={{margin:"10px"}} onClick={() => { window.open(`/broker/invite/edit/${key.id}`) }}>
                  Edit
              </Button>
              <Button style={{margin:"10px"}} onClick = {() => this.handleDeleteRequest(key)}>
                  Delete
              </Button>
          </td>
      </tr>
     ))}
    </PendingTable>
   </Wrap>
  )
 }
}

export default Container(PendingInvites)
