import React from "react"
import styled from "styled-components"
import { commonWrapStyles } from "./common"

const Wrap = styled.div`
  ${commonWrapStyles}
  background: #282C34;
`

const Content = styled.div`
  padding: 20px;
  border: dashed #999 2px;
  border-radius: 4px;
  max-width: 600px;
  margin: auto;
  background: #f9f9f9;
`

const CompWrap = styled.div`
  border: 1px dashed red;
  position: relative;
  .comp-name {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 5px;
    background: green;
    z-index: 10;
    font-size: 12px;
    color: #FFF;
  }
`

class RenderedComp extends React.PureComponent {
  state = {}

  componentDidMount() {
    // const {  } = this.props
  }

  renderResizer = () => (
    <div></div>
  )

  render() {
    const { components, editedCode, isShowNames } = this.props
    return (
      <Wrap>
        <Content>
          {!editedCode
            ? components.map(({ Component, label }, index) => (
              <CompWrap key={index}>
                <span className="comp-name">
                  {label}
                </span>
                <Component />
              </CompWrap>
            ))
            : <div dangerouslySetInnerHTML={{ __html: editedCode }}></div>
          }
        </Content>
      </Wrap>
    )
  }
}

export default RenderedComp
