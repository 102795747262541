import { call, put, throttle, takeLatest, select } from "redux-saga/effects"
import * as RecruitAndEarnActions from "./actions"
import { isSuccess } from "services/Utils"
import { toast } from "react-toastify"
import { format } from "date-fns"
import root from "window-or-global"
import * as APIs from "./api"

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const errorToastHelper = (message) => {
  toast.error(message || "Something went wrong!", {
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

function* handleFetchBrokerInvites(action) {
  try {
    const res = yield call(APIs.getBrokerInvitesAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.getBrokerInvitesAction.success(response))
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.getBrokerInvitesAction.failure(e))
  }
}

function* handleFetchBrokerEarnings(action) {
  try {
    const res = yield call(APIs.getBrokerEarningsAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.getBrokerEarningAction.success(response))
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.getBrokerEarningAction.failure(e))
  }
}

function* handleToggleBrokerInvites(action) {
  try {
    const {
      id,
      payload,
    } = action.data
    const res = yield call(APIs.toggleBrokerInviteAPI, id, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.toggleInviteAction.success(response))
      yield put(RecruitAndEarnActions.getBrokerInvitesAction.request())
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.toggleInviteAction.failure(e))
  }
}

function* handleUpdateBrokerEarnings(action) {
  try {
    const {
      id,
      payload,
    } = action.data
    const res = yield call(APIs.updateBrokerEarningsAPI, id, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.updateBrokerEarningsAction.success(response))
      yield put(RecruitAndEarnActions.getBrokerEarningAction.request())
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.updateBrokerEarningsAction.failure(e))
  }
}

function* handleFetchAgentInviteAnalytics(action) {
  try {
    const {
      agentId,
    } = action.data
    const res = yield call(APIs.fetchAgentInviteAnalyticsAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.fetchAgentInviteAnalytics.success(response))
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.fetchAgentInviteAnalytics.failure(e))
  }
}

function* handleSaveInvite(action) {
  try {
    const {
      payload,
      history,
    } = action.data
    const res = yield call(APIs.createNewInviteAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.createNewInviteAction.success(response))
      yield put(RecruitAndEarnActions.getBrokerInvitesAction.request())

      const {
        receiverId,
      } = response || {}

      if (receiverId) {
        history.push(`/broker/dashboard/${receiverId}/subscription`)
      } else {
        history.push("/broker/recruit-and-earn")
      }
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.createNewInviteAction.failure(e))
  }
}

function* handleEditInvite(action) {
  try {
    const {
      id,
      payload,
      history,
    } = action.data
    const res = yield call(APIs.editInviteAPI, id, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.editInviteAction.success(response))
      yield put(RecruitAndEarnActions.getBrokerInvitesAction.request())
      history.push("/broker/recruit-and-earn")
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.editInviteAction.failure(e))
  }
}

function* handleDeleteInvite(action) {
  try {
    const {
      id,
    } = action.data
    const res = yield call(APIs.deleteInviteAPI, id)
    if (isSuccess(res)) {
      const { response } = res.data
      toast.success("Invite Deleted")
      yield put(RecruitAndEarnActions.deleteInviteAction.success(response))
      yield put(RecruitAndEarnActions.getReferralsListAction.request({
        queryParams: "skip=0&limit=300",
      }))
      yield put(RecruitAndEarnActions.getTotalRecruitAndEarnStatsAction.request({
        query: "",
      }))
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.deleteInviteAction.failure(e))
  }
}

function* handlefetchInviteById(action) {
  try {
    const {
      id,
      callback,
    } = action.data
    const res = yield call(APIs.fetchInviteByIdAPI, id)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.fetchInviteByIdAction.success(response))
      callback(response)
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.fetchInviteByIdAction.failure(e))
  }
}

function* handleFetchReferrals(action) {
  try {
    const {
      queryParams,
    } = action.data
    const res = yield call(APIs.getAllReferralsAPI, queryParams)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.getReferralsListAction.success(response))
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.getReferralsListAction.failure(e))
  }
}

function* handleInviteeTimelineFetch(action) {
  try {
    const {
      agentId,
    } = action.data
    const res = yield call(APIs.getInviteeStatusTimelineAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.getInviteeTimelineAction.success(response))
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.getInviteeTimelineAction.failure(e))
  }
}

function* handleSenderTimelinesFetch(action) {
  try {
    const {
      agentId,
    } = action.data
    const res = yield call(APIs.getSenderTimelinesAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.getSenderTimelinesAction.success(response))
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.getSenderTimelinesAction.failure(e))
  }
}

function* handleUpdateInviteeStatus(action) {
  try {
    const {
      id,
      payload,
    } = action.data
    const res = yield call(APIs.updateInviteeStatusAPI, id, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.updateInviteeStatusAction.success(response))
      yield put(RecruitAndEarnActions.toggleInvitedDrawerAction.call({
        isInfoDrawerOpen: false,
        inviteeData: null,
      }))
      yield put(RecruitAndEarnActions.getReferralsListAction.request({
        queryParams: "skip=0&limit=300",
      }))
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)

    yield put(RecruitAndEarnActions.toggleInvitedDrawerAction.call({
      isInfoDrawerOpen: false,
      inviteeData: null,
    }))

    yield put(RecruitAndEarnActions.updateInviteeStatusAction.failure(e))
  }
}

function* handleFetchAllEarnings(action) {
  try {
    const {
      queryParams,
    } = action.data
    const res = yield call(APIs.getAllEarningsAPI, queryParams)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.getEarningsListAction.success(response))
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.getEarningsListAction.failure(e))
  }
}

function* handleFetchAgentDetails(action) {
  try {
    const {
      queryParams,
    } = action.data
    const res = yield call(APIs.getAllAgentDetailsAPI, queryParams)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.getAgentDetailsActionRAE.success(response))
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.getAgentDetailsActionRAE.failure(e))
  }
}

function* handleFetchAgentEarnings(action) {
  try {
    const {
      id,
    } = action.data
    const res = yield call(APIs.getAgentSpecificStatsAPI, id)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.getAgentSpecificDetailAction.success(response))
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.getAgentSpecificDetailAction.failure(e))
  }
}

function* handleEarningsUpdate(action) {
  try {
    const {
      id,
      payload,
    } = action.data
    const res = yield call(APIs.updateEarningsForAgentAPI, id, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.updateEarningsAction.success(response))
      yield put(RecruitAndEarnActions.getEarningsListAction.request({
        queryParams: "skip=0&limit=300",
      }))
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.updateEarningsAction.failure(e))
  }
}


function* handleFetchTotalStatsForRAE(action) {
  try {
    const {
      query = "",
    } = action.data || {}
    const res = yield call(APIs.getTotalStatsRAEAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RecruitAndEarnActions.getTotalRecruitAndEarnStatsAction.success(response))
    }
  } catch (e) {
    const {
      data: {
        error: {
          message,
        },
      },
    } = e || {}

    errorToastHelper(message)
    yield put(RecruitAndEarnActions.getTotalRecruitAndEarnStatsAction.failure(e))
  }
}

function* handleDownloadCSV(action) {
  try {
    // const {
    //   query = "",
    // } = action.data || {}
    const res = yield call(APIs.downloadCSV)
    const {data} = res
    const blob = new Blob([data],{type: 'text/csv;charset=utf-8;'})
    const link = document.createElement("a")
    link.setAttribute("href", URL.createObjectURL(blob))
    const currentDate = new Date()
    link.setAttribute("download", `${currentDate.getTime()}_${format(currentDate, "MMM_dd_yyyy")}.csv`)
    document.body.appendChild(link)
    link.click()
    yield put(RecruitAndEarnActions.downloadCSV.success())
    // if (isSuccess(res)) {
    //   const { response } = res.data
    //   yield put(RecruitAndEarnActions.downloadCSV.success(response))
    // }
  } catch (e) {
    // const {
    //   data: {
    //     error: {
    //       message,
    //     },
    //   },
    // } = e || {}

    // errorToastHelper(message)
    yield put(RecruitAndEarnActions.downloadCSV.failure())
  }
}

function* handleDownloadReferralCSV(action) {
  try {
    // const {
    //   query = "",
    // } = action.data || {}
    const res = yield call(APIs.downloadReferralCSV)
    const {data} = res
    const blob = new Blob([data],{type: 'text/csv;charset=utf-8;'})
    const link = document.createElement("a")
    link.setAttribute("href", URL.createObjectURL(blob))
    const currentDate = new Date()
    link.setAttribute("download", `${currentDate.getTime()}_${format(currentDate, "MMM_dd_yyyy")}.csv`)
    document.body.appendChild(link)
    link.click()
    yield put(RecruitAndEarnActions.downloadReferralCSV.success())
  } catch (e) {
    yield put(RecruitAndEarnActions.downloadReferralCSV.failure())
  }
}

export default function* main() {
  yield takeLatest(RecruitAndEarnActions.getBrokerInvitesAction.REQUEST, handleFetchBrokerInvites)
  yield takeLatest(RecruitAndEarnActions.getBrokerEarningAction.REQUEST, handleFetchBrokerEarnings)
  yield takeLatest(RecruitAndEarnActions.toggleInviteAction.REQUEST, handleToggleBrokerInvites)
  yield takeLatest(RecruitAndEarnActions.updateBrokerEarningsAction.REQUEST, handleUpdateBrokerEarnings)
  yield takeLatest(RecruitAndEarnActions.fetchAgentInviteAnalytics.REQUEST, handleFetchAgentInviteAnalytics)
  yield takeLatest(RecruitAndEarnActions.createNewInviteAction.REQUEST, handleSaveInvite)
  yield takeLatest(RecruitAndEarnActions.deleteInviteAction.REQUEST, handleDeleteInvite)
  yield takeLatest(RecruitAndEarnActions.fetchInviteByIdAction.REQUEST, handlefetchInviteById)
  yield takeLatest(RecruitAndEarnActions.editInviteAction.REQUEST, handleEditInvite)
  yield takeLatest(RecruitAndEarnActions.getReferralsListAction.REQUEST, handleFetchReferrals)
  yield takeLatest(RecruitAndEarnActions.getInviteeTimelineAction.REQUEST, handleInviteeTimelineFetch)
  yield takeLatest(RecruitAndEarnActions.updateInviteeStatusAction.REQUEST, handleUpdateInviteeStatus)
  yield takeLatest(RecruitAndEarnActions.getEarningsListAction.REQUEST, handleFetchAllEarnings)
  yield takeLatest(RecruitAndEarnActions.getAgentDetailsActionRAE.REQUEST, handleFetchAgentDetails)
  yield takeLatest(RecruitAndEarnActions.getAgentSpecificDetailAction.REQUEST, handleFetchAgentEarnings)
  yield takeLatest(RecruitAndEarnActions.updateEarningsAction.REQUEST, handleEarningsUpdate)
  yield takeLatest(RecruitAndEarnActions.getSenderTimelinesAction.REQUEST, handleSenderTimelinesFetch)
  yield takeLatest(RecruitAndEarnActions.getTotalRecruitAndEarnStatsAction.REQUEST, handleFetchTotalStatsForRAE)
  yield takeLatest(RecruitAndEarnActions.downloadCSV.REQUEST, handleDownloadCSV)
  yield takeLatest(RecruitAndEarnActions.downloadReferralCSV.REQUEST, handleDownloadReferralCSV)
}
