/* eslint-disable import/no-unresolved */
import React from "react"
import styled from "styled-components"
import { get } from "lodash"
import { withRouter } from "react-router-dom"
import { ShimmerBox } from "shared/styles/animation"
import BrokerDashboardContainer from "container/BrokerDashboard/sidebarContainer"
import CircularImage from "../../../../../common/UI/CircularImage"
import { SidebarTitle } from "../../common"

const Wrap = styled.div``

const Container = styled.div`
  padding: 16px;
  border: 1px solid #E8E8E8;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const TitleWrap = styled.div``

const AgentDetailsWrap = styled.div`
  display: flex;
  gap: 8px;
`

const DetailsWrap = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const AgentName = styled.p`
  margin: 0;
  overflow: hidden;
  color: var(--neutral-800, #191D23);
  text-overflow: ellipsis;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const AgentBrokerage = styled.p`
  margin: 0;
  overflow: hidden;
  color: var(--neutral-800, #191D23);
  text-overflow: ellipsis;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const AgentLocation = styled.p`
  margin: 0;
  overflow: hidden;
  color: #4F4F4F;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const Profile = ({
  agentDetailsResponse,
}) => {
  const {
    data: agentDetails,
    isFetching: fetchingAgentDetails,
  } = agentDetailsResponse || {}
  const firstname = get(agentDetails, "firstname", "")
  const lastname = get(agentDetails, "lastname", "")
  const brokerageName = get(agentDetails, "brokerage_info_from_LOC.brokerage")
  const brokerageLocation = get(agentDetails, "brokerage_info_from_LOC.state")
  const agentImage = get(agentDetails, "agent_image", "")
  console.log("agentDetails", agentDetails)
  return (
    <Wrap>
      <Container>
        <TitleWrap>
          <SidebarTitle>
            Profile
          </SidebarTitle>
        </TitleWrap>
        {fetchingAgentDetails ? <ShimmerBox w="256px" h="78px" /> : (
          <AgentDetailsWrap>
            {agentImage ? (
              <CircularImage
                name={`${firstname} ${lastname}`}
                image={agentImage}
                size={78}
              />
            ) : (
              <CircularImage
                name={`${firstname} ${lastname}`}
                // image
                size={78}
              />
            )}
            <DetailsWrap>
              <AgentName>
                {`${firstname} ${lastname}`}
              </AgentName>
              <AgentBrokerage>
                {brokerageName}
              </AgentBrokerage>
              <AgentLocation>
                {brokerageLocation}
              </AgentLocation>
            </DetailsWrap>
          </AgentDetailsWrap>
        )}
      </Container>
    </Wrap>
  )
}

export default withRouter(BrokerDashboardContainer(Profile))
