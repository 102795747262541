import React, { Component } from "react"
import styled from "styled-components"

const PreferencesNotes = styled.span`
  font-size: 13px;
  color: #0b618c;
`

const InnerSpan = styled.span`
  color: #0b618c;
  ${props => props.bold && `
    color: red;
   `}
`

const getPreferencesDivOLD = (settings) => {
    if (!settings){
        return null;
    }
    const {
        is_isa_buyer_client_preferred,
        is_isa_renter_client_preferred,
        is_isa_seller_client_preferred,
        isa_buyer_min_budget,
        isa_seller_min_budget,
        isa_renter_min_budget,
        isa_ask_credit_score,
        isa_preferred_timeline,
        qualification_preference,
        is_active_to_receive_referral,
        is_open_to_negotiation,
    } = settings;
    return <React.Fragment>
        <span className="preferences-note">Ask Credit Score: {isa_ask_credit_score === 1 ? 'YES' : 'NO'} </span><br/>
        <PreferencesNotes>Qualification Preference: <InnerSpan bold={qualification_preference === 0 || qualification_preference === 1}>{qualification_preference === 0 ? ('Minimal') : (qualification_preference === 1 ? 'Partial' : 'Full')}</InnerSpan> </PreferencesNotes><br/>
        {is_isa_buyer_client_preferred ? <React.Fragment><span  className="preferences-note">Interested in buyers</span><br/></React.Fragment> : null}
        {is_isa_seller_client_preferred ? <React.Fragment><span  className="preferences-note">Interested in sellers</span><br/></React.Fragment> : null}
        {is_isa_renter_client_preferred ? <React.Fragment><span className="preferences-note">Interested in renters</span><br/></React.Fragment> : null}
        {isa_buyer_min_budget ?  <React.Fragment><span  className="preferences-note">Buyer Min Budget: {isa_buyer_min_budget}</span><br/></React.Fragment> : null}
        {isa_seller_min_budget ? <React.Fragment><span  className="preferences-note">Seller Min Price: {isa_seller_min_budget}</span><br/></React.Fragment> : null}
        {isa_renter_min_budget ? <React.Fragment><span  className="preferences-note">Renter Min Budget: {isa_renter_min_budget}</span><br/></React.Fragment> : null}
        <span className="preferences-note">Vacation Mode Enabled: {is_active_to_receive_referral === 1 ? 'NO' : 'YES'} </span><br/>
        <span className="preferences-note">Open to negotiation: {is_open_to_negotiation === 1 ? 'YES' : 'NO'} </span><br/>

        <span  className="preferences-note">{isa_preferred_timeline ? ('Timeline: '  + isa_preferred_timeline) : null} </span>

    </React.Fragment>
}

const getTextInstructionsDiv = (settings) => {
    if (!settings){
        return null;
    }
    const {
        assist_preference_text,
        source_preference_text,
        source_display_text,
    } = settings;

    const sourcePreferenceText = source_preference_text || "N/A";
    return <React.Fragment>
        <span>Notes for Assist: {assist_preference_text ? assist_preference_text : "N/A"}</span><br/><br/>
        <span>{source_display_text ? "Notes for " + source_display_text + ": " + sourcePreferenceText: ""}</span><br/>
    </React.Fragment>
}

export {getPreferencesDivOLD, getTextInstructionsDiv}
