import React from "react"
import styled from "styled-components"
import colors from "../../../../lib/colors"
import { LeftOutlined, GoogleOutlined } from "@ant-design/icons"
import TechAssets from "container/TechAssets/index"

const HeaderWrap = styled.div`
  border-bottom: 1px solid #D3DAE8;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  ${props => props.noborder && `
  border: none;
`}
`

const H1 = styled.h1`
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.black};  
`

const SubTextWrap = styled.div``

const ReturnPara = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #303030;
  display: flex;
  cursor: pointer;
  span {
    margin-left: 10px;
  }
`

const Header = ({
  isFormPage,
  history,
  resetForm,
  googleAuthToken,
}) => {
  const {
    data: authToken,
  } = googleAuthToken || {}

  return (
    <>
      {isFormPage ? (
        <HeaderWrap noborder>
          <ReturnPara
            onClick={() => {
              resetForm()
              history.push("/realty/offers")
            }}
          >
            <LeftOutlined />
            <span>Return to All Offers</span>
          </ReturnPara>
  
        </HeaderWrap>
      ) : (
        <>
          <HeaderWrap>
            <H1>Offers</H1>
            <ReturnPara
              onClick={() => {
                resetForm()
                history.push("/broker/tc/offers")
              }}
            >
              <LeftOutlined />
              <span>Return to All Offers</span>
            </ReturnPara>
          </HeaderWrap>
          <SubTextWrap>
            {/* <SubText>View, add or edit your offer listings.</SubText> */}
          </SubTextWrap>
        </>
      )}
    </>
  )
}

export default TechAssets(Header)
