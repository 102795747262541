import React from "react";
import { EditOutlined, DeleteOutlined, PlusOutlined, FolderAddOutlined } from "@ant-design/icons";

export const actionMenu = [
  { key: "1", label: "Edit", icon: <EditOutlined />, borderBottom: true },
  {
    key: "2",
    label: "Add a New Document",
    icon: <PlusOutlined />,
    borderBottom: true,
  },
  {
    key: "3",
    label: "Add Criteria",
    icon: <FolderAddOutlined />,
    borderBottom: true,
  },
  { key: "4", label: "Remove", icon: <DeleteOutlined />, borderBottom: false },
];
