import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "AGENT"

export const fetchAgentSearchAction = createSignalAction(BASE, "FETCH_AGENT_SEARCH")

export const rosterFetchAgentSearchAction = createSignalAction(BASE, "FETCH_AGENT_SEARCH")

export const fetchStripePlansAction = createSignalAction(BASE, "FETCH_STRIPE_PLANS")

export const fetchSubscriptionsAction = createSignalAction(BASE, "FETCH_AGENT_SUBSCRIPTIONS")

export const checkoutPlanAction = createSignalAction(BASE, "CHECKOUT_PLAN")

export const fetchAgentSecureIdAction = createSignalAction(BASE, "FETCH_AGENT_SECURE_ID")

export const fetchUserReferralSettingsAction = createSignalAction(BASE, "FETCH_USER_REFERRAL_SETTINGS")

export const handleCouponApplyAction = createSignalAction(BASE, "APPLY_COUPON")

export const currentSelectedPlanAction = createSimpleCreator(BASE, "SET_CURRENT_PLAN")

export const getCurrentPlanInstallmentsAction = createSignalAction(BASE, "FETCH_CURRENT_PLAN_INSTALLMENTS")

export const setCurrentPlanInstallmentsAction = createSimpleCreator(BASE, "SET_CURRENT_PLAN_INSTALLMENTS")

export const setModifiedPremiumBenefitsAction = createSimpleCreator(BASE, "SET_MODIFIED_BENEFITS")

export const setLeadCapAction = createSimpleCreator(BASE, "SET_LEAD_CAP")

export const setCustomizedSubscriptionSettingsAction = createSignalAction(BASE, "SET_CUSTOMIZED_SUBSCRIPTIONS_SETTINGS")

export const getCurrentAutoReferOutSettingsActions = createSignalAction(BASE, "GET_CURRENT_AUTOREFER_SETTINGS")

export const setCurrentAutoReferOutSettingsActions = createSignalAction(BASE, "SET_AUTOREFER_SETTINGS")

export const couponRemovedAction = createSimpleCreator(BASE, "COUPON_REMOVED")

export const resetInstallmentSelectionAction = createSimpleCreator(BASE, "RESET_INSTALLMENT_PLAN_SELECTED")

export const currentRTLPlanSelectedAction = createSimpleCreator(BASE, "CURRENT_RTL_PLAN")

export const currentBLUPlanSelectedAction = createSimpleCreator(BASE, "CURRENT_BLU_PLAN")

export const currentBROKERPlanSelectedAction = createSimpleCreator(BASE, "CURRENT_BROKER_PLAN")

export const setCurrentModeAction = createSimpleCreator(BASE, "CURRENT_TYPE_MODE")

export const saveCouponStateAction = createSimpleCreator(BASE, "SAVE_COUPON_TYPE")

export const clearErrorMessageAction = createSimpleCreator(BASE, "CLEAR_ERROR_MESSAGE")

export const setBenefitsToBeAddedAction = createSimpleCreator(BASE, "SET_BENEFITS_TO_BE_ADDED")

export const setParentBenefitsPlansAction = createSimpleCreator(BASE, "SET_PARENT_BENEFITS_PLANS")

export const toggleAgentListDropdownAction = createSimpleCreator(BASE, "TOGGLE_DROPDOWN_AGENTLIST")
