import axios from "axios"

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const searchAgentByNameOrEmailAPI = (payload) => axios
    .post(`${baseUrl}/v1.0/agent/elastic/query`, payload)

export const fetchStripePlans = (payload) => axios
    .get(`${baseUrl}/v2.0/stripe/plans`)

export const fetchSubscriptions = (agentId) => axios
    .get(`${baseUrl}/v2.0/stripe/subscriptions?agentId=${agentId}`)

export const checkoutPlan = (payload, config) => axios
    .post(`${baseUrl}/v2.0/stripe/checkout`, payload, config)

export const checkoutV2 = (payload, config) => axios
    .post(`${baseUrl}/v2.0/stripe/checkout-blink`, payload, config)

export const fetchAgentSecureId = (agentId) => axios
    .get(`${baseUrl}/v2.0/user/secure_id?agentId=${agentId}`)

export const fetchUserReferralSettings = (agentId) => axios
    .get(`${baseUrl}/v1.0/user/${agentId}/referral-settings`)

export const applyPricingCouponAPI = payload => axios
    .post(`${baseUrl}/v2.0/stripe/applyCoupon`, payload)

export const getCurrentPlanInstallmentsAPI = planId => axios
   .get(`${baseUrl}/v2.0/stripe/plans?parentPlanId=${planId}`)

export const customizedSubscriptionSettingsAPI = (payload, config)=> axios
   .post(`${baseUrl}/v2.0/user/addCustomizedSubscriptionSettings`, payload, config)

export const getAutoReferOutSettings = agentId => axios
  .get(`${baseUrl}/v1.0/user/${agentId}/referral-settings`)

export const applyAutoReferOutSettings = (agentId, payload) => axios
  .put(`${baseUrl}/v1.0/user/${agentId}/referral-settings`, payload)
