import React, { useState } from "react"
import styled from "styled-components"
import {
  Button,
  Select,
  DatePicker,
  Input,
} from "antd"
import { withRouter } from "react-router-dom"
import AgentSearch from "dumbComponents/BrokerDashboard/Components/AgentSearch"
import RARTechContainer from "container/TechAssets"
import { OfferStatuses } from "dumbComponents/Offers/consts"

const { Option } = Select

const { Search } = Input

const OuterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px
`

const Wrap = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: flex-start;
 gap: 20px;
`

const InputWrap = styled.div`
  max-width: 293px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: baseline;
  height: max-content;
`

const Label = styled.label`
  min-width: fit-content;
`

const SearchControls = (props) => {
  const [currentFilter, setFilter] = useState("")
  // const [currentSort, setSort] = useState("")
  // const [currentMilestone, setCurrentMilestone] = useState("")
  // const [dateValue, setDate] = useState("")
  const [queryStore, setQueryStore] = useState([])

  const {
    history,
    setOfferFilterQueries,
    getOffers,
  } = props

  /**
   * get local query param
   * call api
   * set global value of query param
   * To write an object store.
   */

  const handleQuerySet = (queryPayload) => {
    let tempString = ""
    let temp = []
    if (queryStore && queryStore.length <= 0) {
      setOfferFilterQueries({
        queryObject: [queryPayload],
      })

      temp = [queryPayload]
      tempString = `skip=0&limit=100&${queryPayload.name}=${queryPayload.value}&`
      setQueryStore(temp)
    } else {
      temp = [...queryStore, queryPayload]

      tempString = "skip=0&limit=300&"

      temp.forEach((item) => {
        tempString += `${item.name}=${item.value}&`
      })

      setQueryStore(temp)
    }

    /**API CALLS HERE**/

    getOffers({
      skip: 0,
      limit: 300,
      agentId: 0,
      query: tempString,
    })

    /**API CALLS HERE */
  }

  const handleStatusChange = (value) => {
    if (!value || value === undefined) return false
    const queryPayload = {
      name: "offerType",
      value,
    }
    handleQuerySet(queryPayload)
    setFilter(value)

    return true
  }

  const searchOnLocal = (text, key) => {
    const queryPayload = {
      name: key,
      value: text,
    }

    handleQuerySet(queryPayload)
  }

  return (
    <OuterWrap>
      <Wrap>
        <InputWrap>
          <Label htmlFor="Filter">Filter by Offer Status</Label>
          <Select
            style={{
              width: 140,
            }}
            onChange={e => handleStatusChange(e)}
            value={currentFilter || null}
            placeholder="Filter by"
            options={OfferStatuses}
          />
        </InputWrap>
        <InputWrap>
          <Search
            placeholder="Search by Property Address"
            onSearch={(value) => { searchOnLocal(value, "propertyAddress") }}
            style={{ width: 300, border: "1px solid #C7C7C7", borderRadius: "4px" }}
          />
        </InputWrap>
        <InputWrap>
          <Search
            placeholder="Search by Agent Email"
            onSearch={(value) => { searchOnLocal(value, "agentEmail") }}
            style={{ width: 300, border: "1px solid #C7C7C7", borderRadius: "4px" }}
          />
        </InputWrap>
        <InputWrap>
          <Search
            placeholder="Search by Client Email"
            onSearch={(value) => { searchOnLocal(value, "clientEmail") }}
            style={{ width: 300, border: "1px solid #C7C7C7", borderRadius: "4px" }}
          />
        </InputWrap>
      </Wrap>
      <Wrap>
        <InputWrap>
          <Button
            danger
            onClick={() => {
              // setSort("")
              // setCurrentMilestone("")
              setFilter("")
              // setDate("")
              setQueryStore([])
              getOffers({
                skip: 0,
                limit: 300,
                agentId: 0,
                query: "",
              })
            }}
          >
            Reset Filters
          </Button>
        </InputWrap>
        <InputWrap>
          <Button
            type="primary"
            onClick={() => {
              history.push("/broker/tc/add-offers/coversheet/buyer-agent-info")
            }}
          >
            New Offer
          </Button>
        </InputWrap>
      </Wrap>
    </OuterWrap>
  )
}

export default withRouter(RARTechContainer(SearchControls))
