import React from "react"
import {
  HeaderWrap,
} from "./headerStyles"

const ChatAreaHeader = () => (
  <HeaderWrap>
    <h2>Details View</h2>
  </HeaderWrap>
)

export default ChatAreaHeader
