import axios from "axios"

const API_V2 = process.env.REACT_APP_API_V2_URL
const API_V1 = process.env.REACT_APP_API_BASE_URL

export const fetchClientTimelineAPI = (clientId, limit, offset, agentId) => axios
 .get(`${process.env.REACT_APP_API_V2_URL}clientTimeline/${clientId}?type=outgoing&limit=${limit}&offset=${offset}&agentId=${agentId}`)

export const fetchReferralForMergeAPI = payload => axios
  .post(`${API_V1}/v1.0/list-zillow-referrals`, payload)

export const mergeZillowLeadsAPI = payload => axios
  .post(`${API_V1}/v1.0/merge-referrals`, payload)
