import React, { Component } from 'react';
import _ from "lodash";
import moment from 'moment';
import bugsnag from 'bugsnag-js';

import "./SaleStageTimeline.css";

const originalKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagkey = 'duplicateKey';
const bugsnagClient = bugsnag(originalKey);

class SaleStageTimeline extends Component { 
  state = {
    currentStage: null,
    originalCurrentStage: null,
  };
  componentDidMount(){
    if (this.props.client && this.props.client['sales'] && this.props.client['sales']['Current stage']){
      this.setState(
        {
          currentStage: this.props.client['sales']['Current stage'],
          originalCurrentStage: this.props.client['sales']['Current stage'],

        }
      );
    }
  }

  componentWillReceiveProps(){
    if (this.props.client && this.props.client['sales'] && this.props.client['sales']['Current stage']){
      this.setState(
        {
          currentStage: this.props.client['sales']['Current stage'],
          originalCurrentStage: this.props.client['sales']['Current stage'],
        }
      );
    }
  }

  updateStage = (e) => {
    e && e.preventDefault();
    this.setState({currentStage: e.target.value});
  };

  render () {
    let props = this.props;  
    let keys = [];
    let salesStages = [];
    let client = props.client;
    let currentStage = this.state.currentStage;
    let salesStageDiv = [];
    let checklistdiv = [];
    let checkList = [];
    try {
      if (currentStage) {
        salesStages = props.client['inboundReferralStatuses'];
        _.each(salesStages, (key) => {
            salesStageDiv.push( <option  selected={key === currentStage}>{key}</option>);
          
        });

        if (client.types[0] === 'Seller') {
          checkList = client['sales']['Seller checklist'][currentStage];
        }
        else {
          checkList = client['sales']['Buyer checklist'][currentStage];
        }      
        let checklistKeys = _.keys(checkList);
        _.each(checklistKeys,
          (key) => {
            checklistdiv.push(
              <div>
              {checkList[key].date !== 0 ?
                <div>
                  <h3>                
                    {moment(checkList[key].date*1000).format("MMMM Do")}
                  </h3>
                </div>
                :
                <div>
                <h3>                
                 
                </h3>
                <p></p>
              </div>
              }
              <div>
                <p className={"timeline-title"}>{key}</p>
                <p className={"timeline-description"}>
                {checkList[key].notes}
                </p>
              </div>
            </div>
            )
        });
      }
    }
    catch(e){
      bugsnagClient.notify(e);
    }

    return (<div className={"sales-stage-section"}>
        <select className={"form-control"} onChange={this.updateStage}>
            {salesStageDiv}
        </select>
        <div className={"block"}>
            <div className="timeline">
               {checklistdiv}    
            </div>
        </div>
    </div>
  )};
}

export default SaleStageTimeline;