import React, { Component } from 'react';
import fetch from 'isomorphic-fetch';
import bugsnag from 'bugsnag-js';
import AddEmployee from "./modals/AddEmployee/AddEmployee";
import Modal from "../UI/Modal/Modal";
import styled from "styled-components"
import TitleBox from "../UI/TitleBox/TitleBox";

const baseUrl =process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;
const originalKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(originalKey);

const AccountTable = styled.table`
  font-family: "Rubik", sans-serif;
  th {
    border: none;
    background: #ffffff;
    font-size: 18px;
    font-weight: 500;
    color: #4a4a4a;
    text-transform: uppercase;
  }
  tr {
    td {
      border: none;
      background: #ffffff;
      padding: 10px;
    }
  }

  tbody {
    tr {
      border: solid 1px #d5d5d5;
    }
  }
`;

const AccountTableData = styled.td`
  font-size: 20px;
  font-weight: ${props => props.fontWeight};
  color: #737373;
`;

class Accounts extends Component {
  state = {
    accounts: [],
    currentEmployee: null,
    editEmployeeMode: false,
    createEmployeeMode : false
  };

  addEmployee() {
    this.setState({createEmployeeMode : true});
  }

  closeModal() {
   this.setState({currentEmployee : null,  editEmployeeMode: false, createEmployeeMode: false});
  }

  loadAccounts = async () => {
    try {
      let response = await  fetch(baseUrl + '/v1.0/consultants/all', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'secure-id': token,
          },
      });
      let respJson = await response.json();
      this.setState({accounts: respJson.response.accounts || [],});
    }
    catch (e) {
      bugsnagClient.notify(e);
    }  
  };

  editEmployee = (account) => {
    this.setState({
      editEmployeeMode: true,
      currentEmployee: account,
    })
  };

  componentDidMount() {
    this.loadAccounts(); 
  }

  deleteAccount = async () => {
    try {
      let response = await fetch(baseUrl + '/v1.0/consultants/delete/' + this.state.currentEmployee.id, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'secure-id': token,
          },
          body: JSON.stringify({
            "markInactive" : true
          })

      });
      let respJson = await response.json();
      await this.loadAccounts(); 
      this.closeModal();
      this.loadAccounts(); 
    }
    catch (e) {
      bugsnagClient.notify(e);
    }  
  };

  render() {
    const accounts = this.state.accounts.map((account, index) => {
      let roles = account.role.split(' ');

      return <tr key={"employee-" + index}>

          <AccountTableData className={"name"} fontWeight={500}>{account.name}</AccountTableData>
          <AccountTableData className={"content"}>{roles[0] === 'Sr' ? 'Senior' : 'Junior'}</AccountTableData>
          <AccountTableData className={"content"}>{roles.length === 2 ? roles[1] : roles[1] + ' ' + roles[2]}</AccountTableData>
          <AccountTableData className={"content"}>
            <a href="javascript:void(0);" onClick={() => this.editEmployee(account)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                  <path fill="#11ADF3" fillRule="evenodd" d="M19.006 11.85v4.181c0 2.123-1.827 3.848-4.075 3.848H6.074C3.826 19.88 2 18.154 2 16.031V7.667c0-2.122 1.826-3.848 4.074-3.848h4.429c.294 0 .531.224.531.502s-.237.502-.531.502h-4.43c-1.66 0-3.011 1.276-3.011 2.844v8.364c0 1.569 1.35 2.844 3.012 2.844h8.857c1.661 0 3.013-1.275 3.013-2.844V11.85c0-.277.236-.502.53-.502.295 0 .532.225.532.502zm-.308-5.624l-.87.821-2.129-2.01.87-.821a.88.88 0 0 1 1.169 0l.96.906a.754.754 0 0 1 .242.552.756.756 0 0 1-.242.552zm-8.517 8.042l-2.485.336.356-2.347 6.895-6.51 2.129 2.01-6.895 6.511zm9.268-9.856l-.96-.906c-.713-.675-1.959-.675-2.672 0l-1.242 1.172a.007.007 0 0 0-.003.004c-.002 0-.003.002-.004.003l-7.393 6.981a.489.489 0 0 0-.15.284l-.48 3.174a.48.48 0 0 0 .15.426.542.542 0 0 0 .45.142l3.361-.453a.542.542 0 0 0 .301-.143l7.394-6.98c0-.002.002-.003.003-.004l.004-.004 1.24-1.173c.358-.336.555-.785.555-1.261 0-.477-.197-.925-.554-1.262z" />
                </svg>
            </a>
          </AccountTableData>
        </tr>;    
     });

    return <div className={"accounts container"}>
        <TitleBox>
          <h3>EMPLOYEE MANAGEMENT</h3>
          <button onClick={() => this.addEmployee()}>
            +Add a new employee
          </button>
        </TitleBox>
        <AccountTable>
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Title</th>
              <th />
            </tr>
          </thead>
          <tbody>{accounts}</tbody>
        </AccountTable>  

        <Modal show={this.state.editEmployeeMode || this.state.createEmployeeMode}>
          <AddEmployee 
            closeModal={this.closeModal.bind(this)} 
            currentEmployee={this.state.currentEmployee || {name: '', role: 'Junior Consultant', email: ''}} 
            mode={this.state.createEmployeeMode ? 'create' : 'edit'}  
            deleteAccount={this.deleteAccount.bind(this)}
            refreshPage={this.loadAccounts.bind(this)
            }
          />
        </Modal>  
      </div>; 
  } 
}
export default Accounts;
