import styled from "styled-components"

export const TopicCountWrap = styled.div`
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 13px;
  background: var(--Color-Primary, #5A5FF2);
  min-width: 29px;
  width: max-content;
  height: 19px;
`

export const TopicCountText = styled.p`
  margin: 0;
  color: var(--Shades-White, #FFF);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
