const getStyles = () => `
  * {
    font-family: 'DM Sans', sans-serif;
  }

  table {
    width: 100%;
    max-width: 600px;
  }
`

export const getHtmlPage = body => `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta http-equiv="X-UA-Compatible" content="ie=edge">
      <link href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap" rel="stylesheet">
      <title>Radius Agent</title>
      <style>
        ${getStyles()}
      </style>
    </head>
    <body style="background-color: #FBF8EF; padding-top: 56px">
      <div style="margin: 0px auto; width: 600px">
        ${body}
      </div>
    </body>
  </html>
`
