import React from "react"
import styled from "styled-components"
import { Title } from "./common"

const Wrap = styled.div``

const Requirements = ({
  time,
  text,
}) => (
  <Wrap>
    <Title type={text} time={time} />
  </Wrap>
)

export default Requirements
