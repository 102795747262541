/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react"
import styled from "styled-components"
import SubContainer from "container/BrokerDashboard/subscriptionContainer"
import AgentContainer from "container/Agent"
import { ShimmerBox } from "shared/styles/animation"
import { withRouter } from "react-router-dom"
import { get } from "lodash"
import {
  Container,
  PlanClickBar,
  PlanWrap,
  PlanPriceWrap,
  PlanPrice,
  Title,
  TitleWrap,
  ShimmerWrap,
} from "../../common"
import { showConfirm } from "../../../ICA/components/ConfirmationModalUtil"

const Wrap = styled.div``

const MonthlySupportFee = ({
  selectedPlanList,
  getPlanbenefits,
  selectPlan,
  selectedPlan,
  plans,
  currentPlan,
  selectedAddOns,
  selectAddon,
  couponCodeResponse,
  removeCouponCode,
  ...props
}) => {
  const currentMonthlySupportFee = get(currentPlan, "currentPlan")
  useEffect(() => {
    if (currentMonthlySupportFee) {
      selectPlan({
        planId: currentMonthlySupportFee.id,
        plan: currentMonthlySupportFee,
      })
    }
  }, [currentMonthlySupportFee])
  const agentId = get(props, "match.params.agentId", "")
  const { isFetching } = plans || {}

  const {
    data: couponData,
  } = couponCodeResponse || {}

  const getPlanListPrice = planList => planList.billing_cycle === "YEARLY"
    ? `$${planList.unit_price}/yearly` : `$${planList.unit_price}/monthly`

  const handleClick = (planList) => {
    const message = "A coupon is applied, do you wish to change?"

    const postAction = (deleteCoupon) => {
      if (deleteCoupon) {
        removeCouponCode()
      }
      getPlanbenefits({
        planId: planList.plan_id,
        agentId,
      })
      selectPlan({
        planId: planList.plan_id,
        plan: planList,
      })
      selectAddon("removeKVCore")
    }

    if (couponData && couponData.trialInfo) {
      showConfirm("Do you wish to change?", () => postAction(true), message)
    } else {
      postAction()
    }
  }
  return (
    <Wrap>
      <Container>
        <TitleWrap>
          <Title>
            Support Fee
          </Title>
        </TitleWrap>
        {isFetching ? (
          <ShimmerWrap>
            <ShimmerBox w="223px" h="102px" />
            <ShimmerBox w="223px" h="102px" />
            <ShimmerBox w="223px" h="102px" />
            <ShimmerBox w="223px" h="102px" />
          </ShimmerWrap>
        ) : (
          <PlanWrap>
            {selectedPlanList.map(planList => (
            <>
              <PlanClickBar
                onClick={() => {
                  handleClick(planList)
                }}
                isSelected={(selectedPlan && selectedPlan.planId === planList.plan_id)}
                key={planList.id}
                width={223.67}
              >
                <PlanPriceWrap
                  isSelected={(selectedPlan && selectedPlan.planId === planList.plan_id)}
                  key={planList.id}
                >
                  <PlanPrice
                    isSelected={(selectedPlan && selectedPlan.planId === planList.plan_id)}
                    key={planList.id}
                  >
                    {getPlanListPrice(planList)}
                  </PlanPrice>
                </PlanPriceWrap>
              </PlanClickBar>
            </>
            ))}
          </PlanWrap>
        )}
      </Container>
    </Wrap>
  )
}

export default withRouter(AgentContainer(SubContainer(MonthlySupportFee)))
