import React from "react"

const Button = ({
  style,
  ...props
}) => (
  <button
    style={{
      background: "#ea526f",
      padding: "10px 30px",
      border: "none",
      color: "#FFF",
      borderRadius: "#FFF",
      borderRadius: 50,
      ...style,
    }}
    {...props}
  >
    
  </button>
)

export default Button
