import React, { useState } from "react"
import styled from "styled-components"
import { isEmpty, get } from "lodash"
import { Modal } from "antd"
import StripeCheckout from "react-stripe-checkout"
import moment from "moment"
import Button from "dumbComponents/common/UI/Button"
import { getUnitPrice } from "./AddonsSelector"
import TextInput from "./../Comp/TextInput"
import { getInterval } from "./../helper"

const STRIPE_PUBLISH_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY;

const Wrap = styled.div`
  
`

const SectionTitle = styled.p`
  color: #999;
  font-weight: 300;
`

const Sublist = styled.ul`
  padding: 0px;
  li {
    display: flex;
    > p {
      margin: 5px;
      &:nth-child(1) {
        flex: 1;
      }
      &:nth-child(2) {
        text-align: right;
      }
    }
  }
`

const ShowTotalWrap = styled.div`
  border-top: 2px solid #888;
  > ul {
    > li {
      > p {
        &:nth-child(1) {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }

`

const CheckputWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CouponsWrap = styled.div`
  max-width: 400px;
  h4 {
    margin: 0px;
  }
  > div {
    display: flex;
    align-items: center;
    input {
      margin: 0px 20px 10px 0px;
      flex: 1;
    }

    > a {
      margin: 0px 20px;
      position: relative;
      top: 5px;
      color: #100063;
    }
  }
`

const CouponSuccessError = styled.h6`
  margin: 0px;
  ${props => props.isError && `
    color: red;
  `}
  ${props => props.isValid && `
    color: green;
  `}
`

const Summary = ({
  selectedPlan,
  selectedAddons,
  checkoutBrokerPlan,
  checkCoupon,
  agentId,
  couponCodeResponse,
  removeCouponCode,
  isPlanAlready,
  isCardAdded,
  currentAPISelectionMap,
  currentPlan,
  addOnsSelected,
}) => {
  let showCheckout = false
  console.log(selectedPlan, "here")
  const isZeroDollarPlanWithNoAddOns = selectedPlan
  && (selectedPlan.planId === "broker_zero_monthly_0" || selectedPlan.planId === "broker_test_rar_zero_dollar_qa"
    || selectedPlan.planId === "broker_accelerator_program_yearly_0" || selectedPlan.planId === "broker_90_10_monthly_0"
      || selectedPlan.planId === "broker_95_5_monthly_0")
    && isEmpty(selectedAddons)
  const [ couponCode, setCouponCode ] = useState("")
  const [ isSelectedDurationCalled, setSsSelectedDurationCalled ] = useState()
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const { data: couponData, isFetching: isFetchingCoupon } = couponCodeResponse
  const { plan = {}, planId } = selectedPlan || {}
  const { planFromSub } = currentPlan || {}
  if (planFromSub && planFromSub?.stripe_plan_id !== "broker_95_5_monthly_0") {
    showCheckout = false
  } else if (planFromSub && planFromSub?.stripe_plan_id !== "broker_90_0_monthly_0") {
    showCheckout = false
  } else {
    showCheckout = true
  }
  const handleCheckout = (data) => {
    const { id: tokenId } = data || {}
    checkoutBrokerPlan({
      tokenId,
      currentAPISelectionMap,
    })
    setIsPopupOpen(false)
  }

  if (isEmpty(plan)) {
    return null
  }

  if (!isEmpty(plan) && !isSelectedDurationCalled) {
    setSsSelectedDurationCalled(true)
  }
  const handleCancel = () => {
    setIsPopupOpen(false)
  }

  const isCouponCodeCallMade = !isEmpty(couponCodeResponse.data)
  const isCouponCodeValid = couponData && couponData.trialInfo
  return (
    <Wrap>
      <Modal title="Payment Summary" visible={isPopupOpen} onOk={() => handleCheckout()} onCancel={() => handleCancel()}>
        <SectionTitle>
          Brokerage Plan
        </SectionTitle>
        <Sublist>
          <li>
            <p>
              {(plan && plan.name) || planFromSub && planFromSub.name}
            </p>
            <p>
              <strong>
                {`$${(get(plan, "amount") / 100) || plan.unit_price >= 0 ? plan.unit_price : 0}${getInterval(plan) || getInterval(planFromSub)}`}
              </strong>
            </p>
          </li>
        </Sublist>
        { addOnsSelected && addOnsSelected.length > 0 && (
          <React.Fragment>
            <SectionTitle>
            Addons
            </SectionTitle>
            <Sublist>
              {addOnsSelected && addOnsSelected.map(addon => (
                <li key={addon.id}>
                  <p>
                    {`${addon.name}`}
                  </p>
                  <p>
                    {"$"}
                    <strong>
                      {addon.broker_sub_id && (
                        <React.Fragment>
                          {addon.pricing
                            ? `${get(addon, "pricing.amount", "")}${getInterval(addon.pricing)}`
                            : `${get(addon, "unit_price", "")}`
                          }
                        </React.Fragment>
                      )}
                      {addon.plan_details
                        ? `${get(addon, "plan_details[0].unit_price", "")}${getInterval(addon.plan_details)}`
                        : `${get(addon, "unit_price", "")}`
                      }
                    </strong>
                  </p>
                </li>
              ))}
            </Sublist>
          </React.Fragment>
        )}
        {isCouponCodeValid && (
          <>
            <SectionTitle>
          Coupons Applied
            </SectionTitle>
            <Sublist>
              <li>
                <p>
                  {couponCode}
                </p>
                <p>
                  {couponData.trialInfo.trialDays !== 0 ? (
                    <strong>
                      Trial Days :
                      {" "}
                      {couponData.trialInfo.trialDays}
                    </strong>
                  ) : (
                    <strong>
                      Discount Amount($) :
                      {" "}
                      {couponData.trialInfo.discountAmount}
                    </strong>
                  ) }
                </p>
              </li>
            </Sublist>
          </>
        )}
      </Modal>
      <h5>Summary</h5>
      <SectionTitle>
        Brokerage Plan
      </SectionTitle>
      <Sublist>
        <li>
          <p>
            {(plan && plan.name) || planFromSub && planFromSub.name}
          </p>
          <p>
            <strong>
              {`$${(get(plan, "amount") / 100) || plan.unit_price >= 0 ? plan.unit_price : 0}${getInterval(plan) || getInterval(planFromSub)}`}
            </strong>
          </p>
        </li>
      </Sublist>
      { addOnsSelected && addOnsSelected.length > 0 && (
        <React.Fragment>
          <SectionTitle>
            Addons
          </SectionTitle>
          <Sublist>
            {addOnsSelected && addOnsSelected.map(addon => (
              <li key={addon.id}>
                <p>
                  {`${addon.name}`}
                </p>
                <p>
                  {"$"}
                  <strong>
                    {addon.broker_sub_id && (
                      <React.Fragment>
                        {addon.pricing
                          ? `${get(addon, "pricing.amount", "")}${getInterval(addon.pricing)}`
                          : `${get(addon, "unit_price", "")}`
                        }
                      </React.Fragment>
                    )}
                    {addon.plan_details
                      ? `${get(addon, "plan_details[0].unit_price", "")}${getInterval(addon.plan_details)}`
                      : `${get(addon, "unit_price", "")}`
                    }
                  </strong>
                </p>
              </li>
            ))}
          </Sublist>
        </React.Fragment>
      )}
      {/* <ShowTotalWrap>
        <Sublist>
          <li>
            <p>
              Total
            </p>
            <p>
              asdasd
            </p>
          </li>
        </Sublist>
      </ShowTotalWrap> */}

      <CheckputWrap>
        <CouponsWrap>
          <h4>Coupon</h4>
          <div>
            <TextInput
              placeholder="Add Coupon"
              value={couponCode}
              onChange={(e) => {
                setCouponCode(e.target.value)
              }}
            />
            {isCouponCodeValid && isCouponCodeValid ? (
              <a
                onClick={() => {
                  removeCouponCode()
                  setCouponCode("")
                }}
                style={{ color: "red", cursor: "pointer" }}
              >
                Remove
              </a>
            ) : (
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  checkCoupon({
                    couponCode,
                    agentId,
                    planId,
                  })
                }}
              >
                {isFetchingCoupon ? "Checking.." : "Add"}
              </a>
            )}
          </div>
          {isCouponCodeCallMade && !isCouponCodeValid && (
            <CouponSuccessError isError>Coupon is invalid.</CouponSuccessError>
          )}

          {isCouponCodeValid && isCouponCodeValid && (
            <CouponSuccessError isValid>Coupon is valid.</CouponSuccessError>
          )}

        </CouponsWrap>
        {((isPlanAlready && showCheckout) || isCardAdded) || isZeroDollarPlanWithNoAddOns ? (
          <Button
            success
            onClick={() => {
              setIsPopupOpen(true)
            }}
          >
            Checkout
          </Button>
        ) : (
          <StripeCheckout
            stripeKey={STRIPE_PUBLISH_KEY}
            token={handleCheckout}
          />
        )}
      </CheckputWrap>
    </Wrap>
  )
}

export default Summary
