import React from "react"
import {
  AgentSignaturePending,
  Signed,
  Incomplete,
  SalesRep,
  Draft,
} from "./Statuses"

/**
 * response | ui
created -> draft
active -> incomplete
sales_review_pending -> sales rep
agent_signature_pending -> agent_signature
completed -> signed
 */

export const GetStatusUI = (status) => {
  switch (status) {
    case "created":
      return (<Draft />)
    case "active":
      return (<Incomplete />)
    case "sales_review_pending":
      return (<SalesRep />)
    case "agent_signature_pending":
      return (<AgentSignaturePending />)
    case "agent_signed":
    case "completed":
      return (<Signed />)
    default:
      return (<Incomplete />)
  }
}

export const GetStatusIcon = (status) => {
  switch (status) {
    case "created":
    case "active":
    case "sales_review_pending":
      return "https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/SOUL_ISA_YELLOW_DANGER_ICON.png"
    case "agent_signature_pending":
    case "agent_signed":
    case "completed":
      return "https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/SOUL_GREEN_TICK.png"
    default:
      return "https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/SOUL_ISA_YELLOW_DANGER_ICON.png"
  }
}

// export const GetCTAAction = (status) => {
//   switch (status) {
//     case "created":
//     case "active":
//       return "senderView"
//     case "sales_review_pending":
//     case "agent_signature_pending":
//     case "completed":
//       return "recieverView"
//     default:
//       return "senderView"
//   }
// }

export const GetSenderOrReciever = (status) => {
  switch (status) {
    case "created":
    case "active":
      return "senderView"
    case "agent_signed":
    case "sales_review_pending":
    case "agent_signature_pending":
    case "completed":
      return "recieverView"
    default:
      return "senderView"
  }
}
