import React from "react"
import styled from "styled-components"
import moment from "moment"
import ConfirmModal from "dumbComponents/UI/ConfirmModal"
import { Row, Col, LabelAndText } from "dumbComponents/BrokerDashboard/AgentDashboard/ViewDeal"
import { SectionWrap } from "dumbComponents/BrokerDashboard/AgentDashboard/ViewDeal/DetailedViewComponent"
import { USCurrencyFormat } from "shared/currencyUtils"
import Button from "dumbComponents/common/UI/Button"

const Wrap = styled.div`
  
`

const errorConfirmedDate = <p1 style={{ color: 'red' }}>*Please fill out Confirmed Closed Date to change Deal Status to Closed</p1>

const DealDemandConfirm = ({
  onConfirm,
  onCancel,
  dealSelected,
  onEdit,
}) => {
   return (
    <ConfirmModal
      callback={onConfirm}
      cancel={onCancel}
      show
    >
      <Row>
        <Col>
          <LabelAndText
            title="Est. Closing Date"
            data={dealSelected.closed_on ? `${moment.unix((new Date(dealSelected.closed_on).getTime())).format("D MMM, YYYY")}` : "N/a"}
          />
        </Col>
        <Col>
          <LabelAndText
            title="Confirmed Closing Date"
            data={dealSelected.confirmed_closed_on ? `${moment.unix((new Date(dealSelected.confirmed_closed_on).getTime())).format("D MMM, YYYY")}` : "N/a" }
          />
        </Col>
      </Row>
      <SectionWrap show style={{ marginTop: 30, padding: 20 }}>
        <h6>Pricing Details</h6>
        <div>
          <Row style={{ marginTop: 0 }}>
            <Col>
              <LabelAndText
                title="Fixed Fee"
                data={dealSelected.fixed_fee ? USCurrencyFormat(dealSelected.fixed_fee) : "$0"}
              />
            </Col>
            <Col>
              <LabelAndText
                title="List Price"
                data={dealSelected.list_price ? USCurrencyFormat(dealSelected.list_price) : "$0"}
              />
            </Col>
            <Col>
              <LabelAndText
                title="Sales Price"
                data={dealSelected.price ? USCurrencyFormat(dealSelected.price) : "$0"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <LabelAndText
                title="Rent Price"
                data={dealSelected.rent_amount ? USCurrencyFormat(dealSelected.rent_amount) : "$0"}
              />
            </Col>
            <Col>
              <LabelAndText
                title="Gross Commission"
                data={dealSelected.gross_commission ? USCurrencyFormat(dealSelected.gross_commission) : "$0"}
              />
            </Col>
            <Col>
              <LabelAndText
                title="Agent Commission"
                data={dealSelected.agent_commission ? USCurrencyFormat(dealSelected.agent_commission) : "$0"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <LabelAndText
                title="Radius Transaction Fee"
                data={dealSelected.radius_transaction_fee ? USCurrencyFormat(dealSelected.radius_transaction_fee) : "$0"}
              />
            </Col>
          </Row>
          
        </div>
      </SectionWrap>
      <SectionWrap show style={{ marginTop: 30, padding: 20 }}>
        <h6>Fee Details</h6>
        <div>
        <Row style={{ marginTop: 0 }}>
            <Col>
              <LabelAndText
                title="Brokerage Fee"
                data={dealSelected.brokerage_fee ? USCurrencyFormat(dealSelected.brokerage_fee) : "$0"}
              />
            </Col>
            <Col>
              <LabelAndText
                title="Agent Payout Fee"
                data={dealSelected.agent_payout_fee ? USCurrencyFormat(dealSelected.agent_payout_fee) : "$0"}
              />
            </Col>
            <Col>
              <LabelAndText
                title="TC Fee"
                data={dealSelected.tc_fee ? USCurrencyFormat(dealSelected.tc_fee) : "$0"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <LabelAndText
                title="Audit Fee"
                data={dealSelected.audit_fee ? USCurrencyFormat(dealSelected.audit_fee) : "$0"}
              />
            </Col>
            <Col>
              <LabelAndText
                title="E&O Fee"
                data={dealSelected.e_and_o_fee ? USCurrencyFormat(dealSelected.e_and_o_fee) : "$0"}
              />
            </Col>
            <Col>
              <LabelAndText
                title="Referral Fee"
                data={dealSelected.referral_fee ? USCurrencyFormat(dealSelected.referral_fee) : "$0"}
              />
            </Col>
          </Row>
          <Row>
          <Col>
              <LabelAndText
                title="Pending Subscription Fee"
                data={dealSelected.pending_subscription_fee ? USCurrencyFormat(dealSelected.pending_subscription_fee) : "$0"}
              />
            </Col>
          </Row>
        </div>
      </SectionWrap>
      <Row>
        <Col>
          <Button onClick={onEdit}>
            Edit
          </Button>
        </Col>
      </Row>
    </ConfirmModal>
  )
}

export default DealDemandConfirm
