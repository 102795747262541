import React  from 'react';
import {Fetch} from "../../lib/NetworkUtils";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export function updatePool(poolId, body) {
    let url = baseUrl + "/v1.0/gabby/call/reservation/" + poolId;
    return Fetch(url, {
        method: 'PUT',
        body: JSON.stringify(body)
    })
}

export function updateTaskApi(body) {
    let url = baseUrl + '/v1.0/gabby/call/update-task';
    return Fetch(url, {
        method: 'PUT',
        body: JSON.stringify(body)
    })
}

export function makeConferenceCall(body) {
    let url = baseUrl + '/v2.0/gabby/call/conference-call';
    return Fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export function callClient(to, from, twilio_client_name, referral_id) {
    let url = baseUrl + "/v1.0/gabby/call?";
    url += "to=" + to;
    url += "&from=" + from;
    url += "&twilio_client_name=" + twilio_client_name;
    url += "&referral_id=" + referral_id;
    return Fetch(url)
}

export const removeAgentAPI = (body) => {
    return Fetch(baseUrl + "/v1.0/gabby/call/conference-call-participant/remove", body)
}

export const assignMostRecentlyConnectedAgentAPI = (body) => {
    return Fetch(baseUrl + "/v1.0/gabby/call/conference-call/assign-agent", body)
}

