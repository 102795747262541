import React, { Component } from "react"
import styled, { css } from "styled-components"
import colors from "lib/colors"
import Container from "container/Agent"
import Benefits from "./Benefits"
import FinancingOption from "./FinancingOption"
import Coupon from "./Coupon"
import LeadCap from "./LeadCap"
import StripeCheckout from 'react-stripe-checkout'
import { getDuration } from "./shared"
import root from "window-or-global"
import { DefaultLoader } from "components/UI/Loader/Loader";
import Popup from "reactjs-popup";
import { get } from "lodash";

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;

const PlanSelector = styled.div`

  height: 34px;
  width: 100%;
  border-bottom: 1px solid #8c8c8c;
  display: flex;
  flex-direction: row;
  button{
   height: 100%;
   flex-grow: 1;
   text-align: center;
   border: none;
   cursor: pointer;
   p {
    font-size: 14px;
   }
  }
  button:focus {
   outline: 0;
  }
  ${props => props.isBroker && `
    display: none !important;
   `}
`

const RTL = styled.button`
 background: none;
 ${props => props.isActive && `
    color: #11adf3;
    p {
      border-bottom: 2px solid #11adf3;
    }
  `}
`

const BLU = styled.button`
background: none;
 ${props => props.isActive && `
  color: #11adf3;
  p {
    border-bottom: 2px solid #11adf3;
  }
  `}
`


const CheckoutWrap = styled.div`
  flex-grow: 2;
  max-width: 22%;
  position: sticky;
  top: 0;
  height: max-content;
  min-height: 500px;
  border-radius: 8px;
  border: solid 1px #a8a8a8;
  font-family: "SF Pro Display",sans-serif;
  padding: 11px 0px 15px 0px;
`
const Header = styled.div`
  p {
   font-size: 18px;
   font-weight: 500;
   text-align: center;
  }
`
const NoPlanSelected = styled.h1`
  font-size: 50px;
`
const InstallmentPlan = styled.p`
  font-size: 12px;
  color: #8c8c8c;
`
const Wrap = styled.div`
  width: 100%;
  height: max-content;
  margin-top: 15px;

  ${props => props.coupon && `
   margin-top: 30px;
   box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.14);
   background-color: #fefefe;
   padding-bottom: 20px;
   `}

  ${props => props.pd && `
     padding-left: 71px;
   `}

  ${props => props.amt && `
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   p {
    font-size: 18px;
    font-weight: 500;
   }
   p:nth-child(1) {
    font-size: 14px;
    font-weight: 300;
    color: #8c8c8c;
    padding-top: 5px;
   }
   `}

   ${props => props.inst && `
       p {
        margin: 0;
        padding-left: 12px;
       }
       p:nth-child(2){
        font-size: 16px;
       }
    `}

   ${props => props.isCouponApplied && `
      .defAmt {
       text-decoration: line-through;
      }
    `}
`
const Title = styled.div`
  padding-left: 13px;
  h2{
   font-size: 16px;
   font-weight: 500;
   color: #a8a8a8;
   margin: 0 !important;
  }

  ${props => props.fin && `
     display: flex;
     justify-content: space-between;
     button {
      margin-right: 30px;
      background: transparent;
      cursor: pointer;
      border: 1px solid #8c8c8c;
     }
   `}
`
const Save = styled.button`
  position: relative;
  left: 28%;
  width: 109px;
  height: 27px;
  border-radius: 4px;
  background-color: #c7f8fe;
  border: none;
  cursor: pointer;
  ${props => props.alt && `
    left: 33px;
   `}
`

class Checkout extends React.Component {
  state = {
    isRTLSelected: true,
    openConfirmationSave: false,
    benefitsToAdd: [],
    benefitsToRemove: [],
    benefitPlans: {}
  }

  componentDidMount() {
    //
  }

  onCheckout = ({ id: tokenId }) => {
    const { secure_id } = this.props
    const { data: agentSecureData } = secure_id || {}
    const { secure_id: agentSecureId } = agentSecureData || {}
    this.checkoutStripePlan(tokenId)
  }

  checkoutStripePlan = (tokenId = "") => {
    const {
      checkoutPlan,
      couponResponse,
      rtlCoupon,
      bluCoupon,
      currentSelectedPlan,
      currentRTLPlanSelected,
      currentBLUPlanSelected,
      currentRTLSelectedInstallmentPlan,
      currentBLUSelectedInstallmentPlan,
      currentSelectedInstallmentPlan,
      secure_id,
      currentBROKERPlanSelected,
      modifiedBenefitArray,
      brokerCoupon,
      addedBenefits
    } = this.props
    const { data: agentSecureData } = secure_id || {}
    const { secure_id: agentSecureId } = agentSecureData || {}
    const plansMap = []

    if (currentBROKERPlanSelected && Object.keys(currentBROKERPlanSelected).length > 0) {
      plansMap.push({
        planId: currentBROKERPlanSelected.plan_id,
        couponCode: brokerCoupon && brokerCoupon.couponCode || "",
      })
    }

    if (currentRTLSelectedInstallmentPlan && Object.keys(currentRTLSelectedInstallmentPlan).length > 0) {
      plansMap.push({
        planId: currentRTLSelectedInstallmentPlan.plan_id,
        couponCode: rtlCoupon && rtlCoupon.couponCode || "",
      })
    } else if (currentRTLPlanSelected && Object.keys(currentRTLPlanSelected).length > 0) {
      plansMap.push({
        planId: currentRTLPlanSelected.plan_id,
        couponCode: rtlCoupon && rtlCoupon.couponCode || "",
      })
    }

    if (currentBLUSelectedInstallmentPlan && Object.keys(currentBLUSelectedInstallmentPlan).length > 0) {
      plansMap.push({
        planId: currentBLUSelectedInstallmentPlan.plan_id,
        couponCode: bluCoupon && bluCoupon.couponCode || "",
      })
    } else if (currentBLUPlanSelected && Object.keys(currentBLUPlanSelected).length > 0) {
      plansMap.push({
        planId: currentBLUPlanSelected.plan_id,
        couponCode: bluCoupon && bluCoupon.couponCode || "",
      })
    }
    const {
      data: coupon,
    } = couponResponse
    const { pathname } = root.location || ""
    checkoutPlan({
      tokenId: tokenId,
      plansMap,
      secure_id: agentSecureId,
      modifiedBenefitArray,
      addedBenefits,
      agentId: parseInt(pathname.split("/")[2], 10),
    })
  }

  getCurrentSubbedInstallments = (currSubInstl) => {
    const {
      currentSelectedPlan,
      installmentResponse,
      type,
    } = this.props

    const {
      data: instData,
    } = installmentResponse || []

    const currentTypeOfPlans = `${type}_plans`
    const currentPlanType = currentSelectedPlan && currentSelectedPlan.product_nickname
    if (currSubInstl.length > 0 && instData) {
      const inst = currSubInstl && currSubInstl.length > 0 && currSubInstl.map(curr => {
        const datum = instData
          && instData[currentTypeOfPlans]
          && instData[currentTypeOfPlans][currentPlanType]
        const disp = datum && datum.filter(key => {
          if (key.plan_id === curr.data.id) {
            return key
          }
        })
        return disp && disp[0]
      })
      return inst && inst[0]
    }

    return false
  }

  handleInstallmentReset = () => {
    const {
      resetInstallmentPlanSelected,
    } = this.props

    resetInstallmentPlanSelected()
  }

  handlePlanClick = (planTypeSelected) => {
    const {
      setPlanForCheckout,
      currentRTLPlanSelected,
      currentBLUPlanSelected,
      setCurrentMode,
    } = this.props

    setCurrentMode({
      currentMode: planTypeSelected === "rtlPlan" ? "rtl" : "blu",
    })

    setPlanForCheckout({
      currentSelectedPlan: planTypeSelected === "rtlPlan" ? currentRTLPlanSelected : currentBLUPlanSelected,
    })

    this.setState({
      isRTLSelected: planTypeSelected === "rtlPlan",
    })
  }

  handleCustomization = () => {
    const { pathname } = root.location || ""
    const agentId = parseInt(pathname.split("/")[2], 10)
    const {
      customizedSubscription,
      singleLeadCap,
      teamLeadCap,
      modifiedBenefitArray,
      subscriptions,
      currentSelectedPlan,
      secure_id,
      currentRTLPlanSelected,
      currentBLUPlanSelected,
      currentBROKERPlanSelected,
      addedBenefits,
      alreadyAddedFeatures,
      setBenefitsToBeAdded
    } = this.props

    const {
      data: agentSecureData
    } = secure_id || {}

    const {
      secure_id: agentSecureId
    } = agentSecureData || {}

    const {
      data,
    } = subscriptions

    const {
      subscriptions: subData
    } = data

    let currSubIds = []

    if (subData.length > 0) {
      subData.forEach(key => {
        currSubIds.push(key.id)
      })
    }

    const benefitIdsToAdd = addedBenefits.filter(features => !features.alreadyAdded).map(feat => feat.benefit_id)
    const benefitIdsToRemove = alreadyAddedFeatures.filter(addedFeat => addedFeat.alreadyAdded && !addedBenefits.find(benef => benef.benefit_id === addedFeat.benefit_id)).map(feat => feat.benefit_id) 
    customizedSubscription({
      benefitIds: modifiedBenefitArray,
      subscriptionIds: currSubIds,
      rtl_threshold: singleLeadCap,
      team_rtl_threshold: teamLeadCap,
      agentId,
      agentSecureId,
      benefitIdsToAdd,
      benefitIdsToRemove,
      addedBenefits,
      alreadyAddedFeatures
    })
  }

  getFinalTotalAmount = (totalAmount, rtlCoupon, bluCoupon, brokerCoupon) => {
    const { addedBenefits } = this.props;
    const addedBenefitsAmount = addedBenefits.reduce((sum, benef) => sum + (benef.price || 0), 0)
    let amt = totalAmount

    if (brokerCoupon && Object.keys(brokerCoupon).length > 0 && brokerCoupon.isValid) {
      amt = amt - brokerCoupon.discountAmount
    }

    if (rtlCoupon && Object.keys(rtlCoupon).length > 0 && rtlCoupon.isValid) {
      amt = amt - rtlCoupon.discountAmount
    }

    if (bluCoupon && Object.keys(bluCoupon).length > 0 && bluCoupon.isValid) {
      amt = amt - bluCoupon.discountAmount
    }

    return amt + addedBenefitsAmount
  }

  getFeaturePrice = (key, installmentPlan) => {
    const { installmentResponse, currentSelectedPlan, currentSelectedInstallmentPlan, subscriptions, currentMode } = this.props;
    const {
      data: installmentData,
    } = installmentResponse || {}
    let benefitPlans = get(installmentData, 'benefit_plans', null);
    if(currentMode === 'rtl' && benefitPlans.length === 0) {
      benefitPlans = this.state.benefitPlans;
    }
    let currSelectedInstallmentPlanData = []

    if (subscriptions.data) {
      subscriptions.data && subscriptions.data.subscriptions && subscriptions.data.subscriptions.forEach(key => {
        key
          && key.items.data
          && key.items.data.forEach(sub => {
            if ( sub.plan && sub.plan.parentPlanId ) {
              currSelectedInstallmentPlanData.push({
                parentID: sub.plan.parentPlanId,
                data: sub.plan,
              })
            }
          })
      })
    }
    // const subscribedPlan = get(subscriptions, 'data.')
    let selectedPlan = installmentPlan || currentSelectedInstallmentPlan || currentSelectedPlan;
    if (currSelectedInstallmentPlanData && currSelectedInstallmentPlanData.length) {
      let planOpted = currentSelectedPlan.plan_id
      if (selectedPlan && selectedPlan.plan_id ) {
        planOpted = selectedPlan.plan_id
      }
      if(currSelectedInstallmentPlanData.find(plan => plan.parentID === planOpted)) {
        selectedPlan  = currSelectedInstallmentPlanData.find(plan => plan.parentID === planOpted).data
        selectedPlan = { ...selectedPlan, plan_id: selectedPlan.id }
      }
    }
    const planId = selectedPlan && selectedPlan.plan_id && benefitPlans[selectedPlan.plan_id] ? selectedPlan.plan_id : currentSelectedPlan.plan_id;
    // console.log('==currentSelectedInstallmentPlan===', currentSelectedInstallmentPlan)
    // console.log('==installmentData===', installmentData, '==benefitPlans---', benefitPlans, '000---planId---', planId)
    // const { data: { benefits: { premium_features: premiumFeatures } } } = installmentResponse
    const price = benefitPlans && benefitPlans[planId] ? get(benefitPlans[planId].find(pf => pf.value === key), 'unit_price', 0) : 0;
    return {
      priceString: price ? `$${price}` : '',
      priceValue: price
    }
  }

  initializeAddedBenefits = ({ addedFeatures = null, installmentPlan = null }) => {
    const { subscriptions, plans, setBenefitsToBeAdded, addedBenefits, installmentResponse, currentMode } = this.props
    console.log('===subscriptions===', subscriptions)
    const { data: subData } = subscriptions || {}
    const {
      data: installmentData,
    } = installmentResponse || {}
    let benefitPlans = get(installmentData, 'benefit_plans', null);
    if(benefitPlans && !benefitPlans.length && currentMode === 'rtl') {
      this.setState({
        benefitPlans
      })
    }
    const premiumFeatures = get(plans, 'data.benefits.premium_features', []);
    let assistFeatures = get(subscriptions, 'data.assist_features', [])
    if (!assistFeatures.length) {
      // assistFeatures = premiumFeatures.map(feat => ({
      //   key: feat.value,
      //   ...feat
      // }))
    }
    const newAddedBenefits = addedFeatures && addedFeatures.length ? addedFeatures : (
      assistFeatures && assistFeatures.length ?
        assistFeatures.filter(features => features.value && premiumFeatures.find(premFeature => premFeature.value === features.key)).map(features => ({
          benefit_key: features.key,
          benefit_id: premiumFeatures.find(premFeature => premFeature.value === features.key).benefit_id,
          alreadyAdded: true
        }))
        : addedBenefits

    )
    // this will handle update price for addedFeatures and subData.assist_features.
    newAddedBenefits.forEach(benf => {
      benf.price = this.getFeaturePrice(benf.benefit_key, installmentPlan).priceValue
    })
    setBenefitsToBeAdded({
      addedBenefits: newAddedBenefits,
      alreadyAddedFeatures: newAddedBenefits
    })
  }


  getShownSectionAmount = (amount) => {
    const { addedBenefits } = this.props;
    const addedBenefitsAmount = addedBenefits.reduce((sum, benef) => sum + (benef.price || 0), 0)
    return amount + addedBenefitsAmount;
  }

  toggleOpenConfirmationSave = (status) => {
    const { addedBenefits, alreadyAddedFeatures } = this.props;
    const benefitIdsToAdd = addedBenefits.filter(features => !features.alreadyAdded).map(feat => feat.benefit_id)
    const benefitIdsToRemove = alreadyAddedFeatures.filter(addedFeat => addedFeat.alreadyAdded && !addedBenefits.find(benef => benef.benefit_id === addedFeat.benefit_id)).map(feat => feat.benefit_id)
    if (benefitIdsToAdd.length || benefitIdsToRemove.length) {
      this.setState({
        openConfirmationSave: status,
        benefitsToAdd: addedBenefits.filter(features => !features.alreadyAdded),
        benefitsToRemove: alreadyAddedFeatures.filter(addedFeat => addedFeat.alreadyAdded && !addedBenefits.find(benef => benef.benefit_id === addedFeat.benefit_id))
      })
      return
    }
    this.setState({
      benefitIdsToAdd: [],
      benefitIdsToRemove: []
    })
    this.handleCustomization()
  }

  render() {
    const {
      type,
      currentSelectedPlan,
      subscriptions,
      installmentResponse,
      secure_id,
      plan,
      currentSelectedInstallmentPlan,
      checkout,
      customizedSubscriptionResponse,
      couponResponse,
      couponIsRemoved,
      currentRTLPlanSelected,
      currentBLUPlanSelected,
      currentBROKERPlanSelected,
      rtlCoupon,
      bluCoupon,
      brokerCoupon,
      currentMode,
      currentRTLSelectedInstallmentPlan,
      currentBLUSelectedInstallmentPlan,
    } = this.props

    const {
      isRTLSelected,
      benefitsToAdd,
      benefitsToRemove
    } = this.state

    const {
      data: coupon,
    } = couponResponse

    const currentBROKERAmount = currentBROKERPlanSelected
      && Object.keys(currentBROKERPlanSelected).length > 0
      && currentBROKERPlanSelected.unit_price || 0

    const currentRTLAmount = currentRTLSelectedInstallmentPlan
      && Object.keys(currentRTLSelectedInstallmentPlan).length > 0 ? currentRTLSelectedInstallmentPlan.unit_price :
      currentRTLPlanSelected
      && Object.keys(currentRTLPlanSelected).length > 0
      && currentRTLPlanSelected.unit_price || 0

    const currentBLUAmount = currentBLUSelectedInstallmentPlan
      && Object.keys(currentBLUSelectedInstallmentPlan).length > 0 ? currentBLUSelectedInstallmentPlan.unit_price :
      currentBLUPlanSelected
      && Object.keys(currentBLUPlanSelected).length > 0
      && currentBLUPlanSelected.unit_price || 0

    const totalCost = currentRTLAmount + currentBLUAmount + currentBROKERAmount

    const {
      unit_price,
      product_nickname: pType,
      billing_interval,
      plan_benefits,
      name,
      plan_id: planId
    } = currentSelectedPlan || {}

    const {
      isFetching: checkoutProgress,
    } = checkout

    const {
      isFetching: customizedSubscriptionProgress,
    } = customizedSubscriptionResponse

    const {
      data: subData,
    } = subscriptions || {}

    const {
      data: secData,
    } = secure_id || {}

    const {
      isFetching: fetchingInstallmentData,
      data: installmentData,
    } = installmentResponse || {}

    let currSubs = []
    let currSelectedInstallmentPlanData = []
    let displayItems = []

    if (subData) {
      subData.subscriptions && subData.subscriptions.forEach(key => {
        key
          && key.items.data
          && key.items.data.forEach(sub => {
            currSubs.push(sub.plan.id)
            if (
              sub.plan.parentPlanId
              && sub.plan.parentPlanId.length > 0
            ) {
              currSubs.push(sub.plan.parentPlanId)
              currSelectedInstallmentPlanData.push({
                parentID: sub.plan.parentPlanId,
                data: sub.plan,
              })
            }
          })
      })
      displayItems = this.getCurrentSubbedInstallments(currSelectedInstallmentPlanData)
    }
    const isPlan = currentSelectedPlan && Object.keys(currentSelectedPlan).length > 0
    const noSub = subData && Object.keys(subData).length <= 0
    return (
      <React.Fragment>
        <CheckoutWrap
          noPlan={!isPlan}
        >
          {!isPlan ? (
            <React.Fragment>
              <PlanSelector isBroker={currentMode === "broker"}>
                <RTL
                  onClick={() => this.handlePlanClick("rtlPlan")}
                  isActive={currentMode === "rtl" && currentMode !== 'blu'}
                >
                  <p>RTL Plan</p>
                </RTL>
                <BLU
                  onClick={() => this.handlePlanClick("bluPlan")}
                  isActive={currentMode === "blu" && currentMode !== 'rtl'}
                >
                  <p>BLU Plan</p>
                </BLU>
              </PlanSelector>
              <Wrap style={{ marginTop: "30px", padding: "50px" }}>
                <h2>{`No ${currentMode} plan selected`}</h2>
              </Wrap>
            </React.Fragment>
          ) : (checkoutProgress || customizedSubscriptionProgress || fetchingInstallmentData) ? (
            <DefaultLoader height={0} width={0} />
          ) : (
            <React.Fragment>
              <PlanSelector isBroker={currentMode === "broker"}>
                <RTL
                  onClick={() => this.handlePlanClick("rtlPlan")}
                  isActive={currentMode === "rtl" && currentMode !== 'blu'}
                >
                  <p>RTL Plan</p>
                </RTL>
                <BLU
                  onClick={() => this.handlePlanClick("bluPlan")}
                  isActive={currentMode === "blu" && currentMode !== 'rtl'}
                >
                  <p>BLU Plan</p>
                </BLU>
              </PlanSelector>
              <Header>
                {/*
         <p>{`$${unit_price} per ${(pType === "rtl" ? getDuration(billing_interval) : "accepted lead")}`}</p>
        */}
                <p>{name}</p>
              </Header>
              <Wrap>
                <Title>
                  <h2>Features</h2>
                </Title>
                <Benefits
                  initializeAddedBenefits={this.initializeAddedBenefits}
                  getFeaturePrice={this.getFeaturePrice}
                  planBenefits={plan_benefits}
                  subData={subData}
                  currentMode={currentMode}
                />
              </Wrap>
              {!currSubs.includes(currentSelectedPlan.plan_id) && (
                <React.Fragment>
                  <Wrap style={{ marginTop: "30px" }}>
                    <Title fin>
                      <h2>Financing Options</h2>
                      <button onClick={() => this.handleInstallmentReset()}>Reset</button>
                    </Title>
                    <FinancingOption
                      initializeAddedBenefits={this.initializeAddedBenefits}
                      installmentData={installmentData}
                      type={type}
                      currentMode={currentMode}
                    />
                  </Wrap>
                  <Wrap coupon style={{ marginTop: "30px" }}>
                    <Title>
                      <h2>Coupon</h2>
                    </Title>
                    <Coupon />
                  </Wrap>
                  {currentMode === "rtl" && (
                    <Wrap
                      amt
                      isCouponApplied={!couponIsRemoved && rtlCoupon && rtlCoupon.isValid}
                    >
                      <p>Amount to be charged</p>
                      <p className="defAmt">
                        {`$${currentRTLSelectedInstallmentPlan.unit_price
                          ? this.getShownSectionAmount(currentRTLSelectedInstallmentPlan.unit_price)
                          : this.getShownSectionAmount(currentRTLPlanSelected.unit_price)}`}
                      </p>
                      {currentMode === "rtl" && rtlCoupon && rtlCoupon.isValid && (
                        <React.Fragment>
                          <p className="discAmt">
                            {`$${currentRTLSelectedInstallmentPlan.unit_price
                              ? this.getShownSectionAmount(currentRTLSelectedInstallmentPlan.unit_price - rtlCoupon.discountAmount)
                              : this.getShownSectionAmount(currentRTLPlanSelected.unit_price - rtlCoupon.discountAmount)}`}
                          </p>
                        </React.Fragment>
                      )}
                    </Wrap>
                  )}
                  {currentMode === "broker" && (
                    <Wrap
                      amt
                      isCouponApplied={!couponIsRemoved && brokerCoupon && brokerCoupon.isValid}
                    >
                      <p>Amount to be charged</p>
                      <p className="defAmt">
                        {`$${currentBROKERPlanSelected && Object.keys(currentBROKERPlanSelected).length > 0 && this.getShownSectionAmount(currentBROKERPlanSelected.unit_price)}`}
                      </p>
                      {currentMode === "broker" && brokerCoupon && brokerCoupon.isValid && (
                        <React.Fragment>
                          <p className="discAmt">
                            {`$${this.getShownSectionAmount(currentBROKERPlanSelected.unit_price - brokerCoupon.discountAmount)}`}
                          </p>
                        </React.Fragment>
                      )}
                    </Wrap>
                  )}
                  {currentMode === "blu" && (
                    <Wrap
                      amt
                      isCouponApplied={!couponIsRemoved && bluCoupon && bluCoupon.isValid}
                    >
                      <p>Amount to be charged</p>
                      <p className="defAmt">
                        {`$${currentBLUSelectedInstallmentPlan.unit_price
                          ? this.getShownSectionAmount(currentBLUSelectedInstallmentPlan.unit_price)
                          : this.getShownSectionAmount(currentBLUPlanSelected.unit_price)}`}
                      </p>
                      {currentMode === "blu" && bluCoupon && bluCoupon.isValid && (
                        <React.Fragment>
                          <p className="discAmt">
                            {`$${currentBLUSelectedInstallmentPlan.unit_price
                              ? this.getShownSectionAmount(currentBLUSelectedInstallmentPlan.unit_price - bluCoupon.discountAmount)
                              : this.getShownSectionAmount(currentBLUPlanSelected.unit_price - bluCoupon.discountAmount)}`}
                          </p>
                        </React.Fragment>
                      )}
                    </Wrap>
                  )}
                  <Wrap
                    amt
                  >
                    <p>Total Amount to be charged</p>
                    <p className="defAmt">
                      {`$${this.getFinalTotalAmount(totalCost, rtlCoupon, bluCoupon, brokerCoupon)}`}
                    </p>
                  </Wrap>
                </React.Fragment>
              )}
              {(currSubs.length > 0 && currSubs.includes(currentSelectedPlan.plan_id)) && (
                <React.Fragment>
                  {displayItems && (
                    <Wrap inst>
                      <Title fin>
                        <h2>Selected Finance Options</h2>
                      </Title>
                      <p>{`$${displayItems.unit_price}`}</p>
                      <InstallmentPlan>{`(${displayItems.name})`}</InstallmentPlan>
                    </Wrap>
                  )}
                  <Wrap>
                    <Title>
                      <h2>Lead Cap</h2>
                    </Title>
                    <LeadCap
                      type={type}
                    />
                  </Wrap>
                  <Wrap>
                    <Popup
                      open={this.state.openConfirmationSave}
                      modal
                      closeOnDocumentClick={false}
                      closeOnEscape={true}
                    >
                      {close => (
                        <div className="modal-2">
                          <div className="modal-header-new">
                            <span className="modal-header-text">Do you want to save these changed benefits</span>
                          </div>
                          {benefitsToAdd && benefitsToAdd.length > 0 && (
                            <div>
                              <h3> You are going to add plans </h3>
                              <table>
                                <tr>
                                  <th>Feature</th>
                                  <th>Price</th>
                                </tr>
                                {benefitsToAdd.map(benefit => (
                                  <tr>
                                    <td>{benefit.benefit_key}</td>
                                    <td>{benefit.price ? `$${benefit.price}` : 'Free'}</td>
                                  </tr>
                                ))}
                              </table>
                              <p>
                                These changes will be effective immediately
                              </p>
                            </div>
                          )}
                          {benefitsToRemove && benefitsToRemove.length > 0 && (
                            <div>
                              <h3> You are going to remove plans </h3>
                              <table>
                                <tr>
                                  <th>Feature</th>
                                  <th>Price</th>
                                </tr>
                                {benefitsToRemove.map(benefit => (
                                  <tr>
                                    <td>{benefit.benefit_key}</td>
                                    <td>{benefit.price ? `$${benefit.price}` : 'Free'}</td>
                                  </tr>
                                ))}
                              </table>
                              <p>
                                The above plan changes will take effect from next billing cycle
                              </p>
                            </div>
                          )}
                          <div className="dialogue-box-footer">
                            <div>
                              <button className="med-btn-1" onClick={() => { this.handleCustomization(); this.toggleOpenConfirmationSave(false); }}>
                                YES
                    </button>
                              <button className="med-btn-1" onClick={() => this.toggleOpenConfirmationSave(false)}>
                                NO
                    </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Popup>
                    <Save
                      onClick={() => this.toggleOpenConfirmationSave(true)}
                    >
                      Save
            </Save>
                  </Wrap>
                </React.Fragment>
              )}
              <Wrap pd>
                {noSub ? (
                  <StripeCheckout
                    token={this.onCheckout}
                    stripeKey={stripeKey}
                  />
                ) : !currSubs.includes(currentSelectedPlan.plan_id) ?
                  (
                    <Save alt
                      onClick={() => { this.checkoutStripePlan() }}
                    >
                      Checkout Plan
                    </Save>
                  ) : null}
              </Wrap>
            </React.Fragment>
          )}
        </CheckoutWrap>
      </React.Fragment>
    )
  }


}

export default Container(Checkout)
