import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  
`

class Submitted extends React.PureComponent {
  state = {}

  componentDidMount() {
    // const {  } = this.props
  }

  render() {
    // const {  } = this.state
    // const {  } = this.props
    return (
      <Wrap>
        <h1>asdasdsadadasd as dasdsub</h1>
      </Wrap>
    )
  }
}

export default Submitted
