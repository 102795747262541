import React from "react"
import styled from "styled-components"
import { Title } from "./common"

const Wrap = styled.div``

const Generic = ({
  text,
  time,
}) => (
  <Wrap>
    <Title type={text} time={time} />
  </Wrap>
)

export default Generic
