import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  width: 100%;
  height: 300px;
  overflow: scroll;

`

const ImageList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: 12px;
  padding: 0;
  flex-direction: row;
`

const ImageCard = styled.li`
  height: 127px;
  width: 213px;

  img {
    height: 100%;
    width: 100%;
  }

  &: hover {
    border: 1px solid yellow;
  }
`

const TitleWrap = styled.div``

const ImageSelect = ({
  imageArray = [],
}) => (
  <>
    <TitleWrap>
      <p>Current Property Images</p>
    </TitleWrap>
    <Wrap>
      {imageArray.length <= 0 ? (
        <p>No Images</p>
      ) : (
        <ImageList>
          {imageArray.map((image, index) => (
            <ImageCard highlight={index === 0}>
              <img src={image} alt="img" />
            </ImageCard>
          ))}
        </ImageList>
      )}
    </Wrap>
  </>
)

export default ImageSelect
