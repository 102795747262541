import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { authCheck } from "services/auth"
import {
  createOfferAction,
  updateOfferAction,
  getAllOffersAction,
  getCurrentDealDetailsAction,
  updateOfferDealStatusAction,
  resetLastDealAction,
  setCurrentVendorEditDetailsAction,
  updateVendorAction,
  toggleAllCashOfferAction,
  updateStatusNoteForChangeAction,
  updateLenderAction,
  setOfferFilterQueryAction,
  toggleDrawerActionAction,
  uploadImagesForPropertyAction,
  getConvertedOfferDealAction,
  setOfferInfoDetailsAction,
  uploadPDFToS3Action,
  setLoaderAction,
  setUnselect,
} from "./actions"

const mapStateToProps = (state) => {
  const { rarTech, offerInfoDetails } = state
  const isLogged = authCheck()
  return {
    isLogged,
    offerInfoDetails,
    ...rarTech,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      //offers
      getDealDetails: getCurrentDealDetailsAction.request,
      getOffers: getAllOffersAction.request,
      createOffer: createOfferAction.request,
      updateOffer: updateOfferAction.request,
      updateOfferStatus: updateOfferDealStatusAction.request,
      resetForm: resetLastDealAction.call,
      setCurrentVendorEditDetails: setCurrentVendorEditDetailsAction.call,
      updateVendor: updateVendorAction.request,
      updateLender: updateLenderAction.request,
      toggleAllCashOffer: toggleAllCashOfferAction.call,
      updateNote: updateStatusNoteForChangeAction.call,
      setOfferFilterQueries: setOfferFilterQueryAction.call,
      toggleDrawer: toggleDrawerActionAction.call,
      uploadImageForProperty: uploadImagesForPropertyAction.request,
      uploadPDFtoS3: uploadPDFToS3Action.request,
      initiateOfferConversion: getConvertedOfferDealAction.request,
      setOfferInfoDetails: setOfferInfoDetailsAction.call,
      setLoader: setLoaderAction.call,
      setUnselect: setUnselect.call,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
