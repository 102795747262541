import { takeLatest, call, put, select, takeEvery } from "redux-saga/effects"
import io from "socket.io-client"
import { union } from "lodash"
import * as Actions from "./actions"

window.MULTICALLER_POOL = null

function* connectToSocket(action) {
  try {
    // const { twilioUserName } = action.data
    // const URL = `${process.env.REACT_APP_API_BASE_URL}/socket.io/${twilioUserName}`
    // window.MULTICALLER_POOL = io(URL)
    // const { MULTICALLER_POOL } = window
    // MULTICALLER_POOL.on("SendCallsDetailsEvent", function (data) {
    //   console.log("called...", data)
    // })
    // console.log("Connecting to Multiple-caller Endpoint")
    // console.log("URL => ", URL)
  } catch (error) {
    console.warn("Something went wrong with Multiple caller", error)
  }
}

// function* createInitialCallingModal(action) {
//   try {
//     console.log("called... in action,,", action)
//   } catch (error) {
//     console.warn("Something went wrong with Multiple caller", error)
//   }
// }

function* handleCallStateChange(action) {
  try {
    const callStatus = yield select(state => state.multipleCaller.callStatus)
    Object.keys(action.data).map((key) => {
      action.data[key].map((item) => {
        Object.keys(callStatus).map((key2) => {
          if (callStatus[key2] && callStatus[key2].length > 0) {
            console.log(`Element Exists in ${key2}. Referral Id: ${item.referral_id}, %cREMOVED`, "color: red")
            callStatus[key2] = callStatus[key2].filter(x => x.referral_id !== item.referral_id)
          }
          if (!callStatus[key]) {
            callStatus[key] = []
          }
          callStatus[key] = union(callStatus[key], [item])
        })
      })
    })
    yield put(Actions.updateCallStateAction.call(callStatus))
  } catch (error) {
    console.warn("warn => ", error)
  }
}

function* handleElementDelete(action) {
  try {
    const callStatus = yield select(state => state.multipleCaller.callStatus)
    const DELETION_KEYS = ["CallCycleFinishedEvent", "RemoveReferralFromListEvent"]
    const currentKey = Object.keys(action.data)[0]
    console.log(currentKey)
    if (
      DELETION_KEYS.includes(currentKey)
      && action.data[currentKey] && action.data[currentKey].length > 0) {
      action.data[currentKey].map((item) => {
        // find element in all state
        Object.keys(callStatus).map((key) => {
          if (callStatus[key] && callStatus[key].length > 0) {
            callStatus[key] = callStatus[key] && callStatus[key].filter(x => x.referral_id !== item.referral_id)
          }
        })
      })
      // if (!callStatus.prevCall) {
      //   callStatus.prevCall = []
      // }
      // callStatus.prevCall = [
      //   ...callStatus.prevCall,
      //   ...action.data[currentKey],
      // ]
    } else {
      action.data.map((item) => {
        // find element in all state
        Object.keys(callStatus).map((key) => {
          if (callStatus[key] && callStatus[key].length > 0) {
            callStatus[key] = callStatus[key] && callStatus[key].filter(x => x.referral_id !== item.referral_id)
          }
        })
      })
    }
    yield put(Actions.updateCallStateAction.call(callStatus))
  } catch (error) {
    console.warn("Error => ", error)
  }
}

function* updateItemInAnyState(action) {
  try {
    const callStatus = yield select(state => state.multipleCaller.callStatus)
    if (action.data && action.data.length > 0) {
      action.data.map((item) => {
        Object.keys(callStatus).map((key) => {
          if (callStatus[key] && callStatus[key].length > 0) {
            const elementIndex = callStatus[key] && callStatus[key].findIndex(x => x.referral_id !== item.referral_id)
            callStatus[key][elementIndex] = item
            console.log(`Index of element is ${elementIndex} in ${key} with referral id: ${item.referral_id}`)
          }
        })
      })
    }
  } catch (error) {
    console.log("Error => ", error)
  }
}

function* deleteItemAutomatically(action) {
  try {
    const {
      referral_id,
      deleteFrom,
    } = action.data
    const callStatus = yield select(state => state.multipleCaller.callStatus)
    if (callStatus[deleteFrom]) {
      console.log("Deleting from ", callStatus[deleteFrom])
      console.log("item id", referral_id)
      callStatus[deleteFrom] = callStatus[deleteFrom] && callStatus[deleteFrom]
        .filter(x => x.referral_id !== referral_id)
    }
    yield put(Actions.updateCallStateAction.call(callStatus))
  } catch (error) {
    console.log(error)
  }
}

export default function* main() {
  yield takeEvery(Actions.connectToSocketAction.type, connectToSocket)
  yield takeEvery(Actions.changeCallStateAction.type, handleCallStateChange)
  yield takeEvery(Actions.deleteElementFromStateAction.type, handleElementDelete)
  yield takeLatest(Actions.updateCallStateAction.type, updateItemInAnyState)
  yield takeEvery(Actions.deleteItemAutomaticallyAction.type, deleteItemAutomatically)
}
