import React from "react"
import {
  Table,
  Tag,
  Pagination,
  Button,
  Modal,
} from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import styled from "styled-components"
import colors from "../../../../lib/colors"
import {
  serializer,
} from "./utils"
import ConfirmationNote from "../ConfirmationNote/index"

const { confirm } = Modal

const Wrap = styled.div``

const DeleteButton = styled.button`
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
`

const RecordingTable = ({
  recordings,
  fetchingData,
  toggleDownloadDrawer,
  deleteRecordings,
}) => {
  const createColumn = () => {
    const columns = [
      {
        title: "Date",
        dataIndex: "roomHeldOn",
        key: "roomHeldOn",
      },
      {
        title: "Room Name",
        dataIndex: "name",
        key: "name",
      },
      // {
      //   title: "Download links",
      //   key: "downloadableLinks",
      //   dataIndex: "downloadableLinks",
      //   render: (text, record) => {
      //     console.log(text)
      //     return (
      //       <>
      //         {text ? (
      //           text.map(item => (
      //             <ul>
      //               <li>
      //                 <a href={item.aws_s3_url}>{`Recorded by ${item.name}`}</a>
      //               </li>
      //             </ul>
      //           ))
      //         ) : (
      //           <p>No links</p>
      //         )}
      //       </>
           
      //     )
      //   },
      // },
      {
        title: "Download Link",
        key: "downloadableLinks",
        dataIndex: "downloadableLinks",
        render: data => (
          <button
            type="button"
            onClick={() => {
              toggleDownloadDrawer({
                bool: true,
                data,
              })
            }}
          >
            View all
          </button>
        ),
      },
      {
        title: "Action",
        key: "key",
        dataIndex: "key",
        render: (data) => {
          return (
            <DeleteButton
              type="button"
              onClick={() => {
                const payload = {
                  room_id: `${data}`,
                }

                confirm({
                  title: "Do you want to delete all the recordings of this room?",
                  icon: <ExclamationCircleOutlined />,
                  content: <ConfirmationNote
                    text="This action is irreversible."
                    updateNote=""
                    value=""
                  />,
                  onOk() {
                    deleteRecordings({
                      payload,
                    })
                  },
                  onCancel() {
                    //
                  },
                })
              }}
            >
              Delete Recordings
            </DeleteButton>
          )
        },
      },
    ]
    return columns
  }

  const serialized = recordings && serializer(recordings) || []

  return (
    <Wrap>
      <Table
        className="infoTable"
        columns={createColumn()}
        dataSource={serialized}
        loading={fetchingData}
      >
        <Pagination
          total={serialized.length}
          defaultPageSize={50}
          defaultCurrent={1}
        />
      </Table>
    </Wrap>
  )
}

export default RecordingTable
