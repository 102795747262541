import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"
import moment from 'moment';

const Title = styled.div`
  display: flex;
  p {
   display: block;
   width: 41px;
   height: 16px;
   border-radius: 2px;
   background-color: #c6c6c6;
   padding: 1px 2px 2px 7px;
   margin-top: 2px;
   margin-right: 6px !important;
  }
  .draft {
   color: white;
   padding-left: 8px;
  }
`
const EditCourse = styled(Link)`
  margin-bottom: 14px;
  width: 118px;
  display: block;
  height: 30px;
  font-size: 14px;
  border: none;
  background: none;
  color: black;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding-top: 2px;
`

const ListWrap = styled.ul`
  list-style: none;
  padding:0;
  li{
    div {
     width: 100%;
    }
    width: 100%;
    height: 68px;
    padding: 15px 10px 10px 15px;
    border: solid 1px #e2e4e8;
    display: flex;
    .addedOn {
     margin-top: 6px;
    }
    .courseTitle {
     margin-top: -2px;
    }
    .cta {
     height: 100%;
     width: 310px;
     display: flex;
     div {
      flex-grow: 1;
      height: 100%;
      padding: 8px 8px 8px 24px;
      max-width: 99px;
      button{
       cursor: pointer;
       background: none;
       border: none;
      }
      .delete {
       color: red;
      }
     }
    }
   h2,p{
    margin: 0;
   }
   h2 {
    font-size: 16px;
    font-weight: 600;
    color: #282b2f;
    margin-left: ${props => props.isPublished ? "10px" : "0px"};
   }
   p {
    font-size: 12px;
    color: #586069;
   }
  }
`

class CourseList extends Component{

 componentDidMount() {
  //
 }

 render(){
  const {
   courses,
  } = this.props

  return(
   <ListWrap
   >
    {courses && courses.length > 0 && courses.map(course => (
     <li>
      <div>
       <Title
        isNotPublished={course && !Boolean(course.published)}
       >
         {course && !course.published && (
          <p className="draft">Draft</p>
         )}
         <h2 className="courseTitle">
          {course.title}
         </h2>
       </Title>
       <p className="addedOn">{`Added On ${moment.unix(course.created_at).format("MM/DD/YYYY")}`}</p>
      </div>
      <div className="cta">
        <div>
        </div>
        <div>
         <EditCourse
          to={`/academy-admin/courses/course/${course.id}`}
         >
          Edit
         </EditCourse>
        </div>
      </div>
     </li>
    ))}
   </ListWrap>
  )
 }

}

export default Container(CourseList)
