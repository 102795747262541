import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/ProductInterest"
import Placeholder from "icons/placeholder.jpg"

const Wrap = styled.div`
 width: 100%;

 ul {
   padding: 0;
 }

 li {
   width: 478px;
   height: 73px;
   margin: 0 0 0 24px;
   display: flex;
   border-radius: 7px;
   border: solid 1px grey;
 }
`
const ImageWrap = styled.div`
  width: 65px;
  height: 100%;
  margin: 0 6px 0 0;
  border-radius: 6px;
  border: solid 1px #a8a8a8;
  img{
    height: 100%;
    width: 100%;
  }
`

const DetailWrap = styled.div`
  width: 410px;
  height: 100%;
  margin: 0 6px 0 0;
  padding: 1px 12px 1px 1px;
  border-radius: 6px;
  .modalTitle {
    font-size: 12px;
    font-family: "SF Pro Display", sans-serif;
    color: #a8a8a8;
  }
  .childCount {
    font-family: "SF Pro Display", sans-serif;
    font-size: 12px;
    color: #8c8c8c;
    float: right;
    margin-top: -26px;
    font-weight: 500;
  }
`

class Disabled extends Component {
  componentDidMount(){
   //
  }

  render(){
    const {
      getAllPIMResponse,
    } = this.props

    const {
      data: modals,
      isFetching,
    } = getAllPIMResponse

    return (
      <Wrap>
       <ul>
        {modals && modals.data && modals.data.map((item, index) => {
          if(!item.isEnabled) {
            return (
              <li>
              <ImageWrap>
               <img src={Placeholder} alt="placeholder.jpg" />
              </ImageWrap>
              <DetailWrap>
                <p className="modalTitle">{item.title}</p>
              </DetailWrap>
              </li>
            )
          }
        })}
       </ul>
      </Wrap>
    )
  }
}


export default Container(Disabled)
