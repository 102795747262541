import React from "react"
import styled from "styled-components"
import { getPromptMessage } from "../Utils"

const Wrap = styled.div``

const getInvitationPromptMessage = (status, referrer, agentName) => {
  switch (status) {
    case "invited":
      return `Updating ${referrer}'s invitation for ${agentName} status to Invited.`
    case "declined":
      return `Updating ${referrer}'s invitation for ${agentName} status to Declined.`
    case "joined":
      return `Updating ${referrer}'s invitation for ${agentName} status to Joined.`
    case "non_responsive":
      return `Updating ${referrer}'s invitation for ${agentName} status to Non-Responsive.`
    case "reaching_out":
      return `Updating ${referrer}'s invitation for ${agentName} status to Reaching Out`
    case "in_progress":
      return `Updating ${referrer}'s invitation for ${agentName} status to In Progress`
    case "ineligible":
      return `Updating ${referrer}'s invitation for ${agentName} status to Ineligible`
    case "credited":
    default:
      return false
  }
}

const ConfirmationModal = (props) => {
  const {
    reason,
    agentName,
    typeSet,
    referrer,
    status,
    currentLinkedAgent,
  } = props

  const message = typeSet === "invitation" ? getInvitationPromptMessage(status, referrer, agentName)
    : typeSet === "rewarding" && getPromptMessage(props)

  return (
    <Wrap>
      {typeSet === "invitation" ? (
        <>
          <p>{message}</p>
          {currentLinkedAgent && (
            <p>{`${agentName} is being linked to ${currentLinkedAgent}`}</p>
          )}
        </>
      ) : typeSet === "delete" ? (
        <p>Do you want to delete this invitation?</p>
      ) : (
        <>
          <p>{message}</p>
          <p>{reason}</p>
        </>
      )}
    </Wrap>
  )
}

export default ConfirmationModal
