import React from "react"
import { Modal } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

const { confirm } = Modal

export const showConfirm = (msg, func, otherMessage, revert) => {
  console.log("????")
  confirm({
    title: msg || "Please confirm the action.",
    icon: <ExclamationCircleOutlined />,
    content: otherMessage || "",
    onOk() {
      func()
    },

    onCancel() {
      if (revert && typeof revert === "function") {
        revert()
      }
      return false
    },
  })
}

