import React, { Component } from "react"
import styled, { css } from "styled-components"
import colors from "lib/colors"
import Container from "container/Agent"
import Benefits from "./Benefits"
import { getDuration } from "./shared"
import { DefaultLoader } from "components/UI/Loader/Loader";

const Wrap = styled.div`
  height: 100px;
  width: 100%;
`

const FinancingUL = styled.ul`
  list-style: none;
  margin-top: 0;
  font-family: "SF Pro Display", sans-serif;
  padding-left: 18px;
  padding-top: 10px;
  label {
   margin-left: 10px;
   font-size: 16px;
   font-weight: 300;
   font-stretch: normal;
   line-height: 1.63;
   letter-spacing: normal;
  }
`

const SubData = styled.p`
  margin: 0;
  padding-left: 30px;
  height: 14px;
  font-size: 12px;
  font-weight: 300;
  color: #8c8c8c;
`

const handleChange = (setInstallment, installmentPlan, currentMode, initializeAddedBenefits) => {
  setInstallment({
    currentMode,
    currentSelectedInstallmentPlan: installmentPlan,
  })
  initializeAddedBenefits({ installmentPlan })
}

const FinancingOption = ({
  installmentData,
  currentSelectedPlan,
  setInstallment,
  currentSelectedInstallmentPlan,
  type,
  currentMode,
  initializeAddedBenefits
}) => {
  return (
    <React.Fragment>
      {!installmentData ? (
        <Wrap>
          <DefaultLoader height={0} width={0} />
        </Wrap>
      ) : (
        <FinancingUL>
          <li>
            <input
              checked={currentSelectedInstallmentPlan.plan_id === currentSelectedPlan.plan_id}
              onChange={() => handleChange(setInstallment, currentSelectedPlan, currentMode, initializeAddedBenefits)}
              type="checkbox"
              name={"defaultInstallment"}
            />
            <label>
              {`$${currentSelectedPlan.unit_price}`}
            </label>
            <SubData>{`(PAY FULL)`}</SubData>
          </li>
          { type === "single" && currentMode !== "blu" && installmentData && installmentData.single_plans && installmentData.single_plans.rtl.map(key => (
            <li>
              <input
                checked={currentSelectedInstallmentPlan.plan_id === key.plan_id}
                onChange={() => handleChange(setInstallment, key, currentMode, initializeAddedBenefits)}
                type="checkbox"
                name={"installment"}
              />
              <label>
                {`$${key.unit_price}`}
              </label>
              <SubData>{`(per ${getDuration(key.billing_interval)})`}</SubData>
            </li>
          ))}
          { type === "team" && currentMode !== "blu" && installmentData && installmentData.team_plans && installmentData.team_plans.rtl.map(key => {
            if (key.is_blink_visible) {
              return (
                <li>
                  <input
                    checked={currentSelectedInstallmentPlan.plan_id === key.plan_id}
                    onChange={() => handleChange(setInstallment, key, currentMode, initializeAddedBenefits)}
                    type="checkbox"
                    name={"installment"}
                  />
                  <label>
                    {`$${key.unit_price}`}
                    {/* {`$${key.unit_price} ${(key.billing_cycle).toLowerCase()}`} */}
                  </label>
                  <SubData>{`(per ${getDuration(key.billing_interval)})`}</SubData>
                </li>
              )
            }
          }
          )}
        </FinancingUL>
      )}
    </React.Fragment>
  )
}

export default Container(FinancingOption)
