import React from "react"
import { Checkbox } from "antd"
import {
  MarkAsResolvedWrap,
} from "./markAsResolvedStyles"

const AiToggle = ({
  onChange,
  isDisabled,
}) => (
  <MarkAsResolvedWrap>
    <Checkbox disabled={isDisabled} onChange={onChange}>Mark as Resolved</Checkbox>
  </MarkAsResolvedWrap>
)

export default AiToggle
