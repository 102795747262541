import React from "react"
import styled from "styled-components"
import SignUpForm from "./SignupForm"

const Wrap = styled.div``

const SignUp = () => (
  <Wrap>
    <SignUpForm />
  </Wrap>
)

export default SignUp
