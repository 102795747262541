import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Button from "dumbComponents/common/UI/Button"
import agentContainer from "container/BrokerDashboard/editAgentContainer"
import { Input } from "antd"

const Wrap = styled.div`
  
`

const HeaderWrap = styled.div`
 display: flex;
 justify-content: space-between;
`
const Title = styled.h1`
 margin-left: 8px;
 font-weight: 300;
`

const Label = styled.h3`
 margin: 8px 6px 10px 8px;
 font-weight: 200;
`
const RowDiv = styled.div`
 display: flex;
 margin-bottom: 8px;

`
const InputDiv = styled.div`
 display: flex;
 flex-direction: column;
`
const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`
const LabelSmall = styled.h3`
  margin: 0px 0px 0px 8px;
  font-weight: 200;
`

const SetupProfileInfo = ({ setupProfileResponse, updateSetupProfile, match, agentId }) => {

  const {
    data,
  } = setupProfileResponse || {}

  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [preferredEmail, setPreferredEmail] = useState("")
  const [phoneNum, setPhoneNum] = useState("")
  const [birthday, setBirthday] = useState("")
  const [stateServed, setStateServed] = useState("")
  const [offAdd, setOffAdd] = useState("")
  const [mailAdd, setMailAdd] = useState("")
  const [website, setWebsite] = useState("")
  const [about, setAbout] = useState("")
  const [zipcodes, setZipcodes] = useState([""])
  const [facebook, setFacebook] = useState("")
  const [linkedin, setLinkedin] = useState("")
  const [twitter, setTwitter] = useState("")
  const [zipChanged, setZipChanged] = useState(false)

  const getSite = () => {
    if (data) {
      const fb = data && data.socialMedia && data.socialMedia.map((socialMediaItem) => {
        if (socialMediaItem.site.includes("facebook")) {
          setFacebook(socialMediaItem.handle)
        } else if (socialMediaItem.site.includes("linkedin")) {
          setLinkedin(socialMediaItem.handle)
        } else if (socialMediaItem.site.includes("twitter")) {
          setTwitter(socialMediaItem.handle)
        } else {
          console.log("not found")
        }
        return fb
      })
    }
  }

  useEffect(() => {
    if (data) {
      setFirstname(data.firstname)
      setLastname(data.lastname)
      setPreferredEmail(data.preferred_email)
      setPhoneNum(data.phone)
      setBirthday(data.birthday)
      setStateServed(data.state)
      setOffAdd(data.office_address)
      setMailAdd(data.mailing_address)
      setWebsite(data.website)
      setAbout(data.about_agent)
      setZipcodes(data.user_zipcodes_served)
      getSite()
    }
  }, [data])

  const { TextArea } = Input

  const handleSave = () => {
    const payload = {}

    payload.agent_id = agentId || null
    payload.firstname = firstname || null
    payload.lastname = lastname || null
    payload.preferred_email = preferredEmail || null
    payload.phone = phoneNum || null
    payload.birthday = birthday || null
    payload.state = stateServed || null
    if (zipChanged) {
      payload.zipcodes = zipcodes.split(",") || null
    } else {
      payload.zipcodes = zipcodes || null
    }
    payload.mailing_address = mailAdd || null
    payload.office_address = offAdd || null
    payload.website = website || null
    payload.about_agent = about || null
    payload.socialMedias = {
      facebook: {
        handle: facebook || null,
      },
      twitter: {
        handle: twitter || null,
      },
      linkedin: {
        handle: linkedin || null,
      },
    }

    updateSetupProfile({ payload })
  }

  return (
    <Wrap>
      <HeaderWrap>
        <Title>Edit Setup Profile Info</Title>
        <Button onClick={() => { handleSave() }}>
          Save
        </Button>
      </HeaderWrap>
      <RowDiv>
        <InputDiv>
          <Label>First Name</Label>
          <Input
            value={firstname}
            style={{
              position: "relative",
              width: "140px",
              marginLeft: "8px",
            }}
            onChange={(e) => {
              setFirstname(e.target.value)
            }}
          />
        </InputDiv>
        <InputDiv>
          <Label>Last Name</Label>
          <Input
            value={lastname}
            style={{
              position: "relative",
              width: "140px",
              marginLeft: "12px",
            }}
            onChange={(e) => {
              setLastname(e.target.value)
            }}
          />
        </InputDiv>
      </RowDiv>
      <RowDiv>
        <InputDiv>
          <Label>Preferred Email</Label>
          <Input
            value={preferredEmail}
            style={{
              position: "relative",
              width: "140px",
              marginLeft: "8px",
            }}
            onChange={(e) => {
              setPreferredEmail(e.target.value)
            }}
          />
        </InputDiv>
        <InputDiv>
          <Label>Phone Number</Label>
          <Input
            value={phoneNum}
            style={{
              position: "relative",
              width: "140px",
              marginLeft: "12px",
            }}
            onChange={(e) => {
              setPhoneNum(e.target.value)
            }}
          />
        </InputDiv>
      </RowDiv>
      <InputDiv>
        <Label>Birthday</Label>
        <Input
          type="date"
          value={birthday}
          style={{
            position: "relative",
            width: "160px",
            marginLeft: "8px",
          }}
          onChange={(e) => {
            setBirthday(e.target.value)
          }}
        />
      </InputDiv>
      <RowDiv>
        <InputDiv>
          <Label>State</Label>
          <Input
            value={stateServed}
            style={{
              position: "relative",
              width: "140px",
              marginLeft: "8px",
            }}
            onChange={(e) => {
              setStateServed(e.target.value)
            }}
          />
        </InputDiv>
        <InputDiv>
          <Label>Office Address</Label>
          <Input
            value={offAdd}
            style={{
              position: "relative",
              width: "190px",
              marginLeft: "12px",
            }}
            onChange={(e) => {
              setOffAdd(e.target.value)
            }}
          />
        </InputDiv>
      </RowDiv>
      <InputDiv>
        <Label>Mailing Address</Label>
        <Input
          value={mailAdd}
          style={{
            position: "relative",
            width: "190px",
            marginLeft: "12px",
          }}
          onChange={(e) => {
            setMailAdd(e.target.value)
          }}
        />
      </InputDiv>
      <InputDiv>
        <Label>Website</Label>
        <Input
          value={website}
          style={{
            position: "relative",
            width: "240px",
            marginLeft: "8px",
          }}
          onChange={(e) => {
            setWebsite(e.target.value)
          }}
        />
      </InputDiv>
      <InputDiv>
        <Label>About Agent</Label>
        <TextArea
          rows={4}
          value={about}
          style={{
            position: "relative",
            width: "240px",
            marginLeft: "8px",
          }}
          onChange={(e) => {
            setAbout(e.target.value)
          }}
        />
      </InputDiv>
      <InputDiv>
        <Label>Zipcodes Served</Label>
        <Input
          value={zipcodes}
          style={{
            position: "relative",
            width: "240px",
            marginLeft: "8px",
          }}
          onChange={(e) => {
            setZipcodes(e.target.value)
            setZipChanged(true)
          }}
        />
      </InputDiv>
      <ColumnDiv>
        <Label>Social Media Handles</Label>
        <RowDiv>
          <LabelSmall>Facebook: </LabelSmall>
          <Input
            value={facebook}
            onChange={(e) => { setFacebook(e.target.value) }}
            style={{
              position: "relative",
              width: "200px",
              marginLeft: "8px",
            }}
          />
        </RowDiv>
        <RowDiv>
          <LabelSmall>Twitter: </LabelSmall>
          <Input
            value={twitter}
            onChange={(e) => { setTwitter(e.target.value) }}
            style={{
              position: "relative",
              width: "200px",
              marginLeft: "30px",
            }}
          />
        </RowDiv>
        <RowDiv>
          <LabelSmall>LinkedIn: </LabelSmall>
          <Input
            value={linkedin}
            onChange={(e) => { setLinkedin(e.target.value) }}
            style={{
              position: "relative",
              width: "200px",
              marginLeft: "15px",
            }}
          />
        </RowDiv>
      </ColumnDiv>
    </Wrap>
  )
}

export default agentContainer(SetupProfileInfo)
