import React from "react"
import styled from "styled-components"
import { SaveAgreement } from "./CTAS/index"

const Container = styled.div`
  border-top: 1px solid #F4F4F4;
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const CTASection = () => (
  <Container>
    <SaveAgreement />
  </Container>
)

export default CTASection

