import React from "react"
import { Table, Tag, Pagination } from "antd"
import styled from "styled-components"
import { RightOutlined, UpOutlined } from "@ant-design/icons"
import Expandable from "./Expandable"
import { earningsSerializer, handleDrawerActions } from "../../Common/Utils"
import RecruitAndEarn from "../../../../container/RecruitAndEarn"

const Button = styled.button`
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0px;
`
const ResultsFor = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  -webkit-letter-spacing: 0em;
  -moz-letter-spacing: 0em;
  -ms-letter-spacing: 0em;
  letter-spacing: 0em;
  text-align: left;
  font-style: italic;
  color: #2d2d2d;
`
const NameDiv = styled.div`
  display: flex;
  h3 {
    margin: 0px;
    padding: 0px;
    margin-left: 12px;
    font-size: 16px;
    color: red;
  }
`

const Wrap = styled.div`
  padding-top: 17px;
  .infoTable {
    th, td {
      border: none;
      font-family: "Rubik", sans-serif;
    }

    th {
      padding: 5px 5px 5px 18px;

    }

    td {
      padding: 10px 5px 10px 18px;
    }

    [class*="expanded"] td{
      padding: 0px;
    }

    .ant-table-thead tr th {
      background: linear-gradient(0deg, #E5E5E5, #E5E5E5),
      linear-gradient(0deg, #E6E6E6, #E6E6E6);
    }
  }
`

const ResultsTable = (props) => {
  const {
    fetchingEarnings,
    earningsList,
    toggleAgentDetailsDrawer,
    currentSenderAgent,
  } = props || {}

  const createColumn = () => {
    const columns = [
      {
        title: "Agent Name",
        dataIndex: "agentName",
        key: "agentName",
        render: (text, record) => {
          const togglePayload = {
            agentName: record.referred,
            agentId: record.senderId,
            bool: true,
          }
          return (
            <NameDiv>
              <Button
                style={{
                  textDecoration: "underline",
                }}
                onClick={() => handleDrawerActions(
                  false,
                  false,
                  toggleAgentDetailsDrawer,
                  togglePayload,
                )}
              >
                {text}
              </Button>
              <h3>{record.payout}</h3>
            </NameDiv>
          )
        },
      },
      {
        title: "Reward for",
        dataIndex: "receiver",
        key: "receiver",
        render: text => <Button>{text}</Button>,
      },
      {
        title: "Stock Options",
        dataIndex: "shares",
        key: "shares",
      },
      {
        title: "Status",
        key: "tags",
        dataIndex: "tags",
        render: (_, { tags }) => (
          <>
            {tags.map((tag) => {
              let color = ""
              switch (tag) {
                case "pending":
                  color = "#D8A8007D"
                  break
                case "joined":
                case "transferred":
                  color = "#62AD047D"
                  break
                case "declined":
                  color = "#EFB0B07D"
                  break
                default:
                  color = "#62AD047D"
                  break
              }
              return (
                <Tag color={color} key={tag} style={{ color: "#303030" }}>
                  {tag.toUpperCase()}
                </Tag>
              )
            })}
          </>
        ),
      },
      {
        title: "Created",
        dataIndex: "created",
        key: "created",
      },
    ]

    return columns
  }

  const serialized = !fetchingEarnings && earningsList
  && earningsSerializer(earningsList.results) || []

  return (
    <Wrap>
      {currentSenderAgent && (
        <ResultsFor>
          {`Showing results for: ${currentSenderAgent}`}
        </ResultsFor>
      )}
      <Table
        className="infoTable"
        columns={createColumn()}
        dataSource={serialized}
        loading={fetchingEarnings}
        expandable={{
          expandedRowRender: record => <Expandable text={record.expandedText} date={record.created} record={record} />,
          rowExpandable: record => record.name !== "Not Expandable",
          expandIcon: ({ expanded, onExpand, record }) => expanded ? (
            <UpOutlined onClick={e => onExpand(record, e)} />
          ) : (
            <RightOutlined onClick={e => onExpand(record, e)} />
          ),
        }}
      >
        <Pagination
          total={serialized.length}
          defaultPageSize={50}
          defaultCurrent={1}
        />
      </Table>
    </Wrap>
  )
}

export default RecruitAndEarn(ResultsTable)
