import React, { Component } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, } from 'react-google-maps';
import  MarkerWithLabel  from "react-google-maps/lib/components/addons/MarkerWithLabel";
import _ from 'lodash';
import bugsnag from 'bugsnag-js';
import fetch from 'isomorphic-fetch';
const baseUrl =process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;
const bugsnagkey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(bugsnagkey);
const key = "AIzaSyCdHBYtGkNUbvpTXOSIJCglnVxzyFoyPuo"
const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`

class Map extends Component {
    state = {
      agentId: null,
      locations: [],
      propertiesMap: null,
      propertyIds: [],
      defaultCenter:{ lat: -34.397, lng: 150.644 },
    };
    

    componentWillReceiveProps(props) {
      this.setState({ agentId: props.agentId });
      this.updateLocations(props.agentId );
    }

    updateMapCenter = (lat, lng) => {
      this.setState({defaultCenter:{ lat: lat, lng: lng }});
    };

    updateLocations = async (agentId) => {
      try {
        if (agentId) {
          let res = await fetch(baseUrl + '/v1.0/user/cities-neighbourhoods-served?agentId=' + agentId, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'internal-id': token,
            },
          });
        
          res = await res.json();
          if (res && res.response && res.response.properties && res.response.properties.length > 0 && res.response.cities && res.response.cities.length > 1)
              console.log("hererrerere");
              this.setState({propertyIds: _.keys(res.response.properties), propertiesMap: res.response.properties, defaultCenter: {lat: res.response.cities[0].latitude, lng: res.response.cities[0].longitude}});
              let propertiesMap = res.response.properties;
              _.each(_.keys(res.response.properties), (id) => {
                this.updateMapCenter(propertiesMap[id].latitude, propertiesMap[id].longitude);
              });
          }

      }
      catch(e) {
        bugsnagClient.notify(e);
      }
     
    };
    
  render() {

    let properties = [];
    let MapWithLabel = null;
    const { compose } = require("recompose");


    try {
      if (this.state.propertiesMap) {
        _.each(this.state.propertyIds, (id) => properties.push(this.state.propertiesMap[id]));

        properties = properties.map(property => {
          return  <MarkerWithLabel
                      position={{ lat: property.latitude, lng: property.longitude }}
                      labelAnchor={new window.google.maps.Point(0, 0)}
                      labelStyle={{backgroundColor: "#ffffff", fontSize: "14px", padding: "16px"}}
                      >
                    <div>
                        <div>{property.address}</div>
                        <br/>
                        <div>{'$' + (property.price  ? property.price : 0)}</div>
                        <br/>
                        <div>{property.property_status}</div>
                    </div>
                  </MarkerWithLabel>
        });

        MapWithLabel = compose(
              withScriptjs,
              withGoogleMap
          )(props =>
              <GoogleMap
                  defaultZoom={13}
                  defaultCenter={this.state.defaultCenter}
              >
                  {properties}
              </GoogleMap>
          );

      }

    }
    catch(e) {
      bugsnagClient.notify(e);
    }
    
    return (
      <div>
        {properties.length > 0 &&
          <MapWithLabel
              googleMapURL={googleMapURL}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
        }    
      </div>
    );
    
  }
}  

export default Map;