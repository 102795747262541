import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as Actions from "./actions"

function mapStateToProps(state) {
    const { productInterest } = state
    return {
         globalState: state,
        ...productInterest,
    }
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
               getAllPIMS: Actions.getAllPimAction.request,
               setCurrentProduct: Actions.setCurrentProductAction.call,
               setCurrentEditableProduct: Actions.setCurrentEditableProductAction.call,
               createNewFeatureOrChild: Actions.createNewFeatureOrChildAction.call,
               setFeatureImage: Actions.uploadImageAction.request,
               uploadNewFeature: Actions.uploadNewFeatureAction.request,
               updateFeature: Actions.updateExistingFeatureAction.request,
               reArrange: Actions.updateChildArrangementAction.call,
               setRTFValue: Actions.getCurrentRTFValueAction.call,
               setPublishedStatus: Actions.setPublishedStatusAction.call,
               setRARStatus: Actions.setPIMRARAction.call,
               setAssistStatus: Actions.setPIMAssistAction.call,
        },
        dispatch
    ),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)
