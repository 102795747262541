import React, { PureComponent } from "react"
import styled from "styled-components"
import Loader from "dumbComponents/common/UI/Loader"
import ListingList from "dumbComponents/Listings/components/ListingList"
// import Loader from "@ui/Loader"
import TechAssetsContainer from "container/BrokerDashboard/globalPagesContainer"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import {
  Input, InputNumber, Select, Form, Checkbox,
  Divider, Button, Radio, Space,
} from "dumbComponents/common/UI/antd"
import Sidebar from "dumbComponents/Listings/components/Sidebar"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
import { normalizePhone } from "../../../../services/formUtils"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
  .ant-form-item-label {    
    > label {
      color: #303030;
      font-family: 'DM Sans', sans-serif;
      font-style: normal;
      font-weight: 200;
      font-size: 18px;
      line-height: 22px;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    
  }
`

const CheckboxContainer = styled.div`
  display: flex;
`

const HeaderNav = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
`

const ProgressBarContainer = styled.div`
  padding: 30px 0px 50px 0;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #3B60E4;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LabelText = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
`

const Required = styled.span`
  color: #E34C4C
`

const LocalCheckBox = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #303030;
  border-radius: 4px;
  margin-right: 12px;
  ${props => props.disabled && `
    border: 1px solid #C7C7C7;
  `}
`

const CheckboxLabelText = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  text-transform: uppercase;
  ${props => props.disabled && `
    color: #C7C7C7;
  `}
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const CheckboxWrap = styled.div`
  padding: 0 20px 0 10px;
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
`

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  state = {
    showEscrowOption: null,
    notTCOption: null,
  }

  formRef = React.createRef();

  onFinish = (fieldValues, isDraft) => {
    const { updateListingCoverSheet, history, dealToView, getListingCoverSheetResponse, updateDealStatus, dealAgentId } = this.props
    const selectVendor = getListingCoverSheetResponse.data?.selectVendor
    let nextRoute = "/broker/tc/add-listings"
    nextRoute = isDraft ? "/broker/tc/add-listings" : "/broker/tc/add-listings/coversheet/sign-and-post-installation"
    Object.keys(fieldValues).forEach((key) => {
      if (fieldValues[key] === undefined) {
        // eslint-disable-next-line no-param-reassign
        fieldValues[key] = null
      }
    })
    updateListingCoverSheet({
      payload: {
        is_using_deal_tc: fieldValues.is_using_deal_tc,
        is_radius_tc_to_open_escrow: fieldValues.is_radius_tc_to_open_escrow,
        tc_name: fieldValues.tc_name,
        tc_email: fieldValues.tc_email,
        tc_phone: fieldValues.tc_phone,
        current_listing_step: "tc_info",
        draft: isDraft ? "Y" : "N",
      },
      dealAgentId,
      dealId: dealToView,
      callAPI: ["agent_info", "seller_info", "source_time", "property_info", "tc_info", "sign_post", "select_vendor"],
      nextRoute,
      history,
      callback: () => {
        if (!fieldValues.is_radius_tc_to_open_escrow) {
          // updateDealStatus({
          //   dealId: dealToView,
          //   step: "coming_soon",
          // })
        }
      },
    })
  }

  onSaveAsDraft = (values) => {
    console.log("===this.formRef.current.getFieldsValue===", this.formRef.current.getFieldsValue())
    this.onFinish(this.formRef.current.getFieldsValue(), true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  setShowEscrowOption = (value) => {
    this.setState({
      showEscrowOption: value === 1,
      notTCOption: value === 0,
    })
  }

  getInitialValues = () => {
    const { getListingCoverSheetResponse } = this.props
    const { showEscrowOption, notTCOption } = this.state
    const tcInfo = getListingCoverSheetResponse.data?.tcInfo
    const result = {}
    if (tcInfo) {
      if (showEscrowOption === null && notTCOption === null) {
        this.setState({
          showEscrowOption: tcInfo.is_using_deal_tc === 1,
          notTCOption: tcInfo.is_using_deal_tc === 0,
        })
      }
      return {
        ...tcInfo,
      }
    }
    return result
  }

  render() {
    const { showEscrowOption, notTCOption } = this.state
    const { history, getListingCoverSheetResponse, updateListingCoverSheetResponse, loaderOnAgentInfo } = this.props
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]
    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Transaction Coordination
        </HeaderNav>
        <ProgressBarComponent total={[1, 2, 3, 4, 5, 6]} ongoingOrCompleted={6} />
        <Container>
          <LeftSideBar>
            <Sidebar />
          </LeftSideBar>
          <RightSideBar>
            {loaderOnAgentInfo ? (<Loader />) : (
              <Form colon={false} layout="vertical" requiredMark={false} onFinish={this.onFinish} initialValues={this.getInitialValues()} preserve={false} ref={this.formRef}>
                <Form.Item name="is_using_deal_tc" label="Would you like to use your Radius in-house TC for this transaction?" rules={commonRules}>
                  <Radio.Group onChange={(e) => { this.setShowEscrowOption(e.target.value) }}>
                    <Space direction="vertical">
                      <Radio value={1}>
                        <LabelComponent text="Yes" />
                      </Radio>
                      <Radio value={0}>
                        <LabelComponent text="No" />
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                {/* {showEscrowOption && (
                  <Form.Item name="is_radius_tc_to_open_escrow" label="Do you want Radius TC to open escrow?" rules={commonRules}>
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value={1}>
                          <LabelComponent text="Yes" />
                        </Radio>
                        <Radio value={0}>
                          <LabelComponent text="No" />
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                )} */}
                {/* {notTCOption && (
                  <>
                    <Form.Item name="tc_name" label={<LabelComponent text="TC First and Last Name" required />} rules={commonRules}>
                      <Input />
                    </Form.Item>
                    <FlexBox>
                      <Form.Item
                        name="tc_email"
                        label={<LabelComponent text="TC Email" required />}
                        required
                        rules={[
                          {
                            required: true,
                            type: "email",
                            message: "email is invalid",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item name="tc_phone" label={<LabelComponent text="TC Cell Phone" required />} rules={commonRules} normalize={normalizePhone}>
                        <Input />
                      </Form.Item>
                    </FlexBox>
                  </>
                )} */}
                <FlexBox>
                  <FlexBox wd="100%">
                    <Form.Item>
                      {/* <Button
                        style={{
                          width: 119,
                          height: 48,
                          color: "#849DF8",
                          padding: 0,
                          marginLeft: "-10px",
                        }}
                        size="large"
                        type="link"
                        onClick={this.onSaveAsDraft}
                      >
                        Save for Later
                      </Button> */}
                    </Form.Item>
                  </FlexBox>
                  <FlexBox>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "white",
                          float: "right",
                          borderColor: "#3B60E4",
                          color: "#3B60E4",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        // htmlType="submit"
                        onClick={() => { history.push("/broker/tc/add-listings/coversheet/seller-info") }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "#3B60E4",
                          float: "right",
                          borderColor: "white",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        htmlType="submit"
                        loading={updateListingCoverSheetResponse.isFetching || getListingCoverSheetResponse.isFetching}
                      // onClick={() => { history.push("/broker/tc/add-listings/coversheet/escrow-info") }}
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </FlexBox>
                </FlexBox>
              </Form>
            )}
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)

