import React from "react"
import { Button, Modal } from "antd"
import styled from "styled-components"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import RecruitAndEarn from "../../../../container/RecruitAndEarn"
import InputBox from "../../Common/ReasonBox"

const { confirm } = Modal

const OuterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background: #FFFFFF;
`

const Wrap = styled.div`
  height: 110px;
  width: 100%;
  border-radius: 0px;
  background: #FFFFFF;
  padding: 39px 0px 0px 81px;
  display: flex;
  gap: 41px;
`

const Date = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`

const Text = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`

const CTAWrap = styled.div`
  display: flex;
  gap: 12px;
  background: white;
  padding-right: 30px;
`

const Expandable = (props) => {
  const {
    text,
    date,
    toggleEditDrawer,
    record,
    updateEarnings,
    setModalReasonTextValue,
    reasonText,
  } = props || {}

  const {
    agentName,
    tags,
    milestoneType,
    expandedText,
    reward,
    id,
  } = record || {}

  const showConfirm = (type, action) => {
    confirm({
      title: "Confirm the action.",
      icon: <ExclamationCircleOutlined />,
      content: <InputBox
        type={type}
        agentName={agentName}
        milestoneType={milestoneType}
        setModalReasonTextValue={setModalReasonTextValue}
        reward={reward}
      />,

      onOk() {
        action()
      },

      onCancel() {
        return false
      },
    })
  }

  const handleDrawer = () => {
    toggleEditDrawer({
      bool: true,
      data: {
        agentName,
        tags,
        milestoneType,
        expandedText,
        reward,
        id,
      },
    })
  }

  const directUpdate = (type) => {
    const payload = {
      status: type,
      reward,
      reason: reasonText || type,
      credited: type === "credited",
    }
    updateEarnings({
      id,
      payload,
    })
  }

  return (
    <OuterWrap>
      <Wrap>
        <Date>{date}</Date>
        <Text>{text}</Text>
      </Wrap>
      <CTAWrap>
        <Button
          type="primary"
          onClick={handleDrawer}
        >
            Edit
        </Button>
        {/* Show Decline button if none of the status is of APPROVED or CREDITED*/}
        {tags && tags[0] !== "approved"
        && tags[0] !== "credited"
        && tags[0] !== "declined"
        && (
          <Button
            danger
            onClick={() => {
              showConfirm("declined", () => { directUpdate("declined") })
            }}
          >
            Decline
          </Button>
        )}
        {/* Show CREDIT button if milestone is of type DEAL and status is of APPROVED*/}
        {tags
          && tags[0] === "approved"
          && tags[0] !== "declined"
          && milestoneType === "deal"
          && (
            <Button
              style={{ border: "1px solid green", color: "green" }}
              onClick={() => {
                //directUpdate("credited")
                showConfirm("credited", () => { directUpdate("credited") })
              }}
            >
              Credit
            </Button>
          )}

        {tags
          && tags[0] !== "credited"
          && tags[0] !== "declined"
          && tags[0] !== "approved" && (
          (
            <Button
              style={{ border: "1px solid green", color: "green" }}
              onClick={() => {
                //directUpdate("approved")
                showConfirm("approved", () => { directUpdate("approved") })
              }}
            >
              Approve
            </Button>
          )
        )}
      </CTAWrap>
    </OuterWrap>
  )
}

export default RecruitAndEarn(Expandable)
