import React from "react"
import moment from "moment"
import {
  CardWrap,
  AgentName,
  Topic,
  Since,
  TimeAndFeedback,
  ThumbsAndNumber,
  Feedback,
} from "./cardStyles"

const AgentTopicCard = ({
  name,
  email,
  count,
  topic,
  positiveFeedbackCount,
  negativeFeedbackCount,
  onClick,
  isSelected,
  loading,
  isLoading,
  createdAt,
}) => (
  <CardWrap
    onClick={onClick}
    isSelected={isSelected}
    loading={loading}
    isLoading={isLoading}
  >
    {!loading && (
      <>
        <AgentName>
          <p>{name}</p>
          <p>{email}</p>
        </AgentName>
        <Topic>
          <p>{`Topic ${count + 1}: ${topic}`}</p>
        </Topic>
        <TimeAndFeedback>
          <Since>
            {/**DATE NOT PROVIDED*/}
            <p>{createdAt && moment(createdAt * 1000).fromNow() || "Date "}</p>
          </Since>
          <ThumbsAndNumber>
            <Feedback color="#00B255">
              <img src="https://s3.amazonaws.com/cdn.agentdesks.com/images/SOUL_AI/thumbsUp.png" alt="barely" />
              <p>{positiveFeedbackCount}</p>
            </Feedback>
            <Feedback color="#F87171">
              <img src="https://s3.amazonaws.com/cdn.agentdesks.com/images/SOUL_AI/thumbsDown.png" alt="barely" />
              <p>{negativeFeedbackCount}</p>
            </Feedback>
          </ThumbsAndNumber>
        </TimeAndFeedback>
      </>
    )}
  </CardWrap>
)

export default AgentTopicCard
