export const getDuration = (interval) => {
  // return interval < 1 ? "month" : interval
  switch (interval) {
   case 1:
    return "month"
    break
   case 6:
    return `${interval} months`
    break
   case 12:
    return "year"
    break
   default:
    return `${interval} months`
    break
  }
}
