import React, { Component } from "react"
import styled from "styled-components"
import container from "container/Agent"
import DashboardContainer from "container/Dashboards"

const Card = styled.div`
  width: 940px;
  height: 136px;
  background-color: #ededed;
  display: flex;
  padding: 5px;
  margin-top: 50px;

  ${props => props.odd && `
     background: white;
   `}
`
const Zillow = styled.div`
 p {
  font-size: 10px;
  color: #aaaaaa;
 }
 height: 56px;
 width: 150px;
`

const Inbound = styled.div`
 p {
  font-size: 10px;
  color: #aaaaaa;
 }
 height: 56px;
 width: 150px;
`

const CallResponseTimes = styled.div`
 p {
  font-size: 10px;
  color: #aaaaaa;
 }

 .values, .missed {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
 }

 .mt17 {
  margin-top: 17px;
 }


`

const Avg = styled.div`
  width: 58px;
  height: 17px;
  border-radius: 3px;
  background-color: #d8d8d8;
  font-size: 12px;
  color: black;
  margin-right: 1px;
  margin-bottom: 1px;
  text-align: center;
  padding-top: 1px;
`

const SubscriptionStatus = styled.div`
  width: 65px;
  height: 18px;
  border-radius: 2px;
  color: white;
  font-size: 14px;
  text-align: center;
  ${props => props.active && `
     background-image: linear-gradient(106deg, #2bda8e -1%, #0ac4ba);
   `}
  ${props => props.churned && `
    background-color: #ff9898;
   `}
  ${props => props.paused && `
    background-color: #ffd460;
   `}
`

const InfoBox = styled.div`
   width: 313.33px;
   height: 100%;
   padding-left: 8px;
   ${props => props.border && `
    border-right: 1px solid #d1d1d1;
    `}
   .name {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
   }
   .email {
    font-size: 12px;
    color: #505050;
   }
`

class MetricList extends Component {
 componentDidMount() {
  //
 }

 secondsToHms = (d) => {
    if(d <= 59){
     return "<1m"
    }
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s ") : "";
    return hDisplay + mDisplay
}

 render() {
  const {
   fetchMetricsResponse,
  } = this.props

  const {
   data,
   isFetching,
  } = fetchMetricsResponse

  return (
   <React.Fragment>
    {isFetching ? (
     <h1>Fetching...</h1>
    ) : (
     <React.Fragment>
      {data && data.metrics && data.metrics.length > 0 && data.metrics.map((key, iter) => (
       <Card odd={iter%2 !== 0}>
        <InfoBox border>
         <p className="name">{`${key.name}`}</p>
         <p className="email">{`${key.email}`}</p>
         {
          key.subscription_status && (
           <SubscriptionStatus
            active={key.subscription_status === "Active"}
            paused={key.subscription_status === "Paused"}
            churned={key.subscription_status === "Churned"}
           >
            {key.subscription_status}
           </SubscriptionStatus>
          )
         }
        </InfoBox>
        <InfoBox border>
         <CallResponseTimes>
          <p>Call Response Time (Average)</p>
          <div className="values">
            <Avg>
             {key.first_call_average_time
             ? this.secondsToHms(key.first_call_average_time)
             : "-"
             }
            </Avg>
            <Avg>
             {key.second_call_average_time
             ? this.secondsToHms(key.second_call_average_time)
             : "-"
             }
            </Avg>
            <Avg>
             {key.three_call_average_time
             ? this.secondsToHms(key.three_call_average_time)
             : "-"
             }
            </Avg>
            <Avg>
             {key.four_call_average_time
             ? this.secondsToHms(key.four_call_average_time)
             : "-"
             }
            </Avg>
            <Avg>
             {key.five_call_average_time
             ? this.secondsToHms(key.five_call_average_time)
             : "-"
             }
            </Avg>
            <Avg>
             {key.six_call_average_time
             ? this.secondsToHms(key.six_call_average_time)
             : "-"
             }
            </Avg>
            <Avg>
             {key.seven_call_average_time
             ? this.secondsToHms(key.seven_call_average_time)
             : "-"
             }
            </Avg>
            <Avg>
             {key.eight_call_average_time
             ? this.secondsToHms(key.eight_call_average_time)
             : "-"
             }
            </Avg>
            <Avg>
              {key.nine_call_average_time
              ? this.secondsToHms(key.nine_call_average_time)
              : "-"
              }
            </Avg>
            <Avg>
              {key.ten_call_average_time
              ? this.secondsToHms(key.ten_call_average_time)
              : "-"
              }
            </Avg>
          </div>
          <div className="missed">
           <Zillow>
            <p>Zillow Missed</p>
            <Avg>{key.zillow_missed_calls_count}</Avg>
           </Zillow>
           <Inbound>
            <p>Inbound Missed</p>
            <Avg>{key.inbound_missed_calls_count}</Avg>
           </Inbound>
          </div>
         </CallResponseTimes>
        </InfoBox>
        <InfoBox>
         <CallResponseTimes>
          <p>Text Response Time</p>
          <div className="values">
            <Avg>{key.text_average_time ? this.secondsToHms(key.text_average_time) : "-"}</Avg>
          </div>
         </CallResponseTimes>
        </InfoBox>
       </Card>
      ))}
     </React.Fragment>
    )}
   </React.Fragment>
  )
 }
}

export default DashboardContainer(MetricList)
