import React, { PureComponent } from "react"
import styled from "styled-components"
import moment from "moment"
import {
  Button, Dropdown, Menu, Space, PageHeader,
  Collapse,
  Divider,
  Drawer,
  Tabs,
  Spin,
} from "antd"

import { LoadingOutlined } from "@ant-design/icons"

const { Panel } = Collapse

const { TabPane } = Tabs

const Wrap = styled.div``

const InfoBox = styled.div`
  background: #F9F9F7;
  padding: 15px 20px;
  margin: 5px;
  width: 50%;
`

const InfoBox2 = styled.div`
  background: #F9F9F7;
  padding: 15px 20px;
  margin: 5px;
  display: flex;
  align-items: end;
  justify-content: space-between;
  > div {
    margin-right: 10px;
  }
`

const CashbackStatusBoxContainer = styled.div`
  display: flex;
  ${props => props.fd && `
    flex-direction: ${props.fd};
  `}
`
const Circle = styled.div`
  width: 7px;
  background: #1890FF;
  height: 7px;
  border-radius: 50%;
`
const InfoHeader = styled.div`
  display: flex;
  align-items: baseline;
  p {
    margin: 0 0 7px 10px;
  }
`
const InfoContent = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.06em;
  color: #303030;
  margin: 0;
`
const PanelContainer = styled.div`
  margin-top: 20px;
`

const PanelContent = styled.div`
  .divider {
    margin: 0px;
  }
  border-bottom: 1px solid #E9E9E9;
  margin-top: 30px;
`

const PanelRow = styled.div`
  display: flex;
  padding: 10px 40px 15px 0px;
`

const PanelFlex = styled.div`
  display: flex;
  flex-direction: column;
`

const ColumnHeader = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #0B0A0A;
  text-align: left;
  ${props => props.wd && `
    width: ${props.wd}
  `}
`

const PanelData = styled(ColumnHeader)`
  flex: 1;
  ${props => props.ta && `
    text-align: ${props.ta}
  `}
  color: #6D7E8F;
  .action_button {
    width: 120px;
  }
  ${props => props.text && `
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #6D7E8F;
  `}
`

const CashbackStatusBoxFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

const DateComp = styled.h4`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #6D7E8F;
  margin: 0;
`

const CashbackStatusBox = ({ amount }) => (
  <InfoBox style={{ display: "flex" }}>
    <div style={{ margin: "auto 0" }}>
      <InfoHeader>
        <Circle></Circle>
        <p>Cashback balance</p>
      </InfoHeader>
      <InfoContent>
        {`$${amount.toLocaleString()}`}
      </InfoContent>
    </div>
  </InfoBox>
)

const CashbackStatusBoxFlex = ({ amount, label }) => (
  <InfoBox2>
    <InfoHeader>
      <Circle></Circle>
      <p>{label}</p>
    </InfoHeader>
    <InfoContent>
      {`$${amount.toLocaleString()}`}
    </InfoContent>
  </InfoBox2>
)

const CashbackStatus = ({
  isFetching, cashbackAgentInfoFromId, cashbackAgentStatsFromId, editOpen,
  clickedCashbackListObject,
}) => (
  <Wrap>
    {isFetching ? (
      <Spin style={{ width: "100%", marginTop: "25%" }} indicator={(<LoadingOutlined style={{ fontSize: 44, margin: "30px auto" }} spin />)} />
    ) : (
      <>
        {cashbackAgentStatsFromId && (
          <CashbackStatusBoxContainer fd="row">
            <CashbackStatusBox amount={cashbackAgentStatsFromId.balance_cashback} />
            <CashbackStatusBoxFlexContainer>
              <CashbackStatusBoxFlex label="Approved Cashback" amount={cashbackAgentStatsFromId.total_cashback} />
              <CashbackStatusBoxFlex label="Credited" amount={cashbackAgentStatsFromId.credited_cashback} />
            </CashbackStatusBoxFlexContainer>
          </CashbackStatusBoxContainer>
        )}
        <PanelContainer>
          <h3>
            Pending approvals
          </h3>
          {cashbackAgentInfoFromId && cashbackAgentInfoFromId.length ? cashbackAgentInfoFromId.map((cashbackInfo, index) => (
            <PanelContent>
              <DateComp>
                {moment(cashbackInfo.created_at * 1000).format("MMM DD, YYYY hh:mm A")}
              </DateComp>
              <PanelRow>
                <PanelFlex>
                  <PanelData text>
                    {cashbackInfo.text}
                  </PanelData>
                </PanelFlex>
                <PanelData ta="end">
                  <Button className="action_button" danger type="link" size="medium" onClick={() => editOpen(true, index, "declined")}>
                    Decline
                  </Button>
                  <Button className="action_button" type="primary" ghost size="medium" onClick={() => editOpen(true, index, "approved")}>
                    Approve
                  </Button>
                </PanelData>
              </PanelRow>
            </PanelContent>
          )) : (
            <p style={{ textAlign: "center" }}>No Pending Approvals</p>
          )}
        </PanelContainer>
      </>
    )}
  </Wrap>
)

export default CashbackStatus
