import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  font-size: 10px;
  background: #6dd400;
  color: #FFF;
  margin: 0px 5px;
  padding: 3px;
  border-radius: 3px;

  ${props => props.isExpired && `
    background: red;
  `}
`

class Timer extends React.PureComponent {
  state = {
    timePassed: null,
  }

  componentDidMount() {
    this.TASK = setInterval(() => {
      const { timePassed } = this.state
      if (timePassed > 60) {
        this.setState({
          expire: true,
        })
        clearInterval(this.TASK)
      }
      this.setState({
        timePassed: timePassed + 1,
      })
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.TASK)
  }

  render() {
    const { timePassed, expire } = this.state
    const { count } = this.props
    const currentTime = parseInt((new Date()).getTime() / 1000, 10)
    const isExpired = (currentTime > count)
    return (
      <React.Fragment>
        {timePassed && (
          <Wrap isExpired={isExpired}>
            {expire ? "Expired" : `0: ${timePassed}`}
          </Wrap>
        )}
      </React.Fragment>
    )
  }
}

export default Timer
