import React from "react"
import {
  Table,
  Tag,
  Pagination,
  Button,
  Modal,
} from "antd"
import styled from "styled-components"
import root from "window-or-global"
import { withRouter } from "react-router-dom"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import RARTechContainer from "container/TechAssets"
import ConfirmationModal from "../../Common/ConfirmationModal"
import { serializer, handleDrawerActions } from "../../Common/Utils"

const { confirm } = Modal

const StyledButton = styled.button`
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0px;
  text-align: left;
`
const ResultsFor = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  -webkit-letter-spacing: 0em;
  -moz-letter-spacing: 0em;
  -ms-letter-spacing: 0em;
  letter-spacing: 0em;
  text-align: left;
  font-style: italic;
  color: #2d2d2d;
`

const Wrap = styled.div`
  padding-top: 17px;
  overflow: scroll;
  width: 100%;
  .infoTable {
    th, td {
      border: none;
      font-family: "Rubik", sans-serif;
    }

    th {
      padding: 5px 5px 5px 18px;

    }

    td {
      padding: 10px 5px 10px 18px;
      background: #fafafa;

      &:hover {
        background: #fcfcfc;
      }
     }

    .ant-table-thead tr th {
      background: linear-gradient(0deg, #E5E5E5, #E5E5E5),
      linear-gradient(0deg, #E6E6E6, #E6E6E6);
    }
  }
`

const ResultsTable = (props) => {
  const {
    offers,
    fetchingList,
    deleteInvite,
    history,
    toggleDrawer,
    getConvertedOfferDealActionResponse,
    createContractActionResponse,
  } = props || {}

  const createColumn = () => {
    const columns = [
      {
        title: "Property Address",
        dataIndex: "propertyAddress",
        key: "propertyAddress",
      },
      {
        title: "Offer Price",
        dataIndex: "price",
        key: "price",
      },
      {
        title: "Offer Writing Opt-In",
        dataIndex: "offerWritingOptIn",
        key: "offerWritingOptIn",
      },
      {
        title: "Primary Buyer Agent",
        dataIndex: "primaryBuyerAgentName",
        key: "primaryBuyerAgentName",
      },
      {
        title: "Offer submitted on",
        dataIndex: "offerSubmitDate",
        key: "offerSubmitDate",
      },
      {
        title: "Last Updated",
        dataIndex: "lastUpdated",
        key: "lastUpdated",
      },
      {
        title: "Status",
        key: "tags",
        dataIndex: "tags",
        render: (text, record) => {
          const getColor = (status) => {
            switch (status) {
              case "incomplete":
                return "#D2D8D8"
              case "new_offer":
                return "#F0C3A2"
              case "pending_signatures":
                return "#FAEBA6"
              case "completed":
              case "offer_accepted":
                return "#B3E4C7"
              case "rejected":
                return "#D2D8D8"
              case "cancelled":
                return "#EBBAB5"
              default:
                return "#D2D8D8"
            }
          }

          const getName = (status) => {
            switch (status) {
              case "incomplete":
                return "Incomplete"
              case "new_offer":
                return "New Offer"
              case "pending_signatures":
                return "Pending Signatures"
              case "completed":
                return "Completed"
              case "offer_accepted":
                return "Offer Accepted"
              case "rejected":
                return "Rejected"
              case "cancelled":
                return "Cancelled"
              default:
                return "N/A"
            }
          }

          return (
            <Tag color={getColor(record.currentStatus)} style={{ color: "#303030" }}>
              {getName(record.currentStatus)}
            </Tag>
          )
        },
      },
      {
        title: "Actions",
        dataIndex: "actions",
        render: (text, record) => (
          <Button
            type="primary"
            onClick={() => {
              toggleDrawer({
                bool: true,
                data: record,
              })
            }}
          >
            Edit
          </Button>
        ),
      },
    ]
    return columns
  }

  const serialized = !fetchingList && serializer(offers) || []

  const {
    isFetching: gettingDealId,
  } = getConvertedOfferDealActionResponse || {}

  const {
    isFetching: convertingToContract,
  } = createContractActionResponse || {}

  return (
    <Wrap>
      <Table
        className="infoTable"
        columns={createColumn()}
        dataSource={serialized}
        loading={fetchingList || gettingDealId || convertingToContract}
      >
        <Pagination
          total={serialized.length}
          defaultPageSize={50}
          defaultCurrent={1}
        />
      </Table>
    </Wrap>
  )
}

export default withRouter(RARTechContainer(ResultsTable))
