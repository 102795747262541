import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "RECRUITANDEARN"

export const getBrokerInvitesAction = createSignalAction(BASE, "FETCH_BROKER_INVITES")

export const getBrokerEarningAction = createSignalAction(BASE, "FETCH_BROKER_EARNINGS")

export const toggleInviteAction = createSignalAction(BASE, "TOGGLE_INVITE")

export const updateBrokerEarningsAction = createSignalAction(BASE, "UPDATE_BROKER_EARNINGS")

export const fetchAgentInviteAnalytics = createSignalAction(BASE, "FETCH_AGENT_INVITE_ANALYTICS")

export const createNewInviteAction = createSignalAction(BASE, "CREATE_INVITE_RECORD")

export const deleteInviteAction = createSignalAction(BASE, "DELETE_INVITE_RECORD")

export const fetchInviteByIdAction = createSignalAction(BASE, "FETCH_INVITE_BY_ID")

export const editInviteAction = createSignalAction(BASE, "EDIT_INVITE")

export const toggleInvitedDrawerAction = createSimpleCreator(BASE, "TOGGLE_INVITED_INFO")

export const getReferralsListAction = createSignalAction(BASE, "GET_REFERRALS_LIST")

export const getInviteeTimelineAction = createSignalAction(BASE, "GET_INVITEE_TIMELINE")

export const getSenderTimelinesAction = createSignalAction(BASE, "GET_SENDER_TIMELINE")

export const updateInviteeStatusAction = createSignalAction(BASE, "UPDATE_INVITEE_STATUS")

export const getEarningsListAction = createSignalAction(BASE, "FETCH_EARNINGS")

export const getAgentDetailsActionRAE = createSignalAction(BASE, "FETCH_AGENT_DETAILS")

export const getAgentSpecificDetailAction = createSignalAction(BASE, "GET_AGENT_EARNING_DETAILS")

export const toggleAgentDetailsDrawerAction = createSimpleCreator(BASE, "TOGGLE_AGENT_DRAWER")

export const toggleEditDrawerAction = createSimpleCreator(BASE, "TOGGLE_EDIT_DRAWER")

export const updateEarningsAction = createSignalAction(BASE, "UPDATE_EARNINGS")

export const setSearchSenderAgentAction = createSimpleCreator(BASE, "SET_SENDER_AGENT")

export const setSearchReferralAgentAction = createSimpleCreator(BASE, "SET_REFERRED_AGENT")

export const clearSetSearchValuesAction = createSimpleCreator(BASE, "CLEAR_SEARCH")

export const toggleConfirmationModalAction = createSimpleCreator(BASE, "TOGGLE_CONFIRMATION_MODAL")

export const setConfirmationModalReasonAction = createSimpleCreator(BASE, "SET_REASON_TEXT_IN_MODAL")

export const getTotalRecruitAndEarnStatsAction = createSignalAction(BASE, "GET_TOTAL_STATS_RAE")

export const downloadCSV = createSignalAction(BASE, "DOWNLOAD_CSV")

export const downloadReferralCSV = createSignalAction(BASE, "DOWNLOAD_REFERRAL_CSV")

export const setFilterQueriesAction = createSimpleCreator(BASE, "SET_FILTER_QUERIES")
