import React from "react"
import styled from "styled-components"
import container from "container/AgentComparison"
import { get } from "lodash"
import { notify } from "react-notify-toast"
import Loader from "dumbComponents/common/UI/Loader"
import { makeConferenceCall, removeAgentAPI} from "components/API/CallerApi"
import AgentComparisonHeader from "./AgentComparisonHeader"
import AgentList from "./AgentList"
import SearchModal from "./SearchModal"
import SidePanel from "./FilterPanel"

const Wrap = styled.div`
  background: #fff;
  padding: 20px 30px;
  padding: 30 20px;
  border-radius: 20px;
  min-height: 800px;

  > h3 {
    font-weight: 500;
  }
`

class AgentComparison extends React.PureComponent {
  state = {
    isShowSearchModal: false,
    isShowSidePanel: false,
  }

  componentDidMount() {
    const { getAgentsForComparison, referral, getLMPStatus } = this.props
    const { id: referralId } = referral
    getAgentsForComparison({
      referralId,
    })
    getLMPStatus({
      referralId,
    })
  }

  handleAssignAgent = (agentId, isUnassign) => {
    const {
      assignAgent,
      twillioClientName,
      referral: {
        receiver_client_id,
        id: referralId,
      },
    } = this.props
    assignAgent({
      clientId: receiver_client_id,
      payload: {
        [isUnassign ? "agentIdsToRemove" : "agentIdsToAssign"]: [agentId],
        referral_id: referralId,
        twilio_client_name: twillioClientName,
      },
    })
  }

  handleLiveTransfer = (agentId) => {
    const {
      liveTransfer,
      callSID,
      referral,
      twillioClientName,
    } = this.props
    const { id: referralId, is_isa, conference_agent_id } = referral
    const payload = {
      child_call_sid: callSID,
      referral_id: referralId,
      twilio_client_name: twillioClientName,
      agent_id: agentId,
    }
    if (is_isa) {
      payload.agent_id = conference_agent_id
    }
    makeConferenceCall(payload)
      .then((res) => {
        console.log("Sucessful", res)
      }).catch((err) => {
        notify.show(err.message, "error")
      })
    console.log({ payload })
    // liveTransfer({
    //   agentId,
    // })
  }

  handleRemoveAgentfromList = (agentId) => {
    const { removeFromSuggestions, referral } = this.props
    const { id: referralId } = referral
    removeFromSuggestions({
      referralId,
      payload: {
        agent_id: agentId,
        prospect_status: -1,
        visible: 0,
      },
    })
  }

  handleToggleLMP = () => {
    const { lmpStatusResponse, toggleLMP, referral } = this.props
    const { id: referralId } = referral
    const status = get(lmpStatusResponse, "data.live_on_lmp")
    const payload = {
      live_on_lmp: status ? 0 : 1,
    }
    toggleLMP({
      referralId,
      payload,
    })
  }

  handleReloadProspects = () => {
    const { getAgentsForComparison, referral } = this.props
    const { id: referralId } = referral
    getAgentsForComparison({
      referralId,
      isReloadProspects: true,
    })
  }

  handleSorting = ({ from = "main-list", item, payload }) => {
    const { getAgentsForComparison, referral, searchAgent } = this.props
    const { id: referralId } = referral
    if (from === "main-list") {
      getAgentsForComparison({
        referralId,
        query: "&sortBy=closed_referral_percentage",
      })
    }
    if (from === "search-results") {
      searchAgent({
        isFrom: from,
        payload,
      })
    }
  }

  handleInviteAgent = (agentId, isFromList) => {
    const { inviteAgent, referral } = this.props
    const { id: referralId } = referral
    inviteAgent({
      referralId,
      agentId,
      isFromList,
    })
  }

  handleStarToggle = (agentId, isStar, isSeachModal) => {
    const { toggleStar } = this.props
    toggleStar({
      agentId,
      isSeachModal,
      payload: {
        starred_for_lmp: isStar ? 0 : 1,
      },
    })
  }

  toggleFilter = () => {
    const { isShowSidePanel } = this.state
    this.setState({
      isShowSidePanel: !isShowSidePanel,
    })
  }

  handleFilterSearch = () => {
    const { getAgentsForComparison, referral, filterOptions } = this.props
    const { id: referralId } = referral
    let query = ""
    Object.keys(filterOptions).forEach((key) => {
      query = `${query}&${key}=${filterOptions[key]}`
    })

    getAgentsForComparison({
      referralId,
      query,
    })
  }

  render() {
    const { isShowSearchModal, seachText, isShowSidePanel } = this.state
    const {
      agentRecomendationsResponse,
      searchAgent,
      referral,
      searchAgentResponse,
      callSID,
      lmpStatusResponse,
      setFilterKeys,
      filterOptions,
    } = this.props
    const { id: referralId } = referral
    const { data: agentListData, isFetching } = agentRecomendationsResponse

    let filteredResults = agentListData && agentListData.agents
    if (seachText) {
      filteredResults = agentListData.agents && agentListData.agents.length > 0
        && agentListData.agents
          .filter(({ firstname = "", lastname = "", email = "", phone = "" }) => {
            return ((firstname && firstname.includes(seachText))
            || (lastname && lastname.includes(seachText))
            || (email && email.includes(seachText))
            || (phone && phone.includes(seachText))
            )
          })
    }

    return (
      <Wrap>
        <AgentComparisonHeader
          toggleSearchModal={() => { this.setState({ isShowSearchModal: !isShowSearchModal }) }}
          handleClientFilter={(e) => { this.setState({ seachText: e.target.value }) }}
          seachText={seachText}
          reloadProspects={this.handleReloadProspects}
          sortResults={this.handleSorting}
          lmpStatus={get(lmpStatusResponse, "data.live_on_lmp")}
          toggleStatus={this.handleToggleLMP}
          toggleFilter={this.toggleFilter}
        />

        {isShowSidePanel && (
          <SidePanel
            setFilterKeys={setFilterKeys}
            filterOptions={filterOptions}
            toggleFilter={this.toggleFilter}
            applyFilters={this.handleFilterSearch}
          />
        )}
        {isShowSearchModal && (
          <SearchModal
            searchAgent={searchAgent}
            referralId={referralId}
            searchAgentResponse={searchAgentResponse}
            toClose={() => { this.setState({ isShowSearchModal: !isShowSearchModal }) }}
            sortResults={this.handleSorting}
            inviteAgent={this.handleInviteAgent}
            toggleStarAgent={this.handleStarToggle}
            assignAgent={this.handleAssignAgent}
            liveTransfer={this.handleLiveTransfer}
          />
        )}
        {isFetching ? (
          <div>
            <Loader />
          </div>
        ) : (
          <AgentList
            agents={filteredResults}
            assignAgent={this.handleAssignAgent}
            liveTransfer={this.handleLiveTransfer}
            removeAgentFromSuggestions={this.handleRemoveAgentfromList}
            isAllowedToConnect={!!callSID}
            inviteAgent={this.handleInviteAgent}
            toggleStarAgent={this.handleStarToggle}
          />
        )}
      </Wrap>
    )
  }
}

export default container(AgentComparison)
