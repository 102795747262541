import styled from "styled-components"

export const ActionAreaWrap = styled.div`
  border-top: 1px solid #E8E8FD;
  padding-top: 30px;
`

export const ActionRow = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`
