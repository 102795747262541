import React from 'react';
import './Wizard.css';
const wizardTags = [
  {
    name: "Referred - Contacting",
    stage: 1
  },
  {
    name: "Follow Up - Qualifying",
    stage: 2
  },
  {
    name: "Qualified - Searching",
    stage: 3
  },
  {
    name: "Comparing",
    stage: 4
  },
  {
    name: "Connected",
    stage: 5
  },
];
const wizard = props => (
  <ol className={"track-progress"}>
    {wizardTags.map((tag, index) => (
      <li
        className={(props.currentStage === tag.stage) ? "active" : ""}
        onClick={props.updateStage.bind(this, { stage: tag.stage })}
        key={"wizard-" + index}
      >
        <span>{tag.name}</span>
      </li>
    ))}
  </ol>
);

export default wizard;