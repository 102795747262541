import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import AddCourseMenu from "./AddCourseMenu"
import AddCourseForm from "./AddCourseForm"
import colors from "../../../lib/colors"
import Select from 'react-select';
import { DefaultLoader } from "components/UI/Loader/Loader";
import RTFEditor from "dumbComponents/Academy/Common/RTF"
import RTFEditorIO from "dumbComponents/Academy/Common/EditorRTF"
import _ from 'lodash';

const OuterWrap = styled.div`
 display: flex;
`

const Danger = styled.p`
  color: red;
  font-size: 12px;
`

const SectionHeader = styled.div`
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #9d9d9d;
`

const SectionHeaderText = styled.h1`
  font-size: 16px;
  color: #000;
  margin: 0;
`

const SettingsWrap = styled.div``

const RightMenu = styled.div`
  height: 500px;
  width: 699px;
  padding-left: 20px;

  .access, .testSet {
   display: flex;
   flex-direction: row;
   gap: 10px;
  }

  .rtf {
   margin-top: -50px;
  }
  textarea {
   resize: none;
   padding: 10px;
   font-family: "SF Pro Display", sans-serif;
   width: 724px;
   height: 125px;
   border-radius: 3px;
   border: solid 0.5px #000000;
  }

  .imagePreview {
   margin-top: 50px;
   .preview {
    width: 100%;
    img{
     max-width: 100%;
    }
   }
  }

  .header {
   height: 50px;
   width: 100%;
   p {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin: 0;
   }
  }

  .course-image {
   height: 300px;
   width: 100%;
   .image-header-div {
    display: flex;
    justify-content: space-between;
    button {
     top: 10px;
     height: 30px;
     position: relative;
     left: 50px;
    }
   }
   .title {
    font-size: 14px;
   font-weight: bold;
   color: #24292e;
   }
   .image-placeholder {
    width: 730px;
    height: 242px;
    border-radius: 8px;
    margin-bottom: -97px;

   }
  }

  .instructors-list {
   width: 100%;
   min-height: 100px;
   height: max-content;

   border: 1px solid #e2e4e8;
   .headerDiv {
    height: 33px;
    border-radius: 4px;
    border-bottom: solid 1px #e2e4e8;
    background-color: #f4f5f8;
   }
  }
`

const Remove = styled.button`
  border: none;
  background: transparent;
  padding: 28px;
  font-size: 14px;
  font-weight: 500;
  color: #cb2431;
  cursor: pointer;
`

const SettingLabel = styled.div`
  margin-bottom: 10px;
  p {
    font-size: 14px;
    margin: 0;
  }
`

const ActionButton = styled.button`
  cursor: pointer;
  height: max-content;
  padding: 5px;
  width: max-content;
  color: white;
  border: none;
  margin-top: 5px;

  ${props => props.danger && `
    background: red;
  `}
`

class CourseSettings extends Component {
 state = {
   isUpdateMode: this.props.match.params.id,
 }

 componentDidMount() {
   const {
     getCourseDetails,
     courseDetailResponse,
     accessToCourse,
     setTest,
   } = this.props

   console.log(getCourseDetails)

   const {
     data: courseDetail,
     isFetching: fetchingCourseDetails,
   } = courseDetailResponse || {}

   let courseExists =
  this.props.match.params.id
  && courseDetail
  && Object.keys(courseDetail).length > 0

   if(courseExists) {
     accessToCourse(courseDetail.access_to)
     setTest({
       testOnly: courseDetail.test,
     })
   }

   if(this.props.match.params.id) {
     getCourseDetails({ id: this.props.match.params.id})
   }
 }

 handleDelete = () => {
   const {
     deleteCourse,
   } = this.props
   deleteCourse({
     id: this.props.match.params.id,
   })
 }

 handlePromotedCourse = (e) => {
   const {
     saveSettingsValues,
     isPromoted,
   } = this.props || {}

   saveSettingsValues({
     data: !isPromoted,
     type: e.target.name,
   })
 }

 handleTestOnly = () => {
   const {
     setTest,
     testOnly,
   } = this.props

   setTest({
     testOnly: !testOnly,
   })
 }

 handleAccess = (e) => {
   const {
     accessArray,
     accessToCourse,
   } = this.props

   let access = []
   let curr = e.target.value
   if(accessArray.length > 0) {
     access = [...accessArray]
   }
   // access.push(e.target.value)
   if(access[0] === "registered"){
     access[0] = curr
   } else {
     access[0] = "registered"
   }
   let count = _.countBy(access)
   let uniqAccess = access.filter((value,index) => access.indexOf(value) === index)
   uniqAccess = uniqAccess.filter(value => count[value] === 1 )
   accessToCourse(uniqAccess)
 }

 handleChange = (e) => {
   const {
     saveSettingsValues,
   } = this.props

   this.setState({
     [e.target.name]: e.target.value
   })
   saveSettingsValues({
     data: e.target.value,
     type: e.target.name
   })
 }

 render() {
   const {
     multipleInstructorsResponse,
     instructorArray,
     courseDetailResponse,
     courseReminderPost,
     courseAnnouncementText,
     accessArray,
     testOnly,
     isPromoted,
   } = this.props

   const {
     data: courseDetail,
     isFetching: fetchingCourseDetails,
   } = courseDetailResponse || {}

   let courseExists =
  this.props.match.params.id
  && courseDetail
  && Object.keys(courseDetail).length > 0

   return (
     <React.Fragment>
       {fetchingCourseDetails && !courseDetail ? (
         <OuterWrap>
           <AddCourseMenu
             submitFunc={this.submit}
             isUpdateMode={this.props.match.params.id || ""}
             isPublished={courseDetail && Boolean(courseDetail.published) || false}
           />
           <RightMenu>
             <DefaultLoader height={200} width={200}/>
           </RightMenu>
         </OuterWrap>
       ) : (
         <OuterWrap>
           <AddCourseMenu
             submitFunc={this.submit}
             isUpdateMode={this.props.match.params.id || ""}
             isPublished={courseDetail && Boolean(courseDetail.published) || false}
           />
           <RightMenu>
             <h1>Settings</h1>
             <SectionHeader>
               <h2>Notification Settings</h2>
               <SettingsWrap>
                 <SectionHeaderText>Community Post Text</SectionHeaderText>
                 <textarea
                   onChange={this.handleChange}
                   name="courseAnnouncementText"
                   value={courseAnnouncementText}
                 />
               </SettingsWrap>
               <SettingsWrap>
                 <SectionHeaderText>Notification Text</SectionHeaderText>
                 <textarea
                   onChange={this.handleChange}
                   name="courseReminderPost"
                   value={courseReminderPost}
                 />
               </SettingsWrap>
             </SectionHeader>
             <div>
               <SectionHeader>
                 <SectionHeaderText>Access To</SectionHeaderText>
                 <div className="access">
                   <input checked={accessArray.indexOf("broker") > -1} name="broker" type="checkbox" value="broker" onChange={this.handleAccess} />
                   <label htmlFor="broker">Brokerage plan agents</label>
                 </div>
               </SectionHeader>
               <SectionHeader>
                 <SectionHeaderText>Mark as a test course? (These courses are not made public.)</SectionHeaderText>
                 <div className="testSet">
                   <input checked={testOnly} name="testValue" type="checkbox" onChange={this.handleTestOnly}/>
                   <label htmlFor="broker">Test Course</label>
                 </div>
               </SectionHeader>
               <SectionHeader>
                 <SectionHeaderText>is Promoted?</SectionHeaderText>
                 <div className="testSet">
                   <input
                     checked={isPromoted}
                     name="isPromoted"
                     type="checkbox"
                     onChange={this.handlePromotedCourse}
                   />
                   <label htmlFor="isPromoted">Promoted</label>
                 </div>
               </SectionHeader>
               {this.props.match.params.id && (
                 <div
                   style={{
                     paddingBottom: "16px",
                   }}
                 >
                   <SectionHeader>
                     <SectionHeaderText>Delete this Course ?</SectionHeaderText>
                     <Danger>(This action is irreversible.)</Danger>
                     <ActionButton
                       danger
                       onClick={this.handleDelete}
                     >
                      Delete
                     </ActionButton>
                   </SectionHeader>
                 </div>
               )}
             </div>
           </RightMenu>
         </OuterWrap>
       )}
     </React.Fragment>
   )
 }
}

export default Container(CourseSettings)
