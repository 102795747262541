import { call, put, throttle, takeLatest, select } from "redux-saga/effects"
import * as AcademyActions from "./actions"
import { isSuccess } from "services/Utils"
import { toast } from "react-toastify"
import root from "window-or-global"
import * as APIs from "./api"

const baseUrl = process.env.REACT_APP_API_BASE_URL;

function* handleFetchCourses(action) {
     try {
         const {
          filter,
          id,
         } = action.data || {}
         const nonFilteredAPI = APIs.getCoursesAPI
         const filterAPI = APIs.getCourseListForCategoriesAPI
         let res = false
         if (filter && filter === "categoryIds") {
          res  = yield call(filterAPI, id)
         } else {
          res  = yield call(nonFilteredAPI)
         }
         // const res  = yield call(APIs.getCoursesAPI)
         if (isSuccess(res)) {
             const { response } = res.data
             yield put(AcademyActions.getCoursesAction.success(response))
         }
     } catch(error) {
         alert(error)
         yield put(AcademyActions.getCoursesAction.failure(error))
     }
}

//handleEventsRequest
function* handleEventsRequest(action) {
     try {
      const {
       filter,
      } = action.data
         const nonFilteredAPI = APIs.getAllEventsAPI
         const filterAPI = APIs.getUpcomingEventsAPI
         const res  = yield call(filter && filter === "upcoming" ? filterAPI : nonFilteredAPI)
         if (isSuccess(res)) {
             const { response } = res.data
             yield put(AcademyActions.getAllEventsAction.success(response))
         }
     } catch(error) {
         alert(error)
         yield put(AcademyActions.getAllEventsAction.failure(error))
     }
}


function* handleFetchMultipleInstructors(action) {
     try {
         const res  = yield call(APIs.getMultipleInstructorsAPI)
         if (isSuccess(res)) {
             const { response } = res.data
             yield put(AcademyActions.getMultipleInstructorsAction.success(response))
         }
     } catch(error) {
         alert(error)
         yield put(AcademyActions.getMultipleInstructorsAction.failure(error))
     }
}

function* handleInstructorCreation(action) {
     const {
       payload,
       aboutInstructor,
       currentImageURL,
     } = action.data

     payload.image_url = currentImageURL || ""
     payload.bio = aboutInstructor
     try {
         const res  = yield call(APIs.createInstructorAPI, payload)
         if (isSuccess(res)) {
             const { response } = res.data
             yield put(AcademyActions.createInstructorAction.success(response))
             root.location.href = "/academy-admin/people"
             toast.success("Instructor created")
         }
     } catch(error) {
         yield put(AcademyActions.createInstructorAction.failure(error))
     }
}

function* handleCourseDeletion(action) {
 const {
  id,
 } = action.data
 const payload = {}
 payload.active = false

 try {
     const res  = yield call(APIs.updateCourseAPI, payload, id)
     if (isSuccess(res)) {
         const { response } = res.data
         yield put(AcademyActions.deleteCourseAction.success(response))
         root.location.href = "/academy-admin/courses"
         toast.success("Course Deleted")
     }
 } catch(error) {
     yield put(AcademyActions.deleteCourseAction.failure(error))
 }

}

function* handleUpdateInstructorDetails(action) {
     const {
       payload,
       aboutInstructor,
       existingDetails,
       id,
       imageUpload,
       imageURL,
       currentImageURL,
     } = action.data

     if(!imageUpload) {
      payload.name = payload.name || existingDetails.name
      payload.bio = aboutInstructor || existingDetails.bio
      payload.radius_agent_handle = payload.radius_agent_handle || existingDetails.radius_agent_handle
      payload.image_url = currentImageURL || existingDetails.image_url || ""
      try {
          const res  = yield call(APIs.updateInstructorAPI, payload, id)
          if (isSuccess(res)) {
              const { response } = res.data
              yield put(AcademyActions.updateInstructorAction.success(response))
              yield put(AcademyActions.getInstructorDetailsAction.request({id}))
              toast.success("Updated Instructor Details")
              root.location.href = "/academy-admin/people"
          }
      } catch(error) {
          yield put(AcademyActions.updateInstructorAction.failure(error))
      }
     } else {
      const payload = {}
      payload.name = existingDetails && existingDetails.name
      payload.bio = existingDetails && existingDetails.bio
      payload.email = existingDetails && existingDetails.email
      payload.designation = existingDetails && existingDetails.designation
      payload.firm = existingDetails && existingDetails.firm
      payload.handle = existingDetails && existingDetails.handle
      payload.image_url = currentImageURL || existingDetails.image_url || ""
      payload.radius_agent_handle = existingDetails.radius_agent_handle || ""

      try {
          const res  = yield call(APIs.updateInstructorAPI, payload, id)
          if (isSuccess(res)) {
              const { response } = res.data
              yield put(AcademyActions.updateInstructorAction.success(response))
              yield put(AcademyActions.getInstructorDetailsAction.request({id}))
              toast.success("Updated Instructor Details")
              root.location.href = "/academy-admin/people"
          }
      } catch(error) {
          yield put(AcademyActions.updateInstructorAction.failure(error))
      }
     }
}

function* handleInstructorDeletion(action) {
 const {
  id,
 } = action.data
 const payload = {}
 payload.active = false

  try {
      const res  = yield call(APIs.deleteInstructorAPI, payload, id)
      if (isSuccess(res)) {
          const { response } = res.data
          yield put(AcademyActions.deleteInstructorAction.success(response))
          root.location.href = "/academy-admin/people"
          toast.success("Instructor Deleted")
      }
  } catch(error) {
      yield put(AcademyActions.deleteInstructorAction.failure(error))
  }


}

function* handleCourseCreation(action) {
  try {
   const {
    title,
    aboutCourse,
    lessonBreakdown,
    instructors,
    accessArray,
    currentImageURL,
    lastSequence,
    category_id,
    category_name,
    topic_id,
   } = action.data
   let instructorIDS = []

   instructors && instructors.forEach(inst => {
    instructorIDS.push(inst.id)
   })

   let payload = {}

   payload.title = title
   payload.description = aboutCourse
   payload.Instructors = instructors
   payload.instructor_ids = instructorIDS
   payload.cover_image_url = currentImageURL || ""
   payload.created_at = Date.now()
   payload.active = null
   payload.sequence_number = lastSequence
   payload.category_id = category_id
   payload.category_name = category_name
   payload.published = 0
   payload.lesson_breakdown = lessonBreakdown
   payload.Lessons = null
   payload.Status = "Start" // change this
   payload.test = false
   payload.topic_id = topic_id
   payload.access_to = [
    "registered",
   ]
   payload.total_duration_in_seconds = 0
   payload.TotalLessons = 0
   payload.create_post = null
      const res  = yield call(APIs.createCourseAPI, payload)
      if (isSuccess(res)) {
          const { response } = res.data
          yield put(AcademyActions.createCourseAction.success(response))
          root.location.href = "/academy-admin/courses"
          toast.success("Course Created")
      }
  } catch(error) {
      yield put(AcademyActions.createCourseAction.failure(error))
  }
}

function* handleCourseUpdate(action) {
     const {
      title,
      aboutCourse,
      lessonBreakdown,
      instructors,
      arranged,
      exisitingCourseDetails,
      imageUpload,
      imageURL,
      isDelete,
      courseAnnouncementText,
      courseReminderPost,
      accessArray,
      currentImageURL,
      lastSequence,
      isPublished,
      testOnly,
      topic_id,
      is_promoted,
     } = action.data
     
     console.log("ispromoted", is_promoted)

     if(!imageUpload) {
       let instructorIDS = []

       instructors && instructors.forEach(inst => {
        instructorIDS.push(inst.id)
       })

       let payload = {}

       payload.title = title || exisitingCourseDetails.title
       payload.description = aboutCourse || exisitingCourseDetails.description || null
       payload.Instructors = instructors || exisitingCourseDetails.Instructors
       payload.instructor_ids = instructorIDS && instructorIDS.length > 0 ? instructorIDS : exisitingCourseDetails.instructor_ids
       payload.cover_image_url = currentImageURL || exisitingCourseDetails.cover_image_url || "" //change this
       payload.created_at = exisitingCourseDetails.created_at
       payload.updated_at = Date.now()
       payload.published = Math.floor((Date.now()/1000))
       payload.active = true
       payload.sequence_number = isPublished ? exisitingCourseDetails.sequence_number : lastSequence
       payload.category_id = exisitingCourseDetails.category_id
       payload.category_name = exisitingCourseDetails.category_name
       payload.lesson_breakdown = lessonBreakdown || exisitingCourseDetails.lessonBreakdown || ""
       payload.Lessons = arranged && arranged.length > 0 ? arranged : exisitingCourseDetails.Lessons
       payload.Status = exisitingCourseDetails.Status || "Start" // change this
       payload.access_to = accessArray || exisitingCourseDetails.access_to
       payload.community_post_text = courseAnnouncementText || exisitingCourseDetails.community_post_text || ""
       payload.push_notification_text = courseReminderPost || exisitingCourseDetails.push_notification_text || ""
       payload.total_duration_in_seconds = 0 //change
       payload.TotalLessons = 1
       payload.is_promoted = is_promoted
       payload.topic_id = exisitingCourseDetails.topic_id || topic_id
       && exisitingCourseDetails.Lessons.length
       || 0
       payload.test = testOnly
       payload.create_post = exisitingCourseDetails.create_post ||  null
       const courseID = exisitingCourseDetails.id
       try {
           const res  = yield call(APIs.updateCourseAPI, payload, courseID)
           if (isSuccess(res)) {
               const { response } = res.data
               yield put(AcademyActions.updateCourseAction.success(response))
               toast.success("Course Updated")
               yield put(AcademyActions.getCourseDetailsAction.request({
                id: courseID,
               }))
               yield put(AcademyActions.batchUpdateLessonsAction.request({
                payload: arranged && arranged.length > 0 ? arranged : exisitingCourseDetails.Lessons,
               }))

               setTimeout(() => {
                root.location.href = "/academy-admin/courses"
               }, 2000)
           }
       } catch(error) {
           yield put(AcademyActions.updateCourseAction.failure(error))
       }
     } else {
      try {
        const courseID = exisitingCourseDetails.id
        const payload = {}
        payload.title = exisitingCourseDetails.title
        payload.description = exisitingCourseDetails.description || null
        payload.Instructors = exisitingCourseDetails.instructors
        payload.instructor_ids = exisitingCourseDetails.instructor_ids
        payload.cover_image_url = imageURL
        payload.created_at = exisitingCourseDetails.created_at
        payload.updated_at = Date.now()
        payload.published = exisitingCourseDetails.published
        payload.active = exisitingCourseDetails.active
        payload.lesson_breakdown = exisitingCourseDetails.lessonBreakdown
        payload.Lessons = exisitingCourseDetails.Lessons
        payload.Status = exisitingCourseDetails.Status
        payload.access_to = exisitingCourseDetails.access_to
        payload.total_duration_in_seconds = exisitingCourseDetails.total_duration_in_seconds
        payload.TotalLessons = exisitingCourseDetails.Lessons
        && exisitingCourseDetails.Lessons.length
        || 0
        payload.community_post_text = exisitingCourseDetails.community_post_text || ""
        payload.push_notification_text = exisitingCourseDetails.push_notification_text || ""
        payload.create_post = exisitingCourseDetails.create_post
        const res  = yield call(APIs.updateCourseAPI, payload, courseID)
        if (isSuccess(res)) {
            const { response } = res.data
            yield put(AcademyActions.updateCourseAction.success(response))
            toast.success("Course Updated")
            yield put(AcademyActions.batchUpdateLessonsAction.request({
             payload: arranged && arranged.length > 0 ? arranged : exisitingCourseDetails.Lessons,
            }))
        }
      } catch(error) {
          yield put(AcademyActions.updateCourseAction.failure(error))
      }
     }

}

function* handleDetailRequest(action) {
     const {
       id,
     } = action.data
     try {
         const res  = yield call(APIs.getCourseDetailsAPI, id)
         if (isSuccess(res)) {
             const { response } = res.data
             console.log(response)
             const categoryID = response.category_id
             const currAnnouncement = yield select(state => state.academy.courseAnnouncementText)
             const currNotif = yield select(state => state.academy.courseReminderPost)
             yield put(AcademyActions.getCourseListingForCategoryAction.request({ id: categoryID }))
             yield put(AcademyActions.getCourseDetailsAction.success(response))
             yield put(AcademyActions.saveSettingsValuesAction.call({
                 data: response.is_promoted,
                 type: "isPromoted",
             }))
             yield put(AcademyActions.saveSettingsValuesAction.call({
              data: response.community_post_text || currAnnouncement ,
              type: "courseAnnouncementText",
             }))
             yield put(AcademyActions.saveSettingsValuesAction.call({
              data: response.push_notification_text || currNotif,
              type: "courseReminderPost",
             }))
         }
     } catch(error) {
         yield put(AcademyActions.getCourseDetailsAction.failure(error))
     }
}

//handleFetchEventDetails

function* handleFetchEventDetails(action) {
     const {
       id,
     } = action.data
     try {
         const res  = yield call(APIs.getEventDetailsAPI, id)
         if (isSuccess(res)) {
             const { response } = res.data

             yield put(AcademyActions.setIsPrivateEventAction.call({
              isPrivate: response.third_party_event,
             }))

             yield put(AcademyActions.saveSettingsValuesAction.call({
              data: response.first_announcement_push_text,
              type: "eventAnnouncementText",
             }))
             yield put(AcademyActions.saveSettingsValuesAction.call({
              data: response.community_post_text,
              type: "communityEventPostText",
             }))
             yield put(AcademyActions.saveSettingsValuesAction.call({
              data: response.reminder_push_text,
              type: "eventReminderPost",
             }))
             yield put(AcademyActions.saveSettingsValuesAction.call({
              data: response.feedback_push_text,
              type: "feedbackGatheringPushNotificationText",
             }))

             if(response.type === "virtual") {
              yield put(
               AcademyActions.currentEventTypeAction.call({
                currentEvent: [{
                 value: "virtual",
                 label: "Virtual",
                }]
               })
              )
             } else {
              yield put(
               AcademyActions.currentEventTypeAction.call({
                currentEvent: [{
                 value: "in-person",
                 label: "In Person",
                }]
               })
              )
             }

             yield put(AcademyActions.getCurrentEventDetailsAction.success(response))
         }
     } catch(error) {
         yield put(AcademyActions.getCurrentEventDetailsAction.failure(error))
     }
}

function* handleFetchInstructorDetails(action) {
     const {
       id,
     } = action.data
     try {
         const res  = yield call(APIs.fetchInstructorDetailsAPI, id)
         if (isSuccess(res)) {
             const { response } = res.data
             yield put(AcademyActions.getInstructorDetailsAction.success(response))
         }
     } catch(error) {
         yield put(AcademyActions.getInstructorDetailsAction.failure(error))
     }
}

function* handleLessonCreation(action) {
     const {
       payload,
     } = action.data
     try {
         const res  = yield call(APIs.createLessonAPI, payload)
         if (isSuccess(res)) {
             const { response } = res.data
             yield put(AcademyActions.createLessonAction.success(response))
             root.location.href = `/academy-admin/courses/course/${payload.course_id}/lessons`
             toast.success("Lesson Created")
         }
     } catch(error) {
         yield put(AcademyActions.createLessonAction.failure(error))
     }
}

function* handleEventCreation(action) {
     const {
       payload,
     } = action.data
     try {
         const res  = yield call(APIs.createEventAPI, payload)
         if (isSuccess(res)) {
             const { response } = res.data
             yield put(AcademyActions.createEventAction.success(response))
             root.location.href = `/academy-admin/events`
             toast.success("Event Created")
         }
     } catch(error) {
         yield put(AcademyActions.createEventAction.failure(error))
     }
}

//handleEventUpdatation

function* handleEventUpdatation(action) {
     const {
       payload,
       id,
     } = action.data
     try {
         const res  = yield call(APIs.updateEventAPI, payload, id)
         if (isSuccess(res)) {
             const { response } = res.data
             yield put(AcademyActions.updateEventAction.success(response))
             root.location.href = `/academy-admin/events`
             toast.success("Event Updated")
         }
     } catch(error) {
         yield put(AcademyActions.updateEventAction.failure(error))
     }
}

function* handleEventDeletion(action) {
     const {
       id,
     } = action.data
     const payload = {}
     payload.active = false
     try {
         const res  = yield call(APIs.deleteEventAPI, payload, id)
         if (isSuccess(res)) {
             const { response } = res.data
             yield put(AcademyActions.deleteEventAction.success(response))
             root.location.href = `/academy-admin/events`
             toast.success("Event Deleted")
         }
     } catch(error) {
         yield put(AcademyActions.deleteEventAction.failure(error))
     }
}

function* handleLessonUpdateRequest (action) {
 const {
   payload,
   id,
 } = action.data
 try {
     const res  = yield call(APIs.updateLessonAPI, payload, id)
     if (isSuccess(res)) {
         const { response } = res.data
         yield put(AcademyActions.updateLessonDetailsAction.success(response))
         root.location.href = `/academy-admin/courses/course/${payload.course_id}/lessons`
         toast.success("Lesson Updated")
     }
 } catch(error) {
     yield put(AcademyActions.updateLessonDetailsAction.failure(error))
 }
}

//handleLessonDeleteRequest
function* handleLessonDeleteRequest (action) {
 const {
   id,
   course_id,
 } = action.data
 const payload = {}
 payload.active = false
 try {
     const res  = yield call(APIs.deleteLessonAPI, payload, id)
     if (isSuccess(res)) {
         const { response } = res.data
         yield put(AcademyActions.deleteLessonAction.success(response))
         root.location.href = `/academy-admin/courses/course/${course_id}/lessons`
         toast.success("Lesson Updated")
     }
 } catch(error) {
     yield put(AcademyActions.deleteLessonAction.failure(error))
 }
}

function* handeCourseHeaderImageUpload(action) {
 try {
  const {
   file,
   id,
  } = action.data

  const fd = new FormData()
   fd.append("imageFile", file)
   const data = {
     filePath: `academy/${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`,
     config: {
       bucket: "course",
       convertTo: "jpeg",
     },
   }
   fd.append("requestBody", JSON.stringify(data))

   const imageRes = yield call(APIs.uploadImageAPI, fd)

   if (isSuccess(imageRes)) {
       const {
        data: {
         response: {
          imageURL,
         }
        }
       } = imageRes
       // yield put(AcademyActions.updateCourseAction.request({
       //  imageUpload: true,
       //  exisitingCourseDetails,
       //  imageURL,
       // }))
       toast.success("Image uploaded")
       toast.success("Click on Draft/Publish Course")
       yield put(AcademyActions.currentImageURLSetAction.call({currentImageURL: imageURL}))
       yield put(AcademyActions.setCurrentCourseHeaderImageAction.success(imageRes))
   }

 } catch(e) {
   yield put(AcademyActions.setCurrentCourseHeaderImageAction.failure(e))

 }

}

function* handleUpdateProfileImage(action) {
 try {
  const {
   file,
   id,
  } = action.data

  const fd = new FormData()
   fd.append("imageFile", file)
   const data = {
     filePath: `academy/${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`,
     config: {
       bucket: "instructor",
       convertTo: "jpeg",
     },
   }
   fd.append("requestBody", JSON.stringify(data))

   const imageRes = yield call(APIs.uploadImageAPI, fd)

   if (isSuccess(imageRes)) {
       const {
        data: {
         response: {
          imageURL,
         }
        }
       } = imageRes
       // yield put(AcademyActions.updateInstructorAction.request({
       //  imageUpload: true,
       //  existingDetails,
       //  imageURL,
       //  id,
       // }))
       toast.success("Image uploaded")
       toast.success("Click on Add/Update Instructor")
       yield put(AcademyActions.currentImageURLSetAction.call({currentImageURL: imageURL}))
       yield put(AcademyActions.setCurrentProfileImageAction.success(imageURL))
   }

 } catch(e) {
   yield put(AcademyActions.setCurrentProfileImageAction.failure(e))

 }

}

function* handleBatchLessonUpdate(action) {
 const {
   payload,
 } = action.data
 try {
     const res  = yield call(APIs.batchUpdateLessonAPI, payload)
     if (isSuccess(res)) {
         const { response } = res.data
         yield put(AcademyActions.batchUpdateLessonsAction.success(response))
         toast.success("Lesson Sequence Updated")
     }
 } catch(error) {
     yield put(AcademyActions.batchUpdateLessonsAction.failure(error))
 }
}

function* handleFetchGlobalNotifEvents() {
 try {
     const res  = yield call(APIs.getAllEventsNotificationAPI)
     if (isSuccess(res)) {
         const { response } = res.data
         yield put(AcademyActions.getGlobalEventNotificationAction.success(response))
         yield put(AcademyActions.saveSettingsValuesAction.call({
          data: response.title,
          type: "academyDailyEventSummaryNotification",
         }))
     }
 } catch(error) {
     yield put(AcademyActions.getGlobalEventNotificationAction.failure(error))
 }
}

function* handleSetGlobalNotifEvents(action) {
 const {
  payload,
 } = action.data
 try {
     const res  = yield call(APIs.setAllEventsNotificationAPI, payload)
     if (isSuccess(res)) {
         const { response } = res.data
         yield put(AcademyActions.setGlobalEventNotificationAction.success(response))
         toast.success("Notification Updated")
     }
 } catch(error) {
     yield put(AcademyActions.setGlobalEventNotificationAction.failure(error))
 }
}

function* handleFetchCategories() {
 try {
     const res  = yield call(APIs.getAllCategoriesAPI)
     if (isSuccess(res)) {
         const { response } = res.data
         yield put(AcademyActions.getCategoriesAction.success(response))
     }
 } catch(error) {
     yield put(AcademyActions.getCategoriesAction.failure(error))
 }
}

function* handleFetchCategoryDetail(action) {
 const {
  id,
 } = action.data
 try {
     const res  = yield call(APIs.getCategoryDetailsAPI, id)
     if (isSuccess(res)) {
         const { response } = res.data
         yield put(AcademyActions.getCategoryDetailAction.success(response))
     }
 } catch(error) {
     yield put(AcademyActions.getCategoryDetailAction.failure(error))
 }
}

function* handleUpdateCategoryDetail(action) {
  try {
      const {
       payload,
       id,
      } = action.data
      const res  = yield call(APIs.updateCategoryDetailsAPI, id, payload)
      if (isSuccess(res)) {
          const { response } = res.data
          yield put(AcademyActions.getCategoryDetailAction.success(response))
          toast.success("Category Updated")
          root.location.href = `/academy-admin/categories`
      }
  } catch(error) {
      yield put(AcademyActions.getCategoryDetailAction.failure(error))
  }
}

function* handleCategoryCreation(action) {
 try {
     const {
      payload,
     } = action.data
     const res  = yield call(APIs.createCategoryAPI, payload)
     if (isSuccess(res)) {
         const { response } = res.data
         yield put(AcademyActions.createCategoryAction.success(response))
         toast.success("Category Created")
         root.location.href = `/academy-admin/categories`
     }
 } catch(error) {
     yield put(AcademyActions.createCategoryAction.failure(error))
 }
}

function* handleFetchCourseListingForCategory(action) {
 try {
     const {
      id,
     } = action.data
     const res  = yield call(APIs.getCourseListForCategoriesAPI, id)
     if (isSuccess(res)) {
         const { response } = res.data
         yield put(AcademyActions.getCourseListingForCategoryAction.success(response))
     }
 } catch(error) {
     yield put(AcademyActions.getCourseListingForCategoryAction.failure(error))
 }
}

function* handleBatchUpdateForCourse(action) {
 try {
     const {
      payload,
     } = action.data
     const res  = yield call(APIs.updateCourseBatchAPI, payload)
     if (isSuccess(res)) {
         const { response } = res.data
         yield put(AcademyActions.batchUpdateForCoursesAction.success(response))
         toast.success("Course Rearranged")
         root.location.href = "/academy-admin/categories"
     }
 } catch(error) {
     yield put(AcademyActions.batchUpdateForCoursesAction.failure(error))
 }
}

function* handleEventImageUpdate(action) {
 try {
  const {
   file,
   id,
   exisitingEventDetails: existingDetails,
  } = action.data

  const fd = new FormData()
   fd.append("imageFile", file)
   const data = {
     filePath: `academy/${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`,
     config: {
       bucket: "event",
       convertTo: "jpeg",
     },
   }
   fd.append("requestBody", JSON.stringify(data))

   const imageRes = yield call(APIs.uploadImageAPI, fd)

   if (isSuccess(imageRes)) {
       const {
        data: {
         response: {
          imageURL,
         }
        }
       } = imageRes
       // delete existingDetails.id
       // existingDetails.cover_image_url = imageURL
       // yield put(AcademyActions.updateEventAction.request({
       //  payload: existingDetails,
       //  id,
       // }))
       // toast.success("Image uploaded")
       toast.success("Image uploaded")
       toast.success("Click on Publish Event")
       yield put(AcademyActions.currentImageURLSetAction.call({currentImageURL: imageURL}))
       yield put(AcademyActions.setCurrentEventHeaderImageAction.success(imageURL))
   }

 } catch(e) {
   yield put(AcademyActions.setCurrentEventHeaderImageAction.failure(e))

 }

}


export default function* main() {
    yield takeLatest(AcademyActions.getCoursesAction.REQUEST, handleFetchCourses)
    yield takeLatest(AcademyActions.getMultipleInstructorsAction.REQUEST, handleFetchMultipleInstructors)
    yield takeLatest(AcademyActions.createInstructorAction.REQUEST, handleInstructorCreation)
    yield takeLatest(AcademyActions.createCourseAction.REQUEST, handleCourseCreation)
    yield takeLatest(AcademyActions.getCourseDetailsAction.REQUEST, handleDetailRequest)
    yield takeLatest(AcademyActions.getAllEventsAction.REQUEST, handleEventsRequest)
    yield takeLatest(AcademyActions.createLessonAction.REQUEST, handleLessonCreation)
    yield takeLatest(AcademyActions.updateCourseAction.REQUEST, handleCourseUpdate)
    yield takeLatest(AcademyActions.setCurrentCourseHeaderImageAction.REQUEST, handeCourseHeaderImageUpload)
    yield takeLatest(AcademyActions.getInstructorDetailsAction.REQUEST, handleFetchInstructorDetails)
    yield takeLatest(AcademyActions.updateInstructorAction.REQUEST, handleUpdateInstructorDetails)
    yield takeLatest(AcademyActions.setCurrentProfileImageAction.REQUEST, handleUpdateProfileImage)
    yield takeLatest(AcademyActions.deleteCourseAction.REQUEST, handleCourseDeletion)
    yield takeLatest(AcademyActions.deleteInstructorAction.REQUEST, handleInstructorDeletion)
    yield takeLatest(AcademyActions.updateLessonDetailsAction.REQUEST, handleLessonUpdateRequest)
    yield takeLatest(AcademyActions.deleteLessonAction.REQUEST, handleLessonDeleteRequest)
    yield takeLatest(AcademyActions.createEventAction.REQUEST, handleEventCreation)
    yield takeLatest(AcademyActions.deleteEventAction.REQUEST, handleEventDeletion)
    yield takeLatest(AcademyActions.updateEventAction.REQUEST, handleEventUpdatation)
    yield takeLatest(AcademyActions.getCurrentEventDetailsAction.REQUEST, handleFetchEventDetails)
    yield takeLatest(AcademyActions.batchUpdateLessonsAction.REQUEST, handleBatchLessonUpdate)
    yield takeLatest(AcademyActions.setCurrentEventHeaderImageAction.REQUEST, handleEventImageUpdate)
    yield takeLatest(AcademyActions.getGlobalEventNotificationAction.REQUEST, handleFetchGlobalNotifEvents)
    yield takeLatest(AcademyActions.setGlobalEventNotificationAction.REQUEST, handleSetGlobalNotifEvents)
    yield takeLatest(AcademyActions.getCategoriesAction.REQUEST, handleFetchCategories)
    yield takeLatest(AcademyActions.getCategoryDetailAction.REQUEST, handleFetchCategoryDetail)
    yield takeLatest(AcademyActions.updateCategoryDetailAction.REQUEST, handleUpdateCategoryDetail)
    yield takeLatest(AcademyActions.createCategoryAction.REQUEST, handleCategoryCreation)
    yield takeLatest(AcademyActions.getCourseListingForCategoryAction.REQUEST, handleFetchCourseListingForCategory)
    yield takeLatest(AcademyActions.batchUpdateForCoursesAction.REQUEST, handleBatchUpdateForCourse)
}
