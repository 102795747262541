import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { get, isEmpty } from "lodash"
import { ToastContainer } from "react-toastify"
import globalContainer from "container/BrokerDashboard/globalPagesContainer"
import agentSearchContainer from "container/BrokerDashboard/agentSearchContainer"
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { Col, Select, Row, Button, Collapse } from "antd"
import Loader from "dumbComponents/common/UI/Loader"
import moment from "moment-timezone"
import NumberFormat from "react-number-format"
import { SALES_REPRESENTATIVE } from "dumbComponents/BrokerDashboard/GlobalPages/Resources/data"

const PAGE_SIZE = 30

const Wrap = styled.div``

const WrapAgent = styled.div`
  position: relative;
  width: 300px;
`

//check here
const HeaderDiv = styled.div`
  margin: 20px;
  margin-left: 0px;
  h1 {
    font-weight: 500;
  }
`
const AgentList = styled.div`
  position: absolute;
  width: 300px;
  top: 100px;
  left: 0px;
  right: 0px;
  background: #fff;
  border-radius: 10px;
  z-index: 1000;
  height: max-content;

  ${props => props.hasAgents && `
     border: 1px solid #ccc;
   `}

   ${props => props.hideDropdown && `
     display: none;
    `}
`

const AgentItem = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  &:hover {
    background: #eee;
    transition-duration: 0.3s;
    cursor: pointer;
  }
  > div {
    &:nth-child(1) {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
      background: #eee;
      > img {
        width: 100%;
      }
    }
    &:nth-child(2) {
      margin-left: 20px;
      p, h6 {
        padding: 0px;
        margin: 0px;
      }
      h6 {
        font-weight: 400;
        color: #999;
        font-size: 12px;
      }
      > input {
        color: #999;
        border: 10px;
        font-size: 12px;
        pointer-events: none;
        padding: 0px;
        background: transparent;
      }
    }
  }
`

const MaxMinWrapper = styled.div`
  margin: 0px 8px 16px 8px;
  h6 {
    margin: 0px 0px 6px 10px;
    font-size: 16px;
  }
  > div {
    display: flex;
    input {
      margin: 0px 5px;
      padding: 11px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  div[class*="-container"] {
    width: 150px;
    z-index: 101;
  }

  &.source-container {
    div[class*="-container"] {
      width: 200px;
      z-index: 103;
    }
    div[class*="-control"] {
      width: 200px;
    }
  }

  &.client-type-container {
    div[class*="-container"] {
      width: 200px;
      z-index: 103;
    }
    div[class*="-control"] {
      width: 200px;
    }
  }

  &.agent-type-container {
    div[class*="-container"] {
      width: 200px;
      z-index: 102;
    }
    div[class*="-control"] {
      width: 200px;
    }
  }

  &.transaction-type-container {
    
    div[class*="-container"] {
      width: 250px;
      z-index: 101;
    }
    div[class*="-control"] {
      width: 250px;
    }
  }

  &.deal-status-container {
    div[class*="-container"] {
      width: 250px;
    }
    div[class*="-control"] {
      width: 250px;
    }
  }

  .DayPickerInput {
    input {
      width: 100px;
    }
  }

  .DayPickerInput-OverlayWrapper {
    z-index: 104;
  }
`
const Input = styled.input`
  width: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`

const LISTING_STATUS = [
  { label: "Incomplete", value: "incomplete" },
  { label: "Listing Verified", value: "listing_verified" },
  { label: "On Market", value: "active_listing" },
  { label: "Withdrawn", value: "listing_canceled" },
  { label: "Expired", value: "listing_expired" },
  { label: "Coming Soon", value: "coming_soon" },
  { label: "Pending", value: "pending" },
]

const TC_STATE = [
  { label: "All States", value: "all-states" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Texas", value: "Texas" },
  { label: "Florida", value: "Florida" },
  { label: "Washington", value: "Washington" },
  { label: "Georgia", value: "Georgia" },
  { label: "Oregon", value: "Oregon" },
  { label: "Others", value: "others" },
]

const CLIENT_TYPE_LIST = [
  { label: "Seller", value: "seller" },
  { label: "Buyer", value: "buyer" },
  { label: "Landlord", value: "landlord" },
  { label: "Tenant", value: "tenant" },
  { label: "Dual", value: "dual" },
  { label: "Other", value: "other" },
]

const clearState = {
  isShowActive: false,
  searchText: "",
  searchId: "",
  clientType: null,
  teamId: null,
  sourceType: null,
  planCategory: null,
  agent_state: null,
  property_state: null,
  tc_id: null,
  listingAddress: "",
  transaction_type: null,
  radius_transaction_type: null,
  fromAcceptance: undefined,
  toAcceptance: undefined,
  fromLicenseTransferredDate: undefined,
  toLicenseTransferredDate: undefined,
  dealType: null,
  viewContract: null,
  fromSubmission: undefined,
  toSubmission: undefined,
  fromListing: undefined,
  toListing: undefined,
  isShowActiveListing: false,
  closedFromDate: undefined,
  closedToDate: undefined,
  primarySaleRepresentative: "",
  secondarySaleRepresentative: "",
  sortData: {
    createdAtSortOrder: false,
    rowCreatedAtSortOrder: false,
  },
}

const ListingsFilters = (props) => {
  const {
    tcOverviewListing,
    getAllDealsListing,
    toggleAgentList,
    isAgentListDropdownVisible,
    agents,
    rosterFetchAgentSearch,
  } = props

  const { isFetching: isFetchingName, data: dataAgent } = agents || {}
  const agentsLists = get(dataAgent, "agents", [])

  const [initState, setInitState] = useState({ ...clearState })

  const getFilterParams = () => {
    const payload = {
      ...initState,
    }
    return payload
  }

  const handleSearchType = (value) => {
    setInitState({ ...initState, searchText: value })
    rosterFetchAgentSearch({ search: value })
  }

  const searchHandler = () => {
    tcOverviewListing({ ...getFilterParams() })
    getAllDealsListing({
      ...getFilterParams(),
      limit: PAGE_SIZE,
      type: "global",
      skip: 0,
      transactionType: "Listing",
    })
  }
  const clearFilterData = () => {
    setInitState({ ...clearState })
    getAllDealsListing({
      limit: PAGE_SIZE,
      type: "global",
      skip: 0,
      transactionType: "Listing",
    })
    tcOverviewListing({ })
  }
  return (
    <Wrap>
      <Row gutter={10}>
        <Col flex={1}>
          <Collapse defaultActiveKey={["0"]}>
            <Collapse.Panel key="1" header="Filters">
              <HeaderDiv style={{ margin: 0, zIndex: 2 }}>
                <Row>
                  <Col>
                    <MaxMinWrapper className="agent-type-container">
                      <h6>Property Address</h6>
                      <Input
                        type="text"
                        value={initState.listingAddress}
                        placeholder="Street, City, State"
                        onChange={(e) => { setInitState({ ...initState, listingAddress: e.target.value }) }}
                      />
                    </MaxMinWrapper>
                  </Col>
                  <WrapAgent>
                    <h4>Agent Name Or Email</h4>
                    <div>
                      <Input
                        style={{ width: 300 }}
                        value={initState.searchText}
                        placeholder="Search agent"
                        onChange={(e) => {
                          toggleAgentList(true)
                          handleSearchType(e.target.value)
                        }}
                      />
                      <AgentList
                        hideDropdown={!isAgentListDropdownVisible}
                        hasAgents={agentsLists && agentsLists.length > 0}
                      >
                        {isFetchingName ? (
                          <Loader width="25px" top={0} bottom={0} />
                        ) : (
                          <React.Fragment>
                            {initState.searchText && agentsLists && agentsLists.length > 0 && agentsLists.map(agent => (
                              <AgentItem
                                key={agent.id}
                                onClick={() => {
                                  setInitState({ ...initState, searchId: agent.id, searchText: agent.email })
                                  toggleAgentList(false)
                                  // setSearchActive(true)
                                }}
                              >
                                <div>
                                  <img src={agent.agent_image} alt="" />
                                </div>
                                <div>
                                  <p>
                                    {`${agent.firstname} ${agent.lastname}`}
                                  </p>
                                  <h6>
                                    {agent.email}
                                  </h6>
                                  <NumberFormat format="+1 (###) ###-####" mask="_" value={agent.phone} />
                                </div>
                              </AgentItem>
                            ))}
                          </React.Fragment>
                        )}
                      </AgentList>
                    </div>
                  </WrapAgent>
                  <Col>
                    <MaxMinWrapper className="agent-type-container">
                      <h6>Agent State</h6>
                      <Select
                        isMulti={false}
                        style={{ zIndex: 100, margin: "0px 20px" }}
                        options={TC_STATE}
                        placeholder="Select State"
                        onChange={(value) => { setInitState({ ...initState, agent_state: value }) }}
                        value={initState.agent_state}
                      />
                    </MaxMinWrapper>
                  </Col>
                  <Col>
                    <MaxMinWrapper className="agent-type-container">
                      <h6>Property State</h6>
                      <Select
                        isMulti={false}
                        style={{ zIndex: 100, margin: "0px 20px" }}
                        options={TC_STATE}
                        placeholder="Select State"
                        onChange={(value) => { setInitState({ ...initState, property_state: value }) }}
                        value={initState.property_state}
                      />
                    </MaxMinWrapper>
                  </Col>
                  <Col>
                    <MaxMinWrapper className="agent-type-container">
                      <h6>Status</h6>
                      <Select
                        isMulti={false}
                        style={{ zIndex: 100, margin: "0px 20px" }}
                        options={LISTING_STATUS}
                        placeholder="Select State"
                        onChange={(value) => { setInitState({ ...initState, dealType: value }) }}
                        value={initState.dealType}
                      />
                    </MaxMinWrapper>
                  </Col>
                  <Col>
                    <MaxMinWrapper style={{ zIndex: 100 }}>
                      <h6>Primary Sales Representative</h6>
                      <div>
                        <Select
                          isMulti={false}
                          style={{ width: 200, zIndex: 100, margin: "0px 10px" }}
                          options={SALES_REPRESENTATIVE}
                          value={initState.primarySaleRepresentative}
                          placeholder="Select"
                          onChange={(value) => {
                            setInitState({
                              ...initState,
                              primarySaleRepresentative: value,
                            })
                          }}
                        />
                      </div>
                    </MaxMinWrapper>
                  </Col>
                  <Col>
                    <MaxMinWrapper style={{ zIndex: 100 }}>
                      <h6>Secondary Sales Representative</h6>
                      <div>
                        <Select
                          isMulti={false}
                          style={{ width: 200, zIndex: 100, margin: "0px 10px" }}
                          options={SALES_REPRESENTATIVE}
                          value={initState.secondarySaleRepresentative}
                          placeholder="Select"
                          onChange={(value) => {
                            setInitState({
                              ...initState,
                              secondarySaleRepresentative: value,
                            })
                          }}
                        />
                      </div>
                    </MaxMinWrapper>
                  </Col>
                  <Col>
                    <MaxMinWrapper style={{ marginRight: 20 }}>
                      <h6>Listing Submission Date</h6>
                      <div>
                        <DayPickerInput
                          type="text"
                          placeholder="From"
                          style={{ width: 100, marginRight: 20 }}
                          value={initState.fromListing}
                          onDayChange={(day) => {
                            try {
                              setInitState({ ...initState, fromListing: moment(day.setHours(0, 0, 0)).format("YYYY-MM-DD HH:MM:SS") })
                            } catch (error) {
                              setInitState({ ...initState, fromListing: undefined })
                            }
                          }}
                        />
                        <DayPickerInput
                          type="text"
                          style={{ width: 100 }}
                          placeholder="To"
                          value={initState.toListing}
                          onDayChange={(day) => {
                            try {
                              setInitState({ ...initState, toListing: moment(day.setHours(0, 0, 0)).format("YYYY-MM-DD HH:MM:SS") })
                            } catch (error) {
                              setInitState({ ...initState, toListing: undefined })
                            }
                          }}
                        />
                      </div>
                    </MaxMinWrapper>
                  </Col>
                </Row>
                <Col>
                  <Button style={{ marginTop: 33 }} type="primary" size="large" onClick={searchHandler}>
                    Filter
                  </Button>
                  <Button style={{ marginTop: 33 }} type="link" size="large" onClick={clearFilterData}>
                    Clear
                  </Button>
                </Col>
                <br />
                <Row>
                  <Col flex={1}>
                  </Col>
                </Row>
              </HeaderDiv>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    </Wrap>
  )
}

export default agentSearchContainer(globalContainer(ListingsFilters))
