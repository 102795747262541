import queryString from "query-string"
import root from "window-or-global"
import { get } from "lodash"
// import { gapi } from "gapi-script"

const getDealId = () => {
  const search = get(root, "location.search", {})
  const parsedQuery = queryString.parse(search)

  const {
    dealId,
  } = parsedQuery || 0

  return dealId || 0
}

const getAgentId = () => {
  const search = get(root, "location.search", {})
  const parsedQuery = queryString.parse(search)

  const {
    agentId,
  } = parsedQuery

  return agentId || null
}

/**
 * Common Util function to fetch deals.
 * @param {*} func A saga function
 * @param {*} step Current step to be fetched
 * @param {*} id ID of the current form
 */
function fetchDetails(func, step) {
  const dealId = getDealId()
  const agentId = getAgentId()

  if (dealId !== null) {
    func({
      step,
      id: dealId,
      agentId,
    })
  }
}

/**
 * Common Util function to submit or update deals.
 * @function SubmitForm
 * @param {*} fieldValues Form Values
 * @param {*} isDraft Boolean value storing isDraft
 * @param {*} createOffer saga action to create new offer
 * @param {*} updateOffer saga action to update existing offer
 * @param {*} history React history object
 * @param {*} nextRoute Next route post update/create
 * @param {*} currentStep Current form step
 * @param {*} key Array type payload key
 * @param {*} agentIdRequired is agent id required for payload
 * @param {*} customDraftVal is draft required with special key
 * @param {*} agentId Current deal's Agent's ID
 * @param {*} lenderOrVendor is lender or vendor type payload
 */
function SubmitForm(
  fieldValues,
  isDraft,
  createOffer,
  updateOffer,
  history,
  nextRoute,
  currentStep,
  keyForArray,
  agentIdRequired,
  customDraftVal,
  currentAgentId,
  lenderOrVendor,
) {
  const dealId = getDealId()

  const currentArrayKey = keyForArray

  Object.keys(fieldValues).forEach((key) => {
    if (fieldValues[key] === undefined) {
      // eslint-disable-next-line no-param-reassign
      fieldValues[key] = null
    }
  })
  const localFieldValues = {
    ...fieldValues,
  }
  let finalPayload = {}

  const payload = []

  if (currentStep === "buyer_info") {
    Object.keys(localFieldValues).forEach((fvKey) => {
      if (fvKey === "source" && localFieldValues[fvKey]) {
        payload.source = localFieldValues[fvKey]
      }
      if (fvKey === "other_source" && localFieldValues[fvKey]) {
        payload.other_source = localFieldValues[fvKey]
      }
    })
  }

  if (currentArrayKey) {
    Object.keys(localFieldValues).forEach((fvKey) => {
      const index = parseInt(fvKey.split("-")[1], 10)
      const keyName = fvKey.split("-")[0]
      if (!payload[index]) {
        payload[index] = {
          is_primary: index === 0 ? 1 : 0,
        }

        if (agentIdRequired) {
          payload[index] = {
            ...payload[index],
            agent_id: localFieldValues[fvKey] || null,
          }
        }
      }
      if (fvKey !== "agent_id-0") {
        payload[index] = {
          ...payload[index],
          [keyName]: localFieldValues[fvKey],
        }
      }

      //NEED TO TEST THIS
      // if (keyName === "type_of_ownership" && localFieldValues[fvKey] === "power_of_attorney") {
      //   payload[index] = {
      //     ...payload[index],
      //     ownership_name: "",
      //   }
      // }
    })
  }

  if (currentArrayKey) {
    finalPayload[currentArrayKey] = payload
    if (currentArrayKey === "clients") {
      if (fieldValues.source) {
        finalPayload.source = fieldValues.source
      }
      if (fieldValues.other_source) {
        finalPayload.other_source = fieldValues.other_source
      } else {
        finalPayload.other_source = ""
      }
    }
  } else if (payload.length > 0) {
    finalPayload = get(payload, "[0]", {})
  } else {
    if (lenderOrVendor === "vendor") {
      localFieldValues.vendor_zipcode = parseInt(localFieldValues.vendor_zipcode, 10)
    } else if (lenderOrVendor === "lender") {
      localFieldValues.lender_zipcode = localFieldValues.lender_zipcode
    }
    finalPayload = localFieldValues
  }

  finalPayload.draft = 0
  finalPayload.current_offer_step = currentStep || ""

  if (!currentAgentId) {
    finalPayload.agent_id = parseInt(getAgentId(), 10)
  }

  if (dealId) {
    updateOffer({
      payload: finalPayload,
      nextRoute: !isDraft ? nextRoute : "/broker/tc/offers",
      history,
      dealId,
      dealAgentId: currentAgentId || null,
      bypassAgentId: !currentAgentId,
      routeAgentId: getAgentId(),
    })
  } else {
    createOffer({
      dealAgentId: fieldValues["agent_id-0"],
      payload: {
        agents: payload,
      },
      nextRoute: !isDraft ? nextRoute : "/broker/tc/offers",
      history,
    })
  }
}

/**
 * Common Util function to fetch deals.
 * @function UpdateVendorOrLender
 * @param {*} fieldValues Form Values
 * @param {*} isDraft Boolean value storing isDraft
 * @param {*} update saga action to update existing vendor/lender
 * @param {*} history React history object
 * @param {*} nextRoute Next route post update/create
 * @param {*} id deal id
 * @param {*} customDraftVal Boolean value to set custom draft value
 * @param {*} agentId Id of the vendor's/lender's corresponding agent
 * @param {*} type Type of support (lender or vendor)
 */

function UpdateVendorOrLender(
  fieldValues,
  isDraft,
  update,
  history,
  nextRoute,
  id,
  customDraftVal,
  agentId,
  type,
) {
  const dealId = getDealId()

  Object.keys(fieldValues).forEach((key) => {
    if (fieldValues[key] === undefined) {
      // eslint-disable-next-line no-param-reassign
      fieldValues[key] = null
    }
  })
  const localFieldValues = {
    ...fieldValues,
  }
  let finalPayload = {}
  if (type === "vendor") {
    localFieldValues.vendor_zipcode = parseInt(localFieldValues.vendor_zipcode, 10)
  } else if (type === "lender") {
    localFieldValues.lender_zipcode = localFieldValues.lender_zipcode
  }
  finalPayload = localFieldValues
  finalPayload.draft = 0
  finalPayload.agent_id = agentId && parseInt(agentId, 10)

  update({
    payload: finalPayload,
    nextRoute,
    history,
    dealId,
    vendorId: id,
    agentId,
  })
}

// /**
//  * @function fetchGoogleContacts
//  * @param {number} googleAuthToken
//  * @param {func} setAgentGoogleContacts
//  */
// const fetchGoogleContacts = (
//   googleAuthToken,
//   setAgentGoogleContacts,
//   callback,
// ) => {
//   gapi.client.init({
//     clientId: GOOGLE_CLIENTID,
//     scope: "https://www.googleapis.com/auth/contacts.readonly",
//     discoveryDocs: ["https://people.googleapis.com/$discovery/rest"],
//   }).then(() => {
//     const tempToken = googleAuthToken?.data?.access_token
//     return gapi.client.request({
//       path: "https://content-people.googleapis.com/v1/people/me/connections?pageSize=2000&personFields=names%2CemailAddresses,phoneNumbers&sortOrder=FIRST_NAME_ASCENDING",
//       headers: {
//         Authorization: `Bearer ${tempToken}`,
//       },
//     })
//   }).then((response) => {
//     if (response.result?.connections) {
//       setAgentGoogleContacts(response.result?.connections)
//       callback(response.result?.connections)
//     }
//   }, (reason) => {
//     console.log(`Error: ${reason?.result?.error?.message}`)
//   })
// }

/**
 * @function setStreet
 * @param {object} location
 * @param {object} formRef
 * @param {string} key
 * @param {boolean} isOfferPage
 * @param {number} index
 * @param {string} lenderOrVendorKey
*/
function setStreet(location, formRef, key, isOfferPage, index, lenderOrVendorKey) {
  const locationDetails = {
    address: get(location, "address"),
    city: get(location, "locality.long_name"),
    state: get(location, "administrative_area_level_1.long_name"),
    stateShortName: get(location, "administrative_area_level_1.short_name"),
    country: get(location, "country.long_name"),
    latitude: get(location, "lat"),
    longitude: get(location, "lng"),
    zipCode: get(location, "postal_code.long_name"),
    streetNumber: get(location, "street_number.long_name"),
    county: get(location, "administrative_area_level_2.long_name"),
    route: get(location, "route.long_name"),
  }

  const addressString = locationDetails.streetNumber
    && locationDetails
      .route ? `${locationDetails.streetNumber}${locationDetails.route ? ` ${locationDetails.route}` : ""}` : null

  if (isOfferPage) {
    formRef.current.setFieldsValue({
      [key]: addressString || locationDetails.address,
      city: locationDetails.city,
      state: locationDetails.state,
      zipcode: locationDetails.zipCode,
      county: `${locationDetails.county}`,
      // latKey: locationDetails.latitude,
      // longKey: locationDetails.longitude,
    })
  } else if (index !== null) {
    formRef.current.setFieldsValue({
      [key]: addressString || locationDetails.address,
      [`city-${index}`]: locationDetails.city,
      [`state-${index}`]: locationDetails.state,
      [`zipcode-${index}`]: locationDetails.zipCode,
      // latKey: locationDetails.latitude,
      // longKey: locationDetails.longitude,
    })
  } else if (index === null && lenderOrVendorKey === "vendor") { //vendor and lender page
    formRef.current.setFieldsValue({
      [key]: addressString || locationDetails.address,
      vendor_city: locationDetails.city,
      vendor_state: locationDetails.state,
      vendor_zipcode: locationDetails.zipCode,
      // latKey: locationDetails.latitude,
      // longKey: locationDetails.longitude,
    })
  } else if (index === null && lenderOrVendorKey === "lender") { //vendor and lender page
    formRef.current.setFieldsValue({
      [key]: addressString || locationDetails.address,
      lender_city: locationDetails.city,
      lender_state: locationDetails.state,
      lender_zipcode: locationDetails.zipCode,
      // latKey: locationDetails.latitude,
      // longKey: locationDetails.longitude,
    })
  }
}

const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, "")
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return `${onlyNums}-`
    }
    if (onlyNums.length === 6) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}-`
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}

export {
  fetchDetails,
  SubmitForm,
  getDealId,
  UpdateVendorOrLender,
  setStreet,
  normalizePhone,
  getAgentId,
}
