import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as SettingsActions from "./actions"

function mapStateToProps(state, ownProps) {
  const { settings } = state
  return {
    sadasd: "asdasdas -asd  sad  asd a sd ",
    ...ownProps,
    ...settings,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      changeMultiCallerState: SettingsActions.changeMultiCallerStateActions.request,
      getMultiCallerState: SettingsActions.getMultiCallerStateAction.request,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
