import Loader from 'react-loader-spinner'
import React from "react";

const DefaultLoader = props => (
    <span className={"loader"}>
        <Loader
            type="Oval"
            color="#00BFFF"
            height={props.height ? props.height : "50"}
            width={props.width ? props.width : "50"}
        />
    </span>
);

const AudioLoader = props => (
    <span className={"loader"}>
        <Loader
            type="Audio"
            color="#00BFFF"
            height="100"
            width="100"
        />
    </span>
);
export {AudioLoader, DefaultLoader};