import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { get, isEmpty } from "lodash"
import { ToastContainer } from "react-toastify"
import globalContainer from "container/BrokerDashboard/globalPagesContainer"
import agentSearchContainer from "container/BrokerDashboard/agentSearchContainer"
import DayPickerInput from "react-day-picker/DayPickerInput"
import {
  Row, Col, Button, Modal, Upload, message,
} from "antd"
import Loader from "dumbComponents/common/UI/Loader"
import NumberFormat from "react-number-format"

const PAGE_SIZE = 30

const Wrap = styled.div``

const WrapAgent = styled.div`
  position: relative;
  width: 300px;
`

const AgentList = styled.div`
  position: absolute;
  width: 300px;
  top: 100px;
  left: 0px;
  right: 0px;
  background: #fff;
  border-radius: 10px;
  z-index: 1000;
  height: max-content;

  ${props => props.hasAgents && `
     border: 1px solid #ccc;
   `}

   ${props => props.hideDropdown && `
     display: none;
    `}
`

const AgentItem = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  &:hover {
    background: #eee;
    transition-duration: 0.3s;
    cursor: pointer;
  }
  > div {
    &:nth-child(1) {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
      background: #eee;
      > img {
        width: 100%;
      }
    }
    &:nth-child(2) {
      margin-left: 20px;
      p, h6 {
        padding: 0px;
        margin: 0px;
      }
      h6 {
        font-weight: 400;
        color: #999;
        font-size: 12px;
      }
      > input {
        color: #999;
        border: 10px;
        font-size: 12px;
        pointer-events: none;
        padding: 0px;
        background: transparent;
      }
    }
  }
`
const Input = styled.input`
  width: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`

const ButtonComponent = styled.div`
display: inline-flex;
align-items: center;
justify-content: center;
padding: 8px 16px;
font-size: 14px;
font-weight: 500;
line-height: 1;
border-radius: 2px;
text-align: center;
cursor: pointer;
transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
color: #fff;
background-color: #1890ff;
border: 1px solid #1890ff;
height: 32px;
`

const clearState = {
  isShowActive: false,
  searchText: "",
  searchId: "",
  clientType: null,
  teamId: null,
  sourceType: null,
  planCategory: null,
  agent_state: null,
  property_state: null,
  tc_id: null,
  listingAddress: "",
  transaction_type: null,
  radius_transaction_type: null,
  fromAcceptance: undefined,
  toAcceptance: undefined,
  fromLicenseTransferredDate: undefined,
  toLicenseTransferredDate: undefined,
  dealType: null,
  viewContract: null,
  fromSubmission: undefined,
  toSubmission: undefined,
  fromListing: undefined,
  toListing: undefined,
  isShowActiveListing: false,
  closedFromDate: undefined,
  closedToDate: undefined,
  primarySaleRepresentative: "",
  secondarySaleRepresentative: "",
  sortData: {
    createdAtSortOrder: false,
    rowCreatedAtSortOrder: false,
  },
}

const ImportCSV = (props) => {
  const {
    toggleAgentList,
    isAgentListDropdownVisible,
    agents,
    rosterFetchAgentSearch,
    isModalViewOpen,
    toggleModalView,
  } = props

  const { isFetching: isFetchingName, data: dataAgent } = agents || {}
  const agentsLists = get(dataAgent, "agents", [])

  const [initState, setInitState] = useState({ ...clearState })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  const handleSearchType = (value) => {
    setInitState({ ...initState, searchText: value })
    rosterFetchAgentSearch({ search: value })
  }

  const handleOk = async () => {
    if (!selectedFile || !initState.searchId) {
      message.error("Please select both Agent and File")
      return // Stop further execution
    }
    if (selectedFile && initState.searchId) {
      try {
        await uploadCSV(selectedFile, initState.searchId)
        setIsModalOpen(false)
        setInitState({ ...initState, searchText: "" })
        setInitState({ ...initState, searchId: null })
        setSelectedFile(null)
      } catch (error) {
        console.error("Error occurred during upload:", error)
        // Handle the error, if needed
      }
    }
  }

  const uploadCSV = async (file, searchId) => {
    const { uploadCSVtoS3 } = props
    const location = "agent-transactions"
    const timestamp = Date.now()
    const file_name = `${timestamp}_${file.name}`

    await uploadCSVtoS3({
      file,
      objName: file_name,
      fileName: file_name,
      bucketName: process.env.NODE_ENV === "staging" ? `s-${location}` : location,
      agentId: searchId,
      uploadType: "Listing",
    })
  }

  const handleCancel = () => {
    setInitState({ ...initState, searchId: "", searchText: "" })
    setSelectedFile(null)
    toggleModalView(false)
  }

  const handleUpload = (file) => {
    setSelectedFile(file)
    //this.setState({ selectedFile: file })
  }

  const handleRemove = () => {
    setSelectedFile(null)
  }

  return (
    <div>
      <ButtonComponent onClick={() => toggleModalView(true)}>
              Import Contract CSV on Soul
      </ButtonComponent>
      <Modal title="Select Agent and File" visible={isModalViewOpen} onOk={handleOk} onCancel={handleCancel} okText="Upload">
        <Col>
          <WrapAgent>
            <h4>Agent Name Or Email</h4>
            <div>
              <Input
                style={{ width: 300, marginBottom: "16px" }}
                value={initState.searchText}
                placeholder="Search agent"
                onChange={(e) => {
                  toggleAgentList(true)
                  handleSearchType(e.target.value)
                }}
              />
              <AgentList
                hideDropdown={!isAgentListDropdownVisible}
                hasAgents={agentsLists && agentsLists.length > 0}
              >
                {isFetchingName ? (
                  <Loader width="25px" top={0} bottom={0} />
                ) : (
                  <React.Fragment>
                    {initState.searchText && agentsLists && agentsLists.length > 0 && agentsLists.map(agent => (
                      <AgentItem
                        key={agent.id}
                        onClick={() => {
                          setInitState({ ...initState, searchId: agent.id, searchText: agent.email })
                          toggleAgentList(false)
                          // setSearchActive(true)
                        }}
                      >
                        <div>
                          <img src={agent.agent_image} alt="" />
                        </div>
                        <div>
                          <p>
                            {`${agent.firstname} ${agent.lastname}`}
                          </p>
                          <h6>
                            {agent.email}
                          </h6>
                          <NumberFormat format="+1 (###) ###-####" mask="_" value={agent.phone} />
                        </div>
                      </AgentItem>
                    ))}
                  </React.Fragment>
                )}
              </AgentList>
            </div>
          </WrapAgent>
        </Col>
        <Col>
          <Upload
            accept=".csv"
            fileList={selectedFile ? [selectedFile] : []}
            onCancel={handleCancel}
            onRemove={handleRemove}
            showUploadList={{
              showRemoveIcon: true,
            }}
            beforeUpload={handleUpload}
            action={file => handleUpload(file)}
          >
            <Col>
              <Button type="primary">
                      Select File
              </Button>
            </Col>
          </Upload>
        </Col>
      </Modal>
    </div>
  )
}

export default agentSearchContainer(globalContainer(ImportCSV))
