import cookie from "react-cookies"

const date = new Date()
date.setDate(date.getDate() + 365)

const cookieOptions = {
  path: "/",
  domain: process.env.COOKIE_HOST,
  expires: date,
}

const CookiesStorage = {
  save: (key, value) => cookie.save(key, value, cookieOptions),
  load: (key) => {
    if (cookie.load(key)) {
      const value = cookie.load(key)
      if (value === "true" || value === "false") {
        return JSON.parse(value)
      }
      return cookie.load(key)
    }
    return false
  },
  remove: key => cookie.remove(key, cookieOptions),
}

export default CookiesStorage
