import AgentDetails from "./AgentDetails"
import CurrentPlan from "./CurrentPlan"
import ICADetails from "./ICADetails"
import Licences from "./Licences"
import Profile from "./Profile"
import SalesRep from "./SalesRep"
import SidePanelSettings from "./SidePanelSettings"
import CancelSubscription from "./CancelSubscriptions"

export {
  AgentDetails,
  CurrentPlan,
  ICADetails,
  Licences,
  Profile,
  SalesRep,
  SidePanelSettings,
  CancelSubscription,
}
