import styled from "styled-components"

export const AIDashboardWrap = styled.div``

export const FilterAndHeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ActionsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const RefreshCTA = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
  cursor: pointer;
  border: none;
  background: transparent;


  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin: 0;
  }

  img {
    width: 24px;
    height: 24px;
  }
`

export const AgentListingAndChatWrap = styled.div`
  display: flex;
  width: 100%;
  min-height: 40px;
  // background: grey;
  border-radius: 8px;
  margin-top: 10px;
  border-top: 0.5px solid #E3E4FC;
  padding: 40px 0px;
  gap: 35px;
  justify-content: space-between;
`

export const AgentChatColumn = styled.div`
  height: 100%;
  flex-grow: 1;
  background: #FCFCFF;
  display: flex;
  gap: 15px;
  align-items: center;
  flex-direction: column;
  max-width: 320px;
  max-height: 80vh;
  overflow: scroll;

  ${props => props.isLoading && `
    opacity: 0.4;
  `}
`

export const ChatArea = styled.div`
  flex-grow: 10;
  height: 100%;

  display: flex;
  // width: 633px;
  padding: 8px 20px 32px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4.5px;
  border: 1px solid var(--Color-Secondary, #A5A8FF);
  background: #FCFCFF;
  max-width: 55vw;
`
export const MessagesArea = styled.div`
  max-height: 55vh;
  overflow: scroll;
  width: 100%;
`

export const ActionArea = styled.div`
 width: 100%;
`
