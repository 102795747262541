import React from "react"
import { CloudUploadOutlined } from "@ant-design/icons"
import { message, Upload } from "antd"
import {
  CardV2,
  SectionTitle,
} from "./commonStyles"
const { Dragger } = Upload

const CustomFileUpload = ({
  uploadFunction,
  data,
}) => {
  const props = {
    name: "file",
    multiple: false,
    onError: (err) => {
      console.log("onError", err)
    },
    onSuccess: (res, file) => {
      uploadFunction(res.fileUrlAfterUpload, file.name)
    },
    action: (file) => { console.log(file) },
    customRequest: async (customXHRObject) => {

      const {
        onStart,
        onSuccess,
        onProgress,
        onError,
        file,
      } = customXHRObject || {}
      try {
        const signedRes = await fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=s-ica-docs/${data.agentId}&filename=${file.name}`)
        const signedResJson = await signedRes.json()
        const myHeaders = new Headers({ "Content-Type": "application/pdf" })

        const res = await fetch(signedResJson.url, {
          method: "PUT",
          headers: myHeaders,
          body: file,
        })
        onSuccess(signedResJson, file)
      } catch (err) {
        onError(err)
      }
    },
    onChange(info) {
      const { status } = info.file
      console.log(status)
      if (status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files)
    },
  }

  return (
    <CardV2
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "540px",
      }}
    >
      <SectionTitle>Upload ICA</SectionTitle>
      <span>
        <strong>
          NOTE: Make sure the team agreement is attached in case of
          {" "}
          <i>Custom Uploaded ICA</i>
          .
        </strong>
      </span>
      <span>
        To download the required Team Agreement, kindly scroll down to the &quot;Team Agreement&quot; section below.
      </span>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p className="ant-upload-text">Drag and drop files</p>
      </Dragger>
    </CardV2>
  )
}

export default CustomFileUpload
