import { format } from "date-fns"
import moment from "moment"

export const serializer = (list) => {
  if (!list || Object.keys(list).length <= 0) return []
  return list.map(item => ({
    key: item.room_id,
    downloadableLink: item.rooms_s3_url,
    downloadableLinks: item.recording_info,
    name: item.room_name,
    date: item.created_date,
    //format(new Date(item.updated_at), "MMM dd, yyyy")
    roomHeldOn: moment.unix(item.created_at).format("MM/DD/YYYY hh:mm A"),
  }))
}
