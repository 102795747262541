import React from "react"
import styled from "styled-components"
import { Button } from "./../CallHeader/common"
import { assignMostRecentlyConnectedAgentAPI } from "./../API/CallerApi"
import { ShowSuccessToast } from "./../../lib/ErrorUtils";
import container from "./../../container/AgentComparison"

const Wrap = styled.div`
  margin: 10px;

  ${props => props.isHide && `
    display: none;
  `}
`

const AssignAgent = ({
  twilioClientName,
  referralId,
  parentCallSid,
  childCallSid,
  getAgentsForComparison,
}) => {
  const handleClick = async () => {
    const body = {
      "twilio_client_name": twilioClientName,
      "referral_id": referralId,
      "assign_most_recently_connected_agent": true,
      "parent_call_sid" : parentCallSid,
      "child_call_sid" : childCallSid
    }
    await assignMostRecentlyConnectedAgentAPI({
      method: "POST",
      body: JSON.stringify(body),
    })
    getAgentsForComparison({
      referralId,
    })
    ShowSuccessToast("Agent assigned successfully!");
  }

  return (
    <Wrap isHide={!referralId}>
      <Button onClick={handleClick} small>
        Assign Most recently connected agent
      </Button>
    </Wrap>
  )
}

export default container(AssignAgent)