import React, { Component } from "react";
import Backdrop from "../Backdrop/Backdrop";
import styled from 'styled-components';

const StyledModal = styled.div`
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 50%;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 15px;
  left: 30%;
  top: 20%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  overflow-x: hidden;
  overflow-y: auto;
  @media (min-width: 600px) {
    width: 700px;
    left: calc(50% - 350px);
  }
`;

class Modal extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.show !== this.props.show ||
      nextProps.children !== this.props.children
    );  
  }

  render() {
    return (
      <div>
        <Backdrop show={this.props.show} clicked={this.props.closedModal} />
        <StyledModal
          style={{
            transform: this.props.show ? "translateY(0)" : "translateY(-100vh)",
            opacity: this.props.show ? "1" : "0"
          }}
        >
          {this.props.children}
        </StyledModal>
      </div>
    );
  }
}

export default Modal;
