import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"
import CategoryMenu from "./CategoryMenu"
import AddCategoryForm from "./CategoryForm"
import _ from 'lodash';
import moment from 'moment';
import { DefaultLoader } from "components/UI/Loader/Loader";
import CourseListing from "./CourseListing"


const OuterWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const LeftMenu = styled.div`
  height: 73px;
  max-width: 188px;
  flex-grow: 1;
`

const ListWrap = styled.ul`
 list-style: none;
 padding:0;
 margin:0;
 li {
  width: 100%;
  height: 68px;
  border: solid 1px #e2e4e8;
  display: flex;
  .instructor_image {
    flex-grow: 1;
    height: 100%;
    max-width: 60px;
    padding-top: 14px;
    padding-left: 14px;
    img {
     width: 34px;
     height: 34px;
     border-radius: 18px;
    }
  }
  .instructor_details{
    height: 100%;
    padding: 14px;
    width: 222px;
   p {
    margin: 0;
   }
   p:last-child {
    font-size: 12px;
    color: #586069;
   }
  }
  .cta {
   height: 100%;
   width: 400px;
   display: flex;
   justify-content: flex-end;
   div {
    flex-grow: 1;
    height: 100%;
    padding: 23px 8px 8px 24px;
    max-width: 99px;
    button{
     cursor: pointer;
     background: none;
     border: none;
    }
    .delete {
     color: red;
    }
   }
  }
 }
`

const EventAttendees = styled.ul`
  list-style: none;
  padding: 0;

  li {
   display: flex;
   margin-top: 10px;
  }
`

const RightMenu = styled.div`
  height: 500px;
  width: 699px;
  padding-left: 24px;
  .title {
   .required {
    color: red;
   }
  }
  .row {
   display: flex;
   .selectWrap {
    margin-left: 56px;
    width: 250px;
    label {
     font-family: "SF Pro Display", sans-serif;
     font-size: 14px;
     font-weight: bold;
     font-stretch: normal;
     font-style: normal;
     line-height: normal;
     letter-spacing: normal;
     color: #24292e;
    }
   }
  }
  .imagePreview {
   img {
    width: 100%;
   }
  }
  .header {
   height: 50px;
   width: 100%;
   p {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin: 0;
   }
  }

  .inst {
   margin-top: 50px;
  }

  .event-image {
   height: 300px;
   width: 100%;
   .image-header-div {
    display: flex;
    justify-content: space-between;
    button {
     top: 10px;
     height: 30px;
     position: relative;
     left: 50px;
    }
   }
   .title {
    font-size: 14px;
   font-weight: bold;
   color: #24292e;
   }
   .image-placeholder {
    width: 730px;
    height: 242px;
    border-radius: 8px;
    margin-bottom: -97px;
    .fileContainer {
     padding: 50px 0;
     .uploadPicturesWrapper {
      position: absolute;
      .uploadPictureContainer{
        width: 30%;
      }
     }
    }
   }
  }
`

const CourseListWrap = styled.div`
 height: max-content;
`

const Wrap = styled.div`
height: 100px;
width: 100px;
background: red;
`

const UpdateSequence = styled.button`
    width: 120px;
    display: block;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #2a8b3f;
    background-image: linear-gradient(to bottom,#34ce57,#28a745);
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    float: right;
    position: relative;
    bottom: 25px;
`


class AddCategory extends Component{
 state = {
  isUpdateMode: this.props.match.params.id,
 }

 componentDidMount(){
  const {
   getCategoryDetail,
   getCourseListing,
  } = this.props
  if (this.props.match.params.id) {
   getCategoryDetail({
    id: this.props.match.params.id,
   })
   getCourseListing({
    id: this.props.match.params.id,
   })
  }
 }

 updateCourseBatch = () => {
  const {
   batchUpdateCourse,
   arrangedCourses,
  } = this.props

  batchUpdateCourse({
   payload: arrangedCourses,
  })
 }

 render() {

  const {
   getCategoryDetailResponse,
   getCourseListingForCategoryResponse,
  } = this.props

  const {
   isUpdateMode,
  } = this.state

  const {
    data: categoryDetail,
    isFetching,
  } = getCategoryDetailResponse || {}

  const {
   data: courseListingByCategory,
   isFetching: fetchingList,
  } = getCourseListingForCategoryResponse || {}

  return (
   <React.Fragment>
     {isFetching || fetchingList ? (
      <OuterWrap>
       <LeftMenu>
        <CategoryMenu/>
       </LeftMenu>
       <RightMenu>
        <DefaultLoader height={200} width={200}/>
       </RightMenu>
      </OuterWrap>
     ) : (
      <OuterWrap>
       <LeftMenu>
        <CategoryMenu
         isUpdateMode={this.props.match.params.id}
        />
       </LeftMenu>
       <RightMenu
        isUpdateMode={this.props.match.params.id}
       >
        <div className="header">
         <p className="title">Category Information</p>
        </div>
        <AddCategoryForm
         categoryDetail={categoryDetail}
         isUpdateMode={this.props.match.params.id}
        />
        {this.props.match.params.id && (
         <CourseListWrap>
            <UpdateSequence onClick={this.updateCourseBatch}>
             Update Sequence
            </UpdateSequence>
            <CourseListing
              courseList={courseListingByCategory}
            />
         </CourseListWrap>
        )}
       </RightMenu>
      </OuterWrap>
     )}
   </React.Fragment>
  )
 }
}

export default Container(AddCategory)
