import React, { Component } from 'react';
import fetch from 'isomorphic-fetch';
import bugsnag from 'bugsnag-js';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;

const originalKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(originalKey);

class UpcomingCalls extends Component {
    state = {
        items: [],
        currentConsultantView: this.props.user.twilio_client_name,
        consultantsMap: [],
        consultants: [],
    };

    getAdminUsers = async () => {
        try {
            let response = await fetch(baseUrl + '/v1.0/consultants/all?role=Jr Consultant', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'secure-id': token,
                },
            });
            let respJson = await response.json();
            let consultants = respJson.response.accounts;
            let consultantsMap = [];
            _.each(consultants,(consultant) => {
                consultantsMap[consultant.twilio_client_name] = consultant
            });
            this.setState({consultants});
            this.setState({consultantsMap: consultantsMap})
        }
        catch (e) {
            bugsnagClient.notify(e);
        }
    };

    filterUpcomingCalls = async () => {
        try {
            let self = this;
            let query = '';

            if (self.state.currentConsultantView !== 'All') {
                query += '&twilio_client_name=' + self.state.currentConsultantView;
            }
            let response = await  fetch(baseUrl + '/v1.0/caller/upcoming-calls?done=0&sort=-L.id&limit=1000'+ query, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'secure-id': token,
                },
            });
            let respJson = await response.json();
            console.log(respJson);
            this.setState({items: respJson.response.items});
        }
        catch(e) {
            bugsnagClient.notify(e);
        }
    };

    handleConsultantChange = (e) =>{
        const value = e.target.value;
        this.setState({currentConsultantView: value});
    };


    componentDidMount() {
        this.filterUpcomingCalls();
        this.getAdminUsers();
    }

    render() {
        try{
            let items;
            const consultantDiv = this.state.consultants.map(consultant =>
                <option value={consultant.twilio_client_name} >{consultant.name}</option>
            );
            let consultantsMap = this.state.consultantsMap;
            let clientProfileBaseUrl = process.env.REACT_APP_BLINK_BASE_URL;
            let agentProfileBaseUrl = process.env.REACT_APP_PROFILE_BASE_URL + "/profile/agent?id=";

            let total = this.state.items.length;

            items = (this.state.items).map((log) => {
                let clientProfileUrl = clientProfileBaseUrl + '?referral_id=' + log.referral_id;

                let consultant = consultantsMap[log.twilio_client_name];
                if(consultant) {
                    log.consultant_name = consultant.name;
                }

                if (log.previous_calls) {
                    let consultant = consultantsMap[log.previous_calls.referral_manager];
                    if(consultant) {
                        log.previousConsultant = "Last Called by " + consultant.name;
                    } else {
                        console.log(log.previous_calls.referral_manager);
                    }
                }
                let agentProfileUrl = agentProfileBaseUrl + log.sender_id;

                return <tr>
                    <td>{log.consultant_name}</td>
                    <td>
                        <a href={clientProfileUrl} target="_">
                        {log.client_name}<br/>
                        {log.sender_client_email}<br/>
                        {log.sender_client_phone}
                        </a>
                    </td>
                    <td>
                        <a href={agentProfileUrl} target="_">
                            {log.sender_name}<br/>
                            {log.sender_email}<br/>
                        </a>
                    </td>
                    <td>{log.faild_count}<br/>
                        {log.previousConsultant}
                    </td>
                    <td>{log.referral_source || ''}</td>
                </tr>
            });

            return (<div style={{ paddingTop: "70px" }}>
                    <div className="up-coming-calls-dropdown">
                        Consultant:
                        <select onChange={this.handleConsultantChange} value={this.state.currentConsultantView}>
                            {consultantDiv}
                        </select>
                        <button onClick={this.filterUpcomingCalls.bind(this)}> Filter </button>
                        <span className={"up-coming-calls-total"}>Total: {total}</span>
                    </div>

                    <hr/>
                    <table className="dashboard">
                        <tr>
                            <th>Consultant</th>
                            <th>Client</th>
                            <th>Sender Agent</th>
                            <th>No of previous failed call attempts</th>
                            <th>Source</th>
                        </tr>
                        {items}
                    </table>
                </div>
            );
        }
        catch(e){
            bugsnagClient.notify(e);
            return <h1>failed..</h1>
        }
    }
}
export default UpcomingCalls;
