import React, { Component } from "react"
import styled from "styled-components"
import container from "container/Agent"

const Wrapper =styled.div`
    background: #FFF;
    padding: 75px 50px;
    input {
        font-size: 18px;
        width: 100%;
        padding: 10px 20px;
    }
`

const AgentWrap = styled.div`
    padding: 15px;
    border: 1px solid #CCC;

    &:hover {
        background: #EEE;
    }
`

class Index extends Component {
    state = {
        searchForm: {}
    }

    handleOnType = (event) => {
        const { searchForm } = this.state
        searchForm[event.target.name] = event.target.value
        this.setState({
            searchForm,
        })
    }

    handleAgentSearchChange = (e) => {
        const { fetchAgentSearch } = this.props
        const { value } = e.target
        console.log(value)
        fetchAgentSearch({ search: value } )
    }

    handleAgentSelect = (agent) => {
        console.log("This is my agent", agent)
        const { history } = this.props
        history.push({pathname: `agents/${agent.id}`, state : {agent : agent}})
    }

    render() {
        console.log(this.state.searchForm)
        const { searchForm } = this.state
        const { agents } = this.props
        const { isFetching, data } = agents || {}
        const { agents: agentsData } = data || {}

        console.log(agents)
        const { search, agent } = searchForm

        console.log(this.state)
        return (
            <Wrapper>
                <span>
                    <input placeholder="Search agent" onChange={this.handleAgentSearchChange} value={this.state.agentSearchText} />
                </span>

                {isFetching ? (
                    <h1>loading...</h1>
                ) : (
                    <React.Fragment>
                        {agentsData && agentsData.map(item => (
                            <AgentWrap onClick={() => this.handleAgentSelect(item)}>
                                <h5>{item.firstname}</h5>
                                <small>{item.email}</small>

                            </AgentWrap>
                        ))}
                    </React.Fragment>
                )}
            </Wrapper>
        )
    }
}

export default container(Index);
