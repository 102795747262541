import React, { Component } from "react"
import styled from "styled-components"
import fetch from "isomorphic-fetch"
import ReactModal from "react-modal"
import bugsnag from "bugsnag-js"
import Loader from "dumbComponents/common/UI/Loader"
import ColorPickerWithInput from "dumbComponents/common/UI/ColorPickerWithInput"
import US_STATES from "shared/US_STATES.json"
import root, { alert } from "window-or-global"
import queryString from "query-string"
// import Button from "dumbComponents/common/UI/Button"
import JobCreateModal from "components/Jobs/JobCreateModal"
import JobViewModal from "components/Jobs/JobViewModal"
import ImageUploader from "react-images-upload"
import RichTextEditor from "dumbComponents/common/RichTextEditor/RichEditor"
import PhotoEditor from "dumbComponents/common/PhotoEditor"
import { SALES_REPRESENTATIVE } from "dumbComponents/BrokerDashboard/GlobalPages/Resources/data"
import {
  withRouter,
} from "react-router-dom"
import _, { parseInt, get } from "lodash"
import {
  Button,
  Tabs,
  Form, Input, Modal, Switch, Flex, Row, Col, List, Select, Table, Tag, message, PageHeader, Radio, Space
} from "antd"
import { ExclamationCircleOutlined, CloseCircleTwoTone } from "@ant-design/icons"
import Notifications, { notify } from "react-notify-toast"
const baseUrl = process.env.REACT_APP_API_BASE_URL
const token = process.env.REACT_APP_SECRET_ID
const originalKey = process.env.REACT_APP_BUGSNAG_KEY
const bugsnagClient = bugsnag(originalKey)
const { TabPane } = Tabs
const { confirm } = Modal

const dealForm = {
  width: "50%",
  height: "40px",
}

const RichTextEditorWrapper = styled.div`
  margin-top: 10px;
  .codex-editor__redactor {
    background: white;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 200;
    padding: 10px;
    min-height: 120px;
    margin-bottom: 0px !important;
    /* padding-bottom: 200px !important; */
    ${props => props.pb && `
      padding-bottom: ${props.pb} !important;
    `}
    ${props => props.mh && `
      min-height: ${props.mh};
    `}
  }
  > div {
    width: auto;
  }
`

const InputWrapper = styled.div`
  flex: 1;
  padding: 0px 20px;
  div {
    margin-bottom: 10px;
  }
  ${props => props.mt && `
    margin-top: ${props.mt}
  `}
`

const InputWrapperNew = styled.div`
  flex: 1;
  padding: 0px 20px;

  ${props => props.mt && `
    margin-top: ${props.mt}
  `}

  > div {
    margin-bottom: 10px;
  }
`

const BlockDiv = styled.div`
  display: flex;
  justify-content: space-between;
  ${props => props.mt && `
    margin-top: ${props.mt}
  `}
`

const tableCellStyle = {
  display: "table-cell",
  padding: "10px",
  border: "1px solid black",
}

const DEFAULT_TEAM = {
  name: "",
  members: [],
}

const Wrap = styled.div`
  margin: 0px 40px;
`

const AgentRow = styled.div`
  padding: 15px 25px;
  background: #F1EEFF;
  margin-bottom: 10px;
  border-radius: 10px;
  max-width: 500px;
  display: flex;
  > div {
    &:nth-child(1) {
      flex: 1;
    }
    &:nth-child(2) {
      width: 100px;
    }

  }
`

const Header = styled.div`
  display: flex;
  margin-top: 30px;
  > h1 {
    flex: 1;
  }
`

const ControlsWrap = styled.div`
  width: 250px;
  display: flex;
  justify-content: space-around;
  align-self: flex-start;
`

const TeamMemberInput = styled(Input)`
  padding: 10px 20px;
  width: 500px;
  font-size: 18px;
  margin-bottom: 20px;
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  button {
   top: 10px;
   height: 30px;
   position: relative;
   left: 50px;
  }
`

const ImageUploaderContainer = styled.div`
  display: flex;
  align-items: center;
  .fileUploader {
    width: 30%;
    .fileContainer {
      text-align: center;
      box-shadow: none;
      margin: 0;
      padding: 0;
    }
  }
  // img {
  //   width: 200px;
  //   height: 100px;
  // }
`

const TeamLeadImageUploaderContainer = styled.div`
  display: flex;
  align-items: center;
  .fileContainer {
    width: 20%;
    text-align: center;
    margin-left: 30px;
    box-shadow: none;
  }

  img {
    width: 150px;
    height: 150px;
  }
`

const StyledDiv = styled.div`
  width: 500px;
  padding: 1px 0px 0px 5px;
  cursor: pointer;

  &:hover {
    background-color: aliceblue;
  }
`

const ButtonWrap = styled.div`
  display: flex;
  button {
    margin: 0 40px 0 0;
  }
`

const RequiredWrap = styled.span`
  color: #ED1616;
  font-size: 15px;
  margin-right: 5px;
`

const Label = styled.span`
  margin-left: 5px;
`

const CloseCircleTwoToneIcon = styled(CloseCircleTwoTone)`
  margin-top: -10px;
  position: absolute;
  top: 0;
  font-size: 25px;
  cursor: pointer;
  margin-left: -14px;
`

const ImageWrapper = styled.div`
  display: inline-block;
  position: relative;
`
const STATE_OPTIONS = Object.keys(US_STATES).map(key => ({ label: US_STATES[key], value: US_STATES[key] }))

class BrokerageTeam extends Component {
  state = {
    view: "create",
    name: null,
    iconUrl: null,
    apiCallActive: false,
    showModal: false,
    agentSearchText: null,
    members: [],
    searchResults: [],
    currentSelectedAgent: null,
    apiCallActive: false,
    id: null,
    pictures: [],
    teamLeadPictures: [],
    imageLoading: false,
    adminImageUrl: null,
    city: "",
    state: "",
    productionVolume: "",
    visibleOnLandingPage: false,
    showJobCreateModal: false,
    viewJobModal: false,
    about_team: "",
    other_benefits: "",
    teamInfoLoading: false,
    profile_name: "",
    tcFullName: "",
    tcEmail: "",
    is_cashback_supported: false,
    hide_radius_logo: false,
    brand_primary_color: "",
    brand_background_color: "",
    primary_sale_representative: "",
    secondary_sale_representative: "",
    primary_sale_representative_split_percentage: "",
    secondary_sale_representative_split_percentage: "",
    past_brokerage_name: "",
    inviteType: 1,
  }

  createDefaultTeam = () => {
    const team = DEFAULT_TEAM
    this.setState({ team })
  }

  settcDetails = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  onDrop = async (picture) => {
    this.setState({
      pictures: this.state.pictures.concat(picture),
      imageLoading: true,
    })
    const fd = new FormData()
    fd.append("imageFile", picture[0])
    const data = {
      filePath: `brokerageTeam/${Math.random().toString(36).replace(/[^a-z]+/g, "").substr(0, 5)}`,
      config: {
        bucket: "broker",
        convertTo: "jpeg",
      },
    }
    fd.append("requestBody", JSON.stringify(data))
    let resp = await fetch(`${baseUrl}/v1.0/image`, {
      method: "POST",
      headers: {
        "internal-id": token,
        "X-User-Agent": "agent-php",
      },
      body: fd,
    })
    resp = await resp.json()
    this.setState({
      iconUrl: resp.response.imageURL,
      pictures: this.state.pictures.concat(picture),
      imageLoading: false,
    })
  }

  onDropTeamLead = async (picture) => {
    this.setState({
      teamLeadPictures: this.state.teamLeadPictures.concat(picture),
      imageLoading: true,
    })
    const fd = new FormData()
    fd.append("imageFile", picture[0])
    const data = {
      filePath: `brokerageTeam/${Math.random().toString(36).replace(/[^a-z]+/g, "").substr(0, 5)}`,
      config: {
        bucket: "broker",
        convertTo: "jpeg",
      },
    }
    fd.append("requestBody", JSON.stringify(data))
    let resp = await fetch(`${baseUrl}/v1.0/image`, {
      method: "POST",
      headers: {
        "internal-id": token,
        "X-User-Agent": "agent-php",
      },
      body: fd,
    })
    resp = await resp.json()
    this.setState({
      adminImageUrl: resp.response.imageURL,
      teamLeadPictures: this.state.teamLeadPictures.concat(picture),
      imageLoading: false,
    })
  }

  removeImage = (type) => {
    this.setState({
      [`${type}Src`]: "",
      [`${type}Url`]: "",
    })
  }

  createReactImageCropReader = (picture, type) => {
    console.log("==picture=====", picture)
    const reader = new FileReader()
    reader.addEventListener(
      "load",
      () => this.setState({
        [`${type}Src`]: reader.result,
        [`${type}EditShow`]: true,
      }),
      false
    )
    reader.readAsDataURL(picture[0])
    this.setState({ type })
  }

  closeImageEdit = (type) => {
    this.setState({
      imageLoading: false,
      [`${type}EditShow`]: false,
    })
  }

  uploadTeamCoverImage = async (picture, type) => {
    // teamCoverImageEditShow
    this.setState({
      teamLeadPictures: this.state.teamLeadPictures.concat(picture),
      imageLoading: true,
    })
    const fd = new FormData()
    fd.append("imageFile", picture)
    const data = {
      filePath: `brokerageTeam/${Math.random().toString(36).replace(/[^a-z]+/g, "").substr(0, 5)}`,
      config: {
        bucket: "broker",
        convertTo: "jpeg",
      },
    }
    fd.append("requestBody", JSON.stringify(data))
    let resp = await fetch(`${baseUrl}/v1.0/image`, {
      method: "POST",
      headers: {
        "internal-id": token,
        "X-User-Agent": "agent-php",
      },
      body: fd,
    })
    resp = await resp.json()
    this.setState({
      [`${type}Url`]: resp.response.imageURL,
      teamLeadPictures: this.state.teamLeadPictures.concat(picture),
      imageLoading: false,
      [`${type}EditShow`]: false,
    })
  }

  getNameDiv = () => {
    const {
      imageLoading,
      teamLeadSrc,
      teamCoverImageSrc,
      teamCoverImageEditShow,
      teamCoverImageUrl,
      teamGalleryImage1Src,
      teamGalleryImage1EditShow,
      teamGalleryImage1Url,
      teamGalleryImage2Src,
      teamGalleryImage2EditShow,
      teamGalleryImage2Url,
      teamGalleryImage3Src,
      teamGalleryImage3EditShow,
      state,
      teamGalleryImage3Url,
      type,
      teamInfoLoading,
      iconSrc,
      iconEditShow,
      iconUrl,
      adminImageEditShow,
      adminImageSrc,
    } = this.state
    console.log("===this.state===..", this.state)
    return (<>
      {teamInfoLoading ? (<Loader />) : (
        <div>
          <BlockDiv>
            <InputWrapper>
              <div>
                <RequiredWrap> * </RequiredWrap>
                Name
              </div>
              <Input value={this.state.name} onChange={e => this.changeTeamName(e.target.value)} />
            </InputWrapper>
            <InputWrapper>
              <div>
                City
              </div>
              <Input value={this.state.city} onChange={e => this.changeTeamCity(e.target.value)} />
            </InputWrapper>
            <InputWrapper>
              <div>
                State
              </div>
              {/* <Input value={this.state.state} onChange={e => this.changeTeamState(e.target.value)} /> */}
              <Select
                onChange={value => this.changeTeamState(value)}
                style={{
                  width: 250,
                }}
                value={state}
              >
                {STATE_OPTIONS.map(st => (
                  <Select.Option value={st.value}>{st.label}</Select.Option>
                ))}
              </Select>
            </InputWrapper>
            <InputWrapper>
              <div>
                Enrolled in Cashback Program?
              </div>
              <Switch
                checked={this.state.is_cashback_supported}
                onChange={(value) => {
                  this.setState({ is_cashback_supported: value })
                }}
              />
            </InputWrapper>
            <InputWrapper>
              <div>
                {/* <RequiredWrap> * </RequiredWrap> */}
              Production Volume
              </div>
              <Input type="number" value={this.state.productionVolume} onChange={e => this.changeTeamProductionVolume(e.target.value)} />
            </InputWrapper>
          </BlockDiv>
          <BlockDiv mt="30px">
            <InputWrapper>
              <div>
                {/* <RequiredWrap> * </RequiredWrap> */}
             Primary Sales Representative
              </div>
              <Select
                placeholder="Select"
                onChange={value => this.changePrimarySalesRepresentative(value)}
                value={this.state.primary_sale_representative}
                style={{
                  width: 200,
                }}
                options={SALES_REPRESENTATIVE}
              />
            </InputWrapper>
            <InputWrapper>
              <div>
                {/* <RequiredWrap> * </RequiredWrap> */}
                Primary Sales Representative Split
              </div>
              <Input type="number" value={this.state.primary_sale_representative_split_percentage} onChange={e => this.changePrimarySalesRepresentativeSplit(e.target.value)} />
            </InputWrapper>
            <InputWrapper>
              <div>
                {/* <RequiredWrap> * </RequiredWrap> */}
             Secondary Sales Representative
              </div>
              <Select
                placeholder="Select"
                onChange={value => this.changeSecondarySalesRepresentative(value)}
                value={this.state.secondary_sale_representative}
                style={{
                  width: 200,
                }}
                options={SALES_REPRESENTATIVE}
              />
            </InputWrapper>
            <InputWrapper>
              <div>
                {/* <RequiredWrap> * </RequiredWrap> */}
                Secondary Sales Representative Split
              </div>
              <Input type="number" value={this.state.secondary_sale_representative_split_percentage} onChange={e => this.changeSecondarySalesRepresentativeSplit(e.target.value)} />
            </InputWrapper>
            <InputWrapper>
              <div>
                {/* <RequiredWrap> * </RequiredWrap> */}
              Previous Brokerage Name
              </div>
              <Input type="text" value={this.state.past_brokerage_name} onChange={e => this.changePastBrokerageName(e.target.value)} />
            </InputWrapper>
          </BlockDiv>
          <BlockDiv mt="30px">
            <InputWrapper>
              <div>
                Hide Radius logo for this team?
              </div>
              <Switch
                checked={this.state.hide_radius_logo}
                onChange={(value) => {
                  this.setState({ hide_radius_logo: value })
                }}
              />
            </InputWrapper>

            <InputWrapperNew>
              <div>
                Brand primary color (hex only, example: #393c5d)
              </div>
              <ColorPickerWithInput
                color={this.state.brand_primary_color}
                onChange={(color) => {
                  this.setState({ brand_primary_color: color })
                }}
              />
            </InputWrapperNew>

            <InputWrapperNew>
              <div>
                Brand background color (hex only, example: #393c5d)
              </div>
              <ColorPickerWithInput
                color={this.state.brand_background_color}
                onChange={(color) => {
                  this.setState({ brand_background_color: color })
                }}
              />
            </InputWrapperNew>
          </BlockDiv>
          <InputWrapper mt="35px">
            <div>
              {/* <RequiredWrap> * </RequiredWrap> */}
            About Team
            </div>
            <RichTextEditorWrapper>
              <RichTextEditor saveRTF={value => this.changeTeamInfo("about_team", value)} text={this.state.about_team} height="150px" />
            </RichTextEditorWrapper>
          </InputWrapper>
          <InputWrapper mt="35px">
            <div>
              {/* <RequiredWrap> * </RequiredWrap> */}
            Team Benefits
            </div>
            <RichTextEditorWrapper>
              <RichTextEditor saveRTF={value => this.changeTeamInfo("other_benefits", value)} text={this.state.other_benefits} height="150px" />
            </RichTextEditorWrapper>
          </InputWrapper>
          <InputWrapper mt="35px">
            <div>
              {/* <RequiredWrap> * </RequiredWrap> */}
              Team Icon
            </div>
            <ImageUploaderContainer>
              <ImageUploader
                buttonText="Upload/Edit Team Icon"
                onChange={(picture) => { this.createReactImageCropReader(picture, "icon") }}
                imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                maxFileSize={5242880}
                singleImage
              />
              {/* https://staging-radius.imgix.net/BlankImage.svg */}
              {imageLoading ? <div>uploading....</div> : (
                <ImageWrapper>
                  {this.state.iconUrl ? (
                    <>
                      <img width="300" style={{ border: "2px solid #096dd9" }} src={this.state.iconUrl} />
                      <CloseCircleTwoToneIcon
                        onClick={() => { this.removeImage("icon") }}
                      />
                    </>
                  ) : (
                    <>
                      <img width="200" src="https://staging-radius.imgix.net/BlankImage.svg" />
                    </>
                  )}
                </ImageWrapper>
              )}
            </ImageUploaderContainer>
            {iconEditShow && (<PhotoEditor type={type} imageSrc={iconSrc} uploadImage={this.uploadTeamCoverImage} closeImageEdit={this.closeImageEdit} cropConfig={1} />)}
          </InputWrapper>
          <InputWrapper mt="35px">
            <ImageContainer>
              <div>
                Team Lead Image
              </div>
            </ImageContainer>
            <ImageUploaderContainer>
              <ImageUploader
                buttonText="Choose Admin/Team Lead Image"
                onChange={(picture) => { this.createReactImageCropReader(picture, "adminImage") }}
                imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                maxFileSize={5242880}
                singleImage
              />
              {imageLoading ? <div>uploading....</div> : (
                <ImageWrapper>
                  {this.state.adminImageUrl ? (
                    <>
                      <img width="300" style={{ border: "2px solid #096dd9" }} src={this.state.adminImageUrl} />
                      <CloseCircleTwoToneIcon
                        onClick={() => { this.removeImage("adminImage") }}
                      />
                    </>
                  ) : (<img width="200" src="https://staging-radius.imgix.net/BlankImage.svg" />)}
                </ImageWrapper>

              )}
            </ImageUploaderContainer>
            {adminImageEditShow && (<PhotoEditor type={type} imageSrc={adminImageSrc} uploadImage={this.uploadTeamCoverImage} closeImageEdit={this.closeImageEdit} cropConfig={1} />)}
          </InputWrapper>

          <InputWrapper mt="35px">
            <ImageContainer>
              <div>
                {/* <RequiredWrap> * </RequiredWrap> */}
                Team Cover Image
              </div>
            </ImageContainer>
            <ImageUploaderContainer>
              <ImageUploader
                buttonText="Upload Team Cover Image"
                onChange={(picture) => { this.createReactImageCropReader(picture, "teamCoverImage") }}
                imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                maxFileSize={5242880}
                singleImage
              />
              {imageLoading ? <div>uploading....</div> : (
                <ImageWrapper>
                  {this.state.teamCoverImageUrl ? (
                    <>
                      <img width="300" style={{ border: "2px solid #096dd9" }} src={this.state.teamCoverImageUrl} />

                      <CloseCircleTwoToneIcon
                        onClick={() => { this.removeImage("teamCoverImage") }}
                      />
                    </>
                  ) : (<img width="200" src="https://staging-radius.imgix.net/BlankImage.svg" />)}
                </ImageWrapper>
              )}
            </ImageUploaderContainer>
          </InputWrapper>
          {teamCoverImageEditShow && (<PhotoEditor type={type} imageSrc={teamCoverImageSrc} uploadImage={this.uploadTeamCoverImage} closeImageEdit={this.closeImageEdit} />)}

          <InputWrapper mt="35px">
            <ImageContainer>
              <div>
                Team Gallery Image 1
              </div>
            </ImageContainer>
            <ImageUploaderContainer>
              <ImageUploader
                buttonText="Upload Team Gallery Image 1"
                onChange={(picture) => { this.createReactImageCropReader(picture, "teamGalleryImage1") }}
                imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                maxFileSize={5242880}
                singleImage
              />
              {imageLoading ? <div>uploading....</div> : (
                <ImageWrapper>
                  {this.state.teamGalleryImage1Url ? (
                    <>
                      <img width="300" style={{ border: "2px solid #096dd9" }} src={this.state.teamGalleryImage1Url} />
                      <CloseCircleTwoToneIcon
                        onClick={() => { this.removeImage("teamGalleryImage1") }}
                      />
                    </>
                  ) : (<img width="200" src="https://staging-radius.imgix.net/BlankImage.svg" />)}
                </ImageWrapper>
              )}
            </ImageUploaderContainer>
          </InputWrapper>
          {teamGalleryImage1EditShow && (<PhotoEditor type={type} imageSrc={teamGalleryImage1Src} uploadImage={this.uploadTeamCoverImage} closeImageEdit={this.closeImageEdit} cropConfig={1} />)}

          <InputWrapper mt="35px">
            <ImageContainer>
              <div>
                Team Gallery Image 2
              </div>
            </ImageContainer>
            <ImageUploaderContainer>
              <ImageUploader
                buttonText="Upload Team Gallery Image 2"
                onChange={(picture) => { this.createReactImageCropReader(picture, "teamGalleryImage2") }}
                imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                maxFileSize={5242880}
                singleImage
              />
              {imageLoading ? <div>uploading....</div> : (
                <ImageWrapper>
                  {this.state.teamGalleryImage2Url ? (
                  <>
                    <img width="300" style={{ border: "2px solid #096dd9" }} src={this.state.teamGalleryImage2Url} />
                    <CloseCircleTwoToneIcon
                      onClick={() => { this.removeImage("teamGalleryImage2") }}
                    />
                  </>
                  ) : (<img width="200" src="https://staging-radius.imgix.net/BlankImage.svg" />)}
                </ImageWrapper>
              )}
            </ImageUploaderContainer>
          </InputWrapper>
          {teamGalleryImage2EditShow && (<PhotoEditor type={type} imageSrc={teamGalleryImage2Src} uploadImage={this.uploadTeamCoverImage} closeImageEdit={this.closeImageEdit} cropConfig={1} />)}

          <InputWrapper mt="35px">
            <ImageContainer>
              <div>
                Team Gallery Image 3
              </div>
            </ImageContainer>
            <ImageUploaderContainer>
              <ImageUploader
                buttonText="Upload Team Gallery Image 3"
                onChange={(picture) => { this.createReactImageCropReader(picture, "teamGalleryImage3") }}
                imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                maxFileSize={5242880}
                singleImage
              />
              {imageLoading ? <div>uploading....</div> : (
                <ImageWrapper>
                  {this.state.teamGalleryImage3Url ? (
                  <>
                    <img width="300" src={this.state.teamGalleryImage3Url} />
                    <CloseCircleTwoToneIcon
                      onClick={() => { this.removeImage("teamGalleryImage3") }}
                    />
                  </>
                  ) : (<img width="200" src="https://staging-radius.imgix.net/BlankImage.svg" />)}
                </ImageWrapper>
              )}
            </ImageUploaderContainer>
          </InputWrapper>
          {teamGalleryImage3EditShow && (<PhotoEditor type={type} imageSrc={teamGalleryImage3Src} uploadImage={this.uploadTeamCoverImage} closeImageEdit={this.closeImageEdit} cropConfig={1} />)}

          <InputWrapper mt="35px">
            <div>
              <input
                name="product_visible"
                type="checkbox"
                checked={this.state.visibleOnLandingPage}
                onChange={() => {
                  this.setState({
                    visibleOnLandingPage: !this.state.visibleOnLandingPage,
                  })
                }}
              />
              <Label htmlFor="product_visible">Visible on Product</Label>
            </div>
          </InputWrapper>
          {/* <ButtonWrap style={{ width: "50%", margin: "auto" }}>
            <Button onClick={() => this.saveTeam({})} success disabled={!this.state.name} type="primary" block size="large">
              Save Team
            </Button>
          </ButtonWrap> */}
        </div>
      )}
    </>
    )
  }

  handleAgentSearchChange = (e) => {
    this.setState({
      agentSearchText: e.target.value,
    }, () => this.search(this.state.agentSearchText))
  }

  handleReferringAgentChange = (e) => {
    this.setState({
      referringAgentSearchText: e.target.value,
    }, () => this.search(this.state.referringAgentSearchText, true))
  }

  addToTeam = (agent) => {
    const members = this.state.members
    members.push(agent)
    this.createTeam({ saveTeamMemberOnly: true })
    this.setState({ members, currentSelectedAgent: null })
  }

  search = async (value, isReferringAgentSearch) => {
    const searchBody = {
      ascending: false,
      facade: false,
      from: 0,
      size: 10,
      sortIsAsc: false,
      text_search_params: ["email", "firstname", "lastname", "full_name"],
      text_value: value,
      unRegistered: false,
    }

    let resp = await fetch(`${baseUrl}/v1.0/agent/elastic/query`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "internal-id": token,
        "X-User-Agent": "agent-php",
      },
      body: JSON.stringify(searchBody),
    })

    resp = await resp.json()
    if (this.state.members && this.state.members.length > 0) {
      const currentMemberIds = this.state.members.map(agent => agent.id)
      resp.response.agents = resp.response.agents.filter(agent => currentMemberIds.indexOf(agent.id) === -1)
    }
    if (!isReferringAgentSearch) {
      this.setState({ searchResults: resp.response.agents })
    } else {
      this.setState({ referringAgentSearchResults: resp.response.agents })
    }
  }

  changeTeamName = (value) => {
    this.setState({ name: value })
  }

  changeTeamCity = (value) => {
    this.setState({ city: value })
  }

  changeTeamState = (value) => {
    this.setState({ state: value })
  }

  changeTeamInfo = (key, value) => {
    this.setState({ [key]: value })
  }

  changeTeamProductionVolume = (value) => {
    this.setState({ productionVolume: value })
  }

  changePrimarySalesRepresentative = (value) => {
    this.setState({ primary_sale_representative: value })
  }

  changeSecondarySalesRepresentative = (value) => {
    this.setState({ secondary_sale_representative: value })
  }

  changePrimarySalesRepresentativeSplit = (value) => {
    this.setState({ primary_sale_representative_split_percentage: value })
  }

  changeSecondarySalesRepresentativeSplit = (value) => {
    this.setState({ secondary_sale_representative_split_percentage: value })
  }

  changePastBrokerageName = (value) => {
    this.setState({ past_brokerage_name: value })
  }

  handleOpenModal() {
    this.setState({ showModal: true })
  }

  handleOpenInviteTCModal(value) {
    this.setState({ showInviteTCModal: value })
  }

  handleOpenJobCreateModal(flag) {
    this.setState({ showJobCreateModal: flag })
  }

  handleViewJobModal(flag) {
    this.setState({ viewJobModal: flag })
  }

  handleCloseModal() {
    this.setState({
      currentSelectedAgent: null, searchResults: [], agentSearchText: null, showModal: false,
    })
  }

  handleAgentSelect = (agent) => {
    agent.is_brokerage_admin = false
    this.setState({
      currentSelectedAgent: agent,
      searchResults: [],
      agentSearchText: "",
    })
  }

  handleReferringAgentSelect = (agent) => {
    agent.is_brokerage_admin = false
    this.setState({
      currentReferringAgentSelected: agent,
      referringAgentSearchResults: [],
      referringAgentSearchText: "",
    })
  }

  getTeams = async (teamId) => {
    try {
      this.setState({
        teamInfoLoading: true,
      })
      let teams = await fetch(`${baseUrl}/v1.0/brokerage-teams?id=${teamId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "internal-id": token,
        },
      })
      teams = await teams.json()
      teams = teams.response
      const team = teams[0]

      let teamTCs = await fetch(`${baseUrl}/v1.0/brokerage-team-tc/${teamId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "internal-id": token,
        },
      })
      teamTCs = await teamTCs.json()
      teamTCs = teamTCs.response
      // const teamAllTCs = teamTCs[0]

      const {
        members,
        name,
        id,
        icon_url: iconUrl,
        admin_image_url: adminImageUrl,
        visible_on_landing_page,
        city,
        state,
        production_volume,
        about_team,
        other_benefits,
        team_images,
        profile_name,
        is_cashback_supported,
        hide_radius_logo,
        brand_primary_color,
        brand_background_color,
        past_brokerage_name,
        primary_sale_representative,
        secondary_sale_representative,
        primary_sale_representative_split_percentage,
        secondary_sale_representative_split_percentage,
      } = team || {}
      let teamCoverImageUrl = team_images ? team_images.find(im => im.type === "banner") : ""
      teamCoverImageUrl = teamCoverImageUrl ? teamCoverImageUrl.path : ""
      let teamGalleryImage1Url = team_images ? team_images.find(im => im.type === "other") : ""
      teamGalleryImage1Url = teamGalleryImage1Url ? teamGalleryImage1Url.path : ""
      let teamGalleryImage2Url = team_images ? team_images.find(im => im.type === "other" && im.path !== teamGalleryImage1Url) : ""
      teamGalleryImage2Url = teamGalleryImage2Url ? teamGalleryImage2Url.path : ""
      let teamGalleryImage3Url = team_images ? team_images.find(im => im.type === "other" && im.path !== teamGalleryImage1Url && im.path !== teamGalleryImage2Url) : ""
      teamGalleryImage3Url = teamGalleryImage3Url ? teamGalleryImage3Url.path : ""
      this.setState({
        city,
        state,
        name,
        members,
        id,
        iconUrl,
        adminImageUrl,
        visibleOnLandingPage: Boolean(visible_on_landing_page),
        productionVolume: production_volume,
        about_team: about_team || "",
        other_benefits: other_benefits || "",
        teamCoverImageUrl,
        teamGalleryImage1Url,
        teamGalleryImage2Url,
        teamGalleryImage3Url,
        is_cashback_supported,
        hide_radius_logo,
        brand_primary_color,
        brand_background_color,
        primary_sale_representative,
        secondary_sale_representative,
        primary_sale_representative_split_percentage,
        secondary_sale_representative_split_percentage,
        past_brokerage_name,
        profile_name,
        teamTCs,
      })
      this.setState({
        teamInfoLoading: false,
      })
    } catch (e) {
      console.log(e)
      bugsnagClient.notify(e)
    }
  }

  componentDidMount() {
    const { pathname } = root.location || ""
    if (pathname.indexOf("create") !== -1) {
      this.setState({ view: "create" })
      this.createDefaultTeam()
    } else {
      const { teamId } = this.props.match.params
      this.setState({ view: "edit" })
      this.getTeams(teamId)
    }
  }

  setCurrentAgentAdminRules = (val) => {
    const currentSelectedAgent = this.state.currentSelectedAgent
    currentSelectedAgent.is_brokerage_admin = val
    this.setState({ currentSelectedAgent })
  }

  getCreateView() {
    const keysDiv = this.getNameDiv()
    const {
      agentSearchText,
      searchResults,
      showModal,
      currentSelectedAgent,
      inviteType,
    } = this.state

    return (
      <div>
        {keysDiv}
        <div>
          <Modal
            visible={showModal}
            onOk={() => {
              if (this.state.currentSelectedAgent) {
                this.addToTeam(currentSelectedAgent); this.handleCloseModal()
              }
            }}
            onCancel={() => this.handleCloseModal()}
          >
            <div className="modal-for-team">
              <div>
                <h2>Add new team member</h2>
                <span>
                  <TeamMemberInput placeholder="Search agent" onChange={this.handleAgentSearchChange} value={this.state.agentSearchText} />
                </span>
                <React.Fragment>
                  {this.state.agentSearchText
                    && this.state.searchResults
                    && this.state.searchResults.length > 0
                    && this.state.searchResults.map(item => (
                      <React.Fragment>
                        <StyledDiv onClick={() => this.handleAgentSelect(item)}>
                          <h5>
                            {item.firstname || ""}
                            {" "}
                            {item.lastname || ""}
                          </h5>
                          <small>{item.email}</small>
                          <hr />
                        </StyledDiv>
                        <br />
                      </React.Fragment>
                    ))}
                </React.Fragment>
              </div>
              <div>
                {this.state.currentSelectedAgent
                  && (
                    <div style={dealForm}>
                      <h5>
                        Adding Agent:
                        {this.state.currentSelectedAgent.firstname || " "}
                        {" "}
                        {this.state.currentSelectedAgent.lastname || " "}
                      </h5>
                      <div>
                        <div className="deal-text">
                          <span className="label-text">
                            Team Lead:
                          </span>
                          &nbsp;
                          <select value={this.state.currentSelectedAgent.is_brokerage_admin ? 1 : 0} onChange={e => this.setCurrentAgentAdminRules(parseInt(e.target.value, 10))}>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <p>Invite Status for this agent</p>
                <Radio.Group
                  onChange={(e) => {
                    this.setState({
                      inviteType: e.target.value,
                    })
                  }}
                  value={inviteType}
                >
                  <Space direction="vertical">
                    <Radio value={1}>Invited</Radio>
                    <Radio value={2}>Accepted</Radio>
                    <Radio value={3}>Declined</Radio>
                  </Space>
                </Radio.Group>
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <p>Add referring agent</p>
                <span>
                  <TeamMemberInput placeholder="Search referring agent" onChange={this.handleReferringAgentChange} value={this.state.referringAgentSearchText} />
                </span>
                <React.Fragment>
                  {this.state.referringAgentSearchText
                    && this.state.referringAgentSearchResults
                    && this.state.referringAgentSearchResults.length > 0
                    && this.state.referringAgentSearchResults.map(item => (
                      <React.Fragment>
                        <StyledDiv onClick={() => this.handleReferringAgentSelect(item)}>
                          <h5>
                            {item.firstname || ""}
                            {" "}
                            {item.lastname || ""}
                          </h5>
                          <small>{item.email}</small>
                          <hr />
                        </StyledDiv>
                        <br />
                      </React.Fragment>
                    ))}
                </React.Fragment>
              </div>
              <div>
                {this.state.currentReferringAgentSelected
                  && (
                    <div style={dealForm}>
                      <h5>
                        Referring Agent:
                        {this.state.currentReferringAgentSelected.firstname || " "}
                        {" "}
                        {this.state.currentReferringAgentSelected.lastname || " "}
                      </h5>
                    </div>
                  )
                }
              </div>
              <br />
              <br />
              <br />
              {/* <Button ghost onClick={() => this.handleCloseModal()} style={{ marginRight: 20 }}>Close</Button>
              <Button disabled={!this.state.currentSelectedAgent} success onClick={() => { this.addToTeam(this.state.currentSelectedAgent); this.handleCloseModal() }}>Save</Button> */}
            </div>
          </Modal>
        </div>
      </div>
    )
  }

  getInviteTCModalView() {
    // const keysDiv = this.getNameDiv()
    const {
      agentSearchText,
      searchResults,
      showInviteTCModal,
      currentSelectedAgent,
      tcFullName,
      tcEmail,
    } = this.state

    return (
      <div>
        {/* {keysDiv} */}
        <div>
          <Modal
            visible={showInviteTCModal}
            okText="Invite TC"
            onOk={async () => {
              if (tcFullName && tcEmail) {
                this.handleOpenInviteTCModal(false)
                await this.createTCInvitation()
              }
            }}
            onCancel={() => {
              this.settcDetails("tcEmail", null)
              this.settcDetails("tcFullName", null)
              this.handleOpenInviteTCModal(false)
            }}
          >
            <div className="modal-for-team">
              <div>
                <h2>Add new TC</h2>
                <span>
                  <Input placeholder="Full Name" allowClear onChange={e => this.settcDetails("tcFullName", e.target.value)} />
                </span>
                <span>
                  <Input style={{ marginTop: 10 }} type="email" placeholder="Email (Invitation will be sent to this email)" allowClear onChange={e => this.settcDetails("tcEmail", e.target.value)} />
                </span>
                <React.Fragment>
                </React.Fragment>
              </div>

              {/* <Button ghost onClick={() => this.handleCloseModal()} style={{ marginRight: 20 }}>Close</Button>
              <Button disabled={!this.state.currentSelectedAgent} success onClick={() => { this.addToTeam(this.state.currentSelectedAgent); this.handleCloseModal() }}>Save</Button> */}
            </div>
          </Modal>
        </div>
      </div>
    )
  }

  createTCInvitation = async () => {
    const {
      tcFullName, tcEmail, id: teamId, members,
    } = this.state
    const teamLeadId = members.find(mm => mm.is_brokerage_admin)?.id
    const body = {
      agent_id: teamLeadId,
      firstname: tcFullName,
      lastname: "",
      email: tcEmail,
      share_link: `https://radiusagent.com/signup?agent_id=null&invite_type=tc_login&team_id=${teamId}&email_id=${tcEmail}`,
      brokerage_team_id: teamId,
    }
    try {
      let resp = await fetch(`${baseUrl}/v1.0/brokerage-team-tc`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "internal-id": token,
          "X-User-Agent": "agent-php",
        },
        body: JSON.stringify(body),
      })
      resp = await resp.json()
      if (resp?.error?.message) {
        message.error(resp?.error?.message)
      }
      this.getTeams(teamId)
      this.settcDetails("tcFullName", "")
      this.settcDetails("tcEmail", "")
    } catch (err) {
      alert(1)
      message.error("Something went wrong, Try again later!")
    }
  }

  getInviteType = (id) => {
    switch (id) {
      case 1:
        return "invited"
      case 2:
        return "joined"
      case 3:
        return "declined"
      default:
        return "invited"
    }
  }

  createTeam = async ({ saveTeamMemberOnly }) => {
    try {
      const { history } = this.props
      const {
        teamCoverImageUrl, teamGalleryImage1Url, teamGalleryImage2Url, teamGalleryImage3Url,
        currentReferringAgentSelected, inviteType,
      } = this.state
      let url = "/v1.0/brokerage-teams"
      let method = "POST"
      if (this.state.id) {
        url = `/v1.0/brokerage-teams/${this.state.id}`
        method = "PUT"
      }

      const getAboutTeams = () => {
        const arr_img = []
        if (teamCoverImageUrl) {
          arr_img.push({
            url: teamCoverImageUrl,
            type: "banner",
          })
        }
        if (teamGalleryImage1Url) {
          arr_img.push({
            url: teamGalleryImage1Url,
            type: "other",
          })
        }
        if (teamGalleryImage2Url) {
          arr_img.push({
            url: teamGalleryImage2Url,
            type: "other",
          })
        }
        if (teamGalleryImage3Url) {
          arr_img.push({
            url: teamGalleryImage3Url,
            type: "other",
          })
        }
        return arr_img
        //PhotoEditor
      }

      const body = {
        name: this.state.name,
        city: this.state.city,
        state: this.state.state,
        icon_url: this.state.iconUrl,
        admin_image_url: this.state.adminImageUrl,
        production_volume: Number(this.state.productionVolume),
        visible_on_landing_page: Number(this.state.visibleOnLandingPage),
        about_team: this.state.about_team,
        other_benefits: this.state.other_benefits,
        array_images: getAboutTeams(),
        is_cashback_supported: this.state.is_cashback_supported,
        hide_radius_logo: this.state.hide_radius_logo,
        brand_primary_color: this.state.brand_primary_color,
        brand_background_color: this.state.brand_background_color,
        past_brokerage_name: this.state.past_brokerage_name,
        primary_sale_representative: this.state.primary_sale_representative,
        secondary_sale_representative: this.state.secondary_sale_representative,
        primary_sale_representative_split_percentage: Number(this.state.primary_sale_representative_split_percentage),
        secondary_sale_representative_split_percentage: Number(this.state.secondary_sale_representative_split_percentage),
      }
      // const coverImage = body.array_images.find(im => im.type === "banner")
      if (!body.name) {
        if (saveTeamMemberOnly) {
          message.error("First fill all required fields for team and save !")
          const { members } = this.state
          members.pop()
          this.setState({
            activeKey: "1",
            members: [...members],
          })
          return
        }
        message.error("Please Fill All The Required Fields For Team Info")
        return
      }
      if (saveTeamMemberOnly && body.name && !this.state.id) {
        message.error("Save Team Info Details First before adding Team Member!")
        const { members } = this.state
        members.pop()
        this.setState({
          activeKey: "1",
          members: [...members],
        })
        return
      }
      this.setState({
        teamInfoLoading: true,
        apiCallActive: true,
      })
      let resp = await fetch(baseUrl + url, {
        method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "internal-id": token,
          "X-User-Agent": "agent-php",
        },
        body: JSON.stringify(body),
      })

      resp = await resp.json()

      let teamId = this.state.id
      if (!this.state.id) {
        teamId = resp.response.id
      }
      if (this.state.members) {
        const body = {
          members: this.state.members.map((member) => {
            const agent = {}
            agent.agent_id = member.id
            agent.is_admin = member.is_brokerage_admin
            agent.brokerage_team_id = teamId

            return agent
          }),
        }

        const {
          members,
        } = this.state || {}
        const lastAgentAdded = [...members].pop()

        const requestBody = {
          brokerage_team_id: teamId,
          agent_id: lastAgentAdded.id,
          is_admin: lastAgentAdded.is_brokerage_admin,
          invite_status: this.getInviteType(inviteType),
          sender_id: currentReferringAgentSelected ? currentReferringAgentSelected.id : null,
        }

        const newApiResponse = await fetch(`${baseUrl}/v1.0/brokerage-team/add-member`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "internal-id": token,
            "X-User-Agent": "agent-php",
          },
          body: JSON.stringify(requestBody),
        })

        this.getTeams(teamId)

        console.log(requestBody)

        // const resp = await fetch(`${baseUrl}/v1.0/brokerage-team/${teamId}/member`, {
        //   method: "POST",
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //     "internal-id": token,
        //     "X-User-Agent": "agent-php",
        //   },
        //   body: JSON.stringify(body),
        // })
      }
      this.setState({ apiCallActive: false, teamInfoLoading: false })
      // notify.show("Team added successfully!", "success", 5000)

      //window.location.assign(`/rar/teams`)
      if (!this.state.id) {
        if (saveTeamMemberOnly) {
          message.error("Save Team Info Details First before adding Team Member!")
          const { members } = this.state
          members.pop()
          this.setState({
            activeKey: "1",
            members: [...members],
          })
          return
        }
        history.push("/broker/teams/list")
      }
      if (saveTeamMemberOnly) {
        message.success("Team updated successfully!")
      } else {
        message.success("Team Info save successfully!")
      }
    } catch (e) {
      console.log("e", e)
      message.error("Something went wrong. Try again later")
      // notify.show("Something went wrong, please try again", "error", 5000)
      this.setState({ apiCallActive: false })
    }
  }

  saveTeam = async ({ saveTeamMemberOnly }) => {
    try {
      const { history } = this.props
      const search = get(root, "location.search", {})

      const parsedQuery = queryString.parse(search)
      const {
        agentId,
      } = parsedQuery || {}

      const {
        teamCoverImageUrl, teamGalleryImage1Url, teamGalleryImage2Url, teamGalleryImage3Url,
        currentReferringAgentSelected,
      } = this.state
      let url = "/v1.0/brokerage-teams"
      let method = "POST"
      if (this.state.id) {
        url = `/v1.0/brokerage-teams/${this.state.id}`
        method = "PUT"
      }

      const getAboutTeams = () => {
        const arr_img = []
        if (teamCoverImageUrl) {
          arr_img.push({
            url: teamCoverImageUrl,
            type: "banner",
          })
        }
        if (teamGalleryImage1Url) {
          arr_img.push({
            url: teamGalleryImage1Url,
            type: "other",
          })
        }
        if (teamGalleryImage2Url) {
          arr_img.push({
            url: teamGalleryImage2Url,
            type: "other",
          })
        }
        if (teamGalleryImage3Url) {
          arr_img.push({
            url: teamGalleryImage3Url,
            type: "other",
          })
        }
        return arr_img
        //PhotoEditor
      }

      const body = {
        name: this.state.name,
        city: this.state.city,
        state: this.state.state,
        icon_url: this.state.iconUrl,
        admin_image_url: this.state.adminImageUrl,
        production_volume: Number(this.state.productionVolume),
        visible_on_landing_page: Number(this.state.visibleOnLandingPage),
        about_team: this.state.about_team,
        other_benefits: this.state.other_benefits,
        array_images: getAboutTeams(),
        is_cashback_supported: this.state.is_cashback_supported,
        hide_radius_logo: this.state.hide_radius_logo,
        brand_primary_color: this.state.brand_primary_color,
        brand_background_color: this.state.brand_background_color,
        past_brokerage_name: this.state.past_brokerage_name,
        primary_sale_representative: this.state.primary_sale_representative,
        secondary_sale_representative: this.state.secondary_sale_representative,
        primary_sale_representative_split_percentage: Number(this.state.primary_sale_representative_split_percentage),
        secondary_sale_representative_split_percentage: Number(this.state.secondary_sale_representative_split_percentage),
      }
      // const coverImage = body.array_images.find(im => im.type === "banner")
      if (!body.name) {
        if (saveTeamMemberOnly) {
          message.error("First fill all required fields for team and save !")
          const { members } = this.state
          members.pop()
          this.setState({
            activeKey: "1",
            members: [...members],
          })
          return
        }
        message.error("Please Fill All The Required Fields For Team Info")
        return
      }
      if (saveTeamMemberOnly && body.name && !this.state.id) {
        message.error("Save Team Info Details First before adding Team Member!")
        const { members } = this.state
        members.pop()
        this.setState({
          activeKey: "1",
          members: [...members],
        })
        return
      }
      this.setState({
        teamInfoLoading: true,
        apiCallActive: true,
      })
      let resp = await fetch(baseUrl + url, {
        method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "internal-id": token,
          "X-User-Agent": "agent-php",
        },
        body: JSON.stringify(body),
      })

      resp = await resp.json()

      let teamId = this.state.id
      if (!this.state.id) {
        teamId = resp.response.id
      }
      if (this.state.members) {
        const body = {
          members: this.state.members.map((member) => {
            const agent = {}
            agent.agent_id = member.id
            agent.is_admin = member.is_brokerage_admin
            agent.brokerage_team_id = teamId
            agent.invite_status = member.invite_status

            return agent
          }),
        }

        const resp = await fetch(`${baseUrl}/v1.0/brokerage-team/${teamId}/member`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "internal-id": token,
            "X-User-Agent": "agent-php",
          },
          body: JSON.stringify(body),
        })
      }
      this.setState({ apiCallActive: false, teamInfoLoading: false })
      // notify.show("Team added successfully!", "success", 5000)

      //window.location.assign(`/rar/teams`)
      if (!this.state.id) {
        if (saveTeamMemberOnly) {
          message.error("Save Team Info Details First before adding Team Member!")
          const { members } = this.state
          members.pop()
          this.setState({
            activeKey: "1",
            members: [...members],
          })
          return
        }
        history.push("/broker/teams/list")
      }

      if (agentId) {
        history.push(`/broker/dashboard/${agentId}/subscription`)
      }

      if (saveTeamMemberOnly) {
        message.success("Team updated successfully!")
      } else {
        message.success("Team Info save successfully!")
      }
    } catch (e) {
      console.log("e", e)
      message.error("Something went wrong. Try again later")
      // notify.show("Something went wrong, please try again", "error", 5000)
      this.setState({ apiCallActive: false })
    }
  }

  handleRemoveTeamMember = async (teamId, members) => {
    try {
      const body = {
        members: members.map((item) => {
          const currentDetails = { ...item }
          currentDetails.agent_id = item.id
          currentDetails.is_admin = item.is_brokerage_admin
          currentDetails.brokerage_team_id = parseInt(teamId, 10)
          return currentDetails
        }),
      }

      await fetch(`${baseUrl}/v1.0/brokerage-team/${teamId}/member`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "internal-id": token,
          "X-User-Agent": "agent-php",
        },
        body: JSON.stringify(body),
      })
      // notify.show("Team added successfully!", "success", 5000)
    } catch (e) {
      console.log(e)
      message.error("Something went wrong. Try again later")
      // notify.show("Something went wrong, please try again", "error", 5000)
      this.setState({ apiCallActive: false })
    }
  }

  tabChange = (key) => {
    this.setState({
      activeKey: key,
    })
  }

  showConfirm = (record) => {
    const { deleteJobs } = this.props
    const { jobSearch, teamId } = this.state
  }

  removeMember = (id) => {
    const { teamId: currentTeamId } = this.props.match.params
    confirm({
      title: "Do you Want to remove this Agent!",
      icon: <ExclamationCircleOutlined />,
      content: "You can again add him back from Add New Team Member option.",

      onOk: () => {
        const members = this.state.members.filter(agent => agent.id !== id)
        this.setState({ members })
        // this.createTeam({ saveTeamMemberOnly: true })
        this.handleRemoveTeamMember(currentTeamId, members)
      },
    })
  }

  updateTeamInviteStatus = (id, value) => {
    const { teamId: currentTeamId } = this.props.match.params

    confirm({
      title: "Do you want to update the team invite status?",
      icon: <ExclamationCircleOutlined />,
      content: "You can change this again by choosing a different status again.",

      onOk: () => {
        const {
          members,
        } = this.state || {}
        const currentMembers = [...members]
        currentMembers.forEach((item) => {
          const curr = item
          if (item.id === id) {
            curr.invite_status = value
          }
        })
        this.setState({ members: [...currentMembers] })
        //this.createTeam({ saveTeamMemberOnly: false })
        this.handleTeamMemberInviteStatus(currentTeamId, members)
      },
    })
  }

  handleTeamMemberInviteStatus = async (teamId, members) => {
    try {
      const body = {
        members: members.map((item) => {
          const currentDetails = { ...item }
          currentDetails.agent_id = item.id
          currentDetails.is_admin = item.is_brokerage_admin
          currentDetails.brokerage_team_id = parseInt(teamId, 10)
          return currentDetails
        }),
      }

      await fetch(`${baseUrl}/v1.0/brokerage-team/${teamId}/member`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "internal-id": token,
          "X-User-Agent": "agent-php",
        },
        body: JSON.stringify(body),
      })
      notify.show("Invite Status Updated successfully!", "success", 5000)
    } catch (e) {
      console.log(e)
      message.error("Something went wrong. Try again later")
      // notify.show("Something went wrong, please try again", "error", 5000)
      this.setState({ apiCallActive: false })
    }
  }

  getMembersDiv = () => {
    console.log("===this.state.members=====000009999999880---===", this.state.members)
    // if (this.state.members && this.state.members.length === 0) {
    //   return <span>Add new members to the team!</span>
    // }
    const membersDiv = (
      <React.Fragment>
        <Table
          columns={[
            {
              title: "Agent Name",
              dataIndex: "firstname",
              key: "firstname",
              width: "10%",
              render: (text, record, index) => (
                <span>
                  {`${record.firstname || " "} ${record.lastname || " "}`}
                </span>
              ),
            },
            {
              title: "Member Type",
              dataIndex: "is_brokerage_admin",
              key: "is_brokerage_admin",
              width: "10%",
              render: (text, record, index) => (
                <span>
                  <Tag color={record.is_brokerage_admin ? "green" : "geekblue"}>
                    {record.is_brokerage_admin ? "Team Lead" : "Team Member"}
                  </Tag>
                </span>
              ),
            },
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              width: "10%",
              render: (text, record, index) => (
                <Button type="link" danger onClick={() => this.removeMember(record.id)}>Remove</Button>
              ),
            },
            {
              title: "Invite Status",
              dataIndex: "invite_status",
              key: "invite_status",
              width: "10%",
              render: (text, record, index) => (
                <>
                  {console.log("record", record.invite_status)}
                  <Select
                    onChange={value => this.updateTeamInviteStatus(record.id, value)}
                    style={{
                      width: 250,
                    }}
                    value={record.invite_status}
                  >
                    <Select.Option value="invited">Invited</Select.Option>
                    <Select.Option value="joined">Accepted</Select.Option>
                    <Select.Option value="declined">Declined</Select.Option>
                  </Select>
                </>
                
              ),
            },
          ]}
          bordered={false}
          dataSource={this.state.members && this.state.members.length ? [...this.state.members] : []}
        />
      </React.Fragment>
    )

    return membersDiv
  }

  getTCsDiv = () => {
    console.log("===this.state.members=====000009999999880---===", this.state.teamTCs)
    // if (this.state.members && this.state.members.length === 0) {
    //   return <span>Add new members to the team!</span>
    // }
    const membersDiv = (
      <React.Fragment>
        <Table
          columns={[
            {
              title: "TC Name",
              dataIndex: "firstname",
              key: "firstname",
              width: "10%",
              render: (text, record, index) => (
                <span>
                  {`${record.firstname || " "} ${record.lastname || " "}`}
                </span>
              ),
            },
            {
              title: "Email",
              dataIndex: "email",
              key: "email",
              width: "10%",
              render: (text, record, index) => (
                <span>
                  {record.email}
                  {/* <Tag color={record.email ? "green" : "geekblue"}>
                    {record.email ? "Team Lead" : "Team Member"}
                  </Tag> */}
                </span>
              ),
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
              width: "10%",
              // render: (text, record, index) => (
              //   <Button type="link" danger onClick={() => this.removeMember(record.id)}>Remove</Button>
              // ),
              render: (text, record, index) => (
                <span>
                  {record.status}
                </span>
              ),
            },
          ]}
          bordered={false}
          dataSource={this.state.teamTCs && this.state.teamTCs.length ? [...this.state.teamTCs] : []}
        />
      </React.Fragment>
    )

    return membersDiv
  }

  getRowtitlesDiv = () => {
    const rowTitles = ["Name", "Role", "Action"]
    const rowTitlesdiv = rowTitles.map(val => (
      <div style={tableCellStyle}>
        {val}
      </div>
    ))
    return rowTitlesdiv
  }

  render() {
    const teamDIv = this.getCreateView()
    const InviteTCModal = this.getInviteTCModalView()
    const { history } = this.props
    const { showJobCreateModal, viewJobModal } = this.state
    //const rowTitlesdiv = this.getRowtitlesDiv();
    const membersDiv = this.getMembersDiv()
    const TCsDiv = this.getTCsDiv()
  
    console.log("this.state=======", this.state)
    return (
      <Wrap>
        <JobCreateModal visible={showJobCreateModal} onClose={() => this.handleOpenJobCreateModal(false)} teamInfo={this.state} />
        <JobViewModal visible={viewJobModal} onClose={() => this.handleViewJobModal(false)} teamInfo={this.state} />
        <Notifications />
        <Header>
          <h1
            style={{
              display: "flex",
              alignItems: "baseline",
              marginLeft: "-40px",
            }}
          >
            <PageHeader
              className="site-page-header"
              onBack={() => history.push("/broker/teams/list")}
              title="Create/Edit Team"
              subTitle="Create/Edit Team Or Create/Edit Jobs"
            />
            &nbsp; &nbsp;
            <Button onClick={() => window.open(`https://radiusagent.com/teamprofile/${this.state.profile_name}`, "_blank")} type="link">View Team Public Page</Button>
            {/* <Button onClick={() => window.open(`https://staging.radiusagent.com/teamprofile/${this.state.profile_name}`, "_blank")} type="link">View Team Public Page</Button> */}
          </h1>
          <ControlsWrap>
            <Button ghost onClick={() => { history.push("/broker/teams/list") }}>
              Cancel
            </Button>
          </ControlsWrap>
          <ButtonWrap>
            <Button size="large" onClick={() => this.handleViewJobModal(true)} disabled={this.state.apiCallActive}>View Jobs</Button>
            <Button type="primary" size="large" onClick={() => this.handleOpenJobCreateModal(true)} disabled={this.state.apiCallActive}>Create Job</Button>
          </ButtonWrap>
        </Header>
        <Tabs defaultActiveKey="1" activeKey={this.state.activeKey} onChange={this.tabChange} tabBarExtraContent={
        <Button type="primary" style={{ marginRight: "45px" }} size="large" onClick={() => this.saveTeam({})} success disabled={!this.state.name}>
          Save
        </Button>
      }>
          <TabPane tab="Team Info" key="1">
            <br />
            <br />
            {teamDIv}
            <br />
            <br />
            <br />
            <br />
          </TabPane>
          <TabPane tab="Team Members" key="2">
            <>
              <br />
              <br />
              <Button type="primary" onClick={() => this.handleOpenModal(true)} disabled={this.state.apiCallActive}>Add new team member</Button>
              <br />
              <br />
              <span style={{ fontSize: 12, color: "#444" }}>Add new members to the team by clicking on the add agents button!</span>
              {this.state.members && this.state.members.length > 0

                && (
                  <div>
                    <span style={{ fontSize: 12, color: "#444" }}>If you want to change a team member's role, please delete them and add them again with the new role!</span>
                    <br />
                    <br />
                    <span>
                      {" "}
                      {"Team Members"}
                    </span>
                    <br />
                    <br />
                    <div>
                      {/* <div>
                {rowTitlesdiv}
              </div> */}
                      {membersDiv}
                    </div>
                  </div>
                )
              }
            </>
          </TabPane>
          <TabPane tab="Team TCs" key="3">
            <>
              <br />
              <br />
              <Button type="primary" onClick={() => this.handleOpenInviteTCModal(true)} disabled={this.state.apiCallActive}>Add new TC</Button>
              <br />
              <br />
              <span style={{ fontSize: 12, color: "#444" }}>Invite new TC to the team by clicking on the add TC button!</span>
              {this.state.members && this.state.members.length > 0

                && (
                  <div>
                    <span style={{ fontSize: 12, color: "#444" }}>If you want to change a team member's role, please delete them and add them again with the new role!</span>
                    <br />
                    <br />
                    <span>
                      {" "}
                      {"Team TCs"}
                    </span>
                    <br />
                    <br />
                    <div>
                      {/* <div>
                {rowTitlesdiv}
              </div> */}
                      {TCsDiv}
                      {InviteTCModal}
                    </div>
                  </div>
                )
              }
            </>
          </TabPane>
        </Tabs>
      </Wrap>
    )
  }
}

export default withRouter(BrokerageTeam)
