import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/ProductInterest"
import { reduxForm, Field } from "redux-form"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"


const DescriptionForm = styled.form`
  padding: 53px 53px 0px;
`

const InputFields = styled(Field)`
  width: 100%;
  height: 39px;
  border-radius: 3px;
  border: solid 1px #d1d5da;
  background-color: #fafbfc;
  margin-bottom: 37px;
  padding-left: 9px;
`

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 150px;
  border-radius: 3px;
  border: solid 1px #d1d5da;
  background-color: #fafbfc;
  margin-bottom: 37px;
  padding-left: 9px;
`


const renderField = ({ input, label, type, textarea, meta: { touched, error, warning, invalid }, value }) => {
    const textareaType = <StyledTextArea value="hello" {...input} type={type} className={`form-control ${touched && invalid ? 'has-danger' : ''}`}/>;
    const inputType = <input {...input} placeholder={label}  type={type} className={`form-control ${touched && invalid ? 'has-danger' : ''}`}/>;

    return (
        <div>
            <label>{label}</label>
            <div>
                {textarea ? textareaType : inputType}
                {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
            </div>
        </div>
    );
};

class ProductDescriptionForm extends Component {
  componentDidMount(){
    const {
      currentEditableProduct,
      initialize,
    } = this.props

    if (currentEditableProduct){
      initialize({
       productTitle: currentEditableProduct.title,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      currentEditableProduct,
      initialize,
      isChildView,
      isNewFeatureOrChild,
      isEditingExisting,
    } = this.props
    if (this.props.isNewFeatureOrChild !== prevProps.isNewFeatureOrChild && this.props.isNewFeatureOrChild){
      initialize({
       productTitle: "",
      })
    }

    if (!isNewFeatureOrChild && isChildView && Object.keys(currentEditableProduct).length > 0  && isEditingExisting !== prevProps.isEditingExisting){
      initialize({
       productTitle: currentEditableProduct.title,
      })
    }

    if(!isNewFeatureOrChild && isChildView && Object.keys(currentEditableProduct).length > 0 && currentEditableProduct.title !== prevProps.currentEditableProduct.title) {
      initialize({
       productTitle: currentEditableProduct.title,
      })
    }

  }

  render(){
    const {
      currentEditableProduct,
      isChildView,
    } = this.props

    const {
      description,
    } = currentEditableProduct || {}

    return (
      <DescriptionForm id='description-form'>
        {isChildView && (
          <div>
            <label htmlFor="Product Name">
             Title
             <span className="required">*</span>
            </label>
            <InputFields name="productTitle" component="input" type="text" />
          </div>
        )}
      </DescriptionForm>
    )
  }
}


ProductDescriptionForm = reduxForm({
  form: 'ProductDescriptionForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(ProductDescriptionForm)


export default Container(ProductDescriptionForm)
