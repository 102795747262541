import React, { PureComponent } from "react"
import styled from "styled-components"
import CookiesStorage from "services/CookieStorage"
import TechAssetsContainer from "container/TechAssets"
// import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import { withRouter } from "react-router-dom"
// import ZeroState from "dumbComponents/common/ZeroState"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import {
  Select,
  Input,
} from "dumbComponents/common/UI/antd"
import colors from "../../../../lib/colors"
import OffersListing from "./OfferListing"
import {
  BuyerAgentInfoRoute,
  OfferStatuses,
} from "../../consts"

const { Search } = Input
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 42px;
  .ant-select-selector {
    width: 200px;
    border: 1px solid rgb(199, 199, 199) !important;
    border-radius: 4px;
  }
`

const ListingContainer = styled.div`
  margin-top: 28px;
`

const AddNewOffer = styled.button`
  height: 36px;
  width: 188px;
  border-radius: 100px;
  background: ${colors.marineBLue};
  color: ${colors.white};
  cursor: pointer;
  border: none;
  font-size: 16px;
  font-weight: 200;
  font-family: 'ATC';

  span img {
    height: 15px;
    position: relative;
    left: 11px;
    top: 2px;
  }
`
const ListingHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.jc || "space-between"};
  ${props => props.mt && `
    margin-top: ${props.mt}
  `}
  ${props => props.fd && `
    flex-direction: ${props.fd}
  `}
`
const DropDownHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  margin-left: 5px;
  h2 {

  }
  span {
    margin-left: 5px;
  }
`

const FilterSpace = styled.div`display: flex;`
const FilterInnerSpace = styled.div`
  padding: 10px 0 0 20px;
`
class Offers extends PureComponent {
  state = {
    agentId: CookiesStorage.load("agentId"),
  }

  componentDidMount() {
    const {
      getGoogleAuthToken,
    } = this.props

    getGoogleAuthToken()

    this.getOfferCall()
  }

  getOfferCall = () => {
    const {
      agentId,
    } = this.state

    const {
      getOffers,
    } = this.props

    getOffers({
      skip: 0,
      limit: 100,
      query: `&agentId=${agentId}`,
    })
  }

  setStatusFilter = (value) => {
    const {
      getOffers,
    } = this.props

    const {
      agentId,
    } = this.state

    const query = `&agentId=${agentId}&offerType=${value}`

    getOffers({
      skip: 0,
      limit: 100,
      agentId,
      query: value !== undefined ? query : "",
    })
  }

  searchOnLocal = (text) => {
    this.setState({
      searchText: text,
    })
  }

  getFilteredListings = () => {
    const { getAllOffersActionResponse } = this.props
    const { searchText: text, statusFilter } = this.state
    const searchText = text ? text.toLowerCase() : ""
    if (getAllOffersActionResponse.data) {
      const filterBySearchdeals = getAllOffersActionResponse
        .data?.filter(dt => dt?.listing_address?.toLowerCase()?.includes(searchText)
        || dt.city?.toLowerCase()?.includes(searchText)
        || dt.state?.toLowerCase()?.includes(searchText)
        || dt.client_name?.toLowerCase()?.includes(searchText)
        || !searchText
      )
      const filterByStatusDeals = filterBySearchdeals
        .filter(dt => dt.displayDealStatus === statusFilter
          || !statusFilter)
      return filterByStatusDeals
    }
    return []
  }

  addNewOffer = () => {
    const {
      history,
      resetForm,
    } = this.props

    resetForm()
    history.push(BuyerAgentInfoRoute)
  }

  render() {
    const {
      getAllOffersActionResponse,
    } = this.props

    const {
      data: offers,
      isFetching: fetchingOffers,
    } = getAllOffersActionResponse || {}

    return (
      <Wrap>
        <ListingHeader
          jc="flex-end"
        >
          <FilterSpace>
            <FilterInnerSpace>
              <Search
                placeholder="Search by address or agent name"
                onSearch={(value) => { this.searchOnLocal(value) }}
                style={{ width: 300, border: "1px solid #C7C7C7", borderRadius: "4px" }}
              />
            </FilterInnerSpace>
          </FilterSpace>
        </ListingHeader>
        <ListingHeader
          mt="10px"
          fd="row"
        >
          <AddNewOffer
            onClick={this.addNewOffer}
          >
            Submit an Offer
            <span>
              <img src={PlusIcon} alt="test" />
            </span>
          </AddNewOffer>
          <FilterSpace>
            <Select
              style={{
                width: 200,
                marginLeft: "10px",
                border: "1px solid black !important",
              }}
              placeholder="Select Status"
              onChange={(value) => { this.setStatusFilter(value) }}
              allowClear
              options={OfferStatuses}
              onClear={() => {
                this.getOfferCall()
              }}
            />
          </FilterSpace>
        </ListingHeader>
        <ListingContainer>
          {fetchingOffers ? (
            <p>Loading</p>
          ) : (
            <>
              {offers && this.getFilteredListings().length > 0 ? (
                <>
                  <ul>
                    {this.getFilteredListings().map(offersData => (<OffersListing listingData={offersData} />))}
                  </ul>
                </>
              ) : (
                <>
                  <p>Empty</p>
                  {/* <ZeroState
                    height="474px"
                    width="100%"
                    backgroundColor="#F9F9F7"
                    text="No Offers Submitted"
                    logo=""
                    onClickHandler={() => { }}
                    displayStyle="flex"
                    alignItems="center"
                    justifyContent="center"
                    buttonCTAText=""
                    innerBoxDisplayStyle="flex"
                    innerBoxFlexDirection="column"
                    innerBoxContentJustification="center"
                    innerBoxItemAlign="center"
                  /> */}
                </>
              )}

            </>
          )}

        </ListingContainer>
      </Wrap>
    )
  }
}

export default withRouter(TechAssetsContainer(Offers))
