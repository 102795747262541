import React, { Component } from 'react';
import bugsnag from 'bugsnag-js';
import Notifications, {notify} from 'react-notify-toast';
import styled from "styled-components";
import AutoSuggest from "../../UI/AutoSuggest/AutoSuggest";
import {searchAgent} from "../../API/AgentApi";
import {NotifyBugsnagError} from "../../../lib/ErrorUtils";
import {DefaultLoader} from "../../UI/Loader/Loader";

const baseUrl =process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;
const originalKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(originalKey);

const ModalTitle = styled.h3`
  font-size: 24px;
  font-weight: 500;
  color: #354052;
  margin-top: 0;
`;

const ModalIcon = styled.i`
  float: right;
  color: #dadada;
  font-size: 22px;
  margin-right: -20px;
  cursor: pointer;
`;

class PrioritizeSettings extends Component {

    state = {
        consultant: null,
        prioritized_agents: [],
        prioritizedAgentIds: [],
    };

    startLoader = () => {
        this.setState({
            loading: true
        });
    };
    stopLoader = () => {
        this.setState({
            loading: false
        });
    };

    setConsultantData = (consultant) => {
        try{
            if (consultant){
                let callSettings = consultant.call_settings;
                let prioritizedAgentIds = [];
                let prioritized_agents = [];
                if (callSettings.PrioritizedAgentIds) {
                    prioritizedAgentIds = JSON.parse("[" + callSettings.PrioritizedAgentIds + "]")
                }
                if(callSettings.prioritized_agents) {
                    prioritized_agents = callSettings.prioritized_agents;
                }
                this.setState({ prioritized_agents: prioritized_agents,
                    prioritizedAgentIds: prioritizedAgentIds, consultant: consultant});
            }
        }
        catch(e){
            console.log(e);
            bugsnagClient.notify(e);
        }
    };

    componentWillReceiveProps(props) {
        this.setConsultantData(this.props.consultant);
    }

    componentDidMount() {
        this.setConsultantData(this.props.consultant);
    }

    handleCancel = () => {
        //event.preventDefault();
        this.props.closePrioritizeModal();
    };

    searchAgent = async (currentSearchText) => {
        if(!currentSearchText || currentSearchText.length < 3) {
            return [];
        }
        try {
            return await searchAgent(currentSearchText).then(res => {
                return res.response.agents.map((agent) => {
                    let cli = {};
                    cli.name = agent.firstname + ' ' + agent.lastname;
                    cli.firstname = agent.firstname;
                    cli.lastname = agent.lastname;
                    cli.agent_image = agent.agent_image;
                    cli.email = agent.email;
                    cli.id = agent.id;
                    return cli;
                });
            });
        }
        catch(e) {
            console.log(e);
            NotifyBugsnagError(e);
        }
    };

    getSuggestionValue = (suggestion) => {
        this.addPrioritizedAgent(suggestion);
        return suggestion.name ? suggestion.name : suggestion.email;
    };

    addPrioritizedAgent = (suggestion) => {
        try{
            let prioritizedAgentIds = this.state.prioritizedAgentIds ? this.state.prioritizedAgentIds : [];
            let prioritized_agents = this.state.prioritized_agents ?  this.state.prioritized_agents : [];
            let isPresent = false;
            if (prioritizedAgentIds && prioritizedAgentIds.length > 0) {
                for (let index=0 ; index<prioritizedAgentIds.length ; index++) {
                    if (suggestion.id === prioritizedAgentIds[index]) {
                        isPresent = true;
                    }
                }
            }
            if (!isPresent) {
                prioritizedAgentIds.push(suggestion.id);
                prioritized_agents.push({firstname: suggestion.firstname, lastname: suggestion.lastname, email: suggestion.email});
            }
            this.setState({ prioritized_agents: prioritized_agents, prioritizedAgentIds: prioritizedAgentIds});
        }
        catch(e){
            console.log(e);
            bugsnagClient.notify(e);
        }
    };

    removePrioritizedAgent = (agentId, agentEmail) => {
        try{
            let prioritizedAgentIds = this.state.prioritizedAgentIds;
            let prioritized_agents = this.state.prioritized_agents;
            let prioritizedAgentIds_updated = [];
            if (prioritizedAgentIds && prioritizedAgentIds.length > 0) {
                for (let index = 0; index < prioritizedAgentIds.length; index++) {
                    if (prioritizedAgentIds[index] != agentId) {
                        prioritizedAgentIds_updated.push(prioritizedAgentIds[index]);
                    }
                }
            }
            let prioritized_agents_updated = [];
            if (prioritized_agents && prioritized_agents.length > 0) {
                for (let index = 0; index < prioritized_agents.length; index++) {
                    if (prioritized_agents[index].email != agentEmail) {
                        prioritized_agents_updated.push(prioritized_agents[index]);
                    }
                }
            }
            this.setState({ prioritized_agents: prioritized_agents_updated,
                prioritizedAgentIds: prioritizedAgentIds_updated});
        }
        catch(e){
            console.log(e);
            bugsnagClient.notify(e);
        }
    };

    save = async () => {
        try {
            this.startLoader();
            let call_settings =  this.props.consultant.call_settings;
            call_settings.PrioritizedAgentIds = JSON.stringify(this.state.prioritizedAgentIds).replace("[","").replace("]","");
            delete call_settings["prioritized_agents"];
            let body = {
                is_pool:this.props.isEditingPoolSetting,
                call_settings: call_settings,
            };
            let response = await fetch(baseUrl + '/v1.0/consultants/edit/' + this.props.consultant.id, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'secure-id': token,
                },
                body: JSON.stringify(body)

            });
            let respJson = await response.json();
            await this.props.loadAccounts();
            await this.props.closePrioritizeModal();
            this.stopLoader();
        }
        catch (e) {
            await this.props.loadAccounts();
            await this.props.closePrioritizeModal();
            console.log(e);
            bugsnagClient.notify(e);
            this.stopLoader();
        }
    };

    render() {
        const props = this.props;
        let prioritized_agents = this.state.prioritized_agents;

        if(!this.state.consultant) {
            return <div>Loading</div>
        }
        let prioritizedAgentsDiv = null;

        if(prioritized_agents && prioritized_agents.length > 0) {
            prioritizedAgentsDiv = prioritized_agents.map((prioritized_agent) =>
                <tr>
                    <td>
                        {prioritized_agent.firstname} {prioritized_agent.lastname}
                    </td>
                    <td>
                        {prioritized_agent.email}
                    </td>
                    <td>
                        <button
                            className="btn-primary "
                            onClick={this.removePrioritizedAgent.bind(this, prioritized_agent.id, prioritized_agent.email)}
                            style={{
                                width: '150px',
                                background: '#00adf3',
                                marginLeft: '20px',
                            }}
                        >
                            Remove
                        </button>


                    </td>
                </tr>);
        }

        return <div className={"caller-settings-modal"} style={{'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto'}}>
            <React.Fragment>
                <Notifications />

                <ModalTitle>
                    PRIORITIZE AGENTS
                    <ModalIcon
                        className={"fas fa-times modal-icon"}
                        onClick={props.closePrioritizeModal}
                    />
                </ModalTitle>

                {this.state.loading ? <DefaultLoader/> :
                    <div>
                        <div>
                            <AutoSuggest getSuggestions={this.searchAgent}
                                         getSuggestionValue={this.getSuggestionValue}/>
                        </div>
                        <div style={{paddingBottom: '10px'}}>
                            <table>
                                {prioritizedAgentsDiv}
                            </table>
                        </div>

                        <div>
                            <button className="modal-cancel-btn" onClick={this.handleCancel}>
                                Cancel
                            </button>
                            <button type={"submit"} disabled={this.state.loading} className="modal-submit-btn"
                                    onClick={this.save}>Save
                            </button>
                        </div>
                    </div>
                }

            </React.Fragment>
        </div>

    }

}


export default PrioritizeSettings;