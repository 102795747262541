import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  color: red;
  font-size: 12px;
  margin: 10px 0px;
`

const Note = () => (
  <Wrap>
    <strong>NOTE: </strong>
    Total number of user in the pool should be less than or equal to
    max parallel calls and parallel calls should less than or equal to (3 x total pool user).
  </Wrap>
)

export default Note
