import React, { useState } from "react"
import styled from "styled-components"
import { DownloadOutlined } from "@ant-design/icons"
import {
  Button,
  Select,
  DatePicker,
} from "antd"
import { withRouter } from "react-router-dom"
import AgentSearch from "dumbComponents/BrokerDashboard/Components/AgentSearch"
import container from "container/BrokerDashboard/globalPagesContainer"
import RecruitAndEarn from "../../../container/RecruitAndEarn"

const { Option } = Select

const OuterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px
`

const Wrap = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: flex-start;
 gap: 20px;
`

const InputWrap = styled.div`
  max-width: 293px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: baseline;
  height: max-content;
`

const Label = styled.label`
  min-width: fit-content;
`

const DownloadWrap = styled.div`
  width: 100%;
  text-align: end;
`

const SearchControls = (props) => {
  const [currentFilter, setFilter] = useState("")
  const [currentSort, setSort] = useState("")
  const [currentMilestone, setCurrentMilestone] = useState("")
  const [dateValue, setDate] = useState("")
  const [queryStore, setQueryStore] = useState([])

  const {
    fetchReferrals,
    fetchAllEarnings,
    showStatusFilter,
    clearSetSearchValues,
    history,
    getTotalRecruitAndEarnStats,
    setFilterQueries,
    downloadCSV,
    downloadReferralCSV,
  } = props

  /**
   * get local query param
   * call api
   * set global value of query param
   * To write an object store.
   */

  const handleQuerySet = (queryPayload) => {
    let tempString = ""
    let temp = []
    if (queryStore && queryStore.length <= 0) {
      setFilterQueries({
        queryObject: [queryPayload],
      })

      temp = [queryPayload]
      tempString = `skip=0&limit=300&${queryPayload.name}=${queryPayload.value}&`
      setQueryStore(temp)
    } else {
      temp = [...queryStore, queryPayload]

      tempString = "skip=0&limit=300&"

      temp.forEach((item) => {
        tempString += `${item.name}=${item.value}&`
      })

      setQueryStore(temp)
    }

    /**API CALLS HERE**/

    /**
     * // if referrals tab call fetchReferrals 
     * else if financials tab call fetchAllEarnings
     */

    if (showStatusFilter) {
      fetchReferrals({
        queryParams: tempString,
      })
    } else {
      fetchAllEarnings({
        queryParams: tempString,
      })
    }

    getTotalRecruitAndEarnStats({
      query: tempString,
    })
    /**API CALLS HERE */
  }

  const handleRecieverAgent = (agent) => {
    if (agent === undefined || agent === null || !agent) return false
    const queryPayload = {
      name: "receiverId",
      value: agent.id,
    }

    handleQuerySet(queryPayload)
    setFilter(null) //why this?
    return true
  }

  const handleStatusChange = (value) => {
    if (!value || value === undefined) return false
    const queryPayload = {
      name: "status",
      value,
    }
    handleQuerySet(queryPayload)
    setFilter(value)

    return true
  }

  const handleSort = async (value) => {
    const queryPayload = {
      name: "sort",
      value,
    }
    handleQuerySet(queryPayload)
    setSort(value)
  }

  const handleMilestone = async (value) => {
    const queryPayload = {
      name: "milestone",
      value,
    }
    handleQuerySet(queryPayload)
    setCurrentMilestone(value)
  }

  const handleInviteSenderDetailsFetch = (agent) => {
    if (agent === undefined || agent === null || !agent) return false
    const queryPayload = {
      name: "senderId",
      value: agent.id,
    }
    handleQuerySet(queryPayload)

    return true
  }

  const onDateChange = (event) => {
    const unixValue = event && event.unix() || ""
    if (unixValue) {
      const queryPayload = {
        name: "referredDate",
        value: unixValue,
      }
      handleQuerySet(queryPayload)
      setDate(event)
    }
  }

  return (
    <OuterWrap>
      <DownloadWrap>
        <Button
          type="primary"
          shape="round"
          size="large"
          onClick={() => {
            if (showStatusFilter) {
              downloadReferralCSV()
            } else {
              downloadCSV()
            }
          }}
          icon={<DownloadOutlined />}
        >
          Download CSV
        </Button>
      </DownloadWrap>
      <Wrap>
        <InputWrap>
          <Label htmlFor="agent">Referring Agent</Label>
          <AgentSearch
            onAgentSelect={handleInviteSenderDetailsFetch}
            handleRecieverAgent
            placeholder="Search Referring Agent"
            customInputStyling={{
              height: "34px",
              borderRadius: "0px",
            }}
            type="referred"
          />
        </InputWrap>
        {!showStatusFilter && (
          <InputWrap>
            <Label htmlFor="Filter">Milestone</Label>
            <Select
              style={{
                width: 120,
              }}
              onChange={e => handleMilestone(e)}
              placeholder="milestone"
              value={currentMilestone || null}
            >
              <Option value="0">Joined</Option>
              <Option value="1">1st Deal</Option>
              <Option value="10">10th Deal</Option>
              <Option value="20">20th Deal</Option>
              <Option value="30">30th Deal</Option>
              <Option value="40">40th Deal</Option>
              <Option value="50">50th Deal</Option>
            </Select>
          </InputWrap>
        )}
        {showStatusFilter && (
          <>
            <InputWrap>
              <Label htmlFor="referredBy">Referred Agent</Label>
              <AgentSearch
                onAgentSelect={handleRecieverAgent}
                placeholder="Search Receiver Agent"
                customInputStyling={{
                  height: "34px",
                  borderRadius: "0px",
                }}
                type="sender"
              />
            </InputWrap>
            <InputWrap>
              <Label htmlFor="Filter">Filter by Invite Status</Label>
              <Select
                style={{
                  width: 140,
                }}
                onChange={e => handleStatusChange(e)}
                value={currentFilter || null}
                placeholder="Filter by"
              >
                <Option value="invited">Invited</Option>
                <Option value="joined">Joined</Option>
                <Option value="declined">Declined</Option>
                <Option value="non_responsive">Non-Responsive</Option>
                <Option value="reaching_out">Reaching Out</Option>
                <Option value="in_progress">In Progress</Option>
              </Select>
            </InputWrap>
          </>
        )}
      </Wrap>
      <Wrap>
        {showStatusFilter && (
          <InputWrap>
            <Label htmlFor="Filter">Referred Date</Label>
            <DatePicker allowClear={false} onChange={onDateChange} value={dateValue} />
          </InputWrap>
        )}
        <InputWrap>
          <Label htmlFor="Filter">Sort</Label>
          <Select
            style={{
              width: 120,
            }}
            onChange={e => handleSort(e)}
            placeholder="Sort by"
            value={currentSort || null}
          >
            <Option value="date">Date</Option>
          </Select>
        </InputWrap>
        <InputWrap>
          <Button
            danger
            onClick={() => {
              clearSetSearchValues()
              setSort("")
              setCurrentMilestone("")
              setFilter(null)
              setDate("")
              setQueryStore([])
              const queryParams = "skip=0&limit=300"
              if (showStatusFilter) {
                fetchReferrals({
                  queryParams,
                })
              } else {
                fetchAllEarnings({
                  queryParams,
                })
              }

              getTotalRecruitAndEarnStats()
            }}
          >
            Reset Filters
          </Button>
        </InputWrap>
        <InputWrap>
          <Button
            type="primary"
            onClick={() => {
              history.push("/broker/invite")
            }}
          >
            Invite Agent
          </Button>
        </InputWrap>
      </Wrap>
    </OuterWrap>
  )
}

export default withRouter(container(RecruitAndEarn(SearchControls)))
