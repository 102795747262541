import React from "react"
import styled from "styled-components"
import { Switch, Route, Redirect, withRouter } from "react-router-dom"
import RARTeam from "components/RARTeam"
import RARTeamJobs from "components/RARTeamJobs"
import RARJobApplicants from "components/RARJobApplicants"
import BrokerageTeam from "components/BrokerageTeam"

const Wrap = styled.div`
  
`

const Teams = (props) => {
  return (
    <Switch>
      <Route path="/broker/teams/list" component={RARTeam} />
      <Route path="/broker/teams/jobs" component={RARTeamJobs} />
      <Route path="/broker/teams/applicants/:jobId" component={RARJobApplicants} />
      <Route path="/broker/teams/edit/:teamId" component={BrokerageTeam} />
      <Redirect path="/broker/teams" to="/broker/teams/list"/>
    </Switch>
  )
}

export default withRouter(Teams)