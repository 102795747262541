import React, { useState } from "react"
import { ChromePicker } from "react-color"
import styled from "styled-components"
import { Input } from "antd"
import { isString } from "lodash"

const Container = styled.div`
  position: relative;
`

const ColorPickerContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 40px;
  left: 0;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  border: 1px solid #d9d9d9;
  height: fit-content;
`

const ShowSelectedColor = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${props => props.color};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CloseIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 0.5rem;
  border-radius: 100%;
  border: 1px solid #d9d9d9;
  height: 24px;
  width: 24px;

  svg {
    height: 18px;
    width: 18px;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`

const CloseBackdrop = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`

const ColorPickerWithInput = ({ color, onChange, onChangeComplete }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [isValid, setIsValid] = useState(true)

  const handleClick = () => {
    setDisplayColorPicker(true)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
    setIsValid(true)

    if (color?.length) {
      const isHex = /^#[0-9A-F]{6}$/i.test(color)

      if (!isHex) {
        setIsValid(false)
      }
    }
  }

  const handleChange = (newColor) => {
    if (onChange && newColor?.hex) {
      setIsValid(true)
      onChange(newColor.hex)
    }
  }

  const handleChangeComplete = (newColor) => {
    if (onChangeComplete && newColor?.hex) {
      setIsValid(true)
      onChangeComplete(newColor.hex)
    }
  }

  const handleInputChange = ({ target }) => {
    const { value } = target

    if (value?.length) {
      let newValue = value

      if (!value.includes("#")) {
        newValue = `#${value}`
      }

      if (onChange) {
        onChange(newValue)
      }
    } else if (!value?.length && onChange) {
      onChange("")
    }

    setIsValid(true)
  }

  return (
    <Container>
      <Input
        addonBefore={<ShowSelectedColor color={color} />}
        value={color}
        onChange={handleInputChange}
        onClick={handleClick}
        maxLength={7}
        status={isValid ? null : "error"}
      />

      {
        !isValid && (
          <span style={{ color: "red" }}>Invalid color value. Use a Hex code. Example - #ff0000</span>
        )
      }

      {displayColorPicker ? (
        <>
          <CloseBackdrop onClick={handleClose} />

          <ColorPickerContainer>
            <CloseIcon onClick={handleClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z" />
              </svg>
            </CloseIcon>

            <ChromePicker
              disableAlpha
              color={color?.length > 0 && isString(color) ? color : ""}
              onChange={handleChange}
              onChangeComplete={handleChangeComplete}
            />
          </ColorPickerContainer>
        </>
      ) : (
        <></>
      )}
    </Container>
  )
}

export default ColorPickerWithInput
