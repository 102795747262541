import React from "react"
import styled from "styled-components"
import NotFoundGif from "images/soul/pulp-fiction-wtf.gif"

const Wrap = styled.div`
  margin: 50px auto;
  text-align: center; 
`

const NoResults = () => (
  <Wrap>
    <img src={NotFoundGif} alt="" />
    <h2>No Data Found!</h2>
  </Wrap>
)

export default NoResults