import React from "react"
import { Timeline } from "antd"
import styled from "styled-components"
import { convertToHTML, getISTTime } from "../Utils"

const Wrap = styled.div`
  overflow: scroll;
`

const Title = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`

const AgentActivity = (props) => {
  const {
    data: timelines,
  } = props || {}

  return (
    <Wrap>
      <Title>Agent&apos;s Activity Timeline</Title>
      {timelines && Object.keys(timelines).length > 0 ? (
        <Timeline>
          {timelines.map(item => (
            <Timeline.Item key={item.created_at}>
              <span>{`${getISTTime(item.created_at)} : `}</span>
              <span
                dangerouslySetInnerHTML={{ __html: convertToHTML(item.styled_text && item.styled_text || "") }}
              >
              </span>
            </Timeline.Item>
          ))}
        </Timeline>
      ) : (
        <p>N/A</p>
      )}
    </Wrap>
  )
}

export default AgentActivity
