import React, { Component } from "react"
import styled from "styled-components"
import { reduxForm, Field } from "redux-form"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"

const LessonsForm = styled.form`
 display: flex;
 flex-direction: column;
 margin-bottom: -250px;
 label {
  font-family: "SF Pro Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #24292e;
 }
`
const UpperDiv = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
`

const LowerDiv = styled.div`
  width: 100%;
  height: 350px;
  padding: 50px;
  button {
   float: right;
  }
`

const InfoDiv = styled.div`
  width: 250px;
  height: 350px;
  flex-grow: 3;
  .fields {
   margin-bottom: 30px;
   label {
    padding-bottom: 10px;
   }
  }
  .info {
   font-size: 12px !important;
   margin: 0;
   color: #586069;
  }
`

const InputFields = styled(Field)`
  width: 100%;
  height: 39px;
  border-radius: 3px;
  border: solid 1px #d1d5da;
  background-color: #fafbfc;
  margin-top: 10px;
  padding-left: 9px;
  ${props => props.custom && `
     position: relative;
     z-index: 99999;
   `}
`

class Video extends React.Component {
  componentDidMount() {
   const {
    courseDetail,
    initialize,
    isIndividualLessonMode,
   } = this.props
   
   if(courseDetail && Object.keys(courseDetail).length > 0 && isIndividualLessonMode && isIndividualLessonMode.length > 0){
    let lessonArray = courseDetail.Lessons
    let currentLesson = lessonArray.filter(lesson => lesson.id === isIndividualLessonMode)[0]
    initialize({
     lessonVideo: currentLesson.video_url,
     lessonVideolength: currentLesson.duration_in_seconds / 60,
    })
   }
  }

  render() {
   const {
    handleSubmit,
    courseDetail,
   } = this.props

   return (
     <LessonsForm className="forms" onSubmit={handleSubmit}>
       <UpperDiv>
         <InfoDiv>
           <div className="fields">
             <label htmlFor="Video Content Url">Video Content Url</label>
             <InputFields name="lessonVideo" component="input" type="text" />
             <p className="info">
              Video URL for the lesson.
             </p>
           </div>
           <div className="fields">
             <label htmlFor="Video Length">Video Length</label>
             <InputFields custom name="lessonVideolength" component="input" type="number" />
             <p className="info">
              Lesson length in minutes.
             </p>
           </div>
         </InfoDiv>
       </UpperDiv>
     </LessonsForm>
   )
  }
}

Video = reduxForm({
  form: 'lessonVideoForm',
  destroyOnUnmount: false,
})(Video)

export default Container(Video)
