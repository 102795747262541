import React, { Component } from "react"
import styled from "styled-components"
import { reduxForm, Field } from "redux-form"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"

const required = value => value ? undefined : "* Required"

const InstructorsForm = styled.form`
   display: flex;
   flex-direction: column;
   flex-grow: 2;
   padding-top: 50px;
   padding-right: 11px;
   ${props => props.isUpdateMode && `
      padding-left: 16px;
    `}
   height: 360px;
   .error {
    font-size: 12px;
    color: red;
    margin-bottom: 10px;
   }
 label {
  font-family: "SF Pro Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #24292e;
  .required {
   color: red;
  }
 }
`
const UpperDiv = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
`

const LowerDiv = styled.div`
  width: 100%;
  height: 350px;
  padding: 50px;
  button {
   float: right;
   position: relative;
   top: 207px;
  }
`

const InfoDiv = styled.div`
  width: 250px;
  height: 350px;
  flex-grow: 3;
  padding: 46px;
`


const PhotoDiv = styled.div`
  height: 100%;
  max-width: 250px;
  flex-grow:2;
`

const InputFields = styled(Field)`
  width: 100%;
  height: 39px;
  border-radius: 3px;
  border: solid 1px #d1d5da;
  background-color: #fafbfc;
  margin-bottom: 37px;
  padding-left: 9px;
`
class AddInstructorForm extends React.Component {

 componentDidMount() {
   const {
    existingDetails,
    initialize,
    isUpdateMode,
   } = this.props

   if(
   isUpdateMode
   && isUpdateMode.length > 0
   && existingDetails
   && existingDetails.length > 0) {
    initialize({
     name: existingDetails[0].name,
     email: existingDetails[0].email,
     designation: existingDetails[0].designation,
     firm: existingDetails[0].firm,
     handle: existingDetails[0].handle,
     radius_agent_handle: existingDetails[0].radius_agent_handle,
    })
   }
 }

 render() {
  const {
    handleSubmit,
    error,
    submitFailed,
    isUpdateMode,
   } = this.props

  return (
    <InstructorsForm isUpdateMode={isUpdateMode} id='instructor-form' onSubmit={handleSubmit}>
      {submitFailed && (
       <div className="error">Please fill all the fields marked * (mandatory)</div>
      )}
      <div>
        <label htmlFor="Name">
         Name
         <span className="required">*</span>
        </label>
        <InputFields name="name" component="input" type="text" validate={required}/>
      </div>
      <div>
        <label htmlFor="Designation">
         Designation
        </label>
        <InputFields name="designation" component="input" type="text" />
      </div>
      <div>
        <label htmlFor="email">
         Email
        <span className="required">*</span>
        </label>
        <InputFields name="email" component="input" type="email" validate={required} />
      </div>
      <div>
        <label htmlFor="email">
         Firm
        </label>
        <InputFields
         name="firm"
         component="input"
         type="text"
         />
      </div>
      <div>
        <label htmlFor="handle">
         Handle
        <span className="required">*</span>
        </label>
        <InputFields name="handle" component="input" type="text" validate={required}/>
      </div>
      <div>
        <label htmlFor="Radius Profile">
         Radius Profile (Please provide valid/existing profile url)
        </label>
        <InputFields
         name="radius_agent_handle"
         component="input"
         type="text"
        />
      </div>
    </InstructorsForm>
  )
 }
}

AddInstructorForm = reduxForm({
  form: 'addInstructor',
  destroyOnUnmount: false,
})(AddInstructorForm)

export default Container(AddInstructorForm)
