import React from "react"
//import { Dropdown } from "semantic-ui-react"
import Select from "dumbComponents/common/UI/Select"
import colors from "dumbComponents/common/UI/colors"
import styled from "styled-components"
import Textbox from "./Textbox"

const Bar = styled.span.attrs({
  className: "fg-bar",
})`
  position: absolute;
  width: 0%;
  left: 0px;
  height: 2px;
  background: ${colors.primaryColor};
  bottom: 2px;
  border-radius: 2px;
  transition-duration: 0.3s;
`

const FormGroup = styled.div`
  padding: 3px;
  box-sizing: border-box;
  position: relative;
  margin-top: 24px;
  margin-bottom: 10px;
  position: relative;
  width: 98%;
  bottom: 35px;


  input:focus {
    border-color: ${colors.deepPurple};
    caret-color: ${colors.deepPurple};
  }

  input:focus ~ .fg-label,
  input.fg-input-valid ~ .fg-label {
    font-size: 12px;
  }

  @media(max-width: 767px) {
    width: 100%;
  }
`
const LabelElement = styled.label`
  ${props => props.isSelect && `
     margin-bottom: 10px;
     display: block;
  `}
`

const Error = styled.small`
  display: flex;
  margin-top: 3px;
  line-height: 1.75;
  text-align: left;
  color: #f99fa2;
  position: absolute;
  bottom: -20px;
  font-size: 12px;

`

const Info = styled(Error)`
  color: #354052;
`

const Label = styled.label.attrs({
  className: "fg-label",
})`
  transition-duration: 0.3s;
  background: #FFF;
  pointer-events: none;
  position: absolute;
  bottom: 4px;
  width: 100%;
  font-size: 16px;
  text-align: left;
  display: block;
  line-height: 1.75;
  color: #dadada;
  font-weight: 300;
  padding: 6px 0px;
`

const SelectElement = styled.select`
  display: block;
  width: 100%;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  border-radius: 8px;
  border: 1px solid #949494;
  font-size: 16px;

  &:focus {
    border-color: ${colors.deepPurple};
  }
`

const customStyles = {
  menu: provided => ({
    ...provided,
    marginTop: "-2px",
    border: `1px solid ${colors.deepPurple}`,
    paddingTop: 10,
    paddingBottom: 10,
    cursor: "pointer",
  }),
  control: (provided, state) => ({
    ...provided,
    "&:hover": {
      borderColor: state.isFocused ? colors.deepPurple : colors.slateGrey,
    },
  }),
}

const Input = (props) => {
  let inputElement = null
  let errorMessage = ""
  const {
    touched,
    shouldValidate,
    invalid,
    submitted,
    fieldName,
    label,
    options,
    elementType,
    changed,
  } = props

  if (!touched && shouldValidate) {
    errorMessage = (
      <Info>
        This is a required field.
      </Info>
    )
  }

  if (invalid && shouldValidate && submitted) {
    let errorString
    switch (props.errorType) {
      case "alphanumeric":
        errorString = "No special characters allowed."
        break
      case "required":
        errorString = `${fieldName} is required.`
        break
      case "minlength":
        errorString = `${fieldName} should be atleast + ${shouldValidate.minLength} characters.`
        break
      case "maxlength":
        errorString = `${fieldName} should be upto ${shouldValidate.maxLength} characters.`;
        break
      case "email":
        errorString = `${fieldName} is an invalid email.`
        break
      case "numeric":
        errorString = `${fieldName} is not a valid number.`
        break
      default:
        errorString = `${fieldName} is required.`
        break
    }
    errorMessage = (
      <Error>
        {errorString}
      </Error>
    )
  }

  switch (props.elementType) {
    case "Input":
      inputElement = (
        <Textbox
          {...props.elementConfig}
          value={props.value || ""}
          onChange={props.changed}
        />
      )
      break
    case "textarea":
      inputElement = (
        <textarea
          {...props.elementConfig}
          value={props.value || ""}
          onChange={props.changed}
        />
      )
      break
    case "select":
      inputElement = (
        <Select
          {...props.elementConfig}
          options={options}
          onChange={changed}
          placeholder="Real Estate Agent"
          name="account_type"
          styles={customStyles}
        />
      )
      break
    default:
      inputElement = (
        <Textbox
          {...props.elementConfig}
          value={props.value || ""}
          onChange={props.changed}
        />
      )
  }

  return (
    <FormGroup>
      <LabelElement isSelect={elementType === "select"} htmlFor={label}>{label}</LabelElement>
      {inputElement}
      {errorMessage}
      <Bar />
    </FormGroup>
  )
}

export default Input
