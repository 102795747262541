import React  from 'react';
import {propertyMetrics} from "../../API/AgentApi";
import {NotifyBugsnagError} from "../../../lib/ErrorUtils";
import _ from "lodash";
import {DefaultLoader} from "../../UI/Loader/Loader";

class AgentProfile extends React.Component {

    state = {
        agentProfile: null,
        loading: false,
    };

    startLoader = () => {
        this.setState({loading: true})
    };

    stopLoader = () => {
        this.setState({loading: false})
    };

    openAgentProfile = async (agentId) => {
        try {
            this.startLoader();
            await propertyMetrics(agentId).then(res => {
                this.setState({agentProfile: res.response});
                this.stopLoader();
            });
        } catch(e) {
            console.log(e);
            NotifyBugsnagError(e);
            this.stopLoader();
        }
    };

    componentDidMount() {
        this.openAgentProfile(this.props.agent.id);
    }

    render() {

        let agentProfile = null;
        let imageUrl = null;
        const {agent} = this.props;

        const images = _.filter(agent.images,
            (img) => {
                if (img.type === 'profile') {
                    return true;
                }
            }
        );

        if (images.length > 0) {
            imageUrl = images[0]['path'];
        }

        if (this.state.agentProfile && this.state.agentProfile.metrics) {
            agentProfile = this.state.agentProfile.metrics.map((metric, index) => {
                return <tr key={index}>
                    <td>{metric.span}</td>
                    <td> {metric.buyer_active_properties + ' '}Buyers, {metric.buyer_closed_properties + ' '} Sellers</td>
                    <td> {metric.seller_active_properties + ' '}Buyers, {metric.seller_closed_properties + ' '} Sellers</td>
                    <td> {metric.min_budget}</td>
                    <td> {metric.max_budget}</td>
                    <td> {metric.average_budget}</td>
                </tr>
            });
        }

        return (
            <div>
                {this.state.loading ?
                    <DefaultLoader/>
                    :
                    <div className="modal-1">

                        <div className="modal-header-1">
                            <span className="modal-header-text-1">
                                Deal Volumes and Listings
                            </span>
                        </div>

                        <div>
                            {imageUrl ?
                                <img className="agent-avatar-2" src={imageUrl}/>
                                :
                                <img className="agent-avatar-2"
                                     src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_fO8BlrOnXk250EEyOjWzEt0MiPNkxEKKYK6bYp8DOJOdhsvy4w"/>
                            }
                            <div className="agent-name-in-popup">
                                {agent.firstname ? (agent.firstname + ' ') : ''} {agent.lastname ? agent.lastname : ''}
                            </div>
                        </div>
                        <br/>

                        <table>
                            <tbody>
                                <tr>
                                    <th> Time Period</th>
                                    <th> {(agentProfile && agentProfile.totalActiveProperties ? agentProfile.totalActiveProperties : 0) + ' '} Active
                                        Listings
                                    </th>
                                    <th>{(agentProfile && agentProfile.totalClosedProperties ? agentProfile.totalClosedProperties : 0) + ' '}Closed
                                        Listings
                                    </th>
                                    <th>Minimum Budget</th>
                                    <th>Maximum Budget</th>
                                    <th>Average Budget</th>
                                </tr>
                                {agentProfile}
                            </tbody>
                        </table>

                        <br/>
                        <br/>
                        <br/>
                        <br/>

                        <button className="med-btn-1" onClick={() => {
                            this.props.close();
                        }}>Close
                        </button>

                    </div>
                }
            </div>
        );
    }
}

export default AgentProfile;