import cookie from "services/CookieStorage"
import LogRocketLib from "logrocket"

// temp constant variable

const testEmails = []
class LogRocket {
  initialize = (trackOnlyAuthorized = true) => {
    try {
      const user = cookie.load("user")
      console.log("user==", user)
      // user.twilio_client_name = "test"
      const { origin } = window.location
      // disable base route for soul
      if (!origin.includes("soul.")) {
        return
      }
      if (user.id) {
        // apply for odd even rule like delhi traffic here as well
        // LogRocketLib.init("ksghyr/radiusagent")
        LogRocketLib.init("s3zfme/souldashboard")
        LogRocketLib.identify(user.agent_id, {
          name: `${user.name}`,
          email: `${user.email}`,
          twilio_client_name: `${user.twilio_client_name}`,
        })
      }
    } catch (err) {
      console.warn("Error initializing Logrocket!")
    }
  }
}
export default new LogRocket()
