import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/RecruitAndEarn"

const PendingTable = styled.table``

const TitleWrap = styled.div``

const Name = styled.p`
 margin: 0;
`
const Email = styled.p`
 margin: 0;
 font-size: 12px;
`

const Wrap = styled.div``

class PendingEarnings extends React.Component{
 state = {}
 
 componentDidMount() {
  const {
   inviteList,
  } = this.props
 }

 handleCheckBox = (currObj, ref, currCheck, currAmount) => {
  if(!currObj || !currObj[ref]) {
   this.setState({
    [ref]: {
     is_credited: true,
     amount: currAmount,
    }
   })
  } else {
   this.setState({
    [ref]:{
     is_credited: !currObj[ref].is_credited,
     amount: currObj[ref].amount || currAmount,
    }
   })
  }
 }
 updatePendingEarning = (key, details) => {
   try {
    const {
     updateBrokerEarning,
    } = this.props
    const curr = this.state && this.state[key] || false
    if(!curr) return false
    updateBrokerEarning({
     id: details.id,
     payload: {
      is_credited: curr.is_credited,
      amount: parseInt(curr.amount, 10),
     }
    })
   } catch(e) {
    //do nothing
    console.log(e)
   }

 }

 handleChange = (e) => {
  const currKey = this.state && this.state[e.target.name] || ""
  this.setState({
   [e.target.name]: {
    is_credited: currKey && currKey.is_credited || false,
    amount: e.target.value
   }
  })
 }

 render() {
  const {
   earningData,
  } = this.props

  return(
   <Wrap>
    <TitleWrap>
     <h1>Pending Earnings</h1>
    </TitleWrap>
    <PendingTable>
     <tr>
         <th>Sender</th>
         <th>Receiver</th>
         <th>Client</th>
         <th>Gift Card Amount</th>
         <th>Is Credited?</th>
         <th>Save</th>
     </tr>
     {earningData && earningData.pending && earningData.pending.map((key, index) => (
      <tr>
          <td>
           <Name>{(key.sender_firstname || "") + " " + (key.sender_lastname || "") }</Name>
           <Email>{key.sender_email}</Email>
          </td>
          <td>
           <Name>{(key.firstname || "") + " " + (key.lastname || "") }</Name>
           <Email>{key.email}</Email>
          </td>
          <td>
           <Name>{(key.client_name || "-")}</Name>
           <Email>{key.client_email || "-"}</Email>
          </td>
          <td><input value={this.state && this.state[`${key.sender_email}${key.id}`] && this.state[`${key.sender_email}${key.id}`].amount || key.amount} name={`${key.sender_email}${key.id}`} onChange={this.handleChange} type="number" placeholder="Gift card amount"/></td>
          <td>
           <input
            checked={this.state && this.state[`${key.sender_email}${key.id}`] && this.state[`${key.sender_email}${key.id}`].is_credited || key.is_credited}
            onChange={() => this.handleCheckBox(this.state, `${key.sender_email}${key.id}`, key.is_credited, key.amount)}
            type="checkbox"
            name={`${key.sender_email}${key.id}`}
           />
          </td>
          <td><button onClick={() => this.updatePendingEarning(`${key.sender_email}${key.id}`, key)} disabled={!this.state[`${key.sender_email}${key.id}`]}>Save</button></td>
      </tr>
     ))}
    </PendingTable>
   </Wrap>
  )
 }
}

export default Container(PendingEarnings)
