import React from "react"
import styled from "styled-components"
import { get } from "lodash"
import Section from "./Section"
import CallItem from "./CallItem"
import { CALL_STATUS, GrowTransition } from "./Shared"

const Wrap = styled.div`
  box-shadow: 0 4px 12px -4px #767676;
  position: absolute;
  bottom: 30px;
  padding-bottom: 20px;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  border-right: 1px #CCC solid;
  border-left: 1px #CCC solid;
`

const NoEvents = styled.div`
  padding: 10px;
  text-align: center;
  font-size: 10px;
  color: #999;
  font-weight: bold;
  background: rgba(0, 100,100,0.1);
  background: #FFF;
`

class FullMode extends React.PureComponent {
  state = {}

  componentDidMount() {
    const sectionStates = {}
    Object.keys(CALL_STATUS).forEach((key) => {
      sectionStates[`SECTION_${key}`] = false
    })
    this.setState({
      sectionStates,
    })
  }

  handleArrowClick = (item) => {
    const { sectionStates } = this.state
    this.setState({
      sectionStates: {
        ...sectionStates,
        ...item,
      },
    })
  }

  render() {
    const { sectionStates } = this.state
    const {
      callStatus,
      deleteItemAutomatically,
    } = this.props
    return (
      <Wrap>
        {Object.keys(CALL_STATUS).map(key => (
          <Section
            key={key}
            headerTitle={`${CALL_STATUS[key].value} (${get(callStatus[key], "length", 0)})`}
            color={CALL_STATUS[key].color}
            handleArrowClick={this.handleArrowClick}
            isHide={callStatus[key] && callStatus[key].length === 0}
            isForceHide={sectionStates && sectionStates[`SECTION_${key}`]}
            isHideSection={!get(callStatus[key], "length", 0)}
            nameOfSection={`SECTION_${key}`}
          >
            {callStatus[key] && callStatus[key].length > 0 ? (
              <React.Fragment>
                {callStatus[key].map(callItem => (
                  <CallItem
                    key={callItem.referral_id}
                    deleteItemAutomatically={deleteItemAutomatically}
                    {...callItem}
                  />
                ))}
              </React.Fragment>
            ) : (
              <NoEvents>
                No events
              </NoEvents>
            )}
          </Section>
        ))}
      </Wrap>
    )
  }
}

export default FullMode
