import React, { useState } from "react"
import { Drawer, Timeline } from "antd"
import { toast } from "react-toastify"
import styled from "styled-components"
import axios from "axios"

const DownloadBar = styled.div`
  width: 300px;
  height: 20px;

  div {
    width: ${props => props.percentage}%;
    height: 100%;
    background: green;

    p {
      color: white;
      text-align: center;
    }

    transition: width 0.5s ease-out;
  }
`

const DownloadDrawer = ({
  downloadDrawerOpen,
  toggleDownloadDrawer,
  recordings,
}) => {
  const [downloadStatus, setDownloadStatus] = useState(0)
  const [isDownloading, setDownload] = useState(false)

  const handleClose = () => {
    toggleDownloadDrawer({
      bool: false,
      data: null,
    })
  }

  const handleDownload = async (sUrl, name) => {
    const file = await axios({
      url: sUrl,
      method: "GET",
      responseType: "blob", // important
      onDownloadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setDownloadStatus(percentCompleted)
        if (percentCompleted == 100) {
          setDownload(false)
        }
      },
    })

    if (file) {
      const href = URL.createObjectURL(file.data)
      const link = document.createElement("a")
      link.href = href
      link.setAttribute("download", `${name}.mp4`)
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    }
  }

  return (
    <Drawer
      title="Recording Info"
      width={537}
      placement="right"
      onClose={handleClose}
      visible={downloadDrawerOpen}
    >
      {isDownloading && (
        <p>Download in progress...</p>
      )}
      <DownloadBar
        percentage={downloadStatus}
      >
        <div>
          <p>{`${downloadStatus}%`}</p>
        </div>
      </DownloadBar>
      <h1>Download</h1>
      <Timeline>
        {recordings && recordings.map((item, index) => (
          <Timeline.Item>
            <p>{`Recording ${index + 1}`}</p>
            <button
              type="button"
              disabled={isDownloading}
              onClick={() => {
                toast.success("Download started")
                setDownload(true)
                const name = `Recording_${item.name}_${index}`
                handleDownload(item.aws_s3_url, name)
              }}
            >
              {`Recorded by ${item.name}`}
            </button>
          </Timeline.Item>
        ))}
      </Timeline>
    </Drawer>
  )
}
export default DownloadDrawer
