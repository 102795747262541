import React from "react"
import styled from "styled-components"
import { notify } from "react-notify-toast";
import Select from "react-select"
import { uniq, lowerCase, sortedIndexBy } from "lodash"
import { toast } from "react-toastify"
import Loader from "dumbComponents/common/UI/Loader"
import container from "container/BrokerDashboard/requestsContainer"
import moment from 'moment'

const Wrap = styled.div`
  margin-top: 50px;
  width: 100%;
  padding: 0px 30px;
`

const Header = styled.header`
  display: flex;
  align-items: center;
  > h1 {
    flex: 1;
  }
`

const FilterOptions = styled.div`
  width: 350px;
  display: flex;
  > div {
    flex: 1;
    margin: 0px 5px;
  }
`


const RowWrap = styled.div`
  display: flex;
  border-bottom: 1px solid #CCC;
  padding: 10px 0px;
`

const RowTitle = styled.p`
  font-weight: bold;
  margin: 20px 0px !important;
`

const GlobalTag = styled.div`
  padding: 3px;
  margin: 0px;
  font-size: 12px;
  background: #FF7676;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: 4px;
  margin-right: 10px;
`

const AgentDetails = styled.div`
  width: 300px;
  > p, h6 {
    margin: 0px;
    padding: 0px;
  }

  > h6 {
    font-size: 14px;
    font-weight: 300;
    color: #999;
  }
`

const RequestType = styled.div`
  width: 200px;
  > p, h6 {
    margin: 0px;
    padding: 0px;
  }
`

const LinkDetails = styled.div`
  flex: 1;
  display: flex;
  margin: 0px 20px;

  ${props => props.isRow && `
    padding: 5px 10px;
    border-radius: 10px;
    
  `}

  ${props => props.isEditable ? `
    background: #F9F8FF;
  ` : `
    background: #E5E5E5;
  `}

  ${props => props.isHeader && `
    background: transparent;
  `}

`

const StatusWrap = styled.div`
  width: 160px;
`

const LinkInput = styled.input`
  flex: 1;
  border: none;
  background: transparent;

`

const ConfirmButtonWrap = styled.button`
  background: transparent;
  border: 1px solid #ccc;
  width: 40px;
  color: #999;
  border-radius: 4px;
  cursor: pointer;
  ${props => props.isChanged && `
    background: #2ecc71;
    color: #FFF;
  `}

  ${props => props.isNormal && `
    text-align: center;
    border: 1px solid #E01D59;
    border-radius: 5px;
    > i {
      color: #E01D59;
      &:before {
        left: 0px;
      }
    }
  `}
`

const createStatusValue = (status) => ({
  value: lowerCase(status) , label: status, 
})

const STATUSES = [
  { label: "Setup", value: "Setup" },
  { label: "Access", value: "Access"},
]

function sortByKey(array, key) {
  return array.sort(function(a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

class PendingRequests extends React.PureComponent {
  state = {}

  componentDidMount() {
    const { getPendingRequests } = this.props
    getPendingRequests()
  }
  
  handleLinkSave = (item) => {
    const { resource_mapping_id, link_category } = item
    const updatedLink = this.state[`link-${resource_mapping_id}`]
    if (updatedLink) {
      const { changeLink } = this.props
      const payload = {
        linkCategory: link_category,
        resourceId: resource_mapping_id,
        url: updatedLink,
      }
      changeLink({
        payload,
        callback: () => {
          this.setState({
            [`link-${resource_mapping_id}`]: null
          })
          notify.show("Link updated successfully! 🎉", "success")
        }
      })
    } else {
      toast.success("asasas")
    }
  }

  createOrderByDropdown = (list) => {
    if (list && list.length) {
      const orderList = uniq(list.map(x => x.request_name))
        .map(x => ({
          value: x,
          label: x,
        }))
      return orderList
    }
    return []
  }


  setFilterBy = (selctedItem) => {
    notify.show("asdsad", "success")
    this.setState({
      filterBy: selctedItem,
    })
  }

  handleStatusChange = (item, selectedStatus) => {
    const { changeStatusRequest } = this.props
    changeStatusRequest({
      payload: {
        resourceMappingId: item.resource_mapping_id,
        status: selectedStatus.value,
      },
      callback: () => {
        notify.show("Status updated successfully! 🎉", "success")
      }
    })
  }

  setOrderBy = (selectedValue) => {
    this.setState({
      orderByKey: selectedValue.value,
    })
  }

  render() {
    const { filterBy, orderByKey } = this.state
    const { pendingRequestsResponse } = this.props
    const { data: pendingRequests, isFetching } = pendingRequestsResponse
    let modifiedResult = pendingRequests
    // apply filter by

    if (filterBy) {
      modifiedResult = pendingRequests.filter(x => x.request_name === filterBy.value)
    }

    if (orderByKey) {
      modifiedResult =  pendingRequests.filter(x => x.status === orderByKey)
    }

    return (
      <Wrap>
        <Header>
          <h1>Requests</h1>
          <FilterOptions>
            <Select
              placeholder="Request Type"
              options={this.createOrderByDropdown(pendingRequests)}
              onChange={this.setFilterBy}
              width={200}
            />
             {!filterBy ? (
               <Select
                style={{ display: "none" }}
                placeholder="Status"
                options={[ ...STATUSES, { label: "Locked", value: "Locked"} ]}
                onChange={this.setOrderBy}
              />
             ) : (
              <ConfirmButtonWrap
                isNormal
                onClick={() => {
                  this.setState({
                    filterBy: null
                  })
                }}
              >
                <i className="fa fa-times"></i>
              </ConfirmButtonWrap>
             )}
          </FilterOptions>
        </Header>
        {isFetching ? (
          <Loader />
        ) : (
          <React.Fragment>
            <RowWrap>
              <AgentDetails>
                <RowTitle>
                  Agent Details
                </RowTitle>
              </AgentDetails>
              <RequestType>
                <RowTitle>
                  Request Type
                </RowTitle>
              </RequestType>
              <AgentDetails>
                <RowTitle>
                  Request Date
                </RowTitle>
              </AgentDetails>
              <LinkDetails isHeader>
                <RowTitle>
                  Link Details / Design Ref
                </RowTitle>
              </LinkDetails>
              <StatusWrap>
                <RowTitle>
                  Status
                </RowTitle>
              </StatusWrap>
            </RowWrap>

            {modifiedResult && modifiedResult.length > 0 && modifiedResult.map(item => (
              <RowWrap key={item.resource_mapping_id}>
                <AgentDetails>
                  <p>{`${item.firstName || ""} ${item.lastName || ""}`}</p>
                  <h6>{item.email}</h6>
                </AgentDetails>
                <RequestType>
                  <p>{`${item.request_name}: `}</p>
                  <h6>{item.request_subtext}</h6>
                </RequestType>
                <RequestType>
                  <p>{moment(item.created_at).format('DD-MMM-YYYY')}</p>
                </RequestType>
                <LinkDetails isRow isEditable={item.link_category !== "global"}>
                  {item.link_category === "global" && (
                    <GlobalTag>
                      Global
                    </GlobalTag>
                  )}
                  <LinkInput
                    placeholder="Add Link"
                    disabled={item.link_category === "global" || item.request_type === "assets"}
                    value={this.state[`link-${item.resource_mapping_id}`] || item.link}
                    onChange={(event) => {
                      this.setState({
                        [`link-${item.resource_mapping_id}`]: event.target.value,
                      })
                    }}
                  />
                  {item.link_category !== "global" && (
                    <ConfirmButtonWrap
                      isChanged={!!this.state[`link-${item.resource_mapping_id}`]}
                      onClick={() => {
                        this.handleLinkSave(item)
                      }}
                    >
                      <i className="fa fa-check"></i>
                    </ConfirmButtonWrap>
                  )}
                  
                </LinkDetails>
                <StatusWrap>
                  <Select
                    placeholder="Set up"
                    options={item.request_type === "assets" ? STATUSES : [ ...STATUSES, { label: "Locked", value: "Locked"} ]}
                    defaultValue={createStatusValue(item.status)}
                    onChange={(value) => { this.handleStatusChange(item, value) }}
                  />
                </StatusWrap>
              </RowWrap>
            ))}
          </React.Fragment>
        )}
      </Wrap>
    )
  }
}

export default container(PendingRequests)
