import React, { Component } from "react"
import styled, { css } from "styled-components"
import colors from "lib/colors"
import PlanList from "./PlanList"
import { DefaultLoader } from "components/UI/Loader/Loader";


const PlansWrap = styled.div`
  max-width: 987px;
`

const LoadingDiv = styled.div`
  width: 1000px;
  padding: 100px;
`

class Plans extends React.Component {
  componentDidMount() {
    //
  }

  getFilteredPlans = (plan, base) => {
    if (!plan) return {}
    return {
      broker: plan.filter(item => item.rar_plan_type === base)
    }
  }

  showPlans = (plan, type, isLegacy) => {
    const { activePlansIds } = this.props;
    if (plan && plan[type]) {
      const isBlinkVisibleForAll = plan[type].find(plan => (plan.is_blink_visible || activePlansIds.includes(plan.plan_id)) && ((isLegacy && plan.is_legacy_plan) || !isLegacy))
      return isBlinkVisibleForAll
    }
    return true;
  }

  render() {
    const {
      type: sizeType,
      plan,
      broker,
      activePlansIds,
      agentSecureId,
      subscriptions,
    } = this.props

    const {
      isFetching,
    } = subscriptions

    const {
      broker: brokerPlans,
    } = plan || {}

    return (
      <PlansWrap>
        {isFetching ? (
          <LoadingDiv>
            <DefaultLoader height={250} width={250} />
          </LoadingDiv>
        ) : !broker ? (
          <React.Fragment>
            {sizeType !== "team" && this.showPlans(plan, 'rtl', true) && (
              <PlanList
                isLegacy
                plan={plan}
                base="Real Time"
                type="rtl"
                sizeType={sizeType}
                activePlansIds={activePlansIds}
                subscriptions={subscriptions}
              />
            )}
            {this.showPlans(plan, 'rtl') && (
              <PlanList
                plan={plan}
                base="Real Time"
                type="rtl"
                sizeType={sizeType}
                activePlansIds={activePlansIds}
                subscriptions={subscriptions}
              />
            )}
            { sizeType !== "team" && this.showPlans(plan, 'blu', true) && (
              <PlanList
                isLegacy
                plan={plan}
                base="Bulk Lead Uploads"
                type="blu"
                sizeType={sizeType}
                activePlansIds={activePlansIds}
                subscriptions={subscriptions}
              />
            )
            }
            {this.showPlans(plan, 'blu') && (
              <PlanList
                plan={plan}
                base="Bulk Lead Uploads"
                type="blu"
                sizeType={sizeType}
                activePlansIds={activePlansIds}
                subscriptions={subscriptions}
              />
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {this.showPlans(this.getFilteredPlans(brokerPlans, "Legacy"), 'broker') && (
              <PlanList
                plan={this.getFilteredPlans(brokerPlans, "Legacy")}
                base="Legacy"
                type="broker"
                sizeType={sizeType}
                activePlansIds={activePlansIds}
                subscriptions={subscriptions}
              />
            )}
            {this.showPlans(this.getFilteredPlans(brokerPlans, "Professional"), 'broker') && (
              <PlanList
                plan={this.getFilteredPlans(brokerPlans, "Professional")}
                base="Professional"
                type="broker"
                sizeType={sizeType}
                activePlansIds={activePlansIds}
                subscriptions={subscriptions}
              />
            )}
            {this.showPlans(this.getFilteredPlans(brokerPlans, "Expert"), 'broker') && (
              <PlanList
                plan={this.getFilteredPlans(brokerPlans, "Expert")}
                base="Expert"
                type="broker"
                sizeType={sizeType}
                activePlansIds={activePlansIds}
                subscriptions={subscriptions}
              />
            )}
            {this.showPlans(this.getFilteredPlans(brokerPlans, "Royale"), 'broker') && (
              <PlanList
                plan={this.getFilteredPlans(brokerPlans, "Royale")}
                base="Royale"
                type="broker"
                sizeType={sizeType}
                activePlansIds={activePlansIds}
                subscriptions={subscriptions}
              />
            )}
          </React.Fragment>
        )}
      </PlansWrap>
    )
  }
}

export default Plans
