import React from "react"
import { Redirect, withRouter } from "react-router-dom"
import queryString from "query-string"
import root from "window-or-global"

const ICARedirect = () => {
  const { search } = root.location
  const parsedQuery = queryString.parse(search)

  const {
    agentId,
  } = parsedQuery || {}

  console.log("???")

  root.close()

  return (
    // <Redirect to={`/broker/dashboard/${agentId}/ica`} />
    <></>
  )
}

export default withRouter(ICARedirect)
