import React from "react"
import styled from "styled-components"
import moneyFormatter, { USCurrencyFormat } from "../../../shared/currencyUtils"

const Wrap = styled.div`
  margin-top: 30px;
`

const StatsWrap = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`

const StatWrap = styled.div`
  border: 1px solid #999;
  border-radius: 10px;
  text-align: center;
  padding: 16px;
  min-width: 200px;
  ${props => props.isHide && `
    display: none;
  `}
  h1, h5, p {
    margin: 0px;
    padding: 0px
  }
  h5 {
    font-size: 18px;
    font-weight: 500;
    color: #777;
  }
`
const Heading = styled.h5`
  font-size: 24px;
  text-transform: capitalize;
`

const OverviewTCListing = ({
  overview,
}) => {
  const headingFormatter = (step) => {
    const str = step && step.replaceAll("_", " ")
    return str
  }
//check here
  return (
    <Wrap>
      <h3>Listings Overview</h3>
      <StatsWrap>
        {overview && Object.keys(overview).map(item => (
          <StatWrap>
            <Heading>{headingFormatter(item)}</Heading>
            {overview[item].format === true ? (
              <>
                <h1>{moneyFormatter(overview[item].value, 2)}</h1>
                <p>{USCurrencyFormat(overview[item].value)}</p>
              </>
            ) : (
              <h1>{overview[item].value}</h1>
            )}
          </StatWrap>
        ))}
      </StatsWrap>
    </Wrap>
  )
}

export default OverviewTCListing
