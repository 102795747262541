import React from "react"
import styled from "styled-components"
import Box from "dumbComponents/common/UI/BuilderComponents/BoxV2"
import colors from "../../../lib/colors"

const Text = styled.p`
  font-size: 28px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: ${colors.black};
  margin-top: 32px;
  margin-bottom: 32px;
  ${props => props.textStyles && `
    font-family: ${props.textStyles.fontFamily || "ATC"} 
    font-weight: ${props.textStyles.fontWeight || "300"} 
    font-size: ${props.textStyles.fontSize || "28px"} 
    line-height: ${props.textStyles.lineHeight || "32px"} 
  `}

`
const SubText = styled.p`
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.black};
  margin-top: -28px;
  margin-bottom: 24px;
  ${props => props.subtextStyles && `
    font-family: ${props.subtextStyles.fontFamily || "ATC"} 
    font-weight: ${props.subtextStyles.fontWeight || "200"} 
    font-size: ${props.subtextStyles.fontSize || "14px"} 
    line-height: ${props.subtextStyles.lineHeight || "20px"} 
    width: ${props.subtextStyles.width || "auto"}
    margin-top: ${props.subtextStyles.marginTop || "-28px"}
  `}
`

const SubText2 = styled.p`
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  margin-top: -16px;
  color: #8C8C8C;
  ${props => props.subtextStyles && `
    font-family: ${props.subtextStyles.fontFamily || "ATC"} 
    font-weight: ${props.subtextStyles.fontWeight || "200"} 
    font-size: ${props.subtextStyles.fontSize || "18px"} 
    line-height: ${props.subtextStyles.lineHeight || "25px"} 
    width: ${props.subtextStyles.width || "auto"}
    margin-top: ${props.subtextStyles.marginTop || "-16px"}
  `}
`

const CTA = styled.button`
  cursor: pointer;
  text-align: center;
  border: none;
  height: 48px;
  width: 126px;
  padding: 16px 14px 16px 14px;
  font-family: DM Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  background-color: ${colors.marineBLue};
  color: #E9F3FB;
  ${props => props.buttonStyles && `
    border-radius: ${props.buttonStyles.borderRadius || "0px"};
    width: ${props.buttonStyles.width || "auto"};
    padding: ${props.buttonStyles.padding || "10px"};
    background-color: ${props.buttonStyles.bg || "#3A60E4"};
    color: ${props.buttonStyles.color || "#E9F3FB"};
    border: 1px solid ${props.buttonStyles.borderColor || "none"};
    ${props.buttonStyles.margin && `
      margin: ${props.buttonStyles.margin}
    `}
  `}
`

class ZeroState extends React.Component {
  componentDidMount() {
    //
  }

  render() {
    const {
      onClickHandler,
      text,
      height,
      width,
      backgroundColor,
      logo,
      displayStyle,
      alignItems,
      justifyContent,
      buttonCTAText,
      innerBoxDisplayStyle,
      innerBoxFlexDirection,
      innerBoxContentJustification,
      innerBoxItemAlign,
      subtext,
      buttonStyles,
      subtext2,
      textStyles,
      subtextStyles,
    } = this.props
    return (
      <Box
        height={height}
        width={width}
        backgroundColor={backgroundColor}
        displayStyle={displayStyle}
        alignItems={alignItems}
        justifyContent={justifyContent}
      >
        <Box
          displayStyle={innerBoxDisplayStyle}
          flexDirection={innerBoxFlexDirection}
          justifyContent={innerBoxContentJustification}
          alignItems={innerBoxItemAlign}
        >
          {logo && (<img src={logo} height="106.34" width="106.34" alt="tclogo.svg" />)}
          <Text textStyles={textStyles}>{text}</Text>
          {subtext && (<SubText subtextStyles={subtextStyles}>{subtext}</SubText>)}
          {subtext2 && (<SubText2>{subtext2}</SubText2>)}
          {buttonCTAText && (<CTA buttonStyles={buttonStyles} onClick={() => onClickHandler()}>{buttonCTAText}</CTA>)}
        </Box>
      </Box>
    )
  }
}

export default ZeroState
