/* eslint-disable max-len */
import React, { PureComponent } from "react"
import styled from "styled-components"
// import { ATC } from "@fonts"
import colors from "../../../../lib/colors"
import TechAssetsContainer from "container/TechAssets"
import Loader from "dumbComponents/common/UI/Loader"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
import {
  Select,
  Form,
  Divider,
  Button,
  Space,
  Switch,
  Radio,
} from "dumbComponents/common/UI/antd"
import {
  PageArray,
  OfferInfoRoute,
  OfferWritingRoute,
  SellerInfoStep,
  SellerInfoRoute,
  DocumentsInfoStep,
} from "../../consts"
import {
  fetchDetails,
  getDealId,
  getAgentId,
  SubmitForm,
} from "../../commonUtils"
import Sidebar from "../Sidebar"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const HeaderNav = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  margin-top: 10px;
  color: ${colors.black};
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
  .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-radio-group {
    margin-top: 10px;
  }
`

const LabelText = styled.h2`
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  word-wrap: break-word;
  color: ${colors.black};
  // margin-right: 5px;
`

const Required = styled.span`
  color: #E34C4C
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}

  .ant-form-item-label > label {
    font-size: 16px;
  }
`
const ToggleDiv = styled.div`
  display: flex;
  flex-direction: row;
  .ant-form-item {
    width: 5%;
    margin-left: 10px;
  }
  ${props => props.dropdown && `
    flex-direction: column;
    .ant-form-item {
      width: 100%;
      margin-left: 0px;
    }
  `}
`
const LAD_PURCHASE_CALIFORNIA = [
  {
    value: "no",
    label: "No",
  },
  {
    value: "albany_purchase_agreement_addendum",
    // label: "Albany Purchase Agreement Addendum",
    label: "Alameda County - City of Albany",
  },
  {
    value: "oakland_purchase_agreement_addendum",
    // label: "Oakland Purchase Agreement Addendum",
    label: "Alameda County - City of Oakland",
  },
  {
    value: "piedmont_purchase_agreement_addendum",
    // label: "Piedmont Purchase Agreement Addendum",
    label: "Alameda County - City of Piedmont",
  },
  {
    value: "emeryville_purchase_agreement_addendum",
    // label: "Emeryville Purchase Agreement Addendum",
    label: "Alameda County - City of Emeryville",
  },
  {
    value: "berkeley_purchase_agreement_addendum",
    // label: "Berkeley Purchase Agreement Addendum",
    label: "Alameda County - City of Berkeley",
  },
  {
    value: "castro_valley_purchase_agreement_addendum",
    // label: "Castro Valley Purchase Agreement Addendum",
    label: "Alameda County - Castro Valley",
  },
  {
    value: "city_of_alameda_purchase_agreement_addendum",
    // label: "City of Alameda Purchase Agreement Addendum",
    label: "Alameda County - City of Alameda",
  },
  {
    value: "contra_costa_purchase_agreement_addendum_city_of_el_cerrito",
    label: "Contra Costa Purchase Agreement Addendum - City of El Cerrito",
  },
  {
    value: "contra_costa_purchase_agreement_addendum_city_of_hercules",
    label: "Contra Costa Purchase Agreement Addendum - City of Hercules",
  },
  {
    value: "contra_costa_purchase_agreement_addendum_city_of_oakley",
    label: "Contra Costa Purchase Agreement Addendum - City of Oakley",
  },
  {
    value: "contra_costa_purchase_agreement_addendum_city_of_pinole",
    label: "Contra Costa Purchase Agreement Addendum - City of Pinole",
  },
  {
    value: "contra_costa_purchase_agreement_addendum_city_of_richmond",
    label: "Contra Costa Purchase Agreement Addendum - City of Richmond",
  },
  {
    value: "contra_costa_purchase_agreement_addendum_city_of_san_pablo",
    label: "Contra Costa Purchase Agreement Addendum - City of San Pablo",
  },
  {
    value: "contra_costa_purchase_agreement_addendum_Unincorporated",
    label: "Contra Costa Purchase Agreement Addendum - Unincorporated (El Sobrante, Kensington, North Richmond, East Richmond Heights, Bay View,East Richmond Heights, Montalvin Manor, Rollingwood and Tara Hills)",
  },
]

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  formRef = React.createRef();

  componentDidMount() {
    const { getDealDetails } = this.props
    // const user = CookiesStorage.load("user") || {}
    // const agentId = user.id

    fetchDetails(getDealDetails, DocumentsInfoStep)
    // if (user.id) {
    //   getTCBanner(agentId)
    // }
    // setShowTCBannerForm(true)
  }

  onFinish = (fieldValues, isDraft) => {
    const {
      history,
      updateOffer,
      createOffer,
      offerInfoDetails,
      getDealDetails,
    } = this.props

    const enableTC = ["California", "Oregon", "Washington"]

    const payload = fieldValues
    const a = Object.keys(fieldValues)
    a.map((key) => {
      if (fieldValues[key] === undefined && key !== "include_lad_purchase_agreement_addendum") {
        payload[key] = false
      }
      if (key === "include_lad_purchase_agreement_addendum" && fieldValues[key] === null || fieldValues[key] === undefined) {
        payload[key] = "no"
      }
      return payload
    })

    const state = offerInfoDetails && offerInfoDetails.state
    const currentStep = DocumentsInfoStep

    SubmitForm(
      payload,
      isDraft,
      createOffer,
      updateOffer,
      history,
      SellerInfoRoute,
      currentStep,
      null,
      false,
      false,
      false,
    )
    fetchDetails(getDealDetails, SellerInfoStep)
  }

  onSaveAsDraft = () => {
    // console.log("===this.formRef.current.getFieldsValue===", this.formRef.current.getFieldsValue())
    this.onFinish(this.formRef.current.getFieldsValue(), true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  getInitialValues = () => {
    const { getCurrentDealDetailsResponse } = this.props
    const {
      data: documentsInfo,
    } = getCurrentDealDetailsResponse || {}
    let result = {}
    if (documentsInfo) {
      result = {
        ...documentsInfo,
      }
      if (documentsInfo.include_lad_purchase_agreement_addendum === null) {
        result.include_lad_purchase_agreement_addendum = "no"
      }
      return result
    }
    return {}
  }

  // onClickFunction = () => {
  //   this.formRef.current.setFieldsValue({
  //     writing_opt_in: 1,
  //   })
  //   this.setState({
  //     offerWritingOptIn: 1,
  //   })
  // }

  render() {
    const {
      history,
      toggleLoader,
      getCurrentDealDetailsResponse,
    } = this.props
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]
    const {
      data: documentsInfo,
    } = getCurrentDealDetailsResponse || {}
    const state = documentsInfo && documentsInfo.state

    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Documents Information
        </HeaderNav>
        <ProgressBarComponent total={PageArray} ongoingOrCompleted={4} />
        <Container>
          <LeftSideBar>
            <Sidebar currentStage={1} />
          </LeftSideBar>
          <RightSideBar>
            {toggleLoader ? <Loader /> : (
              <Form name="offer_documents" colon={false} layout="horizontal" initialValues={this.getInitialValues()} requiredMark={false} onFinish={this.onFinish} ref={this.formRef} onFinishFailed={(e) => { console.log("ERROROOOO", e) }} scrollToFirstError>
                {state === "California" && (
                <>
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Is the subject property in Probate?" />
                      <Form.Item name="subject_property_in_probate" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Is the subject property a Short Sale?" />
                      <Form.Item name="subject_property_in_short_sale" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Is the subject property in Foreclosure?" />
                      <Form.Item name="subject_property_in_foreclosure" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include an Contingency Removal with the offer?" />
                      <Form.Item name="include_contingency_removal" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Seller In Possession with this offer? Reminder: SIP applies if Seller remains on the property for less then 29 days after close of escrow." />
                      <Form.Item name="include_seller_in_possession" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Residential Lease After Sale with this offer? Reminder: RLAS applies if Seller remains on the property formore than 30 days after close of escrow." />
                      <Form.Item name="include_residential_lease_after_sale" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a (SWPI) Septic & Well Insption, Property Monument and Propane Tank Allocation of Cost Addendum with your offer?" />
                      <Form.Item name="include_swpi_allocation_cost_addendum" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Contingency For Sale of Buyer's Property with this offer?" />
                      <Form.Item name="include_contingency_sale_of_buyer_property" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="100%">
                    <ToggleDiv dropdown>
                      <LabelComponent text="Would you like to include a LAD Purchase Agreement Addendum based off of the subject property's city and/or county?" />
                      <Form.Item
                        name="include_lad_purchase_agreement_addendum"
                        // rules={commonRules}
                      >
                        <Select
                          placeholder="Select"
                          defaultValue="No"
                          defaultActiveFirstOption
                          style={{
                            width: "70%",
                            marginTop: 8,
                          }}
                          options={LAD_PURCHASE_CALIFORNIA}
                        // onChange={(value) => { this.setState({ financing: value }) }}
                        />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                </>
                )}
                {state === "Washington" && (
                <>
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a FINANCING ADDENDUM TO PURCHASE & SALE AGREEMENT with this offer?" />
                      <Form.Item name="include_financial_addendum_to_purchase_sale_agreement" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a OPTIONAL CLAUSES ADDENDUM TO PURCHASE & SALE AGREEMENT with this offer?" />
                      <Form.Item name="include_optional_clauses_addendum_to_purchase_sale_agreement" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a TITLE CONTINGENCY ADDENDUM TO PURCHASE & SALE AGREEMENT with this offer?" />
                      <Form.Item name="include_title_contingency_addendum_to_purchase_sale_agreement" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a INSPECTION ADDENDUM TO PURCHASE AND SALE AGREEMENT with this offer?" />
                      <Form.Item name="include_inspection_addendum_to_purchase_sale_agreement" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Buyer Sale of Property Contingency with this offer?" />
                      <Form.Item name="include_buyer_sale_of_property_contingency" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Rental Agreement for occupancy prior to closing with this offer?" />
                      <Form.Item name="include_rental_agreement_for_occupancy_prior_to_closing" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Rental Agreement for occupancy after closing with this offer?" />
                      <Form.Item name="include_rental_agreement_for_occupancy_after_closing" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                </>
                )}
                {state === "Georgia" && (
                <>
                  <FlexBox wd="90%">
                    <ToggleDiv dropdown>
                      <LabelComponent text="Is the closing firm holding the Earnest Money Deposit?" required />
                      <Form.Item
                        name="closing_firm_holding_earnest_money_deposit"
                        rules={commonRules}
                      >
                        <Radio.Group>
                          <Space direction="horizontal">
                            <Radio value={1}>
                              <LabelComponent text="Yes" />
                            </Radio>
                            <Radio value={0}>
                              <LabelComponent text="No" />
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Temporary Occupance Agreement with this offer?" />
                      <Form.Item name="include_temporary_occupance_agreement" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Condominimum Resale Purchase and Sale Exhibit with this offer?" />
                      <Form.Item name="include_condominimum_resale_purchase_and_sale_exhibit" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Special Stipulations with this offer?" />
                      <Form.Item name="include_special_stipulations" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                </>
                )}
                {state === "Texas" && (
                <>
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Does the subject property have an HOA?" />
                      <Form.Item name="subject_property_have_hoa" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Is the property subject to a Residential Leases?" />
                      <Form.Item name="property_subject_to_residential_leases" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Is the property subject to a Fixture Leases?" />
                      <Form.Item name="property_subject_to_fixture_leases" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Buyer's Temporary Residential Lease?" />
                      <Form.Item name="include_buyer_temporary_residential_lease" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="100%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include an Addendum Concerning Right to Terminate Due to Lender's Appraisal?" />
                      <Form.Item name="include_addendum_right_to_terminate_due_to_lender_appraisal" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                </>
                )}
                {state === "Florida" && (
                <>
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Post Closing Occupancy Agreement with this offer?" />
                      <Form.Item name="include_post_closing_occupancy_agreement" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Condominium Rider with this offer?" />
                      <Form.Item name="include_condominum_rider" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a HOA Addendum with this offer?" />
                      <Form.Item name="include_hoa_addendum" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                </>
                )}
                {state === "Colorado" && (
                <>
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Post Closing Occupancy Agreement with this offer?" />
                      <Form.Item name="include_post_closing_occupancy_agreement" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                </>
                )}
                {state === "Oregon" && (
                <>
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Private Well Addendum to Real Estate Sale Agreement with this offer?" />
                      <Form.Item name="include_private_well_addendum_to_realestate_sale_agreement" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include Septic Onsite Sewage System Addendum with this offer?" />
                      <Form.Item name="include_septic_onsite_sewage_system_addendum" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Homeowner's Association/Townhome/Planned Community Addendum with this offer?" />
                      <Form.Item name="include_homeowner_assoc_townhome_community_addendum" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Investment Property Addendum with this offer?" />
                      <Form.Item name="include_investment_property_addendum" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Agreement for occupancy prior to closing with this offer?" />
                      <Form.Item name="include_agreement_for_occupancy_prior_to_closing" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Agreement for occupancy after closing with this offer?" />
                      <Form.Item name="include_agreement_for_occupancy_after_closing" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                  <FlexBox wd="90%">
                    <ToggleDiv>
                      <LabelComponent text="Would you like to include a Advisory to Buyer Waiving Contingencies with this offer?" />
                      <Form.Item name="include_advisory_to_buyer_waiving_contingency" valuePropName="checked">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </ToggleDiv>
                  </FlexBox>
                  <Divider />
                </>
                )}
                <FlexBox>
                  <FlexBox wd="100%">
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          color: "#849DF8",
                          padding: 0,
                          marginLeft: "-10px",
                        }}
                        size="large"
                        type="link"
                        onClick={() => this.onSaveAsDraft()}
                      >
                        Save for Later
                      </Button>
                    </Form.Item>
                  </FlexBox>
                  <FlexBox>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "white",
                          float: "right",
                          borderColor: "#3B60E4",
                          color: "#3B60E4",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        // htmlType="submit"
                        onClick={() => { history.push(`${OfferInfoRoute}?dealId=${getDealId()}&agentId=${getAgentId()}`) }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "#3B60E4",
                          float: "right",
                          borderColor: "white",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        // loading={isLoading}
                        htmlType="submit"
                      // onClick={() => { history.push("/realty/listings/coversheet/listing-details") }}
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </FlexBox>
                </FlexBox>
              </Form>
            )}
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)
