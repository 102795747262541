import React from "react"
import root from "window-or-global"
import ReactCrop from "react-image-crop"
import { Button, Modal } from "antd"
import "react-image-crop/dist/ReactCrop.css"

class PhotoEditor extends React.Component {
  state = {
    crop: {
      unit: "%",
      width: 100,
      aspect: 48 / 12,
    },
    croppedImageFile: null,
    loader: false,
  }

  componentDidMount() {
    const { imageSrc, cropConfig } = this.props
    if (cropConfig === 1) {
      this.setState({
        crop: {
          unit: "%",
          width: 100,
          aspect: 16 / 9,
        },
      })
    }
    this.setState({
      src: imageSrc,
    })
  }

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image
  }

  onCropComplete = async (crop) => {
    const canvas = document.createElement("canvas")
    const pixelRatio = window.devicePixelRatio
    const scaleX = this.imageRef.naturalWidth / this.imageRef.width
    const scaleY = this.imageRef.naturalHeight / this.imageRef.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext("2d")
    canvas.width = crop.width * pixelRatio * scaleX
    canvas.height = crop.height * pixelRatio * scaleY
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = "high"

    ctx.drawImage(
      this.imageRef,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    )

    const { croppedImageFile, croppedImageUrl } = await new Promise((resolve) => {
      canvas.toBlob((rawBlob) => {
        if (rawBlob) {
          const blob = rawBlob
          blob.name = "newFile.jpeg"
          root.URL.revokeObjectURL(this.fileUrl)
          this.fileUrl = root.URL.createObjectURL(blob)
          resolve({
            croppedImageUrl: this.fileUrl,
            croppedImageFile: blob,
          })
        }
        resolve({
          croppedImageUrl: {},
          croppedImageFile: "",
        })
      }, "image/jpeg")
    })
    console.log("===croppedImageFile====", croppedImageFile)
    console.log("===croppedImageUrl====", croppedImageUrl)
    this.setState({
      croppedImageFile,
    })
  }

  onCropChange = (crop) => {
    this.setState({ crop })
  }

  setLoader = (val) => {
    this.setState({
      loader: val,
    })
  }

  render() {
    const { crop, src, croppedImageFile, loader } = this.state
    const { uploadImage, type, closeImageEdit } = this.props
    return (
      <div>
        {src && (
          <Modal title="Edit Image" confirmLoading={loader} visible onOk={() => { uploadImage(croppedImageFile, type); this.setLoader(true) }} onCancel={() => { closeImageEdit(type) }} width="640px">
            <ReactCrop
              src={src}
              crop={crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          </Modal>
        )}
      </div>
    )
  }
}

export default PhotoEditor
