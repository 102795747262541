import React, { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Pagination,
  Button,
  Modal,
  DatePicker,
  Input,
  Select,
} from "antd";
import { DebouncedSelect } from "dumbComponents/AIDashboard/Components/FilterSection/Elements";
import styled from "styled-components";
import root from "window-or-global";
import { serializer } from "dumbComponents/AIDashboard/commonUtils.js";
import US_STATES from "shared/US_STATES.json";
import {
  FilterByText,
  FlexWrap,
  FormWrap,
  FormElement,
} from "dumbComponents/AIDashboard/Components/FilterSection/filterCommonStyles.js";
import aiContainer from "container/AI";
import { FlexRow } from "dumbComponents/BrokerDashboard/AgentDashboard/ICA/components/Forms/commonStyles";

const { RangePicker } = DatePicker;

const STATE_OPTIONS = Object.keys(US_STATES).map((key) => ({
  label: US_STATES[key],
  value: US_STATES[key],
}));

const Wrap = styled.div`
  padding-top: 17px;
  overflow: scroll;
  width: 100%;
  .infoTable {
    th,
    td {
      border: none;
      font-family: "Rubik", sans-serif;
    }

    th {
      padding: 5px 5px 5px 18px;
    }

    td {
      padding: 10px 5px 10px 18px;
      background: #fafafa;

      &:hover {
        background: #fcfcfc;
      }
    }

    .ant-table-thead tr th {
      background: linear-gradient(0deg, #e5e5e5, #e5e5e5),
        linear-gradient(0deg, #e6e6e6, #e6e6e6);
    }
  }
`;
const ResultsTable = ({
  fetchTrainedDocuments,
  fetchTrainedDocumentsResponse,
  deleteTrainedDocuments,
}) => {
  useEffect(() => {
    fetchTrainedDocuments();
  }, []);
  const { data: result } = fetchTrainedDocumentsResponse || {};
  const [complianceState, setComplianceState] = useState(null);
  const [category, setCategory] = useState(null);
  const [filename, setFileName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleDelete = (item) => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      const payload = {};
      payload.document_ids = [item.key];
      deleteTrainedDocuments({
        payload,
      });
    }
  };
  const handleFilter = () => {
    const payload = {
      complianceState,
      category,
      filename,
      startDate,
      endDate,
    };
    fetchTrainedDocuments(payload);
  };
  const handleClearFilter = () => {
    setCategory(null);
    setComplianceState(null);
    setFileName(null);
    setStartDate(null);
    setEndDate(null);
    root.location.reload()
  };
  const createColumn = () => {
    const columns = [
      {
        title: "Document Name",
        dataIndex: "fileName",
        key: "fileName",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Link To File",
        dataIndex: "linkToFile",
        key: "linkToFile",
        render: (text, record) => <a href={text}>{text}</a>,
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
      },
      {
        title: "Last Updated",
        dataIndex: "lastUpdated",
        key: "lastUpdated",
      },
      {
        title: "Actions",
        dataIndex: "actions",
        render: (text, record) => (
          <>
            <Button
              type="dashed"
              danger
              onClick={() => {
                handleDelete(record);
              }}
            >
              Delete
            </Button>
          </>
        ),
      },
    ];
    return columns;
  };

  const serialized = serializer(result) || [];
  return (
    <Wrap>
      <FlexWrap>
        <FilterByText>Filter By |</FilterByText>
        <FormWrap>
          <FormElement anim className="styled_Borders">
            <Select
              placeholder="State Name"
              onChange={(val) => {
                setComplianceState(val);
              }}
              style={{
                width: 200,
                marginLeft: 20,
              }}
              options={STATE_OPTIONS}
            />
          </FormElement>
          <FormElement anim className="styled_Borders">
            <Input
              style={{ height: 40 }}
              placeholder="Category"
              onChange={(e) => {
                console.log(e.target.value);
                setCategory(e.target.value);
              }}
            />
          </FormElement>
          <FormElement anim className="styled_Borders">
            <Input
              style={{ height: 40 }}
              placeholder="Document Name"
              onChange={(e) => {
                console.log(e.target.value);
                setFileName(e.target.value);
              }}
            />
          </FormElement>
          <FormElement anim>
            <RangePicker
              style={{ height: 40 }}
              onChange={(value) => {
                const startDate = value[0].unix();
                const endDate = value[1].unix();
                setStartDate(startDate);
                setEndDate(endDate);
              }}
            />
          </FormElement>
          <Button
            style={{
              marginLeft: 50,
              width: 100,
              height: 40,
              background: "#100063",
              color: "white",
            }}
            type="primary"
            size="large"
            onClick={handleFilter}
          >
            Filter
          </Button>
          <Button
            style={{
              background: "red",
              color: "white",
              width: 100,
              height: 40,
            }}
            onClick={handleClearFilter}
            type="link"
            size="large"
          >
            Clear
          </Button>
        </FormWrap>
      </FlexWrap>
      <Table
        className="infoTable"
        columns={createColumn()}
        dataSource={serialized}
        style={{ marginTop: 50 }}
      >
        <Pagination
          total={serialized.length}
          defaultPageSize={50}
          defaultCurrent={1}
        />
      </Table>
    </Wrap>
  );
};

export default aiContainer(ResultsTable);
