export const getDefaultValue = (data) => {
  const filtered = data.filter(item => item.isSelected)

  if (filtered && filtered.length > 0) {
    return filtered[0].value
  }

  return ""
}

export const getInitialValues = (options = [], defaultValues = []) => {
  const final = {}
  options.forEach((item, iter) => {
    final[item] = defaultValues[iter]
  })
  console.log("final", final)
  return final
}

export const US_STATES = ["California", "Colorado", "Florida", "Georgia", "Oregon", "Texas", "Washington"]

export const DEFAULT_FORM_OPTIONS = ["role", "team_name", "split", "risk_fee", "commission_cap", "subscription_fee"]

export const SOLO_DEFAULT_FORM_OPTIONS = ["role", "split", "risk_fee", "commission_cap", "subscription_fee"]
