import styled from "styled-components"

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    color: var(--Gray1, #828282);
    font-family: Roboto;
    font-size: 13.69px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  img {
    width: 20.535px;
    height: 20.535px;
  }
`

export const StatValue = styled.div`
  p {
    color: var(--Value-Color, #0B1354);
    font-family: Roboto;
    font-size: 34.224px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.283px;
  }
`

export const ResponseText = styled.div`
  p {
    color: var(--Gray2, #4F4F4F);
    font-family: Roboto;
    font-size: 11.979px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

export const ExtraStatWrap = styled.div``

export const ExtraStatRow = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: baseline;
`

export const ExtraStatInnerWrap = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`

export const ExtraStatCircle = styled.div`
  background: #FFAB55;
  width: 10.538px;
  height: 10.538px;
  border-radius: 100px;
`

export const ExtraStatText = styled.p`
  color: var(--gray-900, #18181B);
  /* Semi-bold/13px */
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 161.538% */
  width: max-content;
  margin: 0;
`

export const ExtraStatPercentage = styled.p`
  color: var(--gray-900, #18181B);
  text-align: right;

  /* Semi-bold/13px */
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 161.538% */
`
