import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"
import { DefaultLoader } from "components/UI/Loader/Loader";


const ListWrap = styled.ul`
 list-style: none;
 padding:0;
 margin:0;
 li {
  width: 100%;
  height: 68px;
  border: solid 1px #e2e4e8;
  display: flex;
  .instructor_image {
    flex-grow: 1;
    height: 100%;
    max-width: 60px;
    padding-top: 14px;
    padding-left: 14px;
    img {
     width: 34px;
     height: 34px;
     border-radius: 18px;
    }
  }
  .instructor_details{
    height: 100%;
    padding: 14px;
    width: 222px;
   p {
    margin: 0;
   }
   p:last-child {
    font-size: 12px;
    color: #586069;
   }
  }
  .cta {
   height: 100%;
   width: 400px;
   display: flex;
   justify-content: flex-end;
   div {
    flex-grow: 1;
    height: 100%;
    padding: 23px 8px 8px 24px;
    max-width: 99px;
    button{
     cursor: pointer;
     background: none;
     border: none;
    }
    .delete {
     color: red;
    }
   }
  }
 }
`

const ImageDiv = styled.div`
`

const EditInstructor = styled(Link)`
  margin-bottom: 14px;
  width: 118px;
  display: block;
  height: 30px;
  font-size: 14px;
  border: none;
  background: none;
  color: black;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding-top: 2px;
`

const FilterWrap = styled.div`
  width: 100%;
  height: 33px;
  border-radius: 4px;
  border: solid 1px #e2e4e8;
  background-color: #f4f5f8;
`

const Wrap = styled.div`
 height: 100%;
 width: 100%;
 display: flex;
 flex-direction: column;
 border: 1px solid #e3e4e8;
 border-radius: 4px;
`

class InstructorsList extends Component{
 componentDidMount(){
  //
 }

 render() {
  const {
    instructors,
  } = this.props
  return (
   <Wrap>
     <FilterWrap>

     </FilterWrap>
     {instructors && instructors.length > 0 ? (
      <ListWrap>
        {instructors.map(instructor => {
         return (
           <li>
            <div className="instructor_image">
                {instructor.image_url && instructor.image_url !== "abcd.com" ? (
                 <img src={instructor.image_url} />
                ) : (
                 <img src="https://api.adorable.io/avatars/112/abott@adorable.png" alt="instructor_image" />
                )}
            </div>
            <div className="instructor_details">
              <p>{instructor.name}</p>
              <p>{instructor.designation || ""}</p>
            </div>
            <div className="cta">
              <div>
               <EditInstructor
                 to={`/academy-admin/people/${instructor.id}`}
               >
                Edit
               </EditInstructor>
              </div>
              {/*div>
               <button className="delete">Delete</button>
              </div>*/}
            </div>
           </li>
         )
        })}
      </ListWrap>
     ) : (
      <ListWrap>
        <DefaultLoader height={200} width={200}/>
      </ListWrap>
     )}
   </Wrap>
  )
 }
}

export default Container(InstructorsList)
