/* eslint-disable import/no-unresolved */
import React from "react"
import styled from "styled-components"
import { Modal } from "antd"
import root from "window-or-global"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { get } from "lodash"
import { withRouter } from "react-router-dom"
import SubscriptionContainer from "container/BrokerDashboard/subscriptionContainer"

const Wrap = styled.div``

const Title = styled.p`
  margin: 0;
  color: red;
  cursor: pointer;
`

const CancelSubscription = ({
  cancelSubscription,
  ...props
}) => {
  const agentId = get(props, "match.params.agentId", "")

  const handleClick = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Cancel Subscription",
      content: "Are you sure you want to cancel your subscription?",
      okText: "Yes",
      cancelText: "Not Now",
      onOk() {
        cancelSubscription({
          agentId,
          callback: () => root.location.reload(),
        })
      },
    })
  }

  return (
    <Wrap
      onClick={() => handleClick()}
    >
      <Title>
        Cancel Subscription
      </Title>
    </Wrap>
  )
}

export default withRouter(SubscriptionContainer(CancelSubscription))
