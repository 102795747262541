
import {
  call, put, throttle, takeLatest, select,
} from "redux-saga/effects"
import { isSuccess } from "services/Utils"
import { message } from "antd"
import moment from "moment"
import uuid from "uuid"
import root from "window-or-global"
import { toast } from "react-toastify"

// import root from "window-or-global"
import * as EventRoomActions from "./actions"
import * as APIs from "./api"

function* handleFetchRooms() {
  try {
    const res = yield call(APIs.getRoomsAPI)

    const formatResponse = res.response.map((r) => {
      const realAttendees = r.attendees.filter(att => att.id && att.Role !== "host")
      return {
        host_name: `${r.host.firstname} ${r.host.lastname}`,
        is_immortal_val: r.is_immortal ? "Yes" : "No",
        is_public_val: r.is_public ? "Yes" : "No",
        is_live_val: r.is_live ? "Yes" : "No",
        created_at_val: moment(r.created_at * 1000).format("MM/DD/YYYY hh:mm A"),
        attendees_count_val: realAttendees.length,
        ...r,
      }
    })
    yield put(EventRoomActions.getRooms.success(formatResponse))
  } catch (error) {
    yield put(EventRoomActions.getRooms.failure(error))
  }
}

function* handleFetchScheduledRooms() {
  try {
    const res = yield call(APIs.getScheduledRoomsAPI)
    if (isSuccess(res)) {
      console.log(res)
      const { response } = res.data
      yield put(EventRoomActions.getScheduledRoomsAction.success(response))
    }
  } catch (e) {
    yield put(EventRoomActions.getScheduledRoomsAction.failure(e))
  }
}

function* handleCreateRooms(action) {
  try {
    yield call(APIs.createRoomsAPI, action.data)
    message.success(`${action.data.name} - Room Created`)
    yield put(EventRoomActions.getRooms.request())
  } catch (err) {
    message.error("Something went wrong. Try again later")
  }
}

function* handleUpdateRoom(action) {
  const res = yield call(APIs.updateRoomAPI, action.data)
}

function* handleEndRoom(action) {
  try {
    yield call(APIs.endRoomAPI, action.data)
    message.success(`${action.data.name} - Room Deleted`)
    yield put(EventRoomActions.getRooms.request())
  } catch (err) {
    message.error("Something went wrong. Try again later")
  }
}

function* handleGetFinancingClientList(action) {
  try {
    const res = yield call(APIs.getFinancingClientListApi, action.data)
    if (isSuccess(res)) {
      if(res.data.response && res.data.response.clients) {
        res.data.response.clients.forEach((dt) => {
          if (dt.emails && dt.emails.length) {
            // eslint-disable-next-line no-param-reassign
            dt.status = dt.status || "new"
          }
        })
      }
      yield put(EventRoomActions.getFinancingClientList.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(EventRoomActions.getFinancingClientList.failure(error))
  }
}

function* handleGetFinancingSetting() {
  try {
    const res = yield call(APIs.getFinancingSettingApi)
    if (isSuccess(res)) {
      yield put(EventRoomActions.getFinancingSetting.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(EventRoomActions.getFinancingSetting.failure(error))
  }
}

function* handleSaveFinancingSetting(action) {
  try {
    const res = yield call(APIs.saveFinancingSettingApi, action.data)
    if (isSuccess(res)) {
      yield put(EventRoomActions.saveFinancingSetting.success(res.data && res.data.response))
      message.success("Setting updated !")
    }
  } catch (error) {
    yield put(EventRoomActions.saveFinancingSetting.failure(error))
    message.error("Something went wrong. Try again later")
  }
}

function* handleUpdateFinancingClientList(action) {
  try {
    const res = yield call(APIs.updateFinancingClientListApi, action.data)
    if (isSuccess(res)) {
      yield put(EventRoomActions.updateFinancingClientList.success(res.data && res.data.response))
      message.success("Status updated !")
      window.location.reload()
    }
  } catch (error) {
    yield put(EventRoomActions.updateFinancingClientList.failure(error))
    message.error("Something went wrong. Try again later")
  }
}

function* handleDeleteClient(action) {
  try {
    const res = yield call(APIs.handleDeleteClientApi, action.data)
    const resGet = yield call(APIs.getFinancingClientListApi, action.data)
    if (isSuccess(resGet)) {
      if (resGet.data.response && resGet.data.response.clients) {
        resGet.data.response.clients.forEach((dt) => {
          if (dt.emails && dt.emails.length) {
            // eslint-disable-next-line no-param-reassign
            dt.status = dt.status || "new"
          }
        })
      }
      yield put(EventRoomActions.getFinancingClientList.success(resGet.data && resGet.data.response))
    }
    if (isSuccess(res)) {
      yield put(EventRoomActions.deleteClient.success(res.data && res.data.response))
      message.success("Client Deleted !")
    }
  } catch (error) {
    yield put(EventRoomActions.deleteClient.failure(error))
    message.error("Something went wrong. Try again later")
  }
}

function* handleGetAllJobsByTeamId(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    const res = yield call(APIs.getAllJobsByTeamIdApi, query)
    if (isSuccess(res)) {
      yield put(EventRoomActions.getAllJobsByTeamId.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(EventRoomActions.getAllJobsByTeamId.failure(error))
  }
}

function* handleGetJobApplications(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    const res = yield call(APIs.getJobApplicationsApi, query)
    if (isSuccess(res)) {
      yield put(EventRoomActions.getJobApplications.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(EventRoomActions.getJobApplications.failure(error))
  }
}

function* handleUpdateJobApplications(action) {
  try {
    const res = yield call(APIs.updateJobApplicationsApi, action.data)
    if (isSuccess(res)) {
      yield put(EventRoomActions.updateJobApplications.success(res.data && res.data.response))
      message.success(`Applicant Status Updated to: ${action.data.body.status}`)
    }
  } catch (error) {
    yield put(EventRoomActions.updateJobApplications.failure(error))
    message.error("Something went wrong. Try again later")
  }
}

function* handleCreateJobs(action) {
  try {
    const res = yield call(APIs.createJobsApi, action.data)
    if (isSuccess(res)) {
      yield put(EventRoomActions.createJobs.success(res.data && res.data.response))
      yield put(EventRoomActions.showJobConfirmationAction.call(true))
      const { payload } = action.data
      const localBody = {
        skip: 0,
        limit: 5,
      }
      if (payload.teamId) {
        localBody.teamId = payload.teamId
      }
      yield put(EventRoomActions.getAllJobsByTeamId.request(localBody))
      message.success("Job created !")
    }
  } catch (error) {
    console.log("===error=====", error)
    yield put(EventRoomActions.createJobs.failure(error))
    message.error("Something went wrong. Try again later")
  }
}

function* handleUpdateJobs(action) {
  try {
    const res = yield call(APIs.updateJobsApi, action.data)
    if (isSuccess(res)) {
      yield put(EventRoomActions.updateJobs.success(res.data && res.data.response))
      yield put(EventRoomActions.showJobConfirmationAction.call(true))
      const { payload } = action.data
      const localBody = {
        skip: 0,
        limit: 5,
      }
      if (payload.jobSearch) {
        localBody.jobSearch = payload.jobSearch
      }
      if (payload.teamId) {
        localBody.teamId = payload.teamId
      }
      yield put(EventRoomActions.getAllJobsByTeamId.request(localBody))
      message.success("Job created !")
    }
  } catch (error) {
    console.log("===handleUpdateJobs error=====", error)
    yield put(EventRoomActions.updateJobs.failure(error))
    message.error("Something went wrong. Try again later")
  }
}

function* handleDeleteJobs(action) {
  try {
    const res = yield call(APIs.deleteJobsApi, action.data)
    const { body } = action.data
    const localBody = {
      skip: 0,
      limit: 5,
    }
    if (body.jobSearch) {
      localBody.jobSearch = body.jobSearch
    }
    if (body.teamId) {
      localBody.teamId = body.teamId
    }
    yield put(EventRoomActions.getAllJobsByTeamId.request(localBody))
    if (isSuccess(res)) {
      yield put(EventRoomActions.updateJobApplications.success(res.data && res.data.response))
      message.success("Job deleted !")
    }
  } catch (error) {
    yield put(EventRoomActions.updateJobApplications.failure(error))
    message.error("Something went wrong. Try again later")
  }
}

function* handleImageUploadToS3(action) {
  try {
    const {
      file,
      type,
      name,
    } = action.data

    const signedRes = yield fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=s-tc-docs&filename=${`${uuid()}_${name}` || "image_file_.png"}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": "image/png" })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })

    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      yield put(EventRoomActions.setImageBlocksAction.call({
        type,
        image: signedResJson.fileUrlAfterUpload,
      }))
      yield put(EventRoomActions.setCurrentImageToBeCroppedAction.call({
        type: null,
        image: null,
      }))
      yield put(EventRoomActions.uploadImagesToS3Action.success(signedResJson.fileUrlAfterUpload))
      // createToast.info("Image Uploaded", toastConfig)
    }
  } catch (e) {
    // createToast.error("Error Uploading Image", toastConfig)
    yield put(EventRoomActions.uploadImagesToS3Action.failure(e))
  }
}

function* handleGetCashbackList(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    const res = yield call(APIs.getCashbackListApi, query)
    yield put(EventRoomActions.getCashbackList.success(res.data && res.data.response))
  } catch (error) {
    yield put(EventRoomActions.getCashbackList.failure(error))
  }
}

function* handleGetCashbackAgentInfo(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    let savedCashbackAgentInfo = yield select(state => state.eventRooms.cashbackAgentInfo.data)
    if (!savedCashbackAgentInfo) {
      savedCashbackAgentInfo = {}
    }
    const res = yield call(APIs.getCashbackAgentInfoApi, query)
    const response = res.data && res.data.response
    if (response && response.cashback && response.cashback.length && response.cashback[0].agent_id) {
      savedCashbackAgentInfo[response.cashback[0].agent_id] = response
    }

    yield put(EventRoomActions.getCashbackAgentInfo.success(savedCashbackAgentInfo))
  } catch (error) {
    console.log("error", error)
    yield put(EventRoomActions.getCashbackAgentInfo.failure(error))
  }
}

function* handleUpdateCashbackAgentInfo(action) {
  try {
    const res = yield call(APIs.updateCashbackAgentInfoApi, {
      id: action.data.id,
      payload: action.data.payload,
    })
    if (isSuccess(res)) {
      yield put(EventRoomActions.updateCashbackAgentInfo.success(res.data && res.data.response))
      if (action.data.callback) action.data.callback()
      // message.success("Status Updated!")
    }
  } catch (error) {
    yield put(EventRoomActions.updateCashbackAgentInfo.failure(error))
    message.error("Something went wrong. Try again later")
  }
}

function* handleCreateCashbackAmountCredits(action) {
  try {
    const res = yield call(APIs.createCashbackAmountCreditsApi, action.data)
    if (isSuccess(res)) {
      yield put(EventRoomActions.createCashbackAmountCredits.success(res.data && res.data.response))
      if (action.data.callback) action.data.callback()
    }
  } catch (error) {
    yield put(EventRoomActions.createCashbackAmountCredits.failure(error))
    message.error("Something went wrong. Try again later")
  }
}

function* handleGetCashbackAgentTimeline(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    const res = yield call(APIs.getCashbackAgentTimelineApi, query)
    yield put(EventRoomActions.getCashbackAgentTimeline.success(res.data && res.data.response))
  } catch (error) {
    yield put(EventRoomActions.getCashbackAgentTimeline.failure(error))
  }
}

function* handleSendJobNotifications(action) {
  try {
    const { teamId } = action.data
    const res = yield call(APIs.sendJobNotificationsApi, action.data)
    yield put(EventRoomActions.sendJobNotifications.success(res.data && res.data.response))
    message.success("Email has been sent!")
    const localBody = {
      skip: 0,
      limit: 5,
    }
    if (teamId) {
      localBody.teamId = teamId
    }
    yield put(EventRoomActions.getAllJobsByTeamId.request(localBody))
  } catch (error) {
    yield put(EventRoomActions.sendJobNotifications.failure(error))
    message.error("Something went wrong. Try again later")
  }
}

function* handleGetOnboardingCommission(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    const res = yield call(APIs.getOnboardingCommissionAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(EventRoomActions.getOnboardingCommission.success(response))
    }
  } catch (e) {
    yield put(EventRoomActions.getOnboardingCommission.failure(e))
  }
}

function* handleCreateOnboardingCommission(action) {
  try {
    const {
      payload,
      isDraft,
      isContinue,
    } = action.data

    const res = yield call(APIs.createOnboardingCommissionAPI, payload)
    yield put(EventRoomActions.getOnboardingCommission.request({ admin_name: payload.admin_name, agent_id: payload.agent_id }))
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(EventRoomActions.createOnboardingCommission.success(response))
      message.success("Payment Info are Created")
    }
  } catch (e) {
    yield put(EventRoomActions.createOnboardingCommission.failure(e))
    message.error("You are not allowed to Add/Edit Commission Info. Please check with your administrator for access")
  }
}

function* handkleUpdateOnboardingCommission(action) {
  try {
    const {
      payload,
      isDraft,
      isContinue,
    } = action.data
    const res = yield call(APIs.updateOnboardingCommissionAPI, payload)
    yield put(EventRoomActions.getOnboardingCommission.request({ admin_name: payload.admin_name, agent_id: payload.agent_id }))
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(EventRoomActions.updateOnboardingCommission.success(response))
      message.success("Payment Info are Updated")
    }
  } catch (e) {
    yield put(EventRoomActions.updateOnboardingCommission.failure(e))
    message.error("You are not allowed to Add/Edit Commission Info. Please check with your administrator for access")
  }
}

function* handleGetRoomsRecordings(action) {
  try {
    const {
      skip,
      limit,
    } = action.data || {}
    const res = yield call(APIs.getRecordedRoomsContentAPI, skip, limit)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(EventRoomActions.getRecordedRoomsContentAction.success(response))
    }
  } catch (e) {
    yield put(EventRoomActions.getRecordedRoomsContentAction.failure(e))
  }
}

function* handleRoomRecordingDelete(action) {
  try {
    const {
      payload,
    } = action.data || {}
    const res = yield call(APIs.deleteRecordingAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(EventRoomActions.deleteRecordingsAction.success(response))
      yield put(EventRoomActions.toggleDownloadDrawerAction.call({
        bool: false,
        data: null,
      }))
      toast.success("All recording deleted")

      yield put(EventRoomActions.getRecordedRoomsContentAction.request({
        skip: 0,
        limit: 100,
      }))
    }
  } catch (e) {
    yield put(EventRoomActions.deleteRecordingsAction.failure(e))
  }
}

function* handleRoomUpdate(action) {
  try {
    const {
      payload,
    } = action.data || {}
    const res = yield call(APIs.updateScheduledRoomsAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(EventRoomActions.updateScheduledRoomsAction.success(response))
      yield put(EventRoomActions.toggleScheduledRoomDrawerAction.call({
        bool: false,
        data: null,
      }))
      yield put(EventRoomActions.getScheduledRoomsAction.request())
    }
  } catch (e) {
    yield put(EventRoomActions.updateScheduledRoomsAction.failure(e))
  }
}

function* handleScheduledRoomDelete(action) {
  try {
    const {
      id,
      allInstance = "",
      payload,
    } = action.data || {}
    const res = yield call(APIs.deleteScheduledRoomsAPI, id, allInstance, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(EventRoomActions.deleteScheduledRoomsAction.success(response))
      yield put(EventRoomActions.toggleScheduledRoomDrawerAction.call({
        bool: false,
        data: null,
      }))
      root.localStorage.setItem("deleteAllOccurences", false)
      yield put(EventRoomActions.getScheduledRoomsAction.request())
    }
  } catch (e) {
    yield put(EventRoomActions.deleteScheduledRoomsAction.failure(e))
  }
}

export default function* main() {
  yield takeLatest(EventRoomActions.getRooms.REQUEST, handleFetchRooms)
  yield takeLatest(EventRoomActions.createRooms.REQUEST, handleCreateRooms)
  yield takeLatest(EventRoomActions.updateRoom.REQUEST, handleUpdateRoom)
  yield takeLatest(EventRoomActions.endRoom.REQUEST, handleEndRoom)
  yield takeLatest(EventRoomActions.getFinancingClientList.REQUEST, handleGetFinancingClientList)
  yield takeLatest(EventRoomActions.getFinancingSetting.REQUEST, handleGetFinancingSetting)
  yield takeLatest(EventRoomActions.saveFinancingSetting.REQUEST, handleSaveFinancingSetting)
  yield takeLatest(EventRoomActions.updateFinancingClientList.REQUEST, handleUpdateFinancingClientList)
  yield takeLatest(EventRoomActions.deleteClient.REQUEST, handleDeleteClient)
  yield takeLatest(EventRoomActions.getAllJobsByTeamId.REQUEST, handleGetAllJobsByTeamId)
  yield takeLatest(EventRoomActions.getJobApplications.REQUEST, handleGetJobApplications)
  yield takeLatest(EventRoomActions.updateJobApplications.REQUEST, handleUpdateJobApplications)
  yield takeLatest(EventRoomActions.createJobs.REQUEST, handleCreateJobs)
  yield takeLatest(EventRoomActions.updateJobs.REQUEST, handleUpdateJobs)
  yield takeLatest(EventRoomActions.deleteJobs.REQUEST, handleDeleteJobs)
  yield takeLatest(EventRoomActions.uploadImagesToS3Action.REQUEST, handleImageUploadToS3)
  yield takeLatest(EventRoomActions.getCashbackList.REQUEST, handleGetCashbackList)
  yield takeLatest(EventRoomActions.getCashbackAgentInfo.REQUEST, handleGetCashbackAgentInfo)
  yield takeLatest(EventRoomActions.updateCashbackAgentInfo.REQUEST, handleUpdateCashbackAgentInfo)
  yield takeLatest(EventRoomActions.createCashbackAmountCredits.REQUEST, handleCreateCashbackAmountCredits)
  yield takeLatest(EventRoomActions.getCashbackAgentTimeline.REQUEST, handleGetCashbackAgentTimeline)
  yield takeLatest(EventRoomActions.sendJobNotifications.REQUEST, handleSendJobNotifications)

  yield takeLatest(EventRoomActions.getOnboardingCommission.REQUEST, handleGetOnboardingCommission)
  yield takeLatest(EventRoomActions.createOnboardingCommission.REQUEST, handleCreateOnboardingCommission)
  yield takeLatest(EventRoomActions.updateOnboardingCommission.REQUEST, handkleUpdateOnboardingCommission)
  yield takeLatest(EventRoomActions.getRecordedRoomsContentAction.REQUEST, handleGetRoomsRecordings)
  yield takeLatest(EventRoomActions.deleteRecordingsAction.REQUEST, handleRoomRecordingDelete)
  yield takeLatest(EventRoomActions.getScheduledRoomsAction.REQUEST, handleFetchScheduledRooms)
  yield takeLatest(EventRoomActions.updateScheduledRoomsAction.REQUEST, handleRoomUpdate)
  yield takeLatest(EventRoomActions.deleteScheduledRoomsAction.REQUEST, handleScheduledRoomDelete)
}

