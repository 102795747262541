/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import styled from "styled-components"
import Select from "react-select"
import { isEmpty } from "lodash"
import container from "container/BrokerDashboard/subscriptionContainer"
import FeaturesPanel from "./FeaturesPanel"
import AddonsSelector from "./AddonsSelector"
import Summary from "./Summary"
import FetchAddons from "./FetchAddons"
import { getInterval } from "./../helper"

const Wrap = styled.div`
  margin: 30px 20px;
  display: flex;
  position: relative;
  h1 {
    font-weight: 400;
    font-size: 30px;
  }
`

const SubArea = styled.div`
  flex: 1;
`

const OptionsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  > p {
    flex: 1;
  }
  span {
    color: red;
    cursor: pointer;
  }
`

const DurationDropdown = styled.div`
  display: flex;
  align-items: center;
  > p { 
    flex: 1;
    text-align: right;
    padding-right: 20px;
  }
  > div {
    width: 200px;
  }
`

const PlansWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  flex-wrap: wrap;
`

const PlanWrap = styled.div`
  text-align: center;
  width: 30%;
  background: #F1EEFF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  margin: 20px 0px;
  cursor: pointer;
  transition-duration: 0.3s;

  &:hover {
    transform: scale(1.1);
    box-shadow: rgb(0 0 0 / 25%) 0px 5px 10px 0px;
    background: #dbd4fb;
  }

  ${props => props.isSelected && `
    border: 3px solid #100063;
    > h2 {
      background: #100063;
      color: #fff;
    }
  `}

  > h2 {
    border: 1px solid #100063;
    display: inline;
    max-width: 100px;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0px;
  }
`

const DashboardBusy = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > img {
    max-width: 200px;
  }
  p {
    font-size: 28px;
  }
`

const DURATIONS = [
  { label: "Monthly", value: "MONTHLY" },
  { label: "Quarterly", value: "QUARTERLY" },
  { label: "Half-yealy", value: "HALF-YEARLY" },
  { label: "Yearly", value: "YEARLY" },
]

class Subscription extends React.PureComponent {
  state = {}

  componentDidMount() {
    const {
      fetchSubscriptions,
      fetchStripePlans,
      getSubsribedAddons,
      resetSelections,
      clearAddons,
      match,
    } = this.props
    const { agentId } = match.params
    resetSelections()
    clearAddons()
    fetchSubscriptions({
      agentId,
    })
    getSubsribedAddons({
      agentId,
    })
    fetchStripePlans()
  }

  handleSelectPlan = (plan, isDefaultSelect) => {
    const {
      getPlanbenefits,
      match,
      selectPlan,
      allBrokerPlans,
      changePlanDuration,
      selectAddon,
    } = this.props
    const { agentId } = match.params
    const { plan_id, id } = plan
    const selectedSub = allBrokerPlans.find(x => x.plan_id === plan.id)
    if (isDefaultSelect && selectedSub) {
      const selectedDuration = getInterval(selectedSub) || ""
      if (selectedDuration.includes("month")) {
        changePlanDuration(DURATIONS[0].value)
      } else if (selectedDuration.includes("quarterly")) {
        changePlanDuration(DURATIONS[1].value)
      } else if (selectedDuration.includes("half-")) {
        changePlanDuration(DURATIONS[2].value)
      } else  if (selectedDuration.includes("year")) {
        changePlanDuration(DURATIONS[3].value)
      }
    }
    getPlanbenefits({
      planId: plan_id || id,
      agentId,
    })
    selectPlan({
      planId: plan_id || id,
      plan
    })
    selectAddon("removeKVCore")
  }

  render() {
    const {
      currentPlan,
      changePlanDuration,
      selectedDuration,
      selectedPlanList,
      planBenefitsResponse,
      selectedPlan,
      selectAddon,
      selectedAddons,
      checkoutBrokerPlan,
      checkCoupon,
      match,
      couponCodeResponse,
      removeCouponCode,
      cancelSubscription,
      resetSelections,
      isBusy,
      currentAPISelectionMap,
      addons,
      saveCardResponse,
      allBrokerPlans,
      addOnPlanIds,
      addOnPlanIdsToAdd,
      addOnsSelected,
      addOnsRemoved,
      highlightSelectedAddon,
    } = this.props
    const { agentId } = match.params
    return (
      <Wrap>
        {isBusy && (
          <DashboardBusy>
            <img src={require("./../../../images/soul/processing.gif")} alt=""/>
            <h4>Processing..</h4>
          </DashboardBusy>
        )}
        {allBrokerPlans && allBrokerPlans.length > 0 && currentPlan && !isEmpty(currentPlan.currentPlan) && (
          <FetchAddons
            activePlanId={currentPlan.currentPlan}
            onMount={() => { this.handleSelectPlan(currentPlan.currentPlan, true) }}
          />
        )}
        <SubArea>
          <h1>Brokerage Subscription</h1>
          <OptionsWrap>
            <span
              onClick={() => {
                if (window.confirm("Are you sure?")) {
                  cancelSubscription({ agentId })
                }
              }}
            >
              Cancel subscription
            </span>
          </OptionsWrap>
          <DurationDropdown>
            <p>
              Duration
            </p>
            <Select
              options={DURATIONS}
              value={{ value: selectedDuration, label: selectedDuration }}
              onChange={(selectedItem) => {
                resetSelections()
                changePlanDuration(selectedItem.value)
              }}
            />
          </DurationDropdown>
          <SubArea>
          </SubArea>
          <h5>Main Plan</h5>
          <PlansWrap>
            {selectedPlanList.map(plan => (
              <PlanWrap
                key={plan.id}
                onClick={() => { this.handleSelectPlan(plan) }}
                isSelected={(selectedPlan && selectedPlan.planId === plan.plan_id)}
              >
                {plan.plan_id === "broker_accelerator_program_yearly_0" ? (
                  <p>
                    Broker Accelerator
                  </p>
                ) : (
                  <p>
                    {`${plan.rar_plan_type}`}
                  </p>
                )}
                
                <h2>
                  {`$${plan.unit_price}`}
                </h2>
                <h6>
                  {selectedDuration}
                </h6>
              </PlanWrap>
            ))}
          </PlansWrap>
          <h5>Addons</h5>
          <AddonsSelector
            planBenefitsResponse={planBenefitsResponse}
            selectAddon={selectAddon}
            selectedAddons={selectedAddons}
            addons={addons}
            addOnPlanIds={addOnPlanIds}
            addOnPlanIdsToAdd={addOnPlanIdsToAdd}
            addOnsSelected={addOnsSelected}
            addOnsRemoved={addOnsRemoved}
            highlightSelectedAddon={highlightSelectedAddon}
          />
          <Summary
            selectedAddons={selectedAddons}
            isPlanAlready={!isEmpty(currentPlan)}
            isCardAdded={!isEmpty(saveCardResponse.data?.payment_sources)}
            selectedPlan={selectedPlan}
            checkoutBrokerPlan={checkoutBrokerPlan}
            checkCoupon={checkCoupon}
            agentId={agentId}
            couponCodeResponse={couponCodeResponse}
            removeCouponCode={removeCouponCode}
            currentAPISelectionMap={currentAPISelectionMap}
            currentPlan={currentPlan}
            addOnsSelected={addOnsSelected}
          />
        </SubArea>
        <FeaturesPanel
          selectedPlan={selectedPlan && selectedPlan.plan}
        />
      </Wrap>
    )
  }
}

export default (container(Subscription))
