import axios from "axios"

const BASE_URL = "https://api.radius-mortgage.com/v1.0"

const headers = new Headers()
headers.append("internal-access-token", "ed69b3938beb82879072acfd97d1f4a7")

export const getCurrectRateAPI = async () => {
  const res = await fetch(`${BASE_URL}/mortgage/rates-settings`, {
    method: "GET",
    headers,
  })
  const json = await res.json()
  return json.response
}

export const updateRatesAPI = async (id, payload) => {
  const res = await fetch(`${BASE_URL}/mortgage/rates-settings/${id}`, {
    method: "PUT",
    headers,
    body: JSON.stringify(payload),
  })
  const json = await res.json()
  return json.response
}

export const createNewRateAPI = async (payload) => {
  const res = await fetch(`${BASE_URL}/mortgage/rates-settings`, {
    method: "POST",
    headers,
    body: JSON.stringify(payload),
  })
  const json = await res.json()
  return json.response
}

