import React from "react"
import styled from "styled-components"
import { GetCTA, enabledStates } from "./CTAMapping"
import {
  CopyOnboardingURL,
  VoidAndDelete,
} from "./CTAS"

const Container = styled.div`
  border-top: 1px solid #F4F4F4;
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 15px;
`

const CTASection = ({
  isaDocuments,
  fullSignedICADocument,
}) => {
  const docStatus = isaDocuments && isaDocuments.length > 0 && isaDocuments[0].status
  const documentsFiltered = isaDocuments && isaDocuments.length > 0 && isaDocuments.filter(item => !enabledStates.includes(item.status)) || []
  const inactiveDocumentCheck = documentsFiltered && documentsFiltered.length > 0
  const isAgentSignaturePending = docStatus && docStatus === "agent_signature_pending"
  const isFullySignedICAShown = fullSignedICADocument && fullSignedICADocument.length > 0
  const showVoidAndDelete = (docStatus && docStatus !== "completed")

  //if all the buttons are active
  return (
    <Container>
      {(showVoidAndDelete) && (
        <VoidAndDelete disabled={inactiveDocumentCheck} />
      )}
      {(isAgentSignaturePending || isFullySignedICAShown) && (
        <CopyOnboardingURL disabled={!isFullySignedICAShown && inactiveDocumentCheck} />
      )}
      {GetCTA(docStatus, inactiveDocumentCheck, isFullySignedICAShown)}
    </Container>
  )
}

export default CTASection

