import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as Actions from "./actions"

function mapStateToProps(state) {
  const { mortgage } = state
  return {
    ...mortgage,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    getCurrentRates: Actions.getCurrentRates.request,
    updateCurrentRates: Actions.updateCurrentRates.request,
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)

