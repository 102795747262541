import React from "react"

const typeOveride = (type) => {
  switch (type) {
    case "full":
      return {
        display: "block",
        width: "100%",
        margin: "none",
      }
    default:
      return {}
  }
}

const H5 = props => <h5 style={{ fontSize: 12, fontWeight: "bold" }} {...props} />

const ProperyImage = ({
  image = "https://unplash-api.herokuapp.com/api/0Vdrg5pR7NY/thumb",
  address = "Propery Addreess (Line 1)",
  alt="Radius Agent Email Image",
  type,
}) => (
  <div
    style={{
      display: "inline-block",
      verticalAlign: "top",
      textAlign: "left",
      margin: 10,
      width: 200,
      ...typeOveride(type),
    }}
  >
    <img src={image} alt={alt} style={{ width: "100%" }} />
    {address && (
      <H5>{address}</H5>
    )}
  </div>
)

export default ProperyImage
