import React, { useEffect } from "react"
import styled from "styled-components"
import { ToastContainer } from "react-toastify"
import RARTechContainer from "container/TechAssets"
import { withRouter } from "react-router-dom"
import globalContainer from "container/BrokerDashboard/globalPagesContainer"
import Loader from "dumbComponents/common/UI/Loader"
import Overview from "dumbComponents/ListingDashboard/Overview/index_overview"
import { Button } from "antd"
import Header from "./Header_listing"
import Tabs from "./TabsListings"
import ListingsFilters from "./ListingFilters"
import ImportCSV from "./ImportCSV"

// import Stats from "./Common/Stats"

const Wrap = styled.div`
  padding: 20px 74px 20px 74px;

  @media(max-width: 1280px) {
    padding: 10px;
  }
`

//check here
const HeaderDiv = styled.div`
  margin: 20px;
  margin-left: 0px;
  h1 {
    font-weight: 500;
  }
`
const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
`

const ListingsDashboard = (props) => {
  const {
    tcOverviewListing,
    tcOverviewListingResponse,
    resetListings,
    setDealToView,
    setDealAgentId,
    setTotalListingCount,
    totalListingCount,
    history,
  } = props

  const addListings = () => {
    resetListings()
    setDealAgentId(null)
    setDealToView(null)
    history.push("/broker/tc/add-listings/coversheet/listing-agent-info")
  }

  const redirectToGoogleSheets = () => {
    // Redirect to the Google Sheets link when the button is clicked
    window.open("https://docs.google.com/spreadsheets/d/1ntdBuzamDsLfHfmi-epxfZdQW6rqWBYet2pSZegYpgQ/copy", "_blank")
  }

  useEffect(() => {
    tcOverviewListing({ })
  }, [])

  const { data: overviewTCData, isFetching: fetchingOverview } = tcOverviewListingResponse || {}
  const { overview: overviewData } = overviewTCData || {}
  setTotalListingCount(overviewData?.total)
  return (
    <Wrap>
      <Header />
      {fetchingOverview === true ? (
        <Loader />
      ) : (
        <Overview overview={overviewData?.overview} />
      )}
      <HeaderDiv>
        <ListingsFilters />
      </HeaderDiv>
      <ButtonDiv>
        <Button
          type="primary"
          onClick={() => { addListings() }}
          style={{ marginRight: "8px" }}
        >
        Add Listings
        </Button>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ImportCSV style={{ marginRight: "8px" }} />
          <Button
            type="primary"
            onClick={redirectToGoogleSheets}
            style={{ marginLeft: "8px" }}
          >
        Create CSV
          </Button>
        </div>
      </ButtonDiv>
      <Tabs />
      <ToastContainer />
    </Wrap>
  )
}

export default withRouter(globalContainer(ListingsDashboard))
