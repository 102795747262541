import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"
import AddEventMenu from "./AddEventsMenu"
import { DefaultLoader } from "components/UI/Loader/Loader";
import _ from 'lodash';
import { ToastContainer } from "react-toastify"

const Wrap = styled.div`
 height: 100px;
 width: 300px;
 border: 1px solid red;
 margin-left: 100px;
`

const EditInstructor = styled(Link)`
  margin-bottom: 14px;
  width: 118px;
  display: block;
  height: 30px;
  font-size: 14px;
  border: none;
  background: none;
  color: black;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding-top: 2px;
`

const ListWrap = styled.ul`
 list-style: none;
 padding:0;
 margin:0;
 li {
  width: 100%;
  height: 68px;
  border: solid 1px #e2e4e8;
  display: flex;
  .instructor_image {
    flex-grow: 1;
    height: 100%;
    max-width: 60px;
    padding-top: 14px;
    padding-left: 14px;
    img {
     width: 34px;
     height: 34px;
     border-radius: 18px;
    }
  }
  .instructor_details{
    height: 100%;
    padding: 14px;
    width: 222px;
   p {
    margin: 0;
   }
   p:last-child {
    font-size: 12px;
    color: #586069;
   }
  }
  .cta {
   height: 100%;
   width: 400px;
   display: flex;
   justify-content: flex-end;
   div {
    flex-grow: 1;
    height: 100%;
    padding: 23px 8px 8px 24px;
    max-width: 99px;
    button{
     cursor: pointer;
     background: none;
     border: none;
    }
    .delete {
     color: red;
    }
   }
  }
 }
`

const OuterWrap = styled.div`
 display: flex;
`

const CTAArea = styled.div`
 width: 160px;
 padding: 16px;
 padding-right: 25px;
`

const RightMenu = styled.div`
  height: 500px;
  width: 699px;
  padding-left: 20px;

  .access {
   display: flex;
   flex-direction: column;
  }

  .header {
   height: 50px;
   width: 100%;
   p {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin: 0;
   }
  }

  textarea {
   resize: none;
   font-family: "SF Pro Display", sans-serif;
   width: 724px;
   height: 125px;
   border-radius: 3px;
   border: solid 0.5px #000000;
  }
`

const CTA = styled.button`
  margin-bottom: 15px;
  width: 118px;
  display: block;
  height: 30px;
  border-radius: 4px;
  border: solid 1px #2a8b3f;
  background-image: linear-gradient(to bottom,#34ce57,#28a745);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  float: right;
  cursor: pointer;
  font-size: 12px;
`

class AllSettings extends Component {
 state = {}

 componentDidMount(){
  const {
   getCurrentGlobalSetting,
  } = this.props

  getCurrentGlobalSetting()
 }

 handleUpdate = () => {
  const {
   academyDailyEventSummaryNotification,
   setCurrentGlobalSetting,
  } = this.props

  const payload = {
   title: academyDailyEventSummaryNotification || "",
  }
  setCurrentGlobalSetting({
   payload,
  })
 }

 handleChange = (e) => {
  const {
   saveSettingsValues,
  } = this.props

  this.setState({
   [e.target.name]: e.target.value
  })
  saveSettingsValues({
   data: e.target.value,
   type: e.target.name
  })
 }

 render(){
  const {
   getEventDetailsResponse,
   eventAnnouncementText,
   eventReminderPost,
   accessArray,
   academyDailyEventSummaryNotification,
   getGlobalEventNotificationResponse,
   setGlobalEventNotificationResponse,
  } = this.props


  const {
   data: eventsSettingDetails,
   isFetching,
  } = getGlobalEventNotificationResponse

  const {
   isFetching: isSaving,
  } = setGlobalEventNotificationResponse

  console.log(academyDailyEventSummaryNotification)

  return (
    <React.Fragment>
    {isFetching || isSaving ? (
     <OuterWrap>
       <CTAArea>
        <CTA disabled>Update Settings</CTA>
       </CTAArea>
       <RightMenu>
        <DefaultLoader height={200} width={200}/>
       </RightMenu>
     </OuterWrap>
    ) : (
     <OuterWrap>
      <CTAArea>
       <CTA onClick={this.handleUpdate}>Update Settings</CTA>
      </CTAArea>
      <RightMenu>
        <div>
         <p>Notification Settings (Common for all Events)</p>
          <div>
           <p>Daily Event Summary Notification Text</p>
           <textarea
           onChange={this.handleChange}
           name="academyDailyEventSummaryNotification"
           value={academyDailyEventSummaryNotification}
           >
           </textarea>
          </div>
        </div>
      </RightMenu>
      <ToastContainer />
     </OuterWrap>
    )}
    </React.Fragment>
  )
 }
}

export default Container(AllSettings)
