import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import InstructorsList from "./InstructorsList"
import InstructorsMenu from "./InstructorsMenu"
import colors from "../../../lib/colors"

const OuterWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`
const LeftMenu = styled.div`
  height: 73px;
  max-width: 188px;
  flex-grow: 1;
`

const RightMenu = styled.div`
  height: 500px;
  width: 699px;
  .cta {
   display: flex;
   flex-direction: row;
   width: 100%;
   height: 96px;
   .info {
     flex-grow:2;
     padding: 20px;
     width: 166px;
     height: 19px;
     font-family: "SF Pro Display", sans-serif;
     font-size: 16px;
     font-weight: 500;
     color: #000000;
     p {
      margin: 0;
     }

     .sub{
      font-size: 12px;
      color: #586069;
     }
   }

   .add {
     flex-grow:1;
     a {
      float: right;
      text-align: center;
      padding: 4px;
      text-decoration: none;
      margin-right: 10px;
      margin-top: 10px;
      width: 92px;
      height: 30px;
      border-radius: 4px;
      border: solid 1px #2a8b3f;
      background-image: linear-gradient(to bottom, #34ce57, #28a745);
      color: white;
     }
   }
  }
`

const Wrap = styled.div`
height: 100px;
width: 100px;
background: red;
`

class Instructors extends Component{
 componentDidMount(){
  const {
   fetchMultipleInstructors,
  } = this.props

  fetchMultipleInstructors()
 }

 clearAddForm = () => {
  const {
   clearInstructorForm,
   clearFormsEdits,
  } = this.props

  clearInstructorForm()
  clearFormsEdits()
 }

 render() {
  const {
   multipleInstructorsResponse,
  } = this.props

  const {
   data: instructors,
   isFetching: fetchingMultipleInstructors,
  } = multipleInstructorsResponse

  return (
   <OuterWrap>
    <LeftMenu>
     <InstructorsMenu />
    </LeftMenu>
    <RightMenu>
     <div className="cta">
       <div className="info">
         <p>All the available persons</p>
         <p className="sub">Instructors added by other persons will also be visible here</p>
       </div>
       <div className="add">
           <Link onClick={() => this.clearAddForm()} to="/academy-admin/people/add-new-instructor">Add</Link>
       </div>
     </div>
     <div className="list">
      <InstructorsList
        instructors={instructors}
      />
     </div>
    </RightMenu>
   </OuterWrap>
  )
 }
}

export default Container(Instructors)
