import React, { Component } from 'react';
import _ from 'lodash';
import bugsnag from 'bugsnag-js';
import Notifications, {notify} from 'react-notify-toast';
import moment from 'moment';
import { Flex, Box } from "@rebass/grid";
import styled from "styled-components";
import Textbox from "../../UI/Textbox/Textbox";
import Button from "../../UI/Button/Button";
import DatePicker from "react-datepicker";
import {DefaultLoader} from "../../UI/Loader/Loader";
import MultiCallerNote from "./NotesForPool"

const baseUrl =process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;
const originalKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(originalKey);

const ModalTitle = styled.h3`
  font-size: 24px;
  font-weight: 500;
  color: #354052;
  margin-top: 0;
`;

const ModalIcon = styled.i`
  float: right;
  color: #dadada;
  font-size: 22px;
  margin-right: -20px;
  cursor: pointer;
`;

const defaultCallSettings = {
  "Referrals": {
    "sources": [
      "SubmitLeads"
    ],
    "start": 1536750446,
    "end": 1536760446
  },
  "ISA": {
    "sources": [],
     "start": 1536750446,
    "end": 1536760446
  },
  "Enquiry": {
    "sources": [],
      "start": 1536750446,
    "end": 1536760446
  },
  "NewLeadCallQueue" : false,
  "MissedCallQueue" : false,
  "ReceivedSmsQueue": false,
};

const StyledDatePicker = styled(DatePicker)`
  outline: none;
  background-color: white;
  font: inherit;
  padding: 6px 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 0.5px solid #d4d4d4;
  border-radius: 2px;
  height: 40px;
  box-shadow: none;
  overflow: visible;
  margin: 0;
  color: inherit;
  cursor: text;
  &:focus,
  &:active {
    box-shadow: none;
    border: solid 0.5px #11adf3;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc !important;
    font-weight: 300;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ccc !important;
    font-weight: 300;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ccc !important;
    font-weight: 300;
  }
`;

const Paragraph = styled.p`
  font-size: 18px;
  color: #4a4a4a;
`;

const SourceTable = styled.table`
  border: solid 1px #9b9b9b;
  td,
  th {
    border: none;
    background: #ffffff;
    padding: 10px;
  }

  tr {
    &:first-child {
      border-top: 1px solid #9b9b9b;
    }
  }
`;

const StyledAnchor = styled.a`
  font-size: 16px;
  font-weight: 500;
  color: #d26464;
  text-decoration: none;
`;

const ModalFooter = styled(Flex)`
  margin: 20px 0;
`;


class EditSettings extends Component {

  state = {
    consultant: null,
    startDate: null,
    endDate: null,   
    selectAll: false,
    isEditingPoolSetting: false,
      loading:false,
  };

    startLoader = () => {
        this.setState({
            loading: true
        });
    };
    stopLoader = () => {
        this.setState({
            loading: false
        });
    };

  handleStartDateChange(date) {
    this.setState({
        startDate: date,
    });
  }

  handleEndDateChange(end) {
    if (this.state.startDate){
      let start = this.state.startDate;
      if (end) {
        if (end < start){
          notify.show('The start date must be before the end date', 'error', 5000);
        }
        else{
          this.setState({
            endDate: end,
          });
        }
      }
      else{
        this.setState({
          endDate: null,
        });

      }
    }
    else{
      notify.show('Please select the start date first', 'error', 5000);
    }
   
  }

  setConsultantData = (consultant) => {
    try{
      if (consultant){
        let startDate;
        let endDate = null;
    
        let callSettings = consultant.call_settings;
        if (!callSettings){
          consultant.call_settings = defaultCallSettings;
          callSettings = defaultCallSettings;
        }
        let keys = _.keys(callSettings);
        console.log(keys);
        startDate = callSettings[keys[0]].start * 1000;
        console.log(startDate);
        if (callSettings[keys[0]].end){
          endDate = callSettings[keys[0]].end * 1000;
        }
        let selectAll = false;
        if (callSettings.NewLeadCallQueue &&
            callSettings.MissedCallQueue &&
          callSettings.ReceivedSmsQueue && 
          callSettings['Referrals']['sources'].length === 5 &&
          callSettings['ISA']['sources'].length === 5 &&
          callSettings['ISA']['sources'].length === 5 &&
          callSettings['Enquiry']['sources'].length === 3
        ){
          selectAll = true;
        }
        this.setState({ consultant, startDate, endDate, selectAll});
      }
    }
    catch(e){
      console.log(e);
      bugsnagClient.notify(e);
    }
  };

  componentWillReceiveProps(props) {
    this.setConsultantData(props.consultant);
    this.setState({ currentReferralSources: props.integrationSources, isEditingPoolSetting: props.isEditingPoolSetting});
  }

  componentDidMount() {
    this.setConsultantData(this.props.consultant);
    this.setState({ currentReferralSources: this.props.integrationSources});
  }

  toggle = (val, is_isa, isSelectAll) => {
    try {
      if (val === 'App Submits') {
        val = 'SubmitLeads';
      }
      
      if (this.state.consultant && this.state.consultant.call_settings){
        let callSettings = this.state.consultant.call_settings;
        let selectedSources = [];

        // if (isSelectAll) {
        //   selectedSources = callSettings['ISA']['sources'].m
        // }
  
        if (is_isa) {
          selectedSources = callSettings['ISA']['sources'];
        }
        else{
          selectedSources = callSettings['Referrals']['sources'];
        }
  
        if (selectedSources.indexOf(val) !== -1){
          selectedSources = _.reject(selectedSources, (src) => src === val);
        }
        else{
          selectedSources.push(val);
        }
  
        if (is_isa) {
          callSettings['ISA']['sources'] = selectedSources;
        }
        else{
          callSettings['Referrals']['sources'] = selectedSources;
        }
        let consultant = this.state.consultant;
        consultant.call_settings = callSettings;
        console.log(consultant)
        this.setState({consultant});
      }
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

  toggleInquiries = (isShow) => {    
    try {
      if (this.state.consultant && this.state.consultant.call_settings){
        let callSettings = this.state.consultant.call_settings;
        let selectedEnquirySources = callSettings['Enquiry']['sources'];
        if (selectedEnquirySources.indexOf('Enquiry') !== -1 && !isShow){
          callSettings['Enquiry']['sources'] = [];
        }
        else{
          callSettings['Enquiry']['sources'].push('Enquiry');
          callSettings['Enquiry']['sources'].push('trustle');
          callSettings['Enquiry']['sources'].push('SMS call');
        }
        let consultant = this.state.consultant;
        consultant.call_settings = callSettings;
        this.setState({consultant});
      }
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

  updateMaxAllowedFailedCallCount = (e) => {
    try {
      if (this.state.consultant && this.state.consultant.call_settings){
        let callSettings = this.state.consultant.call_settings;
        callSettings.MaxAllowedFailedCallCount = e.target.value;
        let consultant = this.state.consultant;
        consultant.call_settings = callSettings;
        this.setState({consultant});
      }
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

    updateMinIntegrationCalls = (e) => {
        try {
            if (this.state.consultant && this.state.consultant.call_settings){
                let callSettings = this.state.consultant.call_settings;
                callSettings.MinIntegrationCallsBeforeCallingBulkLeads = e.target.value;
                let consultant = this.state.consultant;
                consultant.call_settings = callSettings;
                this.setState({consultant});
            }
        }
        catch(e){
            bugsnagClient.notify(e);
        }
    };

    updateNumberOfParallelDials = (e) => {
        try {

            if (this.state.consultant){
                let consultant = this.state.consultant;
                let min = consultant.pool_agents ? consultant.pool_agents.length : 1;
                let max = consultant.pool_agents ? 3 * consultant.pool_agents.length : consultant.max_calls;
                consultant.max_calls = e.target.value;
                consultant.max_calls = consultant.max_calls > max ? max : consultant.max_calls;
                consultant.max_calls = consultant.max_calls < min ? min : consultant.max_calls;
                this.setState({consultant});
            }
        }
        catch(e){
            bugsnagClient.notify(e);
        }
    };

  toggleIncoming = (isShow) => {
    try {
      if (this.state.consultant && this.state.consultant.call_settings){
        let callSettings = this.state.consultant.call_settings;
        if (isShow) {
          callSettings.MissedCallQueue = true;
        } else {
          callSettings.MissedCallQueue = !callSettings.MissedCallQueue;
        }
        let consultant = this.state.consultant;
        consultant.call_settings = callSettings;      
        this.setState({consultant});
      }
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

  toggleNewLead = (isShow) => {
    try {
      if (this.state.consultant && this.state.consultant.call_settings){
        let callSettings = this.state.consultant.call_settings;
        if (isShow) {
          callSettings.NewLeadCallQueue = true;
        } else {
          callSettings.NewLeadCallQueue = !callSettings.NewLeadCallQueue;
        }
        let consultant = this.state.consultant;
        consultant.call_settings = callSettings;
        this.setState({consultant});
      }
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };


  toggleSMS = (isShow) => {
    try {
      if (this.state.consultant && this.state.consultant.call_settings){
        let callSettings = this.state.consultant.call_settings;
        if (isShow) {
          callSettings.ReceivedSmsQueue = true
        } else {
          callSettings.ReceivedSmsQueue = !callSettings.ReceivedSmsQueue;
        }
        let consultant = this.state.consultant;
        consultant.call_settings = callSettings;      
        this.setState({consultant});
      }
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

  togglePostClaim = (isShow) => {
    try {
      if (this.state.consultant && this.state.consultant.call_settings){
        let callSettings = this.state.consultant.call_settings;
        if (isShow) {
          callSettings.FollowUpCallQueue = true
        } else {
          callSettings.FollowUpCallQueue = !callSettings.FollowUpCallQueue;
        }
        let consultant = this.state.consultant;
        consultant.call_settings = callSettings;      
        this.setState({consultant});
      }
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

  toggleSelectAll = () => {
    try{
      let callSettings = this.state.consultant.call_settings;
      let keys = _.keys(callSettings);
      let booleanKeys = ['MissedCallQueue', 'NewLeadCallQueue', 'ReceivedSmsQueue', 'MaxAllowedFailedCallCount',
          'MinIntegrationCallsBeforeCallingBulkLeads', 'PrioritizedAgentIds', 'prioritized_agents'];

      if (this.state.selectAll) {
        _.each(keys,(key) =>{
            if (booleanKeys.indexOf(key) === -1){
              callSettings[key]['sources'] = [];
            }
            else{
              if (key !== "MaxAllowedFailedCallCount" && key !== "PrioritizedAgentIds" && key !== 'MinIntegrationCallsBeforeCallingBulkLeads') {
                  callSettings[key] = false;
              }
            }
        });
      }
      else {
        let sourceValues = [];
        _.each(this.state.currentReferralSources, (source) => {
            sourceValues.push(source.value);
        });

        callSettings['Enquiry']['sources'] = [];
        callSettings['Enquiry']['sources'].push('Enquiry');
        callSettings['Enquiry']['sources'].push('trustle');
        callSettings['Enquiry']['sources'].push('SMS call');

        callSettings['ISA']['sources'] = [];
        callSettings['ISA']['sources'] =   callSettings['ISA']['sources'].concat(sourceValues);

        callSettings['Referrals']['sources'] = [];
        callSettings['Referrals']['sources'] =   callSettings['Referrals']['sources'].concat(sourceValues);

        _.each(keys,(key) =>{
          if (booleanKeys.indexOf(key) !== -1 && key !== "MaxAllowedFailedCallCount"  && key !== "PrioritizedAgentIds" && key !== "MinIntegrationCallsBeforeCallingBulkLeads"){
            callSettings[key] = true;
          }
        });
      }

      let consultant = this.state.consultant;
      consultant.call_settings = callSettings;

      let selectAll = !!!this.state.selectAll;
      this.setState({consultant, selectAll});
    }
    catch(e){
      bugsnagClient.notify(e);
    }  
  };

  save = async () => {
    try {
      this.startLoader();
      let max_calls = this.state.consultant.max_calls;
      let call_settings =  this.state.consultant.call_settings;
      let isEditingPoolSetting = this.state.isEditingPoolSetting;
      console.log({ call_settings });
      let keys = _.keys(this.state.consultant.call_settings);
      let booleanKeys = ['MissedCallQueue', 'NewLeadCallQueue', 'ReceivedSmsQueue', 'MaxAllowedFailedCallCount',
          'MinIntegrationCallsBeforeCallingBulkLeads', 'PrioritizedAgentIds', 'prioritized_agents', 'FollowUpCallQueue'];
      if (call_settings['MaxAllowedFailedCallCount']){
        call_settings['MaxAllowedFailedCallCount'] = parseInt(call_settings['MaxAllowedFailedCallCount']);
      }
        if (call_settings['MinIntegrationCallsBeforeCallingBulkLeads']){
            call_settings['MinIntegrationCallsBeforeCallingBulkLeads'] = parseInt(call_settings['MinIntegrationCallsBeforeCallingBulkLeads']);
        }
      _.each(keys,(key) =>{
          if (booleanKeys.indexOf(key) === -1){
            call_settings[key]['start'] = parseInt(moment(this.state.startDate).format('X'));
            if (this.state.endDate) {
              call_settings[key]['end'] = parseInt(moment(this.state.endDate).format('X'));
            }
            else {
              call_settings[key]['end'] = null; 
            }
          }
      });

      delete call_settings["prioritized_agents"];
  
      let body = {
        is_pool:isEditingPoolSetting,
        call_settings: call_settings,
        max_calls: max_calls,
      };
      let response = await fetch(baseUrl + '/v1.0/consultants/edit/' + this.state.consultant.id, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'secure-id': token,
          },
          body: JSON.stringify(body)

      });
      let respJson = await response.json();
      await this.props.loadAccounts(); 
      await this.props.closeModal();
      this.stopLoader();
    }
    catch (e) {
      await this.props.loadAccounts(); 
      await this.props.closeModal();
      console.log(e);
      bugsnagClient.notify(e);
      this.stopLoader();
    }  
  };

  handleSelectAll = (data) => {
    this.toggleNewLead(true)
    this.toggleSMS(true)
    this.toggleIncoming(true)
    this.toggleInquiries(true)
  }

  handleSelectSpecificReferral = (type) => {
    const { consultant } = this.state
    const { integrationSources } = this.props
    const allSource = integrationSources.map(item => item.value)
    consultant.call_settings[type].sources = allSource
    console.log(consultant)
    this.setState({
      consultant
    })
  }

  handleDelectAll = (type) => {
    const { consultant } = this.state
    const { integrationSources } = this.props
    const allSource = integrationSources.map(item => item.value)
    consultant.call_settings[type].sources = []
    this.setState({
      consultant
    })
  }

  render() {
    const props = this.props;
    let callSettings = null;
    let isEditingPoolSetting = this.state.isEditingPoolSetting;
    let consultant = this.props.consultant ? this.props.consultant : {};

    if (this.props.consultant && this.props.consultant.call_settings){
      callSettings = this.props.consultant.call_settings;
    }
      let selectedEnquirySources = callSettings ? callSettings['Enquiry']['sources'] : [];
      let selectedISASources = callSettings ? callSettings['ISA']['sources'] : [];
      let selectedReferralSources = callSettings ? callSettings['Referrals']['sources'] : [];
      let newLeadQueue = callSettings ? callSettings.NewLeadCallQueue : false;
      let incomingCalls = callSettings ? callSettings.MissedCallQueue : false;
      let smsReplies = callSettings ? callSettings.ReceivedSmsQueue : false;
      let FollowUpCallQueue = callSettings ? callSettings.FollowUpCallQueue : false;
      let post = callSettings ? callSettings.ReceivedSmsQueue : false;
      let maxAllowedFailedCallCount = callSettings ? callSettings.MaxAllowedFailedCallCount : 9;
      let minIntegrationCallsBeforeCallingBulkLeads = callSettings ? callSettings.MinIntegrationCallsBeforeCallingBulkLeads : 3;
      let numberOfParallelDials = consultant ? consultant.max_calls : 1;
      let allowSave = ((selectedEnquirySources.length !== 0) || (selectedISASources.length !== 0)  || (selectedReferralSources.length) || newLeadQueue || incomingCalls || smsReplies);
      allowSave = allowSave && this.state.startDate;

    let referralSourcesDiv = null;
    if (this.state.currentReferralSources) {
        referralSourcesDiv = this.state.currentReferralSources.map((referralSource) =>
            <tr>
                <td>
                    <input type="checkbox"
                           checked={selectedReferralSources.indexOf(referralSource.value) !== -1}
                           onChange={() => this.toggle(referralSource.value, false)}
                    />
                    &nbsp;{referralSource.display_name}
                </td>
                <td>
                    <input type="checkbox"
                           checked={selectedISASources.indexOf(referralSource.value) !== -1}
                           onChange={() => this.toggle(referralSource.value, true)}
                    />
                    &nbsp;{referralSource.display_name}
                </td>
            </tr>);
    }

    return <div className={"caller-settings-modal"}>
        <React.Fragment>
      <Notifications />

      <ModalTitle>
        EDIT SETTINGS
        <ModalIcon
          className={"fas fa-times modal-icon"}
          onClick={props.closeModal}
        />
      </ModalTitle>


    {this.state.loading ?
        <DefaultLoader/> :
      <form className={"caller-settings-modal-form"}>
        <Flex>
          <Box width={1 / 2} my={2}>
            <StyledDatePicker
              selected={this.state.startDate}
              onChange={this.handleStartDateChange.bind(this)}
              placeholderText={"TIME RANGE - START"}
            />
          </Box>
          <Box width={1 / 2} my={2}>
            <StyledDatePicker
              selected={this.state.endDate}
              onChange={this.handleEndDateChange.bind(this)}
              placeholderText={"TIME RANGE - END"}
            />
          </Box>
        </Flex>

        {/*<Flex>*/}
          {/*<Box width={1 / 2} my={2}>*/}
              {/*{"Maximum failed call count"}*/}
            {/*<Textbox value={maxAllowedFailedCallCount} placeholder="Maximum failed call count"*/}
                     {/*onChange={this.updateMaxAllowedFailedCallCount}/>*/}
          {/*</Box>*/}
        {/*</Flex>*/}

          <Flex>
              <Box width={1/2} my={2}>
                  {"Min integration calls before calling bulk leads"}
                  <Textbox value={minIntegrationCallsBeforeCallingBulkLeads} placeholder="Min integration calls before calling bulk leads"
                           onChange={this.updateMinIntegrationCalls}/>
              </Box>
          </Flex>

          {isEditingPoolSetting && <Flex>
              <Box width={1/2} my={2}>
                  {"Number of parallel dials"}
                  <Textbox type="number" value={numberOfParallelDials} placeholder="Number of parallel dials"
                           onChange={this.updateNumberOfParallelDials}/>
              </Box>
          </Flex>}
          <MultiCallerNote />

        <Flex>
          <Paragraph>SOURCES</Paragraph>
        </Flex>
        <div>
          <SourceTable>
            <thead>
              <th>REFERRALS</th>
              <th>ISA</th>
            </thead>
            <tbody>
              {referralSourcesDiv}
              <tr>
                <td>
                  <button onClick={() => { this.handleSelectSpecificReferral("Referrals") }} type="button">
                    Select All
                  </button>
                  <button onClick={() => { this.handleDelectAll("Referrals") }} type="button">
                    Deselect All
                  </button>
                </td>
                <td>
                  <button onClick={() => { this.handleSelectSpecificReferral("ISA") }} type="button">
                    Select All
                  </button>
                  <button onClick={() => { this.handleDelectAll("ISA") }} type="button">
                    Deselect All
                  </button>
                </td>
              </tr>
            </tbody>
          </SourceTable>
          <div style={{ textAlign: "right", margin: "10px 0px" }}>
            <span onClick={this.handleSelectAll}>SELECT ALL</span>
          </div>
            
            <SourceTable>
                <thead>
                <th>INQUIRIES</th>
                <th>Calls</th>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <input type="checkbox"
                               checked={selectedEnquirySources.indexOf('Enquiry') !== -1}
                               onChange={() => { this.toggleInquiries() }}
                        />
                        &nbsp;INQUIRIES
                    </td>

                    <td>
                        <input type="checkbox"
                               checked={incomingCalls}
                               onChange={() => { this.toggleIncoming() }}
                        />
                        &nbsp;Missed Incoming Calls
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type="checkbox"
                               checked={smsReplies}
                               onChange={() => { this.toggleSMS() }}
                        />
                        &nbsp;SMS Replies
                    </td>
                  <td>
                      <input type="checkbox"
                             checked={newLeadQueue}
                             onChange={() => { this.toggleNewLead() }}
                      />
                      &nbsp;Prioritise integration and app submits
                  </td>
                </tr>
                <tr>
                    <td>
                        <input type="checkbox"
                               checked={FollowUpCallQueue}
                               onChange={() => { this.togglePostClaim() }}
                        />
                        &nbsp;Post Claim calls
                    </td>
                </tr>
                </tbody>
            </SourceTable>


        </div>
        <ModalFooter alignItems={"center"} justifyContent={"space-between"}>
          <StyledAnchor onClick={this.toggleSelectAll} href={"javascript:void(0);"}>{this.state.selectAll ? 'DESELECT ALL' : 'SELECT ALL'}</StyledAnchor>
          <Button disabled={!allowSave}  onClick={(e) => {e && e.preventDefault(); this.save();}}>Save</Button>
        </ModalFooter>

      </form>
    }
     </React.Fragment>
    </div>

  }

}
 

export default EditSettings;