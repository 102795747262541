import React, { Component } from "react"
import styled from "styled-components"
import { reduxForm, Field } from "redux-form"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import RTFEditorIO from "dumbComponents/Academy/Common/EditorRTF"

const Wrap = styled.div`
  height: 1200px;
  width: 140%;
  display: flex;
  flex-direction: column;
  .title {
   font-family: "SF Pro Display", sans-serif;
   font-size: 14px;
   font-weight: bold;
   color: #24292e;
  }
  .sub {
   font-size: 12px;
   color: #586069;
  }
  .ress {
   margin-top: 12px;
  }
  .help {
   margin-top: 17px
  }
`

const LineBreak = styled.div`
  height: 100px;
  width: 100%;
`

const Row = styled.div`
  height: 400px;
  width: 100%;
`

class CourseRTF extends React.Component {
 componentDidMount() {
  //
 }

  render() {
   const {
    rtfContent,
   } = this.props
   return(
    <Wrap>
     <Row>
      <p className="title">About Course</p>
      <p className="sub">Enter About the course to show a description about the course</p>
      <RTFEditorIO
        customMinHeight={200}
        customMarginBottom={56}
        customMaxHeight={349}
        contentType="aboutCourse"
        rtfContent={rtfContent && rtfContent.description}
      />
     </Row>
     <Row
      className="ress"
     >
      <p className="title">Lesson Breakdown</p>
      <p className="sub">Brief description about the lessons</p>
      <RTFEditorIO
        customMinHeight={200}
        customMarginBottom={56}
        customMaxHeight={349}
        contentType="lessonBreakdown"
        rtfContent={rtfContent && rtfContent.lesson_breakdown}
      />
     </Row>
    </Wrap>
   )
  }
}
export default CourseRTF
