import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"

const initState = {
  filterOptions: {},
  ...createDeltaReducer("agentRecomendationsResponse"),
  ...createDeltaReducer("assignAgentResponse"),
  ...createDeltaReducer("searchAgentResponse"),
  ...createDeltaReducer("lmpStatusResponse"),
}

export default function clientProfileReducer(state = initState, action) {
  switch (action.type) {
    case Actions.getAgentsForComparisonAction.REQUEST:
    case Actions.getAgentsForComparisonAction.SUCCESS:
    case Actions.getAgentsForComparisonAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getAgentsForComparisonAction, "agentRecomendationsResponse"),
      }
    }
    case Actions.assignAgentAction.REQUEST:
    case Actions.assignAgentAction.SUCCESS:
    case Actions.assignAgentAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.assignAgentAction, "assignAgentResponse"),
      }
    }

    case Actions.toggleLMPAction.REQUEST: {
      return {
        ...state,
        lmpStatusResponse: {
          data: action.data.payload.live_on_lmp,
        },
      }
    }

    case Actions.searchAgentAction.REQUEST:
    case Actions.searchAgentAction.SUCCESS:
    case Actions.searchAgentAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.searchAgentAction, "searchAgentResponse"),
      }
    }

    case Actions.getLMPStatusAction.REQUEST:
    case Actions.getLMPStatusAction.SUCCESS:
    case Actions.getLMPStatusAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getLMPStatusAction, "lmpStatusResponse"),
      }
    }

    case Actions.setFilterKeysAction.type: {
      return {
        ...state,
        filterOptions: action.data,
      }
    }
    
    default:
      return state
  }
}
