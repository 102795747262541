import React, { useState } from "react"
import styled from "styled-components"
import { Modal } from "antd"
import { get, isEmpty } from "lodash"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import agentSearchContainer from "container/BrokerDashboard/agentSearchContainer"
import Loader from "dumbComponents/common/UI/Loader"
import ConfirmationNote from "../ConfirmationNote"

const { confirm } = Modal

const Input = styled.input`
  width: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`

const AgentList = styled.div`
  position: absolute;
  width: 300px;
  top: 100px;
  left: 0px;
  right: 0px;
  background: #fff;
  border-radius: 10px;
  z-index: 1000;
  height: max-content;

  ${props => props.hasAgents && `
     border: 1px solid #ccc;
   `}

   ${props => props.hideDropdown && `
     display: none;
    `}
`

const AgentItem = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  &:hover {
    background: #eee;
    transition-duration: 0.3s;
    cursor: pointer;
  }
  > div {
    &:nth-child(1) {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
      background: #eee;
      > img {
        width: 100%;
      }
    }
    &:nth-child(2) {
      margin-left: 20px;
      p, h6 {
        padding: 0px;
        margin: 0px;
      }
      h6 {
        font-weight: 400;
        color: #999;
        font-size: 12px;
      }
      > input {
        color: #999;
        border: 10px;
        font-size: 12px;
        pointer-events: none;
        padding: 0px;
        background: transparent;
      }
    }
  }
`

const WrapAgent = styled.div`
  position: relative;
  width: 300px;
`

const AgentSearch = ({
  isAgentListDropdownVisible,
  toggleAgentList,
  rosterFetchAgentSearch,
  agents,
  data,
  handleHostChange,
}) => {
  const { isFetching: isFetchingName, data: dataAgent } = agents
  const agentsLists = get(dataAgent, "agents", [])

  const [searchText, setSearchText] = useState("")
  const [agentId, setAgentId] = useState("")

  const handleSearchType = (value) => {
    setSearchText(value)
    rosterFetchAgentSearch({ search: value })
  }

  const handleSelect = (agent) => {
    setSearchText(agent.email)
    toggleAgentList(false)

    confirm({
      title: `Do you want to make ${agent.firstname} the host of this room?`,
      icon: <ExclamationCircleOutlined />,
      content: <ConfirmationNote
        text={`Previous host was ${data.host}`}
        updateNote=""
        value=""
      />,
      onOk() {
        handleHostChange(agent.id)
      },
      onCancel() {
        setSearchText("")
        setAgentId("")
      },
    })
  }
  return (
    <WrapAgent>
      <h4>Agent Name Or Email</h4>
      <div>
        <Input
          style={{ width: 300 }}
          value={searchText}
          placeholder="Search agent"
          onChange={(e) => {
            toggleAgentList(true)
            handleSearchType(e.target.value)
          }}
        />
        <AgentList
          hideDropdown={!isAgentListDropdownVisible}
          hasAgents={agentsLists && agentsLists.length > 0}
        >
          {isFetchingName ? (
            <Loader width="25px" top={0} bottom={0} />
          ) : (
            <React.Fragment>
              {searchText && agentsLists && agentsLists.length > 0 && agentsLists.map(agent => (
                <AgentItem
                  key={agent.id}
                  onClick={() => {
                    handleSelect(agent)
                  }}
                >
                  <div>
                    <img src={agent.agent_image} alt="" />
                  </div>
                  <div>
                    <p>
                      {`${agent.firstname} ${agent.lastname}`}
                    </p>
                    <h6>
                      {agent.email}
                    </h6>
                  </div>
                </AgentItem>
              ))}
            </React.Fragment>
          )}
        </AgentList>
      </div>
    </WrapAgent>

  )
}

export default agentSearchContainer(AgentSearch)
