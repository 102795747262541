import React, {useState, useEffect} from "react"
import styled from "styled-components"
import colors from "lib/colors"
import {
    Button,
 } from "antd"
 import aiContainer from "container/AI"
import FormModal from "./FormModal";
const HeaderWrap = styled.div`

`
const StyledHeader = styled.h1`
  font-size: 36px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.black};
`

const Header = ({toggleTrainingFormModal}) => { 
  return (
    <HeaderWrap>
    <StyledHeader>Training Documents
      <Button type="primary" style={{
       marginLeft: "1190px",
       width: 150,
       height: 42,
       background: "#100063",
      color: "white", }} 
      onClick={() => {
        toggleTrainingFormModal(true)
      }}
      > Add Documents </Button>
      <FormModal />
    </StyledHeader>
  </HeaderWrap>
  )
}
  


export default aiContainer(Header)
