import React from "react"
import styled from "styled-components"

const ButtonEle = styled.button`
  padding: 8px 16px;
  border: 1px solid #CCC;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;

  ${props => props.success && `
    border: 1px solid #198647;
    color: #FFF;
    background: #27ae60;
  `}

  ${props => props.ghost && `
    border: 1px solid #198647;
    color: #888;
    background: transparent;
  `}

  ${props => props.rounded && `
    border-radius: 50%;
  `}
  ${props => props.blue && `
    color: #3B60E4;
    border-color: #3B60E4;
  `}
`

const Button = props => (
  <ButtonEle {...props} />
)

export default Button
