import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"
const initState = {
    instructorArray: [],
    hostsArray: [],
    accessArray: ["registered"],
    currentLessonType: [{ value: "video", label: "Video"}],
    currentEvent: [{ value: "in-person", label: "In-Person"}],
    currentEventFilter: [{ value: "upcoming", label: "Upcoming"}],
    currentCategorySelected: [{ value: "select-value", label: "Select", data:[]}],
    arranged: [],
    arrangedCourses: [],
    dateIndex: "",
    lessonNotes: "",
    notesNotes: "",
    resourceNotes: "",
    helpNotes: "",
    eventReminderPost: "",
    eventAnnouncementText: "",
    communityEventPostText: "",
    aboutLessonMarketing: "",
    courseReminderPost: "",
    courseAnnouncementText: "",
    feedbackPost: "",
    testOnly: false,
    isPrivate: false,
    currentTopicSelected: [{ value: "select-value", label: "Select", data:[]}],
    enableEventPushNotificationBool: true,
    hideOldTime: false,
    ...createDeltaReducer("coursesResponse"),
    ...createDeltaReducer("multipleInstructorsResponse"),
    ...createDeltaReducer("createInstructorResponse"),
    ...createDeltaReducer("createCourseResponse"),
    ...createDeltaReducer("courseDetailResponse"),
    ...createDeltaReducer("eventsResponse"),
    ...createDeltaReducer("createLessonResponse"),
    ...createDeltaReducer("updateCurrentCourse"),
    ...createDeltaReducer("uploadCourseHeaderImageResponse"),
    ...createDeltaReducer("instructorDetailsResponse"),
    ...createDeltaReducer("updateInstructorResponse"),
    ...createDeltaReducer("updateInstructorProfileImageResponse"),
    ...createDeltaReducer("deleteCourseResponse"),
    ...createDeltaReducer("deleteInstructorResponse"),
    ...createDeltaReducer("updateLessonResponse"),
    ...createDeltaReducer("deleteLessonResponse"),
    ...createDeltaReducer("createEventResponse"),
    ...createDeltaReducer("deleteEventResponse"),
    ...createDeltaReducer("updateEventResponse"),
    ...createDeltaReducer("getEventDetailsResponse"),
    ...createDeltaReducer("batchUpdateLessonsResponse"),
    ...createDeltaReducer("uploadEventHeaderImageResponse"),
    ...createDeltaReducer("getGlobalEventNotificationResponse"),
    ...createDeltaReducer("setGlobalEventNotificationResponse"),
    ...createDeltaReducer("getCategoriesResponse"),
    ...createDeltaReducer("getCategoryDetailResponse"),
    ...createDeltaReducer("updateCategoryDetailResponse"),
    ...createDeltaReducer("createCategoryResponse"),
    ...createDeltaReducer("getCourseListingForCategoryResponse"),
    ...createDeltaReducer("batchCourseUpdateResponse"),
}

export default function clientProfileReducer(state = initState, action) {
    switch (action.type) {
        case Actions.getCoursesAction.REQUEST:
        {
            return {
                 instructorArray: [],
                 lessonNotes: "",
                 notesNotes: "",
                 resourceNotes: "",
                 helpNotes: "",
                 aboutLessonMarketing: "",
                ...state,
                ...handleDeltaReducer(state, action, Actions.getCoursesAction, "coursesResponse"),
            }
        }
        case Actions.getCoursesAction.SUCCESS:
        case Actions.getCoursesAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(state, action, Actions.getCoursesAction, "coursesResponse"),
            }
        }

        case Actions.getMultipleInstructorsAction.REQUEST:
        case Actions.getMultipleInstructorsAction.SUCCESS:
        case Actions.getMultipleInstructorsAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(state, action, Actions.getMultipleInstructorsAction, "multipleInstructorsResponse"),
            }
        }

        case Actions.createInstructorAction.REQUEST:
        case Actions.createInstructorAction.SUCCESS:
        case Actions.createInstructorAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(state, action, Actions.createInstructorAction, "createInstructorResponse"),
            }
        }

        case Actions.createCourseAction.REQUEST:
        case Actions.createCourseAction.SUCCESS:
        case Actions.createCourseAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(state, action, Actions.createCourseAction, "createCourseResponse"),
            }
        }

        case Actions.getCourseDetailsAction.REQUEST:
        case Actions.getCourseDetailsAction.SUCCESS:
        case Actions.getCourseDetailsAction.FAILURE: {
            return {
                ...state,
                lessonNotes: "",
                notesNotes: "",
                resourceNotes: "",
                helpNotes: "",
                aboutLessonMarketing: "",
                ...handleDeltaReducer(state, action, Actions.getCourseDetailsAction, "courseDetailResponse"),
            }
        }

        //createDetlaReducer
        case Actions.getAllEventsAction.REQUEST:
        case Actions.getAllEventsAction.SUCCESS:
        case Actions.getAllEventsAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(state, action, Actions.getAllEventsAction, "eventsResponse"),
            }
        }

        case Actions.createLessonAction.REQUEST:
        case Actions.createLessonAction.FAILURE:{
            return {
                ...state,
                ...handleDeltaReducer(state, action, Actions.createLessonAction, "createLessonResponse"),
            }
        }
        case Actions.createLessonAction.SUCCESS: {
            return {
                ...state,
                lessonNotes: "",
                notesNotes: "",
                resourceNotes: "",
                helpNotes: "",
                aboutLessonMarketing: "",
                ...handleDeltaReducer(state, action, Actions.createLessonAction, "createLessonResponse"),
            }
        }

        case Actions.updateCourseAction.REQUEST:
        case Actions.updateCourseAction.SUCCESS:
        case Actions.updateCourseAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(state, action, Actions.updateCourseAction, "updateCurrentCourse"),
            }
        }

        case Actions.setCurrentCourseHeaderImageAction.REQUEST:
        case Actions.setCurrentCourseHeaderImageAction.SUCCESS:
        case Actions.setCurrentCourseHeaderImageAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.setCurrentCourseHeaderImageAction,
                 "uploadCourseHeaderImageResponse"
                ),
            }
        }

        case Actions.batchUpdateForCoursesAction.REQUEST:
        case Actions.batchUpdateForCoursesAction.SUCCESS:
        case Actions.batchUpdateForCoursesAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.batchUpdateForCoursesAction,
                 "batchCourseUpdateResponse"
                ),
            }
        }

        case Actions.setCurrentEventHeaderImageAction.REQUEST:
        case Actions.setCurrentEventHeaderImageAction.SUCCESS:
        case Actions.setCurrentEventHeaderImageAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.setCurrentEventHeaderImageAction,
                 "uploadEventHeaderImageResponse"
                ),
            }
        }

        case Actions.getInstructorDetailsAction.REQUEST:
        case Actions.getInstructorDetailsAction.SUCCESS:
        case Actions.getInstructorDetailsAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.getInstructorDetailsAction,
                 "instructorDetailsResponse"
                ),
            }
        }

        case Actions.updateInstructorAction.REQUEST:
        case Actions.updateInstructorAction.SUCCESS:
        case Actions.updateInstructorAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.updateInstructorAction,
                 "updateInstructorResponse"
                ),
            }
        }

        case Actions.setCurrentProfileImageAction.REQUEST:
        case Actions.setCurrentProfileImageAction.SUCCESS:
        case Actions.setCurrentProfileImageAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.setCurrentProfileImageAction,
                 "updateInstructorProfileImageResponse"
                ),
            }
        }

        case Actions.deleteInstructorAction.REQUEST:
        case Actions.deleteInstructorAction.SUCCESS:
        case Actions.deleteInstructorAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.deleteInstructorAction,
                 "deleteInstructorResponse"
                ),
            }
        }

        case Actions.updateLessonDetailsAction.REQUEST:
        case Actions.updateLessonDetailsAction.SUCCESS:
        case Actions.updateLessonDetailsAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.updateLessonDetailsAction,
                 "updateLessonResponse"
                ),
            }
        }

        case Actions.deleteLessonAction.REQUEST:
        case Actions.deleteLessonAction.SUCCESS:
        case Actions.deleteLessonAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.deleteLessonAction,
                 "deleteLessonResponse"
                ),
            }
        }

        //createEventAction

        case Actions.createEventAction.REQUEST:
        case Actions.createEventAction.SUCCESS:
        case Actions.createEventAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.createEventAction,
                 "createEventResponse"
                ),
            }
        }

        case Actions.updateEventAction.REQUEST:
        case Actions.updateEventAction.SUCCESS:
        case Actions.updateEventAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.updateEventAction,
                 "updateEventResponse"
                ),
            }
        }
        case Actions.deleteEventAction.REQUEST:
        case Actions.deleteEventAction.SUCCESS:
        case Actions.deleteEventAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.deleteEventAction,
                 "deleteEventResponse"
                ),
            }
        }

        case Actions.batchUpdateLessonsAction.REQUEST:
        case Actions.batchUpdateLessonsAction.SUCCESS:
        case Actions.batchUpdateLessonsAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.deleteEventAction,
                 "batchUpdateLessonsResponse"
                ),
            }
        }

        case Actions.getGlobalEventNotificationAction.REQUEST:
        case Actions.getGlobalEventNotificationAction.SUCCESS:
        case Actions.getGlobalEventNotificationAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.getGlobalEventNotificationAction,
                 "getGlobalEventNotificationResponse"
                ),
            }
        }

        case Actions.setGlobalEventNotificationAction.REQUEST:
        case Actions.setGlobalEventNotificationAction.SUCCESS:
        case Actions.setGlobalEventNotificationAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.setGlobalEventNotificationAction,
                 "setGlobalEventNotificationResponse"
                ),
            }
        }

        case Actions.getCategoryDetailAction.REQUEST:
        case Actions.getCategoryDetailAction.SUCCESS:
        case Actions.getCategoryDetailAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.getCategoryDetailAction,
                 "getCategoryDetailResponse"
                ),
            }
        }

        case Actions.createCategoryAction.REQUEST:
        case Actions.createCategoryAction.SUCCESS:
        case Actions.createCategoryAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.createCategoryAction,
                 "createCategoryResponse"
                ),
            }
        }

        case Actions.getCourseListingForCategoryAction.REQUEST:
        case Actions.getCourseListingForCategoryAction.SUCCESS:
        case Actions.getCourseListingForCategoryAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.getCourseListingForCategoryAction,
                 "getCourseListingForCategoryResponse"
                ),
            }
        }

        case Actions.updateCategoryDetailAction.REQUEST:
        case Actions.updateCategoryDetailAction.SUCCESS:
        case Actions.updateCategoryDetailAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.updateCategoryDetailAction,
                 "updateCategoryDetailResponse"
                ),
            }
        }

        case Actions.getCurrentEventDetailsAction.REQUEST: {
            return {
                ...state,
                hostsArray: [],
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.getCurrentEventDetailsAction,
                 "getEventDetailsResponse"
                ),
            }
        }

        case Actions.getCurrentEventDetailsAction.SUCCESS:
        case Actions.getCurrentEventDetailsAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.getCurrentEventDetailsAction,
                 "getEventDetailsResponse"
                ),
            }
        }

        case Actions.getCategoriesAction.REQUEST:
        case Actions.getCategoriesAction.SUCCESS:
        case Actions.getCategoriesAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(
                 state,
                 action,
                 Actions.getCategoriesAction,
                 "getCategoriesResponse"
                ),
            }
        }

        case Actions.addInstructorToCourseAction.type: {
         return {
             ...state,
             instructorArray: action.data,
         }
        }

        case Actions.addHostsToEventAction.type: {
         return {
             ...state,
             hostsArray: action.data,
         }
        }

        case Actions.setCurrentLessonTypeAction.type: {
         const {
          type,
         } = action.data
         return {
             ...state,
             currentLessonType: type,
         }
        }

        case Actions.getCurrentRTFValueAction.type: {
         const {
          data,
          type,
         } = action.data
         return {
             ...state,
             [type]: data,
         }
        }

        case Actions.currentCourseLessonArrangementAction.type: {
         const {
          lessons,
         } = action.data
         return {
             ...state,
             arranged: lessons,
         }
        }

        //dateIndex
        case Actions.dateIndexUpdateAction.type: {
         const {
          dateIndex,
          hideOldTime,
         } = action.data
         return {
             ...state,
             dateIndex,
             hideOldTime,
         }
        }

        //currentEventTypeAction
        case Actions.currentEventTypeAction.type: {
         const {
          currentEvent,
         } = action.data
         return {
             ...state,
             currentEvent,
         }
        }

        case Actions.saveSettingsValuesAction.type: {
         const {
          data,
          type,
         } = action.data
         return {
             ...state,
             [type]: data,
         }
        }

        case Actions.currentCategoryCourseArrangementAction.type: {
         const {
          courses,
         } = action.data
         return {
             ...state,
             arrangedCourses: courses,
         }
        }


        case Actions.accessToCourseValueAction.type: {
         return {
             ...state,
             accessArray: action.data,
         }
        }

        case Actions.previousRTFStateAction.type: {
         const {
          lastState,
         } = action.data
         return {
             ...state,
             lastState,
         }
        }

        case Actions.currentImageURLSetAction.type: {
         const {
          currentImageURL,
         } = action.data
         return {
             ...state,
             currentImageURL,
         }
        }

        case Actions.eventFilterTypeAction.type: {
         const {
          currentEventFilter,
         } = action.data

         return {
          ...state,
          currentEventFilter,
         }
        }

        case Actions.courseCategorySelectionAction.type: {
         const {
          currentCategorySelected,
         } = action.data

         return {
          ...state,
          currentCategorySelected,
         }
        }

        case Actions.courseTopicSelectionAction.type: {
            const {
             currentTopicSelected,
            } = action.data
   
            return {
             ...state,
             currentTopicSelected,
            }
           }

        case Actions.setCurrentAsTestAction.type: {
         const {
          testOnly,
         } = action.data

         return {
          ...state,
          testOnly,
         }
        }

        case Actions.setIsPrivateEventAction.type: {
          const {
            isPrivate,
          } = action.data

          return {
            ...state,
            isPrivate,
          }
        }

        case Actions.setPushNotificationEnableAction.type: {
          const {
            enableEventPushNotificationBool,
          } = action.data

          return {
            ...state,
            enableEventPushNotificationBool,
          }
        }


        case Actions.clearFormsAction.type: {
          return {
           ...state,
           lessonNotes: "",
           notesNotes: "",
           resourceNotes: "",
           helpNotes: "",
           aboutLessonMarketing: "",
           registrationInfo: "",
           venueLinkInfo: "",
           aboutEvent: "",
           instructorBio: "",
           hostsArray: [],
           instructorArray: [],
           dateIndex: "",
           accessArray: ["registered"],
           addDayTimeForEventsForm: "",
           eventAnnouncementText: "",
           eventReminderPost: "",
           courseAnnouncementText:"",
           communityEventPostText: "",
           courseReminderPost:"",
           feedbackPost: "",
           testOnly: false,
           isPrivate: false,
           enableEventPushNotificationBool: true,
           hideOldTime: false,
          }
        }

        default: {
            return state
        }
    }
}
