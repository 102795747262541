import React, { useEffect } from "react"
import styled from "styled-components"
import RARTechContainer from "container/TechAssets"
import {
  getAgentId,
} from "dumbComponents/Offers/commonUtils"
import SearchControls from "../Common/SearchControls"
import ResultsTable from "./DealsComponents/Table"
import InvitedDrawer from "./DealsComponents/EditDrawer"

const Wrap = styled.div``

const Referrals = (props) => {
  const {
    getAllOffersActionResponse,
    getOffers,
  } = props

  useEffect(() => {
    getOffers({
      skip: 0,
      limit: 300,
      query: `&agentId=${getAgentId() || ""}`,
    })
  }, [])

  const {
    isFetching,
    data: offers,
  } = getAllOffersActionResponse || {}

  return (
    <Wrap>
      <SearchControls showStatusFilter />
      <ResultsTable
        offers={offers}
        fetchingList={isFetching}
      />
      <InvitedDrawer />
      {/* <AgentInfoDrawer /> */}
    </Wrap>
  )
}

export default RARTechContainer(Referrals)
