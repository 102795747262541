import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  flex: 1;
  > p {
    padding: 0px;
    margin: 0px;
    cursor: pointer;
    > span.star {
      margin-left: 10px;
      i.fa.fa-star {
        color:  #FFD700;
      }
    }
  }
`

const AgentNameWithStar = ({
  firstname = "",
  lastname = "",
  textStyle,
  isStared,
  onClick,
  onClickAgentName,
}) => (
  <Wrap>
    <p style={textStyle}>
      <span onClick={onClickAgentName}>{firstname || ""} {lastname || ""}</span>
      <span onClick={onClick} className="star">
        <i className={isStared ? "fa fa-star" : "fa fa-star-o"}></i>
      </span>
    </p>
  </Wrap>
)

export default AgentNameWithStar
