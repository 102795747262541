import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"
const initState = {
  query: {
    agent_id: null,
    team_id: null,
    start_date: null,
    end_date: null,
    state: null,
    city: null,
    forFilter: {
      status: "",
    },
  },
  currentTopics: {
    currentAgentKey: null,
    topics: [],
    data: null,
  },
  currentSession: [],
  currentSessionId: null,
  currentAgentIdForPubnub: null,
  newAgentInQueueForPubNub: null,
  isAIEnabledForThisThread: false,
  actionIdFromPubnub: null,
  chatObject: null,
  trainingFormModalResponse: false,
  ...createDeltaReducer("toggleAIinChatResponse"),
  ...createDeltaReducer("submitQueryToChatResponse"),
  ...createDeltaReducer("markAsResolvedResponse"),
  ...createDeltaReducer("fetchAIChatSessionsResponse"),
  ...createDeltaReducer("fetchAIAnalyticsResponse"),
  ...createDeltaReducer("fetchSessionHistoryResponse"),
  ...createDeltaReducer("fetchAgentResponse"),
  ...createDeltaReducer("fetchTeamsResponse"),
  ...createDeltaReducer("fetchAgentChatSessionResponse"),
  ...createDeltaReducer("fetchTrainedDocumentsResponse"),
  ...createDeltaReducer("deleteTrainedDocumentsResponse"),
  ...createDeltaReducer("uploadLinkToFileResponse"),
}

export default function getAiDashboardReducer(state = initState, action) {
  switch (action.type) {
    case Actions.submitQueryToChatAction.REQUEST:
    case Actions.submitQueryToChatAction.SUCCESS:
    case Actions.submitQueryToChatAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.submitQueryToChatAction,
          "submitQueryToChatResponse"
        ),
      }
    }

    case Actions.toggleAIinChatAction.REQUEST:
    case Actions.toggleAIinChatAction.SUCCESS:
    case Actions.toggleAIinChatAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.toggleAIinChatAction,
          "toggleAIinChatResponse"
        ),
      }
    }

    case Actions.markAsResolvedAction.REQUEST:
    case Actions.markAsResolvedAction.SUCCESS:
    case Actions.markAsResolvedAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.markAsResolvedAction,
          "markAsResolvedResponse"
        ),
      }
    }

    case Actions.fetchAIChatSessionsAction.REQUEST:
    case Actions.fetchAIChatSessionsAction.SUCCESS:
    case Actions.fetchAIChatSessionsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.fetchAIChatSessionsAction,
          "fetchAIChatSessionsResponse"
        ),
      }
    }

    case Actions.fetchAIAnalyticsAction.REQUEST:
    case Actions.fetchAIAnalyticsAction.SUCCESS:
    case Actions.fetchAIAnalyticsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.fetchAIAnalyticsAction,
          "fetchAIAnalyticsResponse"
        ),
      }
    }

    case Actions.fetchSessionHistoryAction.REQUEST:
    case Actions.fetchSessionHistoryAction.SUCCESS:
    case Actions.fetchSessionHistoryAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.fetchSessionHistoryAction,
          "fetchSessionHistoryResponse"
        ),
      }
    }

    //fetchAgentAction
    case Actions.fetchAgentAction.REQUEST:
    case Actions.fetchAgentAction.SUCCESS:
    case Actions.fetchAgentAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.fetchAgentAction,
          "fetchAgentResponse"
        ),
      }
    }

    // /fetchTeamsAction
    case Actions.fetchTeamsAction.REQUEST:
    case Actions.fetchTeamsAction.SUCCESS:
    case Actions.fetchTeamsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.fetchTeamsAction,
          "fetchTeamsResponse"
        ),
      }
    }

    case Actions.fetchAgentChatSessionAction.REQUEST:
    case Actions.fetchAgentChatSessionAction.SUCCESS:
    case Actions.fetchAgentChatSessionAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.fetchAgentChatSessionAction,
          "fetchAgentChatSessionResponse"
        ),
      }
    }

    case Actions.constructQueryAction.type: {
      const {
        constructed,
      } = action.data || {}

      return {
        ...state,
        query: { ...constructed },
      }
    }

    case Actions.setCurrentSessionsArrayAction.type: {
      const {
        currentTopics,
      } = action.data || {}

      return {
        ...state,
        currentTopics: {
          ...currentTopics,
        },
      }
    }

    case Actions.setCurrentChatForDetailsViewAction.type: {
      const {
        chats,
      } = action.data

      return {
        ...state,
        currentSession: [...chats],
      }
    }

    case Actions.setCurrentSessionIdAction.type: {
      return {
        ...state,
        currentSessionId: action.data,
      }
    }

    case Actions.setCurrentAgentSelectedForPubnubAction.type: {
      return {
        ...state,
        currentAgentIdForPubnub: action.data,
      }
    }

    case Actions.handlePubNubEventAction.type: {
      const {
        actionId,
        chatObject,
      } = action.data || {}

      return {
        ...state,
        actionIdFromPubnub: actionId,
        chatObject,
      }
    }

    case Actions.handleNewAgentPubNubAction.type: {
      const {
        newAgentId,
      } = action.data

      return {
        ...state,
        newAgentInQueueForPubNub: newAgentId,
      }
    }

    case Actions.fetchTrainedDocumentsAction.REQUEST:
    case Actions.fetchTrainedDocumentsAction.SUCCESS:
    case Actions.fetchTrainedDocumentsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.fetchTrainedDocumentsAction,
          "fetchTrainedDocumentsResponse"
        ),
      }
    }

    case Actions.uploadLinkToFileAction.REQUEST:
      case Actions.uploadLinkToFileAction.SUCCESS:
      case Actions.uploadLinkToFileAction.FAILURE: {
        return {
          ...state,
          ...handleDeltaReducer(
            state,
            action,
            Actions.uploadLinkToFileAction,
            "uploadLinkToFileResponse"
          ),
        }
      }

      case Actions.deleteTrainedDocumentsAction.REQUEST:
        case Actions.deleteTrainedDocumentsAction.SUCCESS:
        case Actions.deleteTrainedDocumentsAction.FAILURE: {
          return {
            ...state,
            ...handleDeltaReducer(
              state,
              action,
              Actions.deleteTrainedDocumentsAction,
              "deleteTrainedDocumentsResponse"
            ),
          }
        }

      case Actions.toggleTrainingFormModalAction.type: {
        return {
          ...state,
          trainingFormModalResponse: action.data,
        }
      }

    default: {
      return state
    }
  }
}
