import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/ProductInterest"
import { reduxForm, Field } from "redux-form"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"


const DescriptionForm = styled.form`
  padding: 53px 53px 0px;
`

const InputFields = styled(Field)`
  width: 100%;
  height: 39px;
  border-radius: 3px;
  border: solid 1px #d1d5da;
  background-color: #fafbfc;
  margin-bottom: 37px;
  padding-left: 9px;
`

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 39px;
  border-radius: 3px;
  border: solid 1px #d1d5da;
  background-color: #fafbfc;
  margin-bottom: 37px;
  padding-left: 9px;
`

class AdditionalDetailsForm extends Component {
  componentDidMount(){
    // apiCalls

    const {
      currentEditableProduct,
      initialize,
    } = this.props

    if (currentEditableProduct){
      initialize({
       ctaButtonText: currentEditableProduct.ctaButtonText,
       noButtonText: currentEditableProduct.noButtonText,
       webLink: currentEditableProduct.webLink,
       androidLink: currentEditableProduct.androidLink,
       iOSLink:  currentEditableProduct.iOSLink,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      currentEditableProduct,
      initialize,
      isNewFeatureOrChild,
      isChildView,
      isEditingExisting,
    } = this.props

    if (this.props.isNewFeatureOrChild !== prevProps.isNewFeatureOrChild && this.props.isNewFeatureOrChild){
      initialize({
        ctaButtonText: "",
        noButtonText: currentEditableProduct.noButtonText,
        webLink: "",
        androidLink: "",
        iOSLink:  "",
      })
    }

    if (!isNewFeatureOrChild && isChildView && Object.keys(currentEditableProduct).length > 0 && isEditingExisting !== prevProps.isEditingExisting ){
      initialize({
        ctaButtonText: currentEditableProduct.ctaButtonText,
        noButtonText: currentEditableProduct.noButtonText,
        webLink: currentEditableProduct.webLink,
        androidLink: currentEditableProduct.androidLink,
        iOSLink:  currentEditableProduct.iOSLink,
      })
    }

    if(!isNewFeatureOrChild && isChildView && Object.keys(currentEditableProduct).length > 0 && currentEditableProduct.title !== prevProps.currentEditableProduct.title) {
      initialize({
        ctaButtonText: currentEditableProduct.ctaButtonText,
        noButtonText: currentEditableProduct.noButtonText,
        webLink: currentEditableProduct.webLink,
        androidLink: currentEditableProduct.androidLink,
        iOSLink:  currentEditableProduct.iOSLink,
      })
    }
  }

  render(){
    return (
      <DescriptionForm id='description-form'>
        <div>
          <label htmlFor="CTA Button Text">
           CTA Button Text
           <span className="required">*</span>
          </label>
          <InputFields name="ctaButtonText" component="input" type="text" />
        </div>
        <div>
          <label htmlFor="No Button">
           No Button
           <span className="required">*</span>
          </label>
          <InputFields name="noButtonText" component="input" type="text" />
        </div>
        <div>
          <label htmlFor="Web Link">
           Web Link
           <span className="required">*</span>
          </label>
          <InputFields name="webLink" component="input" type="text" />
        </div>
        <div>
          <label htmlFor="Android">
           Android Link
           <span className="required">*</span>
          </label>
          <InputFields name="androidLink" component="input" type="text" />
        </div>
        <div>
          <label htmlFor="iOS">
           iOS Link
           <span className="required">*</span>
          </label>
          <InputFields name="iOSLink" component="input" type="text" />
        </div>
      </DescriptionForm>
    )
  }
}


AdditionalDetailsForm = reduxForm({
  form: 'AdditionalDetailsForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(AdditionalDetailsForm)


export default Container(AdditionalDetailsForm)
