import React, { Component } from "react"
import styled, { css } from "styled-components"
import colors from "lib/colors"
import Container from "container/Agent"
import CheckMark from "icons/CheckIcon"
import { get } from "lodash";
import { console } from "window-or-global"

const BenefitWrap = styled.ul`
  list-style: none;
  margin-top: 0;
  font-family: "SF Pro Display", sans-serif;
  padding-left: 18px;
  padding-top: 10px;
  max-height: 250px;
  overflow: scroll;
  label{
   font-size: 16px;
   font-weight: 300;
   font-stretch: normal;
   font-style: italic;
   line-height: 1.63;
   width: 80%;
   display: inline-block;
   padding: 2px;
  }
`

const NA = styled.p`
  font-style: italic;
  margin-left: 13px;
  font-size: 15px;
  color: #7f7d7d;
`

const FeaturePricingModel = styled.div`
  display: flex;
  justify-content: space-between;
  span:nth-child(2) {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    color: black;
  }
`

class Benefits extends React.Component {
  state = {
    updatedAssistFeatures: [],
    initFeatureArr: [],
    alreadyAddedFeatures: []
  }
  componentDidMount() {
    const {
      alreadyAddedFeatures,
      initializeAddedBenefits
    } = this.props

    initializeAddedBenefits({ addedFeatures: alreadyAddedFeatures });
  }

  // getFeaturePrice = (key) => {
  //   const { installmentResponse, currentSelectedPlan, currentSelectedInstallmentPlan } = this.props;
  //   const {
  //     data: installmentData,
  //   } = installmentResponse || {}
  //   const benefitPlans = get(installmentData, 'benefit_plans', null);
  //   const planId = currentSelectedInstallmentPlan && currentSelectedInstallmentPlan.plan_id && benefitPlans[currentSelectedInstallmentPlan.plan_id] ? currentSelectedInstallmentPlan.plan_id : currentSelectedPlan.plan_id;
  //   // console.log('==currentSelectedInstallmentPlan===', currentSelectedInstallmentPlan)
  //   // console.log('==installmentData===', installmentData, '==benefitPlans---', benefitPlans, '000---planId---', planId)
  //   // const { data: { benefits: { premium_features: premiumFeatures } } } = installmentResponse
  //   const price = benefitPlans && benefitPlans[planId] ? get(benefitPlans[planId].find(pf => pf.value === key), 'unit_price', 0) : 0;
  //   return {
  //     priceString: price ? `$${price}` : '',
  //     priceValue: price
  //   }
  // }

  isOriginalSelected = (assistFeature) => {
    const { alreadyAddedFeatures } = this.props;
    return !!alreadyAddedFeatures.find(feat => feat.benefit_key === assistFeature.key)
  }

  getReadableName = (name) => {
    const nameList = name.split("_").join(" ")
    const finalName = nameList.replace(nameList.charAt(0), nameList.charAt(0).toUpperCase())
    return finalName
  }

  handleInitChange = (refPremium_features, premBenefits, currentValue, id, key, e) => {
    const {
      setModifiedBenefit,
      modifiedBenefitArray,
    } = this.props
    const assistFeatures = refPremium_features.map(feat => ({
      key: feat.value,
      ...feat
    }))
    this.handleUpdateAssistFeatures(assistFeatures, key, e)
    if (modifiedBenefitArray.length > 0) {
      if (modifiedBenefitArray.includes(id)) {
        let newArr = modifiedBenefitArray.filter(item => {
          return id !== item
        })
        setModifiedBenefit({
          modifiedBenefitArray: newArr,
        })
      } else {
        setModifiedBenefit({
          modifiedBenefitArray: [...modifiedBenefitArray, id]
        })
      }
    } else {
      setModifiedBenefit({
        modifiedBenefitArray: [id]
      })
    }

  }

  handleUpdateAssistFeatures = (assistFeatures, key, e) => {
    const {
      plans,
      setBenefitsToBeAdded,
      alreadyAddedFeatures,
      getFeaturePrice
    } = this.props
    const premiumFeatures = get(plans, 'data.benefits.premium_features');
    let updatedAssistFeatures = [];
    if (!this.state.updatedAssistFeatures.length) {
      assistFeatures.forEach(item => {
        updatedAssistFeatures.push({
          ...item,
          value: item.key === key ? (e.target.checked ? 1 : 0) : (this.isOriginalSelected(item) ? 1 : 0)
        })
      });
      this.setState({
        updatedAssistFeatures: updatedAssistFeatures
      });
    } else {
      updatedAssistFeatures = this.state.updatedAssistFeatures;
      updatedAssistFeatures.forEach(item => {
        if (item.key === key) {
          item.value = e.target.checked ? 1 : 0
        }
      })
      this.setState({
        updatedAssistFeatures: updatedAssistFeatures
      })
    }
    const updatedBenefits = updatedAssistFeatures.filter(features => features.value && premiumFeatures.find(premFeature => premFeature.value === features.key)).map(features => ({
      benefit_key: features.key,
      benefit_id: premiumFeatures.find(premFeature => premFeature.value === features.key).benefit_id,
      price: getFeaturePrice(features.key).priceValue,
      alreadyAdded: alreadyAddedFeatures.find(feat => feat.benefit_key === features.key) ? alreadyAddedFeatures.find(feat => feat.benefit_key === features.key).alreadyAdded : false
    }))
    setBenefitsToBeAdded({
      addedBenefits: [...updatedBenefits],
      alreadyAddedFeatures
    })
  }

  handleChange = (assistFeatures, key, currentValue, modifiedBenefit, e) => {
    const {
      plans: {
        data,
      },
      setModifiedBenefit,
      modifiedBenefitArray,
    } = this.props

    const {
      benefits: {
        premium_features,
      }
    } = data

    assistFeatures.forEach((item, iter) => {
      if (item.key === key) {
        assistFeatures[iter].value = currentValue === 1 ? 0 : 1
      }
    })
    this.handleUpdateAssistFeatures(assistFeatures, key, e)
    premium_features.forEach((item, iter) => {
      if (item.value === key) {
        //modifiedBenefitArray.push(item.benefit_id)
        if (modifiedBenefitArray.length > 0) {
          if (modifiedBenefitArray.includes(item.benefit_id)) {
            let newArr = modifiedBenefitArray.filter(key => {
              return item.benefit_id !== key
            })
            setModifiedBenefit({
              modifiedBenefitArray: newArr,
            })
          } else {
            setModifiedBenefit({
              modifiedBenefitArray: [...modifiedBenefitArray, item.benefit_id]
            })
          }
        } else {
          setModifiedBenefit({
            modifiedBenefitArray: [item.benefit_id]
          })
        }
      }
    })
  }

  isDuplicateBenefit = (assistBenefit) => {
    const {
      planBenefits,
      subData,
      installmentResponse,
      plans,
      currentMode
    } = this.props
    const premiumFeatures = get(plans, 'data.benefits.premium_features', []);
    const benefitDetails = premiumFeatures.find(premFeature => premFeature.value === (assistBenefit.key || assistBenefit.value));
    if (benefitDetails && planBenefits.find(plan => plan.benefit_id === benefitDetails.benefit_id)) return true
    if(['blu', 'broker'].includes(currentMode)) return true
    return false;
  }

  render() {
    const {
      planBenefits,
      subData,
      installmentResponse,
      plans,
      getFeaturePrice
    } = this.props

    const {
      data: plansData,
    } = plans

    let refPremium_features = []
    if (plansData) {
      const {
        benefits: {
          premium_features,
        }
      } = plansData

      refPremium_features = premium_features
    }

    const {
      updatedAssistFeatures,
    } = this.state
    let assistFeatures = []
    if (subData) {
      assistFeatures = subData.assist_features
    }
    const showPremiumFeaturesFirstTimeUsers =
      !assistFeatures
      && refPremium_features
      && refPremium_features.length > 0

    return (
      <React.Fragment>
        {planBenefits.length <= 0 ? (
          <NA>Benefits not present</NA>
        ) : (
          <BenefitWrap>
            {planBenefits && planBenefits.map(benefit => {
              return (
                <React.Fragment>
                  <li>
                    <input checked disabled type="checkbox" name={"benefit"} />
                    <label>
                      {`${benefit.text ? benefit.text : ""} ${benefit.benefit_text ? benefit.benefit_text : ""}`}
                    </label>
                  </li>
                </React.Fragment>
              )
            })}
            {showPremiumFeaturesFirstTimeUsers ? refPremium_features.map((assistBenefit, iter) => {
              return (
                <React.Fragment>
                  {!this.isDuplicateBenefit(assistBenefit) && (
                  <li>
                    <input
                      onChange={(e) => this.handleInitChange(refPremium_features, assistBenefit, assistBenefit.value, assistBenefit.benefit_id, assistBenefit.value, e)}
                      type="checkbox"
                      name={"assistBenefit"}
                    />
                    <label>
                      <FeaturePricingModel><span>{assistBenefit.benefit_text}</span> <span>{getFeaturePrice(assistBenefit.value).priceString}</span></FeaturePricingModel>
                    </label>
                  </li>
                  )}
                </React.Fragment>
              )
            }) : assistFeatures.map((assistBenefit, iter) => {
              return (
                <React.Fragment>
                  {!this.isDuplicateBenefit(assistBenefit) && (
                    <li>
                      <input
                        checked={
                          updatedAssistFeatures && updatedAssistFeatures.length > 0
                            ? Boolean(updatedAssistFeatures[iter].value) : Boolean(this.isOriginalSelected(assistFeatures[iter]))}
                        onChange={(e) => this.handleChange(assistFeatures, assistBenefit.key, assistBenefit.value, assistBenefit, e)}
                        type="checkbox"
                        name={"assistBenefit"}
                      />
                      <label>
                        <FeaturePricingModel><span>{`${this.getReadableName(assistBenefit.key)}`}</span> <span>{getFeaturePrice(assistBenefit.key).priceString}</span></FeaturePricingModel>
                      </label>
                    </li>
                  )}
                </React.Fragment>
              )
            })}
          </BenefitWrap>
        )}
        {/*assistFeatures && assistFeatures.length > 0 && assistFeatures.map((assistBenefit, iter) => {
          return (
           <React.Fragment>
             <li>
               <input
                checked={
                updatedAssistFeatures && updatedAssistFeatures.length > 0
                 ? Boolean(updatedAssistFeatures[iter].value) : Boolean(assistFeatures[iter].value)}
                onChange={() => this.handleChange(assistFeatures, assistBenefit.key, assistBenefit.value, assistBenefit)}
                type="checkbox"
                name={"assistBenefit"}
               />
               <label>
                   {`${this.getReadableName(assistBenefit.key)}`}
               </label>
             </li>
           </React.Fragment>
          )
         })}
       </BenefitWrap>
      )*/}

      </React.Fragment>

    )
  }
}

export default Container(Benefits)
