import React from "react"
import styled from "styled-components"
// import colors from "@colors"

const HeaderWrap = styled.div`
  border-bottom: 1px solid #D3DAE8;
  padding-bottom: 16px;
`

const H1 = styled.h1`
  font-family: DM Sans;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
`

const SubTextWrap = styled.div``

const SubText = styled.p`
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  width: 378px;
  margin-top: 30px;
`

const Header = () => (
  <>
    <HeaderWrap>
      <H1>Listing Submissions</H1>
    </HeaderWrap>
    <SubTextWrap>
      {/* <SubText>
        Add new files, see submitted files and active listings
      </SubText> */}
    </SubTextWrap>
  </>
)

export default Header
