import React from "react"
import styled from "styled-components"
import { getSignedURLAPI } from "container/BrokerDashboard/api"
import { Button } from "antd"


const Wrap = styled.div`
  
`

const Label = styled.label`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #d4d4d4;
  margin: 5px 0px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 10px;
  color: #A3A3A3;
  letter-spacing: 2px;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.5;
  > div > div > div {
    border: 1px solid #ED1616 !important;
  }
`

const ErrorLabel = styled.span`
  color: #ff4b4b;
`

class RenderFileUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isUploading: false,
    }
  }

  handleOnChange = async (e) => {
    const {
      input: { onChange },
      isUpload,
    } = this.props
    const file = e.target.files[0]
    if (isUpload) {
      const fileURL = await this.uploadfileGetURL(file)
      onChange(fileURL)
    } else {
      onChange(file)
    }
  }


  uploadfileGetURL = async (file) => {
    try {
      this.setState({
        isUploading: true,
      })
      const { bucket } = this.props
      //const signedRes = await getSignedURLAPI(bucket,"asdasd10023349-1651834442939")
      const signedRes = await fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=${bucket}&filename=${file.name}`)
      const myHeaders = new Headers({ "Content-Type": file.type })
      const signedResJson = await signedRes.json()
      const uploadRes = await fetch(signedResJson.url, {
        method: "PUT",
        headers: myHeaders,
        body: file
      })
      this.setState({
        isUploading: false,
      })
      return signedResJson.fileUrlAfterUpload
    } catch (error) {
      this.setState({
        isUploading: false,
      })
    }
  }

  render() {
    const { isUploading } = this.state
    const { input: { value, onChange }, isUpload, label, meta } = this.props
    const {
      input,
    } = this.props
    return (
      <Wrap>
        <div>
          {label && (
            <Label>{label}</Label>
          )}
          {value && isUpload ? (
            <div>
               <p>{value}</p>
               <div>
                 <Button onClick={() => { onChange("") }} type="danger" size="small">
                   Remove
                 </Button>
               </div>
            </div>
           
          ) : (
            <input
              type="file"
              onChange={this.handleOnChange}
            />
          )}
          
        </div>
        {isUploading && (
          <h6>Uploading...</h6>
        )}
        {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
      </Wrap>
    )
  }
}

export default RenderFileUpload
