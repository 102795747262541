export const createActionCreator = action => arg => ({
  type: action,
  data: arg,
})

export const createBaseActionCreator = (base, action) => {
  const type = `ACTION/${base}/${action}`
  return {
    type,
    action: data => ({
      type,
      data,
    }),
  }
}

export const createSignalAction = function createSignalAction(reducerName, action) {
  return ["REQUEST", "SUCCESS", "FAILURE", "PENDING", "CLEAR"].reduce((prev, curr) => {
    const newPrev = prev
    newPrev[curr] = `SIGNAL/${reducerName}/${action}/${curr}`
    newPrev[curr.toLowerCase()] = createActionCreator(prev[curr])
    return newPrev
  }, {})
}

export const createDeltaReducer = (dataName, extra) => ({
  [dataName]: {
    isFetching: false,
    isError: false,
    error: null,
    data: null,
    ...extra,
  },
})

export const createSimpleCreator = (base, action) => ({
  call: arg => ({
    type: `${base}/${action}`,
    data: arg,
  }),
  type: `${base}/${action}`,
})

export const handleDeltaReducer = (state, action, calledAction, key) => {
  const { CLEAR, FAILURE, PENDING, REQUEST, SUCCESS } = calledAction
  const { type, data } = action
  const { isFetchingMore } = data || {}
  const subState = state[key]
  switch (type) {
    case REQUEST: {
      return {
        ...state,
        [key]: {
          ...subState,
          isFetching: !isFetchingMore,
          isFetchingMore,
        },
      }
    }
    case SUCCESS: {
      return {
        ...state,
        [key]: {
          ...subState,
          isFetching: isFetchingMore,
          isFetchingMore: false,
          isError: false,
          data,
        },
      }
    }
    case FAILURE: {
      return {
        ...state,
        [key]: {
          ...subState,
          isFetching: false,
          isFetchingMore: false,
          isError: true,
          error: data,
          data:{},
        },
      }
    }
    case CLEAR: {
      return {
        ...state,
        [key]: {
          ...createDeltaReducer(key),
        },
      }
    }
    default:
      return state
  }
}
