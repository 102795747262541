import React from "react"
import {
  Select,
} from "dumbComponents/common/UI/antd"
import AI from "container/AI/index"
import {
  Wrap,
  HeaderSubTitle,
} from "../commonStyles"
import {
  ListAgents,
  AgentAndTopics,
  Chat,
} from "./Elements"
import {
  AIDashboardWrap,
  FilterAndHeaderWrap,
  ActionsWrap,
  RefreshCTA,
  AgentListingAndChatWrap,
} from "./chatSectionStyles"

const OPTIONS = [{
  value: "all",
  label: "All",
}, {
  label: "Action Required",
  value: "action_required",
}, {
  label: "Positive",
  value: "reported_good",
}, {
  label: "Resolved",
  value: "resolved",
}]

const ChatSection = ({
  constructQuery,
  query,
  currentTopics,
}) => {
  const {
    topics,
  } = currentTopics || {}

  return (
    <Wrap margin="40px 0px 0px 0px">
      <AIDashboardWrap>
        <FilterAndHeaderWrap>
          <HeaderSubTitle>
            <h2>User feedback on AI responses</h2>
          </HeaderSubTitle>
          <ActionsWrap>
            <RefreshCTA
              onClick={() => {
                const constructed = {
                  ...query,
                }
                constructQuery({
                  constructed,
                })
              }}
            >
              <img src="https://s3.amazonaws.com/cdn.agentdesks.com/images/SOUL_AI/RefereshIcon.svg" alt="refresh" />
              <p>Refresh</p>
            </RefreshCTA>
            <Select
              placeholder="Status"
              onChange={(val) => {
                const constructed = {
                  ...query,
                  forFilter: {
                    status: val,
                  },
                }
                constructQuery({
                  constructed,
                })
              }}
              // value={primarySalesRep}
              style={{
                width: 200,
              }}
              options={[...OPTIONS]}
            />
          </ActionsWrap>
        </FilterAndHeaderWrap>
      </AIDashboardWrap>
      <AgentListingAndChatWrap>
        {topics && topics.length > 0 ? (
          <>
            <ListAgents />
            <AgentAndTopics />
            <Chat />
          </>
        ) : (
          <p>No Results</p>
        )}
      </AgentListingAndChatWrap>
    </Wrap>
  )
}

export default AI(ChatSection)

