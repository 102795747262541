import React from "react"
import { Route, Switch } from "react-router-dom"
import styled from "styled-components"
import Listings from "dumbComponents/Listings/pages/Listings"
import ListingAgentInfo from "dumbComponents/Listings/pages/CoverSheet/ListingAgentInfo"
import SellerInfo from "dumbComponents/Listings/pages/CoverSheet/SellerInfo"
import ListingDetails from "dumbComponents/Listings/pages/CoverSheet/ListingDetails"
import PropertyInformation from "dumbComponents/Listings/pages/CoverSheet/PropertyInformation"
import AskTCInformation from "dumbComponents/Listings/pages/CoverSheet/AskTCInformation"
import SignAndPostInstallation from "dumbComponents/Listings/pages/CoverSheet/SignAndPostInstallation"
import EscrowInformation from "dumbComponents/Listings/pages/CoverSheet/EscrowInformation"
import Vendors from "dumbComponents/Listings/pages/CoverSheet/Vendors"
import Header from "dumbComponents/Listings/components/Header"

const Wrap = styled.div`
  padding: 42px 50px;
`

const ProductAndServices = () => (
  <React.Fragment>
    <Wrap>
      <Header />
      <Switch>
        {/* <Route path="/broker/tc/add-listings" exact component={Listings} /> */}
        <Route path="/broker/tc/add-listings/coversheet/listing-agent-info" exact component={ListingAgentInfo} />
        <Route path="/broker/tc/add-listings/coversheet/seller-info" exact component={SellerInfo} />
        <Route path="/broker/tc/add-listings/coversheet/listing-details" exact component={ListingDetails} />
        <Route path="/broker/tc/add-listings/coversheet/property-info" exact component={PropertyInformation} />
        <Route path="/broker/tc/add-listings/coversheet/tc-info" exact component={AskTCInformation} />
        <Route path="/broker/tc/add-listings/coversheet/sign-and-post-installation" exact component={SignAndPostInstallation} />
        <Route path="/broker/tc/add-listings/coversheet/escrow-info" exact component={EscrowInformation} />
        <Route path="/broker/tc/add-listings/coversheet/vendors" exact component={Vendors} />
      </Switch>
    </Wrap>
  </React.Fragment>
)

export default ProductAndServices
