import styled from "styled-components"
import Input from "react-validation/build/input";

const NewInput = styled(Input)`
  outline: none;
  background-color: white !important;
  font: inherit;
  padding: 6px 10px;
  display: block;
  width: 100% !important;
  box-sizing: border-box !important;
  border: 0.5px solid #d4d4d4 !important;
  border-radius: 2px;
  height: ${props=> props.h}px !important;
  box-shadow: none !important;
  overflow: visible;
  margin: 0 !important;
  text-indent: 0 !important;
  color: inherit;
  cursor: text;
  &:focus,
  &:active {
    box-shadow: none !important;
    border: solid 0.5px #11adf3 !important;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc !important;
    font-weight: 300 !important;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ccc !important;
    font-weight: 300 !important;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ccc !important;
    font-weight: 300 !important;
  }
`;
NewInput.defaultProps = {
    h: "40",
};
export default NewInput
