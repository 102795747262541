import { call, put, throttle, takeLatest } from "redux-saga/effects"
import { isSuccess } from "services/Utils"
import * as Actions from "./actions"
import * as APIS from "./api"

function* handleGetCurrentPricing() {
  try {
    const res = yield call(APIS.getCurrectRateAPI)
    yield put(Actions.getCurrentRates.success(res))
  } catch (error) {
    yield put(Actions.getCurrentRates.failure(error))
  }
}

function* handleUpdateRates(action) {
  try {
    const {
      id,
      payload,
      callback,
      isNew,
    } = action.data
    let res
    if (isNew) {
      res = yield call(APIS.createNewRateAPI, payload)
    } else {
      res = yield call(APIS.updateRatesAPI, id, payload)
    }
    if (callback) callback(res)
  } catch (error) {
    console.log(error);
  }
}

export default function* main() {
  yield takeLatest(Actions.getCurrentRates.REQUEST, handleGetCurrentPricing)
  yield takeLatest(Actions.updateCurrentRates.REQUEST, handleUpdateRates)
}
