/* eslint-disable import/no-unresolved */
import React from "react"
import styled from "styled-components"
import container from "container/BrokerDashboard/globalPagesContainer"
import { Button, Tooltip } from "antd"
import ViewListings from "../ViewListings/index"

const HeaderWrap = styled.div`
  display: flex;
  margin-top: 40px;
  > div {
    &:nth-child(1) {
      flex: 1;
    }
    &:nth-child(2) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

const TCListingsView = ({ goToEditListings, form, getContractsDetailsResponse }) => (

  <>
    <HeaderWrap>
      <div>
        <h3>
          TC Listing Details
        </h3>
      </div>
      <div>
        <Tooltip
        //here
          placement="left"
          title={form[0]?.listing_status !== "pending" ? "Click To Edit this Listing" : "Can only change once in Listing"}
          color="cyan"
        >
          <Button
            type="primary"
            onClick={() => goToEditListings()}
            //here
            disabled={form[0]?.listing_status === "pending"}
            style={{
              marginTop: 13,
              marginLeft: 10,
            }}
          >
            Edit Listings
          </Button>
        </Tooltip>
      </div>
    </HeaderWrap>
    <ViewListings couponClaimed={getContractsDetailsResponse?.data} />
  </>
)

export default container(TCListingsView)
