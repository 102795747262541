import React from "react"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import {
  BlueButton,
} from "../../commonStyles"
import { showConfirm } from "../../ConfirmationModalUtil"

const ReviewAndSend = ({
  disabled,
  //mergeReviewAndSendDocumentsResponse,
  mergeReviewAndSendDocuments,
  fetchICAListingsResponse,
  currentAgentIDViewed,
  mergeReviewAndSendDocumentsResponse,
}) => {
  const {
    data: isaDocuments,
    isFetching: fetchingICADocuments,
  } = fetchICAListingsResponse || {}

  const {
    isFetching: mergingDocuments,
  } = mergeReviewAndSendDocumentsResponse || {}

  const envelopeIds = !fetchingICADocuments && isaDocuments && isaDocuments.length > 0 && isaDocuments.map(doc => doc.envelope_id) || []

  return (
    <BlueButton
      disabled={disabled || fetchingICADocuments || mergingDocuments}
      onClick={() => {
        const postAction = () => {
          const payload = {
            envelopeIds,
          }
          mergeReviewAndSendDocuments({
            payload,
            agentId: currentAgentIDViewed,
          })
        }

        const message = "Do you wish to Merge documents?"
        const warning = "Please ensure you have reviewed the document before merging them."

        showConfirm(message, postAction, warning)
      }}
      ATCFONT
      borderRadius="33.5px"
    >
      {mergingDocuments ? "Please Wait..." : "Create Envelope"}
      {!mergingDocuments && (
        <span>
          <img
            src="https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/ISA_SOUL_SUBMIT_Arrows.svg"
            alt="submit_isa"
          />
        </span>
      )}
    </BlueButton>
  )
}

export default subscriptionContainer(ReviewAndSend)

