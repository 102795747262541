import React, { useEffect, useState } from "react"
import styled from "styled-components"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import editAgentContainer from "container/BrokerDashboard/editAgentContainer"
import Header from "../Header"
import {
  Card,
  SectionTitle,
  BlueButton,
  UnderlinedCTA,
} from "../commonStyles"
import ListAgreements from "../ListTeamAgreements"
import TeamFileUpload from "../TeamFileUpload"
import TeamAgreementRadio from "./TeamAgreementCTA"

const CUSTOM = "team_agreement"
const RADIUS = "radius_team_agreement"

const ComponentWrap = styled.div`
  margin-top: 32px;
`

const FlexWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const TeamAgreement = ({
  toggleTeamAgreementType,
  teamAgreementViewType,
  uploadTeamAgreement,
  currentAgentIDViewed,
  fetchTeamAgreements,
  fetchTeamAgreementsResponse,
  history,
  getTemplateDownloadURL,
  isTeamMemberDashboard,
  rarTeamListResponse,
  agentBrokerageInfoResponse,
  urlBasedTeamId,
  teamBasedCurrentTeamAgreementStatus,
}) => {
  const [uploadUI, setUploadUI] = useState(false)

  const {
    data: teamsData,
  } = rarTeamListResponse || {}

  const {
    data: agentInfo,
  } = agentBrokerageInfoResponse || {}

  useEffect(() => {
    fetchTeamAgreements({
      type: CUSTOM,
      agentId: currentAgentIDViewed,
      history,
      teamId: agentInfo && agentInfo.brokerage_team_id,
    })
  }, [])

  useEffect(() => {
    if (agentInfo && agentInfo.brokerage_team_id) {
      const currentTeam = teamsData && teamsData.filter(item => item.id === agentInfo.brokerage_team_id) || []

      const currentTeamMembers = currentTeam && currentTeam.length > 0 && currentTeam[0].members || null
      const currentTeamLead = currentTeamMembers && currentTeamMembers.length > 0 && currentTeamMembers.filter(item => item.is_brokerage_admin === 1) || []
      const currentTeamLeadId = currentTeamLead && currentTeamLead.length > 0 && currentTeamLead[0].id || ""

      if (currentTeamLeadId) {
        fetchTeamAgreements({
          type: teamAgreementViewType === 1 ? CUSTOM : RADIUS,
          agentId: currentTeamLeadId,
          history,
          teamId: agentInfo.brokerage_team_id,
        })
      }

      setUploadUI(false)
    } else if (urlBasedTeamId) {
      const currentTeam = teamsData && teamsData.filter(item => item.id === parseInt(urlBasedTeamId, 10)) || []
      const currentTeamMembers = currentTeam && currentTeam.length > 0 && currentTeam[0].members || null
      const currentTeamLead = currentTeamMembers && currentTeamMembers.length > 0 && currentTeamMembers.filter(item => item.is_brokerage_admin === 1) || []
      const currentTeamLeadId = currentTeamLead && currentTeamLead.length > 0 && currentTeamLead[0].id || ""

      fetchTeamAgreements({
        type: teamAgreementViewType === 1 ? CUSTOM : RADIUS,
        agentId: currentTeamLeadId,
        history,
        teamId: urlBasedTeamId,
      })
      setUploadUI(false)
    }
  }, [teamAgreementViewType])

  const {
    data: teamAgreements,
    isFetching: fetchingTeamAgreements,
  } = fetchTeamAgreementsResponse || {}

  const handleUpload = (url) => {
    const payload = {
      agentId: parseInt(currentAgentIDViewed, 10),
      document: {
        url,
        name: "Document Coversheet",
        extension: "pdf",
      },
      type: "team_agreement",
    }

    uploadTeamAgreement({
      payload,
      callback: () => {
        toggleUpload(false)
      },
    })
  }

  const toggleUpload = (bool) => {
    setUploadUI(bool)
  }

  const data = {
    agentId: currentAgentIDViewed,
  }

  return (
    <ComponentWrap>
      {/* <Header title="Team Agreement" /> */}
      <Card>
        <FlexWrap>
          <SectionTitle>Team Agreement</SectionTitle>
          {!isTeamMemberDashboard && teamAgreementViewType === 1 && (
            <UnderlinedCTA
              ATCFONT
              borderRadius="33.5px"
              onClick={() => {
                toggleUpload(!uploadUI)
              }}
            >
              {uploadUI ? "List Documents" : "Upload Custom"}
            </UnderlinedCTA>
          )}
        </FlexWrap>

        {isTeamMemberDashboard ? (
          <>
            {teamBasedCurrentTeamAgreementStatus ? (
              <>
                {!fetchingTeamAgreements ? (
                  <ListAgreements
                    teamAgreements={teamAgreements}
                    data={data}
                    handleUpload={handleUpload}
                    type="upload"
                    showUpload={uploadUI}
                    download
                    editViewOnly={!isTeamMemberDashboard}
                    templateDownload
                    isTeamMemberDashboard={isTeamMemberDashboard}
                  />
                ) : (
                  <p>Fetching agreements...</p>
                )}
              </>
            ) : (
              <p>Please use the Radius Team Agreement, when uploading custom ICA.</p>
            )}
          </>
        ) : (
          <>
            <TeamAgreementRadio
              isTeamMemberDashboard={isTeamMemberDashboard}
              onChange={(e) => {
                toggleTeamAgreementType(parseInt(e.target.value, 10))
              }}
              value={teamAgreementViewType}
            />
            {teamAgreementViewType === 1 && (
              <>
                {!fetchingTeamAgreements ? (
                  <ListAgreements
                    teamAgreements={teamAgreements}
                    data={data}
                    handleUpload={handleUpload}
                    type="upload"
                    showUpload={uploadUI}
                    download
                    editViewOnly={!isTeamMemberDashboard}
                    templateDownload
                    isTeamMemberDashboard={isTeamMemberDashboard}
                  />
                ) : (
                  <p>Fetching agreements...</p>
                )}
              </>
            )}

            {!isTeamMemberDashboard && teamAgreementViewType === 2 && (
              <>
                {!fetchingTeamAgreements ? (
                  <ListAgreements
                    teamAgreements={teamAgreements}
                  // templateDownload={isTeamMemberDashboard}
                  />
                ) : (
                  <p>Fetching agreements...</p>
                )}
              </>
            )}

            {isTeamMemberDashboard && teamAgreementViewType === 2 && (
              <p>Please use the Radius Team Agreement, when uploading custom ICA.</p>
            )}

            {isTeamMemberDashboard && teamAgreementViewType === 3 && (
              <p>Please select from the options above.</p>
            )}

            {!isTeamMemberDashboard && teamAgreementViewType === 3 && (
              <p>No Team Agreement</p>
            )}
          </>
        )}
      </Card>
    </ComponentWrap>
  )
}
export default editAgentContainer(subscriptionContainer(TeamAgreement))
