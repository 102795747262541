import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"
import root from "window-or-global"
import EventList from "./EventList"
import Select from "react-select"
import {
  GearIcon,
 } from '@primer/octicons-react'
import { DefaultLoader } from "components/UI/Loader/Loader";

const Wrap = styled.div`
  div {
   height: max-content;
  }
`

const InnerCourseWrap = styled.div`
  min-height: 300px;
  max-height: 600px;
`

const FilterWrap = styled.div`
`

const AddEvent = styled(Link)`
  margin-bottom: 15px;
  width: 118px;
  display: block;
  height: 30px;
  border-radius: 4px;
  border: solid 1px #2a8b3f;
  background-image: linear-gradient(to bottom,#34ce57,#28a745);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  float: right;
  cursor: pointer;
  padding-top: 4px;
  position: relative;
  z-index: 9999;
`

const Settings = styled(Link)`
  margin-bottom: 15px;
  width: 118px;
  display: block;
  height: 30px;
  border-radius: 4px;
  background: #fff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: black;
  text-decoration: none;
  float: right;
  cursor: pointer;
  padding-top: 4px;
  margin-right: 10px;
  position: relative;
  z-index: 9999;
`


class Events extends Component{
 componentDidMount() {
 //
 }

 clearForms = () => {
  const {
   clearFormsEdits,
   clearEventAboutForm,
  } = this.props
  clearFormsEdits()
  clearEventAboutForm()
 }

 handleEventType = (val) => {
   const {
    setCurrentEventFilter,
    fetchEvents,
   } = this.props

   setCurrentEventFilter({
    currentEventFilter: [val]
   })

   if(val.value === "upcoming") {
    fetchEvents({
     filter: "upcoming",
    })
   } else {
    fetchEvents({
     filter: "all",
    })
   }
 }

 render() {

  const {
    eventsResponse,
    currentEventFilter,
  } = this.props

  const {
   data: events,
   isFetching: fetchingEvents,
  } = eventsResponse || []

  return(
   <Wrap>
    <div>
     <AddEvent
     onClick={this.clearForms}
     to="/academy-admin/events/add-new-event">
      Add Event
     </AddEvent>
     <Settings
      to="/academy-admin/events/all-settings">
      <GearIcon size={16} />
     </Settings>
     <Select
      options={[
       { value: "all", label: "All"},
       { value: "upcoming", label: "Upcoming"},
      ]}
      onChange={this.handleEventType}
      value={currentEventFilter}
     />
    </div>
    <InnerCourseWrap>
      {fetchingEvents ? (
       <DefaultLoader height={200} width={200}/>
      ) : (
       <EventList events={events}/>
      )}
    </InnerCourseWrap>
   </Wrap>
  )
 }
}

export default Container(Events)
