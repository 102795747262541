import styled from "styled-components"

export const CardWrap = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  
  max-width: 320px;
  height: max-content;
  border: 1px solid #D6D6D6;
  cursor: pointer;

  &:hover {
    border: 1px solid #5A5FF2;
    background: #F8F8FF;
  }

  transition: all 0.2s ease-out;

  ${props => props.selected && `
    border: 1px solid #5A5FF2;
    background: #F8F8FF;
  `}
`

export const AgentName = styled.div`
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--BG-Dark-mode, #292A37);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 21.6px */
    max-width: 184px;
  }
`

export const Topic = styled.div`
  p {
    color: var(--BG-Dark-mode, #292A37);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 21.6px */
  }
`

export const Since = styled.div`
  p {
    color: #6E7093;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.353px; /* 111.275% */
  }
`

export const UpperWrap = styled.div`
  display: flex;
  gap: 10px;
`

export const HeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: normal;

`

export const ChevronWrap = styled.div`
  position: relative;
  top: -6px;

  img {
    width: 32px;
    height: 32px;
  }
`

export const TimeAndFeedback = styled.div`

  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  p {
    text-align: justify;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`

export const FeedBack = styled.div`
  .green {
    color: #00B255;
  }

  .red {
    color: #F87171;
  }

  img {
    width: 12.8px;
    height: 12.8px;
  }
`

export const ThumbsAndNumber = styled.div`
  display: flex;
  gap: 5px; 
`

export const Feedback = styled.div`

  display: flex;
  gap: 5px;

  p {
    margin: 0;
    text-align: justify;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: ${props => props.color};
  }

  img {
    width: 12.8px;
    height: 12.8px;
    flex-shrink: 0;
  }
`
