import React from "react"
import { Tabs } from "antd"
import RARTechContainer from "container/TechAssets"
import {
  Deals,
} from "./TabComponents"

const { TabPane } = Tabs

const HeaderTab = (props) => {
  // const {
  //   fetchReferrals,
  //   fetchAllEarnings,
  // } = props || {}

  const onChange = (key) => {
    switch (key) {
      case "deals":
        // fetchReferrals({
        //   queryParams: "skip=0&limit=300",
        // })
        break
      default:
        return false
    }

    return true
  }

  return (
    <Tabs defaultActiveKey="deals" onChange={onChange}>
      <TabPane tab="Deals" key="deals">
        <Deals />
      </TabPane>
      {/* <TabPane tab="Financials" key="financials">
        <Financials />
      </TabPane>
      <TabPane tab="Metrics" key="details">
        <Details />
      </TabPane> */}
    </Tabs>
  )
}

export default RARTechContainer(HeaderTab)
