import { createSignalAction, createSimpleCreator } from "shared/reduxUtils";

const BASE = 'CONSUMER';

export const fetchWaitlistAction = createSignalAction(BASE, 'FETCH_WAITLIST');
export const saveWaitlistStatusAction = createSignalAction(BASE, 'SAVE_WAITLIST_STATUS');
export const uploadAppAssetsAction = createSignalAction(BASE, 'UPLOAD_APP_ASSETS');
export const updateAppCustomisationsAction = createSignalAction(BASE, 'UPDATE_CUSTOMIZATION');
export const fetchAppCustomisationsAction = createSignalAction(BASE, 'FETCH_CUSTOMIZATION');
export const selectedAppAction = createSimpleCreator(BASE, "SELECTED_APP")
