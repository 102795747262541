const colors = {
  //SHADES OF SHALLOW WHITE
  blueDarkShade: "#f0f3f5",
  milkyGrey: "#f5f5f5",
  priceCardGrey: "#f7f7f7",
  bgGrey: "#f8f9fa",
  greyBackground: "#fbfcfc",
  lightWhiteGray: "#fcfcfc",
  white: "#ffffff",
  transparentWhite: "#fcfcfc47",
  transaprentYolkWhite: "##8080807a",
  horizonWhite: "#F8F8F8",

  //SHADES OF WHITE
  paleWhite: "#dedede",
  borderGray: "#eaeaea",
  veryLightGray: "#eeeeee",
  newTagInactive: "#eef0f0",
  shallowGrey: "#e5e9eb",
  moonGrey: "#D3DAE8",
  otterGrey: "#B4B4B4",
  disabledInput: "#e6e8e9",
  borderColor: "#e7e8ea",
  disabledColor: "#e7ebed",
  tagActive: "#e9f7fd",
  whiteBlue: "#f8f9fa",
  spaceWhiteGrey: "#FCFCFC",
  eggYolkWhite: "#C7C7C7",

  //SHADES OF YELLOW
  starActive: "#ffd05b",

  //SHADES OF RED
  peachRed: "#c47070",
  redText: "#f96d72",
  strawberryPink: "#ea526f",
  sunsetPink: "#ff3e3e",
  blushRed: "#F6284F",
  red: "#ff0000",
  errorDanger: "#ff4b4b",
  berryRed: "#ED1616",

  //SHADES OF GREEN
  textGreen: "#09c199",
  turqoiseGreen: "#0ac4ba",
  successColor: "#51d3b7",
  gradientGreen: "#2bda8e",

  //SHADES OF ORANGE
  pulpyOrange: "#EE8B31",

  //SHADES OF BLUE
  whiteBlueFaded: "#e9f3fb",
  fadedPrimaryColor: "#ADE1F8",
  fadedBlueSea: "#F1F3F8",
  primaryColor: "#11adf3",
  iconDarkColor: "#868b90",
  infoBorderColor: "#10adf3",
  primaryBorderColor: "#11adf3",
  primaryHover: "#0088a6",
  newPrimaryColor: "#0B8DEC",
  mainFadedPrimaryColor: "#739DDC",
  feedHighlight: "#f5fcff",
  marineBLue: "#3B60E4",
  luxorBlue: "#364A7D",

  // Academy Color
  academyTitleColor: "#392463",
  lightPurple: "#565ca7",
  darkPurple: "#190A36",
  darkRoyalePurple: "#121425",
  lightCream: "#FBF8EF",

  //SHADES OF BLACK AND DARK GREY
  paleGrayWhite: "#f6f6f6",
  milkyWhite: "#dadada",
  starInactive: "#d4d4d4",
  tabDisabledColor: "#c4c7ca",
  textDisabledColor: "#c6c9cb",
  darkGreyPurple: "#524969",
  faintGrey: "#adb2b9",
  bluishGrey: "#adb3bb",
  grayishBlue: "#aab3be",
  primaryInactiveColor: "#a5a8ad",
  tagInactive: "#a7a9ad",
  charcoalWhiteGrey: "#808080",
  faintBlueishBlack: "#868b90",
  newTagText: "#898c90",
  silkGrey: "#929292",
  charcoalBlueGrey: "#979797",
  dirtBrown: "#666b71",
  reddishGrey: "#d8d8d8",
  contentText: "#354052",
  slateGrey: "#949494",
  deepSeaBlue: "#3c4858",
  deepSeaGray: "#767676",
  textColor: "#262626",
  black: "#303030",
  lightGrayishBlue: "#F1F3F8",
  deepPurple: "#4E26BE",
  lightGray: "#BEBEBE",
  suvaGray: "#8C8C8C",
  solitude: "#EAF5FF",
  silentWhite: "#EFEFEF",
  fungiWhite: "#e5e5e5",
  dodoGrey: "#d0d0d0",
  yolkYellow: "#F6F1E9",
}

export default colors
