import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"
import { getLastWeek } from "../../services/Utils"

const initState = {
  inputValue: "",
  lowerZillow: "",
  upperZillow: "",
  lowerInbound: "",
  upperInbound: "",
  lowerRinging: "",
  upperRinging: "",
  callerQTY: "",
  avgCallTime: "",
  maxTextResponseTime: "",
  minTextResponseTime: "",
  durationDay: "",
  durationHour: "",
  durationMin: "",
  textDurationDay: "",
  textDurationHour: "",
  textDurationMin: "",
  currentFilter: "",
  customDate: {
   start: "",
   end: "",
  },
  currentLimit: 10,
  ...createDeltaReducer("agentSearchResponse"),
  ...createDeltaReducer("fetchMetricsResponse"),
  currentSubscriptionFilter: [{ label: "Select..", value: "Select"}],
  currentDateFilter: [{ value: "lastWeek", label: "Last Week", min: getLastWeek(true), max: getLastWeek(false)}],
  currentReferralFilter: [{ label: "Select..", value: "Select"}],
  currentWorkTypeFilter: [{ label: "Select..", value: "Select"}],
  currentReferralSourceFilter: [{ label: "Select..", value: "Select"}],
}

export default function clientProfileReducer(state = initState, action) {
    switch (action.type) {
        case Actions.handleSearchInputAction.type:
        {
          const {
           inputValue,
          } = action.data
            return {
                 inputValue,
                ...state,
            }
        }

        case Actions.setCurrentReferralSourceAction.type: {
         const {
          currentReferralSourceFilter,
         } = action.data
         return {
          ...state,
          currentReferralSourceFilter,
         }
        }

        case Actions.modifyCurrentLimitAction.type: {
          return {
           ...state,
           currentLimit: action.data
          }
        }

        case Actions.setCurrentCustomDateRangeAction.type: {
         const {
          customDate,
         } = action.data
         return {
          ...state,
          customDate,
         }
        }

        case Actions.setCurrentDurationFilterDayAction.type: {
         return {
          ...state,
          durationDay: action.data,
         }
        }


        case Actions.setCurrentDurationFilterMinAction.type: {
         return {
          ...state,
          durationMin: action.data,
         }
        }

        case Actions.setCurrentDurationFilterHourAction.type: {
         return {
          ...state,
          durationHour: action.data,
         }
        }

        case Actions.setCurrentTextDurationFilterDayAction.type: {
         return {
          ...state,
          textDurationDay: action.data,
         }
        }


        case Actions.setCurrentTextDurationFilterMinAction.type: {
         return {
          ...state,
          textDurationMin: action.data,
         }
        }

        case Actions.setCurrentFiltersAction.type: {
         const {
          currentFilter,
         } = action.data
         return {
          ...state,
          currentFilter,
         }
        }

        case Actions.setCurrentTextDurationFilterHourAction.type: {
         return {
          ...state,
          textDurationHour: action.data,
         }
        }

        case Actions.setCurrentReferralTypeAction.type: {
         const {
          currentReferralFilter,
         } = action.data
         return {
          ...state,
          currentReferralFilter,
         }
        }

        case Actions.setCurrentWorkingHoursAction.type: {
         const {
          currentWorkTypeFilter,
         } = action.data
         return {
          ...state,
          currentWorkTypeFilter,
         }
        }

        case Actions.setCurrentSortAction.type:
        {
          const {
           currentSortType,
          } = action.data
           return {
               ...state,
               currentSortType,
               sortValue: currentSortType,
           }
        }

        case Actions.setCurrentAgentsAction.type: {
         const {
          current,
          currentAgents,
         } = action.data
         if(currentAgents && currentAgents.length > 0) {
          const idArr = [...currentAgents, current]
          return {
           ...state,
           currentAgents: idArr,
          }
         } else {
          return {
           currentAgents: [current],
           ...state,
          }
         }
        }

        case Actions.supplyOptionsAction.type:
        {
          const {
           op,
          } = action.data
            return {
                 ...state,
                 agentsOptions: op.map(key => {
                  return {
                   value: "check",
                   label: key.firstname,
                  }
                 }),
            }
        }

        case Actions.lowerInboundMissedCallAction.type: {
         const {
          lowerInbound,
         } = action.data

         return {
          ...state,
          lowerInbound,
         }
        }

        case Actions.upperInboundMissedCallAction.type: {
         const {
          upperInbound,
         } = action.data

         return {
          ...state,
          upperInbound,
         }
        }

        case Actions.minTextResponseTimeAction.type: {
         const {
          minTextResponseTime,
         } = action.data

         return {
          ...state,
          minTextResponseTime,
         }
        }

        case Actions.maxTextResponseTimeAction.type: {
         const {
          maxTextResponseTime,
         } = action.data

         return {
          ...state,
          maxTextResponseTime,
         }
        }

        case Actions.avgCallTimeAction.type: {
         const {
          avgCallTime,
         } = action.data

         return {
          ...state,
          avgCallTime,
         }
        }

        case Actions.callerQtyAction.type: {
          const {
           callerQTY,
          } = action.data

          return {
           ...state,
           callerQTY,
          }
        }

        case Actions.lowerZillowMissedCallAction.type: {
         const {
          lowerZillow,
         } = action.data

         return {
          ...state,
          lowerZillow,
         }
        }

        case Actions.upperZillowMissedCallAction.type: {
         const {
          upperZillow,
         } = action.data

         return {
          ...state,
          upperZillow,
         }
        }

        case Actions.lowerRingingMissedCallAction.type: {
            const {
                lowerRinging,
            } = action.data

            return {
                ...state,
                lowerRinging,
            }
        }

        case Actions.upperRingingMissedCallAction.type: {
            const {
                upperRinging,
            } = action.data

            return {
                ...state,
                upperRinging,
            }
        }

        case Actions.handleAgentSearchAction.REQUEST:
        case Actions.handleAgentSearchAction.SUCCESS:
        case Actions.handleAgentSearchAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(state, action, Actions.handleAgentSearchAction, "agentSearchResponse"),
            }
        }

        //fetchMetricsAction
        case Actions.fetchMetricsAction.REQUEST:
        case Actions.fetchMetricsAction.SUCCESS:
        case Actions.fetchMetricsAction.FAILURE: {
            return {
                ...state,
                ...handleDeltaReducer(state, action, Actions.fetchMetricsAction, "fetchMetricsResponse"),
            }
        }

        case Actions.setCurrentDateFilterAction.type: {
         const {
          currentDateFilter,
         } = action.data
         return {
          ...state,
          currentDateFilter,
         }
        }

        case Actions.setCurrentSubscriptionFilterAction.type: {
         const {
          currentSubscriptionFilter,
         } = action.data
         return {
          ...state,
          currentSubscriptionFilter,
         }
        }

        case Actions.clearAllFiltersAction.type: {
          return {
           ...state,
           currentSubscriptionFilter: [{ label: "Select..", value: "Select"}],
           currentDateFilter: [{ label: "Select..", value: "Select"}],
           currentReferralFilter: [{ label: "Select..", value: "Select"}],
           currentWorkTypeFilter: [{ label: "Select..", value: "Select"}],
           currentReferralSourceFilter: [{ label: "Select..", value: "Select"}],
           lowerZillow: "",
           upperZillow: "",
           lowerInbound: "",
           upperInbound: "",
           lowerRinging: "",
           upperRinging: "",
           callerQTY: "",
           avgCallTime: "",
           maxTextResponseTime: "",
           minTextResponseTime: "",
           currentSortType: "",
           currentLimit: 10,
           textDurationDay: "",
           textDurationHour: "",
           textDurationMin: "",
           durationDay: "",
           durationHour: "",
           durationMin: "",
           customDate: {
            start: "",
            end: "",
           }
          }
        }

        case Actions.removeFromCurrentAgentsActions.type: {
          const {
           deletionID,
           currentAgents,
          } = action.data
          return {
           ...state,
           currentAgents: currentAgents.filter(key => key.id !== deletionID),
          }
        }

        default: {
            return state
        }
    }
}
