import React, { useState } from "react"
import styled from "styled-components"
import {
  Button,
  Select,
  DatePicker,
} from "antd"
import { withRouter } from "react-router-dom"
import AgentSearch from "dumbComponents/BrokerDashboard/Components/AgentSearch"
import container from "container/BrokerDashboard/globalPagesContainer"
import CashbackContainer from "container/EventRooms"

const { Option } = Select

const OuterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px
`

const Wrap = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: flex-start;
 gap: 20px;
`

const InputWrap = styled.div`
  max-width: 293px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: baseline;
  height: max-content;
`

const Label = styled.label`
  min-width: fit-content;
`

const SearchControls = (props) => {
  const {
    dateValue, setDate, agentDetails, setAgentDetails,
  } = props
  // const [dateValue, setDate] = useState("")
  // const [agentDetails, setAgentDetails] = useState({})

  const {
    fetchReferrals,
    fetchAllEarnings,
    showStatusFilter,
    clearSetSearchValues,
    history,
    getTotalRecruitAndEarnStats,
    setFilterQueries,
  } = props

  const fetchFilteredAgents = (agent, event) => {
    const { getCashbackList } = props
    const body = {
      skip: 0,
      limit: 10,
    }
    if (agent && agent.id) {
      body.agentId = agent.id
    }
    if (event && event.unix()) {
      body.createdDate = event.unix()
    }
    getCashbackList(body)
  }

  const handleInviteSenderDetailsFetch = (agent) => {
    if (agent === undefined || agent === null || !agent) return false
    setAgentDetails(agent)
    fetchFilteredAgents(agent, dateValue)
  }

  const onDateChange = (event) => {
    const unixValue = event && event.unix() || ""
    if (unixValue) {
      setDate(event)
      fetchFilteredAgents(agentDetails, event)
    }
  }

  return (
    <OuterWrap>
      <Wrap>
        <div style={{ flexDirection: "column" }}>
          <InputWrap>
            <Label htmlFor="agent">Agent Name / Team Lead</Label>
            <AgentSearch
              onAgentSelect={handleInviteSenderDetailsFetch}
              handleRecieverAgent
              placeholder="Search Agent"
              customInputStyling={{
                height: "34px",
                borderRadius: "0px",
                width: "300px",
              }}
              type="referred"
            />
          </InputWrap>
          {agentDetails && agentDetails.id && (
            <p style={{ fontSize: "15px", fontWeight: 400, padding: "15px 0 0 0" }}>{`Selected Agent: ${agentDetails.firstname || ""} ${agentDetails.lastname || ""}`}</p>
          )}
        </div>
      </Wrap>
      <Wrap>
        <InputWrap>
          <Label htmlFor="Filter">Created Date</Label>
          <DatePicker allowClear={false} onChange={onDateChange} value={dateValue} />
        </InputWrap>
        <InputWrap>
          <Label htmlFor="Filter">Sort</Label>
          <Select
            style={{
              width: 120,
            }}
            placeholder="Sort by"
            value={null}
          >
            <Option value="date">Date</Option>
          </Select>
        </InputWrap>
        <InputWrap>
          <Button
            danger
            onClick={() => {
              setDate("")
              setAgentDetails({})
              props.getCashbackList({
                skip: 0,
                limit: 10,
              })
            }}
          >
            Reset Filters
          </Button>
        </InputWrap>
      </Wrap>
    </OuterWrap>
  )
}

export default withRouter(container(CashbackContainer(SearchControls)))
