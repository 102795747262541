import React, { Component } from 'react';
import Popup from "reactjs-popup";

class AgentComparisonFilter extends Component {

    render() {

        const {filter, handleFilterChange} = this.props;

        return (
            <div>
                <Popup
                    trigger={
                        <button className="filter-comparison-button">Filter</button>
                    }
                    modal
                    closeOnDocumentClick={true}
                    closeOnEscape={true}
                >
                    {close => (
                        <div className="modal-2">

                            <div className="modal-header">
                                    <span className="modal-header-text">
                                        REFINE YOUR SEARCH
                                    </span>
                            </div>

                            <div>
                                <div className="edit-client">
                                    <input type="checkbox" className="checkbox-left-1" checked={filter.showPartnerOnly} onChange={() => handleFilterChange("showPartnerOnly")} />
                                    <span>Partner</span>
                                </div>
                                <div className="edit-client">
                                    <input type="checkbox" className="checkbox-left-1" checked={filter.showCRSOnly} onChange={() => handleFilterChange("showCRSOnly")} />
                                    <span>CRS</span>
                                </div>
                                <div className="edit-client">
                                    <input type="checkbox" className="checkbox-left-1" checked={filter.showRCAOnly} onChange={() => handleFilterChange("showRCAOnly")} />
                                    <span>RCA</span>
                                </div>

                                <div className="edit-client">
                                    <input type="checkbox" className="checkbox-left-1" checked={filter.showRegisteredOnly} onChange={() => handleFilterChange("showRegisteredOnly")} />
                                    <span>Registered</span>
                                </div>

                                <div className="edit-client">
                                    <input type="checkbox" className="checkbox-left-1" checked={filter.showSignedOnly} onChange={() => handleFilterChange("showSignedOnly")} />
                                    <span>Referral agreement signed</span>
                                </div>
                                <div className="edit-client">
                                    <input type="checkbox" className="checkbox-left-1" checked={ filter.showStarredOnly} onChange={() => handleFilterChange("showStarredOnly")} />
                                    <span>Received radius referrals before</span>
                                </div>
                            </div>
                            <div className="edit-client-footer">
                                <button className="med-btn-1" onClick={() => close()}>
                                    Apply
                                </button>
                            </div>
                        </div>
                    )}
                </Popup>
            </div>
        );
    }
}

export default AgentComparisonFilter;