import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "AI_DASHBOARD"

export const submitQueryToChatAction = createSignalAction(BASE, "SUBMIT_QUERY")
export const toggleAIinChatAction = createSignalAction(BASE, "TOGGLE_AI_RESPONSE")
export const markAsResolvedAction = createSignalAction(BASE, "MARK_AS_RESOLVED_ACTION")
export const fetchAIChatSessionsAction = createSignalAction(BASE, "FETCH_AI_CHATS")
export const fetchAIAnalyticsAction = createSignalAction(BASE, "FETCH_FEEDBACK_ANALYTICS")
export const fetchSessionHistoryAction = createSignalAction(BASE, "FETCH_SESSION_HISTORY")
export const constructQueryAction = createSimpleCreator(BASE, "CONSTRUCT_QUERY")
export const fetchAgentAction = createSignalAction(BASE, "FETCH_AGENT_ACTIONS")
export const fetchTeamsAction = createSignalAction(BASE, "FETCH_TEAMS")
export const fetchAgentChatSessionAction = createSignalAction(BASE, "FETCH_AGENT_SESSION")
export const fetchTrainedDocumentsAction = createSignalAction(BASE, "FETCH_TRAINED_DOCUMENTS")
export const deleteTrainedDocumentsAction = createSignalAction(BASE,"DELETE_SIGNAL_ACTION")
export const toggleTrainingFormModalAction = createSimpleCreator(BASE, "TOGGLE_TRAINING_FORM")
export const uploadLinkToFileAction = createSignalAction(BASE, "UPLOAD_LINK_TO_FILE")

//local actions
export const setCurrentSessionsArrayAction = createSimpleCreator(BASE, "CREATE_CURRENT_SESSIONS_ARRAY")
export const setCurrentChatForDetailsViewAction = createSimpleCreator(BASE, "CURRENT_CHAT_FOR_DETAIL_VIEW")
export const setCurrentSessionIdAction = createSimpleCreator(BASE, "SET_CURRENT_SESSION_ID")
export const setCurrentAgentSelectedForPubnubAction = createSimpleCreator(BASE, "SET_CURRENT_AGENT_FOR_PUBNUB")
export const handlePubNubEventAction = createSimpleCreator(BASE, "HANDLE_PUBNUB_EVENT")
export const handleNewAgentPubNubAction = createSimpleCreator(BASE, "HANDLE_NEW_AGENT_PUBNUB")
