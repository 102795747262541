import React from "react"
import styled from "styled-components"
import Button from "dumbComponents/common/UI/Button"
import Select from "react-select"

const Wrap = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  background: rgba(0,0,0,0.6);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
`

const SideWrap = styled.div`
  width: 400px;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  background: #fff;
  padding: 100px 20px 20px 20px;

  > h6 {
    font-size: 20px;
    margin: 0px;
  }
`

const MainAreaWrap = styled.div`
  flex: 1;
`

const SectionWrap = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  p {
    flex: 1;
  }
  > div {
    width: 160px;
  }
`
const CloseBTN = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
`

const ControlsWrap = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`

const AGENT_TYPES = [
  { label: "Broker", value: "is_broker_agent" },
  { label: "Accelerator", value: "is_accelerator_agent" },
  { label: "Unregistered", value: "unregistered_agent" },
  { label: "All", value: "all" },
]

const SidePanel = ({
  applyFilters,
  setFilterKeys,
  filterOptions = {},
  toggleFilter,
}) => {
  const handleUpdateFilter = (item) => {
    const payload = {}
    if (item.value === "is_broker_agent") {
      payload.is_broker_agent = 1
    }
    if (item.value === "is_accelerator_agent") {
      payload.is_accelerator_agent = 1
    }
    if (item.value === "unregistered_agent") {
      payload.unregistered_agent = "Y"
    }
    setFilterKeys({
      ...payload,
    })
  }

  const handleIsSigned = (item) => {
    if (item.value === 1) {
      setFilterKeys({
        ...filterOptions,
        agreement_signed_date: item.value,
      })
    }
  }

  const handleCountryFilter = (item) => {
    if (item.value === 1) {
      setFilterKeys({
        ...filterOptions,
        show_county_agents: item.value,
      })
    }
  }

  const handleApplyFilter = () => {
    applyFilters()
    toggleFilter()
  }

  const handleClear = () => {
    setFilterKeys({})
    toggleFilter()
  }

  return (
    <Wrap>
      <SideWrap>
        <CloseBTN onClick={toggleFilter}>
          <i className="fa fa-times" />
        </CloseBTN>
        <h6>Filters</h6>
        <MainAreaWrap>
          <SectionWrap>
            <p>Agent Type</p>
            <div>
              <Select
                options={AGENT_TYPES}
                onChange={handleUpdateFilter}
              />
            </div>
          </SectionWrap>
          <SectionWrap>
            <p>Is Agreement Signed</p>
            <div>
              <Select
                options={[
                  { label: "Yes", value: 1 },
                ]}
                onChange={handleIsSigned}
              />
            </div>
          </SectionWrap>
          <SectionWrap>
            <p>Show County Agents</p>
            <div>
              <Select
                options={[
                  { label: "1", value: 1 },
                ]}
                onChange={handleCountryFilter}
              />
            </div>
          </SectionWrap>
        </MainAreaWrap>
        <ControlsWrap>
          <Button rounded onClick={handleClear}>
            Clear Filter
          </Button>
          <Button rounded success onClick={handleApplyFilter}>
            Apply
          </Button>
        </ControlsWrap>
      </SideWrap>
    </Wrap>
  )
}

export default SidePanel
