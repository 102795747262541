/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import container from "container/BrokerDashboard/globalPagesContainer"
import { Button, Tooltip } from "antd"
import Loader from "dumbComponents/common/UI/Loader"
import DetailedView from "./DetailedViewComponent"

const HeaderWrap = styled.div`
  display: flex;
  margin-top: 40px;
  > div {
    &:nth-child(1) {
      flex: 1;
    }
    &:nth-child(2) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
//apis
const TCContractsView = ({ getContractsDetailsResponse, goToEdit, vendors, form, getContractsDetails, match, dealsListingResponse }) => {

  return (
    <>
      <HeaderWrap>
        <div>
          <h3>
            TC Contract Details
          </h3>
        </div>
        <div>
          <Tooltip 
          //here
            placement="left" 
            title={(form[0]?.listing_status === "pending" || !form[0]?.listing_status) ? "Click To Edit this Contract" : "Can only change once in Contract"} 
            color="cyan">
            <Button
              type="primary"
              //here
              disabled={!(form[0]?.listing_status === "pending" || !form[0]?.listing_status)}
              onClick={() => goToEdit()}
              style={{
                marginTop: 13,
                marginLeft: 10,
              }}
            >
              Edit Contracts
            </Button>
          </Tooltip>
        </div>
      </HeaderWrap>
      {//here
        dealsListingResponse.isFetching && (
          <Loader />
        )}
      {//here
        dealsListingResponse.data && (
        <>
          <DetailedView 
          //here
          vendors={vendors && vendors} 
          clients={dealsListingResponse.data[0]?.clients} 
          details={dealsListingResponse.data[0]} />
        </>
      )}
    </>
  )
}

export default withRouter(container(TCContractsView))
