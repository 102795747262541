import React, { Component } from 'react';
import * as qs from 'query-string';
import { findKey } from 'lodash';
import moment from 'moment';
import PanelContainer from  "../container/PanelContainer/PanelContainer";
import bugsnag from 'bugsnag-js';
import {fetchCategorizedNotes} from "./API/NotesApi";
import {DefaultLoader} from "./UI/Loader/Loader";
import AddNote from "./Notes/AddNote";
import NotesListView from "./NotesListView";
const bugsnagkey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(bugsnagkey);

class Notes extends Component {

  state = {
    loading: true,
    allNotes: [],
    notes: [],
    clientIdToAgentNameMap: [],
    clientId: null,
  };

  startLoader = () => {
    this.setState({loading: true})
  };

  stopLoader = () => {
    this.setState({loading: false})
  };

  filter = (index) => {
      let allNotes = this.state.allNotes;
      let notes = [];
      if (index === "all") {
          allNotes.map(note => {
              notes = notes.concat(note && note.notes ? note.notes : []);
          });
      } else {
          let notes = allNotes[index] && allNotes[index].notes ? allNotes.notes[index].notes : [];
      }
      this.setState({notes: notes});
  };

  loadNotes = async () => {
      try {
          this.startLoader();
          const self = this;
          if (!self.props.referral) {
              this.stopLoader();
              return;
          }

          let body = {
              sender_client_id: self.props.referral.sender_client_id,
              trustle_client_id: self.props.referral.receiver_client_id,
              receiver_client_ids: self.props.assignedAgents.map((agent) => {
                  return agent.receiver_client_id;
              })
          };

          await fetchCategorizedNotes(body).then(respJson => {
              this.setState({allNotes: respJson.response.notes});
              this.filter("all");
              this.stopLoader();
          });

      } catch(e) {
          this.stopLoader();
          bugsnagClient.notify(e);
      }
  };

  // componentWillReceiveProps(props) {
  //   console.log("I am changing notes");
  //     this.setState({ notes: props.notes, referral: props.referral, user: props.user });
  // }

  componentDidMount() {
    const parsed = qs.parse(window.location.search);

      let clientIdToAgentNameMap = [];
      this.props.assignedAgents.map((agent) => {
          clientIdToAgentNameMap[agent.receiver_client_id] = agent.firstname;
      });
      clientIdToAgentNameMap[this.props.referral.sender_client_id] = this.props.referral.sender_name;

    this.setState({
      referral: this.props.referral,
      user: this.props.user,
      clientIdToAgentNameMap: clientIdToAgentNameMap,

    });
    this.loadNotes();
  }

  compare = (a, b) => {
    if(!a || !b) {
      return 0;
    }
    if (a.created_at < b.created_at) return 1;
    if (a.created_at > b.created_at) return -1;
    return 0;
  };

  updateNoteObject = (id, newNoteObject) => {
      const { notes } = this.state;
      const newNotes = [...notes];

      const resultKey = findKey(newNotes, {
          id,
      });

      newNotes[resultKey] = newNoteObject;

      this.setState({
          notes: newNotes,
      })

  };

  render() {
    let notes = this.state.notes ? this.state.notes.sort(this.compare) : [];
    notes = notes.map((note, index) => {
      let addedFor = this.state.clientIdToAgentNameMap[note.client_id] ? " for " + this.state.clientIdToAgentNameMap[note.client_id] : "";
      return (
          <NotesListView
            key={index}
            note={note}
            addedFor={addedFor}
            updateNoteObject={this.updateNoteObject}/>
      )
    });

    return (
      <PanelContainer title={"Notes"} style={{padding : '0'}}>

        <AddNote referral={this.props.referral}
                 user={this.props.user}
                 assignedAgents={this.props.assignedAgents}
                 callback={this.loadNotes}
                 tempNote={this.props.tempNote}
                 requestUnsavedNote={this.props.requestUnsavedNote}
                 saveUnsavedNoteLocally={this.props.saveUnsavedNoteLocally}/>

          <div style={{marginLeft: '15px'}}>
              <button onClick={() => this.loadNotes()}>
                  Reload Notes</button>
          </div>

        {this.state.loading === true ?
            <DefaultLoader height={0} width={0}/>
            :
            <div className={"scroll-box"}>
              <ul className="note-lists">{notes}</ul>
            </div>
        }
      </PanelContainer>
    );
  }
}
export default Notes;
