import {notify} from "react-notify-toast";
import bugsnag from "bugsnag-js";
const bugsnagBlinkKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(bugsnagBlinkKey);

export function SetBugsnagUser(user) {
    bugsnagClient.user = {
        id: user.id,
        name: user.name,
        agent_id: user.agent_id
    }
}

export function ShowErrorToastWithCustomMessage(resp) {
    notify.hide();
    let message = "Something went wrong";
    if (typeof resp === "string") {
        message = resp;
    } else if(resp && resp.error && resp.error.dev_message){
        message = resp.error.dev_message
    } else if(resp && resp.error && resp.error.message){
        message = resp.error.message
    }
    notify.show(message, 'error', 5000);
}

export function ShowErrorToast(resp) {
    notify.hide();
    let message = "Something went wrong";
    if (typeof resp === "string") {
        message = resp;
    } else if(resp && resp.error && resp.error.message){
        message = resp.error.message
    }
    notify.show(message, 'error', 5000);
}

export function ShowErrorToastFromText(message){
    notify.show(message, 'error', 5000);
}

export function ShowSuccessToast(message) {
    if(!message) {
        message = "Successfully Updated!"
    }
    notify.show(message, 'success', 5000);
}

export function NotifyBugsnagError(e, data) {
    notifyBugsnag(e, data, 'error');
}

export function NotifyBugsnagInfo(e, data) {
    notifyBugsnag(e, data, 'info');
}

function notifyBugsnag(e, data, severity) {
    if ((e instanceof Error) === false)  {
        e = new Error(e);
    }
    bugsnagClient.notify(e, {
        metaData: data,
        severity: severity
    });
    console.log(e);
}

