import React, { Component } from "react"
import { get } from "lodash"
import root from "window-or-global"
import styled from "styled-components"
import md5 from "md5"
import cookie from "react-cookies"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import colors from "dumbComponents/common/UI/colors"
import queryString from "query-string"
import { withRouter } from "react-router-dom"
import {
  SALES_REPRESENTATIVE as SALES_REPRESENTATIVE_OPTIONS,
} from "dumbComponents/BrokerDashboard/GlobalPages/Resources/data"
import GoogleLocation from "../../../components/GoogleAutoComplete"

import {
  formValidator as FormValidator,
  ShowHidePassword,
  UrlSearchParams,
} from "./authUtils"
import { SIGN_UP_FORM_AGENT_TYPE, SALES_REPRESENTATIVE } from "./consts"
import FontAwesome from "./UI/FontAwesome"
import PhoneNumber from "./UI/PhoneNumber"
import Input from "./UI/Input"

/*
 appURL: process.env.REACT_APP_API_BASE_URL + "/v1.0/",
 appURLV2: process.env.REACT_APP_API_BASE_URL + "/v2.0/",
 appURLV3: process.env.REACT_APP_API_BASE_URL + "/v3.0/"
*/

const API_V2_URL = `${process.env.REACT_APP_API_BASE_URL}/v2.0/`
const API_URL = `${process.env.REACT_APP_API_BASE_URL}/v1.0/`

const SignUpBox = styled.div`
  text-align: center;
  margin-top: 45px;
  margin-bottom: 25px;
  font-family: "DM Sans";
`

const Create = styled.button`
  background: ${colors.marineBLue};
  color: ${colors.white};
  cursor: pointer;
  box-shadow: none;
  border: none;
  padding: 16px;
  border-radius: 18px;

  ${props => props.disabled && `
   background: grey;
   color: white;
   cursor: not-allowed;
 `}
`

const EyeIcon = styled.div`
  position: relative;
  bottom: 91px;
  right: 35px;
  z-index: 9999;
`

const DescriptionWrap = styled.div``

const Wrap = styled.div`
  padding: 100px 200px;
`

const FormElement = styled.form`
  margin-top: 60px;
  @media(max-width: 1099px) {
    padding: 10px;
    margin-top: -30px;
  }
`

const FormRelative = styled.div`
  position: relative;
`

const StyledFA = styled(FontAwesome)`
  float: right;
  position: absolute;
  top: 14px;
  right: 15px;
  font-size: 18px;
  color: #aaa;
  cursor: pointer;
`

const PhoneWrapper = styled.div`
  margin-bottom: 40px;
  padding: 0 3px;
`

const ErrorText = styled.small`
  font-family: 'DM Sans', sans-serif;
  display: flex;
  margin-top: 3px;
  line-height: 1.75;
  text-align: left;
  color: #f99fa2;
  font-size: 12px;
  padding-bottom: 15px;
`

const GoogleLocationWrap = styled.div`
 width: 98%;
`

const NoLocation = styled.p`
  margin: 0;
  font-size: 12px;
  color: red;
`

class SignUpForm extends Component {
  constructor(props) {
    super(props)
    const { search } = root.location
    const parsedQuery = queryString.parse(search)
    const emailId = parsedQuery.email_id || ""

    this.state = {
      signupForm: {
        firstname: {
          elementName: "name",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "John",
          },
          show: true,
          value: "",
          fieldName: "First name",
          label: "First name",
          validation: {
            required: true,
            isAlphaNumeric: true,
          },
          valid: false,
          touched: true,
        },
        lastname: {
          elementName: "name",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Doe",
          },
          show: true,
          value: "",
          fieldName: "Last name",
          label: "Last Name",
          validation: {
            required: true,
            isAlphaNumeric: true,
          },
          valid: false,
          touched: true,
        },
        account_type: {
          elementType: "select",
          elementConfig: {
            type: "select",
            placeholder: "Buyer/Seller",
          },
          show: true,
          value: "",
          fieldName: "AgentType",
          label: "I am a",
          validation: {
            required: false,
            isEmail: false,
          },
          valid: false,
          touched: true,
          options: SIGN_UP_FORM_AGENT_TYPE,
        },
        email: {
          elementType: "input",
          elementConfig: {
            type: "email",
            placeholder: "you@domain.com",
          },
          show: true,
          value: emailId || "",
          fieldName: "Email",
          label: "Email",
          validation: {
            required: true,
            isEmail: true,
          },
          valid: false,
          touched: true,
        },
        password: {
          elementType: "input",
          elementConfig: {
            type: "password",
            placeholder: "•••••••",
          },
          show: true,
          value: "",
          fieldName: "Password",
          label: "Password",
          validation: {
            required: true,
          },
          valid: false,
          touched: true,
        },
        is_sale_rep: {
          elementType: "select",
          elementConfig: {
            type: "select",
            placeholder: "Sales Representative",
          },
          show: true,
          value: "",
          fieldName: "SalesRepresentative",
          label: "Are you a Sales Representative adding an Agent?",
          validation: {
            required: false,
            isEmail: false,
          },
          valid: false,
          touched: true,
          options: SALES_REPRESENTATIVE,
        },
        primary_sale_representative: {
          elementName: "primarySaleRep",
          elementType: "select",
          elementConfig: {
            type: "select",
            placeholder: "Select Representative",
          },
          show: false,
          value: "",
          fieldName: "PrimarySaleRep",
          label: "Primary Sales Representative",
          validation: {
            required: false,
            isEmail: false,
          },
          valid: true,
          touched: true,
          options: SALES_REPRESENTATIVE_OPTIONS,
        },
        secondary_sale_representative: {
          elementName: "secondarySaleRep",
          elementType: "select",
          elementConfig: {
            placeholder: "Select Representative",
          },
          show: false,
          value: "",
          fieldName: "SecondarySaleRep",
          label: "Secondary Sales Representative",
          validation: {
            required: false,
            isEmail: false,
          },
          valid: true,
          touched: true,
          options: SALES_REPRESENTATIVE_OPTIONS,
        },
        primary_sale_representative_split_percentage: {
          elementName: "primarySalesRepSplit",
          elementType: "input",
          elementConfig: {
            type: "number",
            placeholder: "%",
          },
          show: false,
          value: "",
          fieldName: "PrimarySaleRepSplit",
          label: "Primary Sales Representative Split",
          validation: {
            required: false,
            isAlphaNumeric: true,
          },
          valid: true,
          touched: true,
        },
        secondary_sale_representative_split_percentage: {
          elementName: "secondarySalesRepSplit",
          elementType: "input",
          elementConfig: {
            type: "number",
            placeholder: "%",
          },
          show: false,
          value: "",
          fieldName: "SecondarySaleRepSplit",
          label: "Secondary Sales Representative Split",
          validation: {
            required: false,
            isAlphaNumeric: true,
          },
          valid: true,
          touched: true,
        },
      },
      formIsValid: false,
      isSubmitted: false,
      showErrorState: false,
      phone: "",
      pageLoaded: false,
      city: "",
      state: "",
      countryCode: "1",
      isSaving: false,
      noLocationError: false,
    }
  }

  inputChangedHandler = (event, inputIdentifier, data) => {
    const {
      signupForm,
    } = this.state
    const { is_sale_rep, primary_sale_representative, secondary_sale_representative, primary_sale_representative_split_percentage, secondary_sale_representative_split_percentage } = signupForm
    const formObject = { ...signupForm }
    const extractedSignupForm = FormValidator(formObject, event, inputIdentifier, data)
    this.setState({
      signupForm: extractedSignupForm.formObject,
      formIsValid: extractedSignupForm.formIsValid,
    })
    if (is_sale_rep.value === 1) {
      this.setState({
        signupForm: {
          ...extractedSignupForm.formObject,
          primary_sale_representative: {
            ...primary_sale_representative,
            show: true,
          },
          secondary_sale_representative: {
            ...secondary_sale_representative,
            show: true,
          },
          primary_sale_representative_split_percentage: {
            ...primary_sale_representative_split_percentage,
            show: true,
          },
          secondary_sale_representative_split_percentage: {
            ...secondary_sale_representative_split_percentage,
            show: true,
          },
        },
      })
    } else if (is_sale_rep.value === 0) {
      this.setState({
        signupForm: {
          ...extractedSignupForm.formObject,
          primary_sale_representative: {
            ...primary_sale_representative,
            show: false,
            valid: true,
          },
          secondary_sale_representative: {
            ...secondary_sale_representative,
            show: false,
            valid: true,
          },
          primary_sale_representative_split_percentage: {
            ...primary_sale_representative_split_percentage,
            show: false,
            valid: true,
          },
          secondary_sale_representative_split_percentage: {
            ...secondary_sale_representative_split_percentage,
            show: false,
            valid: true,
          },
        },
      })
    }
  }

  onPhoneNumberChange = (event) => {
    const {
      target: {
        rawValue,
        rawValue: {
          length,
        },
      },
    } = event
    const errorLength = length <= 10
    this.setState({
      phone: rawValue,
      showErrorState: errorLength,
    })
  }

  showHidePassword = (event, innerObject) => {
    this.setState({ signupForm: ShowHidePassword(event, innerObject, this.state.signupForm) })
  }

  gotoCityServed = () => {
    const { history } = this.props
    history.push("/onboarding/tell-about-yourself")
  }

  redirectionFlow = (accountType, redirectUrl) => {
    const {
      history: {
        push,
      },
    } = this.props

    if (accountType === "real_estate_agent") {
      push("/onboarding/tell-about-yourself")
    } else if (redirectUrl) {
      window.location.href = redirectUrl
    } else {
      window.location.href = "/community"
    }
  }

  createAccount = (event) => {
    event.preventDefault()
    const {
      history,
    } = this.props

    this.setState({ isSubmitted: true })

    const pathname = root.location.search

    const {
      phone,
      countryCode,
      signupForm,
      formIsValid,
      city,
    } = this.state

    const accountType = signupForm.account_type.value
    // const salesRep = signupForm.admin_name && signupForm.admin_name.value
    // console.log("create", salesRep, signupForm.is_sale_rep)
    const data = {
      username: {
        firstname: signupForm.firstname.value,
        lastname: signupForm.lastname.value,
      },
      account_type: accountType || "real_estate_agent",
      email: signupForm.email.value,
      password: md5(signupForm.password.value),
      phone: countryCode + phone.length > 10 ? phone.substr(1, phone.length) : phone,
      is_sale_rep: signupForm.is_sale_rep.value,
      primary_sale_representative: (signupForm.is_sale_rep.value === 1 ? signupForm.primary_sale_representative.value : null),
      secondary_sale_representative: (signupForm.is_sale_rep.value === 1 ? signupForm.secondary_sale_representative.value : null),
      primary_sale_representative_split_percentage: (signupForm.is_sale_rep.value === 1 ? Number(signupForm.primary_sale_representative_split_percentage.value) : null),
      secondary_sale_representative_split_percentage: (signupForm.is_sale_rep.value === 1 ? Number(signupForm.secondary_sale_representative_split_percentage.value) : null),
      signup_source:
        UrlSearchParams(pathname, "signup_source") !== null ? UrlSearchParams(pathname, "signup_source") : undefined,
    }

    if (accountType === "other") {
      data.is_other_account_type = true
    }

    if (!city) {
      this.setState({ noLocationError: true })
      toast.error("Please provide city")
      return
    }

    if (formIsValid) {
      if (phone.length >= 10) {
        this.setState({ isSaving: true })
        axios.post(`${API_V2_URL}user/sign-up`, data).then(
          (result) => {
            const {
              data: {
                response,
              },
            } = result || {}

            const {
              user: userDetail,
            } = response || {}

            const {
              id,
            } = userDetail || {}

            axios
              .post(
                `${API_V2_URL}appDownloadSms`,
                { phone: countryCode + phone.length > 10 ? phone.substr(1, phone.length) : phone },
                { headers: { "secure-id": result.data.response.secure_id } },
              )
              .then(
                () => {
                  this.setState({ isSaving: false })
                  axios.post(`${API_URL}user/cities-neighbourhoods-served`, city, {
                    headers: {
                      "secure-id": result.data.response.secure_id,
                    },
                  }).then(() => {
                    root.location.href = `/broker/dashboard/${id}/subscription`
                  })
                },
                (error) => {
                  console.error(error)
                  this.setState({ isSaving: false })
                },
              )
          },
          (error) => {
            toast.error(error?.data?.error?.message)
            console.error("This email id is already associated with an existing account!", error)
            this.setState({ isSaving: false })
          },
        )
      } else {
        this.setState({ showErrorState: true })
      }
    }
  }

  componentDidMount() {
    this.setState({
      pageLoaded: true,
    })
  }

  render() {
    const formElementsArray = []
    const {
      showErrorState,
      pageLoaded,
      isSaving,
      isSubmitted,
      signupForm,
      phone,
      city,
      state,
      noLocationError,
    } = this.state

    const {
      formIsValid,
    } = signupForm || {}

    let errorState = ""

    const errorLocation = "No City Provided"

    for (let key in signupForm) {
      if (signupForm[key] && signupForm[key].fieldName.indexOf("Password") > -1) {
        formElementsArray.push({
          id: key,
          config: signupForm[key],
          eyeIcon: (
            <StyledFA
              onClick={event => this.showHidePassword(event, signupForm[key])}
              className={
                signupForm[key].elementConfig.type === "password" ? "fas fa-eye" : "fas fa-eye-slash"
              }
            />
          ),
        })
      } else {
        formElementsArray.push({
          id: key,
          config: signupForm[key],
        })
      }
    }
    if (isSubmitted && showErrorState) {
      errorState = <ErrorText>Phone number is required</ErrorText>
    }
    const user = cookie.load("user")

    return (
      <Wrap>
        <DescriptionWrap>
          <h1>Create an Agent Account</h1>
        </DescriptionWrap>
        <FormElement onSubmit={this.createAccount}>
          {formElementsArray.map(formElement => (
            <>
              {formElement.config.show && (
              <>
                <FormRelative key={formElement.id}>
                  <Input
                    key={formElement.id}
                    label={formElement.config.label}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    errorType={formElement.config.errorType}
                    fieldName={formElement.config.fieldName}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    submitted={isSubmitted}
                    required={formElement.config.validation.required}
                    changed={(event, data) => this.inputChangedHandler(event, formElement.id, data)}
                    options={formElement.config.options}
                  />
                  <EyeIcon>
                    {formElement.eyeIcon}
                  </EyeIcon>
                </FormRelative>
              </>
              )}
            </>
          ))}
          {pageLoaded && (
            <PhoneWrapper>
              <PhoneNumber
                placeholder="xxx xxx xxxx"
                onChange={this.onPhoneNumberChange}
                label="Phone Number"
                value={phone}
                required
              />
              {errorState}
            </PhoneWrapper>
          )}
          {pageLoaded && (
            <GoogleLocationWrap>
              <p>City Served</p>
              <GoogleLocation
                type="cities"
                value={city}
                placeholder={city || "City"}
                location={(location) => {
                  const selectedCity = {
                    city: get(location, "locality.long_name"),
                    state: get(location, "administrative_area_level_1.long_name"),
                    country: get(location, "country.long_name"),
                    latitude: get(location, "lat"),
                    longitude: get(location, "lng"),
                  }
                  this.setState({
                    city: selectedCity,
                  })
                }}
              />
              {errorLocation && noLocationError && (
                <ErrorText
                  style={{
                    marginTop: "-15px",
                  }}
                >
                  {errorLocation}
                </ErrorText>
              )}
            </GoogleLocationWrap>
          )}
          <SignUpBox>
            <Create
              width="100%"
              type="submit"
              bsStyle="primary"
              disabled={isSaving}
              boxShadow
            >
              {isSaving ? "Creating Agent Account..." : "Create Account"}
            </Create>
          </SignUpBox>
        </FormElement>
        <ToastContainer />
      </Wrap>
    )
  }
}

export default withRouter(SignUpForm)
