import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`

const Label = styled.label`
  color: #A3A3A3;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  margin-bottom: 10px;
  height: 16px;
  width: 220px;

  ${props => props.fullWidth && `
    width: 100%;
  `}
  ${props => props.fullHeight && `
    height: auto;
  `}
`

const Input = styled.input`
  background: #F9F8FF;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  width: 100%;

  ${props => props.disabled && `
    opacity: 0.4;
    pointer-events: none;
  `}

  ${props => props.isError && `
    border: 1px solid red;
  `}
`

const SymbolWrap = styled.div`
  border: 1px solid #F9F8FF;
  border-radius: 6px;
  position: absolute;
  bottom: 8px;
  right: 10px;
`

const InputWrap = ({
  label,
  isError,
  isHideLabel,
  fullWidth,
  fullHeight,
  typeSymbol,
  ...props
}) => (
  <Wrap>
    {!isHideLabel && (
      <Label fullWidth={fullWidth} fullHeight={fullHeight}>
        {label}
      </Label>
    )}

    <Input isError={isError} {...props} />

    {typeSymbol && (
      <SymbolWrap>
        {typeSymbol}
      </SymbolWrap>
    )}
  </Wrap>
)

export default InputWrap
