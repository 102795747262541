import React from 'react';

export const receiverExpertiseOptions = [
    {
        label: "Select Receiver Expertise",
        value: "",
    },
    {
        label: "Affordable Housing",
        value: "Affordable Housing",
    },
    {
        label: "Appraisal",
        value: "Appraisal",
    },
    {
        label: "Foreclosures",
        value: "Foreclosures",
    },
    {
        label: "First time home buyers",
        value: "First time home buyers",
    },
    {
        label: "Luxury home sales",
        value: "Luxury home sales",
    },
    {
        label: "Relocation",
        value: "Relocation",
    },
];

export const clientConnectOptions = [
    { label: "Select Client Connect", value: "" },
    { label: "Friend or family",    value: "Friend or family" },
    { label: "Current client",      value: "Current client" },
    { label: "Past client",         value: "Past client" },
    { label: "Never contacted",     value: "Never contacted" },
    { label: "Other",               value: "Other" },
];

export function clientConnectDivOptions() {
    let optionsDiv = clientConnectOptions.map(option => {
        return <option key={option.label} value={option.value}>{option.label}</option>
    });
    return optionsDiv;
};

export function receiverExpertiseDivOptions() {
    let optionsDiv = receiverExpertiseOptions.map(option => {
        return <option key={option.label} value={option.value}>{option.label}</option>
    });
    return optionsDiv;
};