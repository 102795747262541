import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as Actions from "./actions"

function mapStateToProps(state) {
  const { aiDashboard } = state
  return {
    ...aiDashboard,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      submitQueryToChat: Actions.submitQueryToChatAction.request,
      toggleAIInChat: Actions.toggleAIinChatAction.request,
      markAsResolved: Actions.markAsResolvedAction.request,
      fetchAiChatSession: Actions.fetchAIChatSessionsAction.request,
      fetchAIAnalytics: Actions.fetchAIAnalyticsAction.request,
      fetchSessionHistory: Actions.fetchSessionHistoryAction.request,
      fetchAgent: Actions.fetchAgentAction.request,
      fetchTeams: Actions.fetchTeamsAction.request,
      constructQuery: Actions.constructQueryAction.call,
      setCurrentSessionsArray: Actions.setCurrentSessionsArrayAction.call,
      updateCurrentChatView: Actions.setCurrentChatForDetailsViewAction.call,
      fetchAgentChatSession: Actions.fetchAgentChatSessionAction.request,
      handlePubNubEvent: Actions.handlePubNubEventAction.call,
      handleNewAgentPubNub: Actions.handleNewAgentPubNubAction.call,
      fetchTrainedDocuments: Actions.fetchTrainedDocumentsAction.request,
      deleteTrainedDocuments: Actions.deleteTrainedDocumentsAction.request,
      toggleTrainingFormModal: Actions.toggleTrainingFormModalAction.call,
      uploadLinkToFile: Actions.uploadLinkToFileAction.request,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
