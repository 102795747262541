import React from "react"
import styled from "styled-components"
import {
  Input,
} from "antd"

const Wrap = styled.div``

const ReasonConfirmationModal = (props) => {
  const {
    onChange,
  } = props

  return (
    <Wrap>
      <p>Reason:</p>
      <Input name="reason" onChange={onChange} />
    </Wrap>
  )
}

export default ReasonConfirmationModal
