import fetch from 'isomorphic-fetch'
import Cookies from 'universal-cookie';

const secretId = process.env.REACT_APP_SECRET_ID;
const cookies = new Cookies();

function isSuccess(res) {
    return res.status >=200 && res.status <= 300
}

export function Fetch(url, options) {

    let user = cookies.get('user');
    let blinkUserId = user ? user.agent_id : "blink";

    return fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'internal-id': secretId,
            'X-User-Agent': 'agent-php',
            'blink-user-id': blinkUserId
        }, ...options}).then(response =>
        response.json().then(data => ({
                data: data,
                status: response.status
            })
        ).then(res => {
            if (isSuccess(res)) {
                return res.data;
            }
            throw res.data
        }));
}