import React, { Component } from 'react';
import styled from "styled-components"
import EditorJs from "react-editor-js";
import Container from "container/Academy"
import { EDITOR_JS_TOOLS } from "./EditorConstants";

const editor = new EditorJs();

const Wrap = styled.div`
 height: max-content;
 .title {
  font-size: 14px;
  font-weight: bold;
  color: #24292e;
  span {
   color: red;
  }
 }
 .sub {
  font-size: 12px;
  color: #586069;
  margin-bottom: 27px;
 }
 .codex-editor {
  z-index: 0;
 }
 .codex-editor__redactor {
   border: 1px solid #e3e4e8;
   padding: 8px;
   min-height: ${props => props.customMinHeight ? `${props.customMinHeight}px` : "450px"};
   margin-bottom: ${props => props.customMarginBottom ? `${props.customMarginBottom}px` : "0"};
   max-height: ${props => props.customMaxHeight ? `${props.customMaxHeight}px` : ""};
   overflow: scroll;
 }
`


class RTFEditorIO extends React.Component {
 state = {
  editorState: "",
  currentBase: "",
 }
 componentDidMount() {
 }

 onChange = (val, e) => {
  const {
   setRTFValue,
   contentType,
  } = this.props

  this.editorInstance.save().then((outputData) => {
  setRTFValue({
   data: btoa(unescape(encodeURIComponent(JSON.stringify(outputData)))),
   type: contentType,
  })
  }).catch((error) => {
    console.log('Saving failed: ', error)
  });
  this.setState({
   editorState: val,
  })
 }
 decode = (rtfContent) => {
  try {
   return JSON.parse(decodeURIComponent(escape(atob(rtfContent))))
  } catch (e) {
   //do nothing
  }
 }

 render() {
  const {
   contentTitle,
   contentType,
   contentSub,
   rtfContent,
   customMinHeight,
   customMarginBottom,
   customMaxHeight,
  } = this.props
  return(
   <Wrap
    customMinHeight={customMinHeight}
    customMarginBottom={customMarginBottom}
    customMaxHeight={customMaxHeight}
   >
    {contentTitle && (
      <p className="title">
      {contentTitle}
       <span className="important">*</span>
      </p>
    )}
    <EditorJs
       holder={contentType}
       tools={EDITOR_JS_TOOLS}
       onChange={this.onChange}
       data={rtfContent && this.decode(rtfContent) || this.state.editorState}
       instanceRef={instance => this.editorInstance = instance}
    >
      <div id={contentType} />
    </EditorJs>
    {contentSub && (
     <p className="sub">{contentSub}</p>
    )}
   </Wrap>
  )
 }
}


export default Container(RTFEditorIO)
