import React from "react"

const Button = ({
  style,
  ...props
}) => (
  <button
    style={{
      background: "#11adf3",
      padding: "20px 30px",
      border: "none",
      color: "#FFF",
      borderRadius: "#FFF",
      borderRadius: 4,
      fontWeight: 100,
      ...style,
    }}
    {...props}
  >
    
  </button>
)

export default Button
