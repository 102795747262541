import React, { useState, useEffect } from "react";
import { Select, Button, Space } from "antd";
import CheckList from "container/CheckList/index";
import "./styles.css";
import ChecklistDocumentAccordion from "./ChecklistDocumentAccordion";
import { PlusOutlined } from "@ant-design/icons";
import AddChecklistTemplateModal from "./AddChecklistTemplateModal";
import NoTemplatesFound from "./NoTemplatesFound";
const ChecklistDocument = ({
  fetchChecklistMetadataResponse,
  fetchStatesMetadataResponse,
  fetchStatesMetadata,
  fetchChecklistMetadata,
  fetchTypeMetadata,
  fetchTypeMetadataResponse,
  fetchAllTemplates,
  addNewChecklistTemplateResponse,
  fetchAllChecklistDataResponse,
  fetchAllChecklistData,
  fetchAllTemplatesResponse,
  dropdownData,
}) => {
  const [localState, setLocalState] = useState("");
  const [localType, setLocalType] = useState("");
  const [localChecklist, setLocalChecklist] = useState("");
    const [addNewChecklist, setAddNewChecklist] = useState(false);

  const [isAddChecklistModalOpen, setIsAddChecklistModalOpen] = useState(false);

  useEffect(() => {
    if (!localState && !localType) {
      fetchStatesMetadata();
      fetchTypeMetadata();
    }
  }, []);

  useEffect(() => {
    setLocalChecklist('');
  },[addNewChecklistTemplateResponse])

  const fetchChecklistTemplates = (state, type) => {
    setLocalType(type);
    const payload = {
      state: state,
      type: type,
    };
    dropdownData({
      state: state,
      type: type,
      checklistTemplate: null,
    });
    setLocalChecklist("");
    fetchChecklistMetadata(payload);
  };

  const fetchAllTemplatesData = (value) => {
    const checklist = fetchChecklistMetadataResponse?.data?.find(
      (e) => e.checklist_template_value === value
    );
    const payload = {
      state: localState,
      type: localType,
      checklistTemplate: checklist?.checklist_template_value,
    };

    setLocalChecklist(checklist);
    dropdownData({
      state: localState,
      type: localType,
      checklistTemplate: checklist,
    });
    fetchAllTemplates(payload);
    fetchAllChecklistData(payload);
  };

  const openAddNewChecklistTemplate = () => {
    setIsAddChecklistModalOpen(true);
    setAddNewChecklist(true)
  };

  const closeModal = () => {
    setIsAddChecklistModalOpen(false);
  };
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <>
      <div className="container">
        <div className="">
          <h1>Checklists</h1>
          <h5>
            A comprehensive guide to essential documents for seamless real
            estate transactions.
          </h5>
        </div>
        <div className="content-wrapper">
          <div className="dropdown-section">
            <div>
              <h4>State</h4>
              {!fetchStatesMetadataResponse?.isFetching ? (
                <Select
                  className="dropdown-state"
                  placeholder="Select State"
                  style={{ width: 300, marginTop: 0 }}
                  onChange={(value) => {
                    setLocalState(value);
                    if (localType) {
                      fetchChecklistTemplates(value, localType);
                    }
                  }}
                  options={
                    fetchStatesMetadataResponse?.data?.length > 0 &&
                    fetchStatesMetadataResponse?.data?.map((state) => ({
                      value: state,
                      label: state,
                    }))
                  }
                />
              ) : (
                <div
                  className="loading-dropdown"
                  style={{ width: 300, marginTop: 0 }}
                >
                  Loading...
                </div>
              )}
            </div>

            <div>
              <h4 style={{ marginLeft: 20 }}>Type</h4>
              {!fetchTypeMetadataResponse?.isFetching ? (
                <Select
                  placeholder="Select Type"
                  className="dropdown-state"
                  style={{ width: 300, marginLeft: 20, marginTop: 0 }}
                  onChange={(value) =>
                    fetchChecklistTemplates(localState, value)
                  }
                  options={
                    fetchTypeMetadataResponse?.data?.length > 0 &&
                    fetchTypeMetadataResponse?.data?.map((type) => ({
                      value: type,
                      label: capitalize(type),
                    }))
                  }
                />
              ) : (
                <div
                  className="loading-dropdown"
                  style={{ width: 300, marginLeft: 20, marginTop: 0 }}
                >
                  Loading...
                </div>
              )}
            </div>

            <div>
              <h4 style={{ marginLeft: 20 }}>Checklist Templates</h4>

              {!fetchChecklistMetadataResponse?.isFetching ? (
                <Select
                  placeholder="Select Checklist Template"
                  className="dropdown-state"
                  style={{ width: 300, marginLeft: 20, marginTop: 0 }}
                  disabled={!localState || !localType}
                  onChange={fetchAllTemplatesData}
                  dropdownRender={(menu) => (
                    <>
                      {menu}

                      <Space style={{ padding: "0 8px 4px" }}>
                        <button
                          style={{
                            color: "white",
                            width: "280px",
                          }}
                          className="add-due-btn add-due-btn-color"
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={openAddNewChecklistTemplate}
                        >
                          Create New
                        </button>
                      </Space>
                    </>
                  )}
                  options={
                    fetchChecklistMetadataResponse?.data?.length > 0 &&
                    fetchChecklistMetadataResponse?.data?.map((checklist) => ({
                      value: checklist.checklist_template_value,
                      label: checklist.checklist_template,
                    }))
                  }
                />
              ) : (
                <div
                  className="loading-dropdown"
                  style={{ width: 300, marginLeft: 20, marginTop: 0 }}
                >
                  Loading...
                </div>
              )}
            </div>
          </div>
        
          <>
            
            {localState && localType && localChecklist && (
              <>
                {fetchAllChecklistDataResponse?.isFetching ? (
                  <div className="loading">Loading...</div>
                ) : (
                    <>
                      {localState && localType && localChecklist && (Object.keys(fetchAllTemplatesResponse?.data || {}).length === 0 && !Array.isArray(fetchAllTemplatesResponse.data)) ?
                        <NoTemplatesFound setIsAddChecklistModalOpen={setIsAddChecklistModalOpen} setAddNewChecklist={ setAddNewChecklist} /> : null
                      }
                      <ChecklistDocumentAccordion />
                      </>
                )}
              </>
            )}
          </>
        </div>
   
        {isAddChecklistModalOpen && (
          <AddChecklistTemplateModal
            isAddChecklistModalOpen={isAddChecklistModalOpen}
            state={localState}
            type={localType}
            isAddingNew = {addNewChecklist}
            checklistTemplate={!addNewChecklist ? localChecklist : null}
            onCancel={closeModal}
          />
        )}
      </div>
    </>
  );
};

export default CheckList(ChecklistDocument);
