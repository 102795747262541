import React, { useState } from "react"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import { Modal } from "antd"
import copyToClipBoard from "copy-to-clipboard"
import {
  WhiteButton,
} from "../../commonStyles"

const CopyOnboardingURL = ({
  disabled,
  copyOnboardingURLResponse,
  copyOnboardingURL,
  currentAgentIDViewed,
}) => {
  const {
    data: fetchedUrl,
    isFetching: fetchingURL,
  } = copyOnboardingURLResponse || {}

  const [copyState, setCopyState] = useState(false)

  return (
    <WhiteButton
      onClick={() => {
        if (!fetchedUrl) {
          copyOnboardingURL({
            agentId: currentAgentIDViewed,
          })
          setCopyState(true)

          Modal.success({
            content: "Onboarding URL Copied",
          })

          setTimeout(() => {
            setCopyState(false)
          }, 2000)
        } else {
          copyToClipBoard(fetchedUrl.url)

          setCopyState(true)

          Modal.success({
            content: "Onboarding URL Copied",
          })

          setTimeout(() => {
            setCopyState(false)
          }, 2000)
        }
      }}
      ATCFONT
      disabled={disabled}
      borderRadius="33.5px"
      style={{
        padding: "7px 14px",
      }}
    >
      {fetchedUrl ? (
        <>
          {copyState ? "Copied!" : "Copy URL"}
        </>
      ) : (
        <>
          { fetchingURL ? "Fetching URL... " : "Copy Onboarding URL"}
        </>
      )}
    </WhiteButton>
  )
}

export default subscriptionContainer(CopyOnboardingURL)

