import React, { useEffect } from "react"
import styled from "styled-components"
import SearchControls from "../Common/SearchControls"
import ResultsTable from "./ReferralsComponents/Table"
import RecruitAndEarn from "../../../container/RecruitAndEarn"
import InvitedDrawer from "./ReferralsComponents/InvitedDrawer"
import AgentInfoDrawer from "../Common/AgentInfoDrawer"

const Wrap = styled.div``

const Referrals = (props) => {
  const {
    fetchReferrals,
    fetchReferralListResponse,
  } = props

  useEffect(() => {
    fetchReferrals({
      queryParams: "skip=0&limit=300",
    })
  }, [])

  const {
    isFetching,
    data: referralList,
  } = fetchReferralListResponse || {}

  return (
    <Wrap>
      <SearchControls showStatusFilter />
      <ResultsTable
        referralList={referralList}
        fetchingList={isFetching}
      />
      <InvitedDrawer />
      <AgentInfoDrawer />
    </Wrap>
  )
}

export default RecruitAndEarn(Referrals)
