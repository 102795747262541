import React, { Component } from 'react';
import _ from 'lodash';
import bugsnag from 'bugsnag-js';
import Popup from "reactjs-popup";
import ComparingWizard from "./ComparingAgent/ComparingWizard/ComparingWizard";
import fetch from 'isomorphic-fetch';
import "./ComparingAgent/ComparingAgent.css";
import Map  from "../../Map";
import "./Comparing.scss";
import {Fetch} from "../../../lib/NetworkUtils";
import {ShowErrorToast, ShowSuccessToast} from "../../../lib/ErrorUtils";
import {DefaultLoader} from "../../UI/Loader/Loader";

const baseUrl =process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;
const originalKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagkey = 'duplicateKey';
const bugsnagClient = bugsnag(originalKey);
const popupstyle =  { overflow: 'auto', width: '80%'};
const labelStyle =  { color: '#737373'};

class Comparing extends Component {
  state = {
    loading: false,
    agentIds: [],
    assignedAgents: [],
    agents: [],
    primaryAgentId: null,
    agentProfile: null,
  };

  componentWillReceiveProps = (props) => {
    this.loadAssignedAgents(props.assignedAgents || [], props.assignedAgentObjects || []);
    this.setState({primaryAgentId: props.referral.primary_agent_id, referral: props.referral})
  };

  componentDidMount = () => {
    this.loadAssignedAgents(this.props.assignedAgents || [], this.props.assignedAgentObjects || []);
    this.setState({primaryAgentId: this.props.referral.primary_agent_id, referral: this.props.referral});
  };

  startLoader = () => {
    this.setState({loading: true})
  };

  stopLoader = () => {
    this.setState({loading: false})
  };

  loadAssignedAgents = async (agentIds, agentMap) => {
    this.startLoader();
    const self = this;
    agentMap = _.groupBy(agentMap, 'id');
    let skeleton = null;

    try {
      skeleton = await fetch(baseUrl + '/v2.0/refer/filter-skeleton', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token
        },
      });
      skeleton = await skeleton.json();
      skeleton = skeleton.response;
    } catch(e) {
      this.stopLoader();
      bugsnagClient.notify(e);
    }

    _.each(agentIds,
      async (agentId) => {
        try {
          let sol = await fetch(baseUrl + '/v2.0/user/secure_id?getAgentDetails=true&agentId=' + agentId, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'internal-id': token
            },
          });
          sol = await sol.json();
          let clientDetails = await fetch(baseUrl + '/v2.0/clients/'+ agentMap[agentId][0].receiver_client_id, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'secure-id': sol.response.secure_id
            },
          });
          clientDetails = await clientDetails.json();
          agentMap[agentId][0].client = clientDetails.response;
          agentMap[agentId][0].client.inboundReferralStatuses = skeleton.inboundReferralStatuses;
          if (self.props.referral.type === "Buyer" || self.props.referral.type === "Renter"){
            if (!agentMap[agentId][0].client["sales"]["Buyer checklist"]){
              agentMap[agentId][0].client["sales"]["Buyer checklist"] = skeleton.inboundBuyerChecklist;
            }
          }
          else {
            if (!agentMap[agentId][0].client["sales"]["Seller checklist"]){
                agentMap[agentId][0].client["sales"]["Seller checklist"] = skeleton.inboundSellerChecklist;
            }
          }

          agentMap[agentId][0].agent_image = sol.response.agent.agent_image;

          let imageUrl = null;
          const images = _.filter(agentMap[agentId][0].images,
            (img) => {
              if (img.type === 'profile') {
                return true;
              }
            }
          );
          if (images.length > 0) {
            imageUrl = images[0]['path'];
          }
          agentMap[agentId][0].imageUrl = imageUrl;

          let agents = self.state.agents;
          agents.push(agentMap[agentId][0]);
          agents = _.uniq(agents);
          self.setState({agents: agents});
        }
        catch(e) {
          this.stopLoader();
         bugsnagClient.notify(e);
        }
      }
    );
    this.stopLoader();
  };

  makePrimary = async (agentId, clientId) => {
    try {
      let body = {
        primary_agent_id: agentId,
        consultant_agent_id: this.props.user.agent_id,
       };
       this.setState({primaryAgentId: agentId});
       let url = baseUrl + '/v1.0/consumers/referral/' + this.state.referral.id;
       let sol = await fetch( url, {
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'internal-id': token,
          },
         body: JSON.stringify(body)
       });
        this.props.togglePrimaryAgentFromChild(agentId, clientId, 'add');
    }
    catch(e){
      bugsnagClient.notify(e);
    }

  };

  //resend the notification to agent if rejected/referred out
  resendProspectNotification = async () => {
    let prospectBody = {
      referral_id: this.state.referral.id,
      referral_type: "isa",
      prospect_status: 0,
      resend_notification: true,
    };
    await Fetch( baseUrl +'/v2.0/prospects/' + this.state.referral.sender_id, {
      method: 'PUT',
      body: JSON.stringify(prospectBody)
    }).then(respJson => {
      ShowSuccessToast("Success!. Reload the page again to see the updated status");
    }).catch(e => {
      ShowErrorToast(e);
    });
  };

  connect = async () => {
    //Update lead prospect, reject it and refer it out.
    let prospectBody = {
      referral_id: this.state.referral.id,
      referral_type: "isa",
      prospect_status: 1,
    };
    let prospectResponse = await fetch( baseUrl +'/v2.0/prospects/' + this.state.referral.sender_id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'internal-id': token,
      },
      body: JSON.stringify(prospectBody)
    });
      this.props.setUpStage(5);
  };

  remove = async (id, notify = true) => {
    try{

      //remove agent first
      let body = {
        agentIdsToRemove: [id],
        referral_id: this.state.referral.id,
      };
      let sol = await fetch( baseUrl +'/v1.0/consumer/' + this.state.referral.receiver_client_id, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
        },
        body: JSON.stringify(body)
      });
        let agents = _.uniq(this.state.agents);


      agents = _.reject(agents, (agent) =>  {
        return agent.id === id}
      );
      this.setState({agents: agents});


      //Update lead prospect, reject it and refer it out.
      let prospectBody = {
        referral_id: this.state.referral.id,
        referral_type: "isa",
        prospect_status: 2,
        notify: notify
      };
      let prospectResponse = await fetch( baseUrl +'/v2.0/prospects/' + this.state.referral.sender_id, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
        },
        body: JSON.stringify(prospectBody)
      });
        //if primary agent, update state in other components
      // probably a bad way to handle state - will redux fix this?
      if (this.state.primaryAgentId === id) {
        this.props.togglePrimaryAgentFromChild(id, null, 'remove');
        if (this.state.referral.is_isa === 1) {
          let url = baseUrl + '/v1.0/consumers/referral/' + this.state.referral.id;
          let body = {
            referred_client_status: "Qualified",
            consultant_agent_id: this.props.user.agent_id,
          };

          let sol = await fetch(url, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'secure-id': token
            },
            body: JSON.stringify(body)
          });
            this.props.setUpStage('Qualified');
        }
      }

    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

  openAgentProfile = async (agent) => {
    let sol = await fetch( baseUrl + '/v1.0/user/' + agent.id + '/property-metrics', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'internal-id': token,
       },
    });
    sol = await sol.json();
    this.setState({agentProfile: sol.response});
  };

  updatechecklist = async (agent, currentStage, key, currentIndex) => {
    try {
      let client = agent.client;
      const sales = "sales"
      const currentAgentType = client.types[0]
      const filterKey = currentAgentType === "Buyer" ? "BuyerChecklistV2" : "SellerChecklistV2"
      const currentKeyDetails = client[sales][filterKey].filter(key => key.sales_stage === currentStage)
      const currentDateValue = currentKeyDetails && currentKeyDetails.length > 0 && currentKeyDetails[0].checklists[currentIndex].date


      if(currentDateValue === 0) {
        client[sales][filterKey].filter(key => key.sales_stage === currentStage)[0].checklists[currentIndex].date = new Date().getTime()
      } else {
        client[sales][filterKey].filter(key => key.sales_stage === currentStage)[0].checklists[currentIndex].date = 0
      }
      client[sales][filterKey].filter(key => key.sales_stage === currentStage)[0].checklists[currentIndex].agent_id = this.props.user.agent_id

      let agents = this.state.agents;
      _.each(agents, (currentAgent) => {
        if (agent.id === currentAgent.id) {
          currentAgent.client = client;
        }
      });

      let sol = await fetch(baseUrl + '/v2.0/user/secure_id?getAgentDetails=true&agentId=' + agent.id, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token
        },
      });
      sol = await sol.json();

      let body = {
          sales : client['sales'],
          types: client.types,
      };
      let clientDetails = await fetch(baseUrl + '/v2.0/clients/'+ client.id, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'secure-id': sol.response.secure_id
        },
        body: JSON.stringify(body)
      });
        this.setState({agents : agents});
    }
    catch(e) {
      console.log(e)
      bugsnagClient.notify(e);
    }

  };

  updateSalesStage = async (client, stage, agent) => {
    try {
      let client = agent.client;
      client['sales']['Current stage'] = stage;
      let agents = this.state.agents;
      _.each(agents, (currentAgent) => {
        if (agent.id === currentAgent.id) {
          currentAgent.client = client;
        }
      });

      let sol = await fetch(baseUrl + '/v2.0/user/secure_id?getAgentDetails=true&agentId=' + agent.id, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
        },
      });
      sol = await sol.json();

      let body = {
          sales : client['sales'],
          types: client.types,
      };
      let clientDetails = await fetch(baseUrl + '/v2.0/clients/'+ client.id, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'secure-id': sol.response.secure_id,
        },
        body: JSON.stringify(body)
      });
        this.setState({agents : agents});
    }
    catch(e) {
      bugsnagClient.notify(e);
    }
  };

  render() {
    let agents = [];
    try {
        agents = this.state.agents.map((agent) => {

        let prospectStatus  = agent.prospect_status;
        let prospectStatusText = "Notification sent";
        if(prospectStatus === 1) {
          prospectStatusText = "Accepted";
        } else if (prospectStatus === 2) {
          prospectStatusText = "Declined/Referred out";
        }
        let client = agent.client;
        let agentProfile = null;
        let currentStage = client['sales']['Current stage'];
        let checkList = [];
        let checklistdiv = [];
        if (this.state.agentProfile && this.state.agentProfile.metrics) {
          agentProfile = this.state.agentProfile.metrics.map((metric) => {
            return <tr>
              <td>{metric.span}</td>
              <td> {metric.buyer_active_properties + ' '}Buyers, {metric.buyer_closed_properties + ' '} Sellers</td>
              <td> {metric.seller_active_properties + ' '}Buyers, {metric.seller_closed_properties + ' '} Sellers</td>
              <td> {metric.min_budget}</td>
              <td> {metric.max_budget}</td>
              <td> {metric.average_budget}</td>
            </tr>
          });
        }

        if (client.types && client.types[0] === 'Seller') {
          if(client['sales'] && client['sales']['Seller checklist']) {
            checkList = client['sales']['SellerChecklistV2'] && client['sales']['SellerChecklistV2'].filter(key => key.sales_stage === currentStage)[0].checklists || []
          }
        }
        else {
          if (client['sales'] && client['sales']['Buyer checklist']) {
            checkList = client['sales']['BuyerChecklistV2'] && client['sales']['BuyerChecklistV2'].filter(key => key.sales_stage === currentStage)[0].checklists || []
          }
        }


        if(checkList && checkList.length > 0) {
          checkList.forEach((key, iter) => {
            checklistdiv.push(
              <div className="details">
                <Popup
                      trigger={
                        <div>
                          <input type="checkbox" checked={checkList[iter].date !== 0}  />
                          <label style={labelStyle}>
                              {key.display_name}
                          </label>
                        </div>
                      }
                        modal
                        closeOnDocumentClick
                      >
                          {close => (
                              <div className="modal-2">
                              <div className="modal-header">
                                  <span className="modal-header-text">
                                      Are you sure you want to continue with this action?
                                  </span>
                              </div>
                              <div className="dialogue-box-footer">
                                  <button className="med-btn-1" onClick={() => { this.updatechecklist(agent, currentStage, key, iter); close();}}>
                                      YES
                                  </button>
                                  <button className="med-btn-1" onClick={()=> {close();}}>
                                      NO
                                  </button>
                              </div>
                        </div>

                          )}
                 </Popup>
                <div>
                  {checkList[iter].notes}
                </div>
              </div>
            )
          });
        }

        return <div className={"comparing-agent"}>
        <div className={"flex-align"}>
          <div className={"agent-info"}>
            <img
              src={
                agent.agent_image
              }
            />
          </div>
          <div className={"agent-name"}>

            <p>{(agent.firstname ? agent.firstname : ' ')+ ' ' + (agent.lastname ? agent.lastname : ' ')}
              <div className="agent-value" onClick={() => {this.openAgentProfile(agent)}}>
                     <Popup
                          trigger={
                            <span><i className="fas fa-info-circle"></i></span>
                          }
                            modal
                            closeOnDocumentClick
                            contentStyle={popupstyle}
                          >
                              {close => (
                                  <div className="modal-1">
                                  <div className="modal-header-1">
                                      <span className="modal-header-text-1">
                                        Deal Volumes and Listings
                                      </span>
                                  </div>
                                  <div>
                                      {agent.agent_image ?
                                            <img className="agent-avatar-2" src={agent.agent_image}/>
                                            :
                                            <img  className="agent-avatar-2" src ="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_fO8BlrOnXk250EEyOjWzEt0MiPNkxEKKYK6bYp8DOJOdhsvy4w" />
                                      }
                                      <div className= "agent-name-in-popup">
                                          {agent.firstname ?  (agent.firstname+ ' ') : ''} {agent.lastname ? agent.lastname : ''}
                                      </div>
                                  </div>
                                  <br/>
                                  <table>
                                        <tr>
                                            <th> Time Period </th>
                                            <th> {(this.state.agentProfile && this.state.agentProfile.totalActiveProperties ? this.state.agentProfile.totalActiveProperties : 0)+' '}Active Listings </th>
                                            <th>{(this.state.agentProfile && this.state.agentProfile.totalClosedProperties ? this.state.agentProfile.totalClosedProperties : 0)+' '}Closed Listings</th>
                                            <th>Minimum Budget</th>
                                            <th>Maximum Budget</th>
                                            <th>Average Budget</th>
                                        </tr>
                                        {agentProfile}
                                  </table>
                                  <br/>
                                  <br/>
                                  <br/>
                                  <br/>
                                      {agent &&
                                      <Map agentId= {agent.id}/>
                                      }
                                  <button className="med-btn-1" onClick={()=> {close();}}>
                                        Close
                                  </button>
                            </div>
                              )}
                    </Popup>
                </div>
              </p>

          </div>
          {this.state.referral.is_isa === 0 &&
            <div className={"primary-agent"}>
              {this.state.primaryAgentId !== agent.id ?
                <button onClick={() => this.makePrimary(agent.id, agent.receiver_client_id)}>SET AS PRIMARY AGENT</button>
                :
                <button onClick={() =>this.remove(agent.id)}>REMOVE AS PRIMARY AGENT</button>
              }
            </div>
          }
        </div>
        <div>
          <ComparingWizard
            client = {agent.client}
            agent = {agent}
            updateSalesStage = {this.updateSalesStage.bind(this)}
          />
        </div>
        {checklistdiv}
        <p className={"text-right"} onClick={() =>this.remove(agent.id)}>
            <a href={"javascript:void(0);"} className={"remove-agent"}>Remove Agent</a>
        </p>

        {this.state.referral && prospectStatus ?
          <div className={"prospect-status-text"}>
            <span>{prospectStatusText}</span>
          </div>
            :
          <div></div>
        }

        {this.state.referral && this.state.referral.is_isa === 1 && prospectStatus === 0 &&
        (<div>
          {!this.state.referral.is_mortgage && <Popup
                      trigger={
                        <button className="med-btn-1">
                            Reject
                         </button>
                      }
                        modal
                        closeOnDocumentClick
                      >
                          {close => (
                              <div className="modal-2">
                              <div className="modal-header">
                                  <span className="modal-header-text">
                                      Mark as a referral and moved to Qualified-Searching?
                                  </span>
                              </div>
                              <div className="dialogue-box-footer">
                                  <button className="med-btn-1" onClick={() => { this.remove(agent.id); close();}}>
                                      YES
                                  </button>
                                  <button className="med-btn-1" onClick={()=> {close();}}>
                                      CANCEL
                                  </button>
                              </div>
                        </div>

                          )}
                </Popup>}
                <Popup
                      trigger={
                        <button className="med-btn-1">
                            Accept
                        </button>
                      }
                        modal
                        closeOnDocumentClick
                      >
                          {close => (
                              <div className="modal-2">
                              <div className="modal-header">
                                  <span className="modal-header-text">
                                     Agent accepted: Move to connected?
                                  </span>
                              </div>
                              <div className="dialogue-box-footer">
                                  <button className="med-btn-1" onClick={() => { this.connect(agent.id); close();}}>
                                      YES
                                  </button>
                                  <button className="med-btn-1" onClick={()=> {close();}}>
                                      CANCEL
                                  </button>
                              </div>
                        </div>

                          )}
              </Popup>
            </div>)
        }

        {this.state.referral && prospectStatus === 2 &&
        <div>
          <Popup
              trigger={
                <button className="med-btn-1">
                  Resend
                </button>
              }
              modal
              closeOnDocumentClick
          >
            {close => (
                <div className="modal-2">
                  <div className="modal-header">
                                <span className="modal-header-text">
                                    Resend notification to agent to accept the lead?
                                </span>
                  </div>
                  <div className="dialogue-box-footer">
                    <button className="med-btn-1" onClick={() => { this.resendProspectNotification(agent.id); close();}}>
                      YES
                    </button>
                    <button className="med-btn-1" onClick={()=> {close();}}>
                      CANCEL
                    </button>
                  </div>
                </div>
            )}
          </Popup>
        </div>
        }

        </div>});

    }
    catch(e){
      bugsnagClient.notify(e);
    }

  return  (
      <div style={{ "flexGrow" : "4"}}>
          {this.state.loading === true ?
              <DefaultLoader height={0} width={0}/>
              :
              <div className={"comparing-agents"}>
                {agents}
              </div>
          }
      </div>
  );
  }
}
export default Comparing;
