import React from "react"
import styled from "styled-components"
import {
  Card,
  SectionTitle,
  CardV2,
  Badge,
  CLOUDFRONT,
  StatusText,
  StatusDiv,
  BadgeText,
  StatusMessage,
} from "../commonStyles"

const ComponentWrap = styled.div``

const Signed = () => (
  <ComponentWrap>
    <CardV2
      cardWidth="800px"
    >
      <StatusText>
        Status of the ICA
      </StatusText>
      <StatusDiv>
        <Badge
          bgColor="#8E24AA"
        >
          <img src="https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/SOUL_ISA_THUMBSUP.svg" alt="soul_asset" />
          <BadgeText>Signed</BadgeText>
        </Badge>
        <StatusMessage>
          The document has been signed by the Agent.
        </StatusMessage>
      </StatusDiv>
    </CardV2>
  </ComponentWrap>
)

export default Signed
