import React, { useState } from "react"
import { Drawer, Switch, Modal } from "antd"
import { toast } from "react-toastify"
import styled from "styled-components"
import axios from "axios"
import root from "window-or-global"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Loader from "dumbComponents/common/UI/Loader"
import AgentSearch from "./AgentSearch"
import ConfirmationNote from "../ConfirmationNote"

const { confirm } = Modal

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const DeleteButton = styled.button`
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
`

const Section = styled.div``

const PromotedWrap = styled.div``

const Label = styled.p`
  font-size: 24px;
`

const DownloadDrawer = ({
  downloadDrawerOpen,
  toggleDownloadDrawer,
  recordings,
  updateScheduledRooms,
  isUpdating,
  deleteScheduledRooms,
}) => {
  console.log(recordings)

  const [allInstanceBool, setAllInstanceBool] = useState(false)

  const handleClose = () => {
    toggleDownloadDrawer({
      bool: false,
      data: null,
    })
  }

  const handleChange = (checked) => {
    const payload = {
      ...recordings.originalData,
      is_promoted: checked ? 1 : 0,
    }
    updateScheduledRooms({
      payload,
    })
  }

  const handleHostChange = (id) => {
    const payload = {
      ...recordings.originalData,
      host_id: id,
    }
    updateScheduledRooms({
      payload,
    })

    handleClose()
  }

  const confirmDelete = () => {
    const payload = {
      ...recordings.originalData,
    }
    const isChecked = root.localStorage.getItem("deleteAllOccurences") === "true"
    deleteScheduledRooms({
      id: recordings.originalData.id,
      allInstance: isChecked ? "&instance-type=all-instances" : "&instance-type=single-instance",
      payload,
    })
  }

  const handleDelete = () => {
    confirm({
      title: "Do you want to delete this room?",
      icon: <ExclamationCircleOutlined />,
      content: <ConfirmationNote
        text="This is to delete only this scheduled room, if you wish to delete all its occurences (if any), select the option below"
        updateNote=""
        value=""
        deleteCheckBox
        updateAllInstance={setAllInstanceBool}
        allInstanceBool={allInstanceBool}
      />,
      onOk() {
        // handleHostChange(agent.id)
        confirmDelete()
      },
      onCancel() {

      },
    })
  }

  const isPromoted = recordings.promoted === 1

  return (
    <Drawer
      title="Scheduled Rooms Info"
      width={537}
      placement="right"
      onClose={handleClose}
      visible={downloadDrawerOpen}
    >
      {isUpdating ? (
        <Loader width="25px" top={0} bottom={0} />
      ) : (
        <Wrap>
          <Section>
            <PromotedWrap>
              <Label>Promoted?</Label>
              <Switch checkedChildren="Yes" unCheckedChildren="No" checked={isPromoted} onChange={handleChange} />
            </PromotedWrap>
          </Section>
          <Section>
            <PromotedWrap>
              <Label>Update Host</Label>
              <AgentSearch
                data={recordings}
                handleHostChange={handleHostChange}
              />
            </PromotedWrap>
          </Section>
          <Section>
            <PromotedWrap>
              <Label>Delete Scheduled Room?</Label>
              <DeleteButton onClick={handleDelete}>Delete Room?</DeleteButton>
            </PromotedWrap>
          </Section>
        </Wrap>
      )}
    </Drawer>
  )
}
export default DownloadDrawer
