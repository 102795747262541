import React from "react"
import { Pie } from "react-chartjs-2"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
// import { Pie } from "@ant-design/plots"
// import { DefaultizedPieValueType } from '@mui/x-charts';
// import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

ChartJS.register(ArcElement, Tooltip, Legend)

const PieCharts = ({
  data,
}) => (
  <div>
    <Pie data={data} />
  </div>
)

export default PieCharts

