import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import uuidv1 from 'uuid/v1';
import fetch from 'isomorphic-fetch';
import classnames from "classnames"
import bugsnag from 'bugsnag-js';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import {DebounceInput} from 'react-debounce-input';
import Notes from './Notes';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-dropdown-timepicker';
import $ from "jquery";
import * as qs from 'query-string';
import _, { parseInt, get } from 'lodash';
import Popup from "reactjs-popup";
import Notifications, { notify } from 'react-notify-toast';
import NotificationSystem from 'react-notification-system';
import { format, normalize } from "react-phone-input-auto-format";
import Cookies from 'universal-cookie';
import Wizard from './UI/Wizard/Wizard';
import CallHeader from "./CallHeader"
import ClientInfo from './CallerBoard/ClientInfo/ClientInfo';
import CallHistory  from "./CallerBoard/CallHistory/CallHistory";
import PanelContainer from "../container/PanelContainer/PanelContainer";
import Reminders from "./CallerBoard/Reminders/Reminders";
import Comparing from "./CallerBoard/Comparing/Comparing";
import Connected from "./CallerBoard/Connected/Connected";
import io from 'socket.io-client';
import NumberFormat from 'react-number-format';
import {Fetch} from "../lib/NetworkUtils";
import {
    NotifyBugsnagError,
    NotifyBugsnagInfo,
    ShowErrorToast,
    ShowErrorToastFromText,
    ShowSuccessToast,
    ShowErrorToastWithCustomMessage
} from "../lib/ErrorUtils";
import ClientModal from "./Client/ClientModal";
import QualifiedSearching from "./CallerBoard/QualifiedSearching/QualifiedSearching";
import AgentReqDetails from "./CallerBoard/ContactedQualifying/AgentReqDetails";
import AddNote from "./Notes/AddNote";
import { store } from "store/config"
import * as ZillowMergeActions from "container/ZillowLeadMerge/actions"
import Timeline from "dumbComponents/Timeline"
import ZillowMergeModal from "dumbComponents/ZillowMergeModal"
import getPreferencesDiv from "./Agent/SenderAgentPreferences";
import getAssistPreferencesDiv from "./Agent/AssistPreferencesDiv"
import * as MultiCallerActions from "container/MultipleCaller/actions"
import NotificationTimer from "../dumbComponents/UI/NotificationTimer";
import {callClient, updateTaskApi} from "./API/CallerApi";
import { ClickAwayListener } from '@material-ui/core';
import QualifyAndReferOut from "./CallerBoard/ClientInfo/QualifyAndReferOut";
import { Button } from "./CallHeader/common"
import ProfileDropdown from "./UI/ProfileDropdown"
import {
    assignUnAssignAgent,
    getAgentReferralSettings,
    markLeadPhoneNumberIncorrect,
    submitLead,
} from "./API/Referral/ReferralApi";
import AppointmentTime from "./CallerBoard/ClientInfo/AppointmentTime";
import LetterAvatar from "./UI/LetterAvatar/LetterAvatar";
import styled from "styled-components";
import ConferenceCall from "./ConferenceCall";
import { window, console } from 'window-or-global';
import Select from "react-select"
import AgentComparison from '../dumbComponents/common/AgentComparison';
let socket = null;

//hack to make beds and baths appear as 1+, 2+ etc
const getBedsAndBaths = (num) =>{
  if (!num){
    return num;
  }
  num = parseInt(num, 10);
  num = num > 5 ? 5: num;
  console.log("beds is ", num);
  return num;
}

const allowedAccountIds = [4, 5, 20, 37, 40]; //Biju, Sanya, Craig and Greg
const getCityAndState = (address) => {
   let parts = address.split(',');
   parts.pop();
   parts.pop();
   if (parts.length === 0){
     return address;
   }
   return parts.join(',');
};

const cookies = new Cookies();
const types = ["Select from below", "Apartment","Condo\/Co-op","Deeded Parking","Farm","House for Rent","Houseboat","Investment","Loft","Lot\/Land","Manufactured\/Mobile","Mixed Use","Multi-Family","Other","Single-family home","Studio Apartment","TIC","Townhouse"];

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const blinkBaseUrl = process.env.REACT_APP_BLINK_BASE_URL;
const settingsUrl = blinkBaseUrl + "/setings";
const messagesUrl = blinkBaseUrl + "/messages";
const accountsUrl = blinkBaseUrl + "/accounts";
const upcomingCallsUrl = blinkBaseUrl + "/upcoming-calls";
const capabilityTokenUrl = 'https://measly-able-4181.twil.io/capability-token?clientName=';
const token = process.env.REACT_APP_SECRET_ID;
const bugsnagBlinkKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(bugsnagBlinkKey);
const v2Url =  'v2.0/';
const Twilio = window.Twilio;
const allowQualify = ["Submitted", "Contacted", "Contacting"];
const workingwithanagentDiv = <React.Fragment><option value="Choose a value" >Choose a value</option>
  <option value="Yes" >Yes</option>
  <option value="No" >No</option>
  <option value="Note">Add a Note</option></React.Fragment>;


const bedAndBathsDiv = <React.Fragment><option value="0" >Did not disclose</option>
  <option value="1" >1+</option>
  <option value="2" >2+</option>
  <option value="3">3+</option>
  <option value="4">4+</option>
  <option value="5">5+</option>
  </React.Fragment>;


$(window).on("beforeunload", function() {
  alert("Do you really want to close?");
});

const defaultAgentReq = {
  "clientAdditionalDetails":"",
  "contactClient":false,
  "receiveReferralUpdate":true,
  "timeline":0,
  "is_property_appraised": false,
  "clientConnect": null,
  "is_online_lead":false,
  "receiver_expertise": null,
  "is_pre_approved": undefined,
  "referral_hist":"",
  "working_with_agent": "Choose a value",
  "appointment_timestamp": null,
};

const DetailsWrap = styled.div`
 flex-grow: 1;
 display: flex;
 flex-direction: column;
`
const InnerWrap = styled.div`
 display: flex;
`
const ImageWrap = styled.div`
 padding-top: 13px;
`
const ContentWrap = styled.div`
 padding-top: 30px;
 padding-left: 15px;
 flex-grow: 1;
`
const CloseWrap = styled.div`
 padding-top: 30px;
 i.fa-times {
    position: relative;
    top: 0px; 
    left: 0px;
 }
`
const ClearButton = styled.button`
  margin-top: 10px;
  margin-bottom: 7px;
`
const UnsavedReferralsWrap = styled.ul`
  list-style: none;
  max-height: 540px;
  overflow: scroll;
  padding: 0px;
`
const CloseBTN = styled.i`
  position: relative;
  font-size: 15px;
  color: black;
`
const WarningDiv = styled.div`
  width: 100%; 
  background-color: yellow; 
  color: grey; 
  position: absolute; 
  bottom: 0; 
  left: 0;
  padding: 5px;
`

const SectionWrap = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  p {
    flex: 1;
  }
  > div {
    width: 160px;
  }
`

class Header extends Component {
  state = {
    name: '',
    socket: null,
    callSid: '',
    propertyId: null,
    type: 'Buyer',
    id: 21,
    status: 'online',
    twiliodevice: null,
    calling: false,
    toNumber: '',
    twilioDevice: null,
    onCall: false,
    connection: null,
    client: null,
    buyerInterestedPropertyDetails: '',
    city: '',
    neighborhood: '',
    minBudget: '',
    maxBudget: '',
    maxSize: '',
    minSize: '',
    propertyTypes: [],
    saving: false,
    submitting: false,
    callDetails: [],
    stage: 1,
    apiCall: false,
    requirementId: '',
    beds: null,
    toPhone: null,
    fromPhone: null,
    locations: [],
    address: null,
    clientId: null,
    addressNeighbourhood: null,
    salesSales: null,
    callDuration: 0,
    intervalId: null,
    referral: null,
    agentId: null,
    agentReq: defaultAgentReq,
    reminderType: 'Call',
    date: null,
    dateinMS: null,
    now: moment().hour(0).minute(0),
    note: null,
    notes:[],
    twilioClientName: null,
    time: '',
    hours: null,
    mins: null,
    searchText: '',
    showSearch: false,
    searchResultClients: [],
    fromScreen: 0,
    suggestedAgents: [],
    assignedAgents: [],
    invitedAgents: [],
    assignedAgentObjects: [],
    primaryAgentId: null,
    agentProfile: null,
    bathrooms: null,
    callOutcome: null,
    text: '',
    sendingText: false,
    messages: [],
    disqualificationReasons: [],
    autoCalls: false,
    nextCall: null,
    countDownintervalId: null,
    countDownSecs: 5,
    connectingWithClient: false,
    pendingCount: 0,
    connectingTimer: 0,
    connectedintervalId: null,
    incomingCallPopup: null,
    emailClientAfterQualification: false,
    emailSenderAfterQualification: false,
    newMessagesCount: 0,
    wasOriginallyIsa: false,
    maskedNumber: null,
    breakEnabled: false,
    queuedCallMap: {},
    callInput: '',
    acceptingCallWhileOnCall: false,
    showSaveProgressAlertDialog: false,
    currentReferralId: null,
    acceptingCallSid: null,
    expired: false,
    outgoingCallsFromNumberToReferralMap: [],
    userReferralSettings: null,
    popupOpen: false,
    neighborhoodDropdownValues: [],
    selectedNeighborhoods: [],
    neighbourhoodsearchText: '',
    unsavedWriteUpsCount: 0,
    requestUnsavedClientInfo: false,
    tempClient: null,
    requestUnsavedNote: false,
    tempNote: null,
    blockPhone: false,
    lookingForALender: undefined,
    timezone: 'MST',
    broker_agents_count: 0,
  };

  constructor(props) {
    super(props);

    const self = this;

    const url = baseUrl + '/socket.io/' + props.user.twilio_client_name;
    socket = io(url);

    socket.on('nextCall', (data) => {
      // store.dispatch(MultiCallerActions.createInitStateAction.call(data));
      if (data && data.next_referral && data.next_referral.sender_client_phone) {
          this.setState({connectingWithClient: false, pendingCount: data.total_count});
          let outgoingCallsFromNumberToReferralIdMap = this.state.outgoingCallsFromNumberToReferralMap;
          outgoingCallsFromNumberToReferralIdMap[data.next_referral.sender_client_phone] = data.next_referral;
          this.setState({outgoingCallsFromNumberToReferralMap:outgoingCallsFromNumberToReferralIdMap});
      } else {
        ShowErrorToast("All calls are done!");
        this.setState({autoCalls: !!!this.state.autoCalls, pendingCount: 0});
      }
      let pendingCount = this.state.pendingCount;
      if (data && data.total_count) {
        pendingCount = data.total_count;
        this.setState({pendingCount: pendingCount})
      }
    });

    // socket.emit('getCallsWithStatus', props.user.twilio_client_name);
    // socket.emit('TestEventFromBlink', "Test message from " + props.user.twilio_client_name);

    // Multi Caller events
    socket.on("SendCallsDetailsEvent", (data) => {
      console.log("SendCallsDetailsEvent")
      console.log("cannot receive");
      store.dispatch(MultiCallerActions.createInitStateAction.call(data))
    })
    socket.on("CallWaiting", (data) => {
      console.log("CallWaiting")
      store.dispatch(MultiCallerActions.changeCallStateAction.call(data))
    })
    socket.on("CallConnecting", (data) => {
      console.log("CallConnecting")
      store.dispatch(MultiCallerActions.changeCallStateAction.call(data))
    })
    socket.on("CallRinging", (data) => {
      console.log("CallRinging")
      store.dispatch(MultiCallerActions.changeCallStateAction.call(data))
    })
    socket.on("CallConnectedToClientAndAdmin", (data) => {
      console.log("CallConnectedToClientAndAdmin")
      store.dispatch(MultiCallerActions.changeCallStateAction.call(data))
    })
    socket.on("ActiveAgentClientCallEvent", (data) => {
      console.log("ActiveAgentClientCallEvent")
      store.dispatch(MultiCallerActions.changeCallStateAction.call(data))
      this.setState({
        isHideRemoveAndHold: true,
      })
    })
    socket.on("CallQueued", (data) => {
      console.log("CallQueued")
      store.dispatch(MultiCallerActions.changeCallStateAction.call(data))
    })
     socket.on("CallCycleFinishedEvent", (data) => {
      store.dispatch(MultiCallerActions.deleteElementFromStateAction.call(data))
     })

     socket.on("RemoveReferralFromListEvent", (data) => {
      store.dispatch(MultiCallerActions.deleteElementFromStateAction.call(data))
     })

     socket.on("prevCall", (data) => {
        console.log("prevCall")
        store.dispatch(MultiCallerActions.changeCallStateAction.call(data))
        this.setState({
          isRinging: false,
        })
     })
      socket.on("ConferenceEvent", (data) => {
          const activeInConferenceCall = get(data, "in-conference[0]")
          if (activeInConferenceCall) {
            this.setState({
              activeInConferenceCall,
              isHideRemoveAndHold: false
            })
          }
          store.dispatch(MultiCallerActions.changeCallStateAction.call(data))
      })

     socket.on('newMessage', (data) => {
      // this.showSmsNotification(data);
      this.setState({newMessagesCount: ++this.state.newMessagesCount});
    });

    socket.on('totalCount', (data) => {
      this.setState({pendingCount: data.total_count})
    });

    socket.on('acceptingCallFailed', (data) => {
      NotifyBugsnagInfo("", data);
      ShowErrorToast(data.message ? data.message : data);
    });

    socket.on('callAck', (data) => {
      console.log("prevCall")
      socket.emit('newCall', this.state.twilioClientName);
    });

    socket.on('connect', function() {
      console.log("connected..")
      self.updateTitle();
      NotifyBugsnagError("socket connection is established for " + props.user.twilio_client_name);
      socket.emit(props.user.twilio_client_name, props.user.twilio_client_name);
      setTimeout(() => {
        console.log("Firing getCallsWithStatus")
        socket.emit('getCallsWithStatus', props.user.twilio_client_name);
      }, 2000)
    });

   setTimeout(() => {
     if (socket) {
       socket.emit('TestEventFromBlink', "Late - test " + props.user.twilio_client_name);
     }
   }, 10000)

    socket.on('disconnect', (reason) => {
      NotifyBugsnagError("Disconnecting socket connection for " + self.state.twilioClientName + " for following reason: " + reason);
      self.updateTitle();
      console.log(reason);
      if (reason === 'io server disconnect' && socket) {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      // else the socket will automatically try to reconnect
    });



    socket.on("LiveTransferNoAgentFoundEvent", (data) => {
      console.log(data)
      ShowErrorToast("No More Agents Found");
    })

    socket.on("referralMergeCompleted", (data) => {
      console.log(data)
      if (data) {
        if (data.is_auto_merge) {
          ShowSuccessToast(`A zillow referral has been auto-merged. Merged referral id: ${data.merged_referral_id}`);
        } else {
          notify.hide()
          this.loadClientDetails(data.merged_referral_id, "referral_id");
        }
      }
    })

    socket.on('callDisconnected', (err, data) => {
      if (self.state.connectingWithClient){
        socket.emit('newCall', self.state.twilioClientName);
      }
      this.setState({
        isRinging: false
      })
    });

    socket.on('callQueued', (data) => {
      let queuedCallMap = self.state.queuedCallMap;
      queuedCallMap[data.call_sid] = {
        referral_id: data.referral_id,
        notification: null,
        notificationResponse: data.notification,
      };
      self.setState({queuedCallMap: queuedCallMap});
      self.showQueuedCallPopup(data.call_sid);
    });

    socket.on('callDequeued', (data) => {
      if (data.call_sid in self.state.queuedCallMap && self.state.queuedCallMap[data.call_sid].notification) {
        if (self.state.queuedCallMap[data.call_sid].interval_id) {
          clearInterval(self.state.queuedCallMap[data.call_sid].interval_id);
        }
        self.state.incomingCallPopup.removeNotification(self.state.queuedCallMap[data.call_sid].notification);
      }
    });

  }

  updateTitle = () => {
    if (socket && socket.connected && this.state.twiliodevice && this.state.twiliodevice.status() === "ready") {
      document.title = "✅ Blink";
    } else {
      document.title = "⛔️ Blink";
    }
  };

  publish = (event, twilioClientName) => {
    if (socket){
      socket.emit(event, twilioClientName, (data) => {
      });
    }
  };


  publishWithJson = (event, twilioClientName, referralId) => {
    if (socket){
      socket.emit(event, JSON.stringify({twilioClientName : twilioClientName, referralId: referralId}), (data) => {
        console.log(JSON.stringify({twilioClientName : twilioClientName, referralId: referralId}));
        console.log("emittted!!!");
      });
    }
  };

  addNeighborhood = (obj) => {
    try{
      const self = this;
      if (obj){
         let selectedNeighborhoods = this.state.selectedNeighborhoods;
         selectedNeighborhoods.push(obj)
         selectedNeighborhoods = _.uniqBy(selectedNeighborhoods, (x) => {
          return x.id;
          });
         this.setState({selectedNeighborhoods}, () => {
           self.setState({neighborhoodDropdownValues : [], neighbourhoodsearchText: ''})
          })
      }

    }
    catch(e){
      console.log(e)
    }

  }

  loadDisqualificationReasons = async () => {
    try{
      let sol = await fetch(baseUrl + '/v1.0/common/referral_disqualified_reasons?sort=id', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token
        },
      });
      sol = await sol.json();
      let disqualificationReasons = _.groupBy(sol.response.items, 'disqualify_reason');
      this.setState({disqualificationReasons  :disqualificationReasons});
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

  formatNumber = (val) => {
    try{
      if (val){
        val = val ? val.replace( /,/g, "" ) : val;
      }
      return val;
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

  refreshQueue = async() => {
    try{
      let body = {
        twilio_client_name: this.props.user.twilio_client_name
      };

      let queueResponse = await fetch(baseUrl + '/v1.0/gabby/caller/load-queues', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
          'X-User-Agent': 'agent-php',
        },
        body: JSON.stringify(body)
      });
        this.getPendingCallsCount();
      notify.show('Refreshed queue successfully!', 'success', 3000);

    }
    catch(e){
      ShowErrorToast(e);
      bugsnagClient.notify(e);
    }
  };


  updateReferralLMPStatusOnParent = (referralId, liveOnLmp) => {
    let referral = this.state.referral;
    referral.live_on_lmp = liveOnLmp;
    this.setState({referral});
  };

  updateBrokerAgentsCountOnParent = (broker_agents_count) => {
    this.setState({broker_agents_count: broker_agents_count});
  };

  togglePrimaryAgentFromChild = (agentId, clientId, action) => {
    let referral = this.state.referral;
    if (action === 'remove') {
      if (this.state.referral && this.state.referral.primary_agent_id && this.state.referral.primary_agent_id === agentId) {
        referral.primary_agent_id = null;
        this.setState({referral: referral});
      }
      let assignedAgents = _.reject(this.state.assignedAgents, (id) => agentId === id);
      this.setState({assignedAgents: assignedAgents});
    }
    else if (action === 'add') {
      if (this.state.referral ) {
        referral.primary_agent_id = agentId;
        referral.primary_client_id = clientId;
        this.setState({referral: referral});
      }
    }
  };

  fetchTaskInfo = async () => {
    let conn = this.state.connection;
    let url = baseUrl + '/v1.0/gabby/call/fetch-task-by-filters?call_sid=' + this.state.acceptingCallSid
    url += "&twilio_client_name=" + this.state.twilioClientName;
    let resp = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json' ,
        'internal-id': token,
      },
    });
    let sol = await resp.json();
    let parentCallSid = "";
    let referralId = null;
    let isShowZillowMergeModal = false
    // current incoming call is reserved for this admin and going to be assigned to them
    if (sol && sol.response && sol.response.attributes_map) {
      let task = sol.response;
      if (task.attributes_map["from"]) {
        conn.parameters.From = task.attributes_map["from"];
      }

      if (task.attributes_map["is_zillow_notification"] === "true") {
        isShowZillowMergeModal = true
      }

      if (task.attributes_map["referralId"]) {
        referralId = parseInt(task.attributes_map["referralId"]);
      }

      if (task.attributes_map["is_zillow_notification"] === "true") {
        isShowZillowMergeModal = true
      }

      if (task.attributes_map["call_sid"]) {
        parentCallSid = sol.response.attributes_map["call_sid"];
      }
    }

    this.setState({
      connection: conn,
      currentReferralId: referralId,
      acceptingCallSid: null,
      isShowZillowMergeModal,
      parentCallSid,
    });
    return parentCallSid;
  };

  setupTwilio = () => {
    let self = this;

    try {
      let twilioClientName = this.props.user.twilio_client_name;
      $.getJSON(capabilityTokenUrl + this.props.user.twilio_client_name)
      .done(function (data) {
          Twilio.Device.setup(data.token);

          Twilio.Device.offline(function () {
            self.updateTitle();
            let fetching = self.state.expired === false ? 'fetching' : 'not fetching';
            bugsnagClient.notify('Twilio token expired. ' + fetching + ' a new token automatically for ' + twilioClientName);
            if (self.state.expired === false) {
              //self.setState({expired: true}, () => {
                self.setupTwilio();
              //});
            }
          });

          Twilio.Device.ready(function (device) {
              notify.show('You are ready to make calls', 'success', 3000);
              self.setState({twiliodevice : Twilio.Device});
              self.updateTitle();

              self.state.twiliodevice.incoming((conn)  => {
                if (!self.state.onCall) {
                  let outgoingCallsFromNumberToReferralMap = self.state.outgoingCallsFromNumberToReferralMap;
                  let next_referral = outgoingCallsFromNumberToReferralMap[conn.parameters.From];

                    if (conn.parameters && conn.parameters.Params && conn.parameters.Params === "direction=0") {
                      //send call event only for auto caller mode.
                      if (self.state.autoCalls){
                        self.cancelNow();
                      }
                      self.setState({connection: conn, nextCall: null, currentReferralId: null}, () => {
                        self.acceptCall();
                      });
                    } else if (next_referral) {
                        delete outgoingCallsFromNumberToReferralMap[conn.parameters.From];
                        self.setState({
                            connection: conn,
                            outgoingCallsFromNumberToReferralMap: outgoingCallsFromNumberToReferralMap,
                            connectingWithClient: false,
                            nextCall: null,
                            toNumber: conn.parameters.From,
                        },()=> {
                            self.acceptCall(next_referral);
                            self.sendCallInProgressEvent(next_referral.id);
                            self.clearConnectedTimer();
                        });
                    } else {
                        NotifyBugsnagInfo("this should not happen for an incoming call ", conn);
                        self.acceptCall();
                    }
                  } else {
                    NotifyBugsnagInfo("this should not happen if already on a call ", "")
                }
                });

              self.state.twiliodevice.cancel((conn)  => {
                self.setState({incomingCall: false, connection: null});
              });

              try{
                self.state.twiliodevice.disconnect(function (conn) {
                  if (!self.state.breakEnabled){
                    self.updateStatus('online');
                  }
                  else{
                    self.updateStatus('break');
                  }

                  let callOutcome = null;
                  if (self.state.referral) {
                    callOutcome = {clientId: self.state.referral.receiver_client_id};
                  }
                  self.setState({onCall: false, connection: null, callOutcome: callOutcome, currentReferralId: null, popupOpen: false},()=> {
                  });
                  self.clearTimer();
                });
              }
              catch(e){
                bugsnagClient.notify(e);
              }

              self.state.twiliodevice.connect((data) => {
                self.setState(
                {callSid: data.parameters.CallSid,});self.setTimer()}
              );
          })
      });
    }
    catch(e){
        bugsnagClient.notify(e);
    }
  };

  handleLenderValue = () => {
    const { lookingForALender } = this.state

    if(lookingForALender === 0){
      return "No"
    } else
    if(lookingForALender === 1){
      return "Yes"
    } else
    if(lookingForALender === undefined || lookingForALender === null){
      return "Select"
    } 
  }

  getNeighborhoods = async (neighborhood) => {

    try {
      this.setState({neighbourhoodsearchText: neighborhood})
      let neighborhoods = await fetch(baseUrl + '/v1.0/search/neighborhoods?neighborhood='+neighborhood, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });
      neighborhoods = await neighborhoods.json();
      if (neighborhoods && neighborhoods.response && neighborhoods.response.neighborhoods){
        let n = [];
        neighborhoods.response.neighborhoods.forEach((neigh) => {
          n.push({id: neigh["_id"]["$oid"], name: neigh["name"], city: neigh["city"]})
        })
        this.setState({neighborhoodDropdownValues: n });
      }
    }
    catch(e) {
      bugsnagClient.notify(e);
    }
  }

  handleReminderTypeChange = (e) => {
    this.setState({reminderType: e.target.value});
  };

  handleDateChange(date) {
    this.setState({
        dateinMS: moment(date).format('X'),
        date: date,
    });
  }

  tick () {
    this.setState({callDuration: (this.state.callDuration + 1)})
  }

  connectedTick(){
    this.setState({connectingTimer: (this.state.connectingTimer + 1)})

  }
  countDownTick () {
    this.setState({countDownSecs: (this.state.countDownSecs - 1)});
    if (this.state.countDownSecs - 1 === 0) {
      if (this.state.autoCalls) {
        this.callNow();
      }
      this.clearCountdownTimer();
    }
  }

  setTimer = () => {
    if (this.state.intervalId)
       clearInterval(this.state.intervalId);
    let intervalId = setInterval(this.tick.bind(this), 1000);
    this.setState({ intervalId: intervalId })
  };

  setUpCountDownTimer = (prevCallStatus) => {
    const self = this;

    //use this flag to compute the amount of time to wait for the next call before making
    // a call. If the previous call is a failed one, i.e, not completed or not empty, call immediately
    // so the consultants don't spend a long time waiting.
    let callNow = !(prevCallStatus === 'completed' || prevCallStatus === '');

    if (this.state.countDownintervalId)
      clearInterval(this.state.countDownintervalId);

    if (this.state.nextCall.next_referral){
      //Call after 10 seconds
      if (!callNow){
        this.setState({countDownSecs:  5}, () => {
          let countDownintervalId = setInterval(self.countDownTick.bind(this), 1000);
          self.setState({ countDownintervalId: countDownintervalId })
        });
      }
      else if (callNow && this.state.autoCalls) {
        //Call immediately
          this.callNow();
      }
    }
  };

  setUpConnectedTimer = (prevCallStatus) => {
    const self = this;

    if (this.state.connectedintervalId)
      clearInterval(this.state.connectedintervalId);

   this.setState({connectingTimer: 0}, () => {
    let connectedintervalId = setInterval(self.connectedTick.bind(this), 1000);
    self.setState({ connectedintervalId: connectedintervalId })
   })
  };

  clearTimer = () => {
    clearInterval(this.state.intervalId);
    this.setState({intervalId: null, callDuration: 0});
  };

  clearCountdownTimer = () => {
    clearInterval(this.state.countDownintervalId);
    this.setState({countDownSecs: 5, countDownintervalId: null});
  };

  clearConnectedTimer = () => {
    clearInterval(this.state.connectedintervalId);
    this.setState({connectingTimer: 0, connectedintervalId: null});
  };

  updateStatus = async (status, fromDropdown = false) => {
    if(fromDropdown === true){
      if (status === 'break'){
        this.setState({breakEnabled: true});
      }
      else{
        this.setState({breakEnabled: false});
      }
    }

    let body = {
      id: parseInt(this.state.id),
      name : this.state.name,
      service_phone : this.state.fromNumber,
      profile_photo_url: this.state.profile_photo_url,
      twilio_client_name : this.state.twilioClientName,
      availability: status,
    };

    await fetch( baseUrl +'/v1.0/gabby/user/availability', {
      method: 'POST',
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'X-User-Agent': 'agent-php',
       },
       body: JSON.stringify(body)
    });
    this.setState({status : status});
  };

  createReminder = async () => {
    try{
      const secondsToAdd =  (parseInt(this.state.hours || 0) * 3600) + (parseInt(this.state.minutes || 0) * 60);
      let body = {
        add_to_calendar: true,
        agent_id: 484725,
        agent_id_to_notify: this.props.user.agent_id,
        date: parseInt(this.state.dateinMS) + secondsToAdd,
        type: this.state.reminderType,
        note: this.state.note,
      };

      let reminders = await fetch(baseUrl+ '/v2.0/client/' +  this.state.referral.receiver_client_id + '/reminders', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'secure-id': token
        },
        body: JSON.stringify(body)
      });
        notify.show('Reminder added successfully', 'success', 10000);
      this.setState({hours: null, mins: null, dateinMS: 0});
    }
    catch(e){
      notify.show('Reminder could not be successfully', 'error', 10000);
      bugsnagClient.notify(e);
    }
  };

  saveLead = async () => {
    try{
      await this.saveRef();
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

  callClientUsingGabby = async (referralId) => {
      callClient(this.state.toNumber, this.state.fromPhone, this.state.twilioClientName, referralId).then(res => {
          ShowSuccessToast("Calling");
      }).catch(err => {
          ShowErrorToast(err);
      });
  };

  submit = async (cb) => {
    try{
      if (!this.state.dateinMS || !this.state.type || !this.state.note) {
        notify.show('Please select all the fields!', 5000);
      }
      else {
        await this.createReminder();
        await this.saveRef();

        //Updating the referral to contacted per Adisen's request.
        let url = baseUrl + '/v1.0/consumers/referral/' + this.state.referral.id;
        let body = {
          referred_client_status: "Contacted",
          consultant_agent_id: this.props.user.agent_id
        };

        if (this.state.type === 'Buyer' || this.state.type === 'Renter') {
          body['req_id'] = this.state.requirementId;
        }
        else if (this.state.propertyId) {
          body['prop_id'] = this.state.propertyId;
        }

        body.type = this.state.type;
        let sol = await fetch(url, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'secure-id': token
            },
            body: JSON.stringify(body)
        });
          cb();
      }
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

putOnHold = async (cb) => {
  try{
    if (!this.state.dateinMS || !this.state.type || !this.state.note) {
      notify.show('Please select all the fields!', 'error', 5000);
    }
    else {
      await this.createReminder();
      await this.saveRef();
      await this.hold();
      cb();
    }
  }
  catch(e){
    bugsnagClient.notify(e);
  }
};

updateNote = async (e) => {
  this.setState({note: e.target.value});
};

getAdditionRequirements = (agent_requirements) => {
  try{
    if (!agent_requirements || agent_requirements === "null") {
      return defaultAgentReq;
    }
    agent_requirements = agent_requirements.replace(/(?:\ r\n|\r|\n)/g, "");

    let agentReq = JSON.parse(agent_requirements);
    agentReq.contactClient = !!!(agentReq.hasOwnProperty("contactClient") && (agentReq.contactClient.toLowerCase === "no" || agentReq.contactClient === false));
    if (!agentReq.timeline) {
      agentReq.timeline = 0;
    }
    agentReq.receiveReferralUpdate = !!!(agentReq.hasOwnProperty("receiveReferralUpdate") && (agentReq.receiveReferralUpdate.toLowerCase === "no" || agentReq.receiveReferralUpdate === false));
    if (!agentReq.clientAdditionalDetails) {
      agentReq.clientAdditionalDetails = "";
    }
    if(!agentReq.clientConnect) {
      agentReq.clientConnect = "";
    }
    if(!agentReq.is_online_lead) {
      agentReq.is_online_lead = false;
    }
    if(!agentReq.is_property_appraised) {
      agentReq.is_property_appraised = false;
    }
    if(!agentReq.receiver_expertise) {
      agentReq.receiver_expertise = "";
    }
    if(!agentReq.is_pre_approved) {
      agentReq.is_pre_approved = false;
    }
    if(!agentReq.referral_hist) {
      agentReq.referral_hist = "";
    }
    if(!agentReq.motivational_level) {
      agentReq.motivational_level = null;
    }

    if(!agentReq.appointment_timestamp) {
      agentReq.appointment_timestamp = null;
    }

    if(!agentReq.working_with_agent) {
      agentReq.working_with_agent = "Choose a value";
    }
    return agentReq;
  }
  catch(e){
    bugsnagClient.notify(e);
    return defaultAgentReq;
  }
};

createClient = async (number) => {
  let body = {
    phones: [{type: "Work", primary: true, phone: normalize(number || "")}],
    agentId: 484725,
    sources: ['SMS call'],
    types: ['Buyer'],
    SalesStage: "Submitted",
  };

  let resp = await fetch(baseUrl + '/v2.0/enquiry', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json' ,
          'internal-id': token,
        },
        body: JSON.stringify(body),
  });
};


getMaskedPhoneNumber = async (num) => {
  try{
    let phone = normalize(this.state.toNumber || "");
    let areaCode = phone.substring(0,3);
    let url = baseUrl + '/v1.0/getCallerNumberUsingAreaCode/' + areaCode;
    let resp = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json' ,
          'internal-id': token,
        },
    });
    let jsonResp = await resp.json();
    this.setState({maskedNumber: jsonResp.response.number });
    return jsonResp.response.number;
  }
  catch(e){
    bugsnagClient.notify(e);
    return null;
  }
}

setOutgoingCallDetails = async (sol) => {
  try{
    if (sol && sol.response) {
      if (sol.response.client && !sol.response.client.types){
        sol.response.client.types = [sol.response.referral.type];
      }
      notify.show('Connecting with client ', 'success', 2000);
      await this.populateClientDetails(sol);
      await this.getAgentReferralSettings();

      //this.loadNotes();
      let fromNumber = await this.getMaskedPhoneNumber(this.state.toNumber);
      let referralId = sol.response.referral ? sol.response.referral.id : 0;
      if (referralId !== 0 ){
        this.setUpStage(sol.response.referral.referred_client_status);
        this.loadMessages();
      }
      const params = {
        To: normalize(this.state.toNumber),
        From: fromNumber ? fromNumber : this.state.fromNumber,
        ReferralId: referralId,
      };
      if (!this.state.twiliodevice) {
        ShowErrorToastFromText("Twilio device is not setup to connect calls. Please refresh and try again.");
        return;
      }
      this.callClientUsingGabby(referralId).then(res => {
          ShowSuccessToast("Ringing");
          //this.updateStatus('call');
          this.setState({
            connection: null,
            callDuration: 0,
            stage: 2,
            callOutcome: null,
            isRinging: true,
          });
      }).catch(err => {
          ShowErrorToast(err);
      });
    }
  } catch(e){
    bugsnagClient.notify(e);
    ShowErrorToast(e);
  }
};

markLeadPhoneNumberIncorrect = () => {
  if (!this.state.referral || !this.state.referral.id) {
    ShowErrorToastFromText("Missing lead id for marking phone number as incorrect");
  }

  if (!this.state.referral.valid_phone) {
    ShowErrorToastFromText("Phone number is already marked incorrect");
    return;
  }

  const referralId = this.state.referral.id;
  markLeadPhoneNumberIncorrect({referral_id: referralId}).then(res => {
    ShowSuccessToast("Phone number has been marked incorrect");
    this.updateValidPhone(referralId);
  }).catch(e => {
    ShowErrorToast(e);
  })
}

updateValidPhone = (referralId) => {
  if (referralId && this.state.referral && referralId === this.state.referral.id) {
    let referral = this.state.referral;
    referral.valid_phone = 0;
    this.setState({referral: referral});
  }
}

disconnectCall = () => {
  // end call if on a call
  if (this.state.onCall) {
    try {
      if (!this.state.twiliodevice) {
        ShowErrorToastFromText("Twilio device is not setup to connect calls. Please refresh and try again.");
        return;
      }
      this.state.twiliodevice.disconnectAll();
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  }
}

actOnClick = async (num = null) => {
  //call or end call.
  if (this.state.onCall) {
    try {
      if (!this.state.twiliodevice) {
        ShowErrorToastFromText("Twilio device is not setup to connect calls. Please refresh and try again.");
        return;
      }
      this.state.twiliodevice.disconnectAll();
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  }
  else {
      if (!this.state.twilioClientName) {
        notify.show('Missing client name in the url!', 'success', 500);
      }
      else {
        let clientIdForOutgoingCall = this.state.referral && this.state.referral.receiver_client_id ? this.state.referral.receiver_client_id : null;
          let referralId = this.state.referral ? this.state.referral.id : null;
          this.setState({client: null, tempClient: null, tempNote: null, referral: null}, async() => {
          notify.show('Fetching client details!', 'success', 500);
          let clientIdString = !clientIdForOutgoingCall ? '' : `&client_id=${clientIdForOutgoingCall}`;
          let resp = await fetch(baseUrl + '/v1.0/caller/client-details?client_phone=' + normalize(this.state.toNumber) + clientIdString +  "&twilio_client_name=" + this.state.twilioClientName + "&referral_id=" + referralId, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json' ,
              'internal-id': token,
            },
          });
          try {
            let sol = await resp.json();
            if (!sol || !sol.response || !sol.response.client) {

              notify.show(('This number does not belong to a client, creating a client before calling '  + normalize(this.state.toNumber)),'success', 3000);
              //If client details do not exist, create the client details first and then call the person
              await this.createClient(this.state.toNumber);
              resp = await fetch(baseUrl + '/v1.0/caller/client-details?client_phone=' + normalize(this.state.toNumber) + "&twilio_client_name=" + this.state.twilioClientName, {
                method: 'GET',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json' ,
                  'internal-id': token,
                },
              });
              sol = await resp.json();
              if (!sol || !sol.response || !sol.response.client) {
                notify.show(('This number does not belong to a client, calling anyway '  + normalize(this.state.toNumber)),'success', 10000);
                this.callClientUsingGabby(referralId).then(res => {
                  ShowSuccessToast("Ringing");
                  this.setState({connection: null, callDuration: 0, stage: 2, callOutcome: null});
                }).catch(err => {
                  ShowErrorToast(err);
                });
              } else{
                this.setOutgoingCallDetails(sol);
              }
          } else {
            this.setOutgoingCallDetails(sol);
          }
        } catch(e) {
          this.setState({onCall: false, connection: null, callDuration: 0, currentReferralId: null});
          notify.show('Could not fetch client details for: '  + normalize(this.state.toNumber), 'error', 6000);
          bugsnagClient.notify(e);
        }
      });
    }

  }
};

populateRequirementData = (sol) => {
  let locations = [];
  if (sol.response.requirement) {
    if(sol.response.requirement.locations) {
      sol.response.requirement.locations = (sol.response.requirement.locations || []).map((loc) => {

        loc.locality = loc.c_locality_name;
        loc.latitude = loc.c_locality_latitude;
        loc.longitude = loc.c_locality_longitude;

        return loc;
      });
      locations = sol.response.requirement.locations;
    }
    this.setState(
        {
          minBudget: (sol.response.requirement.min_budget || 0),
          maxBudget: (sol.response.requirement.max_budget || 0),
          maxSize: sol.response.requirement.max_size,
          minSize: sol.response.requirement.min_size,
          propertyTypes: sol.response.requirement.types ? sol.response.requirement.types : [],
          requirementId: sol.response.requirement.req_id,
          beds: getBedsAndBaths(sol.response.requirement.bedroom) ,
          bathrooms: getBedsAndBaths(sol.response.requirement.bathroom),
          locations: locations,
        });
  }
};

resetRequirementData = () => {
  this.setState(
      {
        minBudget: 0,
        maxBudget: 0,
        maxSize: 0,
        minSize: 0,
        propertyTypes: [],
        requirementId: null,
        beds: 0,
        bathrooms: 0,
        locations: [],
      });
};

resetNeighborhoodData = () => {
  this.setState(
    {
      neighborhoodDropdownValues: [],
      selectedNeighborhoods: [],
    });

}

  populateBuyerInterestedProperty = (sol) => {
    if (sol.response.buyer_interested_property) {
      let buyerInterestedPropertyDetails = '';

      buyerInterestedPropertyDetails = `${buyerInterestedPropertyDetails} Address: ${sol.response.buyer_interested_property.address || 'N/A'},`;

      buyerInterestedPropertyDetails = `${buyerInterestedPropertyDetails}\n City: ${sol.response.buyer_interested_property.city || 'N/A'},`;

      buyerInterestedPropertyDetails = `${buyerInterestedPropertyDetails}\n State: ${sol.response.buyer_interested_property.state || 'N/A'},`;

      buyerInterestedPropertyDetails = `${buyerInterestedPropertyDetails}\n No. of bed rooms: ${sol.response.buyer_interested_property.no_of_bed_room || 'N/A'},`;

      buyerInterestedPropertyDetails = `${buyerInterestedPropertyDetails}\n No. of bath rooms: ${sol.response.buyer_interested_property.no_of_bath_room || 'N/A'},`;

      buyerInterestedPropertyDetails = `${buyerInterestedPropertyDetails}\n Resale Price: ${sol.response.buyer_interested_property.resale_price || 'N/A'},`;

      buyerInterestedPropertyDetails = `${buyerInterestedPropertyDetails}\n Property Type: ${sol.response.buyer_interested_property.property_type || 'N/A'},`;

      buyerInterestedPropertyDetails = `${buyerInterestedPropertyDetails}\n Covered Area: ${sol.response.buyer_interested_property.covered_area || 'N/A'},`;

      if (buyerInterestedPropertyDetails) {
        buyerInterestedPropertyDetails = buyerInterestedPropertyDetails.substr(0, buyerInterestedPropertyDetails.length - 1)
      }
      this.setState({buyerInterestedPropertyDetails});
    }
  };

  populateNeighborhoodInformation = (sol) => {
    if (sol && sol.response && sol.response.referral_neighborhoods && sol.response.referral_neighborhoods.length > 0){
      this.setState({selectedNeighborhoods: sol.response.referral_neighborhoods})
    }
  }

  resetBuyerInterestedPropertyData = () => {
    this.setState({buyerInterestedPropertyDetails: ''});
  };

populatePropertyData = (sol) => {
  let locations = [];
  if (sol.response.property) {
    locations = [
      {
        locality : sol.response.property.locality,
        latitude : sol.response.property.latitude,
        longitude : sol.response.property.longitude,
        address: sol.response.property.address,
        property_name: sol.response.property.property_name,
        city: sol.response.property.city,
        state: sol.response.property.state,
      }
    ];
    this.setState(
        {
          beds: getBedsAndBaths(sol.response.property.no_of_bed_room),
          bathrooms: getBedsAndBaths(sol.response.property.no_of_bath_room),
          maxBudget: sol.response.property.resale_price || 0,
          propertyTypes: [sol.response.property.property_type],
          maxSize: sol.response.property.covered_area,
          propertyId: sol.response.property.basic_id,
          minBudget: 0,
          minSize: 0,
          locations: locations,
        });
  }
};

resetPropertyData = () => {
  this.setState(
      {
        beds: 0,
        bathrooms: 0,
        maxBudget: 0,
        propertyTypes: [],
        maxSize: 0,
        propertyId: null,
        minBudget: 0,
        minSize: 0,
        locations: [],
      });
};

resetNotes = () => {
  this.setState(
      {
        noes: []
      });
};

resetAll = () => {
  this.resetRequirementData();
  this.resetPropertyData();
  this.resetBuyerInterestedPropertyData();
  this.resetClientData();
  this.resetNotes();
  this.resetNeighborhoodData();
};

populateSettings = (referral) => {
  let blockPhone = false;
  let lookingForALender = undefined;
  let {block_phone, looking_for_a_lender, timezone} = referral;
  if (block_phone){
    blockPhone = true;
  }
  lookingForALender = looking_for_a_lender;
  
  this.setState({blockPhone,lookingForALender, timezone });
}


populateClientDetails = async (sol, reset = true) => {
  if (reset) {
    await this.resetAll();
  }
  if (sol && sol.response && sol.response.referral) {
     if (sol.response.referral.type === "Seller") {
       this.populatePropertyData(sol);
     } else {
       this.populateRequirementData(sol);
     }
    this.populateBuyerInterestedProperty(sol);
    this.populateNeighborhoodInformation(sol);
    this.clientData(sol);
    this.populateSettings(sol.response.referral);
    if (sol.response.tempClient && !reset) {
      this.setState({tempClient: sol.response.tempClient})
    }
    if (sol.response.tempNote && !reset) {
      this.setState({tempNote: sol.response.tempNote})
    }
  }
};

resetClientData = () => {
  this.setState(
      {
        client: null,
        tempClient: null,
        tempNote: null,
        isPreApproved: undefined,
        agentReq: null,
        callDetails:[],
        type: null,
        assignedAgentObjects: [],
        assignedAgents: [],
        status_history: [],
        referral: null,
        agentId: null,
        submitting: false,
        maskedNumber: null,
        blockPhone: false,
        lookingForALender: undefined,
        timezone: 'MST'
      }
  );
};

clientData = (sol) => {
    if (sol.response.client && !sol.response.client.types){
      sol.response.client.types = [sol.response.referral.type];
    }
    this.setState(
      {
        client: sol.response.client,
        isPreApproved: sol.response.client.pre_approved,
        agentReq: this.getAdditionRequirements(sol.response.client.agent_requirements),
        callDetails: sol.response.call_details || [],
        type: sol.response.referral.type,
        assignedAgentObjects: sol.response.assigned_agents ?  sol.response.assigned_agents : [],
        assignedAgents: sol.response.assigned_agents ?  sol.response.assigned_agents.map(agent => agent.id) : [],
        status_history: sol.response.status_history || [],
        referral: sol.response.referral,
        agentId: sol.response.client.agent_id,
      }
    );
};

showSmsNotification = (sms) => {
  let from = (sms.SenderName) ? sms.SenderName : sms.From;
  this.state.incomingCallPopup.addNotification({
    title: "SMS received from " + from,
    message: sms.Body,
    level: 'warning',
    autoDismiss: 10, // The time in seconds after which the popup disappears
    dismissible: 'button',
    action: {
      label: 'Ok',
      callback: () => {
        this.resetNewMessagesCount();
      }
    },
  });
};

  showQueuedCallPopup = (call_sid) => {
    const self = this;
    // use this flag to invoke the deny call method on closing the popup.
    // the onremove function gets fired even when the call is accepted but we should deny
    // the call only when it's not accepted
    let accepted = false;
    let title = 'Incoming call';
    let message = 'Click Accept to begin the call or close popup to reject';
    let messageSecond = '';
    let messageThird = '';
    let autoDismiss = 75;
    let isZillowNotification = false;
    let isOutgoingWaitingCall = false;
    let queuedCallMap = this.state.queuedCallMap;
    if (queuedCallMap[call_sid].notificationResponse) {
      const notificationResponse = queuedCallMap[call_sid].notificationResponse;
      isZillowNotification = notificationResponse.is_zillow_notification === true;
      isOutgoingWaitingCall = notificationResponse.outgoing_call == 1;
      if (isOutgoingWaitingCall) {
          title = 'Outgoing waiting call';
      }
      title += ` from ${notificationResponse.referral_source}`;
      message = `${notificationResponse.sender_agent_name}, ${notificationResponse.sender_agent_radius_number}`;
      messageSecond = (notificationResponse.client_name) ? `${notificationResponse.client_name}, ${notificationResponse.client_phone_number}` : `(No Name), ${notificationResponse.client_phone_number}`;
      messageThird = `Incoming Calls Count: ${notificationResponse.incoming_call_count} | Missed Calls Count: ${notificationResponse.missed_call_count}`;
      autoDismiss = parseInt(notificationResponse.timeout_in_seconds);
    }


    let intervalId = null;
    if (!this.state.onCall && this.state.twiliodevice && !isOutgoingWaitingCall) {
      this.state.twiliodevice.audio.ringtoneDevices.test();
    }

    queuedCallMap[call_sid].interval_id = intervalId;
    this.sendDismissEvent(call_sid, autoDismiss, intervalId);

    let incomingCallClassName = "notification-custom-btn-success ";
    let level = "success";
    if (isZillowNotification) {
        incomingCallClassName = "notification-custom-btn-error ";
        level = "error";
    } else if (isOutgoingWaitingCall) {
        incomingCallClassName = "notification-outgoing-waiting-call ";
        level = "info";
    }

    queuedCallMap[call_sid].notification = this.state.incomingCallPopup.addNotification({
      title: title,
      //message: message,
      level: level,
      autoDismiss: autoDismiss, // The time in seconds after which the popup disappears
      dismissible: 'button',
      children: (
          <div>
            <span>{message}</span><br/>
            <span>{messageSecond}</span><br/>
            <span>{messageThird}</span><br/><br/>
            <NotificationTimer timer={autoDismiss} /><br/><br/>
            <button className={incomingCallClassName} onClick={() => {
              accepted = true;
              this.setState({acceptingCallSid: call_sid}, () => {
                if (self.state.onCall || !self.state.twiliodevice || !socket) {
                  self.setState({acceptingCallWhileOnCall: true});
                } else if (self.state.referral && self.state.referral.id) {
                  self.setState({showSaveProgressAlertDialog: true});
                } else {
                  self.saveAndSwitch(false);
                }
                self.state.incomingCallPopup.removeNotification(queuedCallMap[call_sid].notification);
              });
            }}>Accept</button>
          </div>
      ),
      onRemove:  () => {
        if (intervalId) {
          clearInterval(intervalId);
        }

        delete self.state.queuedCallMap[call_sid];
        self.setState({queuedCallMap: self.state.queuedCallMap});
      },
    });

     this.setState({queuedCallMap: queuedCallMap});
  };

  sendDismissEvent = (call_sid, autoDismiss, intervalId) => {
    setTimeout(() => {
      console.log("Call timed out");
      if (intervalId) {
        clearInterval(intervalId);
      }
      this.publish("incomingCallTimedOut",
          {call_sid: call_sid, twilio_client_name: this.state.twilioClientName});
    }, autoDismiss * 1000);
  };

  sendInputToCall = (event) => {
    const charList = '0123456789+*#';
    if (charList.indexOf(event.key) !== -1) {
      if (this.state.connection) {
        this.state.connection.sendDigits(event.key);
      } else {
        bugsnagClient.notify('[IVR-INPUT] Connection object is missing for ' + this.state.twilioClientName);
      }
    } else {
      bugsnagClient.notify('[IVR-INPUT] Invalid key pressed by ' + this.state.twilioClientName);
    }
  };

logout = async () => {
  cookies.remove('user');
  await this.updateStatus('offline');
  window.location.reload();
};


//if referral id is passed, get client details using the referralId query parameter,
//otherwise, use the phone number.
acceptCall = async (next_referral = null) => {
  try{
    let referralId = null;

    if (next_referral) {
      this.state.connection.accept();
      this.updateStatus('call');
    } else {
      if (!this.state.acceptingCallSid) {
        bugsnagClient.notify('Call sid is not found for this call');
        ShowErrorToastFromText('Call sid is not found for this call');
        return;
      }
      let acceptingCallSid = this.state.acceptingCallSid;
      let parentCallSid = await this.fetchTaskInfo();
      if (parentCallSid) {
        this.state.connection.accept();
        this.updateStatus('call');
        this.publish("callPickedUp", {
          twilio_client_name: this.state.twilioClientName,
          call_sid: parentCallSid,
          referral_id: this.state.currentReferralId
        });
      } else {
        bugsnagClient.notify('Task is not found for following call sid: ' + acceptingCallSid);
        ShowErrorToastFromText(`Task is not found for following call sid: ${acceptingCallSid}`);
        return;
      }
    }

    await this.resetAll();

    this.setState(
      {
        onCall: true,
        isRinging: false,
        queue_item: null,
        callDuration: 0,
        nextReferral: null,
        client: null,
        tempClient: null,
        tempNote: null,
        referral: null,
        city: '',
        neighborhood: '',
        minBudget: '',
        maxBudget: '',
        maxSize: '',
        minSize: '',
        propertyTypes: [],
        beds: '',
        locations: [],
        requirementId: null,
        propertyId: null,
        agentReq: defaultAgentReq,
        bathrooms: null,
      }, async () => {

      if(next_referral && next_referral.queue_item) {
        this.setState({queue_item: next_referral.queue_item});
      }
      if (next_referral && next_referral.id) {
          referralId = next_referral.id;
      }
      const fromNumber = this.state.connection.parameters.From;

      if (!referralId && this.state.currentReferralId) {
        referralId = this.state.currentReferralId;
      }

      let resp = null;
      if (referralId !== null)  {
        resp = await fetch(baseUrl + '/v1.0/caller/client-details?referral_id=' + referralId + "&twilio_client_name=" + this.state.twilioClientName, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json' ,
            'internal-id': token,
          },
        });
      }
      else {
        resp = await fetch(baseUrl + '/v1.0/caller/client-details?client_phone=' + normalize(fromNumber) + "&twilio_client_name=" + this.state.twilioClientName, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json' ,
            'internal-id': token,
          },
        });
      }
      let sol = await resp.json();

      if (!sol || !sol.response || !sol.response.client) {
        notify.show(('Client details do not exist '  + normalize(this.state.toNumber)),'error', 10000);
        await this.resetAll();
      }
      else {
        if (sol && sol.response) {
          console.log(sol);
           //temp workflow to notify bugsnag
          if (referralId) {
            if (!sol.response.client) {
              bugsnagClient.notify('Client details could not be fetched for referral ' + referralId);
            }
            else if (!sol.response.referral) {
              bugsnagClient.notify('Referral details could not be fetched for referral ' + referralId);
            }
          }

          if (sol.response.client && !sol.response.client.types){
            sol.response.client.types = [sol.response.referral.type];
          }

          let agentReq = this.state.agentReq;
          agentReq.clientAdditionalDetails = "";
          this.setState({onCall: true, callOutcome: null, agentReq : agentReq});
          await this.populateClientDetails(sol);
          await this.getAgentReferralSettings();
          this.getMaskedPhoneNumber();

          let referralId = sol.response.referral ? sol.response.referral.id : 0;
          if (referralId !== 0 ){
            this.setState({currentReferralId: referralId});
            this.setUpStage(sol.response.referral.referred_client_status);
            this.loadMessages();
          }
        }
      }
    });
  }
  catch(e){
    bugsnagClient.notify(e);
  }
};

  denyCall = () => {
    try{
      if (this.state.connection){
        this.state.connection.reject();
      }
      this.updateStatus('online');
      this.setState({connection: null});
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

  disConnect = () => {
    if (!this.state.twiliodevice) {
      ShowErrorToastFromText("Twilio device is not setup to connect calls. Please refresh and try again.");
      return;
    }
    this.state.twiliodevice.disconnectAll();
    this.setState({ calling: false});

  };

  handlePhoneNumberChange = (e) => {
    this.setState({toNumber: format(e.target.value || "")});
  };

  componentWillUnmount() {
    let body = {
      id: this.state.id,
      availability: 'offline',
    };
    fetch(baseUrl +'/v1.0/gabby/user/availability', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-User-Agent': 'agent-php',
      },
      body: JSON.stringify(body)
    });
  }

  handleTimeChange = (timeObj) => {
    this.setState({hours: timeObj.hour, mins: timeObj.minute});
  };
  secondsTohhmmss = () => {
    const totalSeconds = this.state.callDuration;
    let hours   = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
    let seconds = totalSeconds - (hours * 3600) - (minutes * 60);
    seconds = Math.round(seconds * 100) / 100;
    let result = (hours < 10 ? "0" + hours : hours);
        result += ":" + (minutes < 10 ? "0" + minutes : minutes);
        result += ":" + (seconds  < 10 ? "0" + seconds : seconds);
    return result;
  };

  getPendingCallsCount = async () => {
    try {
      let pendingCount = await fetch(baseUrl + '/v1.0/gabby/caller/total-count?twilio_client_name=' + this.props.user.twilio_client_name, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
          'x-user-agent': 'agent-php',
        },
      });
      pendingCount = await pendingCount.json();

      this.setState({pendingCount: pendingCount.response.total_count});

    }
    catch(e) {
      bugsnagClient.notify(e);
    }
  };

  loadConsultantStatus = async () => {
    try {
      let status = await fetch(baseUrl + '/v1.0/admin/user/' + this.props.user.id, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });
      status = await status.json();
      this.setState({status: status.response.availability});

      this.getPendingCallsCount();
    }
    catch(e) {
      bugsnagClient.notify(e);
    }
  };

  //load client details based on the lookupType parameter, which is the query parameter we pass to the client details api.
  loadClientDetails = async (phoneOrReferralId, lookUpType, loadUnsavedWriteup = false) => {
    this.setState({callOutcome: false, client: null, tempClient: null, tempNote: null, referral: null}, async () => {
      // if we are going to lookup by phone, normalize before looking up.
      const field = lookUpType === 'client_phone' ? normalize(phoneOrReferralId) : phoneOrReferralId;
      const clientDetailsUrl = baseUrl + '/v1.0/caller/client-details?' + lookUpType + '=' + field + "&twilio_client_name=" + this.state.twilioClientName;
      let resp = await fetch(clientDetailsUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json' ,
          'internal-id': token,
        },
      });
      try {
        let sol = await resp.json();
        if (!sol || !sol.response || !sol.response.client) {
          notify.show(('Client details do not exist for: '  + this.state.toNumber),'error', 10000);
          this.setState({client: null, tempClient: null, tempNote: null, referral: null, notes: []});
        }
        else {
          if (sol && sol.response) {
            if (sol.response.client && !sol.response.client.types){
              sol.response.client.types = [sol.response.referral.type];
            }

            await this.populateClientDetails(sol);
            if (loadUnsavedWriteup) {
              let unsavedReferrals = this.getUnsavedReferrals();
              if (unsavedReferrals) {
                let filteredResults = unsavedReferrals.filter((item) => item.response.referral.id === sol.response.referral.id);
                if (filteredResults && filteredResults[0]) {
                  await this.populateClientDetails(filteredResults[0], false);
                  if (filteredResults[0].onCall) {
                    let callOutcome = null;
                    if (this.state.referral) {
                      callOutcome = {clientId: this.state.referral.receiver_client_id};
                    }
                    this.setState({callOutcome})
                  }
                }
              }
            }
            await this.getAgentReferralSettings();
            let referralId = sol.response.referral ? sol.response.referral.id : 0;
            if (referralId !== 0 ){
              this.setUpStage(sol.response.referral.referred_client_status);
              this.loadMessages();
              this.populatePhoneNumber();
              this.setWasOriginallyIsaStatus(sol.response.wasIsa || false);
            }
          }
        }
      }
      catch(e) {
        console.log(e);
        //bugsnagClient.notify(e);
        ShowErrorToast('Could not fetch client details for: ');
      }
    });
  };

  setWasOriginallyIsaStatus = (wasIsa) => {
    this.setState({wasOriginallyIsa: wasIsa});
  }

  setUpStage = (status) => {
    switch(status) {
      case 'Leads':
          if (this.state.referral && this.state.referral.is_isa)
            this.setState({stage: 4});
          else
            this.setState({stage: 5});
          break;
      case 'Submitted':
          this.setState({stage: 1});
          break;
      case 'Comparing Agents':
          this.setState({stage: 3});
          break;
      case 'Contacting':
          this.setState({stage: 2});
          break;
      case 'Contacted':
          this.setState({stage: 2});
          break;
      case 'Qualified':
          if (this.state.referral && this.state.referral.is_isa){
            this.setState({stage: 4});
            break;
          }
          else {
            this.setState({stage: 3});
            break;
          }
      case 'In Contract':
          this.setState({stage: 5});
          break;
      case 5:
          this.setState({stage: 5});
          break;
      default:
          this.setState({stage: 1});
    }
  };

  componentDidMount() {
    const self = this;
    const consultantName = this.props.user.name;

    const { origin } = window.location
    // disable base route for soul
    if (origin.includes("soul.")) {
      window.location = `http://soul.radiusagents.com/broker/tc`
    }

    this.profileAvailablityPortal = document.getElementById("blink-availability")
    const consultatnProfilePhoto = this.props.user.profile_photo_url;
    const twilioClientName = this.props.user.twilio_client_name;
    const fromNumber = this.props.user.service_phone;
    const id = this.props.user.id;
    const parsed = qs.parse(window.location.search);
    const isPageInactive = parsed.isPageInactive;

    if (!parsed.referral_id && !parsed.clientPhone && !parsed.messageDashboardClientId && !parsed.client_id && !parsed.breakEnabled) {
      this.setState(
        {
          name: consultantName,
          profile_photo_url: consultatnProfilePhoto,
          id: id,
          fromNumber: fromNumber,
          twilioClientName: twilioClientName,
          incomingCallPopup: this.refs.incomingCallPopup,
        },
        () => {
          self.publishStopAutoCall();
        }
      );
    }
    else {
      this.setState(
        {
          name: consultantName,
          profile_photo_url: consultatnProfilePhoto,
          id: id,
          fromNumber: fromNumber,
          twilioClientName: twilioClientName,
          toNumber: parsed.clientPhone,
          parsedReferralId: parsed.referral_id,
          incomingCallPopup: this.refs.incomingCallPopup,
          breakEnabled: parsed.breakEnabled,
        },
        () => {
          self.publishStopAutoCall();
        }
      );

      if (parsed.referral_id) {
        let loadUnsavedWriteups = false;
        if (parsed.purpose && parsed.purpose === "writeups") {
          loadUnsavedWriteups = true;
          window.history.replaceState({}, document.title, "/?referral_id=" + parsed.referral_id + "&isPageInactive=true");
        }
        this.loadClientDetails(parsed.referral_id, 'referral_id', loadUnsavedWriteups);
      } else if (parsed.messageDashboardClientId) {
        this.loadClientDetails(parsed.messageDashboardClientId, 'client_id');
      } else if (parsed.client_id) {
          this.loadClientDetails(parsed.client_id, 'client_id');
      } else if (parsed.clientPhone) {
        this.loadClientDetails(parsed.clientPhone, 'client_phone');
      }
    }
    this.loadConsultantStatus();

    console.log("PageInactive: ", isPageInactive)
    if (isPageInactive === "true") {
        if (socket) {
          console.log("Disconnecting socket due to the param isPageInactive: ", isPageInactive)
          socket.disconnect();
          socket = null;
        }
        this.updateTitle();
    } else {
      this.setState({expired: false}, () => {
        this.setupTwilio();
      });
    }
    // loading unsaved referrals
    let unsavedReferrals = this.getUnsavedReferrals();
    this.setState({unsavedWriteUpsCount: unsavedReferrals ? unsavedReferrals.length : 0})

    this.loadDisqualificationReasons();
  }

  getAgentReferralSettings = async () => {
    if (!this.state.referral || !this.state.referral.sender_id) {
      ShowErrorToastFromText("Referral not found");
      return;
    }

    const self = this;
    let res = await getAgentReferralSettings(this.state.referral.sender_id).then(res => {
      let userReferralSettings = res.response;
      console.log("setting state");
      self.setState({userReferralSettings: userReferralSettings});
    }).catch(err => {
      console.log(err);
      ShowErrorToast(err);
    });
  };

  handleTimeline = (e) => {
    let timeline = 0;
    let val = e.target.value;
    if (val === 'asap') {
      timeline = 0;
    }
    else if (val === '30-60') {
      timeline = 30;
    }
    else if (val === '60-90') {
      timeline = 60;
    }
    else {
      timeline = 90;
    }
    let agentReq = this.state.agentReq;
    agentReq.timeline = timeline;
    this.setState({agentReq : agentReq});
  };

  handleCityUpdate = (address) => {
    this.setState({address : address});
  };

  handleNeighborhoodUpdate = (addressNeighbourhood) => {
    this.setState({addressNeighbourhood : addressNeighbourhood});
  };

  handleCitySelect = (address) => {
    let locations = this.state.locations;
    geocodeByAddress(address)
    .then(results => results)
    .then(async (results) => {
        const findResult = function (results, name) {
            const result = _.find(results, function (obj) {
                return obj.types[0] == name && obj.types[1] == "political";
            });
            if (name == "country") {
                return result ? result.short_name : null;
            } else {
                return result ? result.long_name : null;
            }

        };
        let latLng = await getLatLng(results[0]);

      locations.push({
        locality: getCityAndState(address),
        latitude: latLng.lat,
        longitude: latLng.lng,
        city : findResult(results[0].address_components, "locality"),
        state : findResult(results[0].address_components, "administrative_area_level_1"),
        country : findResult(results[0].address_components, "country"),
      });
      this.setState({locations: locations, address: ''});
    })
    .catch(error => console.error('Error', error))
  };

handlePropertyCitySelect = (address) => {
    let locations = [

    ];

    geocodeByAddress(address)
    .then(results => results)
    .then(async (results) => {
        const findResult = function (results, name) {
            const result = _.find(results, function (obj) {
                return obj.types[0] == name && obj.types[1] == "political";
            });
            if (name == "country") {
                return result ? result.short_name : null;
            } else {
                return result ? result.long_name : null;
            }
        };
        let latLng = await getLatLng(results[0]);

      locations.push({
        locality: getCityAndState(address),
        latitude: latLng.lat,
        longitude: latLng.lng,
        city : findResult(results[0].address_components, "locality"),
        state : findResult(results[0].address_components, "administrative_area_level_1"),
        country : findResult(results[0].address_components, "country"),
        address: results[0].formatted_address,
      });
      this.setState({locations: locations, address: ''});
    })
    .catch(error => console.error('Error', error))
  };

  handleNeighborhoodSelect = (addressNeighbourhood) => {
    let locations = this.state.locations;
    geocodeByAddress(addressNeighbourhood)
      .then(results => results)
      .then(async (results) => {
        const findResult = function (results, name) {
            const result = _.find(results, function (obj) {
                return obj.types[0] == name && obj.types[1] == "political";
            });
            if (name == "country") {
                return result ? result.short_name : null;
            } else {
                return result ? result.long_name : null;
            }
        };
        let latLng = await getLatLng(results[0]);

        locations.push({
          locality: addressNeighbourhood,
          latitude: latLng.lat,
          longitude: latLng.lng,
          city : findResult(results[0].address_components, "locality"),
          state : findResult(results[0].address_components, "administrative_area_level_1"),
          country : findResult(results[0].address_components, "country"),
        });
        this.setState({locations: locations, addressNeighbourhood: ''});
      })
      .catch(error => console.error('Error', error))
  };

  handleDeselect = (locality, close) => {
    const locations = _.filter(this.state.locations,
        (location) => {
          return location.locality !== locality
        }
    );
    this.setState({locations: locations});
    close();
  };

  handleDeselectNeighborhood = (neighborhood) => {
    if (neighborhood){
      let selectedNeighborhoods = this.state.selectedNeighborhoods;
      selectedNeighborhoods = _.reject(selectedNeighborhoods, (n) => n.name === neighborhood.name && n.city === neighborhood.city )
      selectedNeighborhoods = _.uniq(selectedNeighborhoods)
      this.setState({selectedNeighborhoods})
    }

  }

  handleQualificationChannel = (e) => {
      let referral = this.state.referral ? this.state.referral : {};
      referral.qualification_channel = e.target.value;
      this.setState({referral : referral})
  };

  handleQualificationPreference = (e) => {
      let referral = this.state.referral ? this.state.referral : {};
      referral.qualification_preference = e.target.value;
      this.setState({referral : referral})
  };



  handleTypeChange = (e) => {
    this.setState({
      //city: '',
      //neighborhood: '',
      minBudget: '',
      maxBudget: '',
      maxSize: '',
      minSize: '',
      propertyTypes: [],
      type: e.target.value,
      beds: '',
      //locations: [],
      requirementId: null,
      propertyId: null,
      bathrooms: null,
    });
  };

  handleChangeChk = (e) => {
    this.setState({isPreApproved: !!!this.state.isPreApproved});
  };

  handleContactClientChange = (e) => {
    this.state.agentReq.contactClient = !!!this.state.agentReq.contactClient;
    this.setState({agentReq: this.state.agentReq});
  };

  handleWorkingWithAgent = async (event) => {
    if (event.target.value === "Note"){
        this.state.agentReq.working_with_agent = "Add a note";
        this.setState({agentReq: this.state.agentReq});
    }
    else{
      this.state.agentReq.working_with_agent = event.target.value;
      this.setState({agentReq: this.state.agentReq})

    }

  };

  handleAppointmentTimeStamp = async (timestamp) => {
    const agentReq = this.state.agentReq;
    agentReq.appointment_timestamp = timestamp;
    this.setState({agentReq})
  };

  handleAppointmentTimezone = (e) => {
    console.log(e.target.value);
    this.setState({timezone: e.target.value})
  }

  toggleWorkingWithAgent = () => {
    this.state.agentReq.working_with_agent = "Choose a value";
    this.setState({agentReq: this.state.agentReq});
  }

  handleClientAdditionalDetailsChange = (event) => {
    this.state.agentReq.clientAdditionalDetails = event.target.value;
    this.setState({agentReq: this.state.agentReq});
  };

  handleReceiveReferralUpdateChange = () => {
    this.state.agentReq.receiveReferralUpdate = !!!this.state.agentReq.receiveReferralUpdate;
    this.setState({agentReq: this.state.agentReq});
  };

  handleAgentReqFieldChange = (value) => {
    console.log(value);
    const { agentReq } = this.state;
    agentReq.is_pre_approved = value
    this.setState({agentReq: this.state.agentReq});
  };

  handleEmailClientAfterQualification = (e) => {
    this.setState({emailClientAfterQualification: !!!this.state.emailClientAfterQualification});
  };

  handleBlockPhone = (e) => {
    this.setState({blockPhone: !!!this.state.blockPhone});
  }

  handleLookingForaLender = (value) => {
    console.log(">>>!!!", value)
    this.setState({lookingForALender: value})
  }

  handleSenderClientAfterQualification = (e) => {
    this.setState({emailSenderAfterQualification: !!!this.state.emailSenderAfterQualification});
  };

  removePropertyType = (propertType) => {
    let propertyTypes = this.state.propertyTypes;
    propertyTypes = _.reject(propertyTypes, (pt) => pt === propertType);
    this.setState({propertyTypes});
  };

  handlePropertyTypeChange = (e, type) => {
    let propertyTypes;
    if (type === 'requirement') {
      propertyTypes = this.state.propertyTypes;
      propertyTypes.push(e.target.value);
      propertyTypes = _.uniq(propertyTypes);
    }
    else{
      propertyTypes = [e.target.value];
    }
    this.setState({propertyTypes: propertyTypes});
  };

  toggleAllowCalls = () => {
    if ( !!!this.state.autoCalls) {
      this.publish('startAutoCall', this.state.twilioClientName);
    }
    this.setState({autoCalls: !!!this.state.autoCalls});
  };


  handleNeighboorhoodUpdate = (e) => {
    this.setState({neighborhood: e.target.value});
  };

  handleMinimumBudgetUpdate = (e) => {
    this.setState({minBudget: e.target.value});
  };

  handleMaximumBudgetUpdate = (e) => {
    this.setState({maxBudget: e.target.value});
  };

  handleMinimumSizeUpdate = (e) => {
    this.setState({minSize: e.target.value});
  };

  handleMaximumSizeUpdate = (e) => {
    this.setState({maxSize: e.target.value});
  };

  handleBedroomUpdate = (e) => {
    this.setState({beds: e.target.value});
  };

  handleBathroomUpdate = (e) => {
    this.setState({bathrooms: e.target.value})
  };

  postSaveActions = async () => {
    console.log("postSaveActions");
    let body = {};
    let referral = this.state.referral ? this.state.referral : {};
    if (this.state.type === 'Buyer' || this.state.type === 'Renter') {
      body['req_id'] = this.state.requirementId;
    }
    else if (this.state.propertyId) {
      body['prop_id'] = this.state.propertyId;
    }
    body['consultant_agent_id'] = this.props.user.agent_id;
    body['qualification_channel'] = referral.qualification_channel;
    body['qualification_preference'] = referral.qualification_preference;
    body['block_phone'] = this.state.blockPhone;
    body['timezone'] = this.state.timezone;
    body['looking_for_a_lender'] = this.state.lookingForALender;
    body["referral_priority"] = get(this.state, "referral.referral_priority")

    body.type = this.state.type;
    try {
      let url = baseUrl + '/v1.0/consumers/referral/' + this.state.referral.id;
      await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'secure-id': token
        },
        body: JSON.stringify(body)
      });
    }
    catch(e) {
      notify.show('Could not update the referral, please make sure you have entered all the details correctly and try again', 'error', 6000);
      bugsnagClient.notify(e);
    }

    this.updateClient();
    notify.show('This referral has been saved!', 'success', 5000);
    this.setState({saving: false});

  };

  handlePriorityChange = (event) => {
    const { value } = event.target
    const { referral } = this.state
    this.setState({
      referral: {
        ...referral,
        referral_priority: parseInt(value)
      },
    })
  }

  createOrUpdateRequirement = async (type, do_not_notify = null) => {
    try {
      let body = {
        min_budget: this.formatNumber(this.state.minBudget),
        bedroom: this.state.beds,
        max_budget: this.formatNumber(this.state.maxBudget),
        max_size: this.state.maxSize,
        min_size: this.state.minSize,
        types: this.state.propertyTypes && this.state.propertyTypes.length > 0 ? this.state.propertyTypes : ['Apartment'],
        area_unit: "Sq.ft",
        req_id: parseInt(this.state.requirementId),
        agent_id: this.state.referral.sender_id,
        u_cust_id: this.state.referral.sender_client_id,
      };


      if (this.state.locations.length !== 0) {
        if(!this.state.locations[0].latitude || ! this.state.locations[0].longitude){
            ShowErrorToastFromText("Requirement details does not not have correct location data filled. Please delete and reset the locations properly before submitting.");
            return false;
        }
        else {
            body.locations = this.state.locations;
        }
      }

      await this.createOrUpdateNeighborhood();
      if (this.state.requirementId) {
        let sol = await fetch(baseUrl + '/v2.0/requirements/' + this.state.requirementId, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'internal-id': token,
            'update-for-referral': true,
          },
          body: JSON.stringify(body)
        });
          if (type === 'save')
          this.postSaveActions();
        else
          await this.postQualifyActions(do_not_notify);
      }
      else {
        let body = {
          min_budget: this.formatNumber(this.state.minBudget),
          bedroom: this.state.beds,
          max_budget: this.formatNumber(this.state.maxBudget),
          max_size: this.state.maxSize,
          min_size: this.state.minSize,
          types: this.state.propertyTypes && this.state.propertyTypes.length > 0 ? this.state.propertyTypes : ['Apartment'],
          area_unit: "Sq.ft",
          enableSearch: 0,
          sale_type: this.state.type === 'Buyer' ? 'resale' : 'rent',
          u_cust_id: this.state.referral.sender_client_id,//referral receiver
          agent_id: this.state.referral.sender_id,
        };
        if (this.state.bathrooms){
          body.bathroom = this.state.bathrooms;
        }

        body['locations']  = this.state.locations || [];

        let res = await fetch(baseUrl+'/v2.0/requirements', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'secure-id': token,
            'update-for-referral': true,
          },
          body: JSON.stringify(body)
        });
        res = await res.json();
        if (type === 'save') {
          this.setState({requirementId: res.response.req_id}, this.postSaveActions);
        }
        else {
          await this.setState({requirementId: res.response.req_id});
          await this.postQualifyActions(do_not_notify);
        }
      }
    }
    catch(e) {
      notify.show('Could not save requirement, please make sure you have entered all the details correctly and try again', 'error', 6000);
      bugsnagClient.notify(e);
    }
    return true;
  };

  createOrUpdateProperty = async (type, do_not_notify = null) => {
    try {

      if(!this.state.locations[0].latitude || !this.state.locations[0].longitude){
        ShowErrorToastFromText("Property details does not not have correct location data filled. Please delete and reset the locations properly before submitting.");
          return false;
      }
      await this.createOrUpdateNeighborhood();
      if (this.state.propertyId) {
        let body = {
          agent_id: this.state.referral.sender_id,
        };
        if (this.state.bathrooms){
          body['no_of_bath_room'] = this.state.bathrooms;
        }
        body['u_cust_id'] = this.state.referral.sender_client_id; //referral receiver client id
        body['area_coverage'] = 'Sq.ft';
        body['covered_area'] = this.state.maxSize;
        body['locality'] = this.state.locations[0].locality;
        body['city'] = this.state.locations[0].city;
        body['address'] = this.state.locations[0].address;
        body['country'] = this.state.locations[0].country;
        body['latitude'] = this.state.locations[0].latitude;
        body['longitude'] = this.state.locations[0].longitude;
        body['no_of_bed_room'] = this.state.beds;
        body['resale_price'] = this.formatNumber(this.state.maxBudget);
        body['property_type'] =  this.state.propertyTypes && this.state.propertyTypes.length > 0 ? this.state.propertyTypes[0] : 'Apartment';
        body['rent_resale'] = 'resale';
        body['basic_id'] = parseInt(this.state.propertyId);
        let sol1 = await fetch(baseUrl + '/v1.0/user/property/' + this.state.propertyId, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'internal-id': token,
            'update-for-referral': true,
          },
          body: JSON.stringify(body)
        });
          if (type === 'save') {
          this.postSaveActions();
        }
        else {
          await this.postQualifyActions(do_not_notify);
        }
      }
      else {
        let body = {
          property_type: this.state.propertyTypes && this.state.propertyTypes.length > 0 ? this.state.propertyTypes[0] : 'Apartment',
          rent_resale: "resale",
          resale_price: this.formatNumber(this.state.maxBudget),
          u_cust_id: this.state.referral.sender_client_id, //referral receiver client id
          area_coverage: "Sq.ft",
          property_status: "active",
          no_of_bed_room: this.state.beds,
          agent_id: this.state.referral.sender_id,
        };
        if(this.state.locations && this.state.locations.length > 0) {
          body["address"]= this.state.locations[0].address;
          body["latitude"] = this.state.locations[0].latitude;
          body["locality"] = this.state.locations[0].locality;
          body["longitude"] = this.state.locations[0].longitude;
          body["city"] = this.state.locations[0].city;
          body["country"] = this.state.locations[0].country;
        }
        if (this.state.bathrooms){
          body['no_of_bath_room'] = this.state.bathrooms;
        }

        let res = await fetch(baseUrl + '/v1.0/user/property',{
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'secure-id': token,
            'update-for-referral': true,
          },
          body: JSON.stringify(body)
        });
        res = await res.json();
        if(res.response && res.response.basic_id) {
          if (type === 'save')
            this.setState({propertyId: res.response.basic_id}, this.postSaveActions);
          else {
            await this.setState({propertyId: res.response.basic_id});
            await this.postQualifyActions(do_not_notify);
          }
        }
      }
    }
    catch(e){
      notify.show('Could not save property, please make sure you have entered all the details correctly and try again', 'error', 6000);
      bugsnagClient.notify(e);
    }
    return true;
  };

  createOrUpdateNeighborhood = async () => {
    try{
      let selectedNeighborhoods = this.state.selectedNeighborhoods;


      let body = {
        "neighborhoods" : selectedNeighborhoods
      };


      let res = await fetch(baseUrl + '/v1.0/leads/referral-neighborhoods/' + this.state.referral.id, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
        },
        body: JSON.stringify(body)
      });

    }
    catch(e){
      console.log(e);
      bugsnagClient.notify(e);

    }

  }


  updateClient = async() => {
    try{

      const secureToken = await this.getSecureToken(this.state.referral.sender_id);
      const clientBody = {
        types: [this.state.type],
        pre_approved: !!this.state.isPreApproved,
        agent_requirements: JSON.stringify(this.state.agentReq),
      };

      await fetch(baseUrl + '/' + v2Url + 'clients/' + this.state.referral.sender_client_id, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'secure-id': secureToken,
        },
        body: JSON.stringify(clientBody)
      });
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

  getSecureToken = async(agentId) => {
    try {
      let sol = await fetch(baseUrl + '/v2.0/user/secure_id?getAgentDetails=true&agentId=' + agentId, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token
        },
      });
      sol = await sol.json();
      return sol.response.secure_id
    }
    catch(e){
      bugsnagClient.notify(e);
    }

  };

  updateAgentAdditionalDetails = async ({agentAdditionalDetails}) => {
    try {
      const token = process.env.REACT_APP_SECRET_ID;
      const body = {
        agent_id : this.state.referral.sender_id,
        admin_note: agentAdditionalDetails,
      };
      let res = await fetch(baseUrl + '/v1.0/admin-notes-for-agent', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
        },
        body: JSON.stringify(body),
      });
      if (res.status === 200) {
        this.state.referral.admin_note = agentAdditionalDetails
        this.setState({referral: this.state.referral})
      }
    } catch (e) {
      bugsnagClient.notify(e);
    }
  };

  updateClientDetails = async (client) => {
    try {
      const {name, email, phone, type} = client;
      let clientId = this.state.referral.sender_client_id;
      let agentId = null;

      if (this.state.referral && this.state.referral.primary_client_id) {
        clientId = this.state.referral.primary_client_id;
      }

      let phones = this.state.client.phones || [];
      if (phones.length === 0) {
        phones = [{primary: true, phone: phone, type: "Work"}];
      }

      _.each(phones,
        (phoneObj) => {
          if (phoneObj.primary){
            phoneObj.phone = phone;
          }
      });
      let emails = this.state.client.emails || [];
      if (emails.length === 0) {
        emails = [{primary: true, email: email, type: "Work"}];
      }

      _.each(emails,
        (emailObj) => {
          if (emailObj.primary){
            emailObj.email = email;
          }
      });
      if (this.state.referral) {
        agentId =  this.state.referral.primary_client_id ? this.state.referral.primary_agent_id : this.state.referral.sender_id;

      }
      const secureToken = await this.getSecureToken(agentId);
      const body = {
        name: name, phones: phones, emails: emails, types: [type],
      };

      let resp = await fetch(baseUrl + '/' + v2Url + 'clients/' + clientId, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'secure-id': secureToken,
          'update-for-referral': true,
        },
        body: JSON.stringify(body),
      });
      let sol = await resp.json();
      let updatedClient= this.state.client;
      let updatedType = this.state.type;
      if (sol && sol.response) {
        updatedClient.name = name;
        updatedClient.phones = phones;
        updatedClient.emails = emails;
        updatedType = type;
        ShowSuccessToast("Client info updated successfully!");
      } else {
        bugsnagClient.notify('Error found while updating client info: ' + JSON.stringify(sol));
        ShowErrorToast("Could not update client info!");
      }
      this.setState({client: updatedClient, type: updatedType});
    }
    catch(e){
      bugsnagClient.notify(e);
    }


  };

  handleSubmit = async () => {
    const { lookingForALender } = this.state

    if (this.state.agentReq.working_with_agent === "Choose a value") {
      ShowErrorToastFromText("Missing value. Fill all the mandatory values.");
      return;
    }

    if (
      this.state.type === "Buyer"
      && (
        (lookingForALender === undefined || lookingForALender === null)
        || this.state.agentReq.is_pre_approved === undefined
      )
    ) {
      ShowErrorToastFromText("Missing value. Fill all the mandatory values.");
      return;
    }
    this.setState({submitting: true});

    try{
      let success = true;
      if (this.state.type === 'Buyer' || this.state.type === 'Renter') {
        success = await this.createOrUpdateRequirement('qualify');
        if(!success) {
            this.setState({submitting: false})
        }
      }
      else {
        success = await this.createOrUpdateProperty('qualify');
          if(!success) {
              this.setState({submitting: false})
              this.refreshClientInfo()
          }
      }
    }
    catch(e){
      this.setState({submitting: false});
      bugsnagClient.notify(e);
    }

  };

  handleSubmitWithReferOut = async () => {
    const { lookingForALender } = this.state

    const { props } = this
    if (
      this.state.agentReq.working_with_agent === "Choose a value"
      || (lookingForALender === undefined || lookingForALender === null)
      || this.state.isPreApproved === undefined
      ) {
      ShowErrorToastFromText("Missing value: Working with an agent?");
      return;
    }
    this.setState({submitting: true});

    try{
      let success = true;
      if (this.state.type === 'Buyer' || this.state.type === 'Renter') {
        success = await this.createOrUpdateRequirement('qualify', true);
        if (success) {
          success = await this.referOut();
        }
        if(!success) {
          this.setState({submitting: false})
        }
      }
      else {
        success = await this.createOrUpdateProperty('qualify', true);
        if (success) {
          success = await this.referOut();
        }
        this.setClientDetailsFromSearch(props.referral.receiver_client_id, this.state.phone)
        if(!success) {
          this.setState({submitting: false})
          this.refreshClientInfo()
        }
      }
    }
    catch(e){
      this.setState({submitting: false});
      bugsnagClient.notify(e);
    }

  };

  referOut = async () => {
    try{
      //remove agent first
      let body = {
        agentIdsToRemove: [this.state.referral.sender_id],
        referral_id: this.state.referral.id,
      };
      let sol = await fetch( baseUrl +'/v1.0/consumer/' + this.state.referral.receiver_client_id, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
        },
        body: JSON.stringify(body)
      });

      //Update lead prospect, reject it and refer it out.
      let prospectBody = {
        referral_id: this.state.referral.id,
        referral_type: "isa",
        prospect_status: 2
      };
      let prospectResponse = await fetch( baseUrl +'/v2.0/prospects/' + this.state.referral.sender_id, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
        },
        body: JSON.stringify(prospectBody)
      });
      //if primary agent, update state in other components
      // probably a bad way to handle state - will redux fix this?
      if (this.state.referral.primary_agent_id === this.state.referral.sender_id) {
        this.togglePrimaryAgentFromChild(this.state.referral.sender_id, null, 'remove');
        if (this.state.referral.is_isa === 1) {
          let url = baseUrl + '/v1.0/consumers/referral/' + this.state.referral.id;
          let body = {
            referred_client_status: "Qualified",
            consultant_agent_id: this.props.user.agent_id,
          };

          let sol = await fetch(url, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'secure-id': token
            },
            body: JSON.stringify(body)
          });
          this.setUpStage('Qualified');
        }
      }
      await this.loadClientDetails(this.state.referral.id, 'referral_id');
      this.setUpStage('Qualified');
    }
    catch(e){
      bugsnagClient.notify(e);
      return false;
    }

    return true;
  };

  saveRef = async () => {
      this.setState({saving: true, apiCall: true});

      await this.updateClient();
      try {
        let success = true;
        if (this.state.type === 'Buyer' || this.state.type === 'Renter') {
          success = await this.createOrUpdateRequirement('save');
        }
        else {
          success = await this.createOrUpdateProperty('save');
        }
        if (!success){
          this.setState({saving:false, apiCall: false});
        }else{
            this.setState({apiCall: false})
        }
      }
      catch(e) {
        this.setState({apiCall: false});
        bugsnagClient.notify(e);
      }
  };

  postQualifyActions = async (do_not_notify) => {
    this.setState({apiCall: true});
    await this.updateClient();
    await this.qualify(do_not_notify);
    this.setState({submitting: false, apiCall:false});
  };

  resetAssignedAgents = async () => {
    const field = normalize(this.state.toNumber);
    let resp = await fetch(baseUrl + '/v1.0/caller/client-details?client_phone=' + field + "&twilio_client_name=" + this.state.twilioClientName, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json' ,
        'internal-id': token,
      },
    });
    try {
      let sol = await resp.json();
      if (sol && sol.response) {
        this.setState(
            {
              assignedAgentObjects: sol.response.assigned_agents ?  sol.response.assigned_agents : [],
              assignedAgents: sol.response.assigned_agents ?  sol.response.assigned_agents.map(agent => agent.id) : [],
            });
      }
    }
    catch(e) {
      //notify.show('Could not fetch client details for: '  + normalize(this.state.toNumber), 'error', 6000);
    }
  };

  assign = async (id) => {
    this.setState({apiCall: true});
    try{
      let assignedAgents = this.state.assignedAgents;

      let body = {
        agentIdsToAssign: [id],
        referral_id: this.state.referral.id,
      };
      //referral receiver client id
      await Fetch( baseUrl +'/v1.0/consumer/' + this.state.referral.receiver_client_id, {
        method: 'POST',
        body: JSON.stringify(body)
      }).then(async (res) => {
        await this.resetAssignedAgents();
        assignedAgents = this.state.assignedAgents;
        assignedAgents.push(id);
        this.setState({assignedAgents: assignedAgents, apiCall: false});
        ShowSuccessToast();
      }).catch(e => {
        ShowErrorToast(e);
        this.setState({apiCall: false});
      });
    }
    catch(e) {
      this.setState({apiCall: false});
    }
  };


  qualify = async (do_not_notify) => {
    let url = baseUrl + '/v1.0/consumers/referral/' + this.state.referral.id;
    let referral = this.state.referral ? this.state.referral : {};
    let body = {
      referred_client_status: "Qualified",
      createProspects: true,
      consultant_agent_id: this.props.user.agent_id,
      email_client_after_qualification: this.state.emailClientAfterQualification,
      email_sender_agent_after_qualification: this.state.emailSenderAfterQualification,
      qualification_channel : referral.qualification_channel,
      qualification_preference : referral.qualification_preference,
      block_phone :this.state.blockPhone,
      looking_for_a_lender: this.state.lookingForALender,
      timezone: this.state.timezone
    };

    if (do_not_notify) {
      body["email_sender_agent_after_qualification"] = false;
      body["notify_sender_agent"] = false;
    }

    if (this.state.type === 'Buyer' || this.state.type === 'Renter') {
      body['req_id'] = this.state.requirementId;
    }
    else if (this.state.propertyId) {
      body['prop_id'] = this.state.propertyId;
    }

    body["referral_priority"] = get(this.state, "referral.referral_priority")

    body.type = this.state.type;

    try {
      let sol = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'secure-id': token,
        },
        body: JSON.stringify(body)
      });
      sol = await sol.json();

      if (!sol.error){
        notify.show('This referral has been qualifed!', 'success', 10000);
        let referral = this.state.referral;
        let stage =  this.state.referral.is_isa ? 4 : 3; //move to searching
        referral.referred_client_status = 'Qualified';
        if (this.state.referral.is_isa){
          const agentIdToAssign = this.state.referral.sender_id;
          //await this.assign(agentIdToAssign);

          /*
          //set it to qualify again.
          let url = baseUrl + '/v1.0/consumers/referral/' + this.state.referral.id;
          let body = {
            referred_client_status: "Qualified",
            consultant_agent_id: this.props.user.agent_id,
          };
          try {
            let qualifiedRes = await fetch(url, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'secure-id': token
              },
              body: JSON.stringify(body)
            });
          }
          catch(e){
            bugsnagClient.notify(e);
          }*/

        }
        this.setState({submitting: false, stage: stage, referral: referral, apiCall: false, fromScreen: 2});
      }
      else{
        throw sol.error.message;
      }
    }
    catch(e) {
      bugsnagClient.notify(e);
      this.setState({apiCall: false});
      notify.show('Could not update referral, please make sure you have entered all the details correctly and try again', 'error', 6000);
    }
  };

  hold = async () => {
    let url = baseUrl + '/v1.0/consumers/referral/' + this.state.referral.id;
    let body = {
      referred_client_status: 'Search Paused',
      consultant_agent_id: this.props.user.agent_id,
    };
    body['consultant_agent_id'] = this.props.user.agent_id;
    body["referral_priority"] = this.state.assistLeadPriority || 0
    if (this.state.type === 'Buyer' || this.state.type === 'Renter') {
      body['req_id'] = this.state.requirementId;
    }
    else if (this.state.propertyId) {
      body['prop_id'] = this.state.propertyId;
    }

    body.type = this.state.type;

    await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'secure-id': token
      },
      body: JSON.stringify(body)
    });
    notify.show('This referral has been put on hold!', 'success', 5000);
  };

  callClient = (cb, number = null) => {
    if (number) {
      this.setState({toNumber: normalize(number)}, this.actOnClick);
    }
    else {
      this.actOnClick();
    }
    cb();
  };

  copyLink = () => {
    let textField = document.createElement('textarea');
    textField.innerText = blinkBaseUrl;
    if (this.state.currentReferralId) {
      textField.innerText += "?referral_id=" + this.state.currentReferralId;
    }
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  saveAndSwitch = async (shouldSaveLocally, onCall = false) => {
    this.updatePopupState();
    this.updateUnsavedProgressPopupState();
    if (shouldSaveLocally) {
      this.saveUnsavedReferralInLocalStorage(onCall);
    }
    this.updateTask();
  };

  updateTask = () => {
    let body = {
      twilio_client_name: this.state.twilioClientName,
      call_sid: this.state.acceptingCallSid,
    };
    updateTaskApi(body
      ).then(res => {
          ShowSuccessToast('Connecting...');
      }).catch(err => {
          ShowErrorToastWithCustomMessage(err);
      });
  };

  updatePopupState = () => {
    this.setState({acceptingCallWhileOnCall: false});
  };

  updateUnsavedProgressPopupState = () => {
    this.setState({showSaveProgressAlertDialog: false});
  };

  checkForUnsavedReferrals = () => {
    let unsavedReferrals = this.getUnsavedReferrals();
    let length = unsavedReferrals ? unsavedReferrals.length : 0;
    if (this.state.unsavedWriteUpsCount !== length) {
      this.setState({unsavedWriteUpsCount: length});
    }
    return length > 0;
  }

  compare = (a, b) => {
    if (a.updated_at < b.updated_at) return 1;
    if (a.updated_at > b.updated_at) return -1;
    return 0;
  };
  handleSearchChange = async (e) => {
    const self = this;
    const currentSearchText = e.target.value;
    this.setState({searchText: currentSearchText, showSearch: true});
    _.debounce(async () => {
      let body =  {
        "text": currentSearchText,
        "sort_by": "created_at",
        "ascending": false,
        "aggregationNotRequired": false,
        "secure_id": token,
        "start": 0,
        "end": 50,
        "agentId": 484725, //trustle agentId
      };

      try {
        //normalize search results for use in the render method.
        let sol = await fetch(baseUrl + '/v2.0/clients/search', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'internal-id': token,
          },
          body: JSON.stringify(body)
        });
        sol = await sol.json();
        let clients = sol.response && sol.response.clients ? sol.response.clients.map((client) => {
            let cli = {

            };
            cli.phone = client.phones && client.phones.length > 0 ? (client.phones[0] ? client.phones[0].phone : null) : null;
            cli.name = client.name;
            cli.id = client.id;
            cli.sender_agent = client.sender_agent;
            return cli;
        }): [];
        /*clients = _.filter(clients, (cli) => {
          if (cli.phone && cli.phone !== "")
            return true;
        });*/
        this.setState({searchResultClients: clients, invitedAgents: []})
      }
      catch(e) {
        bugsnagClient.notify(e);
      }
    }, 500)();
  };

  checkForZillowMergeFlow = () => {
    const { isShowZillowMergeModal } = this.state
    if (isShowZillowMergeModal) {
      store.dispatch(ZillowMergeActions.toggleZillowMergeModalAction.call(true))
      this.setState({
        isShowZillowMergeModal: false,
      })
    }
  }

  setClientDetailsFromSearch = (id, phone) => {
    const self = this;
    this.setState({showSearch : false, searchText: '', toNumber: phone, stage: 1}, () => {
      /*if (!phone) {
        //notify.show('The selected client does not have a valid phone number', 'error', 500);
      }
      else {
        try {
          self.loadClientDetails(id, 'client_id');
          self.loadNotes();
        }
        catch(e) {
          notify.show(e, 'error', 5000);
        }
      }*/
      try {
        self.loadClientDetails(id, 'client_id');
      }
      catch(e) {
        notify.show(e, 'error', 5000);
      }
    });
  };

  sendText = async() => {
    try {
      this.setState({sendingText: true});

      const body = {
        participant:
        [
          {id: '484725',
           isOwner: true,
           type: 'agent',
          },
          {
            id: this.state.referral.receiver_client_id,
            isOwner: false,
            type: 'client'
          }
        ]
      };
      let threadIdObject = await fetch(baseUrl + '/v1.0/gabby/thread/createOrRetrieve?agentId=484725', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
          'X-User-Agent': 'agent-php',
        },
        body: JSON.stringify(body)
      });
      threadIdObject = await threadIdObject.json();
      const threadId = threadIdObject.response.id;
      let smsBody = {
        adminAgentId: this.props.user.agent_id,
        threadId: threadId,
        channel : 'sms',
        clientMessageId: uuidv1(),
        sentAt: new Date().getTime(),
        type: 'text',
        platform: 'web',
        make: "chrome",
        senderKey: 'agent_484725',
        message: this.state.text,
        auto: false,
      };
     await fetch(baseUrl + '/v1.0/gabby/sms/send-from-admin?agentId=484725', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
          'X-User-Agent': 'agent-php',
        },
        body: JSON.stringify(smsBody)
      });
      this.setState({text: '', sendingText: false});
      notify.show('Text sent successfully ', 'success', 2000);
      this.loadMessages();

    }
    catch(e){
      this.setState({text: '', sendingText: false});
      notify.show('Text could not be sent ', 'success', 2000);
      bugsnagClient.notify(e);
    }
  };


  invalidBudget = () => {
    if ( this.state.maxBudget === "" || !("maxBudget" in this.state)){
      return true;
    }
    return false;
  }

  checkFormValidity = () => {
    const { lookingForALender } = this.state
    if (
      this.state.type === "Buyer"
      && (
        (lookingForALender === undefined || lookingForALender === null)
        || this.state.agentReq.is_pre_approved === undefined
      )
    ) {
      return true
    }
    return false
  }

  getQualifyColor = () => {

    const disabled = (this.invalidBudget() || this.state.submitting || (this.state.locations && this.state.locations.length === 0 ) || this.checkFormValidity());
    if(disabled) {
      return "med-btn-1-disabled";
    }
    return "med-btn-1";
  }

  populatePhoneNumber = () => {
    try{
        if (this.state.client && this.state.client.phones){
          this.setState({toNumber: normalize(this.state.client.phones[0].phone)});
          this.getMaskedPhoneNumber(this.state.client.phones[0].phone);
        }

    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };
  loadMessages = async () => {
    try{
      const body = {
        participant:
        [
          {id: '484725',
           isOwner: true,
           type: 'agent',
          },
          {
            id: this.state.referral.receiver_client_id,
            isOwner: false,
            type: 'client'
          }
        ]
      };
      let threadIdObject = await fetch(baseUrl + '/v1.0/gabby/thread/createOrRetrieve?agentId=484725', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
          'X-User-Agent': 'agent-php',
        },
        body: JSON.stringify(body)
      });
      threadIdObject = await threadIdObject.json();

      let messageObject = await fetch(baseUrl + '/v3.0/gabby/thread?agentId=484725&id=' + threadIdObject.response.id, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
          'X-User-Agent': 'agent-php',
        },
      });

      messageObject = await messageObject.json();
      this.setState({messages: messageObject.response.message || [] })
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

  clearSearch = () => {
    this.setState({showSearch : false, searchText: '',searchResultClients: []});
  };

  markAsVoiceMail = async (cb) => {
    let url =  `${baseUrl}/v1.0/gabby/update-voicemail?${this.state.toNumber ? `To=${normalize(this.state.toNumber || "")}` : ""}&ReferralId=${this.state.referral ? this.state.referral.id : ""}&CallSid=${this.state.callSid}` ;
    if (this.state.autoCalls) {
      url += '&auto=true';
    }
    try {
      let sol = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'secure-id': token
        },
      });

    }
    catch(e) {
      bugsnagClient.notify(e);
    }
    cb();
    this.disconnectCall();
  };

  handleValueChange = (e) => {
    this.setState({[e.target.id]: e.target.value});
  };

  makePrimary = async (id) => {
    let body = {
      primary_agent_id: id,
      consultant_agent_id: this.props.user.agent_id,
     };
     this.setState({primaryAgentId: id});
     let url = baseUrl + '/v1.0/consumers/referral/' + this.state.referral.id;
     let sol = await fetch( url, {
       method: 'POST',
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'internal-id': token,
        },
       body: JSON.stringify(body)
     });
  };

  callNow = () => {
    try{
      const self = this;
      this.setState({connectingWithClient: true,}, () => {
        self.publishWithJson('call',self.state.twilioClientName, self.state.nextCall.next_referral.id);
        this.clearCountdownTimer();
        this.setUpConnectedTimer();
      });
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

  publishStopAutoCall = () => {
    this.publish('stopAutoCall',this.state.twilioClientName);
  };

   sendCallInProgressEvent = (referralId) => {
     this.publishWithJson('callInProgress', this.state.twilioClientName, referralId);
   };

  cancelNow = () => {
    this.publishStopAutoCall();
    this.toggleAllowCalls();
    this.clearCountdownTimer();
    if (this.state.nextCall && !this.state.nextCall.next_referral){
      this.refreshQueue();
    }

  };

  removePrimary = async (id) => {
    try{
      const assignedAgents = _.filter(this.state.assignedAgents, (agentId) => agentId !== id);
      this.setState({primaryAgentId: null, assignedAgents: assignedAgents});
      let body = {
        agentIdsToRemove: [id],
        referral_id: this.state.referral.id,
       };
       //referral receiver client id
       let sol = await fetch( baseUrl +'/v1.0/consumer/' + this.state.referral.receiver_client_id, {
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'internal-id': token,
          },
         body: JSON.stringify(body)
       });
    }
    catch(e){
      bugsnagClient.notify(e);
    }

  };

  //resend the notification to agent if rejected/referred out
  resendProspectNotification = async (close) => {
    try{
      let prospectBody = {
        referral_id: this.state.referral.id,
        referral_type: "isa",
        prospect_status: 0,
        resend_notification: true,
      };
      let url  =  baseUrl +'/v2.0/prospects/' + this.state.referral.sender_id;
      let resp = await fetch(url, {
         method: 'PUT',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'internal-id': token,
          },
         body: JSON.stringify(prospectBody)
       });
      resp = await resp.json();
      notify.show('Successfully converted back to ISA - wait till the agent accepts it', 'success', 5000);
      close();
      this.loadClientDetails(this.state.referral.id, 'referral_id');

    }
    catch(e){
      notify.show('Something went wrong - this action was not successful', 'error', 5000);
      bugsnagClient.notify(e);
    }
  };


  disqualifyReferral = async (reason) => {
    try{
      let url = baseUrl + '/v1.0/consumers/referral/' + this.state.referral.id;
      const reasonId = this.state.disqualificationReasons[reason][0].id;
      const body = {
        disqualify_reason_id: reasonId,
        referred_client_status: "Disqualified",
        consultant_agent_id: this.props.user.agent_id,
      };
      await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'secure-id': token
        },
        body: JSON.stringify(body)
      });
      let referral = this.state.referral;
      referral.referred_client_status = 'Disqualified';
      this.setState({referral: referral});
      notify.show('This referral has been disqualified', 'success', 5000);
    }
    catch(e){
      bugsnagClient.notify(e);
    }
  };

  updateState(data) {
    this.setState(data);
  }

  updateAgentReq (key, value) {
    let agentReq= this.state.agentReq;
    agentReq[key] = value;
    this.setState({agentReq});
  }

  saveAdditionalRequirements = async () => {
    try{
      const secureToken = await this.getSecureToken(this.state.referral.sender_id);
      const clientBody = {
        types: [this.state.type],
        agent_requirements: JSON.stringify(this.state.agentReq),
        'update-for-referral': true,
      };
      let result = await fetch(baseUrl + '/' + v2Url + 'clients/' + this.state.referral.sender_client_id, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'secure-id': secureToken,
        },
        body: JSON.stringify(clientBody)
      });
      notify.show('Additional Requirements have been updated', 'success', 5000);
    }
    catch(e){
      notify.show('Could not update additional requirements', 'failure', 5000);
      bugsnagClient.notify(e);
    }
  };

  handlePopupOpen = () => {
    this.setState({popupOpen: true});
  };

  handlePopupClose = () => {
    this.setState({popupOpen: false});
  };

  handleClickAwayFromNeighborhoods = () => {
    this.setState({neighborhoodDropdownValues: []})
  }

  saveUnsavedReferralInLocalStorage = (onCall = false) => {
    if (!this.state.referral || !this.state.referral.id) {
      return;
    }
    let unsavedWriteUps = this.getUnsavedReferrals();
    let newUnsavedWriteUps = [];
    if (unsavedWriteUps) {
      newUnsavedWriteUps = unsavedWriteUps.filter((item) => {
        return item.response.referral.id !== this.state.referral.id
      });
    }
    if (!newUnsavedWriteUps) {
      newUnsavedWriteUps = [];
    }
    let sol = {
      response: {
        referral: this.state.referral,
        client: this.state.client,
        call_details: this.state.callDetails,
        assigned_agents: this.state.assignedAgentObjects,
        status_history: this.state.status_history,
      },
    }
      sol.response.referral.type = this.state.type
      sol.response.client.types = [sol.response.referral.type]
      sol.response.client.pre_approved = this.state.isPreApproved
      sol.response.client.agent_requirements = JSON.stringify(this.state.agentReq)

    if (this.state.type === 'Seller') {
      sol.response.property = {
        locality: this.state.locations && this.state.locations[0] ? this.state.locations[0].locality : null,
        latitude: this.state.locations && this.state.locations[0] ? this.state.locations[0].latitude : null,
        longitude: this.state.locations && this.state.locations[0] ? this.state.locations[0].longitude : null,
        address: this.state.locations && this.state.locations[0] ? this.state.locations[0].address : null,
        property_name: this.state.locations && this.state.locations[0] ? this.state.locations[0].property_name : null,
        city: this.state.locations && this.state.locations[0] ? this.state.locations[0].city : null,
        state: this.state.locations && this.state.locations[0] ? this.state.locations[0].state : null,
        no_of_bed_room: this.state.beds,
        no_of_bath_room: this.state.bathrooms,
        resale_price: this.state.maxBudget,
        property_type: this.state.propertyTypes ? this.state.propertyTypes[0] : null,
        covered_area: this.state.maxSize,
        basic_id: this.state.propertyId,
      }
    } else {
      sol.response.requirement = {
        min_budget: this.state.minBudget,
        max_budget: this.state.maxBudget,
        max_size: this.state.maxSize,
        min_size: this.state.minSize,
        types: this.state.propertyTypes,
        req_id: this.state.requirementId,
        bedroom: this.state.beds,
        bathroom: this.state.bathrooms,
      }
      if (this.state.locations) {
        let locations = []
        this.state.locations.map((item) => {
          locations.push({
            c_locality_name: item.locality,
            c_locality_latitude: item.latitude,
            c_locality_longitude: item.longitude,
          })
        })
        sol.response.requirement.locations = locations
      }
    }

    if (this.state.selectedNeighborhoods) {
      sol.response.referral_neighborhoods = this.state.selectedNeighborhoods
    }

    sol.added_at = moment.now()
    sol.onCall = onCall
    newUnsavedWriteUps.push(sol)
    localStorage.setItem("pending_write_ups", JSON.stringify(newUnsavedWriteUps));
    this.setState({
      unsavedWriteUpsCount: newUnsavedWriteUps.length,
      requestUnsavedClientInfo: true,
      requestUnsavedNote: true,
    })
  }

  refreshClientInfo = () => {
    const clientId = get(this.props, "referral.receiver_client_id")
    const clientPhone = get(this.state, "tempClient.phone")
    this.setClientDetailsFromSearch(clientId, clientPhone)
  }

  saveUnsavedClientInfoLocally = (client) => {
    if (!client || !this.state.client || !this.state.referral || !this.state.referral.id) {
      return;
    }

    let unsavedReferrals = this.getUnsavedReferrals();
    if (unsavedReferrals) {
      let filteredResults = unsavedReferrals.filter((item) => item.response.referral.id === this.state.referral.id);
      if (!filteredResults || !filteredResults[0]) {
        return;
      }
      let unsavedReferral = filteredResults[0]

      unsavedReferral.response.tempClient = {}
      if (this.state.client.name !== client.name) {
        unsavedReferral.response.tempClient.name = client.name
      }

      if (this.state.client.emails && this.state.client.emails[0] && this.state.client.emails[0].email !== client.email) {
        unsavedReferral.response.tempClient.email = client.email
      }

      if (this.state.client.phones && this.state.client.phones[0] && this.state.client.phones[0].phone !== client.phone) {
        unsavedReferral.response.tempClient.phone = client.phone
      }

      if (this.state.type !== client.type) {
        unsavedReferral.response.tempClient.type = client.type
      }

      if (this.state.referral.admin_note !== client.agentAdditionalDetails) {
        unsavedReferral.response.tempClient.admin_note = client.agentAdditionalDetails
      }

      if (client.hasOwnProperty("auto_sms")) {
        unsavedReferral.response.tempClient.auto_sms = client.auto_sms
      }

      if (client.hasOwnProperty("auto_call")) {
        unsavedReferral.response.tempClient.auto_call = client.auto_call
      }

      if (client.hasOwnProperty("allow_missed_call_notifications")) {
        unsavedReferral.response.tempClient.allow_missed_call_notifications = client.allow_missed_call_notifications
      }

      let otherUnsavedReferrals = unsavedReferrals.filter((item) => item.response.referral.id !== this.state.referral.id);
      otherUnsavedReferrals.push(unsavedReferral);
      localStorage.setItem("pending_write_ups", JSON.stringify(otherUnsavedReferrals));
      this.setState({requestUnsavedClientInfo: false})
    }
  }

  saveUnsavedNoteLocally = (tempNote) => {
    if (!tempNote || !this.state.referral || !this.state.referral.id) {
      return;
    }

    let unsavedReferrals = this.getUnsavedReferrals();
    if (unsavedReferrals) {
      let filteredResults = unsavedReferrals.filter((item) => item.response.referral.id === this.state.referral.id);
      if (!filteredResults || !filteredResults[0]) {
        return;
      }
      let unsavedReferral = filteredResults[0]
      unsavedReferral.response.tempNote = tempNote

      let otherUnsavedReferrals = unsavedReferrals.filter((item) => item.response.referral.id !== this.state.referral.id);
      otherUnsavedReferrals.push(unsavedReferral);
      localStorage.setItem("pending_write_ups", JSON.stringify(otherUnsavedReferrals));
      this.setState({requestUnsavedNote: false})
    }
  }

  clearAllUnsavedReferrals = () => {
    localStorage.removeItem("pending_write_ups")
    this.setState({unsavedWriteUpsCount: 0})
  }

  openUnsavedReferral = (writeUp) => {
    let additionalQueryParams = "&isPageInactive=true&purpose=writeups";
    if (this.state.breakEnabled) {
      additionalQueryParams += "&breakEnabled=" + this.state.breakEnabled;
    }
    let url = blinkBaseUrl + "?referral_id=" + writeUp.response.referral.id + additionalQueryParams;
    window.open(url);
  }

  removeUnsavedReferral = (e, writeUp) => {
    e.stopPropagation()
    let unsavedWriteUps = this.getUnsavedReferrals();
    if (unsavedWriteUps) {
      let newUnsavedWriteUps = unsavedWriteUps.filter((item) => {return item.response.referral.id !== writeUp.response.referral.id});
      if (newUnsavedWriteUps) {
        localStorage.setItem("pending_write_ups", JSON.stringify(newUnsavedWriteUps));
        this.setState({unsavedWriteUpsCount: newUnsavedWriteUps.length})
      } else {
        this.clearAllUnsavedReferrals();
      }
    }
  }

  getUnsavedReferrals = () => {
    return JSON.parse(localStorage.getItem("pending_write_ups"));
  }

  render() {
    const { client, referral } = this.state
    let  preferencesDiv = null;
    if (this.state.referral && this.state.referral.user_referral_settings && this.state.referral.is_isa && this.state.userReferralSettings){
      preferencesDiv = getPreferencesDiv(this.state.referral.user_referral_settings, this.state.referral, this.state.userReferralSettings);
    }

    let prefNotesDiv = null;
    if (this.state.referral && this.state.referral.assist_preferences && this.state.referral.is_isa){
      prefNotesDiv = getAssistPreferencesDiv(this.state.referral.assist_preferences);
    }
    const neighborhoodDiv = this.state.neighborhoodDropdownValues && this.state.neighborhoodDropdownValues.length > 0 ? this.state.neighborhoodDropdownValues.map((val) => <React.Fragment><span onClick={() => {  this.addNeighborhood(val)}}>{val ? ((val.name) + ',' + (val.city)) : null}</span><hr/></React.Fragment> ): null;

    const {
      id: clientId,
      agent_id: agentId,
    } = client || {}
    try {
      let statusHistory = null;
      let adminAccess = allowedAccountIds.indexOf(this.props.user.id) !== -1;
      let referralText = '';
      let nextReferral = null;
      let prevCallStatus = '';
      let enquiryDate = null;
      let allowAutoCallerMode = !!(this.props.user && this.props.user.auto_call_allowed === 1);

      if (!this.state.status) {
        this.state.status = 'offline';
      }
      let referral = this.state.referral ?  this.state.referral : {};

      let qualificationChannelDiv = (
          <form style={{"margin-left":"36px"}}>
              Qualification Channel
              <span className="radio">
                <label>
                <input type="radio" value="call" name={'call'}
                       checked={referral.qualification_channel === 'call'}
                       onChange={this.handleQualificationChannel}/>
                Call
                </label>
              </span>
              <span className="radio">
                <label>
                  <input type="radio" value="sms" name={'sms'}
                         checked={referral.qualification_channel === 'sms'}
                         onChange={this.handleQualificationChannel}/>
                  Sms
                </label>
              </span>
          </form>
      );


      let qualificationPreferenceDiv = (
        <form style={{"margin-left":"36px"}}>
            Qualification Preference
          <span className="radio">
            <label>
            <input type="radio" value="FULL" name={'FULL'}
                   checked={referral.qualification_preference === 'FULL'}
                   onChange={this.handleQualificationPreference}/>
            Full
            </label>
          </span>
          <span className="radio">
            <label>
              <input type="radio" value="PARTIAL" name={'PARTIAL'}
                     checked={referral.qualification_preference === 'PARTIAL'}
                     onChange={this.handleQualificationPreference}/>
              Partial
            </label>
          </span>
          <span className="radio">
            <label>
              <input type="radio" value="MINIMAL" name={'MINIMAL'}
                     checked={referral.qualification_preference === 'MINIMAL'}
                     onChange={this.handleQualificationPreference}/>
              Minimal
            </label>
          </span>

        </form>
      );

      let unsavedItems = [];
      if (this.state.unsavedWriteUpsCount > 0) {
        let unsavedWriteUps = this.getUnsavedReferrals();
        if (unsavedWriteUps) {
          unsavedItems.push(<ClearButton onClick={() => this.clearAllUnsavedReferrals()}>Remove All</ClearButton>)
          unsavedWriteUps.map((writeUp) => {
            unsavedItems.push(<div key={writeUp.response.referral.id} className="unsaved-write-up-item" onClick={() => {
              this.openUnsavedReferral(writeUp);
            }}>
                <DetailsWrap>
                  <InnerWrap>
                    <ImageWrap>
                      <LetterAvatar name={writeUp.response.client.name}/>
                    </ImageWrap>
                    <ContentWrap>
                      <div style={{"color": "black"}}>{writeUp.response.referral.id ? "Lead Id: #" + writeUp.response.referral.id : ""}</div>
                      <div style={{
                        "color": "black",
                        "padding-top": "5px"
                      }}>Client Name: {writeUp.response.tempName ? writeUp.response.tempName.toUpperCase() : writeUp.response.client.name ? writeUp.response.client.name.toUpperCase() : "N/A"}</div>
                      <div style={{"color": "black", "padding-top": "5px"}}>Sender Agent's Name: {writeUp.response.referral.sender_name}</div>
                      <div style={{"color": "#8c8c8c", "padding-top": "5px"}}>Added at: {writeUp.added_at ? moment(new Date(writeUp.added_at)).format("YYYY-MM-DD hh:mm a") : ""}</div>
                    </ContentWrap>
                    <CloseWrap>
                      <CloseBTN className="fa fa-times" onClick={(e) => {this.removeUnsavedReferral(e, writeUp)}}></CloseBTN>
                    </CloseWrap>
                  </InnerWrap>
                </DetailsWrap>
            </div>)
          })
        }
      }

      let propertyTypesDiv = this.state.propertyTypes.map((propertType) =>
        <Popup
        client={this.state.client}
        trigger={
          <span className="selected-cities">
            {propertType}
            <span className="close-1">X</span>
          </span>
        }
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
            {close => (
                <div className="modal-2">
                <div className="modal-header">
                    <span className="modal-header-text">
                        Are you sure you want to continue with this action?
                    </span>
                </div>
                <div className="dialogue-box-footer">
                    <button className="med-btn-1" onClick={() => {this.removePropertyType(propertType); close();}}>
                        YES
                    </button>
                    <button className="med-btn-1" onClick={()=> {close();}}>
                        NO
                    </button>
                </div>
          </div>

            )}
        </Popup>
      );


      if (this.state.nextCall) {
        nextReferral = this.state.nextCall.next_referral;
        if (nextReferral){
          referralText = 'Next Caller: ' + (nextReferral.is_isa === 1 ? 'ISA ': ' ')+ (nextReferral.Type || '')+ ' ' + nextReferral.ClientName + ' referred by ' + nextReferral.SenderName;
          prevCallStatus =  this.state.nextCall.prev_call_status;
          if (prevCallStatus === 'no-answer-machine') {
            prevCallStatus = 'Voice Mail';
          }
        }
        else{
          referralText = 'All calls are done, please press cancel to continue';
          if (this.state.nextCall.prev_call_status) {
            prevCallStatus = this.state.nextCall.prev_call_status
          }
        }

        if (nextReferral && nextReferral.received_date){
          let enqDate = moment(nextReferral.received_date);
          enquiryDate = enqDate.utc().format('MMMM Do YYYY');
        }
      }

      //refactor above part
      if (this.state.referral)
         statusHistory = this.state.status_history.sort(this.compare);

      let qualifiedOnText = null;
      let qualifiedStatus = _.find(statusHistory,
          {'old_status': 'Follow up Scheduled', 'new_status': 'Qualified'});
      if (qualifiedStatus) {
        qualifiedOnText = (qualifiedStatus.name || 'NA') + ' qualified on ' +    moment(qualifiedStatus.updated_at * 1000).format("MMMM Do");
      }


      const DisqualifyReasonKeys = _.keys(this.state.disqualificationReasons);
      const DisqualifyReasonsDiv = DisqualifyReasonKeys.map((reason) => <a href="javascript:void(0);" key={reason} onClick={()=>{this.disqualifyReferral(reason)}}>{reason}</a>);
      const selectedCities = (this.state.locations || []).map( (location) => {
        let locationText = '';
        if (this.state.type !== 'Seller'){
          if(location.locality) {
              locationText = location.locality + ', ';
          }
          if (location.city){
            locationText +=  location.city + ', ' ;
          }
          if(location.state){
            locationText += location.state + ', ';
          }
          locationText = locationText.slice(0, -2);
        }
        else {
            locationText = location.address;
        }
        return (
              <Popup
                  client={this.state.client}
                  trigger={
                  <span key={location.locality} className="selected-cities">
                      {locationText}
                      <span className="close-1">X</span>
                  </span>
                  }
                    modal
                    closeOnDocumentClick={false}
                    closeOnEscape={false}
                  >
                      {close => (
                        <div className="modal-2">
                        <div className="modal-header">
                            <span className="modal-header-text">
                                Are you sure you want to continue with this action?
                            </span>
                        </div>
                        <div className="dialogue-box-footer">
                            <button className="med-btn-1" onClick={() => {this.handleDeselect(location.locality, close)}}>
                                YES
                            </button>
                            <button className="med-btn-1" onClick={()=> {close();}}>
                                NO
                            </button>
                        </div>
                  </div>

                      )}
              </Popup>
        )
      });

      const selectedNeighborhoodsDiv = (this.state.selectedNeighborhoods).map( (neighborhood) => {
        let locationText = '';

        if(neighborhood && neighborhood.name) {
            locationText = neighborhood.name + ', ';
        }
        if (neighborhood && neighborhood.city){
          locationText +=  neighborhood.city ;
        }

        return (
              <Popup
                  trigger={
                  <span  className="selected-cities">
                      {locationText}
                      <span className="close-1">X</span>
                  </span>
                  }
                    modal
                    closeOnDocumentClick={false}
                    closeOnEscape={false}
                  >
                      {close => (
                        <div className="modal-2">
                        <div className="modal-header">
                            <span className="modal-header-text">
                                Are you sure you want to continue with this action?
                            </span>
                        </div>
                        <div className="dialogue-box-footer">
                            <button className="med-btn-1" onClick={() => {this.handleDeselectNeighborhood(neighborhood, close)}}>
                                YES
                            </button>
                            <button className="med-btn-1" onClick={()=> {close();}}>
                                NO
                            </button>
                        </div>
                  </div>

                      )}
              </Popup>
        )
      });




      const searchOptions =  this.state.searchResultClients.map(
        (client) => {
          return (<div key={client.id} onClick= {() => {this.setClientDetailsFromSearch(client.id, client.phone)}}>
                    <div className="client-name">
                      {client.name}
                    </div>
                    <div className="client-name">
                      {client.phone}
                    </div>
                    <div className="client-name">
                        {client.sender_agent && client.sender_agent.firstname ? (client.sender_agent.firstname.charAt(0).toUpperCase() + client.sender_agent.firstname.substring(1))  : ""}
                        &nbsp;
                        {client.sender_agent && client.sender_agent.lastname ? (client.sender_agent.lastname.charAt(0).toUpperCase() + client.sender_agent.lastname.substring(1))  : ""}

                    </div>
            <hr/></div>
          );
        }
      );

        return (
          <div style={{ marginTop: 60 }}>
              <Notifications />

              <NotificationSystem ref="incomingCallPopup"/>

              <CallHeader
                // user status
                userStatus={this.state.status}
                // Search Related Props
                isShowSearch={this.state.showSearch}
                searchText={this.state.searchText}
                onSearchType={this.handleSearchChange}
                searchResultClients={this.state.searchResultClients}
                setClientDetailsFromSearch={this.setClientDetailsFromSearch}
                clearSearch={this.clearSearch}
                addNewLeads={(
                  <ClientModal triggerUI={<Button type="info" small icon="plus">New Lead</Button>}/>
                )}

                // Dialer Related props
                handleIVRKeyPress={this.sendInputToCall}
                handleIVRChange={(event) => { this.setState({ callInput: event.target.value }) }}
                IVRInput={this.state.callInput}
                isAutoCalls={this.state.autoCalls}
                dialedNumber={this.state.toNumber}
                isOnCall={this.state.onCall}
                isRinging={this.state.isRinging}
                onDialerType={this.handlePhoneNumberChange}
                callTimer={this.secondsTohhmmss()}
                startCaller={this.toggleAllowCalls}
                allowAutoCallerMode={allowAutoCallerMode}
                refreshQueue={this.refreshQueue}
                upcomingCallsUrl={upcomingCallsUrl}
                handleDisconnectConfirm={this.handlePopupOpen}
                isShowTransferButton={this.state.onCall && this.state.referral}

                // Message
                resetNewMessagesCount={this.resetNewMessagesCount}
                messagesCount={this.state.newMessagesCount}
                dialComponent={(
                  <Popup
                    client={this.state.client}
                    trigger={
                      <Button
                        disabled={!this.state.toNumber && !this.state.onCall}
                        icon={"phone"}
                        type={this.state.onCall ? "danger" : "success"}
                      >
                        {this.state.onCall ? 'END CALL' : 'DIAL'}
                      </Button>
                    }
                      disabled={!this.state.toNumber}
                      modal
                      closeOnDocumentClick={false}
                      closeOnEscape={false}
                      onOpen={this.handlePopupOpen}
                      open={this.state.popupOpen}
                    >
                        {close => (
                          <div className="modal-2">
                            <div className="modal-header">
                                {!this.state.onCall ?
                                <span className="modal-header-text">
                                    Are you sure you want to continue with this action?
                                </span>
                                  :
                                <span className="modal-header-text">
                                    Please click on the appropriate button
                                </span>
                                }
                            </div>
                            <div className="dialogue-box-footer">
                                {!this.state.onCall ?
                                    <div>
                                      <button className="med-btn-1" onClick={() => {this.handlePopupClose(); this.callClient(close)}}>
                                          YES
                                      </button>
                                      <button className="med-btn-1" onClick={()=> {this.handlePopupClose()}}>
                                          NO
                                      </button>
                                    </div>
                                    :
                                    <div>
                                      <button className="med-btn-1" onClick={() => {this.handlePopupClose(); this.disconnectCall(); close();}}>
                                        HANGUP
                                      </button>
                                      <button className="med-btn-1" onClick={() => {this.handlePopupClose(); this.markAsVoiceMail(close)}}>
                                        VOICEMAIL
                                      </button>
                                      <button className="med-btn-1" onClick={()=> {this.handlePopupClose()}}>
                                        CANCEL
                                      </button>
                                    </div>
                                }
                            </div>
                          </div>

                        )}
                </Popup>
                )}
                unsavedReferrals={(
                  <Popup
                    modal
                    trigger={<Button type="info" small>Unsaved Referrals</Button>}
                    closeOnDocumentClick={true}
                    closeOnEscape={true}
                    cloe>
                      {close => (
                        <div className="modal-1">
                          <div className="modal-header">
                            <span className="modal-header-text">Select an unsaved referral to continue with.</span>
                          </div>
                          <div>
                            {this.checkForUnsavedReferrals() && unsavedItems.length > 0 ? <UnsavedReferralsWrap>{unsavedItems}</UnsavedReferralsWrap> : <div className="empty-state-new">No unsaved referral at this moment!</div>}
                          </div>
                          <WarningDiv>*Clearing browser cache will remove all unsaved referrals.</WarningDiv>
                        </div>
                      )}
                    </Popup>
                )}
                conferenceCall={(
                  <ConferenceCall
                    referral={this.state.referral}
                    activeInConferenceCall={this.state.activeInConferenceCall}
                    call_sid={this.state.callSid} // child SID
                    parentCallSid={this.state.parentCallSid}
                    broker_agents_count={this.state.broker_agents_count}
                    twilio_client_name={this.state.twilioClientName}
                    isHideRemoveAndHold={this.state.isHideRemoveAndHold}
                  />
                )}
                //other props (Mostly state related)
                isOnCall={this.state.onCall}
              />
              {/* <ZillowMergeModal /> */}
              <div>
              {
                this.state.status !== 'offline' &&
                  <div className="search">
                        {/* {!this.state.onCall ?
                           <span>
                            <input placeholder="Search a client by phone number, name or phone" onChange={this.handleSearchChange} value={this.state.searchText} />
                            <i className="fa fa-times" aria-hidden="true" onClick={this.clearSearch}></i>
                              <input placeholder="Enter a number to manually call" onChange={this.handlePhoneNumberChange} value={this.state.toNumber} />
                          </span>
                            : this.state.queue_item && this.state.queue_item.item_source && (this.state.queue_item.item_source === "MissedCall" || this.state.queue_item.item_source === "ReceivedSms") &&
                            <span style={{color : '#e60a0a'}}>
                              This call is from {this.state.queue_item.item_source} at {moment(this.state.queue_item.item_source.sent_time).utc().format('YYYY-MM-DD HH:mm:ss A')}
                            </span>
                        } */}


                      {this.state.onCall && this.state.referral && (this.state.broker_agents_count || this.state.referral.is_isa) &&
                        <ConferenceCall
                          referral={this.state.referral}
                          activeInConferenceCall={this.state.activeInConferenceCall}
                          call_sid={this.state.callSid}
                          parentCallSid={this.state.parentCallSid}
                          broker_agents_count={this.state.broker_agents_count}
                          twilio_client_name={this.state.twilioClientName}
                        />
                      }

                    {/* {this.state.onCall &&
                    <span>
                      <input
                        placeholder="Enter key to send to IVR"
                        onKeyPress={this.sendInputToCall}
                        onChange={(event) => { this.setState({ callInput: event.target.value }) }}
                        value={this.state.callInput}
                      />
                    </span>
                    } */}
                    <Popup
                        open={this.state.showSaveProgressAlertDialog}
                        modal
                        closeOnDocumentClick={false}
                        closeOnEscape={false}>
                      {close => (
                          <div className="modal-2">
                            <div className="modal-header-new">
                              <span className="modal-header-text">Do you want to save your unsaved progress on this lead locally?</span>
                            </div>
                            <div className="dialogue-box-footer">
                              <div>
                                <button className="med-btn-1" onClick={() => {this.saveAndSwitch(true); close();}}>
                                  YES
                                </button>
                                <button className="med-btn-1" onClick={()=> {this.saveAndSwitch(false); close();}}>
                                  NO
                                </button>
                              </div>
                            </div>
                          </div>
                      )}
                    </Popup>
                    <Popup
                        open={this.state.acceptingCallWhileOnCall}
                        modal
                        closeOnDocumentClick={false}
                        closeOnEscape={false}>
                      {close => (
                          <div className="modal-2">
                            <div className="modal-header-new">
                                  <span className="modal-header-text">Are you sure you want to continue with this action? Clicking YES will disconnect the ongoing call and connect you to the next incoming call. Your unsaved progress will be saved locally for you to work later on.</span>
                            </div>
                            <div className="dialogue-box-footer">
                                  <div>
                                    <button className="med-btn-1" onClick={() => {this.disconnectCall(); this.saveAndSwitch(true, true); close();}}>
                                      YES
                                    </button>
                                    <button className="med-btn-1" onClick={()=> {this.updatePopupState(close());}}>
                                      NO
                                    </button>
                                  </div>
                            </div>
                          </div>
                      )}
                    </Popup>

                      {/* {this.state.onCall &&
                        <span className="timer">{this.secondsTohhmmss()}</span>
                      } */}
                      <Popup
                          open={this.state.client && this.state.referral && this.state.callOutcome && this.state.callOutcome.clientId === this.state.referral.receiver_client_id}
                          closeOnDocumentClick={false}
                          closeOnEscape={false}
                          onClose={this.closeModal}
                      >
                          {close =>
                            <div className="modal-2">
                                <div className="modal-header">
                                    <span className="modal-header-text">
                                      CALL OUTCOME
                                    </span>
                                </div>
                                <br/>
                                <AddNote
                                  referral={this.state.referral}
                                  user={this.props.user}
                                  assignedAgents={this.state.assignedAgentObjects}
                                  twilioClientName={this.state.twilioClientName}
                                  referralId={get(this.state, "activeInConferenceCall.referral_id")}
                                  parentCallSid={get(this.state, "activeInConferenceCall.conference_parent_call_id")}
                                  childCallSid={this.state.callSid}
                                  refreshProspects={() => {  }}
                                />
                                <br/>
                                <br/>
                                <span style={{marginLeft : '20px', marginTop: '10px'}}>Send text to client</span>
                                <textarea
                                  name="body"
                                  className="call-outcome"
                                  value={this.state.text}
                                  onChange={this.handleValueChange}
                                  id='text'
                                />
                                <button
                                              className="btn-primary "
                                              disabled={this.state.sendingText}
                                              onClick={this.sendText}
                                              style={{width : '150px', background: '#00adf3', float: 'right', marginRight: '20px', marginTop: '10px'}}
                                >
                                    {this.state.sendingText ? 'Sending Text' : 'Send Text'}
                                </button>
                                <br/>
                                <br/>

                                <button
                                  className="btn-primary "
                                  onClick={() => {
                                    if (this.state.autoCalls){
                                        this.setState({
                                          callOutcome : null,
                                          autoCalls: false,
                                      });
                                    }
                                    this.checkForZillowMergeFlow()
                                    close();
                                  }}
                                  style={{width : '150px', background: '#00adf3', marginLeft: '20px', background: 'red'}}
                                >
                                  Close
                                </button>
                            </div>
                          }
                      </Popup>
                      <Popup
                          open={this.state.nextCall !== null && this.state.autoCalls}
                          closeOnDocumentClick={false}
                          closeOnEscape={false}
                          onClose={this.closeModal}
                      >
                          {close =>
                            <div className="modal-2">
                                <div className="modal-header">
                                    <span className="modal-header-text">
                                      {referralText}
                                    </span>
                                </div>
                                <br/>
                                {
                                  prevCallStatus !== '' &&
                                  <span>Previous call status {prevCallStatus}</span>
                                }
                                <br/>
                                {
                                  enquiryDate  &&
                                  <span>Enquiry DATE: {enquiryDate}</span>
                                }
                                <br/>
                                {this.state.connectingWithClient ?
                                     <span>Connecting....Please wait ({'Seconds elapsed - '} {this.state.connectingTimer})</span>
                                :
                                <div>
                                    {this.state.nextCall && this.state.nextCall.next_referral &&
                                      <button
                                        className="btn-primary "
                                        onClick={this.callNow}
                                        style={{width : '150px', background: '#00adf3', float: 'left', marginRight: '20px', marginTop: '35px'}}
                                      >
                                      {'CALL (' + this.state.countDownSecs + ')'}
                                    </button>
                                    }
                                    <button
                                      className="btn-primary "
                                      onClick={() => {this.cancelNow();this.clearCountdownTimer();close();}}
                                      style={{width : '150px', background: '#00adf3', float: 'right', background: 'red', marginTop: '35px'}}
                                    >
                                        CANCEL
                                    </button>
                                </div>
                              }
                            </div>
                          }
                      </Popup>


                      {/* <a className="pending-count" href={upcomingCallsUrl} target="_blank">
                          {"Upcoming calls"}
                      </a> */}
                      {/* {this.state.profile_photo_url ? <img src={this.state.profile_photo_url}  className="consultant-avatar"/> : <span className="consultant-name">{this.state.name}</span>} */}
                      {/* <span className="consultant-number">{this.state.maskedNumber ? this.state.maskedNumber : null}</span> */}
                      {/* {adminAccess &&
                      <span>
                        <a className="settings-page" href={settingsUrl} target="_blank">Settings</a>
                        <a className="accounts-page" href={accountsUrl} target="_blank">Accounts</a>
                      </span>
                      } */}
                      {/* <a onClick={() => this.resetNewMessagesCount()} className="notif">{this.state.newMessagesCount !== 0 &&<span className="number-of-messages">{this.state.newMessagesCount}</span>}</a> */}
                    {/* {allowAutoCallerMode &&
                    <Popup
                        modal
                        trigger={<button className="refresh-button-1">Unsaved Referrals</button>}
                        closeOnDocumentClick={true}
                        closeOnEscape={true}
                    cloe>
                      {close => (
                          <div className="modal-1">
                            <div className="modal-header">
                              <span className="modal-header-text">Select an unsaved referral to continue with.</span>
                            </div>
                            <div>
                              {this.checkForUnsavedReferrals() && unsavedItems.length > 0 ? <UnsavedReferralsWrap>{unsavedItems}</UnsavedReferralsWrap> : <div className="empty-state-new">No unsaved referral at this moment!</div>}
                            </div>
                            <WarningDiv>*Clearing browser cache will remove all unsaved referrals.</WarningDiv>
                          </div>)}
                    </Popup>} */}

                      {/* {allowAutoCallerMode && <button className="refresh-button" onClick={this.refreshQueue}>Refresh</button>} */}
                      {/* {allowAutoCallerMode && !this.state.onCall && !this.state.autoCalls && <button className="allow-button" onClick={this.toggleAllowCalls}>Start Caller</button>} */}
                      {/* <button className="logout-button" onClick={this.logout}>logout</button> */}
                      {/* {this.state.showSearch && this.state.searchText !== 'Search a client by phone number, name or phone' &&
                        <div className="searchResults">
                          {searchOptions}
                        </div>
                      } */}
                    {/* <ClientModal triggerUI={<span className={'add-lead-button'} >{'New Lead!!'}</span>}/> */}
                  </div>
              }
                {this.profileAvailablityPortal && ReactDOM.createPortal(
                  <ProfileDropdown
                    loggedUser={this.state.name}
                    handleLogout={this.logout}
                    settingsUrl={settingsUrl}
                    isAdmin={adminAccess}
                    availabilityDropdown={(
                      <select className="dropdown" onChange={this.handleChange} value={this.state.status}>
                        <option value="break" >Break</option>
                        <option value="offline" >Offline</option>
                        <option value="online">Online</option>
                        <option value="call">On a Call</option>
                      </select>
                    )}
                  />,
                  this.profileAvailablityPortal,
                )}
              { this.state.status  !== 'call' && this.state.status !== 'online' && this.state.status !== 'break' &&
                  <div className="search">
                    <select className="dropdown" onChange={this.handleChange} value={this.state.status}>
                        <option value="break" >Break 5</option>
                        <option value="offline" >Offline 6</option>
                        <option value="online">Online 7</option>
                        <option value="call">On a Call 8</option>
                    </select>
                    {adminAccess &&
                      <span>
                        <a className="settings-page" href={settingsUrl} target="_">Settings</a>
                        <a className="accounts-page" href={accountsUrl} target="_">Accounts</a>
                      </span>
                    }
                    {this.state.profile_photo_url ? <img src={this.state.profile_photo_url}  className="consultant-avatar"/> : <span className="consultant-name">{this.state.name}</span>}
                    {this.state.status !== 'offline' && <button className="allow-button" onClick={this.toggleAllowCalls}>{!this.state.autoCalls ? 'Allow calls' : 'Block calls'}</button>}
                  <button className="logout-button" onClick={this.logout}>logout</button>
                </div>
              }

              </div>
              { this.state.client && (
                  <ClientInfo
                    wasIsa={this.state.wasIsa || false}
                    isOnCall={this.state.onCall}
                    user={this.props.user}
                    client={this.state.client}
                    clientCity={this.state.clientCity}
                    referral={this.state.referral}
                    toNumber={this.state.toNumber}
                    onCall={this.state.onCall}
                    messages={this.state.messages}
                    updateClientDetails={this.updateClientDetails.bind(this)}
                    updateAgentAdditionalDetails={this.updateAgentAdditionalDetails.bind(this)}
                    type={this.state.type}
                    requestUnsavedClientInfo={this.state.requestUnsavedClientInfo}
                    saveUnsavedClientInfoLocally={this.saveUnsavedClientInfoLocally.bind(this)}
                    tempClient={this.state.tempClient}
                    setClientDetailsFromSearch={this.setClientDetailsFromSearch}
                  />
                )
              }
              <div className={!this.state.apiCall ? '': 'inactive-opacity'}>


                  <div className="flex referral-section flexDirCol">
                      <div className={"left-section"}>
                        {this.state.referral &&
                         <PanelContainer hideHeader title={qualifiedOnText ? qualifiedOnText : ''}>
                            <span>
                                {preferencesDiv}
                                {this.state.wasOriginallyIsa &&
                                  <Popup
                                      client={this.state.client}
                                      trigger={
                                         <button className="convert-to-isa">Convert to ISA</button>
                                      }
                                  modal
                                  closeOnDocumentClick={false}
                                  closeOnEscape={false}
                                  >
                                    {close => (
                                        <div className="modal-2">
                                            <div className="modal-header">
                                                <span className="modal-header-text">
                                                    Are you sure you want to continue with this action?
                                                </span>
                                            </div>
                                            <div className="dialogue-box-footer">
                                                <button className="med-btn-1" onClick={() => {this.resendProspectNotification(close);}}>
                                                    YES
                                                </button>
                                                <button className="med-btn-1" onClick={()=> {close();}}>
                                                    NO
                                                </button>
                                            </div>
                                        </div>
                                    )}
                              </Popup>

                                }
                           </span>
                         </PanelContainer>
                        }
                         {this.state.client &&
                          <PanelContainer hideHeader>
                            <Wizard  currentStage={this.state.stage} updateStage={this.updateState.bind(this)} />
                          </PanelContainer>
                         }
                          {this.state.client && this.state.stage === 1 &&
                           <PanelContainer title="Timeline" style={{ "display": "flex" }}>
                              <Timeline clientId={clientId} agentId={agentId}/>
                              {this.state.client && this.state.referral &&
                                <div className="note-section">
                                  <Notes assignedAgents={this.state.assignedAgentObjects}
                                         referral={this.state.referral}
                                         user={this.props.user}
                                         requestUnsavedNote={this.state.requestUnsavedNote}
                                         saveUnsavedNoteLocally={this.saveUnsavedNoteLocally.bind(this)}
                                         tempNote={this.state.tempNote}/>
                                  {/*<PanelContainer title={"Reminders"}>
                                    <Reminders clientId={this.state.referral.receiver_client_id} agentId={this.state.referral.receiver_id}/>
                                  </PanelContainer>*/}
                                </div>
                              }
                           </PanelContainer>
                          }
                          {this.state.client && (this.state.type === 'Buyer'  || this.state.type === 'Renter' )&& this.state.stage === 2 && (
                          <PanelContainer title="Lead Details" style={{ "display": "flex" }}>

                           <div style={{ "width": "100%"}}>
                              <div className = "motivation-level">
                                {this.state.agentReq && this.state.agentReq.motivational_level ? ("Client Motivation Level: "  + this.state.agentReq.motivational_level)  : null }
                              </div>
                              <form>
                                <span className="radio">
                                  <label>
                                  <input type="radio" value="Buyer" checked={this.state.type === 'Buyer'} onChange={this.handleTypeChange}/>
                                  Buyer
                                  </label>
                                </span>
                                <span className="radio">
                                  <label>
                                    <input type="radio" value="Seller" checked={this.state.type === 'Seller'} onChange={this.handleTypeChange}/>
                                    Seller
                                  </label>
                                </span>
                                <span className="radio">
                                  <label>
                                    <input type="radio" value="Renter" checked={this.state.type === 'Renter'} onChange={this.handleTypeChange}/>
                                    Renter
                                  </label>
                                </span>
                              </form>

                             {this.state.buyerInterestedPropertyDetails && <div className="referral-text">
                               <div className="client-additional-details-label-text">Buyer Interested Property Details:</div>
                               <textarea className="agent-req-textarea" disabled={true} style={{"resize": "none"}}
                                         value={this.state.buyerInterestedPropertyDetails}/>
                             </div>}

                              <PlacesAutocomplete
                                 value={this.state.address}
                                 onChange={this.handleCityUpdate}
                                 onSelect={this.handleCitySelect}
                               >
                               {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                 <div>
                                   <div className="referral-search">
                                       <input
                                         {...getInputProps({
                                           placeholder: 'Search Cities ...',
                                         })}
                                       />
                                       <span className="fa fa-search"></span>
                                   </div>
                                   <div className="autocomplete-dropdown-container-1">
                                     {suggestions.map(suggestion => {
                                       const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                       // inline style for demonstration purpose
                                       const style = suggestion.active
                                       ? { backgroundColor: '#00FFFF', cursor: 'pointer', color: 'white' }
                                       : { backgroundColor: '#1c1c1d	', cursor: 'pointer', color: 'white'};
                                       return (
                                         <div {...getSuggestionItemProps(suggestion, { className, style })}>
                                           <span>{suggestion.description}</span>
                                         </div>
                                       )
                                     })}
                                   </div>
                                 </div>
                               )}
                             </PlacesAutocomplete>



                             {selectedCities.length > 0 && <div className="text-color-grey">Click on the selected value(s) if you want to remove them</div> }
                             {selectedCities}

                              <div className="referral-search">
                                <DebounceInput
                                    id="neighbordhood-input"
                                    minLength={2}
                                    placeholder="Search Neighborhoods"
                                    debounceTimeout={300}
                                    autocomplete="off"
                                    value={this.state.neighbourhoodsearchText}
                                    onChange={event => this.getNeighborhoods(event.target.value)}
                                />
                                <span className="fa fa-search"></span>
                                <ClickAwayListener onClickAway={() => this.handleClickAwayFromNeighborhoods()}>
                                    <div className="searchResults">
                                      {neighborhoodDiv}
                                    </div>
                                  </ClickAwayListener>
                              </div>

                             {this.state.selectedNeighborhoods.length > 0 && <div className="text-color-grey">Click on the selected value(s) if you want to remove them</div> }
                             {selectedNeighborhoodsDiv}
                             <div className="referral-text">
                                <div className="client-additional-details-label-text">Working with an agent?<span class="required-asterik">*</span></div>
                                <br/>
                                {
                               this.state.agentReq.working_with_agent === "Yes" ||
                               this.state.agentReq.working_with_agent === "No" ||  this.state.agentReq.working_with_agent === "Choose a value"
                               ?
                                <select className="dropdown" onChange={this.handleWorkingWithAgent} value={this.state.agentReq.working_with_agent}>
                                {workingwithanagentDiv}
                                </select>
                                :
                                <React.Fragment>
                                  <textarea placeholder="Add a note" className="agent-req-textarea" style={{height: "100px"}}  value={this.state.agentReq.working_with_agent} onChange={this.handleWorkingWithAgent}/>
                                  <br/>
                                  <button className="remove-working-with-agent" onClick={this.toggleWorkingWithAgent}>Delete Note</button>
                               </React.Fragment>
                              }
                             </div>
                           <div className="referral-text" onChange={this.handleMinimumBudgetUpdate}>
                             <div className="label-text">Minimum Budget:</div>
                             <NumberFormat placeholder="Minimum Budget" thousandSeparator={true} value={this.state.minBudget}/>
                           </div>
                           <div className="referral-text" onChange={this.handleMaximumBudgetUpdate} >
                             <div className="label-text">Maximum Budget:<span class="required-asterik">*</span></div>
                             <NumberFormat placeholder="Maximum Budget" thousandSeparator={true} value={this.state.maxBudget}/>
                           </div>
                           <div className="referral-text" onChange={this.handleMinimumSizeUpdate} >
                             <div className="label-text">Minimum Size in Sq.Ft:</div>
                             <input type="number" placeholder="Minimum Size" value={this.state.minSize}/>
                           </div>
                           <div className="referral-text" onChange={this.handleMaximumSizeUpdate} >
                             <div className="label-text">Maximum Size in Sq.Ft:</div>
                             <input type="number" placeholder="Maximum Size" value={this.state.maxSize}/>
                           </div>
                           <div className="referral-text">
                             <div className="label-text">No of bedrooms<span class="required-asterik">*</span></div>
                             <select className="dropdown" onChange={this.handleBedroomUpdate} value={this.state.beds}>
                                  {bedAndBathsDiv}
                              </select>
                           </div>
                           <div className="referral-text">
                             <div className="label-text">No of bathrooms<span class="required-asterik">*</span></div>
                             <select className="dropdown" onChange={this.handleBathroomUpdate} value={this.state.bathrooms}>
                                  {bedAndBathsDiv}
                              </select>
                           </div>
                           <AppointmentTime  appointmentTimezone={this.state.timezone}  appointmentTime={this.state.agentReq.appointment_timestamp} handleAppointmentTimezone={this.handleAppointmentTimezone.bind(this)} handleAppointmentTimeStamp={this.handleAppointmentTimeStamp.bind(this)}/>
                           <div className="referral-text">
                             <div className="client-additional-details-label-text">Additional features:</div>
                             <textarea className="agent-req-textarea" placeholder="Client Additional Details"
                                       value={this.state.agentReq.clientAdditionalDetails} onChange={this.handleClientAdditionalDetailsChange}/>
                           </div>

                           <select name="Property Type" className="dropdown-1" onChange={(e) => this.handlePropertyTypeChange(e, 'requirement' )}  value={"Select one or more values"}>
                             {types.map((type) => {
                               return <option key={type}>{type}</option>;
                             })}
                           </select>
                           {propertyTypesDiv}
                           <br/>
                           <br/>
                           <br/>
                           <input type="checkbox"  className="checkbox-left" checked={this.state.blockPhone} onChange={this.handleBlockPhone}/>
                           <label className="checkbox-text">
                              Allow Agents To Contact only over Email
                           </label>
                           {/* <input type="checkbox"  className="checkbox-left" checked={this.state.lookingForALender} onChange={this.handleLookingForaLender}/> */}

                           {this.state.type === "Buyer" && (
                             <React.Fragment>
                               <br/>
                                <br/>
                                <label style={{ marginLeft: 35 }}>
                                    Looking for a Lender<span class="required-asterik">*</span>
                                </label>
                                <SectionWrap>
                                <Select
                                    name="looking-for-lender"
                                    className="checkbox-left"
                                    placeholder={this.handleLenderValue()}
                                    options={[
                                      { label: "Yes", value: 1 },
                                      { label: "No", value: 0 },
                                      ]}
                                    onChange={(value) => { this.handleLookingForaLender(value.value)}}
                                />
                                </SectionWrap>
                             </React.Fragment>
                           )}
                           <br/>
                           <br/>
                           <AgentReqDetails
                            clientType={this.state.type}
                            agentReq={this.state.agentReq}
                            isPreApproved={this.state.isPreApproved}
                            handleChangeChk={this.handleChangeChk}
                            handleTimeline={this.handleTimeline}
                            handleAgentReqFieldChange={this.handleAgentReqFieldChange}
                          />

                           <input type="checkbox"  className="checkbox-left" checked={this.state.emailClientAfterQualification} onChange={this.handleEmailClientAfterQualification}/>
                           <label className="checkbox-text">
                             Email Client After Qualification?
                           </label>



                           <input type="checkbox"  className="checkbox-left" checked={this.state.emailSenderAfterQualification} onChange={this.handleSenderClientAfterQualification} />
                           <label className="checkbox-text">
                             Email Sending Agent After Qualification?
                           </label>
                           <br/>
                           <br/>

                           {qualificationChannelDiv}

                            {get(this.state, "referral.is_isa") ? (
                              <div style={{ marginLeft: 36, padding: "20px 0px" }}>
                              <p style={{ padding: 0, marginBottom: 10 }}>Priority</p>
                              <select
                              name="referral_priority"
                              placeholder="Select priority"
                              onChange={this.handlePriorityChange}
                              value={get(this.state, "referral.referral_priority")}
                              style={{ width: 200 }}
                            >
                                <option value="0">Low</option>
                                <option value="1">High</option>
                              </select>
                            </div>
                            ) : ""}

                           {qualificationPreferenceDiv}

                           <div className="referral-footer">
                             {this.state.referral && (allowQualify.indexOf(this.state.referral.referred_client_status) === -1) ?
                               <button className={this.getQualifyColor()} onClick={this.handleSubmit} disabled={this.state.submitting || (this.state.locations && this.state.locations.length === 0) ||  this.invalidBudget() }>Re-Qualify</button>
                               :
                               <button
                                className={this.getQualifyColor()}
                                onClick={this.state.userReferralSettings && this.state.userReferralSettings.auto_refer_out_leads && this.state.referral && !this.state.referral.is_mortgage ? this.handleSubmitWithReferOut : this.handleSubmit} disabled={this.state.submitting || (this.state.locations && this.state.locations.length === 0) || this.invalidBudget() || this.checkFormValidity()}>Qualify</button>
                             }
                             {this.state.referral && !this.state.referral.is_mortgage &&
                                <QualifyAndReferOut referral={this.state.referral} submitting={this.state.submitting} locations={this.state.locations} handleSubmitWithReferOut={this.handleSubmitWithReferOut.bind(this)} />}
                             <button className="med-btn-1"
                                     onClick={() => {this.markLeadPhoneNumberIncorrect()}}>
                               Wrong number
                             </button>
                             <div className="dropdown-1">
                               {this.state.referral && this.state.referral.referred_client_status === 'Disqualified' ?
                                 <button className="med-btn-1">Disqualified</button>
                                 :
                                 <button className="med-btn-1">Disqualify</button>
                               }
                               <div className="dropdown-1-content">
                                   {DisqualifyReasonsDiv}
                               </div>
                             </div>
                             <button className="med-btn-1" onClick={() => {this.saveLead()}}>
                               Save
                             </button>
                             <Popup
                               client={this.state.client}
                               trigger={
                                 <button className="med-btn-1">Add Reminder</button>
                               }
                                 modal
                                 closeOnDocumentClick={false}
                                 closeOnEscape={false}
                               >
                                   {close => (
                                       <div className="modal">
                                       <div className="modal-header">
                                           <span className="modal-header-text">
                                               Add a Reminder
                                           </span>
                                       </div>
                                       <div className="referral-text">
                                           <div className="label-text">Event</div>
                                               <select className="form-control" onChange={this.handleReminderTypeChange}>
                                                   <option value="Call" selected ={this.state.type === 'Call'}>Call</option>
                                                   <option value="Email" selected ={this.state.type === 'Email'}>Email</option>
                                                   <option value="Sale" selected ={this.state.type === 'Sale'}>Sale</option>
                                               </select>
                                       </div>
                                       <div className="referral-text">
                                           <div className="label-text">Event Date</div>
                                           <DatePicker  selected={this.state.date}
                                               onChange={this.handleDateChange.bind(this)}

                                           />
                                       </div>
                                       <div className="referral-text" >
                                           <div className="label-text">Event Time</div>
                                           <TimePicker
                                                 time={this.state.time}
                                                 onChange={this.handleTimeChange.bind(this)} />
                                       </div>
                                       <div className="referral-text">
                                           <div className="label-text">Notes*</div>
                                           <textarea className="textarea-modal" onChange={this.updateNote}/>
                                       </div>
                                       <div className="referral-text">
                                           <button className="med-btn-1" onClick={() => {this.submit(close)}}>
                                               Submit
                                           </button>
                                           <button className="med-btn-1" onClick={()=> {close();}}>
                                               Cancel
                                           </button>
                                       </div>
                                 </div>
                                   )}
                             </Popup>
                             <Popup
                               client={this.state.client}
                               trigger={
                                 <button className="med-btn-1"  onClick={this.putOnnhold}>Put On Hold</button>
                               }
                                 modal
                                 closeOnDocumentClick={false}
                                 closeOnEscape={false}
                               >
                                   {close => (
                                       <div className="modal">
                                       <div className="modal-header">
                                           <span className="modal-header-text">
                                               Add a Reminder
                                           </span>
                                       </div>
                                       <div className="referral-text">
                                           <div className="label-text">Event</div>
                                               <select className="form-control" onChange={this.handleReminderTypeChange}>
                                                   <option value="Call" selected ={this.state.type === 'Call'}>Call</option>
                                                   <option value="Email" selected ={this.state.type === 'Email'}>Email</option>
                                                   <option value="Sale" selected ={this.state.type === 'Sale'}>Sale</option>
                                               </select>
                                       </div>
                                       <div className="referral-text">
                                           <div className="label-text">Event Date</div>
                                           <DatePicker  selected={this.state.date}
                                               onChange={this.handleDateChange.bind(this)}

                                           />
                                       </div>
                                       <div className="referral-text" >
                                           <div className="label-text">Event Time</div>
                                           <TimePicker
                                                 time={this.state.time}
                                                 onChange={this.handleTimeChange.bind(this)} />
                                       </div>
                                       <div className="referral-text">
                                           <div className="label-text">Notes*</div>
                                           <textarea className="textarea-modal" onChange={this.updateNote}/>
                                       </div>
                                       <div className="referral-text">
                                           <button className="med-btn-1" onClick={() => {this.putOnHold(close)}}>
                                               Submit
                                           </button>
                                           <button className="med-btn-1" onClick={()=> {close();}}>
                                               Cancel
                                           </button>
                                       </div>
                                 </div>
                                   )}
                               </Popup>
                             </div>
                           </div>
                         {this.state.client && this.state.referral &&
                           <div className="note-section">
                             <Notes assignedAgents={this.state.assignedAgentObjects}
                                    referral={this.state.referral}
                                    user={this.props.user}
                                    requestUnsavedNote={this.state.requestUnsavedNote}
                                    saveUnsavedNoteLocally={this.saveUnsavedNoteLocally.bind(this)}
                                    tempNote={this.state.tempNote}/>
                             {/*<PanelContainer title={"Reminders"}>
                               <Reminders clientId={this.state.referral.receiver_client_id} agentId={this.state.referral.receiver_id}/>
                             </PanelContainer>*/}
                           </div>
                         }
                        </PanelContainer>
                          )}
                          { this.state.client && this.state.type === 'Seller' && this.state.stage === 2 && (
                           <PanelContainer title="Lead Details" style={{ "display": "flex" }}>

                            <div style={{"width" : "100%"}}>
                                <div className = "motivation-level">
                                  {this.state.agentReq && this.state.agentReq.motivational_level ? ("Client Motivation Level: "  + this.state.agentReq.motivational_level)  : null }
                                </div>
                                <form>
                                <span className="radio">
                                  <label>
                                  <input type="radio" value="Buyer" checked={this.state.type === 'Buyer'} onChange={this.handleTypeChange}/>
                                  Buyer
                                  </label>
                                </span>
                                <span className="radio">
                                  <label>
                                    <input type="radio" value="Seller" checked={this.state.type === 'Seller'} onChange={this.handleTypeChange}/>
                                    Seller
                                  </label>
                                </span>
                                <span className="radio">
                                  <label>
                                    <input type="radio" value="Renter" checked={this.state.type === 'Renter'} onChange={this.handleTypeChange}/>
                                    Renter
                                  </label>
                                </span>
                              </form>

                              {this.state.buyerInterestedPropertyDetails && <div className="referral-text">
                                <div className="client-additional-details-label-text">Buyer Interested Property Details:</div>
                                <textarea className="agent-req-textarea" disabled={true} style={{"resize": "none"}}
                                          value={this.state.buyerInterestedPropertyDetails}/>
                              </div>}

                               <PlacesAutocomplete
                                  value={this.state.address}
                                  onChange={this.handleCityUpdate}
                                  onSelect={this.handlePropertyCitySelect}
                                >
                                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                  <div>
                                    <div className="referral-search">
                                        <input
                                          {...getInputProps({
                                            placeholder: 'Address ...',
                                          })}
                                        />
                                        <span className="fa fa-search"></span>
                                    </div>
                                    <div className="autocomplete-dropdown-container-1">
                                      {suggestions.map(suggestion => {
                                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                                    ? { backgroundColor: '#00FFFF', cursor: 'pointer', color: 'white' }
                                                    : { backgroundColor: '#1c1c1d', cursor: 'pointer', color: 'white'};
                                        return (
                                          <div {...getSuggestionItemProps(suggestion, { className, style })}>
                                            <span>{suggestion.description}</span>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>

                              {selectedCities.length > 0 && <div className="text-color-grey">Click on the selected value to remove them</div>}
                              {selectedCities}
                              <div className="referral-search">
                               <DebounceInput
                                   minLength={2}
                                   placeholder="Search Neighborhoods"
                                   debounceTimeout={300}
                                   value={this.state.neighbourhoodsearchText}
                                   autoComplete="off"
                                   onChange={event => this.getNeighborhoods(event.target.value)}
                               />
                               <span className="fa fa-search"></span>

                               <ClickAwayListener onClickAway={() => this.handleClickAwayFromNeighborhoods()}>
                                  <div className="searchResults">
                                    {neighborhoodDiv}
                                  </div>
                                </ClickAwayListener>
                             </div>
                             {this.state.selectedNeighborhoods.length > 0 && <div className="text-color-grey">Click on the selected value(s) if you want to remove them</div> }
                             {selectedNeighborhoodsDiv}
                              <div className="referral-text">
                               <div className="client-additional-details-label-text">Working with an agent?<span class="required-asterik">*</span></div>
                               <br/>
                               {
                               this.state.agentReq.working_with_agent === "Yes" ||
                               this.state.agentReq.working_with_agent === "No" || this.state.agentReq.working_with_agent === "Choose a value"
                               ?
                                <select className="dropdown" onChange={this.handleWorkingWithAgent} value={this.state.agentReq.working_with_agent}>
                                  {workingwithanagentDiv}
                                </select>
                                :
                                <React.Fragment>
                                    <textarea placeholder="Add a note" className="agent-req-textarea" style={{height: "100px"}}  value={this.state.agentReq.working_with_agent} onChange={this.handleWorkingWithAgent}/>
                                    <br/>
                                    <button className="remove-working-with-agent" onClick={this.toggleWorkingWithAgent}>Delete Note</button>
                                </React.Fragment>
                              }
                              </div>
                              <div className="referral-text" onChange={this.handleMaximumBudgetUpdate}>
                                <div className="label-text">Budget<span class="required-asterik">*</span></div>
                                <NumberFormat placeholder="Budget" thousandSeparator={true} value={this.state.maxBudget}/>
                              </div>
                              <div className="referral-text" onChange={this.handleMaximumSizeUpdate}>
                                <div className="label-text">Size in Sq. Ft</div>
                                <input type="number" placeholder="Size" value={this.state.maxSize}/>
                              </div>
                              <div className="referral-text">
                             <div className="label-text">No of bedrooms<span class="required-asterik">*</span></div>
                              <select className="dropdown" onChange={this.handleBedroomUpdate} value={this.state.beds}>
                                    {bedAndBathsDiv}
                                </select>
                             </div>
                             <div className="referral-text">
                               <div className="label-text">No of bathrooms<span class="required-asterik">*</span></div>
                               <select className="dropdown" onChange={this.handleBathroomUpdate} value={this.state.bathrooms}>
                                    {bedAndBathsDiv}
                                </select>
                              </div>
                              <AppointmentTime appointmentTimezone={this.state.timezone} appointmentTime={this.state.agentReq.appointment_timestamp} handleAppointmentTimezone={this.handleAppointmentTimezone.bind(this)}  handleAppointmentTimeStamp={this.handleAppointmentTimeStamp.bind(this)}/>
                              <div className="referral-text">
                                <div className="client-additional-details-label-text">Additional features:</div>
                                <textarea className="agent-req-textarea" placeholder="Client Additional Details"
                                          value={this.state.agentReq.clientAdditionalDetails} onChange={this.handleClientAdditionalDetailsChange}/>
                              </div>

                              <select name="Property Type" className="dropdown-1" onChange={(e) => this.handlePropertyTypeChange(e, 'property')} value={'Select a property type'}>
                                {types.map((type) => {
                                  return <option key={type}>{type}</option>;
                                })}
                              </select>
                              {propertyTypesDiv}
                              <br/>
                              <br/>
                              <input type="checkbox"  className="checkbox-left" checked={this.state.blockPhone} onChange={this.handleBlockPhone}/>
                              <label className="checkbox-text">
                                  Allow Agents To Contact only over Email
                              </label>
                                {/* <input type="checkbox"  className="checkbox-left" checked={this.state.lookingForALender} onChange={this.handleLookingForaLender}/>
                                <label className="checkbox-text">
                                  Looking for a Lender 2<span class="required-asterik">*</span>
                                </label>
                                <select
                                  name="looking-for-lender"
                                  className="checkbox-left"
                                  style={{ width: 90, marginRight: 10 }}
                                  onChange={(event) => { this.handleLookingForaLender(event.target.value === "true")}}
                                  value={this.state.lookingForALender}
                                >
                                  <option value="" disabled selected>Select your option</option>
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select> */}
                                <br/>
                                <br/>
                              <AgentReqDetails
                                agentReq={this.state.agentReq}
                                clientType={this.state.type}
                                isPreApproved={this.state.isPreApproved}
                                handleChangeChk={this.handleChangeChk}
                                handleTimeline={this.handleTimeline}
                                handleAgentReqFieldChange={this.handleAgentReqFieldChange}
                              />

                              <input type="checkbox"  className="checkbox-left" checked={this.state.emailClientAfterQualification} onChange={this.handleEmailClientAfterQualification} />
                              <label className="checkbox-text">
                                Email Client After Qualification?
                              </label>
                              <br/>
                              <br/>

                              <input type="checkbox"  className="checkbox-left" checked={this.state.emailSenderAfterQualification} onChange={this.handleSenderClientAfterQualification} />
                              <label className="checkbox-text">
                                Email Sending Agent After Qualification?
                              </label>
                              <br/>
                              <br/>

                              {qualificationChannelDiv}

                              {get(this.state, "referral.is_isa") ? (
                                <div style={{ marginLeft: 36, padding: "20px 0px" }}>
                                  <p style={{ padding: 0, marginBottom: 10 }}>Priority</p>
                                  <select
                                    name="referral_priority"
                                    placeholder="Select priority"
                                    onChange={this.handlePriorityChange}
                                    value={get(this.state, "referral.referral_priority")}
                                    style={{ width: 200 }}
                                  >
                                  <option value="0">Low</option>
                                  <option value="1">High</option>
                                </select>
                              </div>
                              ) : ""}

                              {qualificationPreferenceDiv}

                              <div className="referral-footer">
                                  {this.state.referral && (allowQualify.indexOf(this.state.referral.referred_client_status) === -1) ?
                                      <button className={this.getQualifyColor()} onClick={this.handleSubmit} disabled={this.invalidBudget() || this.state.submitting || (this.state.locations && this.state.locations.length === 0 ) }>Re-Qualify</button>
                                      :
                                      <button className={this.getQualifyColor()} onClick={this.state.userReferralSettings && this.state.userReferralSettings.auto_refer_out_leads && this.state.referral && !this.state.referral.is_mortgage ? this.handleSubmitWithReferOut : this.handleSubmit} disabled={this.invalidBudget() || this.state.submitting || (this.state.locations && this.state.locations.length === 0) || this.checkFormValidity()}>Qualify</button>
                                  }
                                {this.state.referral && !this.state.referral.is_mortgage &&
                                    <QualifyAndReferOut referral={this.state.referral} submitting={this.state.submitting} locations={this.state.locations} handleSubmitWithReferOut={this.handleSubmitWithReferOut.bind(this)} />}
                                  <button className="med-btn-1"
                                          onClick={() => {this.markLeadPhoneNumberIncorrect()}}>
                                    Wrong number
                                  </button>
                                  <div className="dropdown-1">
                                    {this.state.referral && this.state.referral.referred_client_status === 'Disqualified' ?
                                      <button className="med-btn-1">Disqualified</button>
                                      :
                                      <button className="med-btn-1">Disqualify</button>
                                    }
                                    <div className="dropdown-1-content">
                                        {DisqualifyReasonsDiv}
                                    </div>
                                  </div>
                                  <button className="med-btn-1" onClick={() => {this.saveLead()}}>
                                    Save
                                  </button>
                                <Popup
                                    client={this.state.client}
                                    trigger={
                                      <button className="med-btn-1">Add Reminder</button>
                                    }
                                      modal
                                      closeOnDocumentClick={false}
                                      closeOnEscape={false}
                                    >
                                        {close => (
                                            <div className="modal">
                                            <div className="modal-header">
                                                <span className="modal-header-text">
                                                    Add a Reminder
                                                </span>
                                            </div>
                                            <div className="referral-text">
                                                <div className="label-text">Event</div>
                                                    <select className="form-control" onChange={this.handleReminderTypeChange}>
                                                        <option value="Call" selected ={this.state.type === 'Call'}>Call</option>
                                                        <option value="Email" selected ={this.state.type === 'Email'}>Email</option>
                                                        <option value="Sale" selected ={this.state.type === 'Sale'}>Sale</option>
                                                    </select>
                                            </div>
                                            <div className="referral-text">
                                                <div className="label-text">Event Date</div>
                                                <DatePicker  selected={this.state.date}
                                                    onChange={this.handleDateChange.bind(this)}

                                                />
                                            </div>
                                            <div className="referral-text" >
                                                <div className="label-text">Event Time</div>
                                                <TimePicker
                                                    time={this.state.time}
                                                    onChange={this.handleTimeChange.bind(this)} />
                                            </div>
                                            <div className="referral-text">
                                                <div className="label-text">Notes*</div>
                                                <textarea className="textarea-modal" onChange={this.updateNote}/>
                                            </div>
                                            <div className="referral-text">
                                                  <button className="med-btn-1" onClick={() => {this.submit(close)}}>
                                                      Submit
                                                  </button>
                                                  <button className="med-btn-1" onClick={()=> {close();}}>
                                                      Cancel
                                                  </button>
                                            </div>
                                      </div>
                                        )}
                                  </Popup>
                                  <Popup
                                    client={this.state.client}
                                    trigger={
                                      <button className="med-btn-1">Put On Hold</button>
                                    }
                                      modal
                                      closeOnDocumentClick={false}
                                      closeOnEscape={false}
                                      >
                                        {close => (
                                            <div className="modal">
                                            <div className="modal-header">
                                                <span className="modal-header-text">
                                                    Add a Reminder
                                                </span>
                                            </div>
                                            <div className="referral-text">
                                                <div className="label-text">Event</div>
                                                    <select className="form-control" onChange={this.handleReminderTypeChange}>
                                                        <option value="Call" selected ={this.state.type === 'Call'}>Call</option>
                                                        <option value="Email" selected ={this.state.type === 'Email'}>Email</option>
                                                        <option value="Sale" selected ={this.state.type === 'Sale'}>Sale</option>
                                                    </select>
                                            </div>
                                            <div className="referral-text">
                                                <div className="label-text">Event Date</div>
                                                <DatePicker  selected={this.state.date}
                                                    onChange={this.handleDateChange.bind(this)}

                                                />
                                            </div>
                                            <div className="referral-text" >
                                                <div className="label-text">Event Time</div>
                                                <TimePicker
                                                    time={this.state.time}
                                                    onChange={this.handleTimeChange.bind(this)} />
                                            </div>
                                            <div className="referral-text">
                                                <div className="label-text">Notes*</div>
                                                <textarea className="textarea-modal" onChange={this.updateNote}/>
                                            </div>
                                            <div className="referral-text">
                                                <button className="med-btn-1" onClick={() => {this.putOnHold(close)}}>
                                                    Submit
                                                </button>
                                                <button className="med-btn-1" onClick={()=> {close();}}>
                                                    Cancel
                                                </button>
                                            </div>
                                      </div>
                                        )}
                                  </Popup>
                              </div>
                            </div>
                            {this.state.client && this.state.referral &&
                              <div className="note-section">
                                <Notes assignedAgents={this.state.assignedAgentObjects}
                                       referral={this.state.referral}
                                       user={this.props.user}
                                       requestUnsavedNote={this.state.requestUnsavedNote}
                                       saveUnsavedNoteLocally={this.saveUnsavedNoteLocally.bind(this)}
                                       tempNote={this.state.tempNote}/>
                                {/*<PanelContainer title={"Reminders"}>
                                  <Reminders clientId={this.state.referral.receiver_client_id} agentId={this.state.referral.receiver_id}/>
                                </PanelContainer>*/}
                              </div>
                            }
                           </PanelContainer>
                          )}
                          { this.state.stage === 3 && this.state.client &&
                           <PanelContainer
                            title="Agents Comparison"
                            style={{ "display": "flex" }}
                            hideHeader
                          >
                             <div style={{ width: "70%" }}>
                              <AgentComparison
                                referral={this.state.referral}
                                connectToAgent={this.callClient} 
                                callSID={this.state.callSid}
                                twillioClientName={this.props.user.twilio_client_name}
                              />
                              {/* <QualifiedSearching
                                assignedAgents={this.state.assignedAgents}
                                referral={this.state.referral}
                                fromScreen={this.state.fromScreen}
                                updateReferralLMPStatusOnParent={this.updateReferralLMPStatusOnParent.bind(this)}
                                updateBrokerAgentsCountOnParent={this.updateBrokerAgentsCountOnParent.bind(this)}
                              /> */}
                             </div>
                              
                            {this.state.client && this.state.referral &&
                              <div className="note-section">
                                <Notes assignedAgents={this.state.assignedAgentObjects}
                                  referral={this.state.referral}
                                  user={this.props.user}
                                  requestUnsavedNote={this.state.requestUnsavedNote}
                                  saveUnsavedNoteLocally={this.saveUnsavedNoteLocally.bind(this)}
                                  tempNote={this.state.tempNote}
                                />
                                {/*<PanelContainer title={"Reminders"}>
                                  <Reminders clientId={this.state.referral.receiver_client_id} agentId={this.state.referral.receiver_id}/>
                                </PanelContainer>*/}
                              </div>
                            }
                           </PanelContainer>
                          }
                          { this.state.stage === 4 && this.state.client && this.state.referral &&
                           <PanelContainer title="Comparison" style={{ "display": "flex" }}>
                            
                            <Comparing user={this.props.user} assignedAgents={this.state.assignedAgents} assignedAgentObjects={this.state.assignedAgentObjects} referral={this.state.referral} togglePrimaryAgentFromChild={this.togglePrimaryAgentFromChild.bind(this)}
                              setUpStage={this.setUpStage.bind(this)}
                            />
                            {this.state.client && this.state.referral &&
                              <div className="note-section">
                                <Notes assignedAgents={this.state.assignedAgentObjects}
                                       referral={this.state.referral}
                                       user={this.props.user}
                                       requestUnsavedNote={this.state.requestUnsavedNote}
                                       saveUnsavedNoteLocally={this.saveUnsavedNoteLocally.bind(this)}
                                       tempNote={this.state.tempNote}/>
                                {/*<PanelContainer title={"Reminders"}>
                                  <Reminders clientId={this.state.referral.receiver_client_id} agentId={this.state.referral.receiver_id}/>
                                </PanelContainer>*/}
                              </div>
                            }
                           </PanelContainer>
                          }
                          {this.state.stage === 5 && this.state.client &&  this.state.referral && this.state.referral.primary_agent_id &&
                           <PanelContainer title="Sales Stages" style={{ "display": "flex" }}>
                               <Connected assignedAgents={this.state.assignedAgents} assignedAgentObjects={this.state.assignedAgentObjects} referral={this.state.referral} callClient={this.callClient.bind(this)}/>
                               {this.state.client && this.state.referral &&
                                 <div className="note-section">
                                   <Notes assignedAgents={this.state.assignedAgentObjects}
                                          referral={this.state.referral}
                                          user={this.props.user}
                                          requestUnsavedNote={this.state.requestUnsavedNote}
                                          saveUnsavedNoteLocally={this.saveUnsavedNoteLocally.bind(this)}
                                          tempNote={this.state.tempNote}/>
                                   {/*<PanelContainer title={"Reminders"}>
                                     <Reminders clientId={this.state.referral.receiver_client_id} agentId={this.state.referral.receiver_id}/>
                                   </PanelContainer>*/}
                                 </div>
                               }
                           </PanelContainer>
                          }
                          {/*this.state.client && this.state.referral &&
                            <div className="note-section">
                              <Notes assignedAgents={this.state.assignedAgentObjects}
                                     referral={this.state.referral}
                                     user={this.props.user}/>
                              <PanelContainer title={"Reminders"}>
                                <Reminders clientId={this.state.referral.receiver_client_id} agentId={this.state.referral.receiver_id}/>
                              </PanelContainer>
                            </div>
                          */}
                      </div>

                  </div>


            </div>
          </div>
      );
    }
    catch(e) {
      console.log(e)
      bugsnagClient.notify(e);
      return (
        <div>
          Something went wrong, please refresh the page and try again.
        </div>
      )

    }

  }

  handleChange = async (e) => {
    console.log({ value: e.target.value });
    this.updateStatus(e.target.value, true);
  };

  resetNewMessagesCount = () => {
    this.setState({newMessagesCount: 0});
    window.open(messagesUrl, 'messageDashboard')
  }
}
export default Header;