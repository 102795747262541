import React from "react"
import styled from "styled-components"
import Button from "dumbComponents/common/UI/Button"
import TextInput from "dumbComponents/BrokerDashboard/Comp/TextInput"
import Switch from "components/UI/Switch/Switch"

const Wrap = styled.div`
`
const MainWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > h2 {
    font-weight: 400;
    gap: 16px;
    margin-right: 20px;
  }
`

const LMPOptions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`

const FilterWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
  gap: 10px;

  > span {
    margin-right: 16px;
  }
  > select {
    padding: 8px;
  }
`

const SearchWrap = styled.div`
  
`

const LMPSwitchWrap = styled.div`
  display: flex;
  > span {
    padding: 4px;
  }
`

const Header = ({
  toggleSearchModal,
  handleClientFilter,
  searchValue,
  reloadProspects,
  sortResults,
  lmpStatus,
  toggleStatus,
  toggleFilter,
}) => (
  <Wrap>
    <MainWrap>
      <h2>Agent Comparison</h2>
      <Button rounded onClick={toggleSearchModal}>
        + Invite more agents
      </Button>
      <LMPOptions>
        <Button rounded onClick={reloadProspects}>
          Reload Prospects
        </Button>
        <LMPSwitchWrap>
          <span>
            LMP Status:
          </span>
          <Switch enabled={lmpStatus} change={toggleStatus}/>
        </LMPSwitchWrap>
        {/* <Button rounded>
          Filter
        </Button> */}
      </LMPOptions>
    </MainWrap>
    <SearchWrap>
      <TextInput
        isHideLabel
        placeholder="Search agents"
        value={searchValue}
        onChange={handleClientFilter}
      />
    </SearchWrap>
    <FilterWrap>
      <span>Sort By: </span>
      <select name="" id="" onChange={(event) => { sortResults({ from: "main-list", item: event.target.value }) }}>
        <option value="">All</option>
        <option value="closed_referral_percentage">Closed Percentage</option>
      </select>
      <Button rounded onClick={toggleFilter}>
        Filters
      </Button>
    </FilterWrap>
  </Wrap>
)

export default Header
