import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Button, Form, Input, Modal, Switch, Flex, Row, Col } from 'antd';
import container from "container/Mortgage"
import TodaysRate from "./TodaysRate"

const Wrap = styled.div`
  margin: 30px auto;
  max-width: 800px;
  background:  #fff;
  border: 1px solid #e1e1e1;
  padding: 20px;
  border-radius: 20px;
`

const blankRate = {
  title: "",
  rate: 0,
  apr: 0,
  description: null,
  time_period: "",
  active: false
}

const MortgagePage = ({
  getCurrentRates,
  updateCurrentRates,
  currentRates,
  ...props
}) => {
  const [ isShowEditModal, setIsShowEditModal ] = useState()
  const [ editPayload, setEditPayload ] = useState()
  const [ isAddingSection, setIsAddingSection ] = useState(false)

  useEffect(() => {
    getCurrentRates()
  }, [])

  const handleEdit = () => {
    setIsShowEditModal(false)
  }

  const handleClose = () => {
    setIsShowEditModal(false)
    setEditPayload(null)
    setIsAddingSection(false)
  }

  const handleOnEdit = (payload) => {
    setIsShowEditModal(true)
    setEditPayload(payload)
  }

  const handleSave = async (payload) => {
    updateCurrentRates({
      id: editPayload.id,
      payload: {
        ...payload,
        apr: Number(payload.apr),
        rate: Number(payload.rate),
      },
      isNew: isAddingSection,
      callback: () => {
        getCurrentRates()
        handleClose()
      },
    })
  }

  return (
    <Wrap>
      {isShowEditModal && (
        <Modal
          title={isAddingSection ? "Create new Rates Section" : "Edit Rates"}
          visible
          onOk={handleEdit}
          onCancel={handleClose}
          footer={null}
          okText="Save Rates"
        >
          <Form
            layout="vertical"
            initialValues={editPayload}
            onFinish={(data) => { handleSave(data) }}
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Rate"
              name="rate"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="APR"
              name="apr"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Time period"
              name="time_period"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Is Active" name="active" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Row>
              <Col flex={1}>
                
              </Col>
              <Col align="flex-end">
                <Button type="primary" loading={false} htmlType="submit">
                  Save Rates
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
      <h3>
        Current Rates
      </h3>
      <TodaysRate
        onEdit={handleOnEdit}
        rateList={currentRates.data}
      />
      <Button
        onClick={() => {
          setIsAddingSection(true)
          setIsShowEditModal(true)
          setEditPayload(blankRate)
        }}
      >
        Add New Section
      </Button>
    </Wrap>
  )
}

export default container(MortgagePage)