import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils";

import {
  fetchAppCustomisationsAction,
  fetchWaitlistAction, saveWaitlistStatusAction, selectedAppAction, updateAppCustomisationsAction, uploadAppAssetsAction,
} from "./actions";

const initialState = {
  selectedAppObject: {},
  ...createDeltaReducer("fetchWaitlistTableResponse"),
  ...createDeltaReducer("saveWaitlistStatusResponse"),
  ...createDeltaReducer("uploadAppAssetsResponse"),
  ...createDeltaReducer("updateAppCustomisationResponse"),
  ...createDeltaReducer("fetchAppCustomisationResponse")
  
};

const consumerAppReducer = (state = initialState, action) => {

  switch (action.type) {
    case selectedAppAction.type: {
    return {
        ...state,
        selectedAppObject: action.data,
      };
    }
    case fetchWaitlistAction.REQUEST:
    case fetchWaitlistAction.SUCCESS:
    case fetchWaitlistAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchWaitlistAction,
          "fetchWaitlistTableResponse"
        ),
      };
    }

    case saveWaitlistStatusAction.REQUEST:
    case saveWaitlistStatusAction.SUCCESS:
    case saveWaitlistStatusAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          saveWaitlistStatusAction,
          "saveWaitlistStatusResponse"
        ),
      };
    }
      
    case uploadAppAssetsAction.REQUEST:
    case uploadAppAssetsAction.SUCCESS:
    case uploadAppAssetsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          uploadAppAssetsAction,
          "uploadAppAssetsResponse"
        ),
      };
    }
      
    case updateAppCustomisationsAction.REQUEST:
    case updateAppCustomisationsAction.SUCCESS:
    case updateAppCustomisationsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          updateAppCustomisationsAction,
          "updateAppCustomisationResponse"
        ),
      };
    }
    case fetchAppCustomisationsAction.REQUEST:
    case fetchAppCustomisationsAction.SUCCESS:
    case fetchAppCustomisationsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchAppCustomisationsAction,
          "fetchAppCustomisationResponse"
        ),
      };
    }
    default:
      return state;
  }
  
};

export default consumerAppReducer;
