import React from "react"
import styled from "styled-components"
import AgentInfo from "./AgentInfo"
import { LeadRow } from "./RowUtils"

const Wrap = styled.div`
  overflow: scroll;

  ${props => props.isFetcher && `
    min-height: 500px;
    justify-content: center;
    align-items: center;
  `}
`

const AgentListWrap = styled.div`
  width: 1200px;
`

const ItemRow = styled.div`
  border-bottom: 1px solid #CCC;
`

const ItemCell = styled.div`
  width: 130px;
  display: inline-block;
  vertical-align: middle;
  padding: 6px;
`

const HeaderLabel = styled.p`
  
`

const AgentList = ({
  agents,
  assignAgent,
  liveTransfer,
  isFetching,
  removeAgentFromSuggestions,
  isSeachModal,
  inviteAgent,
  isAllowedToConnect,
  toggleStarAgent,
}) => {
  if (isFetching) {
    return (
      <Wrap isFetcher>
        <h4>Fetching...</h4>
      </Wrap>
    )
  }
  console.log({ agents });
  return (
    <Wrap>
      {!agents || agents.length === 0 ? (
        <h4>No Agents Found</h4>
      ) : (
        <AgentListWrap>
          <ItemRow>
            <ItemCell style={{ width: 360 }}>
              <HeaderLabel>
                Agent Details
              </HeaderLabel>
            </ItemCell>
            <ItemCell>
              <HeaderLabel>
                Conversions
              </HeaderLabel>
            </ItemCell>
            <ItemCell>
              <HeaderLabel>
                Type
              </HeaderLabel>
            </ItemCell>
            <ItemCell>
              <HeaderLabel>
                Brokerage
              </HeaderLabel>
            </ItemCell>
            <ItemCell>
              <HeaderLabel>
                Location
              </HeaderLabel>
            </ItemCell>
            <ItemCell>
              <HeaderLabel>
                Language
              </HeaderLabel>
            </ItemCell>
          </ItemRow>
          {agents.map(agent => (
            <ItemRow key={agent.id || agent.agent_id}>
              <ItemCell style={{ width: 380 }}>
                <AgentInfo
                  {...agent}
                  assignAgent={assignAgent}
                  liveTransfer={liveTransfer}
                  removeAgentFromSuggestions={removeAgentFromSuggestions}
                  isSeachModal={isSeachModal}
                  inviteAgent={inviteAgent}
                  isAllowedToConnect={isAllowedToConnect}
                  toggleStarAgent={toggleStarAgent}
                />
              </ItemCell>
              <ItemCell>
                <LeadRow {...agent} />
              </ItemCell>
              <ItemCell>
                {agent.is_broker_agent === 1 && "Broker"}
                {agent.is_accelerator_agent === 1 && "(Accelerator)"}
              </ItemCell>
              <ItemCell>
                <p>{agent.agency_name}</p>
              </ItemCell>
              <ItemCell>
                <p>
                  {agent.citiesServed
                    && agent.citiesServed.length > 0
                    && agent.citiesServed.map(x => x.city)
                      .join(", ")
                  }
                </p>
              </ItemCell>
              <ItemCell>
                <p>
                  {agent.languages
                    && agent.languages.length > 0
                    && agent.languages.join()
                  }
                </p>
              </ItemCell>
            </ItemRow>
          ))}
        </AgentListWrap>
      )}
    </Wrap>
  )
}

export default AgentList
