import React, { Component } from "react"
import styled from "styled-components"
import colors from "../../../lib/colors"
import { reduxForm, Field } from "redux-form"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import AddLessonMenu from "./AddLessonMenu"
import About from "./forms/About"
import RTFEditor from "dumbComponents/Academy/Common/RTF"
import RTFEditorIO from "dumbComponents/Academy/Common/EditorRTF"

const OuterWrap = styled.div`
 display: flex;
 .about-les-form {
   width: 500px;
 }
`
const LessonsForm = styled.form`
 display: flex;
 flex-direction: column;
 margin-bottom: -250px;
 label {
  font-family: "SF Pro Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #24292e;
 }
`
const UpperDiv = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
`

const LowerDiv = styled.div`
  width: 100%;
  height: 350px;
  padding: 50px;
  button {
   float: right;
  }
`

const InfoDiv = styled.div`
  width: 250px;
  height: 350px;
  flex-grow: 3;
  .fields {
   margin-bottom: 30px;
   label {
    padding-bottom: 10px;
   }
  }
  .info {
   font-size: 12px !important;
   margin: 0;
   color: #586069;
  }
`

const InputFields = styled(Field)`
  width: 100%;
  height: 39px;
  border-radius: 3px;
  border: solid 1px #d1d5da;
  background-color: #fafbfc;
  margin-top: 10px;
  padding-left: 9px;
`

class AboutLesson extends Component {
 state = {
  isUpdateMode: this.props.match.params.id,
 }

 componentDidMount(){
  const {
   getCourseDetails,
   setLessonType,
   courseDetailResponse,
  } = this.props

  if (this.props.match.params.id) {
   getCourseDetails({ id: this.props.match.params.id})
  }

  const {
   data: courseDetail,
   isFetching,
  } = courseDetailResponse || {}

  const courseExists =
  this.props.match.params.lid
  && this.props.match.params.id
  && courseDetail
  && Object.keys(courseDetail).length > 0
  && courseDetail.Lessons

  const current = courseExists
  && courseDetail.Lessons.filter(lesson => lesson.id === this.props.match.params.lid)[0].type
  if(current === "text"){
   setLessonType({
    type: [{
     value: "text",
     label: "Notes",
    }],
   })
  } else if(current === "video"){
   setLessonType({
    type: [{
     value: "video",
     label: "Video",
    }],
   })
  }
 }

 render() {
  const {
   courseDetailResponse,
   aboutLessonMarketing,
  } = this.props
  const {
   data: courseDetail,
   isFetching,
  } = courseDetailResponse || {}

  const courseExists =
  this.props.match.params.lid
  && this.props.match.params.id
  && courseDetail
  && Object.keys(courseDetail).length > 0
  && courseDetail.Lessons

  return (
   <React.Fragment>
    {isFetching ? (
     <OuterWrap>
      <AddLessonMenu
        isUpdateMode={this.props.match.params.id || ""}
        isIndividualLessonMode={this.props.match.params.lid || "" }
      />
      <div className="about-les-form">
       <p>Loading</p>
      </div>
     </OuterWrap>
    ) : (
     <OuterWrap>
      <AddLessonMenu
        submitFunc={this.submit}
        isUpdateMode={this.props.match.params.id || ""}
        isIndividualLessonMode={this.props.match.params.lid || "" }
      />
      <div className="about-les-form">
        <p>About Lesson</p>
        <About
         courseDetail={courseDetail}
         isIndividualLessonMode={this.props.match.params.lid || "" }
        />
        <RTFEditorIO
         contentType="aboutLessonMarketing"
         contentTitle="Marketing Intro (applicable only for video lessons)"
         rtfContent={
          this.props.match.params.lid
          && this.props.match.params.id
          && courseExists
          && courseDetail.Lessons.filter(lesson => lesson.id === this.props.match.params.lid)[0].description || aboutLessonMarketing || ""}
        />
      </div>
     </OuterWrap>
    )}
   </React.Fragment>
  )
 }
}

export default Container(AboutLesson)
