import React from "react"
import styled from "styled-components"
import { get } from "lodash"

const Wrap = styled.div`
  position: absolute;
  width: calc(100% - 40px);
  z-index: 101;
  margin: 10px 20px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
`

const Item = styled.div`
  padding: 10px;
  background: #fff;
  border-bottom: 1px solid #ccc;
  transition-duration: 0.3s;
  cursor: pointer;

  &:hover {
    background: #eee;
  }

  > p {
    padding: 0px;
    margin: 0px;
    &:nth-child(1) {
      font-size: 16px;
    }
    &:nth-child(2),
    &:nth-child(3) {
      color: #888;
      font-size: 12px;
    }
  }
`

const SearchResult = ({
  agents,
  setClientDetailsFromSearch,
}) => (
  <Wrap>
    {agents.map(x => (
      <Item key={x.id} onClick={() => { setClientDetailsFromSearch(x.id, x.phone) }}>
        <p>{x.name}</p>
        <p>{x.phone}</p>
        <p>{get(x, "sender_agent.firstname")} {get(x, "sender_agent.lastname", "")}</p>
      </Item>
    ))}
  </Wrap>
)

export default SearchResult