import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"
const initState = {
  currentSelectedPlan: [],
  currentRTLPlanSelected: [],
  currentBLUPlanSelected: [],
  currentBROKERPlanSelected: [],
  currentRTLSelectedInstallmentPlan: [],
  currentBLUSelectedInstallmentPlan: [],
  currentSelectedInstallmentPlan: [],
  modifiedBenefitArray: [],
  leadCapValue: "",
  singleLeadCap: "",
  teamLeadCap: "",
  couponIsRemoved: false,
  rtlCoupon: "",
  bluCoupon: "",
  brokerCoupon: "",
  currentMode: "rtl",
  addedBenefits: [],
  alreadyAddedFeatures: [],
  parentBenefitPlans: [],
  isAgentListDropdownVisible: false,
  ...createDeltaReducer("agents"),
  ...createDeltaReducer("plans"),
  ...createDeltaReducer("subscriptions"),
  ...createDeltaReducer("checkout"),
  ...createDeltaReducer("secure_id"),
  ...createDeltaReducer("preferences"),
  ...createDeltaReducer("couponResponse"),
  ...createDeltaReducer("installmentResponse"),
  ...createDeltaReducer("customizedSubscriptionResponse"),
  ...createDeltaReducer("getAutoReferOutResponse"),
  ...createDeltaReducer("setAutoReferOutResponse"),
}

export default function clientProfileReducer(state = initState, action) {
  switch (action.type) {
    case Actions.fetchAgentSearchAction.REQUEST:
    case Actions.fetchAgentSearchAction.SUCCESS:
    case Actions.fetchAgentSearchAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchAgentSearchAction, "agents"),
      }
    }

    case Actions.fetchStripePlansAction.REQUEST:
    case Actions.fetchStripePlansAction.SUCCESS:
    case Actions.fetchStripePlansAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchStripePlansAction, "plans"),
      }
    }

    case Actions.fetchSubscriptionsAction.REQUEST:
    case Actions.fetchSubscriptionsAction.SUCCESS:
    case Actions.fetchSubscriptionsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchSubscriptionsAction, "subscriptions"),
      }
    }

    case Actions.checkoutPlanAction.REQUEST:
    case Actions.checkoutPlanAction.SUCCESS:
    case Actions.checkoutPlanAction.CLEAR:
    case Actions.checkoutPlanAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.checkoutPlanAction, "checkout"),
      }
    }

    case Actions.fetchAgentSecureIdAction.REQUEST:
    case Actions.fetchAgentSecureIdAction.SUCCESS:
    case Actions.fetchAgentSecureIdAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchAgentSecureIdAction, "secure_id"),
      }
    }

    case Actions.fetchUserReferralSettingsAction.REQUEST:
    case Actions.fetchUserReferralSettingsAction.SUCCESS:
    case Actions.fetchUserReferralSettingsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchUserReferralSettingsAction, "preferences"),
      }
    }

    case Actions.handleCouponApplyAction.REQUEST:
    case Actions.handleCouponApplyAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.handleCouponApplyAction, "couponResponse"),
      }
    }

    case Actions.handleCouponApplyAction.SUCCESS: {
      return {
        ...state,
        couponIsRemoved: false,
        ...handleDeltaReducer(state, action, Actions.handleCouponApplyAction, "couponResponse"),
      }
    }


    case Actions.getCurrentPlanInstallmentsAction.REQUEST:
    case Actions.getCurrentPlanInstallmentsAction.SUCCESS:
    case Actions.getCurrentPlanInstallmentsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getCurrentPlanInstallmentsAction, "installmentResponse"),
      }
    }

    case Actions.setCustomizedSubscriptionSettingsAction.REQUEST:
    case Actions.setCustomizedSubscriptionSettingsAction.SUCCESS:
    case Actions.setCustomizedSubscriptionSettingsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.setCustomizedSubscriptionSettingsAction, "customizedSubscriptionResponse"),
      }
    }

    //getCurrentAutoReferOutSettingsActions

    case Actions.getCurrentAutoReferOutSettingsActions.REQUEST:
    case Actions.getCurrentAutoReferOutSettingsActions.SUCCESS:
    case Actions.getCurrentAutoReferOutSettingsActions.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getCurrentAutoReferOutSettingsActions, "getAutoReferOutResponse"),
      }
    }

    case Actions.setCurrentAutoReferOutSettingsActions.REQUEST:
    case Actions.setCurrentAutoReferOutSettingsActions.SUCCESS:
    case Actions.setCurrentAutoReferOutSettingsActions.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.setCurrentAutoReferOutSettingsActions, "setAutoReferOutResponse"),
      }
    }

    case Actions.currentSelectedPlanAction.type: {
      const {
        data: {
          currentSelectedPlan,
        }
      } = action
      return {
        ...state,
        currentSelectedPlan,
      }
    }

    case Actions.setCurrentPlanInstallmentsAction.type: {
      const {
        data: {
          currentMode,
          currentSelectedInstallmentPlan,
        }
      } = action

      if (currentMode === "rtl") {
        return {
          ...state,
          rtlCoupon: "",
          currentRTLSelectedInstallmentPlan: currentSelectedInstallmentPlan,
          currentSelectedInstallmentPlan,
        }
      }
      if (currentMode === "blu") {
        return {
          ...state,
          bluCoupon: "",
          currentBLUSelectedInstallmentPlan: currentSelectedInstallmentPlan,
          currentSelectedInstallmentPlan,
        }
      }
    }

    case Actions.clearErrorMessageAction.type: {
      return {
        ...state,
        setAutoReferOutResponse: {},
      }
    }

    case Actions.setModifiedPremiumBenefitsAction.type: {
      const {
        data: {
          modifiedBenefitArray,
        }
      } = action
      return {
        ...state,
        modifiedBenefitArray,
      }
    }

    //setLeadCapAction
    case Actions.setLeadCapAction.type: {
      const {
        data: {
          singleLeadCap,
          teamLeadCap,
        }
      } = action
      return {
        ...state,
        singleLeadCap: singleLeadCap || "",
        teamLeadCap: teamLeadCap || "",
      }
    }

    case Actions.couponRemovedAction.type: {
      const {
        couponIsRemoved,
        currentMode
      } = action.data
      if (currentMode === "rtl") {
        return {
          ...state,
          couponIsRemoved,
          rtlCoupon: "",
        }
      }
      if (currentMode === "blu") {
        return {
          ...state,
          couponIsRemoved,
          bluCoupon: "",
        }
      }
      if (currentMode === "broker") {
        return {
          ...state,
          couponIsRemoved,
          brokerCoupon: "",
        }
      }
    }

    //resetInstallmentSelectionAction

    case Actions.resetInstallmentSelectionAction.type: {
      return {
        ...state,
        currentSelectedInstallmentPlan: [],
      }
    }

    case Actions.currentRTLPlanSelectedAction.type: {
      const {
        currentRTLPlanSelected,
      } = action.data
      return {
        ...state,
        currentMode: "rtl",
        rtlCoupon: "",
        currentRTLPlanSelected,
      }
    }

    case Actions.setCurrentModeAction.type: {
      const {
        currentMode,
      } = action.data
      return {
        ...state,
        currentMode,
      }
    }

    case Actions.saveCouponStateAction.type: {
      const {
        currentMode,
        payload,
      } = action.data
      if (currentMode === "rtl") {
        return {
          ...state,
          rtlCoupon: payload,
        }
      }
      if (currentMode === "blu") {
        return {
          ...state,
          bluCoupon: payload,
        }
      }
      if (currentMode === "broker") {
        return {
          ...state,
          brokerCoupon: payload,
        }
      }
    }

    case Actions.currentBLUPlanSelectedAction.type: {
      const {
        currentBLUPlanSelected,
      } = action.data
      return {
        ...state,
        currentMode: "blu",
        bluCoupon: "",
        currentBLUPlanSelected,
      }
    }

    case Actions.currentBROKERPlanSelectedAction.type: {
      const {
        currentBROKERPlanSelected,
      } = action.data
      return {
        ...state,
        currentMode: "broker",
        currentBROKERPlanSelected,
      }
    }

    case Actions.setBenefitsToBeAddedAction.type: {
      const { addedBenefits, alreadyAddedFeatures } = action.data
      return {
        ...state,
        addedBenefits,
        alreadyAddedFeatures
      }
    }

    case Actions.setParentBenefitsPlansAction.type: {
      const { parentBenefitPlans } = action.data
      return {
        ...state,
        parentBenefitPlans
      }
    }

    case Actions.toggleAgentListDropdownAction.type: {
      return {
        ...state,
        isAgentListDropdownVisible: action.data,
      }
    }

    default: {
      return state
    }
  }
}
