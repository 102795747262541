import React from "react";
import styled from "styled-components";


const SwitchWrapper = styled.div`
  flex: 3 0;
  text-align: center;
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 48px !important;
  height: 26px !important;
  input {
    display: none;
  }
  input:checked + span {
    background-image: linear-gradient(to bottom, #39b54a, #34aa44) !important;
  }
  input:focus + span {
    box-shadow: none !important;
  }

  input:checked + span:before {
    -webkit-transform: translateX(21px) !important;
    -ms-transform: translateX(21px) !important;
    transform: translateX(21px) !important;
  }
`;

const SwitchSpan = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: #e9edf1 !important;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: "";
    border-radius: 50%;
    height: 21px !important;
    width: 21px !important;
    left: 3px !important;
    bottom: 3px !important;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`;

const Switch = props => {
    return (
        <SwitchWrapper>
            <SwitchLabel>
                <input
                    type="checkbox"
                    checked={props.enabled}
                    onChange={() => props.change()}
                />
                <SwitchSpan />
            </SwitchLabel>
        </SwitchWrapper>
    );
};

export default Switch;