import React from "react"
import styled from "styled-components"
import colors from "../../lib/colors"

const HeaderWrap = styled.div`

`
const StyledHeader = styled.h1`
  font-size: 36px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.black};
`
//check here
const Header = () => (
  <HeaderWrap>
    <StyledHeader>Listings Dashboard</StyledHeader>
  </HeaderWrap>
)

export default Header
