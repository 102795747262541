import styled from "styled-components"
import { Input, InputNumber } from "antd"

export const SidebarTitle = styled.p`
  color: var(--neutral-500, #64748B);
  font-family: 'Roboto';
  font-size: ${props => props.fontSize || "18px"};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`
export const SidebarHeading = styled.p`
overflow: hidden;
color: var(--neutral-800, #191D23);
text-overflow: ellipsis;
whitespace: nowrap;
font-family: 'Roboto';
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin: 0;
`

export const TitleWrap = styled.div`
display: flex;
flex-direction: column;
gap: 24px;

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: var(--neutral-500, #64748B) !important;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
`

export const CustomInput = styled(Input)`
.ant-input {
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  background: var(--White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
`

export const CustomInputNumber = styled(InputNumber)`
.ant-input {
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  background: var(--White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
`

export const LicenseTransWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const SwitchWrap = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const SwitchText = styled.p`
  margin: 0;
`

export const LicenseTransDateWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Container = styled.div`
  border-radius: 4px;
  border: 1px solid #E8E8E8;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`

export const ContainerMap = styled.div`
  border-radius: 4px;
  background: #F1EEFF;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const ContainerTitle = styled.p`
  overflow: hidden;
  color: var(--neutral-800, #191D23);
  text-overflow: ellipsis;
  font-family: 'Roboto';
  font-size: ${props => props.fontSize || "24px"};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`

export const ContainerDescription = styled.p`
  overflow: hidden;
  color: ${props => props.color || "#656565"};
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`
