import React from "react"
import styled from "styled-components"
import Button from "dumbComponents/common/UI/Button"
import AddDeal from "dumbComponents/BrokerDashboard/AgentDashboard/AddDeal"


const Wrap = styled.div`
  
`

const Header = styled.div`
  
`

class AddGlobalDeals extends React.PureComponent {
  state = {}

  componentDidMount() {
    // const {  } = this.props
  }

  render() {
    // const {  } = this.state
    // const {  } = this.props
    return (
      <Wrap>
        <AddDeal
          isShowAgentSearch
        />
      </Wrap>
    )
  }
}

export default AddGlobalDeals
