import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"
import AddEventMenu from "./AddEventsMenu"
import { DefaultLoader } from "components/UI/Loader/Loader";
import _ from 'lodash';

const Wrap = styled.div`
 height: 100px;
 width: 300px;
 border: 1px solid red;
 margin-left: 100px;
`

const EditInstructor = styled(Link)`
  margin-bottom: 14px;
  width: 118px;
  display: block;
  height: 30px;
  font-size: 14px;
  border: none;
  background: none;
  color: black;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding-top: 2px;
`

const ListWrap = styled.ul`
 list-style: none;
 padding:0;
 margin:0;
 li {
  width: 100%;
  height: 68px;
  border: solid 1px #e2e4e8;
  display: flex;
  .instructor_image {
    flex-grow: 1;
    height: 100%;
    max-width: 60px;
    padding-top: 14px;
    padding-left: 14px;
    img {
     width: 34px;
     height: 34px;
     border-radius: 18px;
    }
  }
  .instructor_details{
    height: 100%;
    padding: 14px;
    width: 222px;
   p {
    margin: 0;
   }
   p:last-child {
    font-size: 12px;
    color: #586069;
   }
  }
  .cta {
   height: 100%;
   width: 400px;
   display: flex;
   justify-content: flex-end;
   div {
    flex-grow: 1;
    height: 100%;
    padding: 23px 8px 8px 24px;
    max-width: 99px;
    button{
     cursor: pointer;
     background: none;
     border: none;
    }
    .delete {
     color: red;
    }
   }
  }
 }
`

const OuterWrap = styled.div`
 display: flex;
`

const RightMenu = styled.div`
  height: 500px;
  width: 699px;
  padding-left: 20px;

  .access {
   display: flex;
   flex-direction: column;
  }

  .header {
   height: 50px;
   width: 100%;
   p {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin: 0;
   }
  }

  textarea {
   resize: none;
   font-family: "SF Pro Display", sans-serif;
   width: 724px;
   height: 125px;
   border-radius: 3px;
   border: solid 0.5px #000000;
  }
`

class EventSettings extends Component {
 state = {
  isUpdateMode: this.props.match.params.id,
 }
 componentDidMount(){
  const {
   getEventDetails,
   accessToCourse,
   getEventDetailsResponse,
   setTest,
   setPrivate,
   enablePushNotification,
  } = this.props

  if(this.props.match.params.id) {
   getEventDetails({ id: this.props.match.params.id})
  }

  const {
   data: eventDetails,
  } = getEventDetailsResponse

  const eventExists =
  this.props.match.params.id
  && eventDetails
  && Object.keys(eventDetails).length > 0

  if(eventExists) {
   accessToCourse(eventDetails.access_to)
   enablePushNotification({
     enableEventPushNotificationBool: eventDetails.send_notification,
   })
   setTest({
    testOnly: eventDetails.test,
   })
  }
 }

 handleAccess = (e) => {
  const {
   accessArray,
   accessToCourse,
  } = this.props

  let access = []
  let curr = e.target.value
  if(accessArray.length > 0) {
   access = [...accessArray]
  }
  if(access[0] === "registered"){
   access[0] = curr
  } else {
   access[0] = "registered"
  }
  let count = _.countBy(access)
  let uniqAccess = access.filter((value,index) => access.indexOf(value) === index)
  uniqAccess = uniqAccess.filter(value => count[value] === 1 )
  accessToCourse(uniqAccess)
 }

 handlePrivateEvent = () => {
   const {
     isPrivate,
     setPrivate,
   } = this.props

   setPrivate({
     isPrivate: !isPrivate,
   })
 }

 handleNotificationPush = () => {
   const {
     enableEventPushNotificationBool,
     enablePushNotification,
   } = this.props

   enablePushNotification({
     enableEventPushNotificationBool: !enableEventPushNotificationBool,
   })
 }

 handleDelete = () => {
   const {
    deleteEvent,
   } = this.props
   deleteEvent({
    id: this.props.match.params.id,
   })
 }

 handleTestOnly = () => {
  const {
   setTest,
   testOnly,
  } = this.props

  setTest({
   testOnly: !testOnly,
  })
 }

 handleChange = (e) => {
  const {
   saveSettingsValues,
  } = this.props

  this.setState({
   [e.target.name]: e.target.value
  })
  saveSettingsValues({
   data: e.target.value,
   type: e.target.name
  })
 }

 render(){
  const {
   communityEventPostText,
   getEventDetailsResponse,
   eventAnnouncementText,
   eventReminderPost,
   accessArray,
   testOnly,
   isPrivate,
   enableEventPushNotificationBool,
   feedbackGatheringPushNotificationText,
  } = this.props

  const {
   data: eventDetails,
   isFetching: fetchingEventDetails,
  } = getEventDetailsResponse || {}

  return (
    <React.Fragment>
    {fetchingEventDetails ? (
     <OuterWrap>
       <AddEventMenu
        isUpdateMode={this.props.match.params.id || ""}
       />
       <RightMenu>
        <DefaultLoader height={200} width={200}/>
       </RightMenu>
     </OuterWrap>
    ) : (
     <OuterWrap>
      <AddEventMenu
       isUpdateMode={this.props.match.params.id || ""}
      />
      <RightMenu>
        {this.props.match.params.id && (
         <div>
          <h1>Settings</h1>
          <p>Delete this Event ?</p>
          <button onClick={this.handleDelete}>Delete</button>
         </div>
        )}
        <div>
         <p>Notification Settings</p>
         <div>
          <p>Announcement Text</p>
          <textarea
          onChange={this.handleChange}
          name="eventAnnouncementText"
          value={eventAnnouncementText}
          >
          </textarea>
         </div>
         <div>
          <p>Community Post Text</p>
          <textarea
          onChange={this.handleChange}
          name="communityEventPostText"
          value={communityEventPostText}
          >
          </textarea>
         </div>
         <div>
          <p>Text for 15 mins before Reminder</p>
          <textarea
          onChange={this.handleChange}
          name="eventReminderPost"
          value={eventReminderPost}
          >
          </textarea>
         </div>
         {/*
         <div>
          <p>Feedback Gathering Push Notification Text</p>
          <textarea
          onChange={this.handleChange}
          name="feedbackGatheringPushNotificationText"
          value={feedbackGatheringPushNotificationText}
          >
          </textarea>
         </div>
        */}
        </div>

        {/*<div>
         <p>Third Party Event</p>
          <div className="access">
           <div>
            <input checked={isPrivate} name="broker" type="checkbox" value="broker" onChange={this.handlePrivateEvent}/>
            <label htmlFor="broker">This is a Third Party Event</label>
           </div>
          </div>
        </div>
        */}
        <div>
         <p>Send Notification</p>
          <div className="access">
           <div>
            <input checked={enableEventPushNotificationBool} name="broker" type="checkbox" value="broker" onChange={this.handleNotificationPush}/>
            <label htmlFor="broker">Notification on publishing event?</label>
           </div>
          </div>
        </div>
        <div>
         <p>Access To</p>
          <div className="access">
           <div>
            <input checked={accessArray && accessArray.indexOf("broker") > -1} name="broker" type="checkbox" value="broker" onChange={this.handleAccess}/>
            <label htmlFor="broker">Brokerage plan agents</label>
           </div>
           {/*<div>
            <input checked={accessArray && accessArray.indexOf("registered") > -1} name="registered" type="checkbox" value="registered" onChange={this.handleAccess}/>
            <label htmlFor="registered">All registered agents</label>
           </div>
           <div>
            <input checked={accessArray && accessArray.indexOf("team") > -1} name="team" type="checkbox" value="team" onChange={this.handleAccess}/>
            <label htmlFor="team">Team plan agents</label>
           </div>*/}
          </div>
        </div>
        <div>
         <p>TEST</p>
          <div className="testSet">
           <div>
            <input checked={testOnly} name="testValue" type="checkbox" onChange={this.handleTestOnly}/>
            <label htmlFor="broker">TEST Event</label>
           </div>
          </div>
         </div>
      </RightMenu>
     </OuterWrap>
    )}
    </React.Fragment>
  )
 }
}

export default Container(EventSettings)
