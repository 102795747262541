import React, { useState, useEffect } from "react"
import CookiesStorage from "services/CookieStorage"
import AI from "container/AI/index"
import {
  ActionAreaWrap,
  ActionRow,
} from "./actionAreaStyle"
import {
  AiToggle,
  MarkAsResolved,
  UserInputBox,
} from "./Elements"

const ActionAreaComponent = ({
  updateCurrentChatView,
  currentSession,
  currentSessionId,
  currentTopics,
  toggleAIInChat,
  toggleAIinChatResponse,
  submitQueryToChat,
  disabled,
}) => {
  const [message, setMessage] = useState("")
  const [isAiEnabled, setIsAIEnabled] = useState(false)
  const [agentId, setCurrentAgentID] = useState("")

  useEffect(() => {
    if (currentTopics && currentSessionId) {
      const filterTopics = currentTopics && currentTopics.topics
        .filter(item => item.session_id === currentSessionId) || []

      const aiEnabled = filterTopics && filterTopics.length > 0 && filterTopics[0].ai_response_on === 1
      const id = currentTopics.data?.agent_id || null
      if (id) {
        setIsAIEnabled(aiEnabled)
        setCurrentAgentID(id)
      }
    }
  }, [currentTopics, currentSessionId, toggleAIinChatResponse])

  const handleChange = (e) => {
    const {
      target: {
        value,
      },
    } = e

    setMessage(value)
  }

  const handleSubmit = (e) => {
    if (e.key === "Enter") {
      const newMessage = {
        createdAt: Math.floor(Date.now() / 1000),
        feedback: 0,
        feedback_reason: null,
        message,
        message_id: 9999,
        message_timestamp: Math.floor(Date.now() / 1000),
        role: "radius_team",
        updated_at: Math.floor(Date.now() / 1000),
      }

      updateCurrentChatView({
        chats: [...currentSession, newMessage],
      })

      const id = CookiesStorage.load("admin_id")

      const payload = {
        message,
        role: "radius_team",
        soul_admin_agent_id: parseInt(id, 10),
      }
      submitQueryToChat({
        payload,
        sessionId: currentSessionId,
        agentId,
      })

      setMessage("")
    }
  }

  const aiToggleChange = () => {
    const payload = {
      session_id: currentSessionId,
      activate: isAiEnabled ? 0 : 1, //0 means turn AI response Off
    }

    toggleAIInChat({
      agentId,
      payload,
      currentTopics,
      markAsResolved: false,
    })
  }

  return (
    <ActionAreaWrap>
      <ActionRow>
        <AiToggle
          isAiEnabled={disabled || isAiEnabled}
          onChange={aiToggleChange}
        />
        {!isAiEnabled && (
          <MarkAsResolved
            isChecked={isAiEnabled}
            onChange={() => {
              const payload = {
                session_id: currentSessionId,
                activate: 1,
              }

              toggleAIInChat({
                agentId,
                payload,
                currentTopics,
                markAsResolved: true,
              })
            }}
          />
        )}
      </ActionRow>
      <ActionRow>
        <UserInputBox
          message={message}
          onChange={handleChange}
          onKeyPress={handleSubmit}
          isDisabled={isAiEnabled || disabled}
        />
      </ActionRow>
    </ActionAreaWrap>
  )
}

export default AI(ActionAreaComponent)
