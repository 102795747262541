import React, { Component } from 'react';
import {Flex, Box} from "@rebass/grid";
import bugsnag from 'bugsnag-js';
import Textbox from "../../../UI/Textbox/Textbox";
import Button from "../../../UI/Button/Button";
import styled from "styled-components";

const baseUrl =process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;

const originalKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(originalKey);

const Select = styled.select`
  height: 40px;
  width: 100%;
`;

const ModalTitle = styled.h3`
  font-size: 24px;
  font-weight: 500;
  color: #354052;
  margin-top: 0;
`;

const ModalIcon = styled.i`
  float: right;
  color: #dadada;
  font-size: 22px;
  margin-right: -20px;
  cursor: pointer;
`;

const roles = [
  {
    name: "Account Manager",
    value: "Account Manager"
  },
  {
    name: "Consultant",
    value: "Consultant"
  }
];

const StyledAnchor = styled.a`
  font-size: 16px;
  font-weight: 500;
  color: #d26464;
  text-decoration: none;
`;

const ModalFooter = styled(Flex)`
  margin: 20px 0;
`;

const titles = [
  {
    name: "Senior",
    value: "Senior"
  },
  {
    name: "Junior",
    value: "Junior"
  }
];

class AddEmployee extends Component {
  state = {
    currentEmployee: null,
    saving: false,
  };

  componentWillReceiveProps(props) {
    this.setState({ currentEmployee: props.currentEmployee, mode: props.mode});
  }

  componentDidMount() {
    this.setState({ currentEmployee: this.props.currentEmployee, mode: this.props.mode});
  }

  updateName = (e) => {
    let currentEmployee = this.state.currentEmployee;
    currentEmployee.name = e.target.value;
    this.setState({currentEmployee});
  };

  updateEmail = (e) => {
    let currentEmployee = this.state.currentEmployee;
    currentEmployee.email = e.target.value;
    this.setState({currentEmployee});

  };

  updateRole = (e) => {
    let currentEmployee = this.state.currentEmployee;
    let roles = currentEmployee.role.split(' ');
    currentEmployee.role = roles[0] + ' ' + e.target.value;
    this.setState({currentEmployee});
  };

  updateTitle = (e) => {
    let currentEmployee = this.state.currentEmployee;
    let roles = currentEmployee.role.split(' ');
    currentEmployee.role = (e.target.value === 'Senior' ? 'Sr' : 'Jr') + ' ' + (roles.length === 2 ? roles[1] : roles[1] + ' ' + roles[2]);
    this.setState({currentEmployee});
  };

  deleteAccount = (e) => {
    this.props.deleteAccount();
  };

  createAccount = async () => {
    try {
      this.setState({saving : true});
      let currentEmployee = this.state.currentEmployee;
      let roleParts = currentEmployee.role.split(' ');
      currentEmployee.title = roleParts[0] === 'Senior' ? 'Sr' : 'Jr';
      currentEmployee.role = roleParts.length === 2 ? roleParts[1] : roleParts[1] + ' ' + roleParts[2];
      
      let response = await fetch(baseUrl + '/v1.0/consultants/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'secure-id': token,
        },
        body: JSON.stringify(this.state.currentEmployee)
      });
      let respJson = await response.json();
      this.setState({saving : false});
      await this.props.refreshPage(); 
      await this.props.closeModal();
    }
    catch (e) {
      this.setState({saving : false});
      bugsnagClient.notify(e);
    }  
  };

  updateAccount = async () => {
    try {
      let title;
      let role = 'Consultant';
      let currentEmployee = this.state.currentEmployee;

      let roles = currentEmployee.role.split(' ');
      title = roles[0];
      if (roles.length === 2){
        role = roles[1];
      }
      else{
        role = roles[1] + ' ' + roles[2];
      }
        
      let body = {
        name: this.state.currentEmployee.name,
        role: role,
        title: title,
      };
      let response = await fetch(baseUrl + '/v1.0/consultants/edit/' + this.state.currentEmployee.id, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'secure-id': token,
          },
          body: JSON.stringify(body)

      });
      let respJson = await response.json();
      await this.props.refreshPage(); 
      await this.props.closeModal();
    }
    catch (e) {
      bugsnagClient.notify(e);
    }  
  };

  render() {
    const { currentEmployee, mode} = this.state;

    const titleDropDown = titles.map(option => {
      return <option value={option.value}>{option.name}</option>
    });

    const roleDropDown = roles.map(option => {
      return <option value={option.value}>{option.name}</option>
    });

    let title = '';
    let role = '';
    if (currentEmployee) {
      let roles = currentEmployee.role.split(' ');
      title = roles[0] === 'Sr' ? 'Senior' : 'Junior'; 
      if (roles.length === 2){
        role = roles[1];
      }
      else {
        role = roles[1] + ' ' + roles[2];
      }
    }   
    return mode !== 'create' ? <div className={"employee"}>
      <ModalTitle>
        {'EDIT EMPLOYEE INFO'} 
        <ModalIcon
          className={"fas fa-times modal-icon"}
          onClick={this.props.closeModal}
        />
      </ModalTitle>
      <form>
        <Flex>
          <Box width={1} pr={"5px"} my={"10px"}>
            <Textbox value={currentEmployee ? currentEmployee.name : 'Name'} onChange={this.updateName}/>
          </Box>
        </Flex>
        <Flex>
          <Box width={1 / 2} pr={"5px"} my={"10px"}>
          <Select onChange={this.updateTitle} value={title}>
              {titleDropDown}
           </Select>
          </Box>
          <Box width={1 / 2} pr={"5px"} my={"10px"}>
          <Select onChange={this.updateRole} value={role}>
              {roleDropDown}
           </Select>
          </Box>
        </Flex>
        <ModalFooter alignItems={"center"} justifyContent={"space-between"}>
            <Box my={3} width={1 / 2}>
                <Button onClick={(e) => {e && e.preventDefault(); this.deleteAccount()}}>DELETE</Button>
            </Box>
            <Box my={3} width={1 / 2}>
              <Button onClick={(e) => {e && e.preventDefault(); this.updateAccount()}}>Save</Button>
            </Box>
        </ModalFooter>
      </form>
    </div>
    :
    <div className={"employee"}>
      <ModalTitle>
        ADD NEW EMPLOYEE 
        <ModalIcon
          className={"fas fa-times modal-icon"}
          onClick={this.props.closeModal}
        />
      </ModalTitle>
      <form>
        <Flex>
          <Box width={1 / 2} pr={"5px"} my={"10px"}>
            <Textbox placeholder={"Name"} onChange={this.updateName} value={currentEmployee.name}/>
          </Box>
          <Box width={1 / 2} pl={"5px"} my={"10px"}>
            <Textbox placeholder={"Email"} onChange={this.updateEmail} value={currentEmployee.mail}/>
          </Box>
        </Flex>
        <Flex>
          <Box width={1 / 2} pr={"5px"} my={"10px"}>
          <Select onChange={this.updateTitle} value={title}>
              {titleDropDown}
           </Select>
          </Box>
          <Box width={1 / 2} pr={"5px"} my={"10px"}>
          <Select onChange={this.updateRole} value={role}>
              {roleDropDown}
           </Select>
          </Box>
        </Flex>
        <ModalFooter alignItems={"center"} justifyContent={"space-between"}>
            <Box my={3} width={1 / 2}>
              <Button disabled={this.state.saving} onClick={(e) => {e && e.preventDefault(); this.createAccount()}}>Save</Button>
            </Box>
        </ModalFooter>
      </form>
    </div>
  }

}

 
export default AddEmployee;
