import React, { Component } from 'react';
import PanelContainer from  "../../container/PanelContainer/PanelContainer";
import bugsnag from 'bugsnag-js';
import {saveNote} from "./../API/NotesApi";
import {DefaultLoader} from "./../UI/Loader/Loader";
import {NotifyBugsnagError} from "../../lib/ErrorUtils";
import AssignMostRecentlyConnectedAgent from "./../UI/AssignMostRecentlyConnectedAgent"
const bugsnagkey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(bugsnagkey);

class AddNote extends Component {

    state = {
        loading: false,
        ccSendingAgent: false,
        ccReceivingAgent: false,
        ccPrimaryAgent: false,
        sendEmail: false,
        currentNote: "",
    };

    startLoader = () => {
        this.setState({loading: true})
    };

    stopLoader = () => {
        this.setState({loading: false})
    };

    handleChange = e => {
        this.setState({ currentNote: e.target.value });
    };

    saveNote = async () => {
        try {

            if(!this.props.referral) {
                return;
            }

            let data = [];

            this.startLoader();
            let note =  {
                agent_id: 484725,
                internal_note: 1,
                client_id: this.props.referral.receiver_client_id,
                admin_agent_id: this.props.user.agent_id,
                sendEmail: this.state.sendEmail,
            };
            data.push(note);

            if(this.state.ccSendingAgent) {
                let note =  {
                    agent_id: this.props.referral.sender_id,
                    internal_note: 0,
                    client_id: this.props.referral.sender_client_id,
                    admin_agent_id: this.props.user.agent_id,
                    sendEmail: this.state.sendEmail,
                };
                data.push(note);
            }

            if(this.state.ccPrimaryAgent) {
                let note =  {
                    agent_id: this.props.referral.primary_agent_id,
                    internal_note: 0,
                    client_id: this.props.referral.primary_client_id,
                    admin_agent_id: this.props.user.agent_id,
                    sendEmail: this.state.sendEmail,
                };
                data.push(note);
            }

            if(this.state.ccReceivingAgent) {
                this.props.assignedAgents.map((assignedAgent) => {
                    if(this.props.referral.is_isa || assignedAgent.receiver_client_id !== this.props.referral.primary_client_id ) {
                        let note = {
                            agent_id: assignedAgent.id,
                            internal_note: 0,
                            client_id: assignedAgent.receiver_client_id,
                            admin_agent_id: this.props.user.agent_id,
                            sendEmail: this.state.sendEmail,
                        };
                        data.push(note);
                    }
                });
            }

            let body = {
                note: this.state.currentNote,
                data: data,
            };

            await saveNote(body).then(respJson => {
                this.setState({currentNote:'',ccSendingAgent: false, ccPrimaryAgent:false,
                    ccReceivingAgent: false, sendEmail: false});
                if(this.props.callback) {
                    this.props.callback();
                }
                this.stopLoader();
            });

        } catch(e) {
            this.setState({currentNote:'',ccSendingAgent: false, ccPrimaryAgent:false,
                ccReceivingAgent: false, sendEmail: false,});
            this.stopLoader();
            NotifyBugsnagError(e);
        }
    };

    componentDidMount() {
        if (this.props.tempNote) {
            this.setState({currentNote: this.props.tempNote})
        }
    }

    componentWillReceiveProps(props) {
        if (props.tempNote) {
            this.setState({currentNote: props.tempNote})
        }

        if (props.requestUnsavedNote) {
            this.props.saveUnsavedNoteLocally(this.state.currentNote)
        }
    }

    handleChangeSendingAgent = () => {
        this.setState({ccSendingAgent: !!!this.state.ccSendingAgent});
    };

    handleChangeReceivingAgent = () => {
        this.setState({ ccReceivingAgent: !!!this.state.ccReceivingAgent });
    };

    handleChangePrimaryAgent = () => {
        this.setState({ ccPrimaryAgent: !!!this.state.ccPrimaryAgent });
    };

    handleEmailChange = () => {
        this.setState({ sendEmail: !!!this.state.sendEmail });
    };

    render() {
        const {
            twilioClientName,
            referralId,
            parentCallSid,
            childCallSid,
        } = this.props
        return (
            <div>
                {this.state.loading === true ?
                    <DefaultLoader height={0} width={0}/>
                    :
                    <div>
                        <AssignMostRecentlyConnectedAgent
                            twilioClientName={twilioClientName}
                            referralId={referralId}
                            parentCallSid={parentCallSid}
                            childCallSid={childCallSid}
                        />
                        <PanelContainer title={"Add new note"} childClass={"child-panel"}>
                        
                        <textarea
                            name="body"
                            onChange={this.handleChange}
                            value={this.state.currentNote}
                            rows="8"
                            className={"form-control"}
                        />
                            <div>
                                <input
                                    type="checkbox"
                                    className="checkbox-left-1"
                                    checked={this.state.ccSendingAgent}
                                    onChange={this.handleChangeSendingAgent}
                                    style={{marginLeft: '5px', height:'12px', width: '30px'}}
                                />
                                <span className="checkbox-notes-label">Sender</span>

                                <input
                                    type="checkbox"
                                    className="checkbox-left-2"
                                    checked={this.state.ccReceivingAgent}
                                    onChange={this.handleChangeReceivingAgent}
                                    style={{marginLeft: '25px',  height:'12px',  width: '30px'}}
                                />
                                <span className="checkbox-notes-label">Receivers</span>

                                <input
                                    type="checkbox"
                                    disabled={this.props.referral && !this.props.referral.primary_client_id}
                                    className="checkbox-left-2"
                                    checked={this.state.ccPrimaryAgent}
                                    onChange={this.handleChangePrimaryAgent}
                                    style={{marginLeft: '25px', height:'12px',  width: '30px'}}
                                />
                                <span className="checkbox-notes-label">Primary Agent</span>

                                {(this.state.ccReceivingAgent || this.state.ccSendingAgent || this.state.ccPrimaryAgent) ?
                                    <span>
                                        <br/>
                                        <input
                                            type="checkbox"
                                            className="checkbox-left-2"
                                            checked={this.state.sendEmail}
                                            onChange={this.handleEmailChange}
                                            style={{marginLeft: '5px',  height:'12px',  width: '30px'}}
                                        />
                                        <span className="checkbox-notes-label">Send Email</span>
                                    </span>
                                    :
                                    null
                                }
                            </div>
                        </PanelContainer>

                        <div className={"text-right marginRight20"}>
                            <button
                                className="btn-primary "
                                onClick={this.saveNote}
                                style={{width: '150px'}}>
                                Add note
                            </button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
export default AddNote;
