import React from "react"
import styled from "styled-components"
import { WHITE_RADIUS_LOGO } from "./../ImagesData"

const Wrap = styled.div`
  
`

const NewHeader = (props) => (
  <Wrap
    style={{
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      backgroundColor: "#0B8DEC",
      padding: 28,
      textAlign: "center",
    }}
  >
    <img
      src={WHITE_RADIUS_LOGO}
      alt="Radius Logo"
      style={{
        maxHeight: 40,
      }}
    />
  </Wrap>
)

export default NewHeader