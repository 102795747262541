import React from "react"
import styled from "styled-components"
import { Button } from "./common"

const Wrap = styled.div`
  padding: 20px;
  border-radius: 16px;
  background: #fff;
  min-width: 800px;

  > h6 {
    margin: 0px;
    font-size: 18px;
    font-weight: 400;
    display: none;
  }
`

const DialWrap = styled.div`
  display: flex;
  > input {
    padding: 10px;
    font-size: 18px;
    border: 1px solid #888;
    border-radius: 40px;
    width: 100%;
    outline: none;
    margin-right: 10px;
  } 
`

const DialerOptions = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  align-items: center;
`

const ActiveCall = styled.div`
  display: flex;
  align-items: center;
`

const RedDot = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #e74c3c;
  margin-right: 20px;
`

const CallDuration = styled.div`
  flex: 1;
  > h2 {
    margin: 0px;
    font-weight: 400;
    padding: 0px;
  }
`

const CallOptions = styled.div`
  display: flex;
`

const CallState = styled.div`
  
`


class Dialer extends React.PureComponent {
  state = {}

  componentDidMount() {
    // const {  } = this.props
  }

  render() {
    // const {  } = this.state
    const { 
      dialedNumber,
      onDialerType,
      dialComponent,
      isOnCall,
      callTimer,
      startCaller,
      isRinging,
      allowAutoCallerMode,
      refreshQueue,
      upcomingCallsUrl,
      handleDisconnectConfirm,
      isShowTransferButton,
      conferenceCall,
      isAutoCalls,

    } = this.props
    return (
      <Wrap>
        <h6>Dialer</h6>
        {isOnCall ? (
          <ActiveCall>
            <RedDot />
            <CallDuration>
            <h2>{callTimer}</h2>
            </CallDuration>
          </ActiveCall>
        ) : (
          <DialWrap>
            <input type="text" placeholder="Enter a number to mannually call" value={dialedNumber} onChange={onDialerType}/>
          </DialWrap>
        )}

        <CallState>
          {isRinging && (
            <p>Ringing..</p>
          )}
          {isOnCall && (
            <p>Connected to ({dialedNumber})</p>
          )}
        </CallState>
        <DialerOptions>
         
          {allowAutoCallerMode && !isOnCall && !isAutoCalls && (
            <Button icon="play" small onClick={startCaller}>
              Start Caller
            </Button>
          )}
          {isOnCall ? (
            <React.Fragment>
              {/* <Button type="info" icon="pause" small>
                Hold Client
              </Button> */}
              {/* <Button type="danger" icon="user-times" small>
                Remove Agent
              </Button> */}
              {!!isShowTransferButton && (
                <React.Fragment>
                  {conferenceCall}
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button icon="list-ol" small type="info" onClick={() => { window.open(upcomingCallsUrl) }}>
                Upcoming calls
              </Button>
              {allowAutoCallerMode && (
                <Button icon="redo" small type="info" onClick={refreshQueue}>
                  Refresh Queue
                </Button>
              )}
            </React.Fragment>
          )}
          <CallOptions>
            {dialComponent}
          </CallOptions>
        </DialerOptions>
      </Wrap>
    )
  }
}

export default Dialer
