import styled from "styled-components"
import Button from "dumbComponents/common/UI/Button"
import React, { useState, useEffect } from "react"
import { Link, withRouter } from "react-router-dom"
import Container from "container/RecruitAndEarn"
import NumberFormat from "react-number-format"
import { ToastContainer } from "react-toastify"
import agentSearchContainer from "container/BrokerDashboard/agentSearchContainer"
import Loader from "dumbComponents/common/UI/Loader"
import { get, join } from "lodash"
import TextInput from "dumbComponents/BrokerDashboard/Comp/TextInput"
import moment from "moment"
import cookie from "react-cookies"
import Select from "react-select"
import { Row, Col } from "antd"
import Text from "antd/lib/typography/Text"

const Wrap = styled.div`
  max-width: 90%;
  display: flex;
  gap: 30px;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 20px;
`

const SearchInput = styled.input`
  flex: 1;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid #ccc;
  width: 30%;

  ${props => props.isError && `
    border: 1px solid red;
  `}
`
const AgentList = styled.div`
  position: relative;
  width: 30%;
  top: 20px;
  left: 0px;
  right: 0px;
  min-height: 200px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #ccc;
  z-index: 1;
`

const AgentItem = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  &:hover {
    background: #eee;
    transition-duration: 0.3s;
    cursor: pointer;
  }
  > div {
    &:nth-child(1) {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
      background: #eee;
      > img {
        width: 100%;
      }
    }
    &:nth-child(2) {
      margin-left: 20px;
      p, h6 {
        padding: 0px;
        margin: 0px;
      }
      h6 {
        font-weight: 400;
        color: #999;
        font-size: 12px;
      }
      > input {
        color: #999;
        border: none;
        font-size: 12px;
        pointer-events: none;
        padding: 0px;
        background: transparent;
      }
    }
  }
`

const GoBack = styled.div`
  height: max-content;
`

const SectionWrap = styled.div`
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #ccc;
  position: relative;
  margin-bottom: 40px;

  @keyframes breath-animation {
    0% { background: transparent; border: 1px solid #ccc; }
    50% { background: #ffcecb; border: 1px solid #fd6459; }
  }

  ${props => props.isAnimate && `
    animation-name: breath-animation;
    animation-duration: 1.5s;
    animation-iteration-count: 3;
  `}

  > h6 {
    top: -16px;
    left: 40px;
    position: absolute;
    background: #fff;
    padding: 5px 16px;
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
    color: #999;
  }
`

const SectionWrapInside = styled.div`
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #ccc;
  position: relative;
  margin-left: 2%
  margin-bottom: 40px;

  @keyframes breath-animation {
    0% { background: transparent; border: 1px solid #ccc; }
    50% { background: #ffcecb; border: 1px solid #fd6459; }
  }

  ${props => props.isAnimate && `
    animation-name: breath-animation;
    animation-duration: 1.5s;
    animation-iteration-count: 3;
  `}

  > h6 {
    top: -16px;
    left: 40px;
    position: absolute;
    background: #fff;
    padding: 5px 16px;
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
    color: #999;
  }
`

const SIGN_UP_FORM_AGENT_TYPE = [
  { value: "Independent Real Estate Agent", label: "Independent Real Estate Agent" },
  { value: "Team Lead", label: "Team Lead" },
  { value: "Managing Broker", label: "Managing Broker" },
  { value: "Agent Looking for Team", label: "Agent Looking for Team" },
  { value: "Other", label: "Other" },
]

const RecordInvite = ({
  agents,
  fetchAgentSearch,
  onAgentSelect,
  history,
  isAgentNotSelected,
  resetSelections,
  clearAgent,
  createNewInvite,
  fetchInviteById,
  editInviteById,
  createNewInviteResponse,
  match,
  ...props
}) => {
  const [searchText, setSearchText] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState(null)
  const [isAccepted, setIsAccepted] = useState("")
  const [referralDate, setReferralDate] = useState("")

  const [referrerFirstName, setReferrerFirstName] = useState("")
  const [referrerLastName, setReferrerLastName] = useState("")
  const [referrerEmail, setReferrerEmail] = useState("")
  const [referrerPhone, setReferrerPhone] = useState(null)
  const [senderAgentId, setSenderAgentId] = useState(null)
  const [agentType, setAgentType] = useState(null)
  const [inviteType, setinviteType] = useState("")

  console.log(createNewInviteResponse)

  useEffect(() => {
    if (match.params.inviteId) {
      fetchInviteById({
        id: match.params.inviteId,
        callback: (values) => {
          setFirstName(values[0].firstname)
          setLastName(values[0].lastname)
          setEmail(values[0].email)
          setPhone(values[0].phone)
          setIsAccepted(values[0].is_accepted === 1 ? "Invite Accepted" : "Invite Pending")
          setReferralDate(values[0].created_at)

          setReferrerFirstName(values[0].referrer_firstname)
          setReferrerLastName(values[0].referrer_lastname)
          setReferrerEmail(values[0].referrer_email)
          setReferrerPhone(values[0].referrer_phone)
          setSenderAgentId(values[0].sender_id)
          setinviteType(values[0].invite_type)
        },
      })
    }
  }, [])

  const handleSearchType = (value) => {
    setSearchText(value)
    fetchAgentSearch({ search: value })
  }

  const setReferraldateChecker = (date) => {
    if (isNaN(date) == false) {
      setReferralDate("")
    }
  }

  const clearAllSelection = () => {
    setSenderAgentId(null)
    setReferrerFirstName("")
    setReferrerLastName("")
    setReferrerEmail("")
    setReferrerPhone(null)
    setAgentType(null)
  }

  const handleSaveInvite = () => {
    try {
      const user = cookie.load("user")
      if (!referrerEmail) {
        alert("Referring Agent Required")
      } else
      if (!email) {
        alert("Referred Agent details Required")
      } else
      if (!isAccepted) {
        alert("Is Invite Accepted Required")
      } else
      if (!match.params.inviteId) {
        
        createNewInvite({
          history,
          payload: {
            senderId: senderAgentId,
            firstName,
            lastName,
            phone,
            email,
            status: isAccepted,
            inviteType: "brokerage",
            accountType: agentType || "",
            added_by: user.twilio_client_name,
          },
        })
      } else
      if (match.params.inviteId) {
        editInviteById({
          id: match.params.inviteId,
          history,
          payload: {
            senderId: senderAgentId,
            firstName,
            lastName,
            phone,
            email,
            status: isAccepted,
            inviteType: "brokerage",
            accountType: agentType || "",
            added_by: user.twilio_client_name,
          },
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const { isFetching, data } = agents
  const { isFetching: submittingReferral } = createNewInviteResponse || {}
  const agentsLists = get(data, "agents", [])

  return (
    <>
      <Wrap>
        <GoBack>
          <Link to="/broker/recruit-and-earn">Go back to dashboard</Link>
        </GoBack>
        <SectionWrap>
          <h6>
                  Invite Details
          </h6>
          <SectionWrapInside>
            <h6>
                  Referring Agent Details
            </h6>
            <SearchInput
              type="text"
              isError={isAgentNotSelected}
              value={searchText}
              placeholder="Search Referring Agent"
              onChange={(e) => { handleSearchType(e.target.value) }}
            />
            {searchText && (
              <AgentList>
                {isFetching ? (
                  <Loader width="25px" top={0} bottom={0} />
                ) : (
                  <React.Fragment>
                    {agentsLists && agentsLists.length > 0 && agentsLists.map(agent => (
                      <AgentItem
                        key={agent.id}
                        onClick={() => {
                          setSenderAgentId(agent.id)
                          setReferrerFirstName(agent.firstname)
                          setReferrerLastName(agent.lastname)
                          setReferrerEmail(agent.email)
                          setReferrerPhone(agent.phone)
                          resetSelections()
                          clearAgent()
                          setSearchText("")
                        }}
                      >
                        <div>
                          <img src={agent.agent_image} alt="" />
                        </div>
                        <div>
                          <p>
                            {`${agent.firstname} ${agent.lastname}`}
                          </p>
                          <h6>
                            {agent.email}
                          </h6>
                          <NumberFormat format="+1 (###) ###-####" mask="_" value={agent.phone} />
                        </div>
                      </AgentItem>
                    ))}
                  </React.Fragment>
                )}
              </AgentList>
            )}
            <Button style={{ padding: 10, marginLeft: 20 }} onClick={clearAllSelection}>
                Clear
            </Button>
            { referrerEmail
                  && (
                    <Row gutter={50} style={{ paddingTop: "10px" }}>
                      <div style={{ padding: "25px" }}>
                        <div>
                          <Text>
                            {" Name : "}
                            {" "}
                            {referrerFirstName || ""}
                            {" "}
                            {referrerLastName}
                          </Text>
                        </div>
                        <br />
                        <div>
                          <Text>
                            {" Email Address : "}
                            {" "}
                            {referrerEmail || "N/A"}
                          </Text>
                        </div>
                        <br />
                        <div>
                          <Text>
                            {" Phone : "}
                            {" "}
                            {referrerPhone || "N/A"}
                          </Text>
                        </div>
                      </div>
                    </Row>
                  )

            }
          </SectionWrapInside>

          <SectionWrapInside>
            <h6>
              Referred Agent Details
            </h6>
            <Row gutter={50}>
              <Col>
                <TextInput
                  placeholder="First Name"
                  type="text"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value)
                  }}
                />
              </Col>
              <Col>
                <TextInput
                  type="text"
                  value={lastName}
                  placeholder=" Last Name (Optional)"
                  onChange={(e) => {
                    setLastName(e.target.value)
                  }}
                />
              </Col>
              <Col>
                <TextInput
                  type="text"
                  value={email}
                  placeholder=" Email Address "
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
              </Col>
              <Col>
                <TextInput
                  type="text"
                  value={phone}
                  placeholder=" Phone Number "
                  onChange={(e) => {
                    setPhone(e.target.value)
                  }}
                />
              </Col>
            </Row>
          </SectionWrapInside>

          <SectionWrapInside>
            <h6>
                  Other Details
            </h6>
            <Row gutter={40}>
              <Col>
                <div style={{ width: "250px", paddingBlock: "35px" }}>
                  <Select
                    placeholder="Invite Status"
                    options={[
                      { label: "Invited", value: "invited" },
                      { label: "Joined", value: "joined" },
                      { label: "Ineligible", value: "ineligible" },
                      { label: "Declined", value: "declined" },
                    ]}
                    onChange={(value) => {
                      setIsAccepted(value.value)
                    }}
                  />
                </div>
              </Col>

              {/* <Col>
                <div style={{ width: "250px" }}>
                  <TextInput
                    type="date"
                    label="Date Of Referral"
                    value={moment(referralDate).format("YYYY-MM-DD")}
                    fullWidth
                    fullHeight
                    onChange={(e) => {
                      setReferralDate(moment(e.target.value).format("YYYY-MM-DD 00:00:00"))
                      setReferraldateChecker(e.target.value)
                    }}
                  />
                </div>
              </Col> */}
              <Col>
                <div style={{ width: "250px", paddingBlock: "35px" }}>
                  <Select
                    placeholder="Agent Type"
                    options={SIGN_UP_FORM_AGENT_TYPE}
                    onChange={(value) => {
                      setAgentType(value.value)
                    }}
                  />
                </div>
              </Col>
            </Row>
          </SectionWrapInside>

          <Button disabled={submittingReferral} success style={{ float: "right" }} onClick={handleSaveInvite}>
            {submittingReferral ? "Sending" : "Send Invite"}
          </Button>

        </SectionWrap>
      </Wrap>
      <ToastContainer />
    </>
  )
}

export default Container(agentSearchContainer(withRouter(RecordInvite)))
