import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as ZillowMergeActions from "container/ZillowLeadMerge/actions"
import * as Actions from "./actions"

const initState = {
  isOpenZillowMergeModal: false,
  ...createDeltaReducer("timeline", {
    isFetchingMore: false,
  }),
  ...createDeltaReducer("zillowMergeResponse"),
  ...createDeltaReducer("mergedZillowResponse"),
}

export default function clientProfileReducer(state = initState, action) {
  switch (action.type) {
    case Actions.fetchClientTimelineAction.REQUEST:
    case Actions.fetchClientTimelineAction.SUCCESS:
    case Actions.fetchClientTimelineAction.CLEAR:
    case Actions.fetchClientTimelineAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchClientTimelineAction, "timeline"),
      }
    }

    case ZillowMergeActions.fetchReferalForMergeAction.REQUEST:
    case ZillowMergeActions.fetchReferalForMergeAction.SUCCESS:
    case ZillowMergeActions.fetchReferalForMergeAction.CLEAR:
    case ZillowMergeActions.fetchReferalForMergeAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ZillowMergeActions.fetchReferalForMergeAction, "zillowMergeResponse"),
      }
    }

    case ZillowMergeActions.mergeZillowLeadsAction.REQUEST:
    case ZillowMergeActions.mergeZillowLeadsAction.SUCCESS:
    case ZillowMergeActions.mergeZillowLeadsAction.CLEAR:
    case ZillowMergeActions.mergeZillowLeadsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ZillowMergeActions.mergeZillowLeadsAction, "mergedZillowResponse"),
      }
    }

    case Actions.fetchedAllTimelineEventsAction.type: {
      return {
        ...state,
        timeline: {
          ...state.timeline,
          isFetchingMore: false,
          isFetchching: false,
          isFetchedAll: true,
        },
      }
    }

    case ZillowMergeActions.toggleZillowMergeModalAction.type: {
      return {
        ...state,
        isOpenZillowMergeModal: action.data,
      }
    }

    default: {
      return state
    }
  }
}
