import React, { Component } from 'react';
import {Flex} from "@rebass/grid";
import styled from "styled-components";
import Switch from "react-switch"
import bugsnag from 'bugsnag-js';
import moment from 'moment';
import _ from 'lodash';
import TitleBox from "../UI/TitleBox/TitleBox";
import EditSettings from "./modals/EditSettings";
import PrioritizeSettings from "./modals/PrioritizeSettings";
import Modal from "../UI/Modal/Modal";
import {NotifyBugsnagError, ShowErrorToast, ShowSuccessToast} from "../../lib/ErrorUtils";
import NumberFormat from "react-number-format";
import Button from "../UI/Button/Button";
import {getDevicePhoneNumber, restartSocket, submitLead, updateDevicePhoneNumber, getCallerSettings, updateAutoPrioritizingStatus} from "../API/Referral/ReferralApi";
import {DefaultLoader} from "../UI/Loader/Loader";
import PoolAgents from "./modals/PoolAgents";
import {propertyMetrics} from "../API/AgentApi";
import {updatePool} from "../API/CallerApi";
import {
  Header,
  MultiCallerWrap,
} from "./UIComp"
import container from "container/Settings"
const baseUrl =process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;
const originalKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(originalKey);

const TableContainer = styled.div`
  margin-top: 20px;
  table {
    font-family: "Rubik", sans-serif;
    td, th {
      border: none;
    }
  }
`;

const TableTitle = styled.h3`
  font-size: 22px;
  font-weight: 500;
  color: #737373;
  margin: 0px;
`;

const SettingsTable = styled.table`
  border: solid 1px #9b9b9b;
`;

const SettingsTableHeader = styled.thead`
  th {
    font-size: 18px;
    font-weight: 100;
    color: #4a4a4a;
  }
`;

const SettingsTableRow = styled.tr`
  border-top: 1px solid #e5e5e5;
  &:first-child {
    border-top: 1px solid #9b9b9b;
  }
`;

const SettingsTableData = styled.td`
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  background: #ffffff;
  padding:10px;
  &:nth-child(3) {
    border-right: 1px solid #979797;
  }
`;

const InteRow = styled.span`
  font-size: 12px;
  font-weight: 100;
`

const CityTag = styled.span`
  background: #EEE;
  border: 1px solid #CCC;
  border-radius: 3px;
  padding: 2px 3px;
  margin: 2px;
  display: inline-block;
`

class AutoCallerSettings extends Component {

  state = {
    editMode : false,
    isEditingPoolSetting : false,
    prioritizeMode: false,
    poolAgentMode:false,
    integrationSources: [],
    juniorConsultants: [],
    seniorConsultants: [],
    pools: [],
    devicePhoneNumber: null,
    currentConsultant: null,
    isLoading: false,
    isMultiCallerEnabled: false,
    isAutoPrioritizationEnabled: false,
    updatingAutoPrioritizingAgent: false,
  };

  editSettings = (consultant) => {
    const self = this;
    this.setState({currentConsultant: consultant, isEditingPoolSetting: false},() => self.setState({editMode: true}));
  };

  editPoolSettings = (consultant) => {
    const self = this;
    this.setState({currentConsultant: consultant, isEditingPoolSetting: true},() => self.setState({editMode: true}));
  };

  prioritizeSettings = (consultant) => {
    const self = this;
    this.setState({currentConsultant: consultant, isEditingPoolSetting: false},() => self.setState({prioritizeMode: true}));
  };

  prioritizePoolSettings = (consultant) => {
    const self = this;
    this.setState({currentConsultant: consultant, isEditingPoolSetting: true},() => self.setState({prioritizeMode: true}));
  };

  updatePoolAgents = (consultant) => {
    const self = this;
    this.setState({currentConsultant: consultant},() => self.setState({poolAgentMode: true}));
  };

  stopLoader = () => {
    this.setState({loading: false})
  };

  startLoader = () => {
    this.setState({loading: true})
  };

  resetPoolCallReservation = async (pool) => {
    try {
        this.startLoader();
        let body = {
            "reset_call_reservation":true,
        };
        await updatePool(pool.id, body).then(res => {
            this.setState({agentProfile: res.response});
            this.stopLoader();
        });
    } catch(e) {
        console.log(e);
        NotifyBugsnagError(e);
        this.stopLoader();
    }
  };

  closeModal = () => {
    this.setState({editMode : false, currentConsultant: null});
  };

  closePrioritizeModal = () => {
    this.setState({prioritizeMode : false, currentConsultant: null});
  };

  closePoolModal = () => {
      this.setState({poolAgentMode : false, currentConsultant: null});
  };

  loadAccounts = async () => {
    try {
      let response = await  fetch(baseUrl + '/v1.0/consultants/all?prioritizedAgents=true&poolAgents=true', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'secure-id': token,
          },
      });
      let respJson = await response.json();
      let juniorConsultants = _.filter( respJson.response.accounts, (account) => account.role === 'Jr Consultant');
      let seniorConsultants = _.filter( respJson.response.accounts, (account) => account.role === 'Sr Consultant');
      let pools = respJson.response.pools ? respJson.response.pools : [];

      this.setState({juniorConsultants,seniorConsultants, pools});
    }
    catch (e) {
      bugsnagClient.notify(e);
    }
  };

    handleChange = (e) => {
        this.setState({devicePhoneNumber: e.target.value})
    };

    getDevicePhoneNumber = async (e) => {
        this.setState({isLoading: true});
        await getDevicePhoneNumber().then(res => {
            this.setState({isLoading: false, devicePhoneNumber: res.zillow_device_number});
        }).catch(err => {
            ShowErrorToast(err);
            this.setState({isLoading: false});
        });
    };

    saveDevicePhoneNumber = async (e) => {
        this.setState({isLoading: true});
        await updateDevicePhoneNumber({zillow_device_number: this.state.devicePhoneNumber}).then(res => {
            ShowSuccessToast("Successfully updated");
            this.setState({isLoading: false});
        }).catch(err => {
            ShowErrorToast(err);
            this.setState({isLoading: false});
        });
    };

    restartSocket = async (e) => {
        this.setState({isLoading: true});
        await restartSocket().then(res => {
            ShowSuccessToast("Successfully updated");
            this.setState({isLoading: false});
        }).catch(err => {
            ShowErrorToast(err);
            this.setState({isLoading: false});
        });
    };

  fetchIntegrationSources = async () => {

      try {
          let response = await  fetch(baseUrl + '/v1.0/crs/referral-sources', {
              method: 'GET',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'secure-id': token,
              },
          });
          let integrationSources =[];
          let respJson = await response.json();

          _.each(respJson.response.integration_sources, (source) => {
              integrationSources.push(source);
          });

          _.each(respJson.response.non_integration_sources, (source) => {
              if(source.value !== 'enquiry' && source.value !== 'trustle' && source.value !== 'trustleads') {
                  integrationSources.push(source);
              }
          });

          // integrationSources.push({display_name: 'SubmitLeads', value:"SubmitLeads"});
          // integrationSources.push({'display_name' : 'Excel', "value":"Excel"});
          this.setState({integrationSources: integrationSources});
      }
      catch (e) {
          bugsnagClient.notify(e);
      }
  };

  componentDidMount = () => {
    const { getMultiCallerState } = this.props
    getMultiCallerState()
    this.loadAccounts();
    this.fetchIntegrationSources();
    this.getDevicePhoneNumber();
    this.getCallerSettings();
  };

  handleMultiDialerSwitch = (value) => {
    if (window.confirm("Are you sure, you want to change Multicaller settings?")) {
      const { changeMultiCallerState, multiCallerStateResponse } = this.props
      const isMultiCallerEnabled = _.get(multiCallerStateResponse, "data.enabled")
      changeMultiCallerState({
        enabled: !isMultiCallerEnabled,
      })
    }
  }

    handleAutoPrioritizingAgents = async (value) => {
        if (window.confirm("Are you sure, you want to change Auto Prioritization settings?")) {
            this.setState({updatingAutoPrioritizingAgent: true});
            let body = {
                "is_auto_prioritization_enabled": this.state.isAutoPrioritizationEnabled === true ? "0" : "1",
            }
            await updateAutoPrioritizingStatus(body).then(res => {
                this.getCallerSettings();
                this.setState({updatingAutoPrioritizingAgent: false});
            }).catch(err => {
                ShowErrorToast(err);
                this.setState({updatingAutoPrioritizingAgent: false});
            });
        }
    }

    getCallerSettings = async () => {
        this.setState({isLoading: true});
        await getCallerSettings().then(res => {
            let isAutoPrioritizationEnabled = false;
            if (res && res.response && res.response.items) {
                res.response.items.map((item) => {
                    if (item && item.type === "is_auto_prioritization_enabled") {
                        isAutoPrioritizationEnabled = item.value === "1";
                    }
                })
            }
            this.setState({isLoading: false, isAutoPrioritizationEnabled: isAutoPrioritizationEnabled});
        }).catch(err => {
            ShowErrorToast(err);
            this.setState({isLoading: false});
        });
    };

  render() {
    const { editMode } = this.state.editMode;
    const { prioritizeMode, devicePhoneNumber, isLoading } = this.state;
    const { multiCallerStateResponse, changedMultiCallerResponse } = this.props
    const isMultiCallerEnabled = _.get(multiCallerStateResponse, "data.enabled")
    const { isFetching: isSavingMultiCallerState } = changedMultiCallerResponse

    const poolsDiv = this.state.pools.map(pool => {
      const call_settings = pool.call_settings;
      const referralSources = _.keys(call_settings);

      const referralSourcesDiv = referralSources.map(rs => (
        <InteRow>
          {(call_settings[rs]['sources'] || []).length > 0 &&
          <div>
            <h5>{rs}</h5>
            {call_settings[rs]['sources'].map(x => <CityTag>{x}</CityTag>)}<br/>
          </div>
          }
      </InteRow>
      ));

          let fromDate = null;
          let toDate = null;

          if (call_settings) {
              fromDate = call_settings[referralSources[0]].start;
              toDate = call_settings[referralSources[0]].end;
          }


          return <SettingsTableRow>
              <SettingsTableData>{pool.name}</SettingsTableData>
              <SettingsTableData>
                  {
                      fromDate &&
                      <span>
          {moment(fromDate * 1000).format("MMMM Do")}&nbsp; &rarr; &nbsp;{toDate ? moment(toDate * 1000).format("MMMM Do") : 'NOW'}
        </span>
                  }
              </SettingsTableData>
              <SettingsTableData>
                  {referralSourcesDiv}
              </SettingsTableData>
              <SettingsTableData>
                  <a href="javascript:void(0);" onClick={() => this.editPoolSettings(pool)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                          <path fill="#11ADF3" fillRule="evenodd" d="M19.006 11.85v4.181c0 2.123-1.827 3.848-4.075 3.848H6.074C3.826 19.88 2 18.154 2 16.031V7.667c0-2.122 1.826-3.848 4.074-3.848h4.429c.294 0 .531.224.531.502s-.237.502-.531.502h-4.43c-1.66 0-3.011 1.276-3.011 2.844v8.364c0 1.569 1.35 2.844 3.012 2.844h8.857c1.661 0 3.013-1.275 3.013-2.844V11.85c0-.277.236-.502.53-.502.295 0 .532.225.532.502zm-.308-5.624l-.87.821-2.129-2.01.87-.821a.88.88 0 0 1 1.169 0l.96.906a.754.754 0 0 1 .242.552.756.756 0 0 1-.242.552zm-8.517 8.042l-2.485.336.356-2.347 6.895-6.51 2.129 2.01-6.895 6.511zm9.268-9.856l-.96-.906c-.713-.675-1.959-.675-2.672 0l-1.242 1.172a.007.007 0 0 0-.003.004c-.002 0-.003.002-.004.003l-7.393 6.981a.489.489 0 0 0-.15.284l-.48 3.174a.48.48 0 0 0 .15.426.542.542 0 0 0 .45.142l3.361-.453a.542.542 0 0 0 .301-.143l7.394-6.98c0-.002.002-.003.003-.004l.004-.004 1.24-1.173c.358-.336.555-.785.555-1.261 0-.477-.197-.925-.554-1.262z" />
                      </svg>
                  </a>
              </SettingsTableData>
              <SettingsTableData>
                  <a href="javascript:void(0);" onClick={() => this.prioritizePoolSettings(pool)}>
                      <i className="fas fa-upload"></i>
                  </a>
              </SettingsTableData>
              <SettingsTableData>
                  <a href="javascript:void(0);" onClick={() => this.updatePoolAgents(pool)}>
                      <i className="fas fa-user"></i>
                  </a>
              </SettingsTableData>
              <SettingsTableData>
                  <button onClick={(e) => {e && e.preventDefault();this.resetPoolCallReservation(pool)}}>Reset</button>
              </SettingsTableData>
          </SettingsTableRow>

      }
  );

    const juniorConsultantsDiv = this.state.juniorConsultants.map(consultant => {
      const call_settings = consultant.call_settings;
      const referralSources = _.keys(call_settings);

      const referralSourcesDiv = referralSources.map(rs => (
        <InteRow>
          {(call_settings[rs]['sources'] || []).length > 0 &&
            <div>
              <h5>{rs}</h5>
               {call_settings[rs]['sources'].map(x => <CityTag>{x}</CityTag>)}<br/>
            </div>
          }
        </InteRow>
      ));

      let fromDate = null;
      let toDate = null;

      if (call_settings) {
        fromDate = call_settings[referralSources[0]].start;
        toDate = call_settings[referralSources[0]].end;
      }


      return <SettingsTableRow>
        <SettingsTableData>{consultant.name}</SettingsTableData>
        <SettingsTableData>
          {
            fromDate &&
            <span>
              {moment(fromDate * 1000).format("MMMM Do")}&nbsp; &rarr; &nbsp;{toDate ? moment(toDate * 1000).format("MMMM Do") : 'NOW'}
            </span>
          }
        </SettingsTableData>
        <SettingsTableData>
          {referralSourcesDiv}
        </SettingsTableData>
        <SettingsTableData>
          <a href="javascript:void(0);" onClick={() => this.editSettings(consultant)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
              <path fill="#11ADF3" fillRule="evenodd" d="M19.006 11.85v4.181c0 2.123-1.827 3.848-4.075 3.848H6.074C3.826 19.88 2 18.154 2 16.031V7.667c0-2.122 1.826-3.848 4.074-3.848h4.429c.294 0 .531.224.531.502s-.237.502-.531.502h-4.43c-1.66 0-3.011 1.276-3.011 2.844v8.364c0 1.569 1.35 2.844 3.012 2.844h8.857c1.661 0 3.013-1.275 3.013-2.844V11.85c0-.277.236-.502.53-.502.295 0 .532.225.532.502zm-.308-5.624l-.87.821-2.129-2.01.87-.821a.88.88 0 0 1 1.169 0l.96.906a.754.754 0 0 1 .242.552.756.756 0 0 1-.242.552zm-8.517 8.042l-2.485.336.356-2.347 6.895-6.51 2.129 2.01-6.895 6.511zm9.268-9.856l-.96-.906c-.713-.675-1.959-.675-2.672 0l-1.242 1.172a.007.007 0 0 0-.003.004c-.002 0-.003.002-.004.003l-7.393 6.981a.489.489 0 0 0-.15.284l-.48 3.174a.48.48 0 0 0 .15.426.542.542 0 0 0 .45.142l3.361-.453a.542.542 0 0 0 .301-.143l7.394-6.98c0-.002.002-.003.003-.004l.004-.004 1.24-1.173c.358-.336.555-.785.555-1.261 0-.477-.197-.925-.554-1.262z" />
            </svg>
          </a>
        </SettingsTableData>
        <SettingsTableData>
          <a href="javascript:void(0);" onClick={() => this.prioritizeSettings(consultant)}>
            <i className="fas fa-upload"></i>
          </a>
        </SettingsTableData>
      </SettingsTableRow>

      }
    );

    const seniorConsultantDiv = this.state.seniorConsultants.map(consultant => {
      const call_settings = consultant.call_settings;
      const referralSources = _.keys(call_settings);

      const referralSourcesDiv = referralSources.map(rs => (
        <InteRow>
          {(call_settings[rs]['sources'] || []).length > 0 &&
            <div>
              <h5>{rs}</h5>
              {call_settings[rs]['sources'].join(', ')}<br/>
            </div>
          }
        </InteRow>
      ));

      let fromDate = null;
      let toDate = null;

      if (call_settings) {
        fromDate = call_settings[referralSources[0]].start;
        toDate = call_settings[referralSources[0]].end;
      }


      return <SettingsTableRow>
        <SettingsTableData>{consultant.name}</SettingsTableData>
        <SettingsTableData>
          {
            fromDate && toDate &&
            <span>
              {moment(fromDate * 1000).format("MMMM Do")}&nbsp; &rarr; &nbsp;{toDate ? ((moment(toDate * 1000).format("MMMM Do"))) : 'Now'}
            </span>
          }
        </SettingsTableData>
        <SettingsTableData>
          {referralSourcesDiv}
        </SettingsTableData>
        <SettingsTableData>
          <a href="javascript:void(0);" onClick={() => this.editSettings(consultant)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
              <path fill="#11ADF3" fillRule="evenodd" d="M19.006 11.85v4.181c0 2.123-1.827 3.848-4.075 3.848H6.074C3.826 19.88 2 18.154 2 16.031V7.667c0-2.122 1.826-3.848 4.074-3.848h4.429c.294 0 .531.224.531.502s-.237.502-.531.502h-4.43c-1.66 0-3.011 1.276-3.011 2.844v8.364c0 1.569 1.35 2.844 3.012 2.844h8.857c1.661 0 3.013-1.275 3.013-2.844V11.85c0-.277.236-.502.53-.502.295 0 .532.225.532.502zm-.308-5.624l-.87.821-2.129-2.01.87-.821a.88.88 0 0 1 1.169 0l.96.906a.754.754 0 0 1 .242.552.756.756 0 0 1-.242.552zm-8.517 8.042l-2.485.336.356-2.347 6.895-6.51 2.129 2.01-6.895 6.511zm9.268-9.856l-.96-.906c-.713-.675-1.959-.675-2.672 0l-1.242 1.172a.007.007 0 0 0-.003.004c-.002 0-.003.002-.004.003l-7.393 6.981a.489.489 0 0 0-.15.284l-.48 3.174a.48.48 0 0 0 .15.426.542.542 0 0 0 .45.142l3.361-.453a.542.542 0 0 0 .301-.143l7.394-6.98c0-.002.002-.003.003-.004l.004-.004 1.24-1.173c.358-.336.555-.785.555-1.261 0-.477-.197-.925-.554-1.262z" />
            </svg>
          </a>
        </SettingsTableData>
        <SettingsTableData>
          <a href="javascript:void(0);" onClick={() => this.prioritizeSettings(consultant)}>
            <i className="fas fa-upload"></i>
          </a>
        </SettingsTableData>
      </SettingsTableRow>

      }
    );
    console.log(this.props)
    return (
      <div className={"container"} style={{ marginTop: 60, paddingTop: 20 }}>
        <Header style={{ marginBottom: 30 }}>
          <h3>AUTO-CALLER SETTINGS</h3>
        </Header>
          {isLoading === true ? <DefaultLoader height={0} width={0}/> :
            <span>
              <span>Device Phone Number:</span>
              < NumberFormat placeholder="(Number)" onChange={this.handleChange} value={devicePhoneNumber}/>
              <button onClick={(e) => {e && e.preventDefault();this.saveDevicePhoneNumber()}}>Save</button>
            </span>
          }&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {isLoading === true ? <DefaultLoader height={0} width={0}/> :
              <span>
              <button onClick={(e) => {e && e.preventDefault();this.restartSocket()}}>Restart Blink</button>
            </span>
          }
        <TableContainer >
          <Flex>
            <TableTitle style={{ flex: 1 }}>Caller Pools</TableTitle>
            <MultiCallerWrap>
                  <span>
                { this.state.updatingAutoPrioritizingAgent ? "Saving.." : "Enable Auto Prioritization" }
              </span>
                <Switch
                    onChange={this.handleAutoPrioritizingAgents}
                    checked={this.state.isAutoPrioritizationEnabled}
                />
              <span>
                { isSavingMultiCallerState ? "Saving.." : "Enable Multicaller" }
              </span>
              <Switch
                onChange={this.handleMultiDialerSwitch}
                checked={isMultiCallerEnabled}
              />
            </MultiCallerWrap>
          </Flex>
            <Flex>
              <SettingsTable>
                <SettingsTableHeader>
                  <tr>
                    <th>Pool name</th>
                    <th>TIME RANGE - SUBMITTED REFERRALS</th>
                    <th>SOURCES</th>
                    <th />
                  </tr>
                </SettingsTableHeader>
                <tbody>
                  {poolsDiv}
                </tbody>
              </SettingsTable>
            </Flex>

          <Flex>
            <TableTitle>Junior Consultant</TableTitle>
          </Flex>
          <Flex>
            <SettingsTable>
              <SettingsTableHeader>
                <tr>
                  <th>CONSULTANT</th>
                  <th>TIME RANGE - SUBMITTED REFERRALS</th>
                  <th>SOURCES</th>
                  <th />
                </tr>
              </SettingsTableHeader>
              <tbody>
                {juniorConsultantsDiv}
              </tbody>
            </SettingsTable>
          </Flex>

          <Flex>

          </Flex>
        </TableContainer>
        <Modal show={this.state.editMode}>
          <EditSettings
              closeModal={this.closeModal}
              isEditingPoolSetting={this.state.isEditingPoolSetting}
              consultant={this.state.currentConsultant}
              integrationSources={this.state.integrationSources}
              loadAccounts={this.loadAccounts}/>
        </Modal>
        <Modal show={this.state.prioritizeMode} style={{height: '50px'}}>
          <PrioritizeSettings
              closePrioritizeModal={this.closePrioritizeModal}
              consultant={this.state.currentConsultant}
              isEditingPoolSetting={this.state.isEditingPoolSetting}
              loadAccounts={this.loadAccounts}/>
        </Modal>
        <Modal show={this.state.poolAgentMode} style={{height: '50px'}}>
            <PoolAgents closePrioritizeModal={this.closePoolModal}
                        pool={this.state.currentConsultant}
                        juniorConsultants={this.state.juniorConsultants}
                        loadAccounts={this.loadAccounts}/>
        </Modal>
      </div>
    )
  }

}

export default container(AutoCallerSettings)
