import React from "react"
import styled from "styled-components"
// import colors from "@colors"

const ProgressBarContainer = styled.div`
  padding: 30px 0px;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #3B60E4;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const ProgressBarComponent = ({ ongoingOrCompleted, total }) => (
  <>
    <ProgressBarContainer>
      {total.map(tt => (<ProgressBar ongoing={tt <= ongoingOrCompleted} />))}
    </ProgressBarContainer>
  </>
)

export default ProgressBarComponent
