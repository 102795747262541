import React from "react"
import styled from "styled-components"
import { get } from "lodash"
import copyToClipBoard from "copy-to-clipboard"

import container from "container/BrokerDashboard/editAgentContainer"
import Button from "dumbComponents/common/UI/Button"
import TextInput from "./../Comp/TextInput"
import { document } from "window-or-global"


const Wrap = styled.div`
  max-width: 600px;
  margin: 20px auto;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > h1 {
    font-weight: 500;
    font-size: 20px;
  }
`

const LinkWrap = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
`

const ItemWrap = styled.div`
   border-bottom: 1px solid #ccc;
   padding: 20px;
  > div {
    > button {
      align-self: flex-end;
      position: relative;
      top: -7px;
    }
  }
  h6 {
    margin: 10px 0px 0px 0px;
  }
`

const OptionWrap = styled.div`
  margin-top: 10px;
`

const FileUpload = styled.div`
  padding: 20px;

  input#fileupload {
    display: none;
  }
`

const LinkUrl = styled.div`
  text-align: left;
  > {
    font-size: 14px;
    font-weight: 300;
  }
`

const ViewField = styled.div`
   width: 100%;
  h5 {
    font-size: 16px;
    color: #888;
    margin: 0px;
  }
  p {
    border: 1px solid #888;
    padding: 5px 10px;
    border-radius: 5px;
  }
`

class Assets extends React.PureComponent {
  state = {
    isEditing: false,
    form: {}
  }

  componentDidMount() {
    const { getAgentAssets, agentId } = this.props
    // const agentId = get(match, "params.agentId")
    getAgentAssets({
      agentId,
    })
    this.setState({
      agentId,
    })
  }

  toggleEdit = (val) => {
    this.setState({
      isEditing: val
    })
  }

  copy = (url) => {
    copyToClipBoard(url)
  }

  handleFieldChange = (index, value) => {
    const { form } = this.state
    this.setState({
      form: {
        ...form,
      }
    })
  }

  handleSave = () => {
    const { updateAgentAssets, assetForm, agentId } = this.props
    const { form } = this.state
    const payload = Object.keys(assetForm).map(key => assetForm[key])
    if (payload && payload.length > 0) {
      for (let i=0; i < payload.length; i++) {
        if (payload[i].download_url === "") {
          payload[i].download_url = null
        }
      }
    }
    updateAgentAssets({ payload, agentId })
    this.toggleEdit(false)
  }

  handleUpload = (event) => {
    try {
      // const { agentId } = this.state
      const { uploadFiles, assetForm, agentId } = this.props
      console.log(event.target.name);
      const { files } = event.target
      uploadFiles({
        files: files[0],
        field: event.target.name,
        form: assetForm,
        agentId,
      })
    } catch (error) {

    }
  }

  render() {
    const { isEditing, form } = this.state
    const {
      updateAssetsFormLinks,
      agentAssetsResponse,
      fileUploadResResponse,
      resetUploadState,
      assetForm,
      updateAgentAssets,
      agentId,
    } = this.props
    const {
      isFetching: isUploadingFile,
      data: uploadData,
    } = fileUploadResResponse
    const { isFetching, data } = agentAssetsResponse
    return (
      <Wrap>
        <Header>
          <h1>Asset Links</h1>
          {isEditing ? (
            <Button success onClick={this.handleSave}>
              Update Links
            </Button>
          ) : (
            <Button link onClick={() => { this.toggleEdit(true) }}>
              Edit Assets
            </Button>
          )}
        </Header>
        {isFetching ? (
          <h2>Loading..</h2>
        ) : (
          <LinkWrap>
            <FileUpload>
              {isUploadingFile ? (
                <React.Fragment>
                  <h4>Uploading...</h4>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {uploadData && false ? (
                    <LinkUrl>
                      <p>
                        {uploadData.fileUrlAfterUpload}
                      </p>
                      <Button onClick={() => { this.copy(uploadData.fileUrlAfterUpload) }} style={{ marginRight: 10 }}>
                        Copy
                      </Button>
                      <Button onClick={() => { resetUploadState() }}>
                        Upload new File
                      </Button>
                    </LinkUrl>
                  ) : (
                    <input type="file" id="fileupload" onChange={this.handleUpload}/>
                  )}
                </React.Fragment>
              )}
              
            </FileUpload>
            {data && data.map(item => (
              <ItemWrap key={item.id}>
                <div>
                  {isEditing ? (
                    <TextInput
                      disabled={!isEditing}
                      placeholder={`Enter ${item.heading_display_name}`}
                      defaultValue={item.download_url}
                      value={assetForm[`item-${item.id}`] && assetForm[`item-${item.id}`].download_url}
                      label={item.heading_display_name}
                      onChange={(e) => {
                        updateAssetsFormLinks({
                          ...form,
                          [`item-${item.id}`]: {
                            rar_asset_id: item.id,
                            download_url: e.target.value,
                            agent_id: agentId,
                          }
                        })
                      }}
                    />
                  ) : (
                    <ViewField>
                      <h5>{item.heading_display_name}</h5>
                      <p>
                        {item.download_url || "No URL"}
                      </p>
                      <Button onClick={() => { this.copy(item.download_url) }} style={{ marginRight: 10 }}>
                        Copy
                      </Button>
                    </ViewField>
                  )}
                </div>
                <h6>{item.description}</h6>
                <OptionWrap>
                  {isEditing && (
                      <Button
                        danger
                        onClick={() => {
                          this.toggleEdit(false)
                          updateAgentAssets({
                            payload: [
                              {
                                rar_asset_id: item.id,
                                download_url: null,
                                agent_id: agentId,
                              }
                            ],
                            agentId,
                          })
                        }}
                      >
                        Remove
                      </Button>
                    )}
                    {isEditing && (
                    <Button
                      onClick={() => {
                        const ele = document.getElementById("fileupload")
                        ele.name = `item-${item.id}`
                        ele.click()
                      }}
                      style={{ marginRight: 10 }}
                    >
                      Upload
                    </Button>
                  )}
                </OptionWrap>
              </ItemWrap>
            ))}
          </LinkWrap>
        )}
      </Wrap>
    )
  }
}

export default container(Assets)