import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import root from "window-or-global"
import Container from "container/ProductInterest"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Placeholder from "icons/placeholder.jpg"

const Wrap = styled.div`
  height: 100%;
  width: 50%;
  border: solid 1px grey;
  .movableListDiv {
    width: 478px;
    height: 73px;
    margin: 0 0 0 24px;
    display: flex;
    border-radius: 7px;
    border: solid 1px grey;
  }
`

const Button = styled.button`
  text-align: right;
  position: relative;
  left: 73%;
  bottom: 45%;
  font-size: 12px;
  cursor: pointer;
  z-index: 9999;
  background: transparent;
  border: none;
  color: black;
  cursor: pointer;
  text-decoration: underline;

  ${props => props.child && `
    position: unset;
    margin-top: 10px;
    float: right;
    `}
`
const DetailWrap = styled.div`
  width: 410px;
  height: 100%;
  margin: 0 6px 0 0;
  padding: 1px 12px 1px 1px;
  border-radius: 6px;
  .modalTitle {
    font-size: 12px;
    font-family: "SF Pro Display", sans-serif;
    color: #a8a8a8;
  }
  .childCount {
    font-family: "SF Pro Display", sans-serif;
    font-size: 12px;
    color: #8c8c8c;
    float: right;
    margin-top: -26px;
    font-weight: 500;
  }
`

const ImageWrap = styled.div`
  width: 65px;
  height: 100%;
  margin: 0 6px 0 0;
  border-radius: 6px;
  border: solid 1px #a8a8a8;
  img{
    height: 100%;
    width: 100%;
  }
`

const grid = 8;

class ParentPIMList extends Component {

  state = {
      items: [],
    }

  componentDidMount(){
    const {
      modals,
      child,
      isChildView,
    } = this.props

    this.setState({
      items: isChildView ? child : modals || [],
    })
  }

  onDragEnd = (result) => {
    const {
     saveSequenceState,
     isChildView,
     reArrange,
    } = this.props

    if (!result.destination) {
      return;
    }

    const obj = this.reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    const converted = []

    obj.forEach((item, index) => {
     converted.push({
      ...item,
     })
    })

    if(isChildView) {
      reArrange({
        arranged: converted,
      })
      this.setState({
        items: converted,
      })
    } else {
      this.setState({
        items: {
          data: converted,
        }
      })
    }
  }

  reset = () => {

  }

  handleEdit = (item, index) => {
    const {
      setCurrentEditableProduct,
    } = this.props

    setCurrentEditableProduct({
      currentEditableProduct: item,
      currentIndex: index > -1 ? index : -1,
      isNewFeatureOrChild: false,
      isEditingExisting: true,
    })
  }

  handleClick = (item) => {
    const {
      setCurrentProduct,
      setCurrentEditableProduct,
    } = this.props

    this.setState({
      items: item.screens,
    })

    setCurrentProduct({
      product: item,
      child: item.screens || [],
      isChildView: item.screens.length > 0,
    })
    setCurrentEditableProduct({
      currentEditableProduct: {},
    })
  }

  // a little function to help us with reordering the result
  reorder = (list, startIndex, endIndex) => {
    const {
      isChildView,
    } = this.props
    const result = isChildView ? Array.from(list) : Array.from(list.data)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed);
    return result;
  }


  getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // styles we need to apply on draggables
    width: "478px",
    height: "73px",
    margin: "0 0 0 24px",
    display: "flex",
    borderRadius: "7px",
    border: "solid 1px grey",
    ...draggableStyle
  });

  getListStyle = isDraggingOver => ({
    padding: grid,
    width: "100%",
  });

 render() {
  const {
    modals,
    currentChildOfProductArray: child,
    isChildView,
  } = this.props

  const {
    items,
  } = this.state

  return (
    <React.Fragment>
      {isChildView ? (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable1">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={this.getListStyle(snapshot.isDraggingOver)}
              >
                {items && items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.title} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className="movableListDiv"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                      <ImageWrap>
                       <img src={Placeholder} alt="placeholder.jpg" />
                      </ImageWrap>
                      <DetailWrap>
                        <Button child onClick={() => this.handleEdit(item, index)}>Edit</Button>
                        <p className="modalTitle">{item.title}</p>
                      </DetailWrap>
                    </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <React.Fragment>
          {items && items.data && items.data.filter(item => item.isEnabled) <= 0 ? (
            <p style={{ textAlign: "center"}}>Click on Add New Feature button to create feature modal.</p>
          ) : (
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable2">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={this.getListStyle(snapshot.isDraggingOver)}
                  >
                    {items && items.data && items.data.map((item, index) => {
                      if(item.isEnabled) {
                        return (
                          <Draggable key={item.date} draggableId={item.title} index={index}>
                            {(provided, snapshot) => (
                              <div
                                className="movableListDiv"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={this.getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )
                              }
                              >
                                <ImageWrap>
                                 <img src={Placeholder} alt="placeholder.jpg" />
                                </ImageWrap>
                                <DetailWrap>
                                  <p className="modalTitle">{item.screens[0].title !== "edit" ? item.screens[0].title : item.title}</p>
                                  <Button onClick={() => this.handleEdit(item)}>Edit</Button>
                                  {item.screens && item.screens.length > 0 && (
                                    <Button onClick={() => this.handleClick(item)}>See Features</Button>
                                  )}
                                </DetailWrap>
                              </div>
                            )}
                          </Draggable>
                        )
                      }
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
 }
}


export default Container(ParentPIMList)
