import root from "window-or-global"
import { findKey } from "lodash"

export function GetUserPlatform() {
  if (/Android/i.test(navigator.userAgent)) {
    return "android"
  } else if (/iPhone/i.test(navigator.userAgent)) {
    return "ios"
  } else if (/iPad/i.test(navigator.userAgent)) {
    return "ipad"
  }
  return "desktop"
}

export function UrlSearchParams(pathname, queryString) {
  var results = new RegExp("[?&]" + queryString + "=([^&#]*)").exec(pathname);
  if (results == null) {
    return null;
  } else {
    return root.decodeURIComponent(results[1]) || 0;
  }
}

export const profileForm = pathname => (
  {
    email: {
      elementType: "input",
      elementConfig: {
        type: "firstname",
        placeholder: "First Name",
        style: { fontSize: "14px" },
      },
      value: UrlSearchParams(pathname, "firstname"),
      fieldName: "First Name",
      label: "First Name",
      validation: {
        required: true,
        isEmail: true,
      },
      valid: UrlSearchParams(pathname, "firstname") !== null,
      touched: true,
    },
  }
)


export const defaultLoginForm = pathname => (
  {
    email: {
      elementType: "input",
      elementConfig: {
        type: "email",
        placeholder: "Email",
        style: { fontSize: "14px" },
      },
      value: UrlSearchParams(pathname, "email"),
      fieldName: "Email",
      label: "Email",
      validation: {
        required: true,
        isEmail: true,
      },
      valid: UrlSearchParams(pathname, "email") !== null,
      touched: true,
    },
    password: {
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: "Password",
        style: { fontSize: "14px" },
      },
      value: "",
      fieldName: "Password",
      label: "Password",
      validation: {
        required: true,
      },
      valid: false,
      touched: true,
    },
  }
)

const alphaNumeric = value => value && /[^a-zA-Z0-9 ]/i.test(value)
  ? false
  : true

export const ShowHidePassword = (event, innerObject, formObject) => {
  event.preventDefault()
  const formKey = findKey(formObject, item => item.fieldName === innerObject.fieldName)
  const customForm1 = {
    ...formObject[formKey],
  }
  customForm1.elementConfig.type === "password" ? (customForm1.elementConfig.type = "text") : (customForm1.elementConfig.type = "password");
  formObject[formKey] = customForm1;
  return formObject
}

let errorType = ""

const checkValidity = (value, rules) => {
  console.log(value)
  let isValid = true
  if (!rules) {
    return true
  }

  if (rules.required) {
    isValid = value.toString().trim() !== "" && isValid;
    errorType = "required"
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
    errorType = "minlength"
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
    errorType = "maxlength"
  }

  if (rules.isEmail) {
    const pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;

    isValid = pattern.test(value) && isValid
    errorType = "email"
  }

  if (rules.isAlphaNumeric) {
    isValid = alphaNumeric(value) && isValid
    errorType = "alphanumeric"
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/
    isValid = pattern.test(value) && isValid
    errorType = "numeric"
  }

  console.log(errorType)

  return isValid
}

export const formValidator = (formObject, event, inputIdentifier, data) => {
  const updatedFormElement = formObject[inputIdentifier];
  if (updatedFormElement.elementType === "select") {
    updatedFormElement.value = event.value
  } else {
    updatedFormElement.value = event.target.value
  }
  updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation)
  updatedFormElement.touched = true
  updatedFormElement.errorType = errorType
  console.log(updatedFormElement)
  formObject[inputIdentifier] = updatedFormElement

  let formIsValid = true
  for (let inputIdentifier in formObject) {
    formIsValid = formObject[inputIdentifier].valid && formIsValid
  }

  if (updatedFormElement.enableDependency) {
    const enableInput = formObject[updatedFormElement.enableDependency]
    enableInput.elementConfig.disabled = false;
    formObject[updatedFormElement.enableDependency] = enableInput;
  }

  return { formObject, formIsValid }
}

export default formValidator;

export function Serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return "?" + str.join("&");
}

export const getOnboardingNavigateRoute = (key) => {
  const onboardingKeyRouteMap = {
    LEARN_MORE: "/onboarding/tell-about-yourself",
    QUIZ_LANDING: "/onboarding/tell-about-yourself",
    INDIVIDUAL_COMMISSION: "/onboarding/tell-about-yourself",
    INDIVIDUAL_SUCCESS: "/onboarding/tell-about-yourself",
    INDIVIDUAL_EARN_MORE: "/onboarding/tell-about-yourself",
    TEAM_COMMISSION: "/onboarding/tell-about-yourself",
    TEAM_SUCCESS: "/onboarding/tell-about-yourself",
    TEAM_EARN_MORE: "/onboarding/tell-about-yourself",
    QUIZ_LANDING_END: "/onboarding/successful-onboarding",
  }
  return onboardingKeyRouteMap[key]
}
