import React from "react"
import styled from "styled-components"
import container from "container/MultipleCaller"
import mockevents from "./MockEvents"

import FloatingIndicator from "./FloatingIndicator"
import FullMode from "./FullMode"

const Wrap = styled.div`
  width: 300px;
  height: 50px;
  border-radius: 10px;
  position: fixed;
  top: calc(100vh - 65px);
  right: 10px;
  z-index: 10;
`

class MultipleCalled extends React.PureComponent {
  state = {
    isFullMode: false,
  }

  componentDidMount() {
    const { connectToSocket, user } = this.props
    const { twilio_client_name } = user || {}
    connectToSocket({
      twilioUserName: twilio_client_name,
    })

    setTimeout(() => {
      this.toggleFullMode(true)
    }, 3000)
  }

  toggleFullMode = (isShow) => {
    this.setState({
      isFullMode: isShow,
    })
  }

  render() {
    const { isFullMode } = this.state
    const {
      isMulticallerActive,
      isConnected,
      callStatus,
      deleteItemAutomatically,
    } = this.props
    return (
      <Wrap>
        {isFullMode && callStatus && (
          <FullMode
            callStatus={callStatus}
            deleteItemAutomatically={deleteItemAutomatically}
          />
        )}
        <FloatingIndicator
          isFullMode={isFullMode}
          toggleFullMode={this.toggleFullMode}
          isConnected={isConnected}
          callStatus={callStatus}
        />
      </Wrap>
    )
  }
}

export default container(MultipleCalled)
