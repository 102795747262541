import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as Actions from "./actions"

function mapStateToProps(state) {
  const { client } = state
  const {
    mergedZillowResponse,
    zillowMergeResponse,
  } = client
  return {
    mergedZillowResponse,
    zillowMergeResponse,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      toggleZillowMergeModal: Actions.toggleZillowMergeModalAction.call,
      fetchReferalForMerge: Actions.fetchReferalForMergeAction.request,
      mergeZillowLeads: Actions.mergeZillowLeadsAction.request,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
