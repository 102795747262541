import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { processTimeline } from "./selectors"
import * as Actions from "./actions"

function mapStateToProps(state) {
  const { client } = state
  const { timeline } = client
  const parsedTimeline = processTimeline(timeline)
  return {
    timeline,
    parsedTimeline,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      fetchClientTimeline: Actions.fetchClientTimelineAction.request,
      clearTimeline: Actions.fetchClientTimelineAction.clear,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
