import React, { Component } from "react"
import styled from "styled-components"
import colors from "../../../lib/colors"
import Container from "container/Academy"
import LessonList from "./LessonList"
import AddCourseMenu from "dumbComponents/Academy/Course/AddCourseMenu"
import {
  Route, Switch, Link,
} from "react-router-dom"
import Select from 'react-select';
import _ from 'lodash';

const Wrap = styled.div`
 height: 100px;
 width: 300px;
 border: 1px solid red;
 margin-left: 100px;
`

const ListWrap = styled.ul`
 list-style: none;
 padding:0;
 margin:0;
 li {
  width: 100%;
  height: 68px;
  border: solid 1px #e2e4e8;
  display: flex;
  .instructor_image,
  .cta_section
  {
   height: 100%;
   width: 10%;
  }
  .instructor_details{
   height: 100%;
   width: 70%;
   min-width: 60px;
   padding: 14px;
   p {
    margin: 0;
   }
   p:last-child {
    font-size: 12px;
    color: #586069;
   }
  }
  .cta_section{
   width: 20%;
  }
 }
`

const OuterWrap = styled.div`
 display: flex;
`

const RightMenu = styled.div`
  height: 500px;
  width: 699px;
  padding-left: 20px;

   .cta {
    display: flex;
    justify-content: space-between;
   }

   .add-lesson {
    width: 84px;
    height: 30px;
    border-radius: 4px;
    border: solid 1px rgba(27, 31, 35, 0.12);
    background-color: #fafbfc;
    margin-top:10px;
   }

   .lessonList{
    border: solid 1px #e2e4e8;
   }
  }

  .instructors-list {
   width: 100%;
   min-height: 100px;
   height: max-content;

   border: 1px solid #e2e4e8;
   .headerDiv {
    height: 33px;
    border-radius: 4px;
    border-bottom: solid 1px #e2e4e8;
    background-color: #f4f5f8;
   }
  }
`

const Remove = styled.button`
  border: none;
  background: transparent;
  padding: 28px;
  font-size: 14px;
  font-weight: 500;
  color: #cb2431;
  cursor: pointer;
`
const AddLessonCTA = styled(Link)`
  margin-bottom: 15px;
  width: 118px;
  display: block;
  height: 30px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: black;
  text-decoration: none;
  cursor: pointer;
  padding-top: 4px;
  width: 84px;
  height: 30px;
  border-radius: 4px;
  border: solid 1px rgba(27, 31, 35, 0.12);
  background-color: #fafbfc;
  margin-top:10px;
`


class AddLesson extends Component {
 state = {
  isUpdateMode: this.props.match.params.id,
 }

 clearForms = () => {
  const {
   clearFormsEdits,
   clearLessonAboutForm,
  } = this.props
  clearLessonAboutForm()
  clearFormsEdits()
 }

 submit = (values) => {
  console.log(values)
 }

 componentDidMount(){
  const {
   getCourseDetails,
  } = this.props

  if(this.props.match.params.id) {
   getCourseDetails({ id: this.props.match.params.id})
  }
 }
 render(){
  const {
   multipleInstructorsResponse,
   instructorArray,
   courseDetailResponse,
  } = this.props

  const {
   isUpdateMode,
  } = this.state

  const {
   data: course
  } = courseDetailResponse || {}

  return (
    <OuterWrap>
     <AddCourseMenu
      submitFunc={this.submit}
      isUpdateMode={this.props.match.params.id || ""}
      isPublished={course && Boolean(course.published) || false}
     />
     <RightMenu>
      <div className="cta">
       <p>Lesson</p>
       <AddLessonCTA
       onClick={() => this.clearForms()}
       to={ isUpdateMode ? `/academy-admin/courses/course/${this.props.match.params.id}/lessons/add-lesson/about`
       : "/academy-admin/courses/add-new-course/lesson/create-lesson/about"}
       >
        Add +
       </AddLessonCTA>
      </div>
      <div className="lessonList">
       {course && Object.keys(course).length > 0 && (
        <LessonList
         course={course}
        />
       )}
      </div>
     </RightMenu>
    </OuterWrap>
  )
 }
}

export default Container(AddLesson)
