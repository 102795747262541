import React from "react"
import styled from "styled-components"

const SIZE = 25;

const Wrap = styled.div`
  display: inline-block;
  position: relative;
  width: ${SIZE * 2}px;
  height: ${SIZE}px;
  background: #EEE;
  cursor: pointer;
  border-radius: ${SIZE}px;

  ${props => props.isChecked && `
    background: #bddfcc;
  `}
`

const Ball = styled.div`
  position: absolute;
  width: ${SIZE}px;
  height: ${SIZE}px;
  border-radius: 50%;
  background: #888;
  top: 0px;
  left: 0px;
  ${props => props.isChecked && `
    left: ${SIZE}px;
    background: #27ae60;
  `}
`

const Toggle = ({
  onChange,
  isChecked
}) => (
  <Wrap onClick={onChange} isChecked={isChecked}>
    <Ball isChecked={isChecked}/>
  </Wrap>
)

export default Toggle