import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"
import AddLessonMenu from "./AddLessonMenu"
import RTFEditorIO from "dumbComponents/Academy/Common/EditorRTF"
import Notes from "./forms/Notes"


const OuterWrap = styled.div`
 display: flex;
 .about-les-form {
   width: 500px;
 }
`
const NotesAndResourcesForm = styled.form`
 display: flex;
 flex-direction: column;
 margin-bottom: -100px;
 label {
  font-family: "SF Pro Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #24292e;
 }
`
const UpperDiv = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
`

const LowerDiv = styled.div`
  width: 100%;
  height: 350px;
  padding: 50px;
  button {
   float: right;
  }
`

const InfoDiv = styled.div`
  width: 250px;
  height: 350px;
  flex-grow: 3;
  .fields {
   margin-bottom: 30px;
   label {
    padding-bottom: 10px;
   }
  }
  .info {
   font-size: 12px !important;
   margin: 0;
   color: #586069;
  }
`

class NotesAndResources extends Component {
 componentDidMount(){
  const {
   getCourseDetails,
  } = this.props

  if (this.props.match.params.id && this.props.match.params.lid) {
   getCourseDetails({ id: this.props.match.params.id})
  }
 }

 render() {
   const {
    courseDetailResponse,
   } = this.props
   const {
    data: course,
    isFetching,
   } = courseDetailResponse || {}

   const courseExists =
   this.props.match.params.lid
   && this.props.match.params.id
   && course
   && Object.keys(course).length > 0
   && course.Lessons
  return (
    <React.Fragment>
      {isFetching ? (
       <OuterWrap>
        <AddLessonMenu
          isUpdateMode={this.props.match.params.id || ""}
          isIndividualLessonMode={this.props.match.params.lid || "" }
        />
        <div className="about-les-form">
          <p>Loading</p>
        </div>
       </OuterWrap>
      ) : (
       <OuterWrap>
        <AddLessonMenu
          isUpdateMode={this.props.match.params.id || ""}
          isIndividualLessonMode={this.props.match.params.lid || "" }
        />
        <div className="about-les-form">
          <p>About Lesson</p>
          <Notes
          rtfContent={courseExists
          && course.Lessons
          && course.Lessons.filter(lesson => lesson.id === this.props.match.params.lid)[0] || ""}
          />
        </div>
       </OuterWrap>
      )}
    </React.Fragment>
  )
 }
}

export default Container(NotesAndResources)
