import React from 'react';

class NotificationTimer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            timer: props.timer,
            task: null,
        }
    }

    componentDidMount() {
        this.setState({task: setInterval(() => {
                const { timer } = this.state;
                if (timer > 0) {
                    this.setState({
                        timer: timer - 1,
                    })
                } else {
                    clearInterval(this.state.task)
                }

            }, 1000)})
    }

    componentWillUnmount() {
        if (this.state.task) {
            clearInterval(this.state.task)
        }
    }

    render() {
        const { timer } = this.state;
        return (
            <span>
                <b>Remaining Time: {timer} seconds</b>
            </span>
        );
    }
}

export default  NotificationTimer