import React, { Component } from "react"
import styled from "styled-components"
import { reduxForm, Field } from "redux-form"
import Container from "container/Academy"
import Select from 'react-select';
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"

const required = value => value ? undefined : "* Required"

const CategoryForm = styled.form`
 label {
  font-family: "SF Pro Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #24292e;
  .required {
   color: red;
  }
 }
`
const UpperDiv = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
`

const LowerDiv = styled.div`
  width: 100%;
  height: 350px;
  padding: 50px;
  button {
   float: right;
   position: relative;
   top: 207px;
  }
`

const InfoDiv = styled.div`
  width: 250px;
  height: 350px;
  flex-grow: 3;
  padding: 46px;
`


const PhotoDiv = styled.div`
  height: 100%;
  max-width: 250px;
  flex-grow:2;
`

const InputFields = styled(Field)`
  width: 100%;
  height: 39px;
  border-radius: 3px;
  border: solid 1px #d1d5da;
  background-color: #fafbfc;
  margin-bottom: 37px;
  padding-left: 9px;
`
class AddCategoryForm extends React.Component {

 componentDidMount() {
  const {
   categoryDetail,
   isUpdateMode,
   initialize,
  } = this.props

  if(isUpdateMode
  && categoryDetail
  && Object.keys(categoryDetail).length > 0) {
    initialize({
     categoryName: categoryDetail[0].name,
    })
  }
 }

 render() {
  const {
    handleSubmit,
   } = this.props

  return (
    <CategoryForm id='category-form' onSubmit={handleSubmit}>
      <div className="categoryname">
        <label htmlFor="Category Name">
         Category Name
         <span className="required">*</span>
        </label>
        <InputFields name="categoryName" component="input" type="text" />
      </div>
    </CategoryForm>
  )
 }
}

AddCategoryForm = reduxForm({
  form: 'addCategoryForm',
  destroyOnUnmount: false,
})(AddCategoryForm)

export default AddCategoryForm
