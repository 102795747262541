import React from "react"
import { Transition } from "react-transition-group"
import styled from "styled-components"

export const CALL_STATUS = {
  // NO_CALLS: {
  //   value: "No calls",
  //   color: "#959595",
  // },
  "in-progress": {
    value: "In Call",
    color: "#2980b9",
  },
  "in-conference": {
    value: "In Conference",
    color: "#8e44ad",
    isHideFromFloater: true,
    isAutoDelete: true,
  },
  waiting: {
    value: "Waiting",
    color: "#e74c3c",
  },
  connecting: {
    value: "Dialing",
    color: "#27ae60",
  },
  queued: {
    value: "Upcoming",
    color: "#3f5651",
  },
  prevCall: {
    value: "Prev. Calls",
    color: "#1f6969",
    isAutoDelete: true,
    deleteAfter: 20000, // in ms
  },
  ActiveAgentClientCallEvent: {
    value: "Active Agent Client Calls",
    color: "#1f6969",
    isHideFromFloater: true,
  },
}

// Growing Animation

const defaultStyle = ({ duration }) => ({
  transition: `opacity ${duration}ms ease-in-out`,
})

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 0 },
  exiting: { opacity: 1 },
  exited: { opacity: 1 },
}

export const GrowTransition = ({ in: inProp, timeout, children }) => (
  <Transition in={inProp} timeout={300}>
    {state => (
      <div
        style={{
          ...defaultStyle({ timeout }),
          ...transitionStyles[state],
        }}
      >
        {children}
      </div>
    )}
  </Transition>
)
