/* eslint-disable max-len */
import React from "react"
import styled from "styled-components"
import moment from "moment"
import { get, isBoolean, isObject, capitalize, isEmpty, } from "lodash"
import Select from "react-select"
import { parse } from "query-string"
import cookie from "react-cookies"
import { Switch, Route, Redirect, withRouter } from "react-router-dom"
import { Button, Tabs } from "antd"
import localStorageUtil from "services/localstorageUtil"
import { formatSelectedValue, downloadFileByURL } from "services/Utils"
import { VIEW_LIST_IGNORE, DEAL_STATUS, TRANSACTION_TYPE } from "./../FieldData"
import { USCurrencyFormat } from "shared/currencyUtils"
import Loader from "dumbComponents/common/UI/Loader"
import ConfirmModal from "dumbComponents/UI/ConfirmModal"
import container from "container/BrokerDashboard/globalPagesContainer"
import { TC_STATUS, getDefaultStatus, NEW_LISTING_SPECIFIC_STATUS } from "dumbComponents/BrokerDashboard/GlobalPages/Deals/consts"

import DealCloseConfirmModal from "dumbComponents/BrokerDashboard/GlobalPages/Deals/DealCloseConfirmModal"
import DealDemandConfirmModal from "dumbComponents/BrokerDashboard/GlobalPages/Deals/DealDemandConfirmModal"
import TimelineDrawer from "./TimelineDrawer"
import { window, JSON } from "window-or-global"
import CookiesStorage from "services/CookieStorage"
import root from "window-or-global"
import TCListingsView from "./TCListingsView"
import TCContractsView from "./TCContractsView"

const Wrap = styled.div`
  margin: 0px 30px;
  padding: 0px 0px 100px 0px;
`

export const Row = styled.div`
  display: flex;
  margin-top: 40px;
`

export const Col = styled.div`
  flex: 1;
  margin-right: 20px;
  margin-bottom: 0px;
  &:last-child {
    margin-right: 0px;
  }
`

const LabelAndTextWrap = styled.div`
  flex: 1;
  h5 {
    font-size: 12px;
    text-transform: uppercase;
    color: #555;
    margin: 0px;
  }
  p {
    color: #111;
    margin: 6px 0px 0px 0px;
  }
`

const FileList = styled.div`
  padding: 8px;
  border-radius: 6px;
  background: #F9F8FF;
  display: flex;
  margin-top: 6px;
  p {
    flex: 1;
    margin: 0;
    padding: 0;
  }

  span {
    width: 100px;
    color: #7B61FF;
    text-align: center;
    cursor: pointer;
  }
`
const ViewLog = styled.div`
  margin-top: 80px;
`

const StatusDiv = styled.div`
  width: 240px;
  margin-left: 10px;
`
const AllStatusDiv = styled.div`
  margin-top: 8px;
  display: flex;
`

const BOOL_FIELDS = [
  "is_all_cash_offer",
  "is_co_listed_deal",
  "is_radius_lead",
  "is_radius_tc_to_open_escrow",
  "schedule_sign_post_installation",
]

const DATE_FIELDS = [
  "closed_on",
  "confirmed_closed_on",
  "created_at",
  "row_created_at",
  "sign_post_installation_on",
  "listing_expiration_date",
]

const CURRENCY_FIELDS = [
  "agent_commission",
  "fixed_fee",
  "gross_commission",
  "list_price",
  "price",
  "radius_transaction_fee",
]

const FORMATTERS_FIELDS = [
  "client_type",
  "commission_type",
  "source",
  "transaction_type",
  "file_type",
  "radius_transaction_type",
  "active_listing_status",
]

const PERCENTAGE_FIELDS = [
  "commision_percentage",
  "team_split_percentage",
]

export const LabelAndText = ({
  title,
  data,
  children,
}) => (
  <LabelAndTextWrap>
    {title && (
      <h5>{title.replaceAll("_", " ")}</h5>
    )}
    {data && (
      <p>{data}</p>
    )}
    {children && (
      <div>
        {children}
      </div>
    )}
  </LabelAndTextWrap>
)

class ViewDeal extends React.PureComponent {
  state = {
    form: {},
    activeKey: "2",
    currentStatus: null,
  }

  //api call
  componentDidMount() {
    const {
      getFileForTC,
      match,
      location,
      getAllDealsListing,
      setDealAgentId,
      dealsListingResponse,
      getListingCoverSheet,
      getContractStatus,
      getVendorDetails,
    } = this.props
    const { dealId, agentId } = match.params
    // setDealAgentId(agentId)
    // setDealToView(2163)
    const dealRes = this.dealFromUrl(dealId)
    if (dealId) {
      getFileForTC({
        tcId: dealId,
      })
    }
    if (dealRes) {
      this.setState({
        form: {
          ...dealRes,
        },
        sectionArray: this.createSections(dealRes, 3),
      })
      if (dealRes.listing_status) {
        this.setState({
          activeKey: "1",
        })
      }
    }
    if (dealId && !location.search.includes("fromListing")) {
      getAllDealsListing({
        type_deal: "deal",
        dealId,
        agentId,
        dealType: dealRes.step,
        transactionType: "Contract",
        callback: (response) => {
          this.setState({
            form: [
              ...response,
            ],
            sectionArray: this.createSections(response, 3),
          })
        },
      })
      getVendorDetails({ dealId, agentId })
    }
    //here
    if (location.search.includes("fromListing")) {
      console.log("show me form listing", dealId)
      getAllDealsListing({
        type_deal: "deal",
        listingId: dealId,
        transactionType: "Listing",
        callback: (response) => {
          console.log("show me form callback", response)
          this.setState({
            form: [
              ...response,
            ],
            sectionArray: this.createSections(response, 3),
          })
        },
      })
      getListingCoverSheet({
        dealAgentId: match.params.agentId,
        dealId: match.params.dealId,
        callAPI: ["agent_info", "seller_info", "source_time", "property_info", "tc_info", "sign_post"],
      })
      this.setState({
        activeKey: "1",
      })
    }
    getContractStatus()
  }

  dealFromUrl = (dealId) => {
    try {
      const resRaw = localStorageUtil.getItem(`deal_${dealId}`)
      console.log(resRaw);
      return resRaw
    } catch (error) {
      console.log(error);
      this.setState({
        isParsinError: true,
      })
    }
  }

  getLabelFromObj = (value, list) => {
    const selectedItem = list.filter(x => x.value === value)
    return selectedItem.length > 0 ? selectedItem[0].label : value
  }


  createSections = (deal = {}, perChunk = 3) => {
    const rawList = Object.keys(deal)
    const inputArray = rawList.filter(x => !VIEW_LIST_IGNORE.includes(x))
    const result = inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
    return result
  }

  handleStatusUpdate = (selectedItem) => {
    const { value } = selectedItem
    const { saveStepsForProcuct, sendSlackNotifyOnStatusChange, createDeals, triggerFetchDeals, getContractStatusResponse, saveFileTypeChange, history, location, saveListingSteps } = this.props
    const { form: formData, currentStatus } = this.state
    const form = formData?.find(dt => dt.id === parseInt(parseInt(location.search.split("=")[1], 10), 10)) || {}
    const user = cookie.load("user")
    
    const ContractStatus = getContractStatusResponse.data && getContractStatusResponse.data.contractStatus && getContractStatusResponse.data.contractStatus.length > 0 && getContractStatusResponse.data.contractStatus.map(x => (
      { label: x.display_name, value: x.value }
    ))
    const ListingStatus = getContractStatusResponse.data && getContractStatusResponse.data.listingStatus && getContractStatusResponse.data.listingStatus.length > 0 && getContractStatusResponse.data.listingStatus.map(x => (
      { label: x.display_name, value: x.value }
    ))

    this.setState({
      currentStatus: selectedItem.value,
    })
    if (selectedItem.value === "closed" || selectedItem.value === "demand_pending" || selectedItem.value === "demand_completed") {
      this.setState({
        isShowConfirmModal: true,
      })
      return
    }

    const statContract = ContractStatus.filter(x => x.value === selectedItem.value) || null


    const statList = ListingStatus.filter(x => x.value === selectedItem.value) || null
    
    //here
    if (selectedItem.value === "pending" && form.file_type === "new_listing") {
      const isActive = window.confirm("Since you are changing the status of a New Listing file type to 'Pending', we will be changing the file type for this file to 'Listing with Accepted Offer' and Changing Contract status to Incomplete. Please fill the  pending Transaction Details for this Deal to change status of contract status from Incomplete to Submitted")
      if (!isActive) {
        return
      }
    } else if (selectedItem.value === "contract_canceled") {
      const isActive = window.confirm(`Since you are changing the status of a Contract to ${selectedItem.label}, would you like to generate a Listing for the same?`)
      if (isActive) {
        //here
        saveStepsForProcuct({
          payload: {
            deal_id: form.id,
            step: selectedItem.value,
            generate_listing: 1,
          },
          callback: () => {
            triggerFetchDeals()
          },
        })
      }
      return
    }
    else {
      const isConfirm = window.confirm("Are you sure?")
      if (isConfirm) {
        if (selectedItem.value !== "active_listing" && selectedItem.value !== "listing_verified") {
          let rowCreatedAt
          if (form.row_created_at === null) {
            rowCreatedAt = moment().format("YYYY-MM-DD HH:mm:ss")
          } else {
            rowCreatedAt = form.row_created_at
          }
          const payload = {
            ...form,
            row_created_at: rowCreatedAt,
          }
          delete payload.date_of_listing_submission
          if (form.radius_transaction_type === null) {
            delete payload.radius_transaction_type
          } else {
            payload.radius_transaction_type = form.radius_transaction_type.value
          }
          //here
        //   createDeals({
        //     payload,
        //     isEdit: form.id,
        //     callback: () => {
        //       triggerFetchDeals()
        //     },
        //   })
        }
      }
    }

    saveStepsForProcuct({
      payload: {
        admin_name: user.twilio_client_name || "anonymous",
        deal_id: formData[0].id,
        step: value,
      },
      callback: () => {
        if (selectedItem.value === "audit_verified" || selectedItem.value === "demand_pending") {
          sendSlackNotifyOnStatusChange({
            agentId: formData[0].agent_id,
            dealId: formData[0].id,
            dealStatus: selectedItem.value,
            listingId: null,
          })
        }
        if (selectedItem.value === "pending") {
          history.push({
            pathname: "/broker/tc/listings",
          })
        } else {
          window.location.reload()
        }
      },
    })
    //here
    saveListingSteps({
      payload: {
        admin_name: user.twilio_client_name || "anonymous",
        listing_id: form.id,
        step: value,
        notes: "",
      },
      callback: () => {
        if (selectedItem.value === "audit_verified" || selectedItem.value === "demand_pending") {
          sendSlackNotifyOnStatusChange({
            agentId: form.agent_id,
            dealId: form.id,
            dealStatus: selectedItem.value,
            listingId: null,
          })
        }
        if (selectedItem.value === "pending") {
          history.push({
            pathname: "/broker/tc/listings",
          })
        } else {
          window.location.reload()
        }
      },
    })


    this.setState({
      form: {
        ...form,
        step: selectedItem.value,
        listing_status: selectedItem.value === "submitted" ? "Pending" : selectedItem.value,
      },
    })
  }

  goToEdit = () => {
    const { history, match, setDealToView, setDealAgentId } = this.props
    const { form } = this.state
    history.push({
      pathname: `/broker/dashboard/${match.params.agentId}/deals/edit/${match.params.dealId}`,
      state: {
        deal: form,
      },
    })
    // setDealAgentId(10026601)
    // setDealToView(2163)
    // history.push("/broker/tc/add-listings/coversheet/listing-agent-info")
  }

  goToEditListings = () => {
    const { history, match, setDealToView, setDealAgentId } = this.props
    const { form } = this.state
    // history.push({
    //   pathname: `/broker/dashboard/${match.params.agentId}/deals/edit/${match.params.dealId}`,
    //   state: {
    //     deal: form,
    //   },
    // })
    const { dealId, agentId } = match.params
    setDealAgentId(agentId)
    setDealToView(dealId)
    history.push("/broker/tc/add-listings/coversheet/listing-agent-info")
  }

  handleValue = (value, label) => {
    if (value === null || value === "") {
      return "N/A"
    }
    if (isObject(value)) {
      return "OBHJ"
    }
    if (CURRENCY_FIELDS.includes(label)) {
      return value === 0 ? 0 : `${USCurrencyFormat(value)}`
    }
    if (BOOL_FIELDS.includes(label)) {
      return value === 0 ? "No" : "Yes"
    }
    if (FORMATTERS_FIELDS.includes(label)) {
      if (label === "transaction_type") {
        return formatSelectedValue(this.getLabelFromObj(value, TRANSACTION_TYPE))
      }
      return formatSelectedValue(value)
    }
    if (PERCENTAGE_FIELDS.includes(label)) {
      return `${value}%`
    }
    if (DATE_FIELDS.includes(label)) {
      const isValidDate = (new Date(value)).getTime() > 0
      if (isValidDate) {
        return `${moment.unix((new Date(value).getTime())).format("D MMM, YYYY")}`
      }
      return "N/A"
    }
    return value
  }

  hackSubSectionTitle = (label) => {
    if (label === "created_at") {
      return "Contract On"
    }
    if (label === "price") {
      return "Sales Price"
    }
    return label
  }

  hideContract = (form = {}) => {
    const { file_type } = form
    if (file_type === "new_listing") {
      return true
    }
    return false
  }

  handleDownload = async (url) => {
    this.setState({
      isShowDownloadLoader: true,
    })
    await downloadFileByURL(url)
    this.setState({
      isShowDownloadLoader: false,
    })
  }

  handleCloseConfirm = () => {
    const { saveStepsForProcuct, history, sendSlackNotifyOnStatusChange } = this.props
    const { form, currentStatus } = this.state
    const user = cookie.load("user")
    console.log(form[0])
    saveStepsForProcuct({
      payload: {
        deal_id: form[0].id,
        step: currentStatus,
        admin_name: user.twilio_client_name || "anonymous",
      },
      callback: () => {
        if (currentStatus === "audit_verified" || currentStatus === "demand_pending") {
          sendSlackNotifyOnStatusChange({
            agentId: form[0].agent_id,
            dealId: form[0].id,
            dealStatus: currentStatus,
            listingId: null,
          })
        }
        if (currentStatus === "pending") {
          history.push({
            pathname: "/broker/tc/listings",
          })
        } else {
          window.location.reload()
        }
      },
    })
    this.setState({
      isShowConfirmModal: false,
    })
  }

  tabChange = (key) => {
    this.setState({
      activeKey: key,
    })
  }

  render() {
    const {
      form,
      sectionArray,
      isShowDownloadLoader,
      isShowConfirmModal,
      activeKey,
      currentStatus,
    } = this.state
    const {
      tcFilesResponse,
      history,
      match,
      dealsListingResponse,
      toggleTimelineDrawer,
      getDealStatusTimeline,
      getContractStatusResponse,
      getVendorDetailsResponse,
      getAllDealsListing,
      location,
    } = this.props
    const { params } = match
    const { data: filesRes } = tcFilesResponse
    const { data: deal, isFetching } = dealsListingResponse
    const ContractStatus = getContractStatusResponse.data && getContractStatusResponse.data.contractStatus && getContractStatusResponse.data.contractStatus.length > 0 && getContractStatusResponse.data.contractStatus.map(x => (
      { label: x.display_name, value: x.value }
    ))
    const ListingStatus = getContractStatusResponse.data && getContractStatusResponse.data.listingStatus && getContractStatusResponse.data.listingStatus.length > 0 && getContractStatusResponse.data.listingStatus.map(x => (
      { label: x.display_name, value: x.value }
    ))
    const getDefaultStatusContract = (listingValue, stepValue) => {
      if (getContractStatusResponse.data && (listingValue || stepValue)) {
        //here
        if (listingValue && listingValue !== "pending") {
          return { label: "None", value: null }
        }
        // if (listingValue === null || listingValue !== "Pending") {
        //   return {
        //     label: "None", value: null,
        //   }
        // }
        const selectedItem = ContractStatus.find(x => x.value === stepValue)
        return selectedItem
      }
      return {
        label: "Incomplete", value: "incomplete",
      }
    }
  
    const getDefaultListingStatus = (listingValue, stepValue) => {
      if (getContractStatusResponse.data && (listingValue || stepValue)) {
        // if (listingValue === "Pending") {
        //   return {
        //     label: "Pending", value: "pending",
        //   }
        // }
        if (!listingValue) {
          return {
            label: "None", value: null,
          }
        }
        const selectedItem = ListingStatus.find(x => x.value === listingValue)
        return selectedItem
      }
    }

    //click tab
    //here
    const onClickTab = (key, event) => {
      const { dealId, agentId } = match.params
      const dealRes = this.dealFromUrl(dealId)
      if (key === "2") {
        getAllDealsListing({
          type: "agent",
          type_deal: "deal",
          // isShowActiveListing: dealRes && dealRes.file_type === "new_listing",
          dealId,
          agentId,
          dealType: dealRes.step,
          transactionType: "Contract",
          callback: (response) => {
            this.setState({
              form: [
                ...response,
              ],
              sectionArray: this.createSections(response, 3),
            })
          },
        })
      }
    }

    return (
      <Wrap>
        {isShowConfirmModal && currentStatus==="closed" && (
          <DealCloseConfirmModal
            onConfirm={() => { this.handleCloseConfirm() }}
            onCancel={() => {
              this.setState({ isShowConfirmModal: false })
            }}
            isDisableConfirm={!form.confirmed_closed_on}
            dealSelected={form}
            onEdit={() => {
              history.push({
                pathname: `/broker/dashboard/${form.agent_id}/deals/edit/${form.id}?checkPricing=true`,
                state: {
                  deal: form,
                },
              })
            }}
          />
        )}
        {isShowConfirmModal && (currentStatus === "demand_pending" || currentStatus === "demand_completed") && (
          <DealDemandConfirmModal
          onConfirm={() => { this.handleCloseConfirm() }}
          onCancel={() => {
            this.setState({ isShowConfirmModal: false })
          }}
          dealSelected={form}
          onEdit={() => {
            history.push({
              pathname: `/broker/dashboard/${form.agent_idd}/deals/edit/${form.id}?checkPricing=true`,
              state: {
                deal: form,
              },
            })
          }}
        />
        )}
        <AllStatusDiv>
          <StatusDiv>
            <LabelAndText
              title="Listing Status"
            >
              <Select
                options={ListingStatus}
                isDisabled={isEmpty(form[0]?.listing_status)}
                value={getDefaultListingStatus(form[0]?.listing_status, form[0]?.step)}
                onChange={this.handleStatusUpdate}
              />
            </LabelAndText>
          </StatusDiv>
          <StatusDiv>
            <LabelAndText
              title="Contract Status"
            >
              <Select
              //here
                isDisabled={!(form[0]?.listing_status === "pending" || !form[0]?.listing_status)}
                options={ContractStatus}
                value={getDefaultStatusContract(form[0]?.listing_status, form[0]?.step)}
                onChange={this.handleStatusUpdate}
              />
            </LabelAndText>
          </StatusDiv>
        </AllStatusDiv>

        <Tabs
          style={{
            marginTop: 10,
          }}
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={this.tabChange}
          //here
          onTabClick={onClickTab}
        >
          <Tabs.TabPane tab="TC Listing Details" key="1">
            <TCListingsView goToEditListings={this.goToEditListings} form={form} />
          </Tabs.TabPane>

          {form && (form[0]?.listing_status === "pending" || location.search.includes("fromContract")) && (
            <Tabs.TabPane tab="TC Contract Details" key="2">
              <TCContractsView form={form} vendors={getVendorDetailsResponse && getVendorDetailsResponse.data && getVendorDetailsResponse.data} goToEdit={this.goToEdit} />
            </Tabs.TabPane>
          )}

        </Tabs>

        {false && sectionArray && (
          <React.Fragment>
            {sectionArray.map((section, sectionIndex) => (
              <Row key={sectionIndex}>
                {section && section.map((subSection, subSectionIndex) => (
                  <Col key={subSectionIndex} isHide={this.hideContract(form)}>
                    <LabelAndText
                      title={this.hackSubSectionTitle(subSection)}
                      data={this.handleValue(form[subSection], subSection)}
                    />
                  </Col>
                ))}
              </Row>
            ))}
          </React.Fragment>
        )}

        {activeKey === "1" && (
          <Button
          //onchange
            type="primary"
            //herr
            onClick={() => {
              toggleTimelineDrawer(true)
              getDealStatusTimeline({ listingId: parseInt(location.search.split("=")[1], 10) })
            }}
            style={{
              marginBottom: "10px",
              width: "250px",
            }}
          >
          View Deal Status Activity Log
          </Button>

        )}
        <TimelineDrawer />
        {form ? (
          <React.Fragment>
            <Row style={{ margin: "20px 0px" }}>
              <LabelAndText
                title="Files"
              >
                {filesRes && filesRes.length > 0 ? filesRes.map(file => (
                  <FileList key={file.id}>
                    <p>{file.title}</p>
                    <span onClick={() => { window.open(file.url) }}>
                      View
                    </span>
                    <span onClick={() => this.handleDownload(file.url)}>
                      {isShowDownloadLoader ? "Processing.." : "Download"}
                    </span>
                  </FileList>
                )) : (
                  <FileList>
                    <p>No Files found</p>
                  </FileList>
                )}
              </LabelAndText>
            </Row>
          </React.Fragment>
        ) : (
          <h3>Loading..</h3>
        )}

      </Wrap>
    )
  }
}

export default withRouter(container(ViewDeal))
