
export const setItem = (key, value, expiry = null) => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export const getItem = (key) => {
  return JSON.parse(window.localStorage.getItem(key))
}

export default {
 setItem,
 getItem, 
}