import React from "react"
import Container from "container/EventRooms"
import TechAssets from "container/TechAssets"
import Loader from "dumbComponents/common/UI/Loader"
import styled from "styled-components"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
  Table, Tag, Space, Form, Input, Modal, Button, Tabs, Select,
  Upload, message,
} from "antd"
import moment from "moment"
const { confirm } = Modal
const { Option } = Select

const { TabPane } = Tabs

const SelectWrap = styled.div`
  
`

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 6,
  },
}
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
}

const Wrap = styled.div`
  padding: 75px 35px;
`

const Address = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  span {
    font-weight: 500;
  }
`

const FormWrap = styled.div`
  padding: 40px;
  background: #e9e9e9;
  margin: 20px 0px;
`

const Header = styled.h1`
  font-size: 24px;
  margin: 20px 0px;
`

const CreateImmortalRoom = ({ saveFinancingSetting, rarFinancingSetting }) => {
  const onFinish = (values) => {
    saveFinancingSetting({
      rate: parseFloat(values.setting.rate || rarFinancingSetting.data.rate),
      apr: parseFloat(values.setting.apr || rarFinancingSetting.data.apr),
    })
    console.log(values)
  }
  console.log("==rarFinancingSetting.data===>", rarFinancingSetting.data)
  return (
    <FormWrap>
      <Form {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
        <Form.Item
          name={["setting", "rate"]}
          label="Rate"
        >
          {rarFinancingSetting.data && (<Input defaultValue={rarFinancingSetting.data && rarFinancingSetting.data.rate} type="number" />)}

        </Form.Item>
        <Form.Item
          name={["setting", "apr"]}
          label="APR"
        >
          {rarFinancingSetting.data && (<Input defaultValue={rarFinancingSetting.data && rarFinancingSetting.data.apr} type="number" />)}
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 12 }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </FormWrap>
  )
}

class EventRooms extends React.PureComponent {
  state = {
    data: [
      {
        key: "1",
        name: "",
      },
    ],
    currentTab: "mortgage",
  }

  componentDidMount() {
    const { getFinancingClientList, getFinancingSetting, rarFinancingSetting } = this.props
    getFinancingClientList({
      type: "mortgage",
    })
    getFinancingSetting()
    // eslint-disable-next-line no-unused-expressions
    import("../../antd.css")
  }

  handleChange = (key, value, record) => {
    // eslint-disable-next-line no-param-reassign
    record.status = key
    const { updateFinancingClientList } = this.props
    updateFinancingClientList({
      clientId: record.id,
      body: record,
    })
    console.log(`selected ${key}`, value, record)
  }

  showConfirm = (record) => {
    confirm({
      title: "Do you want to DELETE this Application?",
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert that back!",

      onOk: () => {
        this.handleDelete(record)
      },
    })
  }

  handleDelete = (record) => {
    const { deleteClient } = this.props
    const { currentTab } = this.state
    deleteClient({
      clientId: record.id,
      type: currentTab,
    })
  }

  uploadDoc = (file) => {
    const isPdf = file.type === "application/pdf"
    if (!isPdf) {
      message.error(`${file.name} is not a pdf file`)
    }
    return isPdf || Upload.LIST_IGNORE
  }

  uploadPDF = async (file, record) => {
    const { uploadPDFtoS3, updateFinancingClientList } = this.props
    const location = "financing-mortgage"
    console.log(record, "here")
    await uploadPDFtoS3({
      file,
      objName: file.name,
      fileName: file.name,
      bucketName: process.env.NODE_ENV === "staging" ? `s-${location}` : location,
      callback: (url) => {
        updateFinancingClientList({
          clientId: record.id,
          body: {
            ...record,
            pre_approved_letter_url: url,
          },
        })
      },
    })
  }

  columns = [
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      ellipsis: true,
      render: data => (<span style={{ width: "140px" }}>{moment.unix(new Date(data * 1000).getTime() / 1000).format("D MMM, YYYY")}</span>),
    },
    {
      title: "Agent Name",
      dataIndex: "agent_name",
      key: "agent_name",
      width: "10%",
    },
    {
      title: "Agent Email",
      dataIndex: "agent_email",
      key: "agent_email",
      width: "10%",
    },
    {
      title: "Agent Phone",
      dataIndex: "agent_phone",
      key: "agent_phone",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "firstname",
      key: "firstname",
      width: "10%",
      render: (_,record) => (<span style={{ width: "140px" }}>{`${record.firstname || ""} ${record.lastname || ""}`}</span>),
    },
    {
      title: "Interested Property",
      dataIndex: "interested_property",
      key: "interested_property",
      width: "40%",
      render: data => (
        <Address>
          <span>{`Address: - ${data && data.address}`}</span>
          <span>{`City: - ${data && data.city}`}</span>
          <span>
            {`State: - ${data && data.state}`}
          </span>
          <span>{`Selling In (days): - ${data && data.selling_in}`}</span>
          <span>{`Price: - ${data && data.price}`}</span>
        </Address>
      ),
    },
    {
      title: "Listing Property",
      dataIndex: "listing_property",
      key: "listing_property",
      width: "10%",
      render: data => (
        <Address>
          <span>{`Address: - ${data && data.address}`}</span>
          <span>{`City: - ${data && data.city}`}</span>
          <span>
            {`State: - ${data && data.state}`}
          </span>
          <span>{`Selling In (days): - ${data && data.selling_in}`}</span>
          <span>{`Price: - ${data && data.price}`}</span>
        </Address>
      ),
    },
    {
      title: "Emails",
      dataIndex: "emails",
      key: "emails",
      width: "10%",
      render: data => (<span>{data && data.length ? data.map(em => em.email).join(", ") : ""}</span>),
    },
    {
      title: "Phones",
      dataIndex: "phones",
      key: "phones",
      width: "10%",
      render: data => (<span>{data && data.length ? data.map(em => em.phone).join(", ") : ""}</span>),
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      width: "10%",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (text, record, index) => (
        <SelectWrap>
          <Select
            key={index}
            defaultValue={record.status}
            style={{ width: 140 }}
            onChange={(key, value) => this.handleChange(key, value, record)}
          >
            <Option value="new">New</Option>
            <Option value="in progress">In Progress</Option>
            <Option value="resolved">Resolved</Option>
          </Select>
        </SelectWrap>
      )
      ,
    },
    {
      title: "Upload Client Pre-Approval Letter",
      dataIndex: "upload",
      key: "upload",
      width: "10%",
      render: (text, record, index) => {
        if (record.pre_approved_letter_url) {
          return (
            <span>
              Uploaded
            </span>
          )
        }
        return (
          <Upload
            beforeUpload={file => this.uploadDoc(file)}
            onChange={info => console.log(info)}
            action={file => this.uploadPDF(file, record)}
          >
            <Button>Upload</Button>
          </Upload>
        )
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (text, record, index) => {
        console.log("==text, record, index======>> 12321211111", text, record, index)
        return (
          <Button danger onClick={() => this.showConfirm(record)}>Delete</Button>
        )
      },
    },
  ]

  getTotal = rarFinancingClientList => rarFinancingClientList && rarFinancingClientList.data && rarFinancingClientList.data.total

  tabChange = (tab) => {
    console.log("event==", tab)
    const { getFinancingClientList } = this.props
    getFinancingClientList({
      type: tab,
    })
    this.setState({
      currentTab: tab,
    })
  }

  render() {
    const { data } = this.state
    const {
      rooms, saveFinancingSetting, rarFinancingClientList, rarFinancingSetting, clientDeleted,
    } = this.props
    console.log("===rarFinancingClientList >", this.props, rarFinancingClientList)
    console.log("===rarFinancingSetting===>", rarFinancingSetting)
    return (
      <Wrap>

        <Tabs onChange={this.tabChange}>
          <TabPane tab="Mortgage" key="mortgage">
            {/* <Header>
              Mortgage Rates and Service Information
            </Header> */}
            {/* <CreateImmortalRoom saveFinancingSetting={saveFinancingSetting} rarFinancingSetting={rarFinancingSetting} /> */}
            <Header>
              Mortgage Client Applications
            </Header>
            {(rarFinancingClientList.isFetching || clientDeleted.isFetching) ? (<Loader top={10} bottom={0} />) : (
              <Table
                tableLayout="auto"
                scroll={{ x: 540 }}
                columns={this.columns}
                dataSource={rarFinancingClientList && rarFinancingClientList.data && rarFinancingClientList.data.clients}
                pagination={{ total: this.getTotal(), defaultPageSize: 20 }}
              />

            )}
          </TabPane>
          <TabPane tab="Cash Offers" key="cash_offer">
            <Header>
              Cash Offers Client Applications
            </Header>
            {(rarFinancingClientList.isFetching || clientDeleted.isFetching) ? (<Loader top={10} bottom={0} />) : (
              <Table
                scroll={{ x: 540 }}
                tableLayout="auto"
                columns={this.columns}
                dataSource={rarFinancingClientList && rarFinancingClientList.data && rarFinancingClientList.data.clients}
                pagination={{ total: this.getTotal(), defaultPageSize: 20 }}
              />
            )}
          </TabPane>
          <TabPane tab="Buy Before Sell" key="buy_before_you_sell">
            <Header>
              Buy Before Sell Client Applications
            </Header>
            {(rarFinancingClientList.isFetching || clientDeleted.isFetching) ? (<Loader top={10} bottom={0} />) : (
              <Table
                scroll={{ x: 540 }}
                tableLayout="auto"
                columns={this.columns}
                dataSource={rarFinancingClientList && rarFinancingClientList.data && rarFinancingClientList.data.clients}
                pagination={{ total: this.getTotal(), defaultPageSize: 20 }}
              />
            )}
          </TabPane>
          <TabPane tab="PreSale" key="presale_home_prep">
            <Header>
              PreSale Client Applications
            </Header>
            {(rarFinancingClientList.isFetching || clientDeleted.isFetching) ? (<Loader top={10} bottom={0} />) : (
              <Table
                scroll={{ x: 540 }}
                tableLayout="auto"
                columns={this.columns}
                dataSource={rarFinancingClientList && rarFinancingClientList.data && rarFinancingClientList.data.clients}
                pagination={{ total: this.getTotal(), defaultPageSize: 20 }}
              />
            )}
          </TabPane>
        </Tabs>
      </Wrap>
    )
  }
}

export default TechAssets(Container(EventRooms))
