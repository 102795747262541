import US_STATES from "services/US_STATES.json"

export const STATE = Object.keys(US_STATES).map(key => ({ label: US_STATES[key], value: US_STATES[key] }))

export const MODIFIED_US_STATE = [ { value: "all-states", label: "All States"}, ...STATE]

export const DOC_CATEGORY = [
  { label: "Transaction Resources" , value: 1 },
  { label: "Agreements" , value: 11 },
  { label: "Marketing Resources" , value: 15 },
]

export const DOC_TYPE = [
  { label: "General" , value: 2 },
  { label: "Brokerage Disclosures" , value: 3 },
  { label: "Leasing Resources" , value: 4 },
  { label: "Additional Training" , value: 5 },
  { label: "Miscellaneous" , value: 7 },
  { label: "Listings" , value: 8 },
  { label: "Purchases" , value: 9 },
  { label: "Leases" , value: 10 },
  { label: "ICA (Agent Specific)" , value: 12 },
  { label: "Mentorship" , value: 13 },
  { label: "Regus" , value: 16 },
  { label: "Mentorship" , value: 17 },
  { label: "Aspire" , value: 18 },
  { label: "Radius Edge" , value: 19 },
  { label: "Processes" , value: 20 },
  { label: "MLS and Association" , value: 21 },
]

export const PAST_BROKERAGE_NAME_OPTIONS = [
  {
    label: "Independent Boutique Firm",
    value: "Independent Boutique Firm",
  },
  {
    label: "Keller Williams",
    value: "Keller Williams",
  },
  {
    label: "Side",
    value: "Side",
  },
  {
    label: "Berkshire Hathaway",
    value: "Berkshire Hathaway",
  },
  {
    label: "Remax",
    value: "Remax",
  },
  {
    label: "eXp",
    value: "eXp",
  },
  {
    label: "Coldwell Banker",
    value: "Coldwell Banker",
  },
  {
    label: "Century 21",
    value: "Century 21",
  },
  {
    label: "Compass",
    value: "Compass",
  },
  {
    label: "Douglas Elliman",
    value: "second_brokerage",
  },
  {
    label: "Corcoran",
    value: "Corcoran",
  },
  {
    label: "Other",
    value: "Other",
  },
]

export const PRODUCTION_TIER_OPTIONS = [
  {
    label: "Tier 1 - 20+",
    value: "tier_1",
  },
  {
    label: "Tier 2 - 10+",
    value: "tier_2",
  },
  {
    label: "Tier 3 - 5+",
    value: "tier_3",
  },
  {
    label: "Tier 4 - 0-4",
    value: "tier_4",
  },
]

export const SALES_REPRESENTATIVE = [
  {
    label: "Amanda Sheddy",
    value: "Amanda Sheddy",
  },
  {
    label: "Jaime Dupont",
    value: "Jaime Dupont",
  },
  {
    label: "Wendy Davis",
    value: "Wendy Davis",
  },
  {
    label: "Kristi Wiles",
    value: "Kristi Wiles",
  },
  {
    label: "Rhonda Lawson",
    value: "Rhonda Lawson",
  },
  {
    label: "Julie Rose",
    value: "Julie Rose",
  },
  {
    label: "Tiffany Klem",
    value: "Tiffany Klem",
  },
  {
    label: "Legacy",
    value: "Legacy",
  },
  {
    label: "Justin Rush",
    value: "Justin Rush",
  },
  { label: "Gabrielle Mazza",
    value: "Gabrielle Mazza",
  },
]

export const DOC_SUBCATEGORY = [{
  label: "CheckLists", value: 6,
}]