import React from "react"
import styled from "styled-components"
import Button from "./../Components/RoundButton"
import Para from "./../Components/Para"
import DetailCell from "./../Components/DetailCell"
import { PLACE_HOLDER_IMAGE } from "./../ImagesData"


const Wrap = styled.div`
  
`

const AssistEmailQualified = (props) => (
    <Wrap
        style={{
            background: "#ffffff",
            textAlign: "left",
            padding: "10px 50px",
            marginBottom: 56,
            paddingBottom: 56,
        }}
    >
        <h2
            style={{
                fontSize: 18,
                fontWeight: 400,
                lineHeight: "23.44px",
                color: '#303030'
            }}
        >
            Hey {`<Agent Name>!`}
        </h2>
        <p
            style={{
                fontWeight: 200,
                fontSize: 16,
                lineHeight: '20px',
                marginTop: 32,
                marginBottom: 28,
            }}
        >
            Congratulations! We have qualified the lead you submitted. Here are the details:
    </p>


        <DetailCell
            header="BUDGET"
            para="$1,010,000"
        />
        <DetailCell
            header="LOCATION"
            para="Manhattan, New York, New York"
        />
        <DetailCell
            header="PRE-APPROVED"
            para="Yes"
        />
        <DetailCell
            header="TIMELINE"
            para="ASAP"
        />
        <DetailCell
            header="SOURCE"
            para="Facebook"
        />
        <DetailCell
            header="ADDITIONAL DETAILS"
            para="The client is interested in below 70 street manhattan preferably below 34th. They interested in high floors and modern interior. Maintainance no more than $3500. They have the down payment and are prequalified"
        />
        <DetailCell
            header="BEDROOMS"
            para="Did not disclose"
        />
        <DetailCell
            header="BATHROOMS"
            para="Did not disclose"
        />
        <DetailCell
            header="IS WORKING WITH AN AGENT"
            para="No"
        />
        <DetailCell
            header="IS LOOKING FOR A LENDER"
            para="No"
        />
        <Button
            style={{
                background: "#0B8DEC",
                width: "100%",
                margin: '10px 0px',
                fontSize: 16,
            }}
        >
            Accept Lead
    </Button>
        <Para style={{
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 16,
            lineHeight: "20px"
        }}>
            Thank You,
            <br/>
            Radius Concierge Team
        </Para>
    </Wrap>
)

export default AssistEmailQualified