import React, { useState } from "react"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import {
  Modal,
} from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
  WhiteButton,
} from "../../commonStyles"
import ReasonConfirmationModal from "../../ReasonConfirmModal"

const { confirm } = Modal

const VoidAndDelete = ({
  disabled,
  toggleVoidAndDeleteModal,
}) => (
  <WhiteButton
    onClick={() => {
      toggleVoidAndDeleteModal(true)
    }}
    ATCFONT
    disabled={disabled}
    borderRadius="33.5px"
  >
    Void & Delete
  </WhiteButton>
)

export default subscriptionContainer(VoidAndDelete)

