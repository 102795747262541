import React, { useState } from "react"
import styled from "styled-components"
import Button from "dumbComponents/common/UI/Button"
import CircularImage from "dumbComponents/common/UI/CircularImage"
import AgentWithStar from "components/UI/AgentWithStar"
import ConfirmModal from "dumbComponents/UI/ConfirmModal"
import { getProspectStatus, getPropectsLabel } from "./Utils"

const Wrap = styled.div`
`

const UserProfile = styled.div`
  
`

const NameWrap = styled.div`
  display: flex;
  align-items: center;
  > span {
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    position: relative;
    display: block;
  }
`
const DetailsWrap = styled.div`
  display: flex;
  align-items: center;
  > div {
    &:nth-child(2) {
      padding-left: 20px;
      flex: 1;
      > p {
        font-size: 12px;
        color: #777;
        margin: 0px;
      }
    }
  }
`


const DropdownListWrap = styled.ul`
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 30px;
  right: 0px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 200px;
  li {
    padding: 12px;
    color: #333;
    border-bottom: 1px solid #ccc;
    list-style: none;
    background: #fff;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    &:nth-child(1) {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &.danger {
      color: #e74c3c;
    }
  }
`

const ButtonWrap = styled.div`
  display: flex;
  margin-top: 14px;
  justify-content: flex-start;
  gap: 10px;

  > a {
    font-size: 12px;
    margin-top: 7px;
  }
`
const AgentInfo = ({
  assignAgent,
  liveTransfer,
  removeAgentFromSuggestions,
  firstname,
  id,
  agent_id,
  starred_for_lmp,
  lastname,
  agent_image,
  phone,
  email,
  schedule_meeting_calendly_link_blink,
  isSeachModal,
  inviteAgent,
  prospect_status,
  isAllowedToConnect,
  agreement_signed_date,
  toggleStarAgent,
}) => {
  const [isOpenDropDown, setIsOpenDropdown] = useState(false)
  const [isShowRemoveSuggestion, setIsShowRemoveSuggestion ] = useState(false)
  const labelText = getPropectsLabel(prospect_status)
  const ctaText = getProspectStatus(prospect_status)
  return (
    <Wrap>
      <ConfirmModal
        callback={() => {
          removeAgentFromSuggestions(id)
          setIsShowRemoveSuggestion(false)
        }}
        cancel={() => { setIsShowRemoveSuggestion(false) }}
        show={isShowRemoveSuggestion}
        message="Are you sure?"
      />
      <NameWrap>
        <AgentWithStar
          firstname={firstname}
          lastname={lastname}
          isStared={starred_for_lmp}
          onClick={() => { toggleStarAgent(agent_id, starred_for_lmp, isSeachModal) }}
          onClickAgentName={() => { window.open(`http://soul.radiusagents.com/broker/dashboard/${agent_id}/subscription`) }}
          textStyle={{
            fontSize: "22px",
          }}
        />
        {!isSeachModal && (
          <span>
            <i
              className={isOpenDropDown ? "fa fa-times" : "fa fa-ellipsis-v" }
              aria-hidden="true"
              onClick={() => { setIsOpenDropdown(!isOpenDropDown) }}
            >
            </i>
            {isOpenDropDown && (
              <DropdownListWrap>
                <li onClick={() => setIsShowRemoveSuggestion(true)}>Remove from Suggestions</li>
                {/* <li className="danger">Remove Permentally </li> */}
              </DropdownListWrap>
            )}
          </span>
        )}
      </NameWrap>
      <DetailsWrap>
        <CircularImage
          name={`${firstname} ${lastname}`}
          image={agent_image}
          size={45}
        />
        <div>
          <p>{email}</p>
          <p>{phone}</p>
          {labelText}
        </div>
      </DetailsWrap>
      <ButtonWrap>
        {(
          prospect_status === "assigned" // Reccomened
          || prospect_status === 1
        ) && (
          <Button rounded danger onClick={() => { assignAgent(agent_id, true) }}>
            Unassign
          </Button>
        )}

        {(
          prospect_status === -1 // Reccomened
          || prospect_status === null // Reccomened
        ) && (
          <Button rounded blue onClick={() => { inviteAgent(agent_id, !isSeachModal); }}>
            Invite
          </Button>
        )}
        {(
          prospect_status === -1 // Reccomened
          || prospect_status === 0 // Invite sent
        ) && (
          <Button rounded blue onClick={() => { assignAgent(agent_id) }}>
            Assign
          </Button>
        )}
        {(labelText === "Invite Sent") && (
          <Button rounded blue onClick={() => { assignAgent(agent_id) }}>
            Assign
          </Button>
        )}

        {/* {isSeachModal ? (
          <Button rounded blue onClick={() => { inviteAgent(agent_id) }}>
            Invite
          </Button>
        ) : (
          <Button rounded blue onClick={() => { assignAgent(agent_id) }}>
            {ctaText}
          </Button>
        )} */}
        {isSeachModal ? (
          <Button rounded green onClick={() => { liveTransfer(agent_id) }}>
            Invite & <i className="fa fa-phone"></i> Connect
          </Button>
        ) : (
          <Button disabled={!isAllowedToConnect} rounded green onClick={() => { liveTransfer(agent_id) }}>
            <i className="fa fa-phone"></i> Connect
          </Button>
        )}
        
        {schedule_meeting_calendly_link_blink ? (
          <a onClick={() => { window.open(schedule_meeting_calendly_link_blink) }} href="#">Calendly</a>
        ) : (
          <a target="_blank" on href={`http://soul.radiusagents.com/broker/dashboard/${agent_id}/edit-agent-info`}>+ Calendly</a>
        )}
      </ButtonWrap>
    </Wrap>
  )
}

export default AgentInfo
