import React, { Component } from "react"
import fetch from 'isomorphic-fetch'
import bugsnag from 'bugsnag-js'
import root, { parseFloat } from "window-or-global"
import moment from 'moment';
import {
	Route, Switch, Redirect, Link
} from "react-router-dom"
import _, { parseInt, get } from 'lodash';
import Notifications, { notify } from 'react-notify-toast';
import { console } from "window-or-global";
import { ThumbsdownIcon } from "@primer/octicons-react";
const baseUrl = process.env.REACT_APP_API_BASE_URL
const token = process.env.REACT_APP_SECRET_ID
const containerStyle = {"backround" : "#7a7a8c0a" }
const marginTop={"margin-top" : "50px"};
const originalKey = process.env.REACT_APP_BUGSNAG_KEY
const bugsnagClient = bugsnag(originalKey)


const cancelDealButton = {
  "float" : "left",
  "margin-right" : "50px",
  "margin-right": "50px",
  "border-radius": "30px",
  "background": "grey",
  "color": "white",
  "font-weight": "100",
}
const saveDealButton = {
  "float" : "left",
  "margin-right" : "50px",
  "margin-right": "50px",
  "border-radius": "30px",
  "background": "green",
  "color": "white",
  "font-weight": "100",
}

const deleteDealButton = {
  "float" : "left",
  "margin-right" : "50px",
  "margin-right": "50px",
  "border-radius": "30px",
  "background": "red",
  "color": "white",
  "font-weight": "100",
}
const dealForm = {
  "width" : "50%",
  "height" : "40px"
}
const dealsStyle = {
  
  "background-color": "white",
  "overflow-y": "scroll",
  "margin-left" : "50px",
 // "margin-top" : "50px",
  "margin-right" : "50px"
};
const floatFields = ["price", "radius_transaction_fee", "gross_commission", "commision_percentage"];
const editKeys = {
  "created_at" : "Closing Date",
  "listing_address": "Listing Address",
  "city": "City",
  "state" : "State",
  "price"  : "Price",
  "commision_percentage" : "Commision Percentage",
  "source" : "Source",
  "gross_commission" : "Gross Commission",
  "radius_transaction_fee" : "Radius Transaction Fee",
  "client_name" : "Client Name",
  "client_email" : "Client Email",
  "transaction_type" : "Transaction Type",
  "client_type" : "Client Type",
  "is_radius_lead" : "Is Radius Lead"
}; 

const DEFAULT_DEAL = {
  "created_at" : Math.floor(Date.now() / 1000)  ,
  "listing_address": null,
  "city": null,
  "state" : null,
  "price"  : null,
  "commision_percentage" : null,
  "source" : "Opcity",
  "gross_commission" : null,
  "radius_transaction_fee" : null,
  "client_name" : null,
  "client_email" : null,
  "transaction_type" : 'Buy',
  "client_type" : 'Buyer',
  "is_radius_lead" : 0,
}

class Deals extends Component {
  state = {
   agentId: null,
   dealId: null, 
   deal: null,
   apiCallActive: false,
   view:'create',
  
  };




 

  loadDeal = async (dealId, agentId) => {
    try {
      let resp = await fetch(`${baseUrl}/v1.0/brokerage-deals/agent/${agentId}?deal_id=${dealId}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token
        },
      });

      console.log(`${baseUrl}/v1.0/brokerage-deals/agent/${agentId}?deal_id=${dealId}`)
      resp = await resp.json();
      console.log(resp)

      let deal =  resp.response[0];
      console.log(deal);
      this.setState({deal})
    }
    catch(e){
      console.log(e);
      //bugsnagClient.notify(e);
    }

  };

  createDefaultDeal = () => {
    let deal = DEFAULT_DEAL;
    this.setState({deal})
  }
 
  componentDidMount() {

    const { pathname } = root.location || ""
    if (pathname.indexOf("create") !== -1){
        const agentId = parseInt(pathname.split("/")[3], 10)
        console.log("agent id is " + agentId);
        this.setState({agentId, view : 'create'})
        this.createDefaultDeal();


    }
    else{
      const dealId = parseInt(pathname.split("/")[5], 10)
      const agentId = parseInt(pathname.split("/")[3], 10)
      this.setState({dealId , agentId,view : 'edit' })
      this.loadDeal(dealId, agentId);

    }
 
   }

   changeDealProperty = (value, key) => {
     let deal = this.state.deal;
     deal[key] = value;
     this.setState({deal})
   }



   getKeyDiv = (key) => {
    if (key === "created_at"){


      console.log("heree bro" + this.state.deal["created_at"]);
      console.log(this.state.deal["created_at"])
      return  <div><div className="deal-text" >
          <div className="label-text">{editKeys[key]}<span class="required-asterik">*</span></div>
          <br/>
          <input style={dealForm} value={moment.unix(this.state.deal[key]).format("YYYY-MM-DD")} type="date"  onChange={(e) => this.changeDealProperty(moment(e.target.value).unix(), key)} />
        </div>  
        <br/>
      </div> ;
    }

    if (key === "is_radius_lead"){
      return  <div><div className="deal-text" >
          <div className="label-text">{editKeys[key]}<span class="required-asterik">*</span></div>
          <br/>
          <select value ={this.state.deal[key]} onChange={(e) => this.changeDealProperty(parseInt(e.target.value, 10), key)} >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
      </div>  
      <br/>
      </div> 

    }

    if (key === "client_type"){
      return  <div><div className="deal-text" >
      <div className="label-text">{editKeys[key]}<span class="required-asterik">*</span></div>
      <br/>
      <select value ={this.state.deal[key]} onChange={(e) => this.changeDealProperty(e.target.value, key)} >
        <option value="Buyer">Buyer</option>
        <option value="Seller">Seller</option>
      </select>
      </div>  
      <br/>
      </div> 

    }

    if (key === "transaction_type"){
      return  <div><div className="deal-text" >
      <div className="label-text">{editKeys[key]}<span class="required-asterik">*</span></div>
      <br/>
      <select value ={this.state.deal[key]} onChange={(e) => this.changeDealProperty(e.target.value, key)} >
        <option value="Buy">Buy</option>
        <option value="Sell">Sell</option>
      </select>
      </div>  
      <br/>
      </div> 
    }

    if (key === "source"){
      return  <div><div className="deal-text" >
      <div className="label-text">{editKeys[key]}<span class="required-asterik">*</span></div>
      <br/>


      <select value ={this.state.deal[key]} onChange={(e) => this.changeDealProperty(e.target.value, key)} >
        <option value="Opcity">Opcity</option>
        <option value="Radius">Radius</option>
        <option value="SOI">SOI</option>
        <option value="Zbuyer">Zbuyer</option>
        <option value="Zillow/Trulia">Zillow/Trulia</option>
      </select>
      </div>  
      <br/>
      </div> 
    }

     return  <div><div className="deal-text" >
        <div className="label-text">{editKeys[key]}<span class="required-asterik">*</span></div>
        <br/>
        <input style={dealForm} value={this.state.deal[key]} onChange={(e) => this.changeDealProperty(e.target.value, key)}/>
    </div>  
    <br/>
    </div> 
   }

   deleteDeal = () => {
     console.log("deleting deal");

     try{
      this.setState({apiCallActive: true})

      fetch(baseUrl + `/v1.0/brokerage-deals/${this.state.dealId}`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
          'X-User-Agent': 'agent-php',
        },
      });



      this.setState({apiCallActive: false})
      window.history.back();
    } 
    catch(e){
      notify.show('Something went wrong, please try again', 'error', 5000);

      this.setState({apiCallActive: false})
      console.log(e);
    }
    
     
   }

   createDeal = async () => {
    try{
      console.log("creating deal");
      this.setState({apiCallActive: true})

      let deal = this.state.deal;
    
      deal.agent_id = this.state.agentId;
      let keys = Object.keys(deal);

      let invalid = false;
      keys.forEach( (key) => {
        if (deal[key] === null){
          invalid= true;
          this.setState({apiCallActive: false})

          notify.show('Please make sure you fill all the required fields -> ' + editKeys[key] + ' is not filled correctly!', 'error', 5000);
          return;

        }
        if (floatFields.indexOf(key) !== -1){
          console.log(deal[key]);
          deal[key] = parseFloat(deal[key]);
        }
      })

      if (invalid){
        return;
      }



      let resp = await fetch(baseUrl + `/v1.0/brokerage-deals`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
          'X-User-Agent': 'agent-php',
        },
        body: JSON.stringify(deal)
      });

      console.log({ deal });
      resp = await resp.json();
      if (resp.error && resp.error.message){
        throw resp.error.message;    // throw a text
      }
      this.setState({apiCallActive: false})
      notify.show('Deal Added Successfully', 'success', 5000);
      //window.location.assign(`/deals/agent/${this.state.agentId}`)
    }

    catch(e){
      notify.show('Something went wrong, please try again', 'error', 5000);

      this.setState({apiCallActive: false})
      console.log(e);
    }

   }

   saveDeal = async () => {
    try{
      console.log("saving deal");
      this.setState({apiCallActive: true})

      let deal = this.state.deal;
      delete deal.id;
      delete deal.row_created_at;
      delete deal.row_updated_at;
      let invalid = false; 
      let keys = Object.keys(deal);
      keys.forEach( (key) => {
        if (floatFields.indexOf(key) !== -1){
          console.log(deal[key]);

          if (deal[key] === null){
            invalid= true;
            this.setState({apiCallActive: false})
            notify.show('Please make sure you fill all the required fields -> ' + editKeys[key] + ' is not filled correctly!', 'error', 5000);
            return;
  
          }

          deal[key] = parseFloat(deal[key]);
        }
      })

      if (invalid){
        return;
      }

      await fetch(baseUrl + `/v1.0/brokerage-deals/${this.state.dealId}`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token,
          'X-User-Agent': 'agent-php',
        },
        body: JSON.stringify(deal)
      });

      this.setState({apiCallActive: false})
      notify.show('Deal Edited Successfully', 'success', 5000);



    } 
    catch(e){
      notify.show('Something went wrong, please try again', 'failure', 5000);

      this.setState({apiCallActive: false})
      console.log(e);
    }
    


  }

   getEditView = () => {
    if(!this.state.deal){
      return null;
    }
     const keys = Object.keys(editKeys);
     const keysDiv = keys.map((key) => {
        return this.getKeyDiv(key);
     });

     return <div style={dealsStyle}>
       {keysDiv}   

        <a href={"/deals/agent/" +  this.state.agentId}><button style={cancelDealButton}>Cancel</button></a>
        <button disabled={this.state.apiCallActive} style={saveDealButton} onClick={(e) => { if (window.confirm('Are you sure you wish to edit this deal?')) this.saveDeal() } } >Save</button>              

        <button disabled={this.state.apiCallActive} style={deleteDealButton} onClick={(e) => { if (window.confirm('Are you sure you wish to delete this deal?')) this.deleteDeal() } } >Delete</button>              
    </div>
   }

   getCreateView = () => {
    if(!this.state.deal){
       return null;
     }
    const keys = Object.keys(editKeys);
    const keysDiv = keys.map((key) => {
       return this.getKeyDiv(key);
    });

    return <div style={dealsStyle}>
      {keysDiv}   

       <a  href={"/deals/agent/" +  this.state.agentId}><button style={cancelDealButton}>Cancel</button></a>
       <button disabled={this.state.apiCallActive} style={saveDealButton} onClick={(e) => { if (window.confirm('Are you sure you wish to create this deal?')) this.createDeal() } } >Save</button>              

   </div>  }

   
  render() {

       const dealDiv = this.state.view === "edit" ? this.getEditView() : this.getCreateView(); 
    
        
    
        return <div style = {containerStyle}> 
          <Notifications />

          <div style={marginTop}>
                {dealDiv}
          </div> 


        </div>

    }

}
export default Deals;
