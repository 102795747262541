import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as SettingsAction from "./actions"


const initState = {
  ...createDeltaReducer("multiCallerStateResponse"),
  ...createDeltaReducer("changedMultiCallerResponse"),
}

export default function settingsReducer(state = initState, action) {
  switch (action.type) {
    case SettingsAction.getMultiCallerStateAction.REQUEST:
    case SettingsAction.getMultiCallerStateAction.SUCCESS:
    case SettingsAction.getMultiCallerStateAction.CLEAR:
    case SettingsAction.getMultiCallerStateAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsAction.getMultiCallerStateAction, "multiCallerStateResponse"),
      }
    }

    case SettingsAction.changeMultiCallerStateActions.REQUEST:
    case SettingsAction.changeMultiCallerStateActions.SUCCESS:
    case SettingsAction.changeMultiCallerStateActions.CLEAR:
    case SettingsAction.changeMultiCallerStateActions.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsAction.changeMultiCallerStateActions, "changedMultiCallerResponse"),
      }
    }

    default: {
      return state
    }
  }
}
