import { Button } from "antd"
import React, { useEffect, useState } from "react"
import { EditOutlined } from '@ant-design/icons';
import styled from "styled-components"

const Wrap = styled.div`
  > h2,
  > p {
    color: #303030;
  }
  > p {
    margin-bottom: 40px;
  }
`

const RateWrap = styled.div`
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 10px;
 
`

const Rate = styled.div`
  border: 1px solid #EE8B31;
  background: #fff;
  width: 32%;
  transition-duration: 0.3s;
  &:hover {
    box-shadow: rgb(0 0 0 / 25%) 0px 1px 2.5px 0px;
    transform: scale(1.02);
  }
  
  text-align: center;
  > header {
    border-bottom: 1px solid #EE8B31;
    padding: 20px 16px;
    font-size: 18px;
    
  }

  > main {
    padding: 10px 16px;
    > p {
      font-size: 16px;
      font-weight: 700;
      margin: 8px;
      &:nth-child(1) {
        color: #EE8B31;
      }
    }
    > h6 {
      font-family: 'ATC Arquette';
      color: #303030;
      font-size: 14px;
      font-weight: 400;
      margin: 20px 10px;
      
    }
  }
  > footer {
    padding: 10px;
  }
`

const TodaysRate = ({
  onEdit,
  rateList,
}) => (
  <Wrap>
    <RateWrap>
      {rateList && rateList.length > 0 && rateList.map(rate => (
        <Rate key={rate.id} onClick={() => { onEdit(rate) }}>
          <header>
            {rate.title}
          </header>
          <main>
            <p>
              {`${rate.rate}% rate`}
            </p>
            <p>
              {`${rate.apr}% APR`}
            </p>
            <h6>
              {rate.time_period}
            </h6>
          </main>
          <footer>
            <Button icon={<EditOutlined />}>
              Edit
            </Button>
          </footer>
        </Rate>
      ))}
    </RateWrap>
    <br />
    <br />
    
  </Wrap>
)

export default TodaysRate
