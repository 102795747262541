import React, { PureComponent } from "react"
import styled from "styled-components"
import Loader from "dumbComponents/common/UI/Loader"
import { get, zip } from "lodash"
import ListingList from "dumbComponents/Listings/components/ListingList"
import TechAssetsContainer from "container/BrokerDashboard/globalPagesContainer"
import PlusIcon from "images/icons/WhiteIconPlus.png"
// import Loader from "@ui/Loader"
import {
  Input, InputNumber, Select, Form, Checkbox,
  Divider, Button, GoogleAutoComplete, Radio,
} from "dumbComponents/common/UI/antd"
import Sidebar from "dumbComponents/Listings/components/Sidebar"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
import US_STATES from "shared/US_STATES.json"
import SizeInputGroup from "../../components/sizeInputGroup"
import SelectField from "../../components/SelectField"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const HeaderNav = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
`

const ProgressBarContainer = styled.div`
  padding: 30px 0px;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #3B60E4;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LabelText = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
`

const Required = styled.span`
  color: #E34C4C
`

const CheckboxLabelText = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  text-transform: uppercase;
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const CheckboxContainer = styled.div`
  padding: 0 20px 0 0;
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
`
const STATE_OPTIONS = Object.keys(US_STATES).map(key => ({ label: US_STATES[key], value: US_STATES[key] }))

const TRANSACTION_TYPE = [
  {
    name: "Standard Residential",
    value: "residential_1_4",
  },
  {
    name: "Residential Income Property",
    value: "residential_inc",
  },
  {
    name: "Vacant Land",
    value: "vacant_land",
  },
  {
    name: "Manufactured/Mobile Home",
    value: "manufactured_mo",
  },
  {
    name: "New Construction",
    value: "new_construction",
  },
  {
    name: "Lease",
    value: "lease",
  },
  {
    name: "Short Sales",
    value: "short_sales",
  },
  {
    name: "Relocation",
    value: "relocation",
  },
  {
    name: "Referral Agreement",
    value: "referral_agreem",
  },
  {
    name: "Other",
    value: "other",
  },
]

const PROPERTY_MEASURED_AS = [{
  label: "Sq.ft",
  value: "Sq.ft",
}, {
  label: "Sq.mt",
  value: "Sq.mt",
}, {
  label: "Acres",
  value: "Acres",
}]

const DEFAULT_TRANSACTION_TYPE = [{
  label: "Standard Residential",
  value: "residential_1_4",
}, {
  label: "Residential Income Property",
  value: "residential_inc",
}, {
  label: "Vacant Land",
  value: "vacant_land",
}, {
  label: "Manufactured/Mobile Home",
  value: "manufactured_mo",
}, {
  label: "New Construction",
  value: "new_construction",
}, {
  label: "Lease",
  value: "lease",
}]

const STATEWISE_TRANSACTION_TYPE = {
  Texas: [{
    value: "condominium",
    name: "Condominium",
  },
  {
    value: "farm_and_ranch",
    name: "Farm and Ranch",
  },
  {
    value: "commercial",
    name: "Commercial",
  }],
  Washington: [{
    value: "condominium",
    name: "Condominium",
  }],
  Georgia: [{
    value: "commercial",
    name: "Commercial",
  }],
  // Oregon: [{
  //   value: "new_construction",
  //   name: "New Construction",
  // }],
}

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  state = {
    cityFormValue: "",
    transactionType: [],
    isTransactionTypeOther: null,
  }

  formRef = React.createRef();

  setLocaCity = (value) => {
    this.setState({
      cityFormValue: value,
    })
  }

  setCities = (location) => {
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    this.formRef.current.setFieldsValue({
      city: `${locationDetails.city || ""}`,
      state: `${locationDetails.state || ""}`,
    })
    // this.setState({
    //   cityFormValue: `${locationDetails.city}`,
    // })
  }

  onStateChange = (value, setEmptyListingType) => {
    if (STATEWISE_TRANSACTION_TYPE[value]) {
      const newTransactionType = [...STATEWISE_TRANSACTION_TYPE[value], ...TRANSACTION_TYPE]
      this.setState({
        transactionType: [...newTransactionType],
      })
    } else {
      this.setState({
        transactionType: [...TRANSACTION_TYPE],
      })
    }
    if (setEmptyListingType) {
      this.formRef.current.setFieldsValue({
        transaction_type: "",
        other_transaction_type: "",
      })
      this.setState({
        isTransactionTypeOther: false,
      })
    }
  }

  setTransactionType = (value) => {
    if (value === "other") {
      this.setState({
        isTransactionTypeOther: true,
      })
    } else {
      this.setState({
        isTransactionTypeOther: false,
      })
    }
  }

  onFinish = (fieldValues, isDraft) => {
    const { updateListingCoverSheet, history, dealToView, dealAgentId } = this.props
    const { cityFormValue } = this.state
    Object.keys(fieldValues).forEach((key) => {
      if (fieldValues[key] === undefined) {
        // eslint-disable-next-line no-param-reassign
        fieldValues[key] = null
      }
    })
    const payload = {
      ...fieldValues,
    }
    updateListingCoverSheet({
      payload: {
        ...payload,
        other_transaction_type: fieldValues.other_transaction_type || "",
        current_listing_step: "property_information_update",
        draft: isDraft ? "Y" : "N",
      },
      dealAgentId,
      dealId: dealToView,
      // callAPI: ["agent_info", "seller_info", "source_time", "property_info", "tc_info", "select_vendor"],
      // //nextRoute
      // nextRoute: isDraft ? "/broker/tc/add-listings" : "/broker/tc/listings",
      callAPI: ["agent_info", "seller_info", "source_time","property_info"],
      nextRoute: isDraft ? "/broker/tc/add-listings" : "/broker/tc/add-listings/coversheet/listing-details",
      history,
    })
  }

  onSaveAsDraft = (values) => {
    console.log("===this.formRef.current.getFieldsValue===", this.formRef.current.getFieldsValue())
    this.onFinish(this.formRef.current.getFieldsValue(), true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  getInitialValues = () => {
    const { getListingCoverSheetResponse, setListingState, listingState } = this.props
    const { isTransactionTypeOther, transactionType } = this.state
    const propertyInfo = getListingCoverSheetResponse.data?.propertyInfo
    const { cityFormValue } = this.state
    const result = {}
    if (propertyInfo) {
      if (!cityFormValue) {
        this.setState({
          cityFormValue: propertyInfo.city,
        })
      }
      if (isTransactionTypeOther === null && propertyInfo.other_transaction_type && propertyInfo.transaction_type === "other") {
        this.setTransactionType(propertyInfo.transaction_type)
      }
      // this.onStateChange(propertyInfo.state)
      if (!transactionType.length) {
        this.onStateChange(propertyInfo?.state)
      }
      if (propertyInfo.state !== null) {
        setListingState(propertyInfo.state)
      }
      return {
        ...propertyInfo,
      }
    }
    if (!transactionType.length) {
      this.onStateChange(propertyInfo?.state)
    }
    return result
  }

  setStreet = (location) => {
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
      county: get(location, "administrative_area_level_2.long_name"),
    }
    let neighborhood = ""
    let zipcode = ""
    let county = ""
    if (location.street_number) {
      neighborhood += `${location.street_number.long_name} `
    }
    if (location.route) {
      neighborhood += location.route.long_name
    }
    if (location.postal_code) {
      zipcode = location.postal_code.long_name
    }
    if (locationDetails.county) {
      locationDetails.county = locationDetails.county.split(" County")?.[0]
      county = locationDetails.county
    }
    this.formRef.current.setFieldsValue({
      city: `${locationDetails.city || ""}`,
      state: `${locationDetails.state || ""}`,
      country: `${locationDetails.country}`,
      zipcode,
      county,
    })

    if (locationDetails.state) {
      const { setListingState, getListingType } = this.props
      setListingState(locationDetails.state)
      getListingType({state:locationDetails.state})
    }

    if (neighborhood) {
      this.formRef.current.setFieldsValue({
        street: neighborhood || "",
      })
    }
    if (STATEWISE_TRANSACTION_TYPE[locationDetails.state]) {
      const newTransactionType = [...STATEWISE_TRANSACTION_TYPE[locationDetails.state], ...TRANSACTION_TYPE]
      this.setState({
        transactionType: [...newTransactionType],
      })
    } else {
      this.setState({
        transactionType: [...TRANSACTION_TYPE],
      })
    }
    this.formRef.current.setFieldsValue({
      transaction_type: "",
      other_transaction_type: "",
    })
    this.setState({
      isTransactionTypeOther: false,
    })
  }

  setPropertyState = (e) => {
    const { setListingState, getListingType } = this.props
    setListingState(e.target.value)
    getListingType({state:e.target.value})
  }

  getTransactionTypes = () => {
    const { listingState, getListingTypeResponse } = this.props
    const {
      data: mappings,
    } = getListingTypeResponse || {}
    if (!listingState) return DEFAULT_TRANSACTION_TYPE
    if (!mappings) return DEFAULT_TRANSACTION_TYPE
    if (mappings === undefined) return DEFAULT_TRANSACTION_TYPE
    if (mappings === null) return DEFAULT_TRANSACTION_TYPE
    if (mappings && !mappings.listing_type) return DEFAULT_TRANSACTION_TYPE
    if (mappings && mappings.listing_type && mappings.listing_type.length <= 0) return DEFAULT_TRANSACTION_TYPE

    const currentMappings = mappings.listing_type.filter(item => item.state === listingState)

    if (currentMappings.length <= 0) return DEFAULT_TRANSACTION_TYPE

    return currentMappings[0].values || DEFAULT_TRANSACTION_TYPE
  }

  render() {
    const { cityFormValue, transactionType, isTransactionTypeOther } = this.state
    const { history, getListingCoverSheetResponse, updateListingCoverSheetResponse, loaderOnAgentInfo, getListingTypeResponse, listingState } = this.props
    {console.log("listingState", listingState)}
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]
    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Property Information
        </HeaderNav>
        <ProgressBarComponent total={[1, 2, 3, 4]} ongoingOrCompleted={3} />
        <Container>
          <LeftSideBar>
            <Sidebar />
          </LeftSideBar>
          <RightSideBar>
            {loaderOnAgentInfo ? (<Loader />) : (
              <Form colon={false} layout="vertical" requiredMark={false} onFinish={this.onFinish} initialValues={this.getInitialValues()} ref={this.formRef}>
                <Form.Item name="price" label={<LabelComponent text="Current Listing Price" required />} rules={commonRules}>
                  <InputNumber
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
                <FlexBox>
                  <Form.Item name="bedrooms" label={<LabelComponent text="Bedrooms" />}>
                    <InputNumber />
                  </Form.Item>

                  <Form.Item name="bathrooms" label={<LabelComponent text="Bathrooms" />}>
                    <InputNumber />
                  </Form.Item>
                  <SizeInputGroup
                    placeholder="Enter Size"
                    inputType="text"
                    selectDefaultValue="Sq.Ft"
                    isFormField
                    name="area"
                    options={PROPERTY_MEASURED_AS}
                    text="Size"
                    selectName="area_unit"
                  />
                </FlexBox>

                <FlexBox>
                  <Form.Item name="street" label={<LabelComponent text="Street" required />} rules={commonRules}>
                    <GoogleAutoComplete
                      id="cities"
                      types={["address"]}
                      name="cities"
                      // formValue={cityFormValue}
                      onChange={(e) => { }}
                      location={(loc) => { this.setStreet(loc) }}
                      placeholder=""
                      styles={{
                        height: "49px",
                        borderRadius: "0px",
                        background: "white",
                        fontSize: "16px",
                        marginTop: "10px",
                      }}
                      restrict={{
                        lat: 37.09024,
                        lng: -95.712891,
                        south_west: {
                          lat: 25.82,
                          lng: -124.39,
                        },
                        north_east: {
                          lat: 49.38,
                          lng: -66.94,
                        },
                        address: "United States",
                        country: {
                          long_name: "United States",
                          short_name: "US",
                          types: [
                            "country",
                            "political",
                          ],
                        },
                      }}
                    />
                  </Form.Item>

                  <Form.Item name="apt" label={<LabelComponent text="Apt/Unit/Suite No." />}>
                    <Input />
                  </Form.Item>
                </FlexBox>
                <FlexBox>
                  <Form.Item name="city" label={<LabelComponent text="City" required />} rules={commonRules}>
                    <GoogleAutoComplete
                      id="cities"
                      types={["(cities)"]}
                      name="cities"
                      // formValue={cityFormValue}
                      onChange={(e) => { this.setLocaCity(e.target.value) }}
                      location={(loc) => { this.setCities(loc) }}
                      placeholder=""
                      styles={{
                        height: "49px",
                        borderRadius: "0px",
                        background: "white",
                        fontSize: "16px",
                        marginTop: "10px",
                      }}
                      restrict={{
                        lat: 37.09024,
                        lng: -95.712891,
                        south_west: {
                          lat: 25.82,
                          lng: -124.39,
                        },
                        north_east: {
                          lat: 49.38,
                          lng: -66.94,
                        },
                        address: "United States",
                        country: {
                          long_name: "United States",
                          short_name: "US",
                          types: [
                            "country",
                            "political",
                          ],
                        },
                      }}
                    />
                  </Form.Item>

                  <Form.Item name="state" label={<LabelComponent text="State" required />} rules={commonRules}>
                    {/* <Input onChange = {(e) => setListingState(e.target.value)}/> setPropertyState */}
                    <Input onChange = { e => this.setPropertyState(e)}/>
                    {/* <Select>
                      {STATE_OPTIONS.map(st => (
                        <Select.Option value={st.value}>{st.label}</Select.Option>
                      ))}
                    </Select> */}
                  </Form.Item>
                </FlexBox>
                <FlexBox>
                  <Form.Item
                    name="zipcode"
                    label={<LabelComponent text="Zip Code" required />}
                    rules={[
                      {
                        required: true,
                        message: "Invalid Input",
                        type: "string",
                        pattern: new RegExp(/\d+/g),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item name="county" label={<LabelComponent text="County" required />} rules={commonRules}>
                    <Input />
                  </Form.Item>
                </FlexBox>
                {listingState === "California" && <Form.Item name="apn_number" label={<LabelComponent text="APN Number" required/>} rules={commonRules}>
                  <Input />
                </Form.Item>}

                {(listingState === "Colorado" || listingState === "Florida") && (
                  <Form.Item 
                    name="legal_description" 
                    label={<LabelComponent text="Legal Description" required/>} 
                    rules={commonRules}>
                    <Input />
                  </Form.Item>
                )}

                {(listingState === "Florida") && (
                  <Form.Item 
                    name="tax_id_number" 
                    label={<LabelComponent text="Tax ID Number"/>} 
                    //rules={commonRules}
                  >
                    <Input />
                  </Form.Item>
                )}

                {(listingState == "Texas") && (
                  <FlexBox>
                    <Form.Item 
                    name="lot" 
                    label={<LabelComponent text="Lot" required/>} 
                    rules={commonRules}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item 
                    name="block" 
                    label={<LabelComponent text="Block" required/>} 
                    rules={commonRules}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item 
                    name="addition" 
                    label={<LabelComponent text="Addition" required/>} 
                    rules={commonRules}
                    >
                      <Input />
                    </Form.Item>
                  </FlexBox>)
                }

                {(listingState === "Washington") && (
                  <Form.Item 
                    name="tax_parcel_id_number" 
                    label={<LabelComponent text="Tax Parcel ID Number"/>} 
                    //rules={commonRules}
                  >
                    <Input />
                  </Form.Item>
                )}

                {(listingState == "Georgia") && (
                  <>
                  <FlexBox>
                    <Form.Item 
                    name="tax_parcel_id_number" 
                    label={<LabelComponent text="Tax Parcel ID Number" required/>} 
                    rules={commonRules}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item 
                    name="land_lots" 
                    label={<LabelComponent text="Land Lot(s)"/>} 
                    //rules={commonRules}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item 
                    name="district" 
                    label={<LabelComponent text="District"/>} 
                    //rules={commonRules}
                    >
                      <Input />
                    </Form.Item>
                  </FlexBox>
                  <FlexBox>
                    <Form.Item 
                    name="section_gmd" 
                    label={<LabelComponent text="Section / GMD"/>} 
                    //rules={commonRules}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item 
                    name="lot" 
                    label={<LabelComponent text="Lot"/>} 
                    //rules={commonRules}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item 
                    name="block" 
                    label={<LabelComponent text="Block"/>} 
                    //rules={commonRules}
                    >
                      <Input />
                    </Form.Item>
                  </FlexBox>
                  <FlexBox>
                    <Form.Item 
                    name="unit" 
                    label={<LabelComponent text="Unit"/>} 
                    //rules={commonRules}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item 
                    name="phase_section" 
                    label={<LabelComponent text="Phase / Section"/>} 
                    //rules={commonRules}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item 
                    name="subdivision_development" 
                    label={<LabelComponent text="Subdivision / Development"/>} 
                    //rules={commonRules}
                    >
                      <Input />
                    </Form.Item>
                  </FlexBox>
                  <FlexBox>
                    <Form.Item 
                    name="plat_book" 
                    label={<LabelComponent text="Plat Book"/>} 
                    //rules={commonRules}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item 
                    name="page" 
                    label={<LabelComponent text="Page"/>} 
                    //rules={commonRules}
                    >
                      <Input />
                    </Form.Item>
                  </FlexBox>
                  </>
                  )
                }

                <SelectField
                  isFormField
                  name="transaction_type"
                  options={this.getTransactionTypes()}
                  placeholder="Listing Type"
                  text="Listing Type"
                  required={true}
                  requiredMessage="Required"
                  onChange={value => this.setTransactionType(value)}
                />

                {/* <Form.Item name="transaction_type" label={<LabelComponent text="Listing Type" required />} rules={commonRules}>
                  <Select onChange={value => this.setTransactionType(value)}>
                    {transactionType.map(tt => (
                      <Select.Option value={tt.value}>{tt.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item> */}

                {isTransactionTypeOther && (
                  <Form.Item name="other_transaction_type" label={<LabelComponent text="Other Listing Type" required />} rules={commonRules}>
                    <Input />
                  </Form.Item>
                )}

                {/* <Form.Item name="key_safe_lockbox_to_be_placed" label={<LabelComponent text="Would you like to include a (KLA) Keysafe/Lockbox Addendum, with this listing?" required />} rules={commonRules}>
                  <Radio.Group>
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name="include_contengency" label={<LabelComponent text="Would you like to include a (SPRP) Seller's Purchase Of Replacement Property form, with this listing?" required />} rules={commonRules}>
                  <Radio.Group>
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item> */}


                <FlexBox>
                  <FlexBox>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "white",
                          float: "right",
                          borderColor: "#3B60E4",
                          color: "#3B60E4",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        // htmlType="submit"
                        onClick={() => { history.push("/broker/tc/add-listings/coversheet/seller-info") }}
                        //onClick={() => { history.push("/broker/tc/add-listings/coversheet/listing-details") }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "#3B60E4",
                          float: "right",
                          borderColor: "white",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        htmlType="submit"
                        loading={updateListingCoverSheetResponse.isFetching || getListingCoverSheetResponse.isFetching}
                      // onClick={() => { history.push("/broker/tc/add-listings/coversheet/tc-info") }}
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </FlexBox>
                </FlexBox>
              </Form>
            )}

          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)
