import React, { PureComponent } from "react"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { get, isEmpty } from "lodash"
import moment from "moment"
import {
  Tabs, Table, Select, Button, Input, Spin, Modal,
} from "antd"

import Container from "container/EventRooms"
import JobEditModal from "components/Jobs/JobEditModal"
import TeamContainer from "container/BrokerDashboard/teamContainer"
const { TabPane } = Tabs

const { confirm } = Modal

const { Option } = Select

const Wrap = styled.div`
  margin: 0px 30px;
`

const PageTitle = styled.h2`
  margin: 40px 0px;
`

const Card = styled.div`
  padding: 24px;
  gap: 10px;
  width: 398px;
  height: 93.55px;
  background: #F9F9F7;
`

const Header = styled.h1`
  font-size: 24px;
  margin: 20px 0px;
  ${props => props.fs && `
    font-size: ${props.fs};
  `}
`

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 30px 0 50px;
  border-radius: 8px;
  > div {
    display: flex;
    align-items: end;
  }
`

const FilterContainer = styled.div`
  margin-right: 5px;
`

const ButtonWrap = styled.div`
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  > button {
    margin-right: 10px;
  }
`

const Label = styled.h3`
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 5px 0;
`
const ButtonContainer = styled.div`
  margin: 10px
`

const Address = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  span {
    font-weight: 500;
    background: #f0f0f0;
    padding: 4px 10px;
    margin: 3px;
    font-size: 12px;
    border-radius: 50px;
    text-align: center;
  }
`

class RARTeamJobs extends PureComponent {
  state = {
    applicantSearch: "",
    teamId: null,
    jobSearch: "",
    editJobModal: false,
  }

  componentDidMount() {
    const {
      getAllJobsByTeamId, getJobApplications, getRARTeamList, teamId,
    } = this.props
    const body = {
      skip: 0,
      limit: 5,
    }
    if (teamId) {
      this.setState({ teamId })
      body.teamId = teamId
    }
    getAllJobsByTeamId(body)
    getJobApplications({
      skip: 0,
      limit: 5,
    })
    getRARTeamList()

    // eslint-disable-next-line no-unused-expressions
    import("../antd.css")
  }

  handleEditJobModal(flag) {
    this.setState({ editJobModal: flag })
  }

  handleJobTitleFilter = (e) => {
    this.setState({
      applicantSearch: e.value !== "none" ? e.value : "",
    })
  }

  handleApplicantNameFilter = (e) => {
    this.setState({
      applicantSearch: e.target.value,
    })
  }

  handleJobNameFilter = (e) => {
    this.setState({
      jobSearch: e.target.value,
    })
  }

  searchApplications = (skip, limit) => {
    const { getJobApplications } = this.props
    const { applicantSearch } = this.state
    getJobApplications({
      skip,
      limit,
      search: applicantSearch,
    })
  }

  searchJobs = (skip, limit) => {
    const { getAllJobsByTeamId } = this.props
    const { jobSearch, teamId } = this.state
    const body = {
      skip,
      limit,
      key: jobSearch,
    }
    if (teamId) {
      body.teamId = teamId
    }
    getAllJobsByTeamId(body)
  }

  handleChange = (key, value, record) => {
    // eslint-disable-next-line no-param-reassign
    record.status = key
    const { updateJobApplications } = this.props
    updateJobApplications({
      applicantId: record.application_id,
      body: {
        status: value.value,
      },
    })
    console.log(`selected ${key}`, value, record)
  }

  checkForNotification = (id, validForRenotify) => {
    if (validForRenotify) {
      this.confirmNotifying(id)
      return null
    }
    this.errorSendingNotification()
  }

  errorSendingNotification = () => {
    Modal.error({
      title: "Not Allowed",
      content: "Resending Notification is allowed in a month",
    })
  };

  confirmNotifying = (id) => {
    confirm({
      title: "Do you want to send Notification",
      icon: <ExclamationCircleOutlined />,
      content: "Email will be notified to all agents in that location once you press ok!",

      onOk: () => {
        const { sendJobNotifications } = this.props
        const { teamId } = this.state
        sendJobNotifications({
          jobId: id,
          teamId,
        })
      },
    })
  }

  columns = [
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    // {
    //   title: "Job Overview",
    //   dataIndex: "job_description_and_responsibilities",
    //   key: "job_description_and_responsibilities",
    //   width: "10%",
    // },
    // {
    //   title: "Requirements",
    //   dataIndex: "requirements",
    //   key: "requirements",
    //   width: "10%",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
    },
    {
      title: "Workplace Type",
      dataIndex: "workplace_type",
      key: "workplace_type",
      width: "10%",
    },
    {
      title: "Applicant Count",
      dataIndex: "job_applicants_count",
      key: "job_applicants_count",
      width: "10%",
    },
    {
      title: "Posted By",
      dataIndex: "creator_firstname",
      key: "creator_firstname",
      width: "10%",
    },
    {
      title: "Posted By Email",
      dataIndex: "creator_email",
      key: "creator_email",
      width: "10%",
    },
    {
      title: "Team",
      dataIndex: "team_name",
      key: "team_name",
      width: "10%",
    },
    {
      title: "Location",
      dataIndex: "location_details",
      key: "location_details",
      width: "10%",
      render: (data) => {
        console.log("===data====?>>>>>", data)
        return (
          <Address>
            {data && data.map((lc, index) => (
              <span>{lc && lc.location}</span>
            ))}
          </Address>
        )
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (text, record, index) => {
        console.log("==record===", record, index, text)
        const { teamId } = this.state
        const { deleteJobs } = this.props
        return (
          <ButtonWrap>
            <ButtonContainer>
              <Button size="medium" type="primary" onClick={() => { window.open(`https://radiusagent.com/teams/jobs/${record.job_slug}`, "_blank").focus() }}>
                View Job
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button
                size="medium"
                onClick={() => {
                  this.handleEditJobModal(true)
                  this.setState({ jobIndex: index })
                }}
              >
                Edit Job
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button size="medium" onClick={() => { window.open(`/broker/teams/applicants/${record.id}`, "_blank").focus() }}>
                View applicants
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button danger size="medium" onClick={() => { this.checkForNotification(record.id, record.validForRenotify) }}>
                Re Notify Agents
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button danger size="medium" onClick={() => this.showConfirm(record)}>
                Delete Job
              </Button>
            </ButtonContainer>
          </ButtonWrap>
        )
      },
    },
  ]

  applicantColumns = [
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "10%",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      width: "10%",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      width: "10%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "10%",
    },
    {
      title: "Applied On",
      dataIndex: "created_at",
      key: "created_at",
      width: "10%",
      render: (text, record, index) => (
        <span>{moment(record.created_at).format("YYYY-MM-DD")}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (text, record, index) => (
        <Select
          key={index}
          defaultValue={record.status}
          style={{ width: 140 }}
          onChange={(key, value) => this.handleChange(key, value, record)}
        >
          <Option value="in_review">In Review</Option>
          <Option value="verified">Verified</Option>
          <Option value="hired">Hired</Option>
          <Option value="declined">Declined</Option>
        </Select>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (text, record, index) => {
        return (
          <ButtonWrap>
            <ButtonContainer>
              <Button size="medium" type="primary" onClick={() => { window.open(`https://radiusagent.com/teams/jobs/${record.job_slug}`, "_blank").focus() }}>
                View Job
              </Button>
            </ButtonContainer>
          </ButtonWrap>
        )
      },
    },
  ]

  tabChange = () => {

  }

  getTotal = arr => arr && arr.total || 0

  getTeamSelectOptions = () => {
    const { rarTeamListResponse } = this.props
    return (rarTeamListResponse && !isEmpty(rarTeamListResponse.data)) ? rarTeamListResponse.data.map(dt => ({
      name: dt.name,
      id: dt.id,
    })) : []
  }

  handleTeamChange = (key, value) => {
    this.setState({
      teamId: value.value,
    })
  }

  jobPagination = (page) => {
    this.searchJobs(((page.current - 1) * page.pageSize), page.pageSize)
  }

  applicantPagination = (page) => {
    this.searchApplications(((page.current - 1) * page.pageSize), page.pageSize)
  }

  showConfirm = (record) => {
    const { deleteJobs } = this.props
    const { jobSearch, teamId } = this.state
    confirm({
      title: "Do you Want to delete this job?",
      icon: <ExclamationCircleOutlined />,
      content: "This job will no longer will be visible on landing page and product recruitment dashboard",

      onOk() {
        deleteJobs({
          jobId: record.id,
          body: {
            jobSearch,
            teamId,
          },
        })
      },
    })
  }

  getTeamInfo = (jobIndex) => {
    const { rarTeamListResponse, allJobsByTeamId } = this.props
    if (rarTeamListResponse.data) {
      const jobDetails = allJobsByTeamId && allJobsByTeamId.data && allJobsByTeamId.data.jobs ? allJobsByTeamId.data.jobs[jobIndex] : null
      if (jobDetails) {
        return rarTeamListResponse.data.find(dt => dt.id === jobDetails.team_id)
      }
    }
    return {}
  }

  render() {
    const {
      allJobsByTeamId, allJobApplications, rarTeamListResponse, showApplicant = true, showTeamFilter = true,
    } = this.props
    const { editJobModal, jobIndex } = this.state
    console.log("==allJobsByTeamId==allJobApplications>", allJobsByTeamId)
    console.log("==rarTeamListResponse===000rarTeamListResponse00", rarTeamListResponse)
    // console.log("asdsadasad", get(allJobsByTeamId.data, "jobs[jobIndex]", null), jobIndex)
    return (
      <Wrap>
        {!showApplicant && (
          <>
            <FilterWrapper>
              <Header>
                Job List
              </Header>
              <div>
                <JobEditModal teamInfo={this.getTeamInfo(jobIndex)} jobDetails={allJobsByTeamId && allJobsByTeamId.data && allJobsByTeamId.data.jobs ? allJobsByTeamId.data.jobs[jobIndex] : null} visible={editJobModal} onClose={() => this.handleEditJobModal(false)} />
                <FilterContainer>

                  {showTeamFilter && (
                    <>
                      <Label>Teams</Label>
                      <Select
                        labelInValue
                        defaultValue={{ value: null, label: "none" }}
                        style={{ width: 420 }}
                        size="large"
                        onChange={this.handleTeamChange}
                      >
                        <Option value="none">none</Option>
                        {this.getTeamSelectOptions().map(option => (
                          <Option value={option.id}>{option.name}</Option>
                        ))}
                      </Select>
                    </>
                  )}
                </FilterContainer>
                <FilterContainer>
                  <Label>Search</Label>
                  <Input style={{ width: 250 }} size="large" placeholder="job title" onChange={this.handleJobNameFilter} onPressEnter={() => this.searchJobs(0, 5)} />
                </FilterContainer>
                <ButtonWrap>
                  <Button type="primary" size="large" onClick={() => this.searchJobs(0, 5)}>
                    Filter Jobs
                  </Button>
                </ButtonWrap>
              </div>
            </FilterWrapper>
            <Table
              scroll={{ x: 540 }}
              columns={this.columns}
              dataSource={allJobsByTeamId && allJobsByTeamId.data && allJobsByTeamId.data.jobs}
              pagination={{ total: this.getTotal(allJobsByTeamId.data), defaultPageSize: 5 }}
              onChange={this.jobPagination}
              loading={{ indicator: <div>{<Spin />}</div>, spinning: !!allJobsByTeamId.isFetching }}
            />
          </>
        )}
        {showApplicant && (
          <Tabs onChange={this.tabChange} size="large" animated>
            <TabPane tab="Job List" key="jobList">
              <FilterWrapper>
                <Header>
                  Job List
                </Header>
                <div>
                  <JobEditModal teamInfo={this.getTeamInfo(jobIndex)} jobDetails={allJobsByTeamId && allJobsByTeamId.data && allJobsByTeamId.data.jobs ? allJobsByTeamId.data.jobs[jobIndex] : null} visible={editJobModal} onClose={() => this.handleEditJobModal(false)} />
                  <FilterContainer>

                    {showTeamFilter && (
                      <>
                        <Label>Teams</Label>
                        <Select
                          labelInValue
                          defaultValue={{ value: null, label: "none" }}
                          style={{ width: 420 }}
                          size="large"
                          onChange={this.handleTeamChange}
                        >
                          <Option value="none">none</Option>
                          {this.getTeamSelectOptions().map(option => (
                            <Option value={option.id}>{option.name}</Option>
                          ))}
                        </Select>
                      </>
                    )}
                  </FilterContainer>
                  <FilterContainer>
                    <Label>Search</Label>
                    <Input style={{ width: 250 }} size="large" placeholder="job title" onChange={this.handleJobNameFilter} onPressEnter={() => this.searchJobs(0, 5)} />
                  </FilterContainer>
                  <ButtonWrap>
                    <Button type="primary" size="large" onClick={() => this.searchJobs(0, 5)}>
                      Filter Jobs
                    </Button>
                  </ButtonWrap>
                </div>
              </FilterWrapper>
              <Table
                scroll={{ x: 540 }}
                columns={this.columns}
                dataSource={allJobsByTeamId && allJobsByTeamId.data && allJobsByTeamId.data.jobs}
                pagination={{ total: this.getTotal(allJobsByTeamId.data), defaultPageSize: 5 }}
                onChange={this.jobPagination}
                loading={{ indicator: <div>{<Spin />}</div>, spinning: !!allJobsByTeamId.isFetching }}
              />
            </TabPane>

            <TabPane tab="Application List" key="applications">
              <FilterWrapper>
                <Header>
                  Application List
                </Header>
                <div>
                  <FilterContainer>
                    <Label>Search</Label>
                    <Input style={{ width: 250 }} size="large" placeholder="firstname, email, position" onChange={this.handleApplicantNameFilter} onPressEnter={() => this.searchApplications(0, 5)} />
                  </FilterContainer>
                  <ButtonWrap>
                    <Button type="primary" size="large" onClick={() => this.searchApplications(0, 5)} shape="round">
                      Search Applications
                    </Button>
                  </ButtonWrap>
                </div>
              </FilterWrapper>
              <Table
                scroll={{ x: 540 }}
                columns={this.applicantColumns}
                dataSource={allJobApplications && allJobApplications.data && allJobApplications.data.applicants}
                pagination={{ total: this.getTotal(allJobApplications.data), defaultPageSize: 5 }}
                onChange={this.applicantPagination}
                loading={{ indicator: <div>{<Spin />}</div>, spinning: !!allJobApplications.isFetching }}
              />
            </TabPane>
          </Tabs>
        )}
      </Wrap>
    )
  }
}
export default TeamContainer(Container(withRouter(RARTeamJobs)))
