import { call, put, throttle, takeLatest, select } from "redux-saga/effects"
import * as PIMActions from "./actions"
import { isSuccess } from "services/Utils"
import { toast } from "react-toastify"
import root from "window-or-global"
import * as APIs from "./api"

const baseUrl = process.env.REACT_APP_API_BASE_URL;

function* handeFeatureImageUpload(action) {
 try {
  const {
   file,
  } = action.data

  const fd = new FormData()
   fd.append("imageFile", file)
   const data = {
     filePath: `pim/${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`,
     config: {
       bucket: "pim",
       convertTo: "jpeg",
     },
   }
   fd.append("requestBody", JSON.stringify(data))

   const imageRes = yield call(APIs.uploadImageAPI, fd)

   if (isSuccess(imageRes)) {
       const {
        data: {
         response: {
          imageURL,
         }
        }
       } = imageRes
       toast.success("Image uploaded")
       toast.success("Please click Update below")
       yield put(PIMActions.currentImageURLSetAction.call({currentImageURL: imageURL}))
       yield put(PIMActions.uploadImageAction.success(imageRes))
   }

 } catch(e) {
   yield put(PIMActions.uploadImageAction.failure(e))
 }
}

function* handleUploadNewFeature(action) {
  try {
      const {
        payload,
      } = action.data

      const res  = yield call(APIs.createNewFeatureAPI, payload)
      if (isSuccess(res)) {
          yield put(PIMActions.uploadNewFeatureAction.success(res.data))
          toast.success("Feature Added")
          setTimeout(() => {
            root.location.href="/product-interest-admin"
          }, 1000)
      }
  } catch(error) {
      alert(error)
      yield put(PIMActions.uploadNewFeatureAction.failure(error))
  }
}

function* handleFeatureUpdate(action) {
  try {
      const {
        id,
        payload,
      } = action.data
      const res  = yield call(APIs.updateFeatureAPI, payload, id)
      if(isSuccess(res)) {
          yield put(PIMActions.updateExistingFeatureAction.success(res.data))
          toast.success("Feature Updated, Refreshing")
          setTimeout(() => {
            root.location.href="/product-interest-admin"
          }, 1000)
      }
  } catch(error) {
      alert(error)
      yield put(PIMActions.updateExistingFeatureAction.failure(error))
  }
}


function* handleFetchAllPIM() {
   try {
       const res  = yield call(APIs.getAllPimAPI)
       if (isSuccess(res)) {

           yield put(PIMActions.getAllPimAction.success(res.data))
       }
   } catch(error) {
       alert(error)
       yield put(PIMActions.getAllPimAction.failure(error))
   }
}

//handleEventsRequest

export default function* main() {
    //yield takeLatest(AcademyActions.getCoursesAction.REQUEST, handleFetchCourses)
    yield takeLatest(PIMActions.getAllPimAction.REQUEST, handleFetchAllPIM)
    yield takeLatest(PIMActions.uploadImageAction.REQUEST, handeFeatureImageUpload)
    yield takeLatest(PIMActions.uploadNewFeatureAction.REQUEST, handleUploadNewFeature)
    yield takeLatest(PIMActions.updateExistingFeatureAction.REQUEST, handleFeatureUpdate)

}
