import React from "react";
import "./ComparingWizard.css";
import Popup from "reactjs-popup";

const wizard = props => {
  let keys;
  let salesStages = [];
  keys = props.client['inboundReferralStatuses'];
  return <ol className={"comparing-track-progress"} >
    {keys.map((stage, index) => (
      
       <Popup
          trigger={
            <li className={props.client['sales']['Current stage'] === stage ? "active" : ""}
            >
                <span>{stage}</span>
            </li>
          }
            modal
            closeOnDocumentClick
          >
              {close => (
                  <div className="modal-2">
                  <div className="modal-header">
                      <span className="modal-header-text"> 
                          Are you sure you want to continue with this action?
                      </span>     
                  </div>  
                  <div className="dialogue-box-footer">
                      <button className="med-btn-1" onClick={() => { props.updateSalesStage(props.client, stage, props.agent); close();}}>
                          YES
                      </button>
                      <button className="med-btn-1" onClick={()=> {close();}}>
                          NO
                      </button>
                  </div> 
            </div>

              )}
        </Popup>    
    ))}
  </ol>
};

export default wizard;
