import React from "react"
import styled from "styled-components"
import { get, orderBy } from "lodash"
import moment from "moment"
import { format } from "react-phone-input-auto-format";
import container from "container/ZillowLeadMerge"
import Modal from "dumbComponents/common/UI/Modal"
import Button from "dumbComponents/common/UI/Button"
import Loader from "dumbComponents/common/UI/Loader"
import ReferralItem from "./ReferralItem"
import ConfirmModal from "./../UI/ConfirmModal"

const Wrap = styled.div`
  position: relative;
  header {
    background: #ededed;
    padding: 15px;
    display: flex;
    > div {
      &:nth-child(1) {
        flex: 1;
      }

      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }
`

const CloseBTN = styled.div`
  position: absolute;
  top: 10px;
  right: 0px;
  font-size: 20px;
  color: #888;
`

export const Text = styled.p`
  ${props => props.size && `
    font-size: ${props.size}px;
  `}
  ${props => props.light && `
    color: #a8a8a8;
  `}
  ${props => props.bold && `
    font-weight: 600;
  `}
  ${props => props.m ? `
    margin: ${props.m || "0px"} !important;
  ` : `
    margin: 0px;
  `}

`

const MainWrap = styled.main`
  padding: 10px;
  background: #FFF;
`

const NoLead = styled.span`
    font-size: 12px;
    margin: 10px 0px;
    display: block;
    padding-left: 10px;
    color: #557;
`

class ZillowMergeModal extends React.Component {
  state = {
    selectedReferrals: [],
  }

  componentDidMount() {
    const { fetchReferalForMerge, client, referral } = this.props
    const {
      client_name,
      sender_client_phone,
      sender_client_email,
      id,
      sender_id,
    } = referral
    fetchReferalForMerge({
      payload: {
        agent_id: sender_id,
        name: client_name || get(client, "name"),
        phone: get(client, "phones[0].phone") || sender_client_phone,
        email: get(client, "emails[0].email") || sender_client_email,
        referral_id: id,
        timezone_offset: new Date().getTimezoneOffset(),
      },
    })
  }

  handleSelectReferral = (item) => {
    const { selectedReferrals } = this.state
    let filteredSelected = []
    if (selectedReferrals.includes(item.referral_id)) {
      filteredSelected = selectedReferrals.filter(x => item.referral_id !== x)
    } else {
      filteredSelected = [
        item.referral_id,
        ...selectedReferrals,
      ]
    }
    this.setState({
      selectedReferrals: filteredSelected,
    })
  }

  handleMerge = () => {
    this.setState({
      isShowConfirmModal: false,
    })
    const { mergeZillowLeads, twilioClientName, referral } = this.props
    let { selectedReferrals } = this.state
    selectedReferrals = [
      referral.id,
      ...selectedReferrals,
    ]
    mergeZillowLeads({
      payload: {
        referral_ids: selectedReferrals,
        twilio_client_name: twilioClientName,
      },
    })
  }

  toggleConfirmModal = (show) => {
    this.setState({
      isShowConfirmModal: show,
    })
  }

  render() {
    const {
      selectedReferrals,
      isShowConfirmModal,
    } = this.state
    const {
      toggleZillowMergeModal,
      zillowMergeResponse,
      client,
    } = this.props
    const { data: mergingList, isFetching } = zillowMergeResponse || {}
    const {
      recent_zillow_leads: recentZillowLeads,
      similar_zillow_leads: similarZillowLeads,
    } = mergingList || {}
    // let mergedList = []
    // if (recentZillowLeads && similarZillowLeads) {
    //   mergedList = orderBy([
    //     ...recentZillowLeads,
    //     ...similarZillowLeads,
    //   ], "created_id")
    // }
    return (
      <Modal
        show
        toClose={() => { toggleZillowMergeModal(false) }}
        dialogStyles={{ background: "transparent" }}
        modalStyles={{
          padding: 0,
          borderRadius: 10,
          overflow: "hidden",
          background: "transparent",
        }}
      >
        <ConfirmModal
          callback={this.handleMerge}
          cancel={() => { this.toggleConfirmModal(false) }}
          show={isShowConfirmModal}
          message="Are you sure?"
        />
        <Wrap>
          <CloseBTN onClick={() => { toggleZillowMergeModal(false) }}>
            <i className="fa fa-times"></i>
          </CloseBTN>
          <header>
            <div>
              <Text size={14} m="0px 0px 10px 0px">
                Merge Lead
              </Text>
              <Text size={16}>
                {client.name}
              </Text>
              <Text size={12}>
                {`Added ${moment.unix(client.created_at).fromNow()} | ${moment.unix(client.created_at).format("MMM DD, YYYY kk:mm")}`}
              </Text>
              <Text size={11}>
                {`${get(client, "emails[0].email")}  / ${format(get(client, "phones[0].phone", "") || "")}`}
              </Text>
            </div>
            <div>
              <Button
                danger
                onClick={this.toggleConfirmModal}
                disabled={selectedReferrals && selectedReferrals.length === 0}
              >
                Merge
              </Button>
            </div>
          </header>
          <MainWrap>
            {isFetching ? (
              <Loader top="0px" bottom="0px" />
            ) : (
              <React.Fragment>
                <Text light size={12} bold>
                  Recent Leads
                </Text>
                {recentZillowLeads && recentZillowLeads.length > 0 ? recentZillowLeads.map(item => (
                  <ReferralItem
                    key={item.referral_id}
                    handleSelect={this.handleSelectReferral}
                    isSelected={selectedReferrals.includes(item.referral_id)}
                    data={{
                      ...item,
                    }}
                  />
                )) : (
                  <NoLead>
                    No recent leads
                  </NoLead>
                )}
                <Text light size={12} bold style={{ marginTop: 10 }}>
                  Matching Leads
                </Text>
                {similarZillowLeads && similarZillowLeads.length > 0 ? similarZillowLeads.map(item => (
                  <ReferralItem
                    key={item.referral_id}
                    handleSelect={this.handleSelectReferral}
                    isSelected={selectedReferrals.includes(item.referral_id)}
                    data={{
                      ...item,
                    }}
                  />
                )) : (
                  <NoLead>
                    No matching leads
                  </NoLead>
                )}
              </React.Fragment>
            )}

          </MainWrap>
        </Wrap>
      </Modal>
    )
  }
}

export default container(ZillowMergeModal)
