import React from "react"
import styled from "styled-components"
import { Title } from "./common"

const Wrap = styled.div``

const Property = ({
  text,
  time,
  property_url,
}) => (
  <Wrap>
    <Title type={text} time={time} />
    <div>
      <a href={property_url} target="_blank">View Property</a>
    </div>
  </Wrap>
)

export default Property
