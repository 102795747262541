/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react"
import styled from "styled-components"
import AgentContainer from "container/BrokerDashboard/editAgentContainer"
import SubDeetsContainer from "container/Agent"
import { get } from "lodash"
import { ShimmerBox } from "shared/styles/animation"
import {
  Container,
  PlanClickBar,
  PlanWrap,
  PlanPriceWrap,
  PlanPrice,
  Title,
  TitleWrap,
  ShimmerWrap,
} from "../../common"

const Wrap = styled.div``

const CommissionCap = ({
  getBrokerageInfoMetabaseResponse,
  selectAgentPlan,
  selectedAgentPlans,
  subscriptions,
}) => {
  const isCapAlready = get(subscriptions, "data.additional_info.commission_cap", "")
  const isFetching = get(getBrokerageInfoMetabaseResponse, "isFetching")
  const commissionCap = get(getBrokerageInfoMetabaseResponse, "data.commission_cap")

  useEffect(() => {
    if (isCapAlready || isCapAlready === 0) {
      const payload = {
        key: "commission_cap",
        commission_cap: isCapAlready.toString(),
      }
      selectAgentPlan(payload)
    }
  }, [isCapAlready])

  const handleClick = (value) => {
    console.log(value, "here")
    const payload = {
      key: "commission_cap",
      commission_cap: value.value,
    }
    selectAgentPlan(payload)
  }
  return (
    <Wrap>
      <Container>
        <TitleWrap>
          <Title>
          Commission Cap
          </Title>
        </TitleWrap>
        {isFetching ? (
          <ShimmerWrap>
            <ShimmerBox w="223px" h="102px" />
            <ShimmerBox w="223px" h="102px" />
            <ShimmerBox w="223px" h="102px" />
            <ShimmerBox w="223px" h="102px" />
            <ShimmerBox w="223px" h="102px" />
          </ShimmerWrap>
        ) : (
          <PlanWrap>
            {commissionCap && commissionCap.map((obj, index) => ({ index, obj })).sort((a, b) => b.obj.value - a.obj.value).map(({ obj }) => (
            <>
              <PlanClickBar
                onClick={() => {
                  handleClick(obj)
                }}
                key={obj.id}
                width={223.67}
                isSelected={selectedAgentPlans.some(el => el.commission_cap === obj.value)}
              >
                <PlanPriceWrap
                  key={obj.id}
                  isSelected={selectedAgentPlans.some(el => el.commission_cap === obj.value)}
                >
                  <PlanPrice
                    key={obj.id}
                    isSelected={selectedAgentPlans.some(el => el.commission_cap === obj.value)}
                  >
                    {obj.key}
                  </PlanPrice>
                </PlanPriceWrap>
              </PlanClickBar>
            </>
            ))}
          </PlanWrap>
        )}
      </Container>
    </Wrap>
  )
}

export default SubDeetsContainer(AgentContainer(CommissionCap))
