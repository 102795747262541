/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react"
import styled from "styled-components"
import { get, isEmpty } from "lodash"
import SubContainer from "container/BrokerDashboard/subscriptionContainer"
import {
  Container,
  PlanClickBar,
  PlanWrap,
  PlanPriceWrap,
  PlanPrice,
  Title,
  TitleWrap,
} from "../../common"
import { Exclamation } from "../../icons"

const Wrap = styled.div``

const Advisory = styled.div`
  display: flex;
  gap: 4px;
`

const AdvisoryText = styled.p`
  color: #52525B;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
`

const RiskManagement = ({
  planBenefitsResponse,
  addOnPlanIdsToAdd,
  addOnPlanIds,
  addonsResponse,
}) => {
  const planBenefitsData = get(planBenefitsResponse, "data")
  const insuranceData = planBenefitsData.filter(filteredData => filteredData.name === "E&O Insurance")
  const planDetails = get(insuranceData, "[0].plan_details", []) || []

  const selectedENOData = get(addonsResponse, "data")
  const selectedENO = !isEmpty(selectedENOData) && selectedENOData?.filter(filterData => filterData.name === "E&O Insurance")

  useEffect(() => {
    console.log("CHECK HERE ==>", selectedENO, addonsResponse)
    if (selectedENO && selectedENO.length > 0) {
      addOnPlanIdsToAdd({ plan_id: selectedENO[0]?.pricing?.plan_id, id: selectedENO[0]?.id, price: selectedENO[0]?.pricing?.amount })
    }
  }, [addonsResponse])

  const getUnitPrice = value => value.billing_cycle === "YEARLY"
    ? `$${value.unit_price}/yearly` : `$${value.unit_price}/monthly`

  return (
    <Wrap>
      <Container>
        <TitleWrap>
          <Title>
          Risk Management (E&O)
          </Title>
        </TitleWrap>
        <PlanWrap>
          {planDetails && planDetails.length > 0 && planDetails.map(planBenefits => (
          <>
            <PlanClickBar
              onClick={() => {
                addOnPlanIdsToAdd({ plan_id: planBenefits.plan_id, id: planBenefits.id, price: planBenefits.unit_price })
              }}
              isSelected={addOnPlanIds.some(el => el.plan_id === planBenefits.plan_id)}
              key={planBenefits.id}
              width={223.67}
            >
              <PlanPriceWrap
                isSelected={addOnPlanIds.some(el => el.plan_id === planBenefits.plan_id)}
                key={planBenefits.id}
              >
                <PlanPrice
                  isSelected={addOnPlanIds.some(el => el.plan_id === planBenefits.plan_id)}
                  key={planBenefits.id}
                >
                  {getUnitPrice(planBenefits)}
                </PlanPrice>
              </PlanPriceWrap>
            </PlanClickBar>
          </>
          ))}
        </PlanWrap>
        <Advisory>
          <Exclamation />
          <AdvisoryText>
            Upon enrollment and annually on enrollment anniversary
          </AdvisoryText>
        </Advisory>
      </Container>
    </Wrap>
  )
}

export default SubContainer(RiskManagement)
