import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { formValueSelector, getFormValues } from "redux-form"
import {
  getResourcesListAction,
  createResourceAction,
  updateResourcesAction,
  deleteResourcesAction,
} from "./actions"

function mapStateToProps(state) {
  const { brokerDashboard } = state
  const { resourcesListResponse } = brokerDashboard
  const formSelector = formValueSelector("ADD_RESOURCES_FORM")
  const linkType = formSelector(state, "link_type")
  return {
    resourcesListResponse,
    formValues: {
      link_type: linkType,
    }
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      getResourcesList: getResourcesListAction.request,
      createResources: createResourceAction.request,
      updateResources: updateResourcesAction.request,
      deleteResource: deleteResourcesAction.request,
    },
    dispatch
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)