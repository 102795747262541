import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"
import root from "window-or-global"
import CategoryList from "./CategoryList"
import {
  GearIcon,
 } from '@primer/octicons-react'
import { DefaultLoader } from "components/UI/Loader/Loader";

const Wrap = styled.div`
  div {
   width: 100%;
   height: max-content;
  }
`

const InnerCourseWrap = styled.div`
  min-height: 300px;
  max-height: 600px;
`

const AddCategory = styled(Link)`
  margin-bottom: 15px;
  width: 118px;
  display: block;
  height: 30px;
  border-radius: 4px;
  border: solid 1px #2a8b3f;
  background-image: linear-gradient(to bottom,#34ce57,#28a745);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  float: right;
  cursor: pointer;
  padding-top: 4px;
`

const Settings = styled(Link)`
  margin-bottom: 15px;
  width: 118px;
  display: block;
  height: 30px;
  border-radius: 4px;
  background: #fff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: black;
  text-decoration: none;
  float: right;
  cursor: pointer;
  padding-top: 4px;
  margin-right: 10px;
`


class Category extends Component{
 componentDidMount() {
  const {
   getCategories,
  } = this.props
  getCategories()
 }

 clearForms = () => {
  const {
   clearFormsEdits,
   clearCategoryForm,
  } = this.props
  clearFormsEdits()
  clearCategoryForm()
 }

 render() {

  const {
    getCategoriesResponse,
  } = this.props

  const {
   data: categories,
   isFetching: fetchingCategories,
  } = getCategoriesResponse || []

  return(
   <Wrap>
    <div>
     <AddCategory
     onClick={this.clearForms}
     to="/academy-admin/categories/add-new-category">
      Add Category
     </AddCategory>
    </div>
    <InnerCourseWrap>
      {fetchingCategories ? (
       <DefaultLoader height={200} width={200}/>
      ) : (
       <CategoryList categories={categories}/>
      )}
    </InnerCourseWrap>
   </Wrap>
  )
 }
}

export default Container(Category)
