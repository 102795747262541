import React, { Component } from "react"
import styled from "styled-components"


const Wrap = styled.div`
  height: max-content;
  margin-top: -39px;
  flex-grow: 2;
  font-family: "SF Pro Display", sans-serif;
  max-width: 499px;
`

const Title = styled.div`
  flex-grow: 1;
  height:100%;
  max-width: 325px
  .headerTitle {
   font-size: 12px;
   font-weight: bold;
   color: #a8a8a8;
  }
  .title {
   font-family: "SF Pro Display", sans-serif;
   font-size: 12px;
   color: #a8a8a8;
   text-decoration: underline;
  }
`

const DisplayName = styled.p`
 font-size: 12px;
 color: #a8a8a8;

`

const Note = styled.div`
  border-radius: 8px;
  border: solid 1px #ededed;
  background-color: #fef9eb;
  padding: 5px 5px 5px 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  word-break: break-all;
  max-height: 150px;
`

const AssistPreferencesDiv = (preferences) => {
    if (!preferences){
        return null;
    }

    if (preferences.length === 0){
        return null;
    }

    return (
     <React.Fragment>
      <Wrap>
      {
       preferences.map((pref) => (
        <React.Fragment>
         <DisplayName>{`Note: for ${pref.displayName}`}</DisplayName>
         <Note>{pref.text || "N/A"}</Note>
        </React.Fragment>
       ))
      }
      </Wrap>
     </React.Fragment>
    )
}

export default AssistPreferencesDiv
