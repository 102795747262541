import React, { useState } from "react"
import Modal from "dumbComponents/common/UI/Modal"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import editAgentContainer from "container/BrokerDashboard/editAgentContainer"
import {
  message,
} from "antd"
import {
  TeamCreation,
} from "./Forms"
import {
  Title,
} from "./commonStyles"
const baseUrl = process.env.REACT_APP_API_BASE_URL
const token = process.env.REACT_APP_SECRET_ID

const renderForm = (
  toClose,
  setSending,
  isSending
) => {
  const onFinishHandle = async (values) => {
    try {
      const url = "/v1.0/brokerage-teams"
      const method = "POST"

      const payload = {
        name: values.name,
        city: "",
        state: "",
        icon_url: null,
        admin_image_url: null,
        production_volume: 0,
        visible_on_landing_page: 0,
        about_team: "",
        other_benefits: "",
        array_images: [],
        is_cashback_supported: false,
        hide_radius_logo: false,
        brand_primary_color: "",
        brand_background_color: "",
        past_brokerage_name: "",
        primary_sale_representative: "",
        secondary_sale_representative: "",
        primary_sale_representative_split_percentage: 0,
        secondary_sale_representative_split_percentage: 0,
      }

      setSending(true)

      message.info("Creating team...")

      let resp = await fetch(baseUrl + url, {
        method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "internal-id": token,
          "X-User-Agent": "agent-php",
        },
        body: JSON.stringify(payload),
      })

      resp = await resp.json()

      setSending(false)

      toClose()

      message.info("Team Created, please assign to the agent if required.")
    } catch (e) {
      message.error("Something went wrong")
    }
  }

  return (
    <TeamCreation
      onFinishHandle={onFinishHandle}
      toClose={toClose}
      isSending={isSending}
    />
  )
}

const FormModal = ({
  toClose,
}) => {
  const [isSending, setSending] = useState(false)
  return (
    <Modal
      toClose={isSending ? () => {} : toClose}
      width="948px"
      show
      showCloseButton
      title="X"
      showHorizontalBar={false}
      styledTextClose={{
        position: "absolute",
        top: "16px",
        right: "0",
        marginRight: "10px",
        cursor: "pointer",
        left: "unset",
        fontSize: "14px",
        fontFamily: "ATC",
        fontWeight: 500,
      }}
    >
      <Title>We need some more details</Title>
      <>
        {renderForm(toClose, setSending, isSending)}
      </>
    </Modal>
  )
}

export default editAgentContainer(subscriptionContainer(FormModal))
