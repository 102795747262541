import React from "react"
import { Switch } from "antd"
import {
  AiToggleWrap,
} from "./aiToggleStyles"

const AiToggle = ({
  onChange,
  isAiEnabled,
  loading,
}) => (
  <AiToggleWrap>
    <Switch disabled={loading} loading={loading} checked={isAiEnabled} onChange={onChange} />
    <p>{`Ai Response ${isAiEnabled ? "ON" : "OFF"}`}</p>
  </AiToggleWrap>
)

export default AiToggle
