import React, { Component } from "react"
import styled from "styled-components"
import { reduxForm, Field, formValueSelector} from "redux-form"
import Container from "container/Academy"
import root from "window-or-global"
import { ToastContainer } from "react-toastify"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"

const Wrap = styled.div`
  height: 500px;
  width: 188px;
`
const HeaderList = styled.li`
  background-color: #f4f5f8;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  padding: 10px 10px 15px 15px;
`

const ListItem = styled.li`
  padding: 10px 10px 15px 15px;
  width: 100%;
  height: 37px;
  border-bottom: 1px solid #e2e4e8;
  font-size: 14px;
  ${props => props.isActive && `
    border-left: 3px solid orange;
   `}
   a {
    text-decoration: none;
    color: black;
    cursor: pointer;
   }
`

const MenuList = styled.ul`
  width: 100%;
  height: max-content;
  margin: 0;
  padding: 0;
  border: 1px solid #e2e4e8;
  border-radius: 3px;
  list-style: none;
`

const CTAWrap = styled.div`
  height: 300px;
  width: 100%;
  margin-top: 46px;

  button {
   width: 145px;
   height: 30px;
   border-radius: 4px;
   font-size: 16px;
   font-weight: 500;
   text-align: center;
  }
  .publish {
   background-image: linear-gradient(to bottom, #34ce57, #28a745);
   border: solid 1px #2a8b3f;
   color: white;
  }

  .draft {
   border: solid 1px rgba(27, 31, 35, 0.12) !important;
   background-color: #fafbfc;
   color: black;
  }
`

class InstructorsMenu extends Component {
 componentDidMount(){
 }

 render(){
  const {
   submitFunc,
   isUpdateMode,
   isPublished,
   createInstructorResponse,
  } = this.props

  const {
   isError,
   error,
  } = createInstructorResponse

  if(isError) {
   console.log(isError, error)
  }

  const globalRegex = RegExp(/([0-9][a-z])\w+/g, 'g');
  let res = globalRegex.test(root.location.pathname);
  return (
   <Wrap>
     <MenuList>
      <HeaderList> People </HeaderList>
      <ListItem
       isActive={res && root.location.pathname.indexOf('settings') <= -1}
      >
       <Link
       to="/academy-admin/people"
       >
        Host / Instructors
       </Link>
      </ListItem>
      {false && (
       <ListItem
        isActive={root.location.pathname.indexOf('settings') > -1}
       >
        <Link
        to={`/academy-admin/people/${isUpdateMode}/settings`}
        >
         Settings
        </Link>
       </ListItem>
      )}
     </MenuList>
     <CTAWrap>
     </CTAWrap>
     <ToastContainer />
   </Wrap>
  )
 }
}

export default Container(InstructorsMenu)
