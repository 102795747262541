import Cookies from "react-cookies"
import cookie from "services/CookieStorage"
import root from "window-or-global"
import urlParser from "url-parse"
import queryString from "query-string"
import moment from 'moment';
import { isEmpty, capitalize, random } from "lodash"

export function getLastWeek(flag){
 if(flag) {
  return moment().subtract(1, 'weeks').startOf('isoWeek').unix()
 }

 return moment().subtract(1, 'weeks').endOf('isoWeek').unix()
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function LoadScript(url, callback) {
  const script = document.createElement("script")
  script.type = "text/javascript"

  if (script.readyState) {
    //IE
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    //Others
    script.onload = function () {
      callback()
    }
  }

  script.src = url
  document.getElementsByTagName("head")[0].appendChild(script)
}

export function UrlSearchParams(queryString) {
  const results = new RegExp(`[?&]${queryString}=([^&#]*)`).exec(window.location.href)
  if (results == null) {
    return null
  }
  return decodeURI(results[1]) || 0
}

export function getEpoch(customDate = new Date()) {
  return window.parseInt(customDate.getTime() / 1000)
}

export const unixToDate = (input) => {
  try {
    const a = new Date(0)
    a.setUTCSeconds(input)
    return a
  } catch (e) {
    return "invalid date"
  }
}

export const getStoreKeyForReferrals = (type) => {
  switch (type) {
    case "incoming":
      return "inboundReferrals"
    case "outgoing":
      return "outboundReferrals"
    case "isa":
      return "isaReferrals"
    default:
      return "referral"
  }
}

export const checkForUserObject = (type) => {
  if (type) {
    if (type === "user") {
      return Cookies.load(type) || {}
    }
    return Cookies.load(type)
  }
  const leadAgreementSigned = Cookies.load("leadAgreementSigned")
  const referralManager = Cookies.load("referralManager")
  const isCrsDesignee = Cookies.load("isCrsDesignee")
  const allowLeadAgreement = Cookies.load("allowLeadAgreement")
  const isaLeadAgreementSigned = Cookies.load("isaLeadAgreementSigned")
  const gmail_email = Cookies.load("gmail_email")
  const office_short_state_name = Cookies.load("office_short_state_name")
  const office_city = Cookies.load("office_city")
  const profile_name = Cookies.load("profile_name")
  return {
    leadAgreementSigned,
    referralManager,
    isCrsDesignee,
    allowLeadAgreement,
    isaLeadAgreementSigned,
    gmail_email,
    office_short_state_name,
    office_city,
    profile_name,
  }
}

export const saveUserObjectToCookie = (data) => {
  //console.log("saving", data)
  Object.keys(data).forEach((key) => {
    //console.log(`Saving ${key}: `, data[key])
    cookie.save(key, data[key])
  })
}

export const isSuccess = (res = {}) => res && (res.status >= 200 && res.status <= 300)

export const getImageBasedOnDPI = (url = "", res) => {
  const FALLBACK_IMG = "http://d3h12z5cxndkqx.cloudfront.net/timelineIcons/submitted-deactivated@2x.png"
  if (!url) return FALLBACK_IMG
  let fileFormat = ""
  for (let i = url.length; i >= 0; i -= 1) {
    fileFormat += url[i - 1]
    if (url[i - 1] === ".") {
      break
    }
  }
  fileFormat = fileFormat
    .split("")
    .reverse()
    .join("")
  const urlBreak = url.split(fileFormat)
  const size = res ? `@${res}x` : ""
  return `${urlBreak[0]}${size}${fileFormat}`
}

export const isCRS = () => checkForUserObject("isCrsDesignee") === "true" && checkForUserObject("allowLeadAgreement") === "false"

export const isTrueCRS = () => checkForUserObject("isCrsDesignee") === "true"

export const getReferralManager = () => {
  let referralManager = cookie.load("referralManager")
  if (!referralManager || referralManager === "undefined" || referralManager === "null") {
    referralManager = {
      firstname: "Jessica",
      lastname: "Cortez",
      email: "jessica@radiusagent.com",
      phone: "(415) 547-0552",
      title: "Hi there! Do you have any questions about Radius Assist?",
      photo: "https://d3cyzvjm1vex6o.cloudfront.net/user/7792851/profile/1539969408880.jpeg",
      calendly_links: {
        concierge_mobile: "https://calendly.com/radiusagent/concierge-intro-m",
        isa_web: "https://calendly.com/radiusagent/isa-new",
        isa_demo_link: "https://calendly.com/radiusagent/isa-interest-w",
        isa_mobile: "https://calendly.com/radiusagent/isa-interest-m",
        concierge_web: "https://calendly.com/radiusagent/concierge-intro-w",
      },
      isa_demo_link: "https://calendly.com/jessicacortez/isa-lead-qualification-interest",
      id: 7787352,
    }
  }
  return referralManager
}

export const isLinkActive = (url) => {
  const { pathname } = window.location || ""
  return pathname.split("/").includes(url)
}

export const phoneNumberFormatter = phone => phone.replace(/(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/, (_, p1, p2, p3, p4) => {
  let output = ""
  if (p1) output = `${p1}`
  if (p2) output += `${p2}-`
  if (p3) output += `${p3}-`
  if (p4) output += `${p4}`
  return output
})

export const isBrowser = () => {
  try {
    if (window || navigator) {
      return true
    }
  } catch (e) {
    return false
  }
  return false
}

export const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
  const reader = new FileReader()
  const newBlob = blob.slice(0, blob.size, "image/jpeg")
  reader.onerror = reject
  reader.onload = () => {
    resolve(reader.result)
  }
  reader.readAsDataURL(newBlob)
})

export function searchToObject() {
  const pairs = root.location.search.substring(1).split("&")

  const obj = {}

  let pair

  let i

  for (i in pairs) {
    if (pairs[i] === "") continue
    pair = pairs[i].split("=")
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
  }

  return obj
}
export const GetUserPlatform = () => {
  if (/Android/i.test(navigator.userAgent)) {
    return "android"
  }
  if (/iPhone/i.test(navigator.userAgent)) {
    return "ios"
  }
  if (/iPad/i.test(navigator.userAgent)) {
    return "ipad"
  }
  return "desktop"
}

export const getQueryFromURL = (url) => {
  // Check for agentId first
  let finalQuery = ""
  if (url.indexOf("profile/agent?id") !== -1) {
    const location = urlParser(url)
    const query = queryString.parse(location.query)
    finalQuery = `?agentId=${query.id}`
  } else if (url.indexOf("agentId") !== -1) {
    // AgenId exists, now get it.
    const location = urlParser(url)
    const query = queryString.parse(location.query)
    finalQuery = `?agentId=${query.agentId}`
  } else if (url.indexOf("/profile/" !== -1)) {
    //we don't have Agent ID, so let's use profileName
    let userId = url.replace("/profile/", "")
    const queryIndex = userId.indexOf("?")
    if (queryIndex !== -1) {
      userId = userId.substring(0, queryIndex)
    }
    if (!userId) {
      return null
    }
    if (url.indexOf("/feed/") !== -1) {
      const { pathname = "" } = urlParser(url)
      const pathArray = pathname.split("/")
      return pathArray[3]
    }
    finalQuery = `?profileName=${userId}`
  } else {
    return ""
  }
  return finalQuery
}

export const canUserEdit = (apiId) => {
  try {
    const user = cookie.load("user")
    if (isBrowser() && user && user.id && parseInt(user.id, 0) === parseInt(apiId, 0)) {
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

export const getFromCookie = (type) => {
  try {
    const res = cookie.load(type)
    return res
  } catch (e) {
    return false
  }
}

export const getUserIdFromCookie = () => {
  try {
    const user = cookie.load("user")
    if (isBrowser() && user && user.id) {
      return user.id
    }
  } catch (e) {
    // handle it
  }
  return ""
}

export const removeCommaFromNumber = (val = "") => {
  try {
    if (typeof val === "number") {
      return val
    }
    if (val && typeof val === "string") {
      return parseInt(val.replace(/,/g, ""), 0)
    }
    return val
  } catch (e) {
    return val
  }
}

export const objectToQueryParams = (obj) => {
  const str = []
  // console.log(obj)
  Object.keys(obj).forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      if (obj[key]) {
        str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      }
    }
  })
  return str.join("&")
}

export const convertUnicode = input => input.replace(/\\u(\w\w\w\w)/g, (a, b) => {
  const charcode = parseInt(b, 16)
  return String.fromCharCode(charcode)
})

export const getOnlyNotNullValues = (obj) => {
  const returnObject = {}
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      returnObject[key] = obj[key]
    }
  })

  return returnObject
}
export const addHttpInURl = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    url = `http://${url}`
  }
  return url
}

export const getProfileUrl = (data) => {
  if (!data) {
    return ""
  }
  const {
    profile_name, agentId, profile_url, id,
  } = data
  if (profile_name) {
    return `/profile/${profile_name}`
  }
  if (agentId || id) {
    return `/profile/agent?id=${agentId || id}`
  }
  if (profile_url) {
    return profile_url
  }
  return "#"
}

export const convertStringToUnicode = str => unescape(encodeURIComponent(str))

const jsxDecimalToHex = (d, padding) => {
  let hex = Number(d).toString(16)
  const paddingResult = typeof padding === "undefined" || padding === null ? 2 : padding

  while (hex.length < paddingResult) {
    hex = `0${hex}`
  }

  return hex
}

export const unicodeToEscaped = (str) => {
  let escapedCode = ""
  let decValue = 0

  for (let i = 0; i < str.length; i += 1) {
    decValue = str.charCodeAt(i)
    if (decValue > 127) {
      escapedCode += `\\u${jsxDecimalToHex(decValue, 4)}`
    } else {
      escapedCode += str.charAt(i)
    }
  }

  return escapedCode
}

// export const escapedToUnicode = (escapedCode) => {
//   const regExp = /\\u([\d\w]{4})/gi
//   const escapedCodeTemp = escapedCode.replace(regExp, function (match, group) {
//     return String.fromCharCode(parseInt(group, 16))
//   })
//   const unicode = unescape(escapedCodeTemp)

//   return unicode
// }

export const getRandomSeedValue = (min = 1000000000, max = 9000000000) => Math.floor(Math.random() * (max - min)) + min

export const isQualifiedProspects = () => root.location && root.location.pathname === "/referrals/prospects/pipeline"

export const isInboundReferrals = () => root.location && root.location.pathname === "/referrals/inbound"

export const isAuthenticated = () => {
  const user = checkForUserObject("user")
  return !isEmpty(user)
}

export const getFirstCharacterCapital = word => word.charAt(0).toUpperCase() + word.slice(1)

export const getAssociationFromCookies = {
  isWcr: () => cookie.load("isWcr"),
  isAreaa: () => cookie.load("isAreaa"),
  isSamcar: () => cookie.load("isSamcar"),
  isCrsDesignee: () => cookie.load("isCrsDesignee"),
}

export const deleteArrayOfCookies = (keyArray) => {
  keyArray.forEach((key) => {
    cookie.remove(key)
  })
}

export const formatMoney = (amount) => {
  try {
    const decimalCount = 2
    const decimal = "."
    const thousands = ","

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(Math.abs(Number(amount) || 0).toFixed(decimalCount), 10).toString()
    let j = (i.length > 3) ? i.length % 3 : 0

    return negativeSign + (j ? i.substr(0, j) + thousands : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "")
  } catch (e) {
    console.log(e)
  }
}

export const getProductFromPlan = planId => planId.substring(0, 3)

export const toggleBooleanFromInteger = (integerValue) => (!!integerValue ? 0 : 1)

export const formatSelectedValue = value => value && capitalize(value.replaceAll("_", " "))

export const downloadFileByURL = (url = "", name) => {
  if (!url) {
    return ""
  }
  return fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = name || (new Date().getTime());
      link.click();
  }).catch(console.error);
}

export const getRandomColor = () => {
  const RANDOM_COLORS = [
    "#1abc9c",
    "#2ecc71",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#16a085",
    "#27ae60",
    "#2980b9",
    "#8e44ad",
    "#2c3e50",
    "#f1c40f",
    "#e67e22",
    "#e74c3c",
    "#ecf0f1",
    "#95a5a6",
    "#c0392b",
    "#d35400",
    "#f39c12",
  ]
  return RANDOM_COLORS[random(RANDOM_COLORS.length)]
}

export const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, "")
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return `${onlyNums}`
    }
    if (onlyNums.length === 6) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}

export const validateEmail = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    false : true
)
