import React from 'react';
import moment from "moment";
import _ from "lodash";
import "./CallHistory.css";
const statusMapper = {
    "completed" : "connected",
    "no-answer" : "No answer",
    "no-answer-machine" : "Voice mail",
    "no-answer-RM" : "Incoming call missed by Consultant",
    "failed" : "Failed",
    "busy" : "Busy",
    "in progress" : "In progress",
    "call-start" : "Call started",
    "busy-RM" : "Consultant was busy"
};
const callHistory = props => {
      return (props.timelineItems && props.timelineItems.length > 0)
      ? ( <div>
          <div className={"call-history-stats flex-align"}>
            <div>
              <p><small>{props.timelineItems.length}</small> Calls Attempted</p>
            </div>
            <div className={"flex-justify-space"}>
              <p>
                <small>{_.filter(props.timelineItems,{status : 'completed'}).length}</small> Connected
              </p>
              <p>
                <small>{_.filter(props.timelineItems,{status : 'no-answer-machine'}).length}</small> Voicemail
              </p>
              <p>
                <small>{_.filter(props.timelineItems,{status : 'busy'}).length}</small> Busy
              </p>
              <p>
                <small>{_.filter(props.timelineItems,{status : 'failed'}).length}</small> Failed
              </p>
            </div>
          </div>
          <ul className={"timeline-list"}>
            {props.timelineItems.map((detail, index) => (
              <li key={"timeline-" + index}>
                <div className={"content timeline-item"}>
                  <div className={"flex-align"}>
                      <div className={"activity-time"}>
                          <p>{moment(detail.created_at * 1000).format("MMMM Do")}</p>
                          <p>{moment(detail.created_at * 1000).format("h:mm a")}</p>
                      </div>
                      <div className={"activity-description"} data-status={detail.status}>
                          <p>{statusMapper[detail.status]}</p>
                      </div>
                    
                  </div>
                </div>
              </li>
            ))}
          </ul>
          </div> )
    : ( <div className={"empty-state"}>
        <p>No call attempts</p>
      </div>)
  };

export default callHistory;