import { call, put, throttle, takeLatest } from "redux-saga/effects"
import * as AgentActions from "./actions"
import cookies from "react-cookies"
import { isSuccess } from "services/Utils"
import * as APIs from "./api"
import { ShowErrorToast } from "../../lib/ErrorUtils";
import { get } from "lodash";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

function* fetchAgentSearch(action) {
  try {
    const { search } = action.data
    console.log(search)
    const payload = {
      "text_value": search,
      "sortIsAsc": false,
      "ascending": false,
      "facade": false,
      "from": 0,
      "size": 10,
      "text_search_params": ["email", "full_name"],
      "unRegistered": false
    };
    const res = yield call(APIs.searchAgentByNameOrEmailAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AgentActions.fetchAgentSearchAction.success(response))
    }
  } catch (error) {
    yield put(AgentActions.fetchAgentSearchAction.failure(error))
  }
}

function* fetchAgentSecureId(action) {
  try {
    const { agentId } = action.data
    const res = yield call(APIs.fetchAgentSecureId, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      cookies.remove("secureId")
      cookies.save("secureId", response.secure_id)
      yield put(AgentActions.fetchAgentSecureIdAction.success(response))
    }
  } catch (error) {
    alert(error)
    yield put(AgentActions.fetchAgentSecureIdAction.failure(error))
  }

}


function* fetchStripePlans(action) {
  try {
    const res = yield call(APIs.fetchStripePlans)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AgentActions.fetchStripePlansAction.success(response))
      let parentBenefitPlans = get(response, 'benefit_plans', null);
      let allPlanIds = [];
      // Todo
      if(parentBenefitPlans && Object.keys(parentBenefitPlans).length) {
        // Object.keys(parentBenefitPlans).forEach(benefits => {
        //   parentBenefitPlans[benefits] = parentBenefitPlans[benefits].find(benf => benf.is_add_on_plan);
        // })
        // parentBenefitPlans = Object.keys(parentBenefitPlans)
        Object.keys(parentBenefitPlans).forEach(plans => {
          allPlanIds = [...allPlanIds, ...parentBenefitPlans[plans].map(p => p.plan_id)]
        });
      }
      yield put(AgentActions.setParentBenefitsPlansAction.call({ parentBenefitPlans: allPlanIds }))
    }

  } catch (error) {
    yield put(AgentActions.fetchStripePlansAction.failure(error))
  }
}

function* fetchSubscriptions(action) {
  try {
    const { agentId } = action.data
    const res = yield call(APIs.fetchSubscriptions, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      console.log("asdsadasdsad");
      console.log(response);
      yield put(AgentActions.fetchSubscriptionsAction.success(response))
    }

  } catch (error) {
    yield put(AgentActions.fetchSubscriptionsAction.failure(error))
  }
}

function* checkoutPlan(action) {
  try {
    const {
      tokenId,
      secure_id,
      //modifiedBenefitArray: benefitIds,
      addedBenefits,
      agentId,
      plansMap,
    } = action.data
    let payload = {
      benefitIds: addedBenefits.map(benf => benf.benefit_id),
      plansMap,
    }

    let config = {
      headers: {
        'secure-id': secure_id,
      }
    }

    if (tokenId != '') {
      payload.tokenId = tokenId;
    }

    const res = yield call(APIs.checkoutV2, payload, config)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AgentActions.checkoutPlanAction.success(response))
      yield put(AgentActions.setBenefitsToBeAddedAction.call({ addedBenefits: [], alreadyAddedFeatures: addedBenefits }))
      yield put(AgentActions.fetchSubscriptionsAction.request({ agentId }))
    }
  } catch (error) {
    yield put(AgentActions.checkoutPlanAction.failure(error))
  }

}


function* fetchUserReferralSettings(action) {
  try {
    const { agentId } = action.data
    const res = yield call(APIs.fetchUserReferralSettings, agentId)

    if (isSuccess(res)) {
      const { response } = res.data
      console.log("fetchUserReferralSettings success")
      console.log(response)
      yield put(AgentActions.fetchUserReferralSettingsAction.success(response))

    }

  } catch (error) {
    yield put(AgentActions.fetchUserReferralSettingsAction.failure(error))
  }

}

function* handleCouponApply(action) {
  try {
    const { couponCode, plans, agentId, currentMode } = action.data
    const payload = {
      agentId,
      plans,
      couponCode,
    }

    const res = yield call(APIs.applyPricingCouponAPI, payload)
    if (isSuccess(res)) {
      const { trialInfo } = res.data.response
      let payload
      if (trialInfo) {
        const { trialDays, planId, discountAmount } = trialInfo
        payload = {
          isValid: true,
          planId,
          trialDays,
          couponCode,
          discountAmount,
        }
      } else {
        payload = {
          isValid: false,
          planId: "",
          trialDays: null,
          couponCode,
        }
      }
      yield put(AgentActions.handleCouponApplyAction.success(payload))
      yield put(AgentActions.saveCouponStateAction.call({ currentMode, payload }))
    }
  } catch (e) {
    yield put(AgentActions.handleCouponApplyAction.failure(e))
  }
}

function* handleInstallmentFetch(action) {
  const {
    planId
  } = action.data
  try {
    const res = yield call(APIs.getCurrentPlanInstallmentsAPI, planId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AgentActions.getCurrentPlanInstallmentsAction.success(response))
    }

  } catch (error) {
    yield put(AgentActions.getCurrentPlanInstallmentsAction.failure(error))
  }
}

function* handleCustomizedSub(action) {
  const {
    benefitIds,
    subscriptionIds,
    rtl_threshold,
    team_rtl_threshold,
    agentId,
    agentSecureId,
    benefitIdsToAdd,
    benefitIdsToRemove,
    addedBenefits,
    alreadyAddedFeatures
  } = action.data

  const payload = {
    benefitIds,
    subscriptionIds,
    rtl_threshold,
    team_rtl_threshold,
    benefitIdsToAdd,
    benefitIdsToRemove,
  }

  let config = {
    headers: {
      'secure-id': agentSecureId,
    }
  }

  try {
    // yield put(AgentActions.fetchSubscriptionsAction.request({ agentId }))
    const res = yield call(APIs.customizedSubscriptionSettingsAPI, payload, config)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AgentActions.setBenefitsToBeAddedAction.call({ addedBenefits, alreadyAddedFeatures: addedBenefits }))
      yield put(AgentActions.fetchSubscriptionsAction.request({ agentId }))
      yield put(AgentActions.setCustomizedSubscriptionSettingsAction.success(response))
    }
  } catch (error) {
    yield put(AgentActions.fetchSubscriptionsAction.request({ agentId }))
    yield put(AgentActions.setCustomizedSubscriptionSettingsAction.failure(error))
    ShowErrorToast(`${error.response.data.error.message}. Please check slack channel #isa_notifications for more details`);
  }
}

function* handleGetAutoRefer(action) {
  const {
    agentId,
  } = action.data
  try {
    const res = yield call(APIs.getAutoReferOutSettings, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AgentActions.getCurrentAutoReferOutSettingsActions.success(response))
    }

  } catch (error) {
    yield put(AgentActions.getCurrentAutoReferOutSettingsActions.failure(error))
  }
}

function* handleSetAutoRefer(action) {
  const {
    agentId,
    autoReferVal,
    updatingAutoRefVal,
    agentWebsite,
    updatingWebsite,
    doNotAutoSubscribeToAssist,
    updatingDisableAutoAssistSubscription,
    maxCall,
    updatingMaxCall,
  } = action.data
  const payload = {}
  if (updatingAutoRefVal) {
    payload.auto_refer_out_leads = autoReferVal
  }

  if (updatingWebsite) {
    payload.agent_website = agentWebsite
  }

  if (updatingDisableAutoAssistSubscription) {
    payload.disable_auto_assist_subscription = doNotAutoSubscribeToAssist
  }

  if (updatingMaxCall) {
    payload.max_call = maxCall
  }

  if (Object.keys(payload).length === 0) {
    yield put(AgentActions.getCurrentAutoReferOutSettingsActions.request({ agentId }))
  } else {
    try {
      const res = yield call(APIs.applyAutoReferOutSettings, agentId, payload)
      if (isSuccess(res)) {
        const { response } = res.data
        yield put(AgentActions.setCurrentAutoReferOutSettingsActions.success(response))
        yield put(AgentActions.getCurrentAutoReferOutSettingsActions.request({ agentId }))

      }

    } catch (error) {
      yield put(AgentActions.setCurrentAutoReferOutSettingsActions.failure(error))
    }
  }
}


export default function* main() {
  yield throttle(1000, AgentActions.fetchAgentSearchAction.REQUEST, fetchAgentSearch)
  yield throttle(500, AgentActions.fetchStripePlansAction.REQUEST, fetchStripePlans)
  yield throttle(500, AgentActions.fetchSubscriptionsAction.REQUEST, fetchSubscriptions)
  yield throttle(500, AgentActions.checkoutPlanAction.REQUEST, checkoutPlan)
  yield throttle(500, AgentActions.fetchAgentSecureIdAction.REQUEST, fetchAgentSecureId)
  yield throttle(500, AgentActions.fetchUserReferralSettingsAction.REQUEST, fetchUserReferralSettings)
  yield takeLatest(AgentActions.handleCouponApplyAction.REQUEST, handleCouponApply)
  yield takeLatest(AgentActions.getCurrentPlanInstallmentsAction.REQUEST, handleInstallmentFetch)
  yield takeLatest(AgentActions.setCustomizedSubscriptionSettingsAction.REQUEST, handleCustomizedSub)
  yield takeLatest(AgentActions.setCurrentAutoReferOutSettingsActions.REQUEST, handleSetAutoRefer)
  yield takeLatest(AgentActions.getCurrentAutoReferOutSettingsActions.REQUEST, handleGetAutoRefer)

}
