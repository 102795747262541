import React from "react"
import styled from "styled-components"

export const getProspectStatus = (prospect_status) => {
  if (prospect_status === "assigned") {
    return "Unassign"
  }
  if (prospect_status === -1) { // Recconded
    return "Invite";
  }
  if (prospect_status === 0) {
    return "Assign"
  }
  if (prospect_status === 1) {
    return "Unassign"
  }
  if (prospect_status === 2) { // rejected
    return "";
  }
  if (prospect_status === 6) {
    return "Assign";
  }
  return "Unknown"
}

const LabelWrap = styled.div`
  padding: 3px 8px;
  border-radius: 5px;
  background: #999;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  margin-top: 4px;
`

export const getPropectsLabel = (prospect_status) => {
  if (prospect_status === "assigned" || prospect_status === 1) {
    return (
      <LabelWrap>
        Assigned
      </LabelWrap>
    )
  }
  if (prospect_status === -1) {
    // CTA should
    return (
      <LabelWrap>
        Reccomended
      </LabelWrap>
    )
  }
  if (prospect_status === 0) {
    return (
      <LabelWrap>
        Invite Sent
      </LabelWrap>
    )
  }
  if (prospect_status === 2) {
    return (
      <LabelWrap>
        Rejected
      </LabelWrap>
    )
  }
  if (prospect_status === 6) {
    return (
      <LabelWrap>
        Partially Claimed
      </LabelWrap>
    )
  }
  return ""
}

