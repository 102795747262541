import React from "react"
import {
  Form,
  Input,
} from "antd"
import { isEmpty } from "lodash"
import {
  FormWrap,
  FlexRow,
  CustomDatePicker,
} from "./commonStyles"
import {
  SubmitFormButton,
  CancelFormButton,
} from "./commonComponents"

const DatePickerStyle = {
  width: "300px",
  height: "39px",
  padding: "10px 14px",
  borderRadius: "4px !important",
  background: "var(--White, #FFF)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  border: "1px solid #D9D9D9 !important",
}

const TeamCreationForm = ({
  onFinishHandle,
  isSending: submitting,
  toClose,
  setDateSelected,
  isDateSelected,
}) => (
  <FormWrap>
    <Form
      name="invite_without_subscription_form"
      layout="vertical"
      size="large"
      onFinish={onFinishHandle}
      onFinishFailed={() => { }}
      autoComplete="off"
    >
      <FlexRow>
        <Form.Item
          label="Subscription Start Date"
          name="credit_card_pop_up_blocker_time"
        >
          <CustomDatePicker
            style={{
              ...DatePickerStyle,
            }}
            allowClear={false}
            // value={!isEmpty(licenseTransferredDate) ? moment(licenseTransferredDate) : licenseTransferredDate}
            onChange={() => setDateSelected(true)}
          />
        </Form.Item>
      </FlexRow>
      <FlexRow
        gap="10px"
      >
        <SubmitFormButton
          submitting={submitting}
          title={isDateSelected ? "Submit" : "Submit/Skip"}
        />
        <CancelFormButton onClick={toClose} />
      </FlexRow>
    </Form>
  </FormWrap>
)

export default TeamCreationForm
