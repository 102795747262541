import React, { Component } from "react"
import { Provider } from "react-redux"
import Header from "./components/Header"
import { isEmpty } from "lodash"
import md5 from "md5"
import axios from "axios"
import "services/axiosConfig"
import "antd/dist/antd.min.css"
import Notifications from "react-notify-toast"
import { store } from "store/config"
import Dashboard from "./components/Dashboard"
import ShareTheLove from "./components/ShareTheLove"
import RecruitAndEarn from "./dumbComponents/RecruitAndEarn"
import RecruitAndEarnAnalytics from "./dumbComponents/RecruitAndEarn/Analytics"
import UpcomingCalls from "./components/UpcomingCalls"
import Messages from "./components/Messages"
import RecommendationToggler from "./components/RecommendationToggler"
import Accounts from "./components/Accounts/Accounts"
import AutoCallerSettings from "./components/AutoCaller/AutoCallerSettings"
import "./App.css"
import { BrowserRouter as Router, Route } from "react-router-dom"
import Cookies from "universal-cookie"
import {SetBugsnagUser} from "./lib/ErrorUtils"
import Login from "./pages/Login"
import Agent from "./dumbComponents/Agent"
import Subscription from "./dumbComponents/Agent/Subscriptions"
import Checkout from "./dumbComponents/Agent/CheckoutPlan"
import AgentNotificationsDashboard from "./components/AgentNotificationsDashboard"
import RAR from "./components/RAR"
import BlinkHeader from "./components/BlinkHeader"
import RARTeam from "./components/RARTeam"
import BrokerageTeam from "./components/BrokerageTeam"

import AgentDeals from "./components/AgentDeals"
import Deals from "./components/Deals"
import EmailGenerator from "./dumbComponents/EmailGenerator"
import MultipleCalled from "./dumbComponents/MultipleCaller"
import AcademyIndex from "./dumbComponents/Academy"
import AcademyHeader from "./dumbComponents/Academy/Header"
import CallDashboard from "./dumbComponents/Dashboards/Reporting"
import EventRoomDashboard from "./dumbComponents/EventRooms"
import ScheduledRooms from "./dumbComponents/ScheduledRooms"
import RecordingDashboard from "./dumbComponents/RoomsRecordings"
// import FinancingDashboard from "./dumbComponents/FinancingDashboard"

import ProductInterest from "./dumbComponents/ProductInterestModal"
import BrokerDashbaord from "./dumbComponents/BrokerDashboard"
import CreateNewAgentProfile from "./dumbComponents/Authentication/SignUp"


const DISABLED_PATHS = [
 "/dashboard",
 "/messages",
 "/agents",
 "/toggle-recommendations",
 "/upcoming-calls",
 "/accounts",
 "/settings",
 "/messages",
 "/academy-admin",
 "/recruit-and-earn",
 "/caller-dashboard",
 "/product-interest-admin",
 "/agent-notifications",
 "/share-the-love",
 "/email-generator",
 "/rar",
 "/deals/agent",
 "rar/teams",
 "teams/create",
 "teams/edit",

]



const ACADEMY_PATH = [
  "/academy",
  "/academy-admin",
  "/academy-admin/courses",
  "/academy-admin/people",
  "/academy-admin/events",
]

const cookies = new Cookies()
const baseUrl = process.env.REACT_APP_API_BASE_URL
const token = process.env.REACT_APP_SECRET_ID
const allowedAccountIds = [4, 5, 20, 37, 40] //Biju, Sanya, Craig and Greg

class App extends Component {
  constructor(props) {
    super(props)
    const user = cookies.get("user")
    this.state = {
      user,
      authFail: false,
      isMounted: false,
    }
  }

  isShowHeaderAndMulticaller = (pathname) => {
   return !DISABLED_PATHS.find(path => pathname.includes(path))
  }

  isShowAcademyHeader = (pathname) => {
   return ACADEMY_PATH.find(path => pathname.includes(path))
  }

  componentDidMount() {
    const axiosCopy = axios.create()
    axiosCopy.interceptors.request.use((config) => {
      config.headers.common = {}
      return config
    })

    const {  user } = this.state
    this.setState({
      isMounted: true,
    })
    const { origin, pathname } = window.location
    if (isEmpty(user) && pathname !== "/login") {
      window.location = `${origin}/login`
    } else {
      const isSoul = window.location.origin.includes("soul.")
    }
  }

  render() {
    let viewAccountsPage = true
    const { isMounted, user } = this.state
    const { pathname } = window.location
    const isLogged = !isEmpty(user)
    if (this.state.user && allowedAccountIds.indexOf(this.state.user.id) !== -1){
      viewAccountsPage = true
    }
    if (!isMounted || !isLogged) {
      return (
        <Router>
          <Route exact path="/login" component={Login} />
        </Router>
      )
    }
    return (
      <Provider store={store}>
        <div>
          <BlinkHeader />
          <Notifications />
          {
            this.isShowAcademyHeader(pathname) && (
             <AcademyHeader user={user}/>
          )}
          <Router>
            <div className="App">
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/email-generator" component={EmailGenerator} />
              <Route exact path="/share-the-love" component={ShareTheLove} />
              <Route exact path="/recruit-and-earn" component={RecruitAndEarn} />
              <Route exact path="/agent-invite-analytics/:agentId" component={RecruitAndEarnAnalytics} />
              <Route exact path="/rar" component={RAR} />
              <Route exact path="/agents" component={Agent} />
              <Route path="/academy-admin" component={AcademyIndex} />
              <Route path="i" component={ProductInterest} />
              <Route path="/product-interest-admin/child-view" component={ProductInterest} />
              <Route path="/caller-dashboard" component={CallDashboard} />
              <Route exact path="/agents/:agentId" component={Subscription} />
              <Route path="/broker" component={(props) => <BrokerDashbaord user={this.state.user} {...props}/>} />
              <Route exact path="/agents/:agentId/checkout" component={Checkout} />
              <Route exact path="/toggle-recommendations" component={RecommendationToggler} />
              <Route exact path="/upcoming-calls" component={() => <UpcomingCalls user={this.state.user} />} />
              <Route exact path="/messages" component={() => <Messages user={this.state.user} />} />
              <Route exact path="/agent-notifications" component={() => <AgentNotificationsDashboard />} />
              <Route exact path="/deals/agent/:agentId" component={() => <AgentDeals reset ={true}/>} />
              <Route exact path="/deals/agent/:agentId/create" component={() => <Deals reset={true}/>} />
              <Route exact path="/deals/agent/:agentId/edit/:dealId" component={() => <Deals reset={true}/>} />
              <Route exact path="/teams/create" component={() => <BrokerageTeam reset={true}/>} />
              <Route exact path="/teams/edit/:teamId" component={() => <BrokerageTeam reset={true}/>} />
              <Route path="/event-rooms" component={EventRoomDashboard} />
              <Route path="/scheduled-rooms" component={ScheduledRooms} />
              <Route path="/rooms" component={RecordingDashboard} />
              {/* <Route path="/financing" component={FinancingDashboard} /> */}

              <Route
                exact
                path="/"
                component={() => (
                  <React.Fragment>
                    <Header user={user} />
                    <MultipleCalled user={user} />
                  </React.Fragment>
                )}
              />
              {viewAccountsPage && (
                <div className="bg-white">
                  <Route exact path="/accounts" component={Accounts} />
                  <Route exact path="/settings" component={AutoCallerSettings} />
                </div>
              )}
              <Route exact path="/rar/teams" component={RARTeam}/>
            </div>
          </Router>
        </div>
      </Provider>
    )
  }
}

export default App
