import React from "react"
import { Tabs } from "antd"
import styled from "styled-components"
import DealsList from "dumbComponents/BrokerDashboard/AgentDashboard/DealsList"
import PaymentInfo from "dumbComponents/BrokerDashboard/PaymentInfoTC"

const Wrap = styled.div`
  padding: 20px;
`

class DealsDashboard extends React.PureComponent {
  componentDidMount() {

  }

  render() {
    return (
      <Wrap>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Deals" key="1">
            <DealsList />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Payment Info" key="2">
            <PaymentInfo />
          </Tabs.TabPane>
        </Tabs>
      </Wrap>
    )
  }
}

export default DealsDashboard
