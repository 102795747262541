import axios from "axios"

const baseUrl = process.env.REACT_APP_API_BASE_URL
// const baseUrl = "http://localhost:3000"

export const getRoomsAPI = () => fetch(`${baseUrl}/v1.0/radius-club/event-rooms?fetch_live=true&is_immortal=true&limit=5000`).then(res => res.json())

export const getScheduledRoomsAPI = () => axios.get(`${baseUrl}/v1.0/radius-club/scheduled-event-rooms?following_host=true&room_not_created=true&only_upcoming=true&skip=0&limit=40`)

export const createRoomsAPI = bodyParam => fetch(`${baseUrl}/v1.0/radius-club/event-room-admin`, {
  method: "POST",
  body: JSON.stringify(bodyParam),
}).then(res => res.json())

export const updateRoomAPI = room => fetch(`${baseUrl}/v1.0/radius-club/event-room-admin-update/${room.room_id}`, {
  method: "POST",
}).then(res => res.json())

export const endRoomAPI = room => fetch(`${baseUrl}/v1.0/radius-club/event-room-admin-delete/${room.room_id}`, {
  method: "POST",
}).then(res => res.json())

export const getFinancingClientListApi = payload => axios.get(`${baseUrl}/v1.0/mortgage/clients?type=${payload.type}&skip=0&limit=50000`)

export const getFinancingSettingApi = () => axios.get(`${baseUrl}/v1.0/mortgage/settings`)

export const saveFinancingSettingApi = payload => axios.put(`${baseUrl}/v1.0/mortgage/settings`, payload)

// eslint-disable-next-line max-len
export const updateFinancingClientListApi = payload => axios.put(`${baseUrl}/v1.0/mortgage/clients-properties/${payload.clientId}`, payload.body)

// eslint-disable-next-line max-len
export const handleDeleteClientApi = payload => axios.delete(`${baseUrl}/v1.0/mortgage/clients-properties/${payload.clientId}`)

export const getAllJobsByTeamIdApi = query => axios.get(`${baseUrl}/v1.0/jobs?${query}`)

export const getJobApplicationsApi = query => axios.get(`${baseUrl}/v1.0/jobs-applications?${query}`)

export const updateJobApplicationsApi = ({ body, applicantId }) => axios.put(`${baseUrl}/v1.0/jobs-applications/${applicantId}`, body)

export const createJobsApi = ({ body }) => axios.post(`${baseUrl}/v1.0/jobs`, body)

export const updateJobsApi = ({ body, jobId }) => axios.put(`${baseUrl}/v1.0/jobs/${jobId}`, body)

export const deleteJobsApi = ({ jobId }) => axios.delete(`${baseUrl}/v1.0/jobs/${jobId}`)

export const getCashbackListApi = query => axios.get(`${baseUrl}/v1.0/cashback-details-admin?${query}`)

export const getCashbackAgentInfoApi = query => axios.get(`${baseUrl}/v1.0/cashback-detail-info?${query}`)

export const updateCashbackAgentInfoApi = ({ id, payload }) => axios.put(`${baseUrl}/v1.0/cashback-detail/${id}`, payload)

export const createCashbackAmountCreditsApi = ({ payload }) => axios.post(`${baseUrl}/v1.0/cashback-detail`, payload)

export const getCashbackAgentTimelineApi = query => axios.get(`${baseUrl}/v1.0/cashback-timelines?${query}`)

export const sendJobNotificationsApi = ({ jobId }) => axios.get(`${baseUrl}/v1.0/job-send-notification/${jobId}`)

export const getOnboardingCommissionAPI = query => axios
  .get(`${baseUrl}/v1.0/onboarding-commission-admin?${query}`)

export const createOnboardingCommissionAPI = payload => axios
  .post(`${baseUrl}/v1.0/onboarding-commission-admin`, payload)

export const updateOnboardingCommissionAPI = payload => axios
  .put(`${baseUrl}/v1.0/onboarding-commission-admin`, payload)

export const getRecordedRoomsContentAPI = (skip, limit) => axios
  .get(`${baseUrl}/v1.0/radius-club/event-rooms/getRecordedRooms?skip=${skip}&limit=${limit}`)

export const deleteRecordingAPI = payload => axios
  .post(`${baseUrl}/v1.0/radius-club/event-rooms/delete-recording`, payload)

export const updateScheduledRoomsAPI = payload => axios
  .post(`${baseUrl}/v1.0/radius-club/event-rooms-blink-request/${payload.id}/blink-dashboard?requestType=UPDATE`, payload)

export const deleteScheduledRoomsAPI = (id, allInstance, payload) => axios
  .post(`${baseUrl}/v1.0/radius-club/event-rooms-blink-request/${id}/blink-dashboard?requestType=delete${allInstance}`, payload)
