import React from "react"
import { Input, Radio, Space } from "antd"

const TeamAgreementRadio = ({
  onChange,
  value,
  isTeamMemberDashboard,
}) => (
  <Radio.Group onChange={onChange} value={value}>
    <Space direction="vertical">
      <Radio value={1}>
        {isTeamMemberDashboard ? "View & Download Custom team agreement template" : " Custom team agreement template"}
      </Radio>
      <Radio value={2}>
        {isTeamMemberDashboard ? "Use Radius team agreement template" : "Radius team agreement template"}
      </Radio>
      {/* {!isTeamMemberDashboard && (
        <Radio value={3}>N/A</Radio>
      )} */}
    </Space>
  </Radio.Group>
)
export default TeamAgreementRadio
