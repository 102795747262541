import React from "react"
import styled from "styled-components"
import { ShimmerBox } from "shared/styles/animation"
import {
  Card,
  SectionTitle,
} from "./commonStyles"
import { GetStatusUI } from "./mappings"
import ListDocs from "./ListDocs"
import { LiveDot } from "./index"

const ComponentWrap = styled.div`
  margin-top: 32px;
  position: relative;
`

const LiveDotWrap = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
`

const StatusBlock = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`

const LiveText = styled.p`
  display: block;
  margin: 0 10px;
  color: var(--gray-900,#18181B);
  font-family: ATC;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;

  ${props => props.hide && `
    display: none;
  `}
`

const MainBlock = ({
  isaDocuments,
  fetchingICADocuments,
  fullySignedDocument,
  fetchingFullySignedICA,
  isLiveLoaderShown,
}) => {
  const docStatus = isaDocuments && isaDocuments.length > 0 && isaDocuments[0].status

  console.log("isLiveLoaderShown ==>", isLiveLoaderShown)

  return (
    <ComponentWrap>
      <LiveDotWrap>
        <LiveText hide={!isLiveLoaderShown}>Checking for updates</LiveText>
        <LiveDot hide={!isLiveLoaderShown} />
      </LiveDotWrap>
      <Card>
        {!fetchingFullySignedICA
          && fullySignedDocument
          && (fullySignedDocument.length <= 0
          || Object.keys(fullySignedDocument).length <= 0) && (
          <SectionTitle>
            ICA Draft (Fill and Send the onboarding invite)
          </SectionTitle>
        )}
        {fetchingICADocuments || fetchingFullySignedICA && (
          <ShimmerBox w="766px" h="71px" />
        )}
        {!fetchingICADocuments && isaDocuments && isaDocuments.length > 0 && (
          <StatusBlock>
            {GetStatusUI(docStatus)}
          </StatusBlock>
        )}
        {!fetchingICADocuments
          && fullySignedDocument
          && (fullySignedDocument.length <= 0
            || Object.keys(fullySignedDocument).length <= 0)
          && (
            <ListDocs
              isaDocuments={isaDocuments}
              docStatus={docStatus}
              fetchingICADocuments={fetchingICADocuments}
            />
          )}
        {!fetchingFullySignedICA
          && fullySignedDocument
          && fullySignedDocument.length > 0 && (
          <ListDocs
            isaDocuments={fullySignedDocument}
            fetchingICADocuments={fetchingFullySignedICA}
            noIcon
            noView
            download
          />
        )}
      </Card>
    </ComponentWrap>
  )
}

export default MainBlock
