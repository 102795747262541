export const TC_STATUS = [
  { label: "Incomplete", value: "incomplete" },
  { label: "Contract Submitted", value: "submitted" },
  { label: "Active Listing", value: "active_listing" },
  { label: "Listing Verified", value: "listing_verified" },
  { label: "Audit Verified", value: "audit_verified" },
  { label: "Commission Verified", value: "commission_verified" },
  { label: "Demand Pending", value: "demand_pending" },
  { label: "Demand Completed", value: "demand_completed" },
  { label: "Closed", value: "closed" },
  { label: "Payment Received", value: "payment_received" },
  { label: "Archived", value: "archived" },
]

export const NEW_LISTING_SPECIFIC_STATUS = [
  { label: "Active Listing", value: "active_listing" },
  { label: "Listing Verified", value: "listing_verified" },
]

export const ACTIVE_LISTING_SPECIFIC_STATUS = [
  { label: "Incomplete", value: "incomplete" },
  { label: "Contract Submitted", value: "submitted" },
  { label: "Active Listing", value: "active_listing" },
  { label: "Listing Verified", value: "listing_verified" },
  { label: "Audit Verified", value: "audit_verified" },
  { label: "Commission Verified", value: "commission_verified" },
  { label: "Demand Pending", value: "demand_pending" },
  { label: "Demand Completed", value: "demand_completed" },
  { label: "Closed", value: "closed" },
  { label: "Payment Received", value: "payment_received" },
  { label: "Archived", value: "archived" },
]

export const getDefaultStatus = (value) => {
  if (value) {
    // Only for New Listing
    const newListingStatusIndex = NEW_LISTING_SPECIFIC_STATUS.findIndex(x => x.value === value)
    if (newListingStatusIndex !== -1) {
      return NEW_LISTING_SPECIFIC_STATUS[newListingStatusIndex]
    }
    const selectedItem = TC_STATUS.find(x => x.value === value)
    return selectedItem
  }
  return {
    // label: "Incomplete", value: "incomplete",
    label: "None", value: null,
  }
}
