import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { getRARTeamListAction, deleteRARTeamAction, getBrokerTeamsOverviewAction, getBrokerageTeamDownloadAction } from "./actions"

function mapStateToProps(state) {
  const { brokerDashboard } = state
  const { rarTeamListResponse, deleteRARTeamResponse, getBrokerTeamsOverviewResponse, teamsDownloadDataResponse } = brokerDashboard
  return {
    rarTeamListResponse,
    deleteRARTeamResponse,
    getBrokerTeamsOverviewResponse,
    teamsDownloadDataResponse,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      getRARTeamList: getRARTeamListAction.request,
      deleteRARTeam: deleteRARTeamAction.request,
      getBrokerTeamsOverview: getBrokerTeamsOverviewAction.request,
      getBrokerageTeamDownload: getBrokerageTeamDownloadAction.request,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)