/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react"
import { get } from "lodash"
import styled from "styled-components"
import { Input } from "antd"

import container from "container/BrokerDashboard/editAgentContainer"
import Loader from "dumbComponents/common/UI/Loader"
import Toggle from "dumbComponents/common/UI/Toggle"
import ImageUploader from "react-images-upload"
import Button from "dumbComponents/common/UI/Button"
import { useInput } from "dumbComponents/common/Hooks/InputHook"
import {
  emptyInput,
} from "dumbComponents/common/Utils"
import Select from "react-select"
import { Link } from "react-router-dom"
import root from "window-or-global"
import colors from "../../../lib/colors"
import DefaultImage from "./Images/Default.jpeg"

const Wrap = styled.div`
margin: 20px;
width: 40%;
`
const HeaderWrap = styled.div`
display: flex;
h1 {
  flex: 1;
  margin: 0px;
  font-weight: 400;
}
> div {

}
`
const UserInput = styled.input`
height: 24px;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
background-color: hsl(0,0%,100%);
border-color: hsl(0,0%,80%);
border-radius: 4px;
border-style: solid;
border-width: 1px;
cursor: default;
margin-top: 0px;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-wrap: wrap;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
-webkit-box-pack: justify;
-webkit-justify-content: space-between;
-ms-flex-pack: justify;
justify-content: space-between;
min-height: 38px;
outline: 0 !important;
position: relative;
-webkit-transition: all 100ms;
transition: all 100ms;
box-sizing: border-box;
padding: 9px;
`
const UserInputArea = styled.textarea`
height: 24px;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
background-color: hsl(0,0%,100%);
border-color: hsl(0,0%,80%);
border-radius: 4px;
border-style: solid;
border-width: 1px;
cursor: default;
margin-top: 0px;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-wrap: wrap;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
-webkit-box-pack: justify;
-webkit-justify-content: space-between;
-ms-flex-pack: justify;
justify-content: space-between;
min-height: 38px;
outline: 0 !important;
position: relative;
-webkit-transition: all 100ms;
transition: all 100ms;
box-sizing: border-box;
padding: 9px;
`
const EarnMoreButton = styled.button`
  border-radius: 4px;
  height: 28px;
  width: 154px;
  background: transparent;
  margin: 8px;
  border-radius: 100px;
  // color: ${colors.marineBLue};
  // border: 1px solid ${colors.marineBLue};
  position: relative;
  cursor: pointer;
`
const InputText = styled.input`
  height: 24px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  margin-top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  padding: 9px;
`

const SubHeader = styled.p`
font-size: 13px;
margin: 0px;
`
const FirstDiv = styled.div`
display: flex;
gap: 20px;
`
const InputDiv = styled.div`
margin-top: 10px;
${props => props.isImage && `
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`}
`
const TitleDiv = styled.div`
display: flex;
flex-direction: column;
`
const FileUpload = styled.div`
`
const FileButton = styled.input`
  margin-top: 8px;
  width: 200px;
  height: 40px;
`
const Line = styled.div`
  width: 100%;
  height: 2px;
  color: black;
`
const ShowFileName = styled.div`
  margin-top: 5px;
  font-size: 12px;
`
const LabelAndToggleWrap = styled.div`
display: flex;
align-items: center;
> p {
  flex: 1;
}
`
const ImageDiv = styled.img`
width: 350px;
height: 350px;
`
const OrientationInfo = ({
  getOrientationInfoResponse,
  createOrientationInfo,
  updateOrientationInfo,
  match,
  uploadDocsToBucket,
  uploadDocsToBucketResponse,
  setFileUploading,
}) => {
  const [isTCChecked, setTCCheked] = useState(false)
  const [licenseUrl, setLicenseUrl] = useState("")
  const [w9Url, setW9Url] = useState("")
  const [sCorpW9Url, setSCorpW9Url] = useState("")

  const {
    value: realEstateLicenseNum,
    bind: licenseNumBind,
    setValue: setRealEstateLicenseNum,
  } = useInput("", emptyInput)

  const {
    value: licenseExpiryDate,
    bind: licenseExpiryDateBind,
    setValue: setLicenseExpiryDate,
  } = useInput("", emptyInput)

  const {
    value: agentMlsId,
    bind: agentMlsIdBind,
    setValue: setAgentMlsId,
  } = useInput("", emptyInput)

  const {
    value: legalAction,
    // bind: legalActionBind,
    setValue: setLegalAction,
  } = useInput("", emptyInput)

  const {
    value: companyName,
    bind: companyNameBind,
    setValue: setCompanyName,
  } = useInput("", emptyInput)

  const {
    value: phoneNumber,
    bind: phoneNumberBind,
    setValue: setphoneNumber,
  } = useInput("", emptyInput)

  const {
    value: email,
    bind: emailBind,
    setValue: setEmail,
  } = useInput("", emptyInput)

  const {
    value: reason,
    bind: reasonBind,
    setValue: setReason,
  } = useInput("", emptyInput)

  const {
    value: referred,
    bind: referredBind,
    setValue: setReferred,
  } = useInput("", emptyInput)

  const LabelAndToggle = ({
    label,
    children,
  }) => (
    <LabelAndToggleWrap>
      <p>
        {label}
      </p>
      <div>
        {children}
      </div>
    </LabelAndToggleWrap>
  )

  const agentId = get(match, "params.agentId")
  const {
    data,
  } = getOrientationInfoResponse || {}

  const {
    isFetching: isUploadingFile,
    data: uploadData,
  } = uploadDocsToBucketResponse || {}

  const [mls, setMLS] = useState("")
  const [localAssociations, setLocalAssociations] = useState("")

  const handleSave = () => {
    const payload = {}

    payload.agent_id = agentId
    payload.real_estate_license_number = realEstateLicenseNum
    payload.license_expire_date = licenseExpiryDate
    payload.mls = mls ? mls.toString() : ""
    payload.local_associations = localAssociations ? localAssociations.toString() : ""
    payload.agent_mls_id = agentMlsId
    payload.legal_action_faced = legalAction
    payload.assistant_company_name = companyName
    payload.assistant_phone_number = phoneNumber
    payload.help_with_mls_transfer = isTCChecked
    payload.assistant_email = email
    payload.reason_to_join_radius = reason
    payload.referred_by = referred
    payload.license_url = uploadData && uploadData.realEstateLicense ? (uploadData.realEstateLicense.realEstateLicense) : licenseUrl
    payload.w9_form_url = uploadData && uploadData.w9Form ? (uploadData.w9Form.w9Form) : w9Url
    payload.s_corp_w9_form_url = uploadData && uploadData.sCorpW9Form ? (uploadData.sCorpW9Form.sCorpW9Form) : sCorpW9Url

    if (!data.agent_id) {
      createOrientationInfo({
        payload,
      })
    } else {
      updateOrientationInfo({
        payload,
        callback: () => {
          root.location.reload()
        },
      })
    }
  }

  useEffect(() => {
    if (data) {
      setRealEstateLicenseNum(data.real_estate_license_number)
      setLicenseExpiryDate(data.license_expire_date)
      setLocalAssociations(data.local_associations)
      setMLS(data.all_mls)
      setAgentMlsId(data.agent_mls_id)
      setLegalAction(data.legal_action_faced)
      setCompanyName(data.assistant_company_name)
      setphoneNumber(data.assistant_phone_number)
      setTCCheked(data.help_with_mls_transfer)
      setEmail(data.assistant_email)
      setReason(data.reason_to_join_radius)
      setReferred(data.referred_by)
      setLicenseUrl(data.license_url)
      setW9Url(data.w9_form_url)
      setSCorpW9Url(data.s_corp_w9_form_url)
    }
  }, [data])

  return (
    <div>
      {data ? (
        <Wrap>
          <HeaderWrap>
            <h1> Edit Orientation Info </h1>
            <Button onClick={() => handleSave()}>
              Save
            </Button>
          </HeaderWrap>
          <h4>Agent&apos;s Details</h4>
          <FirstDiv>
            <InputDiv>
              <SubHeader>Real Estate License No.</SubHeader>
              <UserInput
                type="text"
                {...licenseNumBind}
                name="realEstateLicenseNum"
              />
            </InputDiv>
            <InputDiv>
              <SubHeader>License Expiry Date</SubHeader>
              <UserInput
                type="date"
                {...licenseExpiryDateBind}
                name="licenseExpiryDate"
              />
            </InputDiv>
          </FirstDiv>
          <InputDiv>
            <SubHeader>Local Association(s)</SubHeader>
            <InputText
              type="text"
              value={localAssociations}
              onChange={(e) => {
                setLocalAssociations(e.target.value)
              }}
              name="localAssociations"
            />
          </InputDiv>
          <InputDiv>
            <SubHeader>MLS(s)</SubHeader>
            <InputText
              type="text"
              value={mls}
              onChange={(e) => {
                setMLS(e.target.value)
              }}
              name="MLS"
            />
          </InputDiv>
          <InputDiv>
            <LabelAndToggle
              label="Help with MLS Transfer"
            >
              <Toggle
                isChecked={isTCChecked}
                onChange={() => {
                  setTCCheked(!isTCChecked)
                }}
              />
            </LabelAndToggle>
          </InputDiv>
          <Line />
          <InputDiv>
            <SubHeader>Agent MLS ID</SubHeader>
            <UserInput
              type="text"
              {...agentMlsIdBind}
              name="agent mls id"
            />
          </InputDiv>
          <InputDiv>
            <TitleDiv>
              <SubHeader>Real Estate License</SubHeader>
              {licenseUrl && licenseUrl.slice(-3) === "pdf" ? (
                <EarnMoreButton onClick={() => { root.open(licenseUrl) }}>
                  View PDF
                </EarnMoreButton>
              ) : (
                <>
                  {(data && data.license_url) || (uploadData && uploadData.realEstateLicense && uploadData.realEstateLicense.realEstateLicense) ? (
                   <>
                     {uploadData && uploadData.realEstateLicense && uploadData.realEstateLicense.realEstateLicense.slice(-3) === "pdf" ? (
                       <EarnMoreButton onClick={() => { root.open(uploadData.realEstateLicense.realEstateLicense) }}>
                     View PDF
                       </EarnMoreButton>
                     ) : (
                       <ImageDiv src={uploadData && uploadData.realEstateLicense ? (uploadData.realEstateLicense.realEstateLicense) : data.license_url} />
                     )}
               </>
                  ) : (
                    <ImageDiv src={DefaultImage} />
                  )}
                </>
              )}
            </TitleDiv>
            <FileUpload>
              {isUploadingFile === true ? (
                <h4>Uploading...</h4>
              ) : (
                <FileButton
                  type="file"
                  label="Edit Real Estate License"
                  onChange={(e) => {
                    setFileUploading("realEstateLicense")
                    uploadDocsToBucket({
                      file: e.target.files[0],
                      objName: "realEstateLicense",
                      fileName: e.target.files[0].name,
                    })
                  }}
                />
              )}
            </FileUpload>
            <ShowFileName>
              {uploadData && uploadData.realEstateLicense ? (uploadData.realEstateLicense.realEstateLicenseFileName) : null}
            </ShowFileName>
          </InputDiv>
          <Line />
          <InputDiv>
            <TitleDiv>
              <SubHeader>W9 Form</SubHeader>
              {w9Url && w9Url.slice(-3) === "pdf" ? (
                <EarnMoreButton onClick={() => { root.open(w9Url) }}>
                View PDF
                </EarnMoreButton>
              ) : (
                <>
                  {(data && data.w9_form_url) || (uploadData && uploadData.w9Form && uploadData.w9Form.w9Form) ? (
                    <>
                      {uploadData && uploadData.w9Form && uploadData.w9Form.w9Form.slice(-3) === "pdf" ? (
                        <EarnMoreButton onClick={() => { root.open(uploadData.w9Form.w9Form) }}>
                        View PDF
                        </EarnMoreButton>
                      ) : (
                        <ImageDiv src={uploadData && uploadData.w9Form ? (uploadData.w9Form.w9Form) : data.w9_form_url} />
                      )}
                  </>
                  ) : (
                    <ImageDiv src={DefaultImage} />
                  )}
                </>
              )}
            </TitleDiv>
            <FileUpload>
              {isUploadingFile === true ? (
                <h4>Uploading...</h4>
              ) : (
                <FileButton
                  type="file"
                  onChange={(e) => {
                    setFileUploading("w9Form")
                    uploadDocsToBucket({
                      file: e.target.files[0],
                      objName: "w9Form",
                      fileName: e.target.files[0].name,
                    })
                  }}
                />
              )}
            </FileUpload>
            <ShowFileName>
              {uploadData && uploadData.w9Form ? (uploadData.w9Form.w9FormFileName) : null}
            </ShowFileName>
          </InputDiv>
          <Line />
          <InputDiv>
            <TitleDiv>
              <SubHeader>S Corp W9 Form</SubHeader>
              {sCorpW9Url && sCorpW9Url.slice(-3) === "pdf" ? (
                <EarnMoreButton onClick={() => { root.open(w9Url) }}>
                 View PDF
                </EarnMoreButton>
              ) : (
                <>
                  {(data && data.s_corp_w9_form_url) || (uploadData && uploadData.sCorpW9Form && uploadData.sCorpW9Form.sCorpW9Form) ? (
                    <>
                      {uploadData && uploadData.sCorpW9Form && uploadData.sCorpW9Form.sCorpW9Form.slice(-3) === "pdf" ? (
                        <EarnMoreButton onClick={() => { root.open(uploadData.sCorpW9Form.sCorpW9Form) }}>
                          View PDF
                        </EarnMoreButton>
                      ) : (
                        <ImageDiv src={uploadData && uploadData.sCorpW9Form ? (uploadData.sCorpW9Form.sCorpW9Form) : data.s_corp_w9_form_url} />
                      )}
                    </>
                  ) : (
                    <ImageDiv src={DefaultImage} />
                  )}
                </>
              )}
            </TitleDiv>
            <FileUpload>
              {isUploadingFile === true ? (
                <h4>Uploading...</h4>
              ) : (
                <FileButton
                  type="file"
                  onChange={(e) => {
                    setFileUploading("sCorpW9Form")
                    uploadDocsToBucket({
                      file: e.target.files[0],
                      objName: "sCorpW9Form",
                      fileName: e.target.files[0].name,
                    })
                  }}
                />
              )}
            </FileUpload>
            <ShowFileName>
              {uploadData && uploadData.sCorpW9Form ? (uploadData.sCorpW9Form.sCorpW9FormFileName) : null}
            </ShowFileName>
          </InputDiv>
          <Line />
          <InputDiv>
            <SubHeader>Have you had any legal action taken against you in the last 5 years?</SubHeader>
            <Select
              options={[
                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
              ]}
              value={data ? (legalAction === 0 ? { label: "No" } : { label: "Yes" }) : null}
              onChange={(selectedItem) => {
                selectedItem.label === "No" ? setLegalAction(0) : setLegalAction(1)
              }}
            />
          </InputDiv>
          <InputDiv>
            <SubHeader>Assistant's Name</SubHeader>
            <UserInput
              type="text"
              {...companyNameBind}
              name="assistant company name"
            />
          </InputDiv>
          <FirstDiv>
            <InputDiv>
              <SubHeader>Assistant's Phone Number</SubHeader>
              <UserInput
                type="number"
                {...phoneNumberBind}
                name="assistant phone number"
              />
            </InputDiv>
            <InputDiv>
              <SubHeader> Assistant's Email ID</SubHeader>
              <UserInput
                type="text"
                {...emailBind}
                name="assistant email"
              />
            </InputDiv>
          </FirstDiv>
          <InputDiv>
            <SubHeader>What is your main reason for joining Radius Agent?</SubHeader>
            <UserInputArea
              {...reasonBind}
              name="reason to join"
            />
          </InputDiv>
          <InputDiv>
            <SubHeader>Who referred you?</SubHeader>
            <UserInput
              type="text"
              {...referredBind}
              name="referred by"
            />
          </InputDiv>
        </Wrap>
      ) : <Loader />}
    </div>
  )
}

export default container(OrientationInfo)
