// import * as actions from  "container/MultipleCaller/actions"
// import { store } from "store/config"
// import uuid from "uuid"
// import initRes from "./response"

// const createUniqKeys = (data) => {
//   return Object.keys(data).map((key) => {
//     if (data[key]) {
//       data[key] = data[key].map(item => {
//         return {
//           ...item,
//           referral_id: uuid()
//         }
//       })
//     }
//     return null
//   })
// }

// setTimeout(() => {
//   store.dispatch(actions.createInitStateAction.call(initRes))
// }, 1000)

// setTimeout(() => {
//   const payload = {
//     connecting: [
//       initRes.queued[0],
//     ],
//   }
//   store.dispatch(actions.changeCallStateAction.call(payload))
// }, 6000)

// setTimeout(() => {
//   console.log("Conf call.............................");
//   const payload = {
//     "in-conference": [
//       {
//         ...initRes.queued[1],
//         is_conference: true,
//         call_status: "ringing",
//         conference_agent_name:"Conf. Agent Name",
//         conference_agent_email: "conference@email.com"
//       },
//     ],
//   }
//   store.dispatch(actions.changeCallStateAction.call(payload))
// }, 8000)

// setTimeout(() => {
//   const payload = {
//     prevCall: [
//       {
//         ...initRes.queued[0],
//         call_status: "prevCall",
//       },
//     ],
//   }
//   store.dispatch(actions.changeCallStateAction.call(payload))
// }, 8000)

// setTimeout(() => {
//   const payload = {
//     RemoveReferralFromListEvent: [
//       {
//         ...initRes.queued[1],
//         call_status: "CallCycleFinishedEvent",
//       },
//     ],
//   }
//   store.dispatch(actions.deleteElementFromStateAction.call(payload))
// }, 10000)

// setTimeout(() => {
//   console.log("Deleting....");
//   const payload = [
//     {
//       ...initRes.queued[1],
//     }
//   ]
//   store.dispatch(actions.deleteElementFromStateAction.call(payload))
// }, 10000)
