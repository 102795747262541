import React, { Component } from "react"
import styled from "styled-components"
import container from "container/Agent"
import DashboardContainer from "container/Dashboards"


const Wrapper =styled.div`
    background: #FFF;
    padding-bottom: 20px;
    input {
     font-size: 18px;
     width: 100%;
     padding: 10px 20px;
     height: 40px;
     border-radius: 5px;
     border: solid 1px #dfdede;
    }

    button {
     width: 85px;
     height: 40px;
     border-radius: 4px;
     background-color: #11adf3;
     border: none;
     color: white;
     float: right;
    }
`

const Tag = styled.div`
  width: max-content;
  height: 50px;
`

const AgentWrap = styled.div`
    padding: 15px;
    border: 1px solid #CCC;

    &:hover {
        background: #EEE;
    }
`

class Index extends Component {
    state = {
        searchForm: {},
    }

    handleOnType = (event) => {
        const { searchForm } = this.state
        searchForm[event.target.name] = event.target.value
        this.setState({
            searchForm,
        })
    }

    handleAgentSearchChange = (e) => {
        const { fetchAgentSearch } = this.props
        const { value } = e.target
        this.setState({
         agentSearchText: value,
        })
        fetchAgentSearch({ search: value } )
    }

    handleAgentSelect = (agent) => {
        const {
          setCurrentAgents,
          currentAgents,
          fetchMetrics,
          setCurrentPaginationLimit,
         } = this.props
        setCurrentAgents({
         current: agent,
         currentAgents,
        })
        this.setState({
         agentSearchText: "",
        })
        //setCurrentPaginationLimit(10)

        fetchMetrics({
         filters: "",
         limit: 10,
        })

    }

    render() {
        const { searchForm, agentSearchText } = this.state
        const { agents } = this.props
        const { isFetching, data } = agents || {}
        const { agents: agentsData } = data || {}

        const { search, agent } = searchForm

        return (
            <Wrapper>
                <span>
                    <input placeholder="Search agent" onChange={this.handleAgentSearchChange} value={this.state.agentSearchText} />
                </span>

                {isFetching ? (
                    <h1>Loading...</h1>
                ) : (
                    <React.Fragment>
                        {agentSearchText && agentsData && agentsData.map(item => (
                            <AgentWrap onClick={() => this.handleAgentSelect(item)}>
                                <h5>{item.firstname}</h5>
                                <small>{item.email}</small>
                            </AgentWrap>
                        ))}
                    </React.Fragment>
                )}
            </Wrapper>
        )
    }
}

export default DashboardContainer(container(Index));
