import React, { PureComponent } from "react"
import styled from "styled-components"
import TechAssetsContainer from "container/TechAssets"
import { get } from "lodash"
import root, { setTimeout } from "window-or-global"
// import Loader from "@ui/Loader"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
import {
  Select,
  Form,
  Divider,
  Button,
} from "dumbComponents/common/UI/antd"
import colors from "../../../../lib/colors"
import EscrowForm from "./NewEscrowVendorForm"
import TitleForm from "./NewTitleVendorForm"
import EscrowAndTitleForm from "./EscrowAndTitleForm"
import ClosingAttorneyForm from "./ClosingAttorneyForm"
import {
  VendorSelectRoute,
  CreateVendorStep,
  PageArray,
} from "../../consts"
import {
  SubmitForm,
  getDealId,
  UpdateVendorOrLender,
  getAgentId,
  setStreet,
} from "../../commonUtils"
import Sidebar from "../Sidebar"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const HeaderNav = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LabelText = styled.h2`
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`

const Required = styled.span`
  color: #E34C4C
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  state = {
    showCoListingform: [1],
    typeOfVendor: "",
    isUpdating: false,
  }

  formRef = React.createRef();

  componentDidMount() {
    const {
      currentVendorToEdit,
      history,
    } = this.props

    const {
      location: {
        pathname,
      },
    } = history || {}

    if (currentVendorToEdit.id !== null) {
      const {
        data,
      } = currentVendorToEdit || {}

      this.setState({
        typeOfVendor: (data.vendor_type).toLowerCase(),
      })
    }

    this.setState({
      isUpdating: !pathname.includes("new-vendor"),
    })
  }

  onFinish = (fieldValues, isDraft) => {
    const {
      history,
      updateOffer,
      createOffer,
      updateVendor,
      currentVendorToEdit,
    } = this.props

    const {
      isUpdating,
    } = this.state

    const currentStep = CreateVendorStep
    const agentId = getAgentId() || false

    if (!isUpdating) {
      SubmitForm(
        fieldValues,
        isDraft,
        createOffer,
        updateOffer,
        history,
        VendorSelectRoute,
        currentStep,
        null,
        false,
        true,
        false,
        "vendor",
      )
    } else {
      const {
        id,
      } = currentVendorToEdit || {}
      UpdateVendorOrLender(
        fieldValues,
        isDraft,
        updateVendor,
        history,
        VendorSelectRoute,
        id,
        false,
        agentId,
        "vendor",
      )
    }
  }

  onSaveAsDraft = () => {
    this.onFinish(this.formRef.current.getFieldsValue(), true)
  }

  setTypeOfVendor = (value) => {
    this.setState({
      typeOfVendor: value,
    })
  }

  addAdditionalSeller = () => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform
    colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
    setTimeout(() => {
      root.scrollTo(0, 3100)
    }, 300)
  }

  setCities = (location) => {
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    this.formRef.current.setFieldsValue({
      vendor_city: `${locationDetails.city}`,
      vendor_state: `${locationDetails.state}`,
      vendor_country: `${locationDetails.country}`,
    })
  }

  setLocaCity = (value, cityFormName) => {
    // const { cityFormValue } = this.state
    // this.setState({
    //   cityFormValue: {
    //     ...cityFormValue,
    //     [cityFormName]: value,
    //   },
    // })
  }

  getInitialValues = () => {
    const {
      currentVendorToEdit,
    } = this.props

    let result = {}

    if (currentVendorToEdit.id !== null) {
      const {
        data,
      } = currentVendorToEdit || {}

      result = {
        ...data,
        vendor_type: (data.vendor_type).toLowerCase(),
      }
    }

    return result
  }

  render() {
    const { typeOfVendor } = this.state
    const {
      history,
      updateVendorActionResponse,
    } = this.props

    const {
      isFetching: updatingVendor,
    } = updateVendorActionResponse || {}

    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]

    const isLoading = updatingVendor
    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Escrow &amp; Title Information
        </HeaderNav>
        <ProgressBarComponent total={PageArray} ongoingOrCompleted={6} />
        <Container>
          <LeftSideBar>
            <Sidebar />
          </LeftSideBar>
          <RightSideBar>
            {isLoading ? (
              <p>Loading</p>
            ) : (
              <Form
                name="vendor_information"
                colon={false}
                layout="vertical"
                requiredMark={false}
                onFinish={this.onFinish}
                initialValues={this.getInitialValues()}
                ref={this.formRef}
                onFinishFailed={(e) => { console.log("ERROROOOO", e) }}
                scrollToFirstError
              >
                <Form.Item
                  name="vendor_type"
                  label={<LabelComponent text="Vendor Type" required />}
                  rules={commonRules}
                >
                  <Select
                    onChange={(value) => { this.setTypeOfVendor(value) }}
                  >
                    <Select.Option value="escrow">Escrow </Select.Option>
                    <Select.Option value="title">Title</Select.Option>
                    <Select.Option value="title_and_escrow">Escrow &amp; Title</Select.Option>
                    <Select.Option value="closing_attorney">Closing Attorney</Select.Option>
                  </Select>
                </Form.Item>

                {typeOfVendor === "escrow" && (
                  <EscrowForm
                    commonRules={commonRules}
                    setLocaCity={this.setLocaCity}
                    setCities={this.setCities}
                    setStreet={setStreet}
                    formRef={this.formRef}
                  />
                )}

                {typeOfVendor === "title" && (
                  <TitleForm
                    commonRules={commonRules}
                    setLocaCity={this.setLocaCity}
                    setCities={this.setCities}
                    setStreet={setStreet}
                    formRef={this.formRef}
                  />
                )}
                {/*EscrowAndTitleForm*/}
                {typeOfVendor === "title_and_escrow" && (
                  <EscrowAndTitleForm
                    commonRules={commonRules}
                    setLocaCity={this.setLocaCity}
                    setCities={this.setCities}
                    setStreet={setStreet}
                    formRef={this.formRef}
                  />
                )}

                {typeOfVendor === "closing_attorney" && (
                  <ClosingAttorneyForm
                    commonRules={commonRules}
                    setLocaCity={this.setLocaCity}
                    setCities={this.setCities}
                    setStreet={setStreet}
                    formRef={this.formRef}
                  />
                )}

                <FlexBox>
                  <FlexBox wd="100%">
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          color: "#849DF8",
                          padding: 0,
                          marginLeft: "-10px",
                        }}
                        size="large"
                        type="link"
                        onClick={this.onSaveAsDraft}
                      >
                        Save for Later
                      </Button>
                    </Form.Item>
                  </FlexBox>
                  <FlexBox>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "white",
                          float: "right",
                          borderColor: "#3B60E4",
                          color: "#3B60E4",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        // htmlType="submit"
                        onClick={() => { history.push(`${VendorSelectRoute}?dealId=${getDealId()}&agentId=${getAgentId()}`) }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "#3B60E4",
                          float: "right",
                          borderColor: "white",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        loading={isLoading}
                        htmlType="submit"
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </FlexBox>
                </FlexBox>
              </Form>
            )}
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)

