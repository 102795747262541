import React, { useEffect, useState } from "react";
import { Modal, Button, Select } from "antd";
import CheckList from "container/CheckList/index";
import { LoadingOutlined } from "@ant-design/icons";

import "./styles.css";
const { Option } = Select;

const AddDocumentsToSection = ({
  dropdownDataArray,
  fetchChecklistMetadataResponse,
  selectedSection,
  fetchAllTemplates,
  fetchAllTemplatesForReplication,
  fetchAllTemplatesForReplicationResponse,
  saveNewItemToDocument,
  fetchAllTemplatesResponse,
  saveNewItemDocumentresponse
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  useEffect(() => {
    if (selectedTemplate) {
      const payload = {
        state: dropdownDataArray.state,
        type: dropdownDataArray.type,
        checklistTemplate: selectedTemplate,
      };
      fetchAllTemplatesForReplication(payload);
    }
  }, [selectedTemplate]);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleAddFromTemplate = async () => {
    const documentItems = fetchAllTemplatesForReplicationResponse.data.find(
      (item) => item.checklist === selectedSection.checklist_value
    );

    if (documentItems && documentItems.document_items.length > 0) {
      let payload = [];

      documentItems.document_items.forEach((item) => {
        if (item.templates && item.templates.length > 0) {
          item.templates.forEach((template) => {
            payload.push({
              checklistTemplateId: dropdownDataArray.checklistTemplate.id,
              checklistId: selectedSection.id,
              documentItem: item.document_item,
              mandatory: item.mandatory,
              name: template.name,
              templateId: template.templateId,
            });
          });
        } else {
          payload.push({
            checklistTemplateId: dropdownDataArray.checklistTemplate.id,
            checklistId: selectedSection.id,
            documentItem: item.document_item,
            mandatory: item.mandatory,
            name: null,
            templateId: null,
          });
        }
      });

      const templatesPayload = {
        state: dropdownDataArray.state,
        type: dropdownDataArray.type,
        checklistTemplate:
          dropdownDataArray.checklistTemplate.checklist_template_value,
      };
      await saveNewItemToDocument({
        payload,
        callback: (e) => {
          fetchAllTemplates(templatesPayload);
          setModalVisible(false);
        },
      });
    }
  };

  const fetchReplicableTemplate = (value) => {
    setSelectedTemplate(value);
  };

const isAddButtonEnabled = () => {
  if (
    !selectedSection ||
    !fetchAllTemplatesForReplicationResponse?.data ||
    !Array.isArray(fetchAllTemplatesForReplicationResponse.data)
  ) {
    return false;
  }

  const documentItems = fetchAllTemplatesForReplicationResponse.data.find(
    (item) => item.checklist === selectedSection.checklist_value
  );

  if (documentItems === undefined || documentItems.document_items.length === 0) {
    return false;
  }

  return true;
};


  return (
    <div>
      <div>
        <div className="no-data-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="65"
            height="64"
            viewBox="0 0 65 64"
            fill="none"
          >
            <path
              d="M59.1673 32H5.83398"
              stroke="#ECECEC"
              stroke-width="5.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.034 13.6265L5.83398 31.9998V47.9998C5.83398 49.4143 6.39589 50.7709 7.39608 51.7711C8.39628 52.7713 9.75283 53.3332 11.1673 53.3332H53.834C55.2485 53.3332 56.605 52.7713 57.6052 51.7711C58.6054 50.7709 59.1673 49.4143 59.1673 47.9998V31.9998L49.9673 13.6265C49.5258 12.7379 48.8451 11.9902 48.0019 11.4672C47.1586 10.9443 46.1862 10.667 45.194 10.6665H19.8073C18.8151 10.667 17.8427 10.9443 16.9994 11.4672C16.1562 11.9902 15.4755 12.7379 15.034 13.6265Z"
              stroke="#ECECEC"
              stroke-width="5.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.5 42.6665H16.5265"
              stroke="#ECECEC"
              stroke-width="5.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M27.166 42.6665H27.1925"
              stroke="#ECECEC"
              stroke-width="5.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <p className="no-data-heading"> No Data</p>
        <p className="no-data-description">
          It looks like you don't have any checklist yet. Start by creating a
          new checklist or replicating an existing one to streamline your
          processes.
        </p>
        <button
          type="primary"
          className="no-data-button-wrapper"
          onClick={showModal}
        >
          Replicate from existing templates
        </button>
      </div>
      <Modal
        title={null}
        visible={modalVisible}
        onCancel={handleCancel}
        footer={[
          <button
            className="add-due-btn border-gray"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </button>,
          <button
            key="add"
            type="primary"
            className={`add-due-btn add-due-btn-color ${
              !selectedTemplate || !isAddButtonEnabled() || fetchAllTemplatesForReplicationResponse?.isFetching || fetchAllTemplatesResponse?.isFetching || saveNewItemDocumentresponse?.isFetching ? "disabled-btn" : ""
            }`}
            onClick={handleAddFromTemplate}
            disabled={!selectedTemplate || !isAddButtonEnabled()}
          >
            {fetchAllTemplatesForReplicationResponse?.isFetching || fetchAllTemplatesResponse?.isFetching || saveNewItemDocumentresponse?.isFetching ? (
              <LoadingOutlined style={{ marginRight: "10px" }} />
            ) : null}
            Add from template
          </button>,
        ]}
      >
        <div>
          <p className="icon-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21 7.5L12 2.25L3 7.5M21 7.5L12 12.75M21 7.5V16.5L12 21.75M3 7.5L12 12.75M3 7.5V16.5L12 21.75M12 12.75V21.75"
                stroke="#6366F1"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </p>
          <h4 className="modal-title">Replicate item from Existing Template</h4>
        </div>
        <p className="modal-description">
          Easily add new items to the template based on an existing one for
          quicker setup.
        </p>
        {!fetchChecklistMetadataResponse?.isFetching ? (
          <Select
            placeholder="Select Checklist Template"
            className="dropdown-state"
            style={{ width: "100%", marginTop: 0 }}
            onChange={(value) => fetchReplicableTemplate(value)}
          >
            {fetchChecklistMetadataResponse?.data?.map((checklist) => (
              <Option
                key={checklist.checklist_template_value}
                value={checklist.checklist_template_value}
                disabled={
                  checklist.checklist_template_value ===
                  dropdownDataArray.checklistTemplate?.checklist_template_value
                }
              >
                {checklist.checklist_template}
              </Option>
            ))}
          </Select>
        ) : null}

        {selectedTemplate && fetchAllTemplatesForReplicationResponse?.data && !fetchAllTemplatesForReplicationResponse?.isFetching && !isAddButtonEnabled() && (
            <p style={{ color: "red", marginTop: 10, marginLeft: 5 }}>
              No data available for replication. Please try some other template.
            </p>
          )}
      </Modal>
    </div>
  );
};

export default CheckList(AddDocumentsToSection);
