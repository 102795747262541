import styled from "styled-components"

export const StatusWrap = styled.div`
  width: max-content;
  height: 39px;
  display: flex;
  padding: 6.064px 10.781px;
  align-items: center;
  gap: 5.39px;
  background-color: ${props => props.bgColor || "#ECECF0"}

  border-radius: 3.032px;
`

export const StatusImage = styled.div`
  position: relative;
  top: -2px;

  img {
    width: 13px;
    height: 13px;
  }
`

export const StatusText = styled.div`
  p {
    color: var(--Dark-Blue, #41446A);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.824px; /* 123.529% */
    margin: 0;
  }
`
