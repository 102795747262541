import React from "react"
import {
  Form,
  Input,
  Select,
} from "antd"
import {
  FormWrap,
  FlexRow,
} from "./commonStyles"
import {
  SubmitFormButton,
  CancelFormButton,
} from "./commonComponents"
import {
  getDefaultValue,
  getInitialValues,
  US_STATES,
  DEFAULT_FORM_OPTIONS,
} from "./commonUtils"

const { Option } = Select

const TeamMemberForm = ({
  riskOptions,
  splitOptions,
  commissionOptions,
  supportFeeOptions,
  role,
  teamName,
  onFinishHandle,
  submitting,
  toClose,
}) => (
  <FormWrap>
    <Form
      name="ica_team_member_form"
      layout="vertical"
      size="large"
      initialValues={getInitialValues(
        DEFAULT_FORM_OPTIONS,
        [role,
          teamName,
          getDefaultValue(splitOptions),
          getDefaultValue(riskOptions),
          getDefaultValue(commissionOptions),
          getDefaultValue(supportFeeOptions),
        ]
      )}
      onFinish={onFinishHandle}
      onFinishFailed={() => { }}
      autoComplete="off"
    >
      <FlexRow>
        <Form.Item
          label="Role"
          name="role"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input value={role} disabled />
        </Form.Item>

        <Form.Item
          label="Team Name"
          name="team_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input value={teamName} disabled />
        </Form.Item>
      </FlexRow>
      <FlexRow>
        <Form.Item
          label="Split"
          name="split"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select..."
            disabled={submitting}
            //defaultValue={getDefaultValue(splitOptions)}
          >
            {splitOptions && splitOptions.map(item => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Risk Fee"
          name="risk_fee"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select..."
            disabled={submitting}
            //defaultValue={getDefaultValue(riskOptions)}
          >
            {riskOptions && riskOptions.map(item => (
              <Option
                value={item.value}
              >
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </FlexRow>
      <FlexRow>
        <Form.Item
          label="Commission Cap"
          name="commission_cap"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select..."
            disabled={submitting}
            //defaultValue={getDefaultValue(commissionOptions)}
          >
            {commissionOptions && commissionOptions.map(item => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Team member split(Personal leads)"
          name="self_generated_lead_split"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={submitting} />
        </Form.Item>
      </FlexRow>
      <FlexRow>
        <Form.Item
          label="Subscription fee"
          name="subscription_fee"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select..."
            disabled={submitting}
            //defaultValue={getDefaultValue(supportFeeOptions)}
          >
            {supportFeeOptions && supportFeeOptions.map(item => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Team member split (Leads from team)"
          name="team_lead_generated_split"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={submitting} />
        </Form.Item>
      </FlexRow>

      <FlexRow>
        <Form.Item
          label="State"
          name="state"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select..."
            disabled={submitting}
          //defaultValue={getDefaultValue(supportFeeOptions)}
          >
            {US_STATES && US_STATES.map(item => (
              <Option value={item}>{item}</Option>
            ))}
          </Select>
        </Form.Item>
      </FlexRow>
      <FlexRow
        gap="10px"
      >
        <SubmitFormButton submitting={submitting} />
        <CancelFormButton onClick={toClose} />
      </FlexRow>
    </Form>
  </FormWrap>
)

export default TeamMemberForm
