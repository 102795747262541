import React from "react"
import {
  AgentChatColumn,
} from "../../chatSectionStyles"
import AI from "container/AI/index"
import AgentTopicCard from "../Card"

const ChatTopics = ({
  fetchSessionHistory,
  fetchAIChatSessionsResponse,
  fetchSessionHistoryResponse,
  currentTopics,
  currentSessionId,
  fetchAgentChatSessionResponse,
}) => {
  const {
    isFetching: fetchingChats,
  } = fetchAIChatSessionsResponse || {}

  const {
    isFetching: fetchingSessions,
  } = fetchSessionHistoryResponse || {}

  const {
    isFetching: fetchingAgentSession,
  } = fetchAgentChatSessionResponse || {}

  const handleSetCurrentSessionForDetailView = (data) => {
    fetchSessionHistory({
      limit: "",
      offset: 0,
      agentId: data.agent_id,
      sessionId: data.session_id,
    })
  }

  return (
    <AgentChatColumn
      isLoading={fetchingSessions || fetchingChats || fetchingAgentSession}
    >
      {currentTopics && currentTopics.topics.map((item, count) => (
        <AgentTopicCard
          onClick={() => {
            if (fetchingSessions || fetchingChats || fetchingAgentSession) {
              return
            }
            handleSetCurrentSessionForDetailView(item)
          }}
          name={item.agent_name}
          email={item.email}
          id={item.agent_id}
          topic={item.topic}
          positiveFeedbackCount={item.positive_feedback}
          negativeFeedbackCount={item.negative_feedback}
          count={count}
          isSelected={currentSessionId === item.session_id}
          isLoading={fetchingSessions || fetchingChats || fetchingAgentSession}
          createdAt={item.created_at}
        />
      ))}
    </AgentChatColumn>
  )
}

export default AI(ChatTopics)
