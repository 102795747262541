/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import SubContainer from "container/BrokerDashboard/subscriptionContainer"
import { get } from "lodash"
import {
  Container,
  PlanClickBar,
  PlanTitle,
  PlanWrap,
  PlanPriceWrap,
  PlanPrice,
  Title,
  TitleWrap,
} from "../../common"

const Wrap = styled.div``

const AddOns = ({
  planBenefitsResponse,
  highlightSelectedAddon,
  selectedAddons,
  addonsResponse,
  addOnsSelected,
}) => {
  const planBenefitsData = get(planBenefitsResponse, "data")
  const getUnitPrice = planBenefits => planBenefits.name === "Branding" || planBenefits.name === "Custom Logo"
    ? `$${planBenefits.unit_price}`
    : (planBenefits.plan_details && planBenefits?.plan_details[0]?.billing_cycle === "YEARLY")
      ? planBenefits?.plan_details && `$${planBenefits?.plan_details[0]?.unit_price}/yearly` : planBenefits?.plan_details && `$${planBenefits.plan_details[0]?.unit_price}/month`
  const checkIsSelected = (planBenefits) => {
    const selectedIndex = selectedAddons.findIndex(x => x.id === planBenefits.id)
    const isSelected = selectedIndex !== -1
    return isSelected
  }
  return (
    <Wrap>
      <Container>
        <TitleWrap>
          <Title>
          Add Ons
          </Title>
        </TitleWrap>
        <PlanWrap>
          {planBenefitsData && planBenefitsData.filter(filteredData => filteredData.name !== "E&O Insurance").map(planBenefits => (
          <>
            <PlanClickBar
              onClick={() => {
                const isSelected = addOnsSelected.some(el => el.id === planBenefits.id)
                if (isSelected) {
                  const payload = {
                    removeAddOn: true,
                    planBenefits,
                  }
                  highlightSelectedAddon(payload)
                } else {
                  highlightSelectedAddon(planBenefits)
                }
              }}
              isSelected={addOnsSelected.some(el => el.id === planBenefits.id)}
              key={planBenefits.id}
              width={233.67}
            >
              <PlanTitle
                height="48px"
              >
                {planBenefits.name}
              </PlanTitle>
              <PlanPriceWrap
                isSelected={addOnsSelected.some(el => el.id === planBenefits.id)}
                key={planBenefits.id}
              >
                <PlanPrice
                  isSelected={addOnsSelected.some(el => el.id === planBenefits.id)}
                  key={planBenefits.id}
                >
                  {getUnitPrice(planBenefits)}
                </PlanPrice>
              </PlanPriceWrap>
            </PlanClickBar>
          </>
          ))}
        </PlanWrap>
      </Container>
    </Wrap>
  )
}

export default SubContainer(AddOns)
