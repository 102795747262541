import React, { PureComponent, useState } from "react"
import styled from "styled-components"
import { ClockCircleOutlined } from "@ant-design/icons"
import {
  Button, Dropdown, Menu, Space, PageHeader,
  Collapse,
  Divider,
  Drawer,
  Tabs,
  Timeline,
} from "antd"
import moment from "moment"
import TimelineInnerComponent from "./TimelineComponent"

const { Panel } = Collapse

const { TabPane } = Tabs

const Wrap = styled.div`
  .ant-timeline-item-content {
    width: 100% !important;
  }
`

const InfoBox = styled.div`
  background: #F9F9F7;
  padding: 30px 20px;
  margin: 5px;
  width: 50%;
`
const CashbackStatusBoxContainer = styled.div`
  display: flex;
  ${props => props.fd && `
    flex-direction: ${props.fd};
  `}
`
const Circle = styled.div`
  width: 7px;
  background: #1890FF;
  height: 7px;
  border-radius: 50%;
`
const InfoHeader = styled.div`
  display: flex;
  align-items: baseline;
  p {
    margin: 0 0 7px 10px;
  }
`
const InfoContent = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.06em;
  color: #303030;
  margin: 0;
`
const PanelContainer = styled.div``

const PanelContent = styled.div`
  .divider {
    margin: 0px;
  }
`

const PanelRow = styled.div`
  display: flex;
  padding: 15px 40px 15px 0px;
`

const PanelFlex = styled.div`
  display: flex;
  flex-direction: column;
`

const ColumnHeader = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #0B0A0A;
  margin-left: 30px;
  text-align: left;
  ${props => props.wd && `
    width: ${props.wd}
  `}
`

const PanelData = styled(ColumnHeader)`
  flex: 1;
  ${props => props.ta && `
    text-align: ${props.ta}
  `}
  color: #6D7E8F;
  .action_button {
    width: 25%;
  }
`

const Text = styled.h2`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  text-transform: capitalize;

  ${props => props.status === "credited" && `
    color: #17974A;
  `}
  ${props => props.status === "approved" && `
    color: #0D97FF;
  `}
  ${props => props.status === "declined" && `
    color: #DC0000;
  `}
  ${props => props.status === "pending" && `
    color: #D8A800;
  `}
`

const TimelinePara = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
`

const getDotColor = (status) => {
  if(status === "credited") return "#17974A"
  if(status === "approved") return "#0D97FF"
  if(status === "declined") return "#DC0000"
  if(status === "pending") return "#D8A800"
  return "gray"
}

const RoundBox = styled.div`
  min-width: 10px;
  width: 10px;
  height: 10px;
  border: 2px solid green;
  border-radius: 50%;
  margin-right: 10px;
  ${props => props.color && `
    border: 2px solid ${props.color};
  `}
`

const BoldText = styled.b`
  font-weight: 500;
`

const LeftNode = () => (
  <div>
    <p>2015-09-01 asds </p>
  </div>
)





const RightNode = ({ timeline }) => {
  const [show, setShow] = useState(false)
  return (
    <div>
      <Text status={timeline.status}>
        {timeline.status}
      </Text>
      <TimelinePara onClick={() => setShow(!show)}>
        {"> "}
        {timeline.text}
      </TimelinePara>
      {timeline.sub_timelines && timeline.sub_timelines.length > 0 && timeline.sub_timelines.map(subTimeline => (
        
        <div
        style={{
          padding: "15px",
          background: "#F9F9F7",
          borderRadius: "4px",
          display: show ? "flex" : "none",
          alignItems: "baseline",
        }}
      >
        <RoundBox color={getDotColor(timeline.status)} />
        {moment(subTimeline.created_at * 1000).format("MMM DD, YYYY hh:mm A")} : <BoldText>{subTimeline.text}</BoldText>
        
      </div>
      ))}
    </div>
  )
}

const TimelineComponent = ({ agentId }) => (
  <Wrap>
    <PanelContainer>
      <PanelContent>
        <PanelRow>
          <TimelineInnerComponent
            LeftNode="2015-09-01 asds asd as dsa"
            Icon={ClockCircleOutlined}
            RightNode={RightNode}
            agentId={agentId}
          />
        </PanelRow>
      </PanelContent>
    </PanelContainer>
  </Wrap>
)

export default TimelineComponent
