import React from "react"
import styled from "styled-components"
// import Select from "react-select"
import { get } from "lodash"
import { Row, Col, Button, Select } from "antd"
import moment from "moment"
import globalPagesContainer from "container/BrokerDashboard/globalPagesContainer"
import Loader from "dumbComponents/common/UI/Loader"
import Toggle from "dumbComponents/common/UI/Toggle"
import DealList from "dumbComponents/BrokerDashboard/Components/DealItem"
import OverviewTC from "../TcOverview/OverviewTC"
import NoResults from "dumbComponents/BrokerDashboard/GlobalPages/TCListing/NoResults"
import { withRouter } from "react-router-dom"

const PAGE_SIZE = 30

const Wrap = styled.div`
  padding: 20px;
`

const Header = styled.div`
  max-width: 1100px;
  margin-top: 20px;
`

const ListWrap = styled.div`
  overflow: scroll;
`

const SearchWrap = styled.div`
  display: flex;
  input {
    padding: 8px;
    margin: 8px;
  }

  > span {
    display: block;
    flex: 1;
  }
`
const DEAL_STATUS = [
  {
    label: "Listing Status",
    options: [
      { label: "Incomplete", value: "incomplete" },
      { label: "Listing Verified", value: "listing_verified" },
      { label: "On Market", value: "active_listing" },
      { label: "Withdrawn", value: "listing_canceled" },
      { label: "Expired", value: "listing_expired" },
      { label: "Coming Soon", value: "coming_soon" },
      { label: "Pending", value: "pending" },
    ],
  },
  {
    label: "Contract Status",
    options: [
      { label: "Contract Submitted", value: "submitted" },
      { label: "Audit Verified", value: "audit_verified" },
      { label: "Commission Verified", value: "commission_verified" },
      { label: "Demand Pending", value: "demand_pending" },
      { label: "Demand Completed", value: "demand_completed" },
      { label: "Closed", value: "closed" },
      { label: "Payment Received", value: "payment_received" },
      { label: "All", value: null },
    ],
  },
  {
    label: "Archived",
    options: [{ label: "Archived", value: "archived:contract" }],
  },
]

class DealsList extends React.PureComponent {
  state = {
    isShowActive: false,
    isShowActiveLocal: false,
  }

  componentDidMount() {
    const { getAllDealsListing, tcOverview, match } = this.props
    const agentId = get(match, "params.agentId")
    //brokerage-deals
    getAllDealsListing({
      type: "agent",
      agentId,
      limit: 400,
      transactionType: "Contract",
    })
    this.setState({
      agentId,
    })
    tcOverview({
      agentId,
    })
  }

  handleFilter = () => {
    const { getAllDealsListing, match, tcOverview  } = this.props
    const agentId = get(match, "params.agentId")
    const { startDate, endDate, isShowActiveLocal, dealStatus } = this.state
    console.log({ dealStatus });
    getAllDealsListing({
      type: "agent",
      agentId,
      limit: 400,
      startDate: startDate && startDate.unix(),
      endDate: endDate && endDate.unix(),
      isShowActiveListing: isShowActiveLocal,
      dealType: dealStatus && dealStatus,
    })
    tcOverview({
      agentId,
      startDate: startDate && startDate.unix(),
      endDate: endDate && endDate.unix(),
      isShowActiveListing: isShowActiveLocal,
      dealType: dealStatus && dealStatus,
      limit: 400,
    })
    this.setState({
      isShowActive: isShowActiveLocal,
    })
  }

  handleChange = (e) => {
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        [e.target.name]: e.target.value,
      }
    })
  }

  triggerFetchDeals = () => {
    const { getAllDealsListing, match } = this.props
    const agentId = get(match, "params.agentId")
    getAllDealsListing({
      type: "agent",
      agentId,
      limit: PAGE_SIZE,
      skip: 0,
      transactionType: "Contract",
    })
  }

  render() {
    const { agentId, isShowActiveLocal, dealStatus } = this.state
    const { dealsListingResponse, tcOverviewResponse, history } = this.props
    const { data, isFetching } = dealsListingResponse
    const { data: overviewData } = tcOverviewResponse || {}
    const { overview: agentOverviewData } = overviewData || {}
    return (
      <Wrap>
        {agentOverviewData && (
          <OverviewTC
            overview={agentOverviewData}
          />
        )}
        <Header>
          <Row align="center" gutter={20}>
            <Col flex={1}>
              <br />
              <h2>Submitted Files</h2>
            </Col>
            <Col>
              <h5 style={{ margin: 0 }}>Confirmed/Tentative Closed Date</h5>
              <SearchWrap>
                <input type="date" onChange={(e) => { this.setState({ startDate: moment(e.target.value) }) }} />
                <input type="date" onChange={(e) => { this.setState({ endDate: moment(e.target.value) }) }} />
              </SearchWrap>
            </Col>
            <Col>
              <h5>Deal Status</h5>
              <Select
                style={{ width: 250, borderRadius: 90 }}
                options={DEAL_STATUS}
                placeholder="Select"
                onChange={(value) => { this.setState({ dealStatus: value }) }}
                value={dealStatus}
              />
            </Col>
            <Col>
              <Button onClick={this.handleFilter} type="primary" style={{ marginTop: 26 }}>
                Filter
              </Button>
            </Col>
          </Row>
        </Header>
        {/* <div>
          <label htmlFor="forActive">
            <input
              type="checkbox"
              checked={isShowActive}
              onChange={() => { this.setState({ isShowActive: !isShowActive }) }}
            />
            <span>Show Active Listings</span>
          </label>
          <Button
            style={{ margin: "0px 20px" }}
            onClick={() => { history.push(`/broker/dashboard/${agentId}/deals/create`) }}
          >
            Search
          </Button>
        </div> */}
        {isFetching ? (
          <Loader />
        ) : (
          <ListWrap>
            <React.Fragment>
              {data && data.length > 0 ? (
                <DealList
                  data={data}
                  isAgentPage={true}
                  loadMore={() => {}}
                  hasMore={false}
                  triggerFetchDeals={this.triggerFetchDeals}
                />
              ) : (
                <NoResults />
              )}
            </React.Fragment>  
          </ListWrap>
        )}
      </Wrap>
    )
  }
}

export default globalPagesContainer(withRouter(DealsList))
