import React, { Component } from 'react';
import Popup from "reactjs-popup";
import styled from "styled-components";
import _ from "lodash";
import {createProspect} from "../../API/Referral/ReferralApi";
import {NotifyBugsnagError, ShowSuccessToast} from "../../../lib/ErrorUtils";
import Switch from "../../UI/Switch/Switch";

const AddToMarketPlaceDiv = styled.div`
  float: right;
`;

const AddToMarketPlaceButton = styled.button`
  color: red;
`;

class AddToMarketPlace extends Component {

    state = {
        enabled: false,
        open: false,
    };

    componentDidMount() {
        this.setState({enabled: this.props.referral.live_on_lmp})
    }

    componentWillReceiveProps = (props) => {
        this.setState({enabled: props.referral.live_on_lmp});
    };


    toggleSwitch = () => {
        this.setState({enabled: !this.state.enabled, open: true});
        this.props.toggleLiveOnMarketPlace();
    };

    render() {

        const {toggleLiveOnMarketPlace, agentIds} = this.props;

        const valid = agentIds.length !== 0;

        return (
            <AddToMarketPlaceDiv>
                <Popup
                    trigger={
                        <a className={"market-place-checkbox"}>
                            <Switch enabled={this.state.enabled} change={() => this.toggleSwitch()}/></a>
                    }
                    modal
                    open={this.state.open}
                    closeOnDocumentClick={true}
                    closeOnEscape={true}
                >
                    {close => (
                        <div className="modal-2">
                            <div className="modal-header">
                                <span className="modal-header-text">
                                    {valid ?  "Are you sure you want to continue with this action?" : "No agent selected"}
                                </span>
                            </div>
                            <div className="dialogue-box-footer">
                                {valid && <button className="med-btn-1" onClick={() => {toggleLiveOnMarketPlace(); close();}}>
                                    YES
                                </button>}
                                <button className="med-btn-1" onClick={()=> {close();}}>
                                    {valid ? "NO" : "OK"}
                                </button>
                            </div>
                        </div>
                    )}
                </Popup>
            </AddToMarketPlaceDiv>
        );
    }
}

export default AddToMarketPlace;