import React from "react"
import styled from "styled-components"
import Button from "./../Components/RoundButton"
import { PLACE_HOLDER_IMAGE } from "./../ImagesData"


const Wrap = styled.div`
  
`

const Image = styled.img`
 
`

const Follow = (props) => (
  <Wrap
    style={{
      background: "#ffffff",
      textAlign: "center",
      padding: "10px 50px",
      marginBottom: 56,
      paddingBottom: 56,
    }}
  >
    <h2
      style={{
        fontSize: 24,
        fontWeight: 400,
      }}
    >
      Hey {`< Name >`}
    </h2>
    <p
      style={{
        fontWeight: 100,
      }}
    >
      {`< AgentName > followed you on the Radius! Follow < AgentName > back signing up for Radius and expand your agent network.`}
    </p>
    <Image
      src={PLACE_HOLDER_IMAGE}
      alt="Agent Image"
      style={{
        borderRadius: 60,
        maxWidth: 154,
      }}
    />
    <h3
      style={{
        fontSize: 24,
        fontWeight: 400,
        margin: "10px 0px 0px 0px",
        padding: 0,
        color: "#303030",
      }}
    >
      Harry Potter
    </h3>
    <h6
      style={{
        fontSize: 18,
        fontWeight: 300,
        margin: 0,
        padding: 0,
        color: "#303030",
      }}
    >
      Modi Realty
    </h6>
    <p
      style={{
        color: "#CCC",
        fontWeight: 100,
        marginTop: 20,
        marginBottom: 30,
      }}
    >
      <strong style={{ color: "#303030"}}>100</strong>
      <span style={{ fontWeight: 300 }}> Following</span>
      <strong style={{ color: "#303030"}}> 200</strong>
      <span style={{ fontWeight: 300 }}> Followers</span>
    </p>
    <Button
      style={{
        background: "#0B8DEC"
      }}
    >
      Sign up and Follow Back
    </Button>
  </Wrap>
)

export default Follow