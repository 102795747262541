import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/ProductInterest"
import { ToastContainer, toast } from "react-toastify"
import Header from "./Header"
import LeftPanel from "./LeftPanel"
import ModalForm from "./ModalForm"


const Wrap = styled.div`
 padding: 70px 100px;
 width: 100%;
`

const InnerWrap = styled.div`
  width: 100%;
  height: 1136px;
  border: solid 1px #e7e7e7;
  display: flex;
`

class ProductInterest extends Component {
  componentDidMount(){
    // apiCalls
    const {
      getAllPIMS,
    } = this.props
    getAllPIMS()
  }

  render(){
    const {
      getAllPIMResponse,
    } = this.props

    const {
      data: modals,
      isFetching,
    } = getAllPIMResponse

    return (
      <React.Fragment>
        <Wrap>
          <Header/>
          <InnerWrap>
            <LeftPanel
              modals={modals}
              isFetching={isFetching}
            />
            <ModalForm />
          </InnerWrap>
        </Wrap>
        <ToastContainer />
      </React.Fragment>

    )
  }
}


export default Container(ProductInterest)
