import React, { useState, useEffect } from "react"
import styled from "styled-components"
import globalContainer from "container/BrokerDashboard/globalPagesContainer"
import Button from "dumbComponents/common/UI/Button"

const Wrap = styled.div`
  max-width: 900px;
  margin: 20px auto;
  h1 {
    font-weight: 500;
  }
  h2 {
    font-weight: 400;
  }
`
const AssetsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const AssetItem = styled.div`
  background: #F1EEFF;
  width: 49%;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 20px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  > h4, p {
    margin: 0px;
  }

  > p {
    font-size: 14px;
    margin-top: 10px;
    color: #888;
  }

  ${props => props.isNew && `
    background: #F9F8FF;
    p {
      color: #100063;
    }
  `}
`

const ExitWrap = styled.div`
  display: flex;
  flex-direction: column;
  > input {
    width: 100%;
    margin-top: 5px;
    font-size: 18px;
    border: none;
    background: transparent;
    &:nth-child(2) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
  > button {
    align-self: flex-end;
  }
`

const RemoveButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #444;
  cursor: pointer;
`


const AllAssets = ({
  getAssetsDashboard,
  globalAssetsResponse,
  createNewGlobalAsset,
  deleteGlobalAsset,
}) => {
  useEffect(() => {
    getAssetsDashboard()
  }, [])

  const [ assetName, setAssetName ] = useState("")
  const [ assetDescription, setAssetDescription ] = useState("")
  const [ isEditing, setIsEditing ] = useState(true)
  const { isFetching, data } = globalAssetsResponse

  const handleSave = () => {
    const payload = {
      heading_display_name: assetName,
      description: assetDescription,
    }
    createNewGlobalAsset(payload)
  }

  const handleDeleteAsset = (id) => {
    console.log(id);
    deleteGlobalAsset({
      assetId: id
    })
  }

  console.log({ globalAssetsResponse });
  return (
    <Wrap>
      <h1>Assets Settings</h1>
      <h2>Current Assets</h2>
      {isFetching ? (
        <h2>Loading...</h2>
      ) : (
        <AssetsWrap>
          {data && data.map(item => (
            <AssetItem key={item.id}>
              <RemoveButton onClick={() => handleDeleteAsset(item.id)}>
                <i className="fa fa-times"></i>
              </RemoveButton>
              <h4>{item.heading_display_name}</h4>
              <p>{item.description}</p>
            </AssetItem>
          ))}
          <AssetItem isNew>
            {isEditing ? (
              <ExitWrap>
                <input
                  type="text"
                  placeholder="Asset Name"
                  onChange={(e) => { setAssetName(e.target.value) }}
                />
                <input
                  type="text"
                  placeholder="Description"
                  onChange={(e) => { setAssetDescription(e.target.value) }}
                />
                <Button onClick={handleSave} disabled={!assetName || !assetDescription}>
                  Save
                </Button>
              </ExitWrap>
            ) : (
              <p onClick={() => { setIsEditing(true) }}>Add new Asset</p>
            )} 
          </AssetItem>
        </AssetsWrap>
      )}
      
    </Wrap>
  )
}

export default globalContainer(AllAssets)