import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Input, Space } from "antd"
import RecruitAndEarn from "../../../container/RecruitAndEarn"
import Table from "./DetailsComponents/Table"
const { Search } = Input

const Wrap = styled.div``

const FilterWrap = styled.div``

class Details extends React.PureComponent {
  state = {
    searchText: "",
  }

  componentDidMount() {
    const { fetchAgentDetailsRAE } = this.props
    fetchAgentDetailsRAE({
      queryParams: "skip=0&limit=5000",
    })
  }

  onSearch = (value) => {
    const { searchText } = this.state
    this.setState({
      searchText: value,
    })
  }

  getAgentDetailsListAfterFilter = () => {
    const { searchText } = this.state
    const { getAgentDetailsResponseRAE } = this.props || {}
    const {
      data: agentDetailsList,
    } = getAgentDetailsResponseRAE || {}
    console.log("==searchText===", searchText)
    if (searchText) {
      return agentDetailsList.filter(dt => (dt.firstname && dt.firstname.toLowerCase().includes(searchText.toLowerCase())) || (dt.lastname.toLowerCase() && dt.lastname.includes(searchText.toLowerCase())))
    }
    return agentDetailsList
  }

  render() {
    const {
      getAgentDetailsResponseRAE,
    } = this.props || {}
    const {
      isFetching: fetchingAgentDetails,
    } = getAgentDetailsResponseRAE || {}
    return (
      <Wrap>
        <FilterWrap>
          <Search placeholder="Search Referring Agent" allowClear onSearch={this.onSearch} style={{ width: 400 }} />
        </FilterWrap>
        <Table
          fetchingAgentDetails={fetchingAgentDetails}
          agentDetailsList={this.getAgentDetailsListAfterFilter()}
        />
      </Wrap>
    )
  }
}

export default RecruitAndEarn(Details)
