import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Checkbox, Select, Message } from "antd";
import CheckList from "container/CheckList/index";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

const AddChecklistTemplateModal = ({
  isAddChecklistModalOpen,
  fetchChecklistMetadataResponse,
  fetchAllTemplatesForReplication,
  fetchAllTemplatesForReplicationResponse,
  addNewChecklistTemplate,
  addNewChecklistTemplateResponse,
  dropdownDataArray,
  fetchChecklistMetadata,
  fetchAllChecklistData,
  fetchAllChecklistDataResponse,
  saveNewItemToDocument,
  saveNewItemDocumentresponse,
  fetchAllTemplates,
  checklistTemplate,
  state,
  type,
  fetchAllTemplatesResponse,
  onCancel,
}) => {
  const [templateName, setTemplateName] = useState(
    checklistTemplate?.checklist_template  ? checklistTemplate?.checklist_template : ""
  );
  const [useExistingTemplate, setUseExistingTemplate] = useState(
    checklistTemplate ? true : false
  );
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [selectedTemplateData, setSelectedTemplateData] = useState(null);

  useEffect(() => {
    if (
      fetchChecklistMetadataResponse?.data?.length > 0 && fetchAllChecklistDataResponse?.data?.length>0 &&
      selectedTemplate &&
      useExistingTemplate
    ) {
      const data = fetchChecklistMetadataResponse.data.find(
        (e) => e.checklist_template === templateName
      );
      if (data?.id) {
        setSelectedTemplateData(data);
      }
    }
  }, [fetchChecklistMetadataResponse, fetchAllChecklistDataResponse]);

  useEffect(() => {

    if (
      fetchAllTemplatesForReplicationResponse?.data?.length > 0 &&
      fetchAllChecklistDataResponse?.data?.length > 0 &&
      !saveNewItemDocumentresponse?.isFetching &&
      selectedTemplateData &&
      selectedTemplate &&
      useExistingTemplate
    ) {
      const newPayload = [];

      fetchAllTemplatesForReplicationResponse.data.forEach((templateItem) => {
        const matchingChecklist = fetchAllChecklistDataResponse.data.find(
          (checklistItem) =>
            checklistItem.checklist_value === templateItem.checklist
        );

        if (
          matchingChecklist &&
          templateItem.document_items &&
          templateItem.document_items.length > 0
        ) {
          templateItem.document_items.forEach((documentItem) => {
            if (documentItem.templates && documentItem.templates.length > 0) {
              documentItem.templates.forEach((template) => {
                newPayload.push({
                  checklistId: matchingChecklist.id,
                  checklistTemplateId: selectedTemplateData.id,
                  documentItem: documentItem.document_item,
                  mandatory: documentItem.mandatory,
                  name: template.name,
                  templateId: template.templateId,
                });
              });
            } else {
              newPayload.push({
                checklistId: matchingChecklist.id,
                checklistTemplateId: selectedTemplateData.id,
                documentItem: documentItem.document_item,
                mandatory: documentItem.mandatory,
                name: null,
                templateId: null,
              });
            }
          });
        }
      });

      const payload = newPayload;
         saveNewItemToDocument({
  payload,
  callback: () => {
    if (!checklistTemplate) {
      fetchChecklistMetadata({
        state: state,
        type: type,
      });
  onCancel();
      setSelectedTemplate(null);
      setSelectedTemplateData(null);
      setTemplateName(null);
      setUseExistingTemplate(false);
    } else {
  const request = {
      state: dropdownDataArray.state,
      type: dropdownDataArray.type,
      checklistTemplate: checklistTemplate.checklist_template_value,
    };
      fetchAllTemplates(request);
         onCancel();
      setSelectedTemplate(null);
      setSelectedTemplateData(null);
      setTemplateName(null);
      setUseExistingTemplate(false);
    }
  }
});
    }
  }, [
    fetchAllTemplatesForReplicationResponse,
    fetchAllChecklistDataResponse,
    selectedTemplateData,
  ]);

  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    setUseExistingTemplate(e.target.checked);
    setSelectedTemplate(undefined);
  };

  const handleTemplateSelectChange = (value) => {
    const request = {
      state: dropdownDataArray.state,
      type: dropdownDataArray.type,
      checklistTemplate: value,
    };
    fetchAllTemplatesForReplication(request);
    setSelectedTemplate(value);
  };

  const isLoading = () => {
    return (
      fetchAllTemplatesForReplicationResponse?.isFetching ||
      fetchAllChecklistDataResponse?.isFetching ||
      fetchChecklistMetadataResponse?.isFetching ||
      saveNewItemDocumentresponse?.isFetching ||
      addNewChecklistTemplateResponse?.isFetching
    );
  };
  const handleCreateTemplate = async () => {
    if (!useExistingTemplate && templateName.trim() !== "") {
      const payload = [
        {
          state: state,
          complianceType: type,
          checklistTemplate: templateName,
        },
      ];
      await addNewChecklistTemplate({
        payload,
        callback: () =>
          fetchChecklistMetadata({
            state: state,
            type: type,
          }),
      });
      onCancel();
      setSelectedTemplate(null);
      setSelectedTemplateData(null);
      setTemplateName(null);
      setUseExistingTemplate(false);
    } else if (useExistingTemplate && selectedTemplate && !checklistTemplate) {
      const payload = [
        {
          state: state,
          complianceType: type,
          checklistTemplate: templateName,
        },
      ];
      await addNewChecklistTemplate({
        payload,
        callback: () =>
          fetchChecklistMetadata({
            state: state,
            type: type,
            callback: () =>
              fetchAllChecklistData({
                state: state,
                type: type,
                checklistTemplate: templateName,
              }),
          }),
      });
    } else {
      fetchAllChecklistData({
        state: state,
        type: type,
        checklistTemplate: checklistTemplate.checklist_template_value,
      });
    }
  };

  return (
    <Modal
      destroyOnClose={true}
      mask={true}
      maskClosable={false}
      title={null}
      visible={isAddChecklistModalOpen}
      onCancel={onCancel}
      footer={[
        <button
          className="add-due-btn border-gray"
          key="cancel"
          disabled={isLoading()}
          onClick={onCancel}
        >
          Cancel
        </button>,
        <button
          key="add"
          type="primary"
          className={`add-due-btn add-due-btn-color ${
            templateName.trim() === "" ||
            (useExistingTemplate && !selectedTemplate) ||
            isLoading() || ((useExistingTemplate && !fetchAllTemplatesForReplicationResponse?.data)  ||
              (useExistingTemplate && !Array.isArray(fetchAllTemplatesForReplicationResponse.data)))
              ? "disabled-btn"
              : ""
          }`}
          onClick={handleCreateTemplate}
          disabled={
          templateName.trim() === "" ||
            (useExistingTemplate && !selectedTemplate) ||
            isLoading() || ((useExistingTemplate && !fetchAllTemplatesForReplicationResponse?.data)  ||
              (useExistingTemplate && !Array.isArray(fetchAllTemplatesForReplicationResponse.data)))
          }
        >
          {isLoading() ? (
            <LoadingOutlined style={{ marginRight: "10px" }} />
          ) : null}
          Create Template
        </button>,
      ]}
    >
      <div>
        <p className="icon-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21 7.5L12 2.25L3 7.5M21 7.5L12 12.75M21 7.5V16.5L12 21.75M3 7.5L12 12.75M3 7.5V16.5L12 21.75M12 12.75V21.75"
              stroke="#6366F1"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </p>
        <h4 className="modal-title">{checklistTemplate?.checklist_template ? 'Replicate Checklist Template' : 'Create New Checklist Template'}</h4>
      </div>
      <p className="modal-description">
        Easily create a new checklist template by entering the necessary details
        below. You can choose to start from scratch or base your new template on
        an existing one for quicker setup.
      </p>
      <Input
        placeholder="Enter Template Name"
        value={templateName}
        disabled = {checklistTemplate?.checklist_template}
        onChange={handleTemplateNameChange}
        style={{ marginBottom: 15 }}
      />
      <Checkbox
        checked={useExistingTemplate}
        disabled={checklistTemplate}
        onChange={handleCheckboxChange}
        style={{ marginBottom: 15 }}
      >
        Use Existing Checklist Template
      </Checkbox>

           {useExistingTemplate && !fetchChecklistMetadataResponse?.isFetching ? (
          <Select
            placeholder="Select Template"
            className="dropdown-state"
            style={{ width: "100%", marginTop: 0 }}
          onChange={handleTemplateSelectChange}
          value={selectedTemplate}
          >
            {fetchChecklistMetadataResponse?.data?.map((checklist) => (
              <Option
                key={checklist.checklist_template_value}
                value={checklist.checklist_template_value}
                disabled={
                  checklist.checklist_template_value ===
                  dropdownDataArray.checklistTemplate?.checklist_template_value
                }
              >
                {checklist.checklist_template}
              </Option>
            ))}
          </Select>
      ) : null}
      
      {selectedTemplate &&
        templateName &&
        useExistingTemplate &&
        (!fetchAllTemplatesForReplicationResponse?.data  ||
          !Array.isArray(fetchAllTemplatesForReplicationResponse.data)) &&  !fetchAllTemplatesForReplicationResponse?.isFetching &&(
          <p style={{ color: "red", marginTop: 10, marginLeft: 5 }}>
            No data available for replication. Please try some other template.
          </p>
        )}
    </Modal>
  );
};

export default CheckList(AddChecklistTemplateModal);
