import React, { useEffect, useState, useStateCallback } from "react"
import styled from "styled-components"
import { get, throttle } from "lodash"
import query from "query-string"
import DayPickerInput from 'react-day-picker/DayPickerInput';
import DayPicker, { DateUtils } from 'react-day-picker';
import InfiniteScroll from "react-infinite-scroller"
import { Link } from "react-router-dom"
import Select from "react-select"
import Button from "dumbComponents/common/UI/Button"
import container from "container/BrokerDashboard/globalPagesContainer"
import TextInput from "./../Comp/TextInput"
import moment from "moment-timezone"
import { USCurrencyFormat } from "../../../shared/currencyUtils"
import { alert, Date } from "window-or-global";

const Wrap = styled.div`
  margin: 0px auto;
  max-width: 90%;
`

const TableWrap = styled.div`
  min-height: 150vh;
  width: max-content;
`

const Row = styled.div`
  border-bottom: 1px solid #CCC;
  display: flex;
  > div {
    flex-shrink: 0;
  }
  h5 {
    margin: 0px;
    font-weight: 400;
    color: #888;
    margin-top: 5px;
  }

  ${props => props.isFixed && `
    position: sticky;
    top: 0px;
    z-index: 1;
    background: #fff;
  `}
`

const HighlightWrap = styled.div`
  padding: 10px;
  width: 300px;
  background: #F9F8FF;
  position: relative;
  border-right: 1px solid #CCC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > p {
    margin: 0px;
  }
  ${props => props.isHead && `
    background: #F0F0F0;
  `}
`

const StarWrap = styled.span`
  margin-left: 10px;
  cursor: pointer;
  i.fa.fa-star {
    color:  #FFD700;
  }
`

const GeneralWrap = styled.div`
  width: 220px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #CCC;
  > p {
    margin: 0px;
  }
  ${props => props.isHead && `
    background: #F0F0F0;
  `}
`

const Header = styled.div`
  position: relative;
  align-items: center;
  margin: 30px 0px;
  > h1 {
    flex: 1;
  }
  > div {
    margin: 0px 10px;
    display: flex;
    align-items: flex-end;
    > input {
      margin-right: 10px;
    }
  }
`

const IconWrap = styled.span`
  display: flex;
  position: absolute;
  top: 15px;
  right: 10px;
  color: #999;
  transition-duration: 0.3s;
  cursor: pointer;

  ${props => props.isBeingApplied && `
    color: blue;
  `}
  &:hover {
    color: #333;
  }
`

const Input = styled.input`
  width: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`

const MaxMinWrapper = styled.div`
  margin: 0px 8px;
  h6 {
    margin: 0px 0px 6px 10px;
    font-size: 16px;
  }
  > div {
    display: flex;
    input {
      margin: 0px 5px;
      padding: 11px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  div[class*="-container"] {
    width: 150px;
  }

  &.agent-type-container {
    div[class*="-container"] {
      width: 180px;
    }
    div[class*="-control"] {
      width: 180px;
    }
  }

  .DayPickerInput {
    input {
      width: 100px;
    }
  }
`

const getHeight = () => {
    const body = document.body,
        html = document.documentElement;
    return Math.max( body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight );

}

const SIZE = 50
const ORDER_TYPE = {
    DESC: "DESC",
    ASC: "ASC"
}
const SORT_TYPES = {
    BY_NAME: "nameSort",
    BY_TOTAL_DEALS: "dealsSort",
    BY_DEALS_PER_MONTH: "dealsPerMonthSort",
    BY_OVERALL_CONVERSION_PERCENTAGE: "overallConversionPercentageSort",
    BY_LEADS_OFFERED: "leadsOfferedSort",
    BY_LEADS_CLAIMED: "leadsClaimedSort",
    BY_LIVE_TRANSFER_CLAIMS: "liveTransferClaimsSort",
    BY_MARKETPLACE_CLAIMS: "marketplaceClaimsSort",
    BY_DIRECT_ASSIGNMENTS: "directAssignmentsSort",
    BY_LEADS: "leadsSort",
    BY_ACTIVE: "activeSort",
    BY_APPOINTMENT_SET: "appointmentSetSort",
    BY_MET_WITH_CUSTOMER: "metWithCustomerSort",
    BY_SHOWING_HOMES: "showingHomesSort",
    BY_SUBMITTING_OFFERS: "submittingOffersSort",
    BY_IN_CONTRACT: "inContractSort",
    BY_CLOSED: "closedSort",
    BY_ON_HOLD: "onHoldSort",
    BY_ARCHIVED: "archivedSort",
    BY_TOTAL: "totalSort",
}

const AGENT_TYPE_OPTIONS = [
    { label: "Brokerage Agent", value: "brokerage" },
    { label: "Network Agent", value: "network" },
]

const NETWORK_AGENT_TYPE = "network"

const AgentPipeline = ({
                    getAgentPipelinePage,
                    agentPipelinePageResponse,
                    clearAgentPipelinePageResults,
                    getReferralSources,
                    referralSourcesResponse,
                    match,
                    location,
                    updateAgentSettings,
                    ...props
                }) => {

    const [ searchText, setSearchText ] = useState("")
    const [ isShowDateSelector, setIsShowDateSelector ] = useState(false)
    const [ sourceFilter, setSourceFilter ] = useState("")
    const [ dateRange, setDateRange ] = useState({
        from: undefined, to: undefined
    })
    const [ agentTypeFilter, setAgentTypeFilter ] = useState("brokerage")
    const [ sortBy, setSortBy ] = useState(SORT_TYPES.BY_NAME)
    const [ sortDir, setSortDir ] = useState(ORDER_TYPE.ASC)

    useEffect(() => {
        getReferralSources()
        const payload = {
            limit: SIZE
        }
        getAgentPipelinePage(payload)
    }, [])

    const onToggleStarred = (agentId, starred_for_lmp) => {
        updateAgentSettings({
            payload: {
                starred_for_lmp: starred_for_lmp === 0 ? 1 : 0,
            },
            agentId,
            pageType: "agent-pipeline",
        })
    }

    const handleSortByRequest = (type = "", setDir = null) => {
        clearAgentPipelinePageResults()
        let newSortState = sortDir === ORDER_TYPE.DESC ? ORDER_TYPE.ASC : ORDER_TYPE.DESC
        if (setDir) {
            newSortState = setDir
        }
        getAgentPipelinePage({
            ...createPayload(),
            sortBy: `${type}=${newSortState}`,
            skip: 0,
        })
        setSortDir(newSortState)
        setSortBy(type)
    }

    const fetchMore = () => {
        getAgentPipelinePage(createPayload({
            isFetchingMore: true,
        }))
    }

    const handleSearch = () => {
        setIsShowDateSelector(false)
        if (sourceFilter || dateRange.from || dateRange.to || agentTypeFilter === NETWORK_AGENT_TYPE) {
            handleSortByRequest(SORT_TYPES.BY_LEADS_CLAIMED, ORDER_TYPE.DESC)
        } else {
            getAgentPipelinePage(
                createPayload({}, true)
            )
        }
    }

    const createPayload = (extra = {}, clearSkip = false) => {
        const payload = {
            limit: SIZE,
            ...extra,
        }
        const resultCount = get(agentPipelinePageResponse, "data.result.length")
        if (resultCount && resultCount > 0) {
            payload.skip = resultCount
        }
        if (searchText) {
            payload.agentEmail = searchText
        }
        if (sourceFilter) {
            payload.source = sourceFilter
        }
        if (dateRange.from) {
            payload.fromClaimed = moment(dateRange.from.setHours(0,0,0)).unix()
        }
        if (dateRange.to) {
            payload.toClaimed = moment(dateRange.to.setHours(0,0,0)).add("1", "day").unix()
        }
        if (agentTypeFilter) {
            payload.agentType = agentTypeFilter
        }
        if (sortBy) {
            payload.sortBy = `${sortBy}=${sortDir}`
        }
        if (clearSkip) {
            payload.skip = 0
        }
        return payload
    }
    const { data, isFetching, isFetchingMore } = agentPipelinePageResponse
    const { data: referralSources = [], isFetching: isFetchingSources } = referralSourcesResponse
    const options = []
    referralSources && referralSources.map(source => (
        options.push({ label: source.name, value: source.value })
    ))

    return (
        <Wrap>
            <Header style={{ zIndex: 3 }}>
                <h1>All Agents</h1>
                <div>
                    <MaxMinWrapper>
                        <h6>Agent Email</h6>
                        <div>
                            <Input
                                style={{ width: 300 }}
                                isHideLabel={true}
                                placeholder="Search agent by email"
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value)
                                }}
                            />
                        </div>
                    </MaxMinWrapper>
                    <MaxMinWrapper style={{ zIndex: 100 }}>
                        <h6>Source</h6>
                        <div>
                            <Select
                                width={200}
                                isMulti={false}
                                style={{ zIndex: 100, margin: "0px 20px" }}
                                options={[
                                    ...options,
                                ]}
                                placeholder="Select"
                                onChange={(value) => {
                                    setSourceFilter(value.value)
                                }}
                            />
                        </div>
                    </MaxMinWrapper>
                    <MaxMinWrapper style={{ marginRight: 20 }}>
                        <h6>Claimed Date</h6>
                        <div>
                            <DayPickerInput
                                type="text"
                                onDayChange={(day) => { setDateRange({
                                    ...dateRange,
                                    from: day,
                                }) }}
                                placeholder="From"
                                style={{ width: 100, marginRight: 20 }}
                            />
                            <DayPickerInput
                                type="text"
                                onDayChange={(day) => { setDateRange({
                                    ...dateRange,
                                    to: day,
                                }) }}
                                style={{ width: 100 }}
                                placeholder="To"
                            />
                        </div>
                    </MaxMinWrapper>
                    <MaxMinWrapper className="agent-type-container"  style={{ zIndex: 100 }}>
                        <h6>Agent Type</h6>
                        <div>
                            <Select
                                width={200}
                                isMulti={false}
                                style={{ zIndex: 100, margin: "0px 20px" }}
                                options={AGENT_TYPE_OPTIONS}
                                defaultValue={AGENT_TYPE_OPTIONS.find(option => option.value === "brokerage")}
                                onChange={(value) => {
                                    setAgentTypeFilter(value.value)
                                }}
                            />
                        </div>
                    </MaxMinWrapper>
                    <Button success style={{ padding: 10, marginLeft: 20 }} onClick={handleSearch}>
                        Search
                    </Button>
                </div>
            </Header>
            {isFetching ? (
                <h1>Loading..</h1>
            ) : (
                <React.Fragment>
                    {data && data.result && data.result.length === 0 ? (
                        <div>
                            <h3>No results</h3>
                        </div>
                    ) : (
                        <TableWrap>
                            <Row isFixed>
                                <HighlightWrap isHead>
                  <span>
                    Agent Details
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_NAME) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_NAME}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down": "up"}`}></i>
                                    </IconWrap>
                                </HighlightWrap>
                                {(data && (!data.agent_type || data.agent_type !== NETWORK_AGENT_TYPE)) && (
                                <GeneralWrap isHead>
                                    <span>
                                        Total Deals
                                    </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_TOTAL_DEALS) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_TOTAL_DEALS}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                )}
                                {(data && (!data.agent_type || data.agent_type !== NETWORK_AGENT_TYPE)) && (
                                <GeneralWrap isHead>
                                    <span>
                                        Deals Per Month
                                    </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_DEALS_PER_MONTH) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_DEALS_PER_MONTH}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                )}
                                <GeneralWrap isHead>
                  <span>
                    Overall Conversion %
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_OVERALL_CONVERSION_PERCENTAGE) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_OVERALL_CONVERSION_PERCENTAGE}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    Leads Offered
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_LEADS_OFFERED) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_LEADS_OFFERED}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    Leads Claimed
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_LEADS_CLAIMED) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_LEADS_CLAIMED}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    Live Transfer Claims
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_LIVE_TRANSFER_CLAIMS) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_LIVE_TRANSFER_CLAIMS}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    Marketplace Claims
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_MARKETPLACE_CLAIMS) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_MARKETPLACE_CLAIMS}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    Direct Assignments
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_DIRECT_ASSIGNMENTS) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_DIRECT_ASSIGNMENTS}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    Leads
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_LEADS) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_LEADS}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    Active
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_ACTIVE) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_ACTIVE}

                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down": "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    Appointment Set
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_APPOINTMENT_SET) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_APPOINTMENT_SET}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down": "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    Met With Customer
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_MET_WITH_CUSTOMER) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_MET_WITH_CUSTOMER}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down": "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    Showing Homes
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_SHOWING_HOMES) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_SHOWING_HOMES}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down": "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    Submitting Offers
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_SUBMITTING_OFFERS) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_SUBMITTING_OFFERS}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down": "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    In Contract
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_IN_CONTRACT) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_IN_CONTRACT}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down": "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    Closed
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_CLOSED) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_CLOSED}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down": "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    On Hold
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_ON_HOLD) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_ON_HOLD}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down": "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    Archived
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_ARCHIVED) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_ARCHIVED}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down": "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                                <GeneralWrap isHead>
                  <span>
                    Total
                  </span>
                                    <IconWrap
                                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_TOTAL) }}
                                        isBeingApplied={sortBy === SORT_TYPES.BY_TOTAL}
                                    >
                                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down": "up"}`}></i>
                                    </IconWrap>
                                </GeneralWrap>
                            </Row>
                            <InfiniteScroll
                                hasMore={!isFetchingMore && data && !data.isDone}
                                loadMore={throttle(() => { fetchMore() }, 4000)}
                                threshold={250}
                            >
                                { data && data.result && data.result.map(agent => (
                                    <Row key={agent.agent_id}>
                                        <HighlightWrap>
                                            <div>
                                                <Link to={`/broker/dashboard/${agent.agent_id}/subscription`}>
                                                    {`${agent.firstname || ""} ${agent.lastname || ""}`}
                                                </Link>
                                                <StarWrap onClick={() => { onToggleStarred(agent.agent_id, agent.starred_for_lmp) }}>
                                                    <i className={agent.starred_for_lmp ? "fa fa-star" : "fa fa-star-o"}></i>
                                                </StarWrap>
                                            </div>
                                            <h5>
                                                {agent.email}
                                            </h5>
                                        </HighlightWrap>
                                        {(data && (!data.agent_type || data.agent_type !== NETWORK_AGENT_TYPE)) && (
                                            <GeneralWrap>
                                                {agent.deals}
                                            </GeneralWrap>
                                        )}
                                        {(data && (!data.agent_type || data.agent_type !== NETWORK_AGENT_TYPE)) && (
                                            <GeneralWrap>
                                                {agent.deals_per_month}
                                            </GeneralWrap>
                                        )}
                                        <GeneralWrap>
                                            {`${agent.overall_conversion_percentage}%`}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.leads_offered}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.leads_claimed}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.live_transfer_claims}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.marketplace_claims}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.direct_assignments}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.leads}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.active}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.appointment_set}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.met_with_customer}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.showing_homes}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.submitting_offers}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.in_contract}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.closed}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.on_hold}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.archived}
                                        </GeneralWrap>
                                        <GeneralWrap>
                                            {agent.total}
                                        </GeneralWrap>
                                    </Row>
                                ))}
                            </InfiniteScroll>
                            {(data && !data.isDone) && (
                                <h4>Loading more..</h4>
                            )}
                        </TableWrap>
                    )}
                </React.Fragment>
            )}
            <style>
                {`
          .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
            background-color: #f0f8ff !important;
            color: #4a90e2;
          }
          .Selectable .DayPicker-Day {
            border-radius: 0 !important;
          }
          .Selectable .DayPicker-Day--start {
            border-top-left-radius: 50% !important;
            border-bottom-left-radius: 50% !important;
          }
          .Selectable .DayPicker-Day--end {
            border-top-right-radius: 50% !important;
            border-bottom-right-radius: 50% !important;
          }
        
        `}
            </style>
        </Wrap>
    )
}

export default container(AgentPipeline)