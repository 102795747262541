import React, { Component } from "react"
import styled from "styled-components"
import { reduxForm, Field, formValueSelector} from "redux-form"
import Container from "container/Academy"
import root from "window-or-global"
import { ToastContainer } from "react-toastify"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"
import { DefaultLoader } from "components/UI/Loader/Loader";


const Wrap = styled.div`
  height: max-content;
  width: 188px;
  .alert {
   font-size: 11px;
   p {
    span {
     color: red;
    }
   }
  }
`
const HeaderList = styled.li`
  background-color: #f4f5f8;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  padding: 10px 10px 15px 15px;
`

const ListItem = styled.li`
  padding: 10px 10px 15px 15px;
  width: 100%;
  height: 37px;
  border-bottom: 1px solid #e2e4e8;
  font-size: 14px;
  ${props => props.isActive && `
    border-left: 3px solid orange;
   `}
   a {
    text-decoration: none;
    color: black;
    cursor: pointer;
   }
`

const MenuList = styled.ul`
  width: 100%;
  height: max-content;
  margin: 0;
  padding: 0;
  border: 1px solid #e2e4e8;
  border-radius: 3px;
  list-style: none;
`

const DraftButton = styled.button`
  width: 145px;
  height: 30px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  ${props => props.disabled && `
     background-image: none !important;
     background: grey;
   `}
`

const Publish = styled.button`
   width: 145px;
   height: 30px;
   border-radius: 4px;
   font-size: 16px;
   font-weight: 500;
   text-align: center;
   cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
   ${props => props.disabled && `
      background-image: none !important;
      background: grey;
    `}
`

const CTAWrap = styled.div`
  height: max-content;
  width: 100%;
  margin-top: 46px;

  .publish {
   background-image: linear-gradient(to bottom, #34ce57, #28a745);
   border: solid 1px #2a8b3f;
   color: white;
  }

  .draft {
   border: solid 1px rgba(27, 31, 35, 0.12) !important;
   background-color: #fafbfc;
   color: black;
  }
`

class AddCourseMenu extends Component {
 componentDidMount(){
 }

 handleCourseSubmission = (type, isPublished) => {
  const {
   globalState,
   aboutCourse,
   createCourse,
   updateCourse,
   instructorArray,
   courseDetailResponse,
   arranged,
   lessonBreakdown,
   courseAnnouncementText,
   courseReminderPost,
   accessArray,
   currentImageURL,
   getCourseListingForCategoryResponse,
   currentCategorySelected,
   testOnly,
   currentTopicSelected,
   isPromoted,
  } = this.props

  const {
   data: course,
  } = courseDetailResponse || {}

  const {
   data: courseList,
  } = getCourseListingForCategoryResponse || {}

  let tempCourseDet = course
  const aboutCourseFormSelector = formValueSelector("addCoursesForm")
  const courseTitle = aboutCourseFormSelector(globalState, "courseTitle")

  const courseExists = courseList
  && Array.isArray(courseList)
  && courseList.length > 0
  const filteredCourseList = courseExists && courseList.filter(key => key.published !== 0) || []
  const lastSequence = courseExists
  && filteredCourseList.length > 0
  && filteredCourseList[filteredCourseList.length - 1].sequence_number + 1 || 1

  const category_id = currentCategorySelected[0].value
  const category_name = currentCategorySelected[0].label
  const topic_id = currentTopicSelected[0].value

  if(type === "draft") {
   createCourse({
    title: courseTitle,
    aboutCourse,
    lessonBreakdown,
    currentImageURL,
    instructors: instructorArray,
    lastSequence: null,
    category_id,
    category_name,
    topic_id,
   })
  }

  if(type === "non-draft") {
    const payload = {
      title: courseTitle,
      aboutCourse,
      lessonBreakdown,
      instructors: instructorArray,
      arranged,
      exisitingCourseDetails: course,
      courseAnnouncementText,
      courseReminderPost,
      accessArray,
      currentImageURL,
      lastSequence,
      isPublished,
      testOnly,
      topic_id,
      is_promoted: isPromoted,
    }


   updateCourse(payload)
  }
 }

 render(){
  const {
   submitFunc,
   isUpdateMode,
   isPublished,
   courseReminderPost,
   courseAnnouncementText,
   getCourseListingForCategoryResponse,
   currentCategorySelected,
   getCategoriesResponse,
  } = this.props

  const {
   isFetching: fetchingCoursesList,
  } = getCourseListingForCategoryResponse || {}

  const {
   data: categories,
   isFetching: fetchingCategories,
  } = getCategoriesResponse || {}

  const enableDraft = currentCategorySelected[0].value !== "select-value"

  const enablePublish = (
   courseReminderPost
   && courseReminderPost.length > 0
   && courseAnnouncementText
   && courseAnnouncementText.length > 0)
   || isPublished

  const showNoCategoriesError = categories && categories.length < 0

  return (
   <Wrap>
     <MenuList>
      <HeaderList> Course Details </HeaderList>
      <ListItem
       isActive={
        root.location.pathname.indexOf('course') > -1
        && root.location.pathname.indexOf('lesson') === -1
        && root.location.pathname.indexOf('settings') === -1
       }
      >
       <Link
       to={isUpdateMode ? `/academy-admin/courses/course/${isUpdateMode}` : "/academy-admin/courses/add-new-course"}
       >
        About Course
       </Link>
      </ListItem>
      {isUpdateMode && (
       <ListItem
       isActive={
        root.location.pathname.indexOf('lesson') > -1
       }
       >
         <Link
         to={isUpdateMode ? `/academy-admin/courses/course/${isUpdateMode}/lessons` : "/academy-admin/courses/add-new-course/lesson"}
         >
          Lesson
         </Link>
       </ListItem>
      )}
      {isUpdateMode && (
       <ListItem
         isActive={
          root.location.pathname.indexOf('settings') > -1
         }
       >
        <Link to={`/academy-admin/courses/course/${isUpdateMode}/settings`}>
         Settings
        </Link>
       </ListItem>
      )}
     </MenuList>
     <CTAWrap>
       {!isUpdateMode ? (
        <DraftButton disabled={!enableDraft} onClick={() => this.handleCourseSubmission("draft")} className="draft" type="submit">
         Save As Draft
        </DraftButton>
       ) : (
         <React.Fragment>
          {fetchingCoursesList ? (
           <DefaultLoader height={20} width={20}/>
          ) : (
           <Publish disabled={!enablePublish} onClick={() => this.handleCourseSubmission("non-draft", isPublished)} className="publish" type="submit">
            {isPublished ? "Update Course" : "Publish Course"}
          </Publish>
          )}
         </React.Fragment>
       )}
     </CTAWrap>
     {showNoCategoriesError && (
      <div className="alert">
        <p>
         No categories available. Courses cannot be created without a category, create a category
         <a href="/academy-admin/categories/add-new-category" target="_blank"> here.</a>
        </p>
      </div>
     )}
     {isUpdateMode && (
      <div className="alert">
        <p>Fields marked with <span> *</span> are mandatory, please check before publishing or updating.</p>
        {!enablePublish
        && (
         <p>Notification Settings are mandatory, please fill both via settings page before publishing.</p>
        )}
      </div>
     )}
     <ToastContainer />
   </Wrap>
  )
 }
}

export default Container(AddCourseMenu)
