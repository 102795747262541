import React, { PureComponent } from "react"
import styled from "styled-components"
import TechAssetsContainer from "container/TechAssets"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { Button, Tooltip } from "dumbComponents/common/UI/antd"
import ZeroState from "dumbComponents/common/ZeroState"
import colors from "../../../../lib/colors"
import {
  PageArray,
  VendorSelectStep,
  LenderSelectRoute,
  CreateVendorRoute,
  ListingAgentInfoRoute,
  UpdateVendorRoute,
  VendorInfoStep,
} from "../../consts"
import {
  fetchDetails,
  getDealId,
  getAgentId,
} from "../../commonUtils"
import Sidebar from "../Sidebar"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const AddForm = styled.div`
  padding: 0 0 34px 0;
  text-align: right;
`

const HeaderNav = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const VendorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  

  justify-content: ${props => props.justify || "unset"}

  ${props => props.gap && `
    gap: ${props.gap};
  `}
`

const VendorBox = styled.div`
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
  width: 47%;
  margin: 10px;

  ${props => props.selected && `
    border: 1px solid #1a8ffb;
  `}
`
const VendorHeader = styled.div`
  border-bottom: 1px solid #E5E5E5;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 22px;
  h1 {
    color: #303030;
    margin: 0 0 10px;
  }
  p {
    margin: 5px 0 0 0;
    font-size: 14px;
  }
`
const VendorContent = styled.div`
  margin: 20px 0px;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  color: #303030;
  span {
    color: #8C8C8C;
  }
`
const VendorButton = styled(Button)`
  float: right;

  ${props => props.disabled && `
    background: grey !important;
    color: white !important;
    opacity: 0.5;
  `}
`

class CoverSheet extends PureComponent {
  componentDidMount() {
    const { getDealDetails } = this.props

    fetchDetails(getDealDetails, VendorInfoStep)
  }

  onFinish = (vendorId) => {
    const { updateOffer, history, unselect } = this.props

    const agentId = getAgentId() || false

    const payload = {
      current_offer_step: VendorSelectStep,
      vendor_id: unselect,
    }

    updateOffer({
      payload,
      nextRoute: LenderSelectRoute,
      history,
      dealId: getDealId(),
      dealAgentId: agentId,
    })
  }

  vendorToEdit = (id, data) => {
    const { history, setCurrentVendorEditDetails } = this.props
    setCurrentVendorEditDetails({
      id,
      data,
    })

    history.push(`${UpdateVendorRoute}/${id}?dealId=${getDealId()}&agentId=${getAgentId()}`)
  }

  getVendorType = (type) => {
    switch (type) {
      case "title":
        return "Title"
      case "escrow":
        return "Escrow"
      case "title_and_escrow":
        return "Escrow & Title"
      case "closing_attorney":
        return "Closing Attorney"
      default:
        return ""
    }
  }

  addNewVendor = () => {
    const { history, setCurrentVendorEditDetails } = this.props
    setCurrentVendorEditDetails({
      id: null,
      data: null,
    })
    history.push(`${CreateVendorRoute}?dealId=${getDealId()}&agentId=${getAgentId()}`)
  }

  render() {
    const {
      history,
      getCurrentDealDetailsResponse,
      updateOfferActionResponse,
      unselect,
      setUnselect,
    } = this.props

    const {
      data: vendorData,
      isFetching: fetchingDeal,
    } = getCurrentDealDetailsResponse || {}

    const {
      isFetching: updatingDeal,
    } = updateOfferActionResponse || {}

    const isLoading = fetchingDeal || updatingDeal

    console.log("vendorData", vendorData) 
    

    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Escrow & Title Information
        </HeaderNav>
        <ProgressBarComponent total={PageArray} ongoingOrCompleted={6} />

        <Container>
          <LeftSideBar>
            <Sidebar />
          </LeftSideBar>
          <RightSideBar>
          <AddForm>
              <Button
                ghost
                style={{
                  float: "right",
                  borderColor: "#3B60E4",
                  color: "#3B60E4",
                  display: "flex",
                  alignItems: "center",
                }}
                type="primary"
                shape="round"
                size="large"
                icon={<PlusCircleOutlined />}
                onClick={() => { this.addNewVendor() }}
              >
                Add new vendor
              </Button>
            </AddForm>
            <VendorContainer>
              {isLoading ? (
                <p>Loading</p>
              ) : (
                <>
                  {vendorData && vendorData.vendors && vendorData.vendors.length <= 0 && (
                   <ZeroState
                    height="474px"
                    width="100%"
                    backgroundColor="#F9F9F7"
                    text="No Vendors Added"
                    logo=""
                    onClickHandler={() => { this.addNewVendor() }}
                    displayStyle="flex"
                    alignItems="center"
                    justifyContent="center"
                    buttonCTAText="Add Vendor"
                    innerBoxDisplayStyle="flex"
                    innerBoxFlexDirection="column"
                    innerBoxContentJustification="center"
                    innerBoxItemAlign="center"
                    buttonStyles="#3A60E4"
                 />
                  )}
                  {vendorData && vendorData?.vendors?.map(dt => (
                    <VendorBox
                      selected={vendorData.selected_vendor_id === dt.id}
                    >
                      <VendorHeader>
                        <h1>
                          {this.getVendorType(dt.vendor_type) || "Unknown"}
                          {dt.draft === "Y" && (
                            <p>
                              In Draft
                            </p>
                          )}
                        </h1>
                        <EditOutlined onClick={() => this.vendorToEdit(dt.id, dt)} />
                      </VendorHeader>
                      <VendorContent>
                        <p>
                          {dt.vendor_name || ""}
                        </p>
                        <p>
                          {dt.vendor_number || ""}
                        </p>
                        <p>
                          {`${dt.vendor_officer_first_name || ""} ${dt.vendor_officer_last_name || ""}`}
                        </p>
                        <p>
                          {`${dt.vendor_address || ""}, ${dt.vendor_city || ""}, ${dt.vendor_state || ""} ${dt.vendor_zipcode || ""}`}
                        </p>
                        <p>
                          {dt.vendor_email || ""}
                        </p>
                        <p>
                          {dt.vendor_phone || ""}
                        </p>
                        {dt.notes && (
                          <span>
                            Notes:
                            {dt.notes}
                          </span>
                        )}
                      </VendorContent>
                      {dt.draft === "Y" ? (
                        <div
                          style={{
                            float: "right",
                          }}
                        >
                          <Tooltip
                            placement="topLeft"
                            title="Vendor details are incomplete."
                          >
                            <VendorButton disabled>Select</VendorButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <>
                          {unselect === dt.id ? (
                            <VendorButton
                              type="default"
                              // disabled={dt.draft === "Y" || vendorData.selected_vendor_id === dt.id}
                              loading={isLoading}
                              onClick={() => { setUnselect(null) }}
                            >
                              Selected
                            </VendorButton>
                          ) : (
                            <VendorButton
                              type="primary"
                              // disabled={dt.draft === "Y" || vendorData.selected_vendor_id === dt.id}
                              loading={isLoading}
                              onClick={() => { setUnselect(dt.id) }}
                            >
                              Select
                            </VendorButton>
                          )}
                        </>
                      )}
                    </VendorBox>
                  ))}
                </>
              )}
            </VendorContainer>
            <VendorContainer
              justify="flex-end"
              gap="8px"
              
            >
              <VendorButton
                style={{
                  width: 119,
                  height: 48,
                  background: "#3B60E4",
                  float: "right",
                  borderColor: "white",
                }}
                type="primary"
                shape="round"
                size="large"
                onClick={() => { history.push(`${ListingAgentInfoRoute}?dealId=${getDealId()}&agentId=${getAgentId()}`) }}
              >
                Back
              </VendorButton>
              {vendorData && !vendorData.selected_vendor_id && !unselect ? (
                <div
                  style={{
                    float: "right",
                  }}
                >
                  <Tooltip
                    placement="topLeft"
                    title="Skip and proceed."
                  >
                    <VendorButton
                      style={{
                        width: 119,
                        height: 48,
                        background: "#3B60E4",
                        float: "right",
                        borderColor: "white",
                        color: "white",
                      }}
                      type="primary"
                      shape="round"
                      onClick={() => { history.push(`${LenderSelectRoute}?dealId=${getDealId()}&agentId=${getAgentId()}`) }}
                    >
                      Skip
                    </VendorButton>
                  </Tooltip>
                </div>
              ) : (
                <VendorButton
                  style={{
                    width: 119,
                    height: 48,
                    background: "#3B60E4",
                    float: "right",
                    borderColor: "white",
                    color: "white",
                  }}
                  type="primary"
                  shape="round"
                  size="large"
                  disabled={vendorData && !vendorData.selected_vendor_id && !unselect}
                  loading={isLoading}
                  htmlType="button"
                  onClick={this.onFinish}
                >
                  Next
                </VendorButton>
              )}
            </VendorContainer>
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)
