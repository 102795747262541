export const getText = (status) => {
  switch (status) {
    case "reported_good":
      return "Reported Good"
    case "reported_bad":
      return "Reported Bad"
    case "action_required":
      return "Action Required"
    case "resolved":
    default:
      return "Resolved"
  }
}

export const getIcon = (status) => {
  switch (status) {
    case "reported_good":
      return "https://s3.amazonaws.com/cdn.agentdesks.com/images/SOUL_AI/thumbsUp.png"
    case "reported_bad":
      return "https://s3.amazonaws.com/cdn.agentdesks.com/images/SOUL_AI/thumbsDown.png"
    case "action_required":
      return "https://s3.amazonaws.com/cdn.agentdesks.com/images/SOUL_AI/YellowInfo.svg"
    case "resolved":
    default:
      return "https://s3.amazonaws.com/cdn.agentdesks.com/images/SOUL_AI/soul_checkmark.svg"
  }
}

export const getBGColor = (status) => {
  switch (status) {
    case "reported_good":
      return "#E5FFF2"
    case "reported_bad":
      return "#F87171"
    case "action_required":
      return "#FFE9D3"
    case "resolved":
    default:
      return "#ECECF0"
  }
}
