import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import colors from "../../../lib/colors"
import { reduxForm, Field, FieldArray, formValueSelector } from "redux-form"
import Select from "react-select"
import moment from 'moment-timezone';
import _ from 'lodash';

const ListWrap = styled.ul`
  list-style: none;
  padding-left: 0;

  li {
   display: flex;
   flex-direction: column;
   .entryField {
    display: flex;
    padding: 20px 0px 0px 0px;
    .sub {
     margin: 0;
     padding-left: 19px;
     font-size: 12px;
     color: #586069;
    }
    input{
     margin-right: 10px;
    }
    button {
     height: 22px;
    }
   }
  }
`
const TimeStamp = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #586069;
`

const renderField = ({ input, label, type, meta: { touched, error } }) => {
 const minDate = new Date().toISOString().slice(0, 16)
 return (
  (
    <div>
      <div>
        <input id="dateInputHere" {...input} type={type} placeholder={label} min={minDate} />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  )
 )
}

class TimeFields extends React.Component{
 state = {
  indexLength: 0,
 }

 handleDelete = (fields, index) => {
  fields.remove(index)
  const {
   dateIndex,
   dateIndexUpdate,
  } = this.props
  dateIndexUpdate({
   dateIndex: dateIndex - 1,
  })
 }

 handleIndex = (dIndex) => {
  const {
   dateIndex,
   dateIndexUpdate,
  } = this.props
  dateIndexUpdate({
   dateIndex: dateIndex + 1,
   hideOldTime: true,
  })
 }

 render() {
  const {
   fields,
   dateIndex,
   getEventDetailsResponse,
   globalState,
  } = this.props

  const dateArray = []

  const aboutDayTimeFormSelector = formValueSelector("addDayTimeForEventsForm")

  for(let i = 0; i < dateIndex; i++) {
   dateArray.push({
    start: new Date(aboutDayTimeFormSelector(globalState, `${i}_start_date`)).getTime() / 1000,
    end: new Date(aboutDayTimeFormSelector(globalState, `${i}_end_date`)).getTime() / 1000,
   })
  }

  const {
   data,
  } = getEventDetailsResponse || {}

  const eventExist = data && Object.keys(data).length > 0

  return (
    <ListWrap>
      <div>
        <button
         type="button"
         disabled={dateIndex > 0}
         onClick={() => {
         fields.push({})
         this.handleIndex(dateIndex)
        }}>
          Add/Update
        </button>
      </div>
      {dateArray && dateArray.length > 0 && (
        <div>
           <p>Time Entered</p>
           <TimeStamp>{`Starts on ${moment.unix(dateArray[0].start).tz("America/Tijuana").format("dddd, MMMM Do YYYY, h:mm:ss a z")}`}</TimeStamp>
           <TimeStamp>{`Ends on ${moment.unix(dateArray[0].end).tz("America/Tijuana").format("dddd, MMMM Do YYYY, h:mm:ss a z")}`}</TimeStamp>
        </div>
      )}
      {fields.map((member, index) => {
        if(index <= 0){
          return (
            <li key={index}>
             <div className="entryField">
               <Field
                 className="fieldRow"
                 name={`${index}_start_date`}
                 type="datetime-local"
                 component={renderField}
               />
               <Field
                 className="fieldRow"
                 name={`${index}_end_date`}
                 type="datetime-local"
                 component={renderField}
               />
               <button
                 type="button"
                 title="Remove Member"
                 onClick={() => this.handleDelete(fields, index)}
               > Remove </button>
             </div>
            </li>
          )
        }
      })}
    </ListWrap>
  )
 }
}

export default Container(TimeFields)
