import React, { useEffect } from "react"
import styled from "styled-components"
import { ToastContainer } from "react-toastify"
import RARTechContainer from "container/TechAssets"
import Header from "./Header"
import Tabs from "./Tabs"
import Stats from "./Common/Stats"

const Wrap = styled.div`
  padding: 20px 74px 20px 74px;

  @media(max-width: 1280px) {
    padding: 10px;
  }
`

const RecruitDashboard = () => (
  <Wrap>
    <Header />
    {/* <Stats /> */}
    <Tabs />
    <ToastContainer />
  </Wrap>
)

export default RARTechContainer(RecruitDashboard)
