import React, { useState } from "react"
import styled from "styled-components"
import { get } from "lodash"
import Loader from "dumbComponents/common/UI/Loader"
import Button from "dumbComponents/common/UI/Button"
import TextInput from "dumbComponents/BrokerDashboard/Comp/TextInput"

const Wrap = styled.div`
  position: relative;
  z-index: 20;
`

const ClientList = styled.div`
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0px;
  right: 0px;
  box-shadow: rgb(0 0 0 / 5%) 0px 4px 10px 0px;
  background: #fff;
`

const Client = styled.div`
  border: 1px solid #CCC;
  border-bottom: none;
  padding: 10px;
  position: relative;
  cursor: pointer;
  &:hover {
    background: #e1f2ff;
  }
  &:last-child {
    border-bottom: 1px solid #CCC;
  }
  > p {
    padding: 0;
    margin: 0;
    &:nth-child(2) {
      color: #666;
      font-size: 12px;
    }
  }
`

const RFType = styled.div`
  display: inline-block;
  padding: 4px;
  font-size: 10px;
  background: #009688;
  color: #fff;
  border-radius: 4px;
`

const NavWrap = styled.div`
  position: absolute;
  top: 25px;
  right: 0px;
  background: #fff;
`

const ClientSearch = ({
  searchClientByText,
  agentId,
  secureIdForTC,
  clients,
  handleInput,
  addClient,
}) => {
  const [searchText, setSearchText] = useState("")
  const [isShowLoader, setLoader] = useState(false)
  const [clientsList, setClientList] = useState([])

  const handleSearch = (e) => {
    const { value } = e.target
    setSearchText(value)
    loadOptions(value, (data) => {
      setClientList(data)
    })
  }
  const loadOptions = (text, callback) => {
    try {
      setLoader(true)
      const baseUrlV2 = `${process.env.REACT_APP_API_BASE_URL}/v2.0`
      const payload = {
        text,
        sort_by: "created_at",
        ascending: false,
        aggregationNotRequired: false,
        start: 0,
        agentId: Number(agentId),
        secure_id: secureIdForTC,
        end: 10,
        referralInfo: true,
        primary_referral_id: true,
      }
      fetch(`${baseUrlV2}/clients/search`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "internal-id": "e82f206e5238e9cfbfb613e7a298be34",
          "content-type": "application/json",
        },
      })
        .then(res => res.json())
        .then((res) => {
          const { clients } = res.response
          callback(clients)
          setLoader(false)
        })
    } catch (error) {
      setLoader(false)
    }
  }

  const handleSeachClick = (client) => {
    addClient({
      name: client.name,
      email: get(client, "emails[0].email", ""),
      phone: get(client, "phones[0].phone", ""),
      client_id: get(client, "id", ""),
      referral_id: get(client, "referral_info.id", ""),
    })
    setClientList([])
    setSearchText("")
  }
  return (
    <Wrap>
      <TextInput
        isHideLabel
        placeholder="Search clients by name or email"
        value={searchText}
        onChange={handleSearch}
      />
      {(
        isShowLoader
        || (clientsList && clientsList.length > 0)
      ) && (
        <ClientList>
          {clientsList.map(item => (
            <Client key={item.id} onClick={() => { handleSeachClick(item) }}>
              <p>{item.name}</p>
              <p>{get(item, "emails[0].email", "")} | {get(item, "phones[0].phone", "")}</p>
              <RFType>
                {get(item, "referral_info.referral_type", "Unknowm")}
              </RFType>
              <NavWrap>
                <Button onClick={() => { window.open(`${process.env.REACT_APP_BLINK_BASE_URL}/?referral_id=${get(item, "referral_info.primary_referral_id")}`) }}>
                  View Client Profile
                </Button>
              </NavWrap>
            </Client>
          ))}
          {isShowLoader && (
            <Loader
              top="0px"
              bottom="0px"
            />
          )}
        </ClientList>
      )}
    </Wrap>
  )
}

export default ClientSearch
