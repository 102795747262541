import React, { PureComponent } from "react"
import styled from "styled-components"
import colors from "../../../../lib/colors"
import TechAssetsContainer from "container/TechAssets"
import { get } from "lodash"
import root, { setTimeout } from "window-or-global"
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"
// import Loader from "@ui/Loader"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
import {
  Input, InputNumber, Select, Form,
  Divider, Button,
  GoogleAutoComplete,
  AutoComplete,
} from "dumbComponents/common/UI/antd"
import {
  PageArray,
  BuyerAgentInfoRoute,
  BuyerInfoStep,
  OfferInfoRoute,
} from "../../consts"
import {
  fetchDetails,
  getDealId,
  SubmitForm,
  setStreet,
  normalizePhone,
  getAgentId,
} from "../../commonUtils"
import {
  RemoveCoListingAgentButton,
} from "../../commonStyles"
import Sidebar from "../Sidebar"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const HeaderNav = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LabelText = styled.h2`
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`

const Required = styled.span`
  color: #E34C4C
`

const FlexBox = styled.div`
  display: flex;
  justify-content: flex-end;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const AddForm = styled.div`
  padding: 0 0 34px 0;
  text-align: right;
`

const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    margin: 0px;
  }
`

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  state = {
    showCoListingform: [1],
    cityFormValue: {},
    typeOfOwnership: {},
    bypassReInitiaize: false,
    isSourceOther: null,
  }

  componentDidMount() {
    const { getDealDetails } = this.props
    fetchDetails(getDealDetails, BuyerInfoStep)
  }

  formRef = React.createRef();

  onFinish = (fieldValues, isDraft) => {
    const {
      history,
      updateOffer,
      createOffer,
    } = this.props
    console.log("hello there", fieldValues)

    const currentStep = BuyerInfoStep
    const agentId = getAgentId() || false

    SubmitForm(
      fieldValues,
      isDraft,
      createOffer,
      updateOffer,
      history,
      OfferInfoRoute,
      currentStep,
      "clients",
      false,
      false,
      agentId,
      false,
    )
  }

  onSaveAsDraft = (values) => {
    this.onFinish(this.formRef.current.getFieldsValue(), true)
  }

  addAdditionalBuyer = () => {
    const { showCoListingform, typeOfOwnership } = this.state
    const colisting = showCoListingform
    colisting.push(showCoListingform.length + 1)
    const currBuyersLength = colisting.length - 1
    this.setState({
      showCoListingform: [...colisting],
      typeOfOwnership: {
        ...typeOfOwnership,
        [currBuyersLength]: "individual",
      },
    })
    setTimeout(() => {
      root.scrollTo(0, 3100)
    }, 300)
  }

  setCities = (location, index) => {
    const { cityFormValue } = this.state
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    this.formRef.current.setFieldsValue({
      [`city-${index}`]: `${locationDetails.city}`,
      [`state-${index}`]: `${locationDetails.state}`,
      [`country-${index}`]: `${locationDetails.country}`,
    })
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [`city-${index}`]: `${locationDetails.city}`,
      },
    })
  }

  setLocaCity = (value, cityFormName) => {
    const { cityFormValue } = this.state
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [cityFormName]: value,
      },
    })
  }

  getInitialValues = () => {
    const { getCurrentDealDetailsResponse } = this.props
    const {
      data: buyerInfo,
    } = getCurrentDealDetailsResponse || {}
    const { cityFormValue, showCoListingform, typeOfOwnership, isSourceOther } = this.state
    const localShowCoListingform = showCoListingform
    const result = {}
    if (buyerInfo && buyerInfo.buyers) {
      buyerInfo.buyers.forEach((buyer, index) => {
        Object.keys(buyer).forEach((key) => {
          //result[`ownership_name-${index}`] = ""
          const curr = `${key}-${index}`
          result[curr] = buyer[key]
          if (key === "city") {
            // need to check this logic
            cityFormValue[curr] = buyer[key]
          }

          if (key === "ownership_name") {
            console.log(result)
          }
          if (typeOfOwnership[index] && key === "type_of_ownership" && buyer[key]) {
            result[curr] = typeOfOwnership[index]
          } else if (!typeOfOwnership[index] && key === "type_of_ownership" && buyer[key]) {
            result[curr] = buyer[key].toLowerCase() || "individual"
            typeOfOwnership[index] = result[curr]
            this.setState({
              typeOfOwnership: { ...typeOfOwnership },
            })
          }
        })
        // need to check this logic
        if (index > 0 && !localShowCoListingform.includes(index + 1)) localShowCoListingform.push(index + 1)
      })
    }
    if (buyerInfo && buyerInfo.source) {
      result.source = buyerInfo.source
      if (isSourceOther === null) {
        this.setSource(buyerInfo.source)
      }
    }
    if (buyerInfo && buyerInfo.other_source) {
      result.other_source = buyerInfo.other_source
    }
    return result
  }

  getOptions = () => {
    const { agentGoogleContacts } = this.props
    return agentGoogleContacts
      && agentGoogleContacts.length
      ? agentGoogleContacts.filter(ct => ct.names)
        .map((ct, index) => ({
          label: ct.names[0].displayName,
          value: `${ct.names[0].displayName}`,
          key: index,
        })) : []
  }

  changeOtherFieldsValues = (objString, index, option) => {
    const { agentGoogleContacts } = this.props
    const name = objString
    const nameArray = name.split(" ")
    const contactIndex = option.key
    const phoneNumber = agentGoogleContacts[contactIndex]?.phoneNumbers?.[0].value
    const emailAddresses = agentGoogleContacts[contactIndex]?.emailAddresses?.[0].value
    this.formRef.current.setFieldsValue({
      [`firstname-${index}`]: nameArray[0],
      [`lastname-${index}`]: nameArray[1] || "",
      [`email-${index}`]: emailAddresses,
      [`phone-${index}`]: phoneNumber,
    })
  }

  setTypeOfOwnership = (value, index) => {
    const { typeOfOwnership } = this.state
    typeOfOwnership[index] = value
    this.setState({
      typeOfOwnership: { ...typeOfOwnership },
    }, () => {
      console.log(this.state)
    })
  }

  setSource = (value) => {
    if (value === "other") {
      this.setState({
        isSourceOther: true,
      })
    } else {
      this.setState({
        isSourceOther: false,
      })
    }
  }

  removeColistingAgent = (index) => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform.filter((sc, ind) => ind !== index)
    // colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
  }

  getFieldName = (typeOfOwnership) => {
    if (typeOfOwnership === "trust") {
      return "Full Name of Trust"
    }
    if (typeOfOwnership === "successor_trust") {
      return "Full Name of Trust"
    }
    if (typeOfOwnership === "entity") {
      return "Full Name of Entity"
    }
    if (typeOfOwnership === "probate") {
      return "Full Name of Executer"
    }
    if (typeOfOwnership === "estate") {
      return "Full Name of Estate"
    }
    if (typeOfOwnership === "power_of_attorney") {
      return "Full Name of Attorney-in-fact"
    }
    return "Full Name"
  }

  render() {
    const {
      showCoListingform,
      typeOfOwnership,
      bypassReInitiaize,
      isSourceOther,
    } = this.state
    const {
      history,
      getCurrentDealDetailsResponse,
      updateOfferActionResponse,
    } = this.props
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]

    const {
      isFetching: fetchingDeal,
    } = getCurrentDealDetailsResponse || {}

    const {
      isFetching: updatingDeal,
    } = updateOfferActionResponse || {}

    const isLoading = fetchingDeal || updatingDeal

    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Buyer(s) Information
        </HeaderNav>
        <ProgressBarComponent total={PageArray} ongoingOrCompleted={2} />
        <Container>
          <LeftSideBar>
            <Sidebar />
          </LeftSideBar>
          <RightSideBar>
            <AddForm>
              <Button
                ghost
                style={{
                  float: "right",
                  borderColor: "#3B60E4",
                  color: "#3B60E4",
                  display: "flex",
                  alignItems: "center",
                }}
                type="primary"
                shape="round"
                size="large"
                icon={<PlusCircleOutlined />}
                onClick={() => { this.addAdditionalBuyer() }}
              >
                Add additional buyer
              </Button>
            </AddForm>
            {isLoading ? (
              <p>Loading</p>
            ) : (
              <Form
                name="buyer_information"
                colon={false}
                layout="vertical"
                requiredMark={false}
                onFinish={this.onFinish}
                initialValues={this.getInitialValues()}
                ref={this.formRef}
                onFinishFailed={(e) => { console.log("ERROROOOO", e) }}
                scrollToFirstError
              >
                {showCoListingform.map((lst, index) => (
                  <>
                    {index > 0 && (<Divider />)}
                    {index > 0 && (
                      <HeaderInfo>
                        <h2>
                          Co-Buyer Information
                        </h2>
                        <RemoveCoListingAgentButton
                          onClick={() => { this.removeColistingAgent(index) }}
                        >
                          <CloseCircleOutlined style={{ fontSize: "22px" }} />
                        </RemoveCoListingAgentButton>

                      </HeaderInfo>
                    )}
                    {index === 0 && (
                      <FlexBox wd="100%">
                        <Form.Item name="source" label={<LabelComponent text="Client Source" required />} rules={commonRules}>
                          <Select onChange={value => this.setSource(value)}>
                            <Select.Option value="radius_marketplace">Radius Marketplace </Select.Option>
                            <Select.Option value="zillow_agent_personal_account">Zillow (Agent's Personal Account) </Select.Option>
                            <Select.Option value="zillow_radius_provided">Zillow (Radius Provided) </Select.Option>
                            <Select.Option value="zillow_flex">Zillow Flex </Select.Option>
                            <Select.Option value="opcity_realtor_com">OpCity/Realtor.com </Select.Option>
                            <Select.Option value="team_mentor_lead">Team/Mentor Lead </Select.Option>
                            <Select.Option value="self">Self</Select.Option>
                            <Select.Option value="sphere_of_influence">Sphere of Influence/Personal</Select.Option>
                            <Select.Option value="referrals_agent_Lender">Referrals (Agent/Lender)</Select.Option>
                            <Select.Option value="other">Other</Select.Option>
                          </Select>
                        </Form.Item>
                        {isSourceOther && (
                          <Form.Item name="other_source" label={<LabelComponent text="Other Client Source" required />} rules={commonRules}>
                            <Input />
                          </Form.Item>
                        )}
                      </FlexBox>
                    )}
                    <FlexBox wd="100%">
                      <Form.Item name={`firstname-${index}`} label={<LabelComponent text="First Name" required />} rules={commonRules}>
                        <Input />
                      </Form.Item>
                      <Form.Item name={`lastname-${index}`} label={<LabelComponent text="Last Name" required />} rules={commonRules}>
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item
                        name={`email-${index}`}
                        label={<LabelComponent text="Email" required />}
                        required
                        rules={[
                          {
                            type: "email",
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item name={`landline-${index}`} label={<LabelComponent text="Home Phone" />}>
                        <Input />
                      </Form.Item>
                      <Form.Item name={`phone-${index}`} label={<LabelComponent text="Cell Phone" />} normalize={normalizePhone}>
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item name={`address-${index}`} label={<LabelComponent text="Address" />}>
                        {/* <Input /> */}
                        <GoogleAutoComplete
                          id="cities"
                          types={["address"]}
                          name="cities"
                          // formValue={cityFormValue}
                          onChange={(e) => { }}
                          location={(loc) => { setStreet(loc, this.formRef, `address-${index}`, false, index, false) }}
                          placeholder=""
                          styles={{
                            height: "49px",
                            borderRadius: "0px",
                            background: "white",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        />
                      </Form.Item>

                      <Form.Item name={`apt-${index}`} label={<LabelComponent text="Apt/Unit/Suite No." />}>
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item name={`city-${index}`} label={<LabelComponent text="City" />} >
                        <GoogleAutoComplete
                          id="cities"
                          types={["(cities)"]}
                          name="cities"
                          // formValue={cityFormValue[`city-${index}`]}
                          onChange={(e) => { this.setLocaCity(e.target.value, `city-${index}`) }}
                          location={(loc) => { this.setCities(loc, index) }}
                          placeholder=""
                          styles={{
                            height: "49px",
                            borderRadius: "0px",
                            background: "white",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                          restrict={{
                            lat: 37.09024,
                            lng: -95.712891,
                            south_west: {
                              lat: 25.82,
                              lng: -124.39,
                            },
                            north_east: {
                              lat: 49.38,
                              lng: -66.94,
                            },
                            address: "United States",
                            country: {
                              long_name: "United States",
                              short_name: "US",
                              types: [
                                "country",
                                "political",
                              ],
                            },
                          }}
                        />
                      </Form.Item>

                      <Form.Item name={`state-${index}`} label={<LabelComponent text="State" />} >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name={`zipcode-${index}`}
                        label={<LabelComponent text="Zip Code" />}
                      >
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <Divider />
                    <Form.Item
                      name={`type_of_ownership-${index}`}
                      label={<LabelComponent text="Type Of Ownership" required />}
                      rules={commonRules}
                    >
                      <Select
                        onChange={(value) => { this.setTypeOfOwnership(value, index) }}
                      >
                        <Select.Option value="trust">Trust </Select.Option>
                        <Select.Option value="entity">Entity (LLC, Corp, Partnership,Other) </Select.Option>
                        <Select.Option value="power_of_attorney">Power of Attorney </Select.Option>
                        <Select.Option
                          value="individual"
                        >
                          Individual
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    {(typeOfOwnership[index] !== "individual") && (
                      <>
                        {["trust", "entity", "power_of_attorney"].includes(typeOfOwnership[index]) && (
                          <Form.Item name={`ownership_name-${index}`} label={<LabelComponent text={this.getFieldName(typeOfOwnership[index])} required />} rules={commonRules}>
                            <Input />
                          </Form.Item>
                        )}
                        {/* <FlexBox>
                          <Form.Item
                            name={`ownership_email-${index}`}
                            label={<LabelComponent text="Email" required />}
                            required
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message: "Required",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item name={`ownership_mobile-${index}`} label={<LabelComponent text="Cell Phone" required />} rules={commonRules} normalize={normalizePhone}>
                            <Input />
                          </Form.Item>
                        </FlexBox> */}
                      </>
                    )}
                  </>
                ))}
                <FlexBox>
                  <FlexBox>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "white",
                          float: "right",
                          borderColor: "#3B60E4",
                          color: "#3B60E4",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        // htmlType="submit"
                        onClick={() => { history.push(`${BuyerAgentInfoRoute}?dealId=${getDealId()}&agentId=${getAgentId()}`) }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "#3B60E4",
                          float: "right",
                          borderColor: "white",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        loading={isLoading}
                        htmlType="submit"
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </FlexBox>
                </FlexBox>
              </Form>
            )}
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)

