import React, { Component } from 'react';
import {addClient, updateClient} from "../API/ClientApi";
import {searchAgent} from "../API/AgentApi";
import {NotifyBugsnagError, ShowErrorToast, ShowSuccessToast} from "../../lib/ErrorUtils";
import './AddOrUpdateClient.css'
import {submitLead} from "../API/Referral/ReferralApi";
import {DefaultLoader} from "../UI/Loader/Loader";
import PanelContainer from "../../container/PanelContainer/PanelContainer";
import Form from "react-validation/build/form";
import AutoSuggest from "../UI/AutoSuggest/AutoSuggest";
import Input from "../UI/Textbox/NewTextbox";
import {emailValidation, phoneValidation, requiredValidation} from "../Validators";
import RadioButton from "../UI/RadioButton/RadioButton";
import './AddOrUpdateClient.css'
import styled from "styled-components"
import {CONTACTED, CONTACTED_DISPLAY_NAME, CONTACTING, QUALIFIED, SUBMITTED} from "../../lib/Constants";

const ClientPanelContainer = styled(PanelContainer)`
    .panel{    
        margin-bottom: 0px;
     }
`;

const ReferralStatuses = [
    {value:SUBMITTED, title: SUBMITTED},
    {value:CONTACTING, title: CONTACTING},
    {value:CONTACTED, title: CONTACTED_DISPLAY_NAME},
    {value:QUALIFIED, title: QUALIFIED},
];

const ReferralStatusOptions =  ReferralStatuses.map(
    (rs) => {
        return (<option value={rs.value}>{rs.title}</option>
        );
    }
);

class AddOrUpdateClient extends Component {

    defaultClientFormObj = {
        id: null, name: "", type:  "Buyer", phone: "", email: "", agent_id : "", is_isa: "0", referred_client_status: SUBMITTED
    };

    state = {
        loading: false,
        searchText: '',
        clientFormObj: this.defaultClientFormObj
    };

    convertClientApiObjectToFormObject = (clientResObj) => {
        let clientFormObj = this.defaultClientFormObj;
        if(!clientResObj) {
            return clientFormObj;
        }
        clientFormObj.id = clientResObj.id;
        clientFormObj.agent_id = clientResObj.agent_id;
        clientFormObj.name = clientResObj.name;
        clientFormObj.type = clientResObj.types[0];
        if (clientResObj.emails && clientResObj.emails.length > 0) {
            clientFormObj.email = clientResObj.emails[0].email;
        }
        if (clientResObj.phones && clientResObj.phones.length > 0) {
            clientFormObj.phone = clientResObj.phones[0].phone;
        }
        this.setState(clientFormObj);
        return clientFormObj;
    };

    convertClientFormObjectToReqObject = (clientFormObj) => {
        let clientResObj = {};
        if(!clientFormObj) {
            return clientResObj;
        }
        clientResObj.name = clientFormObj.name;
        clientResObj.agent_id = clientFormObj.agent_id;
        if(clientFormObj.phone) {
            clientResObj.phones = [{primary: true, phone: clientFormObj.phone, type: "Work"}];
        }
        if(clientFormObj.email) {
            clientResObj.emails = [{primary: true, email: clientFormObj.email, type: "Work"}];
        }
        clientResObj.types = [clientFormObj.type];
        return clientResObj;
    };

    handleFormChange = (key, e) => {
        if (typeof e === "boolean") {
            this.state.clientFormObj[key] = e;
        } else {
            this.state.clientFormObj[key] = e.target.value;
        }
        this.setState(this.state.clientFormObj);
    };

    validateClientBeforeCreateOrUpdate = (client) => {
        if(!client.agent_id) {
            ShowErrorToast("Please select agent");
            return false;
        }
        if(!client.name) {
            ShowErrorToast("Name is required");
            return false;
        }
        if(!client.type) {
            ShowErrorToast("Type is required");
            return false;
        }
        if(!client.email && !client.phone) {
            ShowErrorToast("Email or phone is required");
            return false;
        }
        return true;
    };

    clearForm = () => {
        this.setState({clientFormObj: this.defaultClientFormObj})
    };

    closePopup = () => {
        this.props.close();
        this.clearForm();
    };

    handleCancel = (event) => {
        event.preventDefault();
        this.closePopup();
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const self = this;
        try {
            this.startLoader();
            if(!self.validateClientBeforeCreateOrUpdate(this.state.clientFormObj)) {
                this.stopLoader();
                return;
            }
            let clientBody = await self.convertClientFormObjectToReqObject(this.state.clientFormObj);

            //create or update client api hit
            if(!this.state.clientFormObj.id) {
                await addClient(clientBody
                ).then(res => {
                    submitLead(res.response.agent_id, res.response.id, res.response.types[0], this.state.clientFormObj.is_isa, this.state.clientFormObj.referred_client_status).then(res => {
                        ShowSuccessToast("Lead added successfully");
                        this.closePopup();
                        this.stopLoader();
                    }).catch(err => {
                        ShowErrorToast(err);
                        this.stopLoader();
                    });
                }).catch(err => {
                    ShowErrorToast(err);
                    this.stopLoader();
                });
            } else {
                await updateClient(this.state.clientFormObj.id, clientBody
                ).then(res => {
                    ShowSuccessToast("Lead updated successfully");
                    this.closePopup();
                    this.stopLoader();
                }).catch(err => {
                    ShowErrorToast(err);
                    this.stopLoader();
                });
            }
        }
        catch(e){
            this.stopLoader();
            NotifyBugsnagError(e)
        }
    };

    getSuggestionValue = (suggestion) => {
        console.log("inside getSuggestionValue 2");
        this.state.clientFormObj.agent_id = suggestion.id;
        return suggestion.name ? suggestion.name : suggestion.email;
    };

    toggleLoader = () => {
        this.setState({
            loading: !!!this.state.loading
        });
    };
    startLoader = () => {
        this.setState({
            loading: true
        });
    };
    stopLoader = () => {
        this.setState({
            loading: false
        });
    };

    searchAgent = async (currentSearchText) => {
        if(!currentSearchText || currentSearchText.length < 3) {
            return [];
        }
        try {
            return await searchAgent(currentSearchText).then(res => {
                return res.response.agents.map((agent) => {
                    let cli = {};
                    cli.name = agent.firstname + ' ' + agent.lastname;
                    cli.agent_image = agent.agent_image;
                    cli.email = agent.email;
                    cli.id = agent.id;
                    return cli;
                });
            });
        }
        catch(e) {
            NotifyBugsnagError(e);
        }
    };

    componentWillUnmount() {
        console.log("unmounting...");
        //this.convertClientApiObjectToFormObject(this.props.client);
    }

    componentDidMount() {
        console.log("mounting...");
        this.convertClientApiObjectToFormObject(this.props.client);
    }

    render() {

        return (
            <div>
                {this.state.loading && <DefaultLoader/>}
                <ClientPanelContainer title={"Lead details"} className={"client-panel"}>
                    <Form>
                        <div className={this.state.loading ? 'inactive-opacity' : ''}>

                            {!this.state.clientFormObj.id && <span>

                            <div className="modal-element">
                                <AutoSuggest getSuggestions={this.searchAgent} getSuggestionValue={this.getSuggestionValue}/>
                            </div>

                            <div className="modal-element">
                                <RadioButton
                                    label={"Buyer"}
                                    value={"Buyer"}
                                    className={"radio"}
                                    onChange={this.handleFormChange.bind(this, "type")}
                                    checked={this.state.clientFormObj.type === 'Buyer'}
                                />
                                    &nbsp;&nbsp;
                                    <RadioButton
                                        label={"Seller"}
                                        value={"Seller"}
                                        className={"radio"}
                                        onChange={this.handleFormChange.bind(this, "type")}
                                        checked={this.state.clientFormObj.type === 'Seller'}
                                    />
                            </div>

                            <div className="modal-element">
                                <RadioButton
                                    label={"NON ISA"}
                                    value={"0"}
                                    className={"radio"}
                                    onChange={this.handleFormChange.bind(this, "is_isa")}
                                    checked={this.state.clientFormObj.is_isa === "0"}
                                />&nbsp;&nbsp;
                                <RadioButton
                                    label={"ISA"}
                                    value={"1"}
                                    className={"radio"}
                                    onChange={this.handleFormChange.bind(this, "is_isa")}
                                    checked={this.state.clientFormObj.is_isa === "1"}
                                />
                            </div>

                            <div className="modal-element">
                                <select
                                        onChange={this.handleFormChange.bind(this, "referred_client_status")}
                                        value={this.state.referred_client_status}>
                                    {ReferralStatusOptions}
                                </select>
                            </div></span>
                            }

                            <div className="modal-element">
                                <Input disabled={this.state.loading} className="edit-client-input" id ="name"
                                       value={this.state.clientFormObj.name}
                                       placeholder={"Name*"}
                                       validations={[requiredValidation]}
                                       onChange={this.handleFormChange.bind(this,"name")}/>
                            </div>

                            <div className="modal-element">
                                <Input disabled={this.state.loading} className="edit-client-input" id ="phone"
                                       value={this.state.clientFormObj.phone}
                                       placeholder={"Phone*"}
                                       validations={[phoneValidation]}
                                       onChange={this.handleFormChange.bind(this, "phone")}/>
                            </div>

                            <div className="modal-element">
                                <Input disabled={this.state.loading} className="edit-client-input" id ="email"
                                       value={this.state.clientFormObj.email}
                                       placeholder={"Email*"}
                                       validations={[emailValidation]}
                                       onChange={this.handleFormChange.bind(this, "email")}/>
                            </div>

                            <div className="modal-element">
                                <button className="modal-cancel-btn" onClick={this.handleCancel.bind(this)}>
                                    Cancel
                                </button>
                                <button type={"submit"} disabled={this.state.loading} className="modal-submit-btn"
                                        onClick={this.handleSubmit.bind(this)}>{this.state.clientFormObj.id ? 'Update':'Add'}
                                </button>
                            </div>

                        </div>
                    </Form>
                </ClientPanelContainer>
            </div>
        );
    }
}
export default AddOrUpdateClient;
