import React from "react"
import styled from "styled-components"
import moneyFormatter, { USCurrencyFormat } from "../../../shared/currencyUtils"

const Wrap = styled.div`
  margin-top: 30px;
`

const StatsWrap = styled.div`
  display: flex;
  gap: 16px;
`

const StatWrap = styled.div`
  border: 1px solid #999;
  border-radius: 10px;
  text-align: center;
  padding: 16px;
  min-width: 200px;
  ${props => props.isHide && `
    display: none;
  `}
  h1, h5, p {
    margin: 0px;
    padding: 0px
  }
  h5 {
    font-size: 18px;
    font-weight: 500;
    color: #777;
  }
`

const Overview = ({
  total_agent_commission,
  total_agents,
  total_deals,
  total_gross_commission,
  total_price,
  total_radius_fees,
  total_past_volume,
  total_in_contracts,
  average_annual_gross_commission,
}) => (
  <Wrap>
    <h3>Brokerage Overview</h3>
    <StatsWrap>
      <StatWrap>
        <h5>Total Agents</h5>
        <h1>{total_agents || "0"}</h1>
      </StatWrap>
      <StatWrap>
        <h5>Closed Deals</h5>
        <h1>{total_deals || "0"}</h1>
      </StatWrap>
      <StatWrap>
        <h5>In-Contract Deals</h5>
        <h1>{total_in_contracts || "0"}</h1>
      </StatWrap>
      <StatWrap>
        <h5>Total Price</h5>
        <h1>{total_price ? moneyFormatter(total_price, 2) : moneyFormatter(0, 2)}</h1>
        <p>{USCurrencyFormat(total_price)}</p>
      </StatWrap>
      <StatWrap>
        <h5>Total Radius Fees</h5>
        <h1>{total_radius_fees ? moneyFormatter(total_radius_fees, 2) : moneyFormatter(0, 2)}</h1>
        <p>{USCurrencyFormat(total_radius_fees)}</p>
      </StatWrap>
      <StatWrap>
        <h5>Total Gross Commission</h5>
        <h1>{moneyFormatter(total_gross_commission, 2)}</h1>
        <p>{USCurrencyFormat(total_gross_commission)}</p>
      </StatWrap>
      <StatWrap>
        <h5>Total Agent Commission</h5>
        <h1>{moneyFormatter(total_agent_commission, 2)}</h1>
        <p>{USCurrencyFormat(total_agent_commission)}</p>
      </StatWrap>
      <StatWrap>
        <h5>Average Annual Gross Commission</h5>
        <h1>{moneyFormatter(average_annual_gross_commission, 2)}</h1>
        <p>{USCurrencyFormat(average_annual_gross_commission)}</p>
      </StatWrap>
      <StatWrap>
        <h5>Total Past Volume (Last 12 Months)</h5>
        <h1>{total_past_volume ? moneyFormatter(total_past_volume, 2) : moneyFormatter(0, 2)}</h1>
        <p>{total_past_volume ? USCurrencyFormat(total_past_volume) : USCurrencyFormat(0)}</p>
      </StatWrap>
    </StatsWrap>
  </Wrap>
)

export default Overview