import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import styled from "styled-components";
import './AutoSuggest.css'

const StyledAutoSuggest = styled(Autosuggest)`
  input {
  outline: none;
  background-color: white !important;
  font: inherit;
  padding: 6px 10px;
  display: block;
  width: 100% !important;
  box-sizing: border-box !important;
  border: 0.5px solid #d4d4d4 !important;
  border-radius: 2px;
  height: 50px !important;
  box-shadow: none !important;
  overflow: visible;
  margin: 0 !important;
  text-indent: 0 !important;
  color: inherit;
  cursor: text;
  &:focus,
  &:active {
    box-shadow: none !important;
    border: solid 0.5px #11adf3 !important;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc !important;
    font-weight: 300 !important;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ccc !important;
    font-weight: 300 !important;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ccc !important;
    font-weight: 300 !important;
  }
  }
`;

function renderSuggestion(suggestion, { query }) {
    return (
      <span className="name">
        {
            <span>
                {suggestion.name}<br/>{suggestion.email}
            </span>
        }
      </span>
    );
}

class AutoSuggest extends Component {
    constructor() {
        super();

        this.state = {
            value: '',
            suggestions: []
        };
    }

    onChange = (event, { newValue, method }) => {
        if(this.props.onChange) {
            this.props.onChange(newValue);
        }
        this.setState({
            value: newValue
        });
    };

    getSuggestionValue = (suggestion) => {
        if(this.props.getSuggestionValue) {
            return this.props.getSuggestionValue(suggestion);
        }
        return suggestion.name ? suggestion.name : suggestion.email;
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.props.getSuggestions(value).then(options => {
            this.setState({
                suggestions: options
            });
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: this.props.placeholder ? this.props.placeholder : "Search Agent",
            value,
            onChange: this.onChange
        };

        return (
            <StyledAutoSuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps} />
        );
    }
}

export default AutoSuggest;