import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as Actions from "./actions"

function mapStateToProps(state) {
  const { agent } = state
  return {
    ...agent,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      fetchAgentSearch: Actions.fetchAgentSearchAction.request,
      fetchStripePlans: Actions.fetchStripePlansAction.request,
      fetchSubscriptions: Actions.fetchSubscriptionsAction.request,
      checkoutPlan: Actions.checkoutPlanAction.request,
      clearCheckoutPlan: Actions.checkoutPlanAction.clear,
      fetchAgentSecureId: Actions.fetchAgentSecureIdAction.request,
      fetchUserReferralSettings: Actions.fetchUserReferralSettingsAction.request,
      applyCoupon: Actions.handleCouponApplyAction.request,
      setPlanForCheckout: Actions.currentSelectedPlanAction.call,
      getInstallments: Actions.getCurrentPlanInstallmentsAction.request,
      setInstallment: Actions.setCurrentPlanInstallmentsAction.call,
      setModifiedBenefit: Actions.setModifiedPremiumBenefitsAction.call,
      customizedSubscription: Actions.setCustomizedSubscriptionSettingsAction.request,
      setLeadCap: Actions.setLeadCapAction.call,
      getAutoRefer: Actions.getCurrentAutoReferOutSettingsActions.request,
      setAutoRefer: Actions.setCurrentAutoReferOutSettingsActions.request,
      removeCouponAct: Actions.couponRemovedAction.call,
      resetInstallmentPlanSelected: Actions.resetInstallmentSelectionAction.call,
      setCurrentRTLPlan: Actions.currentRTLPlanSelectedAction.call,
      setCurrentBLUPlan: Actions.currentBLUPlanSelectedAction.call,
      setCurrentBROKERPlan: Actions.currentBROKERPlanSelectedAction.call,
      setCurrentMode: Actions.setCurrentModeAction.call,
      clearErrors: Actions.clearErrorMessageAction.call,
      setBenefitsToBeAdded: Actions.setBenefitsToBeAddedAction.call,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
