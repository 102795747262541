import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/ProductInterest"
import ImageUpload from "./Forms/ImageUpload"
import ProductDescriptionForm from "./Forms/ProductDescriptionForm"
import DescriptionRTF from "./Forms/DescriptionRTF"
import { isEmpty, toLower } from "lodash"
import { formValueSelector } from "redux-form"
import AdditionalDetails from "./Forms/AdditionalDetails"
import FeatureShown from "icons/featureShown.png"
import Parent from "icons/parent.png"


const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,8}(:[0-9]{1,5})?(\/.*)?$/

const validURL = value => !value || URL_REGEX.test(toLower(value)) ? undefined : "Please enter valid URL"

const Wrap = styled.div`
  height: 100%;
  width: 50%;
  border: solid 1px #e7e7e7;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`
const InnerWrap = styled.div`
  ${props => props.flex && `
      display: flex;
    `}

    .featureImage {
      height: 100px;
      position: absolute;
      right: 13%;
      top: 23%;
    }

  ${props => props.published && `
      padding-left: 45px;
  `}
`

const ImageWrap = styled.div`
  display: flex;
`

const EmptyState = styled.div`
  padding: 100px 50px;
  p {
    font-size: 16px;
  }
  img{
    position: relative;
  }
  .parent {
    height: 12%;
  }
  .child {
    margin-top: 51px;
    height: 9.5%;
  }
`

const CTAWrap = styled.div`
  padding-right: 18px;
  margin-top: 15px;
  button {
    width: max-content;
    height: 27px;
    border: none;
    float: right;
    padding: 6px 20px 5px 21px;
    border-radius: 4px;
    color: black;
    cursor: pointer;
  }

  .delete {
    background-color: #fec7c7;
  }

  .save {
    background-color: #c7f8fe;
    margin-left: 10px;
  }

  .child {
    background-color: #c7f8fe;
    margin-right: 10px;
  }
`


class InterestForm extends Component {
  state = {
    changed: false,
  }
  componentDidMount(){
    // apiCalls
  }

  handleUpdateChild = () => {
    const {
      globalState,
      currentEditableProduct,
      currentImageURL,
      currentIndex,
      currentProduct,
      updateFeature,
      productDescription,
    } = this.props

    let newScreenArray = currentProduct.screens

    const productDescriptionFormSelector = formValueSelector("ProductDescriptionForm")
    const additionalDetailsFormSelector = formValueSelector("AdditionalDetailsForm")

    const updatedChild = {
      "title" : productDescriptionFormSelector(globalState, "productTitle"),
      "description" : productDescription || currentEditableProduct.description || "",
      "image_url" : currentImageURL || currentEditableProduct.image_url || "",
      "ctaButtonText" : additionalDetailsFormSelector(globalState, "ctaButtonText") || "",
      "noButtonText" : additionalDetailsFormSelector(globalState, "noButtonText") || "",
      "webLink" : additionalDetailsFormSelector(globalState, "webLink") || "",
      "iOSLink" : additionalDetailsFormSelector(globalState, "iOSLink") || "",
      "androidLink" : additionalDetailsFormSelector(globalState, "androidLink") || "",
    }

    newScreenArray[currentIndex] = updatedChild

    const payload = {
      title: currentProduct.title,
      description: currentProduct.description,
      screens: [...newScreenArray],
      "isEnabled" : true,
      "isActiveWeb" : true,
      "isActiveMobile": true,
    }

    updateFeature({
      id: currentProduct._id,
      payload,
    })
  }

  handleDeleteChild = () => {
    const {
      globalState,
      currentEditableProduct,
      currentImageURL,
      currentIndex,
      currentProduct,
      updateFeature,
      productDescription,
    } = this.props

    let newScreenArray = currentProduct.screens.filter((item, index) => index !== currentIndex)

    const payload = {
      title: currentProduct.title,
      screens: newScreenArray,
      "isEnabled" : true,
      "isActiveWeb" : true,
      "isActiveMobile": true,
    }

    updateFeature({
      id: currentProduct._id,
      payload,
    })
  }

  isButtonDisabled = () => {
    const {
      globalState,
      uploadNewFeature,
      currentProduct,
      updateFeature,
      productDescription,
      currentImageURL,
    } = this.props

    const productDescriptionFormSelector = formValueSelector("ProductDescriptionForm")
    const additionalDetailsFormSelector = formValueSelector("AdditionalDetailsForm")

    const isDisabled = currentImageURL
    && currentImageURL.length > 0
    && this.validateUrl(currentImageURL)
    && productDescription
    && productDescription.length > 0
    && productDescriptionFormSelector(globalState, "productTitle")
    && productDescriptionFormSelector(globalState, "productTitle").length > 0

    return isDisabled
  }

  createChild = () => {
    const {
      globalState,
      uploadNewFeature,
      currentProduct,
      updateFeature,
      productDescription,
      currentImageURL,
    } = this.props

    const productDescriptionFormSelector = formValueSelector("ProductDescriptionForm")
    const additionalDetailsFormSelector = formValueSelector("AdditionalDetailsForm")

    const newChild = {
        "title" : productDescriptionFormSelector(globalState, "productTitle"),
        "description" : productDescription || "",
        "image_url" : currentImageURL,
        "ctaButtonText" : additionalDetailsFormSelector(globalState, "ctaButtonText") || "",
        "noButtonText" : additionalDetailsFormSelector(globalState, "noButtonText") || "",
        "webLink" : additionalDetailsFormSelector(globalState, "webLink") || "",
        "iOSLink" : additionalDetailsFormSelector(globalState, "iOSLink") || "",
        "androidLink" : additionalDetailsFormSelector(globalState, "androidLink") || "",
    }

    const payload = {
      title: currentProduct.title,
      screens: [...currentProduct.screens, newChild],
      "isEnabled" : true,
      "isActiveWeb" : true,
      "isActiveMobile": true,
    }

    updateFeature({
      id: currentProduct._id,
      payload,
    })
  }

  handleFeatureDelete = () => {
    const {
      globalState,
      uploadNewFeature,
      currentEditableProduct,
      updateFeature,
      currentProduct,
    } = this.props

    const productDescriptionFormSelector = formValueSelector("ProductDescriptionForm")

    const payload = {
    "title" : currentEditableProduct.title,
    "description": currentEditableProduct.description || "",
    "screens": currentEditableProduct.screens,
    "published": false,
    "isEnabled" : false,
    "isActiveWeb" : true,
    "isActiveMobile": true,
    "isRAR": false,
    "isAssist": false,
    }

    updateFeature({
      id: currentEditableProduct._id,
      payload,
    })
  }

 validateUrl = value => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
 }


  handleUpdateFeature = () => {
    const {
      globalState,
      uploadNewFeature,
      currentEditableProduct,
      updateFeature,
      currentProduct,
      productDescription,
      isPublished,
      isRAR,
      isAssist,
    } = this.props

    const productDescriptionFormSelector = formValueSelector("ProductDescriptionForm")

    const payload = {
    "title" : productDescriptionFormSelector(globalState, "productTitle") || currentEditableProduct.title,
    "description": productDescription || currentEditableProduct.description || "",
    "screens": currentEditableProduct.screens,
    "isEnabled" : true,
    "isActiveWeb" : true,
    "isActiveMobile": true,
    "published": isPublished,
    isRAR,
    isAssist,
    }

    updateFeature({
      id: currentEditableProduct._id,
      payload,
    })
  }

  handleSave = () => {
    const {
      globalState,
      uploadNewFeature,
      productDescription,
      isPublished,
      isRAR,
      isAssist,
    } = this.props

    const productDescriptionFormSelector = formValueSelector("ProductDescriptionForm")
    const additionalDetailsFormSelector = formValueSelector("AdditionalDetailsForm")

    const payload = {
    "title" : productDescriptionFormSelector(globalState, "productTitle"),
    "description": productDescription,
    "published": isPublished,
    "screens": [{
        "title" : "edit",
        "description" : "edit",
        "image_url" : "edit",
        "ctaButtonText" : "edit",
        "noButtonText" : "edit",
        "webLink" : "edit",
        "iOSLink" : "edit",
        "androidLink" : "edit",
    }],
    "isEnabled" : true,
    "isActiveWeb" : true,
    "isActiveMobile": true,
    isRAR,
    isAssist,
    }

    uploadNewFeature({
      payload,
    })
  }

  render(){
    const {
      currentEditableProduct,
      isChildView,
      isNewFeatureOrChild,
      isChild,
      productDescription,
      globalState,
      currentImageURL,
      isPublished,
    } = this.props

    return (
      <Wrap>
         {currentEditableProduct && Object.keys(currentEditableProduct).length > 0 && !isChildView ? (
           <React.Fragment>
             <div>
               <InnerWrap>
                <ProductDescriptionForm currentEditableProduct={currentEditableProduct} isChildView={isChildView} />
               </InnerWrap>
               <InnerWrap>
                 <DescriptionRTF rtfContent={productDescription || currentEditableProduct.description || ""}/>
               </InnerWrap>
             </div>
             <CTAWrap>
              <button onClick={this.handleUpdateFeature} className="btn save">Update Module</button>
              <button onClick={this.handleFeatureDelete} className="btn delete">Delete Module</button>
             </CTAWrap>
           </React.Fragment>
         ) : isChildView && currentEditableProduct && Object.keys(currentEditableProduct).length > 0 ? (
           <React.Fragment>
             <div>
                <InnerWrap flex>
                  <ImageUpload />
                  {this.validateUrl(currentEditableProduct.image_url) && (
                    <img className="featureImage" src={currentEditableProduct.image_url}  alt="image.png"/>
                  )}
                </InnerWrap>
               <InnerWrap>
                <ProductDescriptionForm currentEditableProduct={currentEditableProduct} />
               </InnerWrap>
               <InnerWrap flex>
                 <DescriptionRTF child="true" rtfContent={productDescription || currentEditableProduct.description}/>
                  <AdditionalDetails currentEditableProduct={currentEditableProduct} />
               </InnerWrap>
               {!currentImageURL && !this.validateUrl(currentImageURL) && !this.validateUrl(currentEditableProduct.image_url) && (
                 <InnerWrap style={{ padding: "52px", fontSize: "14px", color: "red"}}>
                   <p>Feature must always have an image, please upload an image before updating.</p>
                 </InnerWrap>
               )}
             </div>
             <CTAWrap>
              <button disabled={!currentImageURL && !this.validateUrl(currentImageURL) && !this.validateUrl(currentEditableProduct.image_url)} onClick={this.handleUpdateChild} className="btn save">Update Feature</button>
              <button onClick={this.handleDeleteChild} className="btn delete">Delete Feature</button>
             </CTAWrap>
           </React.Fragment>
         ) : isNewFeatureOrChild && isChild ? (
           <React.Fragment>
             <div>
               <ImageUpload />
               <InnerWrap>
                <ProductDescriptionForm isChild={isChild} />
               </InnerWrap>
               <InnerWrap flex>
                 <DescriptionRTF child="true" rtfContent={""}/>
                 <AdditionalDetails />
               </InnerWrap>
               {!currentImageURL && !this.validateUrl(currentImageURL) && (
                 <InnerWrap style={{ padding: "52px", fontSize: "14px", color: "red"}}>
                   <p>Feature must always have an image, please upload an image before updating.</p>
                 </InnerWrap>
               )}
             </div>
             <CTAWrap>
              <button disabled={!currentImageURL && !this.validateUrl(currentImageURL)} onClick={this.createChild} className="btn save">Save Feature</button>
             </CTAWrap>
           </React.Fragment>
         ) : isNewFeatureOrChild && !isChild ? (
           <React.Fragment>
             <div>
                <InnerWrap>
                <ProductDescriptionForm isChild={isChild} />
               </InnerWrap>
               <InnerWrap>
                 <DescriptionRTF rtfContent={""}/>
               </InnerWrap>
             </div>
             <CTAWrap>
              <button onClick={this.handleSave} className="btn save">Save</button>
             </CTAWrap>
           </React.Fragment>
         ) : (
          <EmptyState>
             <p>Select a Module to edit or view</p>
             <p>Only the recent most module would be shown on product side.</p>
             <img className="parent" src={Parent} alt="feature.png"/>
             <img className="child" src={FeatureShown} alt="feature.png"/>
          </EmptyState>
        )}
      </Wrap>
    )
  }
}


export default Container(InterestForm)
