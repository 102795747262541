import React from "react"
import styled from "styled-components"
import moment from "moment"
import { format } from "react-phone-input-auto-format"
import { Text } from "./index"

const Wrap = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(224, 224, 224, 0.5);
  margin-top: 10px;
`

const CheckBoxWrap = styled.div`
  padding: 10px;
  border: 1px;
`
const ReferralInfo = styled.div`
  flex: 1;
`
const ContactInfo = styled.div`
  text-align: right;
`

const ReferralItem = ({
  handleSelect,
  isSelected,
  data,
  data: {
    client_name,
    client_email,
    client_phone,
    created_at,
  },
}) => (
  <Wrap>
    <CheckBoxWrap>
      <input
        type="checkbox"
        onClick={() => { handleSelect(data) }}
        isSelected={isSelected}
      />
    </CheckBoxWrap>
    <ReferralInfo>
      <Text size={16}>
        {client_name || "N/A"}
      </Text>
      <Text light size={12}>
        {`Added ${moment(created_at).fromNow()} | ${moment(created_at).format("MMM DD, YYYY kk:mm")}`}
      </Text>
    </ReferralInfo>
    <ContactInfo>
      <Text light size={12}>
        {client_email}
      </Text>
      <Text light size={12}>
        {format(client_phone || "")}
      </Text>
    </ContactInfo>
  </Wrap>
)

export default ReferralItem
