import styled from "styled-components"

export const Container = styled.div`
  margin: 16px;
  padding: ${props => props.padding || "16px"};
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #F3F3F3;
`

export const PlanWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

export const PlanClickBar = styled.div`
  padding: 24px;
  border-radius: 8px;
  background: #FAF9FF;
  width: ${props => `${props.width}px` || "auto"};
  display: flex;
  flex-direction: column;
  gap: 30px;
  cursor: pointer;
  align-items: center;

  ${props => props.isSelected && `
    border: 2px solid var(--stroke, #100063);
  `}
`

export const PlanTitle = styled.p`
  color: var(--gray-900, #18181B);
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  height: ${props => props.height || "auto"};
`
export const PlanPriceWrap = styled.div`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  border: 1px solid #A1A1AA;
  background: #FFF;

  ${props => props.isSelected && `
    background: var(--fill, #100063);
  `}
`

export const PlanPrice = styled.p`
  color: #000;
  text-align: right;
  font-family: 'Roboto';
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin: 0;

  ${props => props.isSelected && `
    color: #FFF;
  `}
`

export const TitleWrap = styled.div``

export const Title = styled.h1`
  color: #000;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 2px;
  margin: 0;
`

export const ShimmerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`
