import styled from "styled-components"

export const FormWrap = styled.div`
  .ant-input {
    height: 38px !important;
  }
`

export const FlexRow = styled.div`
  display: flex;

  ${props => props.gap && `
    gap: ${props.gap};
  `}

  .ant-form-item-label {
    padding: 0 !important;
  }


  .ant-col-offset-8 {
    margin-left: 0 !important;
  }

  .ant-btn, .ant-btn-primary, .ant-btn-lg {
    display: flex;
    padding: 16px 33px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 33.5px !important;
    background: var(--color-primary, #5A5FF2);

    color: #FFF;
    font-family: ATC;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`

export const SubmitButton = styled.button`
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    height: 44px;
    width: 133px;
    cursor: pointer;

    border-radius: 33.5px;
    background: var(--color-primary, #5A5FF2);

    img {
      height: 12px;
      width: 12px;
    }

    &:hover {
      background: #3f43c5;
    }

    transition: background 0.5s ease-out;
`

export const SubmitButtonText = styled.p`
    color: #FFF;
    font-family: ATC;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
`

export const CancelButton = styled.button`
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    height: 44px;
    width: 133px;
    cursor: pointer;

    border-radius: 33.5px;
    background: red;

    img {
      height: 12px;
      width: 12px;
    }

    transition: background 0.5s ease-out;
`

export const CancelButtonText = styled.p`
    color: #FFF;
    font-family: ATC;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
`