import React, {Component} from 'react';
import _ from 'lodash';
import bugsnag from 'bugsnag-js';
import moment from 'moment';
import {format} from "react-phone-input-auto-format";
import Popup from "reactjs-popup";
import axios from "axios"
import LetterAvatar from "../../UI/LetterAvatar/LetterAvatar";
import {notify} from "react-notify-toast";
import {ShowErrorToast, ShowSuccessToast} from "../../../lib/ErrorUtils";
import {
    getReferralSettings,
    isValidForAutoCall,
    updateReferral,
    updateReferralSettings
} from "../../API/Referral/ReferralApi";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import {DefaultLoader} from "../../UI/Loader/Loader";
import Button from "dumbComponents/common/UI/Button";
import container from "container/Client/clientInfoBanner"
import ZillowMergeModal from "dumbComponents/ZillowMergeModal"
import { unixToDate } from "services/Utils"
import Flatpickr from "react-flatpickr";
import { fetch } from 'window-or-global';

const blinkBaseUrl = process.env.REACT_APP_BLINK_BASE_URL;
let recommendationsUrl = blinkBaseUrl + "/toggle-recommendations/";
const baseUrl =process.env.REACT_APP_API_BASE_URL;
const adminBaseUrl = process.env.REACT_APP_ADMIN_URL;
const token = process.env.REACT_APP_SECRET_ID;
const bugsnagBlinkKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(bugsnagBlinkKey);
const referralStatusOptions = [
    {title: "Submitted", value: "Submitted", color: "#24BDBB"},
    {title: "Contacting", value: "Contacting", color: "#FAB615"},
    {title: "Follow up Scheduled", value: "Contacted", color: "#FAB615"},
    {title: "Qualified", value: "Qualified", color: "#13A1E1"},
    {title: "Search Paused", value: "Search Paused", color: "#781D28"},
    {title: "Comparing Agents", value: "Comparing Agents", color: "#CA6121"},
    {title: "Disqualified", value: "Disqualified", color: "#E53C50"}
];

const salesStageOptions = [
    {title: "Accepted", value: "Leads", color: "#24BDBB"},
    {title: "Active", value: "Active", color: "#B628D3"},
    {title: "In Contract", value: "In Contract", color: "#12725C"},
    {title: "Closed", value: "Closed", color: "#2857D3"},
    {title: "Archived", value: "Archived", color: "#400A0A"},
    {title: "On Hold", value: "On Hold", color: "#E53C09"},
];

const EditLi = styled.li`
  font-size: 1em;
  margin-left: 20px;
  color: black;
  position: inherit;
`

const OuterWrap = styled.div`
  margin: 0 0px 0px 5px;
  height: max-content;
  width: 99%;
  display: flex;
  margin-top: 10px;
  font-family: "SF Pro Display", sans-serif;
  border: 1px solid #ebebeb;
  border-radius: 16px;
  p {
   margin: 0 !important;
  }
`


const DetailsWrap = styled.div`
 flex-grow: 1;
 display: flex;
 padding: 0px 15px 0px 17px;
 flex-direction: column;
 max-width: 50%;
`

const CTAWrap = styled.div`
  flex-grow: 5;
  border-left: 1px solid #ebebeb;
  padding: 10px;
`

const SubMenu = styled.div`
  height: max-content;
  display: flex;
  ${props => props.pre && `
     flex-direction: row;
   `}
  ${props => props.showMenuLeft && `
    justify-content: flex-end;
   `}
  a {
    color: #0882e4;
    font-size: 14px;
    padding: 5px 5px 5px 10px;
    cursor: pointer;
    text-decoration: none;
  }
`

const PreAssignedTitle = styled.h2`
  padding: 2px 3px 0px 3px;
  font-size: 14px !important;
  border-radius: 5px;
  background-color: #095e4b;
  color: white !important;
  pointer-events: none !important;
  text-decoration: none !important;
`

const Locations = styled.div`
   /* padding: 15px 15px 10px 15px; */
   height: max-content;
   overflow: scroll;
   .locationWrap {
    border-radius: 12px;
    border: solid 1px #ebebeb;
   }
   .locationList {
    overflow: scroll;
   }
   .citiesServed {
    padding: 0px 0px 10px 0px;
   }
   .locationHeader {
    position: sticky;
    top: -1px;
    background: white;
    height: 50px;
    padding: 15px 15px 0px 15px;
    margin-bottom: -10px;
   }
   h2 {
    margin: 0;
    font-size: 10px;
    padding: 6px;
   }
   ul {
    padding: 0px 15px 0px 15px;
    list-style: none;
    color: #8b8c8b;
    font-size: 15px;
    max-height: 200px;
   }
`

const DropdownWrap = styled.div`
  height: max-content;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  div {
   display: flex;
   flex-direction: column;
   padding: 10px;

   p {
    font-size: 12px;
    color: #a8a8a8;
    margin: 0;
    padding-bottom: 10px
   }

   button {
    margin-top: 11px;
    cursor: pointer;
   }

   input {
    padding: 0px 0px 0px 6px;
    height: 28px;
   }

   select {
    width: 135px;
    height: 28px;
    background: transparent;
   }
  }
`

const NameWrap = styled.div`
  display: flex;
  padding: 12px;
  flex-wrap: wrap;
`

const ImageWrap = styled.div`
 padding-top: 13px;
`

const ContactWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 15px;
  p {
   text-align: end;
   margin: 0px 0px 8px 0px;
   height: 15px;
   font-size: 13px;
   color: #8c8c8c;
  }
  p:nth-child(1) {
   margin-bottom: 3px !important;
  }

  a {
    font-size: 12px;
    color: #0091ff;
    z-index: 100;
    text-align: right;
  }
`

const NoName = styled.p`
  font-style: italic;
  color: #8c8c8c;
`

const Name = styled.div`
  padding: 0px 15px 0px 5px;
  p {
   font-size: 24px;
   max-width: 325px;
   word-break: break-word;
  }
`

const Type = styled.div`
  padding-top: 4px;
  display: flex;
  p{
   height: fit-content;
   padding: 0px 6px 0px 6px;
   border-radius: 5px;
   border: solid 1px #d6d6d6;
  }
`
const Paragraph = styled.p`
   height: fit-content;
   padding: 0px 6px 0px 6px;
   border-radius: 5px;
   border: solid 1px #d6d6d6;
   color: white;
   background: green;
   ${props => props.isMortgage && `
   background : red;
   `}
   ${props => props.isSeller && `
   background : blue;
   `}
`

const CitiesServed = styled.p`
  font-family: "SF Pro Display", sans-serif;
  font-size: 12px;
  color: #a8a8a8;
  margin: 0;
  padding-top: 10px;
`
const PreAssignedAgent = styled.a`
  padding: 0 !important;
  display: flex;
  color: black !important;
  cursor: pointer;
  text-decoration: underline !important;
  margin-left: 2px;
  margin-top: 2px;
`

const InnerWrap = styled.div`
 display: flex;

 h2 {
  font-size: 12px;
  color: #a8a8a8;
  margin: 0;
 }

 ${props => props.headerDetails && `
   max-height: max-content;
   margin-bottom: -13px;
  `}

 ${props => props.responseDetails && `
    padding-left: 56px;
    height: 30px;
  `}
 ${props => props.otherDetails && `
    padding: ${props.firstRow ? "10px 0px 0px 0px" : "0px"};
    flex-wrap: wrap;
    a {
     color: #000000;
     cursor: pointer;
    }
    div:first-child {
     padding: 5px 0px;
     p {
      font-size: 14px;
      color: #8c8c8c;
      padding-top: 3px;
     }
    }
    div {
     padding: 5px 25px 0px 25px;
     min-width: 145px;
     p {
       margin: 0;
       font-size: 16px;
     }
    }
  `}

 ${props => props.addDet && `
   flex-direction: column;
   max-height: 131px;
   padding: 5px 0px 0px 0px;
   .edit-additional-details {
     float: right;
     cursor: pointer;
     color: #0091ff;
     padding-bottom: 10px;
   }
   .preview {
    border-radius: 8px;
    border: solid 1px #ededed;
    background-color: #fef9eb;
    padding: 5px 5px 5px 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    word-break: break-all;
   }
 `}
`

const ResponseToTextWrap = styled.div`
  font-size: 16px;
  color: #8c8c8c;
  display: flex;
  margin-left: 5px;
  padding-top: 3px;
  p{
   height: fit-content;
  }
  ${props => props.caller && `
   margin-left: 19px;
   padding-top: 1px;
    p {
     padding-top: 1px;
    }
   `}
`

const ResponseStatus = styled.div`
  width: fit-content;
  height: fit-content;
  border-radius: 2px;
  background-color: #f89c9c;
  padding: 0px 2px 0px 2px;
  margin-left: 10px;
  font-size: 16px;
  color: white;
  ${props => props.isYes && `
   background-color: mediumseagreen;
   `}
  ${props => props.caller && `
    background: transparent;
    color: #000000;
    font-size: 19px;
    font-weight: 500;
    margin-left: 7px;
   `}
`

class ClientInfo extends Component {
  state = {
    name: '',
    phone: '',
    email: '',
    referral: null,
    referralId: null,
    referred_client_status: 'Contacted',
    thirtyPercentUpfrontStatus: null,
    disqualificationReasons: [],
    date: new Date(),
    referralSettings: {
        referral_priority: "1",
        followup_frequency : 0,
        auto_call: 1,
        auto_sms: 1,
        allow_missed_call_notifications: 1,
        next_follow_up_date: null,
        prev_follo_up_date: null,
    },
      agentAdditionalDetails : '',
      tempAgentAdditionalDetails : '',
      loading : false,
      type: '',
  };

    handleChange = e => {
        this.setState({ tempAgentAdditionalDetails: e.target.value });
    };

    updateAgentAdditionalDetails = async (cb) => {
        this.startLoader();
        const agentAdditionalDetails = this.state.tempAgentAdditionalDetails;
        await this.props.updateAgentAdditionalDetails({agentAdditionalDetails});
        this.stopLoader();
        cb();
    };

    startLoader = () => {
        this.setState({loading: true})
    };

    stopLoader = () => {
        this.setState({loading: false})
    };

    toggleAdditionalDetails = () => {
        this.setState({tempAgentAdditionalDetails : this.state.agentAdditionalDetails,})
    };

  handleFormChange = (e) => {
    this.setState({[e.target.id]: e.target.value });
  };

  handleTypeChange = (e) => {
      this.setState({type: e.target.value});
  };

  updateClient = () => {
    const {name, email, phone, city, state, type} = this.state;
    this.props.updateClientDetails({
      name, email, phone, city, state, type
    });

    this.updateReferralSettings(this.state.referralId, this.state.referralSettings, false);
  };

  handleMergeCallClick = (event) => {
    event.preventDefault()
    const { toggleZillowMergeModal } = this.props
    toggleZillowMergeModal(true)
  }

  isValidForAutoCall = (referralId, twilioClientName) => {
      isValidForAutoCall(referralId, twilioClientName).then(res => {
          ShowSuccessToast(res.response[referralId]);
      }).catch(err => {
          ShowErrorToast(err);
      });
  };

  handleAutoCalls = (e) => {
      const {referralSettings} = this.state;
      if (referralSettings.auto_call === 0) {
          referralSettings.auto_call = 1;
      } else {
          referralSettings.auto_call = 0;
      }
      this.setState({referralSettings: referralSettings});
  };

  handleAutoSms = (e) => {
      const {referralSettings} = this.state;
      if (referralSettings.auto_sms === 0) {
          referralSettings.auto_sms = 1;
      } else {
          referralSettings.auto_sms = 0;
      }
      this.setState({referralSettings: referralSettings});
  };

  handleMissedCallNotifications = (e) => {
    const {referralSettings} = this.state;
    if (referralSettings.allow_missed_call_notifications === 0) {
        referralSettings.allow_missed_call_notifications = 1;
    } else {
        referralSettings.allow_missed_call_notifications = 0;
    }
    this.setState({referralSettings: referralSettings});
  };

  getReferralSettings = async (referralId) => {
      const self = this;
      let res = await getReferralSettings(referralId).then(res => {
          let referralSettings = {};
          referralSettings.is_post_claim_follow_up = res.response.is_post_claim_follow_up
          referralSettings.next_followup_at = res.response.next_followup_at
          referralSettings.post_claim_follow_up_call_count = res.response.post_claim_follow_up_call_count
          referralSettings.referral_priority = res.response.referral_priority.toString();
          referralSettings.followup_frequency =  res.response.followup_frequency;
          referralSettings.auto_sms = this.props.tempClient && this.props.tempClient.hasOwnProperty("auto_sms") ? this.props.tempClient.auto_sms : res.response.auto_sms;
          referralSettings.auto_call = this.props.tempClient && this.props.tempClient.hasOwnProperty("auto_call") ? this.props.tempClient.auto_call : res.response.auto_call;
          referralSettings.next_follow_up_date = res.response.next_follow_up_date;
          referralSettings.prev_follow_up_date = res.response.prev_follow_up_date;
          referralSettings.allow_missed_call_notifications = this.props.tempClient && this.props.tempClient.hasOwnProperty("allow_missed_call_notifications") ? this.props.tempClient.allow_missed_call_notifications : res.response.allow_missed_call_notifications;
          self.setState({referralSettings: referralSettings});
      }).catch(err => {
          console.log(err);
          ShowErrorToast(err);
      });
  };

    updateReferralSettings = (referralId, referralSettings, notify = true) => {
        updateReferralSettings(referralId, referralSettings).then(res => {
            this.setState({referralSettings: referralSettings});
            if (notify) {
                ShowSuccessToast("Successfully updated!")
            }
        }).catch(err => {
            ShowErrorToast(err);
        });
    };

  updateReferralPriority = (e) => {
      //console.log(e.target.value);
      let referralSettings = this.state.referralSettings;
      let referralId = this.state.referralId;
      referralSettings.referral_priority = (e.target.value);
      this.updateReferralSettings(referralId, referralSettings);
  };

  updateFollowupFrequency = (e) => {
      //console.log(e.target.value);
      let referralSettings = this.state.referralSettings;
      let referralId = this.state.referralId;
      referralSettings.followup_frequency = (e.target.value);
      this.updateReferralSettings(referralId, referralSettings);
  };

  updateStatus = async (e) => {
    try {
      if (this.props && this.props.referral) {
        let status = e.target.value;
        this.setState({referred_client_status: status});
        let url = baseUrl + '/v1.0/consumers/referral/' + this.props.referral.id;
        let body = {
          referred_client_status: status,
          consultant_agent_id: this.props.user.agent_id,
        };

          let sol = await fetch(url, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'secure-id': token
            },
            body: JSON.stringify(body)
          });
          let respCode = sol.status;
          sol = await sol.json();
          if(respCode !== 200) {
            ShowErrorToast(sol);
          } else {
            ShowSuccessToast();
          }
      }
    }
    catch(e){
      ShowErrorToast(e);
      console.log(e);
    }
  };

  getThirtyPercentUpfrontStatus = async () => {
    try{
      if (this.props.referral && this.props.referral.is_isa){
        return;
      }

      let url = baseUrl + '/v2.0/consumers/referrals/upfrontStatus/' + this.props.referral.id;

      let sol = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'internal-id': token
        },
      });
      sol = await sol.json();
      let thirtyPercentUpfrontStatus = sol.response.status;
      this.setState({thirtyPercentUpfrontStatus})
    }
    catch(e){
      console.log(e);
    }
  };

  componentDidMount () {
    try{
      //this.loadDisqualificationReasons();
      let client = this.props.client;
      let tempClient = this.props.tempClient;
      let referral = this.props.referral;
      let referralId = referral.id;
      let referred_client_status = referral.referred_client_status;
      let name = tempClient && tempClient.name ? tempClient.name : client.name;
      let phone = tempClient && tempClient.phone ? tempClient.phone : client.phones && client.phones.length > 0 ? (client.phones[0] ? client.phones[0].phone : null) : null;
      let email = tempClient && tempClient.email ? tempClient.email : client.emails && client.emails.length > 0 ? (client.emails[0] ? client.emails[0].email : null) : null;
      let agentAdditionalDetails =  referral.admin_note;
      let type = tempClient && tempClient.type ? tempClient.type : this.props.type;
      let tempAgentAdditionalDetails = tempClient && tempClient.admin_note ? tempClient.admin_note : agentAdditionalDetails;
      this.setState({name, phone, email, referred_client_status, referralId, agentAdditionalDetails, tempAgentAdditionalDetails, type});
      if (client && referral){
        //this.getThirtyPercentUpfrontStatus();
      }
      this.getReferralSettings(referral.id)
    }
    catch(e){
      console.log(e);
    }
  }

  componentWillReceiveProps(props){
    try{
        let client = props.client;
        let referral = props.referral;
        let tempClient = this.props.tempClient;
        if (this.state.referred_client_status !== referral.referred_client_status) {
            this.setState({referred_client_status: referral.referred_client_status});
        }

        if (tempClient && tempClient.name) {
            this.setState({name: tempClient.name})
        } else if (this.state.name === client.name) {
            this.setState({name: client.name});
        }
        let phone = client.phones && client.phones.length > 0 ? (client.phones[0] ? client.phones[0].phone : null) : null;
        if (tempClient && tempClient.phone) {
            this.setState({phone: tempClient.phone})
        } else if (this.state.phone === phone) {
            this.setState({phone});
        }
        let email = client.emails && client.emails.length > 0 ? (client.emails[0] ? client.emails[0].email : null) : null;
        if (tempClient && tempClient.email) {
            this.setState({email: tempClient.email})
        } else if (this.state.email === email) {
            this.setState({email});
        }
        if (tempClient && tempClient.type) {
            this.setState({type: tempClient.type})
        } else if (this.state.type === this.props.type) {
            this.setState({type: this.props.type});
        }

        if (tempClient && tempClient.admin_note) {
            this.setState({tempAgentAdditionalDetails: tempClient.admin_note})
        } else if (this.state.agentAdditionalDetails !== referral.admin_note) {
            this.setState({agentAdditionalDetails: referral.admin_note, tempAgentAdditionalDetails: referral.admin_note});
        }
        //this.getThirtyPercentUpfrontStatus();
        if (client && referral) {
            //this.getThirtyPercentUpfrontStatus();
        }
        if (props.requestUnsavedClientInfo) {
            this.props.saveUnsavedClientInfoLocally({
                name: this.state.name,
                phone: this.state.phone,
                email: this.state.email,
                type: this.state.type,
                agentAdditionalDetails: this.state.tempAgentAdditionalDetails,
                auto_call: this.state.referralSettings.auto_call,
                auto_sms: this.state.referralSettings.auto_sms,
                allow_missed_call_notifications: this.state.referralSettings.allow_missed_call_notifications,
            })
        }
    }
    catch(e){
      console.log(e);
    }
  }

  toggleUpfrontStatus = async () => {
    if (this.state.thirtyPercentUpfrontStatus === 2){
      notify.show("The client has already said they are not interested in claiming this fee!", "error", 5000);

    }
    else if (this.state.thirtyPercentUpfrontStatus === 1){
      notify.show("The client has already said they want to claim this fee, can't revert the status now!", "error", 5000);

    }
    else if (this.state.thirtyPercentUpfrontStatus === 0) {
      notify.show("Notification has already been sent to the client! Can't revert now!", "error", 5000);

    }
    else {
      try{
        let body = {
          "referral_id" : this.props.referral.id,
          "prospect_status" : 0,
          "type" : "upfront"
        };
        let url =  baseUrl + '/v2.0/prospects/' + this.props.referral.sender_id;

        let sol = await fetch(url, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'internal-id': token
          },
          body: JSON.stringify(body)
        });
          await this.getThirtyPercentUpfrontStatus();
        notify.show('Updated upfront payment status successfully', 'success', 3000);
      }
      catch(e){
        notify.show('Could not update upfront payment status', 'error', 3000);
      }
    }
  };

  handleMergeCallClick = (event) => {
    event.preventDefault();
    const { toggleZillowMergeModal } = this.props
    toggleZillowMergeModal(true)
  }

  handleFollowUpDate = async (selectedDates, dateStr) => {
   try{
     let body = {
       referred_client_status: 'Contacted',
       next_follow_up_date: dateStr,
       consultant_agent_id: this.props.user.agent_id
     };
     updateReferral(this.props.referral.id, body).then(res => {
       let referralSettings = this.state.referralSettings;
       referralSettings.next_follow_up_date = dateStr;
       this.setState({referralSettings: referralSettings, referred_client_status: "Contacted"});
       ShowSuccessToast(`This client to be contacted on selected date : ${dateStr}`)
     }).catch(err => {
       ShowErrorToast(err);
     });
   }
   catch(e){
     notify.show('An error occured while setting date');
   }
  }

  handlePostClaimFollowUp = async (date) => {
    const timestamp = Math.floor(new Date(date).getTime() / 1000)
    this.setState({
      postClaimScheduleTimestamp: timestamp,
    })
  }



  savePostClaim = async () => {
    const payload = {
      referral_id: this.props.referral.id,
      consultant_agent_id: this.props.user.id,
      next_followup_at: this.state.postClaimScheduleTimestamp,
    }
    try {
      const res = await fetch(`${baseUrl}/v1.0/post-claim-csc-followup/schedule`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'secure-id': token
        },
        body: JSON.stringify(payload)
      })
      notify.show('Successfully updated.', 'success', 3000);
    } catch (error) {
      console.log(error);
      notify.show('Failed updated.', 'error', 3000);
    }
  }

  handleRefreshPostClaimQueue = async () => {
    try {
      const res = await axios.get(`${baseUrl}/v1.0/gabby/followup-call/load-post-acceptance-items`)
      notify.show('Refreshed the queue', 'success', 3000);
    } catch (error) {
      notify.show('Failed Refreshed the queue', 'error', 3000);
    }
  }


  render() {
    let props = this.props;
    const { referralSettings } = this.state;
    let referral = props.referral;
    const { is_mortgage,buyer_agent }= referral || {}
    const { isOpenZillowMergeModal } = this.props
    let next_follow_up_date = referralSettings.next_follow_up_date ?
        referralSettings.next_follow_up_date : referralSettings.prev_follow_up_date;
    let messageUrl = adminBaseUrl + "/#/submitted-referrals-logs?client_id=";
    /* a little hacky fix to handle the case with no referrals */
    if (!referral){
      referral = {type: 'Seller'}
    }
    if (!referral.type){
      referral.type = 'Buyer';
    }
    let messages = [];
    const popupstyle =  { overflow: 'auto', width: '80%'};
    const senderProfile = process.env.REACT_APP_PROFILE_BASE_URL + '/profile/agent?id=' + (props.referral && props.referral.sender_id? props.referral.sender_id : '');
    const preAssignedAgentProfile = process.env.REACT_APP_PROFILE_BASE_URL + '/profile/agent?id=' + (props.referral && props.referral.pre_assigned_agent_id ? props.referral.pre_assigned_agent_id : '');
    let status_dropdown_options = referralStatusOptions.concat(salesStageOptions);

    if (props.referral.referral_source === "trustle") {
      props.referral.referral_source = "Radius Homes";
    }

    if (props.referral.receiver_client_id) {
          messageUrl += props.referral.receiver_client_id;
    }

    try {
      messages = _.reject(props.messages, (txt) => txt.type === 'call');
      messages = (messages || []).map(message => <div className='message-row'>
        {message.message}
        <br/>
            Added by {message.senderKey === 'agent_484725' ? ' Admin ' : ' Client '} on  {'' + moment(message.sentAt).format("MMMM Do")}
        <hr/>
        </div>

      );
    } catch(e){
      console.log(e);
    }


    let locations = [];
    if (props.referral && props.referral.sender_agent_locations) {
        for (let i in props.referral.sender_agent_locations) {
            let location = props.referral.sender_agent_locations[i];
            if (location) {
                let stateAbbr = "";
                if (location.state_code) {
                    stateAbbr = ` (${location.state_code})`
                }
                // ", " + location.state + ", " + location.country
                locations.push(<li style={{marginTop : '5px'}}>{`${location.city}${stateAbbr}`}</li>);
            }
        }
    }

    const EditLi = styled.li`
      font-size: 1em;
      margin-left: 20px;
      color: black;
      position: inherit;
    `;
  const isFollowUpHidden =
  this.state.referred_client_status === "Qualified"
  || this.state.referred_client_status === "Accepted"
  || this.state.referred_client_status === "Active"
  || this.state.referred_client_status === "On Hold"
  || this.state.referred_client_status === "Archived"
  || this.state.referred_client_status === "Closed"

  const isFollowUpVisible =
  this.state.referred_client_status === "Submitted"
  || this.state.referred_client_status === "Contacting"
  || this.state.referred_client_status === "Contacted"
  || this.state.referred_client_status === "Search Paused"

  let isZillowTestAccount  = false
  const senderClientEmail = _.get(props, "referral.sender_client_email") || ""
  if (senderClientEmail && senderClientEmail.indexOf("zillow_client") !== -1) {
    isZillowTestAccount = true
  }
  const referralSource = (_.get(props, "referral.referral_source", "") || "").toLowerCase()
  return (
     <OuterWrap>
       {/*Details div*/}
       {isOpenZillowMergeModal && (
         <ZillowMergeModal
          client={props.client}
          referral={props.referral}
          twilioClientName={props.user.twilio_client_name}
         />
       )}
       <DetailsWrap>
        <InnerWrap headerDetails>
         <ImageWrap>
           <LetterAvatar name={props.client.name} />
         </ImageWrap>
         <NameWrap>
           <Name
            isClientNameNotExist={props.client.name && props.client.name.length <= 0}
           >
             {props.client.name ? (
              <p>
               {props.client.name}
              </p>
             ) : (
              <NoName>
                Name not available
              </NoName>
             )}

           </Name>
           <Type>
            <Paragraph isMortgage={Boolean(is_mortgage)} isSeller={referral.type === "Seller"}>
              {is_mortgage ? "Mortgage" : referral.type ? referral.type : 'UNKNOWN TYPE'}
            </Paragraph>
            {referralSettings && referralSettings.is_post_claim_follow_up && (
              <Paragraph>
                Post Claim
              </Paragraph>
            )}
           </Type>
         </NameWrap>
         <ContactWrap>
           <p>{props.client.emails && props.client.emails[0].email ? props.client.emails[0].email : "Email ID not available"}</p>
           <p>{props.client.phones && props.client.phones[0].phone ? format(props.client.phones[0].phone || "") : "Phone Number not available"}</p>
           {(referralSource === "zillow" || referralSource === "homelight") && (
             <a onClick={this.handleMergeCallClick} href="javascript:void(0);">
              Merge Lead
             </a>
           )}
         </ContactWrap>
        </InnerWrap>
        <InnerWrap responseDetails>
         <ResponseToTextWrap>
           <p>
            Response to text
           </p>
           <ResponseStatus
             isYes={props.referral.received_sms_count && props.referral.received_sms_count > 0}
           >
            {props.referral.received_sms_count && props.referral.received_sms_count > 0 ? <a style={{marginLeft : '0px'}} href={messageUrl} target="_blank">YES</a> : 'NO'}
           </ResponseStatus>
         </ResponseToTextWrap>
        <ResponseToTextWrap caller>
          <p>
           call attempts
          </p>
          <ResponseStatus caller>
          {props.referral.no_of_call_attempts ? props.referral.no_of_call_attempts : "0"}
          </ResponseStatus>
        </ResponseToTextWrap>
        </InnerWrap>
        <InnerWrap>
          {referralSettings && !!referralSettings.post_claim_follow_up_call_count && (
            <p>
              Post claim follow up call count {referralSettings.post_claim_follow_up_call_count}
            </p>
          )}
        </InnerWrap>
        <InnerWrap addDet>
         {/*Additional Details*/}
         <h2>
          Additional Details
          <Popup
              modal
              closeOnDocumentClick={true}
              closeOnEscape={true}
              trigger={
                  <a className="edit-additional-details">Edit Additional Details</a>
              }
              contentStyle={popupstyle}
          >
              {close => (<div>
                  <div className="modal-header">
                              <span className="modal-header-text">
                                 Agent's Additional Details
                              </span>
                  </div>
                  {this.state.loading && <DefaultLoader/>}
                  {!this.state.loading && <div style={{marginBottom: "20px"}}>
                      <div className="edit-client">
                          <span>Additional Info</span>
                          <textarea
                              name="body"
                              onChange={this.handleChange}
                              value={this.state.tempAgentAdditionalDetails}
                              rows="8"
                              className={"form-control"}
                          />
                      </div>
                  </div>}
                  {!this.state.loading && <div className="edit-client-footer">
                     <button className="med-btn-1" onClick={() => {this.updateAgentAdditionalDetails(close)}}>
                      Save
                      </button>
                      <button className="med-btn-1" onClick={()=> this.toggleAdditionalDetails(close())}>
                      Cancel
                      </button>
                      </div>}
              </div>)}
          </Popup>
         </h2>
         <div class="preview">
           {this.state.agentAdditionalDetails}
         </div>
        </InnerWrap>
        <InnerWrap otherDetails firstRow>
         <div>
          <h2>Sending Agent</h2>
          <a
           href={senderProfile}
           target="_blank">
            {props.referral.sender_name}
           </a>
          <p>
           {props.referral.sender_email}
           </p>
           {props.referral.sender_phone && <p>
             {props.referral.sender_phone}
           </p>}
         </div>
         <div>
          <h2>Referral Source</h2>
          <p>{props.referral.referral_source || "NA"}</p>
         </div>
         <div>
          <h2>Type</h2>
          <p>{props.referral.is_isa ? 'ISA' : 'NON-ISA'}</p>
         </div>
        </InnerWrap>
        <InnerWrap otherDetails>
        <div>
         <h2>Calendly Link</h2>
         <a
          href={props.referral.calendly_link}
          target="_blank">
           {props.referral.calendly_link ? props.referral.calendly_link : "N/A" }
          </a>
        </div>
        <div>
         <h2>Brokerage Firm</h2>
         <p>{this.props.referral.sender_agency_info.agency_name || "N/A"}</p>
        </div>
            {Boolean(is_mortgage) && (
                <div>
                    <h2>Buyer Agent</h2>
                    <p>{buyer_agent || "N/A"}</p>
                </div>
            )
            }
        </InnerWrap>
       </DetailsWrap>
       {/*CTA div*/}
       <CTAWrap>
         <SubMenu showMenuLeft>
            <a style={{marginLeft : '0px'}} href="#" onClick={() => { props.setClientDetailsFromSearch(props.referral.receiver_client_id, this.state.phone) }}>Refresh Client</a>
            
             <a style={{marginLeft : '0px'}} href={recommendationsUrl + '?referralId=' + props.referral.id} target="_blank">Referral Settings</a>
             <Popup
                 trigger={
                   <a>
                     Edit Client
                   </a>
                 }
                 modal
                 closeOnDocumentClick={false}
                 closeOnEscape={false}
             >
               {close => (
                   <div className="modal-2">
                     <div className="modal-header">
                                   <span className="modal-header-text">
                                       EDIT CLIENT INFO
                                   </span>
                     </div>
                     <div>
                       <div className="edit-client">
                         <div>Name</div>
                         <input className="edit-client-input" id ="name" onChange={this.handleFormChange}  value={this.state.name}/>
                       </div>
                       <div className="edit-client">
                         <div>Phone</div>
                         <input className="edit-client-input" id ="phone" onChange={this.handleFormChange} value={this.state.phone}/>
                       </div>
                       <div className="edit-client">
                         <div>Email</div>
                         <input className="edit-client-input" id ="email" onChange={this.handleFormChange} value={this.state.email}/>
                       </div>
                       <div className="edit-client-radio">
                           <p>Lead Type</p>
                           <form>
                               <span className="radio-client-info">
                                   <label style={{background: "#ffffff"}}>
                                   <input type="radio" value="Buyer" checked={this.state.type === 'Buyer'}
                                          onChange={this.handleTypeChange}/>
                                   Buyer
                                   </label>
                             </span>
                               <span className="radio-client-info">
                                   <label style={{background: "#ffffff"}}>
                                     <input type="radio" value="Seller" checked={this.state.type === 'Seller'}
                                            onChange={this.handleTypeChange}/>
                                     Seller
                                   </label>
                             </span>
                               <span className="radio-client-info">
                                   <label style={{background: "#ffffff"}}>
                                     <input type="radio" value="Renter" checked={this.state.type === 'Renter'}
                                            onChange={this.handleTypeChange}/>
                                     Renter
                                   </label>
                             </span>
                           </form>
                       </div>
                       {!isZillowTestAccount && (
                         <React.Fragment>
                           <div>
                             <input type="checkbox"  className="checkbox-left-client_info" checked={this.state.referralSettings.auto_call} onChange={this.handleAutoCalls}/>
                             <label style={{'font-size': "16px",
                                 'border-radius': '0px',
                                 'font-family': "Rubik-Medium",
                                 'background': "#ffffff"}}>
                                 Allow Auto Call
                             </label>
                          </div>

                          <div>
                              <input type="checkbox"  className="checkbox-left-client_info" checked={this.state.referralSettings.auto_sms} onChange={this.handleAutoSms}/>
                              <label style={{'font-size': "16px",
                                  'border-radius': '0px',
                                  'font-family': "Rubik-Medium",
                                  'background': "#ffffff"}}>
                                  Allow Auto SMS
                              </label>
                          </div>
                          <div>
                              <input type="checkbox"  className="checkbox-left-client_info" checked={this.state.referralSettings.allow_missed_call_notifications} onChange={this.handleMissedCallNotifications}/>
                              <label style={{'font-size': "16px",
                                  'border-radius': '0px',
                                  'font-family': "Rubik-Medium",
                                  'background': "#ffffff"}}>
                                  Allow Missed Call Notifications
                              </label>
                          </div>
                         </React.Fragment>
                       )}

                     </div>
                     <div className="edit-client-footer">
                       <button className="med-btn-1" onClick={() => {this.updateClient(close())}}>
                         Save
                       </button>
                       <button className="med-btn-1" onClick={()=> {close();}}>
                         Cancel
                       </button>
                     </div>
                   </div>
               )}
             </Popup>
             <Popup
                 modal
                 closeOnDocumentClick={true}
                 closeOnEscape={true}
                 trigger={
                   <a>View Messages</a>
                 }
                 contentStyle={popupstyle}
               >
                   {close => (<div>
                       {messages.length > 0 ? <div>{messages}</div>:<div>No Messages</div>}
                   </div>)}
             </Popup>
         </SubMenu>
         <SubMenu
           pre
         >
          <Locations>
             <div
               className="citiesServed"
             >
             {this.props.referral && this.props.referral.pre_assigned_agent_name && (
              <React.Fragment>
               <PreAssignedTitle>Pre Assigned Agent</PreAssignedTitle>
               <PreAssignedAgent
                 href={preAssignedAgentProfile}
                 target="_blank"
               >
                {this.props.referral && this.props.referral.pre_assigned_agent_name ? this.props.referral.pre_assigned_agent_name : "N/A"}
               </PreAssignedAgent>
              </React.Fragment>
             )}
               <CitiesServed>Cities Served</CitiesServed>
            </div>
            <div
             className="locationWrap"
            >
              <div
               className="locationList"
              >
              <ul>
               {locations && locations.length > 0 ?
                locations
                : (<li>N/A</li>)
               }
              </ul>
              </div>
            </div>
          </Locations>
          <DropdownWrap>
          {isFollowUpVisible && (
           <div>
            <p>Follow Up date</p>
            <Flatpickr
             className="headerFlatPickr"
             value={next_follow_up_date}
             options={{
               minDate: 'today',
              }}
             onChange={(selectedDates, dateStr, instance) => this.handleFollowUpDate(selectedDates, dateStr, instance)}
             />
           </div>
          )}

          {(referralSettings && referralSettings.is_post_claim_follow_up) && (
           <div>
            <p style={{ padding: 0 }}>Post Claim Follow Up date</p>
            <div style={{ display: "flex", width: 200, flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              <Flatpickr
                className="headerFlatPickr"
                value={unixToDate(this.state.postClaimScheduleTimestamp || referralSettings.next_followup_at)}
                options={{
                  minDate: 'today',
                }}
                onChange={(selectedDates) => this.handlePostClaimFollowUp(selectedDates)}
              />
                <button
                  style={{ marginTop: 0, padding: 5 }}
                  onClick={this.savePostClaim}
                  disabled={this.props.isOnCall}
                >
                  Save
                </button>
            </div>
            
           </div>
          )}

          
           <div>
            <p>Referral Stage:</p>
            <select className="dropdown" onChange={this.updateStatus} value={this.state.referred_client_status}>
             {status_dropdown_options.map(item => (
               <option style={{background: item.color}} key={item.value} value={item.value}>{item.title}</option>
             ))}
            </select>
            <button onClick={() => {this.isValidForAutoCall(props.referral.id, props.user.twilio_client_name)}}>
                Is Valid for AutoCall</button>
           </div>
           <div>
           {/* <p>Lead Priority</p>
           <select className="dropdown" onChange={this.updateReferralPriority}
                   value={this.state.referralSettings.referral_priority}>
               <option value={"1"}>High</option>
               <option value={"0"}>Low</option>
           </select> */}
           </div>
           <div>
           <p>Followup frequency</p>
           <NumberFormat placeholder="(days)" onChange={this.updateFollowupFrequency}
                         value={this.state.referralSettings.followup_frequency}/>
           </div>
           <div>
             <Button onClick={this.handleRefreshPostClaimQueue}>
                Refresh post claim queue
             </Button>
           </div>
          </DropdownWrap>
         </SubMenu>
       </CTAWrap>
     </OuterWrap>
  )
}

}

export default container(ClientInfo);
