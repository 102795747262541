import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import AddInstructorForm from "./AddInstructorForm"
import ImageUploader from 'react-images-upload';
import colors from "../../../lib/colors"
import Select from "react-select"
import InstructorsMenu from "./InstructorsMenu"
import RTFEditor from "dumbComponents/Academy/Common/RTF"
import RTFEditorIO from "dumbComponents/Academy/Common/EditorRTF"

const OuterWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`
const LeftMenu = styled.div`
  height: 73px;
  max-width: 188px;
  flex-grow: 1;
`

const RightMenu = styled.div`
  height: 500px;
  width: 699px;
  .addInstructor > div:last-child {
   width: 1000px;
   margin-top: 250px;
  }
  .addInstructor {
    width: 110%;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    border: solid 1px #e2e4e8;
    padding: 0px 20px 80px 20px;

    .codex-editor__redactor {
     min-height: 200px;
     padding: 0 !important;
    }

    .profilePicWrap {
     border-radius: 4px;
     flex-grow: 2;
     margin-top: 50px;
     height: 240px;
     display: flex;
     flex-direction: column;
     .profImg {
       height: 100px;
       width: 100px;
       padding: 65px;
      img{
       width: 100px;
       height: 100px;
       border-radius: 55%;
      }
     }
     .upload {
      background: none;
      border: none;
      font-size: 16px;
      font-weight: 500;
      color: #0466d6;
      cursor: pointer;
      width: max-content;
     }
     .fileUploader {
      width: 85%;
      max-width: 239px;
     }

     .fileContainer {
      height: 216px;
      p{
       font-size: 9px;
       margin: 8px 0 9px;
      }
     }

     .uploadPicture {
      position: absolute;
      top: -3px;
     }

     .fileContainer .uploadPictureContainer{
      padding: 0px;
      .deleteImage {
       position: absolute;
       top: -9px;
       right: -9px;
       color: #fff;
       background: #ff4081;
       border-radius: 50%;
       text-align: center;
       cursor: pointer;
       font-size: 11px;
       font-weight: bold;
       line-height: 23px;
       width: 21px;
       z-index: 9;
       height: 21px;
      }
     }
    }
  }
  .cta {
   display: flex;
   flex-direction: row;
   width: 100%;
   height: 96px;
   .info {
     flex-grow:2;
     padding: 20px;
     width: 166px;
     height: 19px;
     font-family: "SF Pro Display", sans-serif;
     font-size: 16px;
     font-weight: 500;
     color: #000000;
     p {
      margin: 0;
     }

     .sub{
      font-size: 12px;
      color: #586069;
     }
   }

   .add {
     flex-grow:1;
     button {
      float: right;
      margin-right: 10px;
      margin-top: 10px;
      width: 92px;
      height: 30px;
      border-radius: 4px;
      border: solid 1px #2a8b3f;
      background-image: linear-gradient(to bottom, #34ce57, #28a745);
      color: white;
     }
   }
  }
`

const Wrap = styled.div`
height: 100px;
width: 100px;
background: red;
`

class AddInstructor extends Component {
 state = {
  isUpdateMode: this.props.match.params.id,
  pictures: [],
 }
 componentDidMount() {
  const {
    getInstructorDetail,
  } = this.props
  if(this.props.match.params.id) {
    getInstructorDetail({
     id: this.props.match.params.id,
    })
  }

 }

 onDrop = (picture, file) => {
    this.setState({
        pictures: this.state.pictures.concat(picture),
    });
 }

 initiateImageUpload = (id) => {
  const {
   pictures,
  } = this.state

  const {
   updateProfileImage,
   instructorDetailsResponse,
  } = this.props

  const {
   data: instructorDetails,
  } = instructorDetailsResponse || {}

  const file = pictures[0]

  updateProfileImage({
   file,
   id,
  })

 }

 submit = (values) => {
    const {
     createInstructor,
     instructorBio,
     instructorDetailsResponse,
     updateInstructor,
     currentImageURL,
    } = this.props

   if (!currentImageURL) {
     alert("Please upload image for the instructor")
     return
   }

    const {
     isUpdateMode,
     pictures,
    } = this.state
    if(isUpdateMode
    && this.props.match.params.id
    ) {
      updateInstructor({
       id: this.props.match.params.id,
       payload: values,
       aboutInstructor: instructorBio,
       existingDetails: instructorDetailsResponse.data[0],
       currentImageURL: currentImageURL,
      })
    } else {
     createInstructor({
      payload: values,
      currentImageURL: currentImageURL,
      aboutInstructor: instructorBio,
     })
    }
  }

 render() {
  const {
   instructorDetailsResponse,
   currentImageURL,
  } = this.props

  const {
   isUpdateMode,
   pictures,
  } = this.state

  const {
   data: instructorDetail,
   isFetching,
  } = instructorDetailsResponse || {}
  return (
   <React.Fragment>
     { isFetching ? (
      <OuterWrap>
       <LeftMenu>
       <InstructorsMenu
        isUpdateMode={this.props.match.params.id || ""}
       />
       </LeftMenu>
       <RightMenu></RightMenu>
      </OuterWrap>
     ) : (
      <OuterWrap>
       <LeftMenu>
       <InstructorsMenu
        isUpdateMode={this.props.match.params.id || ""}
       />
       </LeftMenu>
       <RightMenu
        isUpdateMode={isUpdateMode}
       >
        <div className="cta">
          <div className="info">
            {isUpdateMode ?
            (
             <React.Fragment>
               <p>Edit Host/Instructor</p>
               <p className="sub">
                 Modify the fields below to update the Instructor/Host.
               </p>
             </React.Fragment>
            ) : (
             <React.Fragment>
               <p>Add a person</p>
               <p className="sub">
                 Instructors you add here will be added to the Radius system and visible to everyone.
                  Profile image can be updated once instructor is created.
               </p>
             </React.Fragment>
            )}
          </div>
          <div className="add">
           <button
            form='instructor-form'
            type="submit"
           >
           {isUpdateMode ? "Update" : "Add"}
           </button>
          </div>
        </div>
        <div className="addInstructor">
        {true && (
         <div className="profilePicWrap">
           <ImageUploader
               withIcon={true}
               buttonText={instructorDetail && instructorDetail.length > 0
                ? "Change Image" : "Select Image" }
               onChange={this.onDrop}
               imgExtension={['.jpg', '.gif', '.png', '.gif']}
               maxFileSize={5242880}
               withPreview={true}
               singleImage={true}
               withLabel={true}
            />
            <button
             disabled={pictures.length < 0}
             onClick={() => this.initiateImageUpload(isUpdateMode)}
             className="upload"
             >
             Upload
            </button>
            {instructorDetail
              && instructorDetail[0].image_url
              && instructorDetail[0].image_url.indexOf("abc") <= -1
              && (
             <div className="profImg">
               <img src={instructorDetail[0].image_url} alt="inst" />
             </div>
            )}
         </div>
        )}
         <AddInstructorForm
          onSubmit={this.submit}
          existingDetails={instructorDetail}
          isUpdateMode={isUpdateMode}
         />
         <RTFEditorIO
          contentType="instructorBio"
          contentTitle="Bio"
          contentSub="About the Instructor/Host *"
          rtfContent={
           isUpdateMode
           && isUpdateMode.length > 0
           && instructorDetail
           && instructorDetail[0]
           && instructorDetail[0].bio
          || ""}
          isUpdateMode={isUpdateMode}
         />
        </div>
       </RightMenu>
      </OuterWrap>
     )}
   </React.Fragment>
  )
 }
}

export default Container(AddInstructor)
