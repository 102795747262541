import React, { PureComponent } from "react"
import styled from "styled-components"
import colors from "../../../../lib/colors"
import TechAssetsContainer from "container/TechAssets"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { Button, Checkbox, Tooltip, Select } from "dumbComponents/common/UI/antd"
import ZeroState from "dumbComponents/common/ZeroState"
import {
  PageArray,
  LenderSelectStep,
  LenderInfoStep,
  LenderInfoRoute,
  UpdateLenderRoute,
  VendorSelectRoute,
} from "../../consts"
import {
  fetchDetails,
  getDealId,
  getAgentId,
} from "../../commonUtils"
import Sidebar from "../Sidebar"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const AddForm = styled.div`
  text-align: right;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`

const HeaderNav = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LenderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  

  justify-content: ${props => props.justify || "unset"}

  ${props => props.gap && `
    gap: ${props.gap};
  `}

  ${props => props.disable && `
    cursor: not-allowed;
    opacity: 0.2;
  `}
`

const LenderBox = styled.div`
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
  width: 47%;
  margin: 10px;

  ${props => props.selected && `
    border: 1px solid #1a8ffb;
  `}
`
const LenderHeader = styled.div`
  border-bottom: 1px solid #E5E5E5;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 22px;
  h1 {
    color: #303030;
    margin: 0 0 10px;
  }
  p {
    margin: 5px 0 0 0;
    font-size: 14px;
  }
`
const VendorContent = styled.div`
  margin: 20px 0px;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  color: #303030;
  span {
    color: #8C8C8C;
  }
`
const LenderButton = styled(Button)`
  float: right;

  ${props => props.disabled && `
    background: grey !important;
    color: white !important;
    opacity: 0.5;
  `}
`

const CheckboxContainer = styled.div`
  padding: 0 20px 0 0;
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    display: flex;
  }
`
const CheckboxLabelText = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  color: ${colors.black};
  display: flex;
`

const OPTIONS = {
  California: [
    {
      value: "conventional",
      label: "Conventional",
    },
    {
      value: "cash",
      label: "Cash",
    },
    {
      value: "FHA",
      label: "FHA",
    },
    {
      value: "VA",
      label: "VA",
    },
    {
      value: "seller_financing",
      label: "Seller Financing",
    },
  ],
  Georgia: [
    {
      value: "conventional",
      label: "Conventional",
    },
    {
      value: "cash",
      label: "Cash",
    },
    {
      value: "FHA",
      label: "FHA",
    },
    {
      value: "VA",
      label: "VA",
    },
    {
      value: "loan_assumption",
      label: "Loan Assumption",
    },
    {
      value: "usda_rd",
      label: "USDA-RD",
    },
  ],
  Texas: [
    {
      value: "third_party_financing",
      label: "Third Party Financing",
    },
    {
      value: "cash",
      label: "Cash",
    },
    {
      value: "loan_assumption",
      label: "Loan Assumption",
    },
    {
      value: "seller_financing",
      label: "Seller Financing",
    },
  ],
  Florida: [
    {
      value: "conventional",
      label: "Conventional",
    },
    {
      value: "cash",
      label: "Cash",
    },
    {
      value: "FHA",
      label: "FHA",
    },
    {
      value: "VA",
      label: "VA",
    },
    {
      value: "assumption_of_existing_mortgage",
      label: "Assumption of Existing Mortgage",
    },
    {
      value: "purchase_money_note_and_mortgage_to_seller",
      label: "Purchase Money Note & Mortgage to Seller",
    },
  ],
  Oregon: [
    {
      value: "conventional",
      label: "Conventional",
    },
    {
      value: "cash",
      label: "Cash",
    },
    {
      value: "FHA",
      label: "FHA",
    },
    {
      value: "VA",
      label: "VA",
    },
    {
      value: "seller_financing",
      label: "Seller Financing",
    },
  ],
}

class LenderSelect extends PureComponent {
  state = {
    financing_information: "cash",
  }

  componentDidMount() {
    const { getDealDetails } = this.props

    fetchDetails(getDealDetails, LenderInfoStep)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.getCurrentDealDetailsResponse?.data?.financing_information !== this.props.getCurrentDealDetailsResponse?.data?.financing_information) {
      const state = this.props?.offerInfoDetails?.state
      const saveFinancingInfo = this.props.getCurrentDealDetailsResponse?.data?.financing_information
      const vaidState = Object.keys(OPTIONS).includes(state) ? state : "California"
      if (OPTIONS[vaidState]) {
        const allFinancingTypeStateValues = OPTIONS[vaidState].map(ft => ft.value)
        if (allFinancingTypeStateValues.includes(saveFinancingInfo)) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({
            financing_information: saveFinancingInfo,
          })
        }
      }
    }
  }

  onFinish = (lenderId, selectedLenderId) => {
    const { updateOffer, history, isAllCashOffer } = this.props
    const { financing_information } = this.state

    const agentId = getAgentId() || false

    const payload = {
      current_offer_step: LenderSelectStep,
      lender_id: (["cash", "seller_financing"].includes(financing_information) || !financing_information) ? null : (lenderId || selectedLenderId),
      is_all_cash: 0,
      financing_information: financing_information || "cash",
    }

    if (isAllCashOffer) {
      payload.is_all_cash = 1
      payload.lender_id = null
    }

    if (selectedLenderId && !isAllCashOffer) {
      history.push("/broker/tc/offers")
    } else {
      updateOffer({
        payload,
        nextRoute: "/broker/tc/offers",
        history,
        dealId: getDealId(),
        lastStep: true,
        dealAgentId: agentId,
      })
    }
  }

  lenderToEdit = (id, data) => {
    const { history, setCurrentVendorEditDetails: setCurrentLenderEditDetails } = this.props
    setCurrentLenderEditDetails({
      id,
      data,
    })

    history.push(`${UpdateLenderRoute}/${id}?dealId=${getDealId()}&agentId=${getAgentId()}`)
  }

  addNewLender = () => {
    const { history, setCurrentVendorEditDetails } = this.props
    setCurrentVendorEditDetails({
      id: null,
      data: null,
    })
    history.push(`${LenderInfoRoute}?dealId=${getDealId()}&agentId=${getAgentId()}`)
  }

  render() {
    const {
      history,
      getCurrentDealDetailsResponse,
      updateOfferActionResponse,
      isAllCashOffer,
      toggleAllCashOffer,
      offerInfoDetails,
    } = this.props

    const { financing_information } = this.state

    const {
      data: lenderData,
      isFetching: fetchingDeal,
    } = getCurrentDealDetailsResponse || {}

    const {
      isFetching: updatingDeal,
    } = updateOfferActionResponse || {}

    const isLoading = fetchingDeal || updatingDeal
    const state = offerInfoDetails && offerInfoDetails.state

    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Financing Information
        </HeaderNav>
        <ProgressBarComponent total={PageArray} ongoingOrCompleted={7} />
        <Container>
          <LeftSideBar>
            <Sidebar />
          </LeftSideBar>
          <RightSideBar>
            <AddForm>
              <Button
                ghost
                style={{
                  float: "right",
                  borderColor: "#3B60E4",
                  color: "#3B60E4",
                  display: "flex",
                  alignItems: "center",
                }}
                type="primary"
                shape="round"
                size="large"
                icon={<PlusCircleOutlined />}
                onClick={() => { this.addNewLender() }}
              >
                Add new lender
              </Button>
              <CheckboxContainer>
                <CheckboxLabelText>
                  Financing Type
                </CheckboxLabelText>
                <Select
                  placeholder="Select"
                  style={{
                    width: 300,
                    marginTop: 8,
                  }}
                  value={financing_information}
                  defaultValue={{
                    value: "cash",
                    label: "Cash",
                  }}
                  options={state === "California" ? OPTIONS.California : (state === "Georgia" ? OPTIONS.Georgia : (state === "Texas" ? OPTIONS.Texas : (state === "Florida" ? OPTIONS.Florida : (state === "Oregon" ? OPTIONS.Oregon : OPTIONS.California))))}
                  onChange={(value) => { this.setState({ financing_information: value }) }}
                />
              </CheckboxContainer>
            </AddForm>
            <LenderContainer
              disable={financing_information === "cash" || financing_information === "seller_financing"}
            >
              {isLoading ? (
                <p>Loading</p>
              ) : (
                <>
                  {(lenderData && lenderData.lenders && lenderData.lenders.length <= 0) && (
                    <ZeroState
                      height="474px"
                      width="100%"
                      backgroundColor="#F9F9F7"
                      text="No Lenders Added"
                      logo=""
                      onClickHandler={() => { this.addNewLender() }}
                      displayStyle="flex"
                      alignItems="center"
                      justifyContent="center"
                      buttonCTAText={(financing_information === "cash" || financing_information === "seller_financing") ? "" : "Add Lender"}
                      innerBoxDisplayStyle="flex"
                      innerBoxFlexDirection="column"
                      innerBoxContentJustification="center"
                      innerBoxItemAlign="center"
                      buttonStyles="#3A60E4"
                  />
                  )}
                  {lenderData && lenderData?.lenders?.map(dt => (
                    <LenderBox
                      selected={lenderData.selected_lender_id === dt.id}
                    >
                      <LenderHeader>
                        <h1>
                          {dt.lender_name}
                          {dt.draft === 1 && (
                            <p>
                              In Draft
                            </p>
                          )}
                        </h1>
                        <EditOutlined onClick={() => this.lenderToEdit(dt.id, dt)} />
                      </LenderHeader>
                      <VendorContent>
                        <p>
                          {dt.lender_name}
                        </p>
                        <p>
                          {dt.lender_number}
                        </p>
                        <p>
                          {`${dt.lender_officer_first_name} ${dt.lender_officer_last_name}`}
                        </p>
                        <p>
                          {`${dt.lender_address}, ${dt.lender_city}, ${dt.lender_state} ${dt.lender_zipcode}`}
                        </p>
                        <p>
                          {dt.lender_email}
                        </p>
                        <p>
                          {dt.lender_phone}
                        </p>
                        {dt.notes && (
                          <span>
                            Notes:
                            {" "}
                            {dt.notes}
                          </span>
                        )}
                      </VendorContent>
                      {dt.draft === 1 ? (
                        <div
                          style={{
                            float: "right",
                          }}
                        >
                          <Tooltip
                            placement="topLeft"
                            title="Lender details are incomplete."
                          >
                            <LenderButton disabled>Select</LenderButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <LenderButton
                          type="primary"
                          disabled={(financing_information === "cash" || financing_information === "seller_financing") || dt.draft === 1 || lenderData.selected_lender_id === dt.id}
                          loading={isLoading}
                          onClick={() => { this.onFinish(dt.id) }}
                        >
                          {lenderData.selected_lender_id === dt.id ? "Selected" : "Select"}
                        </LenderButton>
                      )}
                    </LenderBox>
                  ))}
                </>
              )}
            </LenderContainer>
            <LenderContainer
              justify="flex-end"
              gap="8px"
              
            >
              <LenderButton
                style={{
                  width: 119,
                  height: 48,
                  background: "#3B60E4",
                  float: "right",
                  borderColor: "white",
                }}
                type="primary"
                shape="round"
                size="large"
                onClick={() => { history.push(`${VendorSelectRoute}?dealId=${getDealId()}&agentId=${getAgentId()}`) }}
              >
                Back
              </LenderButton>
              {lenderData && (!lenderData.selected_lender_id && !(financing_information === "cash" || financing_information === "seller_financing")) ? (
                <div
                  style={{
                    float: "right",
                  }}
                >
                  <Tooltip
                    placement="topLeft"
                    title="Please select a Lender or mark as All cash to proceed."
                  >
                    <LenderButton
                      disabled
                      style={{
                        width: 119,
                        height: 48,
                        background: "#3B60E4",
                        float: "right",
                        borderColor: "white",
                        color: "white",
                      }}
                      type="primary"
                      shape="round"
                      size="large"
                      htmlType="button"
                    >
                      Submit
                    </LenderButton>
                  </Tooltip>
                </div>
              ) : (
                <LenderButton
                  style={{
                    width: 119,
                    height: 48,
                    background: "#3B60E4",
                    float: "right",
                    borderColor: "white",
                    color: "white",
                  }}
                  type="primary"
                  shape="round"
                  size="large"
                  disabled={lenderData && (!lenderData.selected_lender_id && !(financing_information === "cash" || financing_information === "seller_financing"))}
                  loading={isLoading}
                  htmlType="button"
                  onClick={() => { this.onFinish(null, lenderData.selected_lender_id) }}
                >
                  Submit
                </LenderButton>
              )}
            </LenderContainer>
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(LenderSelect)
