import React, { Component } from "react"
import styled from "styled-components"
import { reduxForm, Field, formValueSelector } from "redux-form"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import root from "window-or-global"
import colors from "../../../lib/colors"

const Wrap = styled.div`
  height: 500px;
  width: 188px;
  margin-right: 60px;
`

const HeaderList = styled.li`
  background-color: #f4f5f8;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  padding: 10px 10px 15px 15px;
`

const ListItem = styled.li`
  padding: 10px 10px 15px 15px;
  width: 100%;
  height: 37px;
  border-bottom: 1px solid #e2e4e8;
  font-size: 14px;
  ${props => props.isActive && `
    border-left: 3px solid orange;
   `}
   a {
    text-decoration: none;
    color: black;
    cursor: pointer;
   }
`

const MenuList = styled.ul`
  width: 100%;
  height: max-content;
  margin: 0;
  padding: 0;
  border: 1px solid #e2e4e8;
  border-radius: 3px;
  list-style: none;
`

// const MenuList = styled.ul`
//   width: 100%;
//   height: max-content;
//   margin: 0;
//   padding: 0;
//   border: 1px solid #e2e4e8;
//   border-radius: 3px;
//   list-style: none;
//   li:first-child{
//    background-color: #f4f5f8;
//    border: none;
//    font-size: 16px;
//    font-weight: 500;
//    color: #000000;
//   }
//   li {
//    padding: 10px 10px 15px 15px;
//    width: 100%;
//    height: 37px;
//    border-bottom: 1px solid #e2e4e8;
//    font-size: 14px;
//    a {
//     text-decoration: none;
//     color: black;
//     cursor: pointer;
//    }
//   }
// `

const CTAWrap = styled.div`
  height: 300px;
  width: 100%;
  margin-top: 46px;

  button {
   color: white;
   width: 145px;
   height: 30px;
   border-radius: 4px;
   border: solid 1px #2a8b3f;
   background-image: linear-gradient(to bottom, #34ce57, #28a745);
   font-size: 16px;
   font-weight: 500;
   text-align: center;
   color: #ffffff;
  }
`

class AddLessonMenu extends Component {
 componentDidMount(){
  //
 }

 handleLessonUpdate = (id) => {

  const {
   globalState,
   isUpdateMode,
   lessonType,
   lessonNotes,
   aboutLessonMarketing,
   courseDetailResponse,
   notesNotes,
   resourceNotes,
   helpNotes,
   currentLessonType,
   updateLesson,
  } = this.props

  const {
   data: course,
  } = courseDetailResponse || {}

  const {
   Lessons: lessons,
  } = course || {}

  const currentLesson = course.Lessons.filter(lesson => lesson.id === id)[0]

  const aboutLessonFormSelector = formValueSelector("aboutLessonForm")
  const contentFormSelector = formValueSelector("lessonVideoForm")
  const contentTextSelector = formValueSelector("lessonTextForm")

  const lessonTitle = aboutLessonFormSelector(globalState, "lessonTitle")
  const lessonVideoUrl = contentFormSelector(globalState, "lessonVideo")
  const lessonTextLengthTime = contentTextSelector(globalState, "lessonTextlength")
  const lessonVideoLengthTime = contentFormSelector(globalState, "lessonVideolength")

  const payload = {
   title: lessonTitle || currentLesson.title,
   url: currentLesson.url || "", //change
   course_id: currentLesson.course_id,//dnc
   sequence_number: currentLesson.sequence_number, //dnc
   description: aboutLessonMarketing || currentLesson.description || "",
   resources: resourceNotes || currentLesson.resourceNotes || "",
   access_to: [ //change
            "broker",
            "team",
            "registered"
        ], //does not change
   created_at: currentLesson.created_at,
   updated_at: Date.now(),
   notes: {
    definitions: notesNotes || currentLesson.notes.definitions,
    help: helpNotes || currentLesson.notes.help,
   },
  }

  if(currentLesson.type === "text") {
    payload.type = "text"
    payload.text_content = currentLesson.text_content
  }

  if(currentLesson.type === "video") {
     payload.type = "video"
     payload.video_url = currentLesson.video_url
  }

  payload.duration_in_seconds = currentLesson.duration_in_seconds
   || (lessonTextLengthTime > 0 && lessonTextLengthTime*60)
   || (lessonVideoLengthTime > 0 && lessonVideoLengthTime*60)
   || 0
  if(currentLessonType[0].value === "text" && lessonNotes && lessonNotes.length > 0 ) {
   payload.type = "text"
   payload.text_content = lessonNotes || currentLesson.text_content
   payload.duration_in_seconds = lessonTextLengthTime*60
  } else if (currentLessonType[0].value === "video" && lessonVideoUrl && lessonVideoUrl.length > 0 ) {
   payload.type = "video"
   payload.video_url = lessonVideoUrl || currentLesson.video_url
   payload.duration_in_seconds = lessonVideoLengthTime*60
  }

  updateLesson({
   payload,
   id,
  })

 }

 handleAllFormSubmit = () => {
  const {
   globalState,
   isUpdateMode,
   lessonType,
   lessonNotes,
   aboutLessonMarketing,
   createLesson,
   courseDetailResponse,
   notesNotes,
   resourceNotes,
   helpNotes,
  } = this.props

  const {
   data: course,
  } = courseDetailResponse || {}

  const {
   Lessons: lessons,
  } = course || {}

  const currentNumberOfLessons = lessons && lessons.length || 0

  const aboutLessonFormSelector = formValueSelector("aboutLessonForm")
  const contentFormSelector = formValueSelector("lessonVideoForm")
  const contentTextSelector = formValueSelector("lessonTextForm")

  const lessonTitle = aboutLessonFormSelector(globalState, "lessonTitle")
  const lessonVideoUrl = contentFormSelector(globalState, "lessonVideo")
  const lessonTextLengthTime = contentTextSelector(globalState, "lessonTextlength")
  const lessonVideoLengthTime = contentFormSelector(globalState, "lessonVideolength")

  const course_id = isUpdateMode

  const payload = {
   title: lessonTitle,
   url: "", //change
   course_id,
   description: aboutLessonMarketing || "",
   sequence_number: currentNumberOfLessons + 1,
   resources: resourceNotes || "",
   access_to: [ //change
            "broker",
            "team",
            "registered"
        ],
   created_at: Date.now(),
   notes: {
    definitions: notesNotes || "",
    help: helpNotes || "",
   },
  }

  if( lessonNotes && lessonNotes.length > 0) {
   payload.type = "text"
   payload.text_content = lessonNotes
   payload.duration_in_seconds = lessonTextLengthTime*60
  }

  if( lessonVideoUrl && lessonVideoUrl.length > 0) {
   payload.type = "video"
   payload.video_url = lessonVideoUrl
   payload.duration_in_seconds = lessonVideoLengthTime*60
  }

  createLesson({
   payload,
  })
 }

 render(){
  const {
   submitFunc,
   isUpdateMode,
   isIndividualLessonMode,
  } = this.props

  return (
   <React.Fragment>
   {isIndividualLessonMode && isIndividualLessonMode.length > 0 ? (
    <Wrap>
      <MenuList>
       <HeaderList> Lesson Details </HeaderList>
       <ListItem
        isActive={
         root.location.pathname.indexOf('about') > -1
        }
       >
        <Link
        to={`/academy-admin/courses/course/${isUpdateMode}/lesson/${isIndividualLessonMode}/about`}
        >
         About
        </Link>
       </ListItem>
       <ListItem
         isActive={
          root.location.pathname.indexOf('content') > -1
         }
       >
         <Link
          to={`/academy-admin/courses/course/${isUpdateMode}/lesson/${isIndividualLessonMode}/content`}
         >
          Content
         </Link>
       </ListItem>
       <ListItem
       isActive={
        root.location.pathname.indexOf('notes') > -1
       }
       >
        <Link
         to={`/academy-admin/courses/course/${isUpdateMode}/lesson/${isIndividualLessonMode}/notes-resources`}
        >
         Notes & Resources
        </Link>
       </ListItem>
       <ListItem
       isActive={
        root.location.pathname.indexOf('settings') > -1
       }
       >
        <Link
         to={`/academy-admin/courses/course/${isUpdateMode}/lesson/${isIndividualLessonMode}/settings`}
        >
         Settings
        </Link>
       </ListItem>
      </MenuList>
      <CTAWrap>
        <button onClick={() => this.handleLessonUpdate(isIndividualLessonMode)} type="submit">
         Update Lesson
       </button>
      </CTAWrap>
    </Wrap>
   ): (
    <Wrap>
      <MenuList>
       <HeaderList> Lesson Details </HeaderList>
       <ListItem
        isActive={
         root.location.pathname.indexOf('about') > -1
        }
       >
        <Link
        to={isUpdateMode ? `/academy-admin/courses/course/${isUpdateMode}/lessons/add-lesson/about`
         : "/academy-admin/courses/add-new-course/lesson/create-lesson/about"}
        >
         About
        </Link>
       </ListItem>
       <ListItem
         isActive={
          root.location.pathname.indexOf('content') > -1
         }
       >
         <Link  to={isUpdateMode ? `/academy-admin/courses/course/${isUpdateMode}/lessons/add-lesson/content`
           : "/academy-admin/courses/add-new-course/lesson/create-lesson/content"}>
          Content
         </Link>
       </ListItem>
       <ListItem
       isActive={
        root.location.pathname.indexOf('notes') > -1
       }
       >
        <Link  to={isUpdateMode ? `/academy-admin/courses/course/${isUpdateMode}/lessons/add-lesson/notes-resources`
          : "/academy-admin/courses/add-new-course/lesson/create-lesson/notes-resources"}>
         Notes & Resources
        </Link>
       </ListItem>
      </MenuList>
      <CTAWrap>
        <button onClick={() => this.handleAllFormSubmit()} type="submit">
         Add Lesson
       </button>
      </CTAWrap>
    </Wrap>
   )}
   </React.Fragment>

  )
 }
}

export default Container(AddLessonMenu)
