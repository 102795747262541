import React from "react"
import styled from "styled-components"

const InputEle = styled.input`
  border: 1px solid #CCC;
  font-size: 16px;
  border-radius: 4px;
  padding: 6px 10px;
`
const Input = props => (
  <InputEle {...props}/> 
)

export default Input