import React, { useEffect } from "react"
import styled from "styled-components"
import RecruitAndEarn from "../../../../container/RecruitAndEarn"
import { DefaultLoader } from "../../../../components/UI/Loader/Loader"

const Wrap = styled.div`
  padding-top: 20px;
  padding-bottom: 40px;
`

const InnerBox = styled.div`
  border: 1px solid #999;
  border-radius: 10px;
  text-align: center;
  padding: 16px;
  min-width: 0px;
  width: 196px;
  height: 158px;
`

const InnerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  width: 100%;
`

const Title = styled.p`
  font-size: 22px;
  margin-bottom: 0px;
`

const Value = styled.p`
 font-size: 50px;
 margin-top: 0px;
`

const Stats = (props) => {
  const {
    getTotalRecruitAndEarnStats,
  } = props

  useEffect(() => {
    getTotalRecruitAndEarnStats()
  }, [])

  const {
    getTotalRecruitAndEarnStatsResponse,
  } = props

  const {
    data: statsData,
    isFetching,
  } = getTotalRecruitAndEarnStatsResponse || {}

  return (
    <Wrap>
      {isFetching ? (
        <DefaultLoader />
      ) : (
        <InnerWrap>
          <InnerBox>
            <Title>Total Count</Title>
            <Value>{statsData && statsData.totalCount || 0}</Value>
          </InnerBox>
          <InnerBox>
            <Title>Invited Count</Title>
            <Value>{statsData && statsData.invitedCount || 0}</Value>
          </InnerBox>
          <InnerBox>
            <Title>Joined Count</Title>
            <Value>{statsData && statsData.joinedCount || 0}</Value>
          </InnerBox>
          <InnerBox>
            <Title>Declined Count</Title>
            <Value>{statsData && statsData.declinedCount || 0}</Value>
          </InnerBox>
        </InnerWrap>
      )}
    </Wrap>
  )
}

export default RecruitAndEarn(Stats)
