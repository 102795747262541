import React from "react"
import Container from "container/EventRooms"
import styled from "styled-components"
import "antd/lib/table/style/index.css"
import "antd/lib/input/style/index.css"
import "antd/lib/pagination/style/index.css"
import "antd/lib/button/style/index.css"
import "antd/lib/form/style/index.css"
import "antd/lib/layout/style/index.css"
import "antd/lib/grid/style/index.css"
import "antd/lib/message/style/index.css"
import { Table, Tag, Space, Form, Input, InputNumber, Button } from 'antd';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 6,
  },
}
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
}

const Wrap = styled.div`
  padding: 75px 35px;
`

const FormWrap = styled.div`
  padding: 40px;
  background: #e9e9e9;
  margin: 20px 0px;
`

const Header = styled.h1`
  font-size: 24px;
`

const CreateImmortalRoom = ({ createRoom }) => {
  const onFinish = (values) => {
    createRoom({
      ...values.room,
      is_public: 1,
      is_immortal: 1,
    })
    console.log(values)
  }

  return (
    <FormWrap>
      <Form {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
        <Form.Item
          name={["room", "name"]}
          label="Room Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 12 }}>
          <Button type="primary" htmlType="submit">
            Create Room
          </Button>
        </Form.Item>
      </Form>
    </FormWrap>
  )
}

class EventRooms extends React.PureComponent {
  state = {
    data: [
      {
        key: "1",
        name: "",
      },
    ],
  }

  componentDidMount() {
    const { getRooms } = this.props
    getRooms()
  }

  columns = [
    {
      title: "Room Id",
      dataIndex: "room_id",
      key: "room_id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Host",
      dataIndex: "host_name",
      key: "host_name",
    },
    // {
    //   title: "Is Public",
    //   dataIndex: "is_public_val",
    //   key: "is_public_val",
    // },
    // {
    //   title: "Is Immortal",
    //   dataIndex: "is_immortal_val",
    //   key: "is_immortal_val",
    // },
    {
      title: "Is Live",
      dataIndex: "is_live_val",
      key: "is_live_val",
    },
    {
      title: "Attendees Count",
      dataIndex: "attendees_count_val",
      key: "attendees_count_val",
    },
    {
      title: "Created At",
      dataIndex: "created_at_val",
      key: "created_at_val",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        console.log("==record===", record)
        const { endRoom } = this.props
        return (
          <Space size="middle">
            <Button disabled={record && record.attendees_count_val && record.attendees_count_val > 0} onClick={() => endRoom(record)} danger>End Room</Button>
          </Space>
        )
      },
    },
  ]

  render() {
    const { data } = this.state
    const { rooms, createRooms } = this.props
    console.log("===>", this.props)
    return (
      <Wrap>
        <Header>
          Create Room Form
        </Header>
        <CreateImmortalRoom createRoom={createRooms} />
        <Header>
          Live Rooms
        </Header>
        <Table columns={this.columns} dataSource={rooms && rooms.data} />
      </Wrap>
    )
  }
}

export default Container(EventRooms)
