import React from "react"
import styled from "styled-components"
import Button from "./../Components/RoundButton"
import { PLACE_HOLDER_IMAGE } from "./../ImagesData"


const Wrap = styled.div`
  
`

const Image = styled.img`
 
`

const ImageContainer = ({children}) => (
  <div
   style={{
     textAlign: "left"
   }}
  >
    {children}
  </div>
)

const ImageWrap = ({ children }) => (
  <div
    style={{
      width: "32%",
      display: "inline-block",
      marginBottom: 20,
      textAlign: "center",
    }}
  >
    {children}
  </div>
)

const MultipleFollow = (props) => (
  <Wrap
    style={{
      background: "#ffffff",
      textAlign: "center",
      padding: "10px 50px",
      marginBottom: 56,
      paddingBottom: 56,
      maxWidth: 600,
      margin: "0px auto"
    }}
  >
    <h2
      style={{
        fontSize: 24,
        fontWeight: 400,
      }}
    >
      Hey {`< Name >`}
    </h2>
    <p
      style={{
        fontWeight: 100,
      }}
    >
      You have <strong>5 new</strong> followers
    </p>
    <ImageContainer>
      <ImageWrap>
        <Image
        src={PLACE_HOLDER_IMAGE}
        alt="Agent Image"
        style={{
          borderRadius: 24,
          maxWidth: 64,
        }}
      />
      <h3
        style={{
          fontSize: 18,
          fontWeight: 400,
          margin: "10px 0px 0px 0px",
          padding: 0,
          color: "#303030",
        }}
      >
        Harry Potter
      </h3>
      <h6
        style={{
          fontSize: 16,
          fontWeight: 300,
          margin: 0,
          padding: 0,
          color: "#303030",
        }}
      >
        Modi Realty
      </h6>
      </ImageWrap>
      <ImageWrap>
        <Image
        src={PLACE_HOLDER_IMAGE}
        alt="Agent Image"
        style={{
          borderRadius: 24,
          maxWidth: 64,
        }}
      />
      <h3
        style={{
          fontSize: 18,
          fontWeight: 400,
          margin: "10px 0px 0px 0px",
          padding: 0,
          color: "#303030",
        }}
      >
        Harry Potter
      </h3>
      <h6
        style={{
          fontSize: 16,
          fontWeight: 300,
          margin: 0,
          padding: 0,
          color: "#303030",
        }}
      >
        Modi Realty
      </h6>
      </ImageWrap>
      <ImageWrap>
        <Image
        src={PLACE_HOLDER_IMAGE}
        alt="Agent Image"
        style={{
          borderRadius: 24,
          maxWidth: 64,
        }}
      />
      <h3
        style={{
          fontSize: 18,
          fontWeight: 400,
          margin: "10px 0px 0px 0px",
          padding: 0,
          color: "#303030",
        }}
      >
        Harry Potter
      </h3>
      <h6
        style={{
          fontSize: 16,
          fontWeight: 300,
          margin: 0,
          padding: 0,
          color: "#303030",
        }}
      >
        Modi Realty
      </h6>
      </ImageWrap>
      <ImageWrap>
        <Image
        src={PLACE_HOLDER_IMAGE}
        alt="Agent Image"
        style={{
          borderRadius: 24,
          maxWidth: 64,
        }}
      />
      <h3
        style={{
          fontSize: 18,
          fontWeight: 400,
          margin: "10px 0px 0px 0px",
          padding: 0,
          color: "#303030",
        }}
      >
        Harry Potter
      </h3>
      <h6
        style={{
          fontSize: 16,
          fontWeight: 300,
          margin: 0,
          padding: 0,
          color: "#303030",
        }}
      >
        Modi Realty
      </h6>
      </ImageWrap>
      <ImageWrap>
      <table
        style={{
          width: 64,
          height: 64,
          margin: "0px auto"
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                borderRadius: 24,
                background: "#CCC",
                textAlign: "center",
                fontSize: 20,
                color: "#FFF",
              }}
            >
              HP
            </td>
          </tr>
        </tbody>
      </table>
      <h3
        style={{
          fontSize: 18,
          fontWeight: 400,
          margin: "10px 0px 0px 0px",
          padding: 0,
          color: "#303030",
        }}
      >
        Harry Potter
      </h3>
      <h6
        style={{
          fontSize: 16,
          fontWeight: 300,
          margin: 0,
          padding: 0,
          color: "#303030",
        }}
      >
        Modi Realty
      </h6>
      </ImageWrap>
    </ImageContainer>
    
    <Button
      style={{
        background: "#0B8DEC"
      }}
    >
      Follow Them back
    </Button>
  </Wrap>
)

export default MultipleFollow