import React, { Component } from 'react';
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import Popup from "reactjs-popup";
import styled from "styled-components";
import AgentProfile from "./AgentProfile";
import "./AgentBox.css"
import {assignUnAssignAgent, createProspect, updateProspects, markProspectInvisible} from "../../API/Referral/ReferralApi";
import {NotifyBugsnagError, ShowErrorToast, ShowSuccessToast} from "../../../lib/ErrorUtils";
import {DefaultLoader} from "../../UI/Loader/Loader";
import AgentRemoveButton from "./AgentRemoveButton";
import AgentPreferences from "./AgentPreferences";

const starredString = "Received referrals from Radius before";

//Styled Components

function getAgentHeaderColor(e) {
    let agent = e.agent;
    if (agent.is_partner === 1) {
        return "#CE93D8";
    } else if (agent.is_rca === 1) {
        return "#BCAAA4";
    } else {
        return "#ffffff";
    }
}

function getAgentBodyColor(e) {
    let prospect_status = e.prospect_status;
    if (prospect_status === "assigned") {
        return "#328dcd";
    } else if (prospect_status === -1) {
        return "#ffffff";
    } else if (prospect_status === 0) {
        return "#ffffff";
    } else if (prospect_status === 1) {
        return "#329675";
    } else if (prospect_status === 2) {
        return "#cd5c5c";
    } else {
        return "#ffffff";
    }
}

function getProspectStatus(prospect_status) {
    if (prospect_status === "assigned") {
        return "(Assigned)";
    } else if (prospect_status === -1) {
        return "(Recommended)";
    } else if (prospect_status === 0) {
        return "(Sent)";
    } else if (prospect_status === 1) {
        return "(Accepted)";
    } else if (prospect_status === 2) {
        return "(Rejected)";
    } else if (prospect_status === 6) {
        return "(Partially Claimed)";
    } else {
        return "Unknown";
    }
}

const AgentDivHeader = styled.div`
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 10px;
  background: ${agent => getAgentHeaderColor(agent)};
`;

const AgentDivContent = styled.div`
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 10px;
  background: ${prospect_status => getAgentBodyColor(prospect_status)};
`;



const LineThrough = styled.div`
  text-decoration: ${props => props.doNotContact ? "line-through" : "none"}
`;
const popupstyle =  { overflow: 'auto', width: '80%'};

class AgentBox extends Component {

    state = {
        loading : false,
    };

    startLoader = () => {
        this.setState({loading: true})
    };

    stopLoader = () => {
        this.setState({loading: false})
    };

    assign = async (agent) => {
        let agentId = agent.id;
        this.startLoader();
        let body = {
            agentIdsToAssign: [agentId],
            referral_id: this.props.referral.id,
        };
        try {
            await assignUnAssignAgent(this.props.referral.receiver_client_id, body).then(res => {
                agent.prospect_status = "assigned";
                this.props.assignAndRefreshPage(agentId);
                ShowSuccessToast("Successfully assigned the agent");
                this.stopLoader();
            });
        } catch(e) {
            console.log(e);
            ShowErrorToast(e);
            this.stopLoader();
            NotifyBugsnagError(e);
        }
    };

    unassign = async (agent) => {
        try {
            let agentId = agent.id;
            this.startLoader();
            let body = {
                agentIdsToRemove: [agentId],
                referral_id: this.props.referral.id,
            };
            await assignUnAssignAgent(this.props.referral.receiver_client_id, body).then(res => {
                agent.prospect_status = -1;
                this.props.unassign(agentId);
                ShowSuccessToast("Successfully unassigned the agent");
                this.stopLoader();
            });
        } catch(e) {
            console.log(e);
            NotifyBugsnagError(e);
            this.stopLoader();
        }
    };

    invite = async(agentId) => {
        let body =  {
            "agent_id": agentId,
            "prospect_status": 0,
            "visible": 1,
            type: "qualification"
        };
        this.startLoader();
        try {
            await updateProspects(this.props.referral.id, body).then(res => {
                ShowSuccessToast("Successfully invited the agent");
                this.props.invite(agentId);
                this.stopLoader();
            });
        } catch(e) {
            console.log(e);
            NotifyBugsnagError(e);
            this.stopLoader();
        }
    };

    removeProspect = async(agent) => {
        let agentId = agent.id;
        let body =  {
            "agent_id": agentId,
            "prospect_status": -1,
            "visible": 0
        };
        this.startLoader();
        this.props.removeProspect(agent);
        try {
            await markProspectInvisible(this.props.referral.id, body).then(res => {
                ShowSuccessToast("Successfully removed");
                this.stopLoader();
            });
        } catch(e) {
            console.log(e);
            NotifyBugsnagError(e);
            this.stopLoader();
        }
    };

    render() {

        const {agent, agentMetrics} = this.props;

        let imageUrl = null;

        const images = _.filter(agent.images,
            (img) => {
                if (img.type === 'profile') {
                    return true;
                }
            }
        );

        if (images.length > 0) {
            imageUrl = images[0]['path'];
        }

        let citiesServed = [];
        _.each(agent.citiesServed, city => citiesServed.push(city.city));

        return (

            <div className={"agent-div"}>

                <AgentDivHeader agent={agent}>

                    <AgentRemoveButton removeProspect={this.removeProspect} agent={agent}/>

                    {
                        agent.reasons && (agent.reasons.indexOf(starredString) > -1) &&
                        <img src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/star-128.png"
                             className="star-icon"/>
                    }

                    {
                        imageUrl ?
                            <a data-tip data-for={"tip" + agent.id} href={agent.profile_url} target="_blank">
                                <img className="agent-avatar" src={imageUrl}/>
                            </a>
                            :
                            <a data-tip data-for={"tip" + agent.id} href={agent.profile_url} target="_blank">
                                <img className="agent-avatar"
                                     src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_fO8BlrOnXk250EEyOjWzEt0MiPNkxEKKYK6bYp8DOJOdhsvy4w"/>
                            </a>
                    }


                    {agent.reasons && Array.isArray(agent.reasons) &&
                    <ReactTooltip id={"tip" + agent.id} aria-haspopup='true' role='example'>
                        <p> This agent is recommended because: </p>
                        <ul>{
                            agent.reasons.map((reason) => <li key={reason}>{reason}</li>)
                        }</ul>
                        <p> The agent's lead preferences are: </p>
                        <AgentPreferences selectedAgent={agent}></AgentPreferences>
                    </ReactTooltip>
                    }

                    <ReactTooltip id={"doNotContact" + agent.id} aria-haspopup='true' role='example'>
                        <p> Click here to not contact the agent again</p>
                    </ReactTooltip>

                    <div className="agent-header-text-bold">

                        <p>{getProspectStatus(agent.prospect_status)}</p>

                        <LineThrough doNotContact={agent.do_not_contact}>
                            {agent.firstname ? (agent.firstname + ' ') : ''} {agent.lastname ? agent.lastname : ''} {agent.referral_score ? '(' + agent.referral_score + ')' : ''}
                            <LineThrough doNotContact={agent.do_not_contact}>
                                {agent.email ? agent.email : ''}
                            </LineThrough>
                        </LineThrough>
                        {agent.do_not_contact === 1 && "This agent should not be contacted"}
                    </div>


                    <div className="agent-header-text-normal">

                        <LineThrough doNotContact={agent.do_not_contact}>
                            {(agent.office_city ? agent.office_city + ', ' : '')}{agent.office_state ? agent.office_state : ''}
                        </LineThrough>
                    </div>
                    <div className="agent-header-text-normal">

                        <LineThrough doNotContact={agent.do_not_contact}>
                            {agent.phone ? "Phone: " + agent.phone : "No Phone"}
                        </LineThrough>
                    </div>
                    <div>
                        {agent.prospect_status === -1 && !agent.do_not_contact && !_.includes(agentMetrics.sentProspectIds, agent.id) ?
                            <button className="med-btn-call-agent" onClick={() => {
                                this.invite(agent.id);
                                agent.prospect_status = 0;
                                agent.referral_score = "sent";
                            }}>Invite</button> : ""}
                    </div>

                    {!agent.do_not_contact &&
                    (_.includes(agentMetrics.assignedAgentIds, agent.id) ?
                            <button className="med-btn-call-agent" onClick={() => {
                                this.unassign(agent);
                            }}>Unassign</button>
                            :
                            <button className="med-btn-call-agent" onClick={() => {
                                this.assign(agent);
                            }}>Assign</button>
                    )}

                </AgentDivHeader>

                <AgentDivContent prospect_status={agent.prospect_status}>

                    <hr/>
                    <div className="agent-details-cell">
                        <div className="agent-key">
                            Agreement Signed
                        </div>
                        <div className="agent-value">
                            {agent.leadAgreementSigned && agent.leadAgreementSigned === "signed" ? 'YES' : 'NO'}
                        </div>
                    </div>

                    <hr/>
                    <div className="agent-details-cell">
                        <div className="agent-key">
                            CRS
                        </div>
                        <div className="agent-value">
                            {agent.isCrsDesignee ? 'YES' : 'NO'}
                        </div>
                    </div>

                    <hr/>
                    <div className="agent-details-cell">
                        <div className="agent-key">
                            Partner
                        </div>
                        <div className="agent-value">
                            {agent.is_partner ? 'YES' : 'NO'}
                        </div>
                    </div>

                    <hr/>
                    <div className="agent-details-cell">
                        <div className="agent-key">
                            Preferred Agent
                        </div>
                        <div className="agent-value">
                            {agent.reasons && agent.reasons[0] == "Preferred Agent" ? 'YES' : 'NO'}
                        </div>
                    </div>

                    <hr/>
                    <div className="agent-details-cell">
                        <div className="agent-key">
                            RCA
                        </div>
                        <div className="agent-value">
                            {agent.is_rca ? 'YES' : 'NO'}
                        </div>
                    </div>

                    <hr/>
                    <div className="agent-details-cell">
                        <div className="agent-key">
                            Registered User
                        </div>
                        <div className="agent-value">
                            {agent.unregistered_agent === "N" ? 'YES' : 'NO'}
                        </div>
                    </div>

                    <hr/>
                    <div className="agent-details-cell">
                        <div className="agent-key">
                            FIRM
                        </div>
                        <div className="agent-value">
                            {agent.agency_name ? agent.agency_name : 'Not Available'}
                            <div className="tooltip">
                                {agent.agency_name}
                            </div>
                        </div>
                    </div>

                    <hr/>
                    <div className="agent-details-cell">
                        <div className="agent-key">
                            Expertise
                        </div>
                        <div className="agent-value">
                            {agent.specializations ? agent.specializations.join(', ') : 'Not Available'}
                            {agent.specializations &&
                            <div className="tooltip">
                                {agent.specializations.join(', ')}
                            </div>
                            }
                        </div>
                    </div>

                    <hr/>
                    <div className="agent-details-cell">

                        <div className="agent-key">
                            Certifications
                        </div>
                        <div className="agent-value">
                            {agent.certifications ? agent.certifications.join(', ') : 'Not Available'}
                            {agent.certifications &&
                            <div className="tooltip">
                                {agent.certifications.join(', ')}
                            </div>
                            }
                        </div>
                    </div>

                    <hr/>
                    <div className="agent-details-cell">

                        <div className="agent-key">
                            Cities Served
                        </div>
                        <div className="agent-value">
                            {citiesServed && citiesServed.length > 0 ? citiesServed.join(', ') : ''}
                            {citiesServed && citiesServed.length > 0 &&
                            <div className="tooltip">
                                {citiesServed.join(', ')}
                            </div>
                            }
                        </div>
                    </div>

                    <hr/>
                    <div className="agent-details-cell">
                        <div className="agent-key">
                            Neighborhoods Served
                        </div>
                        <div className="agent-value">
                            {agent.neighbourhoodsServed ? agent.neighbourhoodsServed.join(', ') : 'Not Available'}
                            {agent.neighbourhoodsServed &&
                            <div className="tooltip">
                                {agent.neighbourhoodsServed.join(', ')}
                            </div>
                            }
                        </div>
                    </div>

                    <hr/>
                    <div className="agent-details-cell">
                        <div className="agent-key">
                            Languages Spoken
                        </div>
                        <div className="agent-value">
                            {agent.languages ? agent.languages.join(', ') : 'Not Available'}
                            {agent.languages &&
                            <div className="tooltip">
                                {agent.languages.join(', ')}
                            </div>
                            }
                        </div>
                    </div>

                    <hr/>
                    <div className="agent-details-cell">
                        <div className="agent-key">
                            Joining Date
                        </div>
                        <div className="agent-value">
                            {agent.signup_date ? moment(agent.signup_date * 1000).utc().format('MMMM Do YYYY') : 'Not Available'}
                        </div>
                    </div>

                    <hr/>
                    <div className="agent-details-cell">
                        <div className="agent-key">
                            Contract Signed Date
                        </div>
                        <div className="agent-value">
                            {agent.agreement_signed_date ? moment(agent.agreement_signed_date * 1000).utc().format('MMMM Do YYYY') : 'Not Available'}
                        </div>
                    </div>

                    <hr/>
                    <div className="agent-details-cell">
                        <div className="agent-key">
                            Services offered
                        </div>
                        <div className="agent-value">
                            {agent.preferences ? agent.preferences : 'Not Available'}
                            {agent.preferences &&
                            <div className="tooltip">
                                {agent.preferences}
                            </div>
                            }
                        </div>
                    </div>

                    <hr/>
                    <div className="agent-details-cell">
                        <div className="agent-key">
                            Deal Volume and Listings
                        </div>
                        <div className="agent-value">
                            <Popup
                                trigger={
                                    <button className="med-btn-agent-comparison">View</button>
                                }
                                modal
                                closeOnDocumentClick={false}
                                closeOnEscape={false}
                                contentStyle={popupstyle}
                            >
                                {close => (
                                    <AgentProfile agent={agent} close={close}/>
                                )}
                            </Popup>
                        </div>
                    </div>

                </AgentDivContent>
            </div>
        );
    }

}

export default AgentBox;