export const TRANSACTION_TYPE = [
  {
    id: 1,
    label: "Standard Residential",
    value: "residential_1_4",
  },
  {
    id: 2,
    label: "Residential Income Property",
    value: "residential_inc",
  },
  {
    id: 3,
    label: "Vacant Land",
    value: "vacant_land",
  },
  {
    id: 4,
    label: "Manufactured/Mobile Home",
    value: "manufactured_mo",
  },
  {
    id: 5,
    label: "New Construction",
    value: "new_construction",
  },
  {
    id: 6,
    label: "Lease",
    value: "lease",
  },
  {
    id: 7,
    label: "Short Sales",
    value: "short_sales",
  },
  {
    id: 8,
    label: "Relocation",
    value: "relocation",
  },
  {
    id: 9,
    label: "Referral Agreement",
    value: "referral_agreem",
  },
  {
    id: 10,
    label: "Other",
    value: "other",
  },
]

export const COMMISSION_TYPE = [
  { label: "Percentage", value: "percentage" },
  { label: "Fixed", value: "fixed_fee" },
  { label: "Both", value: "both" },
]

export const US_OBJ = {
  lat: 37.09024,
  lng: -95.712891,
  south_west: {
    lat: 25.82,
    lng: -124.39,
  },
  north_east: {
    lat: 49.38,
    lng: -66.94,
  },
  address: "United States",
  country: {
    long_name: "United States",
    short_name: "US",
    types: [
      "country",
      "political",
    ],
  },
}

export const VIEW_LIST_IGNORE = [
  "id",
  "agent_id",
  "row_created_at",
  "row_updated_at",
  "clients",
  "client_email",
  "client_name",
  "completed_steps",
  "contract_on",
]

export const DEAL_STATUS = [
  { label: "Incomplete", value: "incomplete" },
  { label: "Submitted", value: "submitted" },
  { label: "Audit Verified", value: "audit_verified" },
  { label: "Commission Verified", value: "commission_verified" },
  { label: "Demand Pending", value: "demand_pending" },
  { label: "Demand Completed", value: "demand_completed" },
  { label: "Closed", value: "closed" },
  { label: "Payment Received", value: "payment_received" },
  { label: "Archived", value: "archived" },
]

export const FILE_TYPES_LISTING = [
  { label: "New Listing", value: "new_listing" },
  { label: "Listing w/Accepted Offer", value: "listing_with_accepted_offer" },
  { label: "Pending Contract", value: "pending_contract" },
  { label: "Dual", value: "dual" },
  { label: "Lease Listing", value: "lease_listing" },
  { label: "Pending Lease", value: "pending_lease" },
  { label: "External Referral Agreement", value: "external_referral_agreement" },
]

export const FILE_TYPES = [
  // { label: "New Listing", value: "new_listing" },
  { label: "Listing w/Accepted Offer", value: "listing_with_accepted_offer" },
  { label: "Pending Contract", value: "pending_contract" },
  { label: "Dual", value: "dual" },
  { label: "Lease Listing", value: "lease_listing" },
  { label: "Pending Lease", value: "pending_lease" },
  { label: "External Referral Agreement", value: "external_referral_agreement" },
]

export const RADIUS_TRANSACTION_TYPE = [
  { label: "Flat Fee", value: "flat_fee" },
  { label: "Commission Fee", value: "commission_fee" },
  { label: "Accelerator Fee", value: "accelerator_fee" },
  { label: "Outbound Referral", value: "outbound_referral" },
  { label: "Inbound Referral", value: "inbound_referral" },
  { label: "Commission Split", value: "commission_split" }
]
