import {v1 as uuid } from "uuid"

// Comp
import Header from "./Components/Header"
import GreenHeader from "./Components/GreenHeader"
import Footer from "./Components/Footer"
import NewHeader from "./Components/NewHeader"

// Main
import PropertyStatus from "./MainBody/PropertyStatus"
import UpgradeRequst from "./MainBody/UpgradeRequest"
import NewLead from "./MainBody/NewLead"
import NewLead2 from "./MainBody/NewLead2"
import NewFollowerSingle from "./MainBody/FollowSingle"
import MultipleFollowers from "./MainBody/MultipleFollowers"
import AssistEmailQualified from "./MainBody/AssistEmailQualified"

export const COMPONENT_LIST = [
  {
    label: "Header",
    snapshot: null,
    Component: Header,
    id: uuid(),
  },
  {
    label: "New Header",
    snapshot: null,
    Component: NewHeader,
    id: uuid(),
  },
  {
    label: "Green Header",
    snapshot: null,
    Component: GreenHeader,
    id: uuid(),
  },
  {
    label: "Footer",
    snapshot: null,
    Component: Footer,
    id: uuid(),
  },
]

export const MAIN_LIST = [
  {
    label: "Property Status",
    snapshot: null,
    Component: PropertyStatus,
    id: uuid(),
  },
  {
    label: "Upgrade Request",
    snapshot: null,
    Component: UpgradeRequst,
    id: uuid(),
  },
  {
    label: "New Lead",
    snapshot: null,
    Component: NewLead,
    id: uuid(),
  },
  {
    label: "New Lead 2",
    snapshot: null,
    Component: NewLead2,
    id: uuid(),
  },
  {
    label: "Single Follower",
    snapshot: null,
    Component: NewFollowerSingle,
    id: uuid(),
  },
  {
    label: "Multiple Followers",
    snapshot: null,
    Component: MultipleFollowers,
    id: uuid(),
  },
  {
    label: "Assist Email Qualified",
    snapshot: null,
    Component: AssistEmailQualified,
    id: uuid(),
  },
]
