import axios from "axios"

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/v1.0`

export const getDealBasedOnIDAPI = (step, id, agentId) => axios
  .get(`${baseUrl}/offer-cover/${id}/${step}${agentId ? `?agentId=${agentId}` : ""}`)

export const createOfferAPI = (payload, id) => axios
  .post(`${baseUrl}/offer-cover${id ? `?agent_id=${id}` : ""}`, payload)

export const updateOfferAPI = (payload, dealId, id) => axios
  .put(`${baseUrl}/offer-cover/${dealId}${id ? `?agent_id=${id}` : ""}`, payload)

export const getAllOffersAPI = (skip, limit, query) => axios
  .get(`${baseUrl}/offers?skip=${skip}&limit=${limit}${query || ""}`)

export const updateOfferDealStatusAPI = (payload, agentId) => axios
  .post(`${baseUrl}/offer-status-update?agent_id=${agentId}`, payload)

export const updateVendorAPI = (id, payload, agentId) => axios
  .put(`${baseUrl}/offer-cover/vendor/${id}${agentId ? `?agent_id=${agentId}` : ""}`, payload)

export const updateLenderAPI = (id, payload, agentId) => axios
  .put(`${baseUrl}/offer-cover/lender/${id}${agentId ? `?agent_id=${agentId}` : ""}`, payload)

export const uploadImagesForPropertyAPI = payload => axios
  .post(`${baseUrl}/brokerage-offer-images`, payload)

export const getContractPayloadAPI = id => axios
  .get(`${baseUrl}/brokerage-deals?offerId=${id}`)

export const createContractAPI = (id, payload) => axios
  .post(`${baseUrl}/brokerage-deals-product?offerId=${id}`, payload)
