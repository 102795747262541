import React, { Component } from 'react'
import styled from "styled-components"
import fetch from 'isomorphic-fetch'
import bugsnag from 'bugsnag-js'
import * as HTTP_CODES from 'http-status-codes'
import { Multiselect } from 'multiselect-react-dropdown'
import ThreeDotLoader from "./ThreeDotLoader"
import InfiniteScroll from 'react-infinite-scroller'
import _, {throttle} from 'lodash'
import moment from 'moment'
import {NotifyBugsnagError, ShowErrorToast, ShowSuccessToast} from "../lib/ErrorUtils";
import {Fetch} from "../lib/NetworkUtils";
import {hideMessage, submitLead, updateReferral} from "./API/Referral/ReferralApi";
import {DefaultLoader} from "./UI/Loader/Loader";
import {CONTACTING} from "../lib/Constants";
import {getPreferencesDivOLD, getTextInstructionsDiv} from "./Agent/SenderAgentPrefOLD";
import DatePicker from "react-datepicker/es";
import AutoSuggest from "./UI/AutoSuggest/AutoSuggest";
import {searchAgent} from "./API/AgentApi";
import Modal from "./../dumbComponents/common/UI/Modal"
import MessagerSidebar from "components/MessangerTab"
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;
const originalKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagClient = bugsnag(originalKey);

const ResetWrap = styled.div`
    margin-top: 40px;

    button {
        margin-top: 20px;
        border: none;
        background: #24BDBB;
        color: #FFF;
        padding: 5px 10px;
        border-radius: 4px;
    }
`

const ResetButton = styled.button`
    border: none;
    background: #E53C50;
    color: #FFF;
    padding: 5px 10px;
    border-radius: 4px;
`

const referredClientStatusOptions = [
    {title: "Submitted", value: "Submitted", color: "#24BDBB"},
    {title: "Contacting", value: "Contacting", color: "#FAB615"},
    {title: "Follow up Scheduled", value: "Contacted", color: "#FAB615"},
    {title: "Qualified", value: "Qualified", color: "#13A1E1"},
    {title: "Search Paused", value: "Search Paused", color: "#781D28"},
    {title: "Comparing Agents", value: "Comparing Agents", color: "#CA6121"},
    {title: "Disqualified", value: "Disqualified", color: "#E53C50"},
    {title: "Accepted", value: "Leads", color: "#24BDBB"},
    {title: "Active", value: "Active", color: "#B628D3"},
    {title: "In Contract", value: "In Contract", color: "#12725C"},
    {title: "Closed", value: "Closed", color: "#2857D3"},
    {title: "Archived", value: "Archived", color: "#400A0A"},
    {title: "On Hold", value: "On Hold", color: "#E53C09"},
];

const sortOptions = [
    {title: "Most Positive", value: "positiveScore"},
    {title: "Most recent", value: "created_at"},
    {title: "Most responsive", value: "replyCount"},
];

const validPhoneOptions = [
    {title: "Valid phones", value: 1},
    {title: "Invalid phones", value: 0},
    {title: "Both Valid & Invalid", value: ""},
];

const isaOptions = [
    {title: "ISA", value: true, color: "#24BDBB"},
    {title: "Referral", value: false, color: "#FAB615"},
];

const sourceOptions = [
    {title: "Integration", value: "integration", color: "#FAB615"},
    {title: "Bulk", value: "bulk", color: "#24BDBB"},
];

const priotizedAgentOptions = [
    {title: "Robin Pool", value: 1, color: "#FAB615"},
    {title: "Sammi B Pool", value: 2, color: "#24BDBB"},
    {title: "Sara Pool", value: 3, color: "#FAB615"},
    {title: "Jorge Rivera Pool", value: 4, color: "#FAB615"},
    {title: "Chloe Pool", value: 5, color: "#FAB615"},
    {title: "Dev Pool", value: 8, color: "#FAB615"},
    {title: "QA Pool", value: 9, color: "#FAB615"},
    {title: "SMS Pool 1", value: 10, color: "#FAB615"},
    {title: "SMS Pool 2", value: 11, color: "#FAB615"},
    {title: "SMS Pool 3", value: 12, color: "#FAB615"},
    {title: "SMS Pool 4", value: 13, color: "#FAB615"},
];

const Td = styled.td`
   color: black;
   ${props => props.isMortgage && `
   color: red;
   `}
`

class Messages extends Component {
    state = {
        isShowResetModal: false,
        selectionResetOptions: [],
        loading: true,
        messages: [],
        disqualifiedOrPrioritizedMessages:{},
        referralIdToReferralStatusMap:{},
        fetching: false,
        callingApi: false,
        limit: 10,
        skip: 0,
        accountsMap: null,
        accounts: [],
        accountsMapWithTwilioClientName: null,
        showPrioritizedOnly: false,
        selectedConsultantId: this.props.user.agent_id,
        showMore: true,
        showAllStatus: false,
        selectedStatus: null,
        is_isa: null,
        referral_source: null,
        pool_id: null,
        sender_id: null,
        hiddenMessages:{},
        is_hidden_in_sms_dashboard: false,
        is_active_conversations_set: false,
        unresponsive: false,
        lastSentAt:null,
        startDate: null,
        endDate: null,
        startDateinMS: null,
        endDateinMS: null,
        disqualificationReasons: [],
        total:0,
        selectedPrioritizedAgents: [],
        sort : "positiveScore",
        valid_phone: 1,
    };

    toggleResetModal = (show) => {
        this.setState({
            isShowResetModal: show,
        })
    }

    loadAccounts = async () => {
        var role = "Admin, Jr Consultant, Sr Consultant, Sr Account Manager, Jr Account Manager";
        let response = await Fetch(baseUrl + '/v1.0/consultants/all?roles=' + role).then(respJson => {
            this.setState({
                accounts: respJson.response.accounts,
                accountsMap: _.groupBy((respJson.response.accounts || []),'agent_id'),
                accountsMapWithTwilioClientName: _.groupBy((respJson.response.accounts || []),'twilio_client_name'),
            });
        }).catch(e => {
            ShowErrorToast(e);
            this.setState({ fetching: false });
        });
    };

    isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    componentDidMount() {
        this.loadAccounts().then(res => {
            console.log(this.state.accountsMap);
            this.getMessages();
            this.loadDisqualificationReasons();
        });
    }

    handleScroll = async () => {
        if(this.state.showMore && !this.state.loading) {
            let skip = this.state.skip;
            this.setState({skip: skip + this.state.limit});
            this.getMessages();
        }
    };

    handleSortChange = (e) => {
        const value = e.target.value;
        this.setState({sort: value});
    };

    handleValidPhoneChange = (e) => {
        const value = e.target.value;
        this.setState({valid_phone: value});
    };

    handleStartDateChange(date) {
        this.setState({
            startDateinMS: moment(date).format('X') * 1000,
            startDate: date,
        });
    }

    handleEndDateChange(date) {
        this.setState({
            endDateinMS: moment(date).format('X') * 1000,
            endDate: date,
        });
    }

    handleSenderAgentChange(data) {
        if(!data) {
            this.setState({sender_id: null})
        }
    }

    getAdminRole() {
        if(!this.state.selectedConsultantId || !this.state.accountsMap) {
            return null;
        }
        let user = _.get(this.state.accountsMap, `${this.state.selectedConsultantId}[0]`)
        if (!user) {
            return null;
        }
        let role = user.role;
        if (role === "Sr Consultant") {
            return "AM";
        } else if (role === "Jr Consultant") {
            return "SC";
        } else {
            return null;
        }
    }

    searchAgent = async (currentSearchText) => {
        console.log(currentSearchText)
        if(!currentSearchText || currentSearchText.length < 3) {
            return [];
        }
        try {
            return await searchAgent(currentSearchText).then(res => {
                return res.response.agents.map((agent) => {
                    let cli = {};
                    cli.name = agent.firstname + ' ' + agent.lastname;
                    cli.firstname = agent.firstname;
                    cli.lastname = agent.lastname;
                    cli.agent_image = agent.agent_image;
                    cli.email = agent.email;
                    cli.id = agent.id;
                    return cli;
                });
            });
        }
        catch(e) {
            console.log(e);
            NotifyBugsnagError(e);
        }
    };

    getSuggestionValue = (suggestion) => {
        console.log(suggestion);
        this.setState({sender_id: suggestion.id });
        return suggestion.name ? suggestion.name : suggestion.email;
    };

    loadDisqualificationReasons = async () => {
        try{
            let sol = await fetch(baseUrl + '/v1.0/common/referral_disqualified_reasons?sort=id', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'internal-id': token
                },
            });
            sol = await sol.json();
            let disqualificationReasons = _.groupBy(sol.response.items, 'disqualify_reason');
            this.setState({disqualificationReasons  :disqualificationReasons});
        }
        catch(e){
            bugsnagClient.notify(e);
        }
    };


    getMessages = async () => {
        try {
            this.setState({ fetching: true, loading: true });
            let url = baseUrl + '/v3.0/gabby/thread?agentId=484725&limit=' + this.state.limit + '&nonDisqualifiedLeads=true&skip=' + this.state.skip + '&smsResponse=true';
            let role = this.getAdminRole();
            if (this.state.showAllStatus) {
                role = "ALL";
            }
            if(role) {
                url += '&adminRole=' + role;
            }
            if (this.state.showPrioritizedOnly){
                url += '&prioritized=true';
            }
            if (this.state.selectedConsultantId){
                url += '&adminAgentId=' + this.state.selectedConsultantId;
            }
            if (this.state.selectedStatus){
                url += "&referralStatuses='" + this.state.selectedStatus + "'";
            }
            if (this.state.is_isa){
                url += "&is_isa=" + this.state.is_isa;
            }
            if (this.state.referral_source){
                url += "&referral_source=" + this.state.referral_source;
            }
            if (this.state.selectedPrioritizedAgents && this.state.selectedPrioritizedAgents.length > 0){
                url += "&pool_ids=" + this.state.selectedPrioritizedAgents.map(x => x.value).join();
            }
            if (this.state.sender_id){
                url += "&sender_id=" + this.state.sender_id;
            }
            if(this.state.lastSentAt) {
                url += "&sentAt=" + this.state.lastSentAt;
            }
            if (this.state.endDate) {
                url += '&end_date=' + this.state.endDateinMS;
            }
            if (this.state.startDate) {
                url += '&start_date='+ this.state.startDateinMS;
            }
            url += "&is_hidden_in_sms_dashboard=" + this.state.is_hidden_in_sms_dashboard;
            url += "&is_active_conversations_set=" + this.state.is_active_conversations_set;
            url += "&unresponsive=" + this.state.unresponsive;
            url += "&sort=" + this.state.sort;
            url += "&valid_phone=" + this.state.valid_phone;

            await Fetch(url).then(respJson => {
                let total = this.state.total;
                if (this.state.skip === 0) {
                    total = respJson.response.total;
                }

                if (respJson.response.thread && respJson.response.thread.length > 0) {
                    let temp = this.state.messages;
                    let showMore = true;
                    temp = temp.concat(respJson.response.thread);
                    let referralIdToReferralStatusMap = {};
                    let sentAt = this.state.lastSentAt;
                    _.each(temp, (message) => {
                        referralIdToReferralStatusMap[message.referral.id] = message.referral.referred_client_status;
                        if (!sentAt || message.sentAt < sentAt) {
                            sentAt = message.sentAt;
                        }
                    });
                    if (respJson.response.thread.length < 10) {
                        showMore = false;
                    }
                    this.setState({messages: temp, fetching: false, showMore : showMore,
                        loading: false, referralIdToReferralStatusMap: referralIdToReferralStatusMap,
                        lastSentAt: sentAt, total: total,
                    })
                } else {
                    this.setState({fetching: false, showMore: false, loading: false, total: total});
                }
            }).catch(e => {
                ShowErrorToast(e);
                this.setState({ fetching: false, loading: false });
            });
        }
        catch (e) {
            console.log(e)
            this.setState({ fetching: false, loading: false });
        }
    };
    setPrioritizeState = (messageId, clientName) => {
        let temp = this.state.disqualifiedOrPrioritizedMessages;
        temp[messageId] = {id:messageId, message:"Prioritized by " +  clientName};
        this.setState({disqualifiedOrPrioritizedMessages:temp, callingApi: false})

    };

    setErrorMessage = (messageId, message) => {
        let temp = this.state.disqualifiedOrPrioritizedMessages;
        temp[messageId] = {
            id: messageId,
            message:message
        };
        this.setState({disqualifiedOrPrioritizedMessages: temp, callingApi: false})
    };

    hideMessage = (messageId) => {
        hideMessage(messageId).then(res => {
            let hiddenMessages = this.state.hiddenMessages;
            hiddenMessages[messageId] = true;
            this.setState({hiddenMessages: hiddenMessages});
            ShowSuccessToast("Updated successfully");
        }).catch(err => {
            ShowErrorToast(err);
        });
    };


    prioritize =  (messageId, clientId) => {

            this.setState({callingApi:true});

            let url = baseUrl + '/v1.0/gabby/caller/prioritize';
            //let url = "https://staging.agentdesks.com/v1.0/gabby/caller/prioritize"

            //let url = "https://jsonplaceholder.typicode.com/todos/1"
            let body  = {
                "message_id" : messageId,
                "receiver_client_id" : clientId,
                "prioritized" : true,
                "admin_agent_id" : this.props.user.agent_id,
                "twilio_client_name": this.props.user.twilio_client_name,
            };
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'internal-id': token,
                    'X-User-Agent': 'agent-php',
                },
                body: JSON.stringify(body)
            }).then(res => {
                    let statusCode = res.status;
                    if(statusCode === HTTP_CODES.OK || statusCode === HTTP_CODES.ACCEPTED) {
                        ShowSuccessToast();
                        this.setPrioritizeState(messageId, this.props.user.name)
                    }
                    else
                        res.json().then(data => {
                            if(statusCode === HTTP_CODES.BAD_REQUEST) {
                                this.setErrorMessage(messageId, data.response + ". Please contact Deepak with a screenshot")
                            }else if(statusCode === HTTP_CODES.CONFLICT){
                                this.setErrorMessage(messageId, "Already prioritized by " + this.state.accountsMap[data.response.admin_agent_id][0].name)
                            }else if(statusCode === HTTP_CODES.NOT_ACCEPTABLE){
                                this.setErrorMessage(messageId, "Already disqualified")
                            }else{
                                this.setErrorMessage(messageId, "Error in prioritizing. Please contact Deepak with a screenshot")
                            }
                        })
                }).catch(e => {
                ShowErrorToast(e);
                this.setState({callingApi: false});
                bugsnagClient.notify(e);
            })
    };

    filter = () => {
        this.setState({skip:0, total:0, messages:[], lastSentAt: null, hiddenMessages:{}},() => this.getMessages());
    };

    togglePrioritizedOnly = () => {
        this.setState({showPrioritizedOnly: !!!this.state.showPrioritizedOnly, skip:0, messages:[]},() => this.getMessages());
    };

    disqualify =  (messageId, clientId, text, reason) => {

        this.setState({callingApi:true});
        let body = {
            "source": "chat",
            "consultant_agent_id": this.props.user.agent_id,
            "text": text,
            "clickSource": 'blink-messages',
            "disqualify_reason_id": this.state.disqualificationReasons[reason][0].id,
            "disqualify_message": this.state.disqualificationReasons[reason][0].disqualify_reason,
        };
        let url = baseUrl + '/v1.0/disqualifyReferralFromMessage/' + clientId;
        let options = {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'internal-id': token,
                'X-User-Agent': 'agent-php'
            },
            body: JSON.stringify(body)
        };

        Fetch(url, options)
            .then(res => {
                let temp = this.state.disqualifiedOrPrioritizedMessages;
                temp[messageId] = {
                    id: messageId,
                    message: "Disqualified by " + this.props.user.name,
                };
                this.setState({disqualifiedOrPrioritizedMessages: temp, callingApi: false});
                ShowSuccessToast();
            }).catch(e => {
                ShowErrorToast(e);
                this.setState({callingApi: false})
            })
    };

    handleConsultantChange = (e) =>{
        const value = e.target.value;
        this.setState({selectedConsultantId: value});
    };

    handleStatusChange = (e) =>{
        const value = e.target.value;
        this.setState({selectedStatus: value});
    };

    handleIsaChange = (e) =>{
        const value = e.target.value;
        this.setState({is_isa: value});
    };

    handleSourceChange = (e) => {
        const value = e.target.value;
        this.setState({referral_source: value});
    };

    handlePrioritizedAgentChange = (e) =>{
        const value = e.target.value;
        this.setState({pool_id: value});
    };

    showHiddenMessages = () => {
        const value = this.state.is_hidden_in_sms_dashboard;
        this.setState({is_hidden_in_sms_dashboard: !value});
    }

    showActiveConversations = () => {
        const value = this.state.is_active_conversations_set;
        this.setState({is_active_conversations_set: !value, selectedStatus: CONTACTING});
    }

    unresponsiveFilter = () => {
        const value = this.state.unresponsive;
        this.setState({unresponsive: !value});
    }

    handleResetOptionChange = (item) => {
        const { selectionResetOptions } = this.state
        let newArray = []
        const indexOfItem = selectionResetOptions.indexOf(item.value)
        if (indexOfItem === -1) {
            selectionResetOptions.push(item.value)
        } else {
            selectionResetOptions.splice(indexOfItem, 1)
        }
        this.setState({
            selectionResetOptions,
        })
    }

    handleResetSubmit = async () => {
        try{
            const { selectionResetOptions } = this.state
            let sol = await fetch(baseUrl + '/v1.0/reset-sms-prioritised-agents', {
                method: 'POST',
                body: JSON.stringify({
                    pool_ids: selectionResetOptions
                  }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'internal-id': token
                },
            });
            sol = await sol.json();
            this.toggleResetModal(false)
            console.log(sol)
        }
        catch(e){
            bugsnagClient.notify(e);
            this.toggleResetModal(false)
        }
    }

    handleReferralStatusChange = (referralId, e) => {
        console.log(referralId);
        console.log(e);
        let value = e.target.value;
        let body = {
            "consultant_agent_id" : this.props.user.agent_id,
            "referred_client_status": value
        };
        if(value === 'Qualified') {
            body['qualification_channel'] = 'sms';
        }
        updateReferral(referralId, body).then(res => {
            ShowSuccessToast("Updated successfully");
            let referralIdToReferralStatusMap = this.state.referralIdToReferralStatusMap;
            referralIdToReferralStatusMap[referralId] = value;
            this.setState({referralIdToReferralStatusMap: referralIdToReferralStatusMap})
        }).catch(err => {
            ShowErrorToast(err);
        });
    };

    toggleShowAllStatus = () =>{
        this.setState({showAllStatus: !!!this.state.showAllStatus, messages:[]},() => this.getMessages());
    };

    handleSelectPrioritizedAgent = (items) => {
        this.setState({
            selectedPrioritizedAgents: items
        })
    }
    handleRemovePrioritizedAgent = (items) => {
        this.setState({
            selectedPrioritizedAgents: items
        })
    }

    toggleMessangerSidebar = ({
        isOpen,
        item,
        messageTabURL,
    }) => {
        console.log(item)
        this.setState({
            activeMessage: item,
            isMessageSidebarOpen: isOpen,
            messageTabURL,
        })
    }

    render() {

            const self = this;
            let total = this.state.total;
            const { isMessageSidebarOpen, activeMessage, messageTabURL } = this.state
            let messagesDiv = <tr><td>No messages</td></tr>;

            const accountsDiv = this.state.accounts.map(account =>
                <option value={account.agent_id} >{account.name}</option>
            );
            accountsDiv.unshift(<option value="" >{'All'}</option>);

            const statusDiv = referredClientStatusOptions.map(referredClientStatusOption =>
                <option value={referredClientStatusOption.value} >{referredClientStatusOption.title}</option>
            );
            statusDiv.unshift(<option value="" >{'Select status'}</option>);

            const sortDiv = sortOptions.map(sortOption =>
                <option value={sortOption.value} >{sortOption.title}</option>
            );
            sortDiv.unshift(<option value="" >{'Sort By'}</option>);

            const validPhoneDiv = validPhoneOptions.map(validPhoneOption =>
                <option value={validPhoneOption.value} >{validPhoneOption.title}</option>
            );
            validPhoneDiv.unshift(<option value="" >{'Phone Filter'}</option>);

            const isaDiv = isaOptions.map(isaOption =>
                <option value={isaOption.value} >{isaOption.title}</option>
            );
            isaDiv.unshift(<option value="" >{'ISA and Referrals'}</option>);

            const sourceDiv = sourceOptions.map(sourceOption =>
                <option value={sourceOption.value} >{sourceOption.title}</option>
            );
            sourceDiv.unshift(<option value="" >{'All Lead sources'}</option>);

            const prioritizedAgentDiv = priotizedAgentOptions.map(priotizedAgentOption =>
                <option value={priotizedAgentOption.value} >{priotizedAgentOption.title}</option>
            );
            prioritizedAgentDiv.unshift(<option value="" >{'Select Prioritized Agents'}</option>);

            let callingApi = this.state.callingApi;
            let messages = this.state.messages;
            let disqualifiedOrPrioritizedMessagesMap = this.state.disqualifiedOrPrioritizedMessages;

            // messages = _.reject(messages, (message) => {
            //     let text = "";
            //     if (message.message && message.message.length > 0) {
            //         text = message.message[0].message;
            //     }
            //     return self.isJsonString(text);
            // });

            if (messages.length > 0) {
                messagesDiv = messages.map((message) => {

                    if (!message.message) {
                        return null;
                    }
                    let clientName = "";
                    let redirectUrl = process.env.REACT_APP_BLINK_BASE_URL;
                    let messageUrl = "https://admin1.agentdesks.com/#/submitted-referrals-logs?client_id=";
                    let clientId = null;
                    let info = JSON.parse(message.message[0].additionalInfo);
                    let text = message.message[0].message;
                    let messageId = message.message[0].id;
                    let sentTimeStamp = message.sentAt;
                    const DisqualifyReasonKeys = _.keys(this.state.disqualificationReasons);
                    const DisqualifyReasonsDiv = DisqualifyReasonKeys.map((reason) => <a key={reason} onClick={()=>{this.disqualify(messageId, clientId, text, reason)}}>{reason}</a>);
                    let preferencesDiv = null;
                    let textInstructions = null;
                    if (message && message.user_referral_settings) {
                        preferencesDiv = getPreferencesDivOLD(message.user_referral_settings);
                        textInstructions = getTextInstructionsDiv( message.user_referral_settings);
                    }

                    if (info.prioritized && this.state.accountsMap && this.state.accountsMap[info.admin_agent_id]) {
                        disqualifiedOrPrioritizedMessagesMap[messageId] = {
                            id: messageId,
                            message: "Prioritized by " + this.state.accountsMap[info.admin_agent_id][0].name
                        }
                    }

                    if (message.participant && message.participant.length > 0) {
                        let participants = _.reject(message.participant, participant => participant.type !== 'client');
                        if (participants.length > 0) {
                            clientName = participants[0].name;
                            if (clientName != "undefined") {
                                clientName = clientName.trim()
                            }
                            if (clientName === "" || clientName === "undefined" || clientName.length === 0) {
                                clientName = participants[0].email;
                            }
                            if (clientName != "undefined") {
                                clientName = clientName.trim()
                            }
                            if (clientName === "" || clientName === "undefined" || clientName.length === 0) {
                                clientName = participants[0].phone;
                            }
                            if (clientName != "undefined") {
                                clientName = clientName.trim()
                            }
                            clientId = participants[0].id;
                            if (clientId) {
                                messageUrl += clientId;
                            }
                            redirectUrl += '?messageDashboardClientId=' + clientId + "&isPageInactive=true";
                        }
                    }

                    //last call
                    let lastCallStatus = "";
                    let lastCallBy = "";
                    let lastSms = "";
                    let lastSmsBy = "";
                    let callJson = {};

                    try {
                        if (message.lastCallFromAdmin && message.lastCallFromAdmin.message) {
                            callJson = JSON.parse(message.lastCallFromAdmin.message);
                            if (callJson.call_status) {
                                if ("no-answer-machine" === callJson.call_status) {
                                    callJson.call_status = "Voicemail";
                                }
                                lastCallStatus = "Last Call Status: " + callJson.call_status;
                            }
                            if (callJson.twilio_client_name && this.state.accountsMapWithTwilioClientName && this.state.accountsMapWithTwilioClientName[callJson.twilio_client_name]) {
                                lastCallBy = "Last Called by: " +
                                    this.state.accountsMapWithTwilioClientName[callJson.twilio_client_name][0].name + " at "
                                    + moment(message.lastCallFromAdmin.sentAt).format('MMM Do YYYY, h:mm a')
                            } else {
                                lastCallBy = "Last Called by: " + callJson.twilio_client_name
                            }
                        }
                    } catch (e) {
                        bugsnagClient.notify(e);
                    }

                    //last sms

                    try {
                        if (message.lastSmsFromAdmin) {
                            lastSms = message.lastSmsFromAdmin.message;
                            if (message.lastSmsFromAdmin.adminAgentId && this.state.accountsMap && this.state.accountsMap[message.lastSmsFromAdmin.adminAgentId]) {
                                lastSmsBy = "Last Sms by : " + this.state.accountsMap[message.lastSmsFromAdmin.adminAgentId][0].name + " at "
                                    + moment(message.lastSmsFromAdmin.sentAt).format('MMM Do YYYY, h:mm a')
                            } else {
                                lastSmsBy = "Auto Sms"
                            }
                        }
                    } catch (e) {
                        bugsnagClient.notify(e);
                    }

                    //last sms by client
                    let lastSmsFromClientId = null;
                    try {
                        if (message.lastSmsFromClient) {
                            text = message.lastSmsFromClient.message;
                            lastSmsFromClientId =  message.lastSmsFromClient.id;
                        }
                    } catch (e) {
                        bugsnagClient.notify(e);
                    }

                    let referral = message.referral ? message.referral : {};
                    let clientType = referral ? referral.type : "" ;
                    return <tr>
                        <td>{moment(sentTimeStamp).format('MMM Do YYYY, h:mm a')}</td>
                        <td><a href={redirectUrl} target="_blank">{clientName}</a></td>
                        <Td 
                        isMortgage={referral.is_mortgage}>{referral.is_mortgage ? "Mortgage" : clientType}
                        </Td>
                        <td>{preferencesDiv}</td>
                        <td>{textInstructions}</td>
                        <td> {clientType === "seller" || clientType === "Seller" ?
                            <span>
                                Property Name : {referral.prop_name}<br/>
                                Locality : {referral.prop_neighbourhood}<br/>
                                City : {referral.prop_city}<br/>
                                State : {referral.prop_state}<br/>
                                Address : {referral.prop_address}<br/>
                            </span> :
                            <span>
                                Locality : {referral.req_neighbourhood}<br/>
                                City : {referral.req_city}<br/>
                                State : {referral.req_state}<br/>
                            </span>
                        }

                        </td>
                        <td>{text}<br/><br/>
                        <button onClick={() => { this.toggleMessangerSidebar({ isOpen: true, item: message, messageTabURL: messageUrl }) }}>
                            Open Panel
                        </button>
                        <a href={messageUrl} target="_blank">Open Admin</a>
                        </td>


                        <td>
                            {lastCallBy}<br/><br/>
                            {callJson && callJson.recording_url &&
                            <audio controls>
                                <source src={callJson.recording_url} type="audio/ogg"/>
                            </audio>}<br/>
                            {lastCallStatus}
                        </td>

                        <td>
                            {lastSmsBy}<br/><br/>
                            {lastSms}
                        </td>

                        {this.props.user.role === "Jr Consultant" ?
                            <td>
                                {(callingApi ? <ThreeDotLoader/> : ((disqualifiedOrPrioritizedMessagesMap[messageId] ?
                                    <span>
                                {disqualifiedOrPrioritizedMessagesMap[messageId].message}
                            </span>
                                    : <span>
                                        <div className="dropdown-1">
                                            <button className="disqualify-button"
                                                    onClick={() => this.disqualify(messageId, clientId, text)}>Disqualify</button>
                                            <div className="dropdown-1-content">
                                             {DisqualifyReasonsDiv}
                                           </div>
                                         </div>
                                        <br/>
                                <br/>
                                < button className="prioritize-button"
                                         onClick={() => this.prioritize(messageId, clientId)}>Prioritize</button>
                                <br/>
                                <br/>
                                {this.state.hiddenMessages[lastSmsFromClientId] ?
                                    <span>Hidden</span> :
                                    <span><button className="prioritize-button"
                                         onClick={() => this.hideMessage(lastSmsFromClientId)}>Hide</button></span>
                                }
                                <br/>
                                <br/>
                                <select onChange={this.handleReferralStatusChange.bind(this, message.referral.id)}
                                        value={this.state.referralIdToReferralStatusMap[message.referral.id]}>
                                    {statusDiv}
                                </select>
                            </span>)))
                                }
                            </td> : <td><span>Only allowed for Sales Consultant</span></td>}

                    </tr>
                });
            }
            const { selectionResetOptions, selectedPrioritizedAgents } = this.state
            return (
                <div>
                    {this.state.isShowResetModal && (
                        <Modal
                            show toClose={() => { this.toggleResetModal(false) }}
                            title="Choose Pool"
                        >
                           <ResetWrap>
                               {priotizedAgentOptions.map(item => (
                                  <div key={item.vaue}>
                                      <input
                                        type="checkbox"
                                        onChange={() => { this.handleResetOptionChange(item) }}
                                        value={item.value}
                                        checked={selectionResetOptions.includes(item.value)}
                                        id={`priotizedAgentOptions-${item.value}`}
                                       />
                                      <label htmlFor={`priotizedAgentOptions-${item.value}`}>
                                        {item.title}
                                      </label>
                                  </div>
                               ))}
                               <button type="button" onClick={this.handleResetSubmit}>
                                Submit
                               </button>
                           </ResetWrap>
                        </Modal>
                    )}
                    <div style={{padding: '75px 50px'}}>
                        <input
                            type="checkbox"
                            checked={this.state.showPrioritizedOnly}
                            onChange={this.togglePrioritizedOnly}
                        />
                        <span className="prio-header">Show only prioritized messages.</span>&nbsp;&nbsp;&nbsp;
                        <select onChange={this.handleConsultantChange} value={this.state.selectedConsultantId}>
                            {accountsDiv}
                        </select>&nbsp;&nbsp;&nbsp;
                        <select onChange={this.handleStatusChange} value={this.state.selectedStatus}>
                            {statusDiv}
                        </select>&nbsp;&nbsp;&nbsp;
                        <select onChange={this.handleIsaChange} value={this.state.is_isa}>
                            {isaDiv}
                        </select>&nbsp;&nbsp;&nbsp;
                        <select onChange={this.handleSourceChange} value={this.state.referral_source}>
                            {sourceDiv}
                        </select>&nbsp;&nbsp;&nbsp;
                        {/* <select onChange={this.handlePrioritizedAgentChange} value={this.state.pool_id}>
                            {prioritizedAgentDiv}
                        </select>&nbsp;&nbsp;&nbsp; */}
                        <Multiselect
                            options={priotizedAgentOptions}
                            selectedValues={selectedPrioritizedAgents}
                            onSelect={this.handleSelectPrioritizedAgent}
                            onRemove={this.handleRemovePrioritizedAgent}
                            placeholder="Select Prioritized Agents"
                            displayValue="title"
                            style={{
                                multiselectContainer: {
                                    width: 400,
                                    display: "inline-flex",
                                 }
                            }}
                        />

                        <br/><br/>
                        <input
                            type="checkbox"
                            checked={this.state.is_hidden_in_sms_dashboard}
                            onChange={this.showHiddenMessages}
                        />
                        <span className="prio-header">Show hidden messages</span>&nbsp;&nbsp;&nbsp;
                        {/*<span className="prio-header">Show all statuses.</span>*/}
                        {/*<input*/}
                        {/*type="checkbox"*/}
                        {/*checked={this.state.showAllStatus}*/}
                        {/*onChange={this.toggleShowAllStatus}*/}
                        {/*/>&nbsp;&nbsp;&nbsp;*/}
                        <input
                            type="checkbox"
                            checked={this.state.is_active_conversations_set}
                            onChange={this.showActiveConversations}
                        />
                        <span className="prio-header">Active Conversations</span>&nbsp;&nbsp;&nbsp;
                        <input
                            type="checkbox"
                            checked={this.state.unresponsive}
                            onChange={this.unresponsiveFilter}
                        />
                        <span className="prio-header">Unresponsive</span>&nbsp;&nbsp;&nbsp;


                        <DatePicker
                            placeholderText={"Start"}
                            selected={this.state.startDate}
                            onChange={this.handleStartDateChange.bind(this)}
                        />&nbsp;&nbsp;&nbsp;
                        <DatePicker
                            placeholderText={"End"}
                            selected={this.state.endDate}
                            onChange={this.handleEndDateChange.bind(this)}
                        />&nbsp;&nbsp;&nbsp;
                        <select onChange={this.handleSortChange} value={this.state.sort}>
                            {sortDiv}
                        </select>&nbsp;&nbsp;&nbsp;
                        <select onChange={this.handleValidPhoneChange} value={this.state.valid_phone}>
                            {validPhoneDiv}
                        </select>&nbsp;&nbsp;&nbsp;
                        <ResetButton onClick={() => { this.toggleResetModal(true) }} type="button">Reset</ResetButton>
                        <br/><br/>
                        <div style={{ width : "300px"}}>
                            <AutoSuggest getSuggestions={this.searchAgent}
                                onChange={this.handleSenderAgentChange.bind(this)}
                                getSuggestionValue={this.getSuggestionValue}/>
                        </div>
                        <button onClick={this.filter.bind(this)}> Filter</button>

                    </div>
                    {isMessageSidebarOpen && (
                        <MessagerSidebar
                            show={isMessageSidebarOpen}
                            toggleMessagesPanel={() => { this.toggleMessangerSidebar({ isOpen: false, item: null }) }}
                            url={messageTabURL}
                        />
                    )}

                    {this.state.loading && this.state.messages.length === 0 && <DefaultLoader/>}

                    {(!this.state.loading || this.state.messages.length > 0) &&
                        <InfiniteScroll
                            initialLoad={false}
                            loadMore={throttle(() => {
                                this.handleScroll()

                            }, 4000)}
                            hasMore={this.state.showMore}
                            loader={<div className="messages-loader" key={0}><DefaultLoader/></div>}
                        >

                            <br/>
                            <span style={{fontSize:"30px",margin:'5px'}}>Total: <b>{total}</b></span>
                            <table className="messages-div">
                                <tbody>
                                <tr>
                                    <th>Date</th>
                                    <th>Client</th>
                                    <th>Type</th>
                                    <th style={{width : '200px'}}>Sender Agent Preferences</th>
                                    <th style={{width : '200px'}}>Text Instructions</th>
                                    <th>Location</th>
                                    <th>Message (Client)</th>
                                    <th>Last Call</th>
                                    <th>Message (Radius)</th>
                                    <th>Operation</th>
                                </tr>
                                {messagesDiv}
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    }
                </div>

            );
        }
}

export default Messages;
