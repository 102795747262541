import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import NumberFormat from 'react-number-format';
import agentSearchContainer from "container/BrokerDashboard/agentSearchContainer"
import Loader from "dumbComponents/common/UI/Loader"
import { get } from "lodash"
import RecruitAndEarn from "../../../container/RecruitAndEarn";

const Wrap = styled.div`
  position: relative;
  width: 300px;
`

const SearchInput = styled.input`
  flex: 1;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid #ccc;
  width: 100%;

  &::placeholder {
    color: #bfbfbf;
    font-size: 13px;
  }


  ${props => props.isError && `
    border: 1px solid red;
  `}
`

const AgentList = styled.div`
  position: absolute;
  width: 100%;
  top: 37px;
  left: 0px;
  right: 0px;
  min-height: 200px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #ccc;
  z-index: 1000;

  //overflow css
  height: 400px;
  overflow: scroll;
`

const AgentItem = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  &:hover {
    background: #eee;
    transition-duration: 0.3s;
    cursor: pointer;
  }
  > div {
    &:nth-child(1) {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
      background: #eee;
      > img {
        width: 100%;
      }
    }
    &:nth-child(2) {
      margin-left: 20px;
      p, h6 {
        padding: 0px;
        margin: 0px;
      }
      h6 {
        font-weight: 400;
        color: #999;
        font-size: 12px;
      }
      > input {
        color: #999;
        border: none;
        font-size: 12px;
        pointer-events: none;
        padding: 0px;
        background: transparent;
      }
    }
  }
`

const AgentSearch = ({
  agents,
  fetchAgentSearch,
  onAgentSelect,
  isAgentNotSelected,
  resetSelections,
  clearAgent,
  customInputStyling,
  searched,
  type,
  setSearchSenderAgent,
  setSearchReferralAgent,
  placeholder,
}) => {
  const [searchText, setSearchText] = useState("")

  const handleSearchType = (value) => {
    setSearchText(value)
    fetchAgentSearch({ search: value })
  }
  const { isFetching, data } = agents
  const agentsLists = get(data, "agents", [])

  return (
    <Wrap>
      <SearchInput
        type="text"
        isError={isAgentNotSelected}
        value={searched || searchText}
        placeholder={placeholder || "Quick Agent Search"}
        onChange={(e) => { handleSearchType(e.target.value) }}
        style={{
          ...customInputStyling || {},
        }}
      />
      {searchText && (
        <AgentList>
          {isFetching ? (
            <Loader width="25px" width="25px" top={0} bottom={0} />
          ) : (
            <React.Fragment>
              {agentsLists && agentsLists.length > 0 && agentsLists.map(agent => (
                <AgentItem
                  key={agent.id}
                  onClick={() => {
                    if (onAgentSelect) {
                      onAgentSelect(agent)
                    } else {
                      window.location.href = `/broker/dashboard/${agent.id}/subscription`
                    }

                    switch (type) {
                      case "sender":
                        setSearchSenderAgent(searched || searchText)
                        break
                      case "referred":
                        setSearchReferralAgent(searched || searchText)
                        break
                      default:
                        break
                    }
                    
                    resetSelections()
                    clearAgent()
                    setSearchText("")
                  }}
                >
                  <div>
                    <img src={agent.agent_image} alt=""/>
                  </div>
                  <div>
                    <p>
                      {`${agent.firstname} ${agent.lastname}`}
                    </p>
                    <h6>
                      {agent.email}
                    </h6>
                    <NumberFormat format="+1 (###) ###-####" mask="_" value={agent.phone}/>
                  </div>
                </AgentItem>
              ))}
            </React.Fragment>
          )}
        </AgentList>
      )}
    </Wrap>
  )
}

export default RecruitAndEarn(agentSearchContainer(withRouter(AgentSearch)))