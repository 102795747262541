import React from "react"

const P = ({ style, ...props }) => (
  <p
    style={{
      ...style,
      fontSize: 16,
      fontWeight: "normal",
      fontFamily: "Helvetica",
      lineHeight: 1.2,
    }}
    {...props}
  >

  </p>
)

export default P
