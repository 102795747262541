import React, { PureComponent } from "react"
import styled from "styled-components"
import { Button, Modal, Drawer } from "antd"
import RARTeamJobs from "components/RARTeamJobs"

const Wrap = styled.div`

`

class JobCreateModal extends PureComponent {
  state = {

  }

  render() {
    const { onClose, visible, teamInfo } = this.props
    return (
      <Wrap>
        <Drawer
          height="95%"
          title="Jobs list created by this team"
          centered
          placement="bottom"
          visible={visible}
          onClose={() => onClose(false)}
        >
          {visible && <RARTeamJobs teamId={teamInfo.id} showApplicant={false} showTeamFilter={false} />}
        </Drawer>
      </Wrap>)
  }
}

export default JobCreateModal

