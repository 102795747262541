import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"
const initState = {
  isInfoDrawerOpen: false,
  isAgentInfoDrawerOpen: false,
  isEditDrawerOpen: false,
  idToBeFetched: null,
  agentName: null,
  skip: 0,
  limit: 10,
  editableData: null,
  currentSenderAgent: null,
  currentReferralAgent: null,
  queryObject: [],
  ...createDeltaReducer("getBrokerInvitesResponse"),
  ...createDeltaReducer("getBrokerEarningsResponse"),
  ...createDeltaReducer("toggleBrokerInviteResponse"),
  ...createDeltaReducer("updateBrokerEarningsResponse"),
  ...createDeltaReducer("agentInviteAnalytics"),
  ...createDeltaReducer("createNewInviteResponse"),
  ...createDeltaReducer("deleteInvite"),
  ...createDeltaReducer("fetchInviteByIdResponse"),
  ...createDeltaReducer("editInviteResponse"),
  ...createDeltaReducer("fetchReferralListResponse"),
  ...createDeltaReducer("fetchInviteeTimelineResponse"),
  ...createDeltaReducer("updateInviteeStatusResponse"),
  ...createDeltaReducer("getEarningsListResponse"),
  ...createDeltaReducer("getAgentSpecificDetailResponse"),
  ...createDeltaReducer("updateEarningsResponse"),
  ...createDeltaReducer("fetchSenderTimelinesResponse"),
  ...createDeltaReducer("getTotalRecruitAndEarnStatsResponse"),
  ...createDeltaReducer("downloadCSVResponse"),
  ...createDeltaReducer("downloadReferralCSVResponse"),
}

export default function recruitAndEarnReducer(state = initState, action) {
  switch (action.type) {
    case Actions.getBrokerInvitesAction.REQUEST:
    case Actions.getBrokerInvitesAction.SUCCESS:
    case Actions.getBrokerInvitesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getBrokerInvitesAction, "getBrokerInvitesResponse"),
      }
    }

    case Actions.getBrokerEarningAction.REQUEST:
    case Actions.getBrokerEarningAction.SUCCESS:
    case Actions.getBrokerEarningAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getBrokerEarningAction, "getBrokerEarningsResponse"),
      }
    }

    case Actions.toggleInviteAction.REQUEST:
    case Actions.toggleInviteAction.SUCCESS:
    case Actions.toggleInviteAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.toggleInviteAction, "toggleBrokerInviteResponse"),
      }
    }

    case Actions.updateBrokerEarningsAction.REQUEST:
    case Actions.updateBrokerEarningsAction.SUCCESS:
    case Actions.updateBrokerEarningsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateBrokerEarningsAction, "updateBrokerEarningsResponse"),
      }
    }

    case Actions.createNewInviteAction.REQUEST:
    case Actions.createNewInviteAction.SUCCESS:
    case Actions.createNewInviteAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createNewInviteAction, "createNewInviteResponse"),
      }
    }

    case Actions.deleteInviteAction.REQUEST:
    case Actions.deleteInviteAction.SUCCESS:
    case Actions.deleteInviteAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.deleteInviteAction, "deleteInvite"),
      }
    }

    case Actions.fetchInviteByIdAction.REQUEST:
    case Actions.fetchInviteByIdAction.SUCCESS:
    case Actions.fetchInviteByIdAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchInviteByIdAction, "fetchInviteByIdResponse"),
      }
    }

    case Actions.editInviteAction.REQUEST:
    case Actions.editInviteAction.SUCCESS:
    case Actions.editInviteAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.editInviteAction, "editInviteResponse"),
      }
    }

    case Actions.fetchAgentInviteAnalytics.REQUEST:
    case Actions.fetchAgentInviteAnalytics.SUCCESS:
    case Actions.fetchAgentInviteAnalytics.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchAgentInviteAnalytics, "agentInviteAnalytics"),
      }
    }

    case Actions.getReferralsListAction.REQUEST:
    case Actions.getReferralsListAction.SUCCESS:
    case Actions.getReferralsListAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getReferralsListAction, "fetchReferralListResponse"),
      }
    }

    case Actions.getInviteeTimelineAction.REQUEST:
    case Actions.getInviteeTimelineAction.SUCCESS:
    case Actions.getInviteeTimelineAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getInviteeTimelineAction, "fetchInviteeTimelineResponse"),
      }
    }

    //getSenderTimelinesAction
    case Actions.getSenderTimelinesAction.REQUEST:
    case Actions.getSenderTimelinesAction.SUCCESS:
    case Actions.getSenderTimelinesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getSenderTimelinesAction, "fetchSenderTimelinesResponse"),
      }
    }

    case Actions.updateInviteeStatusAction.REQUEST:
    case Actions.updateInviteeStatusAction.SUCCESS:
    case Actions.updateInviteeStatusAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateInviteeStatusAction, "updateInviteeStatusResponse"),
      }
    }

    case Actions.getEarningsListAction.REQUEST:
    case Actions.getEarningsListAction.SUCCESS:
    case Actions.getEarningsListAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getEarningsListAction, "getEarningsListResponse"),
      }
    }

    case Actions.getAgentDetailsActionRAE.REQUEST:
    case Actions.getAgentDetailsActionRAE.SUCCESS:
    case Actions.getAgentDetailsActionRAE.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getAgentDetailsActionRAE, "getAgentDetailsResponseRAE"),
      }
    }

    case Actions.getAgentSpecificDetailAction.REQUEST:
    case Actions.getAgentSpecificDetailAction.SUCCESS:
    case Actions.getAgentSpecificDetailAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getAgentSpecificDetailAction, "getAgentSpecificDetailResponse"),
      }
    }

    case Actions.updateEarningsAction.REQUEST:
    case Actions.updateEarningsAction.SUCCESS:
    case Actions.updateEarningsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateEarningsAction, "updateEarningsResponse"),
      }
    }

    case Actions.getTotalRecruitAndEarnStatsAction.REQUEST:
    case Actions.getTotalRecruitAndEarnStatsAction.SUCCESS:
    case Actions.getTotalRecruitAndEarnStatsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.getTotalRecruitAndEarnStatsAction,
          "getTotalRecruitAndEarnStatsResponse"
        ),
      }
    }

    case Actions.downloadCSV.REQUEST:
    case Actions.downloadCSV.SUCCESS:
    case Actions.downloadCSV.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.downloadCSV,
          "downloadCSVResponse"
        ),
      }
    }

    case Actions.downloadReferralCSV.REQUEST:
    case Actions.downloadReferralCSV.SUCCESS:
    case Actions.downloadReferralCSV.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.downloadReferralCSV,
          "downloadReferralCSVResponse"
        ),
      }
    }

    case Actions.toggleInvitedDrawerAction.type: {
      const {
        inviteeData,
        bool,
      } = action.data
      return {
        ...state,
        isInfoDrawerOpen: bool,
        inviteeData,
      }
    }

    case Actions.toggleAgentDetailsDrawerAction.type: {
      const {
        bool,
        agentId,
        agentName,
      } = action.data
      return {
        ...state,
        isAgentInfoDrawerOpen: bool,
        idToBeFetched: agentId,
        agentName,
      }
    }

    case Actions.toggleEditDrawerAction.type: {
      const {
        bool,
        data,
      } = action.data
      return {
        ...state,
        isEditDrawerOpen: bool,
        editableData: data,
      }
    }

    case Actions.setSearchSenderAgentAction.type: {
      return {
        ...state,
        currentSenderAgent: action.data,
      }
    }

    case Actions.setSearchReferralAgentAction.type: {
      return {
        ...state,
        currentReferralAgent: action.data,
      }
    }

    case Actions.clearSetSearchValuesAction.type: {
      return {
        ...state,
        currentSenderAgent: "",
        currentReferralAgent: "",
      }
    }

    case Actions.setFilterQueriesAction.type: {
      const {
        queries,
      } = action.data
      return {
        ...state,
        queryObject: queries && queries.length > 0 ? [...queries] : [queries],
      }
    }

    default: {
      return state
    }
  }
}
