import React, { Component } from 'react';
import _ from 'lodash';
import bugsnag from 'bugsnag-js';
import Popup from "reactjs-popup";
import fetch from 'isomorphic-fetch';
import SaleStageTimeline from "./SaleStageTimeline/SaleStageTimeline";
import "./Connected.css";


const baseUrl =process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;
const originalKey = process.env.REACT_APP_BUGSNAG_KEY;
const bugsnagkey = 'duplicateKey';
const bugsnagClient = bugsnag(originalKey);

class Connected extends Component {
  state = {
    agentIds: [],
    assignedAgents: [],
    agents: [],
    primaryAgentId: null,
    agentProfile: null,
  };

  componentWillReceiveProps = (props) => {
    this.loadPrimaryAgent(props.assignedAgents || [], props.assignedAgentObjects || []);
    this.setState({primaryAgentId: props.referral.primary_agent_id, referral: props.referral})
  };

  componentDidMount = () => {
    this.loadPrimaryAgent(this.props.assignedAgents || [], this.props.assignedAgentObjects || []);
    this.setState({primaryAgentId: this.props.referral.primary_agent_id, referral: this.props.referral});
  };

  loadPrimaryAgent = async (agentIds, agentMap) => {
    const self = this;
    agentMap = _.groupBy(agentMap, 'id');
    let agents = [];
    let primaryAgentIds = _.filter(agentIds, (agentId) => self.props.referral.primary_agent_id === agentId);
    if (primaryAgentIds && primaryAgentIds.length >0){
      _.each(primaryAgentIds,
        async (agentId) => {
          try {
            let skeleton = await fetch(baseUrl + '/v2.0/refer/filter-skeleton', {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'internal-id': token
              },
            });
            skeleton = await skeleton.json();
            skeleton = skeleton.response;
            console.log(skeleton);

            let sol = await fetch(baseUrl + '/v2.0/user/secure_id?getAgentDetails=true&agentId=' + agentId, {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'internal-id': token
              },
            });
            sol = await sol.json();
            let clientDetails = await fetch(baseUrl + '/v2.0/clients/'+ agentMap[agentId][0].receiver_client_id, {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'secure-id': sol.response.secure_id
              },
            });
            clientDetails = await clientDetails.json();
            agentMap[agentId][0].client = clientDetails.response;
            if(skeleton) {
              agentMap[agentId][0].client.inboundReferralStatuses = skeleton.inboundReferralStatuses;
            }
            if (self.props.referral.type === "Buyer" || self.props.referral.type === "Renter"){
              if (skeleton && !agentMap[agentId][0].client["sales"]["Buyer checklist"]){
                agentMap[agentId][0].client["sales"]["Buyer checklist"] = skeleton.inboundBuyerChecklist;
              }
            }
            else {
              if (skeleton && !agentMap[agentId][0].client["sales"]["Seller checklist"]){
                  agentMap[agentId][0].client["sales"]["Seller checklist"] = skeleton.inboundSellerChecklist;
              }
            }

            agentMap[agentId][0].agent_image = sol.response.agent.agent_image;

            let imageUrl = null;
            const images = _.filter(agentMap[agentId][0].images,
              (img) => {
                if (img.type === 'profile') {
                  return true;
                }
              }
            );
            if (images.length > 0) {
              imageUrl = images[0]['path'];
            }
            agentMap[agentId][0].imageUrl = imageUrl;

            let agents = self.state.agents;
            agents.push(agentMap[agentId][0]);
            agents = _.uniq(agents);
            self.setState({agents: agents});
          }
          catch(e){
            bugsnagClient.notify(e);
          }
        }
      );
    }


  };

  render() {
    const agents = this.state.agents.map((agent) =>  <div className={"flex"}>
        <div className={"agent-container"}>
          <div className={"agent-section"}>
            <p>Primary Agent</p>
            <div className={"agent-details"}>
              <img
                src={
                  agent.agent_image
                }
              />
                <p>{(agent.firstname ? agent.firstname : ' ')+ ' ' + (agent.lastname ? agent.lastname : ' ')}</p>
            </div>
          </div>
          {agent.phone &&
             <Popup
                          trigger={
                            <div className={"btn-actions"}>
                              <button>CALL</button>
                            </div>
                          }
                            modal
                            closeOnDocumentClick
                          >
                              {close => (
                                 <div className="modal-2">
                                 <div className="modal-header">
                                     <span className="modal-header-text">
                                         Are you sure you want to continue with this action?
                                     </span>
                                 </div>
                                 <div className="dialogue-box-footer">
                                     <button className="med-btn-1"  onClick={()=> this.props.callClient(close, agent.phone)}>
                                         YES
                                     </button>
                                     <button className="med-btn-1" onClick={()=> {close();}}>
                                         NO
                                     </button>
                                 </div>
                           </div>

                              )
                              }
              </Popup>
          }
        </div>
        <SaleStageTimeline client={agent.client}  />
      </div>
    );
    return <div style={{"width" : "100%"}}>{agents || null}</div>
  }
}

export default Connected;
