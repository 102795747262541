import React from "react"
import styled from "styled-components"
import {
  Form,
  Input,
  GoogleAutoComplete,
  InputNumber,
  StyledTextArea,
} from "dumbComponents/common/UI/antd"
import colors from "../../../../lib/colors"
import {
  normalizePhone,
} from "../../commonUtils"

const LabelText = styled.h2`
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`

const Required = styled.span`
  color: #E34C4C
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

const TitleForm = ({
  commonRules,
  setCities,
  setStreet,
  formRef,
  setLocaCity,
}) => (
  <>
    <FlexBox wd="100%">
      <Form.Item
        name="vendor_name"
        label={<LabelComponent text="Title Company Name" required />}
        required
      >
        <Input />
      </Form.Item>
    </FlexBox>

    {/*Title Officer Details*/}
    <FlexBox wd="100%">
      <Form.Item
        name="vendor_officer_first_name"
        label={<LabelComponent text="Title Officer First Name" required />}
        required
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="vendor_officer_last_name"
        label={<LabelComponent text="Title Officer Last Name" required />}
        required
      >
        <Input />
      </Form.Item>
    </FlexBox>

    {/*Contact Details*/}
    <FlexBox>
      <Form.Item
        name="vendor_email"
        label={<LabelComponent text="Email" required />}
        required
        rules={commonRules}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="vendor_phone"
        label={<LabelComponent text="Phone Number" required />}
        rules={commonRules}
        normalize={normalizePhone}
      >
        <Input />
      </Form.Item>
    </FlexBox>

    {/*Address Details*/}
    <FlexBox wd="100%">
      {/* <Form.Item name="vendor_address" label={<LabelComponent text="Address" required />} rules={commonRules}>
        <Input />
      </Form.Item> */}
      <Form.Item name="vendor_address" label={<LabelComponent text="Address" />}>
        {/* <Input /> */}
        <GoogleAutoComplete
          id="cities"
          types={["address"]}
          name="cities"
          // formValue={cityFormValue}
          onChange={(e) => { }}
          location={(loc) => { setStreet(loc, formRef, "vendor_address", false, null, "vendor") }}
          placeholder=""
          styles={{
            height: "49px",
            borderRadius: "0px",
            background: "white",
            fontSize: "16px",
            marginTop: "10px",
          }}
        />
      </Form.Item>
    </FlexBox>

    <FlexBox>
      <Form.Item name="vendor_city" label={<LabelComponent text="City" />}>
        <GoogleAutoComplete
          id="cities"
          types={["(cities)"]}
          name="cities"
          // formValue={cityFormValue[`city-${index}`]}
          onChange={(e) => { setLocaCity(e.target.value, "city") }}
          location={(loc) => { setCities(loc) }}
          placeholder=""
          styles={{
            height: "49px",
            borderRadius: "0px",
            background: "white",
            fontSize: "16px",
            marginTop: "10px",
          }}
          restrict={{
            lat: 37.09024,
            lng: -95.712891,
            south_west: {
              lat: 25.82,
              lng: -124.39,
            },
            north_east: {
              lat: 49.38,
              lng: -66.94,
            },
            address: "United States",
            country: {
              long_name: "United States",
              short_name: "US",
              types: [
                "country",
                "political",
              ],
            },
          }}
        />
      </Form.Item>

      <Form.Item
        name="vendor_zipcode"
        label={<LabelComponent text="Zip Code" />}
      >
        <Input />
      </Form.Item>

      <Form.Item name="vendor_state" label={<LabelComponent text="State" />}>
        <Input />
      </Form.Item>
    </FlexBox>

    <FlexBox>
      <Form.Item name="notes" label={<LabelComponent text="Notes" />}>
        <StyledTextArea />
      </Form.Item>
    </FlexBox>
  </>
)

export default TitleForm
