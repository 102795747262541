import styled from "styled-components"

export const Wrap = styled.div`
  margin: ${props => props.margin || "unset"};
`

export const HeaderTitle = styled.h1`
  color: var(--gray-900, #18181B);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px; /* 131.25% */
`

export const HeaderDescription = styled.p`
  color: var(--gray-600, #52525B);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  margin-top: -10px;
`
export const HeaderSubTitle = styled.div`
  h2 {
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.108px;
  }
`
