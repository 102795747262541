import React, { Component } from "react"
import container from "container/Agent"
import moneyFormatter from "shared/currencyUtils"


export const preferencesMap = {
    is_buyer_client_preferred: {
        label: "Buyer",
    },
    is_seller_client_preferred: {
        label: "Seller",
    },
    is_renter_client_preferred: {
        label: "Renter",
    },
    buyer_min_budget: {
        label: "Buyer Min Budget",
    },
    seller_min_budget: {
        label: "Seller Min Budget",
    },
    renter_min_budget: {
        label: "Renter Min Budget",
    },
    is_active_to_receive_referral: {
        label: "Vacation Mode",
    },
}

class AgentPreferences extends Component {

    render() {

        const {selectedAgent} = this.props || {}
        const {buyer_min_budget, seller_min_budget, renter_min_budget} = selectedAgent || {}
        const {is_active_to_receive_referral, is_buyer_client_preferred, is_seller_client_preferred,
            is_renter_client_preferred} = selectedAgent || {}


        return (

            <div>
                <React.Fragment>
                    {selectedAgent &&
                        <ul>
                        <React.Fragment>
                            <li><p> Lead types/Budget: </p></li>
                                {Boolean(is_buyer_client_preferred) &&  <p>{preferencesMap.is_buyer_client_preferred.label} </p>}
                                {buyer_min_budget && <p>{preferencesMap.buyer_min_budget.label} :
                                    ${moneyFormatter(buyer_min_budget, 1)}</p>}
                                {Boolean(is_seller_client_preferred) && <p>{preferencesMap.is_seller_client_preferred.label}</p>}
                                {seller_min_budget && <p>{preferencesMap.seller_min_budget.label} :
                                    ${moneyFormatter(seller_min_budget, 1)}</p>}
                                {Boolean(is_renter_client_preferred) && <p>{preferencesMap.is_renter_client_preferred.label}</p>}
                                {renter_min_budget && <p>{preferencesMap.renter_min_budget.label} :
                                    ${moneyFormatter(renter_min_budget, 1)}</p>}

                            <li><p>Vacation Mode: {is_active_to_receive_referral ?
                                (<p>NO</p>) : (<p>YES</p>)}
                            </p></li>
                        </React.Fragment>
                        </ul>
                        }
                </React.Fragment>
            </div>
        )
    }
}
export default container(AgentPreferences);