import React, { useState } from "react"
import styled from "styled-components"
import {
  Input,
  Modal as AntDModal,
} from "antd"
import Modal from "dumbComponents/common/UI/Modal"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import ReasonConfirmationModal from "./ReasonConfirmModal"
import {
  Title,
  WhiteButton,
  BlueButton,
} from "./commonStyles"

const { confirm } = AntDModal

const StyledCTAWrap = styled.div`
  display: flex;
  padding: 20px 0px 20px 0px;
  gap: 10px;
`

const Label = styled.p`
  span {
    color: red;
  }
`

const InputWrap = styled.div``

const FormModal = ({
  submitting,
  toClose,
  triggerVoidAndDelete,
  currentAgentIDViewed,
  fetchICAListingsResponse,
}) => {
  const [reason, setReason] = useState("")

  const {
    data: isaDocuments,
  } = fetchICAListingsResponse || {}

  const handleChange = (e) => {
    const {
      target: {
        value,
      },
    } = e || {}
    setReason(value)
  }

  const handleSubmit = () => {
    confirm({
      title: "Please confirm the action.",
      icon: <ExclamationCircleOutlined />,
      content: "Do you wish to proceed? This is irreversible!",
      onOk() {
        const envelopeId = isaDocuments
        && isaDocuments.length > 0
        && isaDocuments[0]
        && isaDocuments[0].envelope_id

        if (envelopeId) {
          const payload = {
            envelopeId,
            reason,
          }

          triggerVoidAndDelete({
            payload,
            agentId: currentAgentIDViewed,
          })
        } else {
          AntDModal.error({
            content: "Envelope ID missing!",
          })
        }
      },

      onCancel() {
        return false
      },
    })
  }

  const handleClose = () => {
    toClose()
  }

  return (
    <Modal
      toClose={submitting ? () => {} : toClose}
      width="948px"
      show
      showCloseButton
      title="X"
      showHorizontalBar={false}
      styledTextClose={{
        position: "absolute",
        top: "16px",
        right: "0",
        marginRight: "10px",
        cursor: "pointer",
        left: "unset",
        fontSize: "14px",
        fontFamily: "ATC",
        fontWeight: 500,
      }}
    >
      <Title>Do you wish to void and delete this generated ICA ?</Title>
      <InputWrap>
        <Label>
          Reason
          <span>*</span>
        </Label>
        <Input required name="reason" onChange={handleChange} placeholder="State reason.." />
      </InputWrap>

      <StyledCTAWrap>
        <WhiteButton disabled={submitting} onClick={handleClose}>Cancel</WhiteButton>
        <BlueButton disabled={submitting || !reason} onClick={handleSubmit}>Confirm</BlueButton>
      </StyledCTAWrap>

    </Modal>
  )
}
export default subscriptionContainer(FormModal)
