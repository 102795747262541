import { createSelector } from "reselect"
import { get, upperCase, sortBy } from "lodash"

const getSubscription = state => state.agent.subscriptions.data
const getPlansList = state => state.agent.plans.data
const getAddonsFromState = state => state.brokerDashboard.addonsResponse.data
const getSelectedDuration = state => state.brokerDashboard.selectedDuration
const getUSStates = state => state.brokerDashboard.statesResponse && state.brokerDashboard.statesResponse.data
const getTCList = state => state.brokerDashboard.getTCListResponse && state.brokerDashboard.getTCListResponse.data

const planTest = /monthly|annually|/gm

export const getAllPlans = createSelector(
  [getPlansList], (planList) => {
    if (planList && planList.single_plans.broker) {
      return planList.single_plans.broker
    }
    return {}
  }
)

const getPlanBySubscriptionId = (subList, id, subId) => {
  if (subList && subList.length > 0) {
    const seletedSub = subList.find(x => x.id === id)
    if (seletedSub) {
      if (seletedSub.plan) {
        return seletedSub.plan
      }
      const dataItem = get(seletedSub, "items.data")
      if (dataItem && dataItem.length && subId) {
        const subPlan = dataItem.find(x => x.id === subId || x.id === id)
        if (subPlan && subPlan.plan) {
          return subPlan.plan
        }
        return subPlan
      }
      return seletedSub
    }
  }
  return null
}

export const getTCListMapped = createSelector([getTCList], (getTCListRes) => {
  // eslint-disable-next-line max-len
  if (getTCListRes) {
    const TCListfinal = getTCListRes.map(x => ({ label: `${x.tc_firstname} ${x.tc_lastname}`, value: x.id }))
    return TCListfinal
  }
  // return null
})

// if `on_request_amount` no need to map it with subscription
// BE will add name

// if recurring_add_on_sub_id, use `broker_sub_id` go to subscription, with same id, go to items.data.
// (find id === recurring_add_on_sub_id and `subscription` === broker_sub_id)
// return plan

// distinct_add_on_sub_id
// if `distinct_add_on_sub_id` is there, find that id subscription root array

// priotize plans in subscription api, if not look for items.data


export const getAddons = createSelector([
  getAddonsFromState,
  getSubscription,
], (
  addonsRes, 
  plansList,
) => {
  const finalAddOns = []
  const { subscriptions } = plansList || {}
  if (subscriptions && subscriptions.length > 0 && addonsRes && addonsRes.length > 0) {
    return addonsRes
  }
  return finalAddOns
})

export const getCurrentPlan = createSelector([
  getSubscription, getPlansList
], (sub, planList) => {
  const subscriptionLength = get(sub, "subscriptions.length")
  let currentPlan, subscriptionDetails, planFromSub
  if (subscriptionLength > 0) {
    // loop through all subscriptions
    for(let subIndex = 0; subIndex < subscriptionLength; subIndex++) {
      const currentSub = sub.subscriptions[subIndex]
      const addOns = []
      const { data: items } = currentSub.items
      if (items && items.length) {
        for(let itemIndex = 0; itemIndex < items.length; itemIndex++) {
          const currentItem = items[itemIndex]
          const currentPlanName = get(currentItem, "plan.id")
          if (currentPlanName.includes("broker")) {
            currentPlan = items[itemIndex].plan
            subscriptionDetails = currentSub
            if (currentPlanName.includes("addons")) {
              addOns.push(items[itemIndex].plan)
            }
            if (planList && planList.single_plans.broker) {
              planFromSub = planList.single_plans.broker.find(x => x.plan_id === currentPlan.id)
            }
            return {
              currentPlan,
              subscriptionDetails,
              addOns,
              planFromSub,
            }
          }
        }
      }
    }
  }
  return {}
})


export const getAssistPlans = createSelector([
  getSubscription, getPlansList
], (sub, planList) => {
  const subscriptionLength = get(sub, "subscriptions.length")
  let  assistPlan
  if (subscriptionLength > 0) {
    // loop through all subscriptions
    for(let subIndex = 0; subIndex < subscriptionLength; subIndex++) {
      console.log({ subIndex, subscriptionLength });
      const currentSub = sub.subscriptions[subIndex]
      const { data: items } = currentSub.items
      if (items && items.length) {
        for(let itemIndex = 0; itemIndex < items.length; itemIndex++) {
          const currentItem = items[itemIndex]
          const currentPlanName = get(currentItem, "plan.id")
          if (currentPlanName.includes("rtl")) {
            assistPlan = currentSub
            return {
              currentAssistPlan: currentItem,
              subscriptionDetails: currentSub,
            }
          }
        }
      }
    }
  }
  return {}
})


export const getSelctedPlansList = createSelector(
  [ getPlansList, getSelectedDuration ],
  (planlist, selectedDuration) => {
    const brokerPlans = get(planlist, "single_plans.broker")
    if (brokerPlans && brokerPlans.length) {
      const searchText = selectedDuration
      console.log({ searchText });
      // monthly
      if (searchText === "MONTHLY") {
        const filteredList = brokerPlans.filter(x => 
          (x.billing_cycle === "MONTHLY" || x.billing_cycle === "YEARLY")
          && x.billing_interval === 1
          && x.is_blink_visible
        ).sort((x, y) => x.unit_price - y.unit_price)
        return filteredList
      }
      if (searchText === "QUARTERLY") {
        const filteredList = brokerPlans.filter(x => 
          x.billing_cycle === "MONTHLY"
          && x.billing_interval === 3
          && x.is_blink_visible
        )
        return filteredList
      }
      if (searchText === "HALF-YEARLY") {
        const filteredList = brokerPlans.filter(x => 
          x.billing_cycle === "MONTHLY"
          && x.billing_interval === 6
          && x.is_blink_visible
        )
        return filteredList
      }
      if (searchText === "YEARLY") {
        const filteredList = brokerPlans.filter(x => 
          x.billing_cycle === "YEARLY"
          && x.billing_interval === 1
          && x.is_blink_visible
        )
        return filteredList
      }
    }
    return []
  }
)

export const formatUSStates = createSelector(
  [getUSStates],
  (states) => {
    if (states && states.length) {
      const newList = states.map(x => ({ label: x.name, value: x.id }))
      return newList
    }
    return []
  }
)

export const createSelectedPlanMapFromAPI = createSelector(
  state => state.addons,
  state => state.currentPlan && state.currentPlan.currentPlan,
  (addons, currentPlan) => {
    const planMap = {}
    if (currentPlan) {
      planMap.plainId = currentPlan.id
    }
    console.log({ addons });
    if (addons && addons.length > 0) {
      const addonsIds = addons.map(x => x.id)
      planMap.addonsIds = sortBy(addonsIds) || []
    }
    return planMap
  }
)

export const createCurrentSelectedPlansMap = createSelector(
  state => state.selectedAddons,
  state => state.selectedPlan,
  (selectedAddons, selectedPlan) => {
    const planMap = {}
    if (selectedPlan) {
      planMap.plainId = selectedPlan.planId
    }
    console.log({ selectedAddons });
    if (selectedAddons && selectedAddons.length > 0) {
      const addonsIds = selectedAddons.map(x => x.id)
      planMap.addonsIds = sortBy(addonsIds) || []
    }
    return planMap
  }
)