import axios from "axios"
import getXUserAgentKey from "./axiosUtils"
import CookiesStorage from "./CookieStorage"

const headerXAgentKey = getXUserAgentKey()

axios.interceptors.request.use(
  (config) => {
    //console.log("Axios Request Config: ", config);
    config.headers.common["X-User-Agent"] = headerXAgentKey
    config.headers.common["internal-id"] = process.env.REACT_APP_SECRET_ID
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response && error.response.status === 422) {
      return error.response
    }
    return Promise.reject(error.response || error)
  }
)
