import React from "react"
import { Tabs } from "antd"
import {
  Referrals,
  Financials,
  Details,
} from "./TabComponents"
import RecruitAndEarn from "../../container/RecruitAndEarn"

const { TabPane } = Tabs

const HeaderTab = (props) => {
  const {
    fetchReferrals,
    fetchAllEarnings,
  } = props || {}

  const onChange = (key) => {
    switch (key) {
      case "referrals":
        fetchReferrals({
          queryParams: "skip=0&limit=300",
        })
        break
      case "financials":
        fetchAllEarnings({
          queryParams: "skip=0&limit=300",
        })
        break
      default:
        return false
    }

    return true
  }

  return (
    <Tabs defaultActiveKey="referrals" onChange={onChange}>
      <TabPane tab="Referrals" key="referrals">
        <Referrals />
      </TabPane>
      <TabPane tab="Financials" key="financials">
        <Financials />
      </TabPane>
      <TabPane tab="Metrics" key="details">
        <Details />
      </TabPane>
    </Tabs>
  )
}

export default RecruitAndEarn(HeaderTab)
