export const generateExtraResponse = keys => ({
  notRelevantPercent: keys.not_relevant_percent
    ? parseInt(keys.not_relevant_percent, 10) / 1 : 0,
  inacuratePercent: keys.inaccurate_percent
    ? parseInt(keys.inaccurate_percent, 10) / 1 : 0,
  inconclusivePercent: keys.inconclusive_percent
    ? parseInt(keys.inconclusive_percent, 10) / 1 : 0,
  repetitivePercent: keys.repetitive_percent
    ? parseInt(keys.repetitive_percent, 10) / 1 : 0,
  somethingElsePercent: keys.something_else_percent
    ? parseInt(keys.something_else_percent, 10) / 1 : 0,
})
