import React from "react"
import styled from "styled-components"
import { Button } from "./common"

const Wrap = styled.div`
  margin-top: 20px;
  display: flex;
`

const OtherOption = ({
  resetNewMessagesCount,
  messagesCount,
  addNewLeads,
  allowAutoCallerMode,
  unsavedReferrals,
}) => (
  <Wrap>
    {addNewLeads}
    {allowAutoCallerMode && (
      <React.Fragment>
        {unsavedReferrals}
      </React.Fragment>
    )}
    <Button type="info" small icon="envelope" onClick={resetNewMessagesCount}>
      Messages ({messagesCount || "0"})
    </Button>
  </Wrap>
)

export default OtherOption