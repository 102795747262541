import styled from "styled-components"
import {
  Select,
  Input,
  InputNumber,
  Spin,
  Form,
  GoogleAutoComplete,
  DatePicker,
  Button,
} from "dumbComponents/common/UI/antd"

const { TextArea, Search } = Input

export const SelectFieldNewAttachedStyled = styled(Select)`
.ant-select-selector {
    position: relative;
    top: 0px;
    box-shadow: none;
    border: none !important
    /* border-radius: 8px !important; */
    // border-top: 1px solid #898989 !important;
    height: 42px !important;
    // border-right: 1px solid #898989 !important;
    // border-bottom: 1px solid #898989 !important;
    box-shadow: none;
    // border-top-left-radius: 0px !important;
    // border-bottom-left-radius: 0px !important;
  }
`

export const InputGroupStyled = styled(Input.Group)`
  display: flex !important;
  height: 42px;
`

export const InputNumberNewWrap = styled(InputNumber)`
  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, #9CA3AF) !important;
  height: 42px !important;
  .ant-input-number-input {
    height: 42px !important;
  }
  width:850px;
    margin-right: -7px !important;
`
export const LabelText = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
`
export const Required = styled.span`
color: #E34C4C
`

export const Wrap = styled.div`
  width: 100%;

  ${props => props.noWidth && `
    width: unset;
  `}
  
  ${props => props.width && `
    width: ${props.width};
  `}
`

export const SelectFieldWrap = styled(Select)`
  width: 100%;

  border-radius: 8px !important;
  border: none;

  .ant-select-selector {
    ${props => props.isFormField && `
      border-radius: 8px !important;
    `}
    height: 42px !important;
  }
`