import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as BrokerDashboardActions from "./actions"
import { formatUSStates } from "./selector"

function mapStateToProps(state) {
  const { brokerDashboard, agent } = state
  const { preferences } = agent
  const {
    statesResponse,
    agentAssetsResponse,
    fileUploadResResponse,
    assetForm,
    agentStates,
    getCitiesServedResponse,
    getNeighbourHoodFromCitiesResponse,
    agentDetailsResponse,
    getOrientationInfoResponse,
    uploadDocsToBucketResponse,
    uploadDocsToBucketBrandingResponse,
    getBrandingInfoResponse,
    getBrandingAssetsResponse,
    yardSign,
    businessCard,
    fileUploading,
    setupProfileResponse,
    getDocusignTemplateResponse,
    getCommonTemplateResponse,
    getTotalCountTemplateResponse,
    templateList,
    templateInvalid,
    deleteTemplates,
    agentBrokerageInfoResponse,
    getBrokerageInfoMetabaseResponse,
    selectedAgentPlans,
    getListingCommonTemplateResponse,
    getListingTotalCountTemplateResponse,
  } = brokerDashboard

  const USStates = formatUSStates(state)
  return {
    USStates,
    agentAssetsResponse,
    fileUploadResResponse,
    assetForm,
    agentStates,
    preferences,
    getCitiesServedResponse,
    getNeighbourHoodFromCitiesResponse,
    agentDetailsResponse,
    getOrientationInfoResponse,
    uploadDocsToBucketResponse,
    uploadDocsToBucketBrandingResponse,
    getBrandingInfoResponse,
    getBrandingAssetsResponse,
    yardSign,
    businessCard,
    fileUploading,
    setupProfileResponse,
    getDocusignTemplateResponse,
    getCommonTemplateResponse,
    getTotalCountTemplateResponse,
    templateList,
    templateInvalid,
    deleteTemplates,
    agentBrokerageInfoResponse,
    getBrokerageInfoMetabaseResponse,
    selectedAgentPlans,
    getListingCommonTemplateResponse,
    getListingTotalCountTemplateResponse,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      getUSStates: BrokerDashboardActions.getUSStatesAction.request,
      changeStatesForAgent: BrokerDashboardActions.changeStatesForAgentAction.request,
      getAgentAssets: BrokerDashboardActions.getAgentAssetsActions.request,
      updateAgentAssets: BrokerDashboardActions.updateAgentAssetsActions.request,
      uploadFiles: BrokerDashboardActions.uploadFilesAction.request,
      resetUploadState: BrokerDashboardActions.uploadFilesAction.clear,
      updateAssetsFormLinks: BrokerDashboardActions.updateAssetsFormLinksAction.call,
      updateUserSettings: BrokerDashboardActions.updateUserSettingsAction.request,
      fetchCitiesServed: BrokerDashboardActions.getCurrentCitiesServedAction.request,
      fetchNeighbourhoods: BrokerDashboardActions.getNeighbourHoodFromCitiesAction.request,
      saveNeighbourhoods: BrokerDashboardActions.saveAgentServedNeighbourhoodAction.request,
      deleteNeighbourhood: BrokerDashboardActions.deleteNeighbourhoodAction.request,
      getOrientationInfo: BrokerDashboardActions.getOrientationInfoAction.request,
      updateOrientationInfo: BrokerDashboardActions.updateOrientationInfoAction.request,
      createOrientationInfo: BrokerDashboardActions.createOrientationInfoAction.request,
      uploadDocsToBucket: BrokerDashboardActions.uploadDocsToBucketAction.request,
      uploadDocsToBucketBranding: BrokerDashboardActions.uploadDocsToBucketBrandingAction.request,
      setFileUploading: BrokerDashboardActions.setFileUploading.call,
      getBrandingInfo: BrokerDashboardActions.getBrandingInfoAction.request,
      getBrandingAssets: BrokerDashboardActions.getBrandingAssetsAction.request,
      createBrandingInfo: BrokerDashboardActions.createBrandingInfoAction.request,
      updateBrandingInfo: BrokerDashboardActions.updateBrandingInfoAction.request,
      createBrandingAssets: BrokerDashboardActions.createBrandingAssetsAction.request,
      updateBrandingAssets: BrokerDashboardActions.updateBrandingAssetsAction.request,
      setBusinessCard: BrokerDashboardActions.setBusinessCard.call,
      setYardSign: BrokerDashboardActions.setYardSign.call,
      getSetupProfileInfo: BrokerDashboardActions.getSetupProfileAction.request,
      updateSetupProfile: BrokerDashboardActions.updateSetupProfileAction.request,
      createDocusignTemplate: BrokerDashboardActions.createDocusignTemplateAction.request,
      getDocusignTemplate: BrokerDashboardActions.getDocusignTemplateAction.request,
      getOffersTotalCommonTemplate: BrokerDashboardActions.getOffersTotalCommonTemplate.request,
      setTemplateList: BrokerDashboardActions.setTemplateListAction.call,
      setTemplateInvalid: BrokerDashboardActions.setTemplateInvalidAction.call,
      setDeleteTemplates: BrokerDashboardActions.deleteTemplateListAction.call,
      deleteDocusignTemplate: BrokerDashboardActions.deleteDocusignTemplateAction.request,
      createOffersDocusignTemplate: BrokerDashboardActions.createOffersDocusignTemplate.request,
      getOffersDocusignTemplate: BrokerDashboardActions.getOffersDocusignTemplate.request,
      deleteOffersDocusignTemplate: BrokerDashboardActions.deleteOffersDocusignTemplate.request,
      getAgentBrokerageInfo: BrokerDashboardActions.getAgentBrokerageInfoAction.request,
      postAgentBrokerageInfo: BrokerDashboardActions.postAgentBrokerageInfoAction.request,
      updateAgentBrokerageInfo: BrokerDashboardActions.updateAgentBrokerageInfoAction.request,
      getAgentBrokerageMetadataInfo: BrokerDashboardActions.getAgentBrokerageInfoMetadataAction.request,
      selectAgentPlan: BrokerDashboardActions.selectedSubscriptionPlansAction.call,
      saveStripeToken: BrokerDashboardActions.saveStripeTokenAction.request,
      getTentativeBrokerageSubscriptionDetails: BrokerDashboardActions.getTentativeBrokerageSubscriptionDetailsAction.request,
      fetchRecruiterAndAsmInfo: BrokerDashboardActions.fetchRecruiterAndAsmInfoAction.request,
      updateRecruiterAndAsmInfo: BrokerDashboardActions.updateRecruiterAndAsmInfoAction.request,
      fetchAgentAsmRecruiterInfo: BrokerDashboardActions.fetchAgentAsmRecruiterInfoAction.request,
      getListingTotalCommonTemplate: BrokerDashboardActions.getListingTotalCommonTemplate.request,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
