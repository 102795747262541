import styled from "styled-components"
import "./styles.css"

export const HeaderWrap = styled.div`
  border-bottom: 1px solid #E8E8FD;
  padding: 8px 0px;
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  h2 {
    color: var(--Gray-900, #101828);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 149%; /* 26.82px */
  }
`
