import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Academy"
import {
  Route, Switch, Link,
} from "react-router-dom"
import colors from "../../../lib/colors"
import root from "window-or-global"
import CourseList from "./CourseList"
import Select from "react-select"
import { DefaultLoader } from "components/UI/Loader/Loader";

const Wrap = styled.div`
  div {
   height: max-content;
  }
`

const InnerCourseWrap = styled.div`
  min-height: 300px;
  max-height: 600px;
`

const AddCourse = styled(Link)`
  margin-bottom: 15px;
  width: 118px;
  display: block;
  height: 30px;
  border-radius: 4px;
  border: solid 1px #2a8b3f;
  background-image: linear-gradient(to bottom,#34ce57,#28a745);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  float: right;
  cursor: pointer;
  padding-top: 4px;
`

const SelectWrap = styled.div`
  width: 50%;
  label {
   font-size: 14px;
   font-weight: bold;
   color: #24292e;
  }
`


class Courses extends Component{
 componentDidMount() {
  const {
   getCategories,
  } = this.props

  getCategories()

 }

 handleCategorySelection = (val) => {
   const {
    setCurrentCategoryType,
    getCourseListing,
    fetchCourses,
   } = this.props

   setCurrentCategoryType({
    currentCategorySelected: [val]
   })

   if(val.value === -1) {
    fetchCourses({
     filter: false,
     id: val.value,
    })
   } else {
    fetchCourses({
     filter: "categoryIds",
     id: val.value,
    })
   }
 }


 getCategoriesOptions = () => {
  const {
   getCategoriesResponse,
  } = this.props

  const {
   data: categoryData,
  } = getCategoriesResponse

  if(categoryData && categoryData.length > 0){

   let arr = categoryData.map(key => {
    return { value: key.id, label: key.name, data: key }
   })
   arr.push({ value: -1, label: "All", data: [] })
   return arr
  }

  return [{ label: "Loading..", value: "Loading.." }]
 }

 render() {

  const {
    coursesResponse,
    getCategoriesResponse,
    currentCategorySelected,
  } = this.props

  const {
   data: courses,
   isFetching: fetchingCourses,
  } = coursesResponse || []

  const {
   isFetching: fetchingCategories,
  } = getCategoriesResponse || {}

  return(
   <Wrap>
    <div>
     <AddCourse to="/academy-admin/courses/add-new-course">
      Add Course
     </AddCourse>
     <SelectWrap>
       <Select
        options={this.getCategoriesOptions()}
        onChange={this.handleCategorySelection}
        value={currentCategorySelected}
       />
     </SelectWrap>
    </div>
    <InnerCourseWrap>
      {fetchingCourses || fetchingCategories ? (
       <DefaultLoader height={200} width={200}/>
      ) : (
       <CourseList courses={courses}/>
      )}
    </InnerCourseWrap>
   </Wrap>
  )
 }
}

export default Container(Courses)
