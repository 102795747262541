import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  width: 300px;
  border-left: 2px solid #ccc;
  padding: 40px 20px;
  margin: 100px 0px 50px 30px;
`

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0px 0px 0px;
  > span {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: #E9F3FB;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    color: #444;
  }
  p {
    margin: 0px;
    font-size: 14px;
    font-weight: 300;
  }
`

const FeatureEmptyState = styled.div`
  text-align: center;
  img {
    max-width: 80px;
    margin: 0px auto;
  }
`

const FeaturesPanel = ({
  selectedPlan = {}
}) => {
  const { plan_benefits } = selectedPlan
  return (
    <Wrap>
      {plan_benefits && plan_benefits.length ? plan_benefits.map(feature => (
        <FeatureItem key={feature.id}>
          <span>
              <i className="fa fa-check"></i>
          </span>
          <p>
            {`${feature.text || "" } ${feature.benefit_text}`}
          </p>
        </FeatureItem>
      )) : (
        <FeatureEmptyState>
          <img src={require("./../../../images/soul/empty-box.png")} alt=""/>
          <p>
            Please select a plan to see it's benefits
          </p>
        </FeatureEmptyState>
      )}
    </Wrap>
  )
}

export default FeaturesPanel