import React from "react"
import {
  Wrap,
  HeaderTitle,
  HeaderDescription,
} from "../commonStyles"
import {
   Button
} from "antd"
import root from "window-or-global"

const HeaderSection = () => (
  <Wrap>
    <HeaderTitle>
      AI Responses
    <Button type="primary" style={{
       marginLeft: "1010px",
       width: 90,
       height: 42,
       background: "#100063",
      color: "white", }} 
      onClick={() => {
        root.open("/broker/upload-ai-documents")
      }}
      > Training </Button>
    </HeaderTitle>
    <HeaderDescription>
      Feedback on AI responses from users
    </HeaderDescription>
  </Wrap>
)

export default HeaderSection
