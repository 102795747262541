import React from "react"
import styled from "styled-components"
import { Divider } from "dumbComponents/common/UI/antd"
import colors from "../../../../lib/colors"

const CheckboxWrap = styled.div`
  padding: 0 20px 0 10px;
`
const CheckboxContainer = styled.div`
  display: flex;
`

const LocalCheckBox = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #303030;
  border-radius: 4px;
  margin-right: 12px;
  ${props => props.disabled && `
    border: 1px solid #C7C7C7;
  `}
`

const CheckboxLabelText = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  color: ${colors.black};
  text-transform: uppercase;
  ${props => props.disabled && `
    color: #C7C7C7;
  `}
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
  .ant-divider-inner-text {
    font-size: 12px;
    font-weight: 300;
    color: red;
  }
`

const Sidebar = () => (
  <>
    <CheckboxWrap>
      <CheckboxContainer>
        <LocalCheckBox />
        <CheckboxLabelText>Cover sheet</CheckboxLabelText>
      </CheckboxContainer>
      <StyledDivider orientation="left" orientationMargin="0">
        COMING SOON
      </StyledDivider>
      <CheckboxContainer>
        <LocalCheckBox disabled />
        <CheckboxLabelText disabled>Cover Letter</CheckboxLabelText>
      </CheckboxContainer>
      <StyledDivider />
      <CheckboxContainer>
        <LocalCheckBox disabled />
        <CheckboxLabelText disabled>Documents</CheckboxLabelText>
      </CheckboxContainer>
      <StyledDivider />
      <CheckboxContainer>
        <LocalCheckBox disabled />
        <CheckboxLabelText disabled>Pre Qualification</CheckboxLabelText>
      </CheckboxContainer>
      <StyledDivider />
      <CheckboxContainer>
        <LocalCheckBox disabled />
        <CheckboxLabelText disabled>Proof of Funds</CheckboxLabelText>
      </CheckboxContainer>
      <StyledDivider />
      <CheckboxContainer>
        <LocalCheckBox disabled />
        <CheckboxLabelText disabled>Signed Disclosures</CheckboxLabelText>
      </CheckboxContainer>
    </CheckboxWrap>
  </>
)

export default Sidebar
