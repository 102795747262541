import React from "react"
import styled from "styled-components"
import { get, isEmpty } from "lodash"
import { getInterval } from "./../helper"

const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const AddonWrap = styled.div`
  text-align: left;
  width: 30%;
  background: #F1EEFF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px 10px 20px 80px;
  margin: 20px 0px;
  cursor: pointer;
  position: relative;
  transition-duration: 0.1s;
  align-items: flex-start;

  ${props => props.isSelected && `
    border: 3px solid #100063;
  `}
  h3 {
    font-size: 14px;
    margin: 0px;
    padding: 0px;
  }
  h1 {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  h6 {
    margin: 0px;
  }

  p {
    display: inline-block;
    background: #100063;
    color: #F1EEFF;
    font-size: 12px;
    padding: 5px;
    border-radius: 3px;
  }
`

const IconWrap = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  background: red;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  font-size: 20px;
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #F1EEFF;

  ${props => props.isSelected && `
    background: #100063;
  `}
`

export const getUnitPrice = (addon) => {
  const { type } = addon
  if (type === "RECURRING" || type === "DISTINCT") {
    return get(addon, "plan_details[0].unit_price")
  }
  return get(addon, "unit_price")
}

const RenderAddon = ({
  handleClick,
  feature,
  selectedAddons,
  addons,
  addOnPlanIdsToAdd,
  addOnPlanIds,
  addOnsSelected,
}) => {
  const selectedIndex = selectedAddons.findIndex(x => x.id === feature.id)
  const isSelected = selectedIndex !== -1
  if (feature.name === "E&O Insurance") {
    const { plan_details } = feature
    return (
    <>
      {plan_details.map(insurance => (
        <AddonWrap
          key={insurance.id}
          onClick={() => addOnPlanIdsToAdd({ plan_id: insurance.plan_id, id: insurance.id, price: insurance.unit_price })}
          isSelected={addOnPlanIds.some(el => el.plan_id === insurance.plan_id)}
        >
          <IconWrap
            isSelected={addOnPlanIds.some(el => el.plan_id === insurance.plan_id)}
          >
            <i className="fa fa-check"></i>
          </IconWrap>
          <h3>
            {feature.name}
          </h3>
          <h1>
            {`${insurance.unit_price}`}
          </h1>
          <h6>
            {insurance.billing_cycle}
          </h6>
        </AddonWrap>
      ))}
    </>
    )
  }
  return (
    <AddonWrap
      key={feature.id}
      onClick={handleClick}
      isSelected={addOnsSelected.some(el => el.id === feature.id)}
    >
      <IconWrap
        isSelected={isSelected}
      >
        <i className="fa fa-check"></i>
      </IconWrap>
      <h3>
        {feature.name}
      </h3>
      <h1>
        {`$${getUnitPrice(feature)}`}
      </h1>
      <h6>
        {getInterval(feature.plan_details)}
      </h6>
      <p>
        {feature.type}
      </p>
    </AddonWrap>
  )
}


const Addones = ({
  planBenefitsResponse,
  selectAddon,
  selectedAddons,
  addons,
  addOnPlanIdsToAdd,
  addOnPlanIds,
  addOnsSelected,
  highlightSelectedAddon,
  addOnsRemoved,
}) => {
  const { data, isFetching } = planBenefitsResponse
  return (
    <Wrap>
      {(!isFetching && data && data.length > 0) ? data.map(planBenefits => (
        <RenderAddon
          key={planBenefits.id}
          feature={planBenefits}
          handleClick={() => {
            const isSelected = addOnsSelected.some(el => el.id === planBenefits.id)
            if (isSelected) {
              const payload = {
                removeAddOn: true,
                planBenefits,
              }
              highlightSelectedAddon(payload)
            } else {
              highlightSelectedAddon(planBenefits)
            }
          }}
          selectedAddons={selectedAddons}
          addons={addons}
          addOnPlanIdsToAdd={addOnPlanIdsToAdd}
          addOnPlanIds={addOnPlanIds}
          addOnsSelected={addOnsSelected}
        />
      )) : (
        <React.Fragment>
          {isEmpty(data) ? (
            <p>
              Select a plan to see Addons
            </p>
          ) : (
            <p>
              Loading..
            </p>
          )}
        </React.Fragment>
      )}
    </Wrap>
  )
}

export default Addones