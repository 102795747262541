import { takeLatest, call, put, select } from "redux-saga/effects"
import deepMerge from "deepmerge"
import { notify } from "react-notify-toast"
import { isSuccess } from "services/Utils"
import * as ZillowMergeActions from "./../ZillowLeadMerge/actions"
import { isEmpty } from "lodash"
import * as Actions from "./actions"
import * as API from "./api"

function* fetchClientTimeline(action) {
  try {
    const {
      clientId,
      limit,
      offset,
      isFetchingMore,
      agentId,
    } = action.data
    const res = yield call(API.fetchClientTimelineAPI, clientId, limit, offset, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      if (isFetchingMore) {
        const { activities: newActivities, entities: newEntities, ...others } = response
        if (isEmpty(newActivities)) {
          yield put(Actions.fetchedAllTimelineEventsAction.call())
          return
        }
        const oldTimeline = yield select(state => state.clientProfile.timeline.data)
        const mergedRes = {
          activities: [
            ...oldTimeline.activities,
            ...newActivities,
          ],
          entities: deepMerge(newEntities || {}, oldTimeline.entities || {}),
        }
        yield put(Actions.fetchClientTimelineAction.success({
          ...mergedRes,
          ...others,
          isFetchingMore: false,
        }))
      } else {
        yield put(Actions.fetchClientTimelineAction.success(response))
      }
    }
  } catch (error) {
    yield put(Actions.fetchClientTimelineAction.failure(error))
  }
}

function* fetchReferralsForMerge(action) {
  try {
    const { payload } = action.data
    const res = yield call(API.fetchReferralForMergeAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ZillowMergeActions.fetchReferalForMergeAction.success(response))
    }
  } catch (error) {
    yield put(ZillowMergeActions.fetchReferalForMergeAction.failure(error))
  }
}

function* handleZillowLeadsMerge(action) {
  try {
    const { payload } = action.data
    const res = yield call(API.mergeZillowLeadsAPI, payload)
    yield put(ZillowMergeActions.toggleZillowMergeModalAction.call(false))
    notify.show("Merging Leads in progress.", "warning", 30000)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ZillowMergeActions.mergeZillowLeadsAction.success(response))
      //notify.hide()
    }
  } catch (error) {
    yield put(ZillowMergeActions.mergeZillowLeadsAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(Actions.fetchClientTimelineAction.REQUEST, fetchClientTimeline)
  yield takeLatest(ZillowMergeActions.fetchReferalForMergeAction.REQUEST, fetchReferralsForMerge)
  yield takeLatest(ZillowMergeActions.mergeZillowLeadsAction.REQUEST, handleZillowLeadsMerge)
}
