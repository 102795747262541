import styled from "styled-components";

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  margin-top: 1rem;
  h3 {
    font-size: 24px;
    font-weight: 500;
    color: #354052;
  }
  button {
    height: 55px;
    border-radius: 5px;
    padding: 15px;
    background-color: #04b1fa;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
  }
`;

export default TitleBox;