import React from "react"
import styled from "styled-components"
import SendEmailModal from "./SendEmailModal"

const Wrap = styled.div`
  position: absolute;
  top: 80px;
  width: 40px;
  right: -40px;
  
`

const Option = styled.div`
  cursor: pointer;
  text-align: center;
  right: -40px;
  height: 40px;
  width: 40px;
  margin-top: 10px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: yellow;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #34ACE1;
  color: #FFF;

  h6 {
    margin: 0px;
    font-size: 10px;
    font-weight: normal;
  }
`

const Tools = ({
  handleExport,
  handleReset,
  handleOpenNewTab,
  toggleEmailModal,
  isSendEmailModalOpen,
}) => (
  <Wrap>
    <Option onClick={handleReset}>
      <i className="fa fa-refresh"></i>
      <h6>Reset</h6>
    </Option>
    <Option onClick={handleExport}>
      <i className="fa fa-floppy-o"></i>
      <h6>Export</h6>
    </Option>
    <Option onClick={handleOpenNewTab}>
      <i className="fa fa-external-link"></i>
      <h6>Demo</h6>
    </Option>
    <Option onClick={() => { toggleEmailModal(true) }}>
      <i className="fa fa-envelope-o"></i>
      <h6>Send Email</h6>
    </Option>
    <SendEmailModal
      toggleEmailModal={toggleEmailModal}
      isOpen={isSendEmailModalOpen}
    />
  </Wrap>
)

export default Tools
