import React, { useState } from "react"
import styled from "styled-components"
import { Input } from "antd"
import { getPromptMessage } from "../Utils"

const {
  TextArea,
} = Input

const Wrap = styled.div``

const InputBox = (props) => {
  const [reason, setReason] = useState("")
  const { type, setModalReasonTextValue } = props
  const message = getPromptMessage(props)

  const handleReasonChange = (e) => {
    setReason(e.target.value)
    setModalReasonTextValue(e.target.value)
  }

  return (
    <Wrap>
      <p>{message}</p>
      {type === "declined" && (
        <TextArea
          name="modal_reason"
          rows={4}
          placeholder="State reason"
          maxLength={120}
          value={reason}
          onChange={handleReasonChange}
          style={{
            position: "relative",
            top: "10px",
          }}
        />
      )}
    </Wrap>
  )
}

export default InputBox
