import { useState } from "react"

export const useInput = (initialValue, validator) => {
  const [value, setValue] = useState(initialValue)
  const [isError, setError] = useState(false)

  return {
    value,
    setValue,
    reset: () => setValue(""),
    error: isError,
    bind: {
      value,
      onChange: (event) => {
        setValue(event.target.value)
        if (validator && typeof validator === "function") {
          setError(validator(event.target.value))
        }
      },
    },
  }
}
