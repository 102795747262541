import styled from "styled-components"
import Button from "dumbComponents/common/UI/Button"
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import Container from "container/RecruitAndEarn"
import NumberFormat from 'react-number-format';
import agentSearchContainer from "container/BrokerDashboard/agentSearchContainer"
import Loader from "dumbComponents/common/UI/Loader"
import { get, join } from "lodash"
import TextInput from "dumbComponents/BrokerDashboard/Comp/TextInput"
import moment from "moment"
import Select from "react-select"
import {Row, Col} from "antd"
import Text from "antd/lib/typography/Text"

const Wrap = styled.div`
  margin: 20px;
  max-width: 90%;
`


const SearchInput = styled.input`
  flex: 1;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid #ccc;
  width: 30%;

  ${props => props.isError && `
    border: 1px solid red;
  `}
`
const AgentList = styled.div`
  position: relative;
  width: 30%;
  top: 20px;
  left: 0px;
  right: 0px;
  min-height: 200px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #ccc;
  z-index: 1;
`

const AgentItem = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  &:hover {
    background: #eee;
    transition-duration: 0.3s;
    cursor: pointer;
  }
  > div {
    &:nth-child(1) {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
      background: #eee;
      > img {
        width: 100%;
      }
    }
    &:nth-child(2) {
      margin-left: 20px;
      p, h6 {
        padding: 0px;
        margin: 0px;
      }
      h6 {
        font-weight: 400;
        color: #999;
        font-size: 12px;
      }
      > input {
        color: #999;
        border: none;
        font-size: 12px;
        pointer-events: none;
        padding: 0px;
        background: transparent;
      }
    }
  }
`
const SectionWrap = styled.div`
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #ccc;
  position: relative;
  margin-left: 10%
  margin-bottom: 40px;

  @keyframes breath-animation {
    0% { background: transparent; border: 1px solid #ccc; }
    50% { background: #ffcecb; border: 1px solid #fd6459; }
  }

  ${props => props.isAnimate && `
    animation-name: breath-animation;
    animation-duration: 1.5s;
    animation-iteration-count: 3;
  `}

  > h6 {
    top: -16px;
    left: 40px;
    position: absolute;
    background: #fff;
    padding: 5px 16px;
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
    color: #999;
  }
`

const SectionWrapInside = styled.div`
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #ccc;
  position: relative;
  margin-left: 2%
  margin-bottom: 40px;

  @keyframes breath-animation {
    0% { background: transparent; border: 1px solid #ccc; }
    50% { background: #ffcecb; border: 1px solid #fd6459; }
  }

  ${props => props.isAnimate && `
    animation-name: breath-animation;
    animation-duration: 1.5s;
    animation-iteration-count: 3;
  `}

  > h6 {
    top: -16px;
    left: 40px;
    position: absolute;
    background: #fff;
    padding: 5px 16px;
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
    color: #999;
  }
`


const RecordInvite = ({
    agents,
    fetchAgentSearch,
    onAgentSelect,
    history,
    isAgentNotSelected,
    resetSelections,
    clearAgent,
    createNewInvite,
    fetchInviteById,
    editInviteById,
    match,
    ...props
  }) => {
    
    const [ searchText, setSearchText ] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState(null)
    const [isAccepted, setIsAccepted] = useState("")
    const [referralDate, setReferralDate] = useState("")

    const [referrerFirstName, setReferrerFirstName] = useState("")
    const [referrerLastName, setReferrerLastName] = useState("")
    const [referrerEmail, setReferrerEmail] = useState("")
    const [referrerPhone, setReferrerPhone] = useState(null)
    const [senderAgentId, setSenderAgentId] = useState(null)
    const [ inviteType, setinviteType] = useState("")
    
    useEffect( () => {
      if(match.params.inviteId) {
        fetchInviteById({
          id: match.params.inviteId,
          callback : (values) => { 
            setFirstName(values[0].firstname)
            setLastName(values[0].lastname)
            setEmail(values[0].email)
            setPhone(values[0].phone)
            setIsAccepted(values[0].is_accepted === 1 ? "Invite Accepted" : "Invite Pending")
            setReferralDate(values[0].created_at)

            setReferrerFirstName(values[0].referrer_firstname)
            setReferrerLastName(values[0].referrer_lastname)
            setReferrerEmail(values[0].referrer_email)
            setReferrerPhone(values[0].referrer_phone)
            setSenderAgentId(values[0].sender_id)
            setinviteType(values[0].invite_type)
          }
        })
      }
    },[])

    const handleSearchType = (value) => {
        setSearchText(value)
        fetchAgentSearch({ search: value })
    }

    const setReferraldateChecker= (date) => {
      if(isNaN(date) == false) {
        setReferralDate("")
      } 
    }

    const clearAllSelection = () => {
        setSenderAgentId(null)
        setReferrerFirstName("")
        setReferrerLastName("")
        setReferrerEmail("")
        setReferrerPhone(null)
    }

    const handleSaveInvite = () => {
        try{
          if(!referrerEmail) {
            alert("Referring Agent Required")
          } else 
          if(!firstName || !email || !phone) {
            alert("Referred Agent details Required")
          } else
          if(!isAccepted) {
            alert("Is Invite Accepted Required")
          } else
          if(!referralDate) {
            alert("Date of Referral is Required")
          } else
          if(!inviteType) {
            alert("Please Select Invite Type")
          } else 
          if(!match.params.inviteId)
            {
              createNewInvite({
                payload: {
                    senderId: senderAgentId,
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    email: email,
                    isAccepted: isAccepted === "Invite Pending" ? "no" : "yes",
                    referralDate: referralDate,
                    inviteType: inviteType,
                }
            })
            history.push("/broker/refer-a-friend")
          } else
          if(match.params.inviteId)
            {
              editInviteById({
                id: match.params.inviteId,
                payload: {
                    senderId: senderAgentId,
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    email: email,
                    isAccepted: isAccepted === "Invite Pending" ? "no" : "yes",
                    referralDate: referralDate,
                    inviteType: inviteType,
                }
            })
            history.push("/broker/refer-a-friend")
          }
        } catch(e) {
            console.log(e)
            }
    }

    const { isFetching, data } = agents
    const agentsLists = get(data, "agents", [])

    return (
      <Wrap>
        <SectionWrap>
            <h6>
                Invite Details
            </h6>
        <SectionWrapInside>
             <h6>
                Referring Agent Details
            </h6>
            <SearchInput
                type="text"
                isError={isAgentNotSelected}
                value={searchText}
                placeholder="Search Referring Agent"
                onChange={(e) => { handleSearchType(e.target.value) }}
            />
        {searchText && (
         <AgentList>
          {isFetching ? (
            <Loader width="25px" top={0} bottom={0} />
          ) : (
            <React.Fragment>
              {agentsLists && agentsLists.length > 0 && agentsLists.map(agent => (
                <AgentItem
                  key={agent.id}
                  onClick={() => {
                    setSenderAgentId(agent.id)
                    setReferrerFirstName(agent.firstname)
                    setReferrerLastName(agent.lastname)
                    setReferrerEmail(agent.email)
                    setReferrerPhone(agent.phone)
                    resetSelections()
                    clearAgent()
                    setSearchText("")  
                }}
                >
                  <div>
                    <img src={agent.agent_image} alt=""/>
                  </div>
                  <div>
                    <p>
                      {`${agent.firstname} ${agent.lastname}`}
                    </p>
                    <h6>
                      {agent.email}
                    </h6>
                    <NumberFormat format="+1 (###) ###-####" mask="_" value={agent.phone}/>
                  </div>
                </AgentItem>
              ))}
            </React.Fragment>
          )}
        </AgentList>
        )}
        <Button style={{ padding: 10, marginLeft: 20 }} onClick={clearAllSelection}>
              Clear  
        </Button>
            { referrerEmail && 
                <Row gutter={50} style={{paddingTop: '10px'}}>
                 <div style={{padding: '25px'}}>
                  <div>
                    <Text>{` Name : `}  {referrerFirstName ? referrerFirstName : ""}   {referrerLastName}</Text>
                  </div>
                  <br/>
                  <div>
                  <Text>{` Email Address : `}  {referrerEmail ? referrerEmail : "N/A"}</Text>
                  </div>
                  <br/>
                  <div>
                  <Text>{` Phone : `}  {referrerPhone ? referrerPhone : "N/A"}</Text>
                  </div>
                  </div>
                </Row>
                
            }
        </SectionWrapInside>
            
        <SectionWrapInside>
            <h6>
            Referred Agent Details
            </h6>
        <Row gutter={50}>
                <Col>
                <TextInput
                   placeholder="First Name" 
                   type = "text"
                   value = {firstName}
                   onChange= {(e) => {
                    setFirstName(e.target.value)
                   }}       
                />
                </Col>
                <Col>
                <TextInput
                  type = "text"
                  value = {lastName}
                   placeholder=" Last Name (Optional)" 
                   onChange= {(e) => {
                    setLastName(e.target.value)
                   }}         
                />
                </Col>
                <Col>
                <TextInput
                   type = "text"
                   value = {email}
                   placeholder=" Email Address " 
                   onChange= {(e) => {
                    setEmail(e.target.value)
                   }}         
                />
                </Col>
                <Col>
                <TextInput
                   type = "text"
                   value = {phone}
                   placeholder=" Phone Number "  
                   onChange= {(e) => {
                    setPhone(e.target.value)
                   }}        
                />
                </Col>
            </Row>
        </SectionWrapInside>

        <SectionWrapInside>
            <h6>
                Other Details
            </h6>
            <Row gutter={40}>
            <Col>
            <div style={{width: '250px',paddingBlock: '35px'}}>
             <Select
                 placeholder={isAccepted ? isAccepted : "Is Invite Accepted?"}
                 options={[
                   { label: "Invite Accepted", value: "Invite Accepted" },
                   { label: "Invite Pending", value: "Invite Pending" },
                 ]}
                 onChange={(value) => {
                    setIsAccepted(value.value)
                  }}
               />
             </div>
            </Col>

            <Col>
            <div style={{width: '250px'}}>
                <TextInput
                 type="date"
                 label="Date Of Referral"
                 value={moment(referralDate).format("YYYY-MM-DD")}
                 fullWidth
                 fullHeight
                 onChange={(e) =>{
                    setReferralDate(moment(e.target.value).format("YYYY-MM-DD 00:00:00"))
                    setReferraldateChecker(e.target.value)
                 }}
                />
            </div>
            </Col>
            <Col>
            <div style={{width: '250px',paddingBlock: '35px'}}>
             <Select
                 placeholder={inviteType ? inviteType === "community" ? "Community" : "Brokerage" : "Select Invite Type"}
                 options={[
                   { label: "Community", value: "community" },
                   { label: "Brokerage", value: "brokerage" },
                 ]}
                 onChange={(value) => {
                  setinviteType(value.value)
                  }}
               />
             </div>
            </Col>
            </Row>
        </SectionWrapInside>
        
        <Button success style={{float: 'right'}} onClick={handleSaveInvite}>
            Save Invite
        </Button>

      </SectionWrap>
        </Wrap>
    )
  }

export default Container(agentSearchContainer(withRouter(RecordInvite)))
