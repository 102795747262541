import React from "react"
import Select from "react-select"
import styled from "styled-components"
import { isArray, isObject } from "lodash"

const ErrorLabel = styled.span`
  color: #ff4b4b;
  font-size: 12px;
`
const Label = styled.label`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #d4d4d4;
  margin: 5px 0px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 10px;
  color: #A3A3A3;
  letter-spacing: 2px;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.5;
  > div > div > div {
    border: 1px solid #ED1616 !important;
  }
`

const checkForValue = (val = "", isMulti) => {
  if (!val) {
    return null
  }
  if (isMulti) {
    return val
  }
  if (isArray(val)) {
    return { value: val[0], label: val[0] }
  }
  if (!isObject(val)) {
    return { value: val, label: val }
  }
  return val
}

const customStyles = {
  singleValue: (provided) => {
    const overflow = "initial"
    return { ...provided, overflow }
  },
}

const RenderSelect = (field) => {
  const {
    input: { onFocus } = {},
    input,
    options,
    config = {},
    placeholder,
    meta,
    label,
    defaultValue,
    width,
    components,
    selectStyles,
    labelStyles = {},
    errorStyles,
    disabled,
  } = field
  return (
    <div onFocus={onFocus} w={width}>
      {label && <Label style={labelStyles}>{label}</Label>}
      <Select
        {...config}
        value={checkForValue(input.value, config.isMulti)}
        onChange={(item) => {
          input.onChange(item)
        }}
        onBlur={() => input.onBlur(input.value)}
        options={options}
        placeholder={placeholder}
        defaultValue={defaultValue}
        styles={meta.touched && meta.error ? errorStyles : (selectStyles || customStyles)}
        components={components}
        isDisabled={disabled}
      />
      {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
    </div>
  )
}

export default RenderSelect
