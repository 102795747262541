import React from "react"
import styled from "styled-components"
import {
  SectionTitle,
} from "./commonStyles"
import DocCard from "./DocCard"
import { GetStatusIcon } from "./mappings"
import { TeamFileUpload } from "./index"

const ComponentWrap = styled.div`
  width: 100%;
`

const DocBlock = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 10px;
`

const ListAgreements = ({
  teamAgreements,
  type,
  handleUpload,
  data,
  showUpload,
  editViewOnly,
  isTeamMemberDashboard,
  templateDownload,
}) => (
  <ComponentWrap>
    <DocBlock>
      {showUpload ? (
        <TeamFileUpload
          uploadFunction={handleUpload}
          data={data}
        />
      ) : (
        <>
          {!isTeamMemberDashboard && teamAgreements && teamAgreements.length <= 0 ? (
          <>
            {type === "upload" ? (
              <TeamFileUpload
                uploadFunction={handleUpload}
                data={data}
              />
            ) : (
              <p>No Team Agreements</p>
            )}
          </>
          ) : teamAgreements && teamAgreements.length <= 0 && type === "upload" && (
            <p>No Custom Team Agreement uploaded</p>
          )}
          {teamAgreements && teamAgreements.length > 0 && teamAgreements.map(doc => (
            <DocCard
              doc={doc}
              // icon={GetStatusIcon(docStatus)}
              noIcon
              noView
              editViewOnly={editViewOnly}
              templateDownload={templateDownload}
              // download={download}
            />
          ))}
        </>
      )}
    </DocBlock>
  </ComponentWrap>
)

export default ListAgreements
