import React, { Component } from "react"
import fetch from 'isomorphic-fetch'
import bugsnag from 'bugsnag-js'
import moment from 'moment';
import Notifications, { notify } from 'react-notify-toast';
import { console } from "window-or-global";
const baseUrl = process.env.REACT_APP_API_BASE_URL
const token = process.env.REACT_APP_SECRET_ID

const originalKey = process.env.REACT_APP_BUGSNAG_KEY
const bugsnagClient = bugsnag(originalKey)

class RAR extends Component {
  state = {
   rarUsers: [],
   saving: {},
   sortOrder: false,
  };

  getRARUsers = async () => {
    try {
        let response = await fetch(baseUrl + '/v2.0/rar/agents', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'internal-id': token,
            },
        });
        const rarUsers = await response.json();
       
        this.setState({rarUsers});
    } catch (e) {
        bugsnagClient.notify(e);
    }
  };


  updateScore = async (agent) => {
    try {
        let saving = this.state.saving;
        saving[agent.id] = true;
        this.setState({saving})

        let body = JSON.stringify({deals : parseInt(agent.deals, 10)})
        let response = await fetch(baseUrl + `/v2.0/rar/sales/${agent.id}`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'internal-id': token,
            },
            body
        });
        saving = this.state.saving;

        saving[agent.id] = true;
        delete saving[agent.id];
        this.setState({saving})   
        notify.show('Update sales count for ' + (agent.firstname || "") + " " + (agent.lastname || ""), 'success', 2000);
    
    } catch (e) {
        notify.show('Could not update sales count for ' + (agent.firstname || "") + " " + (agent.lastname || ""), 'error', 2000);

        let saving = this.state.saving;

        saving[agent.id] = true;
        delete saving[agent.id];
        this.setState({saving})
        bugsnagClient.notify(e);
    }


  }

  componentDidMount() {
      this.getRARUsers();
   }

   updateAgentScore = (e, agentId) => {

       let currentAgent = this.state.rarUsers.find((agent) => agent.id === agentId);
       if (currentAgent){
           let rarUsers = this.state.rarUsers
           rarUsers  = rarUsers.map((agent) => {
               if (agent.id === currentAgent.id){
                   agent.deals = parseInt(e.target.value, 10);
               }
               return agent;

           })
           this.setState({rarUsers});
       }

   }

   getAgentsDiv = () => {
       return this.state.rarUsers.map((agent) =>  <tr >
       <td>{agent.firstname || ""}&nbsp;{agent.lastname},&nbsp; RAR since&nbsp;{moment.unix(agent.created_at).format("MM/DD/YYYY")}</td>
       <td>{agent.email}</td>
       <td>{Math.ceil(((new Date().getTime()/1000) - agent.created_at)/(3600*24*30))}</td>
       <td>{agent.projected_deals || ""}</td>

       <td>
            <input onChange={(e) => this.updateAgentScore(e, agent.id)} value={agent.deals || ""}/>
       </td>
       <td  >
           <button onClick={() => { this.updateScore(agent);}} className="action-button"  >
             Save
            </button>
        </td>
   </tr>)
   }

   sort = (parameter) => {
       console.log("heree");
       let order = '';
       if (!this.state.sortOrder){
            order = 'asc';
           this.setState({sortOrder: true})
       }
       else{
            order = 'desc';

            this.setState({sortOrder: false})
       }
       let agents = this.state.rarUsers;
       if (order === 'asc'){
            agents.sort((a, b) => {
                let fa = a[parameter]
                let    fb = b[parameter]
            
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
       }
       else{
        agents.sort((a, b) => {
            let fa = a[parameter]
            let   fb = b[parameter]
        
            if (fa > fb) {
                return -1;
            }
            if (fa < fb) {
                return 1;
            }
            return 0;
        });

       }

       this.setState({rarUsers: agents});
   }

 
  render() {

        const agentsDiv = this.state.rarUsers.length > 0 ? this.getAgentsDiv() : null;
    
        return <div style={{ paddingTop: "60px" }}>
            <Notifications/>
            <table className="dashboard">
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Months at Radius Agent Realty <span onClick={() => this.sort("created_at")}> 
                                    <i class="fa fa-fw fa-sort"></i>
                                </span></th>
                            <th>Estimated Yealy Sales <span   onClick={() => this.sort("projected_deals")}> 
                                    <i class="fa fa-fw fa-sort"></i>
                                </span></th>
                            <th>Sales  <span  onClick={() => this.sort("deals")}> 
                                    <i class="fa fa-fw fa-sort"></i>
                                </span></th>
                            <th>Action</th>
                        </tr>
                        {agentsDiv}
            </table>
        </div>

    }

}
export default RAR;
