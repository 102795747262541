import React, { PureComponent } from "react"
import styled from "styled-components"
import colors from "../../../../lib/colors"
import TechAssetsContainer from "container/TechAssets"
import { get, update } from "lodash"
import root, { setTimeout } from "window-or-global"
import { CloseCircleOutlined } from "@ant-design/icons"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
import {
  Input,
  InputNumber,
  Form,
  Divider,
  Button,
  GoogleAutoComplete,
  StyledTextArea,
} from "dumbComponents/common/UI/antd"
import {
  //LenderInfoStep,
  PageArray,
  LenderSelectRoute,
  CreateLenderStep,
} from "../../consts"
import {
  SubmitForm,
  getDealId,
  UpdateVendorOrLender,
  setStreet,
  normalizePhone,
  getAgentId,
} from "../../commonUtils"
import Sidebar from "../Sidebar"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const HeaderNav = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LabelText = styled.h2`
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`

const Required = styled.span`
  color: #E34C4C
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    margin: 0px;
  }
`

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  state = {
    showCoListingform: [1],
    cityFormValue: {},
  }

  formRef = React.createRef()

  componentDidMount() {
    const {
      history,
    } = this.props

    const {
      location: {
        pathname,
      },
    } = history || {}

    this.setState({
      isUpdating: pathname.includes("update-lender"),
    })
  }

  onFinish = (fieldValues, isDraft) => {
    const {
      history,
      updateOffer,
      createOffer,
      updateLender,
      currentVendorToEdit,
    } = this.props

    const {
      isUpdating,
    } = this.state

    const currentStep = CreateLenderStep
    const agentId = getAgentId() || false

    if (!isUpdating) {
      SubmitForm(
        fieldValues,
        isDraft,
        createOffer,
        updateOffer,
        history,
        LenderSelectRoute,
        currentStep,
        null,
        false,
        true,
        false,
        "lender,"
      )
    } else {
      const {
        id,
      } = currentVendorToEdit || {}
      UpdateVendorOrLender(
        fieldValues,
        isDraft,
        updateLender,
        history,
        LenderSelectRoute,
        id,
        false,
        agentId,
        "lender",
      )
    }
  }

  onSaveAsDraft = () => {
    this.onFinish(this.formRef.current.getFieldsValue(), true)
  }

  addAdditionalSeller = () => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform
    colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
    setTimeout(() => {
      root.scrollTo(0, 3100)
    }, 300)
  }

  setCities = (location, index) => {
    const { cityFormValue } = this.state
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    this.formRef.current.setFieldsValue({
      lender_city: `${locationDetails.city}`,
      lender_state: `${locationDetails.state}`,
      lender_country: `${locationDetails.country}`,
    })
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [`city-${index}`]: `${locationDetails.city}`,
      },
    })
  }

  setLocaCity = (value, cityFormName) => {
    const { cityFormValue } = this.state
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [cityFormName]: value,
      },
    })
  }

  getInitialValues = () => {
    const {
      currentVendorToEdit: currentLendorToEdit,
    } = this.props

    let result = {}

    if (currentLendorToEdit.id !== null) {
      const {
        data,
      } = currentLendorToEdit || {}

      result = {
        ...data,
      }
    }

    return result
  }

  render() {
    const {
      history,
      getCurrentDealDetailsResponse,
      updateOfferActionResponse,
    } = this.props
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]

    const {
      isFetching: fetchingDeal,
    } = getCurrentDealDetailsResponse || {}

    const {
      isFetching: updatingDeal,
    } = updateOfferActionResponse || {}

    const isLoading = fetchingDeal || updatingDeal

    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Lender Information
        </HeaderNav>
        <ProgressBarComponent total={PageArray} ongoingOrCompleted={7} />
        <Container>
          <LeftSideBar>
            <Sidebar />
          </LeftSideBar>
          <RightSideBar>
            {isLoading ? (
              <p>Loading</p>
            ) : (
              <Form
                name="lender_information"
                colon={false}
                layout="vertical"
                requiredMark={false}
                onFinish={this.onFinish}
                initialValues={this.getInitialValues()}
                ref={this.formRef}
                onFinishFailed={(e) => { console.log("ERROROOOO", e) }}
                scrollToFirstError
              >
                {/*Lender Company Details*/}
                <FlexBox wd="100%">
                  <Form.Item
                    name="lender_name"
                    label={<LabelComponent text="Lender Company Name" required />}
                    required
                  >
                    <Input />
                  </Form.Item>
                </FlexBox>

                {/*Lender Officer Details*/}
                <FlexBox wd="100%">
                  <Form.Item
                    name="lender_officer_first_name"
                    label={<LabelComponent text="Lender Officer First Name" required />}
                    required
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="lender_officer_last_name"
                    label={<LabelComponent text="Lender Officer Last Name" required />}
                    required
                  >
                    <Input />
                  </Form.Item>
                </FlexBox>

                {/*Contact Details*/}
                <FlexBox>
                  <Form.Item
                    name="lender_email"
                    label={<LabelComponent text="Email" required />}
                    required
                    rules={commonRules}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name="lender_phone" label={<LabelComponent text="Phone Number" required />} rules={commonRules} normalize={normalizePhone}>
                    <Input />
                  </Form.Item>
                </FlexBox>

                {/*Address Details*/}
                <FlexBox wd="100%">
                  <Form.Item name="lender_address" label={<LabelComponent text="Address" />}>
                    {/* <Input /> */}
                    <GoogleAutoComplete
                      id="cities"
                      types={["address"]}
                      name="cities"
                      // formValue={cityFormValue}
                      onChange={(e) => { }}
                      location={(loc) => { setStreet(loc, this.formRef, "lender_address", false, null, "lender") }}
                      placeholder=""
                      styles={{
                        height: "49px",
                        borderRadius: "0px",
                        background: "white",
                        fontSize: "16px",
                        marginTop: "10px",
                      }}
                    />
                  </Form.Item>
                </FlexBox>

                <FlexBox>
                  <Form.Item name="lender_city" label={<LabelComponent text="City" />}>
                    <GoogleAutoComplete
                      id="cities"
                      types={["(cities)"]}
                      name="cities"
                      // formValue={cityFormValue[`city-${index}`]}
                      onChange={(e) => { this.setLocaCity(e.target.value, "city") }}
                      location={(loc) => { this.setCities(loc) }}
                      placeholder=""
                      styles={{
                        height: "49px",
                        borderRadius: "0px",
                        background: "white",
                        fontSize: "16px",
                        marginTop: "10px",
                      }}
                      restrict={{
                        lat: 37.09024,
                        lng: -95.712891,
                        south_west: {
                          lat: 25.82,
                          lng: -124.39,
                        },
                        north_east: {
                          lat: 49.38,
                          lng: -66.94,
                        },
                        address: "United States",
                        country: {
                          long_name: "United States",
                          short_name: "US",
                          types: [
                            "country",
                            "political",
                          ],
                        },
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="lender_zipcode"
                    label={<LabelComponent text="Zip Code" />}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item name="lender_state" label={<LabelComponent text="State" />}>
                    <Input />
                  </Form.Item>
                </FlexBox>

                <FlexBox>
                  <Form.Item name="notes" label={<LabelComponent text="Notes" />}>
                    <StyledTextArea />
                  </Form.Item>
                </FlexBox>
                <FlexBox>
                  <FlexBox wd="100%">
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          color: "#849DF8",
                          padding: 0,
                          marginLeft: "-10px",
                        }}
                        size="large"
                        type="link"
                        onClick={this.onSaveAsDraft}
                      >
                        Save for Later
                      </Button>
                    </Form.Item>
                  </FlexBox>
                  <FlexBox>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "white",
                          float: "right",
                          borderColor: "#3B60E4",
                          color: "#3B60E4",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        // htmlType="submit"
                        onClick={() => { history.push(`${LenderSelectRoute}?dealId=${getDealId()}&agentId=${getAgentId()}`) }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "#3B60E4",
                          float: "right",
                          borderColor: "white",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        loading={isLoading}
                        htmlType="submit"
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </FlexBox>
                </FlexBox>
              </Form>
            )}
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)

