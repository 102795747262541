import { format } from "date-fns"
import moment from "moment"
import moneyFormatter from "shared/currencyUtils"

export const serializer = (list) => {
  //check here
  if (!list || Object.keys(list).length <= 0) return []
  return list.map((item, iter) => ({
    key: iter,
    dealId: item.id,
    agentId: item.agent_id,
    propertyName: item.apt || "N/A",
    propertyAddress: `${item.listing_address}, ${item.city || "N/A"}, ${item.state || "N/A"}, ${item.zipcode || "N/A"}`,
    clientType: item.client_type || "",
    agentName: `${item.agent_firstname} ${item.agent_lastname}`,
    preferredEmail: item.preferred_email || "-",
    primaryBuyerAgentContact: {
      phone: item.agent_phone,
      email: item.email,
    },
    coBuyerAgents: item.co_buyer_agent,
    isAllCash: item.is_all_cash,
    buyers: item.buyer_clients,
    sellers: item.seller_clients,
    price: item.purchase_price ? `$${moneyFormatter(item.purchase_price)}` : "$0",
    lastUpdated: item.updated_at ? format(new Date(item.updated_at), "MMM dd, yyyy") : "N/A",
    currentStatus: item.current_offer_status,
    offerSubmitDate: item.offer_submitted_on ? format(new Date(item.offer_submitted_on), "MMM dd, yyyy") : "N/A",
    propertyImages: item.offer_images || [],
    offerWritingOptIn: item.writing_opt_in === 0 ? "No" : "Yes",
    dateOfListingSubmission: item.date_of_listing_submission ? format(new Date(item.date_of_listing_submission), "MMM dd, yyyy") : "N/A",
    listingStatus: item.listing_status || null,
    listingImages: item.listing_images,
    activeContractId: item.active_contract_id,
    capAmount: item.cap_amount,
    listPrice: item.list_price ? `$${moneyFormatter(item.list_price)}` : "$0",
    numOfClients: item.clients ? item.clients.length : null,
    clientDetails: item.clients ? makeClientDetails(item.clients) : null,
  }))
}

export const makeClientDetails = (clients) => {
  if (clients.length>0) {
    const newClients = {}
    newClients.client_name = clients[0].name
    newClients.client_phone = clients[0].phone
    newClients.client_email = clients[0].email
    return newClients
  }
  return null
}

export const earningsSerializer = (list) => {
  if (!list || Object.keys(list).length <= 0) return []

  return list.map((item, iter) => {
    const {
      sender_agent,
      receiver_agent,
      reward,
      status,
      created_at,
      id,
      invite_id,
      milestone,
      milestone_type,
    } = item || {}

    const {
      firstname: receiverFirstName,
      lastname: receiverLastName,
    } = receiver_agent || {}

    const {
      firstname,
      lastname,
      agent_id,
    } = sender_agent || {}

    const rewardFor = milestone_type === "deal"
      ? `${ordinalSuffix(milestone)} Deal Closed`
      : "Joined Brokerage"

    const receiverName = `${receiverFirstName} ${receiverLastName}`

    const created = created_at.split(" ")[0]

    const expandedText = milestone_type === "deal"
      ? `${receiverName} closed the ${ordinalSuffix(milestone)} deal`
      : `${receiverName} joined the brokerage`

    return {
      key: iter,
      agentName: `${firstname} ${lastname}`,
      receiver: `${receiverName}, ${rewardFor || "unknown"}`,
      reward,
      amount: milestone_type === "deal" ? reward : "-",
      shares: milestone_type === "join" ? reward : "-",
      tags: [status],
      inviteId: invite_id,
      senderId: agent_id,
      milestoneType: milestone_type,
      id,
      milestone,
      expandedText,
      created,
    }
  })
}

export const detailsSerializer = (list) => {
  if (!list || Object.keys(list).length <= 0) return []

  return list.map((item, iter) => {
    const {
      firstname,
      lastname,
      email,
      phone,
      total_recruited,
      total_referred,
      pending_stocks,
      earned_stocks,
      pending_rewards,
      earned_rewards,
      total_rewards,
    } = item || {}

    const phoneNumber = (phone === null) ? "-" : phone
    const emailID = (email === null) ? "-" : email

    return {
      key: iter,
      agentName: `${firstname} ${lastname}`,
      email,
      phoneNumber,
      total_recruited,
      total_referred,
      pending_stocks,
      earned_stocks,
      pending_rewards,
      earned_rewards,
      total_rewards,
      emailID,
    }
  })
}

export const ordinalSuffix = (i) => {
  const j = i % 10
  const k = i % 100
  if (j === 1 && k !== 11) {
    return `${i}st`
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`
  }
  return `${i}th`
}

export const handleDrawerActions = (
  action,
  payload,
  toggler,
  togglePayload,
) => {
  if (action && payload)action(payload)
  if (toggler && togglePayload)toggler(togglePayload)
}

export const convertAtob = (a) => {
  let b; let c; let d; const e = {}; let f = 0; let g = 0; let h = ""
  const i = String.fromCharCode
  const j = a.length
  // eslint-disable-next-line no-plusplus
  for (b = 0; b < 64; b++) e["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/".charAt(b)] = b
  for (c = 0; j > c; c++) for (b = e[a.charAt(c)], f = (f << 6) + b, g += 6; g >= 8;) ((d = 255 & f >>> (g -= 8)) || j - 2 > c) && (h += i(d))
  return h
}

export const convertToHTML = (data) => {
  let localData = data
  try {
    localData = decodeURIComponent(escape(convertAtob(data)))
  } catch (e) {
    localData = data
  }
  return localData
}

export const getPromptMessage = (details) => {
  const {
    type,
    agentName,
    milestoneType,
    reward,
  } = details || {}

  switch (type) {
    case "pending":
      return `Updating status for ${agentName}'s 
      ${milestoneType === "deal" ? `reward of $${moneyFormatter(reward, 1)}` : "transferrable stocks"} for referred agent's
      ${milestoneType === "deal" ? "last deal closed" : "joining of Radius Brokerage Service"} to pending.`
    case "declined":
      return `Declining ${agentName}'s 
      ${milestoneType === "deal" ? `reward of $${moneyFormatter(reward, 1)}` : "transferrable stocks"} for referred agent's
      ${milestoneType === "deal" ? "last deal closed" : "joining of Radius Brokerage Service"}.`
    case "approved":
      return `Approving ${agentName}'s 
      ${milestoneType === "deal" ? `reward of $${moneyFormatter(reward, 1)}` : "transferrable stocks"} for referred agent's
      ${milestoneType === "deal" ? "last deal closed" : "joining of Radius Brokerage Service"}.`
    case "credited":
      return `Crediting ${agentName}'s with reward of $${moneyFormatter(reward, 1)} for referred agent's
      last deal closed.`
    default:
      return false
  }
}

export const getISTTime = date => moment.unix(new Date(date * 1000).getTime() / 1000).format("DD/MM/YYYY HH:mm:ss")
