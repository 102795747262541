import React from "react"
import styled from "styled-components"
import { format } from "date-fns"
import { unixToDate } from "services/Utils"
import { Title, Note } from "./common"

const Wrap = styled.div``

const Reminder = ({
  entityName,
  created_at,
  date,
  type,
  note,
  ...props
}) => (
  <Wrap>
    {/* <Title type={entityName} time={created_at} /> */}
    <Note>
      <strong>{type}</strong>
      <span>{date && ` at ${format(unixToDate(date), "ddd, do MMM h:mm a")}`}</span>
    </Note>
    {note && (
      <Note style={{ marginTop: 5 }}>
        <span>{note}</span>
      </Note>
    )}
  </Wrap>
)

export default Reminder
