import React from "react"
import styled from "styled-components"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"

const CircleLive = styled.div`
  display: block;
  height: 12px;
  width: 12px;
  background: red;
  border-radius: 100px;
  animation: expandAndFade 0.8s ease-out infinite;

@keyframes expandAndFade {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  
  50%{
    transform: scale(1.1);
    opacity: 0.5;
  }
  
  100% {
    transform: scale(1.3);
    opacity: 0.1;
  }
}

${props => props.hide && `
    display: none;
  `}
`

const LiveDot = ({
  hide,
}) => (
  <CircleLive hide={hide} />
)

export default subscriptionContainer(LiveDot)
