import styled from "styled-components"

export const commonWrapStyles = `
  flex: 1;
  padding: 20px;
  background: #FFF;
  height: 100vh;
  overflow: scroll;

  td {
    border: none;
  }
`

export const containerStyles = `
  max-width: 600px;
  margin: 0px auto;
`

