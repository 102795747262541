export const getSource = (value) => {
  switch (value) {
    case "radius_marketplace":
      return "Radius Marketplace"
    case "zillow_agent_personal_account":
      return "Zillow (Agent's Personal Account)"
    case "zillow_radius_provided":
      return "Zillow (Radius Provided)"
    case "zillow_flex":
      return "Zillow Flex"
    case "opcity_realtor_com":
      return "OpCity/Realtor.com"
    case "team_mentor_lead":
      return "Team/Mentor Lead"
    case "self":
      return "Self"
    case "sphere_of_influence":
      return "Sphere of Influence/Personal"
    case "referrals_agent_Lender":
      return "Referrals (Agent/Lender)"
    default:
      return "Other"
  }
}
