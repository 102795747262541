import React from "react"
import { Button, notification } from "antd"
import { format } from "date-fns"

export const getIsAIEnabledStatus = (topicsState, id) => {
  const filtered = topicsState.topics
  && topicsState.topics.length > 0
  && topicsState.topics.filter((item => item.session_id === id)) || []

  if (filtered.length > 0) {
    console.log(filtered[0])
    return filtered[0].ai_response_on === 1
  }

  return false
}

export const serializer = (list) => {
  if (!list || Object.keys(list).length <= 0) return []
  list.sort((a, b) => a.document_name.localeCompare(b.document_name));
  return list.map((item, iter) => ({
    key: `${item.id}`,
    fileName: `${item.document_name}`,
    description: `${item.description || "N/A"}`,
    linkToFile: `${item.document_link || "N/A"}`,
    lastUpdated: format(new Date(item.created_at), "MMM dd, yyyy"),
    state: `${item.compliance_state || "N/A"}`,
    category: `${item.category || "N/A"}`,
  }))
}
export const openNotification = (id, query, constructQuery) => {
  const key = `open${Date.now()}`
  const btn = (
    <Button
      type="primary"
      size="small"
      onClick={() => {
        //id

        const constructed = {
          ...query,
          agent_id: id,
        }
        constructQuery({
          constructed,
        })
        notification.close(key)
      }}
    >
      Confirm
    </Button>
  )
  notification.open({
    message: "An agent has started a session",
    description:
      "Click on the button below to view",
    btn,
    key,
    onClose: () => {},
  })
}
