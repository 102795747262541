import React from "react"
import styled from "styled-components"
import { Title, Note } from "./common"

const Wrap = styled.div``

const AdminNotes = ({
  entityName,
  referral_manager = {},
  note,
  ...props
}) => (
  <Wrap>
    {console.log(entityName, props)}
    <Title type="Note" extraText={` by ${referral_manager.firstname} ${referral_manager.lastname}`} />
    {note && (
      <Note>
        <strong>Note: </strong>
        <span>{`"${note}"`}</span>
      </Note>
    )}
  </Wrap>
)

export default AdminNotes
