/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import styled from "styled-components"
import { get } from "lodash"
import { Button, Modal } from "antd"
import Select from "react-select"
import { withRouter } from "react-router-dom"
import AutoComplete from "dumbComponents/common/UI/Form/GoogleAutoCompleteV2.js"
import LoaderButton from "components/UI/Button/Button.js"
// import colors from "@colors"
import Box from "dumbComponents/common/UI/Box"
import root from "window-or-global"

// import AddIconColor from "dumbComponents/RealtyV3/RARRecruitment/images/AddIconColor201.svg"
// import BackArrow from "dumbComponents/RealtyV3/RARRecruitment/images/back-arrow.svg"
// import SubractIcon from "dumbComponents/FinancingDashboard/images/subtract.svg"
import SubractIcon from "icons/cancel.png"
import TeamContainer from "container/BrokerDashboard/teamContainer"
import Container from "container/EventRooms"
import RichTextEditor from "dumbComponents/common/RichTextEditor/RichEditor"

const Wrap = styled.div`
  text-align: left;
  padding: 30px 30px 0px 30px;
  ${props => props.alreadyRequested && `
    height: 374px;
    padding: 80px 100px 72px 100px;
  `}
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #303030;
  }
  h2 {
    font-style: normal;
    font-weight: 200;
    font-size: 28px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #;
    margin: -37px auto 30px;
    justify-content: center;
  }
  label {
    input {
      border: 1px solid #303030;
      font-weight: 200;
    }
    span {
      font-weight: 200;
    }
  }
  input {
    font-weight: 200;
  }
  .form-group {
    background: white;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    input {
      margin-top: 3px;
    }
    span {
      width: 90%;
      margin-left: -18px;
    }
  }
  .form-group:focus-within {
    border: 1px solid #C7C7C7; 
  }
`

const SelectWrap = styled.div`
  margin-top: 20px;
  .sell_option {
    font-size: 16px;
    margin-top: 10px;
    font-weight: 100;
    > div {
      background: white;
      border: 1px solid #C7C7C7;
      border-radius: 4px;
    }
    > div:focus-within {
      box-shadow: none;
    }
  }
`

const ButtonWrap = styled.div`
  margin: 75px 0 40px 0;
  display: flex;
  justify-content: end;
  button {
    padding: 10px 60px;
    height: auto;
    margin-left: 30px;
  }
`

const ImageContainer = styled.div`
  display: flex;
`

const Image = styled.img`
  margin: auto;
  cursor: pointer;
  width: 60%;
`

const Label = styled.span`
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 16px;
  color: #303030;
  margin-bottom: 50px;
`

const CityWrap = styled.div`
  display: flex;
  width: 102%;
  > div {
    width: 100%;
  }
  img {
    position: relative;
    left: -35px;
    top: 24px;
    height: 14px;
    cursor: pointer;
  }
`

const Section = styled.div`
`

const CityContainer = styled.div``

const AddContainer = styled.div`
  display: flex;
  cursor: pointer;
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: #3B60E4;
    margin: 0; 
  }
`

const RichTextEditorWrapper = styled.div`
  margin-top: 10px;
  .codex-editor__redactor {
    background: white;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 200;
    padding: 10px;
    min-height: 120px;
    margin-bottom: 0px !important;
    /* padding-bottom: 200px !important; */
    ${props => props.pb && `
      padding-bottom: ${props.pb} !important;
    `}
    ${props => props.mh && `
      min-height: ${props.mh};
    `}
  }
  > div {
    width: auto;
  }
`

const ProgressBarContainer = styled.div`
  width: 100%;
  background: #E5E5E5;
  height: 6px;
`

const ProgressBar = styled.div`
  width: ${props => props.percentage}%;
  background: #3B60E4;
  height: 6px;
`

const StepsContainer = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #303030;
`

const Error = styled.span`
  color: #ff4b4b;
  font-size: 12px;
`

class JobsCreateModal extends React.Component {
  state = {
    page: 1,
    jobId: null,
    jobSlug: "",
    job_title: "",
    workplace_type: "",
    job_description_and_responsibilities: "",
    status: "drafted",
    commission_split: "",
    about_team: "",
    team_name: "",
    location_details: [],
    location_details_copy: {},
    requirements: "",
    nextClicked: false,
    previewClicked: false,
    teamLeads: [],
    team_id: null,
    team_agent_id_obj: {},
    isLoaded: false,
  }

  componentDidMount() {
    const { jobDetails, teamInfo } = this.props
    if (jobDetails && jobDetails.id) {
      this.setState({
        jobId: jobDetails.id,
        job_title: jobDetails.job_title,
        workplace_type: jobDetails.workplace_type,
        job_description_and_responsibilities: jobDetails.job_description_and_responsibilities,
        commission_split: jobDetails.commission_split,
        about_team: jobDetails.about_team,
        team_name: jobDetails.team_name,
        requirements: jobDetails.requirements,
        location_details: jobDetails.location_details || [],
        jobSlug: jobDetails.job_slug,
      })
    }
    if (teamInfo && teamInfo.members) {
      const memEmail = jobDetails && jobDetails.id ? jobDetails.creator_email : ""
      const member = teamInfo.members.filter(mm => mm.email === memEmail).map(mm => ({
        label: `${mm.firstname} ${mm.lastname || ""}`,
        value: mm.id,
      }))
      this.setState({
        teamLeads: teamInfo.members.filter(mm => mm.is_brokerage_admin).map(mm => ({
          label: `${mm.firstname} ${mm.lastname || ""}`,
          value: mm.id,
        })),
        team_id: teamInfo.id,
        team_agent_id_obj: member && member.length ? member[0] : {},
      })
    }
    this.setState({
      isLoaded: true,
    })
  }

  changePage = (page) => {
    const {
      job_title, workplace_type, location_details, page: currentPage,
    } = this.state
    if (currentPage === 1 && !(job_title && workplace_type && location_details && location_details.length)) {
      this.setState({
        nextClicked: true,
      })
      return
    }
    this.setState({
      page,
    })
  }

  saveDescription = (value) => {
    this.setState({ [value.type]: value.content })
  }

  saveDescriptionV2 = (key, value) => {
    this.setState({ [key]: value })
  }

  addCityForm = () => {
    const { location_details } = this.state
    location_details.push({})
    this.setState({
      location_details,
    })
  }

  removeCityForm = (index) => {
    const { location_details } = this.state
    const locationDetails = location_details.filter((ld, id) => id !== index)
    this.setState({
      location_details: [...locationDetails],
    })
    this.forceUpdate()
  }

  addState = (prop, item) => {
    if (prop === "team_agent_id_obj") {
      this.setState({ [prop]: item })
      return
    }
    this.setState({ [prop]: item.value })
  }

  handleCity = (location, index) => {
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    const { location_details } = this.state
    location_details[index] = {
      city: locationDetails.city,
      state: locationDetails.state,
      location: `${locationDetails.city}, ${locationDetails.stateShortName}`,
    }
    this.setState({
      location_details,
    })
  }

  saveToLocalStorage = () => {
    const {
      jobId, job_title, workplace_type, job_description_and_responsibilities, commission_split, requirements, location_details_copy, location_details,
      about_team, team_name, page, jobSlug,
    } = this.state

    if (page === 2 && !(job_description_and_responsibilities && requirements && commission_split)) {
      this.setState({
        previewClicked: true,
      })
      return
    }

    root.localStorage.setItem("brokerage_jobs_storage", JSON.stringify({
      jobId,
      about_team,
      team_name,
      job_title,
      workplace_type,
      job_description_and_responsibilities,
      status: "completed",
      requirements,
      commission_split,
      location_details: location_details.filter(ld => !!ld.location),
      jobSlug,
    }))
    root.open("/realty/team-jobs/preview")
  }

  createBrokerageJob = (status) => {
    const { createJobs, updateJobs } = this.props
    const {
      job_title, workplace_type, job_description_and_responsibilities, commission_split, requirements, location_details_copy, jobId, location_details, page,
      team_id, team_agent_id_obj,
    } = this.state
    if (!(job_title && workplace_type && location_details && location_details.length && (team_agent_id_obj && team_agent_id_obj.value && job_description_and_responsibilities && commission_split && requirements))) {
      this.setState({
        nextClicked: true,
      })
      return
    }

    const body = {
      job_title,
      workplace_type,
      job_description_and_responsibilities,
      status,
      requirements,
      commission_split,
      location_details: location_details.filter(ld => !!ld.location),
      team_id,
      agentId: team_agent_id_obj.value,
    }
    if (jobId) {
      return updateJobs({
        jobId,
        body,
        payload: {
          teamId: team_id
        }
      })
      // return setOpenJobCreateModal(false)
    }
    return createJobs({
      body,
      payload: {
        teamId: team_id
      }
    })
    // return setOpenJobCreateModal(false)
  }

  getLocation = (index) => {
    const { location_details } = this.state
    return location_details && location_details.length && location_details[index] ? location_details[index].location : ""
  }

  isDisabled = ({
    job_title,
    workplace_type,
    location_details,
    job_description_and_responsibilities,
    requirements,
    commission_split,
  }, step) => {
    if (step === 1) {
      return !(job_title && workplace_type && location_details && location_details.length)
    }
    if (step === 2) {
      return !(job_description_and_responsibilities && requirements && commission_split)
    }
    return true
  }

  render() {
    const {
      job_title, page, requirements, commission_split, workplace_type, job_description_and_responsibilities, location_details,
      nextClicked, previewClicked, teamLeads, team_agent_id_obj, isLoaded,
    } = this.state
    const {
      brokerageCreateJobs, brokerageUpdateJobs, isEdit, showJobConfirmation, createdJobs, updatedJobs,
    } = this.props
    return (
      <React.Fragment>
        {isLoaded && (
          <Wrap>

            {showJobConfirmation && (<ImageContainer><Image src="https://cdn.dribbble.com/users/1751799/screenshots/5512482/check02.gif" /></ImageContainer>)}
            {showJobConfirmation && (
              <h2>
                Job Post Saved Successfully
              </h2>
            )}
            {!showJobConfirmation && (
              <>
                <h2>
                  {`${isEdit ? "Edit" : "Create"} a Job Post`}
                </h2>
                <Section>
                  <SelectWrap>
                    <Label>Job Title</Label>
                    <Select
                      className="sell_option"
                      value={{ label: job_title, value: job_title }}
                      options={[{ label: "Real Estate Agent", value: "Real Estate Agent" }, { label: "Broker", value: "Broker" }, { label: "Team Lead", value: "Team Lead" }]}
                      onChange={(item) => { this.addState("job_title", item) }}
                    />
                    {nextClicked && !job_title && (<Error>* Required</Error>)}
                  </SelectWrap>
                  <SelectWrap>
                    <Label>Select Team Lead</Label>
                    <Select
                      className="sell_option"
                      value={team_agent_id_obj}
                      options={teamLeads}
                      onChange={(item) => { this.addState("team_agent_id_obj", item) }}
                    />
                    {nextClicked && !(team_agent_id_obj && team_agent_id_obj.value) && (<Error>* Required</Error>)}
                  </SelectWrap>
                  <Box mb="10" mt="30">
                    <CityContainer>
                      <Label>Location</Label>
                      <CityWrap key={this.getLocation(0)}>
                        <AutoComplete
                          id="cities"
                          types={["(cities)"]}
                          location={loc => this.handleCity(loc, 0)}
                          value={this.getLocation(0)}
                          placeholder="Add City"
                          handleChangeInput={() => { }}
                          showLoader={false}
                          styles={{
                            height: "49px",
                            borderRadius: "0px",
                            background: "white",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        />
                        <span onClick={() => this.removeCityForm(0)}>
                          <img src={SubractIcon} alt="remove.svg" />
                        </span>
                      </CityWrap>
                      {location_details && location_details.map((ct, index) => (
                        <CityWrap key={ct.location}>
                          {index >= 1 && (
                            <>
                              {/* {ct.location} */}
                              <AutoComplete
                                key={index}
                                id="cities"
                                types={["(cities)"]}
                                location={loc => this.handleCity(loc, (index))}
                                value={ct.location}
                                placeholder="Add City"
                                handleChangeInput={() => { }}
                                showLoader={false}
                                styles={{
                                  height: "49px",
                                  borderRadius: "0px",
                                  background: "white",
                                  fontSize: "16px",
                                  marginTop: "10px",
                                }}
                              />
                              <span onClick={() => this.removeCityForm(index)}>
                                <img src={SubractIcon} alt="remove.svg" />
                              </span>
                            </>
                          )}
                        </CityWrap>
                      ))}
                      {!(location_details && location_details.length >= 5) && (
                        <AddContainer onClick={this.addCityForm}>
                          <h2>Add Another City</h2>
                          {/* <Image
                      src={AddIconColor}
                    /> */}
                        </AddContainer>
                      )}
                      {nextClicked && !(location_details && location_details.length) && (<Error>* Required</Error>)}
                    </CityContainer>

                  </Box>
                  <SelectWrap>
                    <Label>Workplace Type</Label>
                    <Select
                      className="sell_option"
                      options={[{ label: "On Site", value: "On Site" }, { label: "Hybrid", value: "Hybrid" }, { label: "Remote", value: "Remote" }]}
                      value={{ label: workplace_type, value: workplace_type }}
                      onChange={(item) => { this.addState("workplace_type", item) }}
                    />
                    {nextClicked && !workplace_type && (<Error>* Required</Error>)}

                  </SelectWrap>
                </Section>

                <Section>
                  <Box mb="10" mt="30">
                    <Label>Job Description & Responsibilites</Label>
                    <RichTextEditorWrapper>
                      <RichTextEditor saveRTF={value => this.saveDescriptionV2("job_description_and_responsibilities", value)} text={job_description_and_responsibilities} height="150px" />
                    </RichTextEditorWrapper>
                    {nextClicked && !job_description_and_responsibilities && (<Error>* Required</Error>)}
                  </Box>
                  <Box mb="10" mt="30">
                    <Label>Requirements</Label>
                    <RichTextEditorWrapper>
                      <RichTextEditor saveRTF={value => this.saveDescriptionV2("requirements", value)} text={requirements} height="150px" />
                    </RichTextEditorWrapper>

                    {nextClicked && !requirements && (<Error>* Required</Error>)}
                  </Box>
                  <Box mb="10" mt="30">
                    <Label>Commission Split</Label>
                    <RichTextEditorWrapper>
                      <RichTextEditor saveRTF={value => this.saveDescriptionV2("commission_split", value)} text={commission_split} height="150px" />
                    </RichTextEditorWrapper>

                    {nextClicked && !commission_split && (<Error>* Required</Error>)}
                  </Box>
                </Section>
                <ButtonWrap>
                  {/* <Button
                  size="large"
                  onClick={() => this.createBrokerageJob("drafted")}
                >
                  Save for later
                </Button> */}
                  <Button
                    size="large"
                    type="primary"
                    loading={createdJobs.isFetching || updatedJobs.isFetching}
                    onClick={() => this.createBrokerageJob("completed")}
                  >
                    Save
                  </Button>

                </ButtonWrap>
              </>
            )}
          </Wrap>
        )}
      </React.Fragment>
    )
  }
}

export default TeamContainer(Container(withRouter(JobsCreateModal)))
