import React from "react"
import styled from "styled-components"
import Tools from "./Tools"
import { COMPONENT_LIST, MAIN_LIST } from "./consts"

const Wrap = styled.div`
  position: fixed;
  top: 0px;
  transition-duration: 0.3s;
  left: -200px;
  width: 200px;
  bottom: 0px;
  background: red;
  height: 100vh;
  background: #DDD;
  ${props => props.isOpen && `
    left: 0px;
  `}
`
const ToggleWrap = styled.div`
  cursor: pointer;
  position: absolute;
  right: -40px;
  top: 20px;
  height: 40px;
  width: 40px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  background: #DDD;
  i {
    transition-duration: 0.3s;
    ${props => props.isOpen && `
      transform: rotate(180deg);
    `}
  }
`

const Title = styled.div`
  padding: 10px;
  background: rgba(0,0,0,0.1);
  font-size: 14px;
  font-weight: bold;
  color: #222;
`

const ItemList = styled.ul`
  padding: 0px;
  margin: 0px;
  list-style: none;
`

const Item = styled.li`
  border-bottom: 1px solid #CCC;
  padding: 5px 10px;
  cursor: pointer;
  background: rgba(255, 255, 255,0.3);
`

class Sidebar extends React.PureComponent {
  state = {
    isOpen: true,
  }

  componentDidMount() {
    const {  } = this.props
    this.handleItemClick(COMPONENT_LIST[1])
    setTimeout(() => {
      this.handleItemClick(MAIN_LIST[3])
    }, 200)

    setTimeout(() => {
      this.handleItemClick(COMPONENT_LIST[3])
    }, 400)
  }

  toggleOpen = (show) => {
    this.setState({
      isOpen: show,
    })
  }

  handleItemClick = (item) => {
    const { injectCode } = this.props
    injectCode(item)
    this.toggleOpen(false)
    // const { Component } = item
    // const html = renderToString(<Component />)
  }

  render() {
    const { isOpen } = this.state
    const {
      handleExport,
      handleReset,
      handleOpenNewTab,
      isSendEmailModalOpen,
      toggleEmailModal,
    } = this.props
    return (
      <Wrap isOpen={isOpen}>
        <ToggleWrap onClick={() => this.toggleOpen(!isOpen)} isOpen={isOpen}>
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </ToggleWrap>
        <Tools
          handleExport={handleExport}
          handleReset={handleReset}
          handleOpenNewTab={handleOpenNewTab}
          isSendEmailModalOpen={isSendEmailModalOpen}
          toggleEmailModal={toggleEmailModal}
        />
        <Title>
          Common Components
        </Title>
        <ItemList>
          {COMPONENT_LIST.map(item => (
            <Item key={item.label} onClick={() => { this.handleItemClick(item) }}>
              <span>{item.label}</span>
            </Item>
          ))}
        </ItemList>
        <Title>
          Body Components
        </Title>
        <ItemList>
          {MAIN_LIST.map(item => (
            <Item key={item.label} onClick={() => { this.handleItemClick(item) }}>
              <span>{item.label}</span>
            </Item>
          ))}
        </ItemList>
      </Wrap>
    )
  }
}

export default Sidebar
