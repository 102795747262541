import React from "react"
import styled, { css } from "styled-components"
import Textbox from "./Textbox"

const LABEL_TOP_FILLED = "-20px"
const LABEL_FONT_SIZE_FILLED = "12px"
const BORDER_STYLED = `2px solid #ccc`

const Span = styled.span`
  transition-duration: 0.2s;
  display: block;
  width: 100%;
  pointer-events: none;
  left: 0px;
  user-select: none;
  color: #A3A3A3;
  font-weight: 400;
  line-height: 1.5;
  background: #FFF;
  text-transform: uppercase;
  margin: 4px 0px 8px 0px;
`

const Label = styled.label`
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  width: auto;
  flex: 1;
  input {
    ${props => props.meta.touched && props.meta.error && `
      border: 1px solid #ED1616 !important;
    `}

    ${props => props.disabled && `
      color: red;
     `}
  }


  input:active,
  input:focus {
    border-bottom: 2px solid #11adf3;
    ::placeholder {
      color: #ccc !important;
    }

    :-ms-input-placeholder {
      color: #ccc !important;
    }

    ::-ms-input-placeholder {
      color: #ccc !important;
    }
    & ~ span {
      bottom: 45px;
    }
  }



  // Material Design
  ${props => props.isMaterial && css`
    margin-top: 20px;
    input:active,
    input:focus {
      border-bottom: ${BORDER_STYLED} !important;
      & ~ ${Span} {
        position: absolute;
        top: ${LABEL_TOP_FILLED};
        font-size: ${LABEL_FONT_SIZE_FILLED};
        left: 0px;
      }
    }

    ${Span} {
      position: absolute;
      top: 2px;
      width: max-content;
      left: 10px;
      ${prop => prop.socialSignIn && `
        left: auto;
        height: 29px;
        width: 100%;
      `}
    }
  `}

  ${props => props.isFilled && css`
    /* margin-top: 20px; */
    ${props.isMaterial && css`
      ${Span} {
        top: ${LABEL_TOP_FILLED};
        font-size: ${LABEL_FONT_SIZE_FILLED};
      }
    `}
  `}
`

const ErrorLabel = styled.span`
  color: #ff4b4b;
  font-size: 12px;
`

const Optional = styled.span`
  color: #ccc;
  display: block;
  width: 100%;
  margin-top: 5px;
  font-size: 12px;
  left: 0px;
`

const Input = (field) => {
  const {
    label,
    input: { value },
    meta,
    customInputStyle,
    optional,
    optionalText = "Optional",
    type,
    autoFocus,
    placeholder,
    disabled,
    icon,
    inputProps,
    lightBorder,
    isMaterial = false,
    isShowError,
    materailPlaceHolder = "",
    labelStyles = {},
    socialSignIn = false,
    children,
    customLabelStyle,
  } = field

  return (
    <React.Fragment>
      <Label
        style={{ ...customLabelStyle }}
        socialSignIn={socialSignIn}
        isFilled={Boolean(value)}
        isMaterial={isMaterial}
        meta={meta}
      >
        <Textbox
          {...field.input}
          type={type}
          autoFocus={autoFocus}
          placeholder={isMaterial ? materailPlaceHolder : placeholder}
          disabled={disabled}
          icon={icon}
          lightBorder={lightBorder}
          meta={meta}
          style={{
            ...customInputStyle || {}
          }}
          {...inputProps}
        >
        </Textbox>
        {children && children}
        <Span style={{ ...labelStyles }}>{label}</Span>
      </Label>
      {(meta.touched || isShowError) && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
      {/* {!meta.touched && isShowError && (
        <ErrorLabel style={{ textAlign: "left" }}>{isShowError}</ErrorLabel>
      )} */}
      {!(meta.touched && meta.error) && optional && <Optional className="optional-text">{optionalText}</Optional>}
    </React.Fragment>
  )
}
// border: 1px solid #ED1616
export default Input
