import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "ACADEMY"

export const handleSearchInputAction = createSimpleCreator(BASE, "HANDLE_SEARCH_INPUT")

export const handleAgentSearchAction = createSignalAction(BASE, "HANDLE_AGENT_SEARCH")

export const supplyOptionsAction = createSimpleCreator(BASE, "HANDLE_OPTIONS")

export const setCurrentAgentsAction = createSimpleCreator(BASE, "SET_CURRENT_AGENTS")

export const fetchMetricsAction = createSignalAction(BASE, "FETCH_METRICS")

export const removeFromCurrentAgentsActions = createSimpleCreator(BASE, "REMOVE_AGENT")

export const lowerInboundMissedCallAction = createSimpleCreator(BASE, "LOWER_BOUND_INBOUND")

export const upperInboundMissedCallAction = createSimpleCreator(BASE, "UPPER_BOUND_INBOUND")

export const lowerZillowMissedCallAction = createSimpleCreator(BASE, "LOWER_BOUND_ZILLOW")

export const upperZillowMissedCallAction = createSimpleCreator(BASE, "UPPER_BOUND_ZILLOW")

export const lowerRingingMissedCallAction = createSimpleCreator(BASE, "LOWER_BOUND_RINGING")

export const upperRingingMissedCallAction = createSimpleCreator(BASE, "UPPER_BOUND_RINGING")

export const callerQtyAction = createSimpleCreator(BASE, "CALLER_QTY")

export const avgCallTimeAction = createSimpleCreator(BASE, "AVG_CALL_TIME")

export const setCurrentDateFilterAction = createSimpleCreator(BASE, "CURRENT_DATE_FITLER")

export const setCurrentSortAction = createSimpleCreator(BASE, "CURRENT_SORT_TYPE")

export const setCurrentSubscriptionFilterAction = createSimpleCreator(BASE, "CURRENT_SUBSCRIPTION_FILTER")

export const minTextResponseTimeAction = createSimpleCreator(BASE, "MIN_TEXT_RESPONSE_TIME")

export const maxTextResponseTimeAction = createSimpleCreator(BASE, "MAX_TEXT_RESPONSE_TIME")

export const clearAllFiltersAction = createSimpleCreator(BASE, "CLEAR_FILTERS")

export const setCurrentDurationFilterDayAction = createSimpleCreator(BASE, "SET_CURRENT_DURATION_DAY")

export const setCurrentDurationFilterHourAction = createSimpleCreator(BASE, "SET_CURRENT_DURATION_HOUR")

export const setCurrentDurationFilterMinAction = createSimpleCreator(BASE, "SET_CURRENT_DURATION_MIN")

export const setCurrentTextDurationFilterDayAction = createSimpleCreator(BASE, "SET_CURRENT_TEXT_DURATION_DAY")

export const setCurrentTextDurationFilterHourAction = createSimpleCreator(BASE, "SET_CURRENT_TEXT_DURATION_HOUR")

export const setCurrentTextDurationFilterMinAction = createSimpleCreator(BASE, "SET_CURRENT_TEXT_DURATION_MIN")

export const setCurrentReferralTypeAction = createSimpleCreator(BASE, "SET_CURRENT_REFERRAL_TYPE")

export const setCurrentWorkingHoursAction = createSimpleCreator(BASE, "SET_CURRENT_WORK_CALL_TYPE")

export const setCurrentDurationFilterAction = createSimpleCreator(BASE, "SET_CURRENT_DURATION")

export const modifyCurrentLimitAction = createSimpleCreator(BASE, "SET_CURRENT_LIMIT")

export const setCurrentCustomDateRangeAction = createSimpleCreator(BASE, "SET_CUSTOM_DATE")

export const setCurrentReferralSourceAction = createSimpleCreator(BASE, "SET_CURRENT_REFERRAL_SOURCE")

export const setCurrentFiltersAction = createSimpleCreator(BASE, "SET_CURRENT_FILTER_STRING")
