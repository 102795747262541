import React from "react"
import styled from "styled-components"
import DayPickerInput from "react-day-picker/DayPickerInput"
import { ErrorLabel } from "./common"


const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${ErrorLabel} {
    margin-top: 5px;
  }
`

const Label = styled.p`
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 10px;
  color: #A3A3A3;
  margin-bottom: 3px;
  margin-top: 5px;
  letter-spacing: 2px;
  font-size: 14px;
  margin-bottom: 10px;
`

const RenderDatePicker = ({
  config,
  input,
  meta,
  customInputStyle,
  label,
  afterLimit,
  beforeLimit,
  disabled,
  setTodayDateAsDefault,
}) => (
  <Wrap
    onBlur={input.onBlur}
    onFocus={input.onFocus}
  >
    {label && (
      <Label>{label}</Label>
    )}
    <DayPickerInput
      onDayChange={(day) => {
        input.onChange(day)
      }}
      dayPickerProps={{
        disabledDays: {
          after: afterLimit,
          before: beforeLimit,
        },
      }}
      inputProps={{
        disabled,
        style: { ...customInputStyle || {} },
      }}
      value={setTodayDateAsDefault ? input.value || new Date() : input.value}
      {...config}
    />
    {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
  </Wrap>
)

export default RenderDatePicker
