import React from "react"
import styled from "styled-components"
import { ShimmerBox } from "shared/styles/animation"
import {
  SectionTitle,
} from "./commonStyles"
import DocCard from "./DocCard"
import { GetStatusIcon } from "./mappings"

const ComponentWrap = styled.div``

const DocBlock = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 10px;
`

const ListDocs = ({
  isaDocuments,
  docStatus,
  fetchingICADocuments,
  noIcon,
  noView,
  download,
}) => (
  <ComponentWrap>
    {!fetchingICADocuments && (
      <>
        {noIcon && noView ? (
          <SectionTitle>Fully Signed ICA Document</SectionTitle>
        ) : (
          <SectionTitle>ICA Document(s)</SectionTitle>
        )}
      </>
    )}
    <DocBlock>
      {fetchingICADocuments && (
        <ShimmerBox w="167px" h="268px" />
      )}
      {!fetchingICADocuments && isaDocuments && isaDocuments.length <= 0 && (
        <p>No Documents</p>
      )}
      {!fetchingICADocuments && isaDocuments && isaDocuments.length > 0 && isaDocuments.map(doc => (
        <DocCard
          doc={doc}
          icon={docStatus && GetStatusIcon(docStatus) || ""}
          noIcon={noIcon}
          noView={noView}
          download={download}
        />
      ))}
    </DocBlock>
  </ComponentWrap>
)

export default ListDocs
