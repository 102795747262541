import React  from 'react';
import {Fetch} from "../../lib/NetworkUtils";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export function addClient(client) {
    let url = baseUrl + "/v2.0/clients";
    return Fetch(url, {
        method: 'POST',
        body: JSON.stringify(client)
    })
}

export function updateClient(id, client) {
    let url = baseUrl + "/v2.0/clients/" + id;
    return Fetch(url, {
        method: 'PUT',
        body: JSON.stringify(client)
    })
}

