/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react"
import ImgCrop from "antd-img-crop"
import root from "window-or-global"
import styled from "styled-components"
import {
  Drawer,
  Upload,
  Button,
} from "antd"
import Loader from "../../common/UI/Loader"
import container from "../../../container/BrokerDashboard/globalPagesContainer"

const PAGE_SIZE = 30

const Label = styled.h2`
  margin-top: 10px;
  font-size: 14px;
  color: red;
`
const UploadImageDrawer = ({
  data,
  isUploadImage,
  toggleUploadImageDrawer,
  dealId,
  getAllDealsListing,
  uploadDocsForListing,
  uploadDocsForListingResponse,
  resetListingImages,
  allListingImages,
  setAllListingImages,
  createAllListingImages,
  resetDealId,
}) => {
  const handleClose = () => {
    toggleUploadImageDrawer(false)
    resetDealId()
  }

  const {
    isFetching: isUploadingFile,
    data: uploadData,
  } = uploadDocsForListingResponse || {}

  const [fileList, setFileList] = useState([])
  const [listingImages, setListingImages] = useState([])
  const [toRemove, setToRemove] = useState([])
  const [count, setCount] = useState(0)
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  const uploadImages = async (file) => {
    setCount(count + 1)
    await uploadDocsForListing({
      file,
      objName: `image${count}`,
      fileName: file.name,
    })
  }

  const onRemove = (file) => {
    if (uploadData) {
      const object1 = Object.values(uploadData)
      object1.map((x) => {
        if (Object.values(x)[1] === file.name) {
          toRemove.push(Object.values(x)[0])
        }
      })
    }
    if (allListingImages) {
      allListingImages.map((y) => {
        if (file.url === y.url && (y.status !== "removed")) {
          toRemove.push(y.url)
        }
      })
    }
    setToRemove(toRemove)
    const src = new Promise(() => true)
  }

  useEffect(() => {
    if (data) {
      setAllListingImages([])
      setFileList([])
      data.map((item) => {
        if (item.id === dealId) {
          if (item.listing_images !== null) {
            item.listing_images.map((x, i) => {
              allListingImages.push({
                url: x,
                name: i,
                uid: i,
                status: "success",
              })
            })
            setAllListingImages(allListingImages)
            setFileList(allListingImages)
          } if (item.listing_images === null) {
            setFileList([])
          }
        }
      })
    }
  }, [dealId])

  const onPreview = (file) => {
    if (isUploadingFile !== true) {
      if (uploadData) {
        const object1 = Object.values(uploadData)
        object1.map((x) => {
          root.open(Object.values(x)[0])
        })
        return
      }
      root.open(file.url)
    }
  }

  const saveImages = () => {
    setListingImages([])
    if (allListingImages) {
      allListingImages.map((x) => {
        listingImages.push(x.url)
      })
    }
    if (uploadData) {
      const object1 = Object.values(uploadData)
      object1.reverse()
      object1.map((x) => {
        listingImages.push(Object.values(x)[0])
      })
    }
    if (toRemove) {
      toRemove.map((x) => {
        listingImages.map((y, i) => {
          if (x === y) {
            listingImages.splice(i, 1)
          }
        })
      })
    }
    //payload
    const payload = {
      image_urls: listingImages,
      //here
      listing_id: dealId,
    }
    createAllListingImages({
      payload,
      callback: () => {
        getAllDealsListing({
          type: "global",
          limit: PAGE_SIZE,
          skip: 0,
          //here
          transactionType: "Listing",
        })
      },
    })
    toggleUploadImageDrawer(false)
    resetListingImages()
    resetDealId()
    setToRemove([])
  }

  return (
    <Drawer
      title="Image Upload Drawer"
      width={700}
      placement="left"
      onClose={handleClose}
      visible={isUploadImage}
    >
      <h1>Please Upload images for the Listing</h1>
      <ImgCrop
        rotate
        aspect="2"
      >
        <Upload
          action={uploadImages}
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          onRemove={onRemove}
          disabled={isUploadingFile === true}
        >
          {"+ Upload"}
        </Upload>
      </ImgCrop>
      {isUploadingFile === true ? (
        <Loader />
      ) : (
        <>
          <Button
            type="primary"
            onClick={() => saveImages()}
            disabled={isUploadingFile === true}
          >
        Save
          </Button>
          <Label>* Please click on Save after uploading or deleting any images to save the changes</Label>
        </>
      )}
    </Drawer>
  )
}

export default container(UploadImageDrawer)
