import React, { PureComponent, useEffect } from "react"
import styled from "styled-components"
import { ClockCircleOutlined, LoadingOutlined } from "@ant-design/icons"
import { Timeline, Spin } from "antd"
import moment from "moment"
import Container from "container/EventRooms"

const Wrap = styled.div`
  .ant-collapse-header {
    padding: 0 !important;
  }
  ${props => props.isFetching && `
    margin: auto;
  `}  
`

const Label = styled.p`
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #8A8A8A;
  margin-right: 10px;
  text-align: right;
`

const TimelineComponent = ({ LeftNode, Icon, RightNode, agentId, getCashbackAgentTimeline, cashbackAgentTimeline }) => {
  useEffect(() => {
    // cashbackAgentTimeline
    getCashbackAgentTimeline({
      skip: 0,
      limit: 5000,
      agentId,
    })
  }, [])

  const getDotColor = (status) => {
    if (status === "credited") return "#17974A"
    if (status === "approved") return "#0D97FF"
    if (status === "declined") return "#DC0000"
    if (status === "pending") return "#D8A800"
    return "gray"
  }

  return (
    <Wrap isFetching={cashbackAgentTimeline.isFetching}>
      {cashbackAgentTimeline.isFetching ? (

        <Spin style={{ width: "100%" }} indicator={(<LoadingOutlined style={{ fontSize: 44, margin: "30px auto" }} spin />)} />
      ) : (
        <Timeline mode="left" style={{ width: "100%" }}>
          {cashbackAgentTimeline && cashbackAgentTimeline.data && cashbackAgentTimeline.data.timelines.map(timeline => (
            <Timeline.Item style={{ width: "420px" }} color={getDotColor(timeline.status)} label={<Label>{moment(timeline.created_at * 1000).format("MMM DD, YYYY hh:mm A")}</Label>}><RightNode timeline={timeline} /></Timeline.Item>
          ))}
        </Timeline>
      )
      }
    </Wrap>
  )
}

export default Container(TimelineComponent)
// cashbackAgentTimeline