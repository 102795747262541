import React from "react"
import ReactTooltip from "react-tooltip"
import styled from "styled-components"
import LogoImage from "images/RadiusAgentNewLogoNoBar.svg"
import EyeCloseImg from "images/blink-eye-closed.png"
import EyeOpenImg from "images/blink-eye-open.png"

const BASE_URL = process.env.REACT_APP_API_BASE_URL

const Wrap = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  height: 60px;
  background: #FFF;
  z-index: 100;
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 2.5px 0px;
  padding: 10px;
  display: flex;
`

const LogoWrap = styled.div`
   width: 300px;
`

const BlinkWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  > div {
    width: 35px;
    height: 35px;
    position: relative;
    top: -2px;
    background-image: url(${EyeCloseImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition-duration: 0.3s;
    &:hover {
      background-image: url(${EyeOpenImg});
    }
  }
  > p {
    letter-spacing: 5px;
    font-weight: 400;
    margin-left: 10px;
  }
`

const ProfileWrap = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  width: 300px;
`

const UserImgWrap = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #888;
`

const UserDetails = styled.div`
  padding: 0px 20px;
  > p {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
  } 
`

const ModuleDropdown = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > p {
    margin: 0px;
  }
`

const DropdownArea = styled.div`
  position: absolute;
  top: 40px;
  left: -60px;
  width: 200px;
  z-index: 100;
  display: none;
  border-radius: 10px;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  ${props => props.isOpen && `
    display: block;
  `}
  ul {
    padding: 0px;
    margin: 0px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;

    > li {
      list-style: none;
      > a {
        background: #fff;
        padding: 16px 20px;
        display: block;
        border-bottom: 1px solid #ccc;
        transition-duration: 0.2s;
        cursor: pointer;
        text-decoration: none;
        color: #444;
        &:hover {
          background: #af9fd6;
        }
        > p {
          padding: 0px;
          margin: 0px;
          font-size: 14px;
        }
      }
    }
  }
`

const LINK_LIST = [
  {
    name: "Academy Dashboard",
    link: "academy-admin"
  },
  {
    name: "Dashboard",
    link: "/dashboard"
  },
  {
    name: "Email Generator",
    link: "/email-generator"
  },
  {
    name: "Refer & Earn",
    link: "/recruit-and-earn",
  },
  {
    name: "Share the Love",
    link: "/share-the-love",
  },
  {
    name: "RAR Dashboard",
    link: "/rar",
  },
  {
    name: "Caller Dashboard",
    link: "/caller-dashboard",
  },
  {
    name: "Agent Notifications",
    link: "/agent-notifications",
  },
  {
    name: "Event Room Dashboard",
    link: "/event-rooms",
  },
  {
    name: "Event Room Recordings",
    link: "/rooms",
  },
  {
    name: "Schedule Rooms Admin",
    link: "/scheduled-rooms",
  },
  {
    name: "AI Chat Dashboard",
    link: "/broker/ai",
  },
]

const NON_HEADER_ROUTES = [
  "/broker",
]

class BlinkHeader extends React.PureComponent {
  state = {
    isShowDropdown: false,
    isHide: false,
  }
  
  componentDidMount() {
    const { origin, host, pathname } = window.location
    if (
      origin.includes("soul.")
      || (
        host.includes("localhost")
        && pathname.includes("broker")
      )
      || (
        origin.includes("stagingblink")
        && pathname.includes("/broker/")
      )) {
      this.setState({
        isHide: true,
      })
    }
  }

  render() {
    const { isShowDropdown, isShowUserDropdown, isHide } = this.state
    if (isHide) {
      return null
    }
    // const {  } = this.props
    return (
      <Wrap>
        <LogoWrap>
            <img src={LogoImage} alt="Radius Agent Logo"/>
        </LogoWrap>
        <BlinkWrap onClick={() => { window.open("https://www.brainyquote.com/quote_of_the_day", "quote-page") }}>
          <div />
          <p>BLINK</p>
        </BlinkWrap>
        <ModuleDropdown
          onMouseEnter={() => this.setState({ isShowDropdown: true })}
          onMouseLeave={() => this.setState({ isShowDropdown: false })}
        >
          <p>Modules</p>
          <DropdownArea
            isOpen={isShowDropdown}
          >
            <ul>
              {LINK_LIST.map(x => (
                <li key={x.link}>
                  <a href={x.link} target="_blank">
                    <p>{x.name}</p>
                  </a>
                </li>
              ))}
            </ul>
          </DropdownArea>
        </ModuleDropdown>
        <ProfileWrap id="blink-availability">
         
        </ProfileWrap>
      </Wrap>
    )
  }
}

export default BlinkHeader
