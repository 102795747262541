import React, { useState, useEffect } from "react"
import AI from "container/AI"
import {
  Card,
  PieChart,
} from "./Elements"
import {
  Wrap,
} from "../commonStyles"
import {
  StatsParentDiv,
  StatsHeaderTitle,
  SectionalDiv,
} from "./statSectionCommonStyles"
import {
  generateExtraResponse,
} from "./commonUtils"

const INIT_STATE = {
  inaccurate_percent: 0,
  inconclusive_percent: 0,
  negative_feedback: 0,
  no_feedback: 0,
  not_relevant_percent: 0,
  positive_feedback: 0,
  repetitive_percent: 0,
  something_else_percent: 0,
  total_feedback: 0,
}

const data = {
  labels: [
    "Negative",
    "Positive",
    "No response",
  ],
  datasets: [{
    label: "AI Feedback Response",
    data: [0, 0, 0],
    backgroundColor: [
      "rgb(255, 99, 132)",
      "rgb(54, 162, 235)",
      "rgb(255, 205, 86)",
    ],
    hoverOffset: 4,
  }],
}

const StatsCard = ({
  fetchAIAnalyticsResponse,
}) => {
  const {
    data: analytics,
    isFetching: fetchingAnalytics,
  } = fetchAIAnalyticsResponse || {}

  const [keys, setKeys] = useState({ ...INIT_STATE })
  const [pieStats, setPieStats] = useState(data)

  useEffect(() => {
    if (!fetchingAnalytics && analytics) {
      if (analytics && analytics.length > 0) {
        setKeys({
          ...analytics[0],
          total_feedback: analytics[0].no_feedback
          + analytics[0].negative_feedback
          + analytics[0].positive_feedback,
        })

        const noFeedback = analytics[0].no_feedback
        const negativeFeedback = analytics[0].negative_feedback
        const positiveFeedback = analytics[0].positive_feedback

        const newData = {
          labels: [
            "Negative",
            "Positive",
            "No response",
          ],
          datasets: [{
            label: "AI Feedback Response",
            data: [negativeFeedback, positiveFeedback, noFeedback],
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
            ],
            hoverOffset: 4,
          }],
        }
        setPieStats(newData)
      }
    }
  }, [fetchAIAnalyticsResponse])

  return (
    <Wrap margin="40px 0px 0px 0px">
      <StatsHeaderTitle>
        <h2>Feedback over time</h2>
      </StatsHeaderTitle>
      <StatsParentDiv>
        <SectionalDiv sectionBorder>
          <PieChart data={pieStats} />
        </SectionalDiv>
        {fetchingAnalytics ? (
          <>
            <SectionalDiv>
              <Card
                title="Total Feedback"
                value="0"
              />
              <Card
                title="Total Feedback"
                value="0"
              />
              <Card
                title="Total Feedback"
                value="0"
              />
            </SectionalDiv>
            <SectionalDiv>
              <Card
                title="Total Feedback"
                value="0"
              />
            </SectionalDiv>
          </>
        ) : (
          <>
            <SectionalDiv>
              <Card
                title="Total Feedback"
                value={keys.total_feedback}
                promptText="Total number of feedbacks given by agents on an AI response."
              />
              <Card
                title="Positive Feedback"
                value={keys.positive_feedback}
                promptText="Total number of positive feedback by agents on an AI response"
              />
              <Card
                title="No Responses"
                value={keys.no_feedback}
                promptText="Total number of neutral feedback by agents on an AI response."
              />
            </SectionalDiv>
            <SectionalDiv>
              <Card
                title="Negative Feedback"
                value={keys.negative_feedback}
                extraResponse={generateExtraResponse(keys)}
                promptText="Total number of negative feedback by agents on an AI response."
              />
            </SectionalDiv>
          </>
        )}
      </StatsParentDiv>
    </Wrap>
  )
}

export default AI(StatsCard)
