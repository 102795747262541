import React from "react"
import styled from "styled-components"
import { isEmpty, throttle } from "lodash"
import InfiniteScroll from "react-infinite-scroller"
import Container from "container/Client"
import colors from "library/colors"
import icons from "library/icons/icons"
import { ErrorBox, Section, EmptyState } from "dumbComponents/Timeline/HelperComponents"
import Loader from "dumbComponents/common/UI/Loader"
import Acitivity from "./Activity"
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;

const Wrapper = styled.div`
  min-height: 500px;
  margin-bottom: 50px;
`

const LIMIT = 500

const Tips = styled.div`
  background: #FBFCFC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-top: 1px solid ${colors.disabledColor};
  border-bottom: 1px solid ${colors.disabledColor};

  > p {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
    color: ${colors.contentText};
  }
`

class Timeline extends React.Component {
  state = {
    markasQualificationApi : false,
  }

  componentDidMount() {
    const { fetchClientTimeline, clientId, agentId } = this.props
    fetchClientTimeline({
      clientId,
      limit: LIMIT,
      offset: 0,
      agentId,
    })
  }

  fetchPagination = () => {
    const { clientId, fetchClientTimeline, timeline, agentId } = this.props
    const {
      isFetching,
      isFetchingMore,
      data,
      isFetchedAll,
    } = timeline
    const { total, activities, offset } = data
    if (
      !isFetching
      && !isFetchingMore
      && total > (activities && activities.length)
      && !isFetchedAll
    ) {
      fetchClientTimeline({
        clientId,
        limit: LIMIT,
        offset: ~~LIMIT + ~~offset,
        isFetchingMore: true,
        agentId,
      })
    }
  }

  handleQualifyCallCheckbox = async (item) => {
      this.setState({markasQualificationApi: true})
      if (item.isQualified) {
          //toggle off
          item.isQualified = 0;

      }
      else {
          //toggle on
          item.isQualified = 1;
      }

      let url = baseUrl + '/v1.0/qualificationCall';
      let body = {
          client_id: item.client_id,
          is_qualified: item.isQualified,
          resource_referral_id: item.resource_referral_id,
          resource_id: item.resource_id,
          recording_url: item.recording_url,
          entity_id: item.entity_id,
          time: parseInt(item.time, 10),
      };

      try {
          let sol = await fetch(url, {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'secure-id': token,
              },
              body: JSON.stringify(body)
          });
          sol = await sol.json();
          this.setState({markasQualificationApi: false})

      }
      catch (e) {
          console.log(e);
          this.setState({markasQualificationApi: false})
      }
  }

  componentWillUnmount() {
    const { clearTimeline } = this.props
    clearTimeline()
  }

  render() {
    const {
      timeline,
      parsedTimeline,
    } = this.props
    const {
      isFetching,
      isError,
      isFetchingMore,
      data,
    } = timeline
    const {
      total = -1,
      activities,
      entities,
    } = data || {}

    return (
      <Wrapper style={{ width: "100%" }}>
        {!isFetching && !isError ? (
          <div style={{ marginTop: 40}}>
            {!isEmpty(parsedTimeline) ? (
              <InfiniteScroll
                hasMore={!isFetching || !isFetchingMore || total > (activities && activities.length)}
                loadMore={throttle(() => { this.fetchPagination() }, 4000)}
                threshold={250}
              >
                {parsedTimeline.map((item, index) => (
                  <React.Fragment>
                    <Acitivity
                      key={item.id}
                      currentEntityId={item.entity_id}
                      entityData={entities}
                      manualCallRecording={item.recording_url}
                      isActive={index === 0}
                      isLast={total === (index + 1)}
                      {...item}
                    />
                    <span>{item.recording_url ?  <span><input type="checkbox"  disabled={this.state.markasQualificationApi} className="activity-checkbox-left" checked={item.isQualified} onChange={() => this.handleQualifyCallCheckbox(item)}/> <label className="checkbox-text">
                        Mark as qualification call
                    </label> </span>: null}</span>
                  </React.Fragment>

                ))}
              </InfiniteScroll>
            ) : (
              <EmptyState text="No timeline activity Found" icon={icons.interfaceEmptyFile} />
            )}
          </div>
        ) : (
          <div>
            {!isFetchingMore && isFetching && (
              <Loader top="100" bottom="0" />
            )}
          </div>
        )}

        {isFetchingMore && (
          <Loader top="0" bottom="0" size="80" margin="50px 50px 50px 70px" />
        )}
      </Wrapper>
    )
  }
}

export default Container(Timeline)
