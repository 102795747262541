import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "MUTILPLE_CALLER"

export const connectToSocketAction = createSimpleCreator(BASE, "CONNECT_TO_SOCKET")
export const createInitStateAction = createSimpleCreator(BASE, "CREATE_INIT_STATE")

export const changeCallStateAction = createSimpleCreator(BASE, "CHANGE_CALL_STATUS")
export const updateAnElementInCallStatusAction = createSimpleCreator(BASE, "UPDATE_AN_ELEMENT_BY_RID")
export const updateCallStateAction = createSimpleCreator(BASE, "UPDATE_CALL_STATUS")
export const deleteItemAutomaticallyAction = createSimpleCreator(BASE, "AUTOMATIC_DELETION")

export const deleteElementFromStateAction = createSimpleCreator(BASE, "DELETE_CALL_STATUS")

