/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { get } from "lodash"
import { withRouter } from "react-router-dom"
import axios from "axios"
import { toast } from "react-toastify"
import cookies from "services/CookieStorage"
import Container from "container/BrokerDashboard/editAgentContainer"
import SidebarContainer from "container/BrokerDashboard/sidebarContainer"
import { ShimmerBox } from "shared/styles/animation"
import { Switch, Button, Modal } from "antd"
import {
  TitleWrap,
  SidebarTitle,
  LicenseTransDateWrap,
  SidebarHeading,
  CustomInput,
  LicenseTransWrap,
  SwitchText,
  SwitchWrap,
} from "../../common"

const API_V2_URL = `${process.env.REACT_APP_API_BASE_URL}/v2.0/`

const Wrap = styled.div``

const AgentDetails = ({
  agentDetailsResponse,
  getAgentDetails,
  agentBrokerageInfoResponse,
  updateAgentBrokerageInfo,
  getAgentBrokerageInfo,
  updateOrientationInfo,
  ...props
}) => {
  const agentId = get(props, "match.params.agentId", "")
  const {
    data: agentBrokerageInfo,
    isFetching: fetchingAgentBrokerageInfo,
  } = agentBrokerageInfoResponse || {}
  const {
    is_license_transferred, license_transferred_date,
    is_ica_signed, ica_signed_date, past_volume, dba, op_city_qualifications,
    cap_reset_date, past_brokerage_name, primary_sale_representative, cap,
    primary_sale_representative_split_percentage, secondary_sale_representative,
    secondary_sale_representative_split_percentage, other_past_brokerage_name,
  } = agentBrokerageInfo || {}

  const { data: agentData, isFetching: fetchingAgentDetails } = agentDetailsResponse || {}
  const {
    firstname, lastname, preferred_email, id,
    primary_email, phone, real_estate_license_number: realEstateLicenseNum,
  } = agentData || {}

  const [currentFirstname, setFirstname] = useState()
  const [currentLastname, setLastname] = useState()
  const [currentPFEmail, setPFEmail] = useState()
  const [currentDBA, setDBA] = useState()
  const [currentOPCityQual, setOPCityQual] = useState()
  const [currentLicenseNunber, setLicenseNunber] = useState()

  useEffect(() => {
    if (agentBrokerageInfo) {
      setDBA(dba)
      setOPCityQual(op_city_qualifications)
    }
  }, [agentBrokerageInfoResponse])

  const handleClick = (title, content, onClickConfirm) => {
    Modal.confirm({
      title,
      content,
      okText: "Yes",
      cancelText: "Not Now",
      onCancel() {
        onClickConfirm()
      },
    })
  }

  const handleSubmit = () => {
    const userPayload = {}
    userPayload.id = id || ""
    userPayload.firstname = currentFirstname || firstname
    userPayload.lastname = currentLastname || lastname
    userPayload.preferred_email = currentPFEmail || null
    userPayload.real_estate_license_number = currentLicenseNunber || realEstateLicenseNum

    const agentLicensePayload = {
      agent_id: parseInt(agentId, 10),
      real_estate_license_number: currentLicenseNunber || realEstateLicenseNum
    }
    updateOrientationInfo({
      payload: agentLicensePayload,
    })

    axios.put(`${API_V2_URL}user/info/update`, userPayload, {
      headers: {
        "secure-id": cookies.load("secureId"),
      },
    }).then((res) => {
      if (res.status === 200) {
        toast.success("Agent Details Updated")
      }
      getAgentDetails({
        agentId: id,
        isFetching: true,
      })
    }).catch((error) => {
      toast.error("Error!", error)
    })

    const payload = {
      is_license_transferred,
      agent_id: parseInt(agentId, 10),
      primary_sale_representative,
      secondary_sale_representative,
      primary_sale_representative_split_percentage,
      secondary_sale_representative_split_percentage,
      cap,
      is_ica_signed,
      ica_signed_date,
      license_transferred_date,
      other_past_brokerage_name,
      past_brokerage_name,
      past_brokerage_production: null,
      production_tier: null,
      past_volume,
      dba: currentDBA ? 1 : 0,
      op_city_qualifications: currentOPCityQual ? 1 : 0,
    }

    updateAgentBrokerageInfo({
      payload,
      agentId: parseInt(agentId, 10),
      callback: () => {
        getAgentBrokerageInfo({ agentId: parseInt(agentId, 10) })
      },
    })
  }

  return (
    <Wrap>
      <TitleWrap>
        <SidebarTitle>
          Agent Details
        </SidebarTitle>
        <LicenseTransDateWrap>
          <SidebarHeading>
            Phone Number
          </SidebarHeading>
          {fetchingAgentDetails ? <ShimmerBox w="290px" h="39px" /> : (
            <CustomInput
              style={{
                height: "39px",
              }}
              value={phone}
              disabled
            />
          )}
        </LicenseTransDateWrap>
        <LicenseTransDateWrap>
          <SidebarHeading>
            Primary Email
          </SidebarHeading>
          {fetchingAgentDetails ? <ShimmerBox w="290px" h="39px" /> : (
            <CustomInput
              style={{
                height: "39px",
              }}
              value={primary_email}
              disabled
            />
          )}
        </LicenseTransDateWrap>
        <LicenseTransDateWrap>
          <SidebarHeading>
            Real Estate Licence Number
          </SidebarHeading>
          {fetchingAgentDetails ? <ShimmerBox w="290px" h="39px" /> : (
            <CustomInput
              style={{
                height: "39px",
              }}
              defaultValue={realEstateLicenseNum}
              onChange={value => setLicenseNunber(value.target.value)}
            />
          )}
        </LicenseTransDateWrap>
        <LicenseTransWrap>
          <SidebarHeading>
            Agent Opt In
          </SidebarHeading>
          {fetchingAgentDetails ? <ShimmerBox w="290px" h="39px" /> : (
            <SwitchWrap>
              <Switch
                onChange={() => console.log("here")}
                size="small"
              />
              <SwitchText>
                Yes
              </SwitchText>
            </SwitchWrap>
          )}
        </LicenseTransWrap>
        <LicenseTransDateWrap>
          <SidebarHeading>
            First Name
          </SidebarHeading>
          {fetchingAgentDetails ? <ShimmerBox w="290px" h="39px" /> : (
            <CustomInput
              style={{
                height: "39px",
              }}
              defaultValue={firstname}
              onChange={value => setFirstname(value.target.value)}
            />
          )}
        </LicenseTransDateWrap>
        <LicenseTransDateWrap>
          <SidebarHeading>
            Last Name
          </SidebarHeading>
          {fetchingAgentDetails ? <ShimmerBox w="290px" h="39px" /> : (
            <CustomInput
              style={{
                height: "39px",
              }}
              defaultValue={lastname}
              onChange={value => setLastname(value.target.value)}
            />
          )}
        </LicenseTransDateWrap>
        <LicenseTransDateWrap>
          <SidebarHeading>
            Preferred Email
          </SidebarHeading>
          {fetchingAgentDetails ? <ShimmerBox w="290px" h="39px" /> : (
            <CustomInput
              style={{
                height: "39px",
              }}
              defaultValue={preferred_email}
              onChange={value => setPFEmail(value.target.value)}
            />
          )}
        </LicenseTransDateWrap>
        <LicenseTransWrap>
          <SidebarHeading>
            DBA
          </SidebarHeading>
          {fetchingAgentBrokerageInfo ? <ShimmerBox w="290px" h="39px" /> : (
            <SwitchWrap>
              <Switch
                onChange={value => setDBA(value)}
                size="small"
                checked={currentDBA}
                onClick={() => handleClick("Change DBA", "Are you sure you want to change the status of DBA?", () => setDBA(currentDBA))}
              />
              <SwitchText>
                Yes
              </SwitchText>
            </SwitchWrap>
          )}
        </LicenseTransWrap>
        <LicenseTransWrap>
          <SidebarHeading>
            Op City Qualifications
          </SidebarHeading>
          {fetchingAgentBrokerageInfo ? <ShimmerBox w="290px" h="39px" /> : (
            <SwitchWrap>
              <Switch
                onChange={value => setOPCityQual(value)}
                onClick={() => handleClick("Change Op City Qualifications", "Are you sure you want to change the status of Op City Qualifications?", () => setOPCityQual(currentOPCityQual))}
                size="small"
                checked={currentOPCityQual}
              />
              <SwitchText>
                Yes
              </SwitchText>
            </SwitchWrap>
          )}
        </LicenseTransWrap>
        <Button
          onClick={() => {
            handleSubmit()
          }}
          type="primary"
        >
          Save
        </Button>
      </TitleWrap>
    </Wrap>
  )
}

export default withRouter(SidebarContainer(Container(AgentDetails)))
